import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import servicesPic from '../../../../../assets/profiles/profile/avtar_md.jpeg';
import { employmentChoices, genralMonths } from 'config/formConstants';

import { rangeOfFullYears } from 'components/utils';
import { AddCircle } from 'components/common/Icons';
import { BorderLessButton, VerticalLine } from 'components/StyledComponents';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';
import { Input, TextArea } from 'components/common/Input';
import {
  addExperience,
  getExperience,
  updateExperience,
  deleteExperience,
  getIndustries,
} from 'store/slices/userProfileSlice';
import '../../../../../scss/components/_timeline.scss';
const ExperienceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const ExperienceContainer = styled.div``;

const ExperienceDetailsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: ${(props) => (props.noBorder ? '' : '1px solid #d9d9d9')};
  padding: 10px 0;
`;

const ExperienceDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExperienceDetailsAction = styled.div`
  display: flex;
`;

const ExperienceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
`;

const ExperienceDetailsImage = styled.img`
  height: 50px;
`;

const ExperienceDetailsName = styled.p`
  flex-grow: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
`;

const ExperienceDetailsTimeline = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #747474;
`;

const ExperienceDetailsDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5b5b5b;
`;

const initialState = {
  title: '',
  employment_type: '',
  company_name: '',
  location: '',
  industry: '',
  desc: '',
  profile_headline: '',
  start_month: '0',
  start_year: '',
  end_month: '0',
  end_year: '',
  currently_working: false,
  created_at: '',
  updated_at: '',
};

const years = rangeOfFullYears('1960');

const Experience = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const { experience, isNewExperience, loading, industriesList } = useSelector((state) => state.userProfile);
  const [experienceId, setExperienceId] = useState(null);
  const [fields, setFields] = useState(initialState);
  const [editMode, setEditMode] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  useEffect(() => {
    dispatch(getIndustries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const industriesOptions = useMemo(
    () =>
      industriesList?.map((t) => {
        return { value: t.name, label: t.name };
      }),
    [industriesList],
  );

  useEffect(() => {
    if (isNewExperience) {
      setExperienceId(null);
      dispatch(getExperience());
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewExperience]);

  const saveExperience = (id) => {
    const errorObject = {};

    if (!fields.title) {
      errorObject.title = 'Please enter a title';
    }
    if (!fields.employment_type) {
      errorObject.employment_type = 'Please select an employment type';
    }
    if (!fields.company_name) {
      errorObject.company_name = 'Please enter a company name';
    }
    if (!fields.location) {
      errorObject.location = 'Please enter a location';
    }
    if (!fields.industry) {
      errorObject.industry = 'Please select an industry';
    }
    if (!fields.desc) {
      errorObject.desc = 'Please enter a description';
    }
    // if (!fields.profile_headline) {
    //   errorObject.profile_headline = 'Please enter a profile headline';
    // }
    if (!fields.start_month || !fields.start_year) {
      errorObject.joining = 'Please select a start month and year';
    }
    if (fields.currently_working !== true && (!fields.end_month || !fields.end_year)) {
      errorObject.leaving = 'Please select a end month and year';
    }

    if (!fields.currently_working) {
      if (parseInt(fields?.start_year) > parseInt(fields?.end_year)) {
        errorObject.leaving = 'End year should be greater than start year';
      } else {
        if (parseInt(fields?.start_year) === parseInt(fields?.end_year)) {
          if (parseInt(fields?.start_month) > parseInt(fields?.end_month)) {
            errorObject.leaving = 'End year and Month should be greater than start year and month';
          }
        }
      }
    }
    // if (fields.currently_working) {
    //   let currentWorking = experience?.results.find((item) => item.currently_working === true);
    //   if (currentWorking) {
    //     errorObject.leaving = 'Already Working in Old Company please update that before adding New';
    //   }
    // }

    if (Object.keys(errorObject).length > 0) {
      setError(errorObject);
      return;
    }

    const payload = {
      title: fields.title,
      employment_type: fields.employment_type,
      company_name: fields.company_name,
      location: fields.location,
      industry: fields.industry,
      desc: fields.desc,
      profile_headline: fields?.profile_headline || 'Test',
      start_month: fields.start_month,
      start_year: fields.start_year,
      end_month: fields.end_month,
      end_year: fields.end_year,
      currently_working: fields.currently_working,
    };

    if (id) {
      dispatch(updateExperience({ ...payload, id }));
    } else {
      dispatch(addExperience(payload));
    }
  };

  const handleDeleteExperience = (id) => {
    dispatch(deleteExperience(id));
  };

  const editExperience = (id) => {
    setEditMode(true);
    setExperienceId(id);
    const currentForm = experience?.results?.find((exp) => id === exp.id);
    setFields(currentForm);
    setError({});
  };

  const addNewExperience = () => {
    setFields(initialState);
    setExperienceId(null);
    setEditMode(true);
    setError({});
  };

  const onCancelExperience = () => {
    setEditMode(false);
    setExperienceId(null);
    setFields(initialState);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const getDiffYear = (endYear, endMonth, startYear, startMonth) => {
    let sY = startYear !== '0' ? startYear : new Date().getFullYear();
    let eY = endYear !== '0' ? endYear : new Date().getFullYear();

    let sM = startMonth !== '0' ? startMonth : '1';
    let eM = endMonth !== '0' ? endMonth : '1';

    let a = moment(`01/${eM}/${eY}`, 'DD/MM/YYYY');
    let b = moment(`01/${sM}/${sY}`, 'DD/MM/YYYY');

    const diff = moment.duration(a.diff(b));
    return `${diff.years() || '0'} years ${diff.months() || '0'} months`;
  };

  if (loading) return <div>Loading...</div>;

  if (editMode) {
    return (
      <form>
        <p className="helper-text">Position</p>
        <Input type="text" name="title" value={fields.title} placeholder="Title" onChange={onChange} />
        {error.title && <div className="text-danger">{error.title}</div>}

        <p className="helper-text">Employment type</p>
        <Dropdown
          id={'employment_type'}
          name="employment_type"
          options={employmentChoices}
          title={'employment_type'}
          handleChange={onChange}
          selectedValue={fields.employment_type}
        />

        {error.employment_type && <div className="text-danger">{error.employment_type}</div>}
        <p className="helper-text">Company Name</p>
        <Input
          type="text"
          placeholder="Company Name"
          value={fields.company_name}
          name="company_name"
          onChange={onChange}
        />
        {error.company_name && <div className="text-danger">{error.company_name}</div>}
        <p className="helper-text">Location</p>
        <Input type="text" placeholder="Location" value={fields.location} name="location" onChange={onChange} />
        {error.location && <div className="text-danger">{error.location}</div>}
        <Checkbox
          className=""
          id="currentRole"
          label="I am currently working in this role"
          onChange={handleCheckbox}
          name="currently_working"
          checked={fields.currently_working}
        />
        <p className="helper-text">Start Date</p>
        <div className="d-flex gap-3">
          <Dropdown
            id={'start_month'}
            name="start_month"
            options={genralMonths}
            title={'Locations'}
            handleChange={onChange}
            selectedValue={fields.start_month}
            defaultValue={'Month'}
          />
          <Dropdown
            id="start_year"
            name="start_year"
            options={years}
            title={'start_year'}
            handleChange={onChange}
            selectedValue={fields.start_year}
            defaultValue={'Year'}
          />
        </div>
        {error.joining && <div className="text-danger">{error.joining}</div>}

        {fields.currently_working !== true && (
          <>
            <p className="helper-text">End Date</p>
            <div className="d-flex gap-3">
              <Dropdown
                id="end_month"
                name="end_month"
                options={genralMonths}
                title={'end_month'}
                handleChange={onChange}
                selectedValue={fields.end_month}
                defaultValue={'Month'}
              />
              <Dropdown
                id="end_year"
                name="end_year"
                options={years}
                title={'Locations'}
                handleChange={onChange}
                selectedValue={fields.end_year}
                defaultValue={'Year'}
              />
            </div>
          </>
        )}
        {error.leaving && <div className="text-danger">{error.leaving}</div>}

        <p className="helper-text">Industry</p>
        <Dropdown
          id="industry"
          name="industry"
          options={industriesOptions}
          title={'industry'}
          handleChange={onChange}
          selectedValue={fields.industry}
        />

        {error.industry && <div className="text-danger">{error.industry}</div>}
        <p className="helper-text">Description</p>
        <TextArea rows={3} placeholder="Description" name="desc" value={fields.desc} onChange={onChange} />
        {error.desc && <div className="text-danger">{error.desc}</div>}

        <div className="d-flex gap-2 justify-content-end mt-4">
          <Button variant="btn-secondary" className="h-36" onClick={onCancelExperience}>
            Cancel
          </Button>
          <Button variant="btn-primary" className="h-36" onClick={() => saveExperience(experienceId)}>
            {experienceId ? 'Update' : 'Save'}
          </Button>
        </div>
      </form>
    );
  }

  console.log('experience?.results:----', experience?.results);

  const formattedExperiences = experience?.results?.reduce((acc, experience) => {
    const {
      company_name,
      title,
      location,
      industry,
      employment_type,
      start_month,
      start_year,
      end_month,
      end_year,
      currently_working,
      profile_headline,
      id,
      desc,
    } = experience;

    // Check if the company already exists in the accumulator
    const existingCompany = acc.find((item) => item.company_name === company_name);

    if (existingCompany) {
      // If the company exists, append the current experience to its "experiences" array
      existingCompany.children.push({
        title,
        id,
        employment_type,
        location,
        industry,
        desc,
        profile_headline,
        start_month,
        start_year,
        end_month,
        end_year,
        currently_working,
      });
    } else {
      // If the company doesn't exist, create a new object for the company
      const newCompany = {
        company_name,
        title,
        location,
        industry,
        employment_type,
        start_month,
        start_year,
        end_month,
        end_year,
        currently_working,
        profile_headline,
        id,
        desc,
        children: [
          {
            title,
            id,
            employment_type,
            location,
            industry,
            desc,
            profile_headline,
            currently_working,
            start_month,
            start_year,
            end_month,
            end_year,
          },
        ],
      };
      acc.push(newCompany);
    }

    return acc;
  }, []);
  console.log('------________---------------------------------------');
  console.log('formattedExperiences--', formattedExperiences);
  console.log('------________---------------------------------------');

  return (
    <>
      <ExperienceHeader>
        <AddCircle />
        <BorderLessButton onClick={addNewExperience}>Add Experience</BorderLessButton>
      </ExperienceHeader>

      <ExperienceContainer>
        {formattedExperiences?.map((exp, index) => (
          <ExperienceDetailsContainer key={index} noBorder={experience?.results?.length == index + 1}>
            <ExperienceDetailsImage src={servicesPic} />
            <ExperienceDetailsContent>
              <ExperienceDetailsHeader>
                <ExperienceDetailsName>
                  {exp?.children?.length > 1 ? (
                    <>
                      <strong>{exp?.company_name}</strong>
                    </>
                  ) : (
                    <>
                      {' '}
                      {exp.title} at <strong>{exp?.company_name}</strong>
                    </>
                  )}
                </ExperienceDetailsName>
                {exp?.children?.length == 1 && (
                  <>
                    <ExperienceDetailsAction>
                      <BorderLessButton onClick={() => editExperience(exp.id)}>Edit</BorderLessButton>
                      <VerticalLine />
                      <BorderLessButton onClick={() => handleDeleteExperience(exp.id)}>Delete</BorderLessButton>
                    </ExperienceDetailsAction>
                  </>
                )}
              </ExperienceDetailsHeader>
              {exp?.children?.length > 1 ? (
                <>
                  <ExperienceDetailsTimeline>{exp.location}</ExperienceDetailsTimeline>
                  <ExperienceDetailsDescription>
                    <div class="timeline">
                      {exp?.children?.map((indChild, index) => {
                        return (
                          <div class="timeline__event timeline__event--type2" key={index}>
                            <div class="timeline__event__content ">
                              <ExperienceDetailsHeader>
                                <ExperienceDetailsName>{indChild.title}</ExperienceDetailsName>
                                <ExperienceDetailsAction>
                                  <BorderLessButton onClick={() => editExperience(indChild.id)}>Edit</BorderLessButton>
                                  <VerticalLine />
                                  <BorderLessButton onClick={() => handleDeleteExperience(indChild.id)}>
                                    Delete
                                  </BorderLessButton>
                                </ExperienceDetailsAction>
                              </ExperienceDetailsHeader>
                              <ExperienceDetailsTimeline>
                                {((indChild?.start_month && indChild?.start_year) ||
                                  (indChild?.end_month && indChild?.end_year)) && (
                                  <>{`${genralMonths[parseInt(indChild?.start_month - 1)]?.label} ${
                                    indChild?.start_year
                                  } - ${
                                    indChild?.currently_working
                                      ? 'Present'
                                      : `${genralMonths[parseInt(indChild?.end_month - 1)]?.label} ${
                                          indChild?.end_year
                                        }`
                                  } - ${indChild?.location}`}</>
                                )}
                              </ExperienceDetailsTimeline>

                              {indChild.end_year && (
                                <ExperienceDetailsTimeline>
                                  <p>{`${getDiffYear(
                                    indChild.end_year,
                                    indChild.end_month,
                                    indChild.start_year,
                                    indChild.start_month,
                                  )}`}</p>
                                </ExperienceDetailsTimeline>
                              )}
                              <ExperienceDetailsTimeline>
                              
                                {indChild?.industry}  
                                {indChild?.employment_type && (
                      <> {-employmentChoices.find((item) => item.value === indChild?.employment_type)?.label ||''}</>
                    )}
                              </ExperienceDetailsTimeline>

                              <div class="timeline__event__description">
                                <p>{indChild.desc}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </ExperienceDetailsDescription>
                </>
              ) : (
                <>
                  <ExperienceDetailsTimeline>
                    {((exp?.start_month && exp?.start_year) || (exp?.end_month && exp?.end_year)) && (
                      <>
                        {`${genralMonths[parseInt(exp.start_month - 1)]?.label} ${exp.start_year} - ${
                          exp.currently_working
                            ? 'Present'
                            : `${genralMonths[parseInt(exp.end_month - 1)]?.label} ${exp.end_year}`
                        } - ${exp.location}`}
                      </>
                    )}
                  </ExperienceDetailsTimeline>

                  {exp.end_year && (
                    <ExperienceDetailsTimeline>
                      <p>{`${getDiffYear(exp.end_year, exp.end_month, exp.start_year, exp.start_month)}`}</p>
                    </ExperienceDetailsTimeline>
                  )}

                  <ExperienceDetailsTimeline>
                    {exp?.industry}
                    {exp?.employment_type && (
                      <> {-employmentChoices.find((item) => item.value === exp?.employment_type)?.label ||''}</>
                    )}
                  </ExperienceDetailsTimeline>

                  <ExperienceDetailsDescription>{exp.desc}</ExperienceDetailsDescription>
                </>
              )}
            </ExperienceDetailsContent>
          </ExperienceDetailsContainer>
        ))}
      </ExperienceContainer>
    </>
  );
};

export default Experience;

{
  /* <div class="timeline">
          <div class="timeline__event timeline__event--type1">
            
            <div class="timeline__event__content ">
              <div class="timeline__event__title">Birthday</div>
              <div class="timeline__event__description">
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab
                  asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum
                  quidem dolorem in!
                </p>
              </div>
            </div>
          </div>
          <div class="timeline__event timeline__event--type2">
            
            <div class="timeline__event__content">
              <div class="timeline__event__title">Lunch</div>
              <div class="timeline__event__description">
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab
                  asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum
                  quidem dolorem in!
                </p>
              </div>
            </div>
          </div>
          
        </div> */
}
