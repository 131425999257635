import Alert from 'components/common/Alert/Alert';
import { Spinner } from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import { trimStr } from 'components/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUsersUpdated, getTeamOrganiserList, removeTeamMember, updateUserRole } from 'store/slices/teamCommunitySlice';

const MemberStatusModificationModal = ({ onClose, user, team, type }) => {
  const { loading, error, message, msg, usersUpdated, teamOrganisers } = useSelector((state) => state.teamsCommunity);
  const [customError, setCustomError] = useState()
  const dispatch = useDispatch();

  useState(() => {
    dispatch(getTeamOrganiserList({ for_team_community_id: team?.id, list_type: "organizer" }));
  }, [])

  useEffect(() => {
    if (usersUpdated) {
      setTimeout(() => {
        dispatch(clearUsersUpdated())
        handleClose()
      }, 500)
    }
  }, [usersUpdated])

  const handleSend = () => {
    if (type === "make-organizer") {
      if (teamOrganisers?.length >= 3) {
        setCustomError(`Can't make ${getFullName()} organizer! There can not be more than 3 organizers at a time in a team.`)
        setTimeout(() => {
          handleClose()
        }, 3000)
        return;
      }
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
        "user_status": "organizer"
      }
      dispatch(updateUserRole(body));
    }
    if (type === "remove-organizer") {
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
        "user_status": "member"
      }
      dispatch(updateUserRole(body));
    }
    if (type === "block-member") {
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
        "user_status": "blocked"
      }
      dispatch(updateUserRole(body));
    }
    if (type === "remove-member") {
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
      }
      dispatch(removeTeamMember(body));
    }
  };

  const handleClose = () => {
    onClose();
  };

  const getErrorMessage = (msg) => {
    if (msg?.toLowerCase?.()?.includes?.("before left the the team, Assign disk subscription to other organizer".toLowerCase())) {
      return "Please assign the disk subscription to other organizer, before leaving the team!"
    }
    return "Could not leave the team, please try again later!"

  }

  const getHeading = () => {
    return type === "make-organizer" ?
      <h2>
        Make <span title={getFullName()}>{trimStr(getFullName(), { length: 15 })}</span> organizer?
      </h2> : type === "remove-member" ?
        <h2>
          Remove <span title={getFullName()}>{trimStr(getFullName(), { length: 15 })}</span> from the team?
        </h2> : type === "block-member" ?
          <h2>
            Block <span title={getFullName()}>{trimStr(getFullName(), { length: 15 })}</span> from the team?
          </h2> : type === "remove-organizer" ?
            <h2>
              Remove <span title={getFullName()}>{trimStr(getFullName(), { length: 15 })}</span> as organizer?
            </h2> : ""
  }

  const getBody = () => {
    return type === "make-organizer" ?
      <p className="">
        {getFullName()} will have access to myBridge Project and any team resources within, including all files and conversations.
      </p> : type === "remove-member" ?
        <p className="">
          {getFullName()} will no longer have access to myBridge Project and any team resources within, including all files and conversations.
        </p> : type === "block-member" ?
          <p className="">
            {getFullName()} will no longer have access to myBridge Project and any team resources within, including all files and conversations.
          </p> : type === "remove-organizer" ?
            <p className="">
              {getFullName()} will no longer have access to myBridge Project and any team resources within, including all files and conversations.
            </p> : ""
  }

  const getFullName = () => (user?.first_name ?? "") + " " + (user?.last_name ?? "")

  return (
    <>
      <Modal
        onClose={handleClose}
        className="confirmation-modal"
        heading={
          getHeading()
        }
        action={
          <>
            <button className={`btn btn-light `} onClick={handleClose}>
              No
            </button>
            <button className="btn btn-primary" disabled={loading} onClick={handleSend}>
              {loading ? <Spinner /> : "Yes"}
            </button>
          </>
        }
      >
        <>
          {customError ? <Alert type="danger">{customError}</Alert> : ""}
          {error && message ? <Alert type="danger">{getErrorMessage(message)}</Alert> : ""}
          {msg ? <Alert type="success">{getFullName()}Successfuly updated.</Alert> : ""}
          {getBody()}
        </>
      </Modal>
    </>
  );
};

export default MemberStatusModificationModal;
