import React from 'react';

const RadioButton = (props) => {
  return (
    <div className={props.containerClass ? `custom-radio ${props.containerClass}` : 'custom-radio'}>
      <label>
        <input
          id={props.id}
          name={props.name}
          onChange={props.changed}
          value={props.value}
          type="radio"
          checked={props.isSelected}
          onClick={props.onClick}
        />
        <span className={props.label ? 'with-label' : 'wd-20'} style={{ color: props.color || '' }}>{props.label}</span>
      </label>
    </div>
  );
};

export default RadioButton;
