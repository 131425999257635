import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import API from 'store/common/api';
import { CompanyAPIEndpoints } from 'store/common/endPoint';

export const getCompanyPageInfo = createAsyncThunk('getCompanyPageInfo', async (payload) => {
  let { id } = payload || {};
  if (!id) {
    const company = JSON.parse(localStorage.getItem('Company'));
    if (company?.id) id = company.id;
  }
  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${id}/`,
  };
  return await API(obj);
});

export const patchCompanyInfo = createAsyncThunk('patchCompanyInfo', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.updateCompanyInfo(payload.id)}`,
    method: 'PATCH',
    body: formData,
    isFormData: true,
  };
  const response = await API(obj);
  return response;
});

export const getCompanyFollowersList = createAsyncThunk('getCompanyFollowersList', async (id) => {
  const obj = {
    url: CompanyAPIEndpoints.FollowersApi(id),
    method: 'GET',
  };
  return await API(obj);
});

export const getCompanyServicesInfo = createAsyncThunk('getCompanyServicesInfo', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${id}/services/`,
  };
  return await API(obj);
});

export const getAllCompanyPages = createAsyncThunk('getAllCompanyPages', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompaniesAPI}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const createCompanyPage = createAsyncThunk('createCompanyPage', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}`,
    method: 'POST',
    body: formData,
    isFormData: true,
  };
  return await API(obj);
});

export const deleteCompanyPage = createAsyncThunk('deleteCompanyPage', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyDelete(payload.id)}`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const getCompanyPublicData = createAsyncThunk('getCompanyPublicData', async (slug) => {
  const obj = {
    url: `${CompanyAPIEndpoints.PublicCompaniesAPI(slug)}`,
  };
  return await API(obj);
});

export const getFollowCompany = createAsyncThunk('getFollowCompany', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.FollowCompanyApi(id)}`,
  };
  return await API(obj);
});

export const listFollowedCompanies = createAsyncThunk('listFollowedCompanies', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.FollowersApi(id)}`,
  };
  return await API(obj);
});

export const getUnFollowCompany = createAsyncThunk('getUnFollowCompany', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UnFollowCompanyApi(id)}`,
  };
  return await API(obj);
});
export const getCompanyAnalytics = createAsyncThunk('getCompanyAnalytics', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.AnalyticsApi(id)}`,
  };
  return await API(obj);
});

export const getCompanyPageData = createAsyncThunk('getCompanyPageData', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.id}/`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const companyAddInfo = createAsyncThunk('companyAddInfo', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.id}/`,
    method: 'PATCH',
    body: formData,
    isFormData: true,
  };
  const response = await API(obj);

  // const headers ={
  //     "Content-type": "multipart/form-data",
  //     Authorization: `Bearer ${token}`
  // }
  // const response = await axiosInstance.patch(`${CompanyAPIEndpoints.CompaniesAPI}${payload.id}/`, formData, headers)

  return response;
});

// Company Followers API
export const getCompanyPageFollowers = createAsyncThunk('getCompanyPageFollowers', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.FollowersApi(payload.id)}`,
  };
  return await API(obj);
});

// export const companyBannerUpdate = createAsyncThunk('companyBannerUpdate', async (payload) => {

//     const formData = new FormData();
//     formData.append(`${payload.imageType}`, payload.image);

//     const headers ={
//         "Content-type": "multipart/form-data",
//         Authorization: `Bearer ${token}`
//     }
//     const response = await axiosInstance.put(`${CompanyAPIEndpoints.CompaniesAPI}${payload.id}/`, formData, {headers})

//     return response
// });

export const clear = createAction('clear');

export const createService = createAsyncThunk('createService', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }

    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.pageId}/services/`,
    method: 'POST',
    body: formData,
    isFormData: true,
  };

  return await API(obj);
});

export const editCompanyService = createAsyncThunk('editCompanyService', async (payload) => {
  const formData = new FormData();
  formData.append('name', payload?.name);
  formData.append('desc', payload?.desc);
  let imageURL = payload?.image;
  if (imageURL instanceof File) {
    formData.append('image', payload?.image);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.pageId}/services/${payload.id}/`,
    method: 'PUT',
    body: formData,
    isFormData: true,
  };
  return await API(obj);
});

export const deleteCompanyService = createAsyncThunk('deleteCompanyService', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.pageId}/services/${payload.id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});
