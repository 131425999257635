import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatContext } from '../../../util/CometChatContext';
import { CometChatEvent } from '../../../util/CometChatEvent';
import * as enums from '../../../util/enums.js';
import { startConversationWithUser } from 'store/slices/connectionSlice';
import Translator from '../../../resources/localization/translator';
import axiosInstance from 'store/common/axios';
import { useDispatch } from 'react-redux';
import {
  blockedMessageWrapperStyle,
  blockedMessageContainerStyle,
  unblockButtonStyle,
  blockedTitleStyle,
  bockedSubTitleStyle,
} from './style';

const CometChatBlockedUser = (props) => {
  const context = React.useContext(CometChatContext);
  const chatWith = { ...context.item };
  const token = localStorage.getItem('access');
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  const dispatch = useDispatch();
  const unblockUser = async () => {
    let uid = chatWith.uid;
    
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const userId = await axiosInstance
      .get(`${apiUrl}api/v1/user/get_details/?uuid=${uid}`, { headers })
      .then((res) => {
        if (res) {
          let body = { block_user_id: res.id };
          axiosInstance.delete(`${apiUrl}api/v1/user/connection/unblock`, {
            headers: headers,
            data: body,
          });
          CometChat.unblockUsers([uid])
            .then((response) => {
              if (
                response &&
                response.hasOwnProperty(uid) &&
                response[uid].hasOwnProperty('success') &&
                response[uid]['success'] === true
              ) {
                const newType = CometChat.ACTION_TYPE.TYPE_USER;
                const newItem = Object.assign({}, chatWith, { blockedByMe: false });
                // dispatch(startConversationWithUser(chatWith));
                props.onUnblock(true);
                context.setTypeAndItem(newType, newItem);
              } else {
                CometChatEvent.triggerHandler(enums.ACTIONS['ERROR'], 'SOMETHING_WRONG');
              }
            })
            .catch((error) => CometChatEvent.triggerHandler(enums.ACTIONS['ERROR'], 'SOMETHING_WRONG'));
        }
      });
  };

  return (
    <div css={blockedMessageWrapperStyle()} className="">
      <div css={blockedMessageContainerStyle()}>
        <div css={blockedTitleStyle()}>
          {Translator.translate('YOU_HAVE_BLOCKED', props.lang) + ' ' + chatWith.name}
        </div>
        <div css={bockedSubTitleStyle(context)}>
          {Translator.translate('NOT_POSSIBLE_TO_SEND_MESSAGES', props.lang)}
        </div>
      </div>
      <button type="button" css={unblockButtonStyle(context)} onClick={() => unblockUser()}>
        {Translator.translate('Unblock', props.lang)}
      </button>
    </div>
  );
};

// Specifies the default values for props:
CometChatBlockedUser.defaultProps = {
  lang: Translator.getDefaultLanguage(),
};

CometChatBlockedUser.propTypes = {
  lang: PropTypes.string,
};

export { CometChatBlockedUser };
