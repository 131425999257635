import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'components/Layouts';
import { HorizontalTabs, TabWithoutLink, TabContent } from 'components/common/Tabs/Tabs';
import { Text, RootContainer, BorderLessButton, BorderedContainer } from 'components/StyledComponents';
import Checkbox from 'components/common/Checkbox';
import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button';

import TestimonialBox from './components/TestimonialBox';
import AskTestimonialModal from './components/AskModal';
import GiveTestimonialModal from './components/GiveModal';
import { askTestimonal, giveTestimonal, giveTestimonalForAsked } from 'store/actions/testimonial.Action';

import {
  deleteTestimonial,
  patchTestimonial,
  listOfReceivedTestimonial,
  listOfReceivedTestimonialPending,
  listOfAskedTestimonialRequest,
  listOfGiveTestimonialPendingRequest,
  listOfGiveTestimonial,
} from 'store/actions/testimonial.Action';

const TestimonialsHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const TestimonialsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const TestimonialItemContainer = styled.div`
  display: flex;
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: ${(props) => props?.borderBottom ? '1px solid #d9d9d9':'0px'};
`;

const TestimonialsTabContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmationSubText = styled.div`
  color: #5b5b5b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const Count = styled.span`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e41c19;
  color: #fff;
`;

const TestimonialContainer = styled.div`
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 10px;
  overflow: hidden;
  margin-top: 20px;
  padding: 0 10px;
`;

const TestimonialActions = styled.div`
  display: flex;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background: transparent;
`;

const RemindSvg = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99967 10.8333C10.2358 10.8333 10.4338 10.7533 10.5938 10.5933C10.7533 10.4338 10.833 10.2361 10.833 9.99996V7.49996C10.833 7.26385 10.7533 7.06579 10.5938 6.90579C10.4338 6.74635 10.2358 6.66663 9.99967 6.66663C9.76356 6.66663 9.56579 6.74635 9.40634 6.90579C9.24634 7.06579 9.16634 7.26385 9.16634 7.49996V9.99996C9.16634 10.2361 9.24634 10.4338 9.40634 10.5933C9.56579 10.7533 9.76356 10.8333 9.99967 10.8333ZM9.99967 13.3333C10.2358 13.3333 10.4338 13.2533 10.5938 13.0933C10.7533 12.9338 10.833 12.7361 10.833 12.5C10.833 12.2638 10.7533 12.0658 10.5938 11.9058C10.4338 11.7463 10.2358 11.6666 9.99967 11.6666C9.76356 11.6666 9.56579 11.7463 9.40634 11.9058C9.24634 12.0658 9.16634 12.2638 9.16634 12.5C9.16634 12.7361 9.24634 12.9338 9.40634 13.0933C9.56579 13.2533 9.76356 13.3333 9.99967 13.3333ZM4.16634 15.8333C3.93023 15.8333 3.73217 15.7533 3.57217 15.5933C3.41273 15.4338 3.33301 15.2361 3.33301 15C3.33301 14.7638 3.41273 14.5661 3.57217 14.4066C3.73217 14.2466 3.93023 14.1666 4.16634 14.1666H4.99967V8.33329C4.99967 7.18051 5.3469 6.15607 6.04134 5.25996C6.73579 4.3644 7.63856 3.77774 8.74967 3.49996V2.91663C8.74967 2.5694 8.87134 2.2744 9.11467 2.03163C9.35745 1.78829 9.65245 1.66663 9.99967 1.66663C10.3469 1.66663 10.6419 1.78829 10.8847 2.03163C11.128 2.2744 11.2497 2.5694 11.2497 2.91663V3.49996C12.3608 3.77774 13.2636 4.3644 13.958 5.25996C14.6525 6.15607 14.9997 7.18051 14.9997 8.33329V14.1666H15.833C16.0691 14.1666 16.2669 14.2466 16.4263 14.4066C16.5863 14.5661 16.6663 14.7638 16.6663 15C16.6663 15.2361 16.5863 15.4338 16.4263 15.5933C16.2669 15.7533 16.0691 15.8333 15.833 15.8333H4.16634ZM9.99967 18.3333C9.54134 18.3333 9.14912 18.1702 8.82301 17.8441C8.49634 17.5175 8.33301 17.125 8.33301 16.6666H11.6663C11.6663 17.125 11.5033 17.5175 11.1772 17.8441C10.8505 18.1702 10.458 18.3333 9.99967 18.3333Z"
        fill={color ?? '#3C5A80'}
      />
    </svg>
  );
};

const AddCircleSvg = () => {
  return (
    <svg width="21" height="30" viewBox="0 0 21 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="16.5" r="10" fill="white" stroke="#3D5A80" />
      <path
        d="M9.79545 21.0057V11.6307H11.3864V21.0057H9.79545ZM5.90341 17.1136V15.5227H15.2784V17.1136H5.90341Z"
        fill="#3D5A80"
      />
    </svg>
  );
};

const AddSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill="#3D5A80" />
      <path
        d="M9 13C8.8381 13 8.70248 12.9451 8.59314 12.8354C8.48343 12.7261 8.42857 12.5905 8.42857 12.4286V9.57143H5.57143C5.40952 9.57143 5.27371 9.51657 5.164 9.40686C5.05467 9.29752 5 9.1619 5 9C5 8.8381 5.05467 8.70229 5.164 8.59257C5.27371 8.48324 5.40952 8.42857 5.57143 8.42857H8.42857V5.57143C8.42857 5.40952 8.48343 5.27371 8.59314 5.164C8.70248 5.05467 8.8381 5 9 5C9.1619 5 9.29771 5.05467 9.40743 5.164C9.51676 5.27371 9.57143 5.40952 9.57143 5.57143V8.42857H12.4286C12.5905 8.42857 12.7261 8.48324 12.8354 8.59257C12.9451 8.70229 13 8.8381 13 9C13 9.1619 12.9451 9.29752 12.8354 9.40686C12.7261 9.51657 12.5905 9.57143 12.4286 9.57143H9.57143V12.4286C9.57143 12.5905 9.51676 12.7261 9.40743 12.8354C9.29771 12.9451 9.1619 13 9 13Z"
        fill="white"
      />
    </svg>
  );
};

const CloseSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill="#E41C19" />
      <path
        d="M11.8281 11.8284C11.7136 11.9429 11.5789 12 11.424 11.9997C11.2691 12 11.1344 11.9429 11.0199 11.8284L8.99963 9.80812L6.97932 11.8284C6.86484 11.9429 6.73002 12.0002 6.57486 12.0002C6.42024 12.0002 6.28568 11.9429 6.1712 11.8284C6.05672 11.7139 5.99934 11.5793 5.99907 11.4244C5.99934 11.2695 6.05672 11.1348 6.1712 11.0203L8.19151 9L6.1712 6.97969C6.05672 6.86521 5.99947 6.73039 5.99947 6.57523C5.99947 6.42061 6.05672 6.28606 6.1712 6.17157C6.28568 6.05709 6.42037 5.99971 6.57526 5.99944C6.73015 5.99971 6.86484 6.05709 6.97932 6.17157L8.99963 8.19188L11.0199 6.17157C11.1344 6.05709 11.269 5.99985 11.4236 5.99985C11.5787 5.99985 11.7136 6.05709 11.8281 6.17157C11.9425 6.28606 11.9996 6.42074 11.9994 6.57563C11.9996 6.73052 11.9425 6.86521 11.8281 6.97969L9.80775 9L11.8281 11.0203C11.9425 11.1348 11.9998 11.2693 11.9998 11.424C11.9998 11.5791 11.9425 11.7139 11.8281 11.8284Z"
        fill="white"
      />
    </svg>
  );
};

const PencilSvg = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5042_10653)">
        <path
          d="M1.59926 17.5002C1.43001 17.4467 1.25632 17.4155 1.09598 17.3443C0.401195 17.019 0.0315326 16.4622 0.00926382 15.6959C-0.00855122 15.0856 0.00481006 14.4797 0.00481006 13.8694C0.00481006 10.4436 0.00481006 7.01773 0.00481006 3.59189C0.00481006 2.6519 0.59716 1.91684 1.49682 1.7431C1.63043 1.71637 1.77295 1.71191 1.91102 1.71191C4.28042 1.71191 6.64536 1.71191 9.01476 1.71191C9.42896 1.71191 9.67838 1.91684 9.71401 2.26432C9.74518 2.6029 9.49577 2.89692 9.15729 2.9192C9.09048 2.92365 9.02367 2.92365 8.95687 2.92365C6.60974 2.92365 4.26706 2.92365 1.91993 2.92365C1.57253 2.92365 1.32758 3.07512 1.25632 3.36024C1.22959 3.46715 1.22959 3.57853 1.22959 3.6899C1.22959 6.17575 1.22959 8.6616 1.22959 11.143C1.22959 12.6042 1.22959 14.0654 1.22959 15.5266C1.22959 15.6113 1.22959 15.6915 1.2385 15.7761C1.27413 16.0345 1.48346 16.2439 1.74178 16.2751C1.81749 16.284 1.89766 16.284 1.97337 16.284C5.51856 16.284 9.06376 16.284 12.6089 16.284C12.6847 16.284 12.7648 16.284 12.8405 16.2751C13.1122 16.2439 13.326 16.0211 13.3527 15.7449C13.3616 15.6692 13.3572 15.589 13.3572 15.5133C13.3572 13.5887 13.3572 11.6598 13.3572 9.73524C13.3572 9.61941 13.3661 9.49913 13.4017 9.39221C13.4819 9.14273 13.7358 8.98681 13.9941 9.00017C14.2568 9.01354 14.484 9.19619 14.5419 9.45012C14.5641 9.53922 14.5686 9.63723 14.5686 9.73078C14.5686 11.6776 14.5686 13.6288 14.5686 15.5756C14.5686 16.5825 14.0074 17.2685 13.0187 17.4779C13.0053 17.4824 12.992 17.4957 12.9786 17.5002C9.18846 17.5002 5.39386 17.5002 1.59926 17.5002Z"
          fill="#3D5A80"
        />
        <path
          d="M17 3.0884C16.9644 3.23096 16.9377 3.37797 16.8931 3.52053C16.7729 3.91257 16.5457 4.24223 16.2562 4.53626C13.6374 7.14684 11.0231 9.76634 8.40425 12.3814C8.2929 12.4928 8.14148 12.5908 7.99005 12.6398C6.84098 13.0318 5.68745 13.4105 4.53393 13.798C4.30679 13.8738 4.08856 13.8827 3.88814 13.7312C3.66099 13.5575 3.59864 13.2991 3.70108 12.9828C4.01284 12.0517 4.35133 11.1251 4.62301 10.1806C4.81006 9.54359 5.1441 9.04464 5.61619 8.58133C8.07467 6.15339 10.5153 3.69873 12.956 1.25297C13.4637 0.745113 14.0605 0.473362 14.782 0.500092C15.8732 0.540186 16.8085 1.38217 16.9688 2.46471C16.9733 2.4959 16.9866 2.52708 17 2.55827C17 2.73201 17 2.91021 17 3.0884ZM5.21536 12.2834C5.2688 12.27 5.29552 12.2656 5.32225 12.2566C6.04376 12.0161 6.76972 11.7755 7.48677 11.5305C7.56249 11.5038 7.62929 11.4458 7.68719 11.3924C10.2481 8.82635 12.8046 6.26922 15.361 3.70764C15.4278 3.64082 15.4946 3.57399 15.5481 3.49826C15.8198 3.13741 15.8598 2.74538 15.6639 2.34443C15.312 1.62719 14.4213 1.50691 13.8289 2.09941C11.2457 4.67881 8.66257 7.26267 6.08384 9.84653C6.04376 9.88662 5.99922 9.93563 5.97695 9.99354C5.72754 10.7464 5.47813 11.4993 5.21536 12.2834Z"
          fill="#3D5A80"
        />
      </g>
      <defs>
        <clipPath id="clip0_5042_10653">
          <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TestimonialPage = () => {
  const dispatch = useDispatch();
  const testimonialReducer = useSelector((state) => state.TestimonialReducer);

  const [showOnProfile, setShowOnProfile] = useState({});
  const [showModal, setShowModal] = useState('');
  const [preTestimonialData, setPreTestimonialData] = useState();
  const [showConformationModal, setShowConformationModal] = useState(false);
  const [selWithdrawDetail, setSelWithdrawDetail] = useState('');
  const [selRemindDetail, setSelRemindDetail] = useState('');
  const { receivedTestimonial, receivedTestimonialPending, receivedTestimonialUpdatedFlag } = testimonialReducer;
  const { giveTestimonialPending, giveTestimonial, giveTestimonal } = testimonialReducer;
  const { askTestimonal, askedTestimonial } = testimonialReducer;
  const { addedToProfile, deletedTestimonial, testimonalForAsked } = testimonialReducer;
  const [openTab, setOpenTab] = useState('Received');
  useEffect(() => {
    if (
      giveTestimonal ||
      askTestimonal ||
      
      addedToProfile ||
      deletedTestimonial ||
      testimonalForAsked
    ) {
      dispatch(listOfReceivedTestimonial());
      dispatch(listOfReceivedTestimonialPending());
      dispatch(listOfAskedTestimonialRequest());
      dispatch(listOfGiveTestimonialPendingRequest());
      dispatch(listOfGiveTestimonial());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giveTestimonal, askTestimonal, addedToProfile, deletedTestimonial, testimonalForAsked]);

  const writeTestimonial = (item) => {
    setShowModal('Give');
    setPreTestimonialData(item);
  };

  const onRemindTestimonial = (testimonialData) =>{
    console.log(testimonialData,"--testimonialData")
    setSelRemindDetail(testimonialData)
    setShowConformationModal(true);
    // const payload = {
    //   ...testimonialData,
    //   form_type: 'GIVE',
    // };
    //   payload.id = preTestimonialData.id;
    //   dispatch(giveTestimonalForAsked(payload)).then(() => {
    //     console.log('test')
    //   });
  }

  const editTestimonial = (item) => {
    setShowModal('Give');
    setPreTestimonialData(item);
  };

  /* Received Testimonials Updated */
  useEffect(() => {
    dispatch(listOfGiveTestimonialPendingRequest());
    dispatch(listOfAskedTestimonialRequest());

    dispatch(listOfReceivedTestimonialPending());
    dispatch(listOfReceivedTestimonial());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedTestimonialUpdatedFlag]);

  /* Fill up the received testimonials shown and hidden on profile */
  useEffect(() => {
    let showOnProfileObj = {};
    receivedTestimonial?.forEach((testimonial) => {
      showOnProfileObj[testimonial.id] = testimonial.is_added_to_profile;
    });
    setShowOnProfile(showOnProfileObj);
    // eslint-disable-next-line
  }, [receivedTestimonial]);

  const onWithdrawClicked = (item) => {
    setShowConformationModal(true);
    setSelWithdrawDetail(item);
  };

  const onConfirmationCloseEvent = () => {
    setShowConformationModal(false);
    setSelWithdrawDetail('');
    setSelRemindDetail('');
  };
  return (
    <React.Fragment>
      <RootContainer className="mt-5 py-4">
        <TestimonialsHeading>
          <Text fW={600} fS="15px">
            Testimonials
          </Text>
          <BorderLessButton
            className="d-flex align-items-center gap-2"
            fontSize="15px"
            onClick={() => {
              setShowModal('Give');
              setPreTestimonialData('');
            }}
          >
            <AddCircleSvg className="me-2" />
            <Text fS="15px" color="#3D5A80">
              Give a Testimonial
            </Text>
          </BorderLessButton>
          <BorderLessButton
            className="d-flex align-items-center gap-2"
            fontSize="15px"
            onClick={() => setShowModal('Ask')}
          >
            <AddCircleSvg className="me-2" />
            <Text fS="15px" color="#3D5A80">
              Ask for a Testimonial
            </Text>
          </BorderLessButton>
        </TestimonialsHeading>

        <TestimonialsContainer>
          <HorizontalTabs>
            {!!receivedTestimonialPending.length && (
              <>
                <TabWithoutLink
                  onClick={() => setOpenTab('Received')}
                  activeClass={openTab === 'Received' ? 'active' : null}
                >
                  <Text fW={600} fS="15px" color=" ">
                    Received
                  </Text>
                </TabWithoutLink>
              </>
            )}
            {!!giveTestimonialPending?.length && (
              <TabWithoutLink onClick={() => setOpenTab('Given')} activeClass={openTab === 'Given' ? 'active' : null}>
                <Text fW={600} fS="15px" color=" ">
                  Given
                </Text>
              </TabWithoutLink>
            )}

            {!!askedTestimonial?.length && (
              <TabWithoutLink
                onClick={() => setOpenTab('Your Requests')}
                activeClass={openTab === 'Your Requests' ? 'active' : null}
              >
                <Text fW={600} fS="15px" color=" ">
                  Your Requests
                </Text>
              </TabWithoutLink>
            )}
          </HorizontalTabs>

          <TestimonialsTabContent>
            {!!receivedTestimonialPending.length && (
              <TabContent tab="Received" openTab={openTab}>
                <BorderedContainer className={'p-3'}>
                  <Text fS="15px" className="my-2">
                    Pending Testimonial <Count className="ms-2">{receivedTestimonialPending?.length}</Count>
                  </Text>

                  <TestimonialContainer>
                    {receivedTestimonialPending?.map((item, index) => {
                      let showBorder = receivedTestimonialPending.length > 1;
                      if (index === receivedTestimonialPending.length - 1) showBorder = false;

                      const giverName = `${item.giver.first_name} ${item.giver.last_name}`;
                      const giverProfilePic = item.giver.profile_pic;
                      const giverPosition = item.giver.position;
                      const message = item.message;
                      const createdAt = item.created_at;
                      const agreedUserCount = item.agreed_user_count;
                      const agreedUsers = item.agreed_user;

                      return (
                        <TestimonialItemContainer borderBottom={showBorder}>
                          <TestimonialBox
                            key={item.id}
                            giverName={giverName}
                            giverProfilePic={giverProfilePic}
                            giverPosition={giverPosition}
                            message={message}
                            createdAt={createdAt}
                            agreedUserCount={agreedUserCount}
                            agreedUsers={agreedUsers}
                          />

                          <TestimonialActions className="text-end">
                            <ActionButton onClick={() => dispatch(deleteTestimonial(item.id))}>
                              <CloseSvg />
                              <Text fS="12px" className="my-2" color="#E41C19">
                                Ignore
                              </Text>
                            </ActionButton>

                            <ActionButton
                              className="ms-4"
                              onClick={() => {
                                const payload = {
                                  id: item.id,
                                  status: 'Accept',
                                  is_added_to_profile: true,
                                };
                                dispatch(patchTestimonial(payload));
                              }}
                            >
                              <AddSvg />
                              <Text fS="12px" className="my-2" color="#3D5A80">
                                Add to Profile
                              </Text>
                            </ActionButton>
                          </TestimonialActions>
                        </TestimonialItemContainer>
                      );
                    })}
                  </TestimonialContainer>
                </BorderedContainer>
                <BorderedContainer className={'p-3 mt-3'}>
                  <Text fS="15px" className="my-2">
                    You have {receivedTestimonial.length} recommendations (
                    {receivedTestimonial.filter((e) => e.is_added_to_profile).length} visible,{' '}
                    {receivedTestimonial.filter((e) => !e.is_added_to_profile).length} hidden) Pending Testimonial
                  </Text>

                  <div className="row">
                    <TestimonialContainer>
                      {receivedTestimonial?.map((item) => {
                        const giverName = `${item.giver.first_name} ${item.giver.last_name}`;
                        const giverProfilePic = item.giver.profile_pic;
                        const giverPosition = item.giver.position;
                        const message = item.message;
                        const createdAt = item.created_at;
                        const agreedUserCount = item.agreed_user_count;
                        const agreedUsers = item.agreed_user;

                        return (
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-start">
                              <TestimonialActions>
                                <Checkbox
                                  name="add_to_profile"
                                  checked={showOnProfile[item.id]}
                                  onChange={() => {
                                    setShowOnProfile((obj) => {
                                      const newValue = !showOnProfile[item.id];
                                      const payload = {
                                        id: item.id,
                                        is_added_to_profile: newValue,
                                      };
                                      dispatch(patchTestimonial(payload));

                                      return { ...obj, [item.id]: newValue };
                                    });
                                  }}
                                />
                              </TestimonialActions>
                              <TestimonialBox
                                key={item.id}
                                giverName={giverName}
                                giverProfilePic={giverProfilePic}
                                giverPosition={giverPosition}
                                message={message}
                                createdAt={createdAt}
                                agreedUserCount={agreedUserCount}
                                agreedUsers={agreedUsers}
                              />
                            </div>
                            <TestimonialActions className="text-end">
                              <ActionButton onClick={() => dispatch(deleteTestimonial(item.id))}>
                                <CloseSvg />
                                <Text fS="12px" className="my-2" color="#E41C19">
                                  Delete this Testimonial
                                </Text>
                              </ActionButton>
                            </TestimonialActions>
                          </div>
                        );
                      })}
                    </TestimonialContainer>
                  </div>
                </BorderedContainer>
              </TabContent>
            )}

            {!!giveTestimonialPending?.length && (
              <TabContent tab="Given" openTab={openTab}>
                <BorderedContainer className={'p-3'}>
                  <Text fS="15px" className="my-2">
                    Pending Requests <Count className="ms-2">{giveTestimonialPending?.length}</Count>
                  </Text>

                  <TestimonialContainer>
                    {giveTestimonialPending?.map((item, index) => {
                      let showBorder = giveTestimonialPending.length > 1;
                      if (index === giveTestimonialPending.length - 1) showBorder = false;

                      const giverName = `${item.asker.first_name} ${item.asker.last_name}`;
                      const giverProfilePic = item.asker.profile_pic;
                      const giverPosition = item.asker.position;
                      const message = item.message;
                      const createdAt = item.created_at;
                      const agreedUserCount = item.agreed_user_count;
                      const agreedUsers = item.agreed_user;

                      return (
                        <TestimonialItemContainer borderBottom={showBorder}>
                          <TestimonialBox
                            key={item.id}
                            giverName={giverName}
                            giverProfilePic={giverProfilePic}
                            giverPosition={giverPosition}
                            message={message}
                            createdAt={createdAt}
                            agreedUserCount={agreedUserCount}
                            agreedUsers={agreedUsers}
                          />
                          <TestimonialActions className="text-end">
                            <ActionButton onClick={() => dispatch(deleteTestimonial(item.id))}>
                              <CloseSvg />
                              <Text fS="12px" className="my-2" color="#E41C19">
                                Ignore
                              </Text>
                            </ActionButton>

                            <ActionButton className="ms-4" onClick={() => writeTestimonial(item)}>
                              <PencilSvg />
                              <Text fS="12px" className="my-2" color="#3D5A80">
                                Write a Testimonial
                              </Text>
                            </ActionButton>
                          </TestimonialActions>
                        </TestimonialItemContainer>
                      );
                    })}
                  </TestimonialContainer>
                </BorderedContainer>

                <BorderedContainer className={'p-3 mt-3'}>
                  <Text fS="15px" className="my-2">
                    Manage testimonials you've given
                  </Text>

                  <TestimonialContainer>
                    {giveTestimonial?.map((item, index) => {
                      let showBorder = giveTestimonial.length > 1;
                      if (index === giveTestimonial.length - 1) showBorder = false;

                      const giverName = `${item.asker.first_name} ${item.asker.last_name}`;
                      const giverProfilePic = item.asker.profile_pic;
                      const giverPosition = item.asker.position;
                      const message = item.message;
                      const createdAt = item.created_at;
                      const agreedUserCount = item.agreed_user_count;
                      const agreedUsers = item.agreed_user;

                      return (
                        <TestimonialItemContainer borderBottom={showBorder}>
                          <TestimonialBox
                            key={item.id}
                            giverName={giverName}
                            giverProfilePic={giverProfilePic}
                            giverPosition={giverPosition}
                            message={message}
                            createdAt={createdAt}
                            agreedUserCount={agreedUserCount}
                            agreedUsers={agreedUsers}
                          />
                          <TestimonialActions>
                            <ActionButton onClick={() => editTestimonial(item)}>
                              <PencilSvg />
                              <Text fS="12px" className="my-2" color="#3D5A80">
                                Edit
                              </Text>
                            </ActionButton>
                          </TestimonialActions>
                        </TestimonialItemContainer>
                      );
                    })}
                  </TestimonialContainer>
                </BorderedContainer>
              </TabContent>
            )}

            {!!askedTestimonial?.length && (
              <TabContent tab="Your Requests" openTab={openTab}>
                <BorderedContainer className={'p-3 mt-3'}>
                  <Text fS="15px" className="my-2">
                    Your requests <Count>{askedTestimonial.length}</Count>
                  </Text>

                  <TestimonialContainer>
                    {askedTestimonial?.map((item, index) => {
                      let showBorder = askedTestimonial.length > 1;
                      if (index === askedTestimonial.length - 1) showBorder = false;

                      const giverName = `${item.giver.first_name} ${item.giver.last_name}`;
                      const giverProfilePic = item.giver.profile_pic;
                      const giverPosition = item.giver.position;
                      const message = item.message;
                      const createdAt = item.created_at;
                      const agreedUserCount = item.agreed_user_count;
                      const agreedUsers = item.agreed_user;

                      return (
                        <TestimonialItemContainer borderBottom={showBorder}>
                          <TestimonialBox
                            key={item.id}
                            giverName={giverName}
                            giverProfilePic={giverProfilePic}
                            giverPosition={giverPosition}
                            message={message}
                            createdAt={createdAt}
                            agreedUserCount={agreedUserCount}
                            agreedUsers={agreedUsers}
                          />
                          <TestimonialActions className="ms-2">
                            <ActionButton onClick={() => onWithdrawClicked(item)}>
                              <CloseSvg />
                              <Text fS="12px" className="my-2" color="#E41C19">
                                Withdraw
                              </Text>
                            </ActionButton>

                            <ActionButton onClick={() => onRemindTestimonial(item)} className="ms-3">
                              <RemindSvg />
                              <Text fS="12px" className="my-2" color="#3C5A80">
                                Remind
                              </Text>
                            </ActionButton>
                          </TestimonialActions>
                        </TestimonialItemContainer>
                      );
                    })}
                  </TestimonialContainer>
                </BorderedContainer>
              </TabContent>
            )}
          </TestimonialsTabContent>
        </TestimonialsContainer>

        {showConformationModal && (
          <Modal
            onClose={() => onConfirmationCloseEvent('')}
            heading={
              <Text color="#5B5B5B" fS="24px" fW={600}>
               {selRemindDetail?'Reminder':' Withdraw request for testimonial'}
              </Text>
            }
            action={
              <div className="action-btn">
                <Button variant="btn-secondary" className="h-36 me-2" onClick={() => onConfirmationCloseEvent('')}>
                  Cancel
                </Button>
                <Button
                  className="h-36"
                  onClick={async () => {
                    if(selRemindDetail){
                      let payload = {...selRemindDetail,form_type: 'GIVE'}
                      await dispatch(giveTestimonalForAsked(payload))
                    }else{
                      await dispatch(deleteTestimonial(selWithdrawDetail.id));
                    }
                    setSelWithdrawDetail('');
                    setSelRemindDetail('');
                    setShowConformationModal(false);
                  }}
                >
                 {selRemindDetail?'Send':'Withdraw'} 
                </Button>
              </div>
            }
          >
            <ConfirmationSubText>{selRemindDetail?`Remind ${selRemindDetail?.asker?.first_name} ${selRemindDetail?.asker?.last_name} `:'Withdraw testimonial request'}</ConfirmationSubText>
          </Modal>
        )}

        {showModal === 'Give' && (
          <Modal
            onClose={() => setShowModal('')}
            heading={
              <Text fW={600} fS="24px" color="#5B5B5B">
                Give a testimonial
              </Text>
            }
            className={'testimonial-popup'}
          >
            <GiveTestimonialModal onClose={() => setShowModal('')} preTestimonialData={preTestimonialData} />
          </Modal>
        )}

        {showModal === 'Ask' && (
          <Modal
            onClose={() => setShowModal('')}
            heading={
              <Text fW={600} fS="24px" color="#5B5B5B">
                Ask for testimonial
              </Text>
            }
            className={'testimonial-popup'}
          >
            <AskTestimonialModal onClose={() => setShowModal('')} />
          </Modal>
        )}
      </RootContainer>
    </React.Fragment>
  );
};

export default TestimonialPage;
