import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { createCompanyPage, createService, clear } from 'store/actions/companyPages.Action';

import Intro from './sections/Intro';
import Company from './sections/Company';
import Services from './sections/Services';
import { useNavigate } from 'react-router-dom';

const CreateCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [section, setSection] = useState('intro');
  const [companyDetails, setCompanyDetails] = useState({});
  const [companyServices, setCompanyServices] = useState([{}]);

  const addCompanyWithServices = () => {
    dispatch(createCompanyPage(companyDetails)).then(({ payload }) => {
      const pageId = payload.id;

      for (let index = 0; index < companyServices.length; index++) {
        const element = companyServices[index];
        if (isEmpty(element) || !element.name) continue;
        dispatch(createService({ ...element, pageId }));
      }

      dispatch(clear());
      navigate(`/company/page/${pageId}/home`);
    });
  };

  if (section === 'intro') {
    return <Intro setSection={setSection} />;
  }
  if (section === 'company') {
    return <Company setSection={setSection} companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} />;
  }
  if (section === 'services') {
    return (
      <Services
        setSection={setSection}
        companyServices={companyServices}
        setCompanyServices={setCompanyServices}
        addCompanyWithServices={addCompanyWithServices}
      />
    );
  }

  return null;
};

export default CreateCompany;
