import { Text } from 'components/core';
import moment from 'moment';
import { useContext } from 'react';
import PostContext from 'context/PostContext';

const PostTiming = () => {
  const { postData } = useContext(PostContext);
  const { type, created_at } = postData;
  if (type === 2) {
    return (
      <Text.Label color="#747474" size="12px" margin="2px 0px 0px 0px">
        {moment(new Date(created_at)).fromNow()}
      </Text.Label>
    );
  }

  return (
    <Text.Label color="#747474" size="12px" margin="2px 0px 0px 0px">
      {moment(created_at).startOf().fromNow()}
    </Text.Label>
  );
};

export default PostTiming;
