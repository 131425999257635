import React, { useEffect } from 'react';
import SearchBar from 'components/common/SearchBar';
import FilterSectionTeams from 'components/common/FilterSectionTeams';
import NetworkCard from './Components/NetworkCard';
import { organisers } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamDetailsBySlug, getTeamMemberList, getTeamOrganiserList } from 'store/slices/teamCommunitySlice';
const OrganisersTab = ({ isOrganizer }) => {
  const { teamSlugPage, teamMembers, teamOrganisers, teamId, usersUpdated, diskAccessUpdated } = useSelector((state) => state.teamsCommunity);
  const dispatch = useDispatch();
  const filters = [
    { label: 'Locations', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Current company', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Keyword', type: '', data: null, showInput: false, showKeywords: true },
  ];

  useEffect(() => {
    if (teamId ) {
      dispatch(getTeamOrganiserList({ for_team_community_id: teamId, list_type: "organizer" }));
    }
  }, [usersUpdated, diskAccessUpdated]);
  return (
    <React.Fragment>
      <div className="membersContainer">
        <div className="membersContainer--Header">
          <div className="headerLeft">
            <h6>
              Organisers &bull; <span>{teamOrganisers?.length} person</span>
            </h6>
          </div>
          <div className="headerRight">
            <SearchBar />
          </div>
        </div>
        <div className="membersContainer--Filters">
          <FilterSectionTeams filters={filters} />
        </div>
        <div className="membersContainer--CardList">
          {teamOrganisers && teamOrganisers.map((user, i) => (
            <NetworkCard user={user.user} key={i} isOrganizer={isOrganizer} />
          ))}

        </div>
      </div>
    </React.Fragment>
  )
}

export default OrganisersTab