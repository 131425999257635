import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AvatarGroup from '@atlaskit/avatar-group';

import { BorderedContainer, Text } from 'components/StyledComponents';
import { TabContent, Tab, VerticalTabs } from 'components/common/Tabs/Tabs';

import UserConnections from 'pages/Profile/Individual/ProfileSection/ConnectionCard/UserConnections';

import Introduction from './Introduction';
import PersonalDetails from './PersonalDetails';
import Experience from './Experience';
import Skills from './Skills';
import Education from './Education';
import Milestones from './Milestones';
import Certifications from './Certifications';
import Testimonial from './Testimonial';

export const Profile = (props) => {
  const navigate = useNavigate();
  const { id, slug } = useParams();

  const { publicProfileData, profileViewers } = useSelector((state) => state.userProfile);

  const { isPublic } = props;

  const [openTab, setOpenTab] = useState(id || 'personal-details');
  const profileSlug = `p/${slug}`;
  return (
    <section className="profile-section">
      <div className="row gap-4 gap-md-0">
        <div className="col-12 col-md-8 col-xl-9">
          <div className="card">
            <div className="row">
              <div className="col-12 col-md col-lg-4 col-xl-4 d-none d-lg-block">
                <div className="p-3">
                  <h4 className="text-capitalize fw-bold px-3">Profile</h4>
                  <div>
                    <VerticalTabs>
                      {publicProfileData?.profile_intro && (
                        <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="introduction">
                          Introduction
                        </Tab>
                      )}

                      <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="personal-details">
                        Personal Details
                      </Tab>

                      {publicProfileData?.users_experiences?.length ? (
                        <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="experience">
                          Experience
                        </Tab>
                      ) : null}

                      {publicProfileData?.user_skills?.length ? (
                        <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="skills">
                          Skills
                        </Tab>
                      ) : null}

                      {publicProfileData?.users_educations?.length ? (
                        <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="education">
                          Education
                        </Tab>
                      ) : null}

                      {publicProfileData?.profile_milestones?.length ? (
                        <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="milestones">
                          Milestones
                        </Tab>
                      ) : null}

                      {publicProfileData?.profile_certificates?.length ? (
                        <Tab
                          setOpenTab={setOpenTab}
                          openTab={id}
                          path={slug ? slug : 'profile'}
                          to="certificates-and-awards"
                        >
                          Certification and Awards
                        </Tab>
                      ) : null}
                      {publicProfileData?.profile_certificates?.length ? (
                        <Tab setOpenTab={setOpenTab} openTab={id} path={slug ? profileSlug : 'profile'} to="testimonial">
                          Testimonial
                        </Tab>
                      ) : null}
                    </VerticalTabs>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md col-lg-8 col-xl-8 border-start">
                <div className="p-4 ps-0">
                  <TabContent tab="introduction" openTab={id ? id : openTab}>
                    <Introduction isPublic={isPublic} />
                  </TabContent>

                  <TabContent tab="personal-details" openTab={id ? id : openTab}>
                    <PersonalDetails isPublic={isPublic} />
                  </TabContent>

                  <TabContent tab="experience" openTab={id ? id : openTab}>
                    <Experience isPublic={isPublic} />
                  </TabContent>

                  <TabContent tab="skills" openTab={id ? id : openTab}>
                    <Skills />
                  </TabContent>

                  <TabContent tab="education" openTab={id ? id : openTab}>
                    <Education isPublic={isPublic} />
                  </TabContent>

                  <TabContent tab="milestones" openTab={id ? id : openTab}>
                    <Milestones isPublic={isPublic} />
                  </TabContent>

                  <TabContent tab="certificates-and-awards" openTab={id ? id : openTab}>
                    <Certifications isPublic={isPublic} />
                  </TabContent>

                  <TabContent tab="testimonial" openTab={id ? id : openTab}>
                    <Testimonial isPublic={isPublic} />
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-xl-3">
          <div className="row gap-4">
            <div className="col-12">
              <BorderedContainer className={`px-3`}>
                <UserConnections />
                <Text
                  className="cursor-pointer py-3 px-1"
                  fS="16px"
                  color="#003049"
                  onClick={() => navigate('/testimonial')}
                >
                  Give or Ask for a Testimonial
                </Text>
              </BorderedContainer>
              <BorderedContainer className={`px-3 pt-3`}>
                <div className="mx-3">
                  <Text fS="16px" color="#5B5B5B">
                    Your profile has been viewed by {profileViewers?.length} people in the past days
                  </Text>
                  {profileViewers?.length > 0 && (
                    <div className="d-inline-flex pt-3">
                      <AvatarGroup
                        appearance="stack"
                        maxCount={3}
                        data={profileViewers?.slice(0, 6).map((e) => ({
                          key: e.user.id,
                          name: e.user.first_name,
                          src: e.user.profile_pic,
                        }))}
                      />
                      <div
                        style={{ flex: '10', display: 'flex', alignItems: 'center' }}
                        onClick={() => navigate('/profile-views')}
                      >
                        <Text className="cursor-pointer ps-1" fSize="13px" color="#3D5A80">
                          &nbsp; viewing your profile
                        </Text>
                      </div>
                    </div>
                  )}
                </div>

                <div className="border-bottom pt-3" />

                <div className="p-3">
                  <Text className="cursor-pointer" fS="16px" color="#3D5A80" onClick={() => navigate('/invitations')}>
                    Invitations
                  </Text>
                </div>
              </BorderedContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
