import React from 'react';
import { MyBridgeCompoanyAvatar, MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import { ViewProfile, FollowIcon } from 'components/common/Icons';
import myBridge from 'assets/icons/myBridge.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCompanyPageData } from 'store/actions/companyPages.Action';

const PageCard = ({ name, state_region, main_industry, image, connection, isSearchResult, slug, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const goToPage = () => {
    const payload = {
      id: id,
    };
    dispatch(getCompanyPageData(payload)).then((res) => {
      if (res) {
        navigate(`/c/${slug}/overview`);
      }
    });
  };

  return (
    <div className={`network-list-card`}>
      <div className={!isSearchResult ? "network-list-card--inner" : ''}>
        <div className="network-list-card--profile d-flex justify-content-between">
          <div>
            <MyBridgeAvatar icon={image} name={name} size={65} className="company-avatar" round={true} />
          </div>
          <div>
            <div className={`network-list-card--actions ${isSearchResult ? 'network-list-card-via-search' : ''}`}>
              {isSearchResult && (
                <>
                  <div>
                    <Button className={`me-1 ${connection ? 'btn-secondary' : 'btn-blue'} `}>
                      <FollowIcon height={16} width={16} className="me-1" color="white" />{' '}
                      {connection ? 'Following' : 'Follow'}
                    </Button>
                    <Button className="btn-secondary ms-1" onClick={() => goToPage()}>
                      <ViewProfile height={16} width={16} className="me-1" /> View Profile
                    </Button>
                  </div>
                  {userProfileInfo?.connect_count ? (
                    <div className="d-flex mt-1 justify-content-end">
                      <img src={myBridge} className="icon" alt="myBridge" />
                      <span>{userProfileInfo?.connect_count}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="network-list-card--details my-3">
          <h5>{name}</h5>
          <p>{`${main_industry ? main_industry : ''} ${state_region ? `| ${state_region}` : ''}`}</p>
        </div>
      </div>
    </div>
  );
};

export default PageCard;
