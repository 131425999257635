import { useQuery } from '@tanstack/react-query';
import { getIntegrationCallBack } from 'api/google-integration';
import Loader from 'components/common/Loader';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeExternalPops , showIntegrationModal } from 'store/slices/onboardingSlice';

const GoogleIntegrationCallBack = () => {
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { state, code, scope, authuser, prompt } = parsed;
  const dispatch = useDispatch();

  const queryStringBuilder = queryString.stringify({
    state,
    code,
    scope,
    authuser,
    prompt,
  });

  const { data, error, isLoading } = useQuery(['googleIntegration', queryStringBuilder], getIntegrationCallBack, {
    onSuccess: (data) => {
      dispatch(closeExternalPops(true));
      window.opener.postMessage({ msg: 'showIntegrationModal' }, '*');
      window.close();
    },
    onError: (error) => {
      dispatch(closeExternalPops(true));
      window.opener.postMessage({ msg: 'showIntegrationModal' }, '*');
      window.close();
    },
  });

  console.log(data, error, isLoading);
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>{error?.message}</div>;
  }

  return <div>GoogleIntegrationCallBack</div>;
};

export default GoogleIntegrationCallBack;
