  import {
  SimpleCloseIcon,
  FullScreenIcon,
  Smiley,
  Chat,
  AddUser,
  ShareScreen,
  StartVideo,
  Rectangle,
  Minimize,
  EndCall,
  Security,
  Record,
  Mic,
  Unmute,
  CameraOff,
  ChevronUp,
  Search,
  CallLockIcon,
  BlockAudioIcon,
  CallMenuIcon,
  RaiseHandIcon,
  SlowDownIcon,
  SpeedUpIcon,
  SimpleTickIcon,
  SimpleCrossIcon,
  BeBackReaction,
  MultitaskingIcon,
  DoneSmileyIcon,
  ClapHandSmiley,
  SurprisedSmiley,
  HappySmileyRecIcon,
  SadSmileyRecIcon,
  AudioSettingsIcon,
  ExitFullScreenIcon,
  LayoutModeIcon,
  WindowedModeIcon,
  Copy,
} from 'components/common/Icons';
import { CometChat } from '@cometchat-pro/chat';
import { useEffect, useState, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import MeetingParticipants from './components/Participants';
import { useDrag } from 'components/common/DraggableComponent/useDrag';
import { useSelector, useDispatch } from 'react-redux';
import { CometChatMeetingMessages } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';
import _ from 'lodash';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { updateUserNavStatus, joinedUsersMeeting, clearJoinedMeetingData, joinAMeeting, deleteMeetingID } from 'store/slices/meetingSlice';
import Dropdown from 'components/common/Dropdown';
import { Camera } from 'react-camera-pro';
import Button from 'components/common/Button';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Tooltip from 'react-simple-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import * as enums from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/enums';
import { ID, getUnixTimestamp } from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/common';
import Modal from 'components/common/Modal/Modal';
import Avatar from 'react-avatar';

const Meeting = (props) => {
  // Getting props for initializing meeting
  const { meetingID, meetingState, createdBy, joinedBy, callType, callInfo, isWaiting } = props;
  const CSS = `
.mainVideoContainer{
    height:100vh !important;
}
.custom-main-container, .tile-main-container{
	background: #fff;
}
.bottom-buttons{display:none}
.video-container{
  background:#D9D9D9;
  border-radius: 0px;
}
.grid-box{border-radius: 0px; border:none;}
.paginated-container{padding:10px}
.awssld__next {
  right: 20px;
}
.awssld__prev{
  left:20px;
}
.custom-name, .MuiTypography-h5{color:black}
`;
  const tooltipCss = `
  white-space: nowrap;
  text-align:center;
  `;
  const draggableRef = useRef(null);
  const dispatch = useDispatch();

  const { position, handleMouseDown } = useDrag({
    ref: draggableRef,
  });
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { joinedUsers } = useSelector((state) => state.meeting);

  const [timer, setTimer] = useState(0);
  const countRef = useRef(null);
  const handle = useFullScreenHandle();
  const [adminUser, setAdminUser] = useState(createdBy);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [startedMeeting, setStartedMeeting] = useState(false);
  const [recordMeeting, setRecordMeeting] = useState(false);
  const [screenShare, setScreenShare] = useState(false);
  const [userList, setUserlist] = useState();
  const [showParticipants, setShowParticipants] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [userListVisible, setUserListVisible] = useState(true);
  const [callLeaveScreen, setCallLeaveScreen] = useState(false);
  const [screenMinimised, setScreenMinimised] = useState(false);
  const [screenWindow, setScreenWindow] = useState(false);
  const [waitingRoom, setWaitingRoom] = useState(isWaiting ? isWaiting : false);
  const [waitingRoomView, setWaitingRoomView] = useState(false);
  const [newMessageArrived, setNewMessageArrived] = useState(false);
  const [reactionMessage, setReactionMessage] = useState(false);
  const [reactionSender, setReactionSender] = useState();
  const [reactionIcon, setReactionIcon] = useState();
  const [attendies, setAttendies] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [audioInputDevices, setAudioInputDevices] = useState();
  const [audioOutpuDevices, setAudioOutputDevices] = useState();
  const [videoInputDevices, setVideoInputDevices] = useState();
  const [defaultDeviceList, setDefaultDeviceList] = useState();
  const [showAudioSettings, setShowAudioSettings] = useState(true);
  const [senderWhiteBoardUrl, setSenderWhiteBoardUrl] = useState();
  const [showUserApprove, setShowUserApprove] = useState(false);
  const [showWaitingUser, setShowWaitingUser] = useState();
  const [copyText, setCopyText] = useState('Copy');

  const camera = useRef(null);
  // Meeting Settings
  const sessionID = meetingID;
  const audioOnly = callType === 'audio' ? true : false;
  const defaultLayout = false;
  const callController = CometChat.CallController.getInstance();
  const showRecordingButton = false;
  const startWithVideoMuted = callType === 'video' ? false : true;
  const startWithAudioMuted = true;
  const callMode = CometChat.CALL_MODE.TILE;
  const GUID = _.toString(meetingID);
  const UID = userProfileInfo.user_uuid;
  const groupName = `Meeting Group ${meetingID}`;
  const groupType = waitingRoom ? CometChat.GROUP_TYPE.PASSWORD : CometChat.GROUP_TYPE.PRIVATE;
  const password = waitingRoom ? `${meetingID}` : '';
  const username = `${userProfileInfo.first_name} ${userProfileInfo.last_name}`;
  const legacyUI = true;

  const [userMute, setUserMute] = useState(startWithAudioMuted);
  const [cameraState, setCameraState] = useState(startWithVideoMuted);

  const callSettings = new CometChat.CallSettingsBuilder()
    .enableDefaultLayout(defaultLayout)
    .setSessionID(sessionID)
    .setIsAudioOnlyCall(audioOnly)
    .showRecordingButton(showRecordingButton)
    .setCustomCSS(CSS)
    .startWithVideoMuted(startWithVideoMuted)
    .startWithAudioMuted(startWithAudioMuted)
    .setMode(callMode)
    .build();

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };
  // callSettings.setMetadata(waitingRoom);
  const initiateVideoCall = () => {
    CometChat.startCall(
      callSettings,
      document.getElementById('callScreen'),
      new CometChat.OngoingCallListener({
        onUserListUpdated: (userList) => {
          setUserlist(userList);
          updateUserStatus('busy');
          setStartedMeeting(true);
          if (callType === 'meeting' && !callInfo) {
            !userList ? deleteCreatedGroup() : initiateUserGroup(userList, 'list');
          }
        },
        onCallEnded: (call) => {
          setStartedMeeting(false);
          if (callType === 'meeting' && !callInfo) {
            deleteCreatedGroup();
            dispatch(deleteMeetingID({meeting_id:sessionID}));
          }
          updateUserStatus('online');
        },
        onError: (error) => {
          // console.log("Error :", error);
        },
        onUserLeft: (user) => {
          // console.log('User Left', user);
          if (callType === 'meeting' && !callInfo) {
            deleteUserFromGroup(user.uid);
          }
        },
        onMediaDeviceListUpdated: (deviceList) => {
          setDefaultDeviceList(deviceList);
          // console.log(deviceList);
        },
        onUserMuted: (event) => {
          // This event will work in JS SDK v3.0.2-beta1 & later.
          console.log('Listener => onUserMuted:', {
            userMuted: event.muted,
            userMutedBy: event.mutedBy,
          });
          setUserMute(true);
        },
        onScreenShareStarted: () => {
          // This event will work in JS SDK v3.0.3 & later.
          // console.log("Screen sharing started.");
          setScreenShare(true);
        },
        onScreenShareStopped: () => {
          // This event will work in JS SDK v3.0.3 & later.
          setScreenShare(false);
          // console.log("Screen sharing stopped.");
        },
      }),
    );
    dispatch(clearJoinedMeetingData());
  };

  const joinCall = () => {
    CometChat.startCall(
      callSettings,
      document.getElementById('callScreen'),
      new CometChat.OngoingCallListener({
        onUserListUpdated: (userList) => {
          setUserlist(userList);
          updateUserStatus('busy');
          setStartedMeeting(true);
        },
        onCallEnded: (call) => {
          setStartedMeeting(false);
          updateUserStatus('online');
        },
      }),
    );
  };

  const initiateUserGroup = (userList, type) => {
    const group = new CometChat.Group(GUID, groupName, groupType, password);

    CometChat.createGroup(group).then(
      (group) => {
        console.log('Group created successfully');
      },
      (error) => {
        console.log('Group not created', error);
        if (type === 'list') {
          userList.map((user, i) => {
            let members = [new CometChat.GroupMember(user.uid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)];
            CometChat.addMembersToGroup(GUID, members, []).then(
              (response) => { },
              (error) => {
                console.log('Something went wrong', error);
              },
            );
          });
        } else {
          CometChat.joinGroup(GUID, groupType, password).then(
            (group) => {
              console.log('Group joined successfully:', group);
              handleUserAddMessage();
              setWaitingRoomView(true);
            },
            (error) => {
              console.log('Group joining failed with exception:', error);
            },
          );
        }
      },
    );
  };

  CometChat.addGroupListener(
    GUID,
    new CometChat.GroupListener({
      onGroupMemberJoined: (message, joinedUser, joinedGroup) => {
        console.log('onGroupMemberJoined', { message, joinedUser, joinedGroup });
      },
      onGroupMemberLeft: (message, leftUser, leftGroup) => {
        console.log('onGroupMemberLeft', { message, leftUser, leftGroup });
      },
      onGroupMemberKicked: (message, kickedUser, kickedBy, kickedFrom) => {
        console.log('onGroupMemberKicked', { message, kickedUser, kickedBy, kickedFrom });
      },
      onGroupMemberBanned: (message, bannedUser, bannedBy, bannedFrom) => {
        console.log('onGroupMemberBanned', { message, bannedUser, bannedBy, bannedFrom });
      },
      onGroupMemberUnbanned: (message, unbannedUser, unbannedBy, unbannedFrom) => {
        console.log('onGroupMemberUnbanned', { message, unbannedUser, unbannedBy, unbannedFrom });
      },
      onGroupMemberScopeChanged: (message, changedUser, newScope, oldScope, changedGroup) => {
        console.log('onGroupMemberScopeChanged', { message, changedUser, newScope, oldScope, changedGroup });
      },
      onMemberAddedToGroup: (message, userAdded, addedby, addedTo) => {
        console.log('onMemberAddedToGroup', { message, userAdded, addedby, addedTo });
      },
    }),
  );

  const updateUserStatus = (status) => {
    const user = new CometChat.User(UID);
    user.setMetadata({ currentStatus: status });
    CometChat.updateCurrentUserDetails(user).then(
      (user) => {
        if (user) {
          dispatch(updateUserNavStatus(status));
        }
      },
      (error) => {
        console.log('error', error);
      },
    );
  };

  const deleteCreatedGroup = () => {
    if (!userList) {
      CometChat.deleteGroup(GUID).then(
        (response) => {
          console.log('Groups deleted successfully:', response);
        },
        (error) => {
          console.log('Group delete failed with exception:', error);
        },
      );
    }
  };

  const deleteUserFromGroup = (UID) => {
    CometChat.kickGroupMember(GUID, UID).then(
      (response) => {
        console.log('Group member kicked successfully', response);
      },
      (error) => {
        console.log('Group member kicking failed with error', error);
      },
    );
  };

  const handleMuteSelf = () => {
    if (userMute) {
      callController.muteAudio(false);
      setUserMute(false);
    } else {
      callController.muteAudio(true);
      setUserMute(true);
    }
  };

  const handleEndCall = () => {
    console.log('end call');
    CometChat.endCall(sessionID).then(
      (call) => {
        setCallLeaveScreen(true);
        meetingState(false);
        // dispatch(joinAMeeting(true))
      },
      (error) => {
        console.log('error', error);
      },
    );
  };

  const handleCamera = () => {
    if (cameraState) {
      callController.pauseVideo(false);
      setCameraState(false);
    } else {
      callController.pauseVideo(true);
      setCameraState(true);
    }
  };

  const handleRecordMeeting = () => {
    if (recordMeeting) {
      callController.stopRecording();
      setRecordMeeting(false);
    } else {
      callController.startRecording();
      setRecordMeeting(true);
    }
  };

  const handleScreenShare = () => {
    if (screenShare) {
      callController.stopScreenShare();
      setScreenShare(false);
    } else {
      callController.startScreenShare();
      setScreenShare(true);
    }
  };

  const handleWhiteBoardShare = () => {
    CometChat.callExtension('whiteboard', 'POST', 'v1/create', {
      receiver: GUID,
      receiverType: 'group',
    })
      .then((response) => {
        setSenderWhiteBoardUrl(response.board_url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onMinimizeWindow = () => {
    if (screenMinimised) {
      setScreenMinimised(false);
    } else {
      setScreenMinimised(true);
    }
    if (screenWindow) {
      setScreenWindow(false);
    }
  };
  const onMaximiseWindow = () => {
    if (screenWindow) {
      setScreenWindow(false);
    } else {
      setScreenWindow(true);
    }
  };

  const getAudioInputDevices = () => {
    const audioInputDevices = callController.getAudioInputDevices();
    const inputDevices = audioInputDevices.map((device) => {
      return { value: device.id, label: device.name };
    });
    setAudioInputDevices(inputDevices);
  };

  const getAudioOutputDevices = () => {
    const audioOutputDevices = callController.getAudioOutputDevices();
    const outputDevices = audioOutputDevices.map((device) => {
      return { value: device.id, label: device.name };
    });
    setAudioOutputDevices(outputDevices);
  };

  const getVideoDevices = () => {
    const videoInputDevices = callController.getVideoInputDevices();
    const videoDevices = videoInputDevices.map((device) => {
      return { value: device.id, label: device.name };
    });
    setVideoInputDevices(videoDevices);
  };

  const changeMicrophoneDevice = (e) => {
    console.log(e.target.value);
    const deviceId = e.target.value;
    callController.setAudioInputDevice(deviceId);
  };

  const changeSpeakerDevice = (e) => {
    const deviceId = e.target.value;
    callController.setAudioOutputDevice(deviceId);
  };

  const changeCameraDevice = (e) => {
    const deviceId = e.target.value;
    callController.setVideoInputDevice(deviceId);
  };
  // define reaction for meeting
  const RenderReaction = (props) => {
    switch (props.icon) {
      case 'raiseHands':
        return (
          <>
            {props.name} <RaiseHandIcon />
          </>
        );
      case 'slowDown':
        return (
          <>
            {props.name} <SlowDownIcon />
          </>
        );
      case 'speedUp':
        return (
          <>
            {props.name} <SpeedUpIcon />
          </>
        );
      case 'saidYes':
        return (
          <>
            {props.name} <SimpleTickIcon />
          </>
        );
      case 'saidNo':
        return (
          <>
            {props.name} <SimpleCrossIcon />
          </>
        );
      case 'beRightBack':
        return (
          <>
            {props.name} <BeBackReaction />
          </>
        );
      case 'multiTasking':
        return (
          <>
            {props.name} <MultitaskingIcon />
          </>
        );
      case 'doneSmiley':
        return (
          <>
            {props.name} <DoneSmileyIcon />
          </>
        );
      case 'clappingSmiley':
        return (
          <>
            {props.name} <ClapHandSmiley />
          </>
        );
      case 'surprisedSmiley':
        return (
          <>
            {props.name} <SurprisedSmiley />
          </>
        );
      case 'happySmiley':
        return (
          <>
            {props.name} <HappySmileyRecIcon />
          </>
        );
      case 'sadSmiley':
        return (
          <>
            {props.name} <SadSmileyRecIcon />
          </>
        );
      default:
        return '';
    }
  };
  // Sending reaction message to cometchar group
  const handleReactionSend = (reactionType) => {
    const receiverID = GUID;
    const customData = { icon_id: reactionType };
    const customType = 'reaction';
    const receiverType = CometChat.RECEIVER_TYPE.GROUP;
    const customMessage = new CometChat.CustomMessage(receiverID, receiverType, customType, customData);

    CometChat.sendCustomMessage(customMessage).then(
      (message) => {
        console.log('custom message sent successfully', message);
        setReactionIcon(reactionType);
        setReactionSender(message.sender.name);
        setReactionMessage(true);
      },
      (error) => {
        console.log('custom message sending failed with error', error);
      },
    );
  };

  const recieveReactionSent = () => {
    const listenerID = GUID;
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          console.log('Text message received successfully', textMessage);
          setNewMessageArrived(true);
        },
        onMediaMessageReceived: (mediaMessage) => {
          console.log('Media message received successfully', mediaMessage);
          setNewMessageArrived(true);
        },
        onCustomMessageReceived: (customMessage) => {
          console.log('Custom message received successfully', customMessage);
          if (customMessage.type === 'reaction') {
            setReactionIcon(customMessage.customData.icon_id);
            setReactionSender(customMessage.sender.name);
            setReactionMessage(true);
          }
          if (customMessage.type === 'extension_whiteboard') {
            setSenderWhiteBoardUrl(customMessage.data.customData.whiteboard.board_url);
          }
          // Custom message listeners
          if (customMessage.type === 'user_join_request') {
            setAttendies((attendies) => [...attendies, customMessage.sender]);
            setShowUserApprove(true);
            setShowWaitingUser(customMessage.sender);
          }
          if (customMessage.type === 'user_accept_request') {
            if (customMessage.customData.acceptedUser.uid === joinedBy.uid) {
              if (!startedMeeting) {
                joinCall();
                setWaitingRoomView(false);
                setShowWaitingUser();
              }
            }
          }
          if (customMessage.type === 'user_decline_request') {
            if (customMessage.customData.acceptedUser.uid === joinedBy.uid) {
              handleMeetingDeclinedClose();
            }
          }
        },
      }),
    );
  };

  const handleUserAddMessage = (joinedBy) => {
    const receiverID = GUID;
    // To Handle user send join request to call admin
    const customData = {
      sessionID: sessionID,
      sessionId: sessionID,
      callType: CometChat.CALL_TYPE.VIDEO,
    };

    const customType = 'user_join_request';
    const receiverType = CometChat.RECEIVER_TYPE.GROUP;
    const conversationId = `group_${sessionID}`;
    const customMessage = new CometChat.CustomMessage(receiverID, receiverType, customType, customData);
    customMessage.setSender(joinedBy);
    customMessage.setReceiver(receiverType);
    customMessage.setConversationId(conversationId);
    customMessage._composedAt = getUnixTimestamp();
    customMessage._id = ID();
    CometChat.sendCustomMessage(customMessage).then(
      (message) => {
        console.log('custom message sent successfully', message);
      },
      (error) => {
        console.log('custom message sending failed with error', error);
      },
    );
  };

  const handleUserApproveMessage = (joinedBy, type) => {
    const receiverID = GUID;
    // To handle user approve or declined from admin to waiting room user
    const customData = {
      sessionID: sessionID,
      sessionId: sessionID,
      callType: CometChat.CALL_TYPE.VIDEO,
      acceptedUser: joinedBy,
    };
    const customType = type === 'approve' ? 'user_accept_request' : 'user_decline_request';
    const receiverType = CometChat.RECEIVER_TYPE.GROUP;
    const conversationId = `group_${sessionID}`;
    const customMessage = new CometChat.CustomMessage(receiverID, receiverType, customType, customData);
    customMessage.setSender(joinedBy);
    customMessage.setReceiver(receiverType);
    customMessage.setConversationId(conversationId);
    customMessage._composedAt = getUnixTimestamp();
    customMessage._id = ID();
    CometChat.sendCustomMessage(customMessage).then(
      (message) => {
        console.log('custom message sent successfully', message);
        setAttendies(_.dropWhile(attendies, joinedBy));
      },
      (error) => {
        console.log('custom message sending failed with error', error);
      },
    );
  };

  const handleMeetingClose = () => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to exit the meeting?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleEndCall();
            handleLeaveGroup();
            dispatch(joinAMeeting(false))
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleMeetingDeclinedClose = () => {
    confirmAlert({
      title: '',
      message: 'Admin did not allow you in the meeting.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            handleEndCall();
            handleLeaveGroup();
          },
        },
        {
          label: 'Close',
          onClick: () => {
            handleEndCall();
            handleLeaveGroup();
          },
        },
      ],
    });
  };

  const handleAdmitUser = (user) => {
    handleUserApproveMessage(user, 'approve');
  };

  const handleDeclineUser = (user) => {
    handleUserApproveMessage(user, 'declined');
  };

  const handleLeaveGroup = () => {
    CometChat.leaveGroup(GUID).then(
      (hasLeft) => {
        console.log('Group left successfully:', hasLeft);
      },
      (error) => {
        console.log('Group leaving failed with exception:', error);
      },
    );
  };

  const handleMemberJoin = (joinedBy) => {
    initiateUserGroup(joinedBy, 'single');
  };

  useEffect(() => {
    if (!startedMeeting) {
      if (joinedBy && isWaiting) {
        handleMemberJoin(joinedBy);
      } else {
        initiateVideoCall();

        if (UID === createdBy?.uid) {
          setIsAdminUser(true);
        }
        countRef.current = setInterval(() => {
          setTimer((timer) => timer + 1);
        }, 1000);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showSettings) {
      getAudioInputDevices();
      getAudioOutputDevices();
      getVideoDevices();
    }
    // eslint-disable-next-line
  }, [showSettings]);

  useEffect(() => {
    if (attendies.length <= 0) {
      setShowUserApprove(false);
    }
    // eslint-disable-next-line
  }, [attendies]);

  useEffect(() => {
    recieveReactionSent();
    const timer = setTimeout(() => {
      setReactionMessage(false);
      setReactionIcon();
      setReactionSender();
    }, 10000);
    return () => clearTimeout(timer);
  }, [reactionMessage]);

  return (
    <FullScreen handle={handle}>
      <div
        className={classNames(
          'meeting-wrapper',
          [screenMinimised ? 'minimised' : ''],
          [handle.active ? 'maximised' : ''],
          [screenWindow ? 'windowed' : ''],
        )}
      >
        {/* header starts here */}
        <div className="meeting-header">
          {!waitingRoomView ? (
            <div>
              {!screenWindow && !screenMinimised ? (
                callType === 'meeting' ? <>
                <span className="text-primary">Meeting in Progress</span>
                <span className="text-secondary mx-2">{sessionID ? sessionID?.toString().slice(0, 10) : ''}</span>
                <Tooltip
                  fixed={false}
                  content="Copy Meeting ID"
                  padding={10}
                  radius={5}
                  placement="bottom"
                  customCss={tooltipCss}
                >
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(`${sessionID}`).then((res) => {
                        setCopyText('Copied');
                      });
                    }}
                    onMouseLeave={() => setCopyText('Copy')}
                  >
                    {copyText}
                  </span>
                </Tooltip>
              </> : <span className="text-primary">Call in Progress</span>
                
              ) : (
                <span className="text-secondary mx-2">{sessionID}</span>
              )}
            </div>
          ) : (
            <div>
              <span className="text-primary">Waiting Room</span>
            </div>
          )}

          <div className="header-right-controls">
            {screenShare ?
              <Button variant='btn-danger' onClick={() =>handleScreenShare()}>Stop Sharing</Button> :
              <>
            {screenMinimised ? (
              <Rectangle onClick={() => onMinimizeWindow()} className="me-2 position-relative top-14px" />
            ) : (
              <Minimize onClick={() => onMinimizeWindow()} className="me-2 position-relative top-14px" />
            )}

            <WindowedModeIcon
              width={18}
              height={18}
              color="#3D5A80"
              onClick={() => onMaximiseWindow()}
              className="me-2 position-relative top-7px"
            />
              <SimpleCloseIcon className="me-2" onClick={handleMeetingClose} />
            </>
              }
            
          </div>
        </div>
        {/* header ends here */}
        {/* middle portion - user display area, if single user starts here */}
        {callLeaveScreen && (
          <div className="user-display-area d-flex align-items-center justify-content-center h-100 px-5">
            <div>
              <div className="info-display text-center mb-4">
                {userList.length > 1 ? 'You have exited the meeting' : 'You have ended the meeting for everyone'}
              </div>
            </div>
          </div>
        )}
        {/* middle portion - user display area, if single user ends here */}

        {waitingRoomView && (
          <div className="user-display-area">
            <div>
              <div className="info-display text-center mb-4">
                <p className="mb-5">We'll let people know you're waiting</p>
                <MyBridgeAvatar size={200} name={username} className="fs-1" />
              </div>
            </div>
            <div className="user-display-area--bottom">
              <div className="camera-settings">
                <div className={classNames('control-button', [cameraState ? 'active' : ''])} onClick={handleCamera}>
                  {cameraState ? <CameraOff color="#fff" /> : <StartVideo />}
                </div>
                Your camera is turned off
              </div>
              <div className="buttons-container">
                <Button variant="btn-secondary" onClick={() => handleMeetingClose()}>
                  Cancel
                </Button>
                <Button disabled>Join now</Button>
              </div>
            </div>
          </div>
        )}

        {/* Cometchat Meeting Main Wrapper */}
        {!callLeaveScreen && (
          <div id="callScreen" className={classNames('meeting-container', [waitingRoomView ? 'hidden' : ''])}>
            {reactionMessage && (
              <div className="reactionSlider">
                <RenderReaction icon={reactionIcon} name={reactionSender} />
              </div>
            )}
            {senderWhiteBoardUrl && (
              <>
                <iframe
                  title="whiteBoard"
                  name="whiteBoard"
                  className="sharingWHiteBoard"
                  width="100%"
                  height="100%"
                  src={senderWhiteBoardUrl}
                />
                <button className="closeShare" onClick={() => setSenderWhiteBoardUrl()}>
                  <SimpleCloseIcon color="#5B5B5B" height={10} width={10} />
                </button>
              </>
            )}
          </div>
        )}
        {/* Cometchat Meeting Main Wrapper */}

        {/* bottom portion - buttons area starts here */}
        {startedMeeting && !screenWindow && !waitingRoomView && (
          <div className="video-button-container">
            <div className="video-controls dropup-center dropup">
              <div className={classNames('control-button', [userMute ? 'active' : ''])} onClick={handleMuteSelf}>
                {userMute ? <Unmute color="#fff" /> : <Mic />}
              </div>
              <span
                className="dropupMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <ChevronUp />
              </span>
              <ul className="dropdown-menu settings">
                <li>
                  <Link className="dropdown-item" onClick={() => setShowSettings(true)}>
                    Test mic and speaker settings
                  </Link>
                </li>
                {/* <li><Link className="dropdown-item" href="#">Leave computer audio</Link></li> */}
              </ul>
              <span className="text-center mt-2">Mute</span>
            </div>
            <div className="video-controls dropup-center dropup">
              <div className={classNames('control-button', [cameraState ? 'active' : ''])} onClick={handleCamera}>
                {cameraState ? <CameraOff color="#fff" /> : <StartVideo />}
              </div>
              <span
                className="dropupMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <ChevronUp />
              </span>
              <ul className="dropdown-menu settings">
                {/* <li><Link className="dropdown-item" href="#">Disable incoming video</Link></li> */}
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => {
                      setShowSettings(true);
                      setShowAudioSettings(false);
                    }}
                  >
                    Video setting
                  </Link>
                </li>
              </ul>
              <span className="text-center mt-2">Start video</span>
            </div>
            {/* <div className="video-controls">
            <div className={classNames("control-button", [recordMeeting ? 'active' : ''])} onClick={handleRecordMeeting}>
              <Record color={recordMeeting ? '#fff' : ''} />
            </div>
            <span className="text-center mt-2">Record</span>
          </div> */}
            <div className="video-controls dropup-center dropup">
              <div className={classNames('control-button', [screenShare ? 'active' : ''])} onClick={() => handleScreenShare()}>
                <ShareScreen color={screenShare ? '#fff' : ''} />
              </div>
              <span
                className="dropupMenuButton"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <ChevronUp />
              </span>
              <ul className="dropdown-menu settings">
                <li>
                  <Link type="button" className="dropdown-item" onClick={() => handleScreenShare()}>
                    Share Screen
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={handleWhiteBoardShare}>
                    Share whiteboard
                  </Link>
                </li>
              </ul>
              <span className="text-center mt-2">Share</span>
            </div>
            <div className="video-controls">
              <div className="control-button" onClick={() => { setShowParticipants(prev => !prev); setShowChat(false);}}>
                <AddUser />
              </div>
              <span className="text-center mt-2">Participants</span>
            </div>
            {callType === 'meeting' && (
              <div className="video-controls">
                <div
                  className="control-button"
                  onClick={() => {
                    setShowChat(prev => !prev);
                    setNewMessageArrived(false);
                    setShowParticipants(false);
                  }}
                >
                  {newMessageArrived && <span className="notificationIcon"></span>}
                  <Chat />
                </div>
                <span className="text-center mt-2">Chat</span>
              </div>
            )}

            <div className="video-controls dropup-center dropup">
              <div className="control-button" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                <Smiley />
              </div>
              <ul className="dropdown-menu reaction-dropdown">
                {/* <li className="row">
                <div className="col-10">Allow Participant Reaction</div>
                <div className='col-2'>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  </div></div></li> */}
                <li className="row">
                  <div className="col-12">
                    <button className="w-100" onClick={() => handleReactionSend('raiseHands')}>
                      <RaiseHandIcon /> Raise hand
                    </button>
                  </div>
                </li>
                <li className="row">
                  <div className="col-6">
                    <button onClick={() => handleReactionSend('slowDown')}>
                      <SlowDownIcon height={24} /> Slow down
                    </button>
                  </div>
                  <div className="col-6">
                    <button onClick={() => handleReactionSend('speedUp')}>
                      <SpeedUpIcon height={24} /> Speed up
                    </button>
                  </div>
                </li>
                <li className="row">
                  <div className="col-6">
                    <button onClick={() => handleReactionSend('saidYes')}>
                      <SimpleTickIcon /> Yes
                    </button>
                  </div>
                  <div className="col-6">
                    <button onClick={() => handleReactionSend('saidNo')}>
                      <SimpleCrossIcon /> No
                    </button>
                  </div>
                </li>
                <li className="row">
                  <div className="col-6">
                    <button onClick={() => handleReactionSend('beRightBack')}>
                      <BeBackReaction /> Be right back
                    </button>
                  </div>
                  <div className="col-6">
                    <button onClick={() => handleReactionSend('multiTasking')}>
                      <MultitaskingIcon /> Multitasking
                    </button>
                  </div>
                </li>
                <li className="d-flex justify-content-between px-3">
                  <div className="col-2">
                    <button onClick={() => handleReactionSend('doneSmiley')}>
                      <DoneSmileyIcon />
                    </button>
                  </div>
                  <div className="col-2">
                    <button onClick={() => handleReactionSend('clappingSmiley')}>
                      <ClapHandSmiley />
                    </button>
                  </div>
                  <div className="col-2">
                    <button onClick={() => handleReactionSend('surprisedSmiley')}>
                      <SurprisedSmiley />
                    </button>
                  </div>
                  <div className="col-2">
                    <button onClick={() => handleReactionSend('happySmiley')}>
                      <HappySmileyRecIcon />
                    </button>
                  </div>
                  <div className="col-2">
                    <button onClick={() => handleReactionSend('sadSmiley')}>
                      <SadSmileyRecIcon />
                    </button>
                  </div>
                </li>
              </ul>
              <span className="text-center mt-2">Reactions</span>
            </div>
            <div className="video-controls dropup-center dropup">
              <div className="control-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <Security />
              </div>
              <ul className="dropdown-menu">
                <li className="form-element border border-0">
                  <h6 className="dropdown-header ps-0">Settings</h6>
                </li>
                <li className="form-element">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="meetingPasscode" checked disabled />
                    <label className="form-check-label" for="meetingPasscode">
                      Passcode <span className="passcodeText">{meetingID}</span>{' '}
                      <Copy
                        color="#5B5B5B"
                        onClick={() => {
                          navigator.clipboard.writeText(`${sessionID}`);
                        }}
                      />
                    </label>
                  </div>
                  <span className="form-text">
                    Only users who have the invite link or passcode can join the meeting
                  </span>
                </li>
                <li className="form-element">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="waitingRoom"
                      checked={waitingRoom}
                      onChange={(e) => setWaitingRoom(e.target.checked)}
                    />
                    <label className="form-check-label" for="waitingRoom">
                      Waiting Room
                    </label>
                  </div>
                  <span className="form-text">Only users admitted by the host can join the meeting</span>
                </li>
              </ul>
              <span className="text-center mt-2">Security</span>
            </div>
            <div className="video-controls">
              <div className="control-button end-call" onClick={handleEndCall}>
                <EndCall />
              </div>
              <span className="text-center mt-2">Leave</span>
            </div>
          </div>
        )}
        {/* bottom portion - buttons area ends here */}
        {/* Other Video Options and Controls */}
        {startedMeeting && !screenWindow && !screenMinimised && !waitingRoomView && (
          <div className="buttons-right-section">
            <div className="control-button">
              <div className="dropup-center dropup" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {/* <Button className="btn-secondary btn-sm">Layout</Button> */}
                <LayoutModeIcon width={24} height={24} />
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={() => callController.setMode(CometChat.CALL_MODE.SPOTLIGHT)}
                    >
                      Spotlight
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" onClick={() => callController.setMode(CometChat.CALL_MODE.TILE)}>
                      Tile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={() => callController.setMode(CometChat.CALL_MODE.GRID)}
                      href="#"
                    >
                      Grid
                    </Link>
                  </li>
                  {/* <li><Link className="dropdown-item" onClick={() => callController.setMode(CometChat.CALL_MODE.DEFAULT)} href="#">FullScreen</Link></li> */}
                </ul>
              </div>
            </div>
            <div className="control-button">
              {handle.active ? (
                <ExitFullScreenIcon width={24} height={24} onClick={handle.exit} />
              ) : (
                <FullScreenIcon width={24} height={24} onClick={handle.enter} />
              )}
            </div>
          </div>
        )}

        {/* Participants Container */}
        {showParticipants && startedMeeting && (
          <div
            className="draggable"
            ref={draggableRef}
            style={{
              top: position.y,
              left: position.x,
            }}
          >
            <div className="draggable-close">
              <button onClick={() => setShowParticipants(false)}>
                <SimpleCloseIcon color="#5B5B5B" height={20} width={20} />
              </button>
            </div>
            <div className="chatTabsHeader draggable-panel d-flex justify-content-center" onMouseDown={handleMouseDown}>
              Participant
            </div>
            <div
              className="draggable-panel d-flex justify-content-around align-items-center w-100"
              onMouseDown={handleMouseDown}
            >
              <div className="meetingCallTimer">{formatTime()}</div>
            </div>
            <div className="draggable-content pt-0">
              <>
                {/* <div className='callGlobalController'>
              <button className="iconButton"><Search /></button>
              <button className="iconButton"><AddUser /></button>
              <button className="iconButton"><CallLockIcon /></button>
              <button className="iconButton"><BlockAudioIcon /></button>
              <button className="iconButton"><Mic /></button>
              <button className="iconButton"><CallMenuIcon /></button>
            </div> */}
                <MeetingParticipants participants={userList} currentUser={userProfileInfo} />
              </>
            </div>
          </div>
        )}

        {/* Chat Container */}
        {showChat && startedMeeting && (
          <div
            className="draggable"
            ref={draggableRef}
            style={{
              top: position.y,
              left: position.x,
            }}
          >
            <div className="draggable-close">
              <button onClick={() => setShowChat(false)}>
                <SimpleCloseIcon color="#5B5B5B" height={20} width={20} />
              </button>
            </div>

            <div className="chatTabsHeader draggable-panel d-flex justify-content-center" onMouseDown={handleMouseDown}>
              Chats
            </div>
            <div className="draggable-content meetingChatGroup py-0">
              <CometChatMeetingMessages chatWithGroup={GUID} />
            </div>
          </div>
        )}

        {/* Settings Container */}
        {showSettings && (
          <div
            className="draggable wide"
            ref={draggableRef}
            style={{
              top: position.y,
              left: position.x,
            }}
          >
            <div className="draggable-close">
              <button onClick={() => setShowSettings(false)}>
                <SimpleCloseIcon color="#5B5B5B" height={20} width={20} />
              </button>
            </div>
            <div className="chatTabsHeader draggable-panel d-flex justify-content-center" onMouseDown={handleMouseDown}>
              Settings
            </div>

            <div className="draggable-content p-0">
              <div className="settingsContainer">
                <div className="tabSection">
                  <ul className="nav flex-column">
                    <li className={classNames('nav-item', [showAudioSettings ? 'active' : ''])}>
                      <Link className="nav-link" onClick={() => setShowAudioSettings(true)}>
                        <AudioSettingsIcon width={23} height={20} color={showAudioSettings ? '#ffffff' : '#5B5B5B'} />{' '}
                        Voice
                      </Link>
                    </li>
                    <li className={classNames('nav-item', [!showAudioSettings ? 'active' : ''])}>
                      <Link className="nav-link" onClick={() => setShowAudioSettings(false)}>
                        <StartVideo width={23} height={20} color={!showAudioSettings ? '#ffffff' : '#5B5B5B'} /> Video
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tabContent">
                  {showAudioSettings ? (
                    <div className="voiceContainer">
                      <div className="mb-3">
                        <label for="microphoneInput" className="form-label">
                          Microphone
                        </label>
                        <Dropdown
                          id={'microphoneInput'}
                          name="microphoneInput"
                          options={audioInputDevices}
                          title={'microphoneInput'}
                          defaultValue="Select Microphone"
                          selectedValue={defaultDeviceList.audioInputDevices[0].id}
                          handleChange={changeMicrophoneDevice}
                        />
                      </div>
                      <div className="mb-3">
                        <label for="speakerOutput" className="form-label">
                          Speaker
                        </label>
                        <Dropdown
                          id={'speakerOutput'}
                          name="speakerOutput"
                          options={audioOutpuDevices}
                          title={'speakerOutput'}
                          defaultValue="Select Speaker"
                          selectedValue={defaultDeviceList.audioOutputDevices[0].id}
                          handleChange={changeSpeakerDevice}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="voiceContainer">
                      <div className="cameraContainer">
                        <Camera ref={camera} />
                      </div>
                      <div className="mb-3">
                        <label for="cameraOutput" className="form-label">
                          Camera
                        </label>
                        <Dropdown
                          id={'cameraOutput'}
                          name="cameraOutput"
                          options={videoInputDevices}
                          title={'speakerOutput'}
                          defaultValue="Select Camera"
                          selectedValue={defaultDeviceList.videoInputDevices[0].id}
                          handleChange={changeCameraDevice}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* User Waiting confirmation modal */}
        {showUserApprove && isAdminUser && (
          <div
            className="draggable medium"
            ref={draggableRef}
            style={{
              top: position.y,
              left: position.x,
            }}
          >
            <div className="draggable-close">
              <button onClick={() => setShowSettings(false)}>
                <SimpleCloseIcon color="#5B5B5B" height={20} width={20} />
              </button>
            </div>
            <h4
              className="chatTabsHeader draggable-panel border border-0 d-flex justify-content-start"
              onMouseDown={handleMouseDown}
            >
              Waiting Room
            </h4>
            <div className="draggable-content">
              {attendies.map((attendie, i) => (
                <div className="d-flex justify-content-between mb-3" key={i}>
                  <div className="attendies-list">
                    <Avatar size={30} name={attendie.name} round={true} /> {attendie.name} entered the waiting room
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button variant="btn-secondary btn-sm me-3" onClick={() => handleDeclineUser(attendie)}>
                      Decline
                    </Button>
                    <Button variant="btn-primary btn-sm" onClick={() => handleAdmitUser(attendie)}>
                      Admit
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </FullScreen>
  );
};

export default Meeting;
