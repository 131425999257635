import React, { useState, useEffect, useContext } from 'react';
import Layout from 'components/Layouts';
import Card from 'components/common/Card';
import { TabContent, Tab, VerticalTabs } from 'components/common/Tabs/Tabs';
import { useParams } from 'react-router-dom';
import { CheckAll, GroupIcon, JobsIcon, Page, AddPostIcon, CalendarIcon, ArticleIcon } from 'components/common/Icons';
import SearchPeople from './People/SearchPeople';
import SearchPage from './Pages/SearchPage';
import SearchPost from './Posts/SearchPost';
import SearchJob from './Jobs/SearchJob';
import SearchEvent from './Events/SearchEvent';
import SearchTeam from './Team/SearchTeam';
import AllSearch from './AllSearch/AllSearch';
import { useDispatch } from 'react-redux';
import { searchCall } from 'store/slices/searchSlice';
import SearchArticle from './Article/SearchArticle';
import SearchContext from 'context/searchContext';


const Search = () => {
  let { id } = useParams();
  if (!id) {
    id = 'all';
  }
  const { query, setSearchQuery } = useContext(SearchContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchCall(query ? query : ''))
    // .then((res) => console.log({res}));
  }, []);

  const [openTab, setOpenTab] = useState(id || 'account');
  
  return (
    <React.Fragment>
      <section className="search-main">
        <div className="search-main--container container py-3">
          <Card className="shadow-sm">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 border-end">
                <div className="p-3">
                  <h4 className="text-capitalize fw-bold px-3">Search Results</h4>
                  <VerticalTabs>
                    <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="all">
                      <div className="circleIcon">
                        <CheckAll />
                      </div>{' '}
                      All
                    </Tab>
                    <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="people">
                      <div className="circleIcon">
                        <GroupIcon />
                      </div>{' '}
                      People
                    </Tab>
                    <Tab setOpenTab={setOpenTab} openTab={id} path=".." to="jobs">
                      <div className="circleIcon">
                        <JobsIcon width={15} height={13} />
                      </div>{' '}
                      Jobs
                    </Tab>
                    <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="pages">
                      <div className="circleIcon">
                        <Page width={15} height={15} />
                      </div>{' '}
                      Pages
                    </Tab>
                    <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="posts">
                      <div className="circleIcon">
                        <AddPostIcon  />
                      </div>{' '}
                      Posts
                    </Tab>
                    {/* <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="events">
                      <div className="circleIcon">
                        <CalendarIcon height={15} />
                      </div>{' '}
                      Events
                    </Tab> */}
                    <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="team">
                      <div className="circleIcon">
                        <GroupIcon height={15} />
                      </div>{' '}
                      Team
                    </Tab>
                    {/* <Tab setOpenTab={setOpenTab} openTab={id} path="search" to="article">
                      <div className="circleIcon">
                        <ArticleIcon height={15} />
                      </div>{' '}
                      Article
                    </Tab> */}
                  </VerticalTabs>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="p-4 h-100 search-wrapper">
                  <TabContent tab="all" openTab={id}>
                    <AllSearch />
                  </TabContent>
                  <TabContent tab="people" openTab={id}>
                    <SearchPeople />
                  </TabContent>
                  <TabContent tab="jobs" openTab={id}>
                    <SearchJob />
                  </TabContent>
                  <TabContent tab="pages" openTab={id}>
                    <SearchPage />
                  </TabContent>
                  <TabContent tab="posts" openTab={id}>
                    <SearchPost />
                  </TabContent>
                  <TabContent tab="events" openTab={id}>
                    <SearchEvent />
                  </TabContent>
                  <TabContent tab="team" openTab={id}>
                    <SearchTeam />
                  </TabContent> 
                  <TabContent tab="article" openTab={id}>
                    <SearchArticle />
                  </TabContent>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Search;
