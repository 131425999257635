import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

const GoogleMeetingView = () => {
  const { googleMeetingDetails } = useSelector((state) => state.meeting);

  const attendees = googleMeetingDetails?.extendedProps?.attendees;
  const [showRemainingAttendees, setShowRemainingAttendees] = useState(false);
  const [toggleSeeMore, setToggleSeeMore] = useState(true);

  const handleRemainingAttendees = () => {
    setShowRemainingAttendees(!showRemainingAttendees);
  };
  return (
    <>
      <div className="row border-bottom">
        <div className="col-xl-2 mb-3">
          <strong>Title</strong>
        </div>
        <div className="col-xl-10 mb-3">{googleMeetingDetails?.title}</div>
        <div className="col-xl-2 mb-3">
          <strong>Host</strong>
        </div>
        <div className="col-xl-10 mb-3">{googleMeetingDetails?.extendedProps?.host}</div>
        {googleMeetingDetails?.extendedProps?.attendees?.length ? (
          <>
            <div className="col-xl-2 mb-3">
              <strong>Attendees</strong>
            </div>
            <div className="col-xl-10 mb-3">
              {!showRemainingAttendees ? (
                <>
                  {googleMeetingDetails?.extendedProps?.attendees
                    ?.map((user) => user.email)
                    ?.slice(0, 4)
                    ?.join(', ')}
                  and
                  {attendees?.length > 4 ? (
                    <span onClick={handleRemainingAttendees} className="cursor-pointer text-blue">
                      +{attendees?.length - 4} more
                    </span>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <>
                  {googleMeetingDetails?.extendedProps?.attendees?.map((user) => user.email)?.join(', ')}{' '}
                  <span onClick={handleRemainingAttendees} className="cursor-pointer text-blue">
                    - show less
                  </span>
                </>
              )}
            </div>
          </>
        ) : <></>}
        <div className="col-xl-2 mb-3">
          <strong>When</strong>
        </div>
        <div className="col-xl-10 mb-3">
          {moment(googleMeetingDetails?._instance?.range?.start).format('ddd, MMM DD, YYYY hh:mm A')} to{' '}
          {moment(googleMeetingDetails?._instance?.range?.end).format('hh:mm A')}
        </div>
        {googleMeetingDetails?.extendedProps?.location && (
          <>
            <div className="col-xl-2 mb-3">
              <strong>Location</strong>
            </div>
            <div className="col-xl-10 mb-3">
              <Link to={googleMeetingDetails?.extendedProps?.location}>
                {googleMeetingDetails?.extendedProps?.location}
              </Link>
            </div>
          </>
        )}
        <div className='col-xl-2 mb-3'>
          <strong>Description</strong>
        </div>
        <div
          className={`col-xl-10 mb-3 ${googleMeetingDetails?.extendedProps?.description?.length > 500 && toggleSeeMore && 'seemore'}`}
          dangerouslySetInnerHTML={{ __html: googleMeetingDetails?.extendedProps?.description }}
        >
        </div>
        {
                googleMeetingDetails?.extendedProps?.description?.length > 500 && (
                  <>
                  <div className='col-xl-2 mb-3'>
                  </div>
                    {
                      toggleSeeMore ? (
                        <div onClick={()=>setToggleSeeMore(false)} className='col-xl-10 mb-3 seemoreCursor'>See More</div>    
                      ) : (
                        <div onClick={()=>setToggleSeeMore(true)} className='col-xl-10 mb-3 seemoreCursor'>See Less</div>    
                      )
                    }
                  </>
                )
        }
      </div>
      {googleMeetingDetails?.meeting_id && (
        <div className="row mt-4 align-items-center">
          <div className="col-xl-2 mb-3">
            <strong>Meeting ID</strong>
          </div>
          <div className="col-xl-10 mb-3">
            {googleMeetingDetails?.meeting_id}{' '}
            {/* <Button className={'h-36 ms-3'} onClick={() => handleJoinMeeting(googleMeetingDetails)}>
              Join Meeting
            </Button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default GoogleMeetingView;
