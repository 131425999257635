import { Search, CloseIcon } from 'components/common/Icons';
import React, { useRef, useState } from 'react';
import GridContainer from './GridContainer';

const GiphyPicker = ({ onGifClick }) => {
  const searchBar = useRef();
  const [searched, setSearched] = useState(false);
  const [searchQuery, setSearcheQuery] = useState('');

  function search(e) {
    if (e.keyCode === 13 && e.target.value !== '') {
      setSearched(true);
      setSearcheQuery(e.target.value);
    }
  }

  const handleClear = () => {
    setSearched(false);
    setSearcheQuery('');
    searchBar.current.value = '';
  };

  return (
    <div className="giphy-picker">
      <div className="giphy-picker--header">
        <div className="position-relative">
          <svg width={15} height={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
          </svg>

          <input ref={searchBar} onKeyDown={search} className="text-truncate" type="text" placeholder="Search" />

          {/* {searchQuery.trim().length > 0 && (
            <CloseIcon
              className="clear-icon"
              onClick={handleClear}
              width={15}
              height={15}
              color="rgba(34, 36, 39, 0.7)"
            />
          )} */}
        </div>
      </div>
      <div className="giphy-picker--body pt-0">
        <GridContainer onGifClick={onGifClick} searchQuery={searchQuery} searched={searched} />
      </div>
    </div>
  );
};

export default GiphyPicker;
