import { createReducer } from "@reduxjs/toolkit";
import{setMobileDevice, setTabletDevice} from '../actions/layout.Action'

const initialState = {
    isMobile: false,
    isTablet: false
  };

  const layoutReducer = createReducer(initialState, builder => {
    builder
      .addCase(setMobileDevice, (state, action) => {
        state.isMobile = action.payload;
      })
      .addCase(setTabletDevice, (state, action) => {
        state.isTablet = action.payload;
      })
      .addDefaultCase((state, action) => { });
  });

  export default layoutReducer;