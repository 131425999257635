export const conversationActionStyle = context => {
	return {
		display: "flex",
		listStyleType: "none",
		padding: "8px",
		margin: "0",
		width: "72px",
		// backgroundColor: `${context.theme.backgroundColor.primary}`,
		borderRadius: "4px",
		alignItems: "center",
		justifyContent: "flex-end",
		position: "absolute",
		right: "16px",
		height: "100%",
	};
};

export const groupButtonStyle = (actionInProgress, progressIcon, actionIcon) => {

	const backgroundImage = actionInProgress ? progressIcon : actionIcon;

	return {
		outline: "0",
		border: "0",
		height: "24px",
		width: "24px",
		borderRadius: "4px",
		alignItems: "center",
		display: "inline-flex",
		justifyContent: "center",
		position: "relative",
		background: `url(${backgroundImage}) center center no-repeat`,
	};
};

export const userOptions = () => {

  return {
    '& .chat-options' : {
      width: '225px',
      display: 'none',
      background: '#FFF',
      border: '1px solid #DDDDDD',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      borderRadius: '12px',
      position: 'absolute',
      left: '-225px',
      top: '0',
      zIndex: 1,
      fontSize: '15px',
      '& img' : {
        marginRight: '8px'
      }
    }
  }
}

export const userOptionsWrap = () => {

  return {
    position: 'relative',
    '&:hover .chat-options' : {
      display: 'block',
      width: '225px',
      background: '#FFF',
      border: '1px solid #DDDDDD',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      borderRadius: '12px',
      position: 'absolute',
      left: '-225px',
      top: '0',
      zIndex: 1,
      fontSize: '15px',
      '& img' : {
        marginRight: '8px'
      },
      '& span:hover': {
        borderRadius: '4px',
        background: '#E4E6EB'
      }
    }
  }
}
