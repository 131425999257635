import SingleFilter from './SingleFilter';

const FilterSectionTeams = ({ filters, filterData, className }) => {
  const filter = filters.map((filter) => {
    return <SingleFilter {...filter} filterData={filterData} />;
  });

  return (
    <>
      <div className={`${className} d-flex`}>{filter}</div>
    </>
  );
};

export default FilterSectionTeams;
