import React, { useCallback, useEffect, useState } from 'react';
import Layout from 'components/Layouts';
import { Tab, Tabs } from '../../components/common/Tabs/index';
import JobListSuggestedJob from './JobListing/JobListSuggestedJob';
import MyJobs from './JobListing/JobListMyJob';
// import MyJobDetailsPage from './JobListing/MyJobDetailsPage';
import JobSearchHead from './searchHead';
import { HorizontalTabs, TabWithoutLink, TabContent } from 'components/common/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveJobs, getApplyedJobs, getSavedJobs } from 'store/actions/Job.Action';
import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import FilterSection from 'components/common/FilterSection';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';

const JobsPage = () => {
  const { activeJobList, searchJobList, jobApplySuccess } = useSelector((state) => state.jobReducer);
  const [openTab, setOpenTab] = useState('Suggested Jobs');
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState();
  const [updatedFilters, setUpdatedFilters] = useState();
  const [jobs, setJobs] = useState([]);
    const { globalCompanies } = useSelector((state) => state.globalData);

  useEffect(() => {
    dispatch(getActiveJobs());
    dispatch(getSavedJobs());
    dispatch(getApplyedJobs());
  }, [jobApplySuccess]);
  

  const datePostedByFilterData = [
    { label: 'Any time', name: 'any_time', value: 'any_time' },
    { label: 'Past 24 hours', name: 'date_posted', value: 'past_24_hours' },
    { label: 'Past week', name: 'date_posted', value: 'past_week' },
    { label: 'Past month', name: 'date_posted', value: 'past_month' },
  ];

  const experienceLevelFilterData = [
    { label: 'Internship', name: 'internship', value: 'internship' },
    { label: 'Entry level', name: 'entry_level', value: 'entry_level' },
    { label: 'Associate', name: 'associate', value: 'associate' },
    { label: 'Mid-Senior level', name: 'mid_senior_level', value: 'mid_senior_level' },
    { label: 'Director', name: 'director', value: 'director' },
    { label: 'Executive', name: 'executive', value: 'executive' },
  ];

  const jobTypeFilterData = [
    { label: 'Full-time', name: 'full_time', value: 'FT' },
    { label: 'Part-time', name: 'part_time', value: 'PT' },
    { label: 'Contract', name: 'contract', value: 'C' },
    { label: 'Temporary', name: 'temporary', value: 'T' },
    { label: 'Other', name: 'other', value: 'O' },
  ];

  const onSiteFilterData = [
    { label: 'Onsite', name: 'onsite', value: 'onsite' },
    { label: 'Remote', name: 'remote', value: 'remote' },
  ];

  const companyFilterData = [...new Set(activeJobList?.map((item) => item?.hiring_company_details?.name)?.flat())].map(
    (item) => {
      if (item !== null) return { label: item, name: item, value: item };
      return null
    },
  );

  const filters = [
    { label: 'Date posted', type: 'radio', data: datePostedByFilterData },
    { label: 'Experience level', type: 'checkbox', data: experienceLevelFilterData },
    { label: 'Company', type: 'checkbox', data: companyFilterData, showInput: true },
    { label: 'Job type', type: 'checkbox', data: jobTypeFilterData },
    { label: 'On-site/Remote', type: 'checkbox', data: onSiteFilterData },
  ];

  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);

  return (
    <React.Fragment>
      <JobSearchHead setData={(data) => setSearchData(data)} search={true} searchFilters={updatedFilters} />
      <section className="bg-white">
        <div className="container search-container-jobs">
          {searchData?.title && (
            <>
              <FilterSection filters={filters} filterData={filterData} feature={'jobList'} />
              <div className="search-details">
                <p>{searchData?.title} Jobs,</p>
                <p>
                  {searchJobList?.length} jobs found {searchData?.location ? `in ${searchData?.location}` : ''}
                </p>
              </div>
            </>
          )}

          {searchJobList?.length ? (
            <>
              <div className="row">
                {searchJobList.map((item, index) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <JobCardIndividual key={index} data={item} index={index} actions={true} candidateView={true} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <HorizontalTabs className="border-bottom">
                <TabWithoutLink
                  onClick={() => setOpenTab('Suggested Jobs')}
                  activeClass={openTab === 'Suggested Jobs' ? 'active' : null}
                >
                  Suggested Jobs
                </TabWithoutLink>
                <TabWithoutLink
                  onClick={() => setOpenTab('My Jobs')}
                  activeClass={openTab === 'My Jobs' ? 'active' : null}
                >
                  My Jobs
                </TabWithoutLink>
              </HorizontalTabs>
              <TabContent tab="Suggested Jobs" openTab={openTab} className="mx-4">
                <JobListSuggestedJob />
              </TabContent>
              <TabContent tab="My Jobs" openTab={openTab} className="mx-4">
                <MyJobs />
              </TabContent>
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default JobsPage;
