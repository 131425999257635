import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import { CheckMark1, AmexIcon, CardIcon, MastercardIcon, VisaIcon } from 'components/common/Icons';
import RadioButton from 'components/common/RadioButton';
import questionIcon from 'assets/icons/questionIcon.svg';
import { getDiskSubscriptionPlan, getUserSubscriptionDetails } from 'store/actions/myDisk.Action';
import { cardList } from 'store/slices/paymentSlice';
import { useSelector, useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import _ from 'lodash';
import Modal from 'components/common/Modal/Modal';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { Text } from 'components/StyledComponents';

const stripePromise = loadStripe(
  'pk_test_51MCQqELuB3zpT9ZyEG79pofkj2UVGFIy99DIx5IVONzZocVqkoyhAnle1JstrfKeViPAXxysXMsdf037YGyn8MiY00DV4DVtrL',
);

export default function DiskSubscription() {
  const dispatch = useDispatch();
  const { subscriptionPlans, userSubscription } = useSelector((state) => state.myDiskReducer);
  const { cardInfo } = useSelector((state) => state.payment);
  const [paymentPeriod, setPaymentPeriod] = useState('monthly');
  const [selectedPlan, setSelectedPlan] = useState();
  const [stripeProdId, setStripeProdId] = useState();
  const [stripePriceId, setStripePriceId] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState();
  const order = { 1: 1, 3: 2, 2: 3 };
  useEffect(() => {
    dispatch(getDiskSubscriptionPlan());
    dispatch(getUserSubscriptionDetails()).then(res => {
      if (res) {
        setCurrentPlan(userSubscription[0]);
      }
    });
  }, []);
  const mapSubscriptions = _.orderBy(subscriptionPlans, (o) => order[o.id]);
  const defaultCard = _.find(cardInfo?.cards, { id: cardInfo?.default_card_id });

  const handlePaymentPeriod = (e) => {
    setPaymentPeriod(e);
  };

  const calculatePrice = (price) => {
    const newPrices = price / 12;
    return _.round(newPrices, 2);
  };

  const handleBuyPackage = (subscription) => {
    dispatch(cardList());
    setSelectedPlan(subscription);
    setPaymentModal(true);
    if (subscription.id === 3) {
      setStripeProdId('prod_NkOYYc30LGrjbk');
      if (paymentPeriod === 'yearly') {
        setStripePriceId('price_1MytnDLuB3zpT9ZyW0qfwJ2B');
      } else {
        setStripePriceId('price_1MytlcLuB3zpT9ZyLMdtDO9A');
      }
    } else if (subscription.id === 2) {
      setStripeProdId('prod_NkOcIz6iwLq9hB');
      if (paymentPeriod === 'yearly') {
        setStripePriceId('price_1Mytp7LuB3zpT9ZyOmHcpoof');
      } else {
        setStripePriceId('price_1Mytp7LuB3zpT9ZyZfVvweNh');
      }
    }
  };

  const displayCardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <VisaIcon />;
      case 'mastercard':
        return <MastercardIcon />;
      case 'amex':
        return <AmexIcon />;
      default:
        return <CardIcon />;
    }
  };

  const handleClose = () => {
    setPaymentModal(false);
  };

  const handlePaymentSubmit = async (selectedPlan) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      clientReferenceId: cardInfo?.cards?.[0]?.customer,
      lineItems: [
        {
          price: stripePriceId, // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `${process.env.REACT_APP_URL}thankyou`,
      cancelUrl: `${process.env.REACT_APP_URL}settings/disk-subscription`,
    });
    console.log(error);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  return (
    <React.Fragment>
      <div className="user-settings--subscriptions">
        <div className="row gap-lg-3 mb-5">
          <div className="col-12 border-bottom pb-2">
            <Text fW="600" fS="20px" color="#333333">
              Disk Subscription
            </Text>
          </div>

          <div className="col-12">
            <div className="d-flex align-items-center gap-3">
              <RadioButton
                name="subcription_period"
                label="Billed Monthly"
                changed={(e) => handlePaymentPeriod(e.target.value)}
                value="monthly"
                isSelected={paymentPeriod === 'monthly' ? true : false}
              />
              <RadioButton
                name="subcription_period"
                label="Billed Yearly"
                changed={(e) => handlePaymentPeriod(e.target.value)}
                value="yearly"
                isSelected={paymentPeriod === 'yearly' ? true : false}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="pricing-table-container">
              <div className="pricing-table">
                <div className="pricing-table--header">
                  <h3>myDisk features</h3>
                </div>
                <div className="pricing-table--body">
                  <ul className="plan-details">
                    <li className="plan-points">
                      <span>Storage</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id={`tooltip-1`}>The amount of space you recieve by default.</Tooltip>}
                      >
                        <img src={questionIcon} width={17} height={17} alt="" title="" />
                      </OverlayTrigger>
                    </li>
                    <li className="plan-points">
                      <span>Best-in-class sync technology</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-2`}>Faster sync using our delta, LAN and streaming technology</Tooltip>
                        }
                      >
                        <img src={questionIcon} width={17} height={17} alt="" title="" />
                      </OverlayTrigger>
                    </li>
                    <li className="plan-points">
                      <span>Anytime, anywhere access</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-3`}>
                            Access your myDisk account from any Windows, Mac, Linus, iOS or Android devices.
                          </Tooltip>
                        }
                      >
                        <img src={questionIcon} width={17} height={17} alt="" title="" />
                      </OverlayTrigger>
                    </li>
                    <li className="plan-points">
                      <span>Easy and secure sharing</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-3`}>
                            Regardless of who you’re working with or what you’re working on, we have easy ways to share
                            files and folders.
                          </Tooltip>
                        }
                      >
                        <img src={questionIcon} width={17} height={17} alt="" title="" />
                      </OverlayTrigger>
                    </li>
                    <li className="plan-points">
                      <span>256-bit AES and SSL/TLS encryption</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-4`}>
                            We use 256-bit AES for files at rest and SSL/TLS for data in transit, so your files are
                            protected.
                          </Tooltip>
                        }
                      >
                        <img src={questionIcon} width={17} height={17} alt="" title="" />
                      </OverlayTrigger>
                    </li>
                  </ul>
                </div>
              </div>
              {mapSubscriptions &&
                mapSubscriptions?.map((subscription, i) => (
                  <div
                    className={classNames('pricing-table', [i === 1 && 'lightGreyBg', i === 2 && 'darkGreyBg'])}
                    key={i}
                  >
                    <div className="pricing-table--header">
                      <h3>{subscription.plan_name}</h3>
                      <p>
                        {paymentPeriod === 'monthly'
                          ? subscription.price_monthly === '0'
                            ? 'Free'
                            : `$${subscription.price_monthly} / mo`
                          : subscription.price_yearly === '0'
                            ? 'Free'
                            : `$${calculatePrice(subscription.price_yearly)} / mo`}
                      </p>
                      <Button
                        disabled={currentPlan?.disk_plan?.id === subscription.id ? true : false}
                        onClick={() => handleBuyPackage(subscription)}
                        className="w-100"
                      >
                        {currentPlan?.disk_plan?.id === subscription.id
                          ? 'Current Plan'
                          : subscription.price_monthly === '0'
                            ? 'Free'
                            : `Buy Now`}
                      </Button>
                    </div>
                    <div className="pricing-table--body">
                      <ul className="plan-details">
                        <li className="mark-points">
                          {subscription.id === 1
                            ? `${subscription?.storage?.slice?.(0, 1)}GB`
                            : `${subscription?.storage?.slice?.(0, 1)}TB`}
                        </li>
                        <li className="mark-points">
                          <CheckMark1 />
                        </li>
                        <li className="mark-points">
                          <CheckMark1 />
                        </li>
                        <li className="mark-points">
                          <CheckMark1 />
                        </li>
                        <li className="mark-points">
                          <CheckMark1 />
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {paymentModal && defaultCard && (
        <Modal
          onClose={handleClose}
          className="confirmation-modal"
          heading={<h3>Disk subscriptions</h3>}
          action={
            <>
              <Button variant="btn-secondary btn-sm" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn-sm" onClick={() => handlePaymentSubmit(selectedPlan)}>
                Submit
              </Button>
            </>
          }
        >
          <div className="payment-modal">
            <div className="payment-details">
              <div className="plan-name">
                {selectedPlan.plan_name} {`(${_.capitalize(paymentPeriod)})`}
              </div>
              <div className="plan-price">
                ${paymentPeriod === 'monthly' ? `${selectedPlan.price_monthly}/mo` : selectedPlan.price_yearly}
              </div>
            </div>
            <div className="card-section">
              <Link to="/settings/payment">Change Card</Link>
              <div className="card-details">
                {displayCardIcon(defaultCard.card.brand)}
                <span className="ms-2">{`Visa ending in ${defaultCard.card.last4}`}</span>
              </div>
              <div>{`${defaultCard.card.exp_month}/${defaultCard.card.exp_year}`}</div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
