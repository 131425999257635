import React from 'react';

const ToggleSwitch = ({ label, onChange, defaultValue, name, value }) => {
  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="checkbox"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        checked={defaultValue}
        defaultValue={defaultValue}
      />
      <label className="label" htmlFor={name}>
        <span className="inner" />
        <span className="switch" />
      </label>
    </div>
  );
};

export default ToggleSwitch;
