import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import React from 'react';
import { useSelector } from 'react-redux';
import JobListingHeading from './JobListingHeading';
import _ from 'lodash';
import noJobsfound from 'assets/images/blank_jobs_image.svg';

const JobListSuggestedJob = () => {
  const { activeJobList, savedJobList, applyedJobsList } = useSelector((state) => state.jobReducer);
  return (
    <>
      <JobListingHeading />
      <div className="row">
        {activeJobList.length ? activeJobList?.map((item, index) => (
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" key={index}>
            <JobCardIndividual
              data={item}
              index={index}
              actions={true}
              candidateView={true}
              key={index}
              status={_.some(applyedJobsList, ['jobs_id', item.id]) ? 'APPLIED' : ''}
            />
          </div>
        )) : <div className="job-blank-container">
        <img src={noJobsfound} alt="" width="250" className="mb-3" />
      </div>}
      </div>
      {/* {activeJobList?.length > 15 && <div className="d-flex justify-content-center align-items-center _viewAll p-4 mx-4">
        <div className="col-6 d-grid">
          <button className="btn btn-light">View All</button>
        </div>
      </div>} */}
      
    </>
  );
};

export default JobListSuggestedJob;
