import { useSelector } from 'react-redux';

export function useGetProfileSections(){
  const { userProfileInfo, experience, milestones, educations, certificates, profileSkillsList } = useSelector(
    (state) => state.userProfile,
  );
  const { receivedTestimonial, giveTestimonial } = useSelector((state) => state.TestimonialReducer);

  const showProfileIntroductionButton = !userProfileInfo?.profile_headline &&  !userProfileInfo?.profile_intro;
  const showExperienceButton = experience?.results?.length <= 0;
  const showSkillButton = profileSkillsList?.length <= 0;
  const showEducationButton = educations?.results?.length <= 0;
  const showMilestoneButton = milestones?.results?.length <= 0;
  const showCertificationButton = certificates?.results?.length <= 0;
  const receivedTestimonials = receivedTestimonial?.length <= 0;
  const givenTestimonials = giveTestimonial?.length <= 0;
  const showTestimonialsButton = receivedTestimonials && givenTestimonials;
  const showMyActivity = false;
  return {
    showProfileIntroductionButton,
    showExperienceButton,
    showSkillButton,
    showEducationButton,
    showMilestoneButton,
    showCertificationButton,
    showTestimonialsButton,
    showMyActivity,
  }
}