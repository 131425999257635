import React, { useEffect } from 'react';
import SearchBar from 'components/common/SearchBar';
import FilterSectionTeams from 'components/common/FilterSectionTeams';
import PendingsRequestsCard from './Components/PendingsRequests';
import { organisers } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitedMemberList } from 'store/slices/teamCommunitySlice';
import noContentImage from 'assets/images/emptyBoxImage.png';
const InvitedMembers = () => {
  const { invitedMembers, teamId, usersUpdated } = useSelector((state) => state.teamsCommunity);
  const dispatch = useDispatch();
  useEffect(() => {
    if (teamId) {
      dispatch(getInvitedMemberList({ for_team_community_id: teamId, list_type: 'invited' }));
      
    }
  }, [teamId, usersUpdated]);
  const filters = [
    { label: 'Locations', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Current company', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Industry', type: '', data: null, showInput: false, showKeywords: true },
    { label: 'School', type: '', data: null, showInput: false, showKeywords: true },
  ];
  return (
    <React.Fragment>
      <div className="membersContainer">
        <div className="membersContainer--Header">
          <div className="headerLeft">
            <h6>
              Invited &bull; <span>{invitedMembers.length} person(s)</span>
            </h6>
          </div>
          <div className="headerRight">
            <SearchBar />
          </div>
        </div>
        <div className="membersContainer--Filters">
          <FilterSectionTeams filters={filters} />
        </div>
        <div className="membersContainer--CardList">
          {invitedMembers.length > 0 ? (
            invitedMembers.map((user, i) => <PendingsRequestsCard type="invited" user={user.user} key={i} />)
          ) : (
            <div className="empty-list-box">
              <img src={noContentImage} alt="" title="" />
              <p class="text-center mt-3">
                <b>No Invited Members</b>
                <br />
                Once you invite a member from a Team, they will appear here.
              </p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InvitedMembers;
