import React, { useEffect, useMemo, useState } from 'react';
// import './Calendar.scss';
import '../../../../../scss/components/_card.scss';
import '../../../../../scss/components/_card.scss';
// import moment from 'moment';
import moment from 'moment-timezone';
import Checkbox from 'components/common/Checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { newGoogleMeeting, newMeeting, newOutlookMeeting } from 'store/slices/meetingSlice';
import { getAllMeetings, getEventsFromGoogle, getEventsFromOutlook } from 'store/slices/calendarSlice';
import { getConnectionsData } from 'store/slices/connectionSlice';
import Select from 'react-select';
import Button from 'components/common/Button';
import { Input, TextArea } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import { MEETING_LOCATION, TIME_FORMATE } from 'data/data';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MeetingSchedule = () => {
  const { data: connectionData } = useSelector((state) => state.connection);
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(getAllEvent());
    dispatch(getConnectionsData());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('startStrFromCalendar')) {
      setFromDate(new Date(localStorage.getItem('startStrFromCalendar')));
      setToDate(new Date(localStorage.getItem('endStrFromCalendar')));
    }
    setTimeout(() => {
      localStorage.removeItem('startStrFromCalendar');
      localStorage.removeItem('endStrFromCalendar');
    }, 2000);
  }, []);

  const [inviteTo, setInviteTo] = useState([]);

  const handleSelect = (data) => {
    setInviteTo(data);
  };
  const [newEventSubject, setNewEventSubject] = useState('');
  const [newEventLocation, setNewEventLocation] = useState('builtin');
  const [locationUrl, setLocationUrl] = useState(null);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [timeZoneSelected, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [allDay, setAllDay] = useState();
  const [waitingRoom, setWaitingRoom] = useState(false);

  let min = moment().minute();
  let dateTime = moment()
    .add(min > 30 && 1, 'hours')
    .minutes(min <= 30 ? 30 : 0)
    .format('HH:mm');
  let endTimeMin = moment(dateTime, 'HH:mm').add(30, 'minutes').format('HH:mm');

  const [fromTime, setFromTime] = useState(dateTime);
  const [toTime, setToTime] = useState(endTimeMin);

  const setStartTime = (e) => {
    const { value } = e.target;
    let endTime = moment(value, 'HH:mm').add(30, 'minutes').format('HH:mm');
    setFromTime(value);
    setToTime(endTime);
  };
  const setEndTime = (e) => {
    const { value } = e.target;
    setToTime(value);
  };

  // console.log(fromTime)

  // const currentDate = useMemo(() => {
  //   if (localStorage.getItem('startStrFromCalendar')) return new Date(localStorage.getItem('startStrFromCalendar'));
  //   else {
  //     return new Date(moment()._d);
  //   }
  // }, [localStorage.getItem('startStrFromCalendar')]);

  // const byDefaultToDate = useMemo(() => {
  //   if (localStorage.getItem('endStrFromCalendar')) {
  //     return new Date(localStorage.getItem('endStrFromCalendar'));
  //   } else {
  //     return new Date(moment().add(0.5, 'hours')._d);
  //   }
  // }, [localStorage.getItem('endStrFromCalendar')]);

  const [newEventDescription, setNewEventDescription] = useState('');

  const [isInviteeVisible, setIsInviteeVisible] = useState(false);

  const formSubmitHandler = (is_draft) => {
    let attendees = [];
    for (let i = 0; i < inviteTo.length; i++) {
      attendees.push(inviteTo[i].value);
    }
    let meetingID = parseInt(Math.random().toFixed(6).replace('0.', ''));
    let startTime, endTime;
    startTime = moment
      .tz(`${moment(fromDate).format('YYYY-MM-DD')}T${fromTime}`, timeZoneSelected)
      .utc()
      .format();
    endTime = moment
      .tz(`${moment(toDate).format('YYYY-MM-DD')}T${toTime}`, timeZoneSelected)
      .utc()
      .format();
    const meeting = {
      attendees: attendees,
      subject: newEventSubject,
      location: newEventLocation,
      location_url: locationUrl,
      start_time: moment
        .tz(`${moment(fromDate).format('YYYY-MM-DD')}T${fromTime}`, timeZoneSelected)
        .utc()
        .format(),
      end_time: moment
        .tz(`${moment(toDate).format('YYYY-MM-DD')}T${toTime}`, timeZoneSelected)
        .utc()
        .format(),
      timezone: timeZoneSelected,
      waiting_room: waitingRoom,
      description: newEventDescription,
      meeting_id: newEventLocation === 'builtin' ? parseInt(`6013${meetingID}`) : null,
      is_draft: is_draft,
      all_day_meeting: allDay,
    };
    let googleMeeting = null;
    if (userProfileInfo?.google_calendar_email) {
      googleMeeting = {
        summary: newEventSubject,
        location: newEventLocation,
        description: newEventDescription,
        start: {
          dateTime: startTime,
          timeZone: timeZoneSelected,
        },
        end: {
          dateTime: endTime,
          timeZone: timeZoneSelected,
        },
        attendees: attendees,
        reminders: {
          useDefault: true,
        },
      };
    }
    let outlookMeeting = null;
    if (userProfileInfo?.outlook_calendar_email) {
      outlookMeeting = {
        subject: newEventSubject,
        location: {
          displayName: newEventLocation,
        },
        body: {
          contentType: 'TEXT',
          content: newEventDescription,
        },
        start: {
          dateTime: startTime,
          timeZone: timeZoneSelected,
        },
        end: {
          dateTime: endTime,
          timeZone: timeZoneSelected,
        },
        attendees: attendees.map((attendee) => {
          return {
            emailAddress: {
              address: attendee,
              name: attendee.split('@')[0],
            },
            type: 'required',
          };
        }),
      };
    }
    dispatch(newMeeting(meeting)).then((res) => {
      if (googleMeeting) {
        googleMeeting.id = 'bridge' + res?.payload?.id;
        dispatch(newGoogleMeeting(googleMeeting)).then((res) => {
          dispatch(getEventsFromGoogle());
        });
      }
      if (outlookMeeting) {
        outlookMeeting.id = 'bridge' + res?.payload?.id;
        dispatch(newOutlookMeeting(outlookMeeting)).then((res) => {
          dispatch(getEventsFromOutlook());
        });
      }
      dispatch(getAllMeetings()).then(() => {
        navigate('/profile/calendar');
      });
    });
  };

  const ary = Intl.supportedValuesOf('timeZone');

  const connectionOptions = useMemo(
    () =>
      connectionData?.results?.map((t) => {
        let fullName = t?.first_name + ' ' + t?.last_name;
        return { value: t.email, label: fullName };
      }),
    [connectionData],
  );
  const gotoUrl = (page) => {
    navigate(`/profile/${page}`);
  };
  const animatedComponents = makeAnimated();

  const timeZone = ary.map((item) => {
    return { value: item, label: item };
  });

  const setAllDayMeeting = (e) => {
    const time = moment('12:00 AM', 'h:mm A').format('HH:mm');
    const { checked } = e.target;
    if (checked) {
      setAllDay(true);
      setFromTime(time);
      setToTime(time);
    } else {
      setAllDay(false);
      setFromTime(dateTime);
      setToTime(endTimeMin);
    }
  };

  const setStartDate = (data) => {
    setFromDate(data);
    setToDate(data);
  };
  return (
    <div className="form">
      <header>
        <h3>Schedule a Meeting</h3>
        <h4 onClick={() => setIsInviteeVisible((preState) => !preState)}>Invite Attendees</h4>
      </header>
      {isInviteeVisible && (
        <>
          <label className="label-text">To</label>
          <Select
            styles={{
              container: (style, action) => ({
                ...style,
                width: '100%',
                borderRadius: '8px',
                marginBottom: '1rem',
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                width: '100%',
                background: '#F8F8F8',
                padding: '0.313rem .25rem 0.313rem 0.5rem',
              }),
              indicatorsContainer: (provide, state) => ({
                ...provide,
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                display: 'none', // hide the down caret icon
              }),
            }}
            value={inviteTo}
            isSearchable={true}
            options={connectionOptions}
            placeholder="To"
            onChange={handleSelect}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, animatedComponents }}
            isMulti
          />
        </>
      )}
      <label className="label-text">Subject</label>
      <Input
        placeholder="Subject"
        onChange={(event) => setNewEventSubject(event.target.value)}
        value={newEventSubject}
      />

      <label className="label-text">Location</label>
      <Dropdown
        id={'location'}
        name="location"
        options={MEETING_LOCATION}
        title={'location'}
        selectedValue={newEventLocation}
        handleChange={(event) => setNewEventLocation(event.target.value)}
      />

      {newEventLocation === 'provide' && (
        <>
          <label className="label-text">Location</label>
          <Input
            placeholder="Provide a location"
            onChange={(event) => setLocationUrl(event.target.value)}
            value={locationUrl}
          />
        </>
      )}
      {newEventLocation === 'builtin' && (
        <>
          <div className="row">
            <div className="col-12">
              <Checkbox name="Passcode" checked={true} disabled={true} label="Passcode" className="m-0 passcode" />
              <span className="buitinSpan">Only user who have the invite link or passcode can join the meeting</span>
            </div>
            <div className="col-12">
              <Checkbox
                value={waitingRoom}
                checked={waitingRoom}
                onChange={() => setWaitingRoom(!waitingRoom)}
                name="Waiting Room"
                label="Waiting Room"
                className="m-0"
              />
              <span className="buitinSpan">Only user admitted by the host can join the meeting</span>
            </div>
          </div>
        </>
      )}
      <div className="form__first-coloumn my-3">
        <div className="date-wrapper form-group mb-0">
          <DatePicker
            selected={fromDate}
            onChange={(date) => setStartDate(date)}
            minDate={new Date()}
            className="date-selector form-control"
            dateFormat="EEE, MMMM d, yyyy"
          />

          {/*<div className="date">{moment(fromDate).format('ddd MM/DD/YYYY')}</div>
          <Input type="date" containerClass="m-0" className="date-selector" onChange={(e) => setFromDate(e.target.value)} value={fromDate} />*/}
        </div>
        <Dropdown
          id={'fromTime'}
          name="fromTime"
          options={TIME_FORMATE}
          title={'fromTime'}
          selectedValue={fromTime}
          handleChange={setStartTime}
          containerClass="m-0"
          disabled={allDay}
        />

        <div>
          <Dropdown
            id={'timeZone'}
            name="timeZone"
            options={timeZone}
            title={'timeZone'}
            selectedValue={timeZoneSelected}
            handleChange={(event) => setTimeZone(event.target.value)}
            containerClass="m-0"
          />
        </div>
      </div>
      <div className="form__first-coloumn mb-3">
        <div className="date-wrapper form-group mb-0">
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            minDate={new Date(fromDate)}
            className="date-selector form-control"
            dateFormat="EEE, MMMM d, yyyy"
          />
        </div>
        <Dropdown
          id={'toTime'}
          name="toTime"
          options={TIME_FORMATE}
          title={'toTime'}
          selectedValue={toTime}
          handleChange={setEndTime}
          containerClass="m-0"
          disabled={allDay}
        />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox label="All Day Meeting" className="m-0" onChange={setAllDayMeeting} />
        </div>
      </div>
      <TextArea
        rows={4}
        placeholder="Description"
        onChange={(event) => setNewEventDescription(event.target.value)}
        value={newEventDescription}
      />
      {/* <span>Meeting ID:</span> */}
      <div className="text-end">
        <Button variant="btn-secondary" className="h-36 me-2" onClick={() => gotoUrl('calendar')}>
          Cancel
        </Button>
        {((isInviteeVisible && inviteTo) || newEventSubject.length >= 0) && (
          <Button
            variant="btn-primary"
            onClick={
              newEventSubject.length >= 0 && inviteTo.length <= 0
                ? () => formSubmitHandler(true)
                : () => formSubmitHandler(false)
            }
            className={`${inviteTo.length <= 0 ? 'h-36' : 'h-36'}`}
            disabled={newEventSubject.length <= 0}
          >
            {newEventSubject.length >= 0 && inviteTo.length <= 0 ? 'Save' : 'Send'}
          </Button>
        )}
      </div>
    </div>
  );
};
export default MeetingSchedule;
