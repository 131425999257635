import React from 'react';
import Avatar from 'react-avatar';
import { DegreeIcon, SimpleCloseIcon } from 'components/common/Icons';
import Button from 'components/common/Button';
import ReadMore from 'components/common/ReadMore';
import moment from 'moment';
import InviteCard from 'components/Network/InviteCard';
import _ from 'lodash';

const PendingInvitations = (props) => {
    const { user } = props;
    const userList = user;
    const time = moment(userList?.created_at),
        beforeTime = moment().subtract(7, 'days').toDate(),
        afterTime = moment().toDate();
    // console.log(time.isBetween(beforeTime, afterTime));
    const newInvitations = _.filter(userList, function (n) {
        return moment(n.created_at).isBetween(beforeTime, afterTime);
    });
    const oldInvitations = _.filter(userList, function (n) {
        return !moment(n.created_at).isBetween(beforeTime, afterTime);
    });

    return (
        <>
            {newInvitations.length > 0 ?
                <div className='invite-section'>
                    <h5 className="invite-heading">
                        New
                    </h5>
                    <div className="invite-container">
                        {/* Code for declined invitation */}
                        {/* <div className='invite-card declined'>
                <div className='invite-card--inner'>
                    <div className='invite-card--leftSection'>
                        <div className='invite-card--profile'>
                            <Avatar name="Kamal Kant" size='40' round={true} />
                        </div>
                        <div className="invite-card--details justify-content-center">
                            <p className="card-desc">Invitation declined  <a href="#">I don’t know Kamal Kant</a></p>
                        </div>
                    </div>
                    <div className="invite-card--actions">
                        <Button variant='btn-link'><SimpleCloseIcon width={18} height={18} color="#000000" /></Button>
                    </div>
                </div>
            </div> */}
                        {newInvitations?.map((userInfo, i) => (
                            <InviteCard type='pending' key={i} user={userInfo} />
                        ))}

                    </div>
                </div> : <div className='invite-section py-3'>
                    <h5 className="invite-heading text-center">
                        No {newInvitations.length === 0 && 'New'} Invitation Pending
                    </h5>
                </div>
            }


            {oldInvitations.length > 0 ?
                <div className='invite-section'>
                    <h5 className="invite-heading">
                        Earlier
                    </h5>
                    <div className="invite-container">

                        {oldInvitations?.map((userInfo, i) => (<InviteCard type='pending' key={i} user={userInfo} />))}

                    </div>
                </div> : ''
            }

        </>
    )
}

export default PendingInvitations