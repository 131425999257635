import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

const initialState = {
  data: [],
  allCalendarEvents: [],
  loading: false,
  loadingMonthlyView: false,
  upcomingEvents: [],
  monthlyEvents: [],
  weeklyEvents: [],
  dailyEvents: [],
  meetingSubject:[],
  allCalendarMeeting:[],
  allGoogleMeetings:[],
  allOutlookMeetings:[],
};

export const getCurrentDayEvent = createAsyncThunk('getCurrentDayEvent', async (body) => {
  const obj = {
    url: APIEndPoints.TodayEvents(body),
  };
  return await API(obj);
});

export const getUpcomingEvents = createAsyncThunk('getUpcomingEvents', async (body) => {
  const obj = {
    url: APIEndPoints.UpcomingEvents(body.date),
  };
  return await API(obj);
});

export const getAllMeetings = createAsyncThunk('getAllMeetings', async () => {
  const obj = {
    url: APIEndPoints.AllMeetings,
  };
  return await API(obj);
});

export const getAllEvent = createAsyncThunk('getAllEvent', async () => {
  const obj = {
    url: APIEndPoints.AllEvents,
  };
  return await API(obj);
});

export const fetchingAllCalendarEvents = createAsyncThunk('fetchingAllCalendarEvents', async (body) => {
  const obj = {
    url: APIEndPoints.fetchingAllCalendarEvents(body),
  };
  return await API(obj);
});

export const getMonthlyEvents = createAsyncThunk('monthlyCalendarEvents', async ({month, year}) => {
  const obj = {
    url: APIEndPoints.monthlyCalendarEvents(month, year)
  }
  return await API(obj);
})

export const getEventsFromGoogle = createAsyncThunk('getEventsFromGoogle', async (body) => {
  const obj = {
    url: APIEndPoints.GOOGLE_EVENT,
  };
  return await API(obj);
})
export const getEventsFromOutlook = createAsyncThunk('getEventsFromOutlook', async (body) => {
  const obj = {
    url: APIEndPoints.OUTLOOK_EVENT,
  };
  return await API(obj);
})

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},

  extraReducers: {
    [getCurrentDayEvent.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentDayEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCurrentDayEvent.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getAllMeetings.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllMeetings.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCalendarMeeting = action.payload;
    },
    [getAllMeetings.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getEventsFromGoogle.pending]: (state, action) => {
      state.loading = true;
    },
    [getEventsFromGoogle.fulfilled]: (state, action) => {
      state.loading = false;
      state.allGoogleMeetings = action.payload;
    },
    [getEventsFromGoogle.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getEventsFromOutlook.pending]: (state, action) => {
      state.loading = true;
    },
    [getEventsFromOutlook.fulfilled]: (state, action) => {
      state.loading = false;
      state.allOutlookMeetings = action.payload;
    },
    [getEventsFromOutlook.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getAllEvent.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllEvent.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCalendarEvents = action.payload;
    },
    [getAllEvent.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [fetchingAllCalendarEvents.pending]: (state, action) => {
      state.loading = false;
    },
    [fetchingAllCalendarEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCalendarEvents = action.payload;
    },
    [fetchingAllCalendarEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [getUpcomingEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [getUpcomingEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.upcomingEvents = action.payload;
    },
    [getUpcomingEvents.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getMonthlyEvents.pending]: (state, action) => {
      state.loadingMonthlyView = true;
    },
    [getMonthlyEvents.fulfilled]: (state, action) => {
      state.loadingMonthlyView = false;
      state.monthlyEvents = action.payload;
    },
    [getMonthlyEvents.rejected]: (state, action) => {
      state.loadingMonthlyView = false;
      state.error = 'Try again later or contact customer support';
    },
  },
});

export default calendarSlice.reducer;
