import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";

import Translator from "../../../resources/localization/translator";

import { conversationActionStyle, groupButtonStyle, userOptions, userOptionsWrap } from "./style.js";

import loadingIcon from "./resources/progress.svg";

import userProfile from "./resources/userprofile.svg";
import userBlock from "./resources/blockuser.svg";
import meetNow from "./resources/meetnow.svg";
import chatOptions from "./resources/chatoptions.svg";
import schedule from "./resources/schedule.svg";
import mute from "./resources/mute.svg";
import call from "./resources/call.svg";
import deleteChat from "./resources/deletechat.svg";
import read from "./resources/read.svg";

class CometChatConversationListActions extends React.PureComponent {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			deleteInProgress: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	toggleTooltip = (event, flag) => {
		const elem = event.target;

		if (flag) {
			elem.setAttribute("title", elem.dataset.title);
		} else {
			elem.removeAttribute("title");
		}
	};

	deleteConversation = (event) => {
		this.props.actionGenerated(
			enums.ACTIONS["DELETE_CONVERSATION"],
			this.props.conversation
		);
		event.stopPropagation();
	};

	render() {
		const deleteConversation = (

      <li css={userOptionsWrap}>
        <img src={chatOptions} onClick={(e) => e.stopPropagation()} alt="" />

                <div css={userOptions}>
                  <div className="chat-options px-3 py-2">
                    <span className="cursor-pointer d-block p-1" title="Mark as unread" onClick={(e) => e.stopPropagation()}><img src={read} alt=""/>Mark as unread</span>
                    <span className="cursor-pointer d-block p-1" title="Mute notifications" onClick={(e) => e.stopPropagation()}><img src={mute} alt=""/>Mute notifications</span>
                    <span className="cursor-pointer d-block p-1" title="View Profile" onClick={(e) => e.stopPropagation()}> <img src={userProfile} alt=""/>View Profile</span>
                    <hr />
                    <span className="cursor-pointer d-block p-1" title="Audio call" onClick={(e) => e.stopPropagation()}><img src={call} alt=""/>Audio call</span>
                    <span className="cursor-pointer d-block p-1" title="Meet now" onClick={(e) => e.stopPropagation()}><img src={meetNow} alt=""/>Meet now</span>
                    <span className="cursor-pointer d-block p-1" title="Schedule a Meeting" onClick={(e) => e.stopPropagation()}><img src={schedule} alt=""/>Schedule a Meeting</span>
                    <hr />
                    <span className="cursor-pointer d-block p-1" title="Block" onClick={(e) => e.stopPropagation()}><img src={userBlock} alt=""/>Block</span>
                    <span className="cursor-pointer d-block p-1" title="Delete" 
                      onMouseEnter={(event) => this.toggleTooltip(event, true)}
                      onMouseLeave={(event) => this.toggleTooltip(event, false)}
                      onClick={this.deleteConversation}>
                        <img src={deleteChat} alt=""/>Delete chat
                    </span>
                  </div>
                </div>
              
            
      </li>

			// <li>
			// 	<button
			// 		type='button'
			// 		css={groupButtonStyle(
			// 			this.state.deleteInProgress,
			// 			loadingIcon,
			// 			deleteIcon
			// 		)}
			// 		className='group__button button__delete'
			// 		data-title={Translator.translate("DELETE", this.context.language)}
			// 		onMouseEnter={(event) => this.toggleTooltip(event, true)}
			// 		onMouseLeave={(event) => this.toggleTooltip(event, false)}
			// 		onClick={this.deleteConversation}
			// 	/>
			// </li>
		);

		return (
			<ul
				css={conversationActionStyle(this.context)}
				className='list__item__actions'
			>
				{deleteConversation}
			</ul>
		);
	}
}

export { CometChatConversationListActions };
