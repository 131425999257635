import { ReportIcon, TeamMenuLeaveIcon, TeamMenuNotificationIcon } from "components/common/Icons"
import { forwardRef, useEffect, useState } from "react"
import { confirmAlert } from "react-confirm-alert"
import { useDispatch, useSelector } from "react-redux"
import { clearTeamLeft, clearTeamReported, getTeamCommunityList, leaveTeamCommunity } from "store/slices/teamCommunitySlice"
import TeamReportModal from "./TeamReportModal"
import { useNavigate } from "react-router-dom"
import TeamLeaveModal from "./TeamLeaveModal"

export const TeamDropdownMenu = forwardRef(({ team, onLeave, onReport }, ref) => {

    const [showReportModal, setShowReportModal] = useState(false)
    const [showLeaveModal, setShowLeaveModal] = useState(false)
    const { isTeamLeft, isTeamReported } = useSelector((state) => state.teamsCommunity);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(() => {
        if (isTeamLeft) {
            setTimeout(() => {
                handleLeaveTeam()
            }, 3000)
        }
    }, [isTeamLeft])

    useEffect(() => {
        if (isTeamReported) {
            setTimeout(() => {
                handleReportTeam()
            }, 3000)
        }
    }, [isTeamReported])

    const preventDefaults = (e) => {
        e?.stopPropagation?.()
        e?.preventDefault?.()
    }

    const handleReportTeam = () => {
        setShowReportModal(prevState => !prevState)
        if (isTeamReported) {
            onReport?.()
        }
        dispatch(clearTeamReported())
    }

    const handleLeaveTeam = () => {
        setShowLeaveModal(prevState => !prevState)
        if (isTeamLeft) {
            onLeave?.()
        }
        dispatch(clearTeamLeft())
    }

    return (
        <>
            <div onClick={preventDefaults} className="more-menu" ref={ref}>
                <div className="link" onClick={() => navigate("/settings/notifications")}>
                    <TeamMenuNotificationIcon width={20} height={20} />
                    <span>
                        Manage Notification
                    </span>
                </div>
                <div className="link" onClick={handleLeaveTeam}>
                    <TeamMenuLeaveIcon width={20} height={20} />
                    <span>
                        Leave this team
                    </span>
                </div>
                <div className="link" onClick={handleReportTeam}>
                    <ReportIcon width={20} height={19} />
                    <span>
                        Report this team
                    </span>
                </div>
            </div>
            {showReportModal ?
                <TeamReportModal
                    onClose={handleReportTeam}
                    team={team}
                />
                :
                <></>
            }
            {showLeaveModal ?
                <TeamLeaveModal
                    onClose={handleLeaveTeam}
                    team={team}
                />
                :
                <></>
            }
        </>
    )
})