import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postDisLikeFunc, postLikeFunc } from 'api/post';
import PostContext from 'context/PostContext';

import { ChatIcon, DisagreeIcon, HandShakeSimpleIcon } from 'components/common/Icons';
import { Flex, Text } from 'components/core';
import { Spinner } from 'components/common/Loader';

import QuoteDropdown from './QuoteDropdown';
import { BorderLessButton } from 'components/StyledComponents';
import queryString from 'query-string';

const FooterActions = ({ setShowComments, setShowCommentBox }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const parsed = queryString.parse(search);
  const { postData, modal, updatePost, commentAllowed } = useContext(PostContext);
  const { id } = postData;
  const { liked_by, dislike_by, comment_count } = postData;
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const currentUserId = userProfileInfo?.id;
  const queryClient = useQueryClient();
  const { mutate: like, isLoading: likeLoading } = useMutation(postLikeFunc, {
    onSuccess: (data) => {
      const { postId } = parsed;
      if (postId) {
        queryClient.invalidateQueries('post');
      } else {
        updatePost();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: dislike, isLoading: disLikeLoading } = useMutation(postDisLikeFunc, {
    onSuccess: (data) => {
      const { postId } = parsed;
      if (postId) {
        queryClient.invalidateQueries('post');
      } else {
        updatePost();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleLike = () => {
    const isUserLiked = liked_by?.find((el) => el.id === currentUserId);
    if (isUserLiked) {
      like({ id, remove: true });
    } else {
      like({ id, remove: false });
    }
  };

  const handleDislike = () => {
    const isUserDisliked = dislike_by?.find((el) => el.id === currentUserId);
    if (isUserDisliked) {
      dislike({ id, remove: true });
    } else {
      dislike({ id, remove: false });
    }
  };

  return (
    <Flex
      justify="space-evenly"
      align="center"
      padding="0.5rem 1rem"
      style={{ borderTop: '1px solid #efefef', borderBottom: '1px solid #efefef' }}
    >
      {commentAllowed ? <Flex
        align="center"
        justify="center"
        onClick={() => {
          if (!modal && comment_count > 0) {
            navigate(`${pathname}?postId=${postData.id}`);
          }
        }}
      >
        <BorderLessButton width="100%" className="d-flex align-items-center" onClick={() => {
          setShowCommentBox?.(true)
          setShowComments?.(true)
        }}>
          <ChatIcon className="me-2 cursor-pointer" width="18" height="26" />{' '}
          <Text.Label className="cursor-pointer" size="15px">Comment</Text.Label>
        </BorderLessButton>
      </Flex> : ""}
      <Flex align="center" justify="center">
        <BorderLessButton width="100%" onClick={handleLike} className="d-flex align-items-center">
          {likeLoading ? (
            <Spinner />
          ) : (
            <HandShakeSimpleIcon className="me-2 md-md-2 cursor-pointer" width="18" height="26" />
          )}
          <Text.Label className="cursor-pointer" size="15px" >
            Agreee
          </Text.Label>
        </BorderLessButton>
      </Flex>
      <Flex align="center" justify="center">
        <BorderLessButton width="100%" onClick={handleDislike} className="d-flex align-items-center">
          {disLikeLoading ? (
            <Spinner />
          ) : (
            <DisagreeIcon className="me-2 cursor-pointer" width="18" height="26" color={'#E41C19'} />
          )}
          <Text.Label className="cursor-pointer" size="15px" >
            Disagree
          </Text.Label>
        </BorderLessButton>
      </Flex>
      <Flex align="center" justify="center">
        <BorderLessButton width="100%" className="d-flex align-items-center">
          <QuoteDropdown className="cursor-pointer" />
        </BorderLessButton>
      </Flex>
    </Flex>
  );
};

export default FooterActions;
