import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/common/Modal/Modal';
import { Text, VerticalLine } from 'components/StyledComponents';
import { rangeOfFullYears } from 'components/utils';
import Button from 'components/common/Button';
import { Input, TextArea } from 'components/common/Input';
import { Country, State, City } from 'country-state-city';
import { useFormik } from 'formik';
import Select, { components } from 'react-select';
import downArrowSelect from 'assets/icons/arrowDownSelect.svg';

import {
  getGlobalIndustries,
  getGlobalCompanyTypes,
  getGlobalCompanySize,
  getGlobalCountries,
  getGlobalCities,
} from 'store/slices/globalDataSlice';
import { genralMonths } from 'config/formConstants';

const yearOptions = rangeOfFullYears(1950, 'backward');

const EditPageModal = ({ onClose, onClickSave }) => {
  const dispatch = useDispatch();
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const { globalIndustries, globalStates, globalCompanyTypes, globalCompanySize, globalCountries, globalCities } =
    useSelector((state) => state.globalData);
  const [tab, setTab] = useState('about');
  const [companyData, setCompanyData] = useState({});
  const [updatedStates, setUpdatedStates] = useState([]);
  const [updatedCities, setUpdatedCities] = useState([]);
  const [errorTab, setErrorTab] = useState(null);
  const CustomIndicators = (props) => {
    return (
      <components.IndicatorsContainer {...props}>
        {<img src={downArrowSelect} alt="Not Found" className="arrow-down" />}
      </components.IndicatorsContainer>
    );
  };
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const validate = (values) => {
    const errors = {};
    if (!values.city) {
      errors.city = 'City is missing';
      setTab('location');
    }
    if (!values.state_region) {
      errors.state_region = 'State is missing';
      setTab('location');
    }
    if (!values.country) {
      errors.country = 'Country is missing';
      setTab('location');
    }
    if (!values.company_size) {
      errors.company_size = 'Company size is missing';
      setTab('pageInfo');
    }
    if (!values.company_type) {
      errors.company_type = 'Select Company type';
      setTab('pageInfo');
    }

    if (!values.main_industry) {
      errors.main_industry = 'Select main industry';
      setTab('pageInfo');
    }
    if (!values.email) {
      errors.email = 'Email is missing';
      setTab('pageInfo');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
      setTab('pageInfo');
    } else if (
      !errors.website &&
      values.email.indexOf(values?.website.split(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim)[1]) === -1
    ) {
      errors.email = 'Email domain should match website url';
      setTab('pageInfo');
    }
    if (!values.website) {
      errors.website = 'Website is missing';
      setTab('pageInfo');
    } else if (
      !/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(
        values.website,
      )
    ) {
      errors.website = 'Website not valid';
      setTab('pageInfo');
    }
    if (!values.name) {
      errors.name = 'Page name is Missing';
      setTab('pageInfo');
    }
    if (!values.introduction) {
      errors.introduction = 'Company Introduction missing';
      setTab('about');
    }

    return errors;
  };

  const editCompanyFormik = useFormik({
    initialValues: {
      address: companyData.address || '',
      company_size: companyData.company_size || '',
      company_type: companyData.company_type || '',
      country: companyData.country || '',
      state_region: companyData.state_region || '',
      city: companyData.city || '',
      zipcode: companyData.zipcode || '',
      main_industry: companyData.main_industry || '',
      email: companyData.email || '',
      introduction: companyData.introduction || '',
      month_founded: companyData.month_founded || '',
      name: companyData.name || '',
      phone_number: companyData.phone_number || '',
      website: companyData.website || '',
      year_founded: companyData.year_founded || '',
      checkbox: true,
      image: companyData.image,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validate,
    onSubmit: (values) => {
      const {
        address,
        company_size,
        company_type,
        country,
        state_region,
        city,
        zipcode,
        main_industry,
        email,
        introduction,
        month_founded,
        name,
        phone_number,
        website,
        year_founded,
      } = values;
      const payload = {
        json: true,
        isFormData: false,
        data: {
          id: companyData.id,
          address: address,
          company_size: company_size,
          company_type: company_type,
          country: country,
          state_region: state_region,
          city: city,
          zipcode: zipcode,
          main_industry: main_industry,
          email: email,
          introduction: introduction,
          month_founded: month_founded,
          name: name,
          phone_number: phone_number,
          website: website,
          year_founded: year_founded,
        },
      };

      for (let key in payload.data) {
        if (
          payload.data[key] &&
          typeof payload.data[key] !== 'string' &&
          typeof payload.data[key] !== 'boolean' &&
          typeof payload.data[key] !== 'number' &&
          !(payload.data[key] instanceof File)
        ) {
          payload.data[key] = payload.data[key].value;
        }
      }
      onClickSave(payload.data);
    },
  });

  const { values, handleSubmit, setFieldValue, setValues } = editCompanyFormik;
  const selectStyles = {
    valueContainer: () => ({
      padding: 10,
      display: 'flex',
      alignItems: 'center',
    }),
    menu: () => ({
      zIndex: 50,
      position: 'fixed',
      backgroundColor: '#fff',
    }),
  };
  useEffect(() => {
    dispatch(getGlobalIndustries());
    dispatch(getGlobalCompanyTypes());
    dispatch(getGlobalCompanySize());
    dispatch(getGlobalCountries());
    dispatch(getGlobalCities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyPageDetail?.id) {
      setCompanyData(companyPageDetail);
    }
  }, [companyPageDetail]);

  const handleTabChange = (tabName) => {
    setTab(tabName);
  };
  const setFormattedContent = React.useCallback(
    (text) => {
      setCompanyData((prev) => ({ ...prev, introduction: text.slice(0, 350) }));
    },
    [350, companyData.introduction],
  );
  useEffect(() => {
    if (values?.country) {
      setUpdatedStates(
        State.getStatesOfCountry(values.country?.value || values.country).map((state) => ({
          label: state.name,
          value: state.isoCode,
          ...state,
        })),
      );
    } else {
      setUpdatedStates(State.getAllStates().map((state) => ({ label: state.name, value: state.isoCode, ...state })));
    }
  }, [values?.country]);
  useEffect(() => {
    if (values?.state_region) {
      setUpdatedCities(
        City.getCitiesOfState(
          values.country?.value || values.country,
          values?.state_region?.value || values?.state_region,
        ).map((city) => ({
          label: city.name,
          value: city.isoCode ? city.isoCode : city.name,
          ...city,
        })),
      );
    } else {
      setUpdatedCities(City.getAllCities().map((city) => ({ label: city.name, value: city.isoCode, ...city })));
    }
  }, [values?.state_region]);
  return (
    <Modal
      onClose={onClose}
      className="edit-modal"
      form={true}
      formAction={editCompanyFormik.handleSubmit}
      heading={
        <Text color="#3D5A80" fS="18px" fW={600}>
          Edit Page
        </Text>
      }
      action={
        <div className="action-btn">
          <Button variant="btn-secondary" className="h-36 me-2" onClick={onClose}>
            Cancel
          </Button>
          <Button className="h-36" type="submit">
            Update
          </Button>
        </div>
      }
    >
      <div className="d-flex px-3 mb-2">
        <div className="d-flex flex-column gap-4 tabs">
          <Text
            fS="16px"
            fW="500"
            color={tab === 'about' ? '#3D5A80' : '#5B5B5B'}
            className="cursor-pointer"
            onClick={() => handleTabChange('about')}
            data-tab-name="about"
          >
            About
          </Text>
          <Text
            fS="16px"
            fW="500"
            color={tab === 'pageInfo' ? '#3D5A80' : '#5B5B5B'}
            className="cursor-pointer"
            onClick={() => handleTabChange('pageInfo')}
            data-tab-name="pageInfo"
          >
            Page Info
          </Text>
          <Text
            fS="16px"
            fW="500"
            color={tab === 'location' ? '#3D5A80' : '#5B5B5B'}
            className="cursor-pointer"
            onClick={() => handleTabChange('location')}
            data-tab-name="location"
          >
            Location
          </Text>
        </div>

        <VerticalLine className="mx-3" />

        <div className="flex-grow-1">
          {tab === 'about' && (
            <div className="d-flex flex-column">
              <Text color="#868585" className="mb-2">
                Company Introduction
              </Text>
              <TextArea
                name="introduction"
                rows={6}
                maxLength={1500}
                placeholder="Add Introduction"
                value={values?.introduction}
                onChange={(event) => setFormattedContent(event.target.value)}
              />

              <Text fS="13px" color="#868585" className="text-end">{`${
                companyData.introduction?.length || 0
              }/350`}</Text>
              {editCompanyFormik.errors.introduction ? (
                <div className="text-danger">{editCompanyFormik.errors.introduction}</div>
              ) : null}
            </div>
          )}

          {tab === 'pageInfo' && (
            <>
              <div className="d-flex flex-column">
                <p className="helper-text">Page Name*</p>
                <Input
                  type="text"
                  placeholder="Page Name*"
                  name="name"
                  value={values?.name}
                  onChange={(value) => setFieldValue('name', value.target.value)}
                  className={editCompanyFormik.errors.name ? 'error' : ''}
                />
                {editCompanyFormik.errors.name ? (
                  <div className="text-danger">{editCompanyFormik.errors.name}</div>
                ) : null}
              </div>
              <div className="d-flex flex-column">
                <p className="helper-text">Company Website URL*</p>
                <Input
                  type="text"
                  placeholder="Company Website URL"
                  name="website"
                  value={values?.website}
                  onChange={(value) => setFieldValue('website', value.target.value)}
                  className={editCompanyFormik.errors.website ? 'error' : ''}
                />
                {editCompanyFormik.errors.website ? (
                  <div className="text-danger">{editCompanyFormik.errors.website}</div>
                ) : null}
              </div>
              <div className="d-flex flex-column">
                <p className="helper-text">Phone</p>
                <Input
                  type="text"
                  placeholder="Phone"
                  name="phone_number"
                  value={values?.phone_number}
                  onChange={(value) => setFieldValue('phone_number', value.target.value)}
                />
              </div>
              <div className="d-flex flex-column">
                <p className="helper-text">Your email at the Company*</p>
                <Input
                  type="url"
                  placeholder="Your email at the Company"
                  name="email"
                  value={values?.email}
                  onChange={(value) => setFieldValue('email', value.target.value)}
                  className={editCompanyFormik.errors.email ? 'error' : ''}
                />
                {editCompanyFormik.errors.email ? (
                  <div className="text-danger">{editCompanyFormik.errors.email}</div>
                ) : null}
              </div>

              <div className="d-flex w-100 mb-3 flex-column">
                <p className="helper-text">Main Company Industry*</p>
                <Select
                  id={'main_industry'}
                  name="main_industry"
                  options={globalIndustries.map((e) => ({ label: e, value: e }))}
                  placeholder="Search Industries"
                  value={globalIndustries
                    .map((e) => ({ label: e, value: e }))
                    .filter(function (option) {
                      return option.value === values.main_industry?.value || option.value === values.main_industry;
                    })}
                  onChange={(value) => {
                    setFieldValue('main_industry', value);
                  }}
                  isSearchable={true}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className={editCompanyFormik.errors.main_industry ? 'w-100 error' : 'w-100'}
                />
                {editCompanyFormik.errors.main_industry ? (
                  <div className="text-danger">{editCompanyFormik.errors.main_industry}</div>
                ) : null}
              </div>

              <div className="d-flex w-100 mb-3 flex-column">
                <p className="helper-text">Company Type*</p>
                <Select
                  id={'company_type'}
                  name="company_type"
                  options={globalCompanyTypes.map((e) => ({ label: e, value: e }))}
                  placeholder="Select Company type"
                  value={globalCompanyTypes
                    .map((e) => {
                      return { label: e, value: e };
                    })
                    .filter(function (option) {
                      return option.value === values.company_type?.value || option.value === values.company_type;
                    })}
                  onChange={(value) => {
                    setFieldValue('company_type', value);
                  }}
                  isSearchable={true}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className={editCompanyFormik.errors.company_type ? 'w-100 error' : 'w-100'}
                />
                {editCompanyFormik.errors.company_type ? (
                  <div className="text-danger">{editCompanyFormik.errors.company_type}</div>
                ) : null}
              </div>

              <div className="d-flex w-100 mb-3 flex-column">
                <p className="helper-text">Company Size*</p>
                <Select
                  id={'company_size'}
                  name="company_size"
                  options={globalCompanySize.map((e) => ({ label: e, value: e }))}
                  placeholder="Select Size"
                  value={globalCompanySize
                    .map((e) => ({ label: e, value: e }))
                    .filter(function (option) {
                      return option.value === values.company_size?.value || option.value === values.company_size;
                    })}
                  onChange={(value) => {
                    setFieldValue('company_size', value);
                  }}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className={editCompanyFormik.errors.company_size ? 'w-100 error' : 'w-100'}
                />
                {editCompanyFormik.errors.company_size ? (
                  <div className="text-danger">{editCompanyFormik.errors.company_size}</div>
                ) : null}
              </div>

              <p className="helper-text">Year Founded</p>
              <div className="d-flex gap-3 w-100 mb-3">
                <Select
                  id={'year_founded'}
                  name="year_founded"
                  options={yearOptions}
                  placeholder="Select Year"
                  value={yearOptions.filter(function (option) {
                    return option.value == values.year_founded?.value || option.value == values.year_founded;
                  })}
                  onChange={(value) => {
                    setFieldValue('year_founded', value);
                  }}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className="flex-fill"
                />
                <Select
                  id={'month_founded'}
                  name="month_founded"
                  options={genralMonths}
                  placeholder="Select Month"
                  value={genralMonths.filter(function (option) {
                    return option.value == values.month_founded?.value || option.value == values.month_founded;
                  })}
                  onChange={(value) => {
                    setFieldValue('month_founded', value);
                  }}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className="flex-fill"
                />
              </div>
            </>
          )}

          {tab === 'location' && (
            <>
              <div className="d-flex w-100 flex-column">
                <p className="helper-text">Address</p>
                <Input
                  type="text"
                  placeholder="address"
                  name="address"
                  value={values?.address}
                  onChange={(value) => setFieldValue('address', value.target.value)}
                />
              </div>
              <div className="d-flex w-100 mb-3 flex-column">
                <p className="helper-text">Country*</p>
                <Select
                  id={'country'}
                  name="country"
                  options={updatedCountries}
                  placeholder="Search Country"
                  value={updatedCountries.filter(function (option) {
                    const countryName = values?.country?.value ? values?.country?.value : values?.country;
                    return option.value === countryName;
                  })}
                  onChange={(value) => {
                    setFieldValue('country', value);
                    setFieldValue('state_region', '');
                    setFieldValue('city', '');
                  }}
                  isSearchable={true}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className={editCompanyFormik.errors.country ? 'w-100 error' : 'w-100'}
                />
                {editCompanyFormik.errors.country ? (
                  <div className="text-danger">{editCompanyFormik.errors.country}</div>
                ) : null}
              </div>

              <p className="helper-text">State or Region*</p>
              <div className="d-flex w-100 mb-3 flex-column">
                <Select
                  id={'state_region'}
                  name="state_region"
                  options={updatedStates}
                  placeholder="Search States"
                  value={updatedStates.filter(function (option) {
                    const stateName = values?.state_region?.value || values?.state_region;
                    return option.value === stateName;
                  })}
                  onChange={(value) => {
                    setFieldValue('state_region', value);
                    setFieldValue('city', '');
                  }}
                  isSearchable={true}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className={editCompanyFormik.errors.state_region ? 'w-100 error' : 'w-100'}
                />
                {editCompanyFormik.errors.state_region ? (
                  <div className="text-danger">{editCompanyFormik.errors.state_region}</div>
                ) : null}
              </div>

              <p className="helper-text">City*</p>
              <div className="d-flex w-100 mb-3 flex-column">
                <Select
                  id={'city'}
                  name="city"
                  options={updatedCities}
                  placeholder="Search city"
                  value={updatedCities.filter(function (option) {
                    const cityName = values?.city?.value || values?.city;
                    return option.value === cityName;
                  })}
                  onChange={(value) => {
                    setFieldValue('city', value);
                  }}
                  isSearchable={true}
                  classNamePrefix="custom-select"
                  components={{ IndicatorsContainer: CustomIndicators }}
                  className={editCompanyFormik.errors.city ? 'w-100 error' : 'w-100'}
                />
                {editCompanyFormik.errors.city ? (
                  <div className="text-danger">{editCompanyFormik.errors.city}</div>
                ) : null}
              </div>
              <div className="d-flex w-100 mb-3 flex-column">
                <p className="helper-text">Zip Code</p>
                <Input
                  type="text"
                  placeholder="zipCode"
                  name="zipCode"
                  value={values?.zipcode}
                  onChange={(value) => setFieldValue('zipcode', value.target.value)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditPageModal;
