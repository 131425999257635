import { Flex, Text } from 'components/core';
import PostFormContext from 'context/PostFromContext';
import React, { useContext } from 'react';

const PostCharacter = () => {
  const { postText } = useContext(PostFormContext);
  const wordpCount = postText?.length;

  return (
    <Flex justify="flex-end" style={{ right: '10px', position: 'absolute', bottom: '5px', width: '80px' }}>
      {postText?.length > 0 && (
        <Text.Label>
          <span style={{ color: wordpCount > 300 ? 'red' : '#00875A' }}>{wordpCount} </span>/300
        </Text.Label>
      )}
    </Flex>
  );
};

export default PostCharacter;
