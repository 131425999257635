import { getUserFullName } from 'components/MyDisk/common/utils';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { BlockIcon, DeleteIcon, EditPostIcon, FollowIcon, HandShakeSimpleIcon, PinIcon, SmileySadIcon } from 'components/common/Icons';
import PostContext from 'context/PostContext';
import BlockUserModal from 'pages/Dashboard/Feed/BlockUserModal';
import DeletePostModal from 'pages/Dashboard/Feed/DeletePostModal';
import IgnorePostModal from 'pages/Dashboard/Feed/IgnorePostModal';
import UnfriendModal from 'pages/Dashboard/Feed/UnfriendModal';
import React, { useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { blockConnection, getAllConnectionIDs, reportConnection, requestConnection } from 'store/slices/connectionSlice';
import { followUser, unFollowUser } from 'store/slices/followSlice';
import styled from 'styled-components';
import PinModal from './PinModal';
import PostEditModal from './PostEditModal';
import { getFollowCompany, getUnFollowCompany, listFollowedCompanies } from 'store/actions/companyPages.Action';

const Container = styled.div`
font-family: Inter;
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
background: #FFFFFF;
border: 1px solid #E4E6EB;
border-radius: 8px;
position:absolute;
padding: 10px 6px;
top:20px;
right:20px;
min-width: 230px;
font-size: 15px;
z-index: 4;
 

ul{
    list-style-type: none;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    padding:0;
    margin:0;
    width:100%;

}

ul li  {
    color: #5B5B5B;
    background: #FFFFFF;
    border:none;
    border-radius: 5px;
    display:flex
    margin:0;
    margin-top:2px;
    width:100%;
    cursor:pointer;

}

ul > li:hover{
    background-color:#efefef;
}


ul li button {
    color: #5B5B5B;
    border:none;
    display:flex
    padding:0;
    margin:0;
    background: transparent;
    width:100%;
    cursor:pointer;
    padding: 8px 6px;
    display:flex;
    justify-content: flex-start;
    border-radius: 5px;
}
 
`;

const PostActionDropDown = ({ targetRef, onClose }) => {
  const { postData } = useContext(PostContext);
  const { author } = postData;
  const [activeModal, setActiveModal] = useState()
  const [followed, setFollowed] = useState(false)
  const [followedCompany, setFollowedCompany] = useState(false)
  const [handshaked, setHandshaked] = useState(false)
  const [inCircle, setInCircle] = useState(false)
  const [loading, setLoading] = useState(false)
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { allConnectionIDs } = useSelector(state => state.connection)
  const { circle, handshake_requests, following } = allConnectionIDs ?? {}
  const [companyFollowers, setCompanyFollowers] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (handshake_requests?.length) {
      const found = handshake_requests.includes(author?.id)
      if (found) {
        setHandshaked(true)
      } else {
        setHandshaked(false)
      }
    }
  }, [handshake_requests])

  useEffect(() => {
    if (following?.length) {
      const found = following.includes(author?.id)
      if (found) {
        setFollowed(true)
      } else {
        setFollowed(false)
      }
    }
  }, [following])

  useEffect(() => {
    if (circle?.length) {
      const found = circle.includes(author?.id)
      if (found) {
        setInCircle(true)
      } else {
        setInCircle(false)
      }
    }
  }, [circle])

  const handleFollow = () => {
    followed ?
      dispatch(unFollowUser({ follow_to: author?.id })).then(() => {
        dispatch(getAllConnectionIDs())
      })
      : dispatch(followUser({ follow_to: author?.id })).then(() => {
        dispatch(getAllConnectionIDs())
      });
    setFollowed((prev) => !prev);
  };

  const handleCompanyFollow = () => {
    followedCompany ?
      dispatch(getFollowCompany(postData?.company?.id)).then(() => {
        // fetchCompanyFollowers()
      })
      : dispatch(getUnFollowCompany(postData?.company?.id)).then(() => {
        // fetchCompanyFollowers()
      });
    setFollowedCompany((prev) => !prev);
  };

  const handleHandshake = () => {
    inCircle ? unfriend() :
      handshaked ?
        dispatch(blockConnection({ block_user_id: author?.id })).then(() => {
          dispatch(getAllConnectionIDs())
        }) :
        dispatch(requestConnection({ to_user_id: author?.id })).then(() => {
          dispatch(getAllConnectionIDs())
        });
    setHandshaked(ps => !ps)
  };

  const unfriend = () => {
    confirmAlert({
      title: "Unfriend " + getUserFullName(author) + "?",
      message: "Are you sure you want to unfriend " + getUserFullName(author) + "?",
      buttons: [
        {
          label: "Unfriend Now",
          onClick: () => {
            setLoading(true)
            dispatch(reportConnection({ block_user_id: author?.id, report_message: "others" })).then(() => {
              setLoading(false)
            }).catch(() => {
              setLoading(false)
            })
          }
        },
        {
          label: "Cancel",
        }
      ]
    })
  }


  return (
    // <Container>
    //   {userProfileInfo?.id !== author?.id ? (
    //     <ul>
    //       <NotInrestedAction />
    //       <FollowAction />
    //       <HandShakeAction />
    //       <BlockAction />
    //     </ul>
    //   ) : (

    //     <ul>
    //       <DeletePostAction />
    //       <EditPostAction />
    //       <PostPin />
    //       {/* <WhoCanReply /> */}
    //     </ul>
    //   )}
    // </Container>
    <>
      <DropdownMenu
        targetRef={targetRef}
        // onClose={() => setTimeout(() => onClose?.(), 200)}
        items={userProfileInfo?.id !== author?.id ? [
          {
            icon: <SmileySadIcon width={20} height={20} />,
            label: "Not interested in this post",
            onClick: () => {
              setActiveModal('ignore')
            }
          },


          ...(postData?.company?.id ? [
            {
              icon: <FollowIcon width={20} height={20} />,
              label: `${followedCompany ? "Unfollow" : "Follow"} ${postData?.company?.id ? postData?.company?.name : ""}`,
              onClick: e => {
                e.stopPropagation();
                handleCompanyFollow()
              }
            }
          ] : [
            {
              icon: <FollowIcon width={20} height={20} />,
              label: `${followed ? "Unfollow" : "Follow"} ${getUserFullName(author)}`,
              onClick: e => {
                e.stopPropagation();
                handleFollow()
              }
            },
            {
              icon: <HandShakeSimpleIcon color="#666" width={20} height={20} />,
              label: inCircle ? "Unfriend" : handshaked ? "Withdraw Handhshake" : "Handhshake request",
              onClick: e => {
                if (inCircle) {
                  setActiveModal('unfriend')
                } else {
                  e.stopPropagation();
                  handleHandshake()
                }
              }
            },
            {
              icon: <BlockIcon width={20} height={20} />,
              label: "Block and report user",
              onClick: () => {
                setActiveModal('block')
              }
            }]),
        ] : [
          {
            icon: <DeleteIcon width={20} height={20} />,
            label: "Delete Post",
            onClick: () => {
              setActiveModal('delete')
            }
          },
          {
            icon: <EditPostIcon width={20} height={20} />,
            label: "Edit Post",
            onClick: () => {
              setActiveModal('edit')
            }
          },
          {
            icon: <PinIcon width={20} height={20} />,
            label: postData?.pinned ? "Unpin post" : "Pin post",
            onClick: () => {
              setActiveModal('pin')
            }
          }
        ]}
      />
      {activeModal === 'ignore' && <IgnorePostModal onClose={() => setActiveModal(null)} />}
      {activeModal === 'unfriend' && <UnfriendModal author={author} onClose={() => setActiveModal(null)} />}
      {activeModal === 'block' && <BlockUserModal onClose={() => setActiveModal(null)} />}
      {activeModal === 'delete' && <DeletePostModal onClose={() => setActiveModal(null)} />}
      {activeModal === 'edit' && <PostEditModal onClose={() => setActiveModal(null)} />}
      {activeModal === 'pin' && <PinModal onClose={() => setActiveModal(null)} />}
    </>
  );
};

export default PostActionDropDown;
