import Alert from 'components/common/Alert/Alert';
import { Spinner } from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import { trimStr } from 'components/utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDiskAccessUpdated, clearUsersUpdated, getTeamMemberList, removeTeamMember, updateDiskStatus, updateUserRole } from 'store/slices/teamCommunitySlice';

const DiskAccessModificationModal = ({ onClose, user, team, type }) => {
  const { loading, error, message, msg, diskAccessUpdated } = useSelector((state) => state.teamsCommunity);

  const dispatch = useDispatch();

  useEffect(() => {
    if (diskAccessUpdated) {
      setTimeout(() => {
        dispatch(clearDiskAccessUpdated())
        handleClose()
      }, 500)
    }
  }, [diskAccessUpdated])

  const handleSend = () => {
    if (type === "no-access") {
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
        "disk_status": "NO"
      }
      dispatch(updateDiskStatus(body));
    }
    if (type === "read-access") {
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
        "disk_status": "R"
      }
      dispatch(updateDiskStatus(body));
    }
    if (type === "write-access") {
      const body = {
        "for_team_community_id": team?.id,
        "user_id": user?.id,
        "disk_status": "W"
      }
      dispatch(updateDiskStatus(body));
    }
  };

  const handleClose = () => {
    onClose();
  };

  const getErrorMessage = (msg) => {
    if (msg?.toLowerCase?.()?.includes?.("before left the the team, Assign disk subscription to other organizer".toLowerCase())) {
      return "Please assign the disk subscription to other organizer, before leaving the team!"
    }
    return "Could not leave the team, please try again later!"

  }

  const getHeading = () => {
    return type === "no-access" ?
      <h2>
        Revoke disk access?
      </h2> : type === "read-access" ?
        <h2>
          Give read permissions?
        </h2> : type === "write-access" ?
          <h2>
            Give write access?
          </h2> : ""
  }

  const getBody = () => {
    return type === "no-access" ?
      <p className="">
        {getFullName()} will no longer have Read/Write access to the team disk.
      </p> : type === "read-access" ?
        <p className="">
          {getFullName()} will have read only access to the team disk and it's resources.
        </p> : type === "write-access" ?
          <p className="">
            {getFullName()} will have complete access to the team disk and it's resources.
          </p> : ""
  }

  const getFullName = () => (user?.first_name ?? "") + " " + (user?.last_name ?? "")

  return (
    <>
      <Modal
        onClose={handleClose}
        className="confirmation-modal"
        heading={
          getHeading()
        }
        action={
          <>
            <button className={`btn btn-light `} onClick={handleClose}>
              No
            </button>
            <button className="btn btn-primary" disabled={loading} onClick={handleSend}>
              {loading ? <Spinner /> : "Yes"}
            </button>
          </>
        }
      >
        <>
          {error && message ? <Alert type="danger">{getErrorMessage(message)}</Alert> : ""}
          {msg ? <Alert type="success">Successfuly updated.</Alert> : ""}
          {getBody()}
        </>
      </Modal>
    </>
  );
};

export default DiskAccessModificationModal;
