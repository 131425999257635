import ReactDOM from "react-dom"
import "./dropdown-menu.scss"
import { useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "hooks/useOnClickOutside"
let tids = {
    tid: -1
}
export const DropdownContainer = ({
    onClose,
    pos, //  "top-left" | "top-right" | "bottom-right" | "bottom-left"
    targetRef,
    children,
    ...props
}) => {
    const ref = useRef()
    useOnClickOutside(ref, () => {
        onClose?.()
        setVisible(false)
    })
    const [visible, setVisible] = useState(false)
    const [resizeObs, setResizeObs] = useState(new ResizeObserver(() => handlePosition()));

    useEffect(() => {
        if (targetRef?.current) {
            targetRef?.current?.addEventListener("click", handleTargetClick)
        }
        return () => {
            targetRef?.current?.removeEventListener("click", handleTargetClick)
        }
    }, [targetRef?.current])

    useEffect(() => {
        handlePosition()
    }, [visible])

    useEffect(() => {
        if (ref?.current) {
            resizeObs.observe(ref?.current)
        }
    }, [ref?.current])

    const handleTargetClick = () => {
        setVisible(true)
    }

    useEffect(() => {
        handlePosition()
    }, [ref?.current, targetRef?.current])

    const handlePosition = () => {
        if (ref?.current && targetRef?.current) {
            const targetRect = targetRef.current?.getBoundingClientRect?.()
            const rect = ref.current?.getBoundingClientRect?.()
            let left, top, right, bottom = 0;

            if (pos === "bottom-left") {
                left = (targetRect.right - rect.width)
                top = (targetRect.top + targetRect.height)
            } else if (pos === "bottom-right") {
                left = (targetRect.left)
                top = (targetRect.top + targetRect.height)
            } else if (pos === "top-left") {
                left = (targetRect.right - rect.width)
                top = (targetRect.top - rect.height)
            } else if (pos === "top-right") {
                left = (targetRect.left)
                top = (targetRect.top - rect.height)
            } else {
                //defaults
                left = (targetRect.right - rect.width)
                top = (targetRect.top + targetRect.height)
            }
            // ref.current.style["left"] = left + "px";
            // ref.current.style["top"] = top + "px";

            // clearTimeout(tids.tid)
            // tids.tid = setTimeout(() => {
            // check for boundaries
            right = rect.right;
            bottom = rect.bottom;

            if (bottom > window.innerHeight) {
                const diff = bottom - window.innerHeight;
                top = (top - diff);
            }
            if (rect.top < 0) {
                top = 10;
            }
            if (rect.left < 0) {
                left = 10;
            }
            if (rect.right > window.innerWidth) {
                const diff = right - window.innerWidth;
                left = (left - (diff + 10));
            }
            // update pos
            ref.current.style["left"] = left + "px";
            ref.current.style["top"] = top + "px";

            // }, 0)
        }

    }

    const Container =
        <div
            onClick={e => {
                e.stopPropagation();
                setTimeout(() => {
                    setVisible(false)
                    onClose?.()
                }, 10)
            }}
            ref={ref}
            className="dropdown-container"
            {...(props ?? {})}
        >
            {children}
        </div>

    return (
        visible ? ReactDOM.createPortal(Container, document?.querySelector("#loader-root")) : <></>
    )
}

export const DropdownMenu = ({
    items,
    renderItem,
    heading,
    subHeading,
    children,
    ...props
}) => <DropdownContainer
    {...props}
>
        {heading ? <div className="dmc-heading">{heading}</div> : ""}
        {subHeading ? <div className="dmc-sub-heading">{subHeading}</div> : ""}
        {
            items?.length > 0 ? <div className="dmc-list">{items?.map?.((item, index) => (
                renderItem ? renderItem(item, index) :
                    <MenuItem {...item} />
            ))}</div> : children
        }
    </DropdownContainer>

export const MenuItem = ({ icon, onClick, label, ...props }) =>
    <div className="ddm-item" onClick={onClick} {...(props ?? {})}>
        {icon ? <div>{icon}</div> : ""}
        < div > {label}</div>
    </div >