import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'components/Layouts';
import { TabContent } from 'components/common/Tabs/Tabs';
import { getCompanyPageData, getCompanyPageFollowers, getCompanyPageInfo } from 'store/actions/companyPages.Action';
import Banner from './Banner';
import Home from './Home';
import Services from './tabs/services';
import Jobs from './tabs/jobs';
import Followers from './tabs/followers';
import Analytics from './components/Analytics';
import CompanyBio from './components/CompanyBio';
import { getCompanyAnalytics } from 'store/actions/companyPages.Action';
import './styles.css';

const CompanyPage = () => {
  const { companyId, id } = useParams();
  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState(id || 'home');
  const { companyDetailUpdate, companyAnalyticsData } = useSelector((state) => state.companyPageReducer);
  const [analyticsData, setAnalyticsData] = useState([]);
  useEffect(() => {
    dispatch(getCompanyAnalytics(companyId));
  }, [companyId]);

  useEffect(() => {
    setAnalyticsData(companyAnalyticsData);
  }, [companyAnalyticsData]);
  useEffect(() => {
    dispatch(getCompanyPageInfo({ id: companyId }));
    dispatch(getCompanyPageFollowers({ id: companyId }));
    //dispatch(getCompanyPageFollowers({ id: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (companyDetailUpdate) {
      dispatch(getCompanyPageData(companyDetailUpdate));
    }
    // eslint-disable-next-line
  }, [companyDetailUpdate]);

  return (
    <React.Fragment>
      <div className="container mt-3">
        <div className="row">
          <div className="col-xl-9">
            <section className="Profile">
              <Banner openTab={openTab} setOpenTab={setOpenTab} />
              <section className="Profile_Tab">
                <div className="company-profile">
                  <TabContent tab="home" openTab={id}>
                    <Home setOpenTab={setOpenTab} />
                  </TabContent>
                  <TabContent tab="services" openTab={id}>
                    <Services />
                  </TabContent>
                  <TabContent tab="jobs" openTab={id}>
                    <Jobs />
                  </TabContent>
                  <TabContent tab="followers" openTab={id}>
                    <Followers companyId={companyId} />
                  </TabContent>
                </div>
              </section>
            </section>
          </div>
          <div className="col-xl-3">
            <CompanyBio />
            <Analytics analyticsList={analyticsData} isComapnypage={true} companyId={companyId} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyPage;
