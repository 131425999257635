import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resendVerificationEmail, activateYourAccount, changeEmailRequest } from 'store/slices/authSlice';
import { getOnboardingStatus } from 'store/slices/onboardingSlice';
import { Link, useNavigate } from "react-router-dom";
import { SimpleCloseIcon } from "components/common/Icons";
import { Input } from "components/common/Input";
import { getUserProfileInfo } from 'store/slices/userProfileSlice';

const EmailVerification = (props) => {
  const { onClose, type } = props;
  const { userProfileInfo, loading } = useSelector((state) => state.userProfile);
  const { inactiveEmailId, verifyMailSent, error, isActivated, changedEmail, emailMessage } = useSelector((state) => state.user);
  const { onboardingStatus, currStepData } = useSelector((state) => state.onboarding);
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const [verificationCodeBox, setVerificationCodeBox] = useState(false);
  const [stepVerification, setStepVerification] = useState(1);
  const [changeEmailPassword, setChangeEmailPassoword] = useState(false);
  const [password, setPassword] = useState();
  const [newEmail, setNewEmail] = useState();
  const [otp, setOtp] = useState();


  useEffect(() => {
    if (!email) {
      setEmail(userProfileInfo.email);
    }
  }, [userProfileInfo]);


  useEffect(() => {
    if (changedEmail) {
      dispatch(getUserProfileInfo());
      setStepVerification(1);
    }
  }, [changedEmail]);

  const handleResendEmail = () => {
    dispatch(resendVerificationEmail({
      "email": email
    })).then(res => {
      if (res.payload.message) {
        // setMessage(res.payload.message);
        setVerificationCodeBox(true);
        setStepVerification(2);
      }
    });
  }

  const handleVerifyEmail = () => {
    dispatch(activateYourAccount(otp));
  }

  const changeEmailId = () => {
    // setReadOnly(false);
    // setEmail();
    setChangeEmailPassoword(true);
    setStepVerification(0);
  }

  const handleEmailChange = () => {
    const body = {
      "email": newEmail,
      "password": password
    }
    dispatch(changeEmailRequest(body));
  }

  useEffect(() => {
    if (changedEmail) {
      setStepVerification(0);
      setChangeEmailPassoword(false);
    }
  }, [changedEmail]);

  useEffect(() => {
    if (isActivated === true) {
      const payload = {
        emailId: userProfileInfo?.email
      };
      dispatch(getOnboardingStatus(payload)).then((res) => {
        if (res) {
          console.log(res.payload.data);
          if (res.payload.data.connect === false) {
            navigate('/onboarding/photo');
          } else {
            navigate('/');
          }
        }
      });
    }
  }, [isActivated]);

  return (
    <div className="email-verification">
      {!loading && <div className="wrap">
        {type === 'dashboard' && <button className="closeBtn" onClick={() => onClose(false)}><SimpleCloseIcon /></button>}
        {stepVerification === 1 && <React.Fragment>
          <h3>Welcome, {userProfileInfo.first_name}!</h3>
          <p>
            We need to confirm your email address. Check your <a href={`mailto:${email}`}>{email}</a> account or request a new confirmation code.
          </p>

          {message && <p className={!error ? 'text-success' : 'text-danger'}>{message}</p>}

          <div className='custom-react-select'>
            <input
              value={email}
              readOnly={readOnly}
              type="text"
              placeholder="Enter here..."
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button className="btn btn-primary" onClick={handleResendEmail}>
            Send Email
          </button>
          <p className='back-to-page mt-3 mb-0'>
            <button className='text-btn clr-grey' onClick={() => changeEmailId()}>
              Change email
            </button>
          </p>
        </React.Fragment>}
        {stepVerification === 2 && <React.Fragment>
          <h3>Confirm your email</h3>
          <p>
            Type in the code we sent to {email}. <Link className="clr-grey" onClick={() => changeEmailId()}>Edit Email</Link>
          </p>
          {message && <p className={!error ? 'text-success' : 'text-danger'}>{message}</p>}
          <div className='custom-react-select mb-3'>
            <input
              value={otp}
              type="text"
              placeholder="- - - - - -"
              onChange={(e) => setOtp(e.target.value)}
              className="text-center"
            />
          </div>
          <p className="mb-0">Didn’t receive the code? <Link className="clr-grey" onClick={() => handleResendEmail()}>Send again</Link></p>
          <button className="btn btn-primary" onClick={handleVerifyEmail}>
            Agree & Confirm
          </button>
        </React.Fragment>}
        {changeEmailPassword && <React.Fragment>
          <h3>Edit email</h3>
          {emailMessage && <p className={!error ? 'text-success' : 'text-danger'}>{emailMessage}</p>}
          <Input type="email" label="Current email" value={email}
            readOnly={readOnly} />
          <Input type="email" label="New email*" value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)} placeholder="Enter new email ID" />
          <Input type="password" label="Password*" value={password}
            onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" helpText="Enter the password you created when joining." />

          <div className='d-flex justify-content-end mt-3'>
            <a href='/forgot-password' className="cursor-pointer clr-grey">
              Forgot Password?
            </a>
          </div>
          <button className="btn btn-primary" onClick={() => handleEmailChange()}>
            Confirm
          </button>
          <p className='back-to-page mt-3 mb-0'>
            <button className="text-btn clr-grey" onClick={() => { setStepVerification(1); setChangeEmailPassoword(false); }}>
              Cancel
            </button>
          </p>
        </React.Fragment>}

      </div>}

    </div>
  )
};

export default EmailVerification;