import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/slices/authSlice';
import { LogoIcon, MyBLogoHeader } from 'components/common/Icons';
import SearchBar from 'components/common/SearchBar';
import StatusDropdown from 'components/common/Status';
import { navLinks,navLoggedOutLinks, STATUSES } from './constant';
import Notification from 'components/common/Notification';
import ChatMenu from 'components/chat';
import EventMenu from 'components/event';
import ProfileDropDown from 'components/ProfileDropdown';
import { AuthContext } from 'context/authcontext';
import { getConnectionsData } from 'store/slices/connectionSlice';
import { fetchPosts, triggerFetchPosts } from 'store/slices/postSlice';
import { getUpcomingEvents } from 'store/slices/calendarSlice';
import Button from "components/common/Button";
import moment from 'moment';
import { useInbox } from '@trycourier/react-hooks';

const Navbar = () => {
  const [searchInput, setSearchInput] = useState('');
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [status, setStatus] = useState(STATUSES[0]);
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const [unreadMessage, setUnreadMessage] = useState([]);
  const { logoutCometChat } = React.useContext(AuthContext);
  const inbox = useInbox();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const DASHBOARD_URL = '/';

  const updateStatus = (option) => {
    setStatus(option);
  };

  const handleScrollTop = () => {
    // window.scroll({
    // 	top: 0,
    // 	left: 0,
    // 	behavior: "smooth",
    // });
  }

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/jobs' || location.pathname === '/search' || location.pathname === '/create_page' || location.pathname === '/post_jobs') {
      handleScrollTop()
    }
  }, [location]);

  useEffect(() => {
    inbox.init();
    inbox.fetchMessages();
    inbox.getUnreadMessageCount();
    inbox.newMessage();
  }, []);

  const onProfileHandle = (menuName, url) => {
    switch (menuName) {
      case 'Logout':
        logoutCometChat();
        sessionStorage.clear();
        localStorage.clear();
        // localStorage.removeItem('access');
        // localStorage.removeItem('refresh');
        dispatch(logout());
        navigate(url);
        navigate(0);
        break;

      case 'Settings':
        navigate(url);
        break;

      case 'MyDisk':
        navigate(url);
        break;

      case 'MyCalendar':
        navigate(url);
        break;

      default:
        break;
    }
  };

  const handleClick = (url) => {
    if (location?.pathname === DASHBOARD_URL) {
      const value = new Date();
      dispatch(getConnectionsData());
      // dispatch(fetchPosts());
      dispatch(triggerFetchPosts(true))
      dispatch(
        getUpcomingEvents({
          date: `${moment(value).date()}-${moment(value).month() + 1}-${moment(value).year()}`,
        }),
      );
    } else {
      navigate(url);
    }
  }
  console.log('get inbox',inbox); 
  return (
    <>
      <header className="header">
        <div className="container">
          <div className={`header-left pe-3 pe-xl-3`}>
            <button onClick={() => handleClick(DASHBOARD_URL)}>
              <MyBLogoHeader className="me-md-3" />
            </button>
            <SearchBar />
          </div>

          <div className="header-middle">
            {(
              <ul className="navigation">
                {!isMobile && (
                  <>
                    {navLinks.map((item) => {
                      const { id, icon, url } = item;
                      return (
                        <li key={id} className={location.pathname === url ? 'active' : ''}>
                          {url === DASHBOARD_URL ? (
                            <button onClick={() => handleClick(DASHBOARD_URL)}>{icon}</button>
                          ) : (
                            <Link to={url}>{icon}</Link>
                          )}
                        </li>
                      );
                    })}
                  </>
                )}
                <li>
                  <Notification messages={inbox.messages} unreadMessageCount={inbox.unreadMessageCount} />
                </li>
              </ul>
            )}
          </div>

          {!isMobile && (
            <>
              <div className="header-right">
                <ul className="navigation">
                  <li className="me-xl-5">
                    <StatusDropdown />
                  </li>
                  <li>
                    <ChatMenu />
                  </li>
                  <li>
                    <EventMenu />
                  </li>
                  <li className="p-0">
                    <ProfileDropDown onProfileHandle={onProfileHandle} />
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </header>

      {isMobile && (
        <>
          <div className="mobile-nav">
            <ul>
              {navLinks.map((item) => {
                const { id, icon, url } = item;
                return (
                  <li key={id} className={location.pathname === url && 'active'}>
                    <Link to={url}>{icon}</Link>
                  </li>
                );
              })}

              <li>
                <ChatMenu />
              </li>

              <li>
                <EventMenu />
              </li>

              <li>
                <ProfileDropDown onProfileHandle={onProfileHandle} />
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
