import Modal from "components/common/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { onboardingSteps } from "../constant";

const ContactModal = ({ onClose, user }) => {
  const navigate = useNavigate();
  const style = {
    minWidth: 65
  }
  const handleNextStep = () => { 
    navigate('/onboarding/connect');
  }
  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={
        <h3>Are you sure?</h3>
      }
      action={
        <>
          <button className={`btn btn-secondary btn-sm`} onClick={onClose}>
            Import contacts
          </button>
          <button style={style} onClick={() => handleNextStep()} className="btn btn-primary btn-sm">
            Skip
          </button>
        </>
      }
    >
      <>
        myBridge works best when you're connected to more people. No invitation will be sent without your consent.
      </>
    </Modal>
  )
};

export default ContactModal;