import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { CompanyAPIEndpoints } from './../common/endPoint';

export const getCompanyAllJobs = createAsyncThunk('getCompanyAllJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=all&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCompanyActiveJobs = createAsyncThunk('getCompanyActiveJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=active&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCompanyCloseJobs = createAsyncThunk('getCompanyCloseJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=closed&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});
export const getCompanyDraftJobs = createAsyncThunk('getCompanyDraftJobs', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=draft&hiring_company_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getCandidates = createAsyncThunk('getCandidates', async ({ id, jobId, status, perPage }) => {
  const queryParams = new URLSearchParams({});

  if (jobId) {
    queryParams.append('job_ids', jobId);
  }
  if (status) {
    queryParams.append('status', status);
  }
  if (perPage) {
    queryParams.append('perPage', perPage);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/company/${id}/candidates?${queryParams.toString()}`,
    method: 'GET',
  };
  return await API(obj);
});

export const updateCandidateStatus = createAsyncThunk(
  'updateCandidateStatus',
  async ({ jobId, candidateId, status }) => {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${jobId}/candidate/${candidateId}/status/update`,
      method: 'PUT',
      body: JSON.stringify({
        status,
      }),
    };
    return await API(obj);
  },
);

export const postNewJob = createAsyncThunk('postNewJob', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}`,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const UpdateJob = createAsyncThunk('UpdateJob', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${payload.id}`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const getJobCheckout = createAsyncThunk('getJobCheckout', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.JobSubscriptionCheckout}`,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const getJobSubscriptionDetails = createAsyncThunk('getJobSubscriptionDetails', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}`,
  };
  return await API(obj);
});
export const jobSubscriptionPriceDetails = createAsyncThunk('jobSubscriptionPriceDetails', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}new/`,
  };
  return await API(obj);
});

export const createJobSubscription = createAsyncThunk('createJobSubscription', async (payload) => {
  const pathName = payload.subscription_id ? 'preview' : 'create';
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}${pathName}/`,
    method:'POST',
    body:JSON.stringify(payload),
  };
  return await API(obj);
});

export const updateJobSubscription = createAsyncThunk('updateJobSubscription', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.UserJobSubscriptions}update/`,
    method:'POST',
    body:JSON.stringify(payload),
  };
  return await API(obj);
});

export const closeJob = createAsyncThunk('closeJob', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${payload.id}`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const setGlobalJobId = createAction('setGlobalJobId');
export const clearGlobalJobId = createAction('clearGlobalJobId');
