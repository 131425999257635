import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Country, State, City } from 'country-state-city';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import OnboardingWrapper from "../Components/OnboardingWrapper";
import { useNavigate } from 'react-router-dom';
import { onboardingSteps, checkUserOnboardingStatus } from '../constant';
import { getOnboardingData, setOnboardingData, getOnboardingStatus } from 'store/slices/onboardingSlice';
import { userProfileAddInfo, getUserProfileInfo } from 'store/slices/userProfileSlice';
import Button from 'components/common/Button';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
const currStep = onboardingSteps[0];
let nextStep = onboardingSteps[1];
const currActiveSteps = [onboardingSteps[0].id];

const OnboardingProfile = () => {
  const { step } = useParams();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { onboardingStatus, currStepData } = useSelector((state) => state.onboarding);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [zipCode, setZipCode] = useState();
  const order = { 'US': 1, 'CA': 2, 'GB': 3};
  useEffect(() => {
    if (userProfileInfo) {
      const payload = {
        step: currStep?.name,
        emailId: userProfileInfo?.email
      };
      dispatch(getOnboardingStatus(payload));
      dispatch(getOnboardingData(payload));
    }
  }, [userProfileInfo]);

  const countries = Country.getAllCountries();

  const USER_NAME = userProfileInfo?.first_name + ' ' + userProfileInfo?.last_name;
  const orderCountries = _.orderBy(countries, (o) => order[o.isoCode]);
  const updatedCountries = orderCountries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country
  }));
  
  const updatedStates = (countryId) => {
    if (countryId) {
      return State.getStatesOfCountry(countryId)
        .map((state) => ({ label: state.name, value: state.isoCode, ...state }));
    } else {
      return State.getAllStates().map((state) => ({ label: state.name, value: state.isoCode, ...state }));
    }

  }

  const updatedCities = (counrtyId, stateId) => {
    if (counrtyId || stateId) {
      return City.getCitiesOfState(counrtyId, stateId).map((city) => ({ label: city.name, value: city.id, ...city }));
    } else {
      return City.getAllCities().map((city) => ({ label: city.name, value: city.id, ...city }));
    }
  }

  const addressFromik = useFormik({
    initialValues: {
      country: null,
      state: null,
      city: null,
      postal_code: null
    },
    onSubmit: (values) => {
      const { city, country, state, postal_code } = values;
      const payload = {
        step: currStep.name,
        json: true,
        isFormData: false,
        emailId: userProfileInfo.email,
        data: {
          "country": country.name ? country.name : country,
          "state": state.name ? state.name : state,
          "city": city.name ? city.name : city,
          "postal_code": postal_code
        }
      };
      dispatch(setOnboardingData(payload, true)).then((res) => {
        if (res?.payload?.status_code === 200) {
          navigate(nextStep.url);
        }
      })
      const formData = new FormData();
      formData.append('location', `${city.name ? city.name : city}, ${country.name ? country.name : country}`);
      dispatch(userProfileAddInfo(formData)).then((res) => {
        dispatch(getUserProfileInfo());
      });
    }
  });


  useEffect(() => { setFieldValue("postal_code", zipCode) }, [zipCode]);

  const { values, handleSubmit, setFieldValue, setValues } = addressFromik;

  useEffect(() => {
    if (currStepData && userProfileInfo.is_active) {
      setValues(currStepData?.data);
      if (step) {
        const checkStatus = checkUserOnboardingStatus(onboardingStatus);
        navigate(checkStatus.url);
      }
    }
  }, [currStepData, onboardingStatus]);

  const isValid = Boolean(values?.country);


  return (
    <OnboardingWrapper activeSteps={currActiveSteps}>
      <h3>Welcome, {USER_NAME}!</h3>
      <p> First, let's find out where you live. This is the first step in creating your profile (called myProfile).</p>

      <form onSubmit={handleSubmit}>
        <div className='custom-react-select'>
          <label>
            Country<sup>*</sup>
          </label>
          <Select
            id="country"
            name="country"
            label="country"
            placeholder="Country"
            isSearchable
            options={updatedCountries}
            onChange={(value) => {
              setFieldValue("country", value)
            }}
            value={updatedCountries.filter(function (option) {
              const countryName = values?.country?.name ? values?.country?.name : values?.country;
              return option.label === countryName;
            })}
          />
        </div>

        <div className='custom-react-select'>
          <label>State<sup>*</sup></label>
          <Select
            id="state"
            name="state"
            placeholder="State"
            options={updatedStates(values?.country ? values?.country?.isoCode : null)}
            onChange={(value) => {
              setFieldValue("state", value)
            }}
            value={updatedStates(values?.country ? values?.country?.isoCode : null).filter(function (option) {
              const stateName = values?.state?.name ? values?.state?.name : values?.state;
              return option.label === stateName;
            })}
          />
        </div>

        <div className='custom-react-select'>
          <label>City<sup>*</sup></label>
          <Select
            id="city"
            name="city"
            placeholder="City"
            options={updatedCities(values?.country?.isoCode, values?.state?.isoCode)}
            onChange={(value) => setFieldValue("city", value)}
            value={updatedCities(values?.country?.isoCode, values?.state?.isoCode).filter(function (option) {
              const cityName = values?.city?.name ? values?.city?.name : values?.city;
              return option.label === cityName;
            })}
          />
        </div>

        <div className='custom-react-select'>
          <label>Postal Code</label>
          <input id='postal-code' name='postal-code' value={values?.postal_code} placeholder='Postal code' onChange={(value) => setFieldValue("postal_code", value.target.value)} />
        </div>

        <Button type='submit' disabled={!isValid} className="w-100 mt-3">
          Next
        </Button>
      </form>
    </OnboardingWrapper>
  )
};

export default OnboardingProfile;