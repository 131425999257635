import { useMutation } from '@tanstack/react-query';
import { followRequestFunc } from 'api/follow';
import { Spinner } from 'components/common/Loader';
import PropTypes from 'prop-types';
import { useState } from 'react';

const { default: styled } = require('styled-components');

const ButtonConatiner = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 18px;
  gap: 10px;

  width: 85px;
  height: 38px;

  background: ${({ follow }) => (follow === 'Follow' ? '#E18C3E' : '#fff')};
  border-radius: 5px;
  border: none;

  color: ${({ follow }) => (follow === 'Follow' ? '#fff' : '#E18C3E')};
`;

const FollowButton = ({ to }) => {
  const [follow, setFollow] = useState('Follow');
  const { mutate, isLoading } = useMutation(followRequestFunc, {
    onSuccess: () => {
      setFollow('Following');
    },
    onError: () => {
      console.log('error');
    },
  });

  return (
    <ButtonConatiner follow={follow} onClick={() => mutate({ to })}>
      {isLoading ? <Spinner /> : follow}
    </ButtonConatiner>
  );
};

FollowButton.propTypes = {
  to: PropTypes.string.isRequired,
};

export default FollowButton;
