import React, { useState, useEffect } from 'react';
import { CircleFilled } from 'components/common/Icons';
import { Input } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import RadioButton from 'components/common/RadioButton';
import Button from 'components/common/Button';
import { addTeam, deleteTeam, getPages, teamList, updateTeam } from 'store/slices/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import _, { replace, startCase } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { Text } from 'components/StyledComponents';
import { Spinner } from 'components/common/Loader';
import { confirmAlert } from 'react-confirm-alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ERROR_VALUES = {
  teamMate: 'Please enter a team mate email',
  companyPage: 'Please enter a company page',
  role: 'Please select a role',
};
export default function Team() {
  const { isTeamUpdated, loading, teamInfo, pages, msg } = useSelector((state) => state.team);
  const [showTeams, setShowTeams] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [fields, setFields] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorFields, setErrorFields] = useState({});
  const [currentTeamId, setCurrentTeamId] = useState(null);
  const [companyPages, setCompanyPages] = useState([]);

  useEffect(() => {
    dispatch(getPages());
    dispatch(teamList());
  }, []);

  useEffect(() => {
    if (isTeamUpdated) {
      dispatch(teamList());
      setShowTeams(true);
      handleClickScroll();
    }
  }, [isTeamUpdated]);

  useEffect(() => {
    if (pages) {
      const page = pages?.results.map(({ name, id }) => {
        return {
          label: name,
          id,
          value: id,
        };
      });
      setCompanyPages(page);
    }
  }, [pages]);

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
    let valid = value.trim().length > 0;
    if (name === 'teamMate') {
      valid = value
        ?.trim?.()
        ?.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    }
    setErrorFields((prevErrors) => ({ ...prevErrors, [name]: valid ? undefined : ERROR_VALUES[name] }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
    setErrorFields((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };
  const goToCreate = () => {
    setAddOpen(false);
    navigate('/create/company');
  };
  const saveTeam = () => {
    const { teamMate, companyPage, role } = fields;

    const errorObject = {};
    if (!teamMate) {
      errorObject.teamMate = ERROR_VALUES.teamMate;
    }
    if (!companyPage) {
      errorObject.companyPage = ERROR_VALUES.companyPage;
    }
    if (!role) {
      errorObject.role = ERROR_VALUES.role;
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }

    const payload = {
      teammate: teamMate,
      company_page: Number(companyPage),
      access: role,
    };
    if (currentTeamId) {
      payload.id = currentTeamId;
      dispatch(updateTeam(payload));
      setFields({});
      setCurrentTeamId(null);
    } else {
      dispatch(addTeam(payload));
      setFields({});
      setCurrentTeamId(null);
    }
  };

  const cancelTeam = () => {
    setCurrentTeamId(null);
    setShowTeams(true);
    setFields({});
    setAddOpen(false);
  };

  const editTeam = (id) => {
    setErrorFields({});
    setShowTeams(false);
    setCurrentTeamId(id);
    handleClickScroll();
    const currentTeam = teamInfo?.results?.find((t) => t.id === id);
    const { teammate: teamMate, access: role, company_page: companyPage } = currentTeam;
    setFields({ teamMate, role, companyPage });
    setAddOpen(true);
  };

  const askDeleteItem = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteTeam(id),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleDeleteTeam = (id) => {
    dispatch(deleteTeam(id));
    setShowTeams(true);
    dispatch(teamList());
  };

  const radioChangeHandler = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
    setErrorFields((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const addTeamMate = () => {
    setCurrentTeamId(null);
    setShowTeams(false);
    setFields({});
  };

  const handleClickScroll = () => {
    setAddOpen((prev) => !prev);
    setFields({});
    setErrorFields({});
    const element = document.getElementById('addMemberCompany');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    const eles = document.querySelectorAll('input');
    if (eles) {
      eles.forEach((item) => (item.value = ''));
    }
  };

  // console.log(companyPages);
  return (
    <div className="user-settings--team">
      {currentTeamId ? (
        ''
      ) : (
        <div className="col-12 border-bottom pb-2 d-flex align-items-center justify-content-between mb-3">
          <Text fS="20px" color="#333333">
            Permissions
          </Text>
          {showTeams && (
            <button className="btn fw-normal p-2 d-flex align-items-center" onClick={() => handleClickScroll()}>
              <Text fS="16px" color="#333333">
                Add new user
              </Text>
              <CircleFilled color="#3D5A80" className="ms-2" />
            </button>
          )}
        </div>
      )}
      {currentTeamId ? (
        ''
      ) : (
        <div className="mb-3">
          <div className="table-responsive">
            <table className="table border permission-table">
              <thead className="table-light">
                <tr>
                  <th scope="col">
                    <Text fW="600" color="#333333">
                      Name
                    </Text>
                  </th>
                  <th scope="col">
                    <Text fW="600" color="#333333">
                      Access
                    </Text>
                  </th>
                  <th scope="col">
                    <Text fW="600" color="#333333">
                      Page
                    </Text>
                  </th>
                  <th scope="col">
                    <Text fW="600" color="#333333">
                      Status
                    </Text>
                  </th>
                  <th scope="col" className="text-center">
                    <Text fW="600" color="#333333">
                      Action
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {teamInfo?.results?.length > 0 ? (
                  teamInfo?.results?.map((team, index) => (
                    <tr key={team.id}>
                      <td className="d-flex flex-column gap-2 ">
                        <Text fW="500" color="#3D5A80" className="name" width="170px">
                          {startCase(team.teammate.split('@')[0])}
                        </Text>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id={`tooltip-${index}`}>{team.teammate ?? ''}</Tooltip>}
                        >
                          <Text fW="500" color="#5B5B5B" width="170px" className="email">
                            {team.teammate}
                          </Text>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <Text fW="500" color="#333333">
                          {startCase(replace(team.access, '_', ' '))}
                        </Text>
                      </td>
                      <td>
                        <Text fW="500" color="#333333">
                          {companyPages.find(({ value }) => value === team?.company_page)?.label}
                        </Text>
                      </td>
                      <td>
                        <Text fW="500" color="#333333">
                          {team.active ? 'Active' : ''}
                        </Text>
                      </td>
                      <td>
                        <div className="d-flex gap-2 align-items-center justify-content-center">
                          <Link fW="500" color="#3D5A80" onClick={() => editTeam(team.id)}>
                            Edit
                          </Link>
                          <span> | </span>
                          <Link fW="500" color="#3D5A80" onClick={() => askDeleteItem(team.id)}>
                            Delete
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="5" align="center">
                      {' '}
                      You don't have any team members added. Please add teammates below.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {addOpen && (
        <div className="row" id="addMemberCompany">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <Text fS="18px" color="#333333">
              {currentTeamId ? 'Update user' : 'Add a user'}
            </Text>
          </div>
          <div className="col-12 col-md-6">
            <label className="field-label">Invite Connection</label>
            <Input type="text" placeholder="Email" value={fields.teamMate} onChange={fieldsOnChange} name="teamMate" />
            {errorFields.teamMate && <span className="text-danger">{errorFields.teamMate}</span>}
          </div>

          <div className="col-12 col-md-6">
            {companyPages.length > 0 ? (
              <>
                <div className="field-label-container">
                  <label className="field-label">Page*</label>
                  <Text fS="16px" color="#333333" onClick={goToCreate} className="field-action">
                    Add New Page
                  </Text>
                </div>

                <Dropdown
                  id={'pages'}
                  name="companyPage"
                  options={companyPages}
                  title={'pages'}
                  handleChange={handleChange}
                  defaultValue="Select a company"
                  selectedValue={fields.companyPage}
                />
              </>
            ) : (
              <p className="mt-2">You don't have any pages created. Please create a page.</p>
            )}

            {errorFields.companyPage && <span className="text-danger">{errorFields.companyPage}</span>}
          </div>
          <div className="col-12">
            <div className="form-group">
              <RadioButton
                id="1"
                label="Super admin"
                value="super_admin"
                changed={radioChangeHandler}
                name="role"
                isSelected={fields.role === 'super_admin'}
              />
              <p className="ms-4 ps-1">
                This role manages everything on the Page. It’s the only role that can edit the Page and manage all
                admins.
              </p>
            </div>
            <div className="form-group">
              <RadioButton
                id="1"
                label="Content admin"
                value="content_admin"
                name="role"
                changed={radioChangeHandler}
                isSelected={fields.role === 'content_admin'}
              />
              <p className="ms-4 ps-1">
                This role posts and manages content, comments as the Page, exports analytics, post jobs, and manage
                applicants
              </p>
            </div>
            <div className="form-group">
              <RadioButton
                id="1"
                label="Hiring admin"
                value="hiring_admin"
                name="role"
                changed={radioChangeHandler}
                isSelected={fields.role === 'hiring_admin'}
              />
              <p className="ms-4 ps-1">This role post jobs, and move applicants through the hiring process.</p>
            </div>
            {errorFields.role && <div className="text-danger">{errorFields.role}</div>}

            <div className="d-flex gap-2 mt-4">
              {currentTeamId ? (
                <Button className="btn btn-secondary fw-normal" variant="secondary" onClick={cancelTeam}>
                  Cancel
                </Button>
              ) : (
                ''
              )}
              <Button disabled={loading} className="btn btn-primary fw-normal" variant="primary" onClick={saveTeam}>
                {loading ? <Spinner /> : <>{currentTeamId ? 'Update Permission' : 'Add new Permission'} </>}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
