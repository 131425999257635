import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import Checkbox from 'components/common/Checkbox';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptProposedNewTime,
  deleteGoogleMeeting,
  deleteOutlookMeeting,
  deleteMeeting,
  getEventDetail,
  proposeNewTime,
  updateAttendeeStatus,
  updateGoogleMeeting,
  updateOutlookMeeting,
  updateMeeting,
} from 'store/slices/meetingSlice';
import { getAllEvent, getAllMeetings, getEventsFromGoogle, getEventsFromOutlook } from 'store/slices/calendarSlice';
import { CheckMark1, QuestionMark, CrossIcon, NewTime, DeleteIcon, Message } from 'components/common/Icons';
import Select from 'react-select';
import { Input, TextArea } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import { MEETING_LOCATION, TIME_FORMATE } from 'data/data';
import Button from 'components/common/Button';
import makeAnimated from 'react-select/animated';
import ModalPopups from './ModalPopups';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'components/common/Modal/Modal';
import Alert from 'components/common/Alert/Alert';
import { joinMeeting } from 'store/slices/meetingSlice';

const EventView = () => {
  const { data: connectionData } = useSelector((state) => state.connection);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { eventDetails } = useSelector((state) => state.meeting);
  const [inviteTo, setInviteTo] = useState([]);
  const [accept, setAccepted] = useState('Accept');
  const [oldInvites, setOldInvites] = useState([]);
  const [newEventSubject, setNewEventSubject] = useState('');
  const [newEventLocation, setNewEventLocation] = useState('builtin');
  const [locationUrl, setLocationUrl] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toggleSeeMoreDescription, setToggleSeeMoreDescription] = useState(true);
  const [toDate, setToDate] = useState();
  const [newEventDescription, setNewEventDescription] = useState('');
  const [waitingRoom, setWaitingRoom] = useState(false);
  const [isInviteeVisible, setIsInviteeVisible] = useState(false);
  const [timeZoneSelected, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(undefined);
  const [popupHeader, setPopupHeader] = useState('');
  const [modelPropData, setModelPropData] = useState(null);
  const [sendUpdateTo, setSendUpdateTo] = useState('UPDATED'); // UPDATED/ALL
  const [updatedMeetingDetails, setUpdatedMeetingDetails] = useState();
  const [toggleSeeMore, setToggleSeeMore] = useState(true);


  // let min = moment().minute();
  // let dateTime = moment()
  //   .add(min > 30 && 1, 'hours')
  //   .minutes(min <= 30 ? 30 : 0)
  //   .format('HH:mm');
  // let endTimeMin = moment(dateTime, 'HH:mm').add(30, 'minutes').format('HH:mm');

  const [fromTime, setFromTime] = useState(moment.utc(eventDetails?.start_time).format('HH:mm'));
  const [toTime, setToTime] = useState(moment.utc(eventDetails?.end_time).format('HH:mm'));
  const [allDay, setAllDay] = useState();
  const [attendeesList, setAttendeesList] = useState();
  const setAllDayMeeting = (e) => {
    const time = moment('12:00 AM', 'h:mm A').format('HH:mm');
    const { checked } = e.target;
    if (checked) {
      setAllDay(true);
      setFromTime(time);
      setToTime(time);
    } else {
      setAllDay(false);
      setFromTime(moment(eventDetails?.start_time).format('HH:mm'));
      setToTime(moment(eventDetails?.end_time).format('HH:mm'));
    }
  };

  useEffect(() => {
    if (eventDetails) {
      let selectedAttendees = eventDetails?.attendees?.map((attendees) => {
        let fullName = attendees?.first_name + ' ' + attendees?.last_name;
        return { value: attendees?.id, label: fullName };
      });
      setOldInvites(selectedAttendees?.map((a) => a.value));
      setInviteTo(selectedAttendees);
      setNewEventSubject(eventDetails?.subject);
      setNewEventLocation(eventDetails?.location);
      setLocationUrl(eventDetails?.location_url);
      setFromDate(new Date(eventDetails?.start_time));
      setToDate(new Date(eventDetails?.end_time));
      setFromTime(moment(eventDetails?.start_time).format('HH:mm'));
      setToTime(moment(eventDetails?.end_time).format('HH:mm'));
      setNewEventDescription(eventDetails?.description);
      setTimeZone(eventDetails?.timezone);
      setAllDay(eventDetails?.all_day_meeting);
      if (eventDetails?.attendees && eventDetails?.attendees?.length > 0) {
        setIsInviteeVisible(true);
      }
      if (eventDetails?.attendees && eventDetails?.attendees?.length > 0) {
        let finalData = [];
        const eventAttendees = eventDetails?.attendees;
        const grouped = groupBy(eventAttendees, (item) => item.status);

        if (grouped) {
          for (const [key, value] of grouped.entries()) {
            if (value && value.length) {
              let finalObject = Object.assign({ attendeeStatus: key, list: value });
              finalData.push(finalObject);
            }
            if(key=='ACCEPTED'){
              setAcceptedInvited(true);
            }
          }
        }
        setAttendeesList(finalData);
      }
    } else {
      navigate('/profile/calendar');
    }
  }, [eventDetails]);

  const handleSelect = (data) => {
    setInviteTo(data);
  };

  const formSubmitHandler = (is_draft) => {
    let attendees = [];
    for (let i = 0; i < inviteTo.length; i++) {
      attendees.push(inviteTo[i].value);
    }
    const addedMembers = attendees?.filter((member) => !oldInvites.includes(member));
    const removedMembers = oldInvites?.filter((member) => !attendees.includes(member));
    let meetingID = parseInt(Math.random().toFixed(6).replace('0.', ''));
    let startTime, endTime;
    startTime = moment
      .tz(`${moment(fromDate).format('YYYY-MM-DD')}T${fromTime}`, timeZoneSelected)
      .utc()
      .format();
    endTime = moment
      .tz(`${moment(toDate).format('YYYY-MM-DD')}T${toTime}`, timeZoneSelected)
      .utc()
      .format();

    const meeting = {
      id: eventDetails?.id,
      add_attendees: addedMembers,
      remove_attendees: removedMembers,
      subject: newEventSubject,
      location: newEventLocation,
      location_url: locationUrl,
      start_time: moment
        .tz(`${moment(fromDate).format('YYYY-MM-DD')}T${fromTime}`, timeZoneSelected)
        .utc()
        .format(),
      end_time: moment
        .tz(`${moment(toDate).format('YYYY-MM-DD')}T${toTime}`, timeZoneSelected)
        .utc()
        .format(),
      timezone: timeZoneSelected,
      description: newEventDescription,
      meeting_id: newEventLocation === 'builtin' ? parseInt(`6013${meetingID}`) : null,
      is_draft: is_draft,
      all_day_meeting: allDay,
    };

    const googleMeeting = {
      id: eventDetails.id,
      summary: newEventSubject,
      location: newEventLocation,
      description: newEventDescription,
      start: {
        dateTime: startTime,
        timeZone: timeZoneSelected,
      },
      end: {
        dateTime: endTime,
        timeZone: timeZoneSelected,
      },
      attendees: attendees,
      reminders: {
        useDefault: true,
      },
    };
    let outlookMeeting = null;

    if (userProfileInfo?.outlook_calendar_email) {
      outlookMeeting = {
        id: eventDetails.id,
        subject: newEventSubject,
        location: {
          displayName: newEventLocation,
        },
        body: {
          contentType: 'TEXT',
          content: newEventDescription,
        },
        start: {
          dateTime: startTime,
          timeZone: timeZoneSelected,
        },
        end: {
          dateTime: endTime,
          timeZone: timeZoneSelected,
        },
        attendees: attendees,
      };
    }

    if (addedMembers.length > 0 || removedMembers.length > 0) {
      setUpdatedMeetingDetails(meeting);
      topBtnAction('sendupdate');
      meeting.attendees_to_notify = sendUpdateTo;
    } else {
      dispatch(updateMeeting(meeting)).then((res) => {
        dispatch(getAllMeetings()).then(() => {
          dispatch(updateGoogleMeeting(googleMeeting)).then(() => {
            dispatch(updateOutlookMeeting(outlookMeeting)).then(() => {
              dispatch(getEventsFromGoogle());
              dispatch(getEventsFromOutlook());
              navigate('/profile/calendar');
            });
          });
        });
      });
    }
  };

  const ary = Intl.supportedValuesOf('timeZone');

  const connectionOptions = useMemo(
    () =>
      connectionData?.results?.map((t) => {
        let fullName = t?.first_name + ' ' + t?.last_name;
        return { value: t.id, label: fullName };
      }),
    [connectionData],
  );
  const gotoUrl = (page) => {
    navigate(`/profile/${page}`);
  };

  const animatedComponents = makeAnimated();

  const timeZone = ary.map((item) => {
    return { value: item, label: item };
  });

  const topBtnAction = (type) => {
    if (type === 'propose') {
      setModelPropData({
        setFromDate,
        setFromTime,
        setToDate,
        setToTime,
        setTimeZone,
        setAllDay,
        allDay,
        timeZoneSelected,
        fromDate,
        fromTime,
        toDate,
        toTime,
      });
    }
    setModalType(type);
    setModalOpen((prevState) => !prevState);
    setPopupHeader(heading(type));
  };

  // useEffect(() => {
  //   console.log(allDay, fromDate, fromTime, toDate, toTime);
  // }, [allDay, fromDate, fromTime, toDate, toTime]);

  const heading = (type) => {
    switch (type) {
      case 'accept':
        return <h5 className="modal-title">Accepted</h5>;
      case 'tentative':
        return <h5 className="modal-title">Tentative</h5>;
      case 'decline':
        return <h5 className="modal-title">Decline</h5>;
      case 'propose':
        return <h5 className="modal-title">Propose new time</h5>;
      case 'sendupdate':
        return <h5 className="modal-title">Send Update to Attendees</h5>;
      default:
        break;
    }
  };

  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const setStartTime = (e) => {
    const { value } = e?.target;
    let endTime = moment(value, 'HH:mm').add(30, 'minutes').format('HH:mm');
    setFromTime(value);
    setToTime(endTime);
  };
  const setEndTime = (e) => {
    const { value } = e?.target;
    setToTime(value);
  };

  const handleEventDelete = () => {
    dispatch(deleteMeeting(eventDetails?.id)).then((res) => {
      dispatch(deleteGoogleMeeting('bridge' + eventDetails?.id)).then(() => {
        dispatch(deleteOutlookMeeting('bridge' + eventDetails?.id)).then(() => {
          dispatch(getAllMeetings());
          dispatch(getEventsFromGoogle());
          dispatch(getEventsFromOutlook());
          dispatch(getAllEvent()).then((res) => {
            navigate('/profile/calendar');
          });
        });
        // dispatch(getAllMeetings());
        // dispatch(getAllEvent()).then((res) => {
        //   navigate('/profile/calendar');
        // });
      });
    });
  };
  const updateStatus = (status, data) => {
    if (data && status.toLowerCase() === 'sendupdate') {
      setSendUpdateTo(data.value);
      dispatch(
        updateMeeting({
          ...updatedMeetingDetails,
          attendees_to_notify: data.value,
        }),
      ).then((res) => {
        dispatch(getAllMeetings());
        navigate('/profile/calendar');
      });
    } else if (data && status.toLowerCase() === 'proposed') {
      const payload = {
        proposed_start_time: moment
          .tz(`${moment(data.fromDate).format('YYYY-MM-DD')}T${data.fromTime}`, data.timeZoneSelected)
          .utc()
          .format(),
        proposed_end_time: moment
          .tz(`${moment(data.toDate).format('YYYY-MM-DD')}T${data.toTime}`, data.timeZoneSelected)
          .utc()
          .format(),
      };
      dispatch(proposeNewTime({ meetingId: eventDetails.id, payload: payload })).then((res) => {
        if (res) {
          setModalOpen(false);
          setModalType(undefined);
          dispatch(getEventDetail(eventDetails.id));
        }
      });
    } else {
      const attendeeDetail = eventDetails?.attendees.find((item) => item.user_email === userProfileInfo?.email);
      const payload = {
        meetingId: eventDetails?.id,
        id: attendeeDetail?.id,
        status: status,
      };
      dispatch(updateAttendeeStatus(payload)).then((res) => {
        if (res) {
          dispatch(getEventDetail(eventDetails.id));
          setModalOpen(false);
          setModalType(undefined);
          if (status.toLowerCase() === 'declined') {
            dispatch(getAllMeetings());
            navigate('/profile/calendar');
          }
        }
      });
    }
  };

  const groupBy = (list, keyGetter) => {
    if (list) {
      const map = new Map();
      let collection = [];
      list.forEach((item) => {
        const key = keyGetter(item);
        collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });

      return map;
    }
  };

  const isSamePerson = (id) => {
    return userProfileInfo?.id === id;
  };

  const alertVarient = (key) => {
    switch (key) {
      case 'DECLINED':
        return { variant: 'danger', icon: 'crossicon' };
      case 'ACCEPTED':
        return { variant: 'success', icon: 'checkmark' };
      case 'TENTATIVE':
        return { variant: 'warning', icon: 'questionmark' };
      default:
        return { variant: 'info', icon: 'newtime' };
    }
  };

  const iconSelection = (key) => {
    switch (key) {
      case 'checkmark':
        return <CheckMark1 color={'#037F29'} />;
      case 'questionmark':
        return <QuestionMark />;
      case 'crossicon':
        return <CrossIcon color={'#E41C19'} className="me-1" />;
      default:
        return <NewTime />;
    }
  };

  const setProposedTime = (id) => {
    dispatch(acceptProposedNewTime({ meetingId: eventDetails.id, attendeeId: id })).then((res) => {
      setAccepted('Accepted');
    });
  };

  const [view, setView] = useState(true);

  const [acceptedInvited, setAcceptedInvited] = useState(false);


  const attendeeAlerts = (attendeeList, host) => {
    return (
      attendeeList &&
      attendeeList.map((item, index) => {
        const attendeeStatus = item?.attendeeStatus;
        const { variant, icon } = alertVarient(attendeeStatus);
        if (host) {
          if(attendeeStatus=='ACCEPTED'){
              return (
                <div className="alert alert-success mt-3 mb-3">
                  <div style={{height:'auto', overflowY:`${toggleSeeMore? 'hidden' : 'scroll'} `}}>
                  {item &&
                    item?.list?.length &&
                    item?.list.slice(0,item?.list?.length).map((subitem, index) => (
                      <>
                      <div>
                      {isSamePerson(subitem?.attendee_details?.id)
                        ? 'You'
                        : `${subitem?.first_name} ${subitem?.last_name}`}
                      {iconSelection(icon)}
                      {attendeeStatus?.charAt(0).toUpperCase() + attendeeStatus.slice(1).toLowerCase()} on{' '}
                      {moment(subitem.updated_at).format('dddd, MMMM DD, YYYY h:mm A')}
                      </div>
                      </>
                    )
                  )}
                  </div>
                  {
                    item?.list?.length > 2 && (
                        toggleSeeMore ? (
                            <div style={{margin:'10px 0'}} onClick={()=>setToggleSeeMore(false)}>{`+ ${item?.list?.length-2} other people - See More`}</div>
                        ) :(
                          <div style={{margin:'10px 0'}} onClick={()=>setToggleSeeMore(true)}>{`See Less`}</div>
                        )
                    )
                  }
                </div>
              )
          }
          return (
            attendeeStatus !== 'INVITED' && (
              <>
                {item &&
                  item?.list?.length &&
                  item?.list.slice(0, view ? 2 : item?.list?.length).map((subitem, index) => (
                    <>
                      {/* {subitem?.proposed_dt_status && (
                        <Alert type={'info'} className="position-relative">
                          <div className="align-items-center">
                            {isSamePerson(subitem?.attendee_details?.id)
                              ? 'You'
                              : `${subitem?.first_name} ${subitem?.last_name}`}
                            {iconSelection('newtime')}
                            {subitem?.proposed_dt_status &&
                              `proposed a new time
                                  ${moment(subitem?.proposed_start_time).format('ddd, MMM DD, YYYY hh:mm A')} to
                                  ${moment(subitem?.proposed_end_time).format('hh:mm A')}`}
                            {subitem?.proposed_dt_status && (
                              // !moment(subitem?.proposed_start_time).isSame(eventDetails?.start_time)
                              <span
                                className="text-success font bold cursor-pointer ms-3"
                                onClick={() => setProposedTime(subitem?.attendee_details?.id)}
                              >
                                {accept}
                              </span>
                            )}
                          </div>
                        </Alert>
                      )} */}
                      {attendeeStatus && (
                        <Alert type={variant} className="position-relative">
                          <div className="align-items-center">
                            {isSamePerson(subitem?.attendee_details?.id)
                              ? 'You'
                              : `${subitem?.first_name} ${subitem?.last_name}`}
                            {iconSelection(icon)}
                            {attendeeStatus === 'PROPOSED' || attendeeStatus.includes('PROPOSED') ? (
                              <>
                                proposed a new time 
                                {moment(subitem?.proposed_start_time).format('ddd, MMM DD, YYYY hh:mm A')} to 
                                {moment(subitem?.proposed_end_time).format('hh:mm A')}
                                {subitem?.proposed_dt_status && (
                                  // !moment(subitem?.proposed_start_time).isSame(eventDetails?.start_time
                                  <span
                                    className="text-success font bold cursor-pointer ms-3"
                                    onClick={() => setProposedTime(subitem?.attendee_details?.id)}
                                  >
                                    {accept}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {attendeeStatus?.charAt(0).toUpperCase() + attendeeStatus.slice(1).toLowerCase()} on{' '}
                                {moment(subitem.updated_at).format('dddd, MMMM DD, YYYY h:mm A')}
                              </>
                            )}
                          </div>
                          {subitem.status_message ? (
                            <Message
                              width={15}
                              height={15}
                              onClick={() => {
                                setModelPropData(subitem.status_message);
                                topBtnAction('message');
                              }}
                              className="cursor-pointer"
                            />
                          ) : (
                            <></>
                          )}
                        </Alert>
                      )}
                    </>
                  ))}
              </>
            )
          );
        } else {
          return attendeeStatus !== 'INVITED'
            ? item &&
                item?.list?.length &&
                item?.list.map((subitem, index) =>
                  isSamePerson(subitem?.attendee_details?.id) ? (
                    <>
                      {/*
                      {subitem?.proposed_dt_status && (
                        <Alert type={'info'}>
                          <div className="align-items-center">
                            <strong>Response </strong>
                            {iconSelection('newtime')}
                            // !moment(subitem?.proposed_start_time).isSame(eventDetails?.start_time) 
                            proposed a new time{' '}
                            {moment(subitem?.proposed_start_time).format('ddd, MMM DD, YYYY hh:mm A')} to{' '}
                            {moment(subitem?.proposed_end_time).format('hh:mm A')}
                          </div>
                        </Alert>
                      )}
                      */}
                      {attendeeStatus && (
                        <Alert type={variant}>
                          <div className="align-items-center">
                            <strong>Response </strong>
                            {iconSelection(icon)}
                            {attendeeStatus === 'PROPOSED' || attendeeStatus.includes('PROPOSED') ? (
                              <>
                                proposed a new time {' '}
                                {moment(subitem?.proposed_start_time).format('ddd, MMM DD, YYYY hh:mm A')} to {' '}
                                {moment(subitem?.proposed_end_time).format('hh:mm A')}
                              </>
                            ) : (
                              <>
                                {attendeeStatus?.charAt(0).toUpperCase() + attendeeStatus.slice(1).toLowerCase()} on {' '}
                                {moment(subitem.updated_at).format('dddd, MMMM DD, YYYY h:mm A')}
                              </>
                            )}
                          </div>
                        </Alert>
                      )}
                    </>
                  ) : (
                    <></>
                  ),
                )
            : item &&
                item?.list?.length &&
                item?.list.map(
                  (subitem, index) =>
                    isSamePerson(subitem?.attendee_details?.id) && <Alert type={variant}>Please respond</Alert>,
                );
        }
      })
    );
  };

  const handleJoinMeeting = (meeting) => {
    if (meeting)
      if (userProfileInfo?.id === meeting.created_by.id) {
        // if person is host or attendee
        dispatch(
          joinMeeting({
            created_by: userProfileInfo,
            meetingId: meeting?.meeting_id,
            attendees: null,
            isWaiting: meeting?.waiting_room,
          }),
        );
      } else {
        dispatch(
          joinMeeting({
            created_by: null,
            meetingId: meeting?.meeting_id,
            attendees: userProfileInfo,
            isWaiting: meeting?.waiting_room,
          }),
        );
      }
  };
  return (
    <>
      {userProfileInfo?.id === eventDetails?.created_by?.id ? (
        <>
          <div className="form">
            <header>
              <h3>{isDelete ? 'Cancel Meeting' : 'Update Meeting'}</h3>
              <h4 className="invite-btn" onClick={() => setIsInviteeVisible((preState) => !preState)}>
                Invite Attendees
              </h4>
            </header>
            {attendeeAlerts(attendeesList, true)}
            {isInviteeVisible && (
              <>
                <label className="label-text">To</label>
                <Select
                  styles={{
                    container: (style, action) => ({
                      ...style,
                      width: '100%',
                      borderRadius: '8px',
                      marginBottom: '1rem',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '8px',
                      border: '1px solid #d9d9d9',
                      width: '100%',
                      background: '#F8F8F8',
                      padding: '0.313rem .25rem 0.313rem 0.5rem',
                    }),
                    indicatorsContainer: (provide, state) => ({
                      ...provide,
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: 'none', // hide the down caret icon
                    }),
                  }}
                  value={inviteTo}
                  isSearchable={true}
                  options={connectionOptions}
                  placeholder="To"
                  onChange={handleSelect}
                  components={animatedComponents}
                  isMulti
                />
              </>
            )}
            <label className="label-text">Subject</label>
            <Input
              placeholder="Subject"
              onChange={(event) => setNewEventSubject(event.target.value)}
              value={newEventSubject}
            />

            <label className="label-text">Location</label>
            <Dropdown
              id={'location'}
              name="location"
              options={MEETING_LOCATION}
              title={'location'}
              selectedValue={newEventLocation}
              handleChange={(event) => setNewEventLocation(event.target.value)}
            />

            {newEventLocation === 'provide' && (
              <>
                <label className="label-text">Provide a Location</label>
                <Input
                  placeholder="Provide a Location"
                  onChange={(event) => setLocationUrl(event.target.value)}
                  value={locationUrl}
                />
              </>
            )}
            {newEventLocation === 'builtin' && (
              <>
                <div className="row">
                  <div className="col-12">
                    <Checkbox
                      value={true}
                      className="m-0 passcode"
                      name="Passcode"
                      checked={true}
                      disabled
                      label="Passcode"
                    />
                    <span className="buitinSpan">
                      Only user who have the invite link or passcode can join the meeting
                    </span>
                  </div>
                  <div className="col-12">
                    <Checkbox
                      value={waitingRoom}
                      checked={waitingRoom}
                      onChange={() => setWaitingRoom(!waitingRoom)}
                      name="Waiting Room"
                      label="Waiting Room"
                      className="m-0"
                    />
                    <span className="buitinSpan">Only user admitted by the host can join the meeting</span>
                  </div>
                </div>
              </>
            )}
            <div className="form__first-coloumn my-3">
              <div className="date-wrapper form-group mb-0">
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  minDate={new Date()}
                  placeholderText="Select a date after 5 days ago"
                  className="date-selector form-control"
                  dateFormat="EEE, MMMM d, yyyy"
                />
              </div>
              <Dropdown
                id={'fromTime'}
                name="fromTime"
                options={TIME_FORMATE}
                title={'fromTime'}
                selectedValue={fromTime}
                handleChange={setStartTime}
                containerClass="m-0"
                disabled={allDay}
              />
              <div>
                <Dropdown
                  id={'timeZone'}
                  name="timeZone"
                  options={timeZone}
                  title={'timeZone'}
                  selectedValue={timeZoneSelected}
                  handleChange={(event) => setTimeZone(event.target.value)}
                  containerClass="m-0"
                />
              </div>
            </div>
            <div className="form__first-coloumn mb-3">
              <div className="date-wrapper form-group mb-0">
                <DatePicker
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  minDate={new Date()}
                  className="date-selector form-control"
                  dateFormat="EEE, MMMM d, yyyy"
                />
              </div>
              <Dropdown
                id={'toTime'}
                name="toTime"
                options={TIME_FORMATE}
                title={'toTime'}
                selectedValue={toTime}
                handleChange={setEndTime}
                containerClass="m-0"
                disabled={allDay}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox label="All Day Meeting" checked={allDay} className="m-0" onChange={setAllDayMeeting} />
              </div>
            </div>
            {newEventLocation === 'builtin' && eventDetails?.meeting_id && (
              <div className="row mt-4 align-items-center">
                <div className="col-xl-2 mb-3">
                  <strong>Meeting ID</strong>
                </div>
                <div className="col-xl-10 mb-3">
                  {eventDetails?.meeting_id}{' '}
                  <Button className={'h-36 ms-3'} onClick={() => handleJoinMeeting(eventDetails)}>
                    Join Meeting
                  </Button>
                </div>
              </div>
            )}
            <TextArea
              rows={4}
              placeholder="Description"
              onChange={(event) => setNewEventDescription(event.target.value)}
              value={newEventDescription}
            />
            {/* <span>Meeting ID:</span> */}
            <div className="actions">
              <div>
                {!isDelete && (
                  <Button variant="btn-danger" className="h-36" onClick={handleDelete}>
                    {acceptedInvited ? 'Cancel Meeting' :'Delete Meeting'} 
                  </Button>
                )}
              </div>
              <div className="text-end">
                <Button
                  variant="btn-secondary"
                  className="h-36 me-2"
                  onClick={() => {
                    isDelete ? setIsDelete(!isDelete) : gotoUrl('calendar');
                  }}
                >
                  Cancel
                </Button>
                {((isInviteeVisible && inviteTo) || newEventSubject.length >= 0) && (
                  <Button
                    variant="btn-primary"
                    onClick={
                      isDelete
                        ? handleEventDelete
                        : newEventSubject.length >= 0 && inviteTo.length <= 0
                        ? () => formSubmitHandler(true)
                        : () => formSubmitHandler(false)
                    }
                    className={`${inviteTo.length <= 0 ? 'h-36' : 'h-36'}`}
                    disabled={newEventSubject.length <= 0}
                  >
                    {isDelete
                      ? 'Send Cancellation'
                      : newEventSubject.length >= 0 && inviteTo.length <= 0
                      ? 'Save'
                      : 'Send & Update'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {attendeeAlerts(attendeesList, false)}
            <div className="attendee-action">
              <div className="attendee-options">
                <Button className={'action-btn'} onClick={() => topBtnAction('accept')}>
                  <CheckMark1 color={'#037F29'} /> Accept
                </Button>
                <Button className={'action-btn'} onClick={() => topBtnAction('tentative')}>
                  <QuestionMark /> Tentative
                </Button>
                <Button className={'action-btn'} onClick={() => topBtnAction('decline')}>
                  <CrossIcon color={'#E41C19'} className="me-1" />
                  Decline
                </Button>
                <Button className={'action-btn'} onClick={() => topBtnAction('propose')}>
                  <NewTime /> Propose New Time
                </Button>
              </div>
              <div className="">
                {!isDelete && (
                  <Button className={'action-btn delete-btn'} onClick={handleDelete}>
                    <p className="mx-2">Delete</p>
                    <DeleteIcon color={'#E41C19'} />
                  </Button>
                )}
              </div>
            </div>

            <h5 className="my-3">
              <strong>{isDelete ? 'Delete Meeting' : newEventSubject}</strong>
            </h5>
            {/* <div className="my-3 meeting-title">
              <Alert>
                Please Respond
                <p>Sola Daves Proposed new time Friday, January 20, 2023 6:00 AM-6:30 AM</p>
              </Alert>
            </div> */}
            <div className="row border-bottom">
              <div className="col-xl-2 mb-3">
                <strong>Host</strong>
              </div>
              <div className="col-xl-10 mb-3">{eventDetails?.created_by?.first_name}</div>
              <div className="col-xl-2 mb-3">
                <strong>Attendees</strong>
              </div>
              <div className="col-xl-10 mb-3">
                {inviteTo?.map((item, index) => {
                  return (
                    <span>
                      {item.label}
                      {(inviteTo?.length === index + 1) ? '. ' : ', '}
                    </span>
                  );
                })}
              </div>
              <div className="col-xl-2 mb-3">
                <strong>When</strong>
              </div>
              <div className="col-xl-10 mb-3">
                {moment(eventDetails?.start_time).format('ddd, MMM DD, YYYY hh:mm A')} to{' '}
                {moment(eventDetails?.end_time).format('hh:mm A')}
              </div>
              <div className="col-xl-2 mb-3">
                <strong>Location</strong>
              </div>
              <div className="col-xl-10 mb-3">
                {eventDetails?.location === 'builtin' ? (
                  <>{MEETING_LOCATION.find((item) => item.value === eventDetails?.location)?.label}</>
                ) : (
                  <a className="col-xl-10 mb-3" href={eventDetails?.location_url}>{eventDetails?.location_url}</a>
                )}
              </div>
              <div className="col-xl-2 mb-3">
                <strong>Description</strong>
              </div>
              <div style={{whiteSpace: "pre-line"}} className={`col-xl-10 mb-3 ${eventDetails?.description?.length > 500 && toggleSeeMoreDescription && 'seemore' }`}>{eventDetails?.description}</div>
              {
                eventDetails?.description?.length > 500 && (
                  <>
                  <div className='col-xl-2 mb-3'>
                  </div>
                    {
                      toggleSeeMoreDescription ? (
                        <div onClick={()=>setToggleSeeMoreDescription(false)} className='col-xl-10 mb-3 seemoreCursor'>See More</div>    
                      ) : (
                        <div onClick={()=>setToggleSeeMoreDescription(true)} className='col-xl-10 mb-3 seemoreCursor'>See Less</div>    
                      )
                    }
                  </>
                )
        }
            </div>
            {eventDetails?.meeting_id && (
              <div className="row mt-4 align-items-center">
                <div className="col-xl-2 mb-3">
                  <strong>Meeting ID</strong>
                </div>
                <div className="col-xl-10 mb-3">
                  {eventDetails?.meeting_id}{' '}
                  <Button className={'h-36 ms-3'} onClick={() => handleJoinMeeting(eventDetails)}>
                    Join Meeting
                  </Button>
                </div>
              </div>
            )}
            {isDelete && (
              <div className="text-end">
                <Button variant="btn-secondary" className="h-36 w-64 me-2" onClick={handleDelete}>
                  Cancel
                </Button>
                {((isInviteeVisible && inviteTo) || newEventSubject.length >= 0) && (
                  <Button
                    variant="btn-primary"
                    // onClick={}
                    className={`${inviteTo.length <= 0 ? 'h-36' : 'h-36'}`}
                    disabled={newEventSubject.length <= 0}
                  >
                    Delete Meeting
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {modalOpen && (
        <Modal onClose={topBtnAction} heading={popupHeader} className={'meeting-popup'}>
          <ModalPopups
            modalType={modalType}
            setModalOpen={setModalOpen}
            updateStatus={updateStatus}
            data={modalType === 'sendupdate' ? sendUpdateTo : modelPropData}
          />
        </Modal>
      )}
    </>
  );
};
export default EventView;
