import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';

import banner from 'assets/profiles/banner/banner.png';
import myBridge from 'assets/icons/myBridge.svg';

import { Text } from 'components/StyledComponents';
import { HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import { Camera } from 'components/common/Icons';
import Loader from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import ImageUploader from 'components/ImageUploader';
import { MyBridgeAvatar } from 'components/common/Avatar';

import { patchCompanyInfo } from 'store/actions/companyPages.Action';

import './styles.css';

const Banner = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companyPageDetail: companyDetails } = useSelector((state) => state.companyPageReducer);

  const { setOpenTab, openTab, isPublic } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState();

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const uploadCropedImg = async (img) => {
    const payload = {
      id: companyDetails.id,
    };

    if (cropType === 'profilePhoto') {
      payload['image'] = img;
    } else {
      payload['banner_image'] = img;
    }

    await dispatch(patchCompanyInfo(payload));
    setModalOpen(false);
  };

  const USER_NAME = companyDetails?.name;

  const slug = companyDetails?.slug;

  const gotoPublicProfile = () => {
    navigate(`/c/${slug}/overview`);
  };

  let BgImage = companyDetails?.banner_image;

  if (!BgImage || BgImage.includes('banner-placeholder.png')) {
    BgImage = banner;
  }

  const customCSS = {
    imgBg: css`
      background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%), url(${BgImage}), #ffffff;
      background-size: cover;
    `,
  };

  return (
    <section>
      <div className="bordered-container">
        <div className={`Profile_Banner ${cx(customCSS.imgBg)}`}>
          <div className="banner p-0">
            {BgImage ? <img src={BgImage} className="img-fluid" alt="banner" /> : <Loader secondary />}

            <button type="button" className="btn btn-sm edit-banner" onClick={() => handlePopups('bannerPhoto')}>
              <label htmlFor="file-banner">
                <Camera width={20} height={20} />
                <span> Upload Banner </span>
              </label>
            </button>
          </div>
        </div>

        <div className="container" style={{ position: 'relative' }}>
          <div className="user">
            <div className="avatar company-avtar">
              <MyBridgeAvatar
                icon={companyDetails?.image}
                name={USER_NAME ? USER_NAME : 'Company'}
                size={120}
                className="company-avatar"
                isCompany={true}
              />
              {!isPublic && (
                <div className="upload company-upload" onClick={() => handlePopups('profilePhoto')}>
                  <div className="avatar_upload">
                    <label htmlFor="file-input">
                      <Camera width={20} height={20} color={'#000000'} />
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="left">
              <div className="about">
                <div className="info">
                  <Text fW={600} fS="18px" color="#5B5B5B">
                    {companyDetails?.name}
                  </Text>
                </div>

                <div className="personal_info">
                  <Text color="#868585">
                    {companyDetails?.address && <> {companyDetails?.address}</>}
                    {companyDetails?.state_region && <> | {companyDetails?.state_region}</>}
                    {companyDetails?.country && <>, {companyDetails?.country}</>}
                  </Text>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="mybridge_count mb-3">
                <div className="count">
                  <div className="circle">
                    {companyDetails?.follower_count > 0 && (
                      <>
                        <img src={myBridge} className="icon" alt="circle" />
                        <Text fS="16px" color="#868585">
                          {companyDetails?.follower_count}
                        </Text>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="actions mt-2">
                <button
                  type="button"
                  className="btn btn-sm profile-action-btn button-color"
                  onClick={gotoPublicProfile}
                >
                  <span> View as public </span>
                </button>
                {/* <button
                  type="button"
                  className="btn btn-sm profile-action-btn  button-color links"
                  onClick={console.log}
                >
                  <span> Save to pdf </span>
                </button> */}
              </div>
            </div>
          </div>

          <div className="container">
            <HorizontalTabs className="company-tabs-item">
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`company/page/${companyDetails?.id}`} to="home">
                Home
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`company/page/${companyDetails?.id}`} to="services">
                Services
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`company/page/${companyDetails?.id}`} to="jobs">
                Jobs
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`company/page/${companyDetails?.id}`} to="followers">
                Followers
              </Tab>
            </HorizontalTabs>
          </div>
        </div>
      </div>

      {modalOpen && (
        <Modal onClose={handlePopups} className={'crop-modal'}>
          <ImageUploader cropType={cropType} uploadCropedImg={uploadCropedImg} handlePopups={handlePopups} />
        </Modal>
      )}
    </section>
  );
};
export default Banner;
