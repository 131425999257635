import { FolderClosed, GifIcon } from "components/common/Icons";
import { AIIcon, AVIIcon, CSVIcon, DOCIcon, FILEIcon, JPGIcon, MP3Icon, MP4Icon, PDFIcon, PNGIcon, PPTIcon, PSDIcon, WAVIcon, XLSIcon, ZIPIcon } from "components/common/Icons/FileIcons";

const ICONS_MAP = {
    png: PNGIcon,
    pdf: PDFIcon,
    doc: DOCIcon,
    docx: DOCIcon,
    xls: XLSIcon,
    xlsx: XLSIcon,
    jpg: JPGIcon,
    jpeg: JPGIcon,
    gif: GifIcon,
    csv: CSVIcon,
    mp4: MP4Icon,
    avi: AVIIcon,
    ppt: PPTIcon,
    zip: ZIPIcon,
    mp3: MP3Icon,
    wav: WAVIcon,
    ai: AIIcon,
    psd: PSDIcon
}

/**
 * Returns extension specific icon
 * @param {*} param0 
 * @param {*} props 
 * @returns 
 */
export const getIconByExtension = ({ extension }, props) => {
    if (extension) {

        const Comp = ICONS_MAP[extension];
        if (Comp) {
            return <Comp {...props} />
        } else {
            return <FILEIcon {...props} />
        }
    } else return <FolderClosed {...props} />
}
