import { Pictures } from 'components/common/Icons';
import { Input, TextArea } from 'components/common/Input';
import React, { useEffect, useState } from 'react';
import ImageUploader from 'components/ImageUploader';
import Modal from 'components/common/Modal/Modal';

const AddService = (props) => {
  const { setData, inputFile, uploadBanner, serviceDetails } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState();
  const [servicesObj, setServicesObj] = useState(serviceDetails);

  // const setFormattedContent = React.useCallback(
  //   (text) => {
  //     setFieldValue('introduction', text.slice(0, 200));
  //   },
  //   [200, values?.introduction],
  // );
  const uploadCropedImg = (img) => {
    if (props.setServiceDetails) {
      props.setServiceDetails({
        ...servicesObj,
        image: img,
      });
    } else {
      setData(img);
    }
    setServicesObj({
      ...servicesObj,
      image: img,
    });
    setModalOpen(false);
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === 'desc') {
      updatedValue = value.slice(0, 200);
    }
    setServicesObj({
      ...servicesObj,
      [name]: updatedValue,
    });
    if (props.setServiceDetails) {
      props.setServiceDetails({
        ...servicesObj,
        [name]: updatedValue,
      });
    } else {
      setData(e);
    }
  };

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };
  return (
    <>
      <div>
        <p></p>
        <div className="services-img cursor-pointer" onClick={() => handlePopups('profilePhoto')}>
          {servicesObj?.image && (
            <img
              src={servicesObj?.image?.name ? URL.createObjectURL(servicesObj?.image) : servicesObj?.image}
              alt={servicesObj?.image?.name || null}
            />
          )}
          <div className={servicesObj?.image ? 'upload added-img' : 'upload'}>
            <Pictures />
            {servicesObj?.image ? null : (
              <>
                <br />
                Upload Photo
              </>
            )}
          </div>
          <input type="file" name="image" ref={inputFile} style={{ display: 'none' }} onChange={setData} />
        </div>
      </div>
      <Input placeholder={'Service Name'} name="name" onChange={handleData} value={servicesObj?.name} />
      <TextArea
        placeholder={'Service Details'}
        className={'serviceDetail'}
        name="desc"
        onChange={handleData}
        value={servicesObj?.desc}
      />
      <p className="limit-indicator">
        {servicesObj?.desc ? servicesObj?.desc.length : 0}/{200}
      </p>
      {modalOpen && (
        <Modal onClose={handlePopups} className={cropType === 'profilePhoto' ? 'crop-modal image' : 'crop-modal'}>
          <ImageUploader
            ratio={cropType === 'bannerPhoto' ? 193 / 72 : null}
            cropType={cropType}
            uploadCropedImg={uploadCropedImg}
            handlePopups={handlePopups}
          />
        </Modal>
      )}
    </>
  );
};

export default AddService;
