import { MyBridgeCompoanyAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import { Brifecase, Currency, Industry } from 'components/common/Icons';
import { CURRENCY } from 'data/data';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyJob, getJobsDetail } from 'store/actions/Job.Action';
import JobSearchHead from '../searchHead';
import { Link, useParams } from 'react-router-dom';
import SectionLoader from 'components/common/Loader/sectionLoader';
// import JobListingHeading from "./JobListingHeading";

const JobDetails = () => {
  let { slug } = useParams();
  const { jobDetail, loading, jobApplySuccess } = useSelector((state) => state.jobReducer);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const jobId = slug?.split('-')?.pop();

  useEffect(() => {
    if (!jobDetail.length) {
      // let jdi = localStorage.getItem('JDI');
      dispatch(getJobsDetail(jobId));
    }
  }, []);
  const getCurrencyIcon = (name) => {
    let data = CURRENCY.find((item) => item.value === name);
    return data?.symbol;
  };

  const applySelectedJob = (jobId) => {
    const payload = {
      candidate_id: userProfileInfo?.id,
      jobs_id: jobId,
      status: 'APPLIED',
    };
    dispatch(applyJob(payload));
  };
  // const saveJob = (jobId) => {
  //   const payload = {
  //     user_id: userProfileInfo?.id,
  //     job_id: jobId,
  //   };
  //   dispatch(saveJobs(payload));
  // };
  return (
    <React.Fragment>
      <JobSearchHead search={false} />
      <div className="container mt-5 mb-5">
        {loading ? (
          <SectionLoader />
        ) : (
          <Card className={'job-details'}>
            <div className="row g-4">
              <div className="col-12">
                <div className="p-4">
                  <div className="image_container">
                    <MyBridgeCompoanyAvatar
                      icon={jobDetail?.hiring_company_details?.image}
                      name={jobDetail?.hiring_company_details?.name}
                      size={90}
                      className="company-avatar"
                      round={false}
                    />

                    {/* <img src={servicesPic} width="65px" height="65px" /> */}
                  </div>
                  <h4 className="hding_sJobs mt-3">{jobDetail?.title}</h4>
                  <p className="jobDetails-companydetails">
                    {jobDetail?.hiring_company_details?.name}
                    <br />
                    {jobDetail?.city ? `${jobDetail.city}, ${jobDetail.country}` : `${jobDetail.country}`}
                  </p>

                  <div className="jobs-benifits">
                    <div className="fs-13 benifits">
                      <Currency className="me-1" /> {getCurrencyIcon(jobDetail?.currency_choice)}{' '}
                      {jobDetail?.compensation_range_min_amt} to {getCurrencyIcon(jobDetail?.currency_choice)}{' '}
                      {jobDetail?.compensation_range_max_amt} {jobDetail?.payment_choice}
                    </div>
                    <div className="fs-13 benifits">
                      {jobDetail?.benefits && jobDetail?.benefits?.length ? <Industry className="me-1" /> : null}
                      {jobDetail?.benefits?.map((item, index) =>
                        jobDetail.benefits.length === index + 1 ? (
                          <span key={index} className="me-1">
                            {item.name}{' '}
                          </span>
                        ) : (
                          <span key={index} className="me-1">
                            {item.name},
                          </span>
                        ),
                      )}
                    </div>
                    <div className="fs-13 benifits">
                      <Brifecase className="me-1" />
                      {jobDetail?.employment_type === 'FT' && <>Full-Time</>}
                      {jobDetail?.employment_type === 'PT' && <>Part-Time</>}
                      {jobDetail?.employment_type === 'C' && <>Contractor</>}
                      {jobDetail?.employment_type === 'T' && <>Temporary</>}
                      {jobDetail?.employment_type === 'O' && <>Other</>}
                    </div>
                  </div>
                  <h4 className="hding_sJobs mt-3 color-darkGrey2">
                    Why work at {jobDetail?.hiring_company_details?.name}?
                  </h4>
                  <p className="mb-3">{jobDetail?.hiring_company_details?.why_work_here}</p>
                  {jobDetail?.skills && jobDetail?.skills?.length ? (
                    <div className="skills-wrappper">
                      <h4 className="hding_sJobs mt-3 mb-2 color-darkGrey2"> Required skills</h4>
                      <div className="d-flex gap-2">
                        {jobDetail?.skills?.map((item, index) => (
                          <span key={index} className="skill">
                            {item.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  <div className="d-flex mt-md-5 mb-3">
                    {jobApplySuccess?.status === 'APPLIED' ? (
                      <div className="text-success">Applied</div>
                    ) : jobDetail.how_to_apply === 'EXTERNAL' ? (
                      <a
                        className={'btn btn-primary btn-lg me-2'}
                        rel="noreferrer"
                        href={encodeURI(jobDetail.how_to_apply_text)}
                        target="_blank"
                      >
                        Apply Now
                      </a>
                    ) : (
                      <Button
                        variant="btn-primary"
                        disabled={jobDetail?.created_by === userProfileInfo?.email ? true : false}
                        className={'me-2 btn-lg'}
                        onClick={() => applySelectedJob(jobDetail?.id)}
                      >
                        Apply Now
                      </Button>
                    )}
                  </div>
                  <h4 className="hding_sJobs mt-5 mb-3 color-darkGrey2"> Job Description</h4>
                  <div
                    className="job-detail-note color-darkGrey2"
                    dangerouslySetInnerHTML={{ __html: jobDetail?.job_description }}
                  ></div>

                  <div className="d-flex justify-content-start mt-md-5">
                  {jobApplySuccess?.status === 'APPLIED' ? (
                      <div className="text-success">Applied</div>
                    ) : jobDetail.how_to_apply === 'EXTERNAL' ? (
                      <a
                        className={'btn btn-primary btn-lg me-2'}
                        rel="noreferrer"
                        href={encodeURI(jobDetail.how_to_apply_text)}
                        target="_blank"
                      >
                        Apply Now
                      </a>
                    ) : (
                      <Button
                        variant="btn-primary"
                        disabled={jobDetail?.created_by === userProfileInfo?.email ? true : false}
                        className={'me-2 btn-lg'}
                        onClick={() => applySelectedJob(jobDetail?.id)}
                      >
                        Apply Now
                      </Button>
                    )}
                  </div>

                  <div className="report_job py-3 mb-4 border-bottom">
                    <Link className="fs-15 text-decoration-underline">Report Job</Link>
                  </div>
                  <p className="mb-1">Company Address: {jobDetail?.hiring_company_details?.address}</p>
                  <p className="mb-1">Posted date: {moment(jobDetail?.published_date).format('ddd, MMMM Do YYYY')}</p>

                  <Link to={`/c/${jobDetail?.hiring_company_details?.slug}/jobs`}>
                    View all jobs at {jobDetail?.hiring_company_details?.name}
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
