export const statusColor = {
  available: '#0AB33E',
  busy: '#FF0600',
  away: '#5B5B5B',
}

export const userStatus = [
  {
    id: 1,
    title: 'Available',
    subTitle: 'Based on activity',
    color: statusColor.available,
  },
  {
    id: 2,
    title: 'Busy',
    subTitle: 'Mute chat notifications',
    color: statusColor.busy,
  },
  {
    id: 3,
    title: 'Away',
    color: statusColor.away,
  },
];