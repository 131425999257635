export const onboardingSteps = [
  {
    id: 0,
    name: 'profile',
    url: '/onboarding/profile'
  },
  {
    id: 1,
    name: 'employment',
    url: '/onboarding/employment'
  },
  {
    id: 2,
    name: 'photo',
    url: '/onboarding/photo'
  },
  {
    id: 3,
    name: 'job_availabilty',
    url: '/onboarding/job_availabilty'
  },
  {
    id: 4,
    name: 'contact',
    url: '/onboarding/contact'
  },
  {
    id: 5,
    name: 'connect',
    url: '/onboarding/connect'
  }
]

export const checkUserOnboardingStatus = (currStepData) => { 
  let nextStep = '/';
  if (currStepData?.data?.profile === false) {
    nextStep = onboardingSteps[0];
  } else if (currStepData?.data?.employment === false) {
    nextStep = onboardingSteps[1];
  } else if (currStepData?.data?.photo === false) { 
    nextStep = onboardingSteps[2];
  }else if (currStepData?.data?.job_availabilty === false) { 
    nextStep = onboardingSteps[3];
  }else if (currStepData?.data?.contact === false) { 
    nextStep = onboardingSteps[4];
  }else if (currStepData?.data?.connect === false && currStepData?.data?.account_active === false) { 
    nextStep = { url: '/email-verify' };
  }else if (currStepData?.data?.connect === false && currStepData?.data?.account_active === true) { 
    nextStep = onboardingSteps[5];
  }
  return nextStep;
      
}