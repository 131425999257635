import React, { useState } from 'react';
import AvatarGroup from 'react-avatar-group';
import { useNavigate } from 'react-router-dom';

import { BorderLessButton, Text, VerticalLine } from 'components/StyledComponents';
import ReadMore from 'components/common/ReadMore';
import { Address, Email, Globe, Page, Phone, Star } from 'components/common/Icons';
import InviteFriends from 'pages/Teams/Profile/Overview/InviteFriends';

const followers = ['Shubham', 'Sola', 'Ketan', 'Umesh', 'Sasi'];

const CompanyInfo = (props) => {
  const { companyDetails: companyPageDetail } = props;
  const navigate = useNavigate();
  const [showModalType, setShowModalType] = useState('');
  const onClose = () => setShowModalType('');
  return (
    <section>
      <div className="bordered-container d-flex flex-column align-items-center py-3 gap-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-inline-flex avatar-group" onClick={() => navigate('/profile-views')}>
            <AvatarGroup
              // avatars={profileViewers?.map((e) => startCase(e.first_name))}
              avatars={followers}
              initialCharacters={1}
              max={3}
              size={32}
              shadow={0}
              displayAllOnHover={false}
              style={{ flex: 'auto' }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <button type="button" className="btn btn-secondary job-btn" onClick={() => setShowModalType('inviteFriends')}>
            Invite Connections
          </button>
        </div>
      </div>

      <div className="intro-container">
        <div className="frame-49286">
          <div className="d-flex">
            <Page />

            <div className="d-flex flex-column ps-2">
              <Text>Page</Text>
              <Text>{companyPageDetail?.main_industry}</Text>
            </div>
          </div>

          <div className="d-flex mt-3">
            <Address />

            <div className="d-flex flex-column ps-2">
              <Text>Address</Text>
              <Text>{companyPageDetail?.address}</Text>
            </div>
          </div>

          <div className="d-flex mt-3">
            <Phone />

            <div className="d-flex flex-column ps-2">
              <Text>Phone Number</Text>
              <Text color="#3D5A80">{companyPageDetail?.phone_number}</Text>
            </div>
          </div>

          <div className="d-flex mt-3">
            <Email />

            <div className="d-flex flex-column ps-2">
              <Text>Email</Text>
              <Text color="#3D5A80">{companyPageDetail?.email}</Text>
            </div>
          </div>

          {companyPageDetail?.website && (
            <div className="d-flex mt-3">
              <Globe />

              <div className="d-flex flex-column ps-2">
                <Text>Website</Text>
                <Text color="#3D5A80">{companyPageDetail?.website}</Text>
              </div>
            </div>
          )}

          {/* <div className="d-flex mt-3">
            <Star />

            <div className="d-flex flex-column ps-2">
              <Text>Rating 4.3 (7 reviews)</Text>
            </div>
          </div> */}
        </div>

        {/* <div className="d-flex justify-content-between w-100 px-4">
          <Text color="#3D5A80">Open Now</Text>
        </div> */}

        <div className="w-100">
          <VerticalLine />
        </div>

        <div className="d-flex justify-content-between w-100 cursor-pointer">
          <BorderLessButton
            className="d-flex align-items-center justify-content-center borderless-btn"
            onClick={() => navigate('/company/page/' + companyPageDetail.id + '/services')}
          >
            <Text color="#5B5B5B">See all services</Text>
          </BorderLessButton>
        </div>
      </div>
      {showModalType === 'inviteFriends' && (
        <InviteFriends teamId={companyPageDetail?.id} type="company" onClose={onClose} />
      )}
    </section>
  );
};

export default CompanyInfo;
