import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChatIcon, EventIcon, Search, NewGroup } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { CometChatConversationList, CometChatMessages } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';
import './style.scss'
import CreateDirectGroup from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Messages/CometChatMessages/CreateDirectGroup';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from 'store/slices/connectionSlice';
import { CometChat } from '@cometchat-pro/chat';
import { CometChatContextProvider } from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/CometChatContext';
import { clearShowChat, setShowMeet } from 'store/slices/meetingSlice';
import { useLocation } from 'react-router-dom';

const ChatMenu = () => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  const location = useLocation();
  const chatListRef = useRef();
  const chatWindow = useRef([]);
  const groupChatWindow = useRef([]);

  const conversationListenerId = "chatlist_" + new Date().getTime();
  useOnClickOutside(ref, () => setShow(false));
  const dispatch = useDispatch();
  const [showNewChat, setShowNewChat] = useState(false);
  const [showNewMeeting, setShowNewMeeting] = useState(false);
  const [counter, setCounter] = useState(1);
  const [minimized, setMinimized] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [minimizedArray, setMinimisedArray] = useState([]);
  const [userChatArray, setUserChatArray] = useState([]);
  const [groupChatArray, setGroupChatArray] = useState([]);
  const { userConversationId } = useSelector((state) => state.connection);
  const {showChatBoxes } = useSelector((state) => state.meeting);
  const [selectedUserInfo, setSelectedUserInfo] = useState([]);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const checkPage = location.pathname.includes('/create/company');
  useEffect(() => {
    if (userConversationId !== null) {
      let type = 'user';
      setMinimized(false);
      setShowNewChat(false);
      setShowNewMeeting(false);
      if (type === 'user') {
        const usersArr = [...userChatArray];
        if (!usersArr.includes(userConversationId.user_uuid)) {
          setUserChatArray([userConversationId.user_uuid, ...userChatArray]);
          setCounter(prevState => prevState + 1);
        }
      } else if (type === 'group') {
        const groupArr = [...groupChatArray];
        if (!groupArr.includes(userConversationId.guid)) {
          setGroupChatArray([userConversationId.guid, ...groupChatArray]);
          setCounter(prevState => prevState + 1);
        }
      }
      setShow(false);
      dispatch(clear());
      dispatch(setShowMeet(false));
    }
  }, [userConversationId]);

  useEffect(() => {
    if (!showChatBoxes) setUserChatArray([]); setGroupChatArray([]);
    dispatch(clearShowChat());
   }, [showChatBoxes])

  const handleNewChatShow = (userInfo) => {

    setSelectedUserInfo(userInfo)
    if (userInfo !== '') {
      setUserChatArray([]);
    }
    setShowNewChat(true);
    setShow(false);
  }

  const handleNewMeetingShow = () => {
    setShowNewMeeting(true);
    setShow(false);
  }

  const handleShow = () => {
    setShow(prev => !prev);
  }

  const itemClicked = (user, type) => {
    setMinimized(false);
    setShowNewChat(false);
    setShowNewMeeting(false);
    if (type === 'user') {
      const usersArr = [...userChatArray];
      if (!usersArr.includes(user.uid)) {
        setUserChatArray([user.uid, ...userChatArray]);
        setCounter(prevState => prevState + 1);
      }
    } else if (type === 'group') {
      const groupArr = [...groupChatArray];
      if (!groupArr.includes(user.guid)) {
        setGroupChatArray([user.guid, ...groupChatArray]);
        setCounter(prevState => prevState + 1);
      }
    }
    setShow(false);
    dispatch(setShowMeet(false));
  }

  const close = (currUser, type) => {
    if (type === 'user') {
      const filteredChatArray = userChatArray.filter(e => {
        return e !== currUser
      })
      setUserChatArray(filteredChatArray);
    } else if (type === 'group') {
      const filteredChatArray = groupChatArray.filter(e => {
        return e !== currUser
      })
      setGroupChatArray(filteredChatArray);
    } else if (type === 'newChat') {
      setShowNewChat(false);
    }
    setCounter(prevState => prevState + 1);
    dispatch(clear());
  }

  const closeMeeting = () => {
    setShowNewMeeting(false);
  }

  const minimize = (currUser, type , index) => {
    const windowChat = type === 'user' ? chatWindow : groupChatWindow;
    if (windowChat.current[index].className === 'chats__main' && windowChat.current[index].getAttribute("data-userID") === currUser) {
      windowChat.current[index].className = 'chats__main minimized';
    } else { 
      windowChat.current[index].className = 'chats__main';
    }

  }

  const userChatWindows =
    userChatArray.map((e,index) => {
      return <div key={e} id={e} ref={(element) => {chatWindow.current[index] = element}}  data-userID={e} className={'chats__main'}><CometChatMessages key={counter} chatWithUser={e} onClose={() => close(e, 'user')} onMinimize={() => minimize(e, 'user',index)} onNewChat={(userInfo) => handleNewChatShow(userInfo)} onNewMeeting={handleNewMeetingShow} /></div>
    })

  const groupChatWindows =
    groupChatArray.map((e,index) => {
      return <div key={e} id={e} ref={(element) => {groupChatWindow.current[index] = element}} data-userID={e} className={classNames(minimizedArray?.includes(e) ? 'chats__main minimized' : 'chats__main')}><CometChatMessages key={counter} chatWithGroup={e} onClose={() => close(e, 'group')} onMinimize={() => minimize(e, 'group' , index)} onNewChat={(userInfo) => handleNewChatShow(userInfo)} onNewMeeting={handleNewMeetingShow} /></div>
    })

  const totalChatWindows = userChatWindows.concat(groupChatWindows);

  const updateUnreadMsgCount = () => { 
    CometChat.getUnreadMessageCount().then(
      array => {
        let userCount = Object.keys(array.users).length;
        let groupCount = Object.keys(array.groups).length;
        let count = userCount + groupCount;
        setUnreadCount(count);
      }, error => {
        console.error("Error in getting message count", error);
      }
    );
    
  }

  CometChat.addMessageListener(
    conversationListenerId,
    new CometChat.MessageListener({
        onTextMessageReceived: textMessage => {
          updateUnreadMsgCount()
        },
        onMediaMessageReceived: mediaMessage => {
          updateUnreadMsgCount()
        },
        onCustomMessageReceived: customMessage => {
          updateUnreadMsgCount()
        },
        onMessagesRead: messageReceipt => {
          setUnreadCount(0);
        }
    })
);

  return (
    <CometChatContextProvider ref={chatListRef}>
    <div className="navbar-dropdown" ref={ref}>
      <div className={classNames("dropdown-wrap transparent", [show ? 'active' : ''])} onClick={handleShow}>
        {/* <ChatIcon className="default" color={show ? '#0255FE' : '#5B5B5B'} /> */}
        <ChatIcon className="default" width={35} height={35} color={show ? '#3D5A80' : '#5B5B5B'} />
        {unreadCount > 0 ? <span>{unreadCount}</span> : ''}
      </div>
      {!checkPage && userProfileInfo.is_active &&
      <div className='chat-nav new-chat' title='New chat' onClick={() => handleNewChatShow('')}>
        <ChatIcon className='default' />
      </div>
}

      <div className='chats__main__wrapper'>
        {showNewChat && (<div className='chats__main'><CreateDirectGroup defaultUser={selectedUserInfo !== '' ? selectedUserInfo : ''} onClose={() => close('none', 'newChat')} onSuccess={(user, type) => itemClicked(user, type)} /></div>)}
        {totalChatWindows}

      </div>

      {show && (
        <div className='navbar-dropdown--menu'>
          <div className='menu-header pb-0'>
            <div className='wrap align-items-center'>
              <h3>Chat</h3>
              <NewGroup className="default ms-auto cursor-pointer" width={30} height={30} onClick={() => handleNewChatShow('')} />
            </div>

          </div>

          <div className="menu-body scrollbar">
            <div className="block">
              <div className="block-body">
                <CometChatConversationList ref={chatListRef} _parent="unified" onItemClick={(user, type) => itemClicked(user, type)} />
              </div>
            </div>
          </div>

          <div className="menu-footer">
            <Link to="/profile/network">All Network</Link>
          </div>
        </div>
      )}
      </div>
      </CometChatContextProvider>
  );
};

export default ChatMenu;
