import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import backarrow from 'assets/Jobs/backarrow.svg';

import Layout from 'components/Layouts';
import { Text } from 'components/StyledComponents';
import Info from './sections/Info';
import Benefits from './sections/Benefits';
import Role from './sections/Role';
import Apply from './sections/Apply';
import Button from 'components/common/Button';

const sectionText = {
  1: 'Find a great hire fast here with myJobs.',
  2: 'Just a few more buttons to click until your job is posted.',
  3: 'How can the perfect candidate get in touch?',
  4: "Tell us about the role! Now's the time to let your company shine.",
};

const CreateCompanyJob = () => {
  const navigate = useNavigate();

  const { editDetails } = useSelector((state) => state.jobReducer);

  const [section, setSection] = useState(1);

  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    if (editDetails?.id) {
      setJobDetails(editDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickBack = () => {
    if (section === 0) navigate(-1);
    setSection((section) => section - 1);
  };
  console.table('jobDetails', editDetails);
  return (
    <React.Fragment>
      <div className="col-12 d-flex flex-column border-bottom gap-5 p-4">
        <div className="container">
          <Button  onClick={onClickBack} variant='white' className="back mb-3 ps-0"><img src={backarrow} alt="Not Found" className="arrow-back" /> Back</Button>
          <Text fS="18px" color="#3D5A80">
            {sectionText[section]}
          </Text>
        </div>
      </div>

      {section === 1 && <Info setSection={setSection} jobDetails={jobDetails} setJobDetails={setJobDetails} />}
      {section === 2 && <Benefits setSection={setSection} jobDetails={jobDetails} setJobDetails={setJobDetails} />}
      {section === 3 && <Apply setSection={setSection} jobDetails={jobDetails} setJobDetails={setJobDetails} />}
      {section === 4 && <Role setSection={setSection} jobDetails={jobDetails} setJobDetails={setJobDetails} />}
    </React.Fragment>
  );
};

export default CreateCompanyJob;
