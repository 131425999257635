import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Conatiner = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: ${(props) => props.bg || '#E4E6EB'};
  border-radius: 8px;
  border: none;
  margin: 4px;
  position: relative;
`;

const PostActionTile = ({ component, toolTipTitle, toolTipPosition }) => {
  const placement = 'top';
  if (toolTipTitle) {
    return (
      // <OverlayTrigger placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}>{toolTipTitle}</Tooltip>}>

      <Conatiner aria-label={toolTipTitle ?? ''} data-microtip-position={toolTipPosition || 'top-right'} role="tooltip">
        {component}
      </Conatiner>
      // </OverlayTrigger>
    );
  }

  return <Conatiner>{component}</Conatiner>;
};

PostActionTile.propTypes = {
  component: PropTypes.element.isRequired,
  toolTipTitle: PropTypes.string,
};

PostActionTile.defaultProps = {
  icon: null,
  toolTipTitle: null,
};

export default PostActionTile;
