import AutoComplete from 'components/common/AutoComplete';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import { Camera } from 'components/common/Icons';
import { Input, TextArea } from 'components/common/Input';
import RadioButton from 'components/common/RadioButton';
import React, { useRef, useEffect, useState } from 'react';
import { Form, useValidation } from 'usetheform';

import { useDispatch, useSelector } from 'react-redux';
import { getIndustries } from 'store/slices/userProfileSlice';
import Alert from 'components/common/Alert/Alert';
import { Spinner } from 'components/common/Loader';

export const TeamsModal = (props) => {
  const { handlePopups, setData, setDataValue, cropHandlePopups, teamData, onFormSubmit, mode = "create" } = props;
  const [errors, setFieldErrors] = useState({})
  const { error, msg, message, loading } = useSelector((state) => state.teamsCommunity);
  const { industriesList } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!teamData?.discoverability) {
      setData({ target: { name: "discoverability", value: true } })
    }
  }, [])

  useEffect(() => {
    if (message?.length > 0) {
      document.querySelector(".create-team .modal-body").scrollTop = 0;
    }
  }, [error, message])

  useEffect(() => {
    dispatch(getIndustries());
  }, []);

  const getURLForImage = (img) => {
    try {
      if (typeof img !== "string") {
        return URL.createObjectURL(img)
      } else {
        return img
      }
    } catch (e) {
      console.error(e)
    }
  }
  const validateTeamBeforeCreate = (team) => {
    let newErrors = {}
    if (!team.organize_type || team.organize_type?.length <= 0) {
      newErrors = ({ ...(newErrors), "organize_type": "Please choose a type for this team" })
    }
    if (!team.name || team.name?.length <= 0) {
      newErrors = ({ ...(newErrors), "name": "Please provide a valid team name" })
    }
    if (!team.desc || team.desc?.length <= 0) {
      newErrors = ({ ...(newErrors), "desc": "Please provide valid description for this team" })
    }
    if (team.desc?.length < 150) {
      newErrors = ({ ...(newErrors), "desc": "Should be atleast 150 characters" })
    }
    if (team.desc?.length > 2000) {
      newErrors = ({ ...(newErrors), "desc": "Should not be more than 2000 characters" })
    }
    if (!team.industry_name || team.industry_name?.length <= 0) {
      newErrors = ({ ...(newErrors), "industry_name": "Please select at least one industry" })
    }
    if (team.discoverability === undefined || team.discoverability === null) {
      newErrors = ({ ...(newErrors), "discoverability": "Please select appropriate discoverability for this team" })
    }

    setFieldErrors(newErrors)
    const errLen = Object.keys(newErrors).length
    if (errLen) {
      document.querySelector(".create-team .modal-body").scrollTop = 0;
    }
    return errLen <= 0;
  }

  const onSubmit = () => {
    if (validateTeamBeforeCreate(teamData)) {
      onFormSubmit?.()
    }
  }

  const onIndustryListChange = (name, value) => {
    setData?.({ name, value })
  }

  console.log("data", teamData)

  return (
    <>
      {error && message ? <Alert type="danger">{message}</Alert> : ""}
      {msg ? <Alert type="success">{msg}</Alert> : ""}
      <Form onSubmit={onSubmit} >
        <div className="team-banner">
          {teamData?.banner_image && (
            <img
              src={teamData?.banner_image ? getURLForImage(teamData?.banner_image) : null}
              alt={teamData?.banner_image?.name || null}
            />
          )}
          <Button className={'upload-banner'} onClick={() => cropHandlePopups('upload-banner')}>
            <Camera width={20} height={16} />
          </Button>
          {/* <input type="file" name="banner_image" ref={inputFile} style={{ display: 'none' }}  /> */}
          <div className="upload-logo">
            {
              teamData?.image_logo && (
                <img src={teamData?.image_logo ? getURLForImage(teamData?.image_logo) : null} alt={teamData?.image_logo?.name || null} />
              )
              // <img src={pageData?.image} alt="" />
            }
            <span
              className={`upload-icon ${teamData?.image_logo ? "show-on-hover" : ""}`}
              onClick={() => cropHandlePopups('profilePhoto')}
            >
              <label htmlFor="file-input">
                <Camera />
              </label>
              {/* <input id="file-input" type="file" name="image"  className={'d-none'} /> */}
            </span>
          </div>
        </div>

        <div className="team-body">
          <div className="row mb-3 align-items-center">
            <div className="col">Organize as</div>
            <div className="col-xl-10">
              <div className="d-flex align-items-center mt-0">
                <RadioButton
                  name="organize_type"
                  value="team"
                  label="Team"
                  labelClass="label-text"
                  changed={setData}
                  isSelected={teamData?.organize_type === 'team'}
                  containerClass={'me-4'}
                />
                <RadioButton
                  name="organize_type"
                  value="community"
                  label="Community"
                  className="ms-3"
                  labelClass="label-text"
                  changed={setData}
                  isSelected={teamData?.organize_type === 'community'}
                />
              </div>
            </div>
            <div className='d-flex'>
              <div style={{ flex: 1 }} className='field-error'>
                {errors?.organize_type ? <p style={{ flex: 1 }}>{errors?.organize_type}</p> : ""}
              </div>
            </div>
          </div>
          <div className="mb-1">Team name*</div>
          <Input
            type="text"
            placeholder="Team Name"
            value={teamData?.name}
            maxLength="100"
            name="name"
            onChange={setData}
            containerClass={'m-0'}
          />
          <div className='d-flex'>
            <div style={{ flex: 1 }} className='field-error'>
              {errors?.name ? <p style={{ flex: 1 }}>{errors?.name}</p> : ""}
            </div>
            <p className="text-end text-grey fs-12">{teamData?.name?.length || 0}/100 </p>
          </div>
          <div className="mb-1">Description*</div>
          <TextArea
            placeholder={'Description'}
            className={'team_detail'}
            name="desc"
            maxLength={2000}
            containerClass={'m-0'}
            onChange={setData}
            value={teamData?.desc}
          />
          <div className='d-flex'>
            <div style={{ flex: 1 }} className='field-error'>
              {errors?.desc ? <p style={{ flex: 1 }}>{errors?.desc}</p> : ""}
            </div>
            <p className="text-end text-grey fs-12">{teamData?.desc?.length || 0}/2000</p>
          </div>

          <div className="mb-1">Industry (up to 3)</div>
          <AutoComplete
            onChange={onIndustryListChange}
            tagList={industriesList}
            tags={teamData?.industry_name?.map(inm => inm?.id ?? inm)}
            name="industry_name"
            limit={3}
            placeholder="Add industry"
          />
          <div className='d-flex'>
            <div style={{ flex: 1 }} className='field-error'>
              {errors?.industry_name ? <p style={{ flex: 1 }}>{errors?.industry_name}</p> : ""}
            </div>
          </div>
          <div className="mb-1">Location</div>
          <Input placeholder="Location" type="text" onChange={setData} name="location" value={teamData?.location} />

          <div className="mb-1">Team rules</div>
          <TextArea
            placeholder={'Set the tone and expectations of your Team'}
            className={'team_detail'}
            name="rules"
            onChange={setData}
            maxLength={4000}
            containerClass={'m-0'}
            value={teamData?.rules}
          />
          <p className="text-end text-grey fs-12">{teamData?.rules?.length || 0}/4000</p>
          <div className="row mb-3 align-items-center">
            <div>Team discoverability</div>

            <div className="d-flex align-items-start pb-3 mt-2">
              <div>
                <RadioButton
                  name="discoverability"
                  value="Listed"
                  labelClass="label-text"
                  changed={setData}
                  isSelected={teamData?.discoverability === true}
                  containerClass={'me-2'}
                />
              </div>
              <p className="text-black">
                <strong>Listed</strong>
                <br />
                Team appears in search results and is visible to others on members’ profiles.
              </p>
            </div>
            <div className="d-flex align-items-start pb-3">
              <div>
                <RadioButton
                  name="discoverability"
                  value="Unlisted"
                  containerClass={'me-2'}
                  labelClass="label-text"
                  changed={setData}
                  isSelected={teamData?.discoverability === false}
                />
              </div>
              <p className="text-black">
                <strong>Unlisted</strong>
                <br />
                Team does not appear in search results for non-Team members and is not visible to others on members’
                profiles.
              </p>

            </div>
            <div className='d-flex'>
              <div style={{ flex: 1 }} className='field-error'>
                {errors?.discoverability ? <p style={{ flex: 1 }}>{errors?.discoverability}</p> : ""}
              </div>
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <div>Permissions</div>

            <div className="d-flex align-items-start pb-3 mt-2">
              <div>
                <Checkbox
                  className={"mt-0"}
                  name="permission_invite_members"
                  onChange={setData}
                  checked={teamData?.permission_invite_members}
                />
              </div>
              <p className="text-black">
                <strong>Allow members to invite their connections</strong>
                <br />
                If disabled, only admins will be able to invite 1st degree connections to the team. All requests to join
                will still require admin approval.
              </p>
            </div>
            <div className="d-flex align-items-start pb-3">
              <div>
                <Checkbox
                  className="mt-0"
                  name="permission_new_post"
                  onChange={setData}
                  checked={teamData?.permission_new_post} />
              </div>
              <p className="text-black">
                <strong>Require new posts to be reviewed by admins</strong>
                <br />
                If enabled, members’ posts will require admin approval within 14 days before they become visible to
                others.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4 gap-2">
          <Button variant="btn-secondary" className={'h-36'} onClick={() => handlePopups(undefined)}>
            Cancel
          </Button>
          <Button disabled={loading} type="submit" variant="btn-primary" className={'h-36'}>
            {loading ? <Spinner /> :
              (mode === "create" ? "Create Team" : "Save")
            }
          </Button>
        </div>
      </Form >
    </>
  );
};
