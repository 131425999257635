import Button from 'components/common/Button'
import { Input } from 'components/common/Input'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from 'store/slices/authSlice';


function ResetPassword() {
    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fields, setFields] = useState({});
    const [registerError, setRegisterError] = useState({});

    const ERROR_VALUES = {
        signUpPassword: 'Please enter a password',
        upperCaseError: 'At least one Uppercase letter',
        lowerCaseError: 'At least one Lowercase letter',
        digitsError: 'At least one digit',
        specialCharError: 'At least one Special Characters',
        signUpPasswordLength: 'Password is too short',
        signUpConfirmPassword: 'Please enter a confirm password',
        isNotSamePassword: 'Password and confirm password are not same'
    }

    const { msg, error } = useSelector(state => state.user);

    const resetPasswordHandle = () => {
        const errorObject = {}
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const { signUpPassword, signUpConfirmPassword } = fields;

        if (!signUpPassword) {
            errorObject.signUpPassword = ERROR_VALUES.signUpPassword
        }
        if (!uppercaseRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.upperCaseError
        }
        if (!lowercaseRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.lowerCaseError
        }
        if (!digitsRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.digitsError
        }
        if (!specialCharRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.specialCharError
        }
        if (!minLengthRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.signUpPasswordLength
        }
        if (!signUpConfirmPassword) {
            errorObject.signUpConfirmPassword = ERROR_VALUES.signUpConfirmPassword
        }
        if (signUpPassword && signUpConfirmPassword && (signUpPassword !== signUpConfirmPassword)) {
            errorObject.isNotSamePassword = ERROR_VALUES.isNotSamePassword
        }
        if (Object.keys(errorObject).length > 0) {
            setRegisterError(errorObject)
            return
        } else {
            setRegisterError({})
        }

        dispatch(resetPassword({ password:signUpPassword, token: id }))
    }

    const signUpOnChange = (e) => {
        const { name, value } = e.target;
        setFields((prevValues) => ({ ...prevValues, [name]: value }))
        const isValue = value.trim().length > 0;
        setRegisterError(prevErrors => ({ ...prevErrors, [name]: isValue ? undefined : ERROR_VALUES[name] }))
    }

    useEffect(() => { 
        if (!error && msg) { 
            navigate('/signin');
        }
    }, [msg])

    return (

        <div className='user-flow--form'>

            <h2 className="user-flow--formHeader">Set new password</h2>
            <p className="user-flow--formSubHeader">Reset password in two quick steps</p>

            {!error && msg && <div className='text-success helper-text my-2'>{msg}</div>}
            {error && <div className='text-danger helper-text my-2'>{msg}</div>}
            <Input
                type="password"
                name="signUpPassword"
                value={fields.signUpPassword}
                onChange={signUpOnChange}
                className={registerError.signUpPassword ? 'is-invalid' : ''}
                placeholder="Password"
                error={registerError.signUpPassword && registerError.signUpPassword}
                label="Password"
            />

            <Input
                type="password"
                name="signUpConfirmPassword"
                value={fields.signUpConfirmPassword}
                onChange={signUpOnChange}
                className={registerError.signUpConfirmPassword ? 'is-invalid' : ''}
                placeholder="Confirm Password"
                error={registerError.signUpConfirmPassword && registerError.signUpConfirmPassword || registerError.isNotSamePassword && registerError.isNotSamePassword}
                label="Confirm Password"
            />

            <Button className='full-width mb-3' onClick={() => resetPasswordHandle()}>
                Update password
            </Button>
            {!error && msg &&
                <div className="text-center">
                    <Link to="/signin" className="text-decoration-none fw-bold"> Go to sign in </Link>
                </div>
            }
        </div>
       
    )
}

export default ResetPassword