import React from 'react';
import styled from 'styled-components';

import avatar from 'assets/profiles/profile/avtar_md.jpeg';

const ExploreCareerContainer = styled.div``;

const ExploreCareerBox = styled.div`
  padding: 0.7rem;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 4px 6px -6px rgb(217, 217, 217);
`;

const ExploreCareerText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => props.fWeight || 600};
  font-size: ${(props) => props.fSize || '16px'};
  line-height: 120%;
  color: ${(props) => props.color || '#000000'};
`;

const CareerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const ExploreCareer = () => {
  return (
    <ExploreCareerContainer>
      <ExploreCareerBox className="pt-4">
        <ExploreCareerText className="pb-1">Career</ExploreCareerText>
        <ExploreCareerText fWeight={400} fSize="14px" color="#747474">
          Based on your profile and search history
        </ExploreCareerText>
      </ExploreCareerBox>

      <ExploreCareerBox className="py-3">
        <CareerContainer>
          <img src={avatar} alt="Logo" />
          <div className="ps-3">
            <ExploreCareerText fWeight={400} fSize="14px">
              Graphic Design Intern (Remote Internship - Designer)
            </ExploreCareerText>
            <ExploreCareerText fWeight={400} fSize="12px" color="#747474">
              Brainnest Delhi, Delhi, India (Remote) 1 day ago
            </ExploreCareerText>
          </div>
        </CareerContainer>

        <CareerContainer className="d-flex align-items-start">
          <img src={avatar} alt="Logo" />
          <div className="ps-3">
            <ExploreCareerText fWeight={400} fSize="14px">
              Graphic Design Intern (Remote Internship - Designer)
            </ExploreCareerText>
            <ExploreCareerText fWeight={400} fSize="12px" color="#747474">
              Brainnest Delhi, Delhi, India (Remote) 1 day ago
            </ExploreCareerText>
          </div>
        </CareerContainer>
      </ExploreCareerBox>

      <ExploreCareerText fWeight="500" color="#3D5A80" className="text-center py-4">
        Show All
      </ExploreCareerText>
    </ExploreCareerContainer>
  );
};

export default ExploreCareer;
