import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Camera } from 'components/common/Icons';
import AutoSizer from 'react-virtualized-auto-sizer';
import { deleteBlog } from 'store/slices/blogSlice';
import { FixedSizeList as List } from 'react-window';
import Card from './card';
import styles from './style.module.scss';
import { getBlogsByStatus } from 'api/blog';
import { useQuery } from '@tanstack/react-query';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Loader from 'components/common/Loader';
import { useParams } from 'react-router-dom';

const Draft = () => {
  const { status } = useParams();

  const { data, isLoading, error } = useQuery(['getBlogsByStatus', status], getBlogsByStatus);

  if (isLoading) return <Loader secondary />;

  if (error) return 'An error has occurred: ' + error.message;

  const renderData = data || [];
  return (
    <div className={styles.draftCont}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 200: 1, 1200: 2 }}>
        <Masonry>
          {renderData?.map((item, index) => {
            return <Card item={item} key={item?.id} />;
          })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};
export default memo(Draft);
