import { CircleIcon, FollowerIcon, Globe } from 'components/common/Icons';

export const POST_ACTIONS = [
  {
    id: 0,
    title: 'Everyone',
    icon: <Globe width={15} height={15} className="me-2" />,
  },
  {
    id: 1,
    title: 'My circle',
    icon: <CircleIcon width={15} height={15} className="me-2" />,
  },
  {
    id: 2,
    title: 'My circle & followers',
    icon: <FollowerIcon width={15} height={15} className="me-2" />,
  },
];

export const defaultPosts = [
  {
    id: 1,
    author: {
      profile_pic:
        'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/profile/f913b878-2be7-49ab-b543-7020b29b2398/Screenshot_2023-05-16_at_10.13.49_AM.png',
      first_name: 'My',
      last_name: 'Bridge',
    },
    content:
      'Our #1 goal for this platform is to create a space where true conversations and true connections can happen. We might be small now, but we are heading towards great things and we are so happy that you are here with us on this journey to create this space and to better your career.',
    media: [
      {
        id: 147,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/916/image_1.png',
        created_at: '2023-03-22T07:10:27.176605Z',
        post: 1,
        mediaType: 'video',
      },
    ],
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 2,
    author: {
      profile_pic:
        'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/profile/f913b878-2be7-49ab-b543-7020b29b2398/Screenshot_2023-05-16_at_10.13.49_AM.png',
      first_name: 'My',
      last_name: 'Bridge',
    },
    media: [
      {
        id: 147,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/917/image_1_1.png',
        created_at: '2023-03-22T07:10:27.176605Z',
        post: 1,
        mediaType: 'video',
      },
    ],
    content:
      'What are the first steps you should take if you are looking for a new job? We think the 1st thing to do is to look within your networking circle to discover who is hiring, but more importantly, who you would like to work for or with. Networking to find your next job will 100% pay off in the end.',
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 3,
    author: {
      profile_pic:
        'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/profile/f913b878-2be7-49ab-b543-7020b29b2398/Screenshot_2023-05-16_at_10.13.49_AM.png',
      first_name: 'My',
      last_name: 'Bridge',
    },
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/918/image_1_2.png',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content:
      'If extra time in the day seems to always be just beyond your grasp, it helps to block out chunks of time to focus on specific tasks. We mean actually block it out on your calendar! Stick to the specific time on the calendar because focused time is more productive than trying to multitask.',
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 4,
    author: {
      profile_pic:
        'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/profile/f913b878-2be7-49ab-b543-7020b29b2398/Screenshot_2023-05-16_at_10.13.49_AM.png',
      first_name: 'My',
      last_name: 'Bridge',
    },
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/423/wonderfulTechnology.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: "Isn't technology a wonderful thing? Relationships can be bridged and career nurtured.",
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
];
