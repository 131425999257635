import React, { useMemo } from 'react';
import placeholder from '../../../assets/new/user.png';
import Avatar from 'react-avatar';
import Popup from 'reactjs-popup';
import UserCard from '../UserCard';

const AvatarOld = ({ icon, size, overlapIcon }) => {
  const avatarClass = size ? `primary-avatar ${size}` : 'primary-avatar';
  return (
    <div className={avatarClass}>
      <img src={icon || placeholder} alt="" />
      {overlapIcon && overlapIcon}
    </div>
  );
};

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#f39c12',
  '#f1c40f',
  '#27ae60',
  '#e67e22',
  '#d35400',
  '#2980b9',
  '#e74c3c',
  '#c0392b',
  '#8e44ad',
  '#2c3e50',
  '#95a5a6',
  '#7f8c8d',
  '#0054FF',
  '#037F29',
  '#E41C19',
  '#B71C1C',
  '#880E4F',
  '#4A148C',
  '#1A237E',
  '#1B5E20',
  '#3E2723',
  '#BF360C',
  '#4E342E',
  '#263238',
];

const ImageComponent = ({ src, alt, className }) => {
  const cachedImage = useMemo(() => <img src={src} alt={alt} className={className}/>, [src, alt, className]);
  return cachedImage;
};

const CachedImage = ({ src, alt, className, icon, size, onClick, round, margin}) => {
  const cachedImage = useMemo(() => <div
  className={className}
  onClick={onClick}
  style={{
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    borderRadius: round ? '50%' : '',
    backgroundImage: `url(${icon})`,
    margin: margin,
    width: size,
    height: size,
  }}
/>, [src, alt, className, size, icon, margin]);
  return cachedImage;
};

export const MyBridgeAvatar = ({
  icon,
  name,
  size,
  margin,
  round = true,
  colorIndex,
  className,
  onClick,
  isCompany,
}) => {
  const { REACT_APP_IMG_URL } = process.env;
  let userImg = icon && icon.split('/');
  return (
    <>
      {icon && !icon.includes('default-profile-pic') && !icon.includes('placeholder') ? (
        isCompany ? (
          <ImageComponent src={`${icon}`} alt="" className={'rounded-circle profile-pic'} />
        ) : (
          <CachedImage
            className={`${round && 'rounded-circle'} profile-pic ${className}`}
            onClick={onClick}
            round={round}
            icon={icon}
            margin={margin}
            size={size}
          />
        )
      ) : // <img src={`${REACT_APP_IMG_URL}${icon}`} alt="" className={'rounded-circle profile-pic'} />
      // <img src={icon} width={size ? size : 40} height={size ? size : 40} alt={name} className={'rounded-circle'} />
      // <img src={`${REACT_APP_IMG_URL}${icon}`} alt="" className={'rounded-circle profile-pic'} />
      // <img src={`${icon}`} alt="" className={'w-100 rounded-circle'} />
      // <img src={icon} width={size ? size : 40} alt={name} className={'rounded-circle'} />
      !name?.includes('undefined') ? (
        <Avatar
          onClick={onClick}
          name={name}
          size={size ? size : 40}
          maxInitials={2}
          round={round}
          color={colors[colorIndex]}
          className={className ? `${className}` : 'my-bridge-avatar'}
        />
      ) : null}
    </>
  );
};

export const MyBridgeCompoanyAvatar = ({ icon, name, size, round = true, colorIndex, className }) => {
  // const { REACT_APP_IMG_URL } = process.env;
  let userImg = icon && icon.split('/');
  return (
    <>
      {icon && userImg[1] !== 'default-profile-pic.jpg' ? (
        // <img src={`${REACT_APP_IMG_URL}${icon}`} alt="" className={'rounded-circle profile-pic'} />
        <img src={`${icon}`} alt="" width={size} height={size} className={'profile-pic rounded-circle'} />
      ) : (
        // <img src={`${REACT_APP_IMG_URL}${icon}`} alt="" className={'rounded-circle profile-pic'} />
        // <img src={icon} width={size ? size : 40} height={size ? size : 40} alt={name} className={'rounded-circle'} />
        // <img src={`${REACT_APP_IMG_URL}${icon}`} alt="" className={'rounded-circle profile-pic'} />
        // <img src={`${icon}`} alt="" className={'w-100 rounded-circle'} />
        // <img src={icon} width={size ? size : 40} alt={name} className={'rounded-circle'} />
        <Avatar
          name={name}
          size={size ? size : 40}
          maxInitials={2}
          round={round}
          color={colors[colorIndex]}
          className={className ? `${className}` : 'my-bridge-avatar'}
        />
      )}
    </>
  );
};

export const UserAvatarPopup = (props) => {
  const { size, round = true, colorIndex, className, user, onClick } = props;
  const userName = `${user?.first_name} ${user?.last_name}`;

  if (
    user?.profile_pic &&
    !user?.profile_pic.includes('default-profile-pic') &&
    !user?.profile_pic.includes('placeholder')
  ) {
    return (
      <Popup
        className="dashboard-circle-popup"
        on="hover"
        offsetX={0}
        position={'right'}
        arrow={false}
        nested
        trigger={
          <div
            className={'rounded-circle profile-pic ' + className}
            onClick={onClick}
            style={{
              backgroundPosition: 'top center',
              backgroundSize: 'cover',
              borderRadius: '50%',
              backgroundImage: `url(${user?.profile_pic})`,
              width: size,
              height: size,
              cursor: 'pointer',
            }}
          />
        }
      >
        <UserCard user={user} />
      </Popup>
    );
  }

  if (!userName?.includes('undefined')) {
    return (
      <Popup
        on="hover"
        offsetX={0}
        position={'right'}
        arrow={false}
        nested
        trigger={
          <div>
            <Avatar
              name={userName}
              size={size ? size : 40}
              maxInitials={2}
              round={round}
              color={colors[colorIndex]}
              className={className ? `${className}` : 'my-bridge-avatar'}
            />
          </div>
        }
      >
        <UserCard user={user} />
      </Popup>
    );
  }

  return null;
};

export default AvatarOld;
