import { Flex, Text } from 'components/core';
import NetworkCard from 'pages/Dashboard/Circle/NetworkCard';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 22px;
  gap: 12px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 8px;
`;

const ParticipantList = ({ attendees = [] }) => {
  return (
    <Container>
      <Text size="20px" weight="700" color="#333333" display="flex">
        Participants{' '}
        <Text color="#A3A3A3" size="20px" weight="700" margin="0px 0px 0px 10px">
          ({attendees?.length})
        </Text>
      </Text>
      <Flex justify="flex-start" align="center" wrap="wrap">
        {attendees.map((attendee) => {
          if (attendee?.status !== 'ACCEPT') return null;
          return <ParticipantListItem attendee={attendee} key={attendee.id} />;
        })}
      </Flex>
    </Container>
  );
};

export default ParticipantList;

const ParticipantListItem = ({ attendee }) => {
  return <NetworkCard user={attendee?.user} isSearchResult event />;
};
