import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/common/Modal/Modal';
import { Text } from 'components/StyledComponents';
import RadioButton from 'components/common/RadioButton';
import { Input } from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';

import { DEACT_REASONS } from 'data/data';

import { deactivateUser } from 'store/slices/settingsSlice';
import { logout } from 'store/slices/authSlice';
import Button from 'components/common/Button';

const Deactivation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(1);
  const [deactivationDetails, setDeactivationDetails] = useState({});

  const onClickClose = () => {
    setShowModal(false);
    setStep(1);
  };

  const onClickDeactivate = () => {
    const { reason, password, unsubscribe } = deactivationDetails;
    dispatch(deactivateUser({ reason, password, unsubscribe: !!unsubscribe }));
    setShowModal(false);
    dispatch(logout());
    navigate('/signin');
  };

  return (
    <div className="deactivation-section">
      <div className="row g-0 justify-content-end text-end">
        <div className="col-12">
          <p className="deactivation-text" onClick={() => setShowModal(true)}>
            I'd like to deactivate my account
          </p>
        </div>
      </div>
      {showModal && (
        <Modal
          onClose={onClickClose}
          className="deactivation-modal"
          heading={
            <Text fW="700" fS="24px" color="#3d5a80" className="deactivation-header">
              Close Account
            </Text>
          }
          action={
            <>
              <Button className="btn btn-secondary" width="90px" fontSize="15px" padding="5px" onClick={onClickClose}>
                Cancel
              </Button>

              {step === 1 && (
                <Button
                  width="100px"
                  fontSize="15px"
                  color="#fff"
                  padding="5px"
                  onClick={() => setStep(2)}
                >
                  Continue
                </Button>
              )}
              {step === 2 && deactivationDetails?.reason?.length ? (
                <Button
                  width="100px"
                  fontSize="15px"
                  color="#fff"
                  padding="5px"
                  onClick={() => setStep(3)}
                >
                  Next
                </Button>
              ) : ""}
              {step === 3 && (
                <Button
                  width="100px"
                  fontSize="15px"
                  color="#fff"
                  padding="5px"
                  onClick={onClickDeactivate}
                >
                  Deactivate
                </Button>
              )}
            </>
          }
        >
          <div className="px-2">
            <Text fS="20px">{userProfileInfo.first_name}, we're sorry to see you go</Text>
            {step === 1 && (
              <Text fS="16px" className="mt-3">
                You'll loose your connections, messages, files in your disk and testimonials.
              </Text>
            )}

            {step === 2 && (
              <div className="mb-0 mt-4">
                <Text fS="16px" fW="500">
                  Tell us the reason for closing your account:
                </Text>

                {DEACT_REASONS.map((option, i) => {
                  return (
                    <RadioButton
                      id={i}
                      label={option.label}
                      changed={(e) => setDeactivationDetails((details) => ({ ...details, reason: e.target.value }))}
                      name="deactReason"
                      value={option.value}
                      containerClass="mt-2"
                    />
                  );
                })}
              </div>
            )}

            {step === 3 && (
              <div className="mb-0 mt-3">
                <Text fS="20px" className="pb-2">
                  Enter your password to close this account
                </Text>
                <Input
                  type="password"
                  onChange={(e) => setDeactivationDetails((details) => ({ ...details, password: e.target.value }))}
                  placeholder="Password"
                />
                <Checkbox
                  className={"settings-custom-checkbox"}
                  name="unsubscribeMail"
                  value="1"
                  label="Unsubscribe me from myBridge email communications including invitations."
                  onChange={(e) => setDeactivationDetails((details) => ({ ...details, unsubscribe: e.target.value }))}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Deactivation;
