import styled from 'styled-components';
import PostHeader from './PostHeader/PostHeader';
import PostBody from './PostBody/PostBody';
import PostFooter from './PostFooter/PostFooter';
import PostContent from './PostBody/PostContent';
import PostImage from './PostBody/PostImage';

export const PostCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${(props) => (props.repost ? '0px 0px 0px 50px' : '20px 0px 5px 0px')};
  border: ${(props) => {
    return props.repost ? 'none' : '1px solid #E5E5E5';
  }};
  border-radius: 8px;
  box-sizing: border-box;
  background: #ffffff;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '100%')};
  position: relative;
`;

PostCard.Header = PostHeader;
PostCard.Body = PostBody;
PostCard.Footer = PostFooter;
PostCard.Content = PostContent;
PostCard.Image = PostImage;
