
import React, { useEffect, useMemo, useState } from 'react';

import AvatarGroup from '@atlaskit/avatar-group';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Loader, { Spinner } from 'components/common/Loader';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  acceptInviteRequest,
  getInvitedMemberList,
  getRequestedMemberList,
  getTeamDetailsBySlug,
  getTeamMemberList,
  getTeamOrganiserList,
  joinTeamRequest,
  withdrawTeamRequest
} from 'store/slices/teamCommunitySlice';
import Banner from './Banner';
const TeamProfilePublic = () => {
  let { id, slug } = useParams();
  const dispatch = useDispatch();
  if (!id) {
    id = 'overview';
  }
  const [openTab, setOpenTab] = useState(id);

  
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [gotTeamsData, setGotTeamsData] = useState(false);
  const [membersList, setMembersList] = useState();
  const [teamOverviewMembers, setTeamOverviewMembers] = useState();
  
  const { teamSlugPage, teamMembers, userWithdrawn, teamOrganisers, resquestedMembers, invitedMembers, teamId, loading, userRequested, userAcceptedInvite } =
    useSelector((state) => state.teamsCommunity);
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const navigate = useNavigate()

  useEffect(() => {
    if (slug.length && id) {
      fetchAll()
    }
  }, [id, slug]);

  useEffect(() => {
    if (teamMembers && teamOrganisers) {
      let found = false
      if (teamMembers?.length) {
        found = teamMembers?.find(tm => tm?.user?.id === userProfileInfo?.id)
      }
      if (!found && teamOrganisers?.length) {
        found = teamOrganisers?.find(tm => tm?.user?.id === userProfileInfo?.id)
      }
      if (found) {
        navigate("/teams/profile/" + teamSlugPage?.slug)
      }
    }
  }, [
    teamMembers, teamOrganisers, teamSlugPage
  ])

  const fetchAll = () => {
    dispatch(getTeamDetailsBySlug(slug)).then((res) => {
      const teamid = res?.payload?.[0]?.id;
      dispatch(getTeamMemberList({ for_team_community_id: teamid, list_type: 'member' }));
      dispatch(getTeamOrganiserList({ for_team_community_id: teamid, list_type: 'organizer' }));
      dispatch(getInvitedMemberList({ for_team_community_id: teamid, list_type: 'invited' }));
      dispatch(getRequestedMemberList({ for_team_community_id: teamid, list_type: 'requested' }));
      checkUserIsAdmin();
      setTeamOverviewMembers(teamMembers);
      setGotTeamsData(true);
    });
  }

  useEffect(() => {
    if (_.includes(teamSlugPage.organizers, userProfileInfo.id)) {
      setIsOrganizer(true);
    }
  }, [teamSlugPage]);

  useEffect(() => {
    if (teamMembers.length > 0) {
      const data = teamMembers?.map((d, i) => ({
        key: d.user.id,
        first_name: d.user.first_name,
        last_name: d.user.last_name,
        src: d.user.profile_pic,
      }));
      setMembersList(data);
    }
    // eslint-disable-next-line
  }, [teamMembers]);


  useEffect(() => {
    if (userRequested || userAcceptedInvite || userWithdrawn) {
      fetchAll()
    }
  }, [userRequested, userAcceptedInvite, userWithdrawn]);


  const checkUserIsAdmin = () => {
    _.forEach(teamOrganisers, function (users) {
      if (_.includes(users.user, userProfileInfo.id)) {
        setIsOrganizer(true);
      }
    });
  };

  const handleJoinRequest = () => {
    dispatch(joinTeamRequest({ for_team_community_id: teamId }))
  }

  const handleAcceptInvite = () => {
    dispatch(acceptInviteRequest({ for_team_community_id: teamId, user_id: userProfileInfo?.id, user_status: 'member' })).then(() => {
      navigate("/teams/profile/" + teamSlugPage?.slug)
    })
  }

  const handleWithdrawRequest = () => {
    dispatch(withdrawTeamRequest({ for_team_community_id: teamId, user_id: userProfileInfo?.id, user_status: 'member' })).then(() => {
      // navigate("/teams/profile/" + teamSlugPage?.slug)
    })
  }

  const alreadyRequested = useMemo(() => {
    return resquestedMembers?.find?.(rm => rm?.user?.id === userProfileInfo?.id)
  }, [resquestedMembers, userProfileInfo])

  const alreadyInvited = useMemo(() => {
    return invitedMembers?.find?.(rm => rm?.user?.id === userProfileInfo?.id)
  }, [invitedMembers, userProfileInfo])

  // console.log(requestedMembers, userProfileInfo, alreadyRequested)

  return (
    <React.Fragment>
      {gotTeamsData ? (
        <>
          <Banner
            handleAcceptInvite={handleAcceptInvite}
            handleJoinRequest={handleJoinRequest}
            handleWithdrawRequest={handleWithdrawRequest}
            alreadyRequested={alreadyRequested}
            alreadyInvited={alreadyInvited}
            teamDetails={teamSlugPage}
            teamOrganisers={teamOrganisers}
            teamMembers={teamMembers}
            openTab={openTab}
            setOpenTab={setOpenTab}
            isPublic={true}
            organiser={userProfileInfo}
            isOrganizer={isOrganizer}
          />
          <section className="Profile_Tab">
            <div className="container p-0">

              <div className="members-section mb-3">
                <h5 className="members-section--title">{teamMembers.length} members</h5>

                {teamMembers && membersList?.length > 0 ? (
                  <AvatarGroup
                    appearance="stack"
                    data={membersList}
                    overrides={{
                      Avatar: {
                        render(_, props, ind) {
                          console.log(props)
                          return <MyBridgeAvatar
                            key={ind}
                            size={60}
                            icon={(props?.src?.includes("placeholder") ? undefined : props?.src)}
                            name={props?.first_name + " " + props?.last_name}
                          />
                        }
                      }
                    }}
                  />
                ) : (
                  'No Users Found'
                )}

                {alreadyInvited ?
                  <Button className="btn-join-team btn-sm btn-secondary mt-3" onClick={handleAcceptInvite} disabled={loading}>
                    {loading ? <Spinner /> : "Accept Invite"}
                  </Button> :
                  <Button className="btn-join-team btn-sm btn-secondary mt-3" onClick={(alreadyRequested) ? handleWithdrawRequest : handleJoinRequest} disabled={loading}>
                    {loading ? <Spinner /> : (alreadyRequested ? "Withdraw Request" : "Join")}
                  </Button>
                }
              </div>

              <div className="members-section fullpage p-1 mb-3">
                <h1 className="members-section--header m-0">About this team</h1>

                {teamSlugPage.desc?.length ? <div className="members-section--desc">
                  <h3>Description</h3>
                  <p>{teamSlugPage.desc}</p>
                </div> : ""}
                <div className="members-section--desc">
                  <h3>Details</h3>
                  <b>Discoverability</b>
                  <br />
                  {teamSlugPage?.discoverability === true ? 'Listed Team' : 'Unlisted Team'}
                  <br />
                  <b>Created</b>
                  <br />
                  {moment(teamSlugPage?.created_at).format('MMMM YYYY')}
                </div>
                {teamSlugPage.rules?.length ? <div className="members-section--desc">
                  <h3>Rules</h3>
                  <p>{teamSlugPage.rules}</p>
                </div> : ""}
              </div>
            </div>
          </section>
        </>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default TeamProfilePublic;
