import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import AvatarGroup from 'react-avatar-group';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';

import message from 'assets/icons/message.svg';
import ellipse from 'assets/icons/ellipse.svg';
import share from 'assets/icons/share.svg';
import report from 'assets/icons/report.svg';
import block from 'assets/icons/block.svg';
import pdf from 'assets/icons/pdf.svg';
import chat from 'assets/icons/chat.svg';
import unfollow from 'assets/icons/unfollow.svg';
import firstConnection from 'assets/icons/firstConnection.svg';
import otherConnection from 'assets/icons/otherConnection.svg';

import { Tab, TabContent, HorizontalTabs } from 'components/common/Tabs/Tabs';
import { BorderedContainer, Text } from 'components/StyledComponents';
import { Input } from 'components/common/Input';
import { Search } from 'components/common/Icons';

import NetworkList from './NetworkList';
import UserConnections from 'pages/Profile/Individual/ProfileSection/ConnectionCard/UserConnections';

import { getProfileViewers } from 'store/slices/userProfileSlice';
import { getUserConnectionsData, getSharedConnectionsData } from 'store/slices/connectionSlice';
import { MyBridgeAvatar } from 'components/common/Avatar';

const NetworkContainer = styled.div``;

const MeetButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;
  gap: 8px;
  width: 107.68px;
  height: 36px;
  background: #e4e6eb;
  border-radius: 5px;
  border: none;
`;

const MenuList = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 8px;
  list-style: none;
  top: -12rem;
  left: -4rem;
  overflow: hidden;
  z-index: 10;
  box-shadow: 0px 0px 5px 0px #bfbdbd;
`;

const MenuItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 8px;
  background: #ffffff;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f3ba86;
    color: #fff;
  }
`;

const NetworkTab = (props) => {
  const { slug, tid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profileViewers, publicProfileData } = useSelector((state) => state.userProfile);
  const { data, mutualConnections } = useSelector((state) => state.connection);
  const allConnections = data.results;

  const [openTab, setOpenTab] = useState(tid || 'all');
  const [searchText, setSearchText] = useState('');
  const [allConnectionPage, setAllConnectionPage] = useState(1);
  const [showMenu, setShowMenu] = useState(null);

  useEffect(() => {
    if (tid === 'all') {
      dispatch(getUserConnectionsData(publicProfileData.id));
    } else {
      dispatch(getSharedConnectionsData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  useEffect(() => {
    dispatch(getProfileViewers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <NetworkContainer className="row gap-4 gap-md-0">
      <div className="col-12 col-sm-12 col-md-9 col-xl-9">
        <BorderedContainer overflow="visible">
          <div className="user__tabs d-flex pt-3">
            <HorizontalTabs className="user_tabs_item min-w-300 pt-2">
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`${slug}/network`} to="all">
                All Network
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`${slug}/network`} to="mutual">
                Mutual Network
              </Tab>
            </HorizontalTabs>
            <Input
              type="url"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              startIcon={<Search />}
              className="rounded border-none"
              containerClass="d-flex pe-3"
            />
          </div>

          <div className="row p-2">
            <div className="col-12 col-md col-lg-12 col-xl-12">
              <TabContent tab="all" openTab={openTab}>
                {allConnections?.map((connection) => {
                  const { id, first_name, last_name, position, profile_pic, degree_count } = connection;
                  const userName = `${first_name} ${last_name}`;

                  return (
                    <div className="d-flex my-3 pb-2 border-bottom justify-content-between">
                      <div className="d-flex gap-3 ms-3">
                        <MyBridgeAvatar size={40} name={userName} icon={profile_pic} />

                        <div className="d-flex flex-column gap-1">
                          <Text fS="15px" fW={500} color="#3D5A80">
                            {userName}
                          </Text>
                          <Text fS="13px" color="#5B5B5B">
                            {position}
                          </Text>
                          <div className="d-flex align-items-center justify-content-center">
                            <Text fS="12px" color="#747474" className="me-3">
                              {degree_count.first}{' '}
                              <img src={firstConnection} alt="" />{' '}
                              1st Degrees
                            </Text>

                            <Text fS="12px" color="#747474" className="me-3">
                              {degree_count.second}{' '}
                              <img src={otherConnection} alt="" />{' '}
                              2nd Degrees
                            </Text>

                            <Text fS="12px" color="#747474">
                              {degree_count.third}{' '}
                              <img src={otherConnection} alt="" />{' '}
                              3rd Degrees
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center pe-3 position-relative gap-3">
                        <MeetButton>
                          <img src={message} alt="" />
                          <Text fS="15px">Message</Text>
                        </MeetButton>

                        <img
                          src={ellipse}
                          alt=""
                          onClick={() => setShowMenu((showMenu) => (showMenu === id ? null : id))}
                        />
                        {showMenu === id && (
                          <MenuList>
                            <MenuItem>
                              <img src={share} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Shared Connection
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={report} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Report
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={block} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Block
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={pdf} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Save to pdf
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={chat} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Ask for Testimonial
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={unfollow} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Unfollow
                              </Text>
                            </MenuItem>
                          </MenuList>
                        )}
                      </div>
                    </div>
                  );
                })}
              </TabContent>
              <TabContent tab="mutual" openTab={openTab}>
                {mutualConnections?.map((connection) => {
                  const { id, first_name, last_name, position, profile_pic, degree_count } = connection;
                  const userName = `${first_name} ${last_name}`;

                  return (
                    <div className="d-flex my-3 pb-2 border-bottom justify-content-between">
                      <div className="d-flex gap-3 ms-3">
                        <MyBridgeAvatar size={40} name={userName} icon={profile_pic} />

                        <div className="d-flex flex-column gap-1">
                          <Text fS="15px" fW={500} color="#3D5A80">
                            {userName}
                          </Text>
                          <Text fS="13px" color="#5B5B5B">
                            {position}
                          </Text>
                          <div className="d-flex align-items-center justify-content-center">
                            <Text fS="12px" color="#747474" className="me-2">
                              {degree_count.first}
                              <img src={firstConnection} alt="" />
                              1st Degrees
                            </Text>

                            <Text fS="12px" color="#747474" className="me-2">
                              {degree_count.second}
                              <img src={otherConnection} alt="" />
                              2nd Degrees
                            </Text>

                            <Text fS="12px" color="#747474">
                              {degree_count.third}
                              <img src={otherConnection} alt="" />
                              3rd Degrees
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center pe-3 position-relative gap-3">
                        <MeetButton>
                          <img src={message} alt="" />
                          <Text fS="15px">Message</Text>
                        </MeetButton>

                        <img
                          src={ellipse}
                          alt=""
                          onClick={() => setShowMenu((showMenu) => (showMenu === id ? null : id))}
                        />
                        {showMenu === id && (
                          <MenuList>
                            <MenuItem>
                              <img src={share} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Shared Connection
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={report} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Report
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={block} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Block
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={pdf} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Save to pdf
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={chat} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Ask for Testimonial
                              </Text>
                            </MenuItem>
                            <MenuItem>
                              <img src={unfollow} alt="" />
                              <Text fs="15px" color="#5B5B5B">
                                Unfollow
                              </Text>
                            </MenuItem>
                          </MenuList>
                        )}
                      </div>
                    </div>
                  );
                })}
              </TabContent>
            </div>
          </div>
        </BorderedContainer>
      </div>

      <div className="col-12 col-sm-12 col-md-3 col-xl-3">
        <div className="row gap-4">
          <div className="col-12">
            <BorderedContainer className={`px-3`}>
              <UserConnections />
              <Text
                className="cursor-pointer py-3 px-1"
                fS="16px"
                color="#003049"
                onClick={() => navigate('/testimonial')}
              >
                Give or Ask for a Testimonial
              </Text>
            </BorderedContainer>
            <BorderedContainer className={`px-3 pt-3`}>
              <div className="mx-3">
                <Text fS="16px" color="#5B5B5B">
                  Your profile has been viewed by {profileViewers?.length} people in the past days
                </Text>
                {profileViewers?.length > 0 && (
                  <div className="d-inline-flex pt-3">
                    <AvatarGroup
                      avatars={profileViewers?.map((e) => startCase(e.first_name))}
                      initialCharacters={1}
                      max={3}
                      size={32}
                      shadow={0}
                      displayAllOnHover={false}
                      style={{ flex: 'auto' }}
                    />
                    <div
                      style={{ flex: '10', display: 'flex', alignItems: 'center' }}
                      onClick={() => navigate('/profile-views')}
                    >
                      <Text className="cursor-pointer" fSize="13px" color="#3D5A80">
                        {profileViewers?.length > 3 && `+${profileViewers?.length}`} viewing your profile
                      </Text>
                    </div>
                  </div>
                )}
              </div>

              <div className="border-bottom pt-3" />

              <div className="p-3">
                <Text className="cursor-pointer" fS="16px" color="#3D5A80" onClick={() => navigate('/invitations')}>
                  Invitations
                </Text>
              </div>
            </BorderedContainer>
          </div>
        </div>
      </div>
    </NetworkContainer>
  );
};

export default NetworkTab;