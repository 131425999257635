import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/react";
import PropTypes from "prop-types";

import { CometChat } from "@cometchat-pro/chat";

import { CometChatContext } from "../../../util/CometChatContext";
import { Storage } from "../../../util/Storage";
import * as enums from "../../../util/enums.js";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";
import { LocalizedString } from "./strings";

import {
	callScreenBackgroundStyle,
	callScreenContainerStyle,
	callScreenInnerBackgroundStyle,
	callScreenHeaderStyle,
	callScreenWrapperStyle,
	headerTitleStyle,
	headerButtonStyle,
	callScreenResizerStyle,
	iconStyle,
} from "./style";

import minimizeIcon from "./resources/reduce-size.svg";
import maximizeIcon from "./resources/increase-size.svg";
import Meeting from "components/Meeting";

class CometChatCallScreen extends React.PureComponent {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.state = {
			x: 0,
			y: 0,
			rel: null, // position relative to the cursor
			dragging: false,
			maximized: true,
			meetingId: null,
			startMeeting:false
		};

		this.loggedInUser = props.loggedInUser;

		this.callScreenBackgroundEl = React.createRef();
		this.callScreenInnerBackgroundEl = React.createRef();
		this.callScreenEl = React.createRef();
		this.callScreenFrame = React.createRef();
	}

	componentDidMount() {
		this.document = window.document;

		if (this.props.call.type === enums.CUSTOM_TYPE_MEETING) {
			this.startDirectCall(this.props.call);
		} else {
			this.startDefaultCall(this.props.call);
		}
	}

	

	startDirectCall = (call) => {
		const meetingCode = parseInt(Math.random().toFixed(6).replace('0.', ''));
		// const sessionId = parseInt(`6015${meetingCode}`);
		const sessionId = call.data.customData.sessionID;
		this.setState({ meetingId: sessionId })
		this.setState({startMeeting:true})
		// const customCSS = this.context.UIKitSettings.customCSS;
		// const showRecordingButton =
		// 	this.context.UIKitSettings.showCallRecordingOption;

		// const callSettings = new CometChat.CallSettingsBuilder()
		// 	.enableDefaultLayout(true)
		// 	.setSessionID(sessionId)
		// 	.setIsAudioOnlyCall(false)
		// 	.showRecordingButton(showRecordingButton)
		// 	.setCustomCSS(customCSS)
		// 	.setLocalizedStringObject(LocalizedString(this.props.lang))
		// 	.build();

		// const el = this.callScreenFrame;
		// CometChat.startCall(
		// 	callSettings,
		// 	el,
		// 	new CometChat.OngoingCallListener({
		// 		onCallEnded: (call) => {
		// 			if (this.context) {
		// 				this.context.setCallInProgress({}, "");
		// 			}
		// 			Storage.removeItem(enums.CONSTANTS["ACTIVECALL"]);
		// 			this.props.actionGenerated(enums.ACTIONS["DIRECT_CALL_ENDED"]);
		// 		},
		// 		onError: (error) => {
		// 			if (this.context) {
		// 				this.context.setCallInProgress(null, "");
		// 			}

		// 			this.props.actionGenerated(enums.ACTIONS["DIRECT_CALL_ERROR"]);
		// 			const errorCode =
		// 				error && error.hasOwnProperty("code") ? error.code : "ERROR";
		// 			this.context.setToastMessage("error", errorCode);
		// 		},
		// 	})
		// );
	};

	startDefaultCall = (call) => {
		const meetingCode = parseInt(Math.random().toFixed(6).replace('0.', ''));
		// const sessionId = parseInt(`6016${meetingCode}`);
		const sessionId = call.getSessionId();
		this.setState({ meetingId: sessionId })
		this.setState({startMeeting:true})
		// const callType = call.type === CometChat.CALL_TYPE.AUDIO ? true : false;
		// const customCSS = this.context.UIKitSettings.customCSS;
		// const showRecordingButton =
		// 	this.context.UIKitSettings.showCallRecordingOption;

		// const callSettings = new CometChat.CallSettingsBuilder()
		// 	.setSessionID(sessionId)
		// 	.enableDefaultLayout(true)
		// 	.setMode(CometChat.CALL_MODE.DEFAULT)
		// 	.setIsAudioOnlyCall(callType)
		// 	.showRecordingButton(showRecordingButton)
		// 	.setCustomCSS(customCSS)
		// 	.setLocalizedStringObject(LocalizedString(this.props.lang))
		// 	.build();

		// const el = this.callScreenFrame;
		// CometChat.startCall(
		// 	callSettings,
		// 	el,
		// 	new CometChat.OngoingCallListener({
		// 		onUserJoined: (user) => {
		// 			/* Notification received here if another user joins the call. */
		// 			/* this method can be use to display message or perform any actions if someone joining the call */
		// 			//call initiator gets the same info in outgoingcallaccpeted event
		// 			if (
		// 				call.callInitiator.uid !== this.loggedInUser.uid &&
		// 				call.callInitiator.uid !== user.uid
		// 			) {
		// 				const callMessage = {
		// 					category: call.category,
		// 					type: call.type,
		// 					action: call.action,
		// 					status: call.status,
		// 					callInitiator: call.callInitiator,
		// 					callReceiver: call.callReceiver,
		// 					receiverId: call.receiverId,
		// 					receiverType: call.receiverType,
		// 					sentAt: call.sentAt,
		// 					sender: { ...user },
		// 				};
		// 				this.props.actionGenerated(
		// 					enums.ACTIONS["USER_JOINED_CALL"],
		// 					callMessage
		// 				);
		// 			}
		// 		},
		// 		onUserLeft: (user) => {
		// 			/* Notification received here if another user left the call. */
		// 			/* this method can be use to display message or perform any actions if someone leaving the call */
		// 			//call initiator gets the same info in outgoingcallaccpeted event
		// 			if (
		// 				call.callInitiator.uid !== this.loggedInUser.uid &&
		// 				call.callInitiator.uid !== user.uid
		// 			) {
		// 				const callMessage = {
		// 					category: call.category,
		// 					type: call.type,
		// 					action: "left",
		// 					status: call.status,
		// 					callInitiator: call.callInitiator,
		// 					callReceiver: call.callReceiver,
		// 					receiverId: call.receiverId,
		// 					receiverType: call.receiverType,
		// 					sentAt: call.sentAt,
		// 					sender: { ...user },
		// 				};

		// 				this.props.actionGenerated(
		// 					enums.ACTIONS["USER_LEFT_CALL"],
		// 					callMessage
		// 				);
		// 			}
		// 		},
		// 		onCallEnded: (endedCall) => {
		// 			/* Notification received here if current ongoing call is ended. */
		// 			if (this.context) {
		// 				this.context.setCallInProgress(null, "");
		// 			}
		// 			Storage.removeItem(enums.CONSTANTS["ACTIVECALL"]);
		// 			this.props.actionGenerated(
		// 				enums.ACTIONS["OUTGOING_CALL_ENDED"],
		// 				endedCall
		// 			);
		// 			/* hiding/closing the call screen can be done here. */
		// 		},
		// 	})
		// );
	};

	closeMeeting(value) { 
		this.setState({startMeeting:value})
	}

	render() {
	

		return (
			
			<React.Fragment>
				{this.state.startMeeting && <Meeting meetingID={this.state.meetingId} meetingState={(close) => this.closeMeeting(close)} callInfo={this.props.call} callType={this.props.call.type} />}
			</React.Fragment>
		);
	}
}

// Specifies the default values for props:
CometChatCallScreen.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
	minWidth: 400,
	minHeight: 300,
	maxWidth: "100%",
	maxHeight: "100%",
	style: {},
};

CometChatCallScreen.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	minWidth: PropTypes.number,
	minHeight: PropTypes.number,
	maxWidth: PropTypes.string,
	maxHeight: PropTypes.string,
	style: PropTypes.object,
};

export { CometChatCallScreen };
