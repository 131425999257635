import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode'

import { googleSignIn } from 'store/slices/authSlice';
import { GmailIcon } from 'components/common/Icons';

export default function GoogleLoginComponent({ path }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const { access } = useSelector((state) => state.user);

  function handleCallbackResponse(response) {
    // eslint-disable-next-line no-undef
    const userObj = jwt_decode(response.credential)
    setUser(userObj)
    const singInObj = {
      "id_token": response.credential,
      "email": userObj.email,
      "first_name": userObj.given_name,
      "last_name": userObj.family_name
    }
    dispatch(googleSignIn(singInObj));
    // document.getElementById("signInDiv").hidden = true;
  }
  useEffect(() => {
    // eslint-disable-next-line no-undef
    try {
      // eslint-disable-next-line no-undef
      if (google) {
        // eslint-disable-next-line no-undef
        google?.accounts?.id.initialize({
          client_id: process.env.REACT_APP_CLIENT_ID,
          callback: handleCallbackResponse
        })
        // eslint-disable-next-line no-undef
        google.accounts.id.renderButton(
          document.getElementById(path), {
          theme: 'outline', size: 'large'
        }
        )
      }
    } catch (e) {
      console.error(e);
    }
  }, [path])

  useEffect(() => {
    if (access) {
      navigate('/')
    }
  }, [])

  // const onLogoutSuccess = () => {
  // }

  return (
    <>
      <div className='d-flex align-items-center justify-content-center' id={path}>
        <button className="social-login">
          <GmailIcon width={35} height={26} />
          <span className="ms-2">
            Login with Gmail
          </span>
        </button>
      </div>
    </>

  )
}

