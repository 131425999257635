import React, { useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// import { listDraft, listPublished, deleteBlog, setTitle, setCoverImage, setDescription } from 'store/slices/blogSlice';
import styles from './style.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getPostFunc } from 'api/post';
import Loader from 'components/common/Loader';
import PostContext from 'context/PostContext';
import PostHeaderLeft from 'components/PostCard/PostHeader/PostHeaderLeft';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { Flex, Text } from 'components/core';
import InsightPalate from 'components/PostCard/PostFooter/InsightPalate';
import FooterActions from 'components/PostCard/PostFooter/FooterActions';
import Comments from 'components/PostCard/Comment';
import CommentBox from 'components/PostCard/Comment/CommentBox';
import FollowButton from './FollowButton';
import DeletePostModal from 'pages/Dashboard/Feed/DeletePostModal';
import { isDraft } from '@reduxjs/toolkit';
import EventJoinButton from './EventJoinButton';
import EditEvent from './EditEvent';
import SeeParticipants from './SeeParticipantsButton';
import styled from 'styled-components';
import Modal from 'components/common/Modal/Modal';
import EventForm from 'pages/Event/EventForm';
import { editEventFunc } from 'api/event';
import ParticipantList from './ParticipantList';
import queryString from 'query-string';
import { useSelector } from 'react-redux';

const StarteEventDate = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b5b;
  span {
    color: #00875a;
  }
`;

const EndEventDate = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b5b;
  span {
    color: #e13e3e;
  }
`;

const EventTime = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b5b;
  margin-left: 10px;
`;

const Description = styled.div`
  font-family: 'Inter';
  font-style: normal;
  line-height: 120%;
  color: #5b5b5b;
  margin-top: 10px;
  ol,
  ul {
    margin-left: 30px;
  }
`;

const Blogdetail = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const userId = userProfileInfo?.id;
  const { id } = useParams();
  const location = useLocation();
  const { pathname, search } = location;
  const parsed = queryString.parse(search);
  const { showParticipants } = parsed;
  // console.log({ showParticipants });
  const [showComments, setShowComments] = useState(true);
  const [deleted, setDeleted] = React.useState(false);
  const [editEventModal, setEditEventModal] = React.useState(false);
  const navigate = useNavigate();
  const IsDraft = pathname.includes('draft');
  const { data, isLoading, error, refetch } = useQuery(['BlogDetails', id], getPostFunc);

  const { mutate: editEventEvent, isLoading: editEventLoading } = useMutation(editEventFunc, {
    onSuccess: (response) => {
      setEditEventModal(false);
      refetch();
    },
    onError: (_error) => {
      console.log(_error);
    },
  });

  const value = useMemo(() => ({ postData: data }), [data]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  const { postData } = value;
  const { author, type, start_time, end_time, attendees } = postData;
  const isAuthor = author?.id === userId;
  const USER_NAME = author?.first_name + ' ' + author?.last_name;

  const imageSrc = postData?.cover_image || postData?.media?.[0]?.file;

  return (
    <PostContext.Provider value={value}>
      <React.Fragment>
        <div className="container" style={{ marginBottom: 100 }}>
          <div className={styles.blogDetailContainer}>
            <Flex justify="space-between">
              <Flex flex={5}>
                <h3>{postData?.title}</h3>
              </Flex>
              {isAuthor && (
                <Flex flex={1} justify="flex-end" align="center" margin="10px 0px 0px 0px">
                  <DeleteBlogButton onClick={() => setDeleted(true)}>Delete</DeleteBlogButton>
                  <EditBlogButton onClick={() => navigate(`/blog/new/${postData.id}`)}>Edit</EditBlogButton>
                </Flex>
              )}
            </Flex>
            <div className={styles.detailsFeaturedImage}>
              <img src={imageSrc} alt="" />
            </div>
            <Flex margin="24px 0px" padding="0px 16px" justify="space-between" align="center">
              <Flex>
                <MyBridgeAvatar size={40} name={author?.first_name} icon={author?.profile_pic} />
                <PostHeaderLeft />
              </Flex>

              <Flex justify="flex-end">
                {type === 2 && isAuthor && (
                  <EditEvent
                    onClick={() => {
                      if (type === 2) setEditEventModal(true);
                    }}
                  />
                )}
                {type === 2 && (
                  <Link to={showParticipants ? pathname : `${pathname}?showParticipants=true`}>
                    <SeeParticipants title={showParticipants ? 'Hide participants' : 'See participants'} />
                  </Link>
                )}
                {type === 2 && <EventJoinButton />}
                {type === 1 && !IsDraft && <FollowButton to={author.id} />}
              </Flex>
            </Flex>
            {type !== 2 && (
              <Flex margin="24px 0px" padding="0px 16px">
                <Text.Label>{moment(postData?.updated_at).format('MMM Do YYYY')}</Text.Label>
              </Flex>
            )}
            <Flex justify="space-between" align="center" margin="24px 0px" padding="0px 16px">
              {start_time && (
                <Flex>
                  <StarteEventDate>
                    <span>Start Date: </span>
                    {moment(start_time).format('DD/MM/YYYY')}
                  </StarteEventDate>
                  <EventTime>{moment(start_time).format('hh:mm A')}</EventTime>
                </Flex>
              )}
              {end_time && (
                <Flex justify="flex-end">
                  <EndEventDate>
                    <span>End Date: </span>
                    {moment(end_time).format('DD/MM/YYYY')}
                  </EndEventDate>
                  <EventTime>{moment(end_time).format('hh:mm A')}</EventTime>
                </Flex>
              )}
            </Flex>

            {showParticipants ? (
              <Flex margin="24px 0px" padding="0px 16px" width="100%">
                <ParticipantList attendees={attendees} />
              </Flex>
            ) : (
              postData?.description && (
                <Flex margin="24px 0px" padding="0px 16px" width="100%">
                  <Description
                    dangerouslySetInnerHTML={{ __html: postData?.description }}
                    style={{
                      padding: '16px 22px',
                      border: '1px solid #E4E6EB',
                      borderRadius: 8,
                      margin: '24px 0px',
                      minHeight: 200,
                      width: '100%',
                    }}
                    className={styles.blogDetailsMainBody}
                  />
                </Flex>
              )
            )}

            {isDraft && !showParticipants && (
              <Flex margin="24px 0px" padding="0px 16px" justify="center" align="center" direction="column">
                <InsightPalate />
                <FooterActions />
              </Flex>
            )}

            {isDraft && !showParticipants && (
              <Flex margin="24px 0px" padding="0px 0px">
                {showComments && <Comments author={author} id={id} />}
                <CommentBox name={USER_NAME} id={id} author={author} />
              </Flex>
            )}

            {deleted && (
              <DeletePostModal
                onClose={() => {
                  setDeleted(false);
                  navigate(`/blog/published`);
                }}
              />
            )}
          </div>
        </div>

        {editEventModal ? (
          <Modal onClose={() => setEditEventModal(false)} className="confirmation-modal" heading={<h3>Delete Post</h3>}>
            <EventForm
              edit
              onCancel={() => {
                setEditEventModal(false);
              }}
              onSubmit={(data) => {
                console.log(data);
                editEventEvent({ id: postData.id, body: data });
              }}
              editData={postData}
              loading={editEventLoading}
            />
          </Modal>
        ) : null}
      </React.Fragment>
    </PostContext.Provider>
  );
};

export default Blogdetail;

const DeleteBlogButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: #e41c19;
  width: 50.11px;
  height: 20px;
  border: none;
  background: none;
  margin-right: 16px;
`;

const EditBlogButton = styled.button`
  width: 48px;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: #3d5a80;
  border: none;
  background: none;
`;
