import React, { useEffect } from 'react';
import SearchBar from 'components/common/SearchBar';
import FilterSectionTeams from 'components/common/FilterSectionTeams';
import NetworkCard from './Components/NetworkCard';
import { users } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamDetailsBySlug, getTeamMemberList, getTeamOrganiserList } from 'store/slices/teamCommunitySlice';
import NonOrganizerNetworkCard from './Components/NonOrganizerNetworkCard';
import { getFollowers, getInvitations } from 'store/slices/connectionSlice';
const MembersTab = ({ isOrganizer }) => {
  const { teamSlugPage, teamMembers, teamOrganisers, teamId, usersUpdated, diskAccessUpdated } = useSelector((state) => state.teamsCommunity);
  const dispatch = useDispatch();
  const filters = [
    { label: 'Locations', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Current company', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Keyword', type: '', data: null, showInput: false, showKeywords: true },
  ];

  useEffect(() => {
    dispatch(getInvitations('sent'))
    dispatch(getFollowers('following'))
  }, [])

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamMemberList({ for_team_community_id: teamId, list_type: "member" }));
    }
  }, [usersUpdated, diskAccessUpdated]);

  return (
    <React.Fragment>
      <div className="membersContainer">
        <div className="membersContainer--Header">
          <div className="headerLeft">
            <h6>
              Members &bull; <span>{teamMembers?.length} person</span>
            </h6>
          </div>
          <div className="headerRight">
            <SearchBar />
          </div>
        </div>
        <div className="membersContainer--Filters">
          <FilterSectionTeams filters={filters} />
        </div>
        {isOrganizer ?
          <div className="membersContainer--CardList">
            {teamMembers.length > 0 ? teamMembers?.map?.((user, i) => (
              <NetworkCard allowMessage type="member" user={user.user} userObject={user} key={i} isOrganizer={isOrganizer} />
            )) : <p class="text-center mt-3">No Members Found</p>}
          </div> :
          <div className="nonOrganizerMembersContainer--CardList">
            {teamMembers.length > 0 ? teamMembers?.map?.((user, i) => (
              <NonOrganizerNetworkCard type="member" user={user.user} key={i} isOrganizer={isOrganizer} />
            )) : <p class="text-center mt-3">No Members Found</p>}
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default MembersTab;
