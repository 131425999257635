import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { CompanyAPIEndpoints } from '../common/endPoint';

export const clear = createAction('clear');

export const getActiveJobs = createAsyncThunk('getActiveJobs', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=active`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSavedJobs = createAsyncThunk('getSavedJobs', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/bookmark`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSearchJobs = createAsyncThunk('getSearchJobs', async (payload) => {
  try {
    let filters = [];
    const filterArr = ['title', 'location', 'onsite_remote', 'job_type', 'company', 'experience_level'];
    filterArr.forEach(el => {
      const filterKey = el === 'title' ? 'query' : el === 'onsite_remote' ? 'remote' : el
      let filterVal = payload[el];
      if(el === 'onsite_remote') {
        filterVal = payload[el] === 'remote' ? 'Y' : 'N'
      }
      payload[el] && (filters.push(`${filterKey}=${filterVal}`));
    })
    const obj = {
      url: `${CompanyAPIEndpoints.JobSearchAPI}/?${filters.join('&')}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const applyJob = createAsyncThunk('applyJob', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/apply`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const saveJobs = createAsyncThunk('saveJobs', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/bookmark`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getJobsDetail = createAsyncThunk('getJobsDetail', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${id}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const editJobDetail = createAsyncThunk('editJobDetail', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${id}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getApplyedJobs = createAsyncThunk('getApplyedJobs', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/apply`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getUserJobPreferences = createAsyncThunk('getUserJobPreferences', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.JobPreferences}/preference`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const setUserJobPreferences = createAsyncThunk('setUserJobPreferences', async (body) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.JobPreferences}/preference`,
      method: 'PUT',
      body:JSON.stringify(body),
      isFormData:true,
      headers:true
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const setRepostToEditPost = createAction('setRepostToEditPost');
