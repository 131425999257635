import React from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import PropTypes from 'prop-types';
import { SmileyIcon } from 'components/common/Icons';
import styled from 'styled-components';
import { Flex } from 'components/core';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { DropdownContainer } from './DropdownMenu';


const Container = styled.button`
  border: none;
  position: relative;
  background: transparent;
`;

const EmojiPicker = ({ onSelect }) => {
  // const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const ref = React.useRef(null);
  // useOnClickOutside(ref, () => {
  //   setShowEmojiPicker(false);
  // });

  return (
    <Container>
      <div ref={ref}>
        <SmileyIcon color="#5B5B5B" />
      </div>
      {/* {showEmojiPicker && ( */}
      <DropdownContainer
        targetRef={ref}
      >
        <Flex
          onClick={e => { e.stopPropagation() }}
          position="relative"
          // top="50px" width="300px" right="50px" 
          zIndex={2} >
          <Picker data={data} theme="light" skinTonePosition="none" onEmojiSelect={onSelect} />
        </Flex>
      </DropdownContainer>
      {/* )} */}
    </Container>
  );
};

EmojiPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

EmojiPicker.defaultProps = {
  onSelect: () => { },
};

export default EmojiPicker;
