import React from "react";
import { CometChatUI } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src";

class ChatUi extends React.Component {
  
  render() {

    return (
        <>
            <div className="chatProviderContainer">
                <CometChatUI />
            </div>
        </>
	);
	}
}

export default ChatUi;