import React, { useState } from 'react';
import { requestConnection } from "store/slices/connectionSlice";
import { followUser, unFollowUser } from "store/slices/followSlice";
import { HandShakeSimpleIcon } from "components/common/Icons";
import { useDispatch, useSelector } from 'react-redux';

const ButtonGroup = () => {
  const [follow, setFollow] = React.useState(true);
  const [friendRequest, setFriendRequest] = useState(false);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  const handleHandshake = () => {
    if (!friendRequest) {
      dispatch(requestConnection({ to_user_id: userProfileInfo?.id }));
    }
    setFriendRequest(true)
  };

  const handleFollow = () => {
    follow ? dispatch(followUser({ follow_to: userProfileInfo?.id })) : dispatch(unFollowUser({ follow_to: userProfileInfo?.id }));
    setFollow((prev) => !prev);
  };
  return (
    <div className="button-group">
      <button className={`btn btn-secondary`} onClick={handleHandshake}>
        <HandShakeSimpleIcon className="me-2" color={'#037F29'} width="16" height="16" />
        {!friendRequest ? 'Handshake' : 'Cancel'}
      </button>

      <button className={`btn btn-transparent`} onClick={handleFollow}>
        {follow ? 'Follow' : 'UnFollow'}
      </button>
    </div>
  )
}

export default ButtonGroup;