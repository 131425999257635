import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import API from "store/common/api";
import { APIEndPoints } from './../common/endPoint';

const initialState = {
    user: '',
    msg: '',
    loading: false,
    error: false,
    signInError: false,
    signInPage: false,
    email: '',
    id_token: '',
    refresh: '',
    access: '',
    isActivated: false,
    forgetPasswordMsg: '',
    loggedIn: false,
    userInactive: false,
    inactiveEmailId: '',
    changedEmail: false,
    emailMessage: null,
    userFirstLogin:false
}

export const signUpUser = createAsyncThunk('singupuser', async (body) => {
    const obj = {
        url: `${APIEndPoints.Register}`,
        method: 'POST',
        body: JSON.stringify(body),
        isNoToken: true
    }
    return await API(obj)
})

export const signInUser = createAsyncThunk('signinuser', async (body) => {
    const obj = {
        url: `${APIEndPoints.SignIn}/`,
        method: 'POST',
        body: JSON.stringify(body),
        isNoToken: true
    }
    return await API(obj)
})


export const requestTokenRefresh = createAsyncThunk('requestTokenRefresh', async (body) => {
    const obj = {
        url: `${APIEndPoints.SignIn}/refresh/`,
        method: 'POST',
        body: JSON.stringify(body),
        isNoToken: true
    }
    return await API(obj)
})

export const googleSignIn = createAsyncThunk('googleSignIn', async (body) => {
    const obj = {
        url: `${APIEndPoints.GoogleSign}`,
        method: 'POST',
        body: JSON.stringify(body),
        isNoToken: true
    }
    return await API(obj)
})

export const forgetPassword = createAsyncThunk('forgotpassword', async (body) => {
    const obj = {
        url: `${APIEndPoints.Forgotpassword}`,
        method: 'POST',
        body: JSON.stringify(body),
        isNoToken: true
    }
    return await API(obj)
})

export const resetPassword = createAsyncThunk('resetPassword', async (body) => {
    const obj = {
        url: `${APIEndPoints.ResetPassword}/`,
        method: 'POST',
        body: JSON.stringify(body),
        isNoToken: true
    }
    return await API(obj)
})
export const activateYourAccount = createAsyncThunk('activateYourAccount', async (otp) => {
    const obj = {
        url: `${APIEndPoints.Activate}/${otp}`,
        method: 'GET',
    }
    return await API(obj)
})

export const resendVerificationEmail = createAsyncThunk('resendVerificationEmail', async (body) => {
    const obj = {
        url: `${APIEndPoints.Register}/verification/send`,
        method: 'PUT',
        isNoToken: true,
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const changeEmailRequest = createAsyncThunk('changeEmailRequest', async (body) => {
    const obj = {
        url: `${APIEndPoints.InactiveUserChangeEmail}`,
        method: 'POST',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const setInactiveEmail = createAction('setInactiveEmail');
export const setUserFirstLogin = createAction('setUserFirstLogin');

const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addToken: (state, action) => {
            state.access = localStorage.getItem('access')
            state.refresh = localStorage.getItem('refresh')
        },
        addUser: (state, action) => {
            state.user = localStorage.getItem('user')
        },
        logout: (state, action) => {
            state.access = null;
            state.refresh = null;
            localStorage.clear();
            // localStorage.removeItem('access');
            // localStorage.removeItem('refresh');
        },
    },
    extraReducers: {
        [signUpUser.pending]: (state, action) => {
            state.pending = true
            state.error = false;
        },
        [signUpUser.fulfilled]: (state, action) => {
            state.pending = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                // state.msg = a ction.payload.msg;
                if (action.payload.email && action.payload.status_code === 400) {
                    state.msg = "User with email already exists. Sign In to continue";
                    state.error = true;
                } else {
                    state.msg = action.payload.message;
                    state.error = false;
                    state.access = action.payload.access;
                    state.refresh = action.payload.refresh;
                    if (action.payload.access) {
                        localStorage.setItem('access', action.payload.access);
                        localStorage.setItem('refresh', action.payload.refresh);
                    }
                    state.loggedIn = true;
                }
            }
        },
        [signUpUser.rejected]: (state, action) => {
            state.loading = false
        },

        /* sign in */

        [signInUser.pending]: (state, action) => {
            state.pending = true
        },
        [signInUser.fulfilled]: (state, action) => {
            state.pending = false;
            if (action.payload.code === "INACTIVE_USER") {
                state.loggedIn = false;
                state.userInactive = true;
                state.error = 'It seems your account is not active please activate your account!';
            } else if (action.payload.code === "INVALID_CREDS") {
                state.error = "Incorrect login credentials, if you don't have an account please Sign Up";
                state.loggedIn = false;
            } else {
                state.access = action.payload.access;
                state.refresh = action.payload.refresh;
                localStorage.setItem('access', action.payload.access)
                localStorage.setItem('refresh', action.payload.refresh)
                state.loggedIn = true;
            }

        },
        [signInUser.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Try again later or contact customer support'
        },

        [requestTokenRefresh.pending]: (state, action) => {
            state.pending = true
        },
        [requestTokenRefresh.fulfilled]: (state, action) => {
            state.pending = false;
            state.access = action.payload.access;
            localStorage.setItem('access', action.payload.access);
            state.loggedIn = true;

        },
        [requestTokenRefresh.rejected]: (state, action) => {
            state.loading = false
            state.error = 'Try again later or contact customer support'
        },

        /* Forget password actions */
        [forgetPassword.pending]: (state, action) => {
            state.loading = true
            state.error = false;
            state.forgetPasswordMsg = '';
        },
        [forgetPassword.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status_code === 400) {
                state.forgetPasswordMsg = action?.payload?.email[0]
                state.error = true;
            } else {
                state.forgetPasswordMsg = "Link is sent to your mail";
                //state.forgetPasswordMsg = action.payload.forgetPasswordMsg;
            }
        },
        [forgetPassword.rejected]: (state, action) => {
            state.loading = false
            state.error = true
            state.forgetPasswordMsg = "Link is not sent to your mail";
        },

        /* ResetPassword */


        [resetPassword.pending]: (state, action) => {
            state.loading = true
            state.error = false;
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload?.status === "OK") {
                state.msg = 'Password is updated'
            } else {
                state.msg = "Something is wrong";
                state.error = true;
            }
        },
        [resetPassword.rejected]: (state, action) => {
            state.loading = false
            state.error = true
            state.msg = "Link is not sent to your mail";
        },

        /* google sign in */

        [googleSignIn.pending]: (state, action) => {
            state.loading = true
            state.error = false;
        },
        [googleSignIn.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload?.access) {
                state.access = action.payload.access;
                state.refresh = action.payload.refresh;

                localStorage.setItem('access', action.payload.access)
                localStorage.setItem('refresh', action.payload.refresh)
            } else {
                state.error = true
            }

            state.loggedIn = true;
        },
        [googleSignIn.rejected]: (state, action) => {
            state.loading = false
            state.error = true
            //  state.msg = "not logged in";
        },
        /* activate account */
        [activateYourAccount.pending]: (state, action) => {
            state.loading = true
            state.error = false;
            state.isActivated = false;
        },
        [activateYourAccount.fulfilled]: (state, action) => {
            state.loading = false;
            state.access = action.payload.access;
            state.refresh = action.payload.refresh;
            if (action.payload.access) {
                state.isActivated = true;
                localStorage.setItem('access', action.payload.access)
                localStorage.setItem('refresh', action.payload.refresh)
            } else {
                state.error = true;
            }
        },
        [activateYourAccount.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
            state.msg = "not logged in";
        },

        /* Resend Verification Link */
        [resendVerificationEmail.pending]: (state, action) => {
            state.loading = true
            state.error = false;
            state.isActivated = false;
        },
        [resendVerificationEmail.fulfilled]: (state, action) => {
            state.loading = false;
            state.isActivated = false;
            state.verifyMailSent = true;
            state.error = false;
        },
        [resendVerificationEmail.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
            state.msg = "not logged in";
        },
        /* Change Email ID Request */
        [changeEmailRequest.pending]: (state, action) => {
            state.loading = true
            state.error = false;
            state.isActivated = false;
        },
        [changeEmailRequest.fulfilled]: (state, action) => {
            state.loading = false;
            state.isActivated = false;
            if (action.payload.status_code === 200) {
                state.changedEmail = true;
                state.error = false;
            } else {
                state.changedEmail = false;
                state.emailMessage = action.payload.detail;
                state.error = true;

            }

        },
        [changeEmailRequest.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
            state.msg = "not logged in";
        },

        [setInactiveEmail]: (state, action) => {
            state.inactiveEmailId = action.payload
        },
        [setUserFirstLogin]: (state, action) => {
            state.userFirstLogin = action.payload
        }
    }
})

export const { addUser, addToken, logout } = authSlice.actions;
export default authSlice.reducer