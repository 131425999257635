import { Layout } from 'components/Layouts';
import MyDiskMain from 'components/MyDisk';
import { Arrowback } from 'components/common/Icons';
import Loader from 'components/common/Loader';
import { TabContent } from 'components/common/Tabs/Tabs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getInvitedMemberList, getRequestedMemberList, getTeamDetailsBySlug, getTeamMemberList, getTeamOrganiserList } from 'store/slices/teamCommunitySlice';
import Banner from './Banner';
import ManageTeam from './ManageTeam';
import Overview from './Overview';

const TeamProfile = () => {
  let { id, slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!id) {
    id = 'overview';
  }
  const [openTab, setOpenTab] = useState(id);

  const [isOrganizer, setIsOrganizer] = useState(false);
  const [gotTeamsData, setGotTeamsData] = useState(false);
  const { teamSlugPage, teamMembers, teamOrganisers, isTeamUpdated } = useSelector(
    (state) => state.teamsCommunity,
  );
  const [checkForRedirect, setCheckForRedirect] = useState(false)
  const { userProfileInfo } = useSelector((state) => state.userProfile);



  useEffect(() => {
    setOpenTab(id)
  }, [id])

  useEffect(() => {
    fetchAll()
  }, []);

  useEffect(() => {
    if (isTeamUpdated) {
      dispatch(getTeamDetailsBySlug(slug))
    }
  }, [isTeamUpdated]);

  useEffect(() => {
    _.forEach(teamOrganisers, function (user, key) {
      if (user.user.id === userProfileInfo.id) {
        setIsOrganizer(true);
      }
    });
  }, [teamOrganisers]);

  useEffect(() => {
    if (checkForRedirect) {
      redirectIfNotMember()
    }
  }, [checkForRedirect])

  const fetchAll = () => {
    dispatch(getTeamDetailsBySlug(slug)).then((res) => {
      const teamid = res.payload?.[0]?.id;
      dispatch(getTeamOrganiserList({ for_team_community_id: teamid, list_type: 'organizer' })).then(() => {
        dispatch(getRequestedMemberList({ for_team_community_id: teamid, list_type: 'requested' }));
        dispatch(getInvitedMemberList({ for_team_community_id: teamid, list_type: 'invited' }));
        dispatch(getTeamMemberList({ for_team_community_id: teamid, list_type: 'member' })).then(() => {
          setCheckForRedirect(true)
        });
        setGotTeamsData(true);
      });
    });
  }

  const redirectIfNotMember = () => {
    console.log("====", teamSlugPage, teamMembers, teamOrganisers, userProfileInfo)
    if (teamSlugPage?.id && teamMembers && teamOrganisers && userProfileInfo) {
      let found = false
      if (teamMembers?.length) {
        found = teamMembers?.find(tm => tm?.user?.id === userProfileInfo?.id)
      }
      if (!found && teamOrganisers?.length) {
        found = teamOrganisers?.find(tm => tm?.user?.id === userProfileInfo?.id)
      }
      if (!found) {
        navigate("/t/" + teamSlugPage?.slug)
      }
      console.log("...no need")
    }
  }

  return (
    <React.Fragment>
      <section className='header-container container pt-4'>
        <span className='back-link'>
          <Link to="/teams">
            <Arrowback color="black" /> Back
          </Link>
        </span>
      </section>
      {gotTeamsData ? (
        <section className="container  profile__section">
          <Banner refresh={fetchAll} openTab={openTab} setOpenTab={setOpenTab} isPublic={false} />
          <section className="Profile_Tab">
            <div className="container p-0">
              <TabContent tab="overview" openTab={id}>
                <Overview
                  teamDetails={teamSlugPage}
                  teamMembers={teamMembers}
                  isOrganizer={isOrganizer}
                />
              </TabContent>
              <TabContent tab="team_disk" openTab={id}>
                <MyDiskMain teamOrganizers={teamOrganisers} diskType="teamDisk" team={teamSlugPage} teamId={teamSlugPage.id} />
              </TabContent>
              <TabContent tab="manage_team" openTab={id}>
                <ManageTeam isOrganizer={isOrganizer} teamDetails={teamSlugPage} slug={slug} />
              </TabContent>
            </div>
          </section>
        </section>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default TeamProfile;
