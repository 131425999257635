import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "store/common/api";
import { APIEndPoints } from '../common/endPoint';

const initialState = {
    msg: '',
    loading: false,
    addingCard: false,
    error: false,
    cardInfo: {},
    isCardUpdated: false,
}

export const cardList = createAsyncThunk('cardList', async (body) => {
    const obj = {
        url: `${APIEndPoints.PaymentCards}`,
        method: 'GET'
    }
    return await API(obj);
})

export const addCard = createAsyncThunk('addCard', async (body) => {
    const obj = {
        url: `${APIEndPoints.PaymentCards}`,
        method: 'POST',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const updateCard = createAsyncThunk('updateCard', async (body) => {
    const obj = {
        url: `${APIEndPoints.PaymentCards}`,
        method: 'PATCH',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const deleteCard = createAsyncThunk('deleteCard', async (id) => {
    const obj = {
        url: `${APIEndPoints.PaymentCards}${id}/`,
        method: 'DELETE',
    }
    return await API(obj)
})

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
    },
    extraReducers: {
        [cardList.pending]: (state, action) => {
            state.pending = true;
            state.loading = true;
            state.error = false;
        },
        [cardList.fulfilled]: (state, action) => {
            state.loading = false;
            state.pending = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action.payload.msg;
                state.cardInfo = action.payload;
            }
        },
        [cardList.rejected]: (state, action) => {
            state.loading = false
        },

        [addCard.pending]: (state, action) => {
            state.pending = true
            state.addingCard = true
            state.error = false;
            state.isCardUpdated = false;
        },
        [addCard.fulfilled]: (state, action) => {
            state.pending = false;
            state.addingCard = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action && 'Card added successfully';
                state.isCardUpdated = true;
            }
        },
        [addCard.rejected]: (state, action) => {
            state.addingCard = false
        },

        [updateCard.pending]: (state, action) => {
            state.pending = true
            state.loading = true
            state.error = false;
            state.isCardUpdated = false;
        },
        [updateCard.fulfilled]: (state, action) => {
            state.pending = false;
            state.loading = false
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action.payload.msg;
                state.isCardUpdated = true;
            }
        },
        [updateCard.rejected]: (state, action) => {
            state.loading = false
        },

        /* delete cards */

        [deleteCard.pending]: (state, action) => {
            state.pending = true
            state.loading = true
            state.error = false;
            state.isCardUpdated = false;
        },
        [deleteCard.fulfilled]: (state, action) => {
            state.pending = false;
            state.loading = false
            console.log("deleted", action.payload)
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action && 'Card deleted successfully';
                state.isCardUpdated = true;
            }
        },
        [deleteCard.rejected]: (state, action) => {
            state.loading = false
            state.isCardUpdated = true;
        },


    }
})

export default paymentSlice.reducer