import React from 'react';
const CardHeading = ({ Title, subTitle, action }) => {
  return (
    <div className="heading">
      <div>
        <h5 className="jobs-title">{Title}</h5>
        {subTitle && <p>{subTitle}</p>}
      </div>
      {action && action}
    </div>
  );
};
export default CardHeading;
