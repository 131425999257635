import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Text } from 'components/StyledComponents';
import Feed from 'pages/Dashboard/Feed';

const IntroductionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const SeeMoreLessText = styled.p`
  color: var(--heading, #3d5a80);
  font-size: 15px;
  font-family: Inter;
  line-height: 30px;
  text-align: right;
  margin-top: -20px;
  cursor: pointer;
`;

export default function Introduction(props) {
  const { publicProfileData } = useSelector((state) => state.userProfile);
  const { profile_headline, profile_intro } = publicProfileData || {};
  const [isSeeMore, setSeeMore] = useState(false);
  return (
    <div className="profile__introduction__section">
      <IntroductionHeader>
        <Text fW={600} fS="16px">
          {profile_headline}
        </Text>
      </IntroductionHeader>

      <Text fS="16px" className="mt-3">
        {profile_intro?.length > (!isSeeMore ? 250 : profile_intro?.length)
            ? `${profile_intro?.substring(0, 250)}...`
            : profile_intro}

        {profile_intro?.length > 250 && (
          <SeeMoreLessText onClick={() => setSeeMore(!isSeeMore)}>See {!isSeeMore ? 'more' : 'less'}</SeeMoreLessText>
        )}
      </Text>
      <div className="row" style={{ paddingTop: 24 }}>
        <Feed type="myActivity" showModal isPublic={true}/>
      </div>
    </div>
  );
}
