import Checkbox from 'components/common/Checkbox';
import {
  Chart,
  CheckMark,
  ChevronDown,
  Documents,
  Filters,
  FolderIcon,
  Music,
  Others,
  PDF,
  Pictures,
  Sheet,
  Videos
} from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { diskSortList, lastModifyValue, propleList, sortList } from 'pages/Profile/constants';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSelectedFile } from 'store/actions/myDisk.Action';
import { DiskFilesListing } from './common/DiskFilesListing';
import { DiskListingHeader } from './common/DiskListingHeader';
import { FileTypes, LastModified, MimeTypes } from './common/constants';
import moment from 'moment';

const SearchResult = (props) => {
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const { searchResult } = useSelector((state) => state.myDiskReducer);
  const [openDropDown, setOpenDropDown] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [activeMenu, setActiveMenu] = useState()
  const [overlay, setOverlay] = useState()
  const [sortBy, setSortBy] = useState(diskSortList?.[0])
  const [filters, setFilters] = useState({})
  const dispatch = useDispatch()
  const ref = useRef();
  const ddRef = useRef();

  useEffect(() => {
    const filters_ = { ...(filters ?? {}) }
    if (Array.isArray(filters_.last_modified) && filters_.last_modified?.length > 1) {
      filters_.start_date = moment(filters_?.last_modified?.[0])?.format?.("YYYY-MM-DD")
      filters_.end_date = moment(filters_?.last_modified?.[1])?.format?.("YYYY-MM-DD")
    } else {
      filters_.start_date = "";
      filters_.end_date = "";
    }
    filters_.last_modified = undefined;
    delete filters_.last_modified;
    console.log('....', filters_)
    props?.onFiltersChange?.(filters_)
  }, [filters])


  useOnClickOutside(ref, () => {
    setOpenDropDown(null)
  });

  const onCloseMenu = () => {
    setActiveMenu(null);
    setOverlay(false);
  };

  const onOpenMenu = (index, item) => {
    setActiveMenu(index);
    setOverlay(true);
    dispatch(setSelectedFile(item));
  };

  const handleOpenDropDown = (type) => (e) => {
    ddRef.current = e.target;
    e.stopPropagation();
    setOpenDropDown(type)
  }

  const handleFilterChange = (param, value, replace = false) => e => {
    e.stopPropagation();
    e.preventDefault();
    if (replace) {
      filters[param] = value
    } else {
      console.log("setting", param, value, replace)
      let paramVals = filters?.[param] ?? []
      const found = paramVals?.find(v => v === value)
      if (found) {
        paramVals = paramVals?.filter?.(v => v !== value)
      } else {
        paramVals = [...(paramVals ?? []), value]
      }
      filters[param] = [...(paramVals ?? [])]
    }
    setFilters({ ...(filters ?? {}) })

    setTimeout(() => {
      setOpenDropDown(null)
    }, 100)

  }

  const isFilterSelected = (param, value, replaced = false) => {
    const paramVals = filters?.[param] ?? []
    if (replaced) {
      return (paramVals?.length && value?.length) ? JSON.stringify(paramVals) === JSON.stringify(value) : false
    } else {
      return paramVals?.find?.(v => v === value)
    }
  }

  const onSortChange = (item) => {
    setSortBy(item)
  }

  const handleClearFilter = (param) => (e) => {
    e.stopPropagation()
    e.preventDefault();
    filters[param] = []
    setFilters({ ...(filters ?? {}) })
    return false;
  }

  const getSortedList = (arr) => {
    let arr_ = [...(arr ?? [])]
    arr_ = arr_?.sort((item1, item2) => {
      const fn = sortBy?.value === "newest" ? "isAfter" : "isBefore";
      return moment(item1?.created_at)?.[fn]?.(moment(item2?.created_at)) ? 1 : -1
    })
    console.log(arr_)
    return arr_
  }

  return (
    <>
      {overlay ? <div className='overlay-fix' onClick={onCloseMenu}></div> : null}
      <div className="search-head">
        <h5>{searchResult?.count} Result(s)</h5>
        {!isMobile ? null : <Filters onClick={() => setShowFilters((preState) => !preState)} />}
      </div>
      <hr className="mt-1" />
      {true ? (
        <div className="filters" ref={ref}>
          <div className="filter-left">
            <div className="btn-wrapper">
              <button type="button" className="btn filter-btn" onClick={handleOpenDropDown('file-type')}>
                Folder <ChevronDown />
              </button>
              {openDropDown === "file-type" && (
                <div className="filter-wrapper types">
                  <div className="filter-header">
                    File Type <Link to="javascript:void(0);" onClick={handleClearFilter('type')}>Clear All</Link>
                  </div>
                  <ul>
                    {FileTypes.map((item, index) => (
                      <li
                        onClick={handleFilterChange('type', item?.value)}
                      >
                        <span className="icon">
                          <CheckMark color={isFilterSelected("type", item?.value) ? '#000' : 'transparent'} />
                        </span>
                        <span>{item.label}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="btn-wrapper">
              <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('type')}>
                Type <ChevronDown />
              </button>
              {openDropDown === "type" && (
                <div className="filter-wrapper types">
                  <div className="filter-header">
                    Type <Link onClick={handleFilterChange('file_type', MimeTypes?.map(mt => mt.value), true)}>Select All</Link>
                  </div>
                  <ul>
                    {MimeTypes.map((item, ind) =>
                      <li
                        key={ind}
                      >
                        <Checkbox
                          checked={isFilterSelected("file_type", item?.value)}
                          onChange={handleFilterChange('file_type', item?.value)}
                          label={item?.label} />
                        {item?.icon}
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div className="btn-wrapper">
              <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('last-modified')}>
                Last modified <ChevronDown />
              </button>
              {openDropDown === "last-modified" && (
                <div className="filter-wrapper last-modify">
                  <div className="filter-header">
                    Last Modified
                  </div>
                  <ul>
                    {LastModified.map((item, index) => (
                      <li
                        key={index}
                        onClick={handleFilterChange('last_modified', item?.value, true)}
                      >
                        <span className="icon">
                          <CheckMark color={isFilterSelected("last_modified", item?.value, true) ? '#000' : 'transparent'} />
                        </span>
                        <span>{item.label}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* <div className="btn-wrapper">
              <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('people')}>
                People <ChevronDown />
              </button>
              {openDropDown === "people" && (
                <div className="filter-wrapper people">
                  <div className="filter-header">
                    People <Link>Select All</Link>
                  </div>
                  <ul>
                    {propleList.map((item, index) => (
                      <li>
                        <span className="icon">
                          <CheckMark color={index !== 0 ? '#FFF' : '#000'} />
                        </span>
                        <span>{item.label}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div> */}
          </div>
          <div className="filter-right">
            <div className="btn-wrapper">
              <label>Sort By: </label>
              <button type="button" className="btn filter-btn ms-1" onClick={() => setOpenDropDown('sort-by')}>
                {sortBy?.label ?? ""} <ChevronDown />
              </button>
              {openDropDown === "sort-by" && (
                <div className="filter-wrapper sort">
                  <div className="filter-header">
                    Sort By
                  </div>
                  <ul>
                    {diskSortList.map((item, index) => (
                      <li onClick={() => onSortChange(item)}>
                        <span className="icon">
                          <CheckMark color={item?.value === sortBy?.value ? '#000' : '#FFF'} />
                        </span>
                        <span>{item.label}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )
        : (
          showFilters &&
          searchResult?.count > 0 && (
            <div className="mobile-filters">
              <div className="btn-wrapper">
                <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('sort-by')}>
                  Most relevant <ChevronDown />
                </button>
                {openDropDown === "sort-by" && (
                  <div className="filter-wrapper sort">
                    <ul>
                      {sortList.map((item, index) => (
                        <li>
                          <span className="icon">
                            <CheckMark color={index !== 0 ? '#FFF' : '#000'} />
                          </span>
                          <span>{item.label}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="btn-wrapper">
                <button type="button" className="btn filter-btn">
                  Folder <ChevronDown />
                </button>
              </div>
              <div className="btn-wrapper">
                <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('type')}>
                  Type <ChevronDown />
                </button>
                {openDropDown === "type" && (
                  <div className="filter-wrapper types">
                    <ul>
                      <li>
                        <Checkbox label="Images" />
                        <Pictures />
                      </li>
                      <li>
                        <Checkbox label="Folders" />
                        <FolderIcon />
                      </li>
                      <li>
                        <Checkbox label="Video" />
                        <Videos />
                      </li>
                      <li>
                        <Checkbox label="PDFs" />
                        <PDF />
                      </li>
                      <li>
                        <Checkbox label="Documents" />
                        <Documents />
                      </li>
                      <li>
                        <Checkbox label="Spreadsheets" />
                        <Sheet />
                      </li>
                      <li>
                        <Checkbox label="Presentations" />
                        <Chart />
                      </li>
                      <li>
                        <Checkbox label="Audio" />
                        <Music />
                      </li>
                      <li>
                        <Checkbox label="Other" />
                        <Others />
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="btn-wrapper">
                <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('last-modified')}>
                  Last modified <ChevronDown />
                </button>
                {openDropDown === "last-modified" && (
                  <div className="filter-wrapper last-modify">
                    <ul>
                      {lastModifyValue.map((item, index) => (
                        <li>
                          <span className="icon">
                            <CheckMark color={index !== 0 ? '#FFF' : '#000'} />
                          </span>
                          <span>{item.label}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="btn-wrapper">
                <button type="button" className="btn filter-btn" onClick={() => setOpenDropDown('people')}>
                  People <ChevronDown />
                </button>
                {openDropDown === "people" && (
                  <div className="filter-wrapper people">
                    <ul>
                      {propleList.map((item, index) => (
                        <li>
                          <span className="icon">
                            <CheckMark color={index !== 0 ? '#FFF' : '#000'} />
                          </span>
                          <span>{item.label}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      {searchResult?.count > 0 && (
        <div className="row search-listing">
          <div className="my-disk-left-block mt-4">
            {props.viewType === 'ListView' &&
              <DiskListingHeader isMobile={isMobile} />}

            {props.viewType === 'ListView' ? (
              <Fragment>
                {/* {searchResult?.results &&
                  searchResult.results.map((item, index) => (
                    <div className="file-wrapper">
                      <div className="file" title={item.name}>
                        <span className="g1">{trimStr(item.name, { length: 20 })}</span>
                        <span className="g2">{moment(item?.updated_at).format('DD/MM/YYYY hh:mm A')}</span>
                        <span className="g3">{item?.size ?? "-"}</span>
                        <span className="g4">
                          <ThreeDot onClick={activeMenu === index ? onCloseMenu : () => onOpenMenu(index, item)} />
                          {activeMenu === index && (
                            <FileMenu activeSubTab={props.activeSubTab} handlePopups={props.handlePopups} onUploadFilee={props.onUploadFilee} onCloseMenu={onCloseMenu} />
                          )}
                        </span>
                      </div>
                    </div>
                  ))} */}
                <DiskFilesListing
                  fileFolderList={getSortedList(searchResult?.results) ?? []}
                  isMobile={isMobile}
                  selectedFile={null}
                  // handleItemClick={handleItemClick}
                  onOpenMenu={onOpenMenu}
                  handlePopups={props?.handlePopups}
                  activeSubTab={null}
                  onUploadFilee={props?.onUploadFilee}
                  activeMenu={activeMenu}
                  onCloseMenu={onCloseMenu}
                />
              </Fragment>
            ) : (
              <Fragment>
                {/* <div className="grid-item-wrapper">
                  {searchResult?.results &&
                    searchResult.results.map((item, index) => (
                      <div className="grid-view-file">
                        <Documents width={44} height={36} />
                        <span className="g1">{item.name.substring(0, 10)}</span>
                        <span className="g3"></span>
                      </div>
                    ))}
                </div> */}
                <DiskFilesListing
                  fileFolderList={getSortedList(searchResult?.results) ?? []}
                  isMobile={isMobile}
                  selectedFile={null}
                  // handleItemClick={handleItemClick}
                  onOpenMenu={onOpenMenu}
                  handlePopups={props?.handlePopups}
                  activeSubTab={null}
                  onUploadFilee={props?.onUploadFilee}
                  activeMenu={activeMenu}
                  onCloseMenu={onCloseMenu}
                  view="grid"
                />
              </Fragment>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default SearchResult;
