import { CometChat } from '@cometchat-pro/chat';
import ImageUploader from 'components/ImageUploader';
import Layout from 'components/Layouts';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import {
  Arrowback,
  CircleFilled,
  MyBridgeIcon,
  ThreeDot
} from 'components/common/Icons';
import { InfiniteList } from 'components/InfiniteList';
import Modal from 'components/common/Modal/Modal';
import { trimStr } from 'components/utils';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  clearTeamCreated,
  clearTeamUpdated,
  createTeamCommunity,
  getTeamCommunityList,
  getTeamCommunityListByPage
} from 'store/slices/teamCommunitySlice';
import { TeamDropdownMenu } from './Common/TeamDropdownMenu';
import { TeamsModal } from './TeamModal';
import { APIEndPoints } from 'store/common/endPoint';
import noContentImage from 'assets/images/emptyBoxImage.png';
import defaultProfileImage from 'assets/images/default_profile_image.png';
import noTeamIllustration from 'assets/images/no-team-found.svg'
import { sanitizeBeforeUpdate } from './Common';

let isLoading = false;
export const Teams = () => {
  const [activeMenu, setActive] = useState(null);
  const [modalOpen, showPopup] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [popupHeader, setPopupHeader] = useState('');
  const [picModalOpen, setPicModalOpen] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [cropType, setCropType] = useState();
  const [teamData, setTeamData] = useState();
  const [teamCommunities, setTeamCommunities] = useState([])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTeamCreated, isTeamUpdated, createdTeam, teamCommunityList, loading } = useSelector((state) => state.teamsCommunity);
  const { userProfileInfo, industriesList } = useSelector((state) => state.userProfile);
  const [createTeamErrors, setCreateTeamErrors] = useState({})
  // const [rerenderList, setRerenderList] = useState(false)
  const onActiveMenu = (index) => (e) => {
    e.stopPropagation()
    setActive(index);
  };
  const ref = React.useRef();
  useOnClickOutside(ref, () => setActive(null));

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearTeamUpdated())
    }, 1000)
  }, [isTeamUpdated])

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearTeamCreated())
    }, 100)
  }, [isTeamCreated])

  useEffect(() => {
    if (!modalOpen) {
      setCreateTeamErrors({})
    }
  }, [modalOpen])

  useEffect(() => {
    setTeamData((state) => ({
      ...state,
      ['industry_name']: industryList,
    }));
  }, [industryList]);

  useEffect(() => {
    if (isTeamCreated === true) {
      const GUID = _.toString(`${_.snakeCase(`teams_${createdTeam.name}`)}`);
      const groupName = teamData?.name;
      const groupType = CometChat.GROUP_TYPE.PRIVATE;
      const password = '';
      const group = new CometChat.Group(GUID, groupName, groupType, password);

      CometChat.createGroup(group).then(
        (group) => {
          console.log('Group created successfully:', group);
        },
        (error) => {
          console.log('Group creation failed with exception:', error);
        },
      );
      handlePopups(modalType)
      dispatch(getTeamCommunityList());
    }
  }, [isTeamCreated]);



  const gotoCompanyPage = (GUID, id) => {
    navigate(`/teams/profile/${GUID}`);
  };

  const handlePopups = (type) => {
    setModalType(type);
    showPopup((prevState) => !prevState);
    setPopupHeader(heading(type));
    setTeamData({})
  };

  const heading = (type) => {
    if (type === 'Create') {
      return <h5 className="modal-title">Organize Team or Community</h5>;
    }
    if (type === 'leave') {
      return <h5 className="modal-title">Leave this team</h5>;
    }
  };

  const cropHandlePopups = (type) => {
    setPicModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const uploadCropedImg = (img) => {
    if (cropType === 'profilePhoto') {
      setTeamData((state) => ({
        ...state,
        image_logo: img,
      }));
    } else {
      setTeamData((state) => ({
        ...state,
        banner_image: img,
      }));
    }
    setPicModalOpen(false);
  };


  const setData = (e) => {
    console.log(e?.target?.checked)
    const { name, value, checked, files } = e.target ?? e ?? {};

    setTeamData((state) => ({
      ...state,
      [name]: value,
    }));

    if (name === 'image' || name === 'banner_image') {
      setTeamData((state) => ({
        ...state,
        [name]: files[0],
      }));
    }
    if (name === 'permission_new_post') {
      setTeamData((state) => ({
        ...state,
        [name]: checked,
      }));
    }
    if (name === 'permission_invite_members') {
      if (checked) {
        setTeamData((state) => ({
          ...state,
          [name]: true,
        }));
      } else {
        setTeamData((state) => ({
          ...state,
          [name]: false,
        }));
      }
    }
    if (name === 'organize_type') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'desc') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'name') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
      setTeamData((state) => ({
        ...state,
        slug: _.snakeCase(e.target.value, ' ', 2),
      }));
    }
    if (name === 'location') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'rules') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'discoverability') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value === 'Listed' ? true : false,
      }));
    }

  };

  const createTeamSubmit = () => {
    dispatch(createTeamCommunity(sanitizeBeforeUpdate(teamData)));
  }

  const fetchTeams = () => {
    return dispatch(getTeamCommunityList())
  }
  const fetchMoreTeams = () => {
    return dispatch(getTeamCommunityListByPage())
  }



  return (
    <React.Fragment>
      <section className="container">
        <Card className={'p-4  mt-5 mb-5'}>
          <div className="teams">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5 className="fw-medium mb-0">Teams</h5>
              <div>
                <Button className={'custom-button'} onClick={() => handlePopups('Create')}>
                  <span>Create Team</span>
                  <CircleFilled color={"#3D5A80"} className="ms-2" />
                </Button>
              </div>
            </div>
            <div
              className='list-page'
            >
              <InfiniteList
                id={"TeamCommunities"}
                parent={null}
                fetch={fetchTeams}
                fetchMore={fetchMoreTeams}
                loading={loading}
                data={teamCommunityList}
                render={
                  ({ item: team, index }) => <div
                    onClick={() => gotoCompanyPage(team?.slug, team?.id)}
                    className="card"
                    key={index}
                  >
                    <div className="page-list-wrapper" title={team?.name}>
                      <div style={{ backgroundImage: `url(${team?.image_logo?.includes("placeholder") ? defaultProfileImage : team?.image_logo})` }} className="page-list-image" />
                      <div className="page-details">
                        <h6

                          className="fw-bold mb-0 cursor-pointer"
                        >
                          {trimStr(team?.name, { length: 40 })}
                        </h6>
                        <p className="card-text">{team?.industry_name?.length ? (team?.industry_name?.[0]?.name) : ''}{(team?.industry_name?.[0]?.name && team?.location?.length) ? " | " : ""}{team?.location?.length ? (team?.location) : ""}</p>
                        {team?.member_count ? <p className="card-text"><MyBridgeIcon /> {team?.member_count}</p> : ""}
                      </div>
                      <div className="page-list-action">
                        <div className="more_actions" onClick={onActiveMenu(index)}>
                          <ThreeDot />
                        </div>
                        {activeMenu === index && (
                          <div onClick={e => e.stopPropagation()}>
                            <TeamDropdownMenu
                              team={team}
                              ref={ref}
                              onLeave={() => dispatch(getTeamCommunityList())}
                              onReport={() => dispatch(getTeamCommunityList())}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                  </div>}
                noDataBox={
                  <div className="d-flex align-items-center flex-column no-data-container">
                    <img src={noTeamIllustration} />
                    <h6 className="py-4 il-desc">
                      You do not own or belong to a team yet, <br />When you create or join a team, it will appear here.
                    </h6>
                    <Link to="#" onClick={() => handlePopups('Create')}>Create Team</Link>
                  </div>
                }
              />
            </div>
          </div>
        </Card>
      </section>
      {modalOpen && (
        <Modal
          onClose={handlePopups}
          heading={popupHeader}
          className={modalType === 'Create' ? 'create-team' : 'leave-team'}
        >
          <TeamsModal
            handlePopups={handlePopups}
            setData={setData}
            cropHandlePopups={cropHandlePopups}
            teamData={teamData}
            onFormSubmit={createTeamSubmit}
            errors={createTeamErrors}
          />
        </Modal>
      )}

      {picModalOpen && (
        <Modal onClose={cropHandlePopups} className={'crop-modal'}>
          {/* <ImageCrop cropType={cropType} uploadCropedImg={uploadCropedImg} handlePopups={cropHandlePopups} /> */}
          <ImageUploader cropType={cropType} uploadCropedImg={uploadCropedImg} handlePopups={cropHandlePopups} />
        </Modal>
      )}
    </React.Fragment>
  );
};
