import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import replyArrow from 'assets/icons/replyArrow.svg';

import { MyBridgeAvatar } from 'components/common/Avatar';
import { DeleteIcon, EditPostIcon, ThreeDot } from 'components/common/Icons';

import CommentBox from './CommentBox';

import { getUserFullName } from 'components/MyDisk/common/utils';
import { BorderLessButton, Text } from 'components/StyledComponents';
import { DropdownMenu } from 'components/common/DropdownMenu';
import PostContext from 'context/PostContext';
import { deleteComment, disLikeComment, fetchComments, likeComment } from 'store/slices/commentSlice';
import FooterActions from './components/FooterActions';
import InsightPalate from './components/InsightPalate';
import { confirmAlert } from 'react-confirm-alert';
import VideoPlayer from '../PostBody/VideoPlayer';

const CommentRow = ({ comment, id, author, level = 1 }) => {
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(false);
  const [showCommentOptions, setShowCommentOptions] = useState(null);
  const [showEditCommentBox, setShowEditCommentBox] = useState(null);
  const [showCommentBox, setShowCommentBox] = useState(null);
  const { userProfileInfo } = useSelector(state => state.userProfile)
  const { updatePost, postData } = useContext(PostContext);
  const [agreeing, setAgreeing] = useState(false)
  const [disagreeing, setDisagreeing] = useState(false)
  const actionTriggerRef = useRef();
  const { allConnectionIDs } = useSelector(state => state.connection)
  const { circle, following } = allConnectionIDs ?? {}

  const authorName = author.first_name + ' ' + author.last_name;
  const commentorName = comment.owner.first_name + ' ' + comment.owner.last_name;

  const handleAgree = (cId) => {
    const remove = comment?.liked_by?.find?.(cl => cl.id === userProfileInfo?.id) ? true : false
    const payload = {
      id: cId,
      requestBody: {
        remove,
      },
    };
    setAgreeing(cId)
    dispatch(likeComment(payload))
      .then(() => {
        setAgreeing(null)
        dispatch(fetchComments({ id }))
      })
      .catch(() => {
        setAgreeing(null)
      });
  };

  const handleDisagree = (cId) => {
    const remove = comment?.dislike_by?.find?.(cl => cl.id === userProfileInfo?.id) ? true : false
    const payload = {
      id: cId,
      requestBody: {
        remove,
      },
    };
    setDisagreeing(cId)
    dispatch(disLikeComment(payload))
      .then(() => {
        setDisagreeing(null)
        dispatch(fetchComments({ id }))
      })
      .catch(() => {
        setDisagreeing(null)
        dispatch(fetchComments({ id }))
      })
  };

  const toggleComment = () => {
    setShowComments((show) => !show);
  };

  const handleCommentOptionsToggle = (comment) => {
    const { id: commentId } = comment;
    if (showCommentOptions === commentId) setShowCommentOptions(null);
    else setShowCommentOptions(commentId);
  };

  const handleCommentOption = (type, comment) => {
    if (type === 'delete') {
      confirmAlert({
        title: "Remove comment",
        message: "Are you sure you want to remove your comment?",
        buttons: [{
          label: "Remove",
          onClick: () => {
            dispatch(deleteComment({ id: comment.id })).then(() => {
              dispatch(fetchComments({ id }));
              updatePost?.()
            });
          }
        }, {
          label: "No"
        }]
      })
    } else {
      setShowCommentOptions(false);
      setShowEditCommentBox(comment.id);
    }
  };

  const commentAllowed = useMemo(() => {
    const isInCircle = circle?.includes(author?.id);
    const isInFollowers = following?.includes(author?.id);
    if (postData?.reply_control === 0) {
      return true
    } else if (postData?.reply_control === 1) {
      return isInCircle
    } else if (postData?.reply_control === 2) {
      return isInCircle || isInFollowers
    }
  }, [circle, following])

  return (
    <div className={`comment-container  w-100 mb-2`}>
      <div className={classNames('avatar-column', [level > 1 ? 'avatar-column-level' : ''])}>
        <div className="avatar-icon">
          <MyBridgeAvatar icon={comment.owner.profile_pic} size={40} name={commentorName} />
        </div>
        {comment?.replies?.length > 0 && <div className="avatar-line" />}
        <div className="avatar-line-horizontal" />
        {/* <img src={curveArrow} alt="" className="curved-arr" /> */}
      </div>
      <div className={`details-column ${comment?.comment_replies?.length > 0 ? "has-comments" : ""}`}>
        <div className="commentor-details">
          <div className="d-flex w-100 align-items-center">
            <div style={{ flex: 1 }}>
              <div className="d-flex justify-content-between align-items-center">
                <Text fS="18px" fW="500" color="#3D5A80">
                  {comment.owner.first_name + ' ' + comment.owner.last_name}
                </Text>
              </div>
              <Text color="#747474" fW="500">
                {comment.owner.position}
              </Text>
            </div>
          </div>
          <div className='d-flex align-items-center' style={{ flex: "0 0 auto" }}>
            <Text color="#747474">{moment(comment.created_at).fromNow()}</Text>
          </div>
          {comment?.owner?.id === userProfileInfo?.id ? <div className="comment-actions position-relative d-flex align-items-center" >
            <div className='action-btn' ref={actionTriggerRef}>
              <ThreeDot className="cursor-pointer" onClick={(e) => {
                handleCommentOptionsToggle(comment)
              }
              } />
            </div>
            <DropdownMenu
              items={[
                {
                  label: "Edit comment",
                  onClick: () => handleCommentOption('edit', comment),
                  icon: <EditPostIcon height={18} />
                },
                {
                  label: "Delete comment",
                  onClick: () => handleCommentOption('delete', comment),
                  icon: <DeleteIcon height={18} />
                }
              ]}
              targetRef={actionTriggerRef}
            />
            {/* {showCommentOptions === comment.id && (
              <div className="edit-comment-options">
                <p className="edit-comment-option" onClick={() => handleCommentOption('edit', comment)}>
                  Edit Comment
                </p>
                <p className="edit-comment-option" onClick={() => handleCommentOption('delete', comment)}>
                  Delete Comment
                </p>
              </div>
            )} */}
          </div> : ""}
        </div>

        <div className={`comment-details ${comment?.comment_replies?.length > 0 ? "has-comments" : ""}`}>
          {showEditCommentBox === comment.id ? (
            <CommentBox
              name={getUserFullName(userProfileInfo)}
              commentId={comment.id}
              id={id}
              author={author}
              commentText={comment.body}
              setShowEditCommentBox={setShowEditCommentBox}
              comment={comment}
            // noGif={true}
            // noVideo={true}
            />
          ) : (
            <div className="comment-bg">
              <Text fS="15px">{comment.body}</Text>
              {comment?.media?.length > 0 ? (
                <>
                  {comment?.media?.map((img) => (
                    img?.file?.toLowerCase?.()?.includes(".mp4") ?
                      <VideoPlayer
                        src={img?.file}
                        width="100%"
                        height="100%"
                      /> :
                      <img key={img.id} className="pt-2" src={img.file} alt={img.id} />
                  ))}
                </>
              ) : null}
              {comment?.gif && <img src={comment?.gif} className="pt-2" alt="gif" />}
            </div>
          )}

          {/* <div className="comment-bg">
            <Text fS="15px">{comment.body}</Text>
            {comment?.media?.length > 0 ? (
              <>
                {comment?.media?.map((img) => (
                  <img key={img.id} className="pt-2" src={img.file} alt={img.id} />
                ))}
              </>
            ) : null}
            {comment?.gif && <img src={comment?.gif} className="pt-2" alt="gif" />}
          </div> */}

          <InsightPalate comment={comment} />
          <FooterActions
            agreeing={agreeing}
            disagreeing={disagreeing}
            level={level}
            onChatClick={() => {
              setShowCommentBox(s => !s)
            }}
            onAgreeClick={() => handleAgree(comment.id)}
            onDisagreeClick={() => handleDisagree(comment.id)}
          />
        </div>
        {commentAllowed && showCommentBox && level < 3 && <CommentBox
          // noGif={true}
          // noVideo={true}
          comment={comment}
          setShowCommentBox={setShowCommentBox}
          className={comment?.comment_replies?.length > 0 ? "has-comments" : ""}
          name={getUserFullName(userProfileInfo)}
          parentCommentId={comment.id}
          id={id}
          author={author}
        />}
        {comment?.comment_replies?.length > 0 && !showComments && (
          <div className="more-replies d-flex align-items-center">
            <>
              <img src={replyArrow} alt="" />
              <BorderLessButton width="auto" color="#5B5B5B" fontWeight="500" fontSize="18px" padding="0 0.5rem" onClick={toggleComment}>
                {comment?.comment_replies?.length} Replies
              </BorderLessButton>
            </>
          </div>
        )}


        {showComments && (
          <>
            {comment?.comment_replies?.map((reply) => {
              return <CommentRow id={id} author={author} key={comment.id} comment={reply} level={level + 1} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default CommentRow;
