import { FolderIcon, Settings, ThreeDot, Documents, VerticalThreeDot } from 'components/common/Icons';
import React, { Fragment, useRef, useState } from 'react';
// import avatar from 'assets/profiles/banner/avtar.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { MyBridgeAvatar } from 'components/common/Avatar';
import moment from 'moment';
import { getfileNFolder, setOpenFolderItem, setSelectedFile, setSelectedUser } from 'store/actions/myDisk.Action';
import FileMenu from './fileMenu';
import FolderMenu from './folderMenu';

import { isDir } from './common/utils';
import { DiskFilesListing } from './common/DiskFilesListing';
import { NoFilesFolderIllustration } from './common/NoFilesFolderIllustration';
const SharedDisks = (props) => {
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const [showRemove, setShowRemove] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [overlay, setOverlay] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const dispatch = useDispatch();
  const { shareUserList, shareItemListByUser, selectedFile, fileFolderList } = useSelector((state) => state.myDiskReducer);

  const outsideClickRef = useRef()

  const onOpenMenu = (index) => {
    setActiveMenu(index);
    setOverlay(true);
    setOpenIndex(index);
    setShowRemove(true);
  };


  const onCloseMenu = () => {
    setOpenIndex(null);
    setActiveMenu(null);
    setOverlay(false);
  };


  const onSelectFile = (item) => {
    dispatch(setSelectedFile(item));
    setOverlay(false);
  };

  const openFolder = (item) => {
    dispatch(getfileNFolder({ parent: item.id }));
    dispatch(setOpenFolderItem(item));
    setActiveMenu(null);
    setOverlay(false);
  };

  const removeUser = (item) => {
    props.handlePopups('RemoveMe')
    dispatch(setSelectedUser(item))
  }

  const handleItemClick = (item) => {
    if (isDir(item)) {
      openFolder(item)
    } else {
      onSelectFile(item)
    }
  }


  const { viewType, activeSubTab, handleShareTab_getfileNFolder } = props;

  return (
    <>
      {overlay ? <div className="overlay-fix" onClick={onCloseMenu}></div> : null}
      <div className="row">
        <div className="my-disk-left-block">
          {viewType === 'ListView' && (
            <div className={`headers ${!activeSubTab ? "no-last-col" : ""}`}>
              <div className="hd1">Name</div>
              <div className="hd2">Last Modified</div>
              {activeSubTab ? <>
                <div className="hd3">Shared with</div>
                <div className="hd4"></div>
              </>
                : <div className="hd3">Settings</div>}
            </div>
          )}

          {viewType === 'ListView' ? (
            <Fragment>
              {activeSubTab
                ? <>
                  {fileFolderList?.length ?
                    <DiskFilesListing
                      ref={outsideClickRef}
                      checkForAccessLevel={true}
                      fileFolderList={fileFolderList}
                      isMobile={isMobile}
                      selectedFile={selectedFile}
                      handleItemClick={handleItemClick}
                      onOpenMenu={onOpenMenu}
                      handlePopups={props?.handlePopups}
                      activeSubTab={activeSubTab}
                      onUploadFilee={props?.onUploadFilee}
                      activeMenu={activeMenu}
                      onCloseMenu={onCloseMenu}
                      diskType="sharedDisk"
                    /> : <NoFilesFolderIllustration />}
                </>
                : shareUserList?.map((item, index) => (
                  <div className="share-file-wrapper cursor-pointer" key={index}>
                    <div className="file " onClick={() => handleShareTab_getfileNFolder(item)}>
                      <div className="g1 d-flex" >
                        <div className='avtar'>
                          <MyBridgeAvatar icon={item?.profile_pic} name={`${item?.first_name} ${item?.last_name}`} />
                        </div>
                        <span className='file-name'>
                          {item?.first_name} {item?.last_name}
                        </span>
                      </div>
                      <span className="g2" onClick={() => handleShareTab_getfileNFolder(item)}> {moment(item?.updated_at).format('DD/MM/YYYY hh:mm A')}</span>
                      <span className="g3" onClick={openIndex ? () => onCloseMenu() : (e) => { e.stopPropagation(); onOpenMenu(index) }}>
                        <Settings className="cursor-hand" />
                      </span>
                      <span className="g4">
                      </span>
                    </div>
                    {openIndex === index && showRemove && (
                      <div className="remove-me" onClick={(e) => {
                        e.stopPropagation();
                        removeUser(item)
                      }}>
                        Remove Me
                      </div>
                    )}
                  </div>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {activeSubTab
                ?
                <>
                  {fileFolderList && fileFolderList.length ? (
                    <DiskFilesListing
                      ref={outsideClickRef}
                      checkForAccessLevel={true}
                      fileFolderList={fileFolderList}
                      isMobile={isMobile}
                      selectedFile={selectedFile}
                      handleItemClick={handleItemClick}
                      onOpenMenu={onOpenMenu}
                      handlePopups={props?.handlePopups}
                      activeSubTab={activeSubTab}
                      onUploadFilee={props?.onUploadFilee}
                      activeMenu={activeMenu}
                      onCloseMenu={onCloseMenu}
                      view="grid"
                      diskType="sharedDisk"
                    />

                  ) : (
                    <NoFilesFolderIllustration />
                  )}
                </>
                : <div className="grid-item-wrapper">
                  {shareUserList?.map((item, index) => (
                    <div className="grid-view-file cursor-pointer"
                      key={index}
                      onClick={() => handleShareTab_getfileNFolder(item)}
                    >
                      <span className="avtar">
                        <MyBridgeAvatar icon={item?.profile_pic} name={`${item?.first_name} ${item?.last_name}`} />

                        {/* <img src={avatar} className="img-fluid" alt="avatar" /> */}
                      </span>
                      {/* <FolderIcon width={82} height={66}/>  */}
                      <span className="g1" >
                        {item?.first_name} {item?.last_name}
                      </span>
                      <span className="g3" onClick={openIndex ? () => onCloseMenu() : (e) => { e.stopPropagation(); onOpenMenu(index) }}>
                        {/* remove */}
                        <Settings className="cursor-hand" />
                      </span>
                      {openIndex === index && showRemove && (
                        <div className="remove-me" onClick={(e) => {
                          e.stopPropagation();
                          props.handlePopups('RemoveMe')
                        }}>
                          Remove Me
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              }
            </Fragment>
          )}
        </div>
        {openIndex ? <div className="btn-overlay" onClick={onCloseMenu}></div> : null}
      </div >
    </>
  );
};

export default SharedDisks;
