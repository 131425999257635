import { useMutation } from '@tanstack/react-query';
import RadioButton from 'components/common/RadioButton';
import React from 'react';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';
import PropTypes from 'prop-types';

const TagSection = ({ whoCanSee }) => {
  const [state, setState] = React.useState(whoCanSee?.who_can_see || 'anyone');
  const { mutate } = useMutation({
    mutationFn: async (body) => {
      return await API({ method: 'POST', url: `${APIEndPoints.Notification}/email/`, body: JSON.stringify(body) });
    },
    onSuccess: (data) => {
      setState(data.who_can_see);
    },
  });
  return (
    <div className="mt-3">
      <div className="d-flex align-items-center justify-content-between border-bottom py-3">
        <div>Anyone</div>
        <div>
          <RadioButton
            isSelected={state === 'anyone'}
            onClick={() => {
              mutate({ _for: 'tag', enabled: true, who_can_see: 'anyone' });
            }}
            changed={() => {
              setState('anyone');
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between border-bottom py-3">
        <div>Friends</div>
        <div>
          <RadioButton
            isSelected={state === 'friends'}
            onClick={() => {
              mutate({ _for: 'tag', enabled: true, who_can_see: 'friends' });
            }}
            changed={() => {
              setState('friends');
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between py-3">
        <div>Friends of Friends</div>
        <div>
          <RadioButton
            isSelected={state === 'friends_of_friends'}
            onClick={() => {
              mutate({ _for: 'tag', enabled: true, who_can_see: 'friends_of_friends' });
            }}
            changed={() => {
              setState('friends_of_friends');
            }}
          />
        </div>
      </div>
    </div>
  );
};

TagSection.propTypes = {
  whoCanSee: PropTypes.string,
};

TagSection.defaultProps = {
  whoCanSee: 'anyone',
};

export default TagSection;
