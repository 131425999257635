import { useMutation } from '@tanstack/react-query';
import { bookMarkBlogFunc, removeBookMarkBlogFunc } from 'api/blog';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from 'components/common/Loader';

const Container = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  gap: 10px;
  width: 41px;
  height: 37px;
  background: #e4e6eb;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

const SaveArticleButton = ({ saved, blogId, onBookMarkAction }) => {
  const { mutate: bookMark, isLoading: bookMarkLoading } = useMutation(bookMarkBlogFunc, {
    onSuccess: onBookMarkAction,
    onError: (error) => {
      console.log('error bookMarkBlogFunc', error);
    },
  });

  const { mutate: removebookMark, isLoading: removebookMarkLoading } = useMutation(removeBookMarkBlogFunc, {
    onSuccess: onBookMarkAction,
    onError: (error) => {
      console.log('error removeBookMarkBlogFunc', error);
    },
  });

  const onClickHandler = () => {
    if (saved) {
      removebookMark({ blogId });
    } else {
      bookMark({ blogId });
    }
  };

  return (
    <Container onClick={onClickHandler}>
      {bookMarkLoading || removebookMarkLoading ? (
        <Loader.Spinner />
      ) : (
        <>
          {saved ? <img src="/icon/saved-article.svg" alt="save" /> : <img src="/icon/save-article.svg" alt="save" />}
        </>
      )}
    </Container>
  );
};

SaveArticleButton.propTypes = {
  saved: PropTypes.bool.isRequired,
  blogId: PropTypes.string.isRequired,
  onBookMarkAction: PropTypes.func,
};

SaveArticleButton.defaultProps = {
  saved: false,
  onBookMarkAction: () => {},
};

export default SaveArticleButton;
