import React from 'react';

import ManageSubscriptions from './manageSubscription';
import CompanyConnections from './companyConnections';
import Feed from 'pages/Dashboard/Feed';
import { useSelector } from 'react-redux';

const Home = (props) => {
  const { setOpenTab } = props;
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  return (
    <div className="row">
      <div className="col-xl-4">
        <CompanyConnections />
        <ManageSubscriptions setOpenTab={setOpenTab} companyDetails={companyPageDetail} />
      </div>
      <div className="col-xl-8">
        <Feed type="company" showModal companyDetails={companyPageDetail} />
      </div>
    </div>
  );
};
export default Home;
