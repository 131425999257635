import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-calendar/dist/Calendar.css';
import 'react-phone-input-2/lib/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './scss/myBridge.scss';
import 'reactjs-popup/dist/index.css';
import { store } from 'store/store';
import { AuthProvider } from './context/authcontext';
import App from './App';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </QueryClientProvider>,
);
