// import Accordion from "components/common/Accordion";
import Alert from 'components/common/Alert/Alert';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';
import { Documents, FolderClosed, LinkAddIcon } from 'components/common/Icons';
import { Spinner } from 'components/common/Loader';
import TreeMenu from 'components/common/TreeMenu';
import { copyTextToClipboard, trimStr } from 'components/utils';
import { SHAREOPTION } from 'data/data';
import moment from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  changeTeamDiskSubscriber,
  copyFiles,
  createFolder,
  createShareLink,
  deleteFile,
  deleteFolder,
  deleteShareLink,
  getShareLink,
  getSharedDiskUsers,
  getSharedDiskUsersByFile,
  getSharedItemUsers,
  moveFiles,
  removeFileShare,
  removeShare,
  renameFiles,
  renameFolder,
  restoreFile,
  restoreFolder,
  setTeamDiskSubscriberChanged,
  sharedDisk,
} from 'store/actions/myDisk.Action';
import { getUserFullName, isDir } from '../common/utils';

const ModalPopups = (props) => {
  const { allDegreeConnections } = useSelector((state) => state.connection);
  const { popUpType, folderId, teamOrganizers, teamId, activeSubTab } = props;
  const { errorMessage, loading, deletedfileFolderList, selectedFile, openFolderItem, shareUserListByFile, selectedUser, sharelinkData, changeTeamDiskSubscriberLoading, teamDiskSubscriberChanged } = useSelector(
    (state) => state.myDiskReducer,
  );
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [name, setName] = useState(selectedFile?.name ?? openFolderItem?.name);
  const [restoreItem, setRestoreItem] = useState(undefined);
  const [selectedTreeItem, setSelectedItem] = useState(undefined);
  const [selectedUsers, setSelectedUser] = useState([]);
  const [access_type, setAccess_type] = useState(2)
  const [copyLinkDone, setCopyLinkDone] = useState(false)
  const [newSubscriber, setNewSubscriber] = useState()
  const [errorMsg, setError] = useState()
  const [sharing, setSharing] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (popUpType === "Share") {
      const fileFolder = selectedFile ?? openFolderItem
      dispatch(getSharedDiskUsersByFile({ id: fileFolder?.id }))
      dispatch(getShareLink({ id: fileFolder?.id }))
    }
  }, [popUpType, selectedFile, openFolderItem])

  const onChangeData = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const createDir = () => {
    const parent = selectedFile && isDir(selectedFile) ? selectedFile?.id : openFolderItem ? openFolderItem?.id : folderId
    const payload = {
      name: name,
      parent
    };
    dispatch(createFolder(payload));
  };
  const onDeleteFile = () => {
    // unshare if shared with self
    dispatch(removeFileShare({ id: selectedFile?.id, shared_with_id: userProfileInfo?.id })).then(() => {
      dispatch(deleteFile(selectedFile.id));
    }).catch(() => {
      dispatch(deleteFile(selectedFile.id));
    });
  };
  const onDeleteFolder = () => {
    dispatch(removeFileShare({ id: openFolderItem?.id, shared_with_id: userProfileInfo?.id })).then(() => {
      dispatch(deleteFolder(openFolderItem.id));
    }).catch(() => {
      dispatch(deleteFolder(openFolderItem.id));
    });
  };
  const renameFile = () => {
    let payload = {
      name: name,
      id: selectedFile.id,
    };
    dispatch(renameFiles(payload))
  };
  const renameSelectedFolder = () => {
    let payload = {
      id: openFolderItem.id,
      name: name,
      parent: openFolderItem.parent,
    };
    dispatch(renameFolder(payload))
  };
  const setRestoreId = (item) => {
    setRestoreItem(item);
  };
  const restoreData = () => {
    if (restoreItem.type === 'D') {
      const payload = {
        name: restoreItem.name,
        parent: restoreItem.parent,
        is_deleted: false,
        id: restoreItem.id,
      };
      dispatch(restoreFolder(payload));
    } else {
      dispatch(restoreFile(restoreItem.id));
    }
  };
  const moveFile = () => {
    let payload = {
      parent: selectedTreeItem?.id,
      id: selectedFile.id,
    };
    dispatch(moveFiles(payload));
  };
  const copyFile = () => {
    let payload = {
      parent: selectedTreeItem?.id,
      id: selectedFile.id,
    };
    dispatch(copyFiles(payload));
  };
  const moveFolders = () => {
    let payload = {
      name: openFolderItem.name,
      parent: selectedTreeItem?.id,
      id: openFolderItem.id,
    };
    dispatch(moveFiles(payload));
  };
  const handleSelect = (data) => {
    setSelectedUser(data);
  };
  const connectionOptions = useMemo(
    () => {
      const arr = [
        ...(allDegreeConnections ?? []),
        ...(teamOrganizers?. // filter which are not in connection data
          filter?.(to => !(allDegreeConnections?.find?.(cd => cd?.id === to?.user?.id)))?.
          map?.(to => to?.user) ?? [])
      ]

      console.log(arr)

      return arr?.map((t) => {
        return { value: t.id, label: t.first_name + ' ' + t.last_name };
      })
    },
    [allDegreeConnections, teamOrganizers],
  );
  // const animatedComponents = makeAnimated();

  const onShareLink = () => {
    const payload = {
      id: selectedFile?.id,
      access_type: 2
    }
    dispatch(createShareLink(payload));
  };
  const setAccess = (e) => {
    const { value } = e.target
    setAccess_type(value)
  }
  const getUserList = () => {
    let userList = [];
    for (let i = 0; i < selectedUsers.length; i++) {
      userList.push(selectedUsers[i].value);
    }
    return userList;
  };

  const shareItem = () => {
    setSharing(true)
    let userList = getUserList();
    const payload = {
      id: selectedFile?.id ?? openFolderItem?.id,
      shared_with_id: userList,
      access_type: parseInt(access_type),
    };

    dispatch(sharedDisk(payload)).then(res => {
      setSharing(false)
      if (res)
        props.handlePopups(undefined)
    }).catch(() => setSharing(false));
  };

  const removeMe = () => {
    dispatch(removeShare(selectedUser?.id)).then(res => {
      if (res)
        dispatch(getSharedDiskUsers())
      props.handlePopups(undefined)
    })
  }

  const handleSubscriberChange = (e) => {
    const { value } = e.target ?? {}
    if (value && value.length) {
      setNewSubscriber(value)
    }
  }

  const changeSubscriber = () => {
    if (newSubscriber && newSubscriber.length) {
      setError(null)
      const payload = {
        teamId: teamId,
        organizerId: newSubscriber
      }
      dispatch(changeTeamDiskSubscriber(payload)).then(() => {
        setTimeout(() => {
          dispatch(setTeamDiskSubscriberChanged(false))
          props?.handlePopups?.(undefined)
        }, 2000)
      })
    } else {
      setError("Please choose a new subscriber from the list below")
    }
  }

  const copyLinkToClipBoard = async () => {
    if (sharelinkData) {
      const done = await copyTextToClipboard(sharelinkData?.public_share_link)
      if (done) {
        setCopyLinkDone(true);
        setTimeout(() => { setCopyLinkDone(false) }, 3000)
      }
    }
  }

  const changeAccessLevelForUser = (user, level) => {
    const payload = {
      id: selectedFile?.id,
      shared_with_id: [user?.id],
      access_type: level,
    };
    dispatch(sharedDisk(payload)).then(() => {
      dispatch(getSharedDiskUsersByFile({ id: selectedFile?.id }))
    })
  }
  const revokeAccessLevelForUser = (user) => {
    dispatch(removeFileShare({ id: selectedFile?.id, shared_with_id: user?.id })).then(() => {
      dispatch(getSharedDiskUsersByFile({ id: selectedFile?.id }))
    })
  }
  const handleDeleteLink = () => {
    dispatch(deleteShareLink({ id: selectedFile?.id }))
  }

  const handleAccessChange = (user) => (value) => {
    confirmAlert({
      title: value < 1 ? "Revoke Access?" : "Change Access?",
      message: value < 1 ?
        `Are you sure that you want to revoke access for ${user?.first_name}?`
        : `Are you sure that you want to change the access level for ${user?.first_name}?`,
      buttons: [{ label: "No", }, { label: "Yes", onClick: () => value < 1 ? revokeAccessLevelForUser(user) : changeAccessLevelForUser(user, value) }]
    })
  }

  const onDeleteShareLink = () => {
    confirmAlert({
      title: "Delete shareable link?",
      message: `Are you sure that you want to delete the publicly shareable link for this file?`,
      buttons: [{ label: "No", }, { label: "Yes", onClick: () => handleDeleteLink() }
      ]
    })
  }

  const getInviteByUser = (id) => {
    return userProfileInfo?.id === id ? userProfileInfo : allDegreeConnections?.find(c => c?.id === id)
  }

  return (
    <>
      {errorMsg ? <Alert type="danger">{errorMsg}</Alert> : ""}
      {popUpType === 'Create' && (
        <div className="mb-3">
          <label htmlFor="folder" className="form-label">
            Folder Name
          </label>
          <input type="text" className="form-control" id="folder" placeholder="Folder Name" onChange={onChangeData} />
        </div>
      )}
      {popUpType === 'Bin' && (
        <div className="mb-3">
          Are you sure want to delete {selectedFile ? selectedFile?.name : openFolderItem?.name}?
        </div>
      )}
      {popUpType === 'Subscriber' && (
        <div className="mb-3">
          {errorMessage?.length ? <Alert type="danger">{errorMessage}</Alert> : ""}
          {teamDiskSubscriberChanged ? <Alert type="success">Request sent to change subscriber.</Alert> : ""}
          <Dropdown
            // selectedValue={teamOrganizers?.[0]?.user?.id}
            options={teamOrganizers?.map(o => ({ label: o?.user?.first_name, value: o?.user?.id }))} containerClass={'m-0'}
            handleChange={handleSubscriberChange} />
        </div>
      )}

      {popUpType === 'RemoveMe' && <div className="mb-3">You’ll lose access to {getUserFullName(selectedUser)}'s Disk</div>}

      {(popUpType === 'Move' || popUpType === 'Copy') && (
        <div className="mb-3">
          <div className="accordion folder-item">
            <TreeMenu setSelectedItem={setSelectedItem} />
          </div>
        </div>
      )}
      {popUpType === 'Copy' && <div className="mb-3"></div>}
      {popUpType === 'Share' && (
        <div className="mb-3">
          {copyLinkDone ? <Alert type="success">Link copied successfully!</Alert> : <></>}
          {shareUserListByFile?.length > 0 ? <>
            <h6 className="py-2">People with access</h6>
            <Card className="mb-4">
              <div className="share-list" >
                {shareUserListByFile?.map((item, index) => (
                  <div
                    className="user-card"
                    key={index}
                    onClick={e => navigate("/" + item?.shared_with?.slug)}
                  >
                    <MyBridgeAvatar
                      icon={item?.shared_with?.profile_pic}
                      name={`${item?.shared_with?.first_name} ${item?.shared_with?.last_name}`}
                      size={40}
                      className="profile-avatar"
                    />
                    <div title={item?.shared_with?.first_name} className="name">
                      {trimStr((item?.shared_with?.first_name + " " + item?.shared_with?.last_name), { length: 20 })}
                      <div className="invite-by">
                        {getInviteByUser(item?.shared_by) ? <>
                          Invite by {getUserFullName(getInviteByUser(item?.shared_by))}
                        </> : ""}
                      </div>
                      {/* <br />
                  <span title={item?.shared_with?.email} className="small-font">{trimStr(item?.shared_with?.email, { length: 30 })}</span> */}
                    </div>
                    {userProfileInfo?.id !== item?.shared_by ? "" : <div className="drop-down" onClick={e => e.stopPropagation()}>
                      <Dropdown customDefault={false} handleChange={(e) => handleAccessChange(item?.shared_with)(e.target?.value)} options={[{ label: "No Access", value: 0 }, ...SHAREOPTION]} containerClass={'m-0'} selectedValue={item?.access} />
                    </div>}
                  </div>
                ))}
              </div>
            </Card >
          </> : ""}
          <h6 className=" mb-3">{sharelinkData?.public_share_link ? "Copy Link" : "Create Link"}</h6>
          <div>
            <div className="d-flex justify-content-between pe-2">
              <div style={{ flex: 1 }}>
                {sharelinkData?.public_share_link ? <p><LinkAddIcon /> <Link onClick={copyLinkToClipBoard} title={sharelinkData?.public_share_link} href="javascript:void(0)">{trimStr(sharelinkData?.public_share_link, { length: 40 })}</Link></p> : <p style={{ color: "#999" }}>No link created yet</p>}
              </div>
              <div style={{ textAlign: "right" }}>
                {sharelinkData?.public_share_link ?
                  <div className='d-flex gap-4 align-items-center'>
                    <Link style={{ color: "red" }} onClick={onDeleteShareLink}>Delete Link</Link>
                    <Link onClick={copyLinkToClipBoard}>Copy Link</Link>
                  </div>
                  : <Link onClick={() => onShareLink()}>Create a link</Link>}
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex gap-2">
            <div className='custom-react-select' style={{ flex: 1 }}>
              <Select
                options={connectionOptions?.filter(c => (shareUserListByFile?.find?.(su => su?.shared_with?.id === c?.value) === undefined))}
                placeholder="Search Connection"
                value={selectedUser}
                onChange={handleSelect}
                isSearchable={true}
                isMulti
              />
            </div>
            <div >
              <Dropdown customDefault={false} defaultValue={SHAREOPTION?.[1]?.value} options={SHAREOPTION} containerClass={'m-0'} handleChange={setAccess} />
            </div>
          </div>
        </div >
      )}
      {
        popUpType === 'BinOpen' && (
          <div className="mb-3">
            <ul className="folder-files deleted-list">
              {deletedfileFolderList && deletedfileFolderList.length ? (
                deletedfileFolderList.map((item, index) => (
                  <li className={`${restoreItem?.id === item?.id ? "active" : ""}`}>
                    <div className="chk-box" title={item.name}>
                      <Checkbox
                        id={item.id}
                        onChange={() => setRestoreId(item)}
                        checked={restoreItem && restoreItem.id === item.id ? true : false}
                      />
                    </div>
                    <div className="icon-wrapper">
                      <span className="icon">
                        {item.type === 'D' ? <FolderClosed /> : <Documents width={30} height={28} />}
                      </span>
                      <span className="name">{trimStr(item.name, { length: 20 })}</span>
                    </div>
                    <div className="date">{moment(item.updated_at).format('DD/MM/YYYY hh:mm a')}</div>
                  </li>
                ))
              ) : (
                <>{loading ? <Spinner /> : "No Deleted Files Found"}</>
              )}
            </ul>
          </div>
        )
      }

      {
        popUpType === 'Rename' && (
          <div className="mb-3">
            <label htmlFor="folder" className="form-label">
              Folder or file Name
            </label>
            <input defaultValue={selectedFile?.name ?? openFolderItem?.name} type="text" className="form-control" id="folder" placeholder="Folder Name" onChange={onChangeData} />
          </div>
        )
      }
      <div className="mt-4 d-flex justify-content-end" style={{ gap: "0.5rem" }}>
        <Button
          variant='btn-secondary'
          data-dismiss="modal"
          onClick={() => props.handlePopups(undefined)}
        >
          Cancel
        </Button>
        {popUpType === 'Create' && (
          <Button variant='btn-primary' onClick={createDir}>
            {loading ? <Spinner /> : "Create Folder"}
          </Button>
        )}
        {popUpType === 'Bin' && (
          <>
            {selectedFile ? (
              <Button variant='btn-primary' onClick={onDeleteFile}>
                {loading ? <Spinner /> : "Delete"}
              </Button>
            ) : (
              <Button variant='btn-primary' onClick={onDeleteFolder}>
                {loading ? <Spinner /> : "Delete"}
              </Button>
            )}
          </>
        )}
        {popUpType === 'Move' && isDir(selectedTreeItem) && (
          <>
            {selectedFile ? (
              <Button variant='btn-primary' onClick={moveFile}>
                {loading ? <Spinner /> : "Move Files"}
              </Button>
            ) : (
              <Button variant='btn-primary' onClick={moveFolders}>
                {loading ? <Spinner /> : "Move Folders"}
              </Button>
            )}
          </>
        )}
        {popUpType === 'Copy' && (
          <>
            {selectedFile ? (
              <Button variant='btn-primary' onClick={copyFile}>
                {loading ? <Spinner /> : "Copy File"}
              </Button>
            ) : (
              <Button variant='btn-primary'>
                {loading ? <Spinner /> : "Copy Folder"}
              </Button>
            )}
          </>
        )}
        {popUpType === 'Subscriber' && (
          <>
            {selectedFile ? (
              <Button variant='btn-primary' onClick={props?.handlePopups(undefined)}>
                Cancel
              </Button>
            ) : (
              <Button variant='btn-primary' onClick={changeSubscriber}>
                {changeTeamDiskSubscriberLoading ? <Spinner /> : "Send Request"}
              </Button>
            )}
          </>
        )}
        {popUpType === 'Share' && (
          <>
            {selectedUsers?.length ? (
              <Button variant='btn-primary' onClick={shareItem}>
                {(loading || sharing) ? <Spinner /> : "Share"}
              </Button>
            ) : null}
          </>
        )}
        {popUpType === 'BinOpen' && restoreItem?.id && (
          <Button variant='btn-primary' onClick={restoreData}>
            {loading ? <Spinner /> : "Restore"}
          </Button>
        )}
        {popUpType === 'Rename' && (
          <>
            {selectedFile ? (
              <Button variant='btn-primary' onClick={renameFile}>
                {loading ? <Spinner /> : "Rename File"}
              </Button>
            ) : (
              <Button variant='btn-primary' onClick={renameSelectedFolder}>
                {loading ? <Spinner /> : "Rename Folder"}
              </Button>
            )}
          </>
        )}
        {popUpType === 'RemoveMe' && (
          <Button variant='btn-primary' onClick={removeMe}>
            Remove Me
          </Button>
        )}
      </div>
    </>
  );
};

export default ModalPopups;
