import React, { useState, useEffect, useDebugValue } from 'react';
import CreatableSelect from 'react-select/creatable';
import { getGlobalCompanies, createGlobalCompanies } from 'store/slices/globalDataSlice';
import { useDispatch, useSelector } from "react-redux";

const GlobalCompanyDropdown = (props) => {
    const { name, onChange, id, selectedValue } = props;
    const { globalCompanies, companyCreated } = useSelector(
        (state) => state.globalData,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState();
    const [value, setValue] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGlobalCompanies());
    }, []);

    useEffect(() => {
        setOptions(globalCompanies?.map((e) => ({ label: e, value: e })));
    }, [globalCompanies]);


    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const handleOnchange = (newValue) => { 
        setValue(newValue);
        onChange(newValue);
    }
    
    const handleCreate = (inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoading(false);
            setOptions((prev) => [...prev, newOption]);
            dispatch(createGlobalCompanies({'name':inputValue}))
            setValue(newOption);
            onChange(newOption);
        }, 500);
    };

    return (
        <CreatableSelect
            isClearable
            isLoading={isLoading}
            isDisabled={isLoading}
            id={id}
            name={name}
            placeholder="Select Company"
            options={options}
            value={selectedValue && selectedValue !== null ? options?.filter(function (option) {
                return option.label === selectedValue;
              }) : value}
            onChange={(newValue) => handleOnchange(newValue)}
            onCreateOption={handleCreate}
        />
    )
}

export default GlobalCompanyDropdown