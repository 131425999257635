import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem, locations } from 'store/slices/searchSlice';
import { Col, Row } from 'react-bootstrap';
import SectionLoader from 'components/common/Loader/sectionLoader';
import FilterSection from 'components/common/FilterSection';
import ArticleCard from './ArticleCard';
import DefaultSearchPage from '../DefaultSearchPage';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import styles from 'pages/CreatePost/style.module.scss'
import Card from 'pages/CreatePost/card';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const SearchArticle = () => {
  const { query } = useContext(SearchContext);
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const results = item.results;
  const [updatedFilters, setUpdatedFilters] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'articles' }));
    dispatch(locations());
  }, []);

  useEffect(() => {
    if (updatedFilters) {
      dispatch(searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'articles' }));
    }
  }, [updatedFilters]);

  const postedByFilterData = [
    { label: '1st connections', name: '1st_degree', value: '1st_degree' },
    { label: 'Me', name: 'me', value: 'me' },
    { label: 'People you follow', name: 'people_you_follow', value: 'people_you_follow' },
  ];

  const datePostedByFilterData = [
    { label: 'Any time', name: 'created_at', value: 'any_time' },
    { label: 'Past week', name: 'created_at', value: 'past_week' },
    { label: 'Past month', name: 'created_at', value: 'past_month' },
    { label: 'Past 24 hours', name: 'created_at', value: 'past_24_hours' },
  ];

  const sortByFilterData = [
    { label: 'Best Match', name: 'sort_by', value: 'top_match' },
    { label: 'Latest', name: 'sort_by', value: 'latest' },
  ];

  const memberFilterData = [
    ...new Set(results?.map((item) => item?.author?.first_name + ' ' + item?.author?.last_name).flat()),
  ]?.map((item) => {
    if (item !== null) return { label: item, name: item, value: item };
    return null
  });

  const filters = [
    { label: 'Posted by', type: 'checkbox', data: postedByFilterData },
    { label: 'Date posted', type: 'radio', data: datePostedByFilterData },
    { label: 'Sort by', type: 'radio', data: sortByFilterData },
    { label: 'From member', type: 'checkbox', data: memberFilterData, showInput: true },
    { label: 'Keyword', type: '', data: null, showInput: false, showArticleKeywords: true },
  ];

  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);

  const articles =
    results?.length > 0 ? (
      <ResponsiveMasonry columnsCountBreakPoints={{ 200: 1, 1200: 2 }}>
        <Masonry>
          {results?.map((item) => {
            return (
              <Card item={item} readonly={true} />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    ) : <DefaultSearchPage />

  return (
    <React.Fragment>
      <FilterSection filters={filters} filterData={filterData} />
      <h5 className="mb-4">Article</h5>
      {isItemsLoading ? (
        <SectionLoader />
      ) : (
        <div className={styles.draftCont}>
          {articles}
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchArticle;
