import React,{useState} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/common/Loader';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { HandShakeSimpleIcon } from 'components/common/Icons';
import { endorseSkill, getPublicProfile } from 'store/slices/userProfileSlice';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const SeeMoreLessText = styled.p`
  color: var(--heading, #3d5a80);
  font-size: 15px;
  font-family: Inter;
  line-height: 30px;
  cursor: pointer;
`;

export default function Skills(props) {
  const { slug } = useParams();
  const { publicProfileData } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [isSeeMore, setSeeMore] = useState(false);

  const onEndorseSkill = (id) => {
    dispatch(endorseSkill(id)).then((res) => {
      dispatch(getPublicProfile(slug));
    });
  };

  return (
    <section className="profile-skills">
      {publicProfileData ? (
        <div className="mt-3 skills-list">
          {publicProfileData?.user_skills?.slice(0, isSeeMore ? publicProfileData?.user_skills?.length : 3)?.map((item, index) => (
            <div className="skill-item" key={index}>
              <div>{item.name}</div>
              <div className="fs-12 d-flex mt-1">
                {publicProfileData?.id !== item?.user?.id ? (
                  <HandShakeSimpleIcon
                    width="20"
                    height="20"
                    color={item?.agreed_user.find((item) => item.id === publicProfileData?.id) ? '#6BBB84' : '#037F29'}
                    className={'me-1 cursor-pointer'}
                    onClick={
                      item?.agreed_user.find((item) => item.id === publicProfileData?.id)
                        ? null
                        : () => onEndorseSkill(item.id)
                    }
                  />
                ) : null}
                {item?.agreed_user?.length ? (
                  <>
                    <div className="user-list">
                      {item?.agreed_user?.slice(0, 3).map((subItem, index) => (
                        <div className="user-pic">
                          <MyBridgeAvatar
                            icon={subItem.profile_pic}
                            size={20}
                            name={`${subItem.first_name}${subItem.last_name}`}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="ms-2">
                      {item?.agreed_user_count > 3 ? `+${item?.agreed_user_count} agreed` : `agreed`}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ))}
           {publicProfileData?.user_skills?.length > 3 && (
            <SeeMoreLessText onClick={() => setSeeMore(!isSeeMore)}>See {!isSeeMore ? 'more' : 'less'}</SeeMoreLessText>
          )}
        </div>
      ) : (
        <Loader secondary />
      )}
    </section>
  );
}
