import AvatarGroup from "@atlaskit/avatar-group";
import { MyBridgeAvatar } from "components/common/Avatar";
import { CommentsIcon, HandShakeSimpleIcon } from "components/common/Icons";
import { Flex } from "components/core";
import PostContext from "context/PostContext";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const MutualActions = ({ props }) => {
    const { postData } = useContext(PostContext)
    const navigate = useNavigate()
    const { allConnectionIDs } = useSelector(state => state.connection);
    const { circle } = allConnectionIDs ?? {}
    const { comment_by, liked_by, dislike_by } = postData ?? {}
    const mapInCircle = (arr) => arr.filter(v => circle?.includes?.(v.id))
    const { commentsBy, likedBy, dislikedBy } = useMemo(() => {
        return {
            commentsBy: mapInCircle(comment_by ?? []),
            likedBy: mapInCircle(liked_by ?? []),
            dislikedBy: mapInCircle(dislike_by ?? []),
        }
    }, [
        comment_by, liked_by, dislike_by
    ])

    const show = commentsBy?.length > 0 || likedBy?.length > 0 || dislikedBy?.length > 0;
    const mutualActors = commentsBy?.length > likedBy?.length ? (commentsBy?.length > dislikedBy?.length ? commentsBy : (likedBy?.length > dislikedBy?.length ? likedBy : dislikedBy)) : dislikedBy?.length > likedBy?.length ? dislikedBy : likedBy
    // console.log(commentsBy, likedBy, dislikedBy)
    return show ? <Flex className="mutual-actions" style={{ borderBottom: "1px solid #f1f1f1", paddingBottom: "0.5rem" }}>
        {mutualActors?.length ?
            <Flex flex="" width="" align="center" padding="1rem 1rem 0 1rem" gap="0.5rem">
                <CommentsIcon height={18} width={18} />
                <AvatarGroup
                    maxCount={2}
                    appearance="stack"
                    data={mutualActors}
                    overrides={{
                        Avatar: {
                            render(Comp, props, ind) {
                                return (
                                    <MyBridgeAvatar
                                        onClick={() => navigate('/' + props?.slug)}
                                        key={ind}
                                        size={28}
                                        icon={props?.profile_pic?.includes('placeholder') ? undefined : props.profile_pic}
                                        name={props?.first_name + ' ' + props?.last_name}
                                    />
                                );
                            },
                        },
                    }}
                />
                <p> Commented</p>
            </Flex> :
            <></>}

        {/* {likedBy?.length ?
            <Flex flex="" width="" align="center" padding="1rem 1rem 0 1rem" gap="0.5rem">
                <HandShakeSimpleIcon height={18} width={18} />
                <AvatarGroup
                    maxCount={2}
                    appearance="stack"
                    data={likedBy}
                    overrides={{
                        Avatar: {
                            render(Comp, props, ind) {
                                return (
                                    <MyBridgeAvatar
                                        onClick={() => navigate('/' + props?.slug)}
                                        key={ind}
                                        size={28}
                                        icon={props?.profile_pic?.includes('placeholder') ? undefined : props.profile_pic}
                                        name={props?.first_name + ' ' + props?.last_name}
                                    />
                                );
                            },
                        },
                    }}
                />
                <p> Agreed</p>
            </Flex> :
            <></>}
        {dislikedBy?.length ?
            <Flex flex="" width="" align="center" padding="1rem 1rem 0 1rem" gap="0.5rem">
                <HandShakeSimpleIcon color="red" height={18} width={18} />
                <AvatarGroup
                    maxCount={2}
                    appearance="stack"
                    data={dislikedBy}
                    overrides={{
                        Avatar: {
                            render(Comp, props, ind) {
                                return (
                                    <MyBridgeAvatar
                                        onClick={() => navigate('/' + props?.slug)}
                                        key={ind}
                                        size={28}
                                        icon={props?.profile_pic?.includes('placeholder') ? undefined : props.profile_pic}
                                        name={props?.first_name + ' ' + props?.last_name}
                                    />
                                );
                            },
                        },
                    }}
                />
                <p> Disagreed</p>
            </Flex> :
            <></>} */}
    </Flex> : <></>
}