import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import Button from 'components/common/Button';

import ModalClose from 'assets/icons/modalClose.svg';
import RotateLeft from 'assets/icons/rotateLeft.svg';
import RotateRight from 'assets/icons/rotateRight.svg';
import ImageCrop from 'assets/icons/imageCrop.svg';
import ImageCropActive from 'assets/icons/imageCropActive.svg';
import ImageFilter from 'assets/icons/imageFilter.svg';
import ImageFilterActive from 'assets/icons/imageFilterActive.svg';
import ImageAdjust from 'assets/icons/imageAdjust.svg';
import ImageAdjustActive from 'assets/icons/imageAdjustActive.svg';
import Decrease from 'assets/icons/decrease.svg';
import Increase from 'assets/icons/increase.svg';
import { SimpleCloseIcon } from 'components/common/Icons';
import classNames from 'classnames';

import 'react-image-crop/dist/ReactCrop.css';
import './ImageUploader.scss';

const ImageUploader = (props) => {
  const { cropType, uploadCropedImg, handlePopups, ratio } = props;

  const imgRef = useRef(null);
  const inputRef = useRef(null);

  const [src, setSrc] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [crop, setCrop] = useState();
  const [fileData, setFileData] = useState(undefined);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [dragActive, setDragActive] = React.useState(false);
  const [editorFeature, setEditorFeature] = useState('crop');
  const aspect = ratio ? ratio : cropType === 'profilePhoto' ? 1 / 1 : 95 / 24;
  const onImageLoad = (e) => {
    setImageRef(e.target);
    const { width, height } = e.currentTarget;
    const aspectCrop = makeAspectCrop(
      {
        unit: 'px',
        width: width - 15,
      },
      aspect,
      width,
      height,
    );

    const initialCrop = centerCrop(aspectCrop, width, height);
    setCrop(initialCrop);
  };

  const handleFileChange = (e) => {
    setFileData(e.target.files[0]);
    setSrc(URL.createObjectURL(e.target.files[0]));
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileData(e.dataTransfer.files[0]);
      setSrc(URL.createObjectURL(e.dataTransfer.files[0]));
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const getCroppedImage = () => {
    const canvas = document.createElement('canvas');
    const scaleX = imageRef?.naturalWidth / imageRef?.width;
    const scaleY = imageRef?.naturalHeight / imageRef?.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const base64Image = canvas.toDataURL(fileData.type);
    return dataURLtoFile(base64Image, fileData.name);
  };

  const handleSavePhoto = () => {
    const file = getCroppedImage();
    uploadCropedImg(file);
  };

  if (!fileData) {
    return (
      <div className="d-flex flex-column pt-0 p-1 image-uploader">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h1 className="image-uploader__h1">Add Photo </h1>
          <button className={'image-uploader__close-btn'} onClick={handlePopups}>
            <SimpleCloseIcon width="18" height="18" />
          </button>
        </div>

        {/* Body */}
        <div className="image-uploader__canvas" onDragEnter={handleDrag}>
          <input
            type="file"
            ref={inputRef}
            accept="image/png, image/jpeg, image/jpg"
            id="image-uploader-input"
            className="image-uploader__input"
            onChange={handleFileChange}
          />
          <label
            id="image-uploader-label"
            htmlFor="image-uploader-input"
            className={classNames('image-uploader__label', { 'image-uploader__drag-active': dragActive })}
          >
            <h3 className="image-uploader__h3">
              <span className="image-uploader__h3--span">Click to upload</span> or drag and drop
            </h3>
            <p className="image-uploader__p">Maximum file size 50 MB and file should be jpg, jpeg and png</p>
            <p className="image-uploader__p">
              Let's add a recent photo of you. You will be able to edit your photo on the next step.
            </p>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </div>

        {/* Footer */}
        <div className="w-100 d-flex flex-row justify-content-center mt-4">
          {/*<Button className="image-uploader__camera-btn h-36 me-2">Use Camera</Button>*/}
          <Button className="btn btn-primary h-36" onClick={() => inputRef?.current?.click()}>
            Upload Photo
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="image__editor__wrapper">
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between my-2">
        <h5 className="heading__text">{cropType === 'profilePhoto' ? 'Edit Photo' : 'Background photo'} </h5>{' '}
        <span onClick={handlePopups}>
          <img src={ModalClose} alt="close" />
        </span>
      </div>

      {/* Body */}
      <div className="image__editor__container">
        <div className="image__editor__crop">
          <ReactCrop
            crop={crop}
            aspect={aspect}
            onChange={(crop) => setCrop(crop)}
            // keepSelection
          >
            <img
              src={src}
              alt=""
              ref={imgRef}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>

        <div className="image__editor__controls">
          <div className="editor__features">
            <div
              className={`editor__feature editor__feature${editorFeature === 'crop' && '__active'}`}
              onClick={() => setEditorFeature('crop')}
            >
              <img src={editorFeature === 'crop' ? ImageCropActive : ImageCrop} alt="" />
              <span className={`img__features__text${editorFeature === 'crop' && '__active'}`}> Crop</span>
            </div>
            <div
              className={`editor__feature editor__feature${editorFeature === 'filters' && '__active'}`}
              onClick={() => setEditorFeature('filters')}
            >
              <img src={editorFeature === 'filters' ? ImageFilterActive : ImageFilter} alt="" />
              <span className={`img__features__text${editorFeature === 'filters' && '__active'}`}> Filters</span>
            </div>
            <div
              className={`editor__feature editor__feature${editorFeature === 'adjust' && '__active'}`}
              onClick={() => setEditorFeature('adjust')}
            >
              <img src={editorFeature === 'adjust' ? ImageAdjustActive : ImageAdjust} alt="" />
              <span className={`img__features__text${editorFeature === 'adjust' && '__active'}`}> Adjust</span>
            </div>
          </div>

          <div className="rotate__features">
            <img src={RotateRight} alt="" onClick={() => setRotate(rotate + 45)} />
            <img src={RotateLeft} alt="" onClick={() => setRotate(rotate - 45)} />
          </div>
          <div className="img__features">
            <div className="img__features__wrapper">
              <label className="img__features__text" htmlFor="scale-input">
                Zoom: {scale}
              </label>
              <div className="img__features__set">
                <img src={Decrease} alt="" />
                <input
                  id="scale-input"
                  step="0.1"
                  type="range"
                  class="img__form__range"
                  min="1"
                  max="10"
                  value={scale}
                  disabled={!src}
                  onChange={(e) => setScale(e.target.value)}
                />
                <img src={Increase} alt="" />
              </div>
            </div>

            <div className="img__features__wrapper">
              <label className="img__features__text" htmlFor="rotate-input">
                Straighten: {rotate}
              </label>
              <div className="img__features__set">
                <img src={Decrease} alt="" />

                <input
                  type="range"
                  class="img__form__range"
                  id="rotate-input"
                  value={rotate}
                  disabled={!src}
                  onChange={(e) => setRotate(Math.min(180, Math.max(-180, e.target.value)))}
                />
                <img src={Increase} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
       
      <div className="mt-5 " style={{display: 'flex'}}>
      <Button className="btn btn-danger h-36 me-2" onClick={() => {
         const parentElement = imgRef.current.parentElement;
         parentElement.removeChild(imgRef.current);
         setImageRef(null)
         setFileData(null);
      }}>
          Delete Photo
        </Button>
        <input
          type="file"
          ref={inputRef}
          accept="image/png, image/jpeg, image/jpg"
          id="image__uploader__input"
          onChange={handleFileChange}
          style={{ visibility: 'hidden' }}
        />
        <Button className="camera__button h-36 me-2" onClick={() => inputRef?.current?.click()}>
          Change Photo
        </Button>
        <Button className="upload__button h-36" onClick={handleSavePhoto}>
          Save Photo
        </Button>
      </div>
    </div>
  );
};

export default ImageUploader;
