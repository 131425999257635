import React from 'react';
import ReactDOM from 'react-dom';
import SpinnerB from 'react-bootstrap/Spinner';

const MyLoader = ({ className }) => (
  <div className={`loader ${className ? className : ''}`}>
    <span className={className ? 'm-0' : ''}></span>
    {!className && <p>Loading...</p>}
  </div>
);

export const Spinner = ({ ...props }) => (
  <SpinnerB animation="border" role="status" size="sm" {...props}>
    <span className="visually-hidden">Loading...</span>
  </SpinnerB>
);

const portalElement = document.getElementById('loader-root');

const Loader = ({ secondary }) => (
  <>{!secondary ? ReactDOM.createPortal(<MyLoader />, portalElement) : <MyLoader className="secondary" />}</>
);

Loader.Spinner = Spinner;

export default Loader;
