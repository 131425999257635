import React, { useState } from 'react';

const ReadMore = (props) => {
  const { children, wordcount } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <span className="readmoreTxt">
      {isReadMore && children ? children.slice(0, wordcount ? wordcount : 50) : children}
      {children?.length >= wordcount && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? '...see more' : ' see less'}
        </span>
      )}
    </span>
  );
};

export default ReadMore;
