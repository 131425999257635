import React from 'react';
import { AddCircle, ChevronLeft, MapMarker, Search, Arrowback, AdddCircleIcon } from 'components/common/Icons';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from 'store/actions/Job.Action';
import { Link } from 'react-router-dom';
import { Input } from 'components/common/Input';
import SubHeaderSearch from 'components/SubHeaderSearchForm';
import classNames from 'classnames';

const JobSearchHead = (props) => {
  const { setData, searchFilters } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postJobs = () => {
    navigate('/create/company/job');
  };
  const backTo = () => {
    navigate(-1);
    dispatch(clear());
  };
  return (
    <div className="jobs-header">
      <div className="container">
        <div className={classNames('jobs-header--top-container', [props.search ? '' : 'mb-0'])}>
          <Link onClick={() => backTo()}>
            <Arrowback /> Back
          </Link>
        </div>
        {props.search && (
          <div className="jobs-header--bottom-container">
            <SubHeaderSearch searchData={(value) => setData(value)} searchFilters={searchFilters} />
            <Link to='/create/company/job'>
              <AdddCircleIcon /> Post Job
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default JobSearchHead;
