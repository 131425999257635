import React, { useRef, useState } from 'react';
import GridContainer from '../PostCard/Comment/components/GridContainer';
import styled from 'styled-components';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { GifIcon } from 'components/common/Icons';
import { Flex } from 'components/core';
import { DropdownContainer } from './DropdownMenu';

const Conatiner = styled.button`
  display: flex;
  position: relative;
  cursor:pointer;
  border:none;
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.5;'}
`;

const GiphyPicker = ({ onSelect, disabled }) => {
  const searchBar = useRef();
  const [searched, setSearched] = useState(false);
  const [searchQuery, setSearcheQuery] = useState('');
  const [showGifPicker, setShowGifPicker] = React.useState(false);

  const ref = React.useRef(null);

  useOnClickOutside(ref, () => {
    setShowGifPicker(false);
  });

  function search(e) {
    if (e.keyCode === 13 && e.target.value !== '') {
      setSearched(true);
      setSearcheQuery(e.target.value);
    }
  }

  const handleClear = () => {
    setSearched(false);
    setSearcheQuery('');
    searchBar.current.value = '';
  };

  return (
    <Conatiner
      disabled={disabled}
      // ref={ref}
      onClick={() => {
        if (disabled) return;
        setShowGifPicker(!showGifPicker);
      }}
    >
      <div ref={ref}>
        <GifIcon color="#5B5B5B" />
      </div>
      {/* {showGifPicker && ( */}
      <DropdownContainer targetRef={ref}>
        <Flex top="50px" left="-275px" zIndex={2} >
          <div className="giphy-picker">
            <div className="giphy-picker--header">
              <div className="position-relative">
                <svg width={15} height={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                </svg>
                <input onClick={e => { e.stopPropagation() }} ref={searchBar} onKeyDown={search} className="text-truncate" type="text" placeholder="Search" />
              </div>
            </div>
            <div className="giphy-picker--body pt-0">
              <GridContainer onGifClick={onSelect} searchQuery={searchQuery} searched={searched} />
            </div>
          </div>
        </Flex>
      </DropdownContainer>
      {/* )} */}
    </Conatiner>
  );
};

GiphyPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

GiphyPicker.defaultProps = {
  onSelect: () => { },
  disabled: false,
};

export default GiphyPicker;
