import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Button, Text } from 'components/StyledComponents';
import { Input, TextArea } from 'components/common/Input';
import RadioButton from 'components/common/RadioButton';
import classNames from 'classnames';
import { getAllCompanyPages } from 'store/actions/companyPages.Action';

const ApplySection = ({ setSection, jobDetails, setJobDetails }) => {
  const dispatch = useDispatch();
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const [howToApply, setHowToApply] = useState('EMAIL');
  const [whyWorkHere, setWhyWorkHere] = useState();
  useEffect(() => {
    dispatch(getAllCompanyPages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, setErrors] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    setWhyWorkHere(companyPageDetail?.why_work_here);
  }, [companyPageDetail]);

  const setData = (e) => {
    const { name, value } = e.target;
    if(name === 'how_to_apply'){
      setHowToApply(value)
    }
    if(name === 'why_work_here'){
      setJobDetails((state) => ({
        ...state,
        ['why_work_here']: whyWorkHere,
      }));
    }
    setJobDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onClickBack = (e) => {
    e.preventDefault();
    setSection(2);
  };
  
  const onClickNext = (e) => {
    e.preventDefault();
    const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'g');
    const websiteRegex = new RegExp(/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g);

    const newErrors = {};

    if (!jobDetails?.how_to_apply) {
      let data = { target: { name: 'how_to_apply', value: 'EMAIL' } }
      setData(data);
      // newErrors.how_to_apply = 'error';
    }

    if (jobDetails?.how_to_apply && !jobDetails?.how_to_apply_text) {
      newErrors.how_to_apply_text = 'error';
    }

    // if (jobDetails?.how_to_apply && jobDetails?.how_to_apply_text) {
      if (jobDetails?.how_to_apply === 'EMAIL' && !emailRegex.test(jobDetails.how_to_apply_text)) {
        newErrors.how_to_apply_text = 'error';
      }
      if (jobDetails?.how_to_apply === 'EXTERNAL' && !websiteRegex.test(jobDetails.how_to_apply_text)) {
        newErrors.how_to_apply_text = 'error';
      }
    // }
      let whyData = { target: { name: 'why_work_here', value: whyWorkHere } }
    if (!jobDetails.why_work_here) {
      setData(whyData);
    }
    if (jobDetails.why_work_here && jobDetails.why_work_here.length > 140) { 
      newErrors.why_work_here_length = 'error';
    }

    setErrors(newErrors);

    if (isEmpty(newErrors)) {
      setSection(4);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex align-items-center flex-column">
        <div className="bordered-container col-md-12 mt-4 py-4">
          <div className="d-flex flex-column gap-1">
            <Text color="#868585">How would you like people to apply?*</Text>
            <RadioButton
              changed={setData}
              name="how_to_apply"
              label="Let candidates apply with their myBridge profile and notify me by email (recommended)"
              value="EMAIL"
              containerClass="job-fields"
              isSelected={jobDetails.how_to_apply === 'EMAIL' || howToApply === 'EMAIL' ? true : false}
            />
            {errors.how_to_apply && <p className="text-danger">Please select how to apply</p>}

            {(jobDetails.how_to_apply === 'EMAIL' || howToApply === 'EMAIL') && (
              <Input
                type="text"
                placeholder="Email"
                name="how_to_apply_text"
                value={jobDetails.how_to_apply === 'EMAIL' || howToApply === 'EMAIL' ? jobDetails.how_to_apply_text : ''}
                onChange={setData}
                className="jobFields"
              />
            )}
            {jobDetails.how_to_apply === 'EMAIL'  && errors.how_to_apply_text && (
              <p className="text-danger">Please enter correct email</p>
            )}

            <RadioButton
              changed={(e) => { setData(e); setHowToApply('EXTERNAL')}}
              name="how_to_apply"
              label="Direct applicants to an external site to apply"
              value="EXTERNAL"
              containerClass="job-fields"
              isSelected={jobDetails.how_to_apply === 'EXTERNAL' || howToApply === 'EXTERNAL' ? true : false}
            />
            {errors.how_to_apply && <p className="text-danger">Please select how to apply</p>}

            {(jobDetails.how_to_apply === 'EXTERNAL' ||  howToApply === 'EXTERNAL') && (
              <Input
                type="text"
                placeholder="https://abc.domain.com"
                name="how_to_apply_text"
                value={jobDetails.how_to_apply === 'EXTERNAL' ||  howToApply === 'EXTERNAL' ? jobDetails.how_to_apply_text : ''}
                onChange={setData}
                className="jobFields"
              />
            )}
            {jobDetails.how_to_apply === 'EXTERNAL' && errors.how_to_apply_text && (
              <p className="text-danger">Please enter correct domain</p>
            )}
          </div>

          <div className="d-flex flex-column gap-1 mt-3">
            <Text color="#868585">Why Work at this Company?*</Text>
            <TextArea
              type="text"
              placeholder="Why Work at this Company?*"
              name="why_work_here"
              value={whyWorkHere}
              onChange={(e) => { setData(e); setCount(e.target.value.length); setWhyWorkHere(e.target.value)}}
              containerClass="mb-0"
              className={classNames("jobFields", errors.why_work_here || count > 140 ? 'is-invalid':'')}
              maxLength={140}
              error={errors.why_work_here && 'Please enter why work here' || errors.why_work_here_length && 'You are exeeding the limit'}
            />
            <Text color="#868585" className='d-flex justify-content-between'>
              Give a one-line sales pitch for working at this company (140 characters max.) Note: editing this field will affect all jobs at this hiring company.
              <span>({count} / 140)</span>
            </Text>
          </div>

          {/* <div className="d-flex flex-column gap-1 mt-3">
            <Text color="#868585">Add to a project</Text>
            <TextArea
              type="text"
              placeholder="Add to a project"
              name="add_tp_project"
              value={jobDetails.add_to_project}
              onChange={setData}
              containerClass="mb-0"
              className="jobFields"
            />
            <Text color="#868585">
              Your job will be created within a project to help you manage application, source passive candidates and
              get recommended matches in one place
            </Text>
          </div> */}

          <div className="d-flex gap-4 pt-5 justify-content-end">
            <Button padding=".7rem" width="160px" bgColor="#E4E6EB" hoverBgColor="#3D5A80" color="#5B5B5B" onClick={onClickBack}>
              Back
            </Button>
            <Button padding=".7rem" width="160px" bgColor="#3D5A80" hoverBgColor="#003049" color="#fff" onClick={onClickNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplySection;
