// write code for text input using styled components with icon at left side
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e6eb;
  background: #fff;
  padding: 5px 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #5b5b5b;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #747474;
  margin-left: 10px;
`;

const TextInput = ({readonly , placeholder, iconLeft, ...rest }) => {
  return (
    <Container>
      {iconLeft && iconLeft}
      <Input disabled={readonly} placeholder={placeholder} {...rest} />
    </Container>
  );
};

export default TextInput;
