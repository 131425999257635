import { BlockIcon } from 'components/common/Icons';
import Loader from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blockConnection, getConnectionsData, reportConnection } from 'store/slices/connectionSlice';

const BlockModal = ({ onClose, name, id }) => {
  const isLoading = useSelector((state) => state.connection.childLoading);
  const userBlock = useSelector((state) => state.connection.block);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    const payload = {
      block_user_id: id,
    };
    dispatch(blockConnection(payload));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && (
        <Modal
          onClose={onClose}
          className="confirmation-modal"
          heading={!userBlock && <h3>Block {name}?</h3>}
          action={
            <>
              <button className={`btn btn-secondary ${userBlock ? 'mx-auto' : ''}`} onClick={handleClose}>
                {!userBlock ? 'Cancel' : 'Close'}
              </button>
              {!userBlock && (
                <button className="btn btn-primary" onClick={handleConfirm}>
                  Confirm
                </button>
              )}
            </>
          }
        >
          {!userBlock ? (
            <>
              <p className="mb-3">
                <strong>
                  <span className="text-capitalize">{name}</span> will no longer be able to:
                </strong>
              </p>

              <ul className="mb-4">
                <li>See your posts on your timeline</li>
                <li>Tag you</li>
                <li>Invite you to events or groups</li>
                <li>Message you</li>
                <li>Add you as a friend</li>
              </ul>

              <p>
                If you're friends, blocking <strong className="text-capitalize">{name}</strong> will also unfriend them.
              </p>

              <p>
                If you just want to limit what you share with <strong className="text-capitalize">{name}</strong> or see
                less of them on myBridge, you can take a break from them instead.
              </p>
            </>
          ) : (
            <div className="pt-5 text-center">
              <BlockIcon color="#000" width={80} height={80} className="mb-3" />
              <h4 className="text-capitalize mb-0 lh-base">
                {name} {userBlock.message}
              </h4>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default BlockModal;
