import { createReducer } from "@reduxjs/toolkit";
import{setGroupId} from '../actions/teams.Action'

const initialState = {
    groupId:undefined,
  };

  const teamsReducer = createReducer(initialState, builder => {
    builder
      .addCase(setGroupId, (state, action) => {
        state.groupId = action.payload;
      })
      
      .addDefaultCase((state, action) => { });
  });

  export default teamsReducer;