import { createReducer } from '@reduxjs/toolkit';
import {
  listOfReceivedTestimonial,
  patchTestimonial,
  listOfReceivedTestimonialPending,
  listOfAskedTestimonialRequest,
  listOfGiveTestimonialPendingRequest,
  listOfGiveTestimonial,
  deleteTestimonial,
  addToProfile,
  giveTestimonalForAsked,
  askTestimonal,
  giveTestimonal,
} from '../actions/testimonial.Action';

const initialState = {
  receivedTestimonial: [],
  receivedTestimonialPending: [],
  giveTestimonialPending: [],
  askedTestimonial: [],
  giveTestimonial: [],
  deletedTestimonial: {},
  addedToProfile: {},
  testimonalForAsked: [],
  askTestimonal: undefined,
  giveTestimonal: undefined,

  receivedTestimonialUpdatedFlag: false,
};

const TestimonialReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(listOfReceivedTestimonial.fulfilled, (state, action) => {
      state.receivedTestimonial = action.payload;
    })
    .addCase(listOfReceivedTestimonialPending.fulfilled, (state, action) => {
      state.receivedTestimonialPending = action.payload;
    })
    .addCase(listOfAskedTestimonialRequest.fulfilled, (state, action) => {
      state.askedTestimonial = action.payload;
    })
    .addCase(listOfGiveTestimonialPendingRequest.fulfilled, (state, action) => {
      state.giveTestimonialPending = action.payload;
    })
    .addCase(listOfGiveTestimonial.fulfilled, (state, action) => {
      state.giveTestimonial = action.payload;
    })
    .addCase(addToProfile.fulfilled, (state, action) => {
      state.addedToProfile = action.payload;
    })
    .addCase(deleteTestimonial.fulfilled, (state, action) => {
      state.receivedTestimonialUpdatedFlag = !state.receivedTestimonialUpdatedFlag;
    })
    .addCase(patchTestimonial.fulfilled, (state, action) => {
      state.receivedTestimonialUpdatedFlag = action.payload;
    })
    .addCase(giveTestimonalForAsked.fulfilled, (state, action) => {
      state.testimonalForAsked = action.payload;
    })
    .addCase(askTestimonal.fulfilled, (state, action) => {
      state.askTestimonal = action.payload;
    })
    .addCase(giveTestimonal.fulfilled, (state, action) => {
      state.giveTestimonal = action.payload;
    })
    .addDefaultCase((state, action) => {});
});

export default TestimonialReducer;
