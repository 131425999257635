import styled from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 38px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #3d5a80;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #3d5a80;
  margin-right: 10px;
`;

const SeeParticipants = ({ eventId, title = 'See participants', ...rest }) => {
  return <Container {...rest}>{title}</Container>;
};

export default SeeParticipants;
