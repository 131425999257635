import React, { useState, useEffect } from 'react';
import { EventIcon, CalendarIcon, Presentation } from 'components/common/Icons';
import './styleMeeting.scss';
import { useNavigate } from 'react-router-dom';
import Meeting from 'components/Meeting';
import { useSelector, useDispatch } from 'react-redux';
import { joinedUsersMeeting, setShowMeet, generateMeetingId, updateMeetingSecurity, checkMeetingSecurity } from 'store/slices/meetingSlice';
import { CometChat } from '@cometchat-pro/chat';
import Modal from "components/common/Modal/Modal";

const CreateDirectMeeting = (props) => {
  const { inMeeting, onClose, joinedMeetingData, isJoinAMeeting } = props;
  const { generatedMeetingId } = useSelector((state) => state.meeting);
  const [joinMeeting, setJoinMeeting] = useState(isJoinAMeeting);
  const [startedMeeting, setStartedMeeting] = useState(false);
  const [newMeetingId, setNewMeetingId] = useState();
  const [meetingAdmin, setMeetingAdmin] = useState();
  const [meetingAttendee, setMeetingAttendee] = useState();
  const [waitingOpen, setWaitingOpen] = useState(false);
  const [waitingRoom, setWaitingRoom] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const style = {
    minWidth: 65
  }
  useEffect(() => {
    if (Object.keys(joinedMeetingData).length) {
      setNewMeetingId(joinedMeetingData?.meetingId);
      if (joinedMeetingData.created_by !== null) {
        CometChat.getLoggedinUser()
          .then((user) => setMeetingAdmin(user))
          .catch((error) => {
            console.error(error);
          });
      } else {
        CometChat.getLoggedinUser()
          .then((user) => setMeetingAttendee(user))
          .catch((error) => {
            console.error(error);
          });
      }
      setJoinMeeting(true);
    }
  }, [joinedMeetingData]);

  const handleStartMeeting = (meetingNumber) => {
    const meetingCode = parseInt(Math.random().toFixed(6).replace('0.', ''));
    const meetingId = meetingNumber ? meetingNumber : generatedMeetingId;
    setNewMeetingId(meetingId);
    if (meetingNumber) {
      dispatch(checkMeetingSecurity(meetingNumber)).then((res) => {
      CometChat.getLoggedinUser()
        .then((user) => {setMeetingAttendee(user); setWaitingRoom(res?.payload?.meeting_detail?.waiting_room);})
        .catch((error) => {
          console.error(error);
        });
      });
      dispatch(joinedUsersMeeting({ joinedUser: userProfileInfo, meetingId: meetingNumber }));
    } else {
      CometChat.getLoggedinUser()
          .then((user) => {setMeetingAdmin(user); })
          .catch((error) => {
            console.error(error);
          });
    }
    
    setWaitingOpen(false);
  };

  const closeMeeting = (close) => {
    setStartedMeeting(close);
    setJoinMeeting(false);
  };

  const handleJoinMeeting = () => {
    setJoinMeeting(true);
    setWaitingOpen(false);
  };

  const handleCheckWaiting = () => {
    dispatch(generateMeetingId()).then(res => {
      if(res) setWaitingOpen(true);
    });
    
  }

  useEffect(() => {
    if ((meetingAdmin || meetingAttendee) && newMeetingId && !startedMeeting) {
      setStartedMeeting(true);
    }
  }, [meetingAdmin, meetingAttendee, newMeetingId]);


  return (
    <React.Fragment>
      {startedMeeting ? (
        <Meeting
          meetingID={newMeetingId}
          joinedBy={meetingAttendee}
          createdBy={meetingAdmin}
          meetingState={(close) => closeMeeting(close)}
          callType="meeting"
          // TODO : check waiting room is working propely from here.
          isWaiting={waitingRoom}
        />
      ) : (
        <div className="new-chat-wrapper position-relative">
          <div className="main__chat" dir="ltr">
            <div className="chat__header">
              <div className="chat__details">
                <div className="chat__meeting">
                  <h6 className="meeting__name">{joinMeeting ? 'Join Meeting' : 'Meeting Room'}</h6>
                </div>
              </div>
              <div className="chat__options">
                <div title="Close chat" onClick={onClose} className="chat-close">
                  <i></i>
                </div>
              </div>
            </div>

            <div className="rbt-wrap"></div>

            {!joinMeeting ? (
              <>
                <div className="options_list">
                  <div className="option_list--inner">
                    <span
                      className="margin-top-5 d-flex align-items-center cursor-pointer"
                      onClick={() => handleCheckWaiting()}
                    >
                      <EventIcon className="default" width="18" height="18" style={{ marginRight: '5px' }} /> Meet Now
                    </span>
                    <span
                      className="margin-top-5 d-flex align-items-center cursor-pointer"
                      onClick={() => handleJoinMeeting()}
                    >
                      <Presentation className="default" width="18" height="18" style={{ marginRight: '5px' }} /> Join a
                      Meeting
                    </span>
                    <span
                      className="margin-top-5 d-flex align-items-center cursor-pointer"
                        onClick={() => { navigate('/profile/calendar'); dispatch(setShowMeet(false)); }}
                    >
                      <CalendarIcon className="default" width="18" height="18" style={{ marginRight: '5px' }} />{' '}
                      Schedule a Meeting
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div className="options_list">
                <input
                  type="text"
                  placeholder="Enter a code"
                  onChange={(e) => setNewMeetingId(e.target.value)}
                  className="border-0 px-2 py-1 mb-3 rounded input-bg-gray"
                />
                <button className="btn btn-primary px-5" type="button" onClick={() => handleStartMeeting(newMeetingId)}>
                  Join
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {waitingOpen && <Modal
        onClose={onClose}
        className="confirmation-modal"
        heading={
          <h3>Need waiting room?</h3>
        }
        action={
          <>
            <button className={`btn btn-secondary btn-sm`} onClick={onClose}>
              Cancel
            </button>
            <button style={style} onClick={() => handleStartMeeting()} className="btn btn-primary btn-sm">
              Meet Now
            </button>
          </>
        }
      >
        <>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="waitingRoom"
              checked={waitingRoom}
              onChange={(e) => {setWaitingRoom(e.target.checked); dispatch(updateMeetingSecurity({meeting_id:generatedMeetingId, waiting_room:e.target.checked === true ? 'True' : 'False'}))}}
            />
            <label className="form-check-label" for="waitingRoom">
              Waiting Room
            </label>
          </div>
          <span className="form-text">Only users admitted by the host can join the meeting</span>
        </>
      </Modal>}

    </React.Fragment>
  );
};

export default CreateDirectMeeting;
