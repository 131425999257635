import React, {useState, useEffect} from 'react';
import { ReportBugIcon, MenuThreeDots, SimpleTickIcon, DropNotifIcon, SettingsNewIcon } from 'components/Layouts/assets/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { MyBridgeAvatar } from '../Avatar';
import { HandShakeIcon } from '../Icons';
import { NotifHandShakeIcon, NotifMeetingIcon, NotifDiskIcon, NotifTestimonialIcon, NotifTeamIcon } from '../Icons/NotificationIcons';
import { useInbox } from "@trycourier/react-hooks";
import { cleanHTML } from 'components/utils';
import axiosInstance from 'store/common/axios';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

const NotificationCard = (props) => {
  const { details } = props;
  const inbox = useInbox();
  const token = localStorage.getItem('access');
  const dispatch = useDispatch();
  const date = moment.utc(details?.created).local();
  const { messages } = useSelector((state) => state.notification);
  const [inboxMessage, setInboxMessage] = useState(null);
  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  
  const handleReadMessage = (message) => (event) => {
    event.preventDefault();
    inbox.markMessageRead(message.messageId);
  };

  const handleRemoveMessage = (message) => (event) => {
    event.preventDefault();
    inbox.markMessageArchived(message.messageId);
  };

  const notificationIcon = (title) => {
    if (title === "handshake_request") {
      return <NotifHandShakeIcon />;
    } else if (title === "meeting_invite" || title === "attendees_update") {
      return <NotifMeetingIcon />;
    } else if (_.includes(title, 'Testimonial')) {
      return <NotifTestimonialIcon />;
    } else if (title === "team_update") {
      return <NotifTeamIcon />;
    } else if (title === "disk_shared") {
      return <NotifDiskIcon />;
    }  else {
      return <NotifTestimonialIcon />;
    }
  }

  const handleAction = async (url) => {
    await fetch(url, {
      mode: "no-cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    // const response = await axiosInstance.patch(url, '', headers);
    // console.log(response);
  }

  return (
    <div className="notif-card">
      <div className="notif-card--inner">
        <div className='notif-card--profile'>
          <MyBridgeAvatar
            round={true}
            icon={details?.data?.created_by?.profile_pic?.split('?')[0]}
            size={60}
            name={`${details?.data?.created_by.first_name} ${details?.data?.created_by.last_name}`}
          />
          <div className="notif-icon">{notificationIcon(details?.data?.notif_type)}</div>
        </div>
        <div className="notif-card--details">
          <p className='notif-card--title' dangerouslySetInnerHTML={{ __html: details?.title }}></p>
          <p className='notif-card--preview' dangerouslySetInnerHTML={{ __html: details?.preview }}></p>
          <ul className="notif-actions">
            {details?.actions?.map((action, i) => (
              <li>
                {/* <a href={`${action.href}`}>{action.content}</a> */}
                <a onClick={() => handleAction(action.href)}>{action.content}</a>
              </li>
            ))}
          </ul>
          <span className="notif-time">{date.fromNow()}</span>
        </div>
        <div className="notif-card--actions">
          {details.read ? <React.Fragment>&nbsp;</React.Fragment> : <div className="notif-card--statusdot"></div>}
          <Dropdown>
            <Dropdown.Toggle as="span" bsPrefix="notif-dropdown">
              <div className="circle-drop-container"><MenuThreeDots width={11} height={4} /></div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleReadMessage(details)}><SimpleTickIcon /> Mark as read</Dropdown.Item>
              <Dropdown.Item onClick={handleRemoveMessage(details)}><DropNotifIcon /> Remove this notification</Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3"><SettingsNewIcon /> Turn off notifications about live videos form Les Brown</Dropdown.Item> */}
              <Dropdown.Item><ReportBugIcon /> Report issue</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>

  )
}

export default NotificationCard;