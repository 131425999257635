import Button from 'components/common/Button'
import { Input } from 'components/common/Input'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signUpUser, addUser, signInUser, setInactiveEmail } from 'store/slices/authSlice'
import { useNavigate } from 'react-router-dom';
import GoogleLoginComponent from '../GoogleLogin/GoogleLogin'
import { getUserProfileInfo } from 'store/slices/userProfileSlice';
import { getUpcomingEvents } from 'store/slices/calendarSlice';
import { getConnectionsData } from 'store/slices/connectionSlice';
import { fetchPosts } from 'store/slices/postSlice';
import moment from 'moment';

const ERROR_VALUES = {
    firstName: 'Please enter a first name',
    firstNameReg: 'Field should only contain alphabets',
    lastNameReg: 'Field should only contain alphabets',
    lastName: 'Please enter a last name',
    signUpEmail: 'Please enter a valid email id',
    signUpPassword: 'Please enter a password',
    upperCaseError: 'At least one Uppercase letter',
    lowerCaseError: 'At least one Lowercase letter',
    digitsError: 'At least one digit',
    specialCharError: 'At least one Special Characters',
    signUpPasswordLength: 'Password is too short',
    signUpConfirmPassword: 'Please enter a confirm password',
    isNotSamePassword: 'Password and confirm password are not same'
}

export default function Signup() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [registerError, setRegisterError] = useState({});
    const [fields, setFields] = useState({})

    const { loading, msg, error, email, access, signInPage } = useSelector((state) => state.user)
    const [showMsg, setShowMsg] = useState(false);

    useEffect(() => {
        if (access) {
            dispatch(addUser(access));
            navigate('/onboarding/profile');
            return
        }
    }, [access]);

    const handleSignup = () => {
        const errorObject = {}
        const patterns = {
            firstName: /^[A-Za-z]+$/,
            email: /^([a-z\d\.\+-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
        };
        //for password 
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const { firstName, lastName, signUpEmail, signUpPassword, signUpConfirmPassword } = fields;
        if (!firstName) {
            errorObject.firstName = ERROR_VALUES.firstName
        }
        if ((!patterns.firstName.test(firstName))) {
            errorObject.firstNameReg = ERROR_VALUES.firstNameReg
        }
        if ((!patterns.firstName.test(lastName))) {
            errorObject.lastNameReg = ERROR_VALUES.lastNameReg
        }
        if (!lastName) {
            errorObject.lastName = ERROR_VALUES.lastName
        }
        if (!signUpEmail || !patterns.email.test(signUpEmail)) {
            errorObject.signUpEmail = ERROR_VALUES.signUpEmail
        }
        if (!signUpPassword) {
            errorObject.signUpPassword = ERROR_VALUES.signUpPassword
        }
        if (!uppercaseRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.upperCaseError
        }
        if (!lowercaseRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.lowerCaseError
        }
        if (!digitsRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.digitsError
        }
        if (!specialCharRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.specialCharError
        }
        if (!minLengthRegExp.test(signUpPassword)) {
            errorObject.signUpPassword = ERROR_VALUES.signUpPasswordLength
        }
        if (!signUpConfirmPassword) {
            errorObject.signUpConfirmPassword = ERROR_VALUES.signUpConfirmPassword
        }
        if (signUpConfirmPassword && signUpPassword !== signUpConfirmPassword) {
            errorObject.isNotSamePassword = ERROR_VALUES.isNotSamePassword
        }

        if (Object.keys(errorObject).length > 0) {
            setRegisterError(errorObject)
            return
        } else {
            setRegisterError({});
        }
        dispatch(signUpUser({
            first_name: firstName,
            last_name: lastName,
            email: signUpEmail,
            password: signUpPassword,
            password2: signUpConfirmPassword
        })).then(res => {
            if (!res.payload.email) {
                dispatch(setInactiveEmail(signUpEmail));
                localStorage.setItem('access', res.payload.access);
                localStorage.setItem('refresh', res.payload.refresh);
                // navigate('/onboarding/profile');
                window.location.assign('/onboarding/profile');
                setShowMsg(true);
            } else {
                setShowMsg(true);
            }
        })


    }

    const signUpOnChange = (e) => {
        const { name, value } = e.target;
        setFields((prevValues) => ({ ...prevValues, [name]: value }))
        const isValue = value.trim().length > 0;
        setRegisterError(prevErrors => ({ ...prevErrors, [name]: isValue ? undefined : ERROR_VALUES[name] }))
    }

    return (
        <>
            <div className="user-flow--form">
                <h2 className="user-flow--formHeader">Sign up</h2>
                {/* <p className="user-flow--formSubHeader">Make the most of your professional life</p> */}

                {showMsg &&
                    <div>
                        {email && !error && <p className="text-success helper-text my-2">{msg}</p>}
                        {error && <p className="text-danger helper-text my-2">{msg}</p>}
                    </div>
                }
                <div className="row">
                    <div className="col">
                        <Input
                            name="firstName"
                            value={fields.firstName}
                            onChange={(e) => signUpOnChange(e)}
                            className={registerError.firstName || registerError.firstNameReg ? 'is-invalid' : ''}
                            placeholder="First Name"
                            error={registerError.firstName && registerError.firstName || registerError.firstNameReg && registerError.firstNameReg}
                            label="First Name"
                        />
                    </div>
                    <div className="col">
                        <Input
                            name="lastName"
                            value={fields.lastName}
                            onChange={(e) => signUpOnChange(e)}
                            className={registerError.lastName || registerError.lastNameReg ? 'is-invalid' : ''}
                            placeholder="Last Name"
                            error={registerError.lastName && registerError.lastName || registerError.lastNameReg && registerError.lastNameReg}
                            label="Last Name"
                        />
                    </div>
                </div>


                <Input
                    type="email"
                    name="signUpEmail"
                    value={fields.signUpEmail}
                    onChange={(e) => signUpOnChange(e)}
                    className={registerError.signUpEmail ? 'is-invalid' : ''}
                    placeholder="Email"
                    error={registerError.signUpEmail && registerError.signUpEmail}
                    label="Email"
                />

                <Input
                    type="password"
                    name="signUpPassword"
                    value={fields.signUpPassword}
                    onChange={(e) => signUpOnChange(e)}
                    className={registerError.signUpPassword ? 'is-invalid' : ''}
                    placeholder="Password"
                    error={registerError.signUpPassword && registerError.signUpPassword}
                    label="Password"
                />
                {/* <div className='form-text' style={{ "margin-top": "-20px", "margin-bottom": "24px" }}>The password must be at least 8 characters long, contain a combination of upper and lower-case letters, at least one number and one special character.</div> */}


                <Input
                    type="password"
                    name="signUpConfirmPassword"
                    value={fields.signUpConfirmPassword}
                    onChange={(e) => signUpOnChange(e)}
                    className={registerError.signUpConfirmPassword || registerError.isNotSamePassword ? 'is-invalid' : ''}
                    placeholder="Confirm Password"
                    error={registerError.signUpConfirmPassword && registerError.signUpConfirmPassword || registerError.isNotSamePassword && registerError.isNotSamePassword}
                    label="Confirm Password"
                />

                <Button className='full-width mt-2' onClick={handleSignup}>
                    Sign Up
                </Button>
                <p className="mt-3 text-center">
                    Already on myBridge?
                    <a className='text-btn ms-1' href='/signin'>
                        Sign in
                    </a>
                </p>
                <div className='separator'>
                    <span>Or</span>
                </div>

                <GoogleLoginComponent path='signIn' />
            </div>

        </>
    )
}
