import RadioButton from "components/common/RadioButton";
import { isEmpty, isUndefined } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOnboardingData } from "store/slices/onboardingSlice";
import OnboardingWrapper from "../Components/OnboardingWrapper";
import { onboardingSteps } from "../constant";
import Button from "components/common/Button";

const currStep = onboardingSteps[3];
const nextStep = onboardingSteps[4];
const currActiveSteps = [onboardingSteps[0].id, onboardingSteps[1].id, onboardingSteps[2].id, onboardingSteps[3].id];

const jobArr = [
  {
    id: 0,
    value: "Yes, I'm looking for a career change."
  },
  {
    id: 1,
    value: "No, but I could be tempted."
  },
  {
    id: 2,
    value: "No, I'm all set."
  },
]

const OnboardingJob = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [reportReason, setReportReason] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (id) => {
    setReportReason(id)
  };
  console.log(userProfileInfo);
  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      step: currStep.name,
      json: true,
      isFormData: false,
      emailId:userProfileInfo.email,
      data: {
        job_availabilty: reportReason
      }
    }

    dispatch(setOnboardingData(payload))
    navigate(nextStep.url);
  }

  const handleBack = () => {
    navigate(onboardingSteps[2].url)
  };

  const isValid = isUndefined(reportReason);

  return (
    <OnboardingWrapper activeSteps={currActiveSteps}>
      <h3>Next question: Are you wanting to elevate your career?</h3>
      {/* <p className='mb-3'>Your response is private to you.</p> */}

      <form onSubmit={onSubmit}>
        <div className="radio-wrapper">
          {jobArr?.map((job) => {
            return (
              <RadioButton
                key={job.id}
                id={job.id}
                changed={() => handleChange(job.id)}
                name="report"
                label={job.value}
                value={job.value}
              />
            )
          })}
        </div>

        {/* <button type="submit" className="submit" disabled={isValid ? true : false}>
          Next
        </button> */}
        <Button type='submit' disabled={isValid ? true : false} className="w-100 mt-3">
          Next
        </Button>
        <ul className='additional-actions'>
          <li onClick={handleBack}>Back</li>
        </ul>
      </form>
    </OnboardingWrapper>
  )
};

export default OnboardingJob;