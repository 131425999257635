import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MyBridgeAvatar } from 'components/common/Avatar';
import { Flex } from 'components/core';
import PostContext from 'context/PostContext';

import PostHeaderLeft from './PostHeaderLeft';
import PostHeaderRight from './PostHeaderRigth';
import { Text } from 'components/StyledComponents';
import AvatarGroup from '@atlaskit/avatar-group';
import { CommentsIcon } from 'components/common/Icons';
import { MutualActions } from './MutualActions';

export const HeaderConatiner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: 1rem;
`;

const PostHeader = () => {
  const { postData, repost } = useContext(PostContext);
  const navigate = useNavigate();
  const { author, company, comment_by } = postData;
  const navigateTo = company?.slug !== '' ? `/company/${company?.slug}/overview` : `/${author.slug}`;
  const username = company?.name !== '' ? company?.name : `${author?.first_name} ${author?.last_name}`;
  const icon = company?.image !== null ? company?.image : author?.profile_pic;
  return (<>
    <MutualActions />
    <HeaderConatiner>
      <MyBridgeAvatar
        size={40}
        name={username}
        icon={icon}
        className={'cursor-pointer'}
        onClick={() => navigate(navigateTo)}
      />
      <Flex justify="space-between" align="center" width="100%">
        <PostHeaderLeft />
        {!repost && <PostHeaderRight />}
      </Flex>

    </HeaderConatiner>
  </>
  );
};

export default PostHeader;
