import React from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon, SimpleCloseIcon } from '../Icons';

const Backdrop = ({ onClose }) => {
  return <div onClick={onClose} className="modal-backdrop fade show" />;
};

const ModalOverlay = ({ children, className, onClose, heading, action, customSpace, form, formAction }) => {
  const modalClass = `modal fade show d-block ${className ? className : ''}`;
  const modalBody = `modal-body ${customSpace ? customSpace : ''}`;
  return (
    <div className={modalClass} onClick={onClose}>
      {/* <div className={modalClass}> */}
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
        {/* <div className="modal-dialog modal-dialog-centered"> */}
        {!form ? (
          <div className="modal-content">
            {heading && (
              <div className="modal-header">
                {heading}
                <button onClick={onClose}>
                  <SimpleCloseIcon width="15" height="15" />
                </button>
              </div>
            )}

            <div className={modalBody}>{children}</div>
            {action && <div className="modal-footer">{action}</div>}
          </div>
        ) : (
          <form onSubmit={formAction} noValidate>
            <div className="modal-content">
              {heading && (
                <div className="modal-header">
                  {heading}
                  <button onClick={onClose}>
                    <SimpleCloseIcon width="15" height="15" />
                  </button>
                </div>
              )}

              <div className={modalBody}>{children}</div>
              {action && <div className="modal-footer">{action}</div>}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const portalElement = document.getElementById('modal-root');

const Modal = ({ onClose, heading, className, children, customSpace, action, form, formAction }) => {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay
          heading={heading}
          className={className}
          onClose={onClose}
          customSpace={customSpace}
          action={action}
          form={form}
          formAction={formAction}
        >
          {children}
        </ModalOverlay>,
        portalElement,
      )}
    </>
  );
};

export default Modal;
