import styled from 'styled-components';
import { Flex, Grid } from 'components/core';
import PostContext from 'context/PostContext';
import { memo, useContext } from 'react';
import { PostCard } from '../PostCard';
import EventPost from 'pages/Dashboard/Feed/Event';
import { RepostQuoteIcon } from 'components/common/Icons';
import BlogBody from './BlogBody';
import VideoPlayer from './VideoPlayer';
import Excerpt from './Excerpt';
import { Link, useLocation } from 'react-router-dom';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { SeeMore } from 'components/common/SeeMore';
import { postHasInsights } from 'components/utils';

const Conatiner = styled.div`
  width: 100%;
  padding: 12px 0px;
  overflow: hidden;
`;

const PostBody = (props) => {
  const { postType } = props;
  const { pathname } = useLocation();
  const { postData, modal } = useContext(PostContext);

  const { media, gif, content, type, repost = null, cover_image, description, title, author } = postData;
  if (type === 3) {
    return (
      <>
        {cover_image && <PostCard.Image src={cover_image} />}
        <BlogBody />
      </>
    );
  }

  let module = 'post';

  if (type === 2) {
    module = 'event';
  }

  let detailLink = `/${module}/detail/${postData.id}`;

  if (type === 1) {
    detailLink = `${pathname}?postId=${postData.id}`;
  }

  return (
    <div style={{ width: "100%", paddingBottom: (postData?.repost) ? "" : postHasInsights(postData) ? "" : "" }} >
      {/* {postType} | {postHasInsights(postData) + ""} */}

      {title && (
        <Link to={detailLink}>
          <PostCard.Content>{title}</PostCard.Content>{' '}
        </Link>
      )}
      {content && postType !== 'default' && (
        <Link to={detailLink}>
          <div className={!postHasInsights(postData) || media?.length ? "pb-3" : ""}>
            <PostCard.Content>
              <SeeMore maxLength={200} text={content} />
              {/* {content} */}
            </PostCard.Content>
          </div>
        </Link>
      )}
      {content && postType === 'default' && (
        <Flex padding={'12px 16px'}>
          <Flex flex="1">
            <MyBridgeAvatar size={40}
              icon={author?.profile_pic}
              className={'cursor-pointer'}
            />
          </Flex>
          <div className="default-post-content">{content}</div>
        </Flex>
      )}
      {description && (
        <Flex style={{ marginLeft: 10 }}>
          <Link to={detailLink}>
            <Excerpt truncateLength={200} text={stripHTML(description)} to={detailLink} />
          </Link>
        </Flex>
      )}
      {repost && (
        <Flex position="relative" padding="1rem 0 1rem 0 ">
          <Flex position="absolute" top="1rem" left="30px" zIndex={3}>
            <RepostQuoteIcon />
          </Flex>
          <EventPost data={repost} repost postType="repost" />
        </Flex>
      )}


      {media?.length > 0 && !modal && (
        <div
        // className={`${(title || content || description) ? "pt-3" : ""}`}
        >
          <Link style={{ paddingTop: "1rem", width: "100%" }} to={detailLink}>
            <Flex stye={{ overflow: 'none' }}>
              {media?.length > 1 ? (
                <Grid>
                  {media?.map((img) => (
                    <Flex width="100%" height="auto" key={img.id}>
                      <PostCard.Image src={img.file} alt={img.id} />
                    </Flex>
                  ))}
                </Grid>
              ) : (
                <>
                  {media?.map((img) => {
                    const isMp4 = img.file?.toLowerCase?.()?.includes(".mp4");
                    if (isMp4) {
                      return <div className='video-player'>
                        <VideoPlayer src={img.file} />
                      </div>
                    }
                    return (
                      <Flex width="100%" key={img.id} >
                        <PostCard.Image src={img.file} alt={img.id} />
                      </Flex>
                    );
                  })}
                </>
              )}
            </Flex>
          </Link>
        </div>
      )}
      {gif && !modal && <div
      //  className={`${(title || content || description) ? "pt-3" : ""}`}
      >
        <PostCard.Image src={gif} alt="gif" />
      </div>
      }
    </div >
  );
};

export default memo(PostBody);

function stripHTML(myString) {
  return myString.replace(/(<([^>]+)>)/gi, '');
}
