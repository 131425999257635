import Modal from 'components/common/Modal/Modal';
import React from 'react';
import styled from 'styled-components';
import EventForm from './EventForm';
import { createEventFunc } from 'api/event';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

const Heading = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3d5a80;
`;

const EventPage = ({ show = false, setShow, onSubmitEvent }) => {
  const { mutate: createEvent, isLoading: createPostLoading } = useMutation(createEventFunc, {
    onSuccess: (response) => {
      console.log(response);
      onSubmitEvent(response);
    },
    onError: (_error) => {
      console.log(_error);
    },
  });

  if (show === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div>
        <Modal
          onClose={() => setShow(false)}
          className="confirmation-modal"
          heading={<Heading>Create a New Event </Heading>}
          action={<></>}
        >
          <EventForm
            onSubmit={createEvent}
            onCancel={() => {
              setShow(false);
            }}
            loading={createPostLoading}
          />
        </Modal>
      </div>
    </React.Fragment>
  );
};

EventPage.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  onSubmitEvent: PropTypes.func,
};

EventPage.defaultProps = {
  show: false,
  setShow: () => {},
  onSubmitEvent: () => {},
};

export default EventPage;
