import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, startCase } from 'lodash';
import { useSelector } from 'react-redux';

import clockIcon from 'assets/icons/clock.svg';
import closeIcon from 'assets/icons/close.svg';
import plusActiveIcon from 'assets/icons/plusActive.svg';

import Modal from 'components/common/Modal/Modal';
import { Text } from 'components/StyledComponents';

import Button from 'components/common/Button';
import RadioButton from 'components/common/RadioButton';

const HoursDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 13px;
  gap: 10px;

  min-width: 130px;

  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  cursor: pointer;
`;

const OperationButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  gap: 10px;
  border: none;

  width: 44px;
  height: 44px;
  background: #e4e6eb;
  border-radius: 8px;
`;

const TimeList = styled.ul`
  position: absolute;
  top: 2rem;
  left: -3rem;
  z-index: 1000;
  background: white;
  border: 1px solid;
  width: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  max-height: 180px;
  width: 8rem;
`;

const TimeItem = styled.li`
  list-style: none;
  width: 100%;
  text-align: center;
  padding: 2px 3px;
  &:hover {
    background-color: rgb(228, 230, 235);
  }
`;

const timings = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

const TimeDropdown = ({ showDropdown, time, setTime }) => {
  if (!showDropdown) return <Text>{time}</Text>;

  return (
    <>
      <Text>{time}</Text>
      <TimeList className="position-absolute">
        {timings.map((time) => {
          return (
            <TimeItem
              onClick={(e) => {
                e.stopPropagation();
                setTime(time);
              }}
            >
              {time}
            </TimeItem>
          );
        })}
      </TimeList>
    </>
  );
};

const HoursModal = (props) => {
  const { onClose, onClickSave } = props;
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);

  const [companyTimingsType, setCompanyTimingsType] = useState();
  const [hours, setHours] = useState({});
  const [showTimeDropdown, setShowTimeDropdown] = useState(null);

  useEffect(() => {
    let existingCompanyTimingsType = companyPageDetail?.company_timings_type;
    if (!isEmpty(companyPageDetail?.company_timings)) {
      existingCompanyTimingsType = 'Open During The Hours Selected';
    } else {
      existingCompanyTimingsType = 'No Hours Available';
    }
    setCompanyTimingsType(existingCompanyTimingsType);
    setHours(companyPageDetail?.company_timings);
  }, [companyPageDetail]);

  const handleChange = (e) => {
    setCompanyTimingsType(e.target.value);
  };

  return (
    <Modal
      onClose={onClose}
      heading={
        <Text color="#3D5A80" fS="18px" fW={600}>
          Select hours
        </Text>
      }
      action={
        <div className="action-btn">
          <Button variant="btn-secondary" className="h-36 me-2" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="h-36"
            onClick={() => {
              onClickSave({
                id: companyPageDetail.id,
                company_timings: JSON.stringify(hours),
                company_timings_type: companyPageDetail?.company_timings_type,
              });
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="d-flex flex-column px-3 mb-2">
        <div className="mb-3">
          <RadioButton
            changed={handleChange}
            name="companyTimingsType"
            label="No Hours Available"
            value="No Hours Available"
            isSelected={companyTimingsType === 'No Hours Available'}
          />
          <Text className="ps-4">Visitors won't see business hours on this Page</Text>
        </div>

        <div className="mb-3">
          <RadioButton
            changed={handleChange}
            name="companyTimingsType"
            label="Always Open"
            value="Always Open"
            isSelected={companyTimingsType === 'Always Open'}
          />
          <Text className="ps-4">eg, parko, beaches, streets</Text>
        </div>

        <div className="mb-3">
          <RadioButton
            changed={handleChange}
            name="companyTimingsType"
            label="Permanently Closed"
            value="Permanently Closed"
            isSelected={companyTimingsType === 'Permanently Closed'}
          />
          <Text className="ps-4">This business has permanently closed</Text>
        </div>

        <div className="mb-3">
          <RadioButton
            changed={handleChange}
            name="companyTimingsType"
            label="Temporarily Closed"
            value="Temporarily Closed"
            isSelected={companyTimingsType === 'Temporarily Closed'}
          />
          <Text className="ps-4">E.g. closed for remodeling</Text>
        </div>

        <div className="mb-3">
          <RadioButton
            changed={handleChange}
            name="companyTimingsType"
            label="Open During The Hours Selected"
            value="Open During The Hours Selected"
            isSelected={companyTimingsType === 'Open During The Hours Selected'}
          />
          <Text className="ps-4">Input your own hours</Text>
        </div>
      </div>

      {companyTimingsType === 'Open During The Hours Selected' &&
        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => {
          return (
            <div className="d-flex align-items-center justify-content-between px-3 mb-2" key={day}>
              <Text width="65px">{startCase(day)}</Text>
              {['opening', 'closing'].map((hourType) => {
                const key = `${day}-${hourType}`;

                return (
                  <div className="position-relative" key={key}>
                    <HoursDiv onClick={() => setShowTimeDropdown(key)}>
                      <img src={clockIcon} alt="clockIcon" />
                      <div className="position-relative">
                        <Text>{startCase(hourType)}</Text>
                        <TimeDropdown
                          showDropdown={showTimeDropdown === key}
                          time={hours?.[day]?.[hourType]}
                          setTime={(time) => {
                            setShowTimeDropdown(null);
                            setHours((hours) => ({ ...hours, [day]: { ...hours?.[day], [hourType]: time } }));
                          }}
                        />
                      </div>
                    </HoursDiv>
                  </div>
                );
              })}
              <div className="d-flex gap-2 align-self-end">
                <OperationButton>
                  <img src={plusActiveIcon} alt="" />
                </OperationButton>
                <OperationButton>
                  <img src={closeIcon} alt="" />
                </OperationButton>
              </div>
            </div>
          );
        })}
    </Modal>
  );
};
export default HoursModal;
