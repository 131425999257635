export const CircleDotIcon = (props) => {
    return <svg width={props.width || '10px'} height={props.height || '10px'} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5.5" r="5" fill={props.color || '#037F29'} />
    </svg>    
}
export const StarIcon = (props) => {
    return <svg {...props} width={props.width || '12px'} height={props.height || '13px'} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.9959 0.5C6.53333 0.520855 6.92237 0.775287 7.16301 1.27164C7.52398 2.03076 7.88494 2.78989 8.24189 3.54901C8.29002 3.64911 8.34617 3.69082 8.45446 3.70334C9.27264 3.82013 10.0868 3.94108 10.901 4.07456C11.4144 4.15798 11.7673 4.46663 11.9277 4.98384C12.0881 5.4927 11.9799 5.95568 11.6149 6.33524C11.0333 6.93587 10.4438 7.53232 9.85419 8.12044C9.75793 8.21637 9.73788 8.30813 9.76194 8.43326C9.90232 9.27581 10.0467 10.1225 10.1791 10.9692C10.2593 11.4948 10.0708 11.9244 9.66168 12.2331C9.25259 12.5417 8.79938 12.5834 8.34216 12.3415C7.81676 12.0662 7.29537 11.7742 6.77398 11.4906C6.56542 11.378 6.35285 11.2737 6.15232 11.1486C6.03601 11.0777 5.95178 11.0902 5.83948 11.1528C5.12558 11.549 4.41168 11.9453 3.68975 12.329C2.93574 12.7336 2.05339 12.3248 1.84082 11.4864C1.76863 11.2028 1.82077 10.9275 1.86489 10.6522C1.98922 9.90146 2.11355 9.14651 2.24189 8.39572C2.26194 8.28728 2.23387 8.2122 2.15767 8.13712C1.56007 7.54067 0.962479 6.94004 0.376918 6.33107C-0.180569 5.7513 -0.104366 4.80448 0.525313 4.32065C0.749912 4.14546 1.01061 4.09124 1.28333 4.04953C2.04537 3.93691 2.8074 3.82013 3.56943 3.70751C3.6697 3.69499 3.71382 3.64911 3.75392 3.56152C4.11489 2.79406 4.47585 2.03076 4.84082 1.26747C5.07745 0.771116 5.4705 0.516684 5.9959 0.5Z" fill={props.color || "#3D5A80"}/>
    </svg>     
}
export const UnsureIcon = (props) => {
    return <svg width="20" height="5" viewBox="0 0 20 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="2.5" x2="20" y2="2.5" stroke="#BDBCBC" strokeWidth="4"/>
    </svg>
       
}
export const LocationIcon = (props) => {
    return <svg {...props} width={props.width || '12px'} height={props.height || '17px'} viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.40879 0.5C6.72611 0.554266 7.04762 0.58766 7.36077 0.658623C9.67392 1.17623 11.5278 3.15901 11.9161 5.49661C12.1457 6.87829 11.8994 8.18902 11.3942 9.46635C10.7971 10.9858 9.91192 12.3382 8.90148 13.6114C8.14574 14.5631 7.32319 15.4564 6.43384 16.2829C6.12069 16.571 5.88687 16.5751 5.57789 16.2829C3.89939 14.7051 2.42131 12.9602 1.30232 10.9357C0.713589 9.86708 0.262649 8.74837 0.0747579 7.53366C-0.455513 4.12327 1.84511 1.13032 4.98081 0.591834C5.18541 0.55844 5.39 0.52922 5.59459 0.5C5.86599 0.5 6.13739 0.5 6.40879 0.5ZM9.33572 6.40243C9.25639 4.54487 7.72403 3.0964 5.91192 3.16736C4.05388 3.23833 2.59668 4.77863 2.66766 6.59444C2.74282 8.44365 4.2877 9.90464 6.09146 9.82951C7.95367 9.75437 9.41087 8.21406 9.33572 6.40243Z" fill={props.color || "#5B5B5B"}/>
    </svg>
    
       
}

