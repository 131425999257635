import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubfileNFolder } from 'store/actions/myDisk.Action';
import { BoxMinus, BoxPlus, FolderClosed, FolderIcon } from '../Icons';
import { getIconByExtension } from 'components/MyDisk/common/iconsMap';
import { trimStr } from 'components/utils';

const TreeMenu = ({ setSelectedItem }) => {

  const { directory } = useSelector((state) => state.myDiskReducer);
  const [tree, setTree] = useState([])
  const [selected, setSelected] = useState()
  const dispatch = useDispatch();

  useEffect(() => {
    if (directory?.length) {
      const dtree = _.cloneDeep(directory)
      setTree([...(dtree ?? [])])
    }
  }, [directory])

  useEffect(() => {
    setSelectedItem?.(selected)
  }, [selected])

  const getItemFromTree = (arr = [], id) => {
    if (!arr || arr.length <= 0) return;
    let found = arr?.find?.(item => item.id === id)
    if (!found) {
      for (let item_ of arr) {
        found = getItemFromTree(item_?.items, id)
        if (found) return found;
      }
    }
    return found;
  }

  const openFolder = (id, index) => {
    const item = getItemFromTree(tree, id)
    setSelected(item)
    dispatch(getSubfileNFolder({ parent: id, type: 'D' })).then((response) => {
      const results = response.payload.results ?? response.payload;
      item.opened = true;
      setTree([...(tree ?? [])])
      if (results?.length) {
        item.items = _.cloneDeep(results);
        setTree([...(tree ?? [])])
      }
    });
  };

  const closeFolder = (id, index) => {
    const item = getItemFromTree(tree, id)
    item.items = []
    item.opened = false;
    setTree([...(tree ?? [])])
    setSelected(null);
  };

  const getNameByFileType = (item) => {
    return (item?.type === "TD") ? item?.team_info?.name : item.name
  }

  const isDir = (item) => ["RD", "TD", "D"].includes(item.type)

  const renderTree = (tree, indent = 0) => {
    return tree?.map?.((item, index) =>
      <div key={index} style={{ paddingLeft: indent > 0 ? `${indent}px` : 0 }}>
        <div className={`accordion-item`} >
          <div
            className={`accordion-title  ${selected?.id === item?.id ? "active" : ""}`}
            onClick={item?.type === "F" ? () => { } : (item.opened ? () => closeFolder(item.id, index) : () => openFolder(item.id, index))}
          >
            {isDir(item) ? <div className="icon">{item.opened ? <BoxMinus /> : <BoxPlus />}</div> : ""}
            <div>
              {isDir(item) ? <>
                {item?.opened ? <FolderIcon width={34} height={28} /> : <FolderClosed />}
              </> : <>
                {getIconByExtension(item, { width: 28, height: 28 })}
              </>}
              <span title={getNameByFileType(item)} className="ps-2">
                {trimStr(getNameByFileType(item), { length: 20 })}
              </span>
            </div>
          </div>
          {item?.items?.length ? renderTree(item.items, indent + 22) : ""}
        </div>
      </div>)
  }

  return renderTree(tree);
};

export default TreeMenu;
