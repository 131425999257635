import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from './Flex';
import { DownIcon, SimpleTickIcon } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import Text from './Text';
import { DropdownContainer } from 'components/common/DropdownMenu';

const SeelctContainer = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b5b;
  position: relative;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 2px 0px;
`;

const MenuHeader = styled.div`
  padding:10px;
`;

const MenuConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  // position: absolute;
  min-width: 100%;
  left: 0px;
  top: 30px;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 8px;
  z-index: 10;
  transition: all 1s ease-in-out;
  width:250px;
`;

const OptionConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 160px;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  min-height:34px;
  color: #5b5b5b;
  cursor: pointer;
  :hover {
    color: #000000;
    background: #e4e6eb;
  }
`;

const Select = ({ value, options, onChange, title, style, subTitle, pos = "bottom-left" }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const ref = React.useRef();
  const handleShow = () => {
    setShowMenu(!showMenu);
  };

  useOnClickOutside(ref, () => setShowMenu(false));

  const handleSelect = (option) => {
    setShowMenu(!showMenu);
    onChange(option);
  };

  const selected = options.find((option) => option.key.toString() === value.toString());
  return (
    <SeelctContainer onClick={handleShow} ref={ref} style={style}>
      <Flex fontSize="14px" justify="space-between" align="center">
        {selected?.icon}
        {selected?.value || 'Choose one'}
        <DownIcon className="ms-1" />
      </Flex>

      <DropdownContainer
        controlled
        pos={pos ?? "bottom-left"}
        targetRef={ref}
        onClose={() => { setShowMenu(false) }}
      >
        <MenuConatiner>
          <MenuHeader>
            <Text.Title level={7} secondary>
              {title}
            </Text.Title>
            {subTitle && <Text.Label level={8} margin="5px 0px 0px 0px">
              {subTitle}
            </Text.Label>}
          </MenuHeader>

          {options.map((option) => (
            <OptionConatiner key={option.key} onClick={() => handleSelect(option)}>
              <Flex flex={1}>{option.icon}</Flex>
              <Flex flex={5}>{option.value}</Flex>
              <Flex flex={1}><SimpleTickIcon color={value.toString() === option.key.toString() ? "#5B5B5B" : "rgba(0,0,0,0.05)"} /></Flex>
            </OptionConatiner>
          ))}
        </MenuConatiner>
      </DropdownContainer>
    </SeelctContainer>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

Select.defaultProps = {
  value: 'Choose one',
  options: [
    {
      key: 1,
      value: 'Option 1',
      icon: <DownIcon />,
    },
    {
      key: 2,
      value: 'Option 2',
      icon: <DownIcon />,
    },
  ],
  title: '',
  onChange: () => { },
  style: {},
};

export default Select;
