import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CommentRow from './CommentRow';

import './comment.scss';
import { Text } from 'components/StyledComponents';
import { fetchComments } from 'store/slices/commentSlice';

const Comments = ({ id, author, modal }) => {
  const { data } = useSelector((state) => state.comment);
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      dispatch(fetchComments({ id }));
    }
  }, [id])

  let commentsText = ``;
  if (data?.count === 1) {
    commentsText = '1 Comment';
  } else if (data?.count > 1) {
    commentsText = `${data?.count} Comments`;
  }

  const comments = modal ? data?.results : data?.results?.slice(0, 1)
  console.log(comments)
  return (
    <>
      {data?.results?.length > 0 && modal && (
        <Text color="#5B5B5B" fS="16px" fW="500" padding={"1rem 1.3rem"}>
          {commentsText}
        </Text>
      )}

      {data?.results?.length > 0 && (
        <div className="comments-container">
          {comments?.map((comment) => {
            return comment.post === id ? (
              <CommentRow id={id} author={author} key={comment.id} comment={comment} />
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

export default Comments;
