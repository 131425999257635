import { createReducer } from '@reduxjs/toolkit';
import {
  getCompanyAllJobs,
  getCompanyActiveJobs,
  getCompanyCloseJobs,
  getCompanyDraftJobs,
  postNewJob,
  getCandidates,
  getJobCheckout,
  getJobSubscriptionDetails,
  setGlobalJobId,
  clearGlobalJobId,
  jobSubscriptionPriceDetails,
  createJobSubscription,
  updateJobSubscription,
  closeJob
} from '../actions/companyJobs.Actions';

const initialState = {
  loading: false,
  overviewLoading: true,
  companyJobList: [],
  activeJobs: [],
  closedJobs: [],
  draftJobs: [],
  jobPostedSuccess: undefined,
  candidateList: [],
  candidateListByJobs: [],
  checkoutLink: null,
  userSubscription: [],
  globalJobId: null,
  jobSubscriptionPlans:null,
  jobSubscriptionProcess:{},
  jobClosed:false
};

const companyJobsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCompanyAllJobs.fulfilled, (state, action) => {
      state.companyJobList = action.payload;
    })
    .addCase(getCompanyActiveJobs.fulfilled, (state, action) => {
      state.activeJobs = action.payload?.results;
      state.overviewLoading = false;
    })
    .addCase(getCompanyActiveJobs.rejected, (state, action) => {
      state.overviewLoading = false;
    })
    .addCase(getCompanyCloseJobs.fulfilled, (state, action) => {
      state.closedJobs = action.payload;
    })
    .addCase(getCompanyDraftJobs.fulfilled, (state, action) => {
      state.draftJobs = action.payload;
    })
    .addCase(getCandidates.fulfilled, (state, action) => {
      state.candidateList = action.payload;
    })
    .addCase(postNewJob.fulfilled, (state, action) => {
      state.jobPostedSuccess = action.payload;
    })
    .addCase(getJobCheckout.fulfilled, (state, action) => {
      state.checkoutLink = action.payload;
    })
    .addCase(getJobSubscriptionDetails.fulfilled, (state, action) => {
      state.userSubscription = action.payload;
    })
    .addCase(jobSubscriptionPriceDetails.fulfilled, (state, action) => {
      state.jobSubscriptionPlans = action.payload;
    })
    .addCase(createJobSubscription.fulfilled, (state, action) => {
      state.jobSubscriptionProcess = action.payload;
    })
    .addCase(updateJobSubscription.fulfilled, (state, action) => {
      state.jobSubscriptionProcess = action.payload;
    })
    .addCase(setGlobalJobId, (state, action) => {
      state.globalJobId = action.payload;
    })
    .addCase(clearGlobalJobId, (state, action) => {
      state.globalJobId = null;
    })
    .addCase(closeJob.fulfilled, (state, action) => {
      state.jobClosed = true;
    })
    .addDefaultCase((state, action) => {});
});

export default companyJobsReducer;
