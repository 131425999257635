import React from 'react';

const Error404IllustrationSvg = () => (

<svg width="276" height="254" viewBox="0 0 276 254" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.6475 72.5073L41.7459 74.7244L44.2002 75.0837L42.4239 76.8126L42.8442 79.2534L40.6475 78.1008L38.4576 79.2534L38.8779 76.8126L37.1016 75.0837L39.5559 74.7244L40.6475 72.5073Z" fill="#EBEBEB"/>
<path d="M260.083 177.075L261.181 179.299L263.635 179.658L261.859 181.38L262.279 183.828L260.083 182.675L257.893 183.828L258.313 181.38L256.537 179.658L258.991 179.299L260.083 177.075Z" fill="#F5F5F5"/>
<path d="M216.623 241.546L217.721 243.763L220.169 244.123L218.392 245.852L218.813 248.292L216.623 247.14L214.433 248.292L214.846 245.852L213.077 244.123L215.524 243.763L216.623 241.546Z" fill="#F5F5F5"/>
<path d="M117.187 33.1156L117.594 33.9427L118.509 34.0783C118.56 34.085 118.608 34.1061 118.648 34.1392C118.688 34.1722 118.717 34.2158 118.733 34.2649C118.748 34.3139 118.749 34.3665 118.736 34.4163C118.723 34.4661 118.696 34.5112 118.658 34.5462L117.98 35.1903L118.136 36.0988C118.144 36.1494 118.138 36.2011 118.119 36.2485C118.099 36.2958 118.067 36.3368 118.026 36.3669C117.984 36.3971 117.935 36.4152 117.884 36.4193C117.833 36.4235 117.782 36.4134 117.736 36.3903L116.923 35.9564L116.109 36.3903C116.064 36.4134 116.012 36.4235 115.961 36.4193C115.91 36.4152 115.861 36.3971 115.82 36.3669C115.779 36.3368 115.746 36.2958 115.727 36.2485C115.707 36.2011 115.701 36.1494 115.709 36.0988L115.858 35.1903L115.18 34.5462C115.142 34.5107 115.115 34.4651 115.103 34.4148C115.09 34.3644 115.092 34.3115 115.108 34.2622C115.125 34.2129 115.155 34.1694 115.195 34.1368C115.236 34.1042 115.285 34.0839 115.336 34.0783L116.245 33.9427L116.652 33.1156C116.668 33.0571 116.703 33.0055 116.751 32.9688C116.8 32.932 116.859 32.9121 116.919 32.9121C116.98 32.9121 117.039 32.932 117.088 32.9688C117.136 33.0055 117.171 33.0571 117.187 33.1156Z" fill="#EBEBEB"/>
<path d="M5.20865 230.99L5.61545 231.81L6.52397 231.946C6.57555 231.953 6.62409 231.975 6.66408 232.008C6.70406 232.041 6.73388 232.085 6.75013 232.135C6.76639 232.184 6.76842 232.237 6.756 232.288C6.74359 232.338 6.71722 232.384 6.67991 232.421L6.00191 233.058L6.15785 233.967C6.16586 234.017 6.15975 234.069 6.14019 234.116C6.12063 234.163 6.08839 234.204 6.04702 234.235C6.00565 234.265 5.95675 234.283 5.90572 234.287C5.85469 234.291 5.80351 234.281 5.75783 234.258L4.94423 233.831L4.13063 234.258C4.08495 234.281 4.03377 234.291 3.98274 234.287C3.93171 234.283 3.88282 234.265 3.84144 234.235C3.80007 234.204 3.76783 234.163 3.74827 234.116C3.72871 234.069 3.7226 234.017 3.73061 233.967L3.88655 233.058L3.20855 232.421C3.17136 232.385 3.1449 232.339 3.13214 232.289C3.11938 232.239 3.12083 232.187 3.13632 232.137C3.15182 232.088 3.18074 232.044 3.21984 232.01C3.25895 231.976 3.30669 231.954 3.35771 231.946L4.26623 231.81L4.67981 230.99C4.69793 230.934 4.73328 230.886 4.78078 230.851C4.82828 230.816 4.8855 230.798 4.94423 230.798C5.00296 230.798 5.06018 230.816 5.10768 230.851C5.15519 230.886 5.19053 230.934 5.20865 230.99Z" fill="#F5F5F5"/>
<path d="M23.4943 24.4034L23.9011 25.2305L24.8164 25.3594C24.8674 25.3675 24.9152 25.3897 24.9543 25.4235C24.9934 25.4572 25.0223 25.5012 25.0378 25.5505C25.0533 25.5998 25.0547 25.6525 25.042 25.7025C25.0292 25.7526 25.0028 25.7981 24.9656 25.834L24.2876 26.512L24.4435 27.4137C24.4543 27.4649 24.4501 27.5181 24.4314 27.567C24.4127 27.6158 24.3802 27.6582 24.3379 27.689C24.2956 27.7198 24.2453 27.7378 24.1931 27.7407C24.1409 27.7435 24.0889 27.7312 24.0435 27.7052L23.2299 27.2781L22.4163 27.7052C22.3709 27.7312 22.3189 27.7435 22.2667 27.7407C22.2144 27.7378 22.1641 27.7198 22.1219 27.689C22.0796 27.6582 22.0471 27.6158 22.0284 27.567C22.0096 27.5181 22.0054 27.4649 22.0163 27.4137L22.1654 26.512L21.4874 25.834C21.4501 25.7977 21.4238 25.7516 21.4113 25.701C21.3989 25.6505 21.401 25.5974 21.4172 25.548C21.4335 25.4985 21.4633 25.4546 21.5033 25.4213C21.5432 25.3879 21.5918 25.3665 21.6434 25.3594L22.5519 25.2305L22.9858 24.4034C23.0076 24.3541 23.0432 24.3122 23.0884 24.2828C23.1335 24.2534 23.1862 24.2378 23.2401 24.2378C23.2939 24.2378 23.3466 24.2534 23.3918 24.2828C23.4369 24.3122 23.4725 24.3541 23.4943 24.4034Z" fill="#EBEBEB"/>
<path d="M22.3957 187.815C22.3971 187.994 22.3453 188.169 22.2471 188.318C22.1488 188.467 22.0084 188.584 21.8438 188.653C21.6792 188.722 21.4976 188.741 21.3223 188.707C21.1469 188.673 20.9856 188.588 20.8588 188.462C20.732 188.336 20.6455 188.175 20.6101 188C20.5748 187.825 20.5922 187.643 20.6603 187.478C20.7283 187.313 20.8439 187.172 20.9923 187.072C21.1408 186.973 21.3154 186.92 21.494 186.92C21.732 186.92 21.9603 187.014 22.1292 187.182C22.2982 187.349 22.3939 187.577 22.3957 187.815Z" fill="#EBEBEB"/>
<path d="M150.904 74.9755C150.905 75.1542 150.854 75.3292 150.755 75.4784C150.657 75.6275 150.517 75.7442 150.352 75.8135C150.187 75.8828 150.006 75.9016 149.831 75.8676C149.655 75.8335 149.494 75.7482 149.367 75.6224C149.24 75.4965 149.154 75.3359 149.118 75.1608C149.083 74.9857 149.101 74.804 149.169 74.6388C149.237 74.4737 149.352 74.3324 149.501 74.233C149.649 74.1337 149.824 74.0806 150.002 74.0806C150.12 74.0797 150.237 74.1022 150.347 74.1468C150.456 74.1913 150.556 74.2571 150.64 74.3403C150.723 74.4235 150.79 74.5225 150.835 74.6315C150.881 74.7405 150.904 74.8574 150.904 74.9755Z" fill="#EBEBEB"/>
<path d="M104.149 25.1493C104.149 25.3276 104.096 25.502 103.997 25.6503C103.898 25.7986 103.757 25.9141 103.593 25.9824C103.428 26.0506 103.246 26.0685 103.072 26.0337C102.897 25.9989 102.736 25.913 102.61 25.7869C102.484 25.6608 102.398 25.5001 102.363 25.3252C102.328 25.1503 102.346 24.969 102.414 24.8042C102.483 24.6394 102.598 24.4986 102.746 24.3995C102.895 24.3004 103.069 24.2476 103.247 24.2476C103.486 24.2493 103.714 24.3449 103.883 24.5136C104.052 24.6824 104.147 24.9107 104.149 25.1493Z" fill="#F5F5F5"/>
<path d="M105.577 244.919C108.256 244.286 109.915 241.603 109.282 238.924C108.65 236.245 105.966 234.586 103.288 235.219C100.609 235.851 98.95 238.535 99.5822 241.213C100.214 243.892 102.898 245.551 105.577 244.919Z" fill="#F0F0F0"/>
<path d="M102.719 237.153C101.698 237.151 100.703 237.474 99.878 238.075C99.4566 239.042 99.3532 240.117 99.5826 241.146C99.812 242.175 100.362 243.105 101.154 243.801C101.946 244.497 102.939 244.924 103.989 245.02C105.039 245.115 106.092 244.875 106.997 244.333C107.397 243.592 107.599 242.76 107.581 241.918C107.563 241.077 107.327 240.254 106.896 239.531C106.465 238.808 105.853 238.209 105.121 237.793C104.388 237.378 103.561 237.159 102.719 237.16V237.153Z" fill="#E6E6E6"/>
<path d="M64.1269 153.454H22.8706V134.829L64.1269 85.7827H83.8703V135.887H94.1013V153.454H83.8703V168.695H64.1269V153.454ZM64.1269 135.887V110.231L42.3224 135.887H64.1269Z" fill="#3D5A80"/>
<path d="M101.159 127.602C101.159 112.103 103.948 101.255 109.526 95.0576C115.103 88.8607 123.603 85.7645 135.025 85.769C140.508 85.769 145.012 86.447 148.538 87.803C151.741 88.9571 154.673 90.7545 157.155 93.0847C159.247 95.082 161.019 97.3894 162.41 99.9257C163.786 102.574 164.822 105.385 165.495 108.292C167.028 114.503 167.793 120.879 167.773 127.276C167.773 142.066 165.271 152.889 160.267 159.746C155.264 166.602 146.646 170.033 134.415 170.038C127.554 170.038 122.01 168.944 117.784 166.756C113.524 164.533 109.935 161.211 107.39 157.135C105.424 154.134 103.894 150.028 102.8 144.816C101.664 139.149 101.115 133.381 101.159 127.602ZM123.628 127.656C123.628 138.052 124.543 145.142 126.374 148.925C128.205 152.708 130.865 154.609 134.354 154.627C135.461 154.639 136.56 154.432 137.587 154.016C138.613 153.601 139.547 152.985 140.334 152.206C142.025 150.593 143.268 148.043 144.063 144.558C144.859 141.074 145.256 135.65 145.256 128.286C145.256 117.466 144.339 110.191 142.504 106.462C140.669 102.733 137.916 100.87 134.246 100.875C130.503 100.875 127.791 102.776 126.11 106.577C124.428 110.378 123.601 117.405 123.628 127.656Z" fill="#3D5A80"/>
<path d="M216.039 153.454H174.783V134.829L216.039 85.7827H235.783V135.887H246.048V153.454H235.783V168.695H216.039V153.454ZM216.039 135.887V110.231L194.242 135.887H216.039Z" fill="#3D5A80"/>
<g opacity="0.3">
<path d="M100.278 57.1644C100.278 57.4154 100.203 57.6608 100.064 57.8694C99.9241 58.0781 99.7257 58.2406 99.4937 58.3363C99.2616 58.4321 99.0063 58.4568 98.7602 58.4073C98.5141 58.3579 98.2882 58.2364 98.1111 58.0585C97.9341 57.8805 97.8139 57.6539 97.7657 57.4075C97.7176 57.1611 97.7436 56.906 97.8407 56.6745C97.9377 56.4429 98.1012 56.2454 98.3106 56.1069C98.52 55.9684 98.7658 55.8952 99.0168 55.8965C99.183 55.8965 99.3475 55.9293 99.501 55.9931C99.6544 56.0569 99.7938 56.1504 99.911 56.2683C100.028 56.3861 100.121 56.5259 100.184 56.6797C100.247 56.8335 100.279 56.9982 100.278 57.1644Z" fill="#3D5A80"/>
<path d="M13.4736 105.865C13.9678 105.865 14.3685 105.464 14.3685 104.97C14.3685 104.475 13.9678 104.075 13.4736 104.075C12.9793 104.075 12.5786 104.475 12.5786 104.97C12.5786 105.464 12.9793 105.865 13.4736 105.865Z" fill="#3D5A80"/>
<path d="M161.332 236.563C162.039 236.563 162.613 235.989 162.613 235.281C162.613 234.574 162.039 234 161.332 234C160.624 234 160.05 234.574 160.05 235.281C160.05 235.989 160.624 236.563 161.332 236.563Z" fill="#3D5A80"/>
<path d="M192.147 184.425C192.641 184.425 193.042 184.024 193.042 183.53C193.042 183.035 192.641 182.635 192.147 182.635C191.653 182.635 191.252 183.035 191.252 183.53C191.252 184.024 191.653 184.425 192.147 184.425Z" fill="#3D5A80"/>
<path d="M251.587 23.2641C251.587 23.4411 251.535 23.6141 251.436 23.7613C251.338 23.9085 251.198 24.0232 251.035 24.0909C250.871 24.1587 250.691 24.1764 250.518 24.1419C250.344 24.1073 250.185 24.0221 250.059 23.8969C249.934 23.7718 249.849 23.6123 249.815 23.4387C249.78 23.2651 249.798 23.0851 249.865 22.9216C249.933 22.7581 250.048 22.6183 250.195 22.52C250.342 22.4216 250.515 22.3691 250.692 22.3691C250.93 22.3691 251.157 22.4634 251.325 22.6313C251.493 22.7991 251.587 23.0267 251.587 23.2641Z" fill="#3D5A80"/>
<path d="M81.1244 5.21576C81.1244 5.39277 81.0719 5.5658 80.9736 5.71297C80.8752 5.86015 80.7354 5.97486 80.5719 6.0426C80.4084 6.11033 80.2284 6.12806 80.0548 6.09353C79.8812 6.05899 79.7218 5.97375 79.5966 5.84859C79.4714 5.72343 79.3862 5.56397 79.3517 5.39036C79.3171 5.21676 79.3349 5.03681 79.4026 4.87327C79.4703 4.70974 79.585 4.56997 79.7322 4.47163C79.8794 4.37329 80.0524 4.3208 80.2294 4.3208C80.4662 4.32257 80.6929 4.41743 80.8603 4.58489C81.0278 4.75234 81.1226 4.97895 81.1244 5.21576Z" fill="#3D5A80"/>
<path d="M152.281 52.1742C152.775 52.1742 153.176 51.7735 153.176 51.2792C153.176 50.785 152.775 50.3843 152.281 50.3843C151.786 50.3843 151.386 50.785 151.386 51.2792C151.386 51.7735 151.786 52.1742 152.281 52.1742Z" fill="#3D5A80"/>
</g>
<path d="M59.7539 52.1807C67.9581 52.1807 74.6089 45.5299 74.6089 37.3257C74.6089 29.1215 67.9581 22.4707 59.7539 22.4707C51.5497 22.4707 44.8989 29.1215 44.8989 37.3257C44.8989 45.5299 51.5497 52.1807 59.7539 52.1807Z" fill="#3D5A80"/>
<path opacity="0.7" d="M59.7539 52.1807C67.9581 52.1807 74.6089 45.5299 74.6089 37.3257C74.6089 29.1215 67.9581 22.4707 59.7539 22.4707C51.5497 22.4707 44.8989 29.1215 44.8989 37.3257C44.8989 45.5299 51.5497 52.1807 59.7539 52.1807Z" fill="white"/>
<path opacity="0.2" d="M54.635 26.1187C52.612 26.1125 50.6091 26.5209 48.75 27.3188C46.4651 29.83 45.109 33.0479 44.9072 36.437C44.7053 39.8262 45.67 43.1822 47.6407 45.9469C49.6114 48.7116 52.4693 50.7181 55.7389 51.6328C59.0085 52.5474 62.4927 52.315 65.6119 50.9742C67.5457 48.843 68.8192 46.1961 69.2777 43.3551C69.7362 40.514 69.36 37.6009 68.1948 34.9696C67.0295 32.3382 65.1253 30.1018 62.7134 28.5318C60.3016 26.9618 57.4858 26.1259 54.6079 26.1255L54.635 26.1187Z" fill="#3D5A80"/>
<path opacity="0.2" d="M53.1568 30.044C53.1568 30.3122 53.0773 30.5743 52.9283 30.7973C52.7793 31.0203 52.5675 31.1941 52.3197 31.2968C52.072 31.3994 51.7993 31.4263 51.5363 31.3739C51.2732 31.3216 51.0316 31.1925 50.842 31.0028C50.6523 30.8132 50.5232 30.5716 50.4709 30.3085C50.4186 30.0455 50.4454 29.7728 50.548 29.5251C50.6507 29.2773 50.8245 29.0655 51.0475 28.9165C51.2705 28.7675 51.5326 28.688 51.8008 28.688C52.1605 28.688 52.5054 28.8309 52.7597 29.0852C53.014 29.3394 53.1568 29.6844 53.1568 30.044Z" fill="#3D5A80"/>
<path opacity="0.2" d="M69.1305 28.688C69.1305 28.9562 69.0509 29.2184 68.9019 29.4414C68.7529 29.6644 68.5411 29.8382 68.2934 29.9408C68.0456 30.0434 67.7729 30.0703 67.5099 30.018C67.2469 29.9657 67.0053 29.8365 66.8156 29.6469C66.626 29.4572 66.4968 29.2156 66.4445 28.9526C66.3922 28.6895 66.419 28.4169 66.5217 28.1691C66.6243 27.9213 66.7981 27.7096 67.0211 27.5606C67.2441 27.4116 67.5063 27.332 67.7745 27.332C68.1341 27.332 68.479 27.4749 68.7333 27.7292C68.9876 27.9835 69.1305 28.3284 69.1305 28.688Z" fill="#3D5A80"/>
<path opacity="0.2" d="M66.4184 38.3698C66.4184 38.8097 66.288 39.2396 66.0436 39.6053C65.7992 39.971 65.4519 40.2561 65.0456 40.4244C64.6392 40.5927 64.1921 40.6367 63.7607 40.5509C63.3293 40.4651 62.9331 40.2533 62.6221 39.9423C62.311 39.6313 62.0992 39.2351 62.0134 38.8037C61.9276 38.3723 61.9717 37.9252 62.14 37.5188C62.3083 37.1125 62.5933 36.7651 62.959 36.5208C63.3248 36.2764 63.7547 36.146 64.1945 36.146C64.7843 36.146 65.35 36.3803 65.767 36.7973C66.1841 37.2144 66.4184 37.78 66.4184 38.3698Z" fill="#3D5A80"/>
<path opacity="0.2" d="M59.3538 44.7094C59.3538 45.2967 59.1797 45.8709 58.8534 46.3592C58.5271 46.8476 58.0633 47.2282 57.5206 47.453C56.978 47.6777 56.3809 47.7365 55.8048 47.622C55.2288 47.5074 54.6997 47.2245 54.2843 46.8092C53.869 46.3939 53.5862 45.8648 53.4716 45.2887C53.357 44.7127 53.4158 44.1156 53.6406 43.573C53.8654 43.0303 54.246 42.5665 54.7343 42.2402C55.2227 41.9139 55.7969 41.7397 56.3842 41.7397C57.1718 41.7397 57.9271 42.0526 58.484 42.6095C59.041 43.1664 59.3538 43.9218 59.3538 44.7094Z" fill="#3D5A80"/>
<path d="M235.81 216.512C237.464 209.504 233.124 202.482 226.116 200.828C219.108 199.174 212.086 203.514 210.431 210.522C208.777 217.53 213.118 224.552 220.126 226.206C227.134 227.86 234.156 223.52 235.81 216.512Z" fill="#3D5A80"/>
<path opacity="0.3" d="M235.81 216.512C237.464 209.504 233.124 202.482 226.116 200.828C219.108 199.174 212.086 203.514 210.431 210.522C208.777 217.53 213.118 224.552 220.126 226.206C227.134 227.86 234.156 223.52 235.81 216.512Z" fill="white"/>
<path opacity="0.4" d="M231.356 203.422C228.465 202.55 225.362 202.708 222.575 203.87C219.789 205.032 217.492 207.125 216.078 209.792C214.663 212.459 214.219 215.535 214.82 218.493C215.422 221.452 217.032 224.11 219.375 226.013C220.591 226.382 221.854 226.57 223.125 226.569C225.816 226.568 228.442 225.735 230.641 224.182C232.84 222.63 234.504 220.435 235.406 217.899C236.308 215.363 236.403 212.61 235.678 210.018C234.952 207.426 233.443 205.122 231.356 203.422Z" fill="#3D5A80"/>
<path d="M210.677 217.43C196.758 223.979 207.138 229.031 226.752 221.979C244.916 215.45 251.838 207.219 235.566 209.775C236.441 213.199 213.694 222.061 210.677 217.43Z" fill="#3D5A80"/>
<path opacity="0.2" d="M231.2 85.7827C212.969 98.102 189.367 114.374 182.852 153.454H181.462C187.801 114.808 210.541 98.1494 228.78 85.7827H231.2Z" fill="black"/>
<path d="M136.89 208.121C125.364 208.121 113.967 202.921 108.414 194.432C104.99 189.184 101.01 178.194 112.862 163.529L113.913 164.383C105.532 174.757 103.987 185.164 109.553 193.693C116.333 204.067 132.327 209.287 145.982 205.572C159.969 201.768 168.458 189.605 169.278 172.201C172.078 112.665 203.707 94.3254 226.8 80.9349C241.153 72.6159 251.519 66.6088 249.241 53.9234C248.923 52.1674 248.224 50.9538 247.092 50.208C244.143 48.2621 238.38 49.6113 231.708 51.1639C218.616 54.2149 200.69 58.3914 190.011 40.2278L191.184 39.5498C201.354 56.8591 218.013 52.9742 231.403 49.8554C238.38 48.235 244.4 46.8315 247.838 49.0893C249.296 50.0453 250.191 51.5572 250.55 53.6997C252.997 67.3139 241.736 73.8498 227.464 82.1214C204.636 95.356 173.38 113.479 170.614 172.295C169.759 190.33 160.905 202.948 146.314 206.914C143.24 207.733 140.071 208.139 136.89 208.121Z" fill="#3D5A80"/>
<path opacity="0.2" d="M136.89 208.121C125.364 208.121 113.967 202.921 108.414 194.432C104.99 189.184 101.01 178.194 112.862 163.529L113.913 164.383C105.532 174.757 103.987 185.164 109.553 193.693C116.333 204.067 132.327 209.287 145.982 205.572C159.969 201.768 168.458 189.605 169.278 172.201C172.078 112.665 203.707 94.3254 226.8 80.9349C241.153 72.6159 251.519 66.6088 249.241 53.9234C248.923 52.1674 248.224 50.9538 247.092 50.208C244.143 48.2621 238.38 49.6113 231.708 51.1639C218.616 54.2149 200.69 58.3914 190.011 40.2278L191.184 39.5498C201.354 56.8591 218.013 52.9742 231.403 49.8554C238.38 48.235 244.4 46.8315 247.838 49.0893C249.296 50.0453 250.191 51.5572 250.55 53.6997C252.997 67.3139 241.736 73.8498 227.464 82.1214C204.636 95.356 173.38 113.479 170.614 172.295C169.759 190.33 160.905 202.948 146.314 206.914C143.24 207.733 140.071 208.139 136.89 208.121Z" fill="white"/>
<path d="M176.051 24.1997C179.175 24.2177 182.279 24.7045 185.259 25.6438C185.259 25.6438 192.717 38.3699 193.598 41.2853C193.286 44.16 188.438 48.8721 188.438 48.8721L176.051 24.1997Z" fill="#3D5A80"/>
<path opacity="0.3" d="M176.051 24.1997C179.175 24.2177 182.279 24.7045 185.259 25.6438C185.259 25.6438 192.717 38.3699 193.598 41.2853C193.286 44.16 188.438 48.8721 188.438 48.8721L176.051 24.1997Z" fill="white"/>
<path d="M198.141 85.9859C195.269 82.4741 192.649 78.7629 190.303 74.8802C189.733 73.8994 189.193 72.9005 188.682 71.8835C188.391 71.3072 188.113 70.7309 187.842 70.1478C187.486 69.5804 187.21 68.9667 187.021 68.324C186.208 59.7337 189.15 53.408 186.343 46.5127L175.17 50.879C175.17 50.879 176.119 63.1644 178.289 70.541C179.645 75.104 182.682 79.0499 185.618 82.7043C186.533 83.8434 187.415 85.0163 188.33 86.1621C189.245 87.308 190.167 88.2368 191.042 89.3148C192.364 90.9488 192.798 92.515 191.801 94.4405L191.632 94.7456C191.347 95.2473 192.676 95.8169 193.517 94.7456C194.873 92.9964 194.683 93.1048 195.862 91.6132C196.581 90.7115 197.402 89.7284 198.113 88.9012C198.457 88.4945 198.647 87.9807 198.652 87.4484C198.657 86.9162 198.476 86.3989 198.141 85.9859Z" fill="#3D5A80"/>
<path opacity="0.7" d="M198.141 85.9859C195.269 82.4741 192.649 78.7629 190.303 74.8802C189.733 73.8994 189.193 72.9005 188.682 71.8835C188.391 71.3072 188.113 70.7309 187.842 70.1478C187.486 69.5804 187.21 68.9667 187.021 68.324C186.208 59.7337 189.15 53.408 186.343 46.5127L175.17 50.879C175.17 50.879 176.119 63.1644 178.289 70.541C179.645 75.104 182.682 79.0499 185.618 82.7043C186.533 83.8434 187.415 85.0163 188.33 86.1621C189.245 87.308 190.167 88.2368 191.042 89.3148C192.364 90.9488 192.798 92.515 191.801 94.4405L191.632 94.7456C191.347 95.2473 192.676 95.8169 193.517 94.7456C194.873 92.9964 194.683 93.1048 195.862 91.6132C196.581 90.7115 197.402 89.7284 198.113 88.9012C198.457 88.4945 198.647 87.9807 198.652 87.4484C198.657 86.9162 198.476 86.3989 198.141 85.9859Z" fill="white"/>
<path opacity="0.3" d="M195.408 82.4536C193.635 84.3542 191.667 86.0631 189.537 87.5522C189.828 87.8776 190.113 88.2302 190.404 88.5421C192.537 87.101 194.475 85.3895 196.167 83.4503L195.408 82.4536Z" fill="#3D5A80"/>
<path opacity="0.3" d="M198.14 85.986L198.059 85.8911C197.537 86.4896 197.234 87.2472 197.198 88.0404C197.154 88.5845 197.243 89.1311 197.455 89.6337L198.1 88.8675C198.436 88.465 198.624 87.9588 198.632 87.434C198.639 86.9093 198.465 86.398 198.14 85.986Z" fill="#3D5A80"/>
<path d="M173.393 32.336C172.182 34.1498 170.729 35.7898 169.074 37.2108C168.157 38.0016 167.151 38.6841 166.078 39.2448C165.514 39.5339 164.929 39.7809 164.328 39.9839L163.867 40.133L163.718 40.1737L163.4 40.2618C163.203 40.3084 163.004 40.3401 162.803 40.3568C162.231 40.4005 161.656 40.3478 161.101 40.2008C160.402 40.0023 159.732 39.7128 159.108 39.3398C158.606 39.0463 158.119 38.7272 157.65 38.3838C156.802 37.7533 155.992 37.0741 155.223 36.3498C153.74 34.9446 152.379 33.4159 151.155 31.7801C150.919 31.4614 150.802 31.0704 150.824 30.6747C150.846 30.2789 151.005 29.9031 151.274 29.6122C151.543 29.3213 151.906 29.1335 152.298 29.0812C152.691 29.0289 153.09 29.1154 153.426 29.3257H153.48C155.081 30.3427 156.694 31.4139 158.267 32.3699C159.067 32.8377 159.84 33.3123 160.606 33.6785C160.956 33.8616 161.316 34.0246 161.684 34.1666C161.914 34.2679 162.159 34.3276 162.41 34.3429C162.45 34.3429 162.41 34.2954 162.159 34.3022C162.08 34.2975 162.001 34.2975 161.922 34.3022L161.772 34.3361L161.996 34.2209C162.297 34.0588 162.589 33.88 162.871 33.6852C163.477 33.2575 164.045 32.7765 164.566 32.2479C165.75 31.0066 166.86 29.6957 167.888 28.3223C168.451 27.6919 169.23 27.2948 170.071 27.209C170.912 27.1231 171.755 27.3546 172.434 27.858C173.113 28.3615 173.58 29.1006 173.742 29.9303C173.905 30.76 173.751 31.6204 173.312 32.3428L173.393 32.336Z" fill="#3D5A80"/>
<path opacity="0.7" d="M173.393 32.336C172.182 34.1498 170.729 35.7898 169.074 37.2108C168.157 38.0016 167.151 38.6841 166.078 39.2448C165.514 39.5339 164.929 39.7809 164.328 39.9839L163.867 40.133L163.718 40.1737L163.4 40.2618C163.203 40.3084 163.004 40.3401 162.803 40.3568C162.231 40.4005 161.656 40.3478 161.101 40.2008C160.402 40.0023 159.732 39.7128 159.108 39.3398C158.606 39.0463 158.119 38.7272 157.65 38.3838C156.802 37.7533 155.992 37.0741 155.223 36.3498C153.74 34.9446 152.379 33.4159 151.155 31.7801C150.919 31.4614 150.802 31.0704 150.824 30.6747C150.846 30.2789 151.005 29.9031 151.274 29.6122C151.543 29.3213 151.906 29.1335 152.298 29.0812C152.691 29.0289 153.09 29.1154 153.426 29.3257H153.48C155.081 30.3427 156.694 31.4139 158.267 32.3699C159.067 32.8377 159.84 33.3123 160.606 33.6785C160.956 33.8616 161.316 34.0246 161.684 34.1666C161.914 34.2679 162.159 34.3276 162.41 34.3429C162.45 34.3429 162.41 34.2954 162.159 34.3022C162.08 34.2975 162.001 34.2975 161.922 34.3022L161.772 34.3361L161.996 34.2209C162.297 34.0588 162.589 33.88 162.871 33.6852C163.477 33.2575 164.045 32.7765 164.566 32.2479C165.75 31.0066 166.86 29.6957 167.888 28.3223C168.451 27.6919 169.23 27.2948 170.071 27.209C170.912 27.1231 171.755 27.3546 172.434 27.858C173.113 28.3615 173.58 29.1006 173.742 29.9303C173.905 30.76 173.751 31.6204 173.312 32.3428L173.393 32.336Z" fill="white"/>
<path d="M148.613 27.8743L149.406 29.2303C149.406 29.2303 150.009 31.0067 151.223 31.3321L154.518 30.2677L154.349 29.9897C153.928 29.3524 153.976 28.1117 154.118 27.0811C154.26 26.0505 153.732 26.0166 153.338 26.2743C153.06 26.5948 152.864 26.979 152.769 27.393C152.607 27.1691 152.428 26.9582 152.233 26.7624L151.23 25.759C151.019 25.5532 150.739 25.4347 150.445 25.4271C150.15 25.4196 149.864 25.5236 149.643 25.7183L148.83 26.4438C148.637 26.6223 148.51 26.8611 148.471 27.1211C148.431 27.381 148.481 27.6466 148.613 27.8743Z" fill="#3D5A80"/>
<path opacity="0.7" d="M148.613 27.8743L149.406 29.2303C149.406 29.2303 150.009 31.0067 151.223 31.3321L154.518 30.2677L154.349 29.9897C153.928 29.3524 153.976 28.1117 154.118 27.0811C154.26 26.0505 153.732 26.0166 153.338 26.2743C153.06 26.5948 152.864 26.979 152.769 27.393C152.607 27.1691 152.428 26.9582 152.233 26.7624L151.23 25.759C151.019 25.5532 150.739 25.4347 150.445 25.4271C150.15 25.4196 149.864 25.5236 149.643 25.7183L148.83 26.4438C148.637 26.6223 148.51 26.8611 148.471 27.1211C148.431 27.381 148.481 27.6466 148.613 27.8743Z" fill="white"/>
<path d="M179.38 22.9928C175.725 23.9615 172.224 25.4382 168.98 27.3794C168.473 27.6963 168.076 28.1602 167.841 28.7092C167.606 29.2581 167.544 29.8658 167.664 30.4508C168.973 36.8511 171.949 45.421 175.163 50.8789L190.153 44.6752C190.255 42.031 186.614 33.4747 182.906 25.2031C182.241 23.7182 180.96 22.6131 179.38 22.9928Z" fill="#3D5A80"/>
<path opacity="0.8" d="M179.38 22.9928C175.725 23.9615 172.224 25.4382 168.98 27.3794C168.473 27.6963 168.076 28.1602 167.841 28.7092C167.606 29.2581 167.544 29.8658 167.664 30.4508C168.973 36.8511 171.949 45.421 175.163 50.8789L190.153 44.6752C190.255 42.031 186.614 33.4747 182.906 25.2031C182.241 23.7182 180.96 22.6131 179.38 22.9928Z" fill="white"/>
<path opacity="0.3" d="M185.231 30.4444L182.255 29.4478C182.933 31.1902 185.326 33.3937 187.001 34.6887C186.458 33.3327 185.855 31.9089 185.231 30.4444Z" fill="#3D5A80"/>
<path d="M178.397 16.2808C177.156 13.9213 174.478 12.7348 171.264 12.9992C168.552 13.2298 166.152 15.996 166.437 17.4876C166.722 18.9792 169 19.6165 169.434 20.1318L167.556 21.4878C167.333 21.6484 167.146 21.8523 167.004 22.0873C166.862 22.3224 166.77 22.5836 166.732 22.8553C166.694 23.1271 166.711 23.4037 166.783 23.6685C166.855 23.9334 166.979 24.181 167.149 24.3964C167.942 25.3999 168.986 26.4304 169.59 27.1898C174.783 27.0542 178.628 25.0744 180.017 23.1692C179.495 20.7556 179.604 18.6266 178.397 16.2808Z" fill="#3D5A80"/>
<path opacity="0.8" d="M178.397 16.2808C177.156 13.9213 174.478 12.7348 171.264 12.9992C168.552 13.2298 166.152 15.996 166.437 17.4876C166.722 18.9792 169 19.6165 169.434 20.1318L167.556 21.4878C167.333 21.6484 167.146 21.8523 167.004 22.0873C166.862 22.3224 166.77 22.5836 166.732 22.8553C166.694 23.1271 166.711 23.4037 166.783 23.6685C166.855 23.9334 166.979 24.181 167.149 24.3964C167.942 25.3999 168.986 26.4304 169.59 27.1898C174.783 27.0542 178.628 25.0744 180.017 23.1692C179.495 20.7556 179.604 18.6266 178.397 16.2808Z" fill="white"/>
<path d="M175.848 17.7454C176.217 18.691 176.297 19.7246 176.078 20.7157C175.859 21.7067 175.352 22.6107 174.62 23.3133C173.887 24.016 172.963 24.4858 171.964 24.6633C170.965 24.8408 169.935 24.7181 169.006 24.3107C168.076 23.9032 167.288 23.2294 166.742 22.3743C166.195 21.5192 165.914 20.5212 165.935 19.5065C165.955 18.4918 166.276 17.506 166.857 16.6736C167.437 15.8412 168.252 15.1997 169.197 14.83C170.465 14.3358 171.878 14.3652 173.125 14.9118C174.372 15.4584 175.352 16.4775 175.848 17.7454Z" fill="#263238"/>
<path d="M219.871 78.8465C219.796 76.6159 219.694 76.8125 219.633 74.9344C219.593 73.7818 219.586 72.5004 219.579 71.3953C219.577 70.8705 219.393 70.3625 219.06 69.9573C218.726 69.5521 218.263 69.2745 217.748 69.1714C216.853 68.9884 215.952 68.8189 215.036 68.629C213.863 68.3782 212.704 68.107 211.558 67.7748C210.663 67.5171 209.782 67.2324 208.907 66.9137C208.033 66.595 207.05 66.2357 206.141 65.8153C205.07 65.3611 204.012 64.8729 202.968 64.3644C201.795 63.8085 200.636 63.2254 199.476 62.6355C195.015 55.2521 194.92 50.3841 190.133 44.6753L179.855 49.3264C179.855 49.3264 187.408 62.7237 192.547 68.4053C195.51 71.6733 200.154 73.1513 204.297 74.2225C207.287 74.9887 210.318 75.5785 213.348 76.1413C214.528 76.3582 215.809 76.4396 216.826 77.1447C217.579 77.7216 218.095 78.5534 218.277 79.4838C218.322 79.6736 218.363 79.8635 218.399 80.0533C218.528 80.6229 219.918 80.1889 219.871 78.8465Z" fill="#3D5A80"/>
<path opacity="0.8" d="M219.871 78.8465C219.796 76.6159 219.694 76.8125 219.633 74.9344C219.593 73.7818 219.586 72.5004 219.579 71.3953C219.577 70.8705 219.393 70.3625 219.06 69.9573C218.726 69.5521 218.263 69.2745 217.748 69.1714C216.853 68.9884 215.952 68.8189 215.036 68.629C213.863 68.3782 212.704 68.107 211.558 67.7748C210.663 67.5171 209.782 67.2324 208.907 66.9137C208.033 66.595 207.05 66.2357 206.141 65.8153C205.07 65.3611 204.012 64.8729 202.968 64.3644C201.795 63.8085 200.636 63.2254 199.476 62.6355C195.015 55.2521 194.92 50.3841 190.133 44.6753L179.855 49.3264C179.855 49.3264 187.408 62.7237 192.547 68.4053C195.51 71.6733 200.154 73.1513 204.297 74.2225C207.287 74.9887 210.318 75.5785 213.348 76.1413C214.528 76.3582 215.809 76.4396 216.826 77.1447C217.579 77.7216 218.095 78.5534 218.277 79.4838C218.322 79.6736 218.363 79.8635 218.399 80.0533C218.528 80.6229 219.918 80.1889 219.871 78.8465Z" fill="white"/>
<path opacity="0.3" d="M214.657 68.5412C214.25 68.453 213.843 68.3649 213.429 68.2632C213.463 70.6091 212.365 74.6499 211.965 75.8771L213.253 76.1212C214.097 73.6789 214.57 71.1238 214.657 68.5412Z" fill="#3D5A80"/>
<path opacity="0.3" d="M219.606 71.3956C219.604 70.8718 219.421 70.3646 219.089 69.9595C218.757 69.5544 218.295 69.2762 217.782 69.1718L217.104 69.043C217.213 69.8137 217.607 70.5154 218.209 71.0092C218.596 71.367 219.06 71.6314 219.565 71.7821C219.606 71.66 219.606 71.5244 219.606 71.3956Z" fill="#3D5A80"/>
<path d="M174.892 17.786C175.163 18.8166 174.01 19.8946 173.095 19.0064C172.241 18.0905 171.306 17.2532 170.302 16.5046C169.359 15.9147 170.614 14.8842 172.098 15.2842C172.732 15.4434 173.317 15.7543 173.804 16.1902C174.291 16.626 174.664 17.1737 174.892 17.786Z" fill="white"/>
<path d="M174.967 50.547C174.492 50.7233 175.36 51.5369 175.36 51.5369C175.36 51.5369 184.852 48.2893 190.615 44.9467C190.629 44.7444 190.594 44.5416 190.514 44.3553C190.434 44.169 190.31 44.0045 190.154 43.8755C185.222 46.3845 180.151 48.6119 174.967 50.547Z" fill="#3D5A80"/>
<path opacity="0.5" d="M174.967 50.547C174.492 50.7233 175.36 51.5369 175.36 51.5369C175.36 51.5369 184.852 48.2893 190.615 44.9467C190.629 44.7444 190.594 44.5416 190.514 44.3553C190.434 44.169 190.31 44.0045 190.154 43.8755C185.222 46.3845 180.151 48.6119 174.967 50.547Z" fill="white"/>
<path d="M181.95 22.5454C183.821 23.4946 185.577 24.4913 187.374 25.5964C188.248 26.1524 189.116 26.7151 189.977 27.3253C190.838 27.9355 191.693 28.5593 192.554 29.2644L192.872 29.5288L193.272 29.8949C193.511 30.1082 193.737 30.3346 193.95 30.5729C194.167 30.8102 194.35 31.0408 194.527 31.2509C194.703 31.4611 194.893 31.712 195.049 31.9289C195.681 32.8006 196.265 33.7059 196.798 34.6409C197.849 36.4541 198.756 38.3471 199.51 40.3022C199.649 40.6716 199.657 41.0779 199.53 41.4521C199.404 41.8262 199.152 42.145 198.817 42.3544C198.483 42.5637 198.086 42.6506 197.694 42.6004C197.302 42.5501 196.94 42.3657 196.669 42.0786L196.635 42.0379C195.279 40.5938 193.971 39.0751 192.655 37.658C191.34 36.241 190.004 34.8037 188.899 34.1731C187.36 33.2171 185.645 32.2612 183.943 31.3255L178.817 28.4779C178.023 28.0571 177.428 27.3379 177.163 26.4783C176.899 25.6188 176.986 24.6894 177.407 23.8946C177.828 23.0998 178.547 22.5048 179.407 22.2403C180.266 21.9758 181.196 22.0636 181.991 22.4844L181.95 22.5454Z" fill="#3D5A80"/>
<path opacity="0.8" d="M181.95 22.5454C183.821 23.4946 185.577 24.4913 187.374 25.5964C188.248 26.1524 189.116 26.7151 189.977 27.3253C190.838 27.9355 191.693 28.5593 192.554 29.2644L192.872 29.5288L193.272 29.8949C193.511 30.1082 193.737 30.3346 193.95 30.5729C194.167 30.8102 194.35 31.0408 194.527 31.2509C194.703 31.4611 194.893 31.712 195.049 31.9289C195.681 32.8006 196.265 33.7059 196.798 34.6409C197.849 36.4541 198.756 38.3471 199.51 40.3022C199.649 40.6716 199.657 41.0779 199.53 41.4521C199.404 41.8262 199.152 42.145 198.817 42.3544C198.483 42.5637 198.086 42.6506 197.694 42.6004C197.302 42.5501 196.94 42.3657 196.669 42.0786L196.635 42.0379C195.279 40.5938 193.971 39.0751 192.655 37.658C191.34 36.241 190.004 34.8037 188.899 34.1731C187.36 33.2171 185.645 32.2612 183.943 31.3255L178.817 28.4779C178.023 28.0571 177.428 27.3379 177.163 26.4783C176.899 25.6188 176.986 24.6894 177.407 23.8946C177.828 23.0998 178.547 22.5048 179.407 22.2403C180.266 21.9758 181.196 22.0636 181.991 22.4844L181.95 22.5454Z" fill="white"/>
<path d="M201.117 43.6854L200.541 42.2413C200.541 42.2413 200.222 40.3971 199.076 39.8818L195.686 40.4175L195.802 40.7158C196.113 41.3938 195.876 42.6345 195.571 43.6244C195.266 44.6143 195.781 44.7431 196.215 44.5465C196.459 44.438 196.697 43.9973 196.954 43.5295C197.081 43.7652 197.223 43.9917 197.381 44.2075L198.215 45.3533C198.389 45.5907 198.647 45.7535 198.936 45.8088C199.226 45.8641 199.525 45.808 199.775 45.6516L200.697 45.0618C200.913 44.9141 201.072 44.6974 201.148 44.4473C201.224 44.1971 201.214 43.9285 201.117 43.6854Z" fill="#3D5A80"/>
<path opacity="0.8" d="M201.117 43.6854L200.541 42.2413C200.541 42.2413 200.222 40.3971 199.076 39.8818L195.686 40.4175L195.802 40.7158C196.113 41.3938 195.876 42.6345 195.571 43.6244C195.266 44.6143 195.781 44.7431 196.215 44.5465C196.459 44.438 196.697 43.9973 196.954 43.5295C197.081 43.7652 197.223 43.9917 197.381 44.2075L198.215 45.3533C198.389 45.5907 198.647 45.7535 198.936 45.8088C199.226 45.8641 199.525 45.808 199.775 45.6516L200.697 45.0618C200.913 44.9141 201.072 44.6974 201.148 44.4473C201.224 44.1971 201.214 43.9285 201.117 43.6854Z" fill="white"/>
<path opacity="0.3" d="M179.088 30.3423L178.261 30.4101L173.183 42.6683C173.461 42.6883 173.74 42.6654 174.01 42.6005C174.01 42.6005 180.628 40.1326 182.628 39.2105C181.285 36.4375 179.088 30.3423 179.088 30.3423Z" fill="#3D5A80"/>
<path d="M169.82 33.5561C170.437 36.7604 171.583 39.8399 173.21 42.6684C175.692 41.8141 179.828 40.2005 181.834 39.2784C180.486 36.3867 179.288 33.4266 178.248 30.4102C176.126 30.5525 171.468 32.3357 169.82 33.5561Z" fill="white"/>
<path opacity="0.3" d="M174.858 36.2073C174.759 36.5389 174.563 36.8336 174.297 37.0541C174.03 37.2747 173.704 37.4112 173.36 37.4464C173.016 37.4816 172.669 37.4139 172.363 37.2519C172.057 37.0899 171.806 36.8408 171.642 36.5362C171.478 36.2315 171.408 35.885 171.44 35.5405C171.473 35.196 171.608 34.869 171.826 34.6008C172.045 34.3326 172.338 34.1353 172.669 34.0339C173 33.9324 173.353 33.9314 173.685 34.0309C174.129 34.1642 174.502 34.4683 174.722 34.8764C174.942 35.2845 174.991 35.7631 174.858 36.2073Z" fill="#3D5A80"/>
<path opacity="0.6" d="M176.153 33.8748C176.127 33.988 176.067 34.0905 175.981 34.169C175.895 34.2474 175.788 34.2981 175.673 34.3144C175.558 34.3307 175.44 34.3118 175.336 34.2602C175.232 34.2086 175.146 34.1268 175.089 34.0254C175.032 33.924 175.008 33.8078 175.018 33.692C175.028 33.5763 175.074 33.4664 175.148 33.3768C175.222 33.2872 175.321 33.222 175.433 33.1899C175.544 33.1578 175.663 33.1602 175.773 33.1968C175.911 33.2407 176.026 33.3352 176.097 33.461C176.167 33.5868 176.187 33.7347 176.153 33.8748Z" fill="#3D5A80"/>
<path opacity="0.6" d="M177.671 33.2852C177.656 33.3639 177.623 33.4383 177.577 33.5038C177.531 33.5693 177.471 33.6244 177.402 33.6656C177.333 33.7068 177.257 33.7331 177.177 33.7429C177.097 33.7527 177.017 33.7458 176.94 33.7225C176.863 33.6993 176.792 33.6603 176.731 33.608C176.67 33.5557 176.621 33.4912 176.586 33.4188C176.552 33.3464 176.533 33.2675 176.531 33.1873C176.528 33.1071 176.543 33.0273 176.573 32.953C176.595 32.8812 176.63 32.8145 176.678 32.7565C176.726 32.6986 176.784 32.6506 176.85 32.6153C176.916 32.5801 176.989 32.5582 177.063 32.551C177.138 32.5438 177.213 32.5514 177.285 32.5733C177.43 32.6173 177.552 32.7166 177.624 32.8499C177.697 32.9832 177.714 33.1396 177.671 33.2852Z" fill="#3D5A80"/>
<path opacity="0.5" d="M179.801 38.5665L173.529 41.0412L173.156 39.8072L179.421 37.3325L179.801 38.5665Z" fill="#3D5A80"/>
<path opacity="0.2" d="M145.202 132.796C145.188 136.743 144.806 140.681 144.063 144.559C143.263 148.044 142.02 150.593 140.334 152.207C139.549 152.986 138.616 153.602 137.591 154.017C136.565 154.433 135.467 154.64 134.361 154.627C130.876 154.627 128.216 152.726 126.381 148.925C126.058 148.242 125.784 147.538 125.56 146.817C121.685 150.145 118.076 153.772 114.767 157.665L111.167 161.909C113.104 163.873 115.34 165.518 117.791 166.784C122.012 168.967 127.554 170.061 134.415 170.065C135.622 170.065 136.788 170.031 137.92 169.963C142.071 163.821 145.506 157.224 148.158 150.301L158.226 124.049L145.202 132.796Z" fill="black"/>
<path d="M54.4385 168.614L66.3035 180.479L99.5255 149.176C88.0741 144.803 65.8085 157.217 54.4385 168.614Z" fill="#263238"/>
<path d="M95.8167 209.972C92.0266 206.175 83.9517 198.107 83.9517 198.107L115.255 164.885C119.601 176.31 107.187 198.595 95.8167 209.972Z" fill="#263238"/>
<path d="M141.229 135.189L154.172 110.231L129.215 123.188C121.199 127.345 113.797 132.593 107.221 138.782L64.6763 178.831L85.579 199.734L125.628 157.19C131.82 150.612 137.07 143.208 141.229 135.189Z" fill="#3D5A80"/>
<path opacity="0.6" d="M141.229 135.189L154.172 110.231L129.215 123.188C121.199 127.345 113.797 132.593 107.221 138.782L64.6763 178.831L85.579 199.734L125.628 157.19C131.82 150.612 137.07 143.208 141.229 135.189Z" fill="white"/>
<path d="M114.651 158.498C119.482 158.498 123.398 154.582 123.398 149.752C123.398 144.922 119.482 141.006 114.651 141.006C109.821 141.006 105.905 144.922 105.905 149.752C105.905 154.582 109.821 158.498 114.651 158.498Z" fill="white"/>
<path d="M114.651 155.373C117.756 155.373 120.272 152.856 120.272 149.752C120.272 146.648 117.756 144.131 114.651 144.131C111.547 144.131 109.031 146.648 109.031 149.752C109.031 152.856 111.547 155.373 114.651 155.373Z" fill="#3D5A80"/>
<path opacity="0.3" d="M92.6505 192.222L72.1816 171.753L88.9418 155.983L108.421 175.469L92.6505 192.222Z" fill="#3D5A80"/>
<path d="M59.069 187.34C43.7462 188.581 38.668 198.392 36.9323 211.43C36.0509 218.081 35.6577 224.813 30.0778 228.705C29.7421 228.933 29.4893 229.264 29.3574 229.648C29.2254 230.032 29.2214 230.448 29.3458 230.834C29.4703 231.221 29.7165 231.556 30.0477 231.791C30.3789 232.025 30.7772 232.146 31.1829 232.136C51.7534 231.492 61.3471 221.423 62.554 217.22C62.2372 219.417 61.6708 221.571 60.8657 223.64C60.7177 224.006 60.6888 224.408 60.7829 224.791C60.8771 225.173 61.0896 225.516 61.3901 225.771C61.6907 226.026 62.0639 226.179 62.4567 226.209C62.8495 226.239 63.2418 226.145 63.5777 225.939C69.3475 222.325 76.5886 215.667 77.0699 204.867C72.8257 198.853 59.069 187.34 59.069 187.34Z" fill="#3D5A80"/>
<path opacity="0.2" d="M59.069 187.34C43.7462 188.581 38.668 198.392 36.9323 211.43C36.0509 218.081 35.6577 224.813 30.0778 228.705C29.7421 228.933 29.4893 229.264 29.3574 229.648C29.2254 230.032 29.2214 230.448 29.3458 230.834C29.4703 231.221 29.7165 231.556 30.0477 231.791C30.3789 232.025 30.7772 232.146 31.1829 232.136C51.7534 231.492 61.3471 221.423 62.554 217.22C62.2372 219.417 61.6708 221.571 60.8657 223.64C60.7177 224.006 60.6888 224.408 60.7829 224.791C60.8771 225.173 61.0896 225.516 61.3901 225.771C61.6907 226.026 62.0639 226.179 62.4567 226.209C62.8495 226.239 63.2418 226.145 63.5777 225.939C69.3475 222.325 76.5886 215.667 77.0699 204.867C72.8257 198.853 59.069 187.34 59.069 187.34Z" fill="white"/>
<path d="M79.4498 209.497L54.9062 184.954L67.9781 182.126L82.2771 196.425L79.4498 209.497Z" fill="#3D5A80"/>
</svg>

);

export default Error404IllustrationSvg;