import React, { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { getfileNFolder, setOpenFolderItem, setSelectedFile } from 'store/actions/myDisk.Action';
import { DiskFilesListing } from './common/DiskFilesListing';
import { DiskListingHeader } from './common/DiskListingHeader';
import { NoFilesFolderIllustration } from './common/NoFilesFolderIllustration';
import { getGridIcon, getListIcon, isDir, isRootDir } from './common/utils';

const MyDisk = (props) => {
  const { viewType, fileFolderList, directory, activeSubTab } = props;
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const [activeMenu, setActiveMenu] = useState(null);
  const { selectedFile } = useSelector((state) => state.myDiskReducer);

  const dispatch = useDispatch();

  const outsideClickRef = useRef()

  const onCloseMenu = () => {
    setActiveMenu(null);
  };
  const onOpenMenu = (index, item) => {
    setActiveMenu(index);
    dispatch(setSelectedFile(item));
  };

  const openFolder = (item) => {
    if (isRootDir(item)) {
      props.handleSubTab_getfileNFolder(item);
    } else {
      dispatch(getfileNFolder({ parent: item.id }));
      dispatch(setOpenFolderItem(item));
    }
    setActiveMenu(null);
  };
  const onSelectFile = (item) => {
    dispatch(setSelectedFile(item));
  };

  const ref = useRef();
  useOnClickOutside(ref, () => {
    setActiveMenu(null);
  });

  const handleItemClick = (item) => {
    if (isDir(item)) {
      openFolder(item)
    } else {
      onSelectFile(item)
    }
  }





  return (
    <>
      {/* {overlay ? <div className='overlay-fix' onClick={onCloseMenu}></div> : null} */}
      <div className="row" ref={outsideClickRef}>


        <div className="my-disk-left-block">
          {viewType === 'ListView' &&
            <DiskListingHeader isMobile={isMobile} />}

          <div className='my-disk-list-items'>
            {viewType === 'ListView' ? (
              // List View
              <Fragment>
                {activeSubTab ? (
                  <>
                    {fileFolderList?.length ?
                      <DiskFilesListing
                        ref={outsideClickRef}
                        fileFolderList={fileFolderList}
                        isMobile={isMobile}
                        selectedFile={selectedFile}
                        onOpenMenu={onOpenMenu}
                        handlePopups={props?.handlePopups}
                        activeSubTab={activeSubTab}
                        onUploadFilee={props?.onUploadFilee}
                        handleItemClick={handleItemClick}
                      /> : <NoFilesFolderIllustration />}
                  </>
                ) : (
                  directory &&
                    directory.length ?
                    <DiskFilesListing
                      ref={outsideClickRef}
                      fileFolderList={directory}
                      isMobile={isMobile}
                      selectedFile={selectedFile}
                      onOpenMenu={onOpenMenu}
                      handlePopups={props?.handlePopups}
                      activeSubTab={activeSubTab}
                      onUploadFilee={props?.onUploadFilee}
                      handleItemClick={handleItemClick}
                      getListIcon={getListIcon}
                    />
                    :
                    <NoFilesFolderIllustration />
                )}
              </Fragment>
            ) : (
              // Grid View
              <Fragment>
                {activeSubTab ? (
                  <>
                    {fileFolderList && fileFolderList.length ? (
                      <DiskFilesListing
                        ref={outsideClickRef}
                        fileFolderList={fileFolderList}
                        isMobile={isMobile}
                        selectedFile={selectedFile}
                        onOpenMenu={onOpenMenu}
                        handlePopups={props?.handlePopups}
                        activeSubTab={activeSubTab}
                        onUploadFilee={props?.onUploadFilee}
                        activeMenu={activeMenu}
                        handleItemClick={handleItemClick}
                        onCloseMenu={onCloseMenu}
                        getGridIcon={getGridIcon}
                        view="grid"
                      />
                    ) : (
                      <NoFilesFolderIllustration />
                    )}
                  </>
                ) : (
                  directory &&
                    directory.length ?
                    <DiskFilesListing
                      ref={outsideClickRef}
                      fileFolderList={directory}
                      isMobile={isMobile}
                      selectedFile={selectedFile}
                      onOpenMenu={onOpenMenu}
                      handlePopups={props?.handlePopups}
                      activeSubTab={activeSubTab}
                      onUploadFilee={props?.onUploadFilee}
                      activeMenu={activeMenu}
                      handleItemClick={handleItemClick}
                      onCloseMenu={onCloseMenu}
                      getGridIcon={getGridIcon}
                      view="grid"
                    />
                    : <NoFilesFolderIllustration />
                )}
              </Fragment>
            )}
          </div>


        </div>
      </div >
    </>
  );
};

export default MyDisk;
