import classNames from 'classnames';
import { ChevronDown, ChevronRight, Documents, FolderIcon, GridView, ListView, Search } from 'components/common/Icons';
import { Spinner } from 'components/common/Loader';
import { toPascalCase, trimStr } from 'components/utils';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSharedItemUsers, getfileNFolder, setDeleteFolderSeccess, setDeleteSeccess, setOpenFolderItem, setSelectedFile } from 'store/actions/myDisk.Action';
import { getActiveTabHeading, getFileFolderNameByType, getUserFullName } from './common/utils';

const MyDiskHeader = (props) => {
  const { activeSubTab, viewType, handleViewType, heading, onSearch, searchValue, activeTab, gotoRootFolder } = props;
  const [openSearch, setOpenSearch] = useState(false);
  const [showHistoryMenu, setShowHistoryMenu] = useState(false)
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const { openFolderItem, deleteFolderSeccess, deleteSeccess } = useSelector((state) => state.myDiskReducer);
  const [history, setHistory] = useState([])
  const ref = useRef()
  const searchRef = useRef()
  const dispatch = useDispatch()

  const historyList = useMemo(() => {
    console.log(history)
    return history?.length > 0 ?
      history.filter((h, i) => (h !== undefined && h.name !== undefined)) : []
  }, [history])

  useOnClickOutside(ref, () => setShowHistoryMenu(h => !h))
  useOnClickOutside(searchRef, () => {
    if (!searchValue || searchValue?.lenght <= 0) {
      setOpenSearch(false)
    }
  })

  const rootItem = () => ({
    name: getActiveTabHeading(activeTab),
    _type: "root"
  })

  useEffect(() => {
    if ((deleteFolderSeccess || deleteSeccess) && historyList.length > 1) {
      const openFolder = getLastHistoryItem(1);
      if (openFolder && (openFolder.id === deleteFolderSeccess || openFolder.id === deleteSeccess)) {
        dispatch(setDeleteFolderSeccess(null))
        dispatch(setDeleteSeccess(null))
        handleHistoryNavigate(getLastHistoryItem(2), historyList.length - 2)
      }
    }
  }, [deleteFolderSeccess, deleteSeccess, historyList])

  useEffect(() => {
    setHistory([rootItem()])
  }, [activeTab])

  useEffect(() => {
    if (!activeSubTab) {
      setHistory([rootItem()])
      return;
    }

    if (activeSubTab.first_name) {
      setHistory([rootItem(), { ...(activeSubTab ?? {}), name: getUserFullName(activeSubTab) }])
      return;
    }

    // if not parent, considered as root level dir
    if (!activeSubTab.parent) {
      setHistory([rootItem(), activeSubTab])
      return;
    }
  }, [activeSubTab])

  useEffect(() => {
    if (openFolderItem?._type === "root") {
      setHistory([rootItem()])
      return;
    }
    if (openFolderItem && !openFolderItem.parent) {
      setHistory([rootItem(), openFolderItem])
      return;
    }
    const lastItem = history?.[history?.length - 1]
    if (openFolderItem?.id && (!lastItem || lastItem?.id !== openFolderItem?.id)) {
      history.push(openFolderItem)
      setHistory([...(history ?? [])])
    }
  }, [openFolderItem])

  const handleSearchOpen = () => {
    setOpenSearch((prevState) => !prevState);
  };

  const handleHistoryNavigate = (item, index) => {
    if (index <= 0) {
      setShowHistoryMenu(false)
    }
    if (index <= 0) {
      gotoRootFolder?.(activeTab)
    }
    const h_ = [...(history ?? [])]
    const newHistoryList = h_?.splice(0, index + 1)
    // return;
    setHistory([...(newHistoryList ?? [])])
    if (item.first_name) {
      dispatch(getSharedItemUsers(item.id))
    } else {
      dispatch(getfileNFolder({ parent: item.id }));
    }
    dispatch(setOpenFolderItem(item))
  }

  const getLastHistoryItem = (index) =>
    historyList?.[historyList.length - index]

  return (<>
    {!isMobile ? (
      <div className="my-disk-header">
        {searchValue?.length ? <h6>Search Files & Folders</h6> :
          <div className="brd-crum">
            {/* {loading ? <span className="me-2"><Spinner /></span> : ""} */}
            <span className='history-menu'>
              {historyList.length > 2 ? <>
                <button onClick={() => setShowHistoryMenu(true)} className='btn btn-sm btn-light me-2'>
                  <ChevronDown />
                </button>
                {showHistoryMenu ?
                  <ul ref={ref} className="hm-list">
                    {historyList?.map?.((hitem, hind) => {

                      return (hind < historyList.length - 2) ? <li
                        className='hml-li' key={hind}
                        onClick={() => handleHistoryNavigate(hitem, hind)}
                      >
                        {hitem?.type === "F" ? <Documents /> : <FolderIcon />}
                        <span>
                          {trimStr(toPascalCase(getFileFolderNameByType(hitem)), { length: 15 })}
                        </span>
                      </li> : <></>
                    })}

                  </ul> : ""}
              </> : ""}
            </span>
            <span className="link" onClick={
              () => handleHistoryNavigate(getLastHistoryItem(2), historyList?.length - 2)
            }>
              {historyList?.length > 1 ? <>
                {toPascalCase(trimStr(getFileFolderNameByType(getLastHistoryItem(2)), { length: 15 }))}
              </> : ""}
            </span>
            <span>
              {historyList?.length ? <>
                {historyList?.length > 1 ?
                  <span className="ps-1">
                    <ChevronRight width={12} height={12} />
                  </span> : ""} {toPascalCase(trimStr(getFileFolderNameByType(getLastHistoryItem(1)), { length: 15 }))}
              </> : ""}
            </span>
            {/* {activeSubTab && <span className="link active">&#62; {trimStr(activeSubTab?.name, { length: 20 })}</span>} */}
          </div>
        }
        <div className="btn-wrapper">
          <div ref={searchRef} className={classNames('search-container', [openSearch ? 'active' : ''])}>
            <button className="search-btn" onClick={() => handleSearchOpen(true)}>
              <Search />
            </button>
            <input type="text" placeholder="Search" onChange={(e) => onSearch(e)} />
          </div>

          <div className="viewBtn" onClick={() => handleViewType('GridView')}>
            <GridView color={viewType === 'GridView' ? '#3C5A80' : null} />
          </div>
          <div className="viewBtn" onClick={() => handleViewType('ListView')}>
            <ListView color={viewType === 'ListView' ? '#3C5A80' : null} />
          </div>
        </div>
      </div >
    ) : (
      <div className="my-disk-header mobile-search">
        <input type="text" placeholder="Search" onChange={(e) => onSearch(e)} />
      </div>
    )}
  </>
  )
};

export default MyDiskHeader;
