import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { VerticalLine } from 'components/StyledComponents';
import { genralMonths } from 'data/data';

const MilestoneDetailsContainer = styled.div`
  padding-bottom: 15px;
`;

const Milestones = (props) => {
  const { publicProfileData } = useSelector((state) => state.userProfile);
  const { profile_milestones: milestones } = publicProfileData;

  return milestones?.map((milestone, id) => (
    <>
      <MilestoneDetailsContainer className="mb-4" key={id}>
        <div className="d-block d-lg-flex align-items-center justify-content-between gap-2">
          <p className="fw-semibold">{milestone.title}</p>
        </div>
        <div>
          <p className="fs-15">
            {genralMonths[milestone.month - 1]?.label}
            &nbsp;
            {milestone.year}
          </p>
          <p className="pt-2">{milestone.milestone}</p>
        </div>
      </MilestoneDetailsContainer>
      {milestones.length > 0 && <VerticalLine />}
    </>
  ));
};

export default Milestones;
