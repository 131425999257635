import servicesPic from '../../assets/profiles/profile/avtar_md.jpeg';

export const sharedDisk = [
  {
    id: 1,
    user: 'Sola',
    modification_date: '10/12/2022 07:01 AM',
  },
  {
    id: 2,
    user: 'Shubham',
    modification_date: '10/12/2022 07:01 AM',
  },
  {
    id: 3,
    user: 'Ravi',
    modification_date: '10/12/2022 07:01 AM',
  },
  {
    id: 4,
    user: 'Vikrant',
    modification_date: '10/12/2022 07:01 AM',
  },
];

export const subTabs = [
  {
    id: 1,
    label: 'Documents',
    icon: 'Documents',
    modification_date: '10/12/2022 07:01 AM',
  },
  {
    id: 2,
    label: 'Music',
    icon: 'Music',
    modification_date: '-',
  },
  {
    id: 3,
    label: 'Pictures',
    icon: 'Pictures',
    modification_date: '-',
  },
  {
    id: 4,
    label: 'Videos',
    icon: 'Videos',
    modification_date: '-',
  },
];

export const lastModifyValue = [
  {
    label: 'Any date',
  },
  {
    label: 'Last 24 hours',
  },
  {
    label: 'Last 7 days',
  },
  {
    label: 'Last 30 days',
  },
  {
    label: 'Last 90 days',
  },
  {
    label: 'Last 12 months',
  },
  {
    label: 'Custom',
  },
];
export const propleList = [
  {
    label: 'Anyone',
  },
  {
    label: 'Sola Daves',
  },
  {
    label: 'Manish kumar',
  },
  {
    label: 'Asim',
  },
];
export const sortList = [
  {
    label: 'Most relevant',
  },
  {
    label: 'Newest',
  },
  {
    label: 'Oldest',
  },
];

export const services = [
  {
    servicesImg: servicesPic,
    serviceTitle: 'Data Technology & Strategy Consulting',
    servicesDetails:
      'Duration varies At databridge, we provide Artificial intelligence, Big Data & Data Science consulting.',
  },
  {
    servicesImg: servicesPic,
    serviceTitle: 'Data Technology & Strategy Consulting',
    servicesDetails:
      'Duration varies At databridge, we provide Artificial intelligence, Big Data & Data Science consulting.',
  },
  {
    servicesImg: servicesPic,
    serviceTitle: 'Data Technology & Strategy Consulting',
    servicesDetails:
      'Duration varies At databridge, we provide Artificial intelligence, Big Data & Data Science consulting.',
  },
];

export const followerList = [
  {
    profilePic: servicesPic,
    userName: 'Sola Daves',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'busy',
  },
  {
    profilePic: servicesPic,
    userName: 'Kamal Kant',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'available',
  },
  {
    profilePic: servicesPic,
    userName: 'Ravi Chaurasia',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'available',
  },
  {
    profilePic: servicesPic,
    userName: 'Ganesh Kumar',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'available',
  },
  {
    profilePic: servicesPic,
    userName: 'Ashim Raj',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'busy',
  },
  {
    profilePic: servicesPic,
    userName: 'Shubham Pandey',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'available',
  },
  {
    profilePic: servicesPic,
    userName: 'Manish Kumar',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'busy',
  },
  {
    profilePic: servicesPic,
    userName: 'Umesh Jangid',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'notActive',
  },
  {
    profilePic: servicesPic,
    userName: 'Vikranth Reddy',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'available',
  },
  {
    profilePic: servicesPic,
    userName: 'Shivam Chaurasia',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'notActive',
  },
  {
    profilePic: servicesPic,
    userName: 'Mahendra Sharma',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'busy',
  },
  {
    profilePic: servicesPic,
    userName: 'Joanne Acharya',
    Designation: 'Account Executive, Client Services & Recruiting',
    status: 'available',
  },
];

export const JobList = [
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
];

export const folderList = [
  {
    id: 13,
    name: 'Ravi Dir',
    file: null,
    extension: null,
    type: 'D',
    size: null,
    public_share_link: null,
    private_share_link: null,
    downloaded: 0,
    viewed: 0,
    is_private: false,
    is_active: true,
    is_deleted: false,
    created_at: '2022-12-19T04:26:37.693102Z',
    updated_at: '2022-12-19T04:26:37.693121Z',
    created_by: 20,
    updated_by: 20,
    parent: 9,
    shared_with: [],
  },
  {
    id: 14,
    name: 'Ravi 1',
    file: null,
    extension: null,
    type: 'D',
    size: null,
    public_share_link: null,
    private_share_link: null,
    downloaded: 0,
    viewed: 0,
    is_private: false,
    is_active: true,
    is_deleted: false,
    created_at: '2022-12-19T04:53:54.462785Z',
    updated_at: '2022-12-19T04:53:54.462805Z',
    created_by: 20,
    updated_by: 20,
    parent: 9,
    shared_with: [],
  },
  {
    id: 18,
    name: '12',
    file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/disk/20/private/12.jpg?AWSAccessKeyId=DO00647MXDEJJ9WM96E9&Signature=%2FLuAXlOw%2BVqCpHPokFVUb%2BRicqA%3D&Expires=1671448627',
    extension: '.jpg',
    type: 'F',
    size: null,
    public_share_link: null,
    private_share_link: null,
    downloaded: 0,
    viewed: 0,
    is_private: true,
    is_active: true,
    is_deleted: false,
    created_at: '2022-12-19T10:16:43.660176Z',
    updated_at: '2022-12-19T10:16:43.660202Z',
    created_by: 20,
    updated_by: 20,
    parent: 9,
    shared_with: [],
  },
];

export const candidatesList = [
  {
    userImg: servicesPic,
    name: 'Ravi ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Shivam ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Sola ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Ashim ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Shubham ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Umesh ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Manish ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Vikrant ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Mahendra ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Ganesh ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Raj ',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
  {
    userImg: servicesPic,
    name: 'Rajesh',
    jobTitle: 'Account Executive, Client Services & Recruiting',
    company: 'Applied to Attorney Employment Las- Tulsa, OK at Tulsa Community Collage',
    applicationType: 'New',
    date: new Date(),
  },
];
export const DEBOUNCE_dELAY = 600;
