import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import ReactPlayer from 'react-player';
import Post from 'components/Post';

import close from 'assets/icons/close.svg';

import { Text } from 'components/StyledComponents';
import VideoPlayer from 'components/PostCard/PostBody/VideoPlayer';
import './postmodal.scss';
import queryString from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { getPostFunc } from 'api/post';
import Loader from 'components/common/Loader';
import EventPost from 'pages/Company/Home/Feed/Event';
import { PostCard } from 'components/PostCard';
// import Modal from 'components/common/Modal/Modal';

const PostModal = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const parsed = queryString.parse(search);
  const { postId: urlPostId } = parsed;
  const [postId, setPostId] = useState(urlPostId);
  const [show, setShow] = useState(true);

  const {
    isLoading,
    data: postData,
    error,
    refetch
  } = useQuery(['getPostDetail', postId], getPostFunc, {
    refetchOnWindowFocus: false,
  });

  const onClose = () => {
    setShow(false);
    navigate(pathname);
  };

  useEffect(() => {
    if (urlPostId) {
      setPostId(urlPostId);
      setShow(true);
    }
  }, [urlPostId]);

  if (!postId) return null;

  if (isLoading) {
    return <Loader secondary />;
  }

  if (error) {
    return <div>{error?.message}</div>;
  }

  let { author, media, gif } = postData ?? {};
  const { first_name, last_name } = author ?? {};
  const authorName = `${first_name} ${last_name}`;

  let modalType = 'media-modal';
  if ((!media || media?.length === 0) && (!gif || gif.length <= 0)) modalType = 'text-modal';


  return (!show ? <></> :
    <Modal backdropClassName='post-modal-bd' show={show} onClose={() => onClose()} className={`${modalType} post-modal-dialog`} scrollable>
      <div className="post-modal-body">
        {gif ? <div style={{ width: "100%" }} className="post-modal-content-media">
          <div className="post-media-item-gif">
            <PostCard.Image src={gif} alt="gif" />
          </div>
        </div> : <>
          {media?.length <= 0 ? null : (
            <div className="post-modal-content-media">
              {media?.length === 1 ? (
                media?.[0]?.file?.toLowerCase?.()?.includes?.('.mp4') ? (
                  <div key={media[0]?.id} className="post-media-item-video">
                    <VideoPlayer src={media[0]?.file} controls width="50vw" height="100vh" />
                  </div>
                ) : (
                  <div key={media[0]?.id} className="post-media-item-image">
                    <img src={media[0]?.file} className="w-100 h-100" alt="First slide" />
                  </div>
                )
              ) : (
                <Carousel indicators={false}>
                  {media?.map((item) => {
                    console.log(item);
                    const isMp4 = item.file?.toLowerCase?.()?.includes?.('.mp4');

                    return (
                      <Carousel.Item key={item.id} className="post-media-item">
                        {isMp4 ? (
                          <VideoPlayer src={item.file} />
                        ) : (
                          <img src={item.file} className="w-100 h-100" alt="First slide" />
                        )}
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              )}
            </div>
          )}
        </>}

        <div className="post-modal-content-text">
          <div className="post-modal-text-header">
            <Text color="#3D5A80" fS="18px" fW="600">
              {authorName}'s Post
            </Text>

            <img src={close} alt="" className="cursor-pointer" onClick={onClose} />
          </div>
          <div className="post-modal-text-body">
            <Post updatePost={refetch} data={postData} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PostModal;
