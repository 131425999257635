import styled from 'styled-components';

export const PostImage = styled.img`
  font-family: 'Inter';
  font-size: 13px;
  line-height: 120%;
  color: #5b5b5b;
  overflow: hidden;
  width: auto;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  max-height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  margin-top: 0px;
`;

export default PostImage;
