import React from 'react';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
// import { ViewProfile, FollowIcon } from 'components/common/Icons';

// import circle from 'assets/icons/circle.svg';
import myBridge from 'assets/icons/myBridge.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCompanyPageData } from 'store/actions/companyPages.Action';
import { joinTeamRequest, withdrawTeamRequest } from 'store/slices/teamCommunitySlice';

const TeamCard = ({ name, location, desc, industry_name, image_logo, slug, connection, isSearchResult, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { userRequested, userRequestedTeams } = useSelector((state) => state.teamsCommunity);

  const goToPage = () => {
    const payload = {
      id: id,
    };
    dispatch(getCompanyPageData(payload)).then((res) => {
      if (res) {
        navigate(`/c/${slug}/overview`);
      }
    });
  };

  function stripHTML(myString) {
    return myString?.replace(/(<([^>]+)>)/gi, '');
  }

  const handleJoin = () => {
    if(!userRequested) {
      dispatch(joinTeamRequest({ for_team_community_id: id }))
    } else {
      dispatch(withdrawTeamRequest({for_team_community_id: id, user_id: userProfileInfo?.id}))
    }
  };

  return (
    <div className={`network-list-card overflow-hidden m-0 mb-4`} style={{ height: '250px' }}>
      {/*  ${isSearchResult ? 'border-bottom-0' : ''}`  */}
      {/* <div className="network-list-card--inner1"> */}
      <div className="network-list-card--profile d-flex justify-content-between">
        <div>
          <MyBridgeAvatar icon={image_logo} name={name} size={65} className="company-avatar" round={true} />
        </div>
        <div>
          <div className={`network-list-card--actions ${isSearchResult ? 'network-list-card-via-search' : ''}`}>
            {isSearchResult && (
              <>
                <div className="">
                  <Button className={`me-1 ${!userRequested ? 'btn-blue' : 'btn-secondary'}`} onClick={handleJoin}>
                    {userRequested && userRequestedTeams.includes(id) ? 'Withdraw Request' : 'Join'}
                  </Button>
                </div>
                {userProfileInfo?.connect_count ? (
                  <div className="d-flex mt-1 justify-content-end">
                    <img src={myBridge} className="icon" alt="myBridge" />
                    <span>{userProfileInfo?.connect_count}</span>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="network-list-card--details my-3">
        <h5 className="text-blue cursor-pointer" onClick={goToPage}>
          {name}
        </h5>
        <div className="job-card--subtitle">{`${industry_name?.length ? industry_name[0]?.name : ''} ${
          location && industry_name?.length && industry_name[0] ? '|' : ''
        } ${location ? location : ''}`}</div>
        <div className="job-excerpt ">{stripHTML(desc)?.slice(0, 150)}</div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default TeamCard;
