import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';

import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';
import { addCertificate, getCertificate } from 'store/slices/userProfileSlice';
import { rangeOfFullYears } from 'components/utils';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCertificate, updateCertificate } from '../../../../../store/slices/userProfileSlice';
import { genralMonths } from 'data/data';
import { Link } from 'react-router-dom';
import { AddCircle } from 'components/common/Icons';
import { BorderLessButton, VerticalLine } from 'components/StyledComponents';
import styled from 'styled-components';

const CertificationsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const CertificationName = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
`;

const CertificationText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: ${(props) => props.color || '#747474'};
`;

const CertificationLink = styled(Link)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width: 1224px) {
    flex-direction: row;
  }
`;

const LeftDiv = styled.div`
  flex: 1;
  width: 100%;
`;

const RightDiv = styled.div`
  flex: 1;
  width: 100%;
  @media (min-width: 1224px) {
    flex: 1;
    width: 100%;
  }
`;

const CertificationsContainer = styled.div``;

const initialState = {
  cert_name: '',
  cert_authority: '',
  license_number: '',
  cert_url: '',
  start_month: '',
  start_year: '',
  end_month: '',
  end_year: '',
  cert_not_expire: false,
};

const years = rangeOfFullYears('1950');

const Certifications = (props) => {
  const dispatch = useDispatch();
  const { certificates, isNewCertificate } = useSelector((state) => state.userProfile);

  const [fields, setFields] = useState(initialState);
  const [certificateId, setCertificateId] = useState(null);
  const [errorFields, setErrorFields] = useState({});
  const [editMode, setEditMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  // useEffect(() => {
  //     dispatch(getCertificate())
  // }, [])

  useEffect(() => {
    if (isNewCertificate) {
      setCertificateId(null);
      dispatch(getCertificate());
      setEditMode(false);
    }
    // eslint-disable-next-line
  }, [isNewCertificate]);

  const editCertificate = (id) => {
    setCertificateId(id);
    setEditMode(true);
    const currentForm = certificates.results.find((exp) => id === exp.id);
    setFields(currentForm);
    setErrorFields({});
  };

  const handleDeleteCertificate = (id) => {
    dispatch(deleteCertificate(id));
  };

  const onCancelCertificate = () => {
    setFields(initialState);
    setCertificateId(null);
    setEditMode(false);
  };

  const saveCertificates = (id) => {
    const payload = {};

    // function isUrlValid(userInput) {
    //   var res = userInput.match(/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    //   if (res == null) return false;
    //   else return true;
    // }

    const errorObject = {};
    if (!fields.cert_name) {
      errorObject.cert_name = 'Please enter a certificate name';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }

    if (fields.cert_name) payload.cert_name = fields.cert_name;
    if (fields.cert_authority) payload.cert_authority = fields.cert_authority;
    if (fields.license_number) payload.license_number = fields.license_number;
    if (fields.cert_url) payload.cert_url = fields.cert_url;
    if (fields.start_month) payload.start_month = fields.start_month;
    if (fields.start_year) payload.start_year = fields.start_year;
    if (fields.end_month) payload.end_month = fields.end_month;
    if (fields.end_year) payload.end_year = fields.end_year;
    if (fields.cert_not_expire) payload.cert_not_expire = fields.cert_not_expire;

    if (id) {
      dispatch(updateCertificate({ ...payload, id }));
    } else {
      dispatch(addCertificate(payload));
    }
  };

  const addNewCertificate = () => {
    setFields(initialState);
    setCertificateId(null);
    setEditMode(true);
  };

  if (!editMode) {
    return (
      <>
        <CertificationsHeader>
          <AddCircle />
          <BorderLessButton onClick={addNewCertificate}>Add Certifications and Awards</BorderLessButton>
        </CertificationsHeader>
        <CertificationsContainer>
          {certificates &&
            certificates?.results?.map((certificate, id) => (
              <div
                className="mb-4 pb-3"
                key={id}
                style={{ borderBottom: certificates?.results?.length === id + 1 ? '' : '1px solid #d9d9d9' }}
              >
                <div className="d-block d-lg-flex align-items-center justify-content-between">
                  <CertificationName>{certificate.cert_name}</CertificationName>

                  <div className="d-flex py-2 py-lg-0">
                    <BorderLessButton onClick={() => editCertificate(certificate.id)}>Edit</BorderLessButton>
                    <VerticalLine />
                    <BorderLessButton onClick={() => handleDeleteCertificate(certificate.id)}>Delete</BorderLessButton>
                  </div>
                </div>
                {certificate.start_month && certificate.start_year && (
                  <CertificationText className="mb-1">
                    <span>
                      {`${genralMonths[parseInt(certificate.start_month - 1)]?.label}`}
                      {` ${certificate.start_year}`}
                    </span>
                    {!certificate.cert_not_expire ? (
                      <>
                        {!!certificate?.end_month && !!certificate?.end_year && (
                          <span>
                            {` - ${genralMonths[parseInt(certificate.end_month - 1)]?.label}`}
                            {` ${certificate.end_year}`}
                          </span>
                        )}
                      </>
                    ) : (
                      <span>{` - Present`}</span>
                    )}
                  </CertificationText>
                )}
                <CertificationText className="mb-1">{certificate.cert_authority}</CertificationText>

                <CertificationText className="mb-1 text-grey">{certificate.license_number}</CertificationText>

                <CertificationLink className="mb-1" href={certificate.cert_url} target="_blank">
                  <span style={{ color: '#3D5A80' }}>{certificate.cert_url}</span>
                </CertificationLink>
              </div>
            ))}
        </CertificationsContainer>
      </>
    );
  }

  return (
    <>
      <p className="helper-text">Certification Name*</p>
      <Input
        type="text"
        placeholder="Certification Name*"
        value={fields.cert_name}
        name="cert_name"
        onChange={fieldsOnChange}
      />
      {errorFields.cert_name && <div className="text-danger">{errorFields.cert_name}</div>}
      <p className="helper-text">Certification Authority</p>
      <Input
        type="text"
        placeholder="Certification Authority"
        value={fields.cert_authority}
        name="cert_authority"
        onChange={fieldsOnChange}
      />
      {errorFields.cert_authority && <div className="text-danger">{errorFields.cert_authority}</div>}
      <p className="helper-text">License Number</p>
      <Input
        type="text"
        placeholder="License Number"
        value={fields.license_number}
        name="license_number"
        onChange={fieldsOnChange}
      />
      {errorFields.license_number && <div className="text-danger">{errorFields.license_number}</div>}
      <p className="helper-text">Certification URL</p>
      <Input
        type="url"
        placeholder="Certification URL"
        value={fields.cert_url}
        name="cert_url"
        onChange={fieldsOnChange}
      />
      {errorFields.cert_url && <div className="text-danger">{errorFields.cert_url}</div>}
      <p className="helper-text">Duration</p>
      <Container className='align-items-center'>
    <LeftDiv>
    <div className="d-flex gap-1" style={{ width: '100%' }}>
          <Dropdown
            id={'start_month'}
            name={'start_month'}
            options={genralMonths}
            title={'start_month'}
            handleChange={handleChange}
            selectedValue={fields.start_month}
            defaultValue={'Month'}
            containerClass="m-0"
          />
          <Dropdown
            id={'start_year'}
            name={'start_year'}
            options={years}
            title={'start_year'}
            handleChange={handleChange}
            width='70%'
            selectedValue={fields.start_year}
            defaultValue={'Year'}
            containerClass="m-0"
          />
        </div>

    </LeftDiv>
    <span className="d-none d-md-block ms-2 me-2">To</span>

    <RightDiv>
    <div className="d-flex gap-1" style={{ width: '100%' }}>
              <Dropdown
                id={'end_month'}
                name={'end_month'}
                options={genralMonths}
                title={'end_month'}
                handleChange={handleChange}
                selectedValue={fields.end_month}
                containerClass="m-0"
               
                defaultValue={'Month'}
              />
              <Dropdown
                id={'end_year'}
                name={'end_year'}
                options={years}
                title={'end_year'}
                handleChange={handleChange}
                selectedValue={fields.end_year}
                width='70%'
                containerClass="m-0"
                defaultValue={'Year'}
              />
            </div>
    </RightDiv>
  </Container>
      {/* <p className="helper-text">Duration</p>
      <div className="d-block d-md-flex align-items-center">
        <div className="d-flex gap-3" style={{ width: '100%' }}>
          <Dropdown
            id={'start_month'}
            name={'start_month'}
            options={genralMonths}
            title={'start_month'}
            handleChange={handleChange}
            selectedValue={fields.start_month}
            defaultValue={'Month'}
            containerClass="m-0"
          />
          <Dropdown
            id={'start_year'}
            name={'start_year'}
            options={years}
            title={'start_year'}
            handleChange={handleChange}
            selectedValue={fields.start_year}
            defaultValue={'Year'}
            containerClass="m-0"
          />
        </div>

        {!fields?.cert_not_expire && (
          <>
            {' '}
            <span className="d-none d-md-block ms-2 me-2">To</span>
            <div className="d-flex gap-3" style={{ width: '100%' }}>
              <Dropdown
                id={'end_month'}
                name={'end_month'}
                options={genralMonths}
                title={'end_month'}
                handleChange={handleChange}
                selectedValue={fields.end_month}
                containerClass="m-0"
                defaultValue={'Month'}
              />
              <Dropdown
                id={'end_year'}
                name={'end_year'}
                options={years}
                title={'end_year'}
                handleChange={handleChange}
                selectedValue={fields.end_year}
                containerClass="m-0"
                defaultValue={'Year'}
              />
            </div>
          </>
        )}
      </div> */}
      {errorFields.startYear && <div className="text-danger">{errorFields.startYear}</div>}
      {errorFields.endYear && <div className="text-danger">{errorFields.endYear}</div>}
      {errorFields.year && <div className="text-danger">{errorFields.year}</div>}

      <Checkbox
        id="currentRole"
        label="This certificate does not expire"
        name="cert_not_expire"
        onChange={handleCheckbox}
        checked={fields.cert_not_expire}
      />

      <div className="d-flex gap-2 justify-content-end mt-4">
        <Button className="btn btn-secondary fw-normal" onClick={onCancelCertificate}>
          Cancel
        </Button>
        <Button className="btn btn-primary fw-normal" onClick={() => saveCertificates(certificateId)}>
          {' '}
          {certificateId ? 'Update' : 'Save'}
        </Button>
      </div>
    </>
  );
};

export default Certifications;
