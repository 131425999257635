import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { VerticalTabs, Tab, TabContent } from 'components/common/Tabs/Tabs';

import NetworkList from './NetworkList';
import ConnectionCard from '../ProfileSection/ConnectionCard';
import MakeProfilePublic from '../ProfileSection/MakeProfilePublic';
import ExploreCareer from '../ProfileSection/ExploreCareer';

const NetworkContainer = styled.div``;

const NetworkText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
`;

const NetworkSection = (props) => {
  const { slug, tid } = useParams();
  const [openTab, setOpenTab] = useState(tid || 'circle');

  return (
    <NetworkContainer className="row gap-4 gap-md-0">
      <div className="col-12 col-md-8 col-xl-9 border-end">
       
          <div className="row">
            <div className="col-12 col-md-12 col-lg-3 col-xl-3 d-lg-block">
            <h5 className="text-capitalize fw-bold">Network</h5>
              <VerticalTabs>
                <Tab
                  activeStyle={{
                    background: '#E4E6EB',
                  }}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                  path={slug || 'profile/network'}
                  to="circle"
                >
                  <NetworkText>myCircle</NetworkText>
                </Tab>
                <Tab
                  activeStyle={{
                    background: '#E4E6EB',
                  }}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                  path={slug || 'profile/network'}
                  to="follower"
                >
                  <NetworkText>myFollowers</NetworkText>
                </Tab>
                <Tab
                  activeStyle={{
                    background: '#E4E6EB',
                  }}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                  path={slug || 'profile/network'}
                  to="following"
                >
                  <NetworkText>myFollowing</NetworkText>
                </Tab>
              </VerticalTabs>
            </div>
            <div className="col-12 col-md col-lg-9 col-xl-9 border-start">
              <TabContent tab="circle" openTab={openTab}>
                <NetworkList type="network" />
              </TabContent>
              <TabContent tab="follower" openTab={openTab}>
                <NetworkList type="follower" />
              </TabContent>
              <TabContent tab="following" openTab={openTab}>
                <NetworkList type="following" />
              </TabContent>
            </div>
          </div>
      </div>

      <div className="col-12 col-md-4 col-xl-3">
        <div className="row gap-2">
          <div className="col-12">
            <ConnectionCard />
          </div>

          <div className="col-12">
            <MakeProfilePublic />
          </div>

          <div className="col-12">
            <ExploreCareer />
          </div>
        </div>
      </div>
    </NetworkContainer>
  );
};

export default NetworkSection;
