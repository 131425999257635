import React, { useState, useRef, useEffect } from 'react';
import { ChatIcon, EventIcon, Search, NewGroup } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import './style.scss';
import CreateDirectMeeting from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Messages/CometChatMessages/CreateDirectMeeting';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { startDefaultMeeting, joinAMeeting, setShowChat, clearShowMeet } from 'store/slices/meetingSlice';
import { useLocation } from 'react-router-dom';
const MeetingInit = (props) => {

  const { joinedMeetingData, isJoinAMeeting, showMeetBoxes } = useSelector((state) => state.meeting);

  const [show, setShow] = useState(false);
  const ref = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  useOnClickOutside(ref, () => setShow(false));
  // const [showNewChat, setShowNewChat] = useState(false);
  const [showNewMeeting, setShowNewMeeting] = useState(false);
  const [counter, setCounter] = useState(1);
  const [minimized, setMinimized] = useState(false);
  const [isInMeeting, setIsInMeeting] = useState(false);
  const [userChatArray, setUserChatArray] = useState([]);
  const [groupChatArray, setGroupChatArray] = useState([]);
  const { userConversationId } = useSelector((state) => state.connection);
  const { defaultMeetingOpen } = useSelector((state) => state.meeting);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const checkPage = location.pathname.includes('/create/company');
  useEffect(() => {
    if (Object.keys(joinedMeetingData)?.length) {
      setShowNewMeeting(true);
    }
  }, [joinedMeetingData]);


  useEffect(() => {
    if (!showMeetBoxes) {
      setShowNewMeeting(false);
      dispatch(clearShowMeet());
    }
  }, [showMeetBoxes]);

  useEffect(() => {
    if (isJoinAMeeting) {
      console.log("Is Join A Meetimng executing.");
      setShowNewMeeting(true);
    }
  }, [isJoinAMeeting]);

  useEffect(() => {
    if (userConversationId !== null) {
      let type = 'user';
      setMinimized(false);
      setShowNewMeeting(false);
      if (type === 'user') {
        const usersArr = [...userChatArray];
        if (!usersArr.includes(userConversationId.user_uuid)) {
          setUserChatArray([userConversationId.user_uuid, ...userChatArray]);
          setCounter((prevState) => prevState + 1);
        }
      } else if (type === 'group') {
        const groupArr = [...groupChatArray];
        if (!groupArr.includes(userConversationId.guid)) {
          setGroupChatArray([userConversationId.guid, ...groupChatArray]);
          setCounter((prevState) => prevState + 1);
        }
      }
      setShow(false);
    }
  }, [userConversationId]);

  useEffect(() => {
    if (defaultMeetingOpen === true) {
      setShowNewMeeting(true);
      dispatch(startDefaultMeeting(false));
    }
  }, [defaultMeetingOpen]);

  const handleNewMeetingShow = () => {
    setShowNewMeeting(true);
    setShow(false);
    dispatch(setShowChat(false));
  };

  const closeMeeting = () => {
    setShowNewMeeting(false);
    dispatch(startDefaultMeeting(false));
    dispatch(joinAMeeting(false));
  };

  return (
    <div ref={ref}>
      {!checkPage && userProfileInfo.is_active && 
      <div className="chat-nav new-meeting" title="New meeting" onClick={() => handleNewMeetingShow()}>
        <EventIcon className="default" />
      </div>}

      <div className="chats__main__wrapper new-meeting">
        {showNewMeeting && (
          <div className={classNames('meeting__main', [isInMeeting ? 'meetingOn' : ''])}>
            <CreateDirectMeeting
              inMeeting={isInMeeting}
              onClose={closeMeeting}
              joinedMeetingData={joinedMeetingData}
              isJoinAMeeting={isJoinAMeeting}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingInit;
