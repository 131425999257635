import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const SwitchWrapper = styled.div`
  border: 1px solid #dddddd;
  border-radius: 32px;
  padding: 1px; /* <!-- Add padding */
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 32px;
  border-color: #b3b3b3;
  background-color: #ffffff;
  padding: 1px;
`;

const Switch = styled.div`
  position: relative; /* <-- Add relative positioning */
  width: ${(props) => props.switchWidth};
  height: ${(props) => props.switchHeight};
  background: #b3b3b3;
  border-radius: 32px;
  padding: 1px; /* <!-- Add padding */

  /* Add pseudo element */
  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: ${(props) => props.circleWidth};
    height: ${(props) => props.circleHeight};
    border-radius: 35px;
    top: 50%;
    background: ${(props) => (props.checked ? '#3D5A80' : '#ffffff')};
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + ${Switch} {
    background: #e7f3ff;

    &:before {
      transform: translate(25px, -50%);
      right: 24px;
    }
  }
`;

const sizeParams = {
  small: {
    switchWidth: '35px',
    switchHeight: '19px',
    circleWidth: '19px',
    circleHeight: '19px',
  },
  medium: {
    switchWidth: '50px',
    switchHeight: '24px',
    circleWidth: '19px',
    circleHeight: '19px',
  },
  large: {
    switchWidth: '60px',
    switchHeight: '32px',
    circleWidth: '28px',
    circleHeight: '28px',
  },
};

const ToggleSwitch = ({ name, value, onChange, size = 'medium' }) => {
  const [checked, setChecked] = useState();

  useEffect(() => setChecked(value), [value]);

  const sizeProps = sizeParams[size];

  const handleChange = (e) => {
    setChecked(e.target.checked);
    onChange && onChange(e.target.checked);
  };

  return (
    <SwitchWrapper>
      <Label htmlFor={name}>
        <Input
          id={name}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          value={value}
          // defaultValue={defaultValue}
        />
        <Switch checked={checked} {...sizeProps} />
      </Label>
    </SwitchWrapper>
  );
};

export default ToggleSwitch;
