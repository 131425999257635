import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'flex-start'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  flex: ${(props) => props.flex ?? '1'};
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height || 'auto'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.padding || '0'};
  background: ${(props) => props.background || 'transparent'};
  border: ${(props) => (props.border ? '1px solid red' : 'none')};
  border-radius: ${(props) => props.radius || '0'};
  box-shadow: ${(props) => props.shadow || 'none'};
  overflow: ${(props) => props.overflow || 'visible'};
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top || 'auto'};
  left: ${(props) => props.left || 'auto'};
  right: ${(props) => props.right || 'auto'};
  bottom: ${(props) => props.bottom || 'auto'};
  z-index: ${(props) => props.zIndex || 'auto'};
  font-size:${(props) => props.fontSize || '15px'};
  gap:${(props) => props.gap || '0'};
`;

export default Flex;
