import React from 'react';

const Card = ({ children, className, onClick, ...props }) => {
  return (
    <div className={`primary-card ${className ? className : ''}`} onClick={onClick} {...props}>
      {children}
    </div>
  );
};

export default Card;
