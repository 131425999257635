import React, { useEffect } from 'react';
import SearchBar from 'components/common/SearchBar';
import FilterSectionTeams from 'components/common/FilterSectionTeams';
import PendingsRequestsCard from './Components/PendingsRequests';
import { organisers } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearUsersUpdated, getBlockedMemberList } from 'store/slices/teamCommunitySlice';
import noContentImage from 'assets/images/emptyBoxImage.png';
const BlockedMembers = () => {
  const { blockedMembers, teamId, usersUpdated } = useSelector((state) => state.teamsCommunity);
  const dispatch = useDispatch();
  useEffect(() => {
    if (teamId && usersUpdated) {
      dispatch(getBlockedMemberList({ for_team_community_id: teamId, list_type: "blocked" }));
      dispatch(clearUsersUpdated())
    }
  }, [teamId, usersUpdated]);

  const filters = [
    { label: 'Locations', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Current company', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Industry', type: '', data: null, showInput: false, showKeywords: true },
    { label: 'School', type: '', data: null, showInput: false, showKeywords: true },
  ];
  return (
    <React.Fragment>
      <div className="membersContainer">
        <div className="membersContainer--Header">
          <div className="headerLeft">
            <h6>
              Blocked &bull; <span>{blockedMembers?.length} person</span>
            </h6>
          </div>
          <div className="headerRight">
            <SearchBar />
          </div>
        </div>
        <div className="membersContainer--Filters">
          <FilterSectionTeams filters={filters} />
        </div>
        <div className="membersContainer--CardList">
          {blockedMembers.length > 0 ? blockedMembers.map((user, i) => (
            <PendingsRequestsCard type="blocked" user={user.user} key={i} />
          )) : <div className="empty-list-box">
            <img src={noContentImage} alt='' title='' />
            <p class="text-center mt-3"><b>No Blocked Members</b><br />Once you block a member from a Team, they will be removed from the Team and will no longer be able to request to join.</p>
          </div>}

        </div>
      </div>
    </React.Fragment>
  )
}

export default BlockedMembers