import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem, locations } from 'store/slices/searchSlice';
import EventPost from 'pages/Dashboard/Feed/Event';
import SectionLoader from 'components/common/Loader/sectionLoader';
import FilterSection from 'components/common/FilterSection';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import DefaultSearchPage from '../DefaultSearchPage';

const SearchEvent = () => {
  const dispatch = useDispatch();
  const { item, isItemsLoading, locationList } = useSelector((state) => state.search);
  const results = item.results;
  const { query } = useContext(SearchContext);
  const [filterLocations, setFilterLocations] = useState(null);
  const [updatedFilters, setUpdatedFilters] = useState();

  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'events' }));
    dispatch(locations());
  }, []);

  useEffect(() => {
    if (updatedFilters) {
      dispatch(searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'events' }));
    }
  }, [updatedFilters]);

  useEffect(() => {
    if (locationList.length > 0) setFilterLocations(locationList);
  }, [locationList]);

  const postedByFilterData = [
    { label: '1st connections', name: '1st_degree', value: '1st_degree' },
    { label: 'Me', name: 'me', value: 'me' },
    { label: 'People you follow', name: 'people_you_follow', value: 'people_you_follow' },
  ];

  const datePostedByFilterData = [
    { label: 'Past 24 hours', name: 'date_posted', value: 'past_24_hours' },
    { label: 'Past week', name: 'date_posted', value: 'past_week' },
    { label: 'Past month', name: 'date_posted', value: 'past_month' },
  ];

  const locationFilterData = [...new Set(results?.map((item) => item.location))].map((item) => {
    if (item !== '') return { label: item, name: item, value: item };
  });

  const filters = [
    { label: 'Posted by', type: 'checkbox', data: postedByFilterData },
    { label: 'Date posted', type: 'radio', data: datePostedByFilterData },
    { label: 'Location', type: 'checkbox', data: filterLocations || locationFilterData, showInput: true },
  ];

  const events =
    results?.length > 0 ? (
      results?.map((item) => {
        return (
          <div className="d-flex justify-content-center">
            <EventPost data={item} key={item.id} width={'75%'} />;
          </div>
        );
      })
    ) : (
      <DefaultSearchPage />
    );

  const filterData = (collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  };

  return (
    <React.Fragment>
      <FilterSection filters={filters} filterData={filterData} />
      <h5 className="mb-4">Events</h5>
      {isItemsLoading ? <SectionLoader /> : <>{events}</>}
    </React.Fragment>
  );
};

export default SearchEvent;
