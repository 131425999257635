import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import { Spinner } from 'components/common/Loader';
import { sendGroupPost } from 'pages/Teams/Common';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { approveGroupPost } from 'store/slices/teamCommunitySlice';
import { timeSince } from 'utils';

const PostCards = (props) => {
    const { user, posts, isOrganizer } = props;
    const { loading, teamSlugPage, teamMembers } = useSelector((state) => state.teamsCommunity);
    const [pending, setPending] = useState(false)
    const dispatch = useDispatch();

    const userName = `${user?.first_name} ${user?.last_name}`;
    const handleAccept = (e) => {
        setPending(true);
        const body = { id: posts.id, "status": e }
        dispatch(approveGroupPost(body)).then(() => {
            setPending(false)
            const messageType = posts?.message_category === "Text" ? "Text"
                : (posts?.message_category === "Media" && posts?.message?.length) ? "Gif"
                    : posts?.message_category === "Media" ? "Image" : "";
            sendGroupPost({
                receiverID: "teams_" + teamSlugPage?.slug,
                messageType,
                text: posts?.message,
                gif: { url: posts?.message, name: posts?.name ?? "" },
                image: posts?.file,
                senderId: posts?.uid
            })
        }).catch(() => {
            setPending(false)
        });
    }

    const createdSince = useMemo(() => timeSince(new Date(posts?.created_at)), [posts])

    return (
        <>
            <div className='network-list-card'>
                <div className='network-list-card--inner'>
                    <div className='network-list-card--profile'>
                        <MyBridgeAvatar size={60} name={userName} icon={user?.profile_pic} />
                    </div>
                    <div className='network-list-card--details'>
                        <h5>{userName}</h5>
                        <p>{user?.position}</p>
                        {posts.message_category === 'Image' ? <div className="network-list-card--imageBox"><img src={posts.file} width={200} height={200} /></div> : ""}
                        {posts.message_category === 'Video' ? <div className="network-list-card--imageBox"><video loop src={posts.message} autoPlay width={200} height={200} /></div> : ""}
                        {(posts.message_category === 'Text' || posts.message_category === null) ? <div className="network-list-card--messageBox">{posts.message}</div> : ""}
                        <div className='network-list-card--messageBoxTimer'>{createdSince?.length ? `${createdSince} ago` : ""}</div>
                    </div>

                </div>
                <div className='network-list-card--bottomActions'>
                    {isOrganizer && <><Button className='btn-secondary btn-sm' onClick={() => handleAccept('declined')}>{pending ? <Spinner /> : "Decline"}</Button>
                        <Button className="btn-sm" onClick={() => handleAccept('accept')}>{(pending) ? <Spinner /> : "Accept"}</Button></>}

                </div>
            </div>

        </>
    )
}

export default PostCards