import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Move, Copy, Download, Bin, Rename, Share, Create, BinOpen, Upload } from 'components/common/Icons';
import { saveAs } from 'file-saver';
import { cleanDownloadedFile, downloadFile, finishFileDownload } from 'store/actions/myDisk.Action';
import { CircularProgress } from 'components/common/Progress';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { hasAccessLevel, isChildFileOrDir, isRootDir } from './common/utils';

const FolderMenu = (props) => {
  const { openFolderItem, selectedFile } = useSelector((state) => state.myDiskReducer);
  const {
    downloadedPercent,
    downloading,
    onDownload,
    onStop
  } = useDownloadFile()
  const { activeSubTab, checkForAccessLevel, item } = props;

  const setPopupTypeNCloseDropdown = (type) => {
    props.handlePopups(type);
    props.onCloseMenu();
  };

  const hasFullAccess = (item) => {
    return checkForAccessLevel ? hasAccessLevel(item, 1) : true
  }

  if (!item || !item.id) return <></>

  return (
    hasFullAccess(item) ?
      <div className="cta-wrapper">
        <>
          {isChildFileOrDir(item) ? (
            <div className="file-options">
              <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Move')}>
                <Move />
                Move
              </div>

              <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Copy')}>
                <Copy />
                Copy
              </div>

              {item?.type === "F" ? <>
                {downloading ?
                  <div onClick={onStop}>
                    <CircularProgress
                      style={{ width: "38px", height: "38px", fontSize: "0.5rem" }}
                      done={downloadedPercent}
                      canStop
                    />
                  </div>
                  : <div className="cta" onClick={onDownload}>
                    <Download />
                    Download
                  </div>
                }</> : ""}

              <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Bin')}>
                <Bin />
                Delete
              </div>
              <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Rename')}>
                <Rename />
                Rename
              </div>
            </div>
          ) : null}

          <div className="folder-options">
            <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Share')}>
              <Share />
              Share
            </div>
            <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Create')}>
              <Create />
              Create Folder
            </div>
            <div className="cta" onClick={() => setPopupTypeNCloseDropdown('BinOpen')}>
              <BinOpen />
              Deleted Files
            </div>
            <div className="cta" onClick={() => props.onUploadFilee('Upload')}>
              <Upload />
              Upload Files
            </div>
          </div>
        </>
      </div> :
      <div className="cta-wrapper">
        <>
          {(
            <div className="file-options-view-access">
              <div className="cta" onClick={() => setPopupTypeNCloseDropdown('Copy')}>
                <Copy />
                Copy
              </div>
              {item?.type === "F" ? <>
                {downloading ?
                  <div onClick={onStop}>
                    <CircularProgress
                      style={{ width: "38px", height: "38px", fontSize: "0.5rem" }}
                      done={downloadedPercent}
                      canStop
                    />
                  </div>
                  : <div className="cta" onClick={onDownload}>
                    <Download />
                    Download
                  </div>
                }</> : ""}
            </div>
          )}

        </>
      </div>
  );
};

export default FolderMenu;
