import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'components/Layouts';
import { TabContent } from 'components/common/Tabs/Tabs';

import Banner from './Banner';
import CompanyInfo from './CompanyInfo';
import Overview from './Overview';
import Posts from './Posts';
import Jobs from './Jobs';
import { fetchCompanyPosts } from 'store/slices/postSlice';
import { getCompanyPublicData } from 'store/actions/companyPages.Action';

const PublicProfileCompany = () => {
  const { slug, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openTab, setOpenTab] = useState(id || 'overview');
  const { companyPublicData } = useSelector((state) => state.companyPageReducer);
  const { data } = useSelector((state) => state.post);

  const [companyDetails, setCompanyDetails] = useState();
  const [postData, setPostData] = useState();

  useEffect(() => {
    if (!slug) {
      navigate('/404');
    }
    dispatch(getCompanyPublicData(slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (companyPublicData) {
      setCompanyDetails(companyPublicData);
      const body = { page: 1, size: 2, companyId: companyPublicData.id };
      dispatch(fetchCompanyPosts(body));
    }
  }, [companyPublicData]);

  useEffect(() => {
    if (data) {
      setPostData(data);
    }
  }, [data]);

  return (
    <React.Fragment>
      <section className="container my-4">
        <div className="row">
          <div className="col-lg-9">
            <Banner openTab={openTab} setOpenTab={setOpenTab} companyDetails={companyDetails} />
            <section className="Profile_Tab">
              <div className="container g-0">
                <div>
                  <TabContent tab="overview" openTab={id}>
                    <Overview companyDetails={companyDetails} postData={postData} setOpenTab={setOpenTab} />
                  </TabContent>
                  <TabContent tab="posts" openTab={id}>
                    <Posts companyDetails={companyDetails} />
                  </TabContent>
                  <TabContent tab="jobs" openTab={id}>
                    <Jobs companyDetails={companyDetails} />
                  </TabContent>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-3">
            <CompanyInfo companyDetails={companyDetails} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PublicProfileCompany;
