import React, { useEffect, useState, useRef } from 'react';
import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionsData } from 'store/slices/connectionSlice';
import SectionLoader from 'components/common/Loader/sectionLoader';
import Checkbox from 'components/common/Checkbox';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { clearUserInvited, inviteUsersToTeam, inviteUsersToFollowCompany } from 'store/slices/teamCommunitySlice';
import { confirmAlert } from 'react-confirm-alert';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { InfiniteList } from 'components/InfiniteList';

const InviteFriends = (props) => {
  const { onClose, teamId, type } = props;
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { data, loading } = useSelector((state) => state.connection);
  const { usersInvited } = useSelector((state) => state.teamsCommunity);
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [inviteUsers, setInviteUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [mounted, setMounted] = useState(false);
  const ref = React.useRef();
  useOnClickOutside(ref, () => onClose(false));

  useEffect(() => {
    if (usersInvited) {
      setTimeout(() => {
        onClose?.();
        dispatch(clearUserInvited());
      }, 500);
    }
  }, [usersInvited]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    const { next, results } = data ?? {};
    let dataSet = results ?? [];
    if (!results) {
      setPage((prev) => prev - 1);
    }
    if (page > 1) {
      dataSet = [...(userList ?? []), ...(results ?? [])];
    }
    if (type === 'company') {
      dataSet = dataSet.filter(
        (item) =>
          item.email.indexOf(searchQuery) !== -1 ||
          item.first_name.indexOf(searchQuery) !== -1 ||
          item.last_name.indexOf(searchQuery) !== -1,
      );
      setUserList(dataSet);
    }
    setUserList(dataSet);
  }, [data?.results, mounted]);

  useEffect(() => {
    if (page > 1) dispatch(getConnectionsData({ page }));
  }, [page, searchQuery]);

  useEffect(() => {
    dispatch(getConnectionsData({ page }));
  }, [searchQuery]);

  const toggleUserForInvite = (id) => {
    if (isUserSelected(id)) {
      const inviteUsers_ = inviteUsers.filter((id_) => id_ !== id);
      setInviteUsers(inviteUsers_);
    } else {
      inviteUsers.push(id);
      setInviteUsers([...inviteUsers]);
    }
  };

  const isUserSelected = (id) => {
    return inviteUsers?.find((id_) => id === id_);
  };

  const toggleAllUsersForInvite = () => {
    if (inviteUsers?.length) {
      setInviteUsers([]);
    } else {
      setInviteUsers(userList?.map((u) => u?.id));
    }
  };

  const sendInvites = () => {
    let body = {
      for_team_community_id: teamId,
      invite_user_ids: inviteUsers,
    };
    if (type === 'company') {
      body = {
        company_id: teamId,
        users: inviteUsers,
      };
      dispatch(inviteUsersToFollowCompany(body));
    } else {
      dispatch(inviteUsersToTeam(body));
    }
  };

  const fetch = async () => {
    setPage(1);
    return dispatch(getConnectionsData());
  };
  const fetchMore = async () => {
    setPage((prev) => prev + 1);
  };

  return (
    <Modal
      onClose={() => onClose(false)}
      className="confirmation-modal"
      heading={<h3>Invite connections</h3>}
      action={
        <>
          <Button variant="btn-secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button variant="btn-primary" onClick={sendInvites}>
            Invite
          </Button>
        </>
      }
    >
      <div className="inviteFriendsPopup">
        <>
          <div className="searchFriends">
            <Input placeholder="Search in All friends" onChange={(event) => setSearchQuery(event.target.value)} />
          </div>
          <div className="inviteSelector">
            <div>
              <Checkbox
                checked={inviteUsers?.length === userList?.length}
                onChange={toggleAllUsersForInvite}
                className="my-0"
                label={`Select All (${userList?.length})`}
              />
            </div>

            <span>Selected ({inviteUsers?.length})</span>
          </div>
          <div id="INVITE_CONTAINER" ref={listInnerRef} className="inviteContainer">
            <InfiniteList
              data={userList}
              fetch={fetch}
              fetchMore={fetchMore}
              loading={loading}
              parent={listInnerRef.current}
              render={({ item: user, index }) => (
                <div key={index} className="inviteUserContainer">
                  <div className="inviteUserContainer--inner">
                    <div className="inviteUserContainer--action">
                      <Checkbox
                        checked={isUserSelected(user?.id)}
                        onChange={() => toggleUserForInvite(user.id)}
                        label={<>&nbsp;</>}
                      />
                    </div>
                    <div className="inviteUserContainer--Details">
                      <div className="inviteUserContainer--Profile">
                        <MyBridgeAvatar
                          size={40}
                          icon={user.profile_pic}
                          name={`${user.first_name} ${user.last_name}`}
                        />
                      </div>
                      <div className="inviteUserContainer--innerDetails">
                        <h5>
                          {user.first_name} {user.last_name}
                        </h5>
                        <p>{user.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            {loading && page <= 1 ? <SectionLoader /> : <></>}
            {/* {userList &&
                userList.map((user, i) => (
                  <div className="inviteUserContainer" key={i}>
                    <div className="inviteUserContainer--inner">
                      <div className="inviteUserContainer--action">
                        <Checkbox onChange={() => markUserSelected(user.id)} />
                      </div>
                      <div className="inviteUserContainer--Details">
                        <div className="inviteUserContainer--Profile">
                          <MyBridgeAvatar
                            size={40}
                            icon={user.profile_pic}
                            name={`${user.first_name} ${user.last_name}`}
                          />
                        </div>
                        <div className="inviteUserContainer--innerDetails">
                          <h5>
                            {user.first_name} {user.last_name}
                          </h5>
                          <p>{user.position}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
          </div>
        </>
      </div>
    </Modal>
  );
};

export default InviteFriends;
