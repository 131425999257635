import Button from 'components/common/Button'
import { Input } from 'components/common/Input'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from 'store/slices/authSlice';

export default function ForgotPassword() {

    const dispatch = useDispatch();
    const { loading, error, forgetPasswordMsg, } = useSelector((state) => state.user)

    const [signUpEmail, setSignUpEmail] = useState('');
    const [emailError, setEmailError] = useState({})
    const [receivedEmail, setReceivedEmail] = useState(false)

    const [showMsg, setShowMsg] = useState(false);


    const forgotPasswordChange = (e) => {
        setSignUpEmail(e.target.value)
    }

    const handleResetPassword = () => {
        if (!signUpEmail) {
            setEmailError({ required: true })
            return;
        }
        dispatch(forgetPassword({ email: signUpEmail }))
        setShowMsg(true);
    }

    useEffect(() => {
        forgetPasswordMsg && !error ? setReceivedEmail(true) : setEmailError({ error: true })
    }, [forgetPasswordMsg])


    return (
        <div className="user-flow--form">
            <h2 className="user-flow--formHeader">Forgot password?</h2>
            <p className="user-flow--formSubHeader">Reset your password in two easy steps.</p>
            {emailError.required && <p className="text-danger helper-text my-3">Email is required</p>}

            {showMsg &&
                <div>
                    {receivedEmail ? <p className="text-success helper-text my-3">{forgetPasswordMsg}</p> :
                        <p className="text-danger helper-text my-3">{forgetPasswordMsg}</p>}
                </div>
            }


            <Input
                type="email"
                name="signUpEmail"
                value={signUpEmail}
                onChange={forgotPasswordChange}
                placeholder="Email"
                className={emailError.required ? 'error' : ''}
                label="Email"
            />

            <Button className='full-width' onClick={handleResetPassword}>
                Reset password
            </Button>

            <p className='back-to-page mt-3'>
                <a className='text-btn' href='/signin'>
                    Back
                </a>
            </p>
        </div>
    )
}
