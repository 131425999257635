import { CometChat } from '@cometchat-pro/chat';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CourierProvider } from '@trycourier/react-provider';
import { Toast } from '@trycourier/react-toast';
import Layout from 'components/Layouts';
import MeetingInit from 'components/Meeting/MeetingInit';
import PostModal from 'components/Modals/PostModal';
import PrivateRoute from 'components/Routing/PrivateRoute';
import SearchContext from 'context/searchContext';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import ChatUi from 'pages/Chat';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './context/authcontext';
import routes from './routes';

import NotFound from 'pages/NotFound';

import ErrorBoundary from 'components/ErrorBoundary';
import { setMobileDevice, setTabletDevice } from 'store/actions/layout.Action';
import { addToken } from 'store/slices/authSlice';
import { getUpcomingEvents } from 'store/slices/calendarSlice';
import { getAllConnectionIDs, getConnectionsData } from 'store/slices/connectionSlice';
import { getUserProfileInfo } from 'store/slices/userProfileSlice';
import Error404 from './pages/Error404';

const stripePromise = loadStripe(`${process.env.STRIPE_API}`);

function App() {
  const dispatch = useDispatch();
  const storageAccess = localStorage.getItem('access');
  const refreshAccess = localStorage.getItem('refresh');
  const { loginCometchat } = React.useContext(AuthContext);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { allConnectionIDs } = useSelector((state) => state.connection);
  const { flag } = useSelector((state) => state.post);
  const { access } = useSelector((state) => state.user);
  const { commentFlag } = useSelector((state) => state.comment);
  const uid = userProfileInfo?.user_uuid;
  const uName = userProfileInfo?.first_name + ' ' + userProfileInfo?.last_name;
  const userSlug = userProfileInfo?.slug;
  const userImage = userProfileInfo?.profile_pic;
  const COURIER_CLIENT_KEY = 'MzQzZDQ5ZGUtYzI0OC00MTdjLWFjNDctMGYyY2VhOTM3OTZi';
  const COURIER_USER_ID = `user_${userProfileInfo.id}`;
  const [searchQuery, setSearchQuery] = useState('');

  const appID = '2276002860111f2a';
  const region = 'us';

  const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();

  useEffect(() => {
    if (storageAccess) {
      const token = jwtDecode(storageAccess);
      const exp = token.exp;
      const expirationTime = exp * 1000 - 600000;
      if (Date.now() >= expirationTime) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        window.location.assign('/signin');
        return false;
      } else {
        dispatch(getUserProfileInfo());
        const value = new Date();
        dispatch(
          getUpcomingEvents({
            date: `${moment(value).date()}-${moment(value).month() + 1}-${moment(value).year()}`,
          }),
        );
        dispatch(getConnectionsData());
      }
      // dispatch(fetchPosts());
    }
    // eslint-disable-next-line
  }, [storageAccess]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', () => {
      onResize();
    });
    // eslint-disable-next-line
  }, []);

  const onResize = () => {
    if (window.innerWidth <= 767) {
      dispatch(setMobileDevice(true));
      dispatch(setTabletDevice(false));
    } else if (window.innerWidth > 767 && window.innerWidth < 1025) {
      dispatch(setMobileDevice(false));
      dispatch(setTabletDevice(true));
    } else {
      dispatch(setMobileDevice(false));
      dispatch(setTabletDevice(false));
    }
  };

  // usxeEffect(() => {
  //   if (!flag || !commentFlag) {
  //     dispatch(fetchUpdatedPosts());
  //   }
  // }, [flag, commentFlag]);

  // React.useEffect(() => {
  //   if (access) {
  //     const value = new Date();
  //     dispatch(
  //       getUpcomingEvents({
  //         date: `${moment(value).date()}-${moment(value).month() + 1}-${moment(value).year()}`,
  //       }),
  //     );
  //   }
  // }, [access]);

  // useEffect(() => {
  //   if (access) {
  //     dispatch(getConnectionsData());
  //     dispatch(fetchPosts());
  //   }
  // }, [access]);
  useEffect(() => {
    if (access) {
      dispatch(getAllConnectionIDs());
    }
  }, [access]);

  useEffect(() => {
    dispatch(addToken(storageAccess));
    // eslint-disable-next-line
  }, [storageAccess]);

  useEffect(() => {
    // // load connections, handshakes, follows and more (heavy query, should be called only once in global scope)
    // if(userProfileInfo?.id){
    //   dispatch(getAllConnectionIDs())
    // }
    // login to comet chat
    CometChat.init(appID, appSetting).then(
      () => {
        if (CometChat.setSource) {
          CometChat.setSource('ui-kit', 'web', 'reactjs');
        }
        console.log('Initialization completed successfully');
        loginCometchat(uid, uName, userSlug, userImage);
      },
      (error) => {
        console.error('Initialization failed with error:', error);
        // Check the reason for error and take appropriate action.
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileInfo]);

  return (
    <ErrorBoundary>
      <Elements stripe={stripePromise}>
        <CourierProvider clientKey={COURIER_CLIENT_KEY} userId={COURIER_USER_ID}>
          <SearchContext.Provider value={{ query: searchQuery, setSearchQuery: setSearchQuery }}>
            <BrowserRouter>
              <Routes>
                {routes.map((props, index) => {
                  const { route, routeProps, component, privateRoute } = props;
                  const getRoute = privateRoute ? (
                    <PrivateRoute>
                      <Layout>
                        <PostModal />
                        {/* <Toast /> */}
                        <MeetingInit />
                        <ChatUi />
                        {component}
                      </Layout>
                    </PrivateRoute>
                  ) : (
                    component
                  );
                  return <Route key={index} path={route} {...routeProps} element={getRoute} />;
                })}

                <Route path="/" element={<Navigate replace to="/signin" />} />

                <Route path="/404" element={<Error404 />} />
                <Route path="/profile-not-found" element={<NotFound />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
            </BrowserRouter>
          </SearchContext.Provider>
        </CourierProvider>
      </Elements>
    </ErrorBoundary>
  );
}

export default App;
