import React, { useEffect, useState } from 'react';

const QuantityInputBox = (props) => {
  const { quantity } = props;
  const [value, setValue] = useState(1);
  const decrement = () => {
    setValue((prevState) => (prevState >= 0 ? --prevState : 0));
  };

  const increment = () => {
    setValue((prevState) => (prevState >= 0 ? ++prevState : 0));
  };
    useEffect(() => { 
        quantity(value);
    }, [value])
  return (
    <div className="quantity-input">
      <button className="quantity-input__modifier quantity-input__modifier--right" onClick={() => decrement()}>
        &mdash;
      </button>
      <input className="quantity-input__screen" type="text" value={value} readonly />
      <button className="quantity-input__modifier quantity-input__modifier--left" onClick={() => increment()}>
        &#xff0b;
      </button>
    </div>
  );
};

export default QuantityInputBox;
