import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints, UserAPIEndpoints } from './../common/endPoint';
const initialState = {
  msg: '',
  loading: false,
  error: false,
  experience: {},
  isNewExperience: false,
  userProfileInfo: {},
  isProfileUpdated: false,
  isNewPersonDetail: false,
  isNewMilestone: false,
  isNewCertificate: false,
  isNewEducation: false,
  milestones: {},
  certificates: {},
  educations: {},
  searchResults: [],
  initialInfo: false,
  isProfileResult: false,
  userSlug: undefined,
  publicProfileData: {},
  industriesList: [],
  addedSkills: undefined,
  profileSkillsList: [],
  analyticsDetail: [],
  endorsedSkill: undefined,
  connectionDegreeCount: {},
  profileViewers: [],
  
};

export const addExperience = createAsyncThunk('addExperience', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileExperience}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateExperience = createAsyncThunk('updateExperience', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileExperience}/${body.id}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const getExperience = createAsyncThunk('getExperience', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileExperience}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const getMyActivity = createAsyncThunk('getMyActivity', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileActivity}`,
    method: 'GET',
  };
  return await API(obj);
});

export const deleteExperience = createAsyncThunk('deleteExperience', async (id) => {
  const obj = {
    url: `${APIEndPoints.ProfileExperience}/${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const userProfileAddInfo = createAsyncThunk('userProfileAddInfo', async (body) => {
  const obj = {
    url: `${APIEndPoints.UserProfileInfo}/`,
    method: 'PUT',
    body: body,
    isFormData: true,
  };
  return await API(obj);
});

export const getUserProfileInfo = createAsyncThunk('getUserProfileInfo', async (body) => {
  const obj = {
    url: `${APIEndPoints.UserProfileInfo}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const addMilestone = createAsyncThunk('addMilestone', async (body) => {
  const obj = {
    url: `${APIEndPoints.Milestone}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateMilestone = createAsyncThunk('updateMilestone', async (body) => {
  const obj = {
    url: `${APIEndPoints.Milestone}/${body.id}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const getMilestone = createAsyncThunk('getMilestone', async (body) => {
  const obj = {
    url: `${APIEndPoints.Milestone}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const deleteMilestone = createAsyncThunk('deleteMilestone', async (id) => {
  const obj = {
    url: `${APIEndPoints.Milestone}/${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});
//analytics
export const getUserAnalytics = createAsyncThunk('getUserAnalytics', async (id) => {
  const obj = {
    url: `${APIEndPoints.UserAnalytics}`,
    method: 'GET',
  };
  return await API(obj);
});
// Skills
export const getProfileSkills = createAsyncThunk('getProfileSkills', async () => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}`,
    method: 'GET',
  };
  return await API(obj);
});

export const addSkill = createAsyncThunk('addSkill', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const deleteSkill = createAsyncThunk('deleteSkill', async (id) => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const endorseSkill = createAsyncThunk('endorseSkill', async (id) => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}${id}/endorse`,
    method: 'POST',
  };
  return await API(obj);
});

/* certificate */

export const addCertificate = createAsyncThunk('addCertificate', async (body) => {
  const obj = {
    url: `${APIEndPoints.Certificate}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateCertificate = createAsyncThunk('updateCertificate', async (body) => {
  const obj = {
    url: `${APIEndPoints.Certificate}/${body.id}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const getCertificate = createAsyncThunk('getCertificate', async (body) => {
  const obj = {
    url: `${APIEndPoints.Certificate}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const deleteCertificate = createAsyncThunk('deleteCertificate', async (id) => {
  const obj = {
    url: `${APIEndPoints.Certificate}/${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

/* Education */

export const getEducation = createAsyncThunk('getEducation', async () => {
  const obj = {
    url: `${APIEndPoints.Education}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const deleteEduction = createAsyncThunk('deleteEduction', async (id) => {
  const obj = {
    url: `${APIEndPoints.Education}/${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const addEducation = createAsyncThunk('addEducation', async (body) => {
  const obj = {
    url: `${APIEndPoints.Education}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateEducation = createAsyncThunk('updateEducation', async (body) => {
  const obj = {
    url: `${APIEndPoints.Education}/${body.id}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

// All Search API's
export const searchByTerm = createAsyncThunk('searchByTerm', async ({ searchBy, term }) => {
  const obj = {
    url: `${APIEndPoints[searchBy]}/?search=${term}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getIndustries = createAsyncThunk('getIndustries', async () => {
  const obj = {
    url: `${APIEndPoints.Industries}/`,
    method: 'GET',
  };
  return await API(obj);
});

// Get Slug
export const getUserSlug = createAsyncThunk('getUserSlug', async (slug) => {
  const obj = {
    url: `${APIEndPoints.Slug}?search=${slug}`,
  };
  return await API(obj);
});

/* User Details Update Settings */
export const updateUserDetails = createAsyncThunk('updateUserDetails', async (body) => {
  const obj = {
    url: `${UserAPIEndpoints.UpdateUser}`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

// Public Profile
export const getPublicProfile = createAsyncThunk('getPublicProfile', async (slug) => {
  const obj = {
    url: `${APIEndPoints.PublicProfile}?slug=${slug}`,
  };
  return await API(obj);
});

export const getProfileViewers = createAsyncThunk('getProfileViewers', async () => {
  const obj = {
    url: `${UserAPIEndpoints.GetProfileViews}`,
    method: 'GET',
  };
  return await API(obj);
});

/* User Connection APIs */
export const getUserConnectionCount = createAsyncThunk('getUserConnectionCount', async (degree) => {
  const obj = {
    url: `${UserAPIEndpoints.GetDegreeCount(degree)}`,
  };
  return await API(obj);
});

export const clear = createAction('clear');

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.access = null;
      state.refresh = null;
      // localStorage.clear();
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
    },
  },
  extraReducers: {
    [addExperience.pending]: (state, action) => {
      state.isNewExperience = false;
      state.loading = true;
    },
    [addExperience.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewExperience = true;
    },
    [addExperience.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getExperience.pending]: (state, action) => {
      state.isNewExperience = false;
      state.loading = true;
    },
    [getExperience.fulfilled]: (state, action) => {
      state.experience = action.payload;
      state.loading = false;
    },
    [getExperience.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getMyActivity.pending]: (state, action) => {
      state.isMyActivities = false;
      state.loading = true;
    },
    [getMyActivity.fulfilled]: (state, action) => {
      state.myActivities = action.payload;
      state.loading = false;
    },
    [getMyActivity.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateExperience.pending]: (state, action) => {
      state.isNewExperience = false;
      state.loading = true;
    },
    [updateExperience.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewExperience = true;
    },
    [updateExperience.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteExperience.pending]: (state, action) => {
      state.isNewExperience = false;
      state.loading = true;
    },
    [deleteExperience.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewExperience = true;
    },
    [deleteExperience.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    [userProfileAddInfo.pending]: (state, action) => {
      state.isProfileUpdated = false;
      state.loading = true;
    },
    [userProfileAddInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.isProfileUpdated = true;
      state.userProfileInfo = action.payload;
    },
    [userProfileAddInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getUserProfileInfo.pending]: (state, action) => {
      state.loading = true;
      state.isProfileResult = false;
    },
    [getUserProfileInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.userProfileInfo = action.payload;
      state.isProfileResult = true;
    },
    [getUserProfileInfo.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    /* milestones */

    [addMilestone.pending]: (state, action) => {
      state.isNewMilestone = false;
      state.loading = true;
    },
    [addMilestone.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewMilestone = true;
    },
    [addMilestone.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getMilestone.pending]: (state, action) => {
      state.isNewMilestone = false;
      state.loading = true;
    },
    [getMilestone.fulfilled]: (state, action) => {
      state.milestones = action.payload;
      state.loading = false;
    },
    [getMilestone.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateMilestone.pending]: (state, action) => {
      state.isNewMilestone = false;
      state.loading = true;
    },
    [updateMilestone.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewMilestone = true;
    },
    [updateMilestone.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteMilestone.pending]: (state, action) => {
      state.isNewMilestone = false;
      state.loading = true;
    },
    [deleteMilestone.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewMilestone = true;
    },
    [deleteMilestone.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    // analytics
    [getUserAnalytics.fulfilled]: (state, action) => {
      state.analyticsDetail = action?.payload?.results;
    },
    /* Skills */
    [getProfileSkills.fulfilled]: (state, action) => {
      state.profileSkillsList = action.payload;
    },
    [addSkill.fulfilled]: (state, action) => {
      state.addedSkills = action.payload;
    },
    [endorseSkill.fulfilled]: (state, action) => {
      state.endorsedSkill = action.payload;
    },

    /* certificate */
    [addCertificate.pending]: (state, action) => {
      state.isNewCertificate = false;
      state.loading = true;
    },
    [addCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewCertificate = true;
    },
    [addCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getCertificate.pending]: (state, action) => {
      state.isNewCertificate = false;
      state.loading = true;
    },
    [getCertificate.fulfilled]: (state, action) => {
      state.certificates = action.payload;
      state.loading = false;
    },
    [getCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateCertificate.pending]: (state, action) => {
      state.isNewCertificate = false;
      state.loading = true;
    },
    [updateCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewCertificate = true;
    },
    [updateCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteCertificate.pending]: (state, action) => {
      state.isNewCertificate = false;
      state.loading = true;
    },
    [deleteCertificate.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewCertificate = true;
    },
    [deleteCertificate.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    /* education */

    [addEducation.pending]: (state, action) => {
      state.isNewEducation = false;
      state.loading = true;
    },
    [addEducation.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewEducation = true;
    },
    [addEducation.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getEducation.pending]: (state, action) => {
      state.isNewEducation = false;
      state.loading = true;
    },
    [getEducation.fulfilled]: (state, action) => {
      state.educations = action.payload;
      state.loading = false;
    },
    [getEducation.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateEducation.pending]: (state, action) => {
      state.isNewEducation = false;
      state.loading = true;
    },
    [updateEducation.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewEducation = true;
    },
    [updateEducation.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteEduction.pending]: (state, action) => {
      state.isNewEducation = false;
      state.loading = true;
    },
    [deleteEduction.fulfilled]: (state, action) => {
      state.loading = false;
      state.isNewEducation = true;
    },
    [deleteEduction.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    /* All Search API's */
    [searchByTerm.pending]: (state, action) => {
      state.loading = true;
      state.searchResults = [];
    },
    [searchByTerm.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchResults = action?.payload?.results?.map((item) => item.name);
    },
    [searchByTerm.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getUserSlug.pending]: (state, action) => {
      state.loading = true;
      state.userSlug = [];
    },
    [getUserSlug.fulfilled]: (state, action) => {
      state.loading = false;
      state.userSlug = action.payload.results;
    },
    [getUserSlug.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    [getPublicProfile.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPublicProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.publicProfileData = action.payload;
    },
    [getPublicProfile.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getIndustries.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getIndustries.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.industriesList = action.payload.results;
    },
    [getIndustries.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [clear]: (state, action) => {
      state.userSlug = initialState.userSlug;
    },

    /* User Details Update Setting Reducers */
    [updateUserDetails.pending]: (state, action) => {
      state.updateStatus = 'pending';
      state.error = false;
      state.isProfileUpdated = false;
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.updateStatus = 'error';
        state.error = action.payload.error;
        state.isProfileUpdated = false;
      } else {
        state.updateStatus = 'success';
        state.msg = action.payload.msg;
        state.isProfileUpdated = true;
      }
    },
    [updateUserDetails.rejected]: (state, action) => {
      state.updateStatus = 'error';
      state.error = action.payload;
      state.isProfileUpdated = false;
    },

    [getProfileViewers.fulfilled]: (state, action) => {
      state.profileViewers = action.payload.results;
    },

    /* Connection Reducers */
    [getUserConnectionCount.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUserConnectionCount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.connectionDegreeCount = action.payload.results;
    },
    [getUserConnectionCount.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { logout } = userProfileSlice.actions;
export default userProfileSlice.reducer;
