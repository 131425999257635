import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import AvatarGroup from '@atlaskit/avatar-group';

import editBox from 'assets/icons/editBox.svg';
import copyIcon from 'assets/icons/copy.svg';

import { CheckGreen, CloseIcon, Email } from 'components/common/Icons';
import RadioButton from 'components/common/RadioButton';
import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import { CometChat } from '@cometchat-pro/chat';

import { clear, getUserSlug, userProfileAddInfo } from 'store/slices/userProfileSlice';

const ProfileActionContainer = styled.div``;

const ProfileActionBox = styled.div`
  padding: 0.7rem;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 4px 6px -6px rgb(217, 217, 217);
`;

const ProfileActionText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  word-break: ${(props) => props.wb?'':'break-word'};  
  font-weight: ${(props) => props.fWeight || 400};
  font-size: ${(props) => props.fSize || '16px'};
  line-height: 120%;
  color: ${(props) => props.color || '#000000'};
`;

const ProfileActionButton = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  background: ${(props) => props.color || '#e18c3e'};
  border-radius: 5px;
`;

const MakeProfilePublic = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfileInfo, profileViewers, isProfileUpdated, isProfileResult, userSlug } = useSelector(
    (state) => state.userProfile,
  );

  let slugCheckTimeout = useRef();

  const [profilePublicVisibility, setProfilePublicVisibility] = useState(false);
  const [profileUrl, setProfileUrl] = useState('');
  const [editProfileUrl, setEditProfileUrl] = useState(false);
  const [slugAvailable, setSlugAvailable] = useState('');

  useEffect(() => {
    if (isProfileUpdated) {
      setEditProfileUrl(false);
      setProfileUrl(userProfileInfo?.slug);
    }
    // eslint-disable-next-line
  }, [isProfileUpdated]);

  useEffect(() => {
    if (isProfileResult) {
      setProfilePublicVisibility(userProfileInfo?.is_visible);
      setProfileUrl(userProfileInfo?.slug);
    }
    // eslint-disable-next-line
  }, [isProfileResult]);

  useEffect(() => {
    if (userSlug?.length === 0) {
      // No users with this slug
      setSlugAvailable('available');
    } else if (userSlug?.length > 0) {
      setSlugAvailable('not_available');
    }

    setTimeout(() => {
      dispatch(clear());
    }, 6000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSlug]);

  useEffect(() => {
    if (profileUrl && profileUrl !== userProfileInfo?.slug) {
      clearTimeout(slugCheckTimeout.current);
      slugCheckTimeout.current = setTimeout(() => {
        dispatch(getUserSlug(profileUrl));
      }, 500);
    } else {
      setSlugAvailable('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUrl]);

  const radioChangeHandler = (e) => {
    const isVisible = !profilePublicVisibility;
    setProfilePublicVisibility(isVisible);
    const formData = new FormData();
    formData.append('is_visible', isVisible);
    dispatch(userProfileAddInfo(formData));
  };

  const handleProfileUrl = () => {
    const formData = new FormData();
    formData.append('slug', profileUrl);
    dispatch(userProfileAddInfo(formData)).then((res) => {
      if(res){
        console.log(res.payload.slug);
        let uid = res?.payload?.user_uuid;
        let slug = res.payload.slug;

        var user = new CometChat.User(uid);

        user.setLink(`https://bybk.org/${slug}`);

        CometChat.updateCurrentUserDetails(user).then(
          user => {
            console.log("user updated", user);
          }, error => {
            console.log("error", error);
          }
        )
      }
    });
  };

  const handleCopy = () => {
    let text = `${window.location.origin}/${profileUrl}`;
    let elem = window.document.createElement("textarea");
    window.document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    window.document.execCommand("copy");
    window.document.body.removeChild(elem);
  };

  return (
    <ProfileActionContainer>
      <ProfileActionBox>
        <ProfileActionText fWeight={600}>Profile</ProfileActionText>
      </ProfileActionBox>

      <ProfileActionBox className="p-3">
        <div className="d-flex align-items-center pb-3">
          <div className="me-2">
            <RadioButton
              changed={radioChangeHandler}
              name="profile_visible"
              value="Make my public profile visible to no one"
              isSelected={!profilePublicVisibility}
            />
          </div>
          <ProfileActionText className="text-black">Make my public profile visible to no one</ProfileActionText>
        </div>

        <div className="d-flex align-items-center pb-3">
          <div className="me-2">
            <RadioButton
              changed={radioChangeHandler}
              name="profile_visible"
              value="Make my public profile visible to everyone"
              isSelected={profilePublicVisibility}
            />
          </div>
          <ProfileActionText className="text-black">Make my public profile visible to everyone</ProfileActionText>
        </div>
      </ProfileActionBox>

      <ProfileActionBox>
        <div
          className="d-flex align-items-center gap-2 pt-1"
          role="button"
          onClick={() => setEditProfileUrl(!editProfileUrl)}
        >
          <img src={editBox} alt="edit" />
          <ProfileActionText fWeight={500} color="#3D5A80">
            Edit public profile & URL
          </ProfileActionText>
        </div>

        {editProfileUrl && (
          <div className="edit-public-profile">
            <div className="position-relative">
              <ProfileActionText fSize="14px" color="#5B5B5B" className="pt-3">
                Personalize the URL for your profile.
              </ProfileActionText>

              <div className="d-flex align-items-center justify-content-between pt-3 mb-2">
                <div>
                  <ProfileActionText fSize="14px" color="#5B5B5B" id="userUrl">
                    {window.location.origin}/{profileUrl}
                  </ProfileActionText>
                </div>
                <div onClick={handleCopy}>
                <Popup
                  trigger={
                    <img
                      src={copyIcon}
                      alt=""
                      
                    />
                  }
                 
                  position="top left"
                  closeOnDocumentClick
                  closeOnEscape
                  contentStyle={{ width: 'auto' }}
                  nested
                >
                  <div>Copied !!</div>
                </Popup>
                </div>
               
              </div>

              <div className="position-relative">
                <Input
                  className={`text-lowercase mt-2 mb-3 ${
                    slugAvailable === 'available' ? 'success' : slugAvailable === 'not_available' ? 'error' : ''
                  }`}
                  // containerClass={window.location.origin.includes('bybk.org/') ? 'slugtext pd-145' : 'slugtext pd-180'}
                  type="url"
                  // startIcon={`${window.location.origin}/`}
                  value={profileUrl}
                  name="public_profile_url"
                  onChange={(e) => setProfileUrl(e.target.value)}
                  placeholder=""
                />

                {slugAvailable === 'available' ? (
                  <span className="aval">
                    <CheckGreen />
                  </span>
                ) : slugAvailable === 'not_available' ? (
                  <span className="notaval">
                    <CloseIcon width={15} height={15} color="#FF0600" />
                  </span>
                ) : null}
              </div>
              {slugAvailable === 'available' ? (
                <span className="aval">
                  <CheckGreen />
                </span>
              ) : slugAvailable === 'not_available' ? (
                <span className="notaval">
                  <CloseIcon width={15} height={15} color="#FF0600" />
                </span>
              ) : null}
            </div>

            <div className="d-flex gap-2 justify-content-end">
              <Button onClick={() => setEditProfileUrl(false)} className="btn btn-secondary fw-normal h-36">
                Cancel
              </Button>
              <Button
                onClick={handleProfileUrl}
                className="btn btn-primary fw-normal h-36"
                disabled={slugAvailable === 'available' ? false : true}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </ProfileActionBox>

      <ProfileActionBox className="p-3">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-start">
          <ProfileActionText
              wb={true}
              className="cursor-pointer"
              fWeight="500"
              fSize="15px"
              color="#3D5A80"
              onClick={() => navigate('/profile-views')}
            >
              {profileViewers?.length}
            </ProfileActionText> 
            <ProfileActionText className="ps-2" fSize="14px">
            Look at you go! People are noticing your profile.
            </ProfileActionText>
          </div>
          {profileViewers?.length > 0 && (
            <div className="d-inline-flex pt-3">
              <AvatarGroup
                appearance="stack"
                maxCount={3}
                data={profileViewers?.slice(0, 6).map((e) => ({
                  key: e.user.id,
                  name: e.user.first_name,
                  src: e.user.profile_pic.includes('default-profile-pic') ? '' : e.user.profile_pic,
                }))}
              />
              <div
                style={{ flex: '10', display: 'flex', alignItems: 'center' }}
                onClick={() => navigate('/profile-views')}
              >
                <ProfileActionText className="cursor-pointer" fSize="13px" color="#3D5A80">
                  &nbsp; viewing your profile
                </ProfileActionText>
              </div>
            </div>
          )}
        </div>

        <ProfileActionButton color='#3D5A80'>
          <Email color="#ffffff" />
          <ProfileActionText
            className="cursor-pointer"
            fSize="14px"
            color="#ffffff"
            onClick={() => navigate('/invitations')}
          >
            Manage invitations
          </ProfileActionText>
        </ProfileActionButton>

        <ProfileActionButton color="#E4E6EB">
          <ProfileActionText
            className="cursor-pointer"
            fSize="14px"
            color="#003049"
            onClick={() => navigate('/testimonial')}
          >
            Give or Ask for a Testimonial
          </ProfileActionText>
        </ProfileActionButton>
      </ProfileActionBox>
    </ProfileActionContainer>
  );
};
export default MakeProfilePublic;
