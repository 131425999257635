import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from 'store/common/axios';

const initialState = {
    messages: null,
    loading: false,
    error:null
}

export const getNotificationDetails = createAsyncThunk('getNotificationDetails', async (id) => {
    try {
        const headers = {
          'Content-type': 'application/json',
          Authorization: `Bearer ${process.env.COURIER_API_KEY}`,
        };
        const response = await axiosInstance.get(`${process.env.COURIER_API_BASE_URL}messages/${id}`, { headers });
        return response;
      } catch (error) {
        return error;
      }
})


const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    
    extraReducers: {
        [getNotificationDetails.pending]: (state, action) => {
            state.pending = true
            state.error = false;
        },
        [getNotificationDetails.fulfilled]: (state, action) => {
            state.pending = false;
            state.messages = action.payload
        },
        [getNotificationDetails.rejected]: (state, action) => {
            state.loading = false
        },
    }
})

export default notificationSlice.reducer