import React, { useState, useEffect } from 'react';
import Modal from 'components/common/Modal/Modal';
import QuantityInputBox from 'components/common/QuantityInput';
import { cardList } from 'store/slices/paymentSlice';
import { CheckMark1, AmexIcon, CardIcon, MastercardIcon, VisaIcon } from 'components/common/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  getJobSubscriptionDetails,
  getJobCheckout,
  jobSubscriptionPriceDetails,
  createJobSubscription,
  updateJobSubscription
} from 'store/actions/companyJobs.Actions';
import Button from 'components/common/Button';
import { Link } from 'react-router-dom';
import SectionLoader from 'components/common/Loader/sectionLoader';

const JobSubscriptionModal = (props) => {
  const { onClose, jobDetails } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let baseSlotPrice = 0;
  const { cardInfo } = useSelector((state) => state.payment);
  const { userSubscription, jobSubscriptionPlans, jobSubscriptionProcess } = useSelector((state) => state.companyJobsReducer);
  const [checkoutStep, setCheckoutStep] = useState(1);
  const [slotQuantity, setSlotQuantity] = useState(1);
  const [slotPrice, setSlotPrice] = useState(baseSlotPrice);
  const [maxSlotAllowed, setMaxSlotAllowed] = useState(0);
  const [remainingSlots, setRemainingSlots] = useState(0);
  const [currentSubscription, setCurrentSubscription] = useState();
  const defaultCard = _.find(cardInfo?.cards, { id: cardInfo?.default_card_id });

  useEffect(() => {
    dispatch(getJobSubscriptionDetails());
    dispatch(jobSubscriptionPriceDetails());
    dispatch(cardList());
    // eslint-disable-next-line
  }, []);

useEffect(() => {
  if(jobSubscriptionProcess?.status){
    window.location.assign(jobSubscriptionProcess?.URL);
    onClose(true);
  }
}, [jobSubscriptionProcess]);

  useEffect(() => {
    if (userSubscription?.results) {
      const jobSlotsDetails = _.find(userSubscription?.results, function (o) {
        return o.company_page == jobDetails?.hiring_company;
      });
      setCurrentSubscription(jobSlotsDetails);
      setMaxSlotAllowed(jobSlotsDetails?.job_slots ? jobSlotsDetails?.job_slots.total : 0);
      setRemainingSlots(
        jobSlotsDetails?.job_slots
          ? jobSlotsDetails?.job_slots.remaining === -1
            ? 0
            : jobSlotsDetails?.job_slots.remaining
          : 0,
      );
    }
    // eslint-disable-next-line
  }, [userSubscription]);
  const displayCardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <VisaIcon />;
      case 'mastercard':
        return <MastercardIcon />;
      case 'amex':
        return <AmexIcon />;
      default:
        return <CardIcon />;
    }
  };
  const handlePaymentSubmit = async (selectedPlan) => {
    const body = {
      "price_id": jobSubscriptionPlans.price_id,
      "quantity": slotQuantity,
      "company_page": jobDetails.hiring_company,
      "subscription_id":currentSubscription?.subscription_id
    };
    dispatch(updateJobSubscription(body));
  };
  const createNewSubscription = () => {
    let payload = {}
    if(!currentSubscription?.subscription_id){
      payload = {"price_id":jobSubscriptionPlans.price_id, "quantity":slotQuantity, "company_page":jobDetails.hiring_company}
    } else {
      payload = {"price_id":jobSubscriptionPlans.price_id, "quantity":slotQuantity, "company_page":jobDetails.hiring_company, "subscription_id": currentSubscription.subscription_id}
    }
    dispatch(createJobSubscription(payload));
    setCheckoutStep(2)
  }

  const ButtonContainer = (props) => {
    if (props.currentStep === 1) {
      return (
        <>
          <Button variant="btn-secondary btn-sm" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn-sm btn-blue" onClick={() => {createNewSubscription()}}>
            Review Order
          </Button>
        </>
      );
    } else if (props.currentStep === 2) {
      return (
        <>
          <Button variant="btn-secondary btn-sm" onClick={() => setCheckoutStep(1)}>
            Back
          </Button>
          <Button className="btn-sm btn-blue" onClick={() => setCheckoutStep(3)}>
            Proceed to Checkout
          </Button>
        </>
      );
    } else if (props.currentStep === 3) {
      return (
        <div className="d-flex justify-content-between w-100">
          <div className="footer-left">
            <Button variant="btn-secondary btn-sm" onClick={() => setCheckoutStep(2)}>
              Back
            </Button>
          </div>
          <div className="footer-right">
            <Button variant="btn-secondary btn-sm me-2" onClick={onClose}>
              Cancel
            </Button>
            <Button className="btn-sm btn-blue" onClick={() => handlePaymentSubmit()}>
              Submit
            </Button>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (slotQuantity >= 4 && slotQuantity <= 10) {
      baseSlotPrice = 50 * slotQuantity;
    } else if (slotQuantity > 10) {
      baseSlotPrice = 24 * slotQuantity;
    } else {
      baseSlotPrice = 0;
    }
    setSlotPrice(baseSlotPrice);
  }, [slotQuantity]);

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<h3>My subscriptions</h3>}
      action={<ButtonContainer currentStep={checkoutStep} />}
    >
      <div className="payment-modal">
        <div className="message-box-alert mb-2">
          Your myBridge subscriptions currently has <br /> {Math.abs(remainingSlots)} out of {Math.abs(maxSlotAllowed)}{' '}
          open job Slot
        </div>
        <table className="pricingTable-cart">
          <thead>
            <tr>
              <th>Number of Jobs slots</th>
              <th>Price per job slots</th>
            </tr>
          </thead>
          <tbody>
            {jobSubscriptionPlans?.tiers?.map((tier, i) => (
              <tr key={i}>
                <td>{tier.text} slots</td>
                <td>${tier.unit_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {checkoutStep === 1 && (
          <>
            <div className="payment-details pt-2">
              <div className="plan-name">Job slots to buy</div>
              <div className="plan-price">
                <QuantityInputBox quantity={(value) => setSlotQuantity(value)} />
              </div>
            </div>
            <hr />
            <div className="payment-details">
              <div className="plan-name">Total</div>
              <div className="plan-price">${slotPrice} per month</div>
            </div>
          </>
        )}
        {checkoutStep === 2 && (
          <>
            <div className="payment-details pt-2">
              <div className="plan-name">Job slots to buy</div>
              <div className="plan-price box">{slotQuantity}</div>
            </div>
            <hr />
            <div className="payment-details mb-1">
              <div className="plan-name">Total</div>
              <div className="plan-price">${slotPrice} per month</div>
            </div>
            <div className="payment-details">
              <div className="plan-name">Due Today</div>
              <div className="plan-price">${jobSubscriptionProcess ? jobSubscriptionProcess?.due_amount : slotPrice} per month</div>
            </div>
          </>
        )}
        
        {checkoutStep === 3 &&(
          <>
            <div className="payment-details pt-2 mt-3">
              <div className="plan-name">Job slots to buy</div>
              <div className="plan-price box">{slotQuantity}</div>
            </div>
            <hr className="my-4" />
            {jobSubscriptionProcess?.due_amount && <div className="payment-details mb-4">
              <div className="plan-name">Total</div>
              <div className="plan-price">$ {jobSubscriptionProcess?.due_amount ? jobSubscriptionProcess?.due_amount + slotPrice : slotPrice} per month</div>
            </div>}
            
            <div className="card-section">
              {defaultCard ? (
                <>
                  <Link to="/settings/payment">Change Card</Link>
                  <div className="card-details">
                    {displayCardIcon(defaultCard.card.brand)}
                    <span className="ms-2">{`Visa ending in ${defaultCard.card.last4}`}</span>
                  </div>
                  <div>{`${defaultCard.card.exp_month}/${defaultCard.card.exp_year}`}</div>
                </>
              ) : (
                <Link to="/settings/payment">Setup Cards for Payment</Link>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default JobSubscriptionModal;
