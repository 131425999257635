import React from 'react';
import Avatar from 'react-avatar';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import { DegreeIcon } from 'components/common/Icons';
import ReadMore from 'components/common/ReadMore';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { acceptRequest, rejectRequest } from 'store/slices/connectionSlice';
import { confirmAlert } from 'react-confirm-alert';

const InviteCard = (props) => {
    const { user, type } = props;
    const { requestChange, report } = useSelector((state) => state.connection);
    const dispatch = useDispatch();
    const userInfo = user.user_info;
    const username = `${userInfo.first_name} ${userInfo.last_name}`;
    const acceptConnectionReq = (id) => {
        dispatch(acceptRequest({ "connection_request_id": id }));
    }

    const rejectConnectionReq = (id) => {
        confirmAlert({
            // title: 'Confirm to Delete',
            message: 'Are you sure you want to delete the request?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dispatch(rejectRequest({ "connection_request_id": id }))
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const removeConnectionReq = (user) => {
        confirmAlert({
            title: 'Remove request',
            message: `Are you sure you want to remove this request for connection? ${user?.user_info?.first_name} ${user?.user_info?.last_name} get notified that you did this, but will stop seeing it in Pending invitation.`,
            buttons: [
                {
                    label: 'Remove',
                    onClick: () => dispatch(rejectRequest({ "connection_request_id": user?.id }))
                },
                {
                    label: 'Cancel',
                }
            ]
        });
    }

    return (
        <div className='invite-card'>
            <div className='invite-card--inner'>
                <div className='invite-card--leftSection'>
                    <div className='invite-card--profile'>
                        <MyBridgeAvatar name={username} size='70' round={true} />
                    </div>
                    <div className="invite-card--details">
                        <h5 className="card-name">{username}</h5>
                        <p className="card-desc">{userInfo.position}</p>
                        <p className="card-degrees"><span>{userInfo?.degree_count?.first} <DegreeIcon width="12" /> 1st Degrees</span><span>{userInfo?.degree_count?.second} <DegreeIcon width="12" /> 2nd Degrees</span> <span>{userInfo?.degree_count?.third} <DegreeIcon width="12" /> 3rd Degrees</span></p>
                        <p className="card-meta">{moment.utc(user?.created_at).local().fromNow()}</p>
                    </div>
                </div>
                {type === 'pending' && <div className="invite-card--actions">
                    <Button variant='btn-secondary' onClick={() => rejectConnectionReq(user.id)}>Ignore</Button>
                    <Button onClick={() => acceptConnectionReq(user.id)}>Accept</Button>
                </div>}
                {type === 'sent' && <div className="invite-card--actions">
                    <Button variant='btn-secondary' onClick={() => removeConnectionReq(user)}>Remove</Button>
                    <Button onClick={() => acceptConnectionReq(user.id)}>Reminder</Button>
                </div>}

            </div>
            {user.message && <div className='invite-card--bottom'>
                <p className='card-message'><ReadMore wordcount={100}>{user.message}</ReadMore></p>
                <p><a href='#'>Reply to {username}</a></p>
            </div>}

        </div>
    )
}

export default InviteCard