import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import EditorConvertToHTML from 'components/common/Editor';
import { Text } from 'components/StyledComponents';

import { postNewJob, UpdateJob, getCompanyAllJobs, getJobSubscriptionDetails } from 'store/actions/companyJobs.Actions';
import { clear } from 'store/actions/Job.Action';
import { getGlobalSkills } from 'store/slices/globalDataSlice';
import { get, upperCase } from 'lodash';

import _ from 'lodash';

import Select from 'react-select';
import Pills from 'components/common/Pills';
import JobSubscriptionModal from 'components/JobSubscriptionModal/JobSubscriptionModal';

const RoleSection = ({ jobDetails, setJobDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const { editDetails, isRepost } = useSelector((state) => state.jobReducer);
  const { companyJobList, userSubscription } = useSelector((state) => state.companyJobsReducer);
  const { globalSkills } = useSelector((state) => state.globalData);

  const [toolbarOptions] = useState(['inline', 'list', 'textAlign']);
  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [jobPostedSuccess, setJobPostedSuccess] = useState(false);
  const [jobDescription, setJobDescription] = useState();
  const [currentSubscription, setCurrentSubscription] = useState();
  const [maxSlotAllowed, setMaxSlotAllowed] = useState(0);
  const [needPayment, setNeedPayment] = useState(false);

  useEffect(() => {
    dispatch(getGlobalSkills());
    dispatch(getCompanyAllJobs(jobDetails.hiring_company));
    dispatch(getJobSubscriptionDetails())
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobDetails) {
      setJobDescription(jobDetails.job_description ? jobDetails.job_description : '');
      setSkills(jobDetails.skills ? jobDetails.skills : []);
    }
    // eslint-disable-next-line
  }, [jobDetails]);

  const setDataValue = (name, data) => {
    setJobDetails((state) => ({
      ...state,
      [name]: data.name,
    }));
  };

  const setEditorData = (name, data) => {
    setJobDetails((stats) => ({
      ...stats,
      [name]: data,
    }));
  };

  useEffect(() => {
    const jobSlotsDetails = _.find(userSubscription?.results, function (o) {
      return o.company_page == jobDetails.hiring_company;
    });
    setCurrentSubscription(jobSlotsDetails);
    setMaxSlotAllowed(jobSlotsDetails?.job_slots ? jobSlotsDetails?.job_slots.total - jobSlotsDetails?.job_slots.used : 0);
  }, [userSubscription])

  // useEffect(() => {
  //   setEditorData('job_description', jobDescription);
  // }, [jobDescription])

  const savePostJobs = (isDraft) => {
    let finalSkills = [];
    for (let i = 0; i < skills.length; i++) {
      finalSkills.push(skills[i].name ? skills[i].name : skills[i].label);
    }
    if(maxSlotAllowed === 0) {
      setPaymentModal(true);
      isDraft = true;
      setNeedPayment(true)
    }
    const payload = {
      ...jobDetails,
      is_draft: isDraft ? true : false,
      published_date: isDraft ? null : new Date(),
      skills: finalSkills,
      how_to_apply: upperCase(jobDetails.how_to_apply),
      job_description:jobDescription
    };
    localStorage.setItem('recentJob', JSON.stringify(payload));
    
    dispatch(postNewJob(payload)).then((res) => {
      const jobId = get(res, 'payload.id');
      if (jobId) {
        setJobDetails();
        setSkills([]);
        setJobPostedSuccess(true);
        localStorage.removeItem('recentJob');
        if(!needPayment){
          navigate(`/company/page/${jobDetails.hiring_company}/jobs`);
        }
      }
    });
  };

  const updateJobDetails = () => {
    let finalSkills = [];
    for (let i = 0; i < skills.length; i++) {
      finalSkills.push(skills[i].name ? skills[i].name : skills[i].label);
    }
    const payload = {
      ...jobDetails,
      is_draft: false,
      published_date: new Date(),
      how_to_apply: upperCase(jobDetails.how_to_apply),
      job_description:jobDescription,
      skills: finalSkills,
    };
    dispatch(UpdateJob(payload)).then((res) => {
      dispatch(clear());
      setJobDetails();
      setSkills([]);

      setJobPostedSuccess(true);
      navigate(-1);
    });
  };

  const handleRemoveItem = (skill) => {
    const name = skill;
    setSkills(skills.filter((item) => item.name !== name));
  };

  const handleClose = () => {
    setPaymentModal(false);
  };

  return (
    <>
      <section className="container">
        <Card className="p-4 mt-5">
          <div className="mb-3">
            <Text color="#868585">Job Description*</Text>
            <EditorConvertToHTML
              toolbarOptions={toolbarOptions}
              toolbardropDown={false}
              // setEditorData={setEditorData}
              setEditorData={(e) => setJobDescription(e)}
              name="job_description"
              defaultValue={jobDescription}
              editorClass={'page-textarea'}
            />
          </div>

          <div className="">
            <Text color="#868585" className="mb-1">
              Skills
            </Text>
            <Text color="#868585" fS="13px" margin="0 0 3px 0">
              Target the jobseekers you need by keyword below
            </Text>
            <CreatableSelect
              name="skills"
              options={globalSkills.map((e) => ({ label: e.name, value: e.name, name:e.name }))}
              classNamePrefix="jobFields"
              isSearchable={true}
              onChange={(value) => {
                setSkills((prev) => [...prev, value]);
                // setSkill(value);
                // console.log(ref.current.clearValue())
              }}
              value={skill}
              openMenuOnClick={false}
              maxMenuHeight={50}
            />
            <div className="d-flex gap-2 mt-3">
              {skills &&
                skills?.map((item, i) => (
                  <Pills
                    className="jobPills"
                    closeIconColor="#fff"
                    key={i}
                    label={item.name ? item.name : item.label}
                    onClose={() => handleRemoveItem(item.name)}
                  />
                ))}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              Need to finish up later?{' '}
              <span className="color-primary cursor-pointer" onClick={() => savePostJobs(true)}>
                Save as draft
              </span>
            </div>

            <div>
              <Button variant="btn-secondary" className="me-3 h-36" onClick={() => navigate(-1)}>
                Cancel
              </Button>

              {editDetails && !isRepost ? (
                <Button variant="btn-primary" className="h-36" onClick={updateJobDetails}>
                  Update & Post
                </Button>
              ) : (
                <Button variant="btn-primary" className="h-36" onClick={() => savePostJobs(false)}>
                  Save & Post Now
                </Button>
              )}
            </div>
          </div>
        </Card>
      </section>
      {/* {jobPostedSuccess && (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Holy guacamole!</strong> You should check in on some of those fields below.
          <Button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => jobPostedSuccess(false)}
          ></Button>
        </div>
      )} */}

      {paymentModal && <JobSubscriptionModal onClose={handleClose} jobDetails={jobDetails} />}
    </>
  );
};

export default RoleSection;
