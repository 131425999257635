import { CheckGreen, DeleteIcon, Documents, ExclamationIcon, PauseIcon, PlayIcon, UploadModalHeaderIconLg } from "components/common/Icons"
import Modal from "components/common/Modal/Modal"
import { calculateChecksum, getFileExtension, trimStr } from "components/utils"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addFilesToUpload, getfileNFolder, removeFilesToUpload, setFilesToUpload, updateFileToUpload, uploadFileByIndex } from "store/actions/myDisk.Action"
import "./index.scss"
import { APIEndPoints } from "store/common/endPoint"
import { getIconByExtension } from "components/MyDisk/common/iconsMap"

let tids = {
    fakeTimeout: -1
}

/**
 * Simple file upload modal for uploading multiple files (sequentially)\
 * TODO: Make it upload parallely
 * @param {*} props 
 * @returns 
 */
export const FileUploadModal = ({ parentDirectoryId, onUpload, ...props }) => {

    const { filesToUpload: files } = useSelector(state => state.myDiskReducer)

    const [dragHover, setDragHover] = useState(false)
    // const [files, setFiles] = useState([])
    const fileInputRef = useRef(null)
    const [isUploading, setIsUploading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        // setup event bindings
        bindDnDAreaEvents()
        return () => unbindDnDAreaEvents()
    }, [])

    // listen to file changes and set uploading state properly
    useEffect(() => {
        if (files && files.length) {
            if (!isUploadPending()) {
                setIsUploading(false)
            }
        }
    }, [files])


    /**
     * Bind drag/drop events to dndArea
     */
    const bindDnDAreaEvents = () => {
        if (typeof window !== undefined) {
            const dndAreaElement = document.querySelector(".drag__area");
            if (dndAreaElement) {
                dndAreaElement.addEventListener('dragenter', handleDragEnter)
                dndAreaElement.addEventListener('dragover', handleDragOver)
                dndAreaElement.addEventListener('dragleave', handleDragLeave)
                dndAreaElement.addEventListener('drop', handleDrop)
            }
        }
    }

    /**
     * Unbind drag/drop events from dndArea
     */
    const unbindDnDAreaEvents = () => {
        if (typeof window !== undefined) {
            const dndAreaElement = document.querySelector(".drag__area");
            if (dndAreaElement) {
                dndAreaElement.removeEventListener('dragenter', handleDragEnter)
                dndAreaElement.removeEventListener('dragover', handleDragOver)
                dndAreaElement.removeEventListener('dragleave', handleDragLeave)
                dndAreaElement.removeEventListener('drop', handleDrop)
            }
        }
    }

    /**
     * Handle drag enter event on the dnd area
     * @param {*} e 
     */
    const handleDragEnter = (e) => {
        preventDefaults(e)
        setDragHover(true)
    }

    /**
     * Handle drag over event on the dnd area
     * @param {*} e 
     */
    const handleDragOver = (e) => {
        preventDefaults(e)
        setDragHover(true)
    }

    /**
     * Handle drag leave event on the dnd area
     * @param {*} e 
     */
    const handleDragLeave = (e) => {
        preventDefaults(e)
        setDragHover(false)
    }

    /**
     * Handle drop event on the dnd area
     * @param {*} e 
     */
    const handleDrop = (e) => {
        preventDefaults(e)
        setDragHover(false)
        if (e.dataTransfer?.files) {
            handleFiles(e.dataTransfer.files)
        }
    }

    /**
     * Prevent default actions for any kind of event
     * @param {*} e 
     */
    const preventDefaults = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    /**
     * Opens operating system's file selection dialog
     * @param {*} e 
     */
    const triggerFileUploadSelector = (e) => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    /**
     * Handle file selection by click
     * @param {*} e 
     */
    const onFileInputChange = (e) => {
        preventDefaults(e)
        setDragHover(false)
        if (e?.target?.files) {
            handleFiles(e?.target?.files)
        }
    }

    /**
     * Handle selected files
     * @param {*} selectedFiles 
     */
    const handleFiles = (selectedFiles) => {
        if (selectedFiles && selectedFiles.length) {
            dispatch(addFilesToUpload(selectedFiles))
        }
    }

    /**
     * Remove file from uploading list (before upload begins)
     * @param {*} fileIndex 
     */
    const removeFile = (fileIndex) => {
        if (files && files.length && fileIndex > -1 && fileIndex < files.length) {
            dispatch(removeFilesToUpload([fileIndex]))
            fileInputRef.current.value = ""
        }
    }

    /**
     * Upload using XMLHttpReq
     * @param {*} file 
     */
    const xUpload = async (payload, index) => {
        return new Promise((res, rej) => {
            try {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", process.env.REACT_APP_API_URL + APIEndPoints.MyDisk_Files);
                xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('access'))
                // xhr.setRequestHeader("content-type", 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e')
                if (xhr.upload) {
                    xhr.upload.onprogress = function (e) {
                        dispatch(updateFileToUpload({ index, json: { loaded: ((e.loaded ?? 0) / (e.total ?? 1)) * 100 } }))
                    }
                }

                xhr.onload = function () {
                }

                xhr.onerror = function (err) {
                    console.error("error")
                    rej(err)
                }
                xhr.onloadend = function () {
                    console.error("done")
                    res()
                }
                xhr.onreadystatechange = function (e) {
                    if (e.target?.status >= 400) {
                        const error = JSON.parse(e.target.responseText);
                        rej(error?.message)
                    }
                }

                xhr.send(payload)
            } catch (e) {
                rej(e)
            }
        })
    }

    /**
     * Actually upload file
     * @param {*} file 
     * @param {*} index 
     */
    const upload = async (file, index) => {
        if (parentDirectoryId) {
            let payload = new FormData();
            dispatch(updateFileToUpload({ index, json: { status: "uploading" } }))
            payload.append('file', file);
            payload.append('parent', parentDirectoryId);
            try {
                await xUpload(payload, index)
                dispatch(updateFileToUpload({ index, json: { status: "done" } }))
            } catch (errorMessage) {
                dispatch(updateFileToUpload({ index, json: { status: "error", errorMessage } }))
            }

        } else {
            throw new Error("Invalid parent directory!")
        }

    };

    /**
     * Start uploading files one by one
     */
    const startUploading = async () => {
        if (files && files.length > 0) {
            setIsUploading(true)
            try {
                for (let index in (files ?? [])) {
                    const file = files[index]
                    if (file.status === "done" || file.state === "error") continue
                    try {
                        const checksum = await calculateChecksum(file, 10000)
                        dispatch(updateFileToUpload({ index, json: { checksum } }))
                        await upload(file, index)
                    } catch (e) {
                        console.error("Error", e)
                        file.status = "error"
                        file.errorMessage = e.message
                        dispatch(updateFileToUpload({ index, json: { status: "error", errorMessage: e.message } }))
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    /**
     * Check if all the files in the list have been uploaded
     */
    const isUploadPending = () => {
        return files?.filter?.(f => f.status !== "done" && f.status !== "error")?.length > 0
    }

    const isFileUploadPending = (file) => {
        return file.status === "uploading"
    }

    const handleOnClose = () => {
        if (isUploadPending()) {
            if (typeof window !== "undefined") {
                const confirm = window.confirm("Changes will be lost! Press OK to continue...")
                if (!confirm) return;
            }
        }
        dispatch(setFilesToUpload([]))
        if (!isUploadPending()) {
            dispatch(getfileNFolder({ parent: parentDirectoryId }));
        }
        props?.onClose?.()
    }

    /**
     * Returns components based on file type to be shown as icon
     * @param {*} file 
     * @returns 
     */
    // const getIconByFileType = (file) => {
    //     return <div className="li__icon">
    //         {
    //             file?.type?.startsWith("image/") ?
    //                 <img className="image__preview" src={URL.createObjectURL(file)} />
    //                 :
    //                 file?.type?.startsWith("video/") ?
    //                     <div className="video__preview" />
    //                     :
    //                     <Documents style={{ width: "32px", height: "32px" }} />
    //         }
    //     </div>
    // }

    return (
        <Modal
            {...props}
            onClose={handleOnClose}
        >
            {/* Uploading section */}
            <section
                style={{
                    pointerEvents: isUploading ? "none" : "all",
                    opacity: isUploading ? 0.5 : 1
                }}
                className={`drag__area rounded d-flex justify-content-center align-items-center ${dragHover ? "hover" : ""}`}
                onClick={triggerFileUploadSelector}
            >
                <UploadModalHeaderIconLg />
                <div  >
                    <span>Drag and drop or&nbsp;<span className="drag__area__focus">browse</span>&nbsp;your files</span>
                </div>
                <input onChange={onFileInputChange} style={{ display: "none" }} ref={fileInputRef} type="file" multiple id="FILE_UPLOADER_FILES_INPUT" />
            </section>
            {/* Listing section */}
            <section
                className="listing__section"
            >
                {files && files?.length ?
                    <ul>
                        {files.map((file, findex) =>
                            <li key={findex}>
                                {getIconByExtension({ extension: getFileExtension(file?.name) })}
                                {/* {getIconByFileType(file)} */}
                                <div title={file.name}>
                                    {trimStr(file.name, { length: 30 })}
                                    {
                                        (file.status === "error") ?
                                            <div className="error">
                                                <span>
                                                    {typeof file.errorMessage === "string" ? file.errorMessage : (file.errorMessage?.message ?? "")}
                                                </span>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div>
                                    <div className="progress">
                                        <div style={{ width: file.status === "done" ? "100%" : `${file.loaded ?? 0}%` }} className="progress-bar" />
                                    </div>
                                </div>
                                <div>
                                    {/* {isUploading ? <>
                                        {file?.status === "uploading" ?
                                            <button className="px-1" onClick={e => { }} >
                                                <PauseIcon width={"18px"} />
                                            </button> : (file.status !== "error" && file.status !== "done") ? <button className="px-1" onClick={e => { }} >
                                                <PlayIcon width={"18px"} />
                                            </button> : ""
                                        }
                                    </> : ""} */}
                                    {!isUploading && (file?.status !== "done") ?
                                        <button className="px-1" onClick={e => removeFile(findex)} >
                                            <DeleteIcon style={{ width: "18px" }} />
                                        </button> : ""
                                    }
                                    {
                                        (file.status === "done") ?
                                            <CheckGreen style={{ width: "18px" }} />
                                            :
                                            <></>
                                    }
                                    {
                                        (file.status === "error") ?
                                            <div aria-label={file.errorMessage} data-microtip-position="top-left" role="tooltip" title={file.errorMessage}>
                                                <ExclamationIcon style={{ width: "18px", height: "18px" }} />
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </li>
                        )}
                    </ul> : <></>}
            </section>

            {/* Footer section */}
            {files && files.length ?
                <section
                    className="footer__section"
                >
                    {!isUploadPending() ? <div >
                        <button onClick={handleOnClose} className="btn btn-light" >
                            Close
                        </button>
                    </div> : ""}
                    {isUploadPending() ?
                        <div>
                            <button disabled={isUploading} onClick={startUploading} className="btn btn-primary" >
                                {isUploading ? "Uploading..." : "Submit"}
                            </button>
                        </div>
                        : ""}
                </section>
                :
                <></>
            }
        </Modal>
    )
}