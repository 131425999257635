import { useMutation } from '@tanstack/react-query';
import { pinPost } from 'api/post';
import Button from 'components/common/Button';
import { Spinner } from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import PostContext from 'context/PostContext';
import { useContext } from 'react';
import styled from 'styled-components';

const PinHeading = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3d5a80;
`;

const PinButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 18px;

  width: 59px;
  height: 36px;
  background: #3d5a80;
  border-radius: 5px;
  border: none;
  color: #fff;
`;

const CancelPinButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 18px;
  width: 86px;
  height: 36px;
  background: #e4e6eb;
  border-radius: 5px;
  border: none;
  #color: #3d5a80;
`;

const PinModal = ({ onClose }) => {
  const { postData, updatePost } = useContext(PostContext);
  const { id, pinned } = postData;
  const postId = id;
  const { mutate, isLoading } = useMutation(pinPost, {
    onSuccess: (data) => {
      onClose();
      updatePost();
    },
    onError: (error) => {
      onClose();
      console.log(error);
    },
  });

  const handlePinPost = () => {
    mutate({ id: postId, method: 'POST' });
    // scrollToTop();
    window.scrollTo(0, 0);
  };

  const handleUnPinPost = () => {
    mutate({ id: postId, method: 'DELETE' });
  };

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<PinHeading> {pinned ? 'Unpin' : 'Pin'} Post</PinHeading>}
      action={
        <>
          <Button className={"btn btn-secondary"} onClick={onClose}>Cancel</Button>
          <Button onClick={pinned ? handleUnPinPost : handlePinPost}>
            {isLoading ? <Spinner /> : pinned ? 'Unpin' : 'Pin'}
          </Button>
        </>
      }
    >
      <p style={{ padding: '0px 12px' }}>
        {pinned
          ? 'This will no longer appear at the top of your profile.'
          : 'This will appear at the top of your profile and replace any previously pinned post.'}
      </p>
    </Modal>
  );
};

export default PinModal;
