import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem } from 'store/slices/searchSlice';
import PageCard from 'components/PageCard/index.js';
import SectionLoader from 'components/common/Loader/sectionLoader';
import FilterSection from 'components/common/FilterSection';
import { Col, Row } from 'react-bootstrap';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import { getGlobalCompanySize, getGlobalIndustries } from 'store/slices/globalDataSlice';
import DefaultSearchPage from '../DefaultSearchPage';

const SearchPage = () => {
  const dispatch = useDispatch();
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const { globalCompanySize, globalIndustries } = useSelector((state) => state.globalData);
  const results = item?.results;
  const { query } = useContext(SearchContext);
  const [updatedFilters, setUpdatedFilters] = useState();

  useEffect(() => {
    if (updatedFilters) dispatch(searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'pages' }));
  }, [dispatch, updatedFilters]);

  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'pages' }));
    dispatch(getGlobalCompanySize());
    dispatch(getGlobalIndustries());
  }, [dispatch, query]);

  const companySizeFilterData = globalCompanySize?.map((size) => {
    return { label: size, name: size, value: size };
  });

  const locationFilterData = [...new Set(results?.map((item) => item.state_region))].map((item) => {
    if (item !== '') return { label: item, name: item, value: item };
    return null
  });

  const keywordsData = [
    { label: 'Yes', name: 'Yes', value: 'Yes', labelHead: true, labelHeadTitle: 'Job listing on myBrdige' },
    { label: '1st Degree', name: '1st_degree', value: '1st_degree', labelHead: true, labelHeadTitle: 'Connections' },
    { label: '2nd Degree', name: '2nd_degree', value: '2nd_degree', labelHead: true },
    { label: '3rd Degree', name: '3rd_degree', value: '3rd_degree', labelHead: true },
  ];

  const industryFilterData = globalIndustries?.map((size) => {
    return { label: size, name: size, value: size, type: 'checkbox' };
  });

  const filters = [
    {
      label: 'Locations',
      type: 'checkbox',
      data: locationFilterData,
      showInput: true,
      showKeywords: false,
      showKeywordsTitle: '',
    },
    {
      label: 'Industry',
      type: 'checkbox',
      data: industryFilterData,
      showInput: true,
      showKeywords: false,
      showKeywordsTitle: '',
    },
    {
      label: 'Company size',
      type: 'checkbox',
      data: companySizeFilterData,
      showInput: true,
      showKeywords: false,
      showKeywordsTitle: '',
    },
    {
      label: 'Keyword',
      type: 'checkbox',
      data: keywordsData,
      showInput: false,
      showKeywords: false,
      showKeywordsTitle: 'Keyword',
    },
  ];

  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);

  const pages =
    results?.length > 0 ? (
      results?.map((item) => {
        return (
          <Col xs={6}>
            <PageCard {...item} isSearchResult={true} key={item.id} />
          </Col>
        );
      })
    ) : (
      <DefaultSearchPage />
    );


  return (
    <React.Fragment>
      <FilterSection filters={filters} filterData={filterData} />
      <h5 className="mb-4">Pages</h5>
      {isItemsLoading ?
        <SectionLoader /> :
        <>
          <Row>{pages}</Row>
        </>
      }
    </React.Fragment>
  );
};

export default SearchPage;
