import styled, { css } from 'styled-components';

export const RootContainer = styled.div.attrs({
  className: 'container',
})`
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 12px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const BorderedContainer = styled.div`
  background: #ffffff;
  border: ${(props) => props?.isBorder?'0px solid #e4e6eb': '1px solid #e4e6eb'};
  border-radius: 12px;
  margin-bottom: 10px;
  overflow: ${(props) => props.overflow || 'hidden'};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding || '0 1rem'};
  background-color: ${(props) => props.bgColor || 'transparent'};
  width: ${(props) => props.width || '200px'};
  /* height: 36px; */
  border-radius: 8px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: 30px;
  color: ${(props) => props.color || '#3d5a80'};

  &:hover {
    background-color: ${(props) => props.hoverBgColor || '#003049'};
    color: ${(props) => props.hoverColor || '#FFF'};
  }
`;

export const BorderLessButton = styled.button`
  padding: ${(props) => props.padding || '0.4rem 0.8rem'};
  background-color: ${(props) => props.background || 'transparent'};
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: 30px;
  text-align: ${(props) => props.tAlign || 'center'};
  border-radius: ${(props) => props.borderRadius || '5px'};
  color: ${(props) => props.color || '#3d5a80'};
  display: ${(props) => props?.flex ?? 'flex'};
  align-items: ${(props) => props?.alignItems ?? 'center'};
  justify-content: ${(props) => props?.justifyContent ?? 'center'};
  width: ${(props) => props?.width ?? 'auto'};
  /* ${(props) =>
    props.$primary &&
    css`
      background: white;
      color: black;
    `} */

  &:hover {
    background-color: ${(props) => `${props.hover ? '50%' : '#e4e6eb'}`};
  }
`;

export const VerticalLine = styled.div`
  border: solid 1px ${(props) => props.bColor || '#d9d9d9'};
`;

export const Text = styled.p`
  font-family: 'Inter';
  line-break: anywhere;
  font-style: normal;
  font-weight: ${(props) => props.fW || 400};
  font-size: ${(props) => props.fS || '14px'};
  margin: ${(props) => props.margin || '0px'};
  padding-bottom: ${(props) => props.pb || '0px'};
  padding: ${(props) => props.padding || '0px'};
  line-height: ${(props) => props.lh || '120%'};
  color: ${(props) => props.color || '#000000'};
  width: ${(props) => props.width};
  cursor: ${(props) => props.cursor};
  /* display: flex;
  align-items: ${(props) => props.alignSef || 'center'};
  gap: 5px; */
`;

export const Image = styled.img`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
`;
