import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem } from 'store/slices/searchSlice';
import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import SectionLoader from 'components/common/Loader/sectionLoader';
import FilterSection from 'components/common/FilterSection';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import { getGlobalCompanies, getGlobalIndustries } from 'store/slices/globalDataSlice';
import DefaultSearchPage from '../DefaultSearchPage';

const SearchJob = () => {
  const dispatch = useDispatch();
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const results = item?.results;
  // const { data: connectionData } = useSelector((state) => state.connection);
  const { query } = useContext(SearchContext);
  const [jobs, setJobs] = useState(results);
  const [updatedFilters, setUpdatedFilters] = useState();
  const { globalIndustries } = useSelector((state) => state.globalData);

  useEffect(() => {
    if (updatedFilters) {
      dispatch(searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'jobs' }));
    }
  }, [dispatch, updatedFilters]);

  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'jobs' }));
    dispatch(getGlobalCompanies());
    dispatch(getGlobalIndustries());
  }, [dispatch, query]);

  useEffect(() => {
    const filteredJobs = results?.filter((item) => {
      return item.job_status === 'active';
    });
    setJobs(filteredJobs);
  }, [results]);

  const datePostedByFilterData = [
    { label: 'Any time', name: 'any_time', value: 'any_time' },
    { label: 'Past 24 hours', name: 'date_posted', value: 'past_24_hours' },
    { label: 'Past week', name: 'date_posted', value: 'past_week' },
    { label: 'Past month', name: 'date_posted', value: 'past_month' },
  ];

  const experienceLevelFilterData = [
    { label: 'Internship', name: 'internship', value: 'internship' },
    { label: 'Entry level', name: 'entry_level', value: 'entry_level' },
    { label: 'Associate', name: 'associate', value: 'associate' },
    { label: 'Mid-Senior level', name: 'mid_senior_level', value: 'mid_senior_level' },
    { label: 'Director', name: 'director', value: 'director' },
    { label: 'Executive', name: 'executive', value: 'executive' },
  ];

  const jobTypeFilterData = [
    { label: 'Full-time', name: 'full_time', value: 'FT' },
    { label: 'Part-time', name: 'part_time', value: 'PT' },
    { label: 'Contract', name: 'contract', value: 'C' },
    { label: 'Temporary', name: 'temporary', value: 'T' },
    { label: 'Other', name: 'other', value: 'O' },
  ];

  const onSiteFilterData = [
    { label: 'Onsite', name: 'onsite', value: 'onsite' },
    { label: 'Hybrid', name: 'hybrid', value: 'hybrid' },
    { label: 'Remote', name: 'remote', value: 'remote' },
  ];

  const keywordLocation = [...new Set(jobs?.map((item) => item?.hiring_company_details?.address)?.flat())].map(
    (item) => {
      if (item !== null) return { label: item, name: item, value: item, type: 'checkbox' };
      return null
    },
  );

  const keywordIndustry = globalIndustries?.map((size) => {
    return { label: size, name: size, value: size, type: 'checkbox' };
  });

  const keywordJobFunction = [
    { label: 'Engineering', name: 'Engineering', value: 'Engineering', type: 'checkbox' },
    { label: 'Accounting/Auditing', name: 'Accounting/Auditing', value: 'Accounting/Auditing', type: 'checkbox' },
    { label: 'Sales', name: 'Sales', value: 'Sales', type: 'checkbox' },
    { label: 'Manufacturing', name: 'Manufacturing', value: 'Manufacturing', type: 'checkbox' },
    { label: 'Health Care Provider', name: 'Health Care Provider', value: 'Health Care Provider', type: 'checkbox' },
    {
      label: 'Information Technology',
      name: 'Information Technology',
      value: 'Information Technology',
      type: 'checkbox',
    },
  ];

  const keywordTitle = [...new Set(jobs?.map((item) => item?.title))].flat().map((item) => {
    if (item !== null) return { label: item, name: item, value: item, type: 'checkbox' };
    return null
  });

  const KeywordSalary = [
    { label: '$123.000+', name: '$123.000+', value: '$123.000+', type: 'radio' },
    { label: '$100,000+', name: '$100,000+', value: '$100,000+', type: 'radio' },
    { label: '$200,000+', name: '$200,000+', value: '$200,000+', type: 'radio' },
    { label: '$100,000+', name: '$100,000+', value: '$100,000+', type: 'radio' },
    { label: '$40,000+', name: '$40,000+', value: '$40,000+', type: 'radio' },
    { label: '$40,000+', name: '$40,000+', value: '$40,000+', type: 'radio' },
    { label: '$80,000+', name: '$80,000+', value: '$80,000+', type: 'radio' },
    { label: '$80,000+', name: '$80,000+', value: '$80,000+', type: 'radio' },
    { label: '$180,000+', name: '$180,000+', value: '$180,000+', type: 'radio' },
    { label: '$180,000+', name: '$180,000+', value: '$180,000+', type: 'radio' },
  ];

  const KeywordBenefits = [
    ...new Set(jobs?.map((item) => item?.benefits?.map((benefit) => benefit?.name))?.flat()),
  ].map((item) => {
    if (item !== null) return { label: item, name: item, value: item, type: 'checkbox' };
  });

  const companyFilterData = [...new Set(jobs?.map((item) => item?.hiring_company_details?.name)?.flat())].map(
    (item) => {
      if (item !== null) return { label: item, name: item, value: item };
    },
  );

  const searchJobsKeywordsData = {
    location: keywordLocation,
    Industry: keywordIndustry,
    'Job Function': keywordJobFunction,
    Title: keywordTitle,
    Salary: KeywordSalary,
    Benefits: KeywordBenefits,
  };

  const filters = [
    { label: 'Date posted', type: 'radio', data: datePostedByFilterData },
    { label: 'Experience level', type: 'checkbox', data: experienceLevelFilterData },
    { label: 'Company', type: 'checkbox', data: companyFilterData?.slice(0, 3), showInput: true },
    { label: 'Job type', type: 'checkbox', data: jobTypeFilterData },
    { label: 'On-site/Remote', type: 'checkbox', data: onSiteFilterData },
    {
      label: 'Keyword',
      data: null,
      showInput: false,
      showKeywords: false,
      searchJobsKeywords: true,
      searchJobsKeywordsData: searchJobsKeywordsData,
      showKeywordsTitle: 'Keyword',
    },
  ];

  const jobList =
    jobs?.length > 0 ? (
      jobs?.map((item, index) => {
        return (
          <div className="col-12 col-sm-6">
            <JobCardIndividual isSearchResult={true} data={item} key={item.id} />
          </div>
        );
      })
    ) : (
      <DefaultSearchPage />
    );

  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);

  return (
    <React.Fragment>
      <FilterSection filters={filters} filterData={filterData} />
      <h5 className="mb-4">Jobs</h5>
      {isItemsLoading ? (
        <SectionLoader />
      ) : (
        <>
          <div className="row">{jobList}</div>
        </>
      )}
    </React.Fragment>
  );
};

export default SearchJob;
