import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';
import { TextArea } from 'components/common/Input';
import { TIME_FORMATE } from 'data/data';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import RadioButton from 'components/common/RadioButton';

const ModalPopups = (props) => {
  const { modalType, setModalOpen, updateStatus, data } = props;

  const ary = Intl.supportedValuesOf('timeZone');
  const timeZone = ary.map((item) => {
    return { value: item, label: item };
  });

  const [timeZoneSelected, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [fromDate, setFromDate] = useState(moment(new Date(data?.fromDate)).format('YYYY-MM-DD') ?? moment(new Date()).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(new Date(data?.toDate)).format('YYYY-MM-DD') ?? moment(new Date()).format('YYYY-MM-DD'));

  let min = moment().minute();
  let dateTime = moment()
    .add(min > 30 && 1, 'hours')
    .minutes(min <= 30 ? 30 : 0)
    .format('HH:mm');
  let endTimeMin = moment(dateTime, 'HH:mm').add(30, 'minutes').format('HH:mm');

  const [fromTime, setFromTime] = useState(data?.fromTime ?? dateTime);
  const [toTime, setToTime] = useState(data?.toTime ?? endTimeMin);

  const setStartTime = (e) => {
    const { value } = e.target;
    let endTime = moment(value, 'HH:mm').add(30, 'minutes').format('HH:mm');
    setFromTime(value);
    setToTime(endTime);
  };
  const setEndTime = (e) => {
    const { value } = e.target;
    setToTime(value);
  };

  const [allDay, setAllDay] = useState(false);

  const setAllDayMeeting = (e) => {
    const time = moment('12:00 AM', 'h:mm A').format('hh:mm');
    const { checked } = e.target;
    if (checked) {
      setAllDay(true);
      setFromTime(time);
      setToTime(time);
    } else {
      setAllDay(false);
      setFromTime(dateTime);
      setToTime(endTimeMin);
      // setFromTime(moment(eventDetails?.start_time).format('HH:mm'));
      // setToTime(moment(eventDetails?.end_time).format('HH:mm'));
    }
  };

  const updateProposedStatus = () => {
    // no use of these setFunctions
    data.setAllDay(allDay);
    data.setFromTime(fromTime);
    data.setToTime(toTime);
    data.setFromDate(fromDate);
    data.setToDate(toDate);
    data.setTimeZone(timeZoneSelected);

    updateStatus('PROPOSED', {
      allDay,
      fromDate,
      fromTime,
      toDate,
      toTime,
      timeZoneSelected,
    });
  };

  const [radioValue, setRadioValue] = useState({ name: 'radio-btn', value: data });

  const modalBody = (key) => {
    switch (key) {
      case 'accept':
        return (
          <div className="modals-popups accept-meeting">
            <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
            <div className="text-end">
              <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
              <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('ACCEPTED')}>
                Send
              </Button>
            </div>
          </div>
        );
      case 'sendupdate':
        return (
          <div className="modals-popups send-update">
            <div className="border-bottom pb-3 mb-3 mx-4">
              <h6>You have made changes to the list of attendees. Choose one of the following:</h6>
              <RadioButton
                label={'Send updates only to added or deleted attendees.'}
                containerClass="my-3 d-flex align-items-end"
                name="radio-btn"
                value={'UPDATED'}
                isSelected={radioValue.value === 'UPDATED' ? true : false}
                changed={(e) => setRadioValue({ name: 'radio-btn', value: e.target.value })}
              />
              <RadioButton
                label={'Send updates to all attendees.'}
                name="radio-btn"
                containerClass="my-3 d-flex align-items-end"
                value={'ALL'}
                isSelected={radioValue.value === 'ALL' ? true : false}
                changed={(e) => setRadioValue({ name: 'radio-btn', value: e.target.value })}
              />
            </div>
            <div className="text-center">
              <Button
                variant="btn-primary"
                className={'h-36 me-2'}
                onClick={() => updateStatus('SENDUPDATE', radioValue)}
              >
                Send
              </Button>
              <Button variant="btn-secondary" className={'h-36'} onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
        );
      case 'tentative':
        return (
          <div className="modals-popups tentative-meeting">
            {/* <storage>Tentative</storage> */}
            <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
            <div className="text-end">
              <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
              <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('TENTATIVE')}>
                Send
              </Button>
            </div>
          </div>
        );
      case 'message':
        return (
          <div className="modals-popups tentative-meeting">
            <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
            {/* <div className="text-end">
              <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
                OK
              </Button>
            </div> */}
          </div>
        );
      case 'decline':
        return (
          <div className="modals-popups decline-meeting">
            {/* <storage>Decline</storage> */}
            <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
            <div className="text-end">
              <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
              <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('DECLINED')}>
                Send
              </Button>
            </div>
          </div>
        );
      case 'propose':
        return (
          <div className="modals-popups propose-meeting">
            <div>
              <div className="form__first-coloumn my-3">
                <div className="date-wrapper">
                  <DatePicker
                    selected={new Date(fromDate)}
                    onChange={(date) => setFromDate(date)}
                    // minDate={new Date().toUTCString()}
                    placeholderText="Select a date after 5 days ago"
                    className="date-selector form-control"
                    dateFormat="EEE, MMMM d, yyyy"
                  />
                </div>
                <Dropdown
                  id={'fromTime'}
                  name="fromTime"
                  disabled={allDay}
                  options={TIME_FORMATE}
                  title={'fromTime'}
                  selectedValue={fromTime}
                  handleChange={setStartTime}
                  containerClass="m-0"
                />
                <div>
                  <Dropdown
                    id={'timeZone'}
                    name="timeZone"
                    options={timeZone}
                    title={'timeZone'}
                    selectedValue={timeZoneSelected}
                    handleChange={(event) => setTimeZone(event.target.value)}
                    containerClass="m-0"
                  />
                </div>
              </div>
              <div className="form__first-coloumn mb-3">
                <div className="date-wrapper">
                  <DatePicker
                    selected={new Date(toDate)}
                    onChange={(date) => setToDate(date)}
                    // minDate={new Date().toUTCString()}
                    placeholderText="Select a date after 5 days ago"
                    className="date-selector form-control"
                    dateFormat="EEE, MMMM d, yyyy"
                  />
                </div>
                <Dropdown
                  id={'toTime'}
                  name="toTime"
                  disabled={allDay}
                  options={TIME_FORMATE}
                  title={'toTime'}
                  selectedValue={toTime}
                  handleChange={setEndTime}
                  containerClass="m-0"
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    value={true}
                    checked={allDay}
                    name="allDay"
                    label="All Day Events"
                    className="m-0"
                    onChange={setAllDayMeeting}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
              <Button variant="btn-primary" className={'h-36'} onClick={() => updateProposedStatus()}>
                Send
              </Button>
            </div>
          </div>
        );
      default:
        break;
    }
  };
  return modalBody(modalType);
  // <>
  //   {modalType === 'accept' && (
  //     <div className="modals-popups accept-meeting">
  //       <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
  //       <div className="text-end">
  //         <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
  //           Cancel
  //         </Button>
  //         <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('ACCEPTED')}>
  //           Send
  //         </Button>
  //       </div>
  //     </div>
  //   )}
  //   {modalType === 'sendupdate' && (
  //     <div className="modals-popups send-update">
  //       <div className="border-bottom pb-3 mb-3 mx-4">
  //         <h6>You have made changes to the list of attendees. Choose one of the following:</h6>
  //         <RadioButton
  //           label={'Send updates only to added or deleted attendees.'}
  //           containerClass="my-3 d-flex align-items-end"
  //           name="radio-btn"
  //           value={1}
  //           changed={(e) => setRadioValue({ name: 'radio-btn', value: e.target.value })}
  //         />
  //         <RadioButton
  //           label={'Send updates to all attendees.'}
  //           name="radio-btn"
  //           containerClass="my-3 d-flex align-items-end"
  //           value={2}
  //           changed={(e) => setRadioValue({ name: 'radio-btn', value: e.target.value })}
  //         />
  //       </div>
  //       <div className="text-center">
  //         <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => updateStatus('SENDUPDATE')}>
  //           OK
  //         </Button>
  //         <Button variant="btn-secondary" className={'h-36'} onClick={() => setModalOpen(false)}>
  //           Cancel
  //         </Button>
  //       </div>
  //     </div>
  //   )}
  //   {modalType === 'tentative' && (
  //     <div className="modals-popups tentative-meeting">
  //       {/* <storage>Tentative</storage> */}
  //       <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
  //       <div className="text-end">
  //         <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
  //           Cancel
  //         </Button>
  //         <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('TENTATIVE')}>
  //           Send
  //         </Button>
  //       </div>
  //     </div>
  //   )}
  //   {modalType === 'message' && (
  //     <div className="modals-popups tentative-meeting">
  //       <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
  //       {/* <div className="text-end">
  //         <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
  //           OK
  //         </Button>
  //       </div> */}
  //     </div>
  //   )}
  //   {modalType === 'decline' && (
  //     <div className="modals-popups decline-meeting">
  //       {/* <storage>Decline</storage> */}
  //       <TextArea className={'opt-comment'} placeholder="Optional Comment..." maxLength={200} />
  //       <div className="text-end">
  //         <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
  //           Cancel
  //         </Button>
  //         <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('DECLINED')}>
  //           Send
  //         </Button>
  //       </div>
  //     </div>
  //   )}
  //   {modalType === 'propose' && (
  //     <div className="modals-popups propose-meeting">
  //       <div>
  //         <div className="form__first-coloumn my-3">
  //           <div className="date-wrapper">
  //             <DatePicker
  //               selected={new Date()}
  //               onChange={(date) => setFromDate(date)}
  //               // minDate={new Date().toUTCString()}
  //               placeholderText="Select a date after 5 days ago"
  //               className="date-selector form-control"
  //               dateFormat="EEE, MMMM d, yyyy"
  //             />
  //           </div>
  //           <Dropdown
  //             id={'fromTime'}
  //             name="fromTime"
  //             disabled={allDay}
  //             options={TIME_FORMATE}
  //             title={'fromTime'}
  //             selectedValue={fromTime}
  //             handleChange={setStartTime}
  //             containerClass="m-0"
  //           />

  //           <div>
  //             <Dropdown
  //               id={'timeZone'}
  //               name="timeZone"
  //               options={timeZone}
  //               title={'timeZone'}
  //               selectedValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
  //               handleChange={(event) => setTimeZone(event.target.value)}
  //               containerClass="m-0"
  //             />
  //           </div>
  //         </div>
  //         <div className="form__first-coloumn mb-3">
  //           <div className="date-wrapper">
  //             <DatePicker
  //               selected={new Date()}
  //               onChange={(date) => setFromDate(date)}
  //               // minDate={new Date().toUTCString()}
  //               placeholderText="Select a date after 5 days ago"
  //               className="date-selector form-control"
  //               dateFormat="EEE, MMMM d, yyyy"
  //             />
  //           </div>
  //           <Dropdown
  //             id={'toTime'}
  //             name="toTime"
  //             disabled={allDay}
  //             options={TIME_FORMATE}
  //             title={'toTime'}
  //             selectedValue={toTime}
  //             handleChange={setEndTime}
  //             containerClass="m-0"
  //           />
  //           <div style={{ display: 'flex', alignItems: 'center' }}>
  //             <Checkbox
  //               value={true}
  //               checked={allDay}
  //               name="allDay"
  //               label="All Day Events"
  //               className="m-0"
  //               onChange={setAllDayMeeting}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div>
  //         <Button variant="btn-secondary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
  //           Cancel
  //         </Button>
  //         <Button variant="btn-primary" className={'h-36'} onClick={() => updateStatus('PROPOSED')}>
  //           Send
  //         </Button>
  //       </div>
  //     </div>
  //   )}
  // </>
};

export default ModalPopups;
