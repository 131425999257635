import Alert from 'components/common/Alert/Alert';
import Loader, { Spinner } from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import { trimStr } from 'components/utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearTeamLeft, getTeamCommunityList, leaveTeamCommunity } from 'store/slices/teamCommunitySlice';

const TeamLeaveModal = ({ onClose, team, title }) => {
  const { isTeamLeft, loading, error, message, msg } = useSelector((state) => state.teamsCommunity);

  const dispatch = useDispatch();

  const handleSend = () => {
    dispatch(leaveTeamCommunity({ "for_team_community_id": team?.id }))
  };

  const handleClose = () => {
    onClose();
  };

  const getErrorMessage = (msg) => {
    if (msg?.toLowerCase?.()?.includes?.("before left the the team, Assign disk subscription to other organizer".toLowerCase())) {
      return "Please assign the disk subscription to other organizer, before leaving the team!"
    }
    return "Could not leave the team, please try again later!"

  }

  return (
    <>
      <Modal
        onClose={handleClose}
        className="confirmation-modal"
        heading={
          <h2>
            Leave Team, <span title={team?.name}>{trimStr(team?.name, { length: 15 })}</span>?
          </h2>
        }
        action={
          <>
            <button className={`btn btn-light `} onClick={handleClose}>
              Cancel
            </button>
            <button className="btn btn-primary" disabled={isTeamLeft || loading} onClick={handleSend}>
              {loading ? <Spinner /> : "Leave"}
            </button>
          </>
        }
      >
        <>
          {error && message ? <Alert type="danger">{getErrorMessage(message)}</Alert> : ""}
          {msg ? <Alert type="success">The team has been left. You would no longer be able to access the team and it's resources now.</Alert> : ""}
          <p className="">
            You will no longer be able to access "{team?.name}" and any team resources, including all files and conversations.
            <br />
            <br />
            Would you still like to leave this team?
          </p>
        </>
      </Modal>
    </>
  );
};

export default TeamLeaveModal;
