import Modal from 'components/common/Modal/Modal';
import React, { useContext } from 'react';
import PostContext from 'context/PostContext';
import PostForm from 'components/PostForm';

const QuoteModal = ({ onClose }) => {
  const { postData } = useContext(PostContext);
  return (
    <Modal onClose={onClose} className="confirmation-modal" heading={<h3>Quote Post</h3>}>
      <PostForm
        containerStyle={{ padding: "1rem 0 0 0", border: "none;" }}
        repostData={postData}
        repostSuccess={() => {
          onClose();
        }}
      />
    </Modal>
  );
};

export default QuoteModal;
