export const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const genralMonths = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const monthToNameMapping = {
  1: 'January',
  '01': 'January',
  2: 'February',
  '02': 'February',
  3: 'March',
  '03': 'March',
  4: 'April',
  '04': 'April',
  5: 'May',
  '05': 'May',
  6: 'June',
  '06': 'June',
  7: 'July',
  '07': 'July',
  8: 'August',
  '08': 'August',
  9: 'September',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const getDayOptionsInMonth = (rawMonth, rawYear) => {
  const month = rawMonth || new Date().getMonth();
  const year = rawYear || new Date().getFullYear();
  return [...Array(new Date(year, month, 0).getDate())].map((_, i) => ({ label: i + 1, value: i + 1 }));
};

export const generalCauses = [
  'Animal Welfare',
  'Civil Rights and Social Action',
  'Economic Empowerment',
  'Environment',
  'Human Rights',
  'Poverty Alleviation',
  'Social Services',
  'Children',
  'Disaster and Humanitarian Relief',
  'Education',
  'Health',
  'Politics',
  'Science and Technology',
  'Others',
];

export const employmentChoices = [
  { label: 'Full-Time', value: 'FT' },
  { label: 'Part-Time', value: 'PT' },
  { label: 'Contractor', value: 'C' },
  { label: 'Temporary', value: 'T' },
  { label: 'Other', value: 'O' },
];
