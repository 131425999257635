import { MyBridgeCompoanyAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import {
  ArrowUp2,
  Brifecase,
  ChevronDown,
  Currency,
  Industry,
  Candidates,
  EditJob,
  Eye,
  Email,
  SaveJobs,
} from 'components/common/Icons';
import { CURRENCY } from 'data/data';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { applyJob, getJobsDetail, saveJobs, editJobDetail, getActiveJobs } from 'store/actions/Job.Action';
import { currencyFormatter, convertToSlug } from 'components/utils';

const JobCardIndividual = (props) => {
  const { data, candidateView, actions, index, getcandidatesByJob, status, isSearchResult, saved } = props;
  const [activeMenu, setActiveMenu] = useState(null);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loading, jobApplySuccess } = useSelector((state) => state.jobReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onActiveMenu = (index) => {
    setActiveMenu(index);
  };
  const closeMenu = () => {
    setActiveMenu(null);
  };
  const ref = React.useRef();
  useOnClickOutside(ref, () => setActiveMenu(null));

  const getCurrencyIcon = (name) => {
    let data = CURRENCY.find((item) => item.value === name);
    return data?.symbol;
  };

  const getJobDetails = (id) => {
    dispatch(getJobsDetail(id)).then((res) => {
      if (res) {
        navigate(`/jobs/${convertToSlug(data.title)}-${data.id}`);
      }
    });
  };

  const editJobDetails = (id) => {
    dispatch(editJobDetail(id)).then((res) => {
      if (res) {
        navigate('/post_jobs');
      }
    });
  };

  const applySelectedJob = (jobId) => {
    const payload = {
      candidate_id: userProfileInfo?.id,
      jobs_id: jobId,
      status: 'APPLIED',
    };
    dispatch(applyJob(payload));
    dispatch(getActiveJobs());
  };

  const saveJob = (jobId) => {
    const payload = {
      user_id: userProfileInfo?.id,
      job_id: jobId,
    };
    dispatch(saveJobs(payload));
  };

  function stripHTML(myString) {
    return myString?.replace(/(<([^>]+)>)/gi, '');
  }
  // const getDays = ()=>{
  //   let endDate = moment(data?.published_date).format("DD/MM/YYYY")
  //   console.log(endDate)
  // }
  return (
    <div className="job-card individual">
      <div className="title-row mb-2">
        <div className="company-logo individual cursor-pointer" onClick={() => getJobDetails(data?.id)}>
          <MyBridgeCompoanyAvatar
            icon={data?.hiring_company_details?.image}
            name={data?.hiring_company_details?.name}
            size={65}
            className="company-avatar"
            round={false}
          />
          {/* <img src={getLogo(data?.hiring_company)} alt="logo" /> */}
        </div>
      </div>
      <div>
        <h6 className="job-card--title" onClick={() => getJobDetails(data?.id)}>
          {data?.title}
        </h6>
        <p className="job-card--subtitle">
          {data?.hiring_company_details?.name} {data?.city ? `| ${data?.city}, ${data?.country}` : `| ${data?.country}`}
        </p>
        <div className="job-card--benifits">
          <div className="fs-12 benifits">
            <Currency color="#747474" className="me-1" /> {getCurrencyIcon(data?.currency_choice)}{' '}
            {currencyFormatter(data?.compensation_range_min_amt, data?.currency_choice)} to{' '}
            {currencyFormatter(data?.compensation_range_max_amt, data?.currency_choice)}
          </div>
          <div className="fs-12 benifits">
            {data?.benefits && data?.benefits?.length ? <Industry color="#747474" className="me-1" /> : null}
            {data?.benefits?.map((item, index) =>
              data.benefits.length === index + 1 ? <span key={index}>{item.name} </span> : <span key={index}>{item.name}, </span>,
            )}
          </div>
          <div className="fs-12 benifits">
            <Brifecase color="#747474" className="me-1" /> {data?.employment_type === 'FT' && <>Full-Time</>}
            {data?.employment_type === 'PT' && <>Part-Time</>}
            {data?.employment_type === 'C' && <>Contractor</>}
            {data?.employment_type === 'T' && <>Temporary</>}
            {data?.employment_type === 'O' && <>Other</>}
          </div>
        </div>
        <div className="job-description px-0 individual">
          {!candidateView && !isSearchResult ? (
            <div dangerouslySetInnerHTML={{ __html: data?.job_description }}></div>
          ) : (
            <div className="job-excerpt">{stripHTML(data?.job_description)?.slice(0, 150)}</div>
          )}
        </div>
        {/* <p>{otherDetail}</p> */}
        {!candidateView && !isSearchResult ? (
          <>
            <hr />
            <div className="row">
              <div className="col-4 text-center">
                <p>{data?.days_posted}</p>
                <p>Days Posted</p>
                {data?.job_status === 'close' && <p className="color-red">{'Close'}</p>}
                {data?.job_status === 'active' && <p className="color-success">{'Active'}</p>}
                {data?.job_status === 'draft' && <p className="color-info">{'Draft'}</p>}
              </div>
              <div className="col-4 text-center">
                <p>{data?.total_views}</p>
                <p>Views</p>
              </div>
              <div className="col-4 text-center">
                <p>{50}</p>
                <p>Candidates</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row job-btn-wrapper">
              <div className="col-12 text-center">
                <button type="button" className="btn btn-secondary job-btn" onClick={() => getJobDetails(data?.id)}>
                  View Details
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {!candidateView && !isSearchResult ? (
        <>
          {actions && (
            <span className="action-btn" onClick={activeMenu === index ? closeMenu : () => onActiveMenu(index)}>
              Manage <ChevronDown />
            </span>
          )}
          {actions && activeMenu === index && (
            <div className="action-menu" ref={ref}>
              <div className="links">
                <span className="icon">
                  <ArrowUp2 width={14} height={16} />
                </span>{' '}
                Re-post job
              </div>
              <div className="links" onClick={() => getcandidatesByJob(data?.id)}>
                <span className="icon">
                  <Candidates />
                </span>{' '}
                View candidates(6)
              </div>
              <div className="links" onClick={() => editJobDetails(data?.id)}>
                <span className="icon">
                  <EditJob />{' '}
                </span>
                Edit Job
              </div>
              <div className="links" onClick={() => getJobDetails(data?.id)}>
                <span className="icon">
                  <Eye width={18} height={12} />
                </span>{' '}
                Preview job
              </div>
              <div className="links">
                <span className="icon">
                  <Email />
                </span>{' '}
                Email candidates
              </div>
            </div>
          )}
        </>
      ) : userProfileInfo?.email !== data?.created_by ? (
          <div className="btn-wrapper d-flex gap-2 align-items-center">
              {data.how_to_apply === 'EXTERNAL' && (jobApplySuccess?.status === 'APPLIED' || status === 'APPLIED') ? (
                <a className={'h-36 btn btn-primary'} href={encodeURI(data.how_to_apply_text)} target="_blank">
                  Apply
                </a>
              ) : (
                ((jobApplySuccess?.status === 'APPLIED' && jobApplySuccess?.jobs_id === data?.id) || status === 'APPLIED') ? <div className="text-success">Applied</div> : 
                  <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => applySelectedJob(data?.id)}>
                    Apply
                  </Button>
            )}

            {!saved && saved !== null && (
              <Button variant="btn-secondary" className={'h-36'} onClick={() => saveJob(data?.id)}>
                <SaveJobs width={20} height={20} />
              </Button>
            )}
          </div>
        )
       : null}
    </div>
  );
};
export default JobCardIndividual;
