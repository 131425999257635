import React, { Component, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "./react-draft-wysiwyg.css";


class EditorConvertToHTML extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    toolbarOptions : this.props.toolbarOptions,
    toolbardropDown : this.props.toolbardropDown
  }
  componentDidMount(){
    if(this.props.defaultValue){
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.defaultValue)
          )
        ),
      })
    }
  }
  UNSAFE_componentWillReceiveProps (nextprops){
    if(this.props.defaultValue !== nextprops.defaultValue){
      if(nextprops.defaultValue){this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(nextprops.defaultValue)
          )
        ),
      })}else{
        this.setState({
          editorState: EditorState.createEmpty()
        })
      }
    }
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.setEditorData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    
  };

  render() {
    const { editorState, toolbarOptions, toolbardropDown } = this.state;
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName={this.props.editorClass?this.props.editorClass:"demo-editor"}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: toolbarOptions,
            inline: { inDropdown: toolbardropDown },
            list: { inDropdown: toolbardropDown },
            textAlign: { inDropdown: toolbardropDown },
            link: { inDropdown: toolbardropDown },
            history: { inDropdown: toolbardropDown },
          }}
        />
      </div>
    );
  }
}

export default EditorConvertToHTML


// const EditorConvertToHTML = (props)=>{
//   const [editorState, setEditorState] = useState(props.defaultValue?EditorState.createWithContent(
//     ContentState.createFromBlockArray(
//       convertFromHTML(props.defaultValue)
//     )
//   ):() => EditorState.createEmpty());
//   const [toolbarOptions] = useState(props.toolbarOptions)
//   const [toolbardropDown] = useState(props.toolbardropDown)

//   // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(<p>my text</p>))));

//   const onEditorStateChange = (editorState) => {
//     setEditorState(editorState)
//     props.setEditorData(props.name, draftToHtml(convertToRaw(editorState.getCurrentContent())));
//   };
//   useEffect(()=>{
//     if(props.defaultValue){
//       setEditorState(EditorState.createWithContent(
//         ContentState.createFromBlockArray(
//           convertFromHTML(props.defaultValue)
//         )
//       ))
//     }
//   },[])

  
//   return (<div>
//     <Editor
//       editorState={editorState}
//       wrapperClassName="demo-wrapper"
//       editorClassName={props.editorClass?props.editorClass:"demo-editor"}
//       onEditorStateChange={onEditorStateChange}
//       toolbar={{
//         options: toolbarOptions,
//         inline: { inDropdown: toolbardropDown },
//         list: { inDropdown: toolbardropDown },
//         textAlign: { inDropdown: toolbardropDown },
//         link: { inDropdown: toolbardropDown },
//         history: { inDropdown: toolbardropDown },
//       }}
//     />
//     {/* <textarea
//       disabled
//       value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
//     /> */}
//   </div>)
// }
// export default EditorConvertToHTML