import { Flex } from 'components/core';
import { useContext } from 'react';
import PostContext from 'context/PostContext';
import PostEventAcceptButton, { PostEventAcceptedButton } from './PostEventAcceptButton';
import PostEventIgnoreButton from './PostEventIgnoreButton';
import PostActions from '../PostAction/PostActions';
import { useMutation } from '@tanstack/react-query';
import { Spinner } from 'components/common/Loader';
import { acceptEventApi, ignoreEventApi } from 'api/event';
import { useSelector } from 'react-redux';

const PostHeaderRight = () => {
  const { postData, updatePost } = useContext(PostContext);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { mutate: accepMutate, isLoading: accpetLoading } = useMutation(acceptEventApi, {
    onSuccess: (data) => {
      console.log(data);
      updatePost();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { mutate: ignoreMutate, isLoading: ignoreLoading } = useMutation(ignoreEventApi, {
    onSuccess: (data) => {
      updatePost();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const acceptEvent = () => {
    accepMutate({ postId: postData.id });
  };

  const ignoreEvent = () => {
    ignoreMutate({ postId: postData.id });
  };

  const { type } = postData;
  if (type === 2) {
    const { attendees, author } = postData;
    const userId = userProfileInfo?.id;
    const eventAuthor = author?.id === userId;
    const isAttendee = attendees?.find((el) => el?.user?.id === userId);

    if (eventAuthor) return null;

    if (isAttendee?.status === 'ACCEPT') {
      return <PostEventAcceptedButton>Accepted</PostEventAcceptedButton>;
    }

    if (isAttendee?.status === 'IGNORE') {
      return <PostEventIgnoreButton>Ignored</PostEventIgnoreButton>;
    }

    return (
      <Flex position="absolute" top="12px" right="24px" width="160px" justify="space-between" align="center">
        <PostEventIgnoreButton onClick={ignoreEvent}>{ignoreLoading ? <Spinner /> : 'Ignore'}</PostEventIgnoreButton>
        <PostEventAcceptButton className="btn btn-blue" onClick={acceptEvent}>
          {accpetLoading ? <Spinner /> : 'Accept'}
        </PostEventAcceptButton>
      </Flex>
    );
  }

  return (
    <Flex justify="flex-end">
      <PostActions />
    </Flex>
  );
};

export default PostHeaderRight;
