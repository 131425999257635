import React, { useEffect, useState } from 'react';
import Signin from './Signin/Signin';
import Logo from 'assets/images/MyBridgeWhite.svg';
// import MyCalendarIcon from 'assets/icons/Mycalendar.svg';
import { useLocation } from 'react-router-dom';
import Signup from './Signup/Signup';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { MyCalendarIcon, JobsIcon, EventIcon, FolderIcon } from 'components/common/Icons';
import ResetPassword from './ForgotPassword/ResetPassword';

export default function Authentication() {
  const location = useLocation();
  const path = location.pathname;
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => setMounted(true), 300);
  }, [])

  // const allPaths = ['signup', 'signin', 'forgot-password', 'password_resetconfirm'];
  if (!mounted) return <></>
  return (
    <div className="user-flow">
      <div className="container-fluid vh-100">
        <div className="row p-0">
          <div className="user-flow--bannerSection col-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="user-flow--info newLoginInfoBox">
              <img src={Logo} alt="Logo" className="logo" />
              <h1>Start your journey with us.</h1>
              <h3>and elevate your career. </h3>
              <p>Create your profile to connect, develop and grow in a space created exclusively for professionals.</p>

              <div className="user-flow--bottom">
                <div className="user-flow--iconBox">
                  <div className="iconbox-circle">
                    <div className="iconbox-circle-inner">
                      <MyCalendarIcon />
                    </div>
                  </div>
                  <div className="iconbox-text">myCalendar</div>
                </div>
                <div className="user-flow--iconBox">
                  <div className="iconbox-circle">
                    <div className="iconbox-circle-inner">
                      <JobsIcon color="white" width={30} height={30} />
                    </div>
                  </div>
                  <div className="iconbox-text">myJobs</div>
                </div>
                <div className="user-flow--iconBox">
                  <div className="iconbox-circle">
                    <div className="iconbox-circle-inner">
                      <EventIcon width={30} height={30} color="white" />
                    </div>
                  </div>
                  <div className="iconbox-text">myMeetings</div>
                </div>
                <div className="user-flow--iconBox">
                  <div className="iconbox-circle">
                    <div className="iconbox-circle-inner">
                      <FolderIcon color="white" width={30} height={30} />
                    </div>
                  </div>
                  <div className="iconbox-text">myDisk</div>
                </div>
              </div>
            </div>

            <div className="newLoginInfoBoxBottom">
              <a target="_blank" href="/aboutus">About Us</a>
              <a target="_blank" href="/termscondition">Terms & Conditions</a>
              <a target="_blank" href="/privacypolicy">Privacy Policy</a>
            </div>
          </div>
          <div className="user-flow--formSection col-12 col-lg-6 col-xl-6 col-xxl-6">
            {path.endsWith('signup') && <Signup />}
            {(path.endsWith('signin') || path.endsWith('/')) && <Signin />}
            {path.endsWith('forgot-password') && <ForgotPassword />}
            {path.includes('password_resetconfirm') && <ResetPassword />}

            {/* {!allPaths.find((p) => path.endsWith(p)) && <Signin />} */}
          </div>
        </div>
      </div>
    </div>
  );
}
