import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { getEventsFunc } from 'api/event';

import { EventSecondaryIcon } from 'components/common/Icons';
import { Flex, Text } from 'components/core';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import EventListItem from './EventListItem';
import EventPage from './EventPage';

const EventListPage = () => {
  const isFetch = useRef(true);

  const [eventModalShow, setEventModalShow] = React.useState(false);
  const queryClient = useQueryClient();

  const {
    isLoading,
    data = { pages: [] },
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['getEventList'],
    queryFn: getEventsFunc,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.next) {
        const url = new URL(lastPage.next);
        const page = url.searchParams.get('page');
        return parseInt(page, 10);
      }
      return undefined;
    },
  });

  const handleScroll = async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
    const scrolled = scrollHeight - scrollTop;
    const minThreshold = clientHeight * 4;
    const maxThreshold = clientHeight * 5;
    if (isFetch.current && scrolled < maxThreshold && scrolled > minThreshold) {
      isFetch.current = false;
      console.log('scrollHeight', scrollHeight);
      await fetchNextPage();
      isFetch.current = true;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   if (isLoading) {
  //     return <Loader />;
  //   }

  console.log('data', data);
  return (
    <React.Fragment>
      <div className="container">
        <EventListPageContainer>
          <Flex justify="space-between" align="center">
            <Text.Title level={1} secondary>
              myEvents
            </Text.Title>
            <CreateNewEventButton onClick={() => setEventModalShow(true)}>
              <EventSecondaryIcon /> Create New Event
            </CreateNewEventButton>
          </Flex>
          <EventListPageSubContainer>
            <Text.Title level={3}>Today</Text.Title>
            <Flex wrap="wrap" justify="space-between" align="center">
              {data?.pages?.map((page) => {
                const events = page?.results || [];
                return events.map((event) => <EventListItem event={event} key={event?.id} />);
              })}
            </Flex>
          </EventListPageSubContainer>
        </EventListPageContainer>
        <EventPage
          show={eventModalShow}
          setShow={setEventModalShow}
          onSubmitEvent={(event) => {
            queryClient.invalidateQueries('getEventList');
            setEventModalShow(false);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default EventListPage;

const EventListPageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: #ffffff;
  border-radius: 12px;
  margin: 20px 0px;
`;

const EventListPageSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 12px;
  margin: 20px 0px;
`;

const CreateNewEventButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  width: 182px;
  height: 40px;
  background: #e4e6eb;
  border-radius: 8px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #5b5b5b;

  &:hover {
    background: #dfdfdf;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: #dfdfdf;
  }
`;

const EVENTS = [
  {
    id: 1,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 2,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 3,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 4,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 5,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 6,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 7,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
  {
    id: 8,
    title: 'Do you have a corporate event to plan? This guide will help you pull it off with ease.',
    location: 'Location: Charlotte, North Carolina, United States',
    startDate: '2023-04-11T00:00:00.000Z',
    endDate: '2023-04-11T00:00:00.000Z',
    description:
      'Creating an animation in Figma is a simple process that can be accomplished in just a few steps. First, open up the Figma interface and create a new project. Then, click on the "Layer" icon in the toolbar and select "New Frame."... read more',
    image: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/823/Screenshot_2023-05-05_at_5.20.04_PM.png',
  },
];
