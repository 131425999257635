import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text } from 'components/StyledComponents';
import { MyBridgeAvatar } from 'components/common/Avatar';
import {
  ChatIcon,
  EventIcon,
  HandShakeSimpleIcon,
  DegreeIcon,
  ScheduleIcon,
  MyBridgeIcon,
  UsersIcon,
  FollowersCircleIcon,
} from '../Icons';

import UserPresence from '../UserPresense';
import { useNavigate } from 'react-router-dom';
import { startConversationWithUser, clear } from 'store/slices/connectionSlice';
import { CometChat } from '@cometchat-pro/chat';

import './follower-card.scss';

const FollowerCard = ({ user }) => {
  // console.log(user);
  const {
    id,
    first_name,
    last_name,
    position,
    profile_pic,
    follow_count,
    degree_count,
    connect_count,
    profile_headline,
    location,
  } = user;
  const fullName = `${first_name} ${last_name}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isConnection = true;

  const [modalType, setModalType] = useState('');
  const [callInitiated, setCallInitiated] = useState(false);
  const [callInitiatedText, setCallInitiatedText] = useState('Meet Now');

  const handleStartChat = () => {
    dispatch(startConversationWithUser(user));
  };

  const handleMeetNow = (user) => {
    const receiverID = user.user_uuid;
    const callType = CometChat.CALL_TYPE.VIDEO;
    const receiverType = CometChat.RECEIVER_TYPE.USER;

    const call = new CometChat.Call(receiverID, callType, receiverType);

    CometChat.initiateCall(call).then(
      (outGoingCall) => {
        // console.log('Call initiated successfully:', outGoingCall);
        setCallInitiated(true);
        setCallInitiatedText('Calling');
      },
      (error) => {
        // console.log('Call initialization failed with exception:', error);
        setCallInitiated(false);
        setCallInitiatedText('Meet Now');
      },
    );
  };

  return (
    <>
      <div className="follower-card-container" key={id}>
        <div className="follower-info-container">
          <div className="follower-info-details">
            <div className="icon-status-container">
              <MyBridgeAvatar icon={profile_pic} size={70} name={fullName} />
              <span className={`status`}>
                <UserPresence userId={user?.user_uuid} />
              </span>
            </div>

            <div className="name-position-container">
              <div className="d-flex">
                <Text fS="16px" fW="500">
                  {fullName}{' '}
                </Text>
                {follow_count > 0 && (
                  <div className="d-flex gap-1 align-items-center align-self-end px-1">
                    <MyBridgeIcon width={14} height={14} />
                    <Text color="#4F4F4F">{follow_count}</Text>
                  </div>
                )}
                {/* <div className="d-flex gap-1 align-items-center align-self-end px-1">
                  <MyBridgeIcon width={14} height={14} />
                  <Text color="#4F4F4F">{follow_count || 200}</Text>
                </div> */}
              </div>
              <Text fS="14px" fW="500" color="#747474">
                {position || profile_headline}
              </Text>
              <Text fS="12px" fW="400" color="#747474">
                {location}
              </Text>
            </div>
          </div>
          <div className="follower-actions-container">
            {isConnection ? (
              <button className="gray-button" onClick={handleStartChat}>
                <ChatIcon width={15} height={15} />
              </button>
            ) : (
              <button className="gray-button" onClick={() => setModalType('handshake')}>
                <HandShakeSimpleIcon width={15} height={15} />
              </button>
            )}
            {isConnection ? (
              <>
                <button className="gray-button" onClick={() => handleMeetNow(user)}>
                  <EventIcon width={15} height={15} />
                </button>
              </>
            ) : (
              <button className="blue-button">
                <UsersIcon width={15} height={15} color="#fff" />
                <Text fS="13px" color="#fff"></Text>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowerCard;
