import { useQuery } from '@tanstack/react-query';
import { getIntegrationCallBack } from 'api/microsoft-integration';
import Loader from 'components/common/Loader';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';

const MicrosoftIntegrationCallBack = () => {
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { state, code } = parsed;
  console.log({ state, code });
  const queryStringBuilder = queryString.stringify({
    state,
    code,
  });

  const { data, error, isLoading } = useQuery(['microsoftIntegration', queryStringBuilder], getIntegrationCallBack, {
    onSuccess: (data) => {
      setTimeout(() => {
        window.close();
      }, 5000);
    },
    onError: (error) => {
      setTimeout(() => {
        window.close();
      }, 5000);
    },
  });

  console.log(data, error, isLoading);
  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div className='text-center'>Error Occured while integrating outlook : {error?.message}</div>;
  }

  return <div className='text-center'>Outlook Integration Successfull! You can now close this window or wait for 5 sec. to automatically close.</div>;
};

export default MicrosoftIntegrationCallBack;
