export const PDFIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6097H3.13763C2.30585 20.6097 1.50811 20.2795 0.919755 19.6915C0.331397 19.1035 0.000573243 18.306 0 17.4743V3.13762C0 2.30547 0.330576 1.50741 0.918996 0.918989C1.50741 0.33057 2.30548 0 3.13763 0H10.9958L15.3205 4.3464V17.4743C15.32 18.3057 14.9894 19.1028 14.4015 19.6907C13.8137 20.2786 13.0165 20.6092 12.1851 20.6097Z" fill="#EE3E2C" />
        <g opacity="0.5">
            <path d="M15.3227 4.34187H11.8866C11.653 4.34187 11.429 4.24922 11.2636 4.08423C11.0982 3.91924 11.005 3.69539 11.0044 3.46178V0.00195312" fill="white" />
        </g>
        <path d="M5.16815 10.5574C5.17754 10.7011 5.1542 10.845 5.09989 10.9783C5.04558 11.1117 4.9617 11.231 4.8546 11.3272C4.59736 11.5196 4.27993 11.6139 3.95937 11.5932H3.67609V12.7025H3.0144V9.58431H4.01127C4.31923 9.56272 4.62508 9.64913 4.87622 9.82866C4.97731 9.91976 5.05617 10.0328 5.10678 10.1591C5.15738 10.2855 5.17838 10.4217 5.16815 10.5574ZM3.67609 11.0526H3.89233C4.05366 11.0641 4.21423 11.0215 4.3486 10.9315C4.39895 10.8886 4.43877 10.8347 4.46501 10.7739C4.49125 10.7132 4.50322 10.6472 4.49997 10.5812C4.50519 10.518 4.49647 10.4545 4.47443 10.3951C4.45238 10.3357 4.41754 10.2818 4.37238 10.2374C4.25719 10.1543 4.11605 10.1151 3.97451 10.1271H3.67394L3.67609 11.0526Z" fill="white" />
        <path d="M8.99754 11.1131C9.01269 11.3289 8.98199 11.5455 8.90745 11.7486C8.83291 11.9517 8.71623 12.1368 8.56506 12.2916C8.21135 12.5866 7.75744 12.7338 7.2979 12.7024H6.41565V9.58426H7.39521C7.82812 9.55717 8.25416 9.70255 8.5802 9.98863C8.72443 10.1365 8.83608 10.313 8.90796 10.5066C8.97984 10.7003 9.01036 10.9069 8.99754 11.1131ZM8.31206 11.1304C8.31206 10.46 8.01582 10.127 7.42549 10.127H7.06437V12.1553H7.34764C7.99203 12.1553 8.31206 11.8137 8.31206 11.1304Z" fill="white" />
        <path d="M10.9568 12.7022H10.3081V9.58398H12.0942V10.1267H10.9568V10.929H12.0142V11.4782H10.9568V12.7022Z" fill="white" />
    </svg>
}

export const DOCIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6097H3.13763C2.30585 20.6097 1.50811 20.2795 0.919754 19.6915C0.331397 19.1035 0.000573243 18.306 0 17.4743V3.13762C0 2.30547 0.33057 1.50741 0.918989 0.918989C1.50741 0.33057 2.30548 0 3.13763 0H10.9957L15.3205 4.3464V17.4743C15.3199 18.3057 14.9894 19.1028 14.4015 19.6907C13.8136 20.2786 13.0165 20.6092 12.1851 20.6097Z" fill="#5380C0" />
        <g opacity="0.5">
            <path d="M15.3227 4.34187H11.8866C11.653 4.34187 11.4289 4.24922 11.2636 4.08423C11.0982 3.91924 11.005 3.69539 11.0044 3.46178V0.00195312" fill="white" />
        </g>
        <path d="M5.61363 11.1131C5.62878 11.3289 5.59808 11.5455 5.52354 11.7486C5.449 11.9517 5.33232 12.1368 5.18115 12.2916C4.82855 12.5871 4.37509 12.7343 3.91615 12.7024H3.03174V9.58426H4.01129C4.44421 9.55717 4.87024 9.70255 5.19628 9.98863C5.34051 10.1365 5.45216 10.313 5.52404 10.5066C5.59592 10.7003 5.62645 10.9069 5.61363 11.1131ZM4.92814 11.1304C4.92814 10.46 4.6319 10.127 4.04157 10.127H3.6891V12.1553H3.97237C4.611 12.1553 4.92959 11.8137 4.92814 11.1304Z" fill="white" />
        <path d="M9.08854 11.1389C9.11746 11.5696 8.98053 11.9952 8.7058 12.3282C8.40152 12.5957 8.01026 12.7432 7.60514 12.7432C7.20002 12.7432 6.80878 12.5957 6.50449 12.3282C6.22949 11.9936 6.09259 11.5667 6.12174 11.1346C6.09163 10.7033 6.22955 10.2771 6.50664 9.94525C6.65136 9.80337 6.82441 9.69362 7.01444 9.6232C7.20448 9.55277 7.40724 9.52324 7.60947 9.53656C7.81148 9.52261 8.01412 9.55228 8.20365 9.62354C8.39319 9.6948 8.56519 9.80599 8.70797 9.94957C8.98231 10.2827 9.1185 10.7084 9.08854 11.1389ZM6.81371 11.1389C6.79412 11.4158 6.86384 11.6917 7.01265 11.926C7.08259 12.0147 7.17308 12.0851 7.27631 12.1311C7.37953 12.1771 7.49239 12.1972 7.60514 12.1898C8.13276 12.1898 8.39658 11.8395 8.39658 11.1389C8.39658 10.4383 8.13421 10.0872 7.60947 10.0858C7.49618 10.0783 7.38277 10.0986 7.27912 10.145C7.17547 10.1913 7.08473 10.2623 7.01481 10.3518C6.86525 10.5859 6.79476 10.8618 6.81371 11.1389Z" fill="white" />
        <path d="M11.0584 10.0901C10.9464 10.0849 10.8348 10.1079 10.7339 10.1571C10.633 10.2062 10.5461 10.2798 10.4811 10.3712C10.3313 10.603 10.2594 10.8764 10.2757 11.1519C10.2757 11.8453 10.5366 12.1927 11.0584 12.1941C11.331 12.1841 11.6 12.1286 11.8542 12.0298V12.5834C11.5798 12.6951 11.2855 12.7496 10.9892 12.7434C10.7962 12.7568 10.6026 12.7269 10.4226 12.6558C10.2427 12.5847 10.0809 12.4742 9.94914 12.3325C9.68753 11.9952 9.559 11.5735 9.58801 11.1475C9.58121 10.8537 9.64187 10.5623 9.76533 10.2956C9.87376 10.0615 10.0511 9.86612 10.2735 9.7355C10.5101 9.60216 10.7782 9.53494 11.0498 9.54089C11.3664 9.54272 11.6785 9.61668 11.9623 9.75713L11.7461 10.2956C11.6311 10.2407 11.5135 10.1916 11.3936 10.1485C11.2858 10.1111 11.1726 10.0913 11.0584 10.0901Z" fill="white" />
    </svg>
}
export const PNGIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1829 20.6066H3.13546C2.30389 20.6066 1.50637 20.2762 0.918355 19.6882C0.330342 19.1002 0 18.3027 0 17.4711V3.13449C0 2.30291 0.330342 1.50539 0.918355 0.917379C1.50637 0.329365 2.30389 -0.000976562 3.13546 -0.000976562H10.9957L15.3205 4.34326V17.4711C15.3199 18.3029 14.9891 19.1004 14.4008 19.6884C13.8124 20.2763 13.0147 20.6066 12.1829 20.6066Z" fill="#00AC54" />
        <g opacity="0.5">
            <path d="M15.3205 4.3455H11.8845C11.6505 4.3455 11.4261 4.25255 11.2606 4.08709C11.0951 3.92164 11.0022 3.69723 11.0022 3.46324V0.00341797" fill="white" />
        </g>
        <path d="M5.19835 10.5535C5.20732 10.6973 5.18356 10.8413 5.12888 10.9746C5.0742 11.1079 4.99001 11.2271 4.88264 11.3233C4.62607 11.5153 4.30942 11.6096 3.98957 11.5892H3.70414V12.6985H3.05542V9.58255H4.04796C4.35566 9.56034 4.66149 9.64597 4.91292 9.82474C5.01257 9.91666 5.09005 10.03 5.13949 10.1562C5.18894 10.2825 5.20906 10.4183 5.19835 10.5535ZM3.70414 11.0486H3.92038C4.0817 11.0602 4.24228 11.0176 4.37665 10.9275C4.42792 10.8854 4.46838 10.8316 4.49471 10.7707C4.52103 10.7098 4.53245 10.6435 4.52801 10.5772C4.53323 10.5141 4.52451 10.4505 4.50247 10.3911C4.48042 10.3317 4.44559 10.2779 4.40043 10.2334C4.28523 10.1503 4.14409 10.1112 4.00255 10.1231H3.70199L3.70414 11.0486Z" fill="white" />
        <path d="M8.55014 12.6992H7.71113L6.35531 10.3422H6.33585C6.36397 10.7573 6.37694 11.0557 6.37694 11.2331V12.6992H5.77795V9.58315H6.61047L7.96413 11.9164H7.97926C7.95764 11.512 7.94684 11.2244 7.94684 11.0514V9.57666H8.54149L8.55014 12.6992Z" fill="white" />
        <path d="M10.5199 10.9473H11.7547V12.5647C11.3976 12.6831 11.0237 12.743 10.6475 12.742C10.4479 12.7556 10.2478 12.7255 10.0611 12.6538C9.87434 12.5821 9.7055 12.4705 9.56632 12.3269C9.29839 11.9898 9.16576 11.565 9.19438 11.1354C9.18194 10.9201 9.21392 10.7046 9.28832 10.5023C9.36272 10.2999 9.47795 10.115 9.62686 9.95905C9.78732 9.81094 9.97576 9.69639 10.1811 9.62213C10.3865 9.54786 10.6046 9.51537 10.8227 9.52657C11.1412 9.52547 11.4565 9.59102 11.7482 9.71903L11.5319 10.2467C11.312 10.1353 11.0692 10.0768 10.8227 10.0758C10.694 10.0707 10.5658 10.0937 10.447 10.1434C10.3282 10.1931 10.2217 10.2682 10.135 10.3634C9.95518 10.58 9.86333 10.8564 9.8777 11.1376C9.86193 11.4131 9.93798 11.6861 10.0939 11.9138C10.1661 12.003 10.2584 12.0737 10.3634 12.1199C10.4683 12.1662 10.5828 12.1867 10.6972 12.1798C10.8388 12.1799 10.98 12.1661 11.1189 12.1387V11.49H10.5286L10.5199 10.9473Z" fill="white" />
    </svg>
}

export const XLSIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6097H3.13763C2.30585 20.6097 1.50811 20.2795 0.919754 19.6915C0.331397 19.1035 0.000573243 18.306 0 17.4743V3.13762C0 2.30547 0.33057 1.50741 0.918989 0.918989C1.50741 0.33057 2.30548 0 3.13763 0H10.9979L15.3227 4.3464V17.4743C15.3221 18.306 14.9913 19.1035 14.4029 19.6915C13.8146 20.2795 13.0168 20.6097 12.1851 20.6097Z" fill="#00AC54" />
        <g opacity="0.5">
            <path d="M15.3227 4.34187H11.8866C11.653 4.34187 11.429 4.24922 11.2636 4.08423C11.0982 3.91924 11.005 3.69539 11.0044 3.46178V0.00195312" fill="white" />
        </g>
        <path d="M6.40058 12.7021H5.64591L4.93232 11.5214L4.20793 12.7021H3.49866L4.53444 11.0933L3.56569 9.57959H4.28361L4.95611 10.7019L5.60482 9.57959H6.31841L5.33885 11.1257L6.40058 12.7021Z" fill="white" />
        <path d="M6.79419 12.7022V9.58398H7.44291V12.1551H8.70791V12.7022H6.79419Z" fill="white" />
        <path d="M11.0997 11.8344C11.1051 11.9613 11.0805 12.0878 11.0279 12.2036C10.9753 12.3193 10.8962 12.421 10.7969 12.5004C10.551 12.6753 10.2527 12.7608 9.95144 12.7426C9.64638 12.7481 9.34401 12.6845 9.06702 12.5566V11.9425C9.2396 12.022 9.4182 12.0878 9.60113 12.1393C9.7314 12.1759 9.86586 12.1955 10.0012 12.1976C10.1182 12.2082 10.2358 12.1834 10.3385 12.1263C10.3766 12.0975 10.407 12.0597 10.427 12.0163C10.447 11.9729 10.456 11.9253 10.4531 11.8776C10.4547 11.8196 10.4372 11.7626 10.4034 11.7154C10.3613 11.6609 10.3101 11.6141 10.252 11.577C10.1215 11.4973 9.98645 11.4251 9.84764 11.3608C9.69266 11.2947 9.54722 11.2081 9.41516 11.1035C9.32528 11.0224 9.25187 10.9247 9.19892 10.8159C9.11781 10.6452 9.09295 10.4531 9.12793 10.2674C9.1629 10.0816 9.2559 9.91178 9.39354 9.78225C9.61498 9.6134 9.88972 9.52975 10.1677 9.54655C10.3244 9.54513 10.4807 9.56402 10.6326 9.60277C10.7899 9.6453 10.9439 9.69951 11.0932 9.76495L10.8769 10.2796C10.7431 10.2216 10.605 10.1739 10.4639 10.1369C10.3587 10.1097 10.2504 10.0959 10.1417 10.0958C10.0386 10.0893 9.93659 10.12 9.85413 10.1823C9.82138 10.2106 9.79542 10.2458 9.7782 10.2855C9.76098 10.3252 9.75293 10.3683 9.75465 10.4115C9.75309 10.4649 9.76739 10.5176 9.79574 10.5629C9.82865 10.6134 9.87219 10.6563 9.92333 10.6883C10.0585 10.769 10.1986 10.8412 10.3428 10.9045C10.571 10.9984 10.7756 11.1417 10.9418 11.324C11.0501 11.4717 11.1057 11.6514 11.0997 11.8344Z" fill="white" />
    </svg>


}
export const CSVIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1829 20.6097H3.13546C2.30389 20.6097 1.5064 20.2794 0.918382 19.6914C0.330368 19.1034 0 18.3058 0 17.4743V3.13762C-1.97534e-07 2.30585 0.330298 1.5081 0.91825 0.919748C1.5062 0.331391 2.30369 0.000573243 3.13546 0H10.9958L15.3205 4.3464V17.4743C15.32 18.306 14.9892 19.1035 14.4008 19.6915C13.8124 20.2795 13.0147 20.6097 12.1829 20.6097Z" fill="#00AC54" />
        <g opacity="0.5">
            <path d="M15.3205 4.34187H11.8845C11.6509 4.34187 11.4268 4.24922 11.2614 4.08423C11.096 3.91924 11.0028 3.69539 11.0022 3.46178V0.00195312" fill="white" />
        </g>
        <path d="M5.29787 10.0901C5.18579 10.0849 5.07422 10.1079 4.97335 10.1571C4.87248 10.2062 4.78555 10.2798 4.72052 10.3712C4.57155 10.6032 4.50034 10.8767 4.51725 11.1519C4.51725 11.8453 4.77745 12.1927 5.29787 12.1941C5.57043 12.1841 5.8394 12.1286 6.09364 12.0298V12.5834C5.81924 12.6951 5.52489 12.7496 5.22868 12.7434C5.03565 12.7568 4.84205 12.7269 4.66208 12.6558C4.48211 12.5847 4.32034 12.4742 4.18858 12.3325C3.92806 11.9946 3.79967 11.5733 3.82746 11.1475C3.82066 10.8537 3.88131 10.5623 4.00476 10.2956C4.11319 10.0615 4.2905 9.86612 4.51294 9.7355C4.74956 9.60216 5.01769 9.53494 5.28923 9.54089C5.60589 9.54272 5.91795 9.61668 6.20176 9.75713L5.98552 10.2956C5.87057 10.2407 5.75293 10.1916 5.63305 10.1485C5.52522 10.1111 5.41201 10.0913 5.29787 10.0901Z" fill="white" />
        <path d="M8.59757 11.8347C8.60309 11.9619 8.57835 12.0886 8.52535 12.2044C8.47234 12.3202 8.3926 12.4218 8.29269 12.5007C8.04678 12.6756 7.74841 12.7611 7.44719 12.7429C7.1428 12.7487 6.84109 12.6851 6.56494 12.5569V11.9428C6.73739 12.0227 6.916 12.0885 7.09905 12.1396C7.22928 12.1764 7.36376 12.196 7.49908 12.198C7.61585 12.2049 7.73198 12.1763 7.8321 12.1158C7.87091 12.0875 7.90196 12.0499 7.92237 12.0064C7.94278 11.963 7.95188 11.9151 7.94885 11.8671C7.94963 11.8089 7.93141 11.7519 7.89695 11.7049C7.85514 11.6509 7.80475 11.6042 7.74776 11.5666C7.61694 11.4873 7.48196 11.4151 7.3434 11.3503C7.18842 11.2842 7.04298 11.1976 6.91092 11.093C6.8165 11.013 6.73862 10.9153 6.68169 10.8054C6.62328 10.6864 6.59436 10.5552 6.59736 10.4227C6.59161 10.3004 6.61375 10.1785 6.66208 10.0661C6.71041 9.95375 6.78366 9.85383 6.87631 9.77394C7.09859 9.60511 7.37398 9.5215 7.6526 9.53824C7.80864 9.53681 7.9642 9.55571 8.11536 9.59446C8.27331 9.63732 8.42796 9.69152 8.57811 9.75664L8.36187 10.2713C8.22814 10.213 8.09004 10.1653 7.94885 10.1286C7.84437 10.1012 7.73682 10.0874 7.62882 10.0875C7.5257 10.081 7.42369 10.1117 7.34123 10.174C7.29021 10.2216 7.25657 10.2849 7.2456 10.3539C7.23463 10.4228 7.24697 10.4934 7.28068 10.5546C7.31506 10.6047 7.35918 10.6473 7.41042 10.68C7.54535 10.7612 7.6855 10.8334 7.82993 10.8962C8.0578 10.9907 8.26225 11.1339 8.42892 11.3157C8.54293 11.4643 8.60245 11.6475 8.59757 11.8347Z" fill="white" />
        <path d="M10.933 9.58398H11.6012L10.5416 12.7022H9.81938L8.77063 9.58398H9.4388L10.0248 11.4393C10.0573 11.5496 10.0919 11.6772 10.1265 11.8221C10.1611 11.9669 10.1827 12.0686 10.1913 12.1248C10.2298 11.8931 10.2832 11.6641 10.3513 11.4393L10.933 9.58398Z" fill="white" />
    </svg>
}
export const MP4Icon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6097H3.13763C2.30548 20.6097 1.50741 20.2792 0.918996 19.6907C0.330576 19.1023 0 18.3043 0 17.4721V3.13546C0.000573243 2.30369 0.331397 1.50617 0.919755 0.918223C1.50811 0.330272 2.30585 -1.97534e-07 3.13763 0H10.9958L15.3205 4.34424V17.4721C15.3205 18.3039 14.9903 19.1016 14.4023 19.69C13.8144 20.2783 13.0168 20.6092 12.1851 20.6097Z" fill="#809BBE" />
        <g opacity="0.5">
            <path d="M15.3227 4.34626H11.8866C11.653 4.34626 11.429 4.2536 11.2636 4.08862C11.0982 3.92363 11.005 3.69978 11.0044 3.46617V0.00634766" fill="white" />
        </g>
        <path d="M4.2297 12.7022L3.47935 10.2565H3.45989C3.488 10.7538 3.50097 11.0847 3.50097 11.2512V12.7022H2.91064V9.58398H3.82101L4.55838 11.9626H4.57136L5.35414 9.58398H6.2537V12.7022H5.63741V11.2274C5.63741 11.156 5.63741 11.076 5.63741 10.9852C5.63741 10.8944 5.65039 10.6522 5.66769 10.2608H5.64823L4.84382 12.7022H4.2297Z" fill="white" />
        <path d="M9.17929 10.5531C9.18868 10.6967 9.16534 10.8407 9.11103 10.974C9.05672 11.1074 8.97285 11.2266 8.86574 11.3229C8.6085 11.5153 8.29107 11.6096 7.97052 11.5888H7.68724V12.6981H7.02771V9.58431H8.02241C8.33037 9.56272 8.63622 9.64913 8.88737 9.82866C8.98772 9.9194 9.06615 10.0318 9.11672 10.1573C9.16729 10.2827 9.18868 10.4181 9.17929 10.5531ZM7.68724 11.0482H7.90348C8.06481 11.0598 8.22537 11.0172 8.35974 10.9272C8.41009 10.8842 8.44992 10.8303 8.47616 10.7696C8.5024 10.7089 8.51436 10.6429 8.51111 10.5768C8.51634 10.5137 8.50761 10.4502 8.48557 10.3907C8.46352 10.3313 8.42869 10.2775 8.38353 10.233C8.26833 10.1499 8.12719 10.1108 7.98565 10.1227H7.68508L7.68724 11.0482Z" fill="white" />
        <path d="M11.8001 12.0663H11.426V12.7151H10.7773V12.0663H9.44312V11.6079L10.8097 9.59473H11.4174V11.5539H11.7915L11.8001 12.0663ZM10.7816 11.5539V11.0262C10.7816 10.9376 10.7816 10.81 10.7924 10.6435C10.8033 10.477 10.8054 10.3775 10.8097 10.3494H10.7924C10.7379 10.468 10.6743 10.5822 10.6022 10.6911L10.0313 11.556L10.7816 11.5539Z" fill="white" />
    </svg>

}
export const AVIIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1742 20.6097H3.12682C2.29654 20.6069 1.50124 20.275 0.915162 19.6869C0.329079 19.0988 -4.92943e-06 18.3024 0 17.4721V3.13546C0.000573243 2.30369 0.331397 1.50617 0.919755 0.918223C1.50811 0.330272 2.30585 -1.97534e-07 3.13763 0H10.9849L15.3097 4.34424V17.4721C15.3097 18.3039 14.9794 19.1016 14.3915 19.69C13.8035 20.2783 13.006 20.6092 12.1742 20.6097Z" fill="#809BBE" />
        <g opacity="0.5">
            <path d="M15.3119 4.34626H11.8759C11.6423 4.34626 11.4182 4.2536 11.2528 4.08862C11.0874 3.92363 10.9942 3.69978 10.9937 3.46617V0.00634766" fill="white" />
        </g>
        <path d="M5.96815 12.7015L5.75191 11.9598H4.60585L4.38961 12.7015H3.67603L4.77452 9.57031H5.58325L6.68823 12.7015H5.96815ZM5.58542 11.404C5.36918 10.7315 5.2589 10.3531 5.23295 10.2644C5.207 10.1758 5.18754 10.1066 5.17672 10.0482C5.12915 10.2298 4.99436 10.6796 4.77236 11.3975L5.58542 11.404Z" fill="white" />
        <path d="M8.85057 9.58398H9.51442L8.45485 12.7022H7.73478L6.67737 9.58398H7.35203L7.9402 11.4393C7.97263 11.5496 8.00507 11.6772 8.03967 11.8221C8.07427 11.9669 8.09589 12.0686 8.10454 12.127C8.14376 11.8947 8.1972 11.665 8.26455 11.4393L8.85057 9.58398Z" fill="white" />
        <path d="M9.91016 12.7022V9.58398H10.5718V12.7022H9.91016Z" fill="white" />
    </svg>

}
export const PPTIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6097H3.13763C2.30548 20.6097 1.50741 20.2792 0.918989 19.6907C0.33057 19.1023 0 18.3043 0 17.4721V3.13546C0.000573243 2.30369 0.331397 1.50617 0.919755 0.918223C1.50811 0.330272 2.30585 -1.97534e-07 3.13763 0H10.9979L15.3227 4.34424V17.4721C15.3227 17.8841 15.2415 18.2921 15.0839 18.6728C14.9262 19.0535 14.6951 19.3994 14.4037 19.6907C14.1123 19.9821 13.7664 20.2132 13.3858 20.3709C13.0051 20.5286 12.5971 20.6097 12.1851 20.6097Z" fill="#EE3E2C" />
        <g opacity="0.5">
            <path d="M15.3227 4.34723H11.8866C11.653 4.34723 11.429 4.25458 11.2636 4.08959C11.0982 3.92461 11.005 3.70076 11.0044 3.46715V0.00732422" fill="white" />
        </g>
        <path d="M5.87297 10.5531C5.88236 10.6967 5.85902 10.8407 5.80471 10.974C5.7504 11.1074 5.66654 11.2266 5.55943 11.3229C5.3022 11.5153 4.98476 11.6096 4.6642 11.5888H4.38093V12.6981H3.71924V9.58431H4.7161C5.02405 9.56272 5.32991 9.64913 5.58105 9.82866C5.68141 9.9194 5.75983 10.0318 5.8104 10.1573C5.86097 10.2827 5.88236 10.4181 5.87297 10.5531ZM4.38093 11.0482H4.59717C4.75849 11.0598 4.91906 11.0172 5.05343 10.9272C5.10377 10.8842 5.14359 10.8303 5.16984 10.7696C5.19608 10.7089 5.20804 10.6429 5.2048 10.5768C5.21002 10.5137 5.20129 10.4502 5.17925 10.3907C5.1572 10.3313 5.12237 10.2775 5.07721 10.233C4.96201 10.1499 4.82088 10.1108 4.67934 10.1227H4.37876L4.38093 11.0482Z" fill="white" />
        <path d="M8.60858 10.5531C8.61797 10.6967 8.59463 10.8407 8.54032 10.974C8.486 11.1074 8.40213 11.2266 8.29503 11.3229C8.03779 11.5153 7.72035 11.6096 7.3998 11.5888H7.11652V12.6981H6.45483V9.58431H7.45171C7.75966 9.56272 8.06552 9.64913 8.31666 9.82866C8.41701 9.9194 8.49543 10.0318 8.54601 10.1573C8.59658 10.2827 8.61797 10.4181 8.60858 10.5531ZM7.11652 11.0482H7.33276C7.49409 11.0598 7.65467 11.0172 7.78904 10.9272C7.83938 10.8842 7.8792 10.8303 7.90545 10.7696C7.93169 10.7089 7.94364 10.6429 7.94039 10.5768C7.94562 10.5137 7.9369 10.4502 7.91486 10.3907C7.89281 10.3313 7.85798 10.2775 7.81282 10.233C7.69746 10.1503 7.55644 10.1112 7.41494 10.1227H7.11437L7.11652 11.0482Z" fill="white" />
        <path d="M10.4054 12.7022H9.75671V10.1354H8.90906V9.58398H11.2639V10.1354H10.4162L10.4054 12.7022Z" fill="white" />
    </svg>

}
export const ZIPIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1829 20.6076H3.13546C2.30389 20.6076 1.5064 20.2772 0.918382 19.6892C0.330368 19.1012 0 18.3037 0 17.4721V3.13546C0 2.30389 0.330368 1.50637 0.918382 0.918356C1.5064 0.330342 2.30389 0 3.13546 0H10.9958L15.3205 4.34424V17.4699C15.3205 18.3021 14.99 19.1002 14.4016 19.6886C13.8131 20.277 13.0151 20.6076 12.1829 20.6076Z" fill="#596C89" />
        <g opacity="0.5">
            <path d="M15.3205 4.3443H11.8845C11.6509 4.34431 11.4268 4.25165 11.2614 4.08666C11.096 3.92168 11.0028 3.69783 11.0022 3.46422V0.00439453" fill="white" />
        </g>
        <path d="M6.44381 12.7001H4.12573V12.2676L5.61995 10.1247H4.16464V9.57764H6.4049V10.0101L4.91068 12.153H6.44381V12.7001Z" fill="white" />
        <path d="M6.94128 12.7002V9.58203H7.60296V12.7002H6.94128Z" fill="white" />
        <path d="M10.5395 10.5506C10.5488 10.6943 10.5255 10.8382 10.4712 10.9716C10.4169 11.1049 10.333 11.2242 10.2259 11.3204C9.96865 11.5128 9.65121 11.6071 9.33066 11.5864H9.0474V12.6957H8.39868V9.58186H9.39338C9.70133 9.56028 10.0072 9.64668 10.2583 9.82622C10.3567 9.91798 10.4331 10.0308 10.4818 10.1562C10.5305 10.2817 10.5502 10.4165 10.5395 10.5506ZM9.0474 11.0458H9.26364C9.42496 11.0574 9.58553 11.0147 9.7199 10.9247C9.77024 10.8818 9.81006 10.8279 9.83631 10.7672C9.86255 10.7064 9.8745 10.6405 9.87125 10.5744C9.87648 10.5112 9.86778 10.4477 9.84573 10.3883C9.82368 10.3289 9.78884 10.275 9.74368 10.2306C9.62848 10.1475 9.48735 10.1084 9.34581 10.1203H9.04523L9.0474 11.0458Z" fill="white" />
    </svg>

}
export const FILEIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1742 20.6097H3.12682C2.29692 20.6069 1.50195 20.2753 0.915927 19.6877C0.329906 19.1 0.000567028 18.3042 0 17.4743V3.13762C0 2.30547 0.33057 1.50741 0.918989 0.918989C1.50741 0.33057 2.30548 0 3.13763 0H10.9849L15.3097 4.3464V17.4743C15.3091 18.3057 14.9786 19.1028 14.3907 19.6907C13.8028 20.2786 13.0056 20.6092 12.1742 20.6097Z" fill="#5380C0" />
        <g opacity="0.5">
            <path d="M15.3119 4.34187H11.8759C11.6423 4.34187 11.4182 4.24922 11.2528 4.08423C11.0874 3.91924 10.9942 3.69539 10.9937 3.46178V0.00195312" fill="white" />
        </g>
        <path d="M12.6586 7.71338H2.64026V8.3621H12.6586V7.71338Z" fill="white" />
        <path d="M12.6586 10.0449H2.64026V10.6936H12.6586V10.0449Z" fill="white" />
        <path d="M12.6586 12.3755H2.64026V13.0242H12.6586V12.3755Z" fill="white" />
        <path d="M7.64835 14.7065H2.64026V15.3553H7.64835V14.7065Z" fill="white" />
    </svg>


}
export const JPGIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6021H3.13763C2.30585 20.6021 1.50811 20.2719 0.919755 19.6839C0.331397 19.096 0.000573243 18.2984 0 17.4667V3.13003C0.00343173 2.30013 0.335521 1.50538 0.923569 0.919767C1.51162 0.334151 2.30772 0.005364 3.13763 0.00537109H10.9958L15.3205 4.34962V17.4775C15.3171 18.307 14.9853 19.1014 14.3977 19.687C13.8102 20.2725 13.0146 20.6016 12.1851 20.6021Z" fill="#00AC54" />
        <g opacity="0.5">
            <path d="M15.3227 4.3411H11.8866C11.6527 4.3411 11.4283 4.24815 11.2628 4.0827C11.0973 3.91724 11.0044 3.69283 11.0044 3.45885V-0.000976562" fill="white" />
        </g>
        <path d="M3.77119 13.6108C3.63984 13.6135 3.5088 13.5975 3.38196 13.5633V13.014C3.48364 13.0409 3.58819 13.0554 3.69334 13.0573C3.75254 13.0629 3.81218 13.0533 3.86666 13.0294C3.92114 13.0056 3.96865 12.9683 4.00473 12.921C4.07822 12.7894 4.11061 12.6388 4.09771 12.4886V9.56934H4.7594V12.4842C4.78009 12.7831 4.69089 13.0791 4.50856 13.3168C4.41526 13.4175 4.30066 13.4962 4.1731 13.5471C4.04554 13.598 3.90823 13.6197 3.77119 13.6108Z" fill="white" />
        <path d="M7.69587 10.5491C7.70527 10.6927 7.68192 10.8367 7.62761 10.97C7.57329 11.1034 7.48943 11.2226 7.38233 11.3189C7.12509 11.5113 6.80765 11.6056 6.48709 11.5848H6.1995V12.6942H5.55078V9.57815H6.54548C6.85318 9.55594 7.15901 9.64157 7.41044 9.82034C7.51022 9.91217 7.58777 10.0255 7.63723 10.1518C7.68668 10.278 7.70674 10.4139 7.69587 10.5491ZM6.20382 11.0442H6.42006C6.58139 11.0558 6.74196 11.0132 6.87633 10.9232C6.92667 10.8802 6.96649 10.8263 6.99273 10.7656C7.01898 10.7049 7.03093 10.6389 7.02769 10.5728C7.03291 10.5097 7.0242 10.4462 7.00215 10.3867C6.98011 10.3273 6.94527 10.2735 6.90011 10.229C6.78491 10.1459 6.64377 10.1068 6.50223 10.1188H6.1995L6.20382 11.0442Z" fill="white" />
        <path d="M9.46906 10.9429H10.7059V12.5604C10.5212 12.6214 10.3317 12.667 10.1394 12.6966C9.95984 12.7246 9.77836 12.7383 9.59664 12.7377C9.39702 12.7519 9.19672 12.7221 9.00989 12.6503C8.82306 12.5786 8.65427 12.4667 8.51545 12.3225C8.24674 11.9858 8.11335 11.5609 8.14136 11.1311C8.12892 10.9158 8.1609 10.7003 8.2353 10.4979C8.3097 10.2956 8.42493 10.1107 8.57384 9.95472C8.73463 9.80644 8.92343 9.6918 9.12916 9.61753C9.33488 9.54326 9.55337 9.51087 9.77179 9.52224C10.0903 9.52133 10.4055 9.58688 10.6973 9.7147L10.4811 10.2423C10.2611 10.131 10.0183 10.0725 9.77179 10.0715C9.64317 10.0666 9.51503 10.0898 9.39629 10.1395C9.27754 10.1892 9.17103 10.2641 9.08416 10.3591C8.90431 10.5757 8.81246 10.852 8.82683 11.1332C8.81106 11.4088 8.88711 11.6818 9.04307 11.9095C9.1154 11.999 9.20812 12.0698 9.31347 12.1161C9.41881 12.1624 9.53371 12.1828 9.64854 12.1755C9.78938 12.1755 9.92988 12.1617 10.068 12.1344V11.4857H9.47771L9.46906 10.9429Z" fill="white" />
    </svg>

}
export const SVGIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1742 20.6021H3.12682C2.29692 20.5993 1.50195 20.2677 0.915927 19.6801C0.329906 19.0924 0.000567028 18.2966 0 17.4667V3.13003C0.00341911 2.302 0.334015 1.50889 0.919728 0.923581C1.50544 0.338273 2.29879 0.00821737 3.12682 0.00537109H10.9849L15.3097 4.34962V17.4775C15.3063 18.307 14.9745 19.1014 14.3869 19.687C13.7993 20.2725 13.0038 20.6016 12.1742 20.6021Z" fill="#00AC54" />
        <g opacity="0.5">
            <path d="M15.3119 4.3411H11.8759C11.6419 4.3411 11.4175 4.24815 11.2521 4.0827C11.0866 3.91724 10.9937 3.69283 10.9937 3.45885V-0.000976562" fill="white" />
        </g>
        <path d="M5.44265 10.9429H6.67955V12.5604C6.49481 12.6214 6.30531 12.667 6.113 12.6966C5.93346 12.7247 5.75196 12.7384 5.57024 12.7377C5.37066 12.7514 5.17049 12.7214 4.98373 12.6497C4.79697 12.578 4.62814 12.4663 4.48905 12.3225C4.22112 11.9855 4.08849 11.5606 4.11711 11.131C4.10467 10.9158 4.13665 10.7003 4.21105 10.4979C4.28545 10.2955 4.40068 10.1106 4.54959 9.9547C4.70998 9.80649 4.89841 9.69187 5.10378 9.61759C5.30914 9.54332 5.5273 9.5109 5.74539 9.52222C6.06394 9.52112 6.37919 9.58668 6.67089 9.71468L6.45465 10.2423C6.23394 10.131 5.9904 10.0725 5.74323 10.0715C5.61489 10.0663 5.48699 10.0894 5.36855 10.1391C5.25011 10.1888 5.14402 10.2639 5.05776 10.3591C4.87696 10.5752 4.78498 10.8519 4.80043 11.1332C4.78466 11.4088 4.86071 11.6818 5.01667 11.9095C5.08878 11.9987 5.18115 12.0693 5.28608 12.1156C5.39102 12.1619 5.5055 12.1824 5.61997 12.1755C5.76082 12.1756 5.90134 12.1619 6.03948 12.1344V11.4857H5.44915L5.44265 10.9429Z" fill="white" />
        <path d="M7.42139 12.6946V9.57861H8.08308V12.6946H7.42139Z" fill="white" />
        <path d="M9.51456 12.6946H8.86584V9.57861H10.652V10.1192H9.51456V10.9236H10.572V11.4664H9.51456V12.6946Z" fill="white" />
    </svg>

}
export const MP3Icon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6044H3.13546C2.30389 20.6044 1.50637 20.2741 0.918355 19.6861C0.330342 19.0981 0 18.3005 0 17.469V3.13449C0 2.30291 0.330342 1.50539 0.918355 0.917379C1.50637 0.329365 2.30389 -0.000976562 3.13546 -0.000976562H10.9957L15.3205 4.34327V17.4711C15.3199 18.3023 14.9894 19.0993 14.4014 19.6868C13.8134 20.2744 13.0163 20.6044 12.1851 20.6044Z" fill="#9954A1" />
        <g opacity="0.5">
            <path d="M15.3205 4.34306H11.8844C11.6506 4.34249 11.4266 4.24935 11.2612 4.08402C11.0959 3.91869 11.0028 3.69461 11.0022 3.4608V0.000976562" fill="white" />
        </g>
        <path d="M4.37017 12.6961L3.61766 10.2504H3.60037C3.62632 10.7478 3.63928 11.0808 3.63928 11.2473V12.6961H3.04895V9.58008H3.94851L4.68587 11.9587H4.69885L5.48164 9.58008H6.3812V12.6961H5.76492V11.2213C5.76492 11.1521 5.76492 11.07 5.76492 10.9792C5.76492 10.8883 5.77571 10.6483 5.79301 10.2547H5.77356L4.97132 12.6961H4.37017Z" fill="white" />
        <path d="M9.31987 10.551C9.32883 10.6948 9.30507 10.8388 9.25039 10.9722C9.19571 11.1055 9.11154 11.2247 9.00417 11.3208C8.74777 11.5132 8.43099 11.6076 8.1111 11.5868H7.82782V12.6961H7.16614V9.58011H8.16083C8.46853 9.5579 8.77436 9.64353 9.02579 9.82229C9.127 9.91339 9.20608 10.0264 9.25705 10.1527C9.30801 10.279 9.3295 10.4152 9.31987 10.551ZM7.82782 11.0462H8.04405C8.20541 11.0581 8.36608 11.0155 8.50031 10.9251C8.55158 10.883 8.59206 10.8292 8.61839 10.7683C8.64471 10.7073 8.65614 10.641 8.6517 10.5748C8.6572 10.5118 8.64882 10.4484 8.62715 10.389C8.60548 10.3296 8.57103 10.2756 8.52626 10.231C8.4109 10.1482 8.2699 10.1091 8.12839 10.1207H7.82782V11.0462Z" fill="white" />
        <path d="M11.7483 10.2703C11.7532 10.4524 11.69 10.6298 11.571 10.7677C11.4393 10.912 11.2651 11.0104 11.0736 11.0488V11.0618C11.2841 11.0752 11.4849 11.1555 11.6466 11.291C11.7113 11.3548 11.7618 11.4314 11.795 11.516C11.8281 11.6005 11.8432 11.6911 11.8391 11.7818C11.8456 11.9163 11.8192 12.0504 11.7623 12.1725C11.7054 12.2945 11.6197 12.4009 11.5126 12.4825C11.2386 12.666 10.9118 12.7541 10.5827 12.7333C10.2738 12.7391 9.96696 12.6817 9.68103 12.5646V12.0002C9.80856 12.0649 9.94268 12.1157 10.0811 12.1516C10.2223 12.1884 10.3676 12.2073 10.5135 12.2078C10.6817 12.2202 10.8497 12.1818 10.9958 12.0976C11.0495 12.0559 11.0919 12.0013 11.1191 11.939C11.1463 11.8767 11.1574 11.8085 11.1514 11.7408C11.1553 11.677 11.1403 11.6135 11.1084 11.5581C11.0765 11.5028 11.0291 11.458 10.972 11.4294C10.7906 11.3577 10.5957 11.3267 10.4011 11.3386H10.1632V10.8326H10.4054C10.5872 10.8449 10.7691 10.8122 10.9352 10.7374C10.9907 10.7046 11.0359 10.6567 11.0653 10.5993C11.0948 10.5419 11.1074 10.4773 11.1017 10.413C11.1017 10.1773 10.9547 10.0584 10.6692 10.0584C10.5633 10.0591 10.4582 10.0766 10.3579 10.1103C10.2325 10.1539 10.1133 10.2135 10.0032 10.2876L9.69832 9.83353C9.99608 9.62456 10.3532 9.51691 10.7168 9.52648C10.9855 9.51131 11.2523 9.58008 11.4801 9.72325C11.5679 9.78383 11.6387 9.86597 11.6857 9.96176C11.7326 10.0576 11.7542 10.1638 11.7483 10.2703Z" fill="white" />
    </svg>

}
export const WAVIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6044H3.13546C2.30389 20.6044 1.50637 20.2741 0.918355 19.6861C0.330342 19.0981 0 18.3005 0 17.469V3.13449C0 2.30291 0.330342 1.50539 0.918355 0.917379C1.50637 0.329365 2.30389 -0.000976562 3.13546 -0.000976562H10.9979L15.3227 4.34327V17.4711C15.3224 17.8829 15.241 18.2905 15.0832 18.6709C14.9254 19.0512 14.6942 19.3966 14.4028 19.6876C14.1114 19.9786 13.7656 20.2093 13.3851 20.3666C13.0046 20.5239 12.5968 20.6047 12.1851 20.6044Z" fill="#9954A1" />
        <g opacity="0.5">
            <path d="M15.3226 4.34306H11.8844C11.7686 4.34306 11.6539 4.32023 11.5468 4.27589C11.4398 4.23155 11.3425 4.16658 11.2606 4.08465C11.1787 4.00273 11.1137 3.90546 11.0693 3.79842C11.025 3.69138 11.0022 3.57666 11.0022 3.4608V0.000976562" fill="white" />
        </g>
        <path d="M5.94016 12.6961H5.18765L4.75517 11.0592C4.74004 11.0008 4.71409 10.8797 4.67516 10.698C4.63624 10.5164 4.61677 10.3931 4.61028 10.3304C4.60163 10.4068 4.58002 10.5301 4.54543 10.7002C4.51083 10.8703 4.48488 10.9914 4.46758 11.0635L4.04807 12.6961H3.29772L2.50195 9.58008H3.15067L3.54857 11.2797C3.61776 11.5954 3.66965 11.8657 3.69993 12.0971C3.70857 12.0149 3.72804 11.8895 3.75831 11.7208C3.78859 11.55 3.81887 11.4181 3.84481 11.3251L4.29891 9.58008H4.9433L5.3974 11.3251C5.41686 11.403 5.4428 11.5219 5.47307 11.6841C5.50335 11.8463 5.52498 11.9825 5.54012 12.0971C5.55526 11.9868 5.57687 11.8484 5.60931 11.6819C5.64174 11.5154 5.66769 11.3835 5.69364 11.2797L6.08938 9.58008H6.73809L5.94016 12.6961Z" fill="white" />
        <path d="M9.03438 12.6968L8.81814 11.9551H7.68073L7.46449 12.6968H6.75305L7.8537 9.56787H8.66243L9.76527 12.6968H9.03438ZM8.65164 11.3994C8.4426 10.7276 8.32439 10.3471 8.297 10.2577C8.27105 10.169 8.25159 10.0998 8.24078 10.0414C8.19537 10.2252 8.05914 10.675 7.83858 11.3929L8.65164 11.3994Z" fill="white" />
        <path d="M11.9168 9.58008H12.5849L11.5254 12.6961H10.8031L9.74573 9.58008H10.4139L10.9999 11.4332C11.0324 11.5435 11.067 11.6711 11.1015 11.816C11.1361 11.9609 11.1578 12.0625 11.1664 12.1209C11.2046 11.8884 11.258 11.6587 11.3264 11.4332L11.9168 9.58008Z" fill="white" />
    </svg>

}
export const AIIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6088H3.13546C2.30369 20.6082 1.50618 20.2774 0.918227 19.689C0.330275 19.1006 -1.97534e-07 18.3029 0 17.4711V3.13449C0 2.30291 0.330342 1.50539 0.918355 0.917379C1.50637 0.329365 2.30389 -0.000976562 3.13546 -0.000976562H10.9958L15.3205 4.34326V17.4711C15.3205 18.3029 14.9903 19.1006 14.4023 19.689C13.8144 20.2774 13.0168 20.6082 12.1851 20.6088Z" fill="#F47B20" />
        <g opacity="0.5">
            <path d="M15.3205 4.3455H11.8802C11.6462 4.3455 11.4218 4.25255 11.2563 4.08709C11.0909 3.92164 10.9979 3.69723 10.9979 3.46324V0.00341797" fill="white" />
        </g>
        <path d="M7.6592 13.9783L7.27862 12.7263H5.36058L4.98 13.9783H3.77771L5.6352 8.69775H6.99751L8.85284 13.9783H7.6592ZM7.01048 11.79C6.65873 10.6569 6.46051 10.0154 6.41582 9.86545C6.37041 9.71624 6.33798 9.59947 6.32068 9.51298C6.23995 9.82004 6.0129 10.579 5.63953 11.79H7.01048Z" fill="white" />
        <path d="M9.52319 13.9789V8.71777H10.639V13.9789H9.52319Z" fill="white" />
    </svg>
}

export const PSDIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6088H3.13763C2.30548 20.6088 1.50741 20.2782 0.918996 19.6898C0.330576 19.1013 0 18.3033 0 17.4711V3.13449C0.000573243 2.30271 0.331397 1.5052 0.919754 0.917247C1.50811 0.329295 2.30585 -0.00097676 3.13763 -0.000976562H10.9958L15.3205 4.34326V17.4711C15.3205 18.3029 14.9903 19.1006 14.4023 19.689C13.8144 20.2774 13.0168 20.6082 12.1851 20.6088Z" fill="#3A6BB4" />
        <g opacity="0.5">
            <path d="M15.3227 4.3455H11.8866C11.6527 4.3455 11.4283 4.25255 11.2628 4.08709C11.0973 3.92164 11.0044 3.69723 11.0044 3.46324V0.00341797" fill="white" />
        </g>
        <path d="M7.49693 9.87501C7.51263 10.1258 7.47138 10.3768 7.37626 10.6094C7.28114 10.8419 7.13462 11.05 6.94768 11.2179C6.58224 11.5271 6.0611 11.6828 5.3886 11.6828H4.89341V13.6159H3.73438V8.17969H5.46429C6.12454 8.17969 6.62549 8.32385 6.96714 8.61217C7.14625 8.76804 7.28717 8.96298 7.37902 9.18193C7.47088 9.40088 7.51122 9.638 7.49693 9.87501ZM4.90206 10.74H5.28048C5.56249 10.7593 5.8428 10.6831 6.07624 10.5237C6.16544 10.4498 6.23585 10.3558 6.2817 10.2495C6.32756 10.1431 6.34756 10.0274 6.34005 9.91176C6.34969 9.80221 6.33534 9.69187 6.298 9.58842C6.26065 9.48498 6.20121 9.39092 6.12381 9.31279C5.92258 9.16836 5.67656 9.10015 5.42969 9.12033H4.90639L4.90206 10.74Z" fill="white" />
        <path d="M11.3266 12.3742C11.339 12.5593 11.3061 12.7447 11.2308 12.9142C11.1554 13.0838 11.0399 13.2324 10.8941 13.3473C10.5979 13.5635 10.1546 13.6824 9.56427 13.6824C9.3049 13.6863 9.04574 13.6661 8.79013 13.6219C8.58455 13.583 8.38373 13.5221 8.19115 13.4403V12.5039C8.42062 12.6092 8.65955 12.6925 8.90474 12.7526C9.1318 12.8149 9.36565 12.849 9.60103 12.8542C10.0119 12.8542 10.2195 12.7353 10.2195 12.4975C10.2207 12.4578 10.2141 12.4182 10.2 12.3811C10.1859 12.344 10.1646 12.3101 10.1373 12.2812C10.0525 12.2045 9.95714 12.1404 9.85402 12.0909C9.71996 12.0217 9.54264 11.9396 9.31776 11.8444C9.06957 11.7488 8.83365 11.6239 8.61498 11.4725C8.47043 11.3711 8.35369 11.235 8.27548 11.0768C8.20314 10.9045 8.16848 10.7187 8.17385 10.5318C8.16579 10.3627 8.20133 10.1943 8.27706 10.0428C8.35279 9.89139 8.46617 9.76193 8.60633 9.66689C8.9717 9.44401 9.39655 9.33837 9.82375 9.36416C10.3285 9.36546 10.8268 9.47766 11.2834 9.69283L10.9417 10.5102C10.7341 10.4208 10.5395 10.3488 10.3579 10.294C10.1787 10.2379 9.99203 10.2095 9.80429 10.2096C9.46912 10.2096 9.30262 10.3005 9.30262 10.4799C9.30516 10.5343 9.32134 10.5872 9.34968 10.6337C9.37803 10.6802 9.41762 10.7188 9.4648 10.7459C9.69067 10.8761 9.92723 10.9868 10.1719 11.0768C10.4251 11.1685 10.6656 11.292 10.8876 11.4444C11.0283 11.5473 11.1424 11.6822 11.2207 11.8379C11.296 12.0064 11.3322 12.1897 11.3266 12.3742Z" fill="white" />
    </svg>

}

export const CODEIcon = ({ width, height }) => {
    return <svg width={width ?? "16"} height={height ?? "21"} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1851 20.6088H3.13763C2.30548 20.6088 1.50741 20.2782 0.918989 19.6898C0.33057 19.1013 0 18.3033 0 17.4711V3.13449C0.000573243 2.30271 0.331397 1.5052 0.919754 0.917247C1.50811 0.329295 2.30585 -0.00097676 3.13763 -0.000976562H10.9957L15.3205 4.34326V17.4711C15.3205 18.3029 14.9902 19.1006 14.4023 19.689C13.8143 20.2774 13.0168 20.6082 12.1851 20.6088Z" fill="#596C89" />
        <g opacity="0.5">
            <path d="M15.3227 4.3455H11.8866C11.6527 4.3455 11.4283 4.25255 11.2628 4.08709C11.0973 3.92164 11.0044 3.69723 11.0044 3.46324V0.00341797" fill="white" />
        </g>
        <path d="M5.41025 12.783L2.62292 11.5439V11.0769L5.41025 9.68213V10.4995L3.70195 11.2801L5.41025 11.9764V12.783Z" fill="white" />
        <path d="M10.3729 12.783L13.1602 11.5439V11.0769L10.3729 9.68213V10.4995L12.0812 11.2801L10.3729 11.9764V12.783Z" fill="white" />
        <path d="M9.41718 8.5708L7.31966 14.3379H6.36389L8.46141 8.5708H9.41718Z" fill="white" />
    </svg>
}