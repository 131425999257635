import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "store/common/api";
import { APIEndPoints } from "store/common/endPoint";
import axiosInstance from 'store/common/axios';
import { jsonToFormData } from "utils";
const token = localStorage.getItem('access');


const initialState = {
  onboardingStatus: null,
  currStepData: null,
  connections: null,
  loading: false,
  externalAction:false,
  integrationData:null,
};

export const getOnboardingStatus = createAsyncThunk('getOnboardingStatus', async (body) => {
  const obj = {
    url: APIEndPoints.GetStatus(body.emailId),
    isNoToken: true,
    method: 'GET'
  }

  return await API(obj);
});

export const setOnboardingData = createAsyncThunk('setOnboardingData', async (payload) => {
  try {
    const headers = (payload.isFormData) ? {
      "Content-type": "multipart/form-data; boundary=63c5979328c44e2c869349443a94200e",
      Authorization: `Bearer ${token}`
    } : {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`
    }
    const formData = (payload.isFormData) ? jsonToFormData(payload.data) : JSON.stringify(payload.data);
    const response = await axiosInstance.post(`${APIEndPoints.SetOnboardingData(payload.step, payload.emailId)}`, formData, { headers });
    return response
  } catch (error) {
    return error
  }
})

export const getOnboardingData = createAsyncThunk('getOnboardingData', async (body) => {

  const obj = {
    url: APIEndPoints.SetOnboardingData(body.step, body.emailId),
    method: 'GET'
  };

  return await API(obj);
})

export const getOnboardingConnections = createAsyncThunk('getOnboardingConnections', async (body) => {

  const obj = {
    url: APIEndPoints.SetOnboardingData(body.step, body.emailId),
    method: 'GET'
  };

  return await API(obj);
})


export const resendEmail = createAsyncThunk('resendEmail', async (body) => {
  const obj = {
    url: APIEndPoints.Resend(body.emailId),
    method: 'POST',
    body: body,
    isNoToken: true
  };

  return await API(obj);
})

export const clearOnboardingData = createAction('clearOnboardingData')
export const closeExternalPops = createAction('closeExternalPops');
export const showIntegrationModal = createAction('showIntegrationModal');



const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  extraReducers: {
    [getOnboardingConnections.pending]: (state, action) => {
      state.loading = true
    },
    [getOnboardingConnections.fulfilled]: (state, action) => {
      state.connections = action.payload;
      state.loading = false
    },
    [getOnboardingConnections.rejected]: (state, action) => {
      state.loading = false
      state.error = 'Try again later or contact customer support';
    },

    [getOnboardingStatus.pending]: (state, action) => {
    },
    [getOnboardingStatus.fulfilled]: (state, action) => {
      state.onboardingStatus = action.payload;
    },
    [getOnboardingStatus.rejected]: (state, action) => {
      state.error = 'Try again later or contact customer support';
    },

    [getOnboardingData.pending]: (state, action) => {
      state.loading = true;
    },
    [getOnboardingData.fulfilled]: (state, action) => {
      state.currStepData = action.payload;
      state.loading = false;
    },
    [getOnboardingData.rejected]: (state, action) => {
      state.error = 'Try again later or contact customer support';
    },

    [setOnboardingData.pending]: (state, action) => {
      state.loading = true;
    },
    [setOnboardingData.fulfilled]: (state, action) => {
      state.currStepData = action.payload;
      state.loading = false;
    },
    [setOnboardingData.rejected]: (state, action) => {
      state.error = 'Try again later or contact customer support';
    },
    [clearOnboardingData]: (state, action) => {
      state.currStepData = null;
    },
    [closeExternalPops]: (state, action) => {
      state.externalAction = true;
    },
  }
});

export default onboardingSlice.reducer;