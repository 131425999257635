import React, { useEffect, useState } from 'react';
import Layout from 'components/Layouts';
import Card from 'components/common/Card';
import { useInbox } from '@trycourier/react-hooks';
import Dropdown from 'react-bootstrap/Dropdown';
import { isBeforeToday, isToday } from 'components/utils';
import {
  ReportBugIcon,
  MenuThreeDots,
  SimpleTickIcon,
  DropNotifIcon,
  SettingsNewIcon,
} from 'components/Layouts/assets/icons';
import SectionLoader from 'components/common/Loader/sectionLoader';
import NotificationCard from 'components/common/Notification/NotificationCard';
import { EmptyNotifIcon } from 'components/Layouts/assets/icons';

const NotificationPage = (props) => {
  const [todayNotifs, setTodayNotifs] = useState([]);
  const [earlierNotifs, setEarlierNotifs] = useState([]);
  const [unreadMessage, setUnreadMessage] = useState([]);
  const [unreadListActive, setUnreadListActive] = useState(false);

  const inbox = useInbox();

  useEffect(() => {
    inbox.fetchMessages();
    if (inbox) {
      setTodayNotifs([]);
      setEarlierNotifs([]);
      setUnreadMessage([]);
      inbox?.messages?.forEach((message) => {
        if (isToday(message.created)) setTodayNotifs((prevState) => [message, ...prevState]);
        else if (isBeforeToday(message.created)) setEarlierNotifs((prevState) => [message, ...prevState]);
        if (!message.read) setUnreadMessage((prevState) => [message, ...prevState]);
      });
    }
  }, []);


  return (
    <React.Fragment>
      <section className="container py-4">
        <div className="row">
          <div className="col-12 col-md-8 col-xl-9">
            <h4 className="user-settings--main-header">Notifications</h4>

            <Card className="p-3">
              <div className="header-btn-container my-3">
                <button className={!unreadListActive ? 'active' : ''} onClick={() => setUnreadListActive(false)}>
                  All
                </button>
                <button className={unreadListActive ? 'active' : ''} onClick={() => setUnreadListActive(true)}>
                  Unread
                </button>
              </div>

              <div className="page-body">
                {!unreadListActive ? (
                  inbox ? (
                    <React.Fragment>
                      {todayNotifs?.length > 0 && (
                        <>
                          <div className="block-header mb-3">
                            <p><b>New</b></p>
                          </div>
                          {todayNotifs?.reverse().map((message, i) => (
                            <NotificationCard key={i} details={message} />
                          ))}
                        </>
                      )}
                      {earlierNotifs?.length > 0 ? (
                        <>
                          <div className="block-header mb-3">
                            <p><b>Earlier</b></p>
                          </div>
                          {earlierNotifs?.reverse().map((message, i) => (
                            <NotificationCard key={i} details={message} />
                          ))}
                        </>
                      ) : (
                        <div className="px-3 py-5 text-center"><EmptyNotifIcon /></div>
                      )}
                    </React.Fragment>
                  ) : (
                    <SectionLoader />
                  )
                ) : unreadMessage?.length > 0 ? (
                  <>
                    <div className="block-header">
                      <p>New</p>
                    </div>
                    {todayNotifs?.reverse().map((message, i) => (
                      <NotificationCard key={i} details={message} />
                    ))}
                  </>
                ) : (
                  <div className="px-3 py-5 text-center"><EmptyNotifIcon /></div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default NotificationPage;
