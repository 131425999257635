import Excerpt from 'components/PostCard/PostBody/Excerpt';
import Modal from 'components/common/Modal/Modal';
import { Flex } from 'components/core';
import moment from 'moment';
import EditEvent from 'pages/CreatePost/EditEvent';
import EventJoinButton from 'pages/CreatePost/EventJoinButton';
import SeeParticipants from 'pages/CreatePost/SeeParticipantsButton';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import EventForm from './EventForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { editEventFunc } from 'api/event';
import { getPostFunc } from 'api/post';

const EventListItem = ({ event }) => {
  const [eventState, setEventState] = React.useState(event);
  const eventId = eventState?.id;
  const [editEventModal, setEditEventModal] = React.useState(false);
  const queryClient = useQueryClient();

  const { refetch } = useQuery(['getEventDetail', eventId], getPostFunc, {
    refetchOnWindowFocus: false,
    enabled: false,
    initialData: () => {
      const { pages, pageParams = [] } = queryClient.getQueryData(['getEventList']);
      const pageNumber = pageParams?.length - 1;
      const posts = pages[pageNumber]?.results || [];
      const post = posts?.find((post) => post?.id?.toString() === eventId?.toString());
      return post;
    },
    onSuccess: (data) => {
      setEventState(data);
    },
  });
  const { mutate: editEventEvent, isLoading: editEventLoading } = useMutation(editEventFunc, {
    onSuccess: (response) => {
      setEditEventModal(false);
      refetch();
    },
    onError: (_error) => {
      console.log(_error);
    },
  });

  const { id, cover_image, location, start_time, excerpt } = eventState;
  return (
    <Conatainer>
      {cover_image && <EventImage src={event?.cover_image} />}
      <EventTitle>{event?.title}</EventTitle>
      <EventDescription>
        <span>Location : </span>
        {location}
      </EventDescription>
      <Flex>
        <EventDescription>
          <span>Start Date : </span>
          {moment(start_time).format('MM/DD/YYYY')}
        </EventDescription>
        <EventDescription style={{ marginLeft: 20 }}>
          <span>Time : </span>
          {moment(start_time).format('HH:mm')}
        </EventDescription>
      </Flex>
      <Excerpt text={excerpt} readMoreColor="#003049" truncateLength={200} />
      <Flex justify="space-between">
        <Flex>
          <EditEvent
            onClick={() => {
              setEditEventModal(true);
            }}
          />
        </Flex>
        <Flex>
          <Link to={`/event/detail/${id}?showParticipants=true`}>
            <SeeParticipants />
          </Link>
          <EventJoinButton />
        </Flex>
      </Flex>
      {editEventModal ? (
        <Modal onClose={() => setEditEventModal(false)} className="confirmation-modal" heading={<h3>Delete Post</h3>}>
          <EventForm
            edit
            onCancel={() => {
              setEditEventModal(false);
            }}
            onSubmit={(data) => {
              editEventEvent({ id: eventState.id, body: data });
            }}
            editData={eventState}
            loading={editEventLoading}
          />
        </Modal>
      ) : null}
    </Conatainer>
  );
};

export default EventListItem;

const Conatainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  min-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin: 15px;
`;

const EventImage = styled.img`
  width: 100%;
  height: 245.65px;
`;

const EventTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #3d5a80;
`;

const EventDescription = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #747474;
  span {
    color: #3d5a80;
  }
  margin: 0;
`;
