import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem } from 'store/slices/searchSlice';
import Post from 'components/Post';
import SectionLoader from 'components/common/Loader/sectionLoader';
import FilterSection from 'components/common/FilterSection';
import { queryGeneratorForFilters, groupData } from 'utils/queryGenerator';
import DefaultSearchPage from '../DefaultSearchPage';
import { getGlobalCompanies, getGlobalIndustries } from 'store/slices/globalDataSlice';
import Card from 'components/common/Card';
import { Flex } from 'components/core';

const SearchPost = () => {
  const dispatch = useDispatch();
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const results = item?.results;

  const { query } = useContext(SearchContext);
  const [updatedFilters, setUpdatedFilters] = useState();

  useEffect(() => {
    if (updatedFilters) {
      dispatch(searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'post' }));
    }
  }, [updatedFilters, dispatch]);

  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'post' }));
    dispatch(getGlobalCompanies());
    dispatch(getGlobalIndustries());
  }, [dispatch, query]);

  const postedByFilterData = [
    { label: '1st connections', name: '1st_degree', value: '1st_degree' },
    { label: 'Me', name: 'me', value: 'me' },
    { label: 'People you follow', name: 'people_you_follow', value: 'people_you_follow' },
  ];

  const companySizeFilterData = [
    ...new Set(results?.map((item) => item && item?.company?.name).flat()),
  ]?.map((item) => {
    if (item) return { label: item, name: item, value: item };
    return null;
  });

  const datePostedByFilterData = [
    { label: 'Past 24 hours', name: 'date_posted', value: 'past_24_hours' },
    { label: 'Past week', name: 'date_posted', value: 'past_week' },
    { label: 'Past month', name: 'date_posted', value: 'past_month' },
  ];

  const memberFilterData = [
    ...new Set(results?.map((item) => item?.author?.first_name + ' ' + item?.author?.last_name).flat()),
  ]?.map((item) => {
    if (item !== null) return { label: item, name: item, value: item };
    return null;
  });

  const sortByFilterData = [
    { label: 'Best Match', name: 'sort_by', value: 'top_match' },
    { label: 'Latest', name: 'sort_by', value: 'latest' },
  ];

  const filters = [
    { label: 'Posted by', type: 'checkbox', data: postedByFilterData },
    { label: 'Date posted', type: 'radio', data: datePostedByFilterData },
    { label: 'Sort by', type: 'radio', data: sortByFilterData },
    { label: 'From member', type: 'checkbox', data: memberFilterData, showInput: true },
    { label: 'From company', type: 'checkbox', data: companySizeFilterData, showInput: true },
    { label: 'Mentioning member', type: 'checkbox', data: memberFilterData, showInput: true },
  ];

  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);

  const posts =
    results?.length > 0 ? (
      <div style={{ width: "100%", maxWidth: "700px", margin: "0px auto" }}>
        <Flex gap="1rem" direction="column">
          {results?.map((item) => {
            return <Card style={{ width: "100%", overflow:"hidden" }}>
              <Post modal={false} key={item.id} data={item} />
            </Card>
          })}
        </Flex>
      </div>
    ) : (
      <DefaultSearchPage />
    );

  return (
    <>
      <FilterSection filters={filters} filterData={filterData} />
      {isItemsLoading ? (
        <SectionLoader />
      ) : (
        <>
          <h5 className="mb-4">Post</h5>
          {posts}
        </>
      )}
    </>
  )
};

export default SearchPost;
