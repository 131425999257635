/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MyBlogEditor from './editor';
import { DeleteIcon, AddCircle, Camera } from 'components/common/Icons';
import { clearCreateBlog, setDescription, setTitle, setCoverImage, setId, setBlogType } from 'store/slices/blogSlice';
import styles from './style.module.scss';
import Modal from 'components/common/Modal/Modal';
import ImageUploader from 'components/ImageUploader';
import { createNewBlogFunc, editBlogFunc, publishBlogFunc } from 'api/blog';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPostFunc } from 'api/post';
import { Spinner } from 'components/common/Loader';
import queryString from 'query-string';
import { store } from 'store/store';

const post = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState('4:3');
  let { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const publishRef = React.useRef(false);

  const { newBlog } = useSelector((state) => state.blog);

  const { post_id, cover_image, description, title } = newBlog;
  const [coverPhoto, setCoverPhoto] = useState(true);
  const parsed = queryString.parse(search);
  const { blogType: urlBlogType } = parsed;

  const postId = id || post_id;
  const { data } = useQuery(['BlogDetailinEditor', postId], getPostFunc, {
    enabled: !!postId,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (data) => {
      console.log('data', data);
      dispatch(setCoverImage(data?.cover_image));
      dispatch(setDescription(data?.description));
      dispatch(setTitle(data?.title));
      dispatch(setId(data?.id));
      dispatch(setBlogType(parseInt(urlBlogType) || data?.blog_type));
    },
  });

  const dispatch = useDispatch();

  const { mutate: createNewBlog, isLoading: createBlogLoading } = useMutation(createNewBlogFunc, {
    onSuccess: (data) => {
      if (data?.post_id) {
        dispatch(setId(data?.post_id));
      }
    },
    onError: (error) => {
      console.log('error creating new blog', error);
    },
  });

  const { mutate: editBlog, isLoading: editBlogLoading } = useMutation(editBlogFunc, {
    onSuccess: (data) => {
      if (publishRef.current) {
        dispatch(clearCreateBlog());
        publishRef.current = false;
        navigate('/blog/published');
        queryClient.invalidateQueries(['getBlogsByStatus', 'published']);
      }

      if (data?.status_code === 500) {
        dispatch(clearCreateBlog());
        navigate('/blog/published');
        queryClient.invalidateQueries(['getBlogsByStatus', 'published']);
      }
    },
    onError: (error) => {
      console.log('error editing  blog', error.status);
    },
  });

  const { mutate: publishBlogF, isLoading: publishLoading } = useMutation(publishBlogFunc, {
    onSuccess: (data) => {
      publishRef.current = true;
      keepUpdateBloginBackend();
    },
    onError: (error) => {
      console.log('error editing  blog', error.status);
    },
  });

  const handleDraft = () => {
    const storeState = store.getState();
    const { newBlog } = storeState.blog;
    const { post_id, cover_image, description, title, blogType = 1 } = newBlog;
    let formdata = new FormData();
    formdata.append('blog_type', blogType);
    formdata.append('type', '3');
    formdata.append('title', title || '');
    formdata.append('blog_status', 1);
    description && formdata.append('description', description);
    if (cover_image?.type?.includes('image')) {
      cover_image && formdata.append('cover_image', cover_image);
    }
    if (postId) {
      editBlog({ body: formdata, id: post_id });
    } else {
      if (title || description || cover_image) {
        createNewBlog({ body: formdata });
      }
    }
  };

  const imageUpload = (e) => {
    setModalOpen(true);
  };

  const publishBlog = async () => {
    const storeState = store.getState();
    const { newBlog } = storeState.blog;
    const { post_id } = newBlog;
    publishBlogF({ postId: post_id, method: 'PUT' });
  };

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const uploadCropedImg = (img) => {
    setModalOpen(false);
    dispatch(setCoverImage(img));
  };

  const removeCover = () => {
    dispatch(setCoverImage(null));
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const keepUpdateBloginBackend = () => {
    const storeState = store.getState();
    const { newBlog } = storeState.blog;
    const { post_id, cover_image, description, title, blogType = 1 } = newBlog;
    let formdata = new FormData();
    formdata.append('blog_type', blogType);
    formdata.append('type', '3');
    formdata.append('title', title || '');
    description && formdata.append('description', description);
    if (cover_image?.type?.includes('image')) {
      cover_image && formdata.append('cover_image', cover_image);
    }
    if (postId) {
      editBlog({ body: formdata, id: post_id });
    } else {
      if (title || description || cover_image) {
        createNewBlog({ body: formdata });
      }
    }
  };

  const updateTitle = () => {
    const storeState = store.getState();
    const { newBlog } = storeState.blog;
    const { post_id, title } = newBlog;
    let formdata = new FormData();
    title && formdata.append('title', title);
    if (post_id) {
      editBlog({ body: formdata, id: post_id });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedTitleFn = useCallback(debounce(updateTitle), []);

  const updateDescription = () => {
    const storeState = store.getState();
    const { newBlog } = storeState.blog;
    const { post_id, description } = newBlog;
    let formdata = new FormData();
    description && formdata.append('description', description);
    if (post_id) {
      editBlog({ body: formdata, id: post_id });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedDescriptionFn = useCallback(debounce(updateDescription), []);

  const dispatchDescription = (data) => {
    dispatch(setDescription(data));
    optimizedDescriptionFn();
  };

  const dispatchTitle = (e) => {
    const title = e.target.value;
    dispatch(setTitle(title));
    optimizedTitleFn();
  };

  useEffect(() => {
    dispatch(setBlogType(parseInt(urlBlogType || 1) || data?.blog_type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlBlogType]);

  useEffect(() => {
    return keepUpdateBloginBackend;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function auto_grow(e) {
    const element = e.target;
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 'px';
  }

  return (
    <div className="row">
      {!coverPhoto && (
        <div onClick={() => setCoverPhoto(true)} className={styles.addCoverPhoto}>
          <AddCircle />
          <span>Add Cover Photo</span>
        </div>
      )}
      {coverPhoto && (
        <div className={styles.backgroundCont}>
          {cover_image ? (
            <>
              <img
                className={styles.uploadedImage}
                alt=""
                src={cover_image?.type?.includes('image') ? URL.createObjectURL(cover_image) : cover_image}
              />
              <div onClick={removeCover} className={styles.hoverDelete}>
                <DeleteIcon />
              </div>
            </>
          ) : (
            <div className={styles.cameraPlaceholder} onClick={imageUpload}>
              {/* <div onClick={() => setCoverPhoto(false)} className={styles.closeIconCont}>
                <CloseIcon />
              </div> */}

              <Camera />
              {/* <input className={styles.imageUpload} onChange={imageUpload} type="file" accept="image/*" /> */}
              <h6>No cover image uploaded</h6>
              <p>Grab more attention with a cover photo. We recommend uploading a pixel size of 1280x720</p>
            </div>
          )}
        </div>
      )}

      <textarea
        onChange={dispatchTitle}
        className={styles.postHeadLine}
        maxlength="150"
        value={title}
        onInput={auto_grow}
        placeholder="Headline"
      ></textarea>
      <div className={styles.editorCont}>
        <MyBlogEditor setEditorState={dispatchDescription} editorState={description} />
      </div>
      <div className={styles.btnGroups}>
        <button onClick={handleDraft}> {editBlogLoading || createBlogLoading ? <Spinner /> : 'Save as draft'} </button>
        <button onClick={publishBlog}>{publishLoading ? <Spinner /> : 'Publish'}</button>
      </div>
      {modalOpen && (
        <Modal onClose={handlePopups} className={cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}>
          <ImageUploader
            ratio={'2'}
            cropType={cropType}
            uploadCropedImg={uploadCropedImg}
            handlePopups={handlePopups}
          />
        </Modal>
      )}
    </div>
  );
};
export default post;
