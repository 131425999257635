import React from 'react';
import { PushIcon } from 'components/common/Icons';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import { APIEndPoints } from 'store/common/endPoint';
import API from 'store/common/api';
import { useMutation } from '@tanstack/react-query';

const PushToggle = ({ module, settings = [], onChange, companyPage }) => {
  const number = Math.floor(100 + Math.random() * 900);
  const isTrue = settings.find((item) => item._for === module && item.enabled && (companyPage ? item.company_page === companyPage : true)) ? true : false;

  const { mutateAsync } = useMutation({
    mutationFn: async (body) => {
      return await API({ method: 'POST', url: `${APIEndPoints.Notification}/push/`, body: JSON.stringify(body) });
    },
  });

  const onChange_ = async () => {
    const current = !isTrue;
    await mutateAsync({ _for: module, enabled: current, ...(companyPage ? { company_page: companyPage } : {}) });
    onChange?.()
  };

  return (
    <div className="d-flex align-items-center justify-content-between border-bottom py-3">
      <div>
        <PushIcon className="me-3" />
        Push
      </div>
      <ToggleSwitch name={module + number} label="Push" value={isTrue} defaultValue={isTrue} onChange={onChange_} />
    </div>
  );
};

export default PushToggle;
