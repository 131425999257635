import React from 'react';
import Footer from 'components/Layouts/Footer';
import StaticNavbar from 'components/Layouts/StaticNavbar';
import '../../scss/pages/_aboutus.scss';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <StaticNavbar />
      <div className="header-page">
        <div className="head">{`Privacy Policy`}</div>
        <div className="route">
          <span
            onClick={() => {
              navigate('/');
            }}
          >
            Home{' '}
          </span>
          <span>{`/ Privacy Policy`}</span>
        </div>
      </div>
      <div className="aboutus-container">
        <div className="container">
          <div className="heading">Privacy Policy</div>
          <div className="fs-20">Effective date: 16 March 2023</div>
          <div className="container-heading">Privacy Statement</div>
          <div className="container-text">
            In this Privacy Statement, we describe how we collect, use, and handle your information when you use our
            websites, software, and services ("Services").
            <br></br>
            Our Privacy Policy explains how we collect and use your information while our Terms of Service outlines your
            responsibilities when using our services. By using our services, you are agreeing to be bound by these
            terms, and to review our Privacy Policy and Terms of Service policies. If you are using our services for an
            organization, group or forum, you are agreeing to these terms on their behalf.
          </div>
          <div className="container-heading">Data we collect and store</div>
          <div className="container-text">
            We collect and use the following information to provide, improve, and protect our services:
            <div className="mg-10"></div>
            <b>Account</b> - We collect information such as your name, email address, phone number, payment info, and
            physical address to associate with your account. Some of our services let you access your accounts and your
            information with other service providers.
            <div className="mg-10"></div>
            <b>Services</b> - When you use our services, we store, process, and transmit your files (including
            information like your photos, structured data and emails) and information related to them (for example,
            location tags in photos). If you give us access to your contacts, we will store those contacts on our
            servers for you to use. This will make it easy for you to do things like share your information, send
            emails, and invite others to use the services.
            <div className="mg-10"></div>
            <b>Usage</b> - We collect information from and about the devices you use to access the services. This
            includes things like IP addresses, the type of browser and device you use, the web page you visited before
            coming to our sites, and identifiers associated with your devices. Your devices (depending on their
            settings) may also transmit location information to the services.
            <div className="mg-10"></div>
            <b>Cookies and other technologies</b> - We use technologies like cookies and pixel tags to provide, improve,
            protect, and promote our services. For example, cookies help us with things like remembering your username
            for your next visit, understanding how you are interacting with our services, and improving them based on
            that information. You can set your browser to not accept cookies, but this may limit your ability to use the
            services. If our systems receive a DNT:1 signal from your browser, we will respond to that signal as
            outlined here.
          </div>
          <div className="container-heading">Circumstances in which we may disclose user data</div>
          <div className="container-text">
            We may share information as discussed below, but we will not sell it to advertisers or other third-parties.
            <div className="mg-10"></div>
            Others working for myBridge use certain trusted third parties to help us provide, improve, protect, and
            promote our services. These third parties will access your information only to perform tasks on our behalf
            and in compliance with this Privacy Policy.
            <div className="mg-10"></div>
            <b>Other users</b> - Our services display information like your name and email address to other users in
            places like your user profile and sharing notifications. Certain features let you make additional
            information available to other users.
            <div className="mg-10"></div>
            <b>Other applications</b> - You can also give third parties access to your information and account - for
            example, via myBridge APIs. Just remember that their use of your information will be governed by their
            privacy policies and terms.
            <div className="mg-10"></div>
            <b>Forum and Business use</b> - If you use myBridge for Forum or Business, your administrator may have the
            ability to access and control the Forum or Business account. Members of the Forum may be able to view the
            name, email address, and IP address that were associated with your account at the time of interaction.
            <div className="mg-10"></div>
            <b>Law and Order</b> - We may disclose your information to third parties if we determine that such
            disclosure is reasonably necessary to
            <div className="mg-10"></div>
            (a) comply with the law;<br></br>
            (b) protect any person from death or serious bodily injury;<br></br>
            (c) prevent fraud or abuse of myBridge or our users; or<br></br>
            (d) protect myBridge’s property rights.<br></br>
            <div className="mg-10"></div>
            Stewardship of your data is critical to us and a responsibility that we embrace. We believe that our users'
            data should receive the same legal protections regardless of whether it is stored on our services or on
            their home computer's hard drive. We will abide by the following Government Request Principles when
            receiving, scrutinizing, and responding to government requests for our users' data:
            <div className="mg-10"></div>
            <b>1. Be transparent,</b>
            <br></br>
            <b>2. Protect all users, and</b>
            <br></br>
            <b>3. Provide trusted services.</b>
            <br></br>
          </div>
          <div className="container-heading">How</div>
          <div className="container-text">
            <b>Security</b> - We have a team dedicated to keeping your information secure and testing for
            vulnerabilities. We also continue to work on features to keep your information safe in addition to things
            like two-factor authentication, encryption of files at rest, and alerts when new devices and apps are linked
            to your account.
            <br></br>
            <b>Retention</b> – We will retain information you store on our services for as long as we need it to provide
            you with services. If you delete your account, we will also delete this information. But please note:
            <br></br>1 There might be some latency in deleting this information from our servers and backup storage; and{' '}
            <br></br>2 We may retain this information if necessary to comply with our legal obligations, resolve
            disputes, or enforce our agreements.
          </div>
          <div className="container-heading">Where</div>
          <div className="container-text">
            <b>Around the world</b> - To provide you with the services, we may store, process, and transmit information
            in locations around the world - including those outside your country. Information may also be stored locally
            on the devices you use to access the services.
            <br></br>
            <b>Safe Harbor</b> - myBridge complies with the EU-U.S. and Swiss-U.S. Safe Harbor ("Safe Harbor")
            frameworks and principles. We have certified our compliance, and you can view our certifications here. You
            can learn more about Safe Harbor by visiting export.gov . JAMS is the independent organization responsible
            for reviewing and resolving complaints about our Safe Harbor compliance. We ask that you first submit any
            such complaints directly to us via privacy@myBridge.me
          </div>
          <div className="container-heading">Changes</div>
          <div className="container-text">
            If we are involved in a reorganization, merger, acquisition, or sale of our assets, your information may be
            transferred as part of that deal. We will notify you (for example, via a message to the email address
            associated with your account) of any such deal and outline your choices in that event.
            <br></br>
            We may revise this Privacy Policy from time to time and will post the most current version on our website.
            If a revision meaningfully reduces your rights, we will notify you.
          </div>
          <div className="container-heading">Contact</div>
          <div className="container-text">
            Have questions or concerns about myBridge, our services and privacy? Contact us at terms@myBridge.me
            <br></br>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
