import Alert from 'components/common/Alert/Alert';
import Loader, { Spinner } from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import RadioButton from 'components/common/RadioButton';
import { trimStr } from 'components/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearTeamReported, reportTeamCommunity } from 'store/slices/teamCommunitySlice';

const TeamReportModal = ({ onClose, team, title }) => {
  const [reportReason, setReportReason] = useState(null);
  const { isTeamReported, loading, error, msg, message } = useSelector((state) => state.teamsCommunity);
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setReportReason(e.target.value);
  };

  const handleSend = () => {
    const payload = {
      user_id: userProfileInfo?.id,
      for_team_community_id: team?.id,
      report_message: reportReason,
    };
    dispatch(reportTeamCommunity(payload));
  };

  const handleClose = () => {
    onClose();
  };

  const getErrorMessage = msg => {
    if (msg?.toLowerCase?.().includes?.("404")) return "Team has already been reported and user is blocked.";
    return "Could not report team, please try again later!"

  }

  return (
    <>
      <Modal
        onClose={handleClose}
        className="confirmation-modal"
        heading={
          <h2>
            {title ?? 'Report Team, '}<span title={team?.name}>{trimStr(team?.name, { length: 15 })}</span>?
          </h2>
        }
        action={
          <>
            <button className={`btn btn-light `} onClick={handleClose}>
              Cancel
            </button>
            <button className="btn btn-primary" disabled={Boolean(!reportReason) || isTeamReported || loading} onClick={handleSend}>
              {loading ? <Spinner /> : "Submit"}
            </button>
          </>
        }
      >
        <>
          {error && message ? <Alert type="danger">{getErrorMessage(message)}</Alert> : ""}
          {msg ? <Alert type="success">Team has been reported successfully.</Alert> : ""}
          <h4 className="mb-1">Please select a problem</h4>
          <p className="mb-3">
            <strong>If someone is in immediate danger, get help before reporting to myBridge. Don't wait.</strong>
          </p>

          <div className="radio-wrapper">
            <RadioButton
              changed={handleChange}
              name="report"
              label="Pretending to be someone"
              value="Pretending to be someone"
            />
            <RadioButton changed={handleChange} name="report" label="Fake account" value="Fake account" />
            <RadioButton changed={handleChange} name="report" label="Fake name" value="Fake name" />
            <RadioButton
              changed={handleChange}
              name="report"
              label="Posting inappropriate things"
              value="Posting inappropriate things"
            />
            <RadioButton
              changed={handleChange}
              name="report"
              label="Harassment or bullying"
              value="Harassment or bullying"
            />
            <RadioButton changed={handleChange} name="report" label="I want to help" value="I want to help" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default TeamReportModal;
