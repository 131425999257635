import React, { useEffect } from 'react';
import SearchBar from 'components/common/SearchBar';
import FilterSectionTeams from 'components/common/FilterSectionTeams';
import PostCards from './Components/PostCards';
import { organisers } from '../../constants';
import { getGroupPost, getGroupAdminPost, clearIsPostAccepted } from 'store/slices/teamCommunitySlice';
import { useSelector, useDispatch } from 'react-redux';
const PendingPosts = (props) => {
  const { isOrganizer } = props;
  const { teamPosts, teamId, isPostAccepted } = useSelector((state) => state.teamsCommunity);

  const dispatch = useDispatch();
  const filters = [
    { label: 'Locations', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Current company', type: 'checkbox', data: null, showInput: true, showKeywords: false },
    { label: 'Industry', type: '', data: null, showInput: false, showKeywords: true },
    { label: 'School', type: '', data: null, showInput: false, showKeywords: true },
  ];

  useEffect(() => {
    if (isOrganizer) {
      dispatch(getGroupAdminPost(teamId));
    } else {
      dispatch(getGroupPost(teamId));
    }
    dispatch(clearIsPostAccepted())
  }, [isPostAccepted]);

  return (
    <React.Fragment>
      <div className="membersContainer">
        <div className="membersContainer--Header">
          <div className="headerLeft">
            <h6>
              {teamPosts.length} Pending chats
            </h6>
          </div>
          <div className="headerRight">
            <SearchBar />
          </div>
        </div>

        <div className="membersContainer--CardList pending-posts">
          {teamPosts.length > 0 ? teamPosts.map((teampost, i) => (
            <PostCards isOrganizer={isOrganizer} user={teampost.user} posts={teampost} key={i} />
          )) : <p className="text-center mt-3">No Pending Posts</p>}

        </div>
      </div>
    </React.Fragment>
  )
}

export default PendingPosts