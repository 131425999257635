import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JWT from 'expo-jwt';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import { Input } from 'components/common/Input';
import { addUser, signInUser, setInactiveEmail } from 'store/slices/authSlice';
import GoogleLoginComponent from '../GoogleLogin/GoogleLogin';
import Loader from 'components/common/Loader';
import moment from 'moment';
import { getUserProfileInfo } from 'store/slices/userProfileSlice';
import { getUpcomingEvents } from 'store/slices/calendarSlice';
import { getConnectionsData } from 'store/slices/connectionSlice';
import { fetchPosts } from 'store/slices/postSlice';

export default function Signin() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const remAccessToken = localStorage.getItem('remAccess');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setrememberMe] = useState(false);
  const [registerError, setRegisterError] = useState({});
  const [loginError, setLoginError] = useState({
    userName: false,
    password: false,
  });

  const [showMsg, setShowMsg] = useState(false);


  const { access, refresh, loading, msg, error, signInPage, userInactive } = useSelector((state) => state.user);

  const isValid = userName.trim().length > 0 && password.trim().length > 0;

  const ERROR_VALUES = {
    signUpEmail: 'Please enter a valid email id',
    signUpPassword: 'Please enter a password',
    upperCaseError: 'At least one Uppercase letter',
    lowerCaseError: 'At least one Lowercase letter',
    digitsError: 'At least one digit',
    specialCharError: 'At least one Special Characters',
    signUpPasswordLength: 'Password is too short',
  }

  const handleLogin = () => {
    const errorObject = {}
    const patterns = {
      email: /^([a-z\d\.\+-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
      uppercaseRegExp: /(?=.*?[A-Z])/,
      lowercaseRegExp: /(?=.*?[a-z])/,
      digitsRegExp: /(?=.*?[0-9])/,
      specialCharRegExp: /(?=.*?[#?!@$%^&*-])/,
      minLengthRegExp: /.{8,}/,
    };

    if (!patterns.email.test(userName)) {
      errorObject.signUpEmail = ERROR_VALUES.signUpEmail
    }


    if (Object.keys(errorObject).length > 0) {
      setRegisterError(errorObject)
      return
    } else {
      setRegisterError({})
    }
    setLoginError({
      ...loginError,
      userName: !userName ? true : false,
      password: !password ? true : false
    })

    if (!userName || !password) return;
    dispatch(signInUser({
      email: userName, password
    }))
    //   .then(res => {

    //   if (res.meta.requestStatus === "fulfilled") {
    //     if (res.payload.access) {
    //       dispatch(getUserProfileInfo());
    //       const value = new Date();
    //       dispatch(
    //         getUpcomingEvents({
    //           date: `${moment(value).date()}-${moment(value).month() + 1}-${moment(value).year()}`,
    //         }),
    //       );
    //       dispatch(getConnectionsData());
    //       dispatch(fetchPosts());
    //     } else if (res.payload.code === "INACTIVE_USER") {
    //       dispatch(setInactiveEmail(userName));
    //       navigate('/');
    //     }

    //   }
    // })
    setShowMsg(true)
  }

  // const logoutHandle = () => {
  //   //dispatch(logout)
  // }

  const onChange = (e) => {
    e.target.name === 'userName' ? setUserName(e.target.value) : setPassword(e.target.value);
  }

  useEffect(() => {
    if (access) {
      dispatch(addUser(access));
      navigate('/')
      return
    }
    if (!access) {
      if (signInPage) {
        setLoginError({ ...loginError })
      }
    }
  }, [access]);

  useEffect(() => {
    try {
      const token = JWT.decode(remAccessToken, 'remAccess');
      setUserName(token.username);
      setPassword(token.password);
      setrememberMe(true);
    } catch (e) {
      localStorage.removeItem('remAccess');
    }

  }, [remAccessToken])

  const handleRemenber = (e) => {
    const checked = e.target.checked;
    const exp = moment().add(30, 'days');
    if (checked && userName !== '' && password !== '') {
      let remToken = JWT.encode({ username: userName, password: password, exp: moment(exp).unix(), iat: moment().unix() }, 'remAccess');
      localStorage.setItem('remAccess', remToken);
      setrememberMe(true);
    }
    if (!checked) {
      localStorage.removeItem('remAccess');
      setrememberMe(false);
    }
  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13) {
    handleLogin();
  }
};


  return (
    <>
      <div className='user-flow--form'>

        <h2 className="user-flow--formHeader">Sign in</h2>
        {/* <p className="user-flow--formSubHeader">Stay updated on your professional world</p> */}
        {loading && (
          <Loader />
        )}

        {showMsg &&
          <div>
            {error && <p className="form-message helper-text text-danger mb-2 text-start mt-0">{error}</p>}
          </div>
        }

        


        <Input
          name="userName"
          value={userName}
          onChange={onChange}
          className={loginError.userName ? 'is_invalid' : ''}
          placeholder="Email"
          type="email"
          error={registerError.signUpEmail && registerError.signUpEmail}
          label="Email"
        />

        <Input
          type="password"
          name="password"
          value={password}
          onChange={onChange}
          className={loginError.password ? 'is_invalid' : ''}
          placeholder="Password"
          error={registerError.signUpPassword && registerError.signUpPassword}
          label="Password"
          onKeyPress={() => handleKeypress()}
        />

        <Button className='full-width' disabled={!isValid} onClick={handleLogin} onKeyPress={handleKeypress}>
          Sign In
        </Button>

        <div className='forgot-password mt-3'>
          <div>
            <Checkbox checked={rememberMe} className="m-0" label="Remember Me" onChange={(e) => handleRemenber(e)} />
          </div>
          <a href='/forgot-password' className="cursor-pointer">
            Forgot Password?
          </a>
        </div>
        <p className='my-3 text-center'>
          Don't have an account?
          <a href='/signup' className='text-btn ms-1'>
            Sign up
          </a>
        </p>
        <div className='separator'>
          <span>Or</span>
        </div>

        <GoogleLoginComponent path='signIn' />
      </div>



    </>
  )
}
