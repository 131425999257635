import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/common/Button';
import { Input } from 'components/common/Input';
import { updateUserPassword } from 'store/slices/settingsSlice';
import { Spinner } from 'components/common/Loader';

const Password = () => {
  const dispatch = useDispatch();
  // TODO: Handle state/props/redux update and show a popup if error or success
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { updateStatus } = useSelector((state) => state.settings);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (userProfileInfo?.id) {
      setValues(userProfileInfo);
    }

    return () => setValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const onClickSave = () => {
    setErrors({})
    const errorObject = {};
    const { oldPassword, newPassword, confirmPassword } = values;

    if (!oldPassword) {
      errorObject.oldPassword = 'Please enter old password';
    }
    if (!newPassword) {
      errorObject.newPassword = 'Please enter new password';
    }
    if (!confirmPassword) {
      errorObject.confirmPassword = 'Please enter confirmation password';
    }

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      errorObject.passwordMatch = 'Password and confirm password should match';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrors(errorObject);
      return;
    }
    // else {
    //   setErrors({ errorFields, oldPassword: '', newPassword: '', confirmPassword: '' });
    // }

    dispatch(
      updateUserPassword({
        old_password: oldPassword,
        new_password: newPassword,
        verify_new_password: confirmPassword,
      })
    ).then(() => {
      setEditMode(false)
    });
  };

  const onClickCancel = () => {
    setEditMode(false);
    setErrors({});
    setValues(userProfileInfo);
  };

  if (editMode) {
    return (
      <div className="row g-0 justify-content-md-between">
        <div className="col-12 col-lg">
          <div className="row">
            <div className="col-12">
              <p className="label">Change Password</p>
              <p>Choose a unique password to protect your account</p>
            </div>
            <div className="col-12 col-lg">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <Input
                    value={values.oldPassword}
                    name="oldPassword"
                    onChange={fieldsOnChange}
                    type="password"
                    className="form-control"
                    placeholder="Old Password"
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <Input
                    value={values.newPassword}
                    name="newPassword"
                    onChange={fieldsOnChange}
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <Input
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={fieldsOnChange}
                    type="password"
                    className="form-control"
                    placeholder="Confirm New Password"
                  />
                </div>
              </div>
              {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
              {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
              {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
              {errors.passwordMatch && <div className="text-danger">{errors.passwordMatch}</div>}
            </div>
            <div className="d-flex gap-2 mt-4">
              <Button className="btn btn-secondary fw-normal" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button disabled={updateStatus === "pending"} className="btn btn-primary fw-normal" onClick={onClickSave}>
                {updateStatus === "pending" ? <Spinner /> : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row g-0 justify-content-md-between">
      <div className="col-12 col-lg">
        <div className="row">
          <div className="col-12 col-lg">
            <p className="label">Change Password</p>
            <p className="label-subtext">Choose a unique password to protect your account</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-2">
        <div className="text-lg-end">
          <p className="text-primary links" role="button" onClick={() => setEditMode(true)}>
            Change
          </p>
        </div>
      </div>
    </div>
  );
};

export default Password;
