import React, { useRef, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import debouce from 'lodash.debounce';
import lodash from 'lodash';
import Badge from 'react-bootstrap/Badge';

import servicesPic from 'assets/profiles/profile/avtar_md.jpeg';

import Button from 'components/common/Button';
import { BorderLessButton, VerticalLine } from 'components/StyledComponents';
import { AddCircle } from 'components/common/Icons';
import { Input, TextArea } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import Checkbox from 'components/common/Checkbox';
import { rangeOfFullYears } from 'components/utils';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { DEBOUNCE_DELAY } from '../../../constants';

import { addEducation, deleteEduction, getEducation } from 'store/slices/userProfileSlice';
import { getGlobalSchools, getGlobalColleges, getGlobalDegrees, getGlobalCourses } from 'store/slices/globalDataSlice';
import { updateEducation } from 'store/slices/userProfileSlice';
import { monthOptions, monthToNameMapping } from 'config/formConstants';
import Pills from 'components/common/Pills';

const EducationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const EducationContainer = styled.div``;

const EducationDetailsContainer = styled.div`
  margin-bottom: 10px;
`;

const EducationDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const EducationDetailsType = styled.p``;

const EducationDetailsAction = styled.div`
  display: flex;
`;

const EducationDetailsBody = styled.div`
  display: flex;
`;

const EducationDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const EducationDetailsImage = styled.img`
  height: 50px;
`;

const EducationDetailsName = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
`;

const EducationDetailsTimeline = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #000000;
`;

const EducationDetailsDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
`;

const initialState = {
  name: '',
  type: '',
  start_year: '',
  end_year: '',
  is_graduate: false,
  courses: '',
  desc: '',
};

const EDUCATION_TYPES = {
  Degree: 'College',
  College: 'College',
  School: 'High School',
};

const years = rangeOfFullYears(1950, 'backward');

const Education = (props) => {
  const dispatch = useDispatch();
  const { educations, isNewEducation } = useSelector((state) => state.userProfile);
  const { globalSchools, globalColleges, globalDegrees, globalCourses } = useSelector((state) => state.globalData);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showDegreeSuggestions, setShowDegreeSuggestions] = useState(false);
  const [showCourseSuggestions, setShowCourseSuggestions] = useState(false);

  const [fields, setFields] = useState(initialState);
  const [educationId, setEducationId] = useState(null);
  const nameRef = useRef('');
  const degreeRef = useRef('');
  const courseRef = useRef('');
  const [collegeCourses, setCollegeCourses] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [educationType, setEducationType] = useState();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (isNewEducation) {
      setEducationId(null);
      dispatch(getEducation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewEducation]);

  let eduName;
  // dispatch(getEducation());
  useOnClickOutside(nameRef, (e) => {
    if (e.target.className !== 'dropdown-item') setShowSuggestions(false);
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleNameChange = (e) => {
    const term = e.target.value.trim();
    if (term) {
      setShowSuggestions(true);
      if (educationType === 'School') {
        dispatch(getGlobalSchools({ search: term }));
      } else {
        dispatch(getGlobalColleges({ search: term }));
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleNameChange, DEBOUNCE_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDegreeChange = (e) => {
    const term = e.target.value.trim();
    if (term) {
      setShowDegreeSuggestions(true);
      dispatch(getGlobalDegrees({ search: term }));
    } else {
      setShowDegreeSuggestions(false);
    }
  };

  const debouncedDegreeResults = useMemo(() => {
    return debouce(handleDegreeChange, DEBOUNCE_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCourseChange = (e) => {
    const term = e.target.value.trim();
    if (term) {
      setShowCourseSuggestions(true);
      dispatch(getGlobalCourses({ search: term }));
    } else {
      setShowCourseSuggestions(false);
    }
  };

  const debouncedCourseResults = useMemo(() => {
    return debouce(handleCourseChange, DEBOUNCE_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
      debouncedDegreeResults.cancel();
      debouncedCourseResults.cancel();
    };
  });

  const handleAutoComplete = (e) => {
    nameRef.current.value = e.target.textContent;
    setShowSuggestions(false);
  };

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    if (educationId) {
      const currentForm = educations?.results?.find((education) => educationId === education.id);
      setFields(currentForm);
      nameRef.current.value = currentForm.name;
      educationType === 'College' && (degreeRef.current.value = currentForm?.degree);
      educationType === 'College' && (courseRef.current.value = currentForm?.courses);
      educationType === 'College' && setCollegeCourses(currentForm?.courses.split(","));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationId]);

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const editEducation = (id, type) => {
    setEditMode(true);
    setEducationId(id);
    setEducationType(type);
  };

  const addEducationRecord = (type) => {
    setEditMode(true);
    setEducationId(null);
    setEducationType(type);
    setFields(initialState);
    setShowSuggestions(false);
  };

  const onClickCancel = () => {
    setEditMode(false);
    setEducationId(null);
    setEducationType();
    setFields(initialState);
    setCollegeCourses([]);
  };

  const removeCollegeCourses = (idx) =>{
    setCollegeCourses((collegeCourses) => collegeCourses.filter((_, index) => index !== idx));
  }

  const onClickSaveUpdate = (id) => {
    const errorObject = {};
    if (!nameRef?.current?.value) {
      errorObject.name = 'Please enter a name';
    }
    if (!fields.desc) {
      errorObject.desc = 'Please enter a description';
    }
    if (!fields.start_year) {
      errorObject.startYear = 'Please select a start year';
    }
    if (!fields.start_month) {
      errorObject.startYear = 'Please select a start month';
    }
    if (fields.is_graduate) {
      if (!fields.end_year) {
        errorObject.endYear = 'Please select a end year';
      } else if (parseInt(fields?.start_year) > parseInt(fields?.end_year)) {
        errorObject.year = 'Start year should not be greater than end year';
      }
      if (!fields.end_month) {
        errorObject.endMonth = 'Please select a end month';
      }
    }

    const payload = {
      name: nameRef.current.value,
      start_year: fields.start_year,
      start_month: fields.start_month,
      end_year: fields.end_year,
      end_month: fields.end_month,
      is_graduate: fields.is_graduate,
      desc: fields.desc,
      type: educationType,
    };

    if (educationType === 'College') {
      if (!degreeRef?.current?.value) {
        errorObject.degree = 'Please enter a degree name';
      } else {
        payload.degree = degreeRef.current.value;
      }

      if (!collegeCourses) {
        errorObject.courses = 'Please enter a course name';
      } else {
        payload.courses = collegeCourses.join(', ');
      }
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }

    if (id) {
      dispatch(updateEducation({ ...payload, id }));
    } else {
      dispatch(addEducation(payload));
    }

    setEditMode(false);
    setCollegeCourses([]);
  };

  const renderEducationList = (educationType) => { 
    let educationRecords = educations?.results?.filter((edu) => edu.type === educationType);
    if (educationType === 'College' || educationType === 'Degree') {
      educationRecords = educations?.results?.filter((edu) => ['College', 'Degree'].includes(edu.type));
    }

    return educationRecords?.map((education) => {
      const { id, name, courses, degree, start_month, start_year, end_month, end_year, is_graduate, desc, type } =
        education;

      return (
        <EducationDetailsContainer key={`edu-${id}`}>
          <EducationDetailsHeader>
            <EducationDetailsType>{EDUCATION_TYPES[type]}</EducationDetailsType>
            <EducationDetailsAction>
              <BorderLessButton onClick={() => editEducation(id, type)}>Edit</BorderLessButton>
              <VerticalLine />
              <BorderLessButton onClick={() => dispatch(deleteEduction(id))}>Delete</BorderLessButton>
            </EducationDetailsAction>
          </EducationDetailsHeader>

          <EducationDetailsBody>
            <EducationDetailsImage src={servicesPic} alt="" />

            <EducationDetailsContent>
              <EducationDetailsName>{name}</EducationDetailsName>

              <EducationDetailsDescription>
                {degree}
                {courses && `, ${courses}`}
              </EducationDetailsDescription>

              <EducationDetailsTimeline>
                {`${monthToNameMapping[start_month]} ${start_year} - ${
                  is_graduate ? `${monthToNameMapping[end_month]} ${end_year}` : 'In Progress'
                }`}
              </EducationDetailsTimeline>

              <EducationDetailsDescription>{desc}</EducationDetailsDescription>
            </EducationDetailsContent>
          </EducationDetailsBody>
        </EducationDetailsContainer>
      );
    });
  };

  const hasCollegeEducation = educations?.results?.find((edu) => edu.type === 'Degree' || edu.type === 'College');

  if (!editMode) {
    return (
      <>
        <EducationHeader>
          <AddCircle />
          <BorderLessButton onClick={() => addEducationRecord('College')}>Add College</BorderLessButton>
          <AddCircle />
          <BorderLessButton onClick={() => addEducationRecord('School')}>Add High School</BorderLessButton>
        </EducationHeader>

        <EducationContainer>
          {renderEducationList('College')}
          {hasCollegeEducation && <VerticalLine />}
          {renderEducationList('School')}
        </EducationContainer>
      </>
    );
  }

  if (educationType === 'School') {
    return (
      <>
        <EducationHeader>
          <AddCircle />
          <BorderLessButton onClick={() => addEducationRecord('Degree')}>Add College</BorderLessButton>
        </EducationHeader>

        <div className="">
          <p className="fw-bold mb-4">High School</p>
          <p className="helper-text">School Name</p>
          <div className="">
            <Input
              type="text"
              ref={nameRef}
              value={eduName}
              placeholder={`School Name`}
              name="name"
              onChange={debouncedResults}
            />

            {errorFields.name && <div className="text-danger">{errorFields.name}</div>}
            {globalSchools?.length > 0 && showSuggestions && (
              <ul class="suggestions">
                {globalSchools?.map((result) => (
                  <li className="dropdown-item" key={result} onClick={handleAutoComplete}>
                    {result}
                  </li>
                ))}
              </ul>
            )}
            <p className="helper-text">Time Period</p>
            <div className="d-inline-flex align-items-center gap-3">
              <Dropdown
                id={'start_year'}
                name={'start_year'}
                options={years}
                title={'start_year'}
                handleChange={handleChange}
                selectedValue={fields.start_year}
                defaultValue={'Year'}
                containerClass="m-0"
              />
              <Dropdown
                id={'start_month'}
                name={'start_month'}
                options={monthOptions}
                title={'start_month'}
                handleChange={handleChange}
                selectedValue={fields.start_month}
                defaultValue={'Month'}
                containerClass="m-0"
              />
              {fields.is_graduate && (
                <>
                  <span>to</span>
                  <Dropdown
                    id={'end_year'}
                    name={'end_year'}
                    options={years}
                    title={'end_year'}
                    handleChange={handleChange}
                    selectedValue={fields.end_year}
                    defaultValue={'Year'}
                    containerClass="m-0"
                  />
                  <Dropdown
                    id={'end_month'}
                    name={'end_month'}
                    options={monthOptions}
                    title={'end_month'}
                    handleChange={handleChange}
                    selectedValue={fields.end_month}
                    defaultValue={'Month'}
                    containerClass="m-0"
                  />
                </>
              )}
            </div>
            {errorFields.startYear && <div className="text-danger">{errorFields.startYear}</div>}
            {errorFields.startMonth && <div className="text-danger">{errorFields.startMonth}</div>}
            {errorFields.endYear && <div className="text-danger">{errorFields.endYear}</div>}
            {errorFields.endMonth && <div className="text-danger">{errorFields.endMonth}</div>}
            {errorFields.year && <div className="text-danger">{errorFields.year}</div>}

            <Checkbox
              id="currentRole"
              label="Graduated"
              name="is_graduate"
              checked={fields.is_graduate}
              onChange={handleCheckbox}
            />
            <p className="helper-text">Description</p>
            <TextArea rows={5} placeholder="Description" value={fields.desc} name="desc" onChange={fieldsOnChange} />
            {errorFields.desc && <div className="text-danger">{errorFields.desc}</div>}
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end mt-4">
          <Button variant="btn-secondary" className="h-36" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button variant="btn-primary" className="h-36" onClick={() => onClickSaveUpdate(educationId)}>
            {educationId ? 'Update' : 'Save'}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <EducationHeader>
        <AddCircle />
        <BorderLessButton onClick={() => addEducationRecord('School')}>Add High School</BorderLessButton>
      </EducationHeader>

      <div className="">
        <p className="fw-bold mb-4">University</p>
        <p className="helper-text">University Name</p>
        <div className="">
          <Input
            type="text"
            ref={nameRef}
            value={eduName}
            placeholder={`University Name`}
            name="name"
            onChange={debouncedResults}
          />
          {errorFields.name && <div className="text-danger">{errorFields.name}</div>}
          {globalColleges?.length > 0 && showSuggestions && (
            <ul class="suggestions">
              {globalColleges?.map((result) => (
                <li className="dropdown-item" key={result} onClick={handleAutoComplete}>
                  {result}
                </li>
              ))}
            </ul>
          )}

          <p className="helper-text">Degree Name</p>
          <Input
            type="text"
            placeholder={`Degree Name`}
            ref={degreeRef}
            // value={eduName}
            name="degree"
            onChange={debouncedDegreeResults}
          />
          {errorFields.degree && <div className="text-danger">{errorFields.degree}</div>}
          {globalDegrees?.length > 0 && showDegreeSuggestions && (
            <ul class="suggestions">
              {globalDegrees?.map((result) => (
                <li
                  className="dropdown-item"
                  key={result}
                  onClick={(e) => {
                    degreeRef.current.value = e.target.textContent;
                    setShowDegreeSuggestions(false);
                  }}
                >
                  {result}
                </li>
              ))}
            </ul>
          )}

          <p className="helper-text">Concentrations</p>
          <Input
            type="text"
            placeholder={`Course Name`}
            ref={courseRef}
            // value={eduName}
            name="courses"
            onChange={debouncedCourseResults}
          />
          {errorFields.courses && <div className="text-danger">{errorFields.courses}</div>}
          {globalCourses?.length > 0 && showCourseSuggestions && (
            <ul class="suggestions">
              {globalCourses?.map((result) => (
                <li
                  className="dropdown-item"
                  key={result}
                  onClick={(e) => {
                    courseRef.current.value = e.target.textContent;
                    setCollegeCourses((coll) => lodash.uniq([...coll, e.target.textContent]));
                    setShowCourseSuggestions(false);
                    courseRef.current.value=''
                  }}
                >
                  {result}
                </li>
              ))}
            </ul>
          )}
          <div style={{display: 'flex',flexFlow: 'row wrap',gap: 10}}>
          {collegeCourses?.map((crs, idx) => {
            return (
              <Pills key={idx} label={crs} onClose={() => removeCollegeCourses(idx)} />
            );
          })}
          </div>
          

          <p className="helper-text mt-2">Time Period</p>
          <div className="d-inline-flex align-items-center gap-3">
            <Dropdown
              id={'start_year'}
              name={'start_year'}
              options={years}
              title={'start_year'}
              handleChange={handleChange}
              selectedValue={fields.start_year}
              defaultValue={'Year'}
              containerClass="m-0"
            />
            <Dropdown
              id={'start_month'}
              name={'start_month'}
              options={monthOptions}
              title={'start_month'}
              handleChange={handleChange}
              selectedValue={fields.start_month}
              defaultValue={'Month'}
              containerClass="m-0"
            />
            {fields.is_graduate && (
              <>
                <span>to</span>
                <Dropdown
                  id={'end_year'}
                  name={'end_year'}
                  options={years}
                  title={'end_year'}
                  handleChange={handleChange}
                  selectedValue={fields.end_year}
                  defaultValue={'Year'}
                  containerClass="m-0"
                />
                <Dropdown
                  id={'end_month'}
                  name={'end_month'}
                  options={monthOptions}
                  title={'end_month'}
                  handleChange={handleChange}
                  selectedValue={fields.end_month}
                  defaultValue={'Month'}
                  containerClass="m-0"
                />
              </>
            )}
          </div>
          {errorFields.startYear && <div className="text-danger">{errorFields.startYear}</div>}
          {errorFields.startMonth && <div className="text-danger">{errorFields.startMonth}</div>}
          {errorFields.endYear && <div className="text-danger">{errorFields.endYear}</div>}
          {errorFields.endMonth && <div className="text-danger">{errorFields.endMonth}</div>}
          {errorFields.year && <div className="text-danger">{errorFields.year}</div>}

          <Checkbox
            id="currentRole"
            label="Graduated"
            name="is_graduate"
            checked={fields.is_graduate}
            onChange={handleCheckbox}
          />
          <p className="helper-text">Description</p>
          <TextArea rows={5} placeholder="Description" value={fields.desc} name="desc" onChange={fieldsOnChange} />
          {errorFields.desc && <div className="text-danger">{errorFields.desc}</div>}
        </div>
      </div>
      <div className="d-flex gap-2 justify-content-end mt-4">
        <Button variant="btn-secondary" className="h-36" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant="btn-primary" className="h-36" onClick={() => onClickSaveUpdate(educationId)}>
          {educationId ? 'Update' : 'Save'}
        </Button>
      </div>
    </>
  );
};

export default Education;
