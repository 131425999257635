import { DeleteIcon } from 'components/common/Icons';
import React, { useEffect, useState } from 'react';

const Img = ({ file, onRemove, index, gif = false }) => {
  const [fileUrl, setFileUrl] = useState(null);
  useEffect(() => {
    if (!gif) {
      if (file) {
        setFileUrl(URL.createObjectURL(file));
      }
    } else {
      setFileUrl(file);
    }
  }, [file, gif]);

  return fileUrl ? (
    <div className="comment-image">
      <img alt="pic" src={fileUrl} />

      {onRemove && (
        <button onClick={() => onRemove(index)}>
          <DeleteIcon color="#fff" width={20} height={20} />
        </button>
      )}
    </div>
  ) : null;
};

export default Img;
