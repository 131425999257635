import React, { useState, useEffect, useDebugValue } from 'react';
import CreatableSelect from 'react-select/creatable';
import { getGlobalColleges, getGlobalSchools, createGlobalSchools } from 'store/slices/globalDataSlice';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';

const GlobalUniversityDropdown = (props) => {
    const { name, onChange, id, selectedValue } = props;
    const { globalSchools, globalColleges } = useSelector(
        (state) => state.globalData,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState();
    const [value, setValue] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGlobalColleges());
        dispatch(getGlobalSchools());
    }, []);

    useEffect(() => {
        const college = _.merge(globalSchools, globalColleges);
        setOptions(college?.map((e) => ({ label: e, value: e })));
    }, [globalSchools, globalColleges]);


    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const handleOnchange = (newValue) => { 
        setValue(newValue);
        onChange(newValue);
    }
    
    const handleCreate = (inputValue) => {
        setIsLoading(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoading(false);
            setOptions((prev) => [...prev, newOption]);
            dispatch(createGlobalSchools({'name':inputValue}))
            setValue(newOption);
            onChange(newOption);
        }, 500);
    };
    
    return (
        <CreatableSelect
            isClearable
            isLoading={isLoading}
            isDisabled={isLoading}
            id={id}
            name={name}
            placeholder="School or College/University"
            options={options}
            value={selectedValue && selectedValue !== null ? options?.filter(function (option) {
                return option.label === selectedValue;
              }) : value}
            onChange={(newValue) => handleOnchange(newValue)}
            onCreateOption={handleCreate}
        />
    )
}

export default GlobalUniversityDropdown