import banner from 'assets/profiles/banner/banner.jpeg';
import React, { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import edit from 'assets/icons/edit.svg';
import defaultProfileLogo from "assets/images/default_profile_image.png";
import ImageUploader from 'components/ImageUploader';
import { MyBridgeCompoanyAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import {
  Camera,
  VerticalThreeDot
} from 'components/common/Icons';
import Modal from 'components/common/Modal/Modal';
import { HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import _ from 'lodash';
import { sanitizeBeforeUpdate } from 'pages/Teams/Common';
import { TeamDropdownMenu } from 'pages/Teams/Common/TeamDropdownMenu';
import { TeamsModal } from 'pages/Teams/TeamModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearTeamUpdated, setEditModalOpen, updateTeamDetails } from 'store/slices/teamCommunitySlice';
import { trimStr } from 'components/utils';

const Banner = (props) => {
  const [moreMenu, setMoreMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState();
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [popupHeader, setPopupHeader] = useState('');
  const [isMember, setIsMember] = useState(false)
  const [teamData, setTeamData] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slug = useParams();
  const { setOpenTab, openTab } = props;
  const url = `teams/profile/${slug.slug}`;

  const ref = React.useRef();

  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { teamSlugPage, teamMembers, editModalOpen, invitedMembers, resquestedMembers, userRequested, userAcceptedInvite, isTeamLeft, isTeamReported, teamOrganisers, teamId, loading, pending, isTeamUpdated } = useSelector(
    (state) => state.teamsCommunity,
  );

  useOnClickOutside(ref, () => setMoreMenu(false));

  useEffect(() => {
    _.forEach(teamOrganisers, function (user, key) {
      if (user.user.id === userProfileInfo.id) {
        setIsOrganizer(true);
      }
    });
  }, [teamOrganisers, userProfileInfo]);

  useEffect(() => {
    if (isTeamUpdated) {
      handlePopups()
      setTimeout(() => {
        dispatch(clearTeamUpdated())
      }, 100)
    }
  }, [isTeamUpdated])

  useEffect(() => {
    if (teamMembers?.length) {
      const isMember_ = teamMembers.find(m => m.user?.id === userProfileInfo.id)
      if (isMember_) {
        setIsMember(true)
      } else {
        setIsMember(false)
      }
    }
  }, [teamMembers, userProfileInfo])

  useEffect(() => {
    if (JSON.stringify(teamSlugPage) !== JSON.stringify(teamData)) {
      setTeamData({ ...(teamData ?? {}), ...(teamSlugPage ?? {}) })
    }
  }, [teamSlugPage])

  const customCSS = {
    imgBg: css`
      background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%), url(${teamSlugPage?.banner_image}),
        #ffffff;
      background-size: cover;
    `,
  };

  const cropHandlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const handlePopups = (type) => {
    setPopupHeader(heading(type));
    if (type === "Create") {
      dispatch(setEditModalOpen(true))
    } else {
      dispatch(setEditModalOpen(false))
    }

  };

  const heading = (type) => {
    if (type === 'Create') {
      return <h5 className="modal-title">Organize Team or Community</h5>;
    }
  };

  const uploadCropedImg = (img) => {
    if (cropType === 'profilePhoto') {
      setTeamData((state) => ({
        ...state,
        image_logo: img,
      }));
    } else {
      setTeamData((state) => ({
        ...state,
        banner_image: img,
      }));
    }
    setModalOpen(false);
  };

  const setDataValue = (name, data) => {
    setTeamData((state) => ({
      ...state,
      [name]: data,
    }));
  };

  const setData = (e) => {
    const { name, value, checked, files } = e.target ?? e ?? {};
    setTeamData((state) => ({
      ...state,
      [name]: value,
    }));

    if (name === 'image' || name === 'banner_image') {
      setTeamData((state) => ({
        ...state,
        [name]: files[0],
      }));
    }
    if (name === 'permission_new_post') {
      if (checked) {
        setTeamData((state) => ({
          ...state,
          [name]: true,
        }));
      } else {
        setTeamData((state) => ({
          ...state,
          [name]: false,
        }));
      }
    }
    if (name === 'permission_invite_members') {
      if (checked) {
        setTeamData((state) => ({
          ...state,
          [name]: true,
        }));
      } else {
        setTeamData((state) => ({
          ...state,
          [name]: false,
        }));
      }
    }
    if (name === 'organize_type') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'desc') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'name') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
      setTeamData((state) => ({
        ...state,
        slug: _.snakeCase(e.target.value, ' ', 2),
      }));
    }
    if (name === 'desc') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'location') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'rules') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value,
      }));
    }
    if (name === 'discoverability') {
      setTeamData((state) => ({
        ...state,
        [name]: e.target.value === 'Listed' ? true : false,
      }));
    }
  };

  const updateTeamSubmit = () => {
    dispatch(updateTeamDetails({ 'id': teamId, 'data': sanitizeBeforeUpdate(teamData) }));
  };

  // console.log("-------", invitedMembers, requestedMembers, userProfileInfo)
  const orgList = teamOrganisers?.map((organizer, i) => (`${organizer.user.first_name} ${organizer.user.last_name}`))?.join(", ")

  return (
    <section className="bordered-container overflow-visible">

      <div className="team-profile-banner">
        <div className={`Profile_Banner ${cx(customCSS.imgBg)}`}>
          <div className="banner container p-0">
            {isOrganizer ?
              <div className='upload_banner_button' onClick={() => {
                handlePopups('Create')
                setTimeout(() => {
                  cropHandlePopups("upload-banner")
                }, 100)
              }}>
                <Button variant="btn-secondary" className="btn btn-sm btn-light">
                  <span className='uicon'>
                    <Camera width={20} height={20} />
                  </span>
                  <span>
                    {" Upload Photo"}
                  </span>
                </Button>
              </div> : ""}
            {teamSlugPage?.banner_image && (
              <img src={teamSlugPage?.banner_image || banner} className="img-fluid" alt="banner" />
            )}

          </div>
        </div>

        <div className="user__info">
          <div className="user pb-0">
            <div className="avatar">
              {isOrganizer ?
                <div className='upload__icon' onClick={() => {
                  handlePopups('Create')
                  setTimeout(() => {
                    cropHandlePopups("profilePhoto")
                  }, 100)
                }}>
                  <Camera />
                </div> : ""}
              <MyBridgeCompoanyAvatar
                icon={teamSlugPage?.image_logo?.includes("placeholder") ? defaultProfileLogo : teamSlugPage?.image_logo}
                name={teamSlugPage?.name}
                size={120}
                className="profile-avatar"
              />

            </div>

            <div className="left">
              <div className="about">
                <div className="info">
                  <p className="profile__user__name text-capitalize">{teamSlugPage?.name}</p>
                  {/* <p className="connections">{teamMembers && teamMembers?.length > 0 ? teamMembers.length : '0'} members</p> */}
                </div>

                <div className="personal_info">
                  <p className="user__position">
                    {teamSlugPage?.industry_name?.length ? (teamSlugPage?.industry_name?.[0]?.name) : ''} {(teamSlugPage?.industry_name?.[0]?.name && teamSlugPage?.location?.length) ? " | " : ""} {teamSlugPage?.location?.length ? (teamSlugPage?.location) : ""}
                  </p>
                  {/* <p className="role">Created Team: { moment(teamSlugPage?.created_at).format('MMMM YYYY')}</p> */}
                </div>
              </div>
            </div>

            <div className="right">
              <div className="count"
                aria-label={orgList} data-microtip-position="top" role="tooltip"
              >
                {teamOrganisers && teamOrganisers.length > 0
                  ? trimStr(orgList, { length: 30 })
                  : `${userProfileInfo.first_name} ${userProfileInfo.last_name}`}

                <div className="mybridge_count">
                  <span className="organizer-label">Organizer</span>
                </div>
              </div>

              <div className="actions">
                {isMember || isOrganizer ? (
                  <>{isOrganizer ?
                    <Button
                      type="button"
                      variant="btn-secondary"
                      className="btn btn-sm btn-light"
                      onClick={() => handlePopups('Create')}
                    >
                      <img src={edit} className="icon" alt="edit profile" />
                      <span> Edit Profile </span>
                    </Button>
                    : ""}
                    <div className="more_actions" onClick={() => setMoreMenu((preState) => !preState)}>
                      {/* <span className="threedots" /> */}
                      <VerticalThreeDot />
                    </div>
                  </>
                ) : (
                  // isInvited ?
                  //   <Button disabled={loading} onClick={handleAcceptInvite}
                  //   >
                  //     {loading ? <Spinner /> : "Accept Invite"}
                  //   </Button>
                  //   : isRequested ?
                  //     <Button onClick={handleJoinRequest} disabled={true}>Request Sent</Button>
                  //     : <Button disabled={loading} onClick={handleJoinRequest}>
                  //       {loading ? <Spinner /> : "Join Team"}
                  //     </Button>
                  <></>
                )}

                {moreMenu ? (
                  <TeamDropdownMenu
                    ref={ref}
                    team={teamSlugPage}
                    onLeave={() => { navigate("/teams") }}
                    onReport={() => { navigate("/teams") }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {isMember || isOrganizer ?
            <div className="user__tabs">
              <HorizontalTabs className="user_tabs_item">
                <Tab setOpenTab={setOpenTab} openTab={openTab} path={url} to="overview">
                  Overview
                </Tab>
                <Tab setOpenTab={setOpenTab} openTab={openTab} path={url} to="team_disk">
                  Team Disk
                </Tab>
                <Tab setOpenTab={setOpenTab} openTab={openTab} path={url} to="manage_team">
                  {isOrganizer ? "Manage Team" : "Team Members"}
                </Tab>
              </HorizontalTabs>
            </div>
            : ""
          }
        </div>
      </div>

      {modalOpen && (
        <Modal
          onClose={cropHandlePopups}
          className={cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}
        >
          <ImageUploader cropType={cropType} uploadCropedImg={uploadCropedImg} handlePopups={cropHandlePopups} />
        </Modal>
      )}

      {editModalOpen && (
        <Modal
          onClose={handlePopups}
          heading={popupHeader}
          // className={modalType === 'Create' ? 'create-team' : 'leave-team'}
          className={'create-team'}
        >
          <TeamsModal
            handlePopups={handlePopups}
            setData={setData}
            setDataValue={setDataValue}
            cropHandlePopups={cropHandlePopups}
            teamData={teamData}
            onFormSubmit={updateTeamSubmit}
            mode="update"
          />
        </Modal>
      )}
    </section>
  );
};
export default Banner;
