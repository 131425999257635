import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';

import { userProfileAddInfo } from 'store/slices/userProfileSlice';

import { monthNameMapping } from 'config/constants';
import { HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import Modal from 'components/common/Modal/Modal';
import Loader from 'components/common/Loader';
import ImageUploader from 'components/ImageUploader';
import { MyBridgeAvatar } from 'components/common/Avatar';

import { Camera } from 'components/common/Icons';
import followers from 'assets/icons/followers.svg';

import { CometChat } from '@cometchat-pro/chat';
import defaultBanner from 'assets/profiles/banner/banner.png';

import './Banner.scss';

const Banner = (props) => {
  const { openTab, setOpenTab, isPublic } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfileInfo, experience } = useSelector((state) => state.userProfile);

  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState();

  const { first_name, last_name, slug, profile_banner_pic: BgImage, position, user_uuid } = userProfileInfo || {};

  const { company_name, location, start_month, currently_working, start_year, title, end_month, end_year } =
    experience?.results?.[0] || {};

  const USER_NAME = `${first_name} ${last_name}`;

  const gotoPublicProfile = () => {
    navigate(`/p/${slug}`);
  };

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const uploadCropedImg = (img) => {
    const formData = new FormData();
    if (cropType === 'profilePhoto') {
      formData.append('profile_pic', img);
    } else {
      formData.append('profile_banner_pic', img);
    }
    dispatch(userProfileAddInfo(formData)).then((res) => {
      if (res) {
        let uid = { user_uuid };
        let image = res?.payload?.profile_pic?.split('?');
        let avatar = image[0];

        var user = new CometChat.User(uid);

        user.setAvatar(avatar);

        CometChat.updateCurrentUserDetails(user).then(
          (user) => {
            console.log('user updated', user);
          },
          (error) => {
            console.log('error', error);
          },
        );
        setModalOpen(false);
      }
    });
  };

  const customCSS = {
    imgBg: css`
      background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%), url(${BgImage}), #ffffff;
      background-size: cover;
    `,
  };

  return (
    <div className="bordered-container">
      <div className={`Profile_Banner ${cx(customCSS.imgBg)}`}>
        <div className="banner p-0" style={{height:300}}>
          {BgImage ? <img src={''} className="img-fluid" alt="banner" /> : <Loader secondary />}
          <button type="button" className="btn btn-sm edit-banner" onClick={() => handlePopups('bannerPhoto')}>
            <label htmlFor="file-banner">
              <Camera width={20} height={20} />
              {/* <input className="d-none" id="file-banner" type="file" onChange={onBannerPicUpload} /> */}
              <span> Upload Banner </span>
            </label>
          </button>
        </div>
      </div>

      <div className="user__info">
        <div className="user">
          <div className="avatar">
            <MyBridgeAvatar
              icon={userProfileInfo?.profile_pic}
              name={USER_NAME}
              size={120}
              className="profile-avatar"
            />
            <div className="upload" onClick={() => handlePopups('profilePhoto')}>
              <div className="avatar_upload">
                <label htmlFor="file-input">
                  <Camera width={20} height={20} color={'#000000'} />
                </label>
                {/* <input id="file-input" type="file" onChange={onProfilePicUpload} /> */}
              </div>
            </div>
          </div>

          <div className="left">
            <div className="about">
              <div className="info">
                <p className="profile__user__name text-capitalize">
                  {userProfileInfo.first_name} {userProfileInfo.last_name}
                </p>
                {userProfileInfo.connect_count > 0 ? (
                  <p className="user__connections">{userProfileInfo.connect_count} connections</p>
                ) : null}
              </div>

              <div className="personal_info">
                {/* <p className="role">{userProfileInfo?.position}</p> */}
                {position && (
                  <>
                    <p className="user__position">
                      {title} at <b>{company_name}</b>
                    </p>
                    <p className="user__position__timeline">
                      {start_month && start_year && (
                        <>
                          {' '}
                          {monthNameMapping[start_month]} {start_year} -{' '}
                        </>
                      )}
                      {!!end_month ||
                        (currently_working && (
                          <> {currently_working ? 'Present' : `${monthNameMapping[end_month]} ${end_year}`}</>
                        ))}
                      {!!location && <> · {location}</>}
                    </p>
                  </>
                )}
              </div>

              {isPublic && (
                <div className="badge">
                  <span>Follow</span>
                </div>
              )}
            </div>
          </div>

          <div className="right">
            {userProfileInfo.follow_count > 0 ? (
              <div className="count">
                <div className="circle">
                  <img src={followers} className="icon" alt="circle" />
                  <span className="user__follow__count">{userProfileInfo.follow_count}</span>
                </div>
              </div>
            ) : null}

            <div className="actions">
              <button type="button" className="btn btn-sm profile__action__btn" onClick={gotoPublicProfile}>
                <span> View as public </span>
              </button>
              {/* <button type="button" className="btn btn-sm profile__action__btn">
                <span> Save to pdf </span>
              </button> */}
            </div>
          </div>
        </div>
        <div className="user__tabs">
          <HorizontalTabs className="user_tabs_item">
            <Tab setOpenTab={setOpenTab} openTab={openTab} path="profile" to="my-profile">
              myProfile
            </Tab>
            <Tab setOpenTab={setOpenTab} openTab={openTab} path="profile" to="network">
              myNetwork
            </Tab>
            <Tab setOpenTab={setOpenTab} openTab={openTab} path="profile" to="calendar">
              myCalender
            </Tab>
            <Tab setOpenTab={setOpenTab} openTab={openTab} path="profile" to="mydisk">
              myDisk
            </Tab>
          </HorizontalTabs>
        </div>
      </div>

      {modalOpen && (
        <Modal onClose={handlePopups} className={cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}>
          <ImageUploader cropType={cropType} uploadCropedImg={uploadCropedImg} handlePopups={handlePopups} />
        </Modal>
      )}
    </div>
  );
};
export default Banner;
