import React, { useMemo } from 'react';

import PostContext from 'context/PostContext';

import { PostCard } from 'components/PostCard';

import './post.scss';
import { useSelector } from 'react-redux';

const Post = ({ data, updatePost, modal = true }) => {
  const { like_count, dislike_count, comment_count, reposts_by } = data ?? {}
  const likeDislikeCount = (like_count ?? 0) + (dislike_count ?? 0);
  const hasInsights = likeDislikeCount > 0 || comment_count > 0 || reposts_by > 0
  const { userProfileInfo } = useSelector(state => state.userProfile)
  const { allConnectionIDs } = useSelector(state => state.connection)
  const { circle, following } = allConnectionIDs ?? {}

  const commentAllowed = useMemo(() => {
    if (data?.author?.id === userProfileInfo?.id) return true;
    const isInCircle = circle?.includes(data?.author?.id);
    const isInFollowers = following?.includes(data?.author?.id);
    if (data?.reply_control === 0) {
      return true
    } else if (data?.reply_control === 1) {
      return isInCircle
    } else if (data?.reply_control === 2) {
      return isInCircle || isInFollowers
    }
  }, [data, circle, following])

  return (
    <PostContext.Provider value={{
      postData: data,
      modal,
      hasInsights,
      likeDislikeCount,
      updatePost,
      commentAllowed
    }}>
      <div className="post-container">
        <PostCard.Header />
        <PostCard.Body />
        <PostCard.Footer />
      </div>
    </PostContext.Provider>
  );
};

export default Post;
