import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationIcon } from '../Icons';
import classNames from 'classnames';
import { useInbox, useGetNotifications } from '@trycourier/react-hooks';
import Dropdown from 'react-bootstrap/Dropdown';
import SectionLoader from '../Loader/sectionLoader';
import { isBeforeToday, isToday } from 'components/utils';
import { useNavigate } from 'react-router-dom';
import {
  ReportBugIcon,
  MenuThreeDots,
  SimpleTickIcon,
  DropNotifIcon,
  SettingsNewIcon,
} from 'components/Layouts/assets/icons';
import NotificationCard from './NotificationCard';
import { EmptyNotifIcon } from 'components/Layouts/assets/icons';
import { Inbox } from '@trycourier/react-inbox';

const Notification = (props) => {
  const { messages, unreadMessageCount } = props;
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [todayNotifs, setTodayNotifs] = useState([]);
  const [earlierNotifs, setEarlierNotifs] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [unreadListActive, setUnreadListActive] = useState(false);

  const ref = React.useRef();

  useOnClickOutside(ref, () => setShow(false));

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const inbox = useInbox();

  useEffect(() => {
    if (messages) {
      setTodayNotifs([]);
      setEarlierNotifs([]);
      setUnreadMessages([]);
      messages?.forEach((message) => {
        if (isToday(message.created)) setTodayNotifs((prevState) => [message, ...prevState]);
        else if (isBeforeToday(message.created)) setEarlierNotifs((prevState) => [message, ...prevState]);
        if (!message.read) setUnreadMessages((prevState) => [message, ...prevState]);
      });
    }
  }, [messages]);


  return (
    <div className="navbar-dropdown notification" ref={ref}>
      <div className={classNames('dropdown-wrap transparent', [show ? 'active' : ''])} onClick={handleShow}>
        <NotificationIcon color={show ? '#3D5A80' : '#5B5B5B'} />
        {unreadMessageCount ? <span>{unreadMessageCount}</span> : ''}
        {/* <Inbox/> */}
      </div>
      {show && (
        <div className="navbar-dropdown--menu">
          <div className="menu-header position-relative">
            <div className="wrap">
              <h3>Notification</h3>
              <Dropdown>
                <Dropdown.Toggle as="span" bsPrefix="notif-dropdown">
                  <MenuThreeDots width={11} height={4} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => inbox.markAllAsRead()}>
                    <SimpleTickIcon /> Mark all as read
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => inbox.setView()}>
                    <SimpleTickIcon /> Mark all as read
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate('/notifications')}>
                    <DropNotifIcon /> Open Notifications
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate('/settings/notifications')}>
                    <SettingsNewIcon /> Notification Settings
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="header-btn-container">
              <button className={!unreadListActive ? 'active' : ''} onClick={() => setUnreadListActive(false)}>
                All
              </button>
              <button className={unreadListActive ? 'active' : ''} onClick={() => setUnreadListActive(true)}>
                Unread
              </button>
            </div>
          </div>

          <div className="menu-body scrollbar">
            <div className="block">
              {!unreadListActive ? (
                inbox && !inbox?.isLoading ? (
                  <React.Fragment>
                    {todayNotifs?.length || earlierNotifs?.length ? (
                      <>
                        {todayNotifs?.length > 0 && (
                          <>
                            <div className="block-header">
                              <p>New</p>
                            </div>
                            {todayNotifs?.reverse().map((message, i) => (
                              <NotificationCard key={i} details={message} />
                            ))}
                          </>
                        )}
                        {earlierNotifs?.length > 0 && (
                          <>
                            <div className="block-header">
                              <p>Earlier</p>
                            </div>
                            {earlierNotifs?.reverse().map((message, i) => (
                              <NotificationCard key={i} details={message} />
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <div className="px-3 py-5 text-center">
                        <EmptyNotifIcon />
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <SectionLoader />
                )
              ) : unreadMessages?.length > 0 ? (
                <>
                  <div className="block-header">
                    <p>New</p>
                  </div>
                  {unreadMessages?.reverse().map((message, i) => (
                    <NotificationCard key={i} details={message} />
                  ))}
                </>
              ) : (
                <div className="px-3 py-5 text-center">
                  <EmptyNotifIcon />
                </div>
              )}
            </div>
          </div>
          <div className="menu-footer">
            <Link to="/notifications">All Notifications</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
