import React, { useState, useEffect } from 'react';
import Layout from 'components/Layouts';
import { TabContent, HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PendingInvitations from './PendingInvitations';
import SentInvitations from './InvitationsSent';
import NetworkSuggestions from './Suggestions';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitations, getSuggestions } from 'store/slices/connectionSlice';
import Loader from 'components/common/Loader';
import SectionLoader from 'components/common/Loader/sectionLoader';
import { Arrowback } from 'components/common/Icons';

const NetworkInvitations = () => {
    let { id } = useParams();
    if (!id) {
        id = 'pending-invitations';
    }
    const dispatch = useDispatch();
    const [, setOpenTab] = useState(id || 'pending-invitations');
    const { mutualConnections, loading, follow, invites, requestChange, requestDeclined, suggestions } = useSelector((state) => state.connection);
    const navigate = useNavigate()
    useEffect(() => {
        if (id === "pending-invitations") {
            dispatch(getInvitations('pending'));
        } else if (id === "request-sent") {
            dispatch(getInvitations('sent'));
        } else if (id === "suggestions") {
            dispatch(getSuggestions('following'));
        }
    }, [id, requestChange, requestDeclined]);

    const goBack = () => {
        navigate(-1)
    }

    return (
        <React.Fragment>
            <section className='networks-container'>
                <div className='container'>
                    <div className='networks-container--body'>
                        <span className='back-link'>
                            <Link onClick={() => goBack()}>
                                <Arrowback color="black" /> Back
                            </Link>
                        </span>
                        <div className="d-flex justify-content-between mt-4">
                            <div className='d-flex justify-content-start align-items-center'>
                                <h5 className="fw-medium">
                                    Manage invitations
                                </h5>
                            </div>
                            <Link className='link-blue'>Blocked Network</Link>
                        </div>
                        <div className="networks-container--tabContainer mt-2">
                            <HorizontalTabs>
                                <Tab setOpenTab={setOpenTab} openTab={id} path="invitations" to="pending-invitations">
                                    Pending Invitations
                                </Tab>
                                <Tab setOpenTab={setOpenTab} openTab={id} path="invitations" to="request-sent">
                                    Requests Sent
                                </Tab>
                                <Tab setOpenTab={setOpenTab} openTab={id} path="invitations" to="suggestions">
                                    Suggestions
                                </Tab>

                            </HorizontalTabs>
                            <TabContent tab="pending-invitations" openTab={id}>
                                {loading ? <SectionLoader /> : <PendingInvitations user={invites} />}
                            </TabContent>
                            <TabContent tab="request-sent" openTab={id}>
                                {loading ? <SectionLoader /> : <SentInvitations user={invites} />}
                            </TabContent>
                            <TabContent tab="suggestions" openTab={id}>
                                <NetworkSuggestions suggestions={suggestions} />
                            </TabContent>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default NetworkInvitations;