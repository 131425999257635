import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TabContent, Tab, VerticalTabs } from 'components/common/Tabs/Tabs';
import { Menu } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import Introduction from './Introduction';
import PersonalDetails from './PersonalDetails';
import Experience from './Experience';
import Skills from './Skills';
import Education from './Education';
import Milestones from './Milestones';
import Certifications from './Certifications';
import Testimonial from './Testimonial';
import AddSection from './AddSections';

import MakeProfilePublic from './MakeProfilePublic';
import ConnectionCard from './ConnectionCard';
import ExploreCareer from './ExploreCareer';
import { useGetProfileSections } from '../../hooks';
import MyActivity from './MyActivity';
import Analytics from 'pages/Company/components/Analytics';

const ProfileSection = (props) => {
  const { tid, slug } = useParams();
  const [createParams] = useSearchParams();
  const [editTab, setEditTab] = useState(createParams.get('create') === 'true' || false);
  const navigate = useNavigate();
  const { isMobile, isTablet } = useSelector((state) => state.layoutReducer);
  const { analyticsDetail } = useSelector((state) => state.userProfile);

  const {
    showProfileIntroductionButton,
    showExperienceButton,
    showSkillButton,
    showEducationButton,
    showMilestoneButton,
    showCertificationButton,
    showTestimonialsButton,
    showMyActivity,
  } = useGetProfileSections();

  const [openTab, setOpenTab] = useState(tid || 'personal-details');

  const showAddPanel =
    showProfileIntroductionButton ||
    showExperienceButton ||
    showSkillButton ||
    showEducationButton ||
    showMilestoneButton ||
    showCertificationButton ||
    showTestimonialsButton;

  useLayoutEffect(() => {
    if (!tid && !showProfileIntroductionButton) {
      navigate('/profile/my-profile/introduction');
      setOpenTab('introduction');
    } else if (!tid) {
      navigate('/profile/my-profile/personal-details');
      setOpenTab('personal-details');
    }
  }, [tid, navigate, showProfileIntroductionButton]);

  const onTabUpdate = () => {
    setEditTab(false);
  };

  const profileTabs = [
    {
      title: 'Introduction',
      path: 'introduction',
      show: !showProfileIntroductionButton,
      content: <Introduction edit={editTab} onTabUpdate={onTabUpdate} />,
    },
    // {
    //   title: 'myActivity',
    //   path: 'my-activity',
    //   show: true,
    //   content: <MyActivity />,
    // },
    {
      title: 'Personal Details',
      path: 'personal-details',
      show: true,
      content: <PersonalDetails />,
    },
    {
      title: 'Experience',
      path: 'experience',
      show: !showExperienceButton,
      content: <Experience />,
    },
    {
      title: 'Skill',
      path: 'skills',
      show: !showSkillButton,
      content: <Skills />,
    },
    {
      title: 'Education',
      path: 'education',
      show: !showEducationButton,
      content: <Education />,
    },
    {
      title: 'Milestones',
      path: 'milestones',
      show: !showMilestoneButton,
      content: <Milestones />,
    },
    {
      title: 'Certifications and Awards',
      path: 'certificates-and-awards',
      show: !showCertificationButton,
      content: <Certifications />,
    },
    {
      title: 'Testimonial',
      path: 'testimonial',
      show: !showTestimonialsButton,
      content: <Testimonial />,
    },
  ];

  const [showMenu, onMenuClick] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => {
    onMenuClick(false);
  });

  const handleMenuClick = (toPath) => {
    onMenuClick(false);
    navigate(`/profile/my-profile/${toPath}`);
  };

  const tabs = profileTabs
    .filter((t) => t.show || (tid === t.path && createParams.get('create')))
    .map((t) => {
      return (
        <Tab
          activeStyle={{
            background: '#E4E6EB',
          }}
          setOpenTab={setOpenTab}
          openTab={tid}
          path={slug ?? 'profile/my-profile'}
          to={t.path}
        >
          {t.title}
        </Tab>
      );
    });

  const mobileTabMenu = profileTabs
    .filter((t) => t.show || (tid === t.path && createParams.get('create')))
    .map((t) => {
      return (
        <div className={'link-menu'} onClick={() => handleMenuClick(t.path)}>
          {t.title}
        </div>
      );
    });

  const tabContents = profileTabs
    .filter((t) => t.show || (tid === t.path && createParams.get('create')))
    .map((t) => {
      return (
        <TabContent tab={t.path} openTab={openTab}>
          {t.content}
        </TabContent>
      );
    });

  function handleAddSection(section) {
    setOpenTab(section.path);
    setEditTab(true);
  }

  return (
    <>
      {showAddPanel && (
        <div className="col-12">
          <AddSection onAddSection={handleAddSection} />
        </div>
      )}

      <div className="row pt-3">
        <div className="col-12 col-md-8 col-xl-9">
          <div className="row ht-100">
            <div className="col-12 col-md col-lg-4 col-xl-4">
              <div className="pe-0">
                {/* Hamburger icon for mobile */}
                {(isMobile || isTablet) && (
                  <>
                    <div className="mobile-head mb-3">
                      <div className="left-wrapper">
                        <Menu onClick={() => onMenuClick(true)} />
                      </div>
                    </div>
                    {showMenu && (
                      <div className="mobile-menu" ref={ref}>
                        {mobileTabMenu}
                      </div>
                    )}
                  </>
                )}

                {!isMobile && !isTablet && <VerticalTabs>{tabs}</VerticalTabs>}
              </div>
              <Analytics analyticsList={analyticsDetail} />
            </div>

            <div
              className={
                isMobile || isTablet
                  ? 'col-12 col-md col-lg-8 col-xl-8'
                  : 'border-start border-end col-12 col-md col-lg-8 col-xl-8 px-4'
              }
            >
              {tabContents}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 col-xl-3">
          <div className="row gap-2">
            <div className="col-12">
              <ConnectionCard />
            </div>
            <div className="col-12">
              <MakeProfilePublic />
            </div>
            <div className="col-12">
              <ExploreCareer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSection;
