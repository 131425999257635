import { CircleIcon, FollowerIcon, Globe } from 'components/common/Icons';

export const POST_ACTIONS = [
  {
    key: 0,
    value: 'Everyone',
    icon: <Globe width={15} height={15} className="me-2" />,
  },
  {
    key: 1,
    value: 'My circle',
    icon: <CircleIcon width={15} height={15} className="me-2" />,
  },
  {
    key: 2,
    value: 'My circle & followers',
    icon: <FollowerIcon width={15} height={15} className="me-2" />,
  },
];

export const POST_REPLY_ACTIONS = [
  {
    key: 0,
    value: 'Everyone can reply',
    icon: <Globe width={15} height={15} className="me-2" />,
  },
  {
    key: 1,
    value: 'My circle can reply',
    icon: <CircleIcon width={15} height={15} className="me-2" />,
  },
  {
    key: 2,
    value: 'My circle & followers',
    icon: <FollowerIcon width={15} height={15} className="me-2" />,
  },
];

export const COMPANY_POST_REPLY_ACTIONS = [
  {
    key: 0,
    value: 'Everyone',
    icon: <Globe width={15} height={15} className="me-2" />,
  },
  {
    key: 1,
    value: 'No One',
    icon: <FollowerIcon width={15} height={15} className="me-2" />,
  },
 
];

export const defaultPosts = [
  {
    id: 1,
    media: [
      {
        id: 147,
        file: './assets/greatseeingyou.mov',
        created_at: '2023-03-22T07:10:27.176605Z',
        post: 1,
        mediaType: 'video',
      },
    ],
    content: 'Fell the energy... Gald you joined.2',
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 2,
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/422/happyPostImage.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: 'We are happy to see you join our network',
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 3,
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/423/wonderfulTechnology.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: "Isn't technology a wonderful thing? Relationships can be bridged and career nurtured.",
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 3,
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/423/wonderfulTechnology.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: "Isn't technology a wonderful thing? Relationships can be bridged and career nurtured.",
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },

  {
    id: 3,
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/423/wonderfulTechnology.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: "Isn't technology a wonderful thing? Relationships can be bridged and career nurtured.",
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 3,
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/423/wonderfulTechnology.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: "Isn't technology a wonderful thing? Relationships can be bridged and career nurtured.",
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
  {
    id: 3,
    media: [
      {
        id: 146,
        file: 'https://nyc3.digitaloceanspaces.com/mybridge-cdn/media/posts/423/wonderfulTechnology.jpg',
        created_at: '2023-03-22T07:03:39.372889Z',
        post: 2,
        mediaType: 'image',
      },
    ],

    content: "Isn't technology a wonderful thing? Relationships can be bridged and career nurtured.",
    created_at: '2023-03-22T07:25:10.242709Z',
    updated_at: '2023-03-22T07:25:10.242731Z',
  },
];
