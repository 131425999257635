import { SmileyIcon } from 'components/common/Icons';
import React from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

const EmojiPicker = ({ onSelect, setShow, show, setShowGiphy }) => {
  return (
    <>
      <button
        onClick={() => {
          setShow((prev) => !prev);
          setShowGiphy(false);
        }}
      >
        <SmileyIcon />
      </button>
      {show && <Picker data={data} theme="light" skinTonePosition="none" onEmojiSelect={onSelect} />}
    </>
  );
};

export default EmojiPicker;
