import { CometChat } from '@cometchat-pro/chat';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import { CalendarIcon, CallIcon, EventIcon, GifIcon, StartVideo, upload } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { startDefaultMeeting } from 'store/slices/meetingSlice';
import { createGourpPost } from 'store/slices/teamCommunitySlice';
import EmojiPicker from './components/EmojiPicker';
import FileInput from './components/FileInput';
import GiphyPicker from './components/GiphyPicker';
import Img from './components/Img';
import { randomId } from 'components/utils';
import { sendGroupPost } from 'pages/Teams/Common';
import * as enums from "cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/enums"
import { Spinner } from 'components/common/Loader';

const FeedForm = ({ className, id, onClose, quotePost, teamDetails, groupId, messageSent, organiser, actionGenerated }) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { isPostCreated } = useSelector((state) => state.teamsCommunity);
  const [currentPost, setCurrentPost] = useState(null);
  const [textFocus, setTextFocus] = useState(false);
  const [callListenerId, setCallListenerId] = useState(randomId(5))
  const ref = React.useRef();
  const inputRef = useRef();
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [pics, setPics] = useState([]);
  const [gif, setGif] = useState([]);
  const [showGiphy, setShowGiphy] = useState(false);
  const [sendingPost, setSendingPost] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const showImgaeUpload = pics.length || gif.length;

  const postIsValid = text?.trim()?.length !== 0 || pics.length !== 0 || gif.length !== 0;

  useOnClickOutside(ref, () => {
    setShow(false);
    setShowGiphy(false);
  });

  // useEffect(() => {
  //   CometChat.addCallListener(
  //     callListenerId,
  //     new CometChat.CallListener({
  //       onIncomingCallReceived: (call) => {
  //         console.log("Incoming call:", call);

  //       },
  //       onOutgoingCallAccepted: (call) => {
  //         console.log("Outgoing call accepted:", call);
  //         startCall(call);
  //       },
  //       onOutgoingCallRejected: (call) => {
  //         console.log("Outgoing call rejected:", call);
  //       },
  //       onIncomingCallCancelled: (call) => {
  //         console.log("Incoming call calcelled:", call);
  //         CometChat.rejectCall(call?.sessionId, CometChat.CALL_STATUS.CANCELLED)
  //           .then((r) => { console.log("rejected call", call) })
  //           .catch((e) => { console.error("error rejected the call", call) })
  //       }
  //     })
  //   );
  // }, [])

  const USER_NAME = userProfileInfo?.first_name + ' ' + userProfileInfo?.last_name;

  const handleShowGiphy = () => {
    setShow(false);
    setShowGiphy((prev) => !prev);
  };

  const insertAtPos = (value) => {
    setTextFocus(true);
    const { current: ref } = inputRef;
    let startPos = ref.selectionStart;
    let endPos = ref.selectionEnd;
    setText(ref.value.substring(0, startPos) + value.native + ref.value.substring(endPos, ref.value.length));
  };

  const onGifClick = (image, e) => {
    e.preventDefault();
    setTextFocus(true);
    setGif([...gif, image]);
    showGiphy(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShow(false);
    setSendingPost(true);
    setShowGiphy(false);
    let message = '';
    let uploadedFile = '';
    let messageCat = '';
    let messageType = 'Text';
    let resourceId = '';
    if (text.trim().length > 0) {
      message = text;
      messageCat = 'Text';
      messageType = 'Text'
    }

    if (pics.length > 0) {
      message = text;
      uploadedFile = pics[0];
      messageCat = 'Media';
      messageType = 'Image'
    }

    if (gif.length > 0) {
      const url = gif[0]?.images?.original?.url;
      if (!url || url.length <= 0) {
        console.error("Invalid gif", gif)
        return;
      }
      message = url;
      uploadedFile = {
        name: gif?.[0]?.title,
        extension: "gif",
        mimeType: "image/gif",
        url
      }
      messageCat = 'Media';
      messageType = 'Gif';
    }

    if (teamDetails.permission_new_post === false && !organiser) {
      // const body = { team_id: teamDetails.id, uid: userProfileInfo.user_uuid, guid: groupId, message:message, file: uploadedFile, message_category: messageCat,resource_id: resourceId};
      const body = { for_team_community_id: teamDetails.id, uid: userProfileInfo.user_uuid, guid: groupId, message: message, file: uploadedFile, message_category: messageCat, resource_id: resourceId };
      dispatch(createGourpPost(body)).then(() => {
        setSendingPost(false)
      }).catch(() => {
        setSendingPost(false)
      });
    } else {
      const msg = {
        receiverID: groupId,
        messageType,
        text: message,
        gif: uploadedFile,
        image: uploadedFile,
        senderId: userProfileInfo?.uuid
      }
      sendGroupPost(msg, (message) => {
        setText('');
        setPics([]);
        setGif([]);
        setSendingPost(false)
        console.log("sent message", message)
        actionGenerated?.(enums.ACTIONS["MESSAGE_COMPOSED"], [
          message,
        ])
      }, (err) => {
        setSendingPost(false)
        console.error(err)
      })
    }
    setText('');
    setPics([]);
    setGif([]);
  };

  const initiateCall = (type) => {
    if (type === "Audio") {
      actionGenerated?.(enums.ACTIONS.INITIATE_VIDEO_CALL);
    }
    if (type === "Video") {
      actionGenerated?.(enums.ACTIONS.INITIATE_VIDEO_CALL);
    }
  }

  return (
    <div className={className} ref={ref}>
      <Card className="mb-3 create-post">
        <div className="create-post--header">
          <div className="wrap">
            <div className="icon">
              <MyBridgeAvatar icon={userProfileInfo?.profile_pic} name={USER_NAME} />
            </div>
            <div className="content">

              <TextareaAutosize
                onChange={(e) => setText(e.target.value)}
                ref={inputRef}
                value={text}
                className={textFocus ? 'focused' : ''}
                onFocus={() => {
                  setTextFocus(true);
                  setShow(false);
                  setShowGiphy(false);
                }}
                placeholder="What's in you mind"
                minRows={textFocus ? 2 : 1}
                maxRows={5}
              />

              {quotePost?.quote && (
                <Card className="pt-3 overflow-hidden">
                  <div className="post-card--header">
                    <div className="wrap">
                      <div className="icon">
                        <MyBridgeAvatar
                          icon={currentPost?.author?.profile_pic}
                          name={currentPost?.author?.first_name + ' ' + currentPost?.author?.last_name}
                        />
                      </div>

                      <div className="content">
                        <div>
                          <h4>{currentPost?.author?.first_name + ' ' + currentPost?.author?.last_name}</h4>
                          <strong>{currentPost?.author?.position}</strong>
                          <span>{moment(currentPost?.created_at).startOf().fromNow()}</span>
                        </div>
                      </div>
                    </div>

                    {currentPost?.content && <div className="description mt-2 ps-0">{currentPost?.content}</div>}
                  </div>

                  <div className="post-card--body">
                    {currentPost?.media?.length > 0 ? (
                      <>
                        {currentPost?.media?.map((img) => (
                          <img key={img.id} src={img.file} alt={img.id} />
                        ))}
                      </>
                    ) : null}

                    {currentPost?.gif && <img src={currentPost?.gif} alt="gif" />}
                  </div>
                </Card>
                // <div className='quote-post'>
                //   <div className='quote-post_header'>

                //   </div>
                //   <div className='quote-post_icon'>
                //     <MyBridgeAvatar
                //       icon={currentPost?.author?.profile_pic}
                //       name={currentPost?.author?.first_name + ' ' + currentPost?.author?.last_name}
                //     />
                //   </div>

                //   <div className='quote-post_content'>
                //     <h4>{currentPost?.author?.first_name + ' ' + currentPost?.author?.last_name}</h4>
                //     <strong>{currentPost?.author?.position}</strong>
                //     <span>{moment(currentPost?.created_at).startOf().fromNow()}</span>
                //     {currentPost?.content && <div className="description mt-2">{currentPost?.content}</div>}
                //   </div>
                // </div>
              )}

              {pics.length > 0 && (
                <div className="mt-2">
                  {pics.map((picFile, index) => (
                    <Img
                      key={index}
                      index={index}
                      file={picFile}
                      onRemove={(rmIndx) => setPics(pics.filter((pic, index) => index !== rmIndx))}
                    />
                  ))}
                </div>
              )}

              {gif.length > 0 && (
                <div className="mt-2">
                  {gif.map((picFile, index) => (
                    <Img
                      gif
                      key={index}
                      index={index}
                      file={picFile?.images?.original?.url}
                      onRemove={(rmIndx) => setGif(pics.filter((pic, index) => index !== rmIndx))}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>


        </div>
        <div className="create-post--footer">
          <ul>
            {!showImgaeUpload && (
              <>
                <li>
                  <FileInput
                    setTextFocus={setTextFocus}
                    onClick={() => {
                      setShow(false);
                      setShowGiphy(false);
                    }}
                    onChange={(pics) => setPics(pics)}
                  />
                </li>
                <li>
                  <button onClick={handleShowGiphy}>
                    <GifIcon />
                  </button>

                  {showGiphy && <GiphyPicker onGifClick={onGifClick} />}
                </li>
              </>
            )}
            <li>
              <EmojiPicker show={show} setShowGiphy={setShowGiphy} onSelect={insertAtPos} setShow={setShow} />
            </li>
            {teamDetails.permission_new_post === false && !organiser ? "" : <>
              <li>
                <button onClick={e => navigate("/profile/schedule")} className={'msg-btn'}>
                  <CalendarIcon />
                </button>
              </li>
              <li>
                <button onClick={e => dispatch(startDefaultMeeting(true))} className={'msg-btn'}>
                  <EventIcon />
                </button>
              </li>
              <li><button onClick={() => initiateCall('Audio')} className={'msg-btn'}>
                <CallIcon />
              </button>
              </li>
              <li>
                <button onClick={() => initiateCall('Video')} className={'msg-btn'}>
                  <StartVideo width={25} height={25} />
                </button>
              </li>
            </>}
          </ul>
          <div className='d-flex'>
            <Button disabled={sendingPost || !postIsValid} className="post-button" onClick={handleSubmit}>
              {sendingPost ? <Spinner /> : "Post"}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default FeedForm;
