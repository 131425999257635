import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOnboardingStatus } from "store/slices/onboardingSlice";
import OnboardingStepper from "./Stepper";
import { onboardingSteps } from '../constant'

const OnboardingWrapper = ({ children, activeSteps, className }) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { onboardingStatus } = useSelector((state) => state.onboarding);
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(true)
  const contentClass = className ? `onboarding_content ${className}` : 'onboarding_content';
  useEffect(() => { setEmail(userProfileInfo?.email) }, [userProfileInfo]);

  const handleRedirect = () => {
    const { profile, employment, photo, job_availabilty, contact, connect } = onboardingStatus?.data;
    if (!profile) {
      navigate(onboardingSteps[0].url)
    }
    else if (!employment) {
      navigate(onboardingSteps[1].url)
    }
    else if (!photo) {
      navigate(onboardingSteps[2].url)
    }
    else if (!job_availabilty) {
      navigate(onboardingSteps[3].url)
    }
    else if (!contact) {
      navigate(onboardingSteps[4].url)
    }
    else if (!connect) {
      navigate(onboardingSteps[5].url)
    }
    else {
      navigate('/')
    }
  }

  useEffect(() => {
    // if (onboardingStatus) {
    //   handleRedirect();
    // }
  }, [onboardingStatus])

  useEffect(() => {
    if (email !== undefined) {
      const payload = {
        emailId: email
      }
      dispatch(getOnboardingStatus(payload))
      ref.current = false;
    }
  }, [email])

  return (
    <section className="onboarding">
      <div className='container'>
        <OnboardingStepper activeSteps={activeSteps} />
        <div className={contentClass}>
          {children}
        </div>
      </div>
    </section>
  )
};

export default OnboardingWrapper;