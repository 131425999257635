import React, { memo } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Spinner } from 'components/common/Loader';

// const VideoPlayer = ({ src, ...props }) => {
//   return <ReactPlayer url={src} controls {...props} />;
// }

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.ready !== this.state.ready) {
      return true;
    }
    if (nextProps?.src?.substring?.(0, nextProps?.src?.indexOf("?")) !== this.props.src?.substring?.(0, this.props?.src?.indexOf("?"))) {
      return true
    }
    return false;
  }

  render() {

    return <>
      <span style={{
        opacity: this.state.ready ? 0 : 1,
        zIndex: this.state.ready ? -1 : 1,
        pointerEvents: this.state.ready ? 'none' : 'all'
      }} >
        <Spinner size="lg" color="white" />
      </span>
      <ReactPlayer
        style={{ opacity: this.state.ready ? 1 : 0 }}
        url={this.props.src}
        controls
        {...this.props}
        onReady={() => this.setState({ ready: true })}
      />
    </>
  }
}

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default memo(VideoPlayer);
