import React, { useState } from 'react';
import { Input } from 'components/common/Input';
import { SimpleCloseIcon } from '../Icons';
import Button from '../Button';
import classNames from 'classnames';

const AutoComplete2 = (props) => {
  const [value, setValue] = useState('');
  const [clauses, setClauses] = useState(props.tags ? props.tags.map((item) => item.name) : []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      const clause = value.trim();

      if (clause) {
        setClauses((clauses) => [...clauses, clause]);
        setTags(value);
        setValue('');
      }
      setValue('');
    }
  };

  const onClickAdd = (evt) => {
      evt.preventDefault();
      const clause = value.trim();

      if (clause) {
        setClauses((clauses) => [...clauses, clause]);
        setTags(value);
        setValue('');
      }
      setValue('');
  };

  const handleDelete = (clause) => () => {
    setClauses((clauses) => clauses.filter((item) => item !== clause));

    if (props.newTags && props.newTags.length) {
      const filteredNewTags = props.newTags.filter((item) => item.value !== clause);

      if (filteredNewTags.length < props.newTags.length) {
        props.setdatabyname(props.name, filteredNewTags);
      }
    }

    if (props.tags && props.tags.length) {
      const filteredTags = props.tags.filter((item) => item.name !== clause);

      if (filteredTags.length < props.tags.length) {
        props.setdatabyname(props.name, filteredTags);
      }
    }

    if (props.listedData && props.listedData.length) {
      const filteredListedData = props.listedData.filter((item) => item.name !== clause);

      if (filteredListedData.length < props.listedData.length) {
        props.setdatabyname(props.name, filteredListedData);
      }
    }
  };

  const isOperator = (clause) => {
    return ['OR', 'AND'].includes(clause.toUpperCase());
  };

  const handleValue = (item) => {
    const clause = item.name.trim();

    if (clause) {
      setClauses((clauses) => [...clauses, clause]);
      setTags(item);
      setValue('');
    }
    setValue('');
  };

  const setTags = (newItem) => {
    // props.setdatabyname(props.name, [newItem]);
      if (props.listedData) {
        props.setdatabyname(props.name, [...props.listedData, newItem]);
      } else {
        props.setdatabyname(props.name, [{is_active:true, name:newItem}]);
      }
      setValue('');
  };

  return (
    <div className={props.className?`${props.className} search-chip`:"search-chip"}>
      <div className='d-flex'>
        <Input
        id="clauses"
        placeholder={props.placeholder}
        fullWidth
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={classNames("me-3", props.inputClassName && props.inputClassName)}
        />
        {props.needBtn && <Button variant='btn-secondary px-4 ' onClick={onClickAdd}>Add</Button>}
      
      </div>
      {value && (
        <ul className="tagList">
          {props.tagList &&
            props.tagList
              .filter((tag) => tag.name.toLowerCase().includes(value.toLowerCase()))
              .map((item, index) => (
                <li key={index} onClick={() => handleValue(item)}>
                  {item && item.name}
                </li>
              ))}
        </ul>
      )}
      
      {clauses && (
        <div className='mb-3'>
          {clauses.map((clause, i) => (
            <span key={i} className={'chips'}>
              {!isOperator(clause) ? clause : clause.toUpperCase()}
              <SimpleCloseIcon color="#fff" width={12} height={12} onClick={handleDelete(clause)} className="ms-2" />
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoComplete2;
