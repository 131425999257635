import React, { useState } from 'react';
import JobListingHeading from './JobListingHeading';
import { JobList } from 'pages/Profile/constants';
import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import { HorizontalTabs, TabContent, TabWithoutLink } from 'components/common/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import {
  getGlobalIndustries,
  getGlobalCities,
  getGlobalCountries,
  getGlobalStates,
} from 'store/slices/globalDataSlice';
import { Input } from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { getUserJobPreferences, setUserJobPreferences } from 'store/actions/Job.Action';
import Pills from 'components/common/Pills';
import Button from 'components/common/Button';
import noJobsfound from 'assets/images/blank_jobs_image.svg';
import _ from 'lodash';

const JobListMyJobs = () => {
  const [openSubTab, setSubOpenTab] = useState('Pending Review');
  // const [jobsListbyStatus, setJobList] = useState([]);
  const [pendingReview, setPending] = useState([]);
  const [inReview, setInReview] = useState([]);
  const [declined, setDeclined] = useState([]);
  const [userPreferences, setUserPreferences] = useState();
  const [userTitles, setUserTitles] = useState([]);
  const [userTitle, setUserTitle] = useState();
  const [industriesArr, setIndustriesArr] = useState([]);
  const [locationsArr, setLocationsArr] = useState([]);
  const [jobAvailabilty, setJobAvailability] = useState(false);
  const [jobAlert, setJobAlert] = useState();
  const [employmentTypeArray, setEmploymentTypeArray] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedSate] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [resumeFile, setResumeFile] = useState();
  const [additionalFile, setAdditionalFile] = useState();

  const { savedJobList, applyedJobsList, jobPreferences } = useSelector((state) => state.jobReducer);
  const { globalIndustries, globalCities, globalCountries, globalStates, loading } = useSelector(
    (state) => state.globalData,
  );
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGlobalIndustries());
    dispatch(getGlobalCities());
    dispatch(getUserJobPreferences());
    dispatch(getGlobalCountries());
    dispatch(getGlobalStates());
  }, []);

  const employmentTypeArr = [
    { label: 'Full-Time', value: 1 },
    { label: 'Contractor', value: 2 },
    { label: 'Part Time', value: 3 },
    { label: 'Internship', value: 4 },
    { label: 'Freelancer', value: 5 },
  ];

  const cities = globalCities.map((e) => ({ label: e.city, value: e.id }));

  useEffect(() => {
    if (applyedJobsList) {
      let finalData = [];

      const grouped = groupBy(applyedJobsList, (item) => item.status);
      if (grouped) {
        for (const [key, value] of grouped.entries()) {
          if (value && value.length) {
            let finalObject = Object.assign({ status: key, jobslist: value });
            finalData.push(finalObject);
          }
        }
      }
      for (let i = 0; i < finalData.length; i++) {
        if (finalData[i].status === 'APPLIED') {
          setPending(finalData[i].jobslist);
        }
        if (finalData[i].status === 'SHORTLISTED') {
          setInReview(finalData[i].jobslist);
        }
        if (finalData[i].status === 'REJECTED') {
          setDeclined(finalData[i].jobslist);
        }
      }
      // setJobList(finalData);
    }
    // eslint-disable-next-line
  }, [applyedJobsList]);

  useEffect(() => {
    setUserPreferences(jobPreferences);
    setUserTitles(jobPreferences.job_title);
  }, [jobPreferences]);

  const groupBy = (list, keyGetter) => {
    if (list) {
      const map = new Map();
      let collection = [];
      list.forEach((item) => {
        const key = keyGetter(item);
        collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });

      return map;
    }
  };

  const handleTitleTags = (e) => {
    if (e.key === 'Enter') {
      setUserTitles([...userTitles, e.target.value]);
      setUserTitle('');
    }
  };

  const handleRemoveItem = (skill) => {
    const name = skill;
    setUserTitles(userTitles.filter((item) => item !== name));
  };

  const filterCities = (inputValue) => {
    return cities.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const addDeleteEmployment = (e) => {
    if (e.target.checked) {
      //append to array
      setEmploymentTypeArray((oldArray) => [...oldArray, e.target.value]);
    } else {
      //remove from array
      setEmploymentTypeArray(employmentTypeArray.filter((item) => item !== e.target.value));
    }
  };

  const countrySearch = (e) => {
    dispatch(getGlobalCountries({ page: 1, search: e }));
  };

  const stateSearch = (e) => {
    dispatch(getGlobalStates({ page: 1, search: e, country_code: selectedCountry.country_code }));
  };

  const citySearch = (e) => {
    dispatch(getGlobalCities({ search: e, state_code: selectedState.state_code }));
  };

  const handleCountrySelect = (e) => {
    setSelectedCountry(e);
    dispatch(getGlobalStates({ page: 1, search: '', country_code: e.country_code }));
  };

  const handleStateSelect = (e) => {
    setSelectedSate(e);
    dispatch(getGlobalCities({ search: '', state_code: e.state_code }));
  };

  const submitPreferences = () => {
    const formData = new FormData();
    // const body = {'locations':{'country': selectedCountry.id, 'city':selectedCity.id}, 'job_availabilty': jobAvailabilty, 'job_alert': jobAlert, 'job_title': userTitles, 'resume_first': resumeFile, 'resume_second': additionalFile, 'employment_type': employmentTypeArray, 'industries': industriesArr};
    formData.append('locations', {'country': selectedCountry.id, 'city':selectedCity.id})
    formData.append('job_availabilty', jobAvailabilty)
    formData.append('job_alert', jobAlert)
    formData.append('job_title', userTitles)
    formData.append('resume_first', resumeFile)
    formData.append('resume_second', additionalFile)
    formData.append('employment_type', employmentTypeArray)
    formData.append('industries', industriesArr)
    dispatch(setUserJobPreferences(formData));
  }

  return (
    <>
      {/* My Job Preferences */}
      <div className="post_job_hding">Preferences </div>
      <div className="job-preference">
        <div className="job-preference--inner-section">
          <div>
            <h5>Let recruiters know your status</h5>
            <p>Share that you’re open to new opportunities and let your next job find you.</p>
          </div>
          <div className="toggleSection">
            <ToggleSwitch
              onChange={(e) => setJobAvailability(e.target.value)}
              name="job_availabilty"
              // defaultValue={jobAvailabilty === '0' ? true : false}
              label="Let recruiters know your status"
              value={jobAvailabilty}
            />
          </div>
        </div>
        <hr />
        <div className="job-preference--inner-section mb-3">
          <div>
            <h5>Job alert</h5>
            <p>You’ll receive notifications when new jobs are posted that match your search preferences.</p>
          </div>
          <div className="toggleSection">
            <ToggleSwitch
              onChange={(e) => setJobAlert(e.target.value)}
              name="job_alert"
              // defaultValue={userPreferences?.job_alert}
              label="Let recruiters know your status"
              value={jobAlert}
            />
          </div>
        </div>
        <div className="job-preference--inner-section mb-3">
          <div className="w-50">
            <h5>Job Title</h5>
            <Input
              placeholder="Add Job Title"
              onKeyDown={(e) => {
                handleTitleTags(e);
              }}
              onChange={(e) => setUserTitle(e.target.value)}
              name="job_title"
              value={userTitle}
            />
            <div className="d-flex gap-2">
              {userTitles &&
                userTitles?.map((item, i) => <Pills key={i} label={item} onClose={() => handleRemoveItem(item)} />)}
            </div>
          </div>
        </div>
        <div className="job-preference--inner-section mb-3">
          <div className="w-100">
            <h5>Employment Type</h5>
            <div className="d-flex justify-content-start">
              {employmentTypeArr?.map((employmentTYpe, i) => (
                <Checkbox
                  label={employmentTYpe.label}
                  name="employment_type"
                  value={employmentTYpe.value}
                  onChange={(e) => addDeleteEmployment(e)}
                  // checked={employmentTypeArray.includes(employmentTYpe.value) ? true : false}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="job-preference--inner-section mb-3">
          <div className="w-50">
            <h5>Industry</h5>
            <Select
              name="industries"
              options={globalIndustries.map((e) => ({ label: e, value: e }))}
              classNamePrefix="custom-select"
              isSearchable={true}
              onChange={(value) => {
                setIndustriesArr((prev) => [...prev, value]);
              }}
              // value={globalIndustries.filter(function (option) {
              //   return option.value === userPreferences;
              // })}
            />
            <div className="d-flex gap-2 mt-3">
              {industriesArr && industriesArr?.map((item, i) => <Pills key={i} label={item.label} />)}
            </div>
          </div>
        </div>
        <div className="job-preference--inner-section mb-3">
          <div className='w-100'>
          <h5>Location</h5>
          <div className="row">
            <div className="col-md-4">
              <Select
                name="country"
                options={globalCountries?.map((e) => ({ label: e.name, value: e.id, ...e }))}
                classNamePrefix="custom-select"
                isSearchable={true}
                isLoading={loading}
                onChange={(e) => {
                  handleCountrySelect(e);
                }}
                onInputChange={(e) => countrySearch(e)}
              />
              {/* <AsyncSelect cacheOptions loadOptions={globalCountries.map((e) => ({ label: e.name, value: e.id, ...e }))} defaultOptions /> */}
            </div>
            <div className="col-md-4">
              <Select
                name="location"
                options={globalStates.map((e) => ({ label: e.name, value: e.id, ...e }))}
                classNamePrefix="custom-select"
                isSearchable={true}
                isLoading={loading}
                onChange={(e) => {
                  handleStateSelect(e);
                }}
                onInputChange={(e) => stateSearch(e)}
              />
            </div>
            <div className="col-md-4">
              <Select
                name="location"
                options={globalCities.map((e) => ({ label: e.city_ascii, value: e.id, ...e }))}
                classNamePrefix="custom-select"
                isSearchable={true}
                isLoading={loading}
                onChange={(value) => {
                  setSelectedCity(value);
                }}
                onInputChange={(e) => citySearch(e)}
              />
            </div>
          </div>
          </div>
        </div>
        <div className="job-preference--inner-section mb-3">
          <div className='w-100'>
          <div className='mb-3'>
            <h5>Resume</h5>
            <p>Only pdf, doc, docx and ppt files accepted.</p>
            <input class="form-control" type="file" onChange={(e) => setResumeFile(e)} id="resumeFile" accept=".pdf,.doc,.docx,.ppt" />
          </div>
          <div>
            <p><b>Addition File</b></p>
            <p>Only pdf, doc, docx and ppt files accepted.</p>
            <input class="form-control" type="file" onChange={(e) => setAdditionalFile(e)} id="additionalFile" accept=".pdf,.doc,.docx,.ppt" />
          </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button onClick={() => submitPreferences()}>Save</Button>
        </div>
      </div>

      {/* <JobListingHeading /> */}
      {savedJobList?.length > 0 && (
        <React.Fragment>
          <div className="post_job_hding mt-md-4">Saved Jobs</div>
          <div className="mt-md-3">
            <div className="row">
              {savedJobList?.map((item, index) => (
                <div className="col-md-4 col-sm-6" key={index}>
                  <JobCardIndividual
                    data={item.job_details}
                    index={index}
                    actions={true}
                    candidateView={true}
                    status={item.status}
                    saved={item.saved_at}
                  />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}

      {/* <div className="d-flex justify-content-center align-items-center _viewAll p-4 mt-md-3 mb-md-0">
        <div className="col-6 d-grid">
          <button className="btn btn-light">View All</button>
        </div>
      </div> */}

      {applyedJobsList?.length > 0 && (
        <React.Fragment>
          <div className="post_job_hding mt-md-4">Applied Jobs</div>
          <div className="mt-md-3">
            <div className="row">
              {applyedJobsList?.map((item, index) => (
                <div className="col-md-4 col-sm-6" key={index}>
                  <JobCardIndividual
                    key={index}
                    data={item.job_details}
                    index={index}
                    actions={true}
                    candidateView={true}
                    status={item.status}
                  />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}

      {/* <div className="d-flex justify-content-center align-items-center _viewAll p-4 mt-md-3 mb-md-0">
        <div className="col-6 d-grid">
          <button className="btn btn-light">View All</button>
        </div>
      </div> */}
      {(pendingReview?.length > 0 || inReview?.length > 0 || declined?.length > 0) && (
        <React.Fragment>
          <div className="post_job_hding mt-md-4">Track Your applications</div>

          <HorizontalTabs>
            <TabWithoutLink
              onClick={() => setSubOpenTab('Pending Review')}
              activeClass={openSubTab === 'Pending Review' ? 'active' : null}
            >
              Pending Review ({pendingReview.length})
            </TabWithoutLink>
            <TabWithoutLink
              onClick={() => setSubOpenTab('In Review')}
              activeClass={openSubTab === 'In Review' ? 'active' : null}
            >
              In Review ({inReview.length})
            </TabWithoutLink>
            <TabWithoutLink
              onClick={() => setSubOpenTab('Declined')}
              activeClass={openSubTab === 'Declined' ? 'active' : null}
            >
              Declined({declined.length})
            </TabWithoutLink>
          </HorizontalTabs>
          <TabContent tab="Pending Review" openTab={openSubTab}>
            <div className="row">
              {pendingReview.length > 0 ? (
                pendingReview.map((item, index) => (
                  <div className="col-md-4 col-sm-6" key={index}>
                    <JobCardIndividual
                      key={index}
                      data={item.job_details}
                      index={index}
                      actions={false}
                      candidateView={true}
                      status={item.status}
                    />
                  </div>
                ))
              ) : (
                <div className="job-blank-container">
                  <img src={noJobsfound} alt="" width="182" className="mb-3" />
                </div>
              )}
            </div>
          </TabContent>
          <TabContent tab="In Review" openTab={openSubTab}>
            <div className="row">
              {inReview.length > 0 ? (
                inReview.map((item, index) => (
                  <div className="col-md-4 col-sm-6" key={index}>
                    <JobCardIndividual
                      key={index}
                      data={item.job_details}
                      index={index}
                      actions={false}
                      candidateView={true}
                      status={item.status}
                    />
                  </div>
                ))
              ) : (
                <div className="job-blank-container">
                  <img src={noJobsfound} alt="" width="182" className="mb-3" />
                </div>
              )}
            </div>
          </TabContent>
          <TabContent tab="Declined" openTab={openSubTab}>
            <div className="row">
              {declined.length > 0 ? (
                declined.map((item, index) => (
                  <div className="col-md-4 col-sm-6" key={index}>
                    <JobCardIndividual
                      key={index}
                      data={item.job_details}
                      index={index}
                      actions={false}
                      candidateView={true}
                      status={item.status}
                    />
                  </div>
                ))
              ) : (
                <div className="job-blank-container">
                  <img src={noJobsfound} alt="" width="182" className="mb-3" />
                </div>
              )}
            </div>
          </TabContent>
        </React.Fragment>
      )}
    </>
  );
};

export default JobListMyJobs;
