import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

const initialState = {
  loading: false,
  flag: false,
  meetingCreate: {},
  googleMeetingDetails: {},
  googleMeetingCreate: {},
  outlookMeetingCreate: {},
  eventDetails: undefined,
  googleEventDetails: undefined,
  outlookEventDetails: undefined,
  meetingDeleted: undefined,
  googleMeetingDeleted: undefined,
  outlookMeetingDeleted: undefined,
  userNavStatus: 'online',
  joinedUsers: {},
  joinedMeetingData: {},
  isJoinAMeeting:false,
  defaultMeetingOpen:false,
  showChatBoxes:true,
  showMeetBoxes:true,
  generatedMeetingId:null,
  waitingRoomDetails:null
};

export const newMeeting = createAsyncThunk('newMeeting', async (body) => {
  const obj = {
    url: APIEndPoints.newMeeting,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const newGoogleMeeting = createAsyncThunk('newGoogleMeeting', async (body) => {
  const obj = {
    url: APIEndPoints.GOOGLE_EVENT,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const newOutlookMeeting = createAsyncThunk('newOutlookMeeting', async (body) => {
  const obj = {
    url: APIEndPoints.OUTLOOK_EVENT,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateMeeting = createAsyncThunk('updateMeeting', async (body) => {
  const obj = {
    url: `${APIEndPoints.newMeeting}/${body.id}/update`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const updateGoogleMeeting = createAsyncThunk('updateGoogleMeeting', async (body) => {
  const obj = {
    url: `${APIEndPoints.GOOGLE_EVENT}bridge${body.id}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const updateOutlookMeeting = createAsyncThunk('updateOutlookMeeting', async (body) => {
  const obj = {
    url: `${APIEndPoints.OUTLOOK_EVENT}/${body.id}/`,
    method: 'PATCH',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateAttendeeStatus = createAsyncThunk('updateAttendeeStatus', async (payload) => {
  const obj = {
    url: `${APIEndPoints.newMeeting}/${payload.meetingId}/attendee/${payload.id}/update`,
    method: 'PUT',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const acceptProposedNewTime = createAsyncThunk('acceptProposedNewTime', async (payload) => {
  const obj = {
    url: `${APIEndPoints.newMeeting}/${payload.meetingId}/proposal/${payload.attendeeId}/accept`,
    method: 'PUT',
    // body: JSON.stringify(payload.proposeNewTime),
  };
  return await API(obj);
});

export const getEventDetail = createAsyncThunk('getEventDetail', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.newMeeting}/${meetingId}`,
  };
  return await API(obj);
});
export const getGoogleEventDetail = createAsyncThunk('getGoogleEventDetail', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.GOOGLE_EVENT}/${meetingId}`,
  };
  return await API(obj);
});
export const getOutlookEventDetail = createAsyncThunk('getOutlookEventDetail', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.OUTLOOK_EVENT}/${meetingId}`,
  };
  return await API(obj);
});

export const proposeNewTime = createAsyncThunk('propseNewTime', async ({ meetingId, payload }) => {
  const obj = {
    url: `${APIEndPoints.proposeNewTime(meetingId)}`,
    method: 'PUT',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const deleteMeeting = createAsyncThunk('deleteMeeting', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.newMeeting}/${meetingId}`,
    method: 'DELETE',
  };
  return await API(obj);
});
export const deleteGoogleMeeting = createAsyncThunk('deleteGoogleMeeting', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.GOOGLE_EVENT}${meetingId}/`,
    method: 'DELETE',
  };
  return await API(obj);
});
export const deleteOutlookMeeting = createAsyncThunk('deleteOutlookMeeting', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.OUTLOOK_EVENT}/${meetingId}`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const generateMeetingId = createAsyncThunk('generateMeetingId', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.meetingIDAPI}/generate_meeting_id`,
    method: 'GET',
  };
  return await API(obj);
});
export const updateMeetingSecurity = createAsyncThunk('updateMeetingSecurity', async (payload) => {
  const obj = {
    url: `${APIEndPoints.meetingIDAPI}/dynamic_meeting`,
    method: 'PUT',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});
export const checkMeetingSecurity = createAsyncThunk('checkMeetingSecurity', async (meetingId) => {
  const obj = {
    url: `${APIEndPoints.meetingIDAPI}/dynamic_meeting?meeting_id=${meetingId}`,
    method: 'GET',
  };
  return await API(obj);
});
export const deleteMeetingID = createAsyncThunk('deleteMeetingID', async (payload) => {
  const obj = {
    url: `${APIEndPoints.meetingIDAPI}/dynamic_meeting`,
    method: 'DELETE',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const getGoogleMeetingDetails = createAction('getGoogleMeetingDetails');
export const updateUserNavStatus = createAction('updateUserNavStatus');

export const joinedUsersMeeting = createAction('joinedUsersMeeting');
export const joinMeeting = createAction('joinMeeting');
export const joinAMeeting = createAction('joinAMeeting');
export const clearJoinedMeetingData = createAction('clearJoinedMeetingData');
export const startDefaultMeeting = createAction('startDefaultMeeting');
export const setShowChat = createAction('setShowChat');
export const clearShowChat = createAction('clearShowChat');
export const setShowMeet = createAction('setShowMeet');
export const clearShowMeet = createAction('clearShowMeet');

const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {},
  extraReducers: {
    [newMeeting.pending]: (state, action) => {
      state.flag = true;
      state.loading = true;
    },
    [newMeeting.fulfilled]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.meetingCreate = action.payload;
    },
    [newMeeting.rejected]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [newGoogleMeeting.pending]: (state, action) => {
      state.flag = true;
      state.loading = true;
    },
    [newGoogleMeeting.fulfilled]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.googleMeetingCreate = action.payload;
    },
    [newGoogleMeeting.rejected]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [newOutlookMeeting.pending]: (state, action) => {
      state.flag = true;
      state.loading = true;
    },
    [newOutlookMeeting.fulfilled]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.outlookMeetingCreate = action.payload;
    },
    [newOutlookMeeting.rejected]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateAttendeeStatus.pending]: (state, action) => {
      state.flag = true;
      state.loading = true;
    },
    [updateAttendeeStatus.fulfilled]: (state, action) => {
      state.flag = false;
      state.loading = false;
    },
    [updateAttendeeStatus.rejected]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [acceptProposedNewTime.pending]: (state, action) => {
      state.flag = true;
      state.loading = true;
    },
    [acceptProposedNewTime.fulfilled]: (state, action) => {
      state.flag = false;
      state.loading = false;
    },
    [acceptProposedNewTime.rejected]: (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getEventDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getEventDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventDetails = action.payload;
    },
    [getEventDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getGoogleEventDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getGoogleEventDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.googleEventDetails = action.payload;
    },
    [getGoogleEventDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [getOutlookEventDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getOutlookEventDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.outlookEventDetails = action.payload;
    },
    [getOutlookEventDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    },
    [deleteMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteGoogleMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteGoogleMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    },
    [deleteGoogleMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteOutlookMeeting.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOutlookMeeting.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    },
    [deleteOutlookMeeting.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [proposeNewTime.pending]: (state, action) => {
      state.loading = true;
    },
    [proposeNewTime.fulfilled]: (state, action) => {
      state.loading = false;
      state.meetingDeleted = false;
    },
    [proposeNewTime.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [generateMeetingId.pending]: (state, action) => {
      state.loading = true;
    },
    [generateMeetingId.fulfilled]: (state, action) => {
      state.loading = false;
      state.generatedMeetingId = action.payload.meeting_id;
    },
    [generateMeetingId.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateMeetingSecurity.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMeetingSecurity.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateMeetingSecurity.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [checkMeetingSecurity.pending]: (state, action) => {
      state.loading = true;
    },
    [checkMeetingSecurity.fulfilled]: (state, action) => {
      state.loading = false;
      state.waitingRoomDetails = action.payload.meeting_detail;
    },
    [checkMeetingSecurity.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [deleteMeetingID.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMeetingID.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteMeetingID.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },
    [updateUserNavStatus]: (state, action) => {
      state.userNavStatus = action.payload;
    },
    [joinedUsersMeeting]: (state, action) => {
      state.joinedUsers = action.payload;
    },
    [joinAMeeting]:(state, action)=> {
      state.isJoinAMeeting = action.payload;
    },
    [joinMeeting]: (state, action) => {
      state.joinedMeetingData = action.payload;
    },
    [clearJoinedMeetingData]: (state, action) => {
      state.joinedMeetingData = {};
    },
    [setShowChat]: (state, action) => {
      state.showChatBoxes = action.payload;
    },
    [clearShowChat]: (state, action) => {
      state.showChatBoxes = true;
    },
    [setShowMeet]: (state, action) => {
      state.showMeetBoxes = action.payload;
    },
    [clearShowMeet]: (state, action) => {
      state.showMeetBoxes = true;
    },
    [startDefaultMeeting]: (state, action) => {
      state.defaultMeetingOpen = action.payload;
    },
    [getGoogleMeetingDetails]: (state, action) => {
      state.loading = false;
      state.googleMeetingDetails = action.payload;
    },
  },
});

export const { getConnections, rejectConnection, acceptConnection } = meetingSlice.actions;

export default meetingSlice.reducer;
