import React from 'react';

import { ChatIcon, DisagreeIcon, HandShakeSimpleIcon } from 'components/common/Icons';
import { Flex, Text } from 'components/core';
import OverlayList from '../../../OverlayList';

const InsightPalate = ({ comment }) => {
  const { like_count, liked_by = [], dislike_count, dislike_by = [], reply_count = 0, comment_replies = [] } = comment;

  const likeDislikeCount = like_count + dislike_count;

  if (likeDislikeCount + reply_count === 0) return null;
  const show = likeDislikeCount > 0 || reply_count > 0
  return (
    <Flex justify="space-between" align="center" padding={show ? "0.5rem" : ""}>

      <Flex align="center" flex={""} width="">
        {likeDislikeCount > 0 && (
          <Flex align="center">
            {liked_by?.length ? <OverlayList type="name" data={[...(liked_by ?? [])].reduceRight?.((arr, cv) => arr?.concat(arr?.find(pv => pv?.id === cv?.id) ? [] : [cv]), [])}>
              <div>
                <HandShakeSimpleIcon className="me-1 md-md-2" width="12" height="18" />
              </div>
            </OverlayList> : ""}
            {dislike_by?.length ?
              <OverlayList type="name" data={[...(dislike_by ?? [])].reduceRight?.((arr, cv) => arr?.concat(arr?.find(pv => pv?.id === cv?.id) ? [] : [cv]), [])}>
                <div>
                  <DisagreeIcon className="me-2 md-md-2" width="12" height="18" color="#E41C19" />
                </div>
              </OverlayList> : ""}
            <Text.Label size="15" style={{ marginRight: 10 }} whiteSpace="nowrap">
              {likeDislikeCount}
            </Text.Label>
          </Flex>
        )}
      </Flex>
      <Flex flex={1} />
      <Flex align="center" width="auto" flex={"0 0 auto"}>
        {reply_count !== 0 && (
          <OverlayList data={comment_replies?.reduceRight?.((arr, cv) => arr?.concat(arr?.find(pv => pv?.owner?.id === cv?.owner?.id) ? [] : [cv]), [])?.map?.((e) => e.owner)} type="name">
            <Flex>
              <ChatIcon height={"18"} />
              <Text.Label size="15px">
                {`${reply_count} Comments`}
              </Text.Label>
            </Flex>
          </OverlayList>
        )}
      </Flex>
    </Flex >
  );
};

export default InsightPalate;
