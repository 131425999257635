import Button from 'components/common/Button'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { activateYourAccount } from 'store/slices/authSlice';
import { MyBLogoHeader } from 'components/common/Icons';
import { getOnboardingStatus } from 'store/slices/onboardingSlice';
import { checkUserOnboardingStatus } from 'pages/Onboarding-ui/constant';


function ActivateAccount() {
    const { uid, token } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { msg, error, isActivated } = useSelector(state => state.user);
    const { userProfileInfo } = useSelector((state) => state.userProfile);
    const { onboardingStatus } = useSelector((state) => state.onboarding);
    useEffect(() => {
        if (uid && token) {
            const payload = {
                step: 'profile',
                emailId: userProfileInfo?.email
            };
            const body = { uid: uid, token: token };
            dispatch(activateYourAccount(body));
            dispatch(getOnboardingStatus(payload));
        } else {
            // navigate('/');
        }
    }, []);

    useEffect(() => {
        if (uid && token && userProfileInfo) {
            const payload = {
                step: 'profile',
                emailId: userProfileInfo?.email
            };
            dispatch(getOnboardingStatus(payload));
        }
    }, [userProfileInfo]);

    useEffect(() => {
        if (isActivated) {
            let timer = setTimeout(() => {
                const checkStatus = checkUserOnboardingStatus(onboardingStatus);
                navigate(checkStatus.url);
            }, 5000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [isActivated]);

    return (
        <div className='fullpage-container'>
            <div className='thankyoupage'>
                <MyBLogoHeader width={95} height={95} />
                <p className="py-5 mb-0">
                    Achieve more than you imagined possible The future has finally arrived…
                </p>
                {error &&
                    <p className="fs-5 text-danger mb-4">
                        Something went wrong / already activated
                    </p>
                }
                {isActivated &&
                    <>
                        <p className="fs-5 text-success mb-4">
                            Account is activated successfully
                        </p>
                    </>
                }
                {(error || isActivated) &&
                    <Link to='/signin'>
                        <Button
                            className="btn btn-primary Signin__Button fw-bold m-0"
                            variant="primary"
                        >
                            Go to sign in
                        </Button>
                    </Link>
                }
            </div>


        </div>
    )
}

export default ActivateAccount