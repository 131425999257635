import { useEffect, useMemo, useState } from "react"
import styles from "./index.module.scss"
import { Link } from "react-router-dom"
export const SeeMore = ({ maxLength = 200, text, ...props }) => {
    const [text_, setText] = useState()
    const [show, setShow] = useState(false)
    useEffect(() => {
        if (show) {
            setText(text);
            return;
        }
        if (maxLength && text?.length > maxLength) {
            setText(text?.substr(0, maxLength ?? text?.length - 1))
        }else {
            setText(text);
        }
    }, [text, maxLength, show])
    return (<>
        <span className={styles["see-more"]} {...props}>{text_}</span>
        {!show && text?.length > maxLength ?
            <Link onClick={() => setShow(ps => !ps)}>
                ... see more
            </Link>
            : ""}
    </>
    )
}