import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { PROFILE_OPTIONS } from 'components/Layouts/constant';
import { useSelector, useDispatch } from 'react-redux';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { useNavigate } from 'react-router-dom';
import { getAllCompanyPages } from 'store/actions/companyPages.Action';

const ProfileDropDown = ({ onProfileHandle }) => {
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const { companyPageList } = useSelector((state) => state.companyPageReducer);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllCompanyPages());
  }, []);
  const ref = React.useRef();
  useOnClickOutside(ref, () => setShow(false));

  const handleShow = () => {
    setShow((prev) => !prev);
  };
  const handleNav = () => {
    navigate('/settings/pages');
    setShow((prev) => !prev);
  };
  const slug = userProfileInfo?.slug;

  const USER_NAME = userProfileInfo?.first_name + ' ' + userProfileInfo?.last_name;

  return (
    <div className="navbar-dropdown" ref={ref}>
      <div className="dropdown-wrap" onClick={handleShow}>
        <MyBridgeAvatar icon={userProfileInfo?.profile_pic} name={USER_NAME} />
      </div>

      {show && (
        <div className="navbar-dropdown--menu profile-dropdown">
          <div className="menu-header d-flex align-items-start">
            <div className="icon">
              <MyBridgeAvatar icon={userProfileInfo?.profile_pic} name={USER_NAME} />
            </div>

            <div className="content">
              <h4>{USER_NAME}</h4>
              <p>{userProfileInfo.position}</p>
              <p>
                <Link to="/profile/my-profile">View Profile</Link>
              </p>
            </div>
          </div>

          <div className="menu-body">
            <p className="mb-3 cursor-pointer" onClick={handleNav}>
              Pages
              <span>{companyPageList.length}</span>
            </p>
            <ul>
              {PROFILE_OPTIONS.map((option) => {
                return (
                  <li key={option.name}>
                    <button onClick={() => onProfileHandle(option.name, option.url)}>
                      <span>{option.icon}</span>
                      {option.name}

                      {option.name !== 'Logout' && <ChevronRight className="ms-auto" />}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="menu-footer">
            <p>Privacy - Term - Advertising - &copy; 2023</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropDown;
