import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Layout } from 'components/Layouts';
import { TabContent } from 'components/common/Tabs/Tabs';
import { getMyActivity, getUserAnalytics, getUserProfileInfo } from 'store/slices/userProfileSlice';

import Banner from './Banner';
import Profile from './Individual/ProfileSection';
import Network from './Individual/NetworkSection';
import Calendar from './Individual/CalendarSection';
// import MyDisk from './Individual/MyDiskSection';
import MyDiskMain from 'components/MyDisk';

import {
  getCertificate,
  getEducation,
  getExperience,
  getMilestone,
  getProfileSkills,
  getProfileViewers,
} from 'store/slices/userProfileSlice';
import {
  listOfAskedTestimonialRequest,
  listOfGiveTestimonial,
  listOfGiveTestimonialPendingRequest,
  listOfReceivedTestimonial,
  listOfReceivedTestimonialPending,
} from 'store/actions/testimonial.Action';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openTab, setOpenTab] = useState(id || 'my-profile');

  useEffect(() => {
    dispatch(getExperience());
    dispatch(getMilestone());
    dispatch(getEducation());
    dispatch(getCertificate());
    dispatch(listOfReceivedTestimonial());
    dispatch(listOfReceivedTestimonialPending());
    dispatch(listOfAskedTestimonialRequest());
    dispatch(listOfGiveTestimonialPendingRequest());
    dispatch(listOfGiveTestimonial());
    dispatch(getProfileSkills());
    dispatch(getUserAnalytics())
    dispatch(getProfileViewers());
    dispatch(getMyActivity());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleScrollTop = () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };

    dispatch(getUserProfileInfo());
    handleScrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <section className="container bordered-container profile__section mt-4">
        <Banner openTab={openTab} setOpenTab={setOpenTab} profileType={'individual'} />
        <section className="Profile_Tab">
          <div className="container p-0">
            <div>
              <div>
                <TabContent tab="my-profile" openTab={openTab}>
                  <Profile />
                </TabContent>
                <TabContent tab="network" openTab={openTab}>
                  <Network />
                </TabContent>
                <TabContent
                  tab={
                    openTab === 'calendar'
                      ? 'calendar'
                      : openTab === 'schedule'
                      ? 'schedule'
                      : openTab === 'viewMeeting'
                      ? 'viewMeeting'
                      : ''
                  }
                  openTab={openTab}
                >
                  <Calendar />
                </TabContent>
                <TabContent tab="mydisk" openTab={openTab}>
                  <MyDiskMain diskType="personalDisk" />
                </TabContent>
              </div>
            </div>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
};

export default ProfilePage;
