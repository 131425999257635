import React from "react";
import { useNavigate } from "react-router-dom";
import { onboardingSteps } from "../constant";

const OnboardingStepper = ({ activeSteps }) => {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-md-10'>
        <ul className='onboarding_stepper'>
          {onboardingSteps?.map(step => (
            // onClick={() => handleNavigate(step.url)}
            <li className={activeSteps.includes(step.id) ? 'active' : ''} key={step.id} />
          ))}
        </ul>
      </div>
    </div>
  )
};

export default OnboardingStepper;