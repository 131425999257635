import React from 'react';
import { SMSIcon } from 'components/common/Icons';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import { APIEndPoints } from 'store/common/endPoint';
import { useMutation } from '@tanstack/react-query';
import API from 'store/common/api';

const SMSToggle = ({ companyPage, module, settings = [], onChange }) => {
  const number = Math.floor(100 + Math.random() * 900);
  const isTrue = settings.find((item) => item._for === module && item.enabled && (companyPage ? item.company_page === companyPage : true)) ? true : false;

  const { mutateAsync } = useMutation({
    mutationFn: async (body) => {
      return await API({ method: 'POST', url: `${APIEndPoints.Notification}/sms/`, body: JSON.stringify(body) });
    },
  });

  const onChange_ = async () => {
    const current = !isTrue;
    await mutateAsync({ _for: module, enabled: current, ...(companyPage ? { company_page: companyPage } : {})  });
    onChange?.()
  };
  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div>
        <SMSIcon className="me-3" />
        SMS
      </div>
      <ToggleSwitch
        name={module + number}
        label="commentsSMS"
        value={isTrue}
        defaultValue={isTrue}
        onChange={onChange_}
      />
    </div>
  );
};

export default SMSToggle;
