import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button';

import { requestConnection } from 'store/slices/connectionSlice';
import { TickMarkIcon } from 'components/common/Icons';
import { TextArea } from 'components/common/Input';

const HandshakeModal = ({ user, onClose }) => {
  const dispatch = useDispatch();

  const userName = `${user.first_name} ${user.last_name}`;

  const [directRequest, setDirectRequest] = useState(true);
  const [customMessage, setCustomMessage] = useState('');

  const handleHandshakeReq = () => {
    dispatch(requestConnection({ to_user_id: user?.id, invite_message: customMessage }));
  };

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<h3>{directRequest ? 'You can customize this invitation' : 'Invite ' + userName + ' to connect'}</h3>}
      action={
        <>
          {directRequest ? (
            <Button
              className="h-36"
              variant="btn-secondary"
              onClick={() => {
                setDirectRequest(false);
              }}
            >
              Add a note
            </Button>
          ) : (
            <Button className="h-36" variant="btn-secondary" onClick={onClose}>
              Cancel
            </Button>
          )}

          <Button className="h-36" onClick={() => handleHandshakeReq(user?.id)}>
            Send
          </Button>
        </>
      }
    >
      {directRequest ? (
        <p>
          <TickMarkIcon /> You can add a note to personalize your invitation to {userName}
        </p>
      ) : (
        <div>
          <p className="mb-2">Build a quality network by connecting only with people you know.</p>
          <div className="clearfix">
            <TextArea
              label="Message (optional)"
              placeholder="Ex We know each other from..."
              rows="3"
              onChange={(e) => {
                setCustomMessage(e.target.value);
              }}
              className={customMessage.length >= 300 && 'is-invalid'}
              maxLength={300}
              error={customMessage.length >= 300 && 'Character Limit is reached'}
            />
            <span className="form-text float-end text-end mt-0">{customMessage.length}/300</span>
          </div>
          {/* <div className="mt-4">
            <h6 className="mb-1">PREMIUM</h6>
            <p className="mb-1">
              Don’t know Kamal kant? Send an InMail with Premium to introduce yourself. More people reply to an InMail
              than a connection request.
            </p>
            <p>
              <Link>Retry Premium Free</Link>
            </p>
          </div> */}
        </div>
      )}
    </Modal>
  );
};

export default HandshakeModal;
