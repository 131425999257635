import { Bin, BinOpen, Copy, Create, Download, Move, Rename, Share, Upload } from 'components/common/Icons';
import { useDownloadFile } from "hooks/useDownloadFile";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { hasAccessLevel, isChildFileOrDir, isRootDir } from './common/utils';
import { DropdownMenu } from 'components/common/DropdownMenu';


const FileMenu = props => {
    const isMobile = useSelector(state => state.layoutReducer.isMobile)
    const { openFolderItem, selectedFile } = useSelector(state => state.myDiskReducer)
    const {
        onDownload,
    } = useDownloadFile()
    const { item, triggerRef, checkForAccessLevel = false } = props
    useEffect(() => {
        if (typeof window !== "undefined") {
            setTimeout(handleMenuPosition, 0)
            window.addEventListener("scroll", handleMenuPosition)
            return () => window.removeEventListener("scroll", handleMenuPosition)
        }
    }, [])

    const handleMenuPosition = () => {
        if (typeof window !== "undefined") {
            const ele = document.querySelector(".mobile-more-menu")
            if (ele) {
                const { bottom, top } = ele.getBoundingClientRect()
                if (bottom > window.innerHeight) {
                    ele.style.bottom = "1.5rem"
                    ele.style.top = "auto"
                }
                console.log(bottom, top, window.innerHeight)
            }
        }
    }

    // const dispatch = useDispatch()
    const handleDownload = (e) => {
        props.onCloseMenu?.()
        onDownload?.()
    }

    const setPopupTypeNCloseDropdown = (type) => {
        props.handlePopups(type)
        props.onCloseMenu()
    }

    const hasFullAccess = (item) => {
        return checkForAccessLevel ? hasAccessLevel(item, 1) : true
    }

    return (
        <>
            <DropdownMenu
                onClose={props?.onCloseMenu}
                targetRef={triggerRef}
            >
                {isChildFileOrDir(item) ? <>
                    {hasFullAccess(item) ? <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("Move")}><Move /> Move</div> : ""}
                    <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("Copy")}><Copy /> Copy</div>
                    {item?.type === "F" ? <>
                        <div className="ddm-item" onClick={handleDownload}><Download /> Download</div>
                    </>
                        : ""
                    }
                    {hasFullAccess(item) ? <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("Bin")}><Bin /> Delete</div> : ""}
                    {hasFullAccess(item) ? <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("Rename")}><Rename /> Rename</div> : ""}
                </>
                    : ""}
                {hasFullAccess(item) ? <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("Share")}><Share /> Share</div> : ""}
                {hasFullAccess(item) ? <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("Create")}><Create /> Create Folder</div> : ""}
                {hasFullAccess(item) ? <div className="ddm-item" onClick={() => setPopupTypeNCloseDropdown("BinOpen")}><BinOpen /> Show Delete Files</div> : ""}
                {hasFullAccess(item) ? <div className="ddm-item" onClick={() => props.onUploadFilee("Upload")}><Upload /> Upload Files</div> : ""}
            </DropdownMenu>
        </>
    )
}

export default FileMenu;