import { ThreeDot, VerticalThreeDot } from "components/common/Icons"
import { trimStr } from "components/utils"
import { forwardRef, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedFile } from "store/actions/myDisk.Action"
import FileMenu from "../fileMenu"
import FolderMenu from "../folderMenu"
import { getIconByExtension } from "./iconsMap"
import { formatDate, getFileFolderNameByType, getGridIcon, getListIcon, humanFileSize, isChildFileOrDir, isRootDir } from "./utils"
import { useOnClickOutside } from "hooks/useOnClickOutside"
import { Spinner } from "components/common/Loader"

export const DiskFilesListing = forwardRef(({
    fileFolderList,
    isMobile,
    selectedFile,
    handleItemClick,
    handlePopups,
    activeSubTab,
    onUploadFilee,
    checkForAccessLevel,
    view = "list",
    diskType = "myDisk"
}, ref) => {

    const [activeMenu, setActiveMenu] = useState()
    const { loading } = useSelector((state) => state.myDiskReducer);
    const menuTriggerRef = useRef()

    const dispatch = useDispatch()

    const onCloseMenu = () => {
        setActiveMenu(null);
    };
    const onOpenMenu = (index, item) => {
        dispatch(setSelectedFile(item));
    };

    const handleOutsideClick = () => {
        dispatch(setSelectedFile(null))
    }

    if (loading) return (
        <div className="d-flex justify-content-center align-items-center pt-4 pb-4">
            <Spinner />
        </div>
    )

    const ListViewItem = ({ item, index }) => {
        const ref = useRef();

        return isMobile ? (
            <>
                <div
                    key={index}
                    className={
                        selectedFile && selectedFile.id === item.id ? 'file-wrapper active' : 'file-wrapper'
                    }
                >
                    <div className="file">
                        <span className="g1" onClick={() => handleItemClick(item)}>
                            {isChildFileOrDir(item) ? getIconByExtension(item, { width: 20 }) : getListIcon(item)}
                            <div style={{ flex: 1 }} className='ps-1' title={item?.name}>
                                {trimStr(getFileFolderNameByType(item), { length: 20 })}
                            </div>
                        </span>
                        <span></span>
                        {
                            <span ref={ref} onClick={e => {
                                e.stopPropagation();
                                onOpenMenu(index, item)
                            }}>
                                <ThreeDot />
                            </span>
                        }
                    </div>
                    <FolderMenu item={item} handlePopups={handlePopups} activeSubTab={activeSubTab} onUploadFilee={onUploadFilee} onCloseMenu={onCloseMenu} />
                </div>
            </>
        ) : (
            <>
                <div
                    key={index}
                    className={
                        selectedFile && selectedFile.id === item.id ? 'file-wrapper active' : 'file-wrapper'
                    }
                >
                    <div className="file" onClick={() => handleItemClick(item)}>
                        <span className="g1 d-flex align-items-center">
                            <div className='px-8'>
                                {isChildFileOrDir(item) ? getIconByExtension(item, { width: 20 }) : getListIcon(item)}
                            </div>
                            <div style={{ flex: 1 }} className='ps-1' title={item?.name}>
                                {trimStr(getFileFolderNameByType(item), { length: 20 })}
                            </div>
                        </span>
                        {!isMobile ? (
                            <>
                                <span className="g2" title={formatDate(item?.updated_at)}> {trimStr(formatDate(item?.updated_at), { length: 13 })}</span>
                                {diskType === "myDisk" ? <span className="g3">{item?.size > 0 ? humanFileSize?.(item?.size ?? 0) : "-"}</span> : ""}
                                {diskType === "sharedDisk" ? <span className="g3">{item?.shared_with?.length > 0 ? item?.shared_with?.length : "0"}</span> : ""}
                                {
                                    <span className="g4" ref={ref} onClick={e => {
                                        e.stopPropagation();
                                        onOpenMenu(index, item)
                                    }}>
                                        <ThreeDot />
                                    </span>
                                }
                            </>
                        ) : (
                            <>
                                <span></span>
                                {
                                    <span ref={ref} onClick={e => {
                                        e.stopPropagation();
                                        onOpenMenu(index, item)
                                    }}>
                                        <ThreeDot />
                                    </span>
                                }
                            </>
                        )}
                    </div>
                    <FileMenu checkForAccessLevel={checkForAccessLevel} item={item} triggerRef={ref} handlePopups={handlePopups} activeSubTab={activeSubTab} onUploadFilee={onUploadFilee} onCloseMenu={onCloseMenu} />
                </div>
            </>
        )
    }

    return fileFolderList && fileFolderList.length ? (
        view === "list" ? (
            fileFolderList.map((item, index) =>
                <ListViewItem item={item} key={index} index={index} />
            )
        ) : <>
            <div className="grid-item-wrapper">
                {
                    fileFolderList?.map((item, index) => (
                        <div
                            className="grid-view-file"
                            style={item?.id === selectedFile?.id ? { backgroundColor: "#F1F1F1" } : {}}
                            onClick={() => handleItemClick(item)}
                        >
                            {isRootDir(item) ? getGridIcon(item) : getIconByExtension(item, { width: 44, height: 36 })}
                            {isMobile ? (
                                <div className="grid-view-name">
                                    <div>
                                        <span className="g1">{trimStr(item?.name, { length: 10 })}</span>
                                        <span className="g3"></span>
                                    </div>
                                    {
                                        <div onClick={activeMenu === index ? onCloseMenu : (e) => {
                                            menuTriggerRef.current = e.currentTarget;
                                            onOpenMenu(index, item)
                                        }}>
                                            <VerticalThreeDot />
                                        </div>
                                    }
                                    {isMobile && activeMenu === index && (
                                        <FileMenu checkForAccessLevel={checkForAccessLevel} item={item} triggerRef={menuTriggerRef} handlePopups={handlePopups} activeSubTab={activeSubTab} onUploadFilee={onUploadFilee} onCloseMenu={onCloseMenu} />
                                    )}
                                </div>
                            ) : (
                                <>
                                    <span className="g1">{trimStr(getFileFolderNameByType(item), { length: 10 })}</span>
                                </>
                            )}
                            <div className='grid-view-submenu' ref={menuTriggerRef} onClick={activeMenu === index ? onCloseMenu : (e) => {
                                // menuTriggerRef.current = e.currentTarget;
                                // e.stopPropagation(); onOpenMenu(index, item)
                            }}>
                                <VerticalThreeDot />
                            </div>
                            {/* {activeMenu === index && ( */}
                            <FileMenu checkForAccessLevel={checkForAccessLevel} item={item} triggerRef={menuTriggerRef} handlePopups={handlePopups} activeSubTab={activeSubTab} onUploadFilee={onUploadFilee} onCloseMenu={onCloseMenu} />
                            {/* )} */}
                        </div>
                    ))}
            </div>
        </>
    ) : (
        <></>
    )
})