import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEETING_LOCATION } from 'data/data';
import Card from 'components/common/Card';
import { isAfterPresentTime, meetingsByDate } from 'components/utils';
import Loader from 'components/common/Loader';
import { useNavigate } from 'react-router-dom';
import { getEventDetail, getGoogleMeetingDetails } from 'store/slices/meetingSlice';

const UpcomingMeetings = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loading, allCalendarMeeting, allGoogleMeetings, allOutlookMeetings } = useSelector((state) => state.calendar);
  const [sortedMeetings, setSortedMeetings] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEventClick = (event) => {
    if (event?.location_url) {
      const gmeetDetails = {
        id: event?.id,
        title: event?.subject,
        extendedProps: {
          host: event?.host,
          attendees: event?.attendees,
          description: event?.descripion,
          location: null,
          _instance: {
            range: {
              start: event?.start_time?._d,
              end: event?.end_time?._d,
            },
          },
        },
      };
      dispatch(getGoogleMeetingDetails(gmeetDetails));
      navigate('/profile/viewGoogleMeeting');
      return;
    }
    dispatch(getEventDetail(event?.id)).then((res) => {
      navigate('/profile/viewMeeting');
    });
  };

  const MeetingDetailing = (day, events) => {
    return (
      <>
        <div className="meetings__header">
          <p className="meetings__title">{day}</p>
        </div>
        {!events?.length ? (
          <p className="py-2 px-3 text-center">No Upcoming Events.</p>
        ) : (
          events?.map((event, i) =>
            event ? (
              <>
                <div className="meetings__details" key={i}>
                  <div className="meetings__time">{moment(event.start_time).format('hh:mm A')}</div>
                  <div className="meetings__links">
                    <span onClick={() => handleEventClick(event)} className="meetings__links--join cursor-pointer">
                      {event.subject}
                    </span>
                    <span className="meetings__links--visit">
                      {event?.location === 'builtin' ? (
                        <>{MEETING_LOCATION.find((item) => item.value === event?.location)?.label}</>
                      ) : (
                        <>{event?.location_url}</>
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            ),
          )
        )}
      </>
    );
  };

  useEffect(() => {
    setSortedMeetings([]);
    let myBridgeMeetings, googleMeetings, outlookMeetings;
    if (!!allCalendarMeeting.results) {
      myBridgeMeetings = allCalendarMeeting?.results?.filter((event) => {
        const declinedAttendee = event?.attendees?.find(
          (m) => m?.user === userProfileInfo?.id && m?.status === 'DECLINED',
        );
        if (declinedAttendee?.status !== 'DECLINED' && isAfterPresentTime(declinedAttendee?.start_time)) {
          if (isAfterPresentTime(event?.start_time)) return event;
        }
      });
    }
    if (!!allGoogleMeetings.items) {
      googleMeetings = allGoogleMeetings?.items
        ?.filter((event) => {
          if (event?.start?.dateTime && isAfterPresentTime(event?.start?.dateTime) && event?.summary) return event;
        })
        ?.map((item) => {
          const meetExist = myBridgeMeetings?.filter((meeting) =>meeting?.id == item?.id?.slice(6));
          if((meetExist?.length===0)){
            return {
              attendees: item?.attendees,
              host: item?.organizer?.email,
              description: item?.description,
              start_time: moment(item?.start?.dateTime),
              end_time: moment(item?.end?.dateTime),
              id: item?.id,
              subject: item?.summary,
              location: item?.location ?? 'google',
              location_url: item?.hangoutLink ?? item?.location,
              eventUrl: item?.htmlLink,
            };
        }
        });
    }
    if (!!allOutlookMeetings.value) {
      outlookMeetings = allOutlookMeetings?.value
        ?.filter((event) => {
          if (event?.start?.dateTime && isAfterPresentTime(event?.start?.dateTime) && event?.summary) return event;
        })
        ?.map((item) => {
          if((!myBridgeMeetings?.includes(item?.id?.slice(6)))){
            return {
              attendees: item?.attendees?.map((attendee) => attendee?.emailAddress?.address),
              host: item?.organizer?.emailAddress?.address,
              description: item?.bodyPreview,
              start_time: moment(item?.start?.dateTime),
              end_time: moment(item?.end?.dateTime),
              id: item?.id,
              subject: item?.subject,
              location: item?.location?.displayName ?? 'outlook',
              location_url: item?.onlineMeeting?.joinUrl ?? item?.location?.locationType,
            };
          }
        });
    }

    const sortedMeetings = meetingsByDate(
      [myBridgeMeetings, googleMeetings, outlookMeetings]
        ?.flat()
        ?.filter((a) => a !== undefined)
        ?.sort((a, b) => {
          return new Date(a.start_time) - new Date(b.start_time);
        })
        ?.slice(0, 3),
    );
    setSortedMeetings(sortedMeetings);
  }, [allGoogleMeetings, allCalendarMeeting, userProfileInfo?.id]);

  return loading ? (
    <Loader secondary />
  ) : Object.keys(sortedMeetings).length ? (
    <Card className={`my-4 px-3 py-2 position-relative`}>
      <a href="/calendarlisting" className="meetings__all m-3 top-0 end-0 position-absolute fs-6">
        View All
      </a>
      <div className="meetings w-100">
        {sortedMeetings['Today']?.length ? (
          <div className="upcoming-meeting-card">{MeetingDetailing('Today', sortedMeetings['Today'])}</div>
        ) : (
          ''
        )}
        {sortedMeetings['Tomorrow']?.length ? (
          <div className="upcoming-meeting-card">{MeetingDetailing('Tomorrow', sortedMeetings['Tomorrow'])}</div>
        ) : (
          ''
        )}
        {Object.keys(sortedMeetings)?.map(
          (meeting) =>
            meeting &&
            meeting !== 'Today' &&
            meeting !== 'Tomorrow' && (
              <div className="upcoming-meeting-card">{MeetingDetailing(meeting, sortedMeetings[meeting])}</div>
            ),
        )}
      </div>
    </Card>
  ) : (
    <Card className={`my-4 px-3 py-2`}>
      <p className="py-2 px-3 text-center">No Upcoming Events.</p>
    </Card>
  );
};

export default UpcomingMeetings;
