import React, { useState, useEffect } from 'react';
import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { SimpleCloseIcon } from 'components/common/Icons';
import { searchItem } from 'store/slices/searchSlice';
import { useDispatch, useSelector } from 'react-redux';
import SectionLoader from 'components/common/Loader/sectionLoader';
import { InfiniteList } from 'components/InfiniteList';
import { Input } from 'components/common/Input';

const AddMembersModal = (props) => {
    const { onClose, selectedUsers } = props;
    const dispatch = useDispatch();
    const { item, flag } = useSelector((state) => state.search);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    useEffect(() => {
        dispatch(searchItem({ query: `&custom_param_name=mycircle`, item: 'people' }));
    }, [])

    const handleClose = () => {
        onClose(true);
    }

    const searchUsers = () => { 
        dispatch(searchItem({searchQuery:selectedOption, query: `&custom_param_name=mycircle`, item: 'people' }));
    }

    const handleClosePill = (user) => { 
        setUserOptions(userOptions.filter((item) => item.id !== user))
    }

    const handleSelectUserToAdd = (user) => { 
        if (!userOptions.includes(user)) { 
            setUserOptions(prev => [...prev, user])
        }
    }

    const sendSelectedData = () => { 
        selectedUsers(userOptions);
    }

    return (
        <Modal onClose={handleClose}
            heading={<h3 className="deactivation-header">
                Add People
            </h3>}
            action={
                <>
                    <Button className="btn btn-secondary btn-sm" onClick={handleClose}>Cancel</Button>
                    <Button className="btn btn-primary btn-sm" onClick={() => sendSelectedData()}>Save</Button>
                </>
            }
        >
            <div className="chatNameModal">
                {!flag ? <SectionLoader /> : <React.Fragment>
                    <div className="input-container">
                        <Input onChange={(e) => setSelectedOption(e.target.value)} />
                        <Button className="btn btn-primary px-4" onClick={searchUsers}>Done</Button>
                    </div>
                    {userOptions.length > 0 && <h4 className="sub-title mt-2">Added</h4>}
                    <div className="user-pills-container">
                        {userOptions?.map((user, i) => (
                            <div className="user-pills">
                                <div className="user-pills--avatar">
                                    <MyBridgeAvatar size={24} name={`${user.first_name} ${user.last_name}`} icon={user.profile_pic} />
                                </div>
                                <div className="user-pills--name">{`${user.first_name} ${user.last_name}`}</div>
                                <div className="user-pills--closeIcon"><SimpleCloseIcon onClick={() => handleClosePill(user.id)} width={10} height={10} /></div>
                            </div>
                        ))}
                    </div>
                    <div className="suggested-container">
                        <h4 className="sub-title">Suggestions</h4>
                        <div className="suggested-list-container">
                            {item?.count > 0 ? item.results.map((user, i) => (
                                <div className="user-list" key={i} onClick={() => handleSelectUserToAdd(user)}>
                                    <div className="user-list--avatar">
                                        <MyBridgeAvatar size={40} name={`${user.first_name} ${user.last_name}`} icon={user.profile_pic} />
                                    </div>
                                    <div className="user-list--name">{`${user.first_name} ${user.last_name}`}</div>
                                </div>
                            )) : 'No Results'}

                        </div>
                    </div>

                </React.Fragment>}
                

            </div>
        </Modal>
    )
}

export default AddMembersModal