import { createReducer } from '@reduxjs/toolkit';
import { getActiveJobs, applyJob, getSearchJobs, saveJobs, clear, getSavedJobs, getJobsDetail, getApplyedJobs, editJobDetail, getUserJobPreferences, setUserJobPreferences, setRepostToEditPost} from '../actions/Job.Action';

const initialState = {
  loading: false,
  activeJobList: [],
  savedJobList: [],
  searchJobList: [],
  applyedJobsList:[],
  jobApplySuccess:undefined,
  saveJobSuccess:undefined,
  jobDetail:{},
  editDetails: undefined,
  jobPreferences: {},
  jobPreferenceUpdated: false,
  isRepost:false,
  jobClosed:false
};

const jobsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getActiveJobs.fulfilled, (state, action) => {
      state.activeJobList = action.payload.results;
    })
    .addCase(getSavedJobs.fulfilled, (state, action) => {
      state.savedJobList = action.payload.results;
    })
    .addCase(getSearchJobs.fulfilled, (state, action) => {
        state.searchJobList = action.payload.results;
      })
    .addCase(applyJob.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(applyJob.fulfilled, (state, action) => {
      if (action.payload.message) {
        state.jobApplySuccess = action.payload;
      } else { 
        state.jobApplySuccess = action.payload;
      }
      
    })
    .addCase(saveJobs.fulfilled, (state, action) => {
      state.saveJobSuccess = action.payload;
    })
    .addCase(getJobsDetail.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getJobsDetail.fulfilled, (state, action) => {
      state.jobDetail = action.payload;
      state.loading = false;
    })
    .addCase(editJobDetail.fulfilled, (state, action) => {
      state.editDetails = action.payload;
    })
    .addCase(getApplyedJobs.fulfilled, (state, action) => {
      state.applyedJobsList = action.payload.results;
    })
    .addCase(getUserJobPreferences.fulfilled, (state, action) => {
      state.jobPreferences = action.payload;
    })
    .addCase(setUserJobPreferences.fulfilled, (state, action) => {
      state.jobPreferences = action.payload;
      state.jobPreferenceUpdated = true;
    })
    .addCase(clear, (state, action) => {
      state.saveJobSuccess = initialState.createdPage;
      state.jobPostedSuccess = initialState.serviceAdded;
      state.searchJobList = initialState.searchJobList;
      state.editDetails = initialState.editDetails;
    })
    .addCase(setRepostToEditPost, (state, action) => { 
      state.isRepost = true;
    })
    .addDefaultCase((state, action) => {});
});

export default jobsReducer;
