import React, { useEffect, useState } from 'react';
import { addMilestone, getMilestone, updateMilestone, deleteMilestone } from 'store/slices/userProfileSlice';
import Button from 'components/common/Button';
import { Input, TextArea } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import { rangeOfFullYearsWithFuture } from 'components/utils';
import { genralMonths } from 'data/data';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle } from 'components/common/Icons';
import { BorderLessButton, VerticalLine } from 'components/StyledComponents';
import styled from 'styled-components';

const MilestoneHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const MilestoneContainer = styled.div``;

const MilestoneDetailsContainer = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
`;

const initialState = {
  month: '',
  year: '',
  title: '',
  milestone: '',
};

const Milestones = (props) => {
  const dispatch = useDispatch();
  const { milestones, isNewMilestone } = useSelector((state) => state.userProfile);
  const years = rangeOfFullYearsWithFuture(new Date('Jun 01 2000'), new Date('Jun 01 2030'));
  const [fields, setFields] = useState(initialState);
  const [milestoneId, setMilestoneId] = useState(null);
  const [errorFields, setErrorFields] = useState({});
  const [editMode, setEditMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  // useEffect(() => {
  //     dispatch(getMilestone())
  // }, [])

  useEffect(() => {
    if (isNewMilestone) {
      setMilestoneId(null);
      dispatch(getMilestone());
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMilestone]);

  const editMilestone = (id) => {
    setMilestoneId(id);
    const currentForm = milestones.results.find((exp) => id === exp.id);
    setFields(currentForm);
    setEditMode(true);
  };

  const handleDeleteMilestone = (id) => {
    dispatch(deleteMilestone(id));
  };

  const onCancelMilestone = () => {
    setFields(initialState);
    setMilestoneId(null);
    setEditMode(false);
  };

  const saveMilestones = (id) => {
    const errorObject = {};
    if (!fields.title) {
      errorObject.title = 'Please enter a title';
    }
    if (!fields.month || !fields.year) {
      errorObject.year = 'Please select a month and year';
    }
    if (!fields.milestone) {
      errorObject.milestone = 'Please enter a milestone';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }

    const payload = {
      title: fields.title,
      month: fields.month,
      year: fields.year,
      milestone: fields.milestone,
    };
    if (id) {
      dispatch(updateMilestone({ ...payload, id }));
    } else {
      dispatch(addMilestone(payload));
    }
  };

  const addNewMilestone = () => {
    setFields(initialState);
    setMilestoneId(null);
    setEditMode(true);
  };

  if (!editMode) {
    return (
      <>
        <MilestoneHeader>
          <AddCircle />
          <BorderLessButton onClick={addNewMilestone}>Add Milestones</BorderLessButton>
        </MilestoneHeader>
        <MilestoneContainer>
          {milestones &&
            milestones?.results?.map((milestone, id) => (
              <MilestoneDetailsContainer className="mb-4" key={id}>
                <div className="d-block d-lg-flex align-items-center justify-content-between gap-2">
                  <p className="fw-semibold">{milestone.title}</p>

                  <div className="d-flex py-2 py-lg-0">
                    <BorderLessButton onClick={() => editMilestone(milestone.id)}>Edit</BorderLessButton>
                    <VerticalLine />
                    <BorderLessButton onClick={() => handleDeleteMilestone(milestone.id)}>Delete</BorderLessButton>
                  </div>
                </div>
                <div>
                  <p className="fs-15">
                    {genralMonths[milestone.month - 1]?.label}
                    &nbsp;
                    {milestone.year}
                  </p>
                  <p className="pt-2">{milestone.milestone}</p>
                </div>
              </MilestoneDetailsContainer>
            ))}
        </MilestoneContainer>
      </>
    );
  }

  return (
    <>
      <p className="helper-text">Duration</p>
      <div className="d-inline-flex align-items-center gap-3">
        <Dropdown
          id={'month'}
          name="month"
          options={genralMonths}
          title={'month'}
          handleChange={handleChange}
          selectedValue={fields.month}
          defaultValue={'Month'}
        />
        <Dropdown
          id={'year'}
          name={'year'}
          options={years}
          title={'year'}
          handleChange={handleChange}
          selectedValue={fields.year}
          defaultValue={'Year'}
        />
      </div>
      {errorFields.year && <div className="text-danger">{errorFields.year}</div>}

      <p className="helper-text">Title</p>
      <Input type="text" placeholder="Title" value={fields.title} name="title" onChange={fieldsOnChange} />
      {errorFields.title && <div className="text-danger">{errorFields.title}</div>}
      <p className="helper-text">Milestone</p>
      <TextArea rows={6} placeholder="Milestone" value={fields.milestone} name="milestone" onChange={fieldsOnChange} />
      {errorFields.milestone && <div className="text-danger">{errorFields.milestone}</div>}

      <div className="d-flex gap-2 justify-content-end mt-4">
        <Button className="h-36" variant="btn-secondary" onClick={onCancelMilestone}>
          Cancel
        </Button>
        <Button className="h-36" variant="btn-primary" onClick={() => saveMilestones(milestoneId)}>
          {' '}
          {milestoneId ? 'Update' : 'Save'}
        </Button>
      </div>
    </>
  );
};

export default Milestones;
