import Card from 'components/common/Card';
import React, { useState, useEffect } from 'react';
import { TabContent, Tab, VerticalTabs } from 'components/common/Tabs/Tabs';
import { useParams } from 'react-router-dom';
import MembersNote from './MembersNote';
import {
  TeamMembersIcon,
  TeamOrganizerIcon,
  PendingPostsIcon,
  TeamRequestsIcon,
  TeamInvitesIcon,
  TeamBlockedIcon,
} from 'components/common/Icons';
import MembersTab from './MembersTab';
import { Link } from 'react-router-dom';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import OrganisersTab from './OrganisersTab';
import PendingPosts from './PendingPosts';
import PendingRequest from './PendingRequest';
import InvitedMembers from './InvitedMembers';
import BlockedMembers from './BlockedMembers';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamMemberList, setEditModalOpen, updateTeamDetails } from 'store/slices/teamCommunitySlice';
import ReadMore from 'components/common/ReadMore';
import moment from 'moment';
import RadioButton from 'components/common/RadioButton';
const ManageTeam = (props) => {
  const { teamDetails, isOrganizer } = props;
  const { teamMembers, teamId, usersUpdated, resquestedMembers, teamSlugPage } = useSelector(
    (state) => state.teamsCommunity,
  );
  let { tid } = useParams();
  if (!tid) {
    tid = 'members';
  }
  const slug = `teams/profile/${props.slug}/manage_team`;
  const [, setOpenTab] = useState(tid || 'members');
  const dispatch = useDispatch();

  const updateTeamPostDetails = (e, type) => {
    let body = {};
    if (type === 'permission_new_post') {
      body = {
        id: teamId,
        permission_new_post: e.target.checked,
      };
      dispatch(updateTeamDetails(body));
    } else if (type === 'permission_invite_members') {
      body = {
        id: teamId,
        permission_invite_members: e.target.checked,
      };
      dispatch(updateTeamDetails(body));
    } else if (type === 'discoverability') {
      body = {
        id: teamId,
        discoverability: e.target.value === "Listed",
      };
      dispatch(updateTeamDetails(body));
      
    }
  };

  const onEditLinkClick = () => dispatch(setEditModalOpen(true))

  return (
    <section className="profile-section">
      {isOrganizer ? (
        <div className="row gap-4 gap-md-0">
          <div className="col-12 col-md-4 col-xl-3">
            <Card className={'p-3 mb-4'}>
              <h5>Settings</h5>
              <VerticalTabs className="teamsTabs">
                <Tab setOpenTab={setOpenTab} openTab={tid} path={slug} to="members">
                  <TeamMembersIcon
                    color={tid === "members" ? "white" : null}
                    fill={tid === "members" ? "#3D5A80" : null}
                  /> Members
                </Tab>

                <Tab setOpenTab={setOpenTab} openTab={tid} path={slug} to="organizer">
                  <TeamOrganizerIcon
                    color={tid === "organizer" ? "white" : null}
                    fill={tid === "organizer" ? "#3D5A80" : null}
                  /> Organizer
                </Tab>

                <Tab setOpenTab={setOpenTab} openTab={tid} path={slug} to="pendingPosts">
                  <PendingPostsIcon
                    color={tid === "pendingPosts" ? "white" : null}
                    fill={tid === "pendingPosts" ? "#3D5A80" : null}
                  /> Pending posts
                </Tab>

                <Tab setOpenTab={setOpenTab} openTab={tid} path={slug} to="requests">
                  <TeamRequestsIcon
                    color={tid === "requests" ? "white" : null}
                    fill={tid === "requests" ? "#3D5A80" : null}
                  /> Requests
                </Tab>

                <Tab setOpenTab={setOpenTab} openTab={tid} path={slug} to="invited">
                  <TeamInvitesIcon
                    color={tid === "invited" ? "white" : null}
                    fill={tid === "invited" ? "#3D5A80" : null}
                  /> Invited
                </Tab>

                <Tab setOpenTab={setOpenTab} openTab={tid} path={slug} to="blocked">
                  <TeamBlockedIcon
                    color={tid === "blocked" ? "white" : null}
                    fill={tid === "blocked" ? "#3D5A80" : null}
                  /> Blocked
                </Tab>
              </VerticalTabs>
            </Card>

            <Card className={'p-3'}>
              <ul className="sidebarList">
                <li>
                  <label>Pending posts</label> <Link>0</Link>
                </li>
                <li>
                  <label>Requests to join</label>{' '}
                  <Link>{resquestedMembers.length > 0 ? resquestedMembers.length : '0'}</Link>
                </li>
              </ul>
              <hr className="my-1" />
              <ul className="sidebarList pt-2 sec-options">
                <li>
                  <div>
                    <p>
                      Allow members to post chats <br />
                      <span>If disabled, only admins will be able to post chats</span>
                    </p>
                  </div>
                  <div>
                    <ToggleSwitch
                      onChange={(e) => updateTeamPostDetails(e, 'permission_new_post')}
                      name="permission_new_post"
                      defaultValue={teamDetails.permission_new_post}
                      label="permission_new_post"
                    />
                  </div>
                </li>
                <li>
                  <div>
                    <p>
                      Allow members to Invite <br />
                      <span>If disabled, only admins will be able to invite members</span>
                    </p>
                  </div>
                  <div>
                    <ToggleSwitch
                      onChange={(e) => updateTeamPostDetails(e, 'permission_invite_members')}
                      name="permission_invite_members"
                      defaultValue={teamDetails.permission_invite_members}
                      label="permission_invite_members"
                    />
                  </div>
                </li>
              </ul>

              <hr className="my-1" />
              <div className="cardBottomLink">
                <Link onClick={onEditLinkClick}>Edit Team</Link>
              </div>

              <h6 className='pt-4'>Team Discoverablity</h6>
              <ul className="sidebarList align-items-center">
                <li >
                  <div>
                    <RadioButton
                      name="discoverability"
                      value="Listed"
                      labelClass="label-text"
                      changed={e => updateTeamPostDetails(e, 'discoverability')}
                      isSelected={teamSlugPage?.discoverability === true}
                      containerClass={'me-2'}
                    />
                  </div>
                  <div>
                    <p >
                      <strong>Listed</strong>
                      <br />
                      <span>
                        Team appears in search results and is visible to others on members’ profiles.
                      </span>
                    </p>
                  </div>
                </li>
                <li className="">
                  <div>
                    <RadioButton
                      name="discoverability"
                      value="Unlisted"
                      containerClass={'me-2'}
                      labelClass="label-text"
                      changed={e => updateTeamPostDetails(e, 'discoverability')}
                      isSelected={teamSlugPage?.discoverability === false}
                    />
                  </div>
                  <div>
                    <p >
                      <strong>Unlisted</strong>
                      <br />
                      <span>
                        Team does not appear in search results for non-Team members and is not visible to others on members' profiles.
                      </span>
                    </p>
                  </div>

                </li>
              </ul>
              {/* <hr className="my-1" /> */}

            </Card>
          </div>
          <div className="col-12 col-md-8 col-xl-9">
            <Card className={'p-4 mb-4'}>
              <MembersNote />
            </Card>
            <Card className={'p-4'}>
              <TabContent tab="members" openTab={tid}>
                <MembersTab isOrganizer={isOrganizer} />
              </TabContent>
              <TabContent tab="organizer" openTab={tid}>
                <OrganisersTab isOrganizer={isOrganizer} />
              </TabContent>
              <TabContent tab="pendingPosts" openTab={tid}>
                <PendingPosts isOrganizer={isOrganizer} />
              </TabContent>
              <TabContent tab="requests" openTab={tid}>
                <PendingRequest isOrganizer={isOrganizer} />
              </TabContent>
              <TabContent tab="invited" openTab={tid}>
                <InvitedMembers isOrganizer={isOrganizer} />
              </TabContent>
              <TabContent tab="blocked" openTab={tid}>
                <BlockedMembers isOrganizer={isOrganizer} />
              </TabContent>
            </Card>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-md-4 col-xl-3">
            <Card className="about-card">
              <div className="card-header">About this team</div>
              <div className="card-body">
                <div className="sub-head">Description</div>
                <div className="sub-body">
                  <ReadMore wordcount={100}>{teamSlugPage?.desc}</ReadMore>
                  {/* <span className='cursor-pointer color-primary'>see more</span> */}
                </div>
                <hr />
                <div className="sub-head">Details</div>
                <div className="sub-body mb-3">
                  Discoverability
                  <br />
                  {teamSlugPage?.discoverability === true ? 'Listed Team' : 'Unlisted Team'}
                </div>
                <div className="sub-body">
                  Created
                  <br />
                  {moment(teamSlugPage?.created_at).format('MMMM YYYY')}
                </div>
                <hr />
                <div className="sub-head">Rules</div>
                <div className="sub-body mb-3">
                  <ReadMore wordcount={100}>{teamSlugPage?.rules}</ReadMore>
                  {/* <span className='cursor-pointer color-primary'>see more</span> */}
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 col-md-4 col-xl-9">
            <Card className="p-2 mb-4">
              <PendingPosts isOrganizer={isOrganizer} />
            </Card>
            <Card className="p-2">
              <MembersTab isOrganizer={isOrganizer} />
            </Card>
          </div>
        </div>
      )}
    </section>
  );
};

export default ManageTeam;
