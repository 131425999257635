import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Flex, Text } from 'components/core';
import PostContext from 'context/PostContext';
import PostTiming from './PostTiming';
import _ from 'lodash';
import { PinIcon } from 'components/common/Icons';

const PostHeaderLeft = () => {
  const { postData } = useContext(PostContext);
  const navigate = useNavigate();

  const { author, start_time, type, company, pinned } = postData;
  const username = company?.name !== '' ? company?.name : `${author?.first_name} ${author?.last_name}`;
  const navigateTo = company?.slug !== '' ? `/company/${company?.slug}/overview` : `/p/${author.slug}`;
  const position = company?.main_industry !== '' ? company?.main_industry : author?.position;
  const location = company?.country !== '' ? company?.country : author?.location;
  return (
    <Flex direction="column" margin="0px 8px" className="justify-content-center">
      {type === 2 && <Text.Label>Event - {moment(new Date(start_time)).format('MMM DD, hh:mm a')}</Text.Label>}
      <Flex alignItems="center">
        <Text.Title
          level={6}
          secondary
          margin="4px 0px 0px 0px"
          className="cursor-pointer"
          onClick={() => navigate(navigateTo)}
        >
          {_.startCase(username)}
        </Text.Title>
        {pinned ? <> <Text.Tag> <PinIcon width={12} height={12} />  Pinned</Text.Tag></> : ""}
      </Flex>
      <Text.Label margin="2px 0px 0px 0px">
        {position} {position && location ? "|" : ""} {location}
      </Text.Label>
      <PostTiming />
    </Flex>
  );
};

export default PostHeaderLeft;
