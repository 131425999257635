import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMeetings, getEventsFromGoogle, getEventsFromOutlook } from 'store/slices/calendarSlice';
import { ScheduleIcon, JoinMeetingIcon, EventIcon } from 'components/common/Icons';
import { getConnectionsData } from 'store/slices/connectionSlice';
import Loader from 'components/common/Loader';
import CalendarComp from './CalendarComp';
import MeetingSchedule from './MeetingSchedule';
import EventView from './eventView';
import Card from 'components/common/Card';
import UpcomingMeetings from './UpcomingMeetings';
import { joinMeeting, joinAMeeting } from 'store/slices/meetingSlice';
import GoogleMeetingView from './googleMeetingView';

export default function CalendarSection() {
  const { id } = useParams();
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(getAllMeetings());
    if (userProfileInfo?.google_calendar_email) dispatch(getEventsFromGoogle());
    if (userProfileInfo?.outlook_calendar_email) dispatch(getEventsFromOutlook());
    dispatch(getConnectionsData());
  }, [userProfileInfo]);

  const { loading: loadingCalendar } = useSelector((state) => state.calendar);

  const gotoUrl = (page) => {
    navigate(`/profile/${page}`);
  };

  const handleJoinMeeting = (meetingNumber) => {
    const meetingCode = parseInt(Math.random().toFixed(6).replace('0.', ''));
    const meetingId = meetingNumber ? meetingNumber : parseInt(`6002${meetingCode}`);
    const meeting = {
      created_by: userProfileInfo,
      meetingId: meetingId,
      attendees: null,
      isWaiting: false,
    };
    if (meeting) dispatch(joinMeeting(meeting));
  };

  const renderPage = (id) => {
    if (loadingCalendar === true) {
      return <Loader secondary />;
    } else {
      switch (id) {
        case 'schedule':
          return <MeetingSchedule />;
        case 'viewMeeting':
          return <EventView />;
        case 'viewGoogleMeeting':
          return <GoogleMeetingView />;
        default:
          return <CalendarComp />;
      }
    }
  };

  return (
    <section className="Profile_Tab">
      <div className="row">
        <div className="col-xl-9 border-right">
          <div className={'pt-3 mb-4 calendar-section position-relative'}>
            {renderPage(id)}
            {/* {id === 'schedule' ? (
              <MeetingSchedule />
            ) : id === 'viewMeeting' ? (
              <EventView />
            ) : id === 'viewGoogleMeeting' ? (
              <GoogleMeetingView />
            ) : loadingCalendar === true ? (
              <Loader secondary />
            ) : (
              <CalendarComp />
            )} */}
          </div>
        </div>
        <div className="col-xl-3">
          <Card className="px-3 py-2 mb-4 cursor-pointer" onClick={() => handleJoinMeeting()}>
            <EventIcon className="me-3" />
            Meet Now
          </Card>
          <Card className="px-3 py-2 mb-4 cursor-pointer" onClick={() => dispatch(joinAMeeting(true))}>
            <JoinMeetingIcon className="me-3" />
            Join a Meeting
          </Card>
          <Card className="px-3 py-2 mb-4 cursor-pointer" onClick={() => gotoUrl('schedule')}>
            <ScheduleIcon className="me-3" width="27" height="27" />
            Schedule a Meeting
          </Card>
          <UpcomingMeetings isDashboard={false} />
        </div>
      </div>
    </section>
  );
}
