import React, { useState } from 'react';

import close from 'assets/icons/close.svg';

import Modal from 'components/common/Modal/Modal';
import { Text } from 'components/StyledComponents';
import { Input } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import { MyBridgeAvatar } from 'components/common/Avatar';

const ManagePageModal = (props) => {
  const { company, name, onClose } = props;

  const [data, setData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const onSubmit = () => {
    console.log(data);
  };

  return (
    <Modal
      onClose={onClose}
      heading={
        <Text fW="700" fS="24px">
          Manage Page
        </Text>
      }
      className={'confirmation-modal'}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <div>
            <MyBridgeAvatar icon={company.image} name={name} size={60} round={false} />
          </div>

          <div className="d-flex flex-column gap-1">
            <Text fW="700">{company.name}</Text>
            <Text>{company.company_size} employees</Text>
            <Text>
              {company.main_industry} | {company.state_region}, {company.country}
            </Text>
          </div>
        </div>

        <div className="d-flex flex-column gap-1 mt-2">
          <Text fW="500" fS="16px">
            Add Admin
          </Text>
          <div className="d-flex gap-2">
            <Input
              containerClass="d-flex"
              className="rounded"
              type="text"
              placeholder="--"
              name="why"
              value={data.why}
              onChange={handleChange}
            />
            <Button>Add</Button>
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          {Array(2)
            .fill({})
            .map((employee) => {
              const { first_name, last_name, profile_pic, position } = employee;
              let userName = `${first_name} ${last_name}`;
              return (
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2">
                    <MyBridgeAvatar icon={profile_pic} name={'Kamal Kant'} size={40} />
                    <div className="d-flex flex-column gap-1">
                      <Text fS="15px" fW="500">
                        Kamal Kant
                      </Text>
                      <Text fS="12px" fW="500" color="#747474">
                        Quality Assurance
                      </Text>
                      <Text fS="12px" fW="500" color="#747474">
                        Company Name
                      </Text>
                    </div>
                  </div>
                  <div>
                    <img src={close} alt="" />
                  </div>
                </div>
              );
            })}
        </div>

        <div className="d-flex mt-2 justify-content-end">
          <Text
            fS="18px"
            fW="500"
            color="#3D5A80"
            className="cursor-pointer"
            onClick={(event) => onClose(event, 'triggerDeactivate')}
          >
            I’d like to deactivate my account
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default ManagePageModal;
