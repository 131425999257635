import styled from 'styled-components';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PostContext from 'context/PostContext';
import InsightPalate from './InsightPalate';
import FooterActions from './FooterActions';
import CommentBox from '../Comment/CommentBox';
import Comments from '../Comment';
import { getUserFullName } from 'components/MyDisk/common/utils';
import { useSelector } from 'react-redux';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin-top: 0px;
`;

const PostFooter = () => {
  const [showComments, setShowComments] = useState(true);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const { postData, modal, commentAllowed } = useContext(PostContext);
  const { author, id, comment_count } = postData;
  const inpRef = useRef()
  const { userProfileInfo } = useSelector(state => state.userProfile)

  const USER_NAME = author?.first_name + ' ' + author?.last_name;

  const handleShowComments = (val) => {
    setShowComments(val)
  }

  const handleShowCommentBox = (val) => {
    setShowCommentBox(val)
    setTimeout(() => {
      if (inpRef?.current) {
        inpRef?.current?.focus?.()
      }
    }, 10)

  }



  return (
    <>
      <Container className="post-footer">
        <InsightPalate setShowComments={handleShowComments} />
        <FooterActions setShowCommentBox={handleShowCommentBox} setShowComments={handleShowComments} />
        {commentAllowed && (modal || (showCommentBox && comment_count <= 0)) ? <CommentBox  setShowCommentBox={setShowCommentBox} ref={inpRef} name={getUserFullName(userProfileInfo)} id={id} author={author} /> : ""}
      </Container>
      {showComments && modal && <Comments author={author} id={id} modal={modal} />}
      {/* {<Comments author={author} id={id} modal={modal} />} */}
    </>
  );
};

export default PostFooter;
