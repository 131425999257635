/** @format */

import axios from 'axios';
// const token = localStorage.getItem('access');
const { REACT_APP_API_URL } = process.env;

// const headers = {
//   'Content-Type': 'application/json',
//   Authorization: `Bearer ${token}`
// };

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response || 'Something went wrong');
  },
);

export default axiosInstance;
