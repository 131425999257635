import React, { useState } from 'react'
//import { useHistory } from 'react-router'
import { CometChat } from '@cometchat-pro/chat'

const defaultState = {
  user: null,
  IsLoggedIn: false,
  loginCometchat: async (username) => { },
  logoutCometChat: () => { },
}
export const AuthContext = React.createContext(defaultState)
const AUTH_KEY = '1e5e95ba7b679efc9be5a1fe1d162b8786ba5074';
export const AuthProvider = ({ children }) => {
  
  const [cometChatUser, setCometChatUser] = useState(null);
  const [IsLoggedIn, setIsLoggedIn] = useState(false);

  const loginCometchat = async (user_uuid, uName, userSlug, userImage) => {
    if (user_uuid) {

      // console.log('current user UID signed in MyBridge - ', user_uuid)

      // CometChat.login(user_uuid, AUTH_KEY).then(
      //   loggedInUser => {
      //     console.log('Already existing user login - success', loggedInUser.uid)
      //     setCometChatUser(loggedInUser.uid)
      //     setIsLoggedIn(true)
      //   }, err => {
          // console.log('Not an existing user - error, forwarding to user creation part', err);

          CometChat.login(user_uuid, AUTH_KEY).then(
            loggedInUser => {
              // console.log('Already existing user login - success', loggedInUser.uid)
              setCometChatUser(loggedInUser.uid)
              setIsLoggedIn(true)
            }, err => {
              // console.log('Not an existing user - error, forwarding to user creation part', err);
              let image = userImage?.split('?');
              let avatar = image[0];
              const newUser = new CometChat.User(user_uuid);
              
              newUser.setName(uName);
              newUser.setLink(`https://bybk.org/${userSlug}`);
              newUser.setAvatar(avatar);             
              

              CometChat.createUser(newUser, AUTH_KEY).then(
                user => {

                  // console.log('created new user - success', user)
                  setCometChatUser(user.uid)
                  setIsLoggedIn(true)

                }, error => {

                  console.error('create new user - error', error)

                }
              )
            }
          )
      //   }
      // )
    }
  }

  const logoutCometChat = async () => {
    cometChatUser.setStatus('offline');
    cometChatUser.setMetadata({ 'currentStatus': 'offline' });
    CometChat.updateCurrentUserDetails(cometChatUser).then(
      user => {
        console.log('user status', user);
      }, error => {
        console.log("error", error);
      }
    )
    CometChat.logout(cometChatUser).then(logoutUser => {
      setIsLoggedIn(false)
      console.log('logout user response - ', logoutUser)
    });
  }

  return (
    <AuthContext.Provider
      value={{ cometChatUser, loginCometchat, logoutCometChat, IsLoggedIn }}
    >
      {children}
    </AuthContext.Provider>
  )
}