import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from '../common/endPoint';
import axiosInstance from 'store/common/axios';
import { jsonToFormData } from 'utils';
const token = localStorage.getItem('access');

const initialState = {
  msg: '',
  loading: false,
  error: false,
  teamCommunityList: {},
  createdTeam: {},
  isTeamCreated: false,
  isTeamUpdated: false,
  isTeamLeft: false,
  isTeamReported: false,
  pages: null,
  groupId: null,
  teamId: null,
  teamPage: {},
  teamSlugPage: {},
  teamMembers: {},
  teamOrganisers: {},
  usersInvited: false,
  isPostCreated: false,
  teamPosts: {},
  isPostAccepted: false,
  pending: false,
  usersUpdated: false,
  userRequested: false,
  userWithdrawn: false,
  userAcceptedInvite: false,
  userRequestedTeams: [],
  resquestedMembers: {},
  blockedMembers: {},
  invitedMembers: {},
  teamUserPosts: {},
  errorMessage: null,
  page: 0,
  noMoreData: false,
  diskAccessUpdated: false,
  isRemovingTeamMember: false,
  editModalOpen: false,
};

export const setGroupId = createAction('setGroupId');
export const setTeamId = createAction('setTeamId');
export const clearTeamUpdated = createAction('clearTeamUpdated');
export const clearTeamCreated = createAction('clearTeamCreated');
export const clearTeamLeft = createAction('clearTeamLeft');
export const clearTeamReported = createAction('clearTeamReported');
export const clearUserRequested = createAction('clearUserRequested');
export const clearUserInvited = createAction('clearUserInvited');
export const clearUserAcceptedInvite = createAction('clearUserAcceptedInvite');
export const clearUsersUpdated = createAction('clearUsersUpdated');
export const clearDiskAccessUpdated = createAction('clearDiskAccessUpdated');
export const clearIsPostAccepted = createAction('clearIsPostAccepted');
export const setEditModalOpen = createAction('setEditModalOpen');

export const getTeamCommunityList = createAsyncThunk('getTeamCommunityList', async (opts, store) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}`,
    method: 'GET',
  };
  const res = await API(obj);
  return res;
});
export const getTeamCommunityListByPage = createAsyncThunk('getTeamCommunityListByPage', async (opts, store) => {
  const { teamsCommunity } = store?.getState?.() ?? {};
  const { page } = teamsCommunity ?? {};
  const obj = {
    url: `${APIEndPoints.TeamCommunity}?page=${(page ?? 0) + 1}`,
    method: 'GET',
  };
  const res = await API(obj);
  return res;
});

export const createTeamCommunity = createAsyncThunk('createTeamCommunity', async (payload) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const formData = jsonToFormData(payload);
    const response = await axiosInstance.post(`${APIEndPoints.TeamCommunity} `, formData, { headers });
    return response;
  } catch (error) {
    return error;
  }
});

export const updateTeamDetails = createAsyncThunk('updateTeamDetails', async (body) => {
  const token = localStorage.getItem('access');
  const headers = {
    'Content-type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  };
  const formData = jsonToFormData(body?.data ?? body ?? {});
  const response = await axiosInstance.patch(`${APIEndPoints.TeamCommunity}${body?.id}/`, formData, { headers });
  return response;
});

export const updateTeamDetailsImages = createAsyncThunk('updateTeamDetailsImages', async (body) => {
  // const obj = {
  //     url: `${APIEndPoints.TeamCommunity}${body.id}/`,
  //     method: 'PATCH',
  //     body: JSON.stringify(body),
  // }
  // return await API(obj)
  const token = localStorage.getItem('access');
  try {
    const headers = {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.patch(`${APIEndPoints.TeamCommunity}${body.id}`, body.form, { headers });
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteTeam = createAsyncThunk('deleteTeam', async (id) => {
  const obj = {
    url: `${APIEndPoints.Teams}${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const leaveTeamCommunity = createAsyncThunk('leaveTeamCommunity', async (payload) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/leave/`,
    method: 'DELETE',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const reportTeamCommunity = createAsyncThunk('reportTeamCommunity', async (payload) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/report/`,
    method: 'PUT',
    body: JSON.stringify(payload),
  };
  return await API(obj);
});

export const getTeamDetails = createAsyncThunk('getTeamDetails', async (id) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}${id}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const getTeamDetailsBySlug = createAsyncThunk('getTeamDetailsBySlug', async (slug) => {
  const obj = {
    url: `${APIEndPoints.Slug}team/?search=${slug}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getTeamMemberList = createAsyncThunk('getTeamMemberList', async (body) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(`${APIEndPoints.TeamCommunity}users/list/`, { params: body }, { headers });
    return response;
  } catch (error) {
    return error;
  }
});

export const getInvitedMemberList = createAsyncThunk('getInvitedMemberList', async (body) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(`${APIEndPoints.TeamCommunity}users/list/`, { params: body }, { headers });
    return response;
  } catch (error) {
    return error;
  }
});

export const getRequestedMemberList = createAsyncThunk('getRequestedMemberList', async (body) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(`${APIEndPoints.TeamCommunity}users/list/`, { params: body }, { headers });
    return response;
  } catch (error) {
    return error;
  }
});
export const getBlockedMemberList = createAsyncThunk('getBlockedMemberList', async (body) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(`${APIEndPoints.TeamCommunity}users/list/`, { params: body }, { headers });
    return response;
  } catch (error) {
    return error;
  }
});

export const getTeamOrganiserList = createAsyncThunk('getTeamOrganiserList', async (body) => {
  // const obj = {
  //     url: `${APIEndPoints.TeamCommunity}users/list/`,
  // }
  // return await API(obj)
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.get(`${APIEndPoints.TeamCommunity}users/list/`, { params: body }, { headers });

    return response;
  } catch (error) {
    return error;
  }
});

export const inviteUsersToTeam = createAsyncThunk('inviteUsersToTeam', async (body) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}invite/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const inviteUsersToFollowCompany = createAsyncThunk('inviteUsersToFollowCompany', async (body) => {
  const obj = {
    url: `${APIEndPoints.InviteConnectionsToFollowCompany}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const joinTeamRequest = createAsyncThunk('joinTeamRequest', async (body) => {
  // initialState.userRequestedTeams.push(body.for_team_community_id)
  const obj = {
    url: `${APIEndPoints.TeamCommunity}join/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const acceptInviteRequest = createAsyncThunk('acceptInviteRequest', async (body) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/status/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const withdrawTeamRequest = createAsyncThunk('withdrawTeamRequest', async (body) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/remove/`,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const createGourpPost = createAsyncThunk('createGourpPost', async (body) => {
  const token = localStorage.getItem('access');
  const headers = {
    'Content-type': 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
    Authorization: `Bearer ${token}`,
  };
  const response = await axiosInstance.post(`${APIEndPoints.TeamChat}`, body, { headers });
  return response;
});

export const getGroupPost = createAsyncThunk('getGroupPost', async (id) => {
  const obj = {
    url: `${APIEndPoints.TeamChat}user/?for_team_community_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getGroupAdminPost = createAsyncThunk('getGroupAdminPost', async (id) => {
  const obj = {
    url: `${APIEndPoints.TeamChat}?for_team_community_id=${id}`,
    method: 'GET',
  };
  return await API(obj);
});

export const approveGroupPost = createAsyncThunk('approveGroupPost', async ({ id, status }) => {
  const token = localStorage.getItem('access');
  const headers = {
    'Content-type': 'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
    Authorization: `Bearer ${token}`,
  };
  const response = await axiosInstance.patch(`${APIEndPoints.TeamChat}${id}/`, { status }, { headers });
  return response;
});

export const updateUserRole = createAsyncThunk('updateUserRole', async (body) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/status/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateDiskStatus = createAsyncThunk('updateDiskStatus', async (body) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/disk/status/`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const removeTeamMember = createAsyncThunk('removeTeamMember', async (body) => {
  const obj = {
    url: `${APIEndPoints.TeamCommunity}user/remove/`,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

const teamCommunitySlice = createSlice({
  name: 'teamCommunity',
  initialState,
  reducers: {},
  extraReducers: {
    [getTeamCommunityList.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.loading = true;
      state.page = 0;
    },
    [getTeamCommunityList.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      state.page = 0;
      if (action.payload.error || action.payload.status_code >= 400) {
        state.error = action.payload.detail;
        state.noMoreData = true;
      } else {
        state.msg = action.payload.msg;
        if (!action.payload.results || action.payload.results.length < 0) {
          state.noMoreData = true;
        } else {
          state.teamCommunityList = action.payload?.results ?? [];
          state.noMoreData = false;
          state.page = 1;
        }
      }
    },
    [getTeamCommunityList.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTeamCommunityListByPage.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.loading = true;
    },
    [getTeamCommunityListByPage.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error || action.payload.status_code >= 400) {
        state.error = action.payload.detail;
        state.noMoreData = true;
      } else {
        state.msg = action.payload.msg;
        if (!action.payload.results || action.payload.results.length < 0) {
          state.noMoreData = true;
        } else {
          state.teamCommunityList = [...(state.teamCommunityList ?? []), ...(action.payload.results ?? [])];
          state.noMoreData = false;
          state.page = state.page + 1;
        }
      }
    },
    [getTeamCommunityListByPage.rejected]: (state, action) => {
      state.loading = false;
    },

    [createTeamCommunity.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamCreated = false;
    },
    [createTeamCommunity.fulfilled]: (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.id) {
        state.msg = 'Team Created succesfully!';
        state.message = undefined;
        state.error = false;
        state.createdTeam = action.payload;
        state.isTeamCreated = true;
      } else {
        state.error = true;
        state.message = action.payload.slug?.[0] ?? action.payload.message;
      }
    },
    [createTeamCommunity.rejected]: (state, action) => {
      state.loading = false;
      state.loading = false;
      state.error = true;
    },

    [createGourpPost.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.isTeamUpdated = false;
    },
    [createGourpPost.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isPostCreated = true;
      }
    },
    [createGourpPost.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateTeamDetails.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamUpdated = false;
    },
    [updateTeamDetails.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (!action.payload.id) {
        state.error = action.payload.error;
        state.message = action.payload.message;
      } else {
        state.msg = action.payload.msg;
        state.isTeamUpdated = true;
      }
    },
    [updateTeamDetails.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateTeamDetailsImages.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.isTeamUpdated = false;
    },
    [updateTeamDetailsImages.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isTeamUpdated = true;
      }
    },
    [updateTeamDetailsImages.rejected]: (state, action) => {
      state.loading = false;
    },

    /* delete team */

    [deleteTeam.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
      state.isTeamUpdated = false;
    },
    [deleteTeam.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isTeamUpdated = true;
      }
    },
    [deleteTeam.rejected]: (state, action) => {
      state.loading = false;
    },

    [leaveTeamCommunity.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamLeft = false;
    },
    [leaveTeamCommunity.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (!action.payload.message?.toLowerCase?.()?.includes?.('user left')) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.msg = action.payload.message;
        state.isTeamLeft = true;
      }
    },
    [leaveTeamCommunity.rejected]: (state, action) => {
      state.loading = false;
    },

    [reportTeamCommunity.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamReported = false;
    },
    [reportTeamCommunity.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (!action.payload.message.includes('reported')) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.msg = action.payload.message;
        state.isTeamReported = true;
      }
    },
    [reportTeamCommunity.rejected]: (state, action) => {
      state.loading = false;
    },

    [approveGroupPost.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isPostAccepted = false;
    },
    [approveGroupPost.fulfilled]: (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isPostAccepted = true;
      }
    },
    [approveGroupPost.rejected]: (state, action) => {
      state.loading = false;
    },

    /* Team Details */
    [getTeamDetails.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [getTeamDetails.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamPage = action.payload;
      }
    },

    [getTeamDetails.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTeamDetailsBySlug.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [getTeamDetailsBySlug.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamSlugPage = action.payload?.[0];
        state.teamId = action.payload?.[0]?.id;
        state.loading = false;
      }
    },
    [getTeamDetailsBySlug.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTeamMemberList.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [getTeamMemberList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamMembers = action.payload;
        state.pending = false;
        state.teamMembers = action.payload;
        // if (action.meta.arg.list_type === 'member') {
        //     state.teamMembers = action.payload;
        // } else if (action.meta.arg.list_type === 'requested') {
        //     state.resquestedMembers = action.payload;
        // } else if (action.meta.arg.list_type === 'blocked') {
        //     state.blockedMembers = action.payload;
        // } else if (action.meta.arg.list_type === 'invited') {
        //     state.invitedMembers = action.payload;
        // }
      }
    },
    [getTeamMemberList.rejected]: (state, action) => {
      state.loading = false;
    },

    // Getting Invited members in team
    [getInvitedMemberList.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [getInvitedMemberList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.pending = false;
        state.invitedMembers = action.payload;
      }
    },
    [getInvitedMemberList.rejected]: (state, action) => {
      state.loading = false;
    },

    // Getting Requested members in team
    [getRequestedMemberList.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [getRequestedMemberList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.pending = false;
        state.resquestedMembers = action.payload;
      }
    },
    [getRequestedMemberList.rejected]: (state, action) => {
      state.loading = false;
    },

    // Getting Blocked members in team
    [getBlockedMemberList.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [getBlockedMemberList.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.pending = false;
        state.blockedMembers = action.payload;
      }
    },
    [getBlockedMemberList.rejected]: (state, action) => {
      state.loading = false;
    },

    [getTeamOrganiserList.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [getTeamOrganiserList.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamOrganisers = action.payload;
        state.pending = false;
      }
    },
    [getTeamOrganiserList.rejected]: (state, action) => {
      state.pending = false;
    },

    [getGroupPost.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [getGroupPost.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamPosts = action.payload;
      }
    },
    [getGroupPost.rejected]: (state, action) => {
      state.loading = false;
    },

    [getGroupAdminPost.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [getGroupAdminPost.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamPosts = action.payload;
      }
    },
    [getGroupAdminPost.rejected]: (state, action) => {
      state.loading = false;
    },

    [inviteUsersToTeam.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [inviteUsersToTeam.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersInvited = true;
      }
    },
    [inviteUsersToTeam.rejected]: (state, action) => {
      state.loading = false;
    },

    [inviteUsersToFollowCompany.pending]: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    [inviteUsersToFollowCompany.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersInvited = true;
      }
    },
    [inviteUsersToFollowCompany.rejected]: (state, action) => {
      state.loading = false;
    },

    [joinTeamRequest.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.userRequested = false;
    },
    [joinTeamRequest.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.userRequested = true;
      }
    },
    [joinTeamRequest.rejected]: (state, action) => {
      state.loading = false;
    },
    [withdrawTeamRequest.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.userWithdrawn = false;
      state.error = false;
    },
    [withdrawTeamRequest.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.userWithdrawn = true;
      }
    },
    [withdrawTeamRequest.rejected]: (state, action) => {
      state.loading = false;
    },

    [acceptInviteRequest.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
    },
    [acceptInviteRequest.fulfilled]: (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.userAcceptedInvite = true;
      }
    },
    [acceptInviteRequest.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateUserRole.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.usersUpdated = false;
    },
    [updateUserRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersUpdated = true;
      }
    },
    [updateUserRole.rejected]: (state, action) => {
      state.loading = false;
    },

    [updateDiskStatus.pending]: (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.diskAccessUpdated = false;
    },
    [updateDiskStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.diskAccessUpdated = true;
      }
    },
    [updateDiskStatus.rejected]: (state, action) => {
      state.loading = false;
    },

    [removeTeamMember.pending]: (state, action) => {
      state.pending = true;
      state.isRemovingTeamMember = true;
      state.error = false;
      state.usersUpdated = false;
    },
    [removeTeamMember.fulfilled]: (state, action) => {
      state.isRemovingTeamMember = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersUpdated = true;
      }
    },
    [removeTeamMember.rejected]: (state, action) => {
      state.isRemovingTeamMember = false;
    },

    [setTeamId]: (state, action) => {
      state.teamId = action.payload;
    },
    [setEditModalOpen]: (state, action) => {
      state.editModalOpen = action.payload;
    },
    [setGroupId]: (state, action) => {
      state.groupId = action.payload;
    },
    [clearTeamUpdated]: (state, action) => {
      state.isTeamUpdated = false;
    },
    [clearTeamCreated]: (state, action) => {
      state.isTeamCreated = false;
    },
    [clearTeamLeft]: (state, action) => {
      state.isTeamLeft = false;
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
    },
    [clearTeamReported]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.isTeamReported = false;
    },
    [clearUserInvited]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.usersInvited = false;
    },
    [clearUserRequested]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.userRequested = false;
    },
    [clearUserAcceptedInvite]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.userAcceptedInvite = false;
    },
    [clearUsersUpdated]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.usersUpdated = false;
    },
    [clearDiskAccessUpdated]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.diskAccessUpdated = false;
    },
    [clearIsPostAccepted]: (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.isPostAccepted = false;
    },
  },
});

export default teamCommunitySlice.reducer;
