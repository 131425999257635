import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

import Button from 'components/common/Button';
import { Input } from 'components/common/Input';

import {
  addEmail,
  deactivateUser,
  emailList,
  getVerifyEmailOTP,
  removeEmail,
  sendMobileVerifyCode,
  setPrimaryEmail,
  verifyEmailOTP,
  verifyMobileOtp,
} from 'store/slices/settingsSlice';
import { getUserProfileInfo, userProfileAddInfo } from 'store/slices/userProfileSlice';

import Name from './sections/Name';
import Password from './sections/Password';
import Language from './sections/Language';
// import Email from './sections/Email';
// import PhoneNumber from './sections/PhoneNumber';
import Location from './sections/Location';
import Deactivation from './sections/Deactivation';
import { confirmAlert } from 'react-confirm-alert';
import { Spinner } from 'components/common/Loader';
import { Link } from 'react-router-dom';

const Account = () => {
  const [fields, setFields] = useState({});
  const { userProfileInfo, isProfileUpdated, loading } = useSelector((state) => state.userProfile);

  const { isEmailUpdated, emailInfo, receivedOtpCode, isMobileOtp } = useSelector((state) => state.settings);

  const [addEditMobileNo, setAddEditMobileNo] = useState(false);
  const [addEditEmail, setAddEditEmail] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addEditEmailOtp, setAddEditEmailOtp] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otp, setOtp] = useState('');
  const [verifyEmailId, setVerifyEmailId] = useState('');
  const [showVerify, setShowVerify] = useState(false);

  const [mobileOtp, setMobileOtp] = useState(isMobileOtp);
  const [isChangeNumber, setIsChangeNumber] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(emailList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEmailUpdated) {
      setAddEditEmail(false);
      setAddEditEmailOtp(false);
      dispatch(emailList());
    }
    // eslint-disable-next-line
  }, [isEmailUpdated]);

  useEffect(() => {
    if (receivedOtpCode) {
      setAddEditEmailOtp(true);
    }
  }, [receivedOtpCode]);

  useEffect(() => {
    if (isMobileOtp) {
      dispatch(getUserProfileInfo());
      setAddEditMobileNo(false);
    }
  }, [isMobileOtp]);

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const phoneOnChange = (e) => {
    let name = 'changeNumber';
    const value = e;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const resendVerification = (emailId) => {
    setOtp('');
    setVerifyEmailId(emailId);
    dispatch(getVerifyEmailOTP({ email: emailId }));
  };

  const handleSetPrimaryEmail = (emailId) => {
    confirmAlert({
      title: "Change primary email address?",
      message: `Are you sure you want to change the primary email address to: ${emailId}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(setPrimaryEmail({ email: emailId })).then(() => {
            dispatch(getUserProfileInfo())
          })
        },
        {
          label: "No"
        }
      ]
    })

  };

  const deleteEmail = (emailId) => {
    confirmAlert({
      title: "Delete email address?",
      message: `Are you sure you want to delete this email address: ${emailId}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(removeEmail({ email: emailId }))
        },
        {
          label: "No"
        }
      ]
    })

  };

  const editEmail = () => {
    setAddEditEmail(true);
    setFields({ ...userProfileInfo });
  };

  const editMobileNo = () => {
    setAddEditMobileNo(true);
    setIsChangeNumber(true);
    setShowVerify(false);
    setMobileOtp(false);
  };

  const cancelChangeNumber = () => {
    setAddEditMobileNo(false);
    setIsChangeNumber(false);
  };

  const saveNewMobileNumber = () => {
    let errorObject = {};

    if (!fields.changeNumber) {
      errorObject.changeNumber = 'Please enter a new mobile number';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }
    const formData = new FormData();
    formData.append('mobile_number', fields.changeNumber);
    dispatch(userProfileAddInfo(formData)).then((response) => {
      setIsChangeNumber(false);
    });
  };

  const handleSendOtpCode = () => {
    // let errorObject = {}

    // if (!fields.verfiyNumber) {
    //     errorObject.verfiyNumber = 'Please enter a mobile number'
    // }

    // if (Object.keys(errorObject).length > 0) {
    //     setErrorFields(errorObject)
    //     return
    // } else {
    //     setErrorFields({})
    // }

    // dispatch(sendMobileVerifyCode({
    //     mobile_number: fields?.verfiyNumber
    // }))
    dispatch(sendMobileVerifyCode());
    setAddEditMobileNo(true);
    setShowVerify(true);
    setIsChangeNumber(false);
    setMobileOtp(true);
  };

  const handleVerifyMobileOtp = () => {
    let errorObject = {};

    if (!fields.mobileOtp || fields.mobileOtp.length <= 0) {
      errorObject.mobileOtp = 'Please enter a valid OTP';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }
    dispatch(
      verifyMobileOtp({
        otp: fields.mobileOtp,
      }),
    );
    setMobileOtp(false);
  };

  const handleVerifyMobileNo = () => {
    setAddEditMobileNo(true);
    setShowVerify(true);
    setMobileOtp(false);
    setIsChangeNumber(false);
  };

  const cancelVerifyPhoneNo = () => {
    setAddEditMobileNo(false);
    setShowVerify(false);
  };

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(getUserProfileInfo());
      setAddEditMobileNo(false);
    }
    // eslint-disable-next-line
  }, [isProfileUpdated]);

  const handleEmail = () => {
    const emailVerify = /^([a-z\d\.\+-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    if (!email || !emailVerify.test(email)) {
      setEmailError('Enter a valid email address');
      return;
    }
    try {
      setVerifyEmailId(email);
      // eslint-disable-next-line no-undef
      dispatch(addEmail({ email })).then((response) => {
        if (!response.payload.status_code) {
          dispatch(getVerifyEmailOTP({ email: email })).then((response) => {
            if (response) {
              setAddEditEmail(false);
              setEmail('');
              setEmailError('');
            }
          });
        } else {
          setEmailError(response.payload.email);
          setVerifyEmailId('');
          setAddEditEmail(true);
          return;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const verifyEmailOtpCode = () => {
    if (!otp) {
      setOtpError('Enter a OTP');
      return;
    }
    dispatch(verifyEmailOTP({ email: verifyEmailId, otp })).then((response) => {
      if (!response.payload.status_code) {
        setAddEditEmail(false);
        setEmail('');
        setEmailError('');
        setVerifyEmailId('');
      } else {
        setOtpError(response.payload.detail);
        setAddEditEmailOtp(true);
      }
    });
  };

  const phoneWithCode = (num) => {
    if (num?.length > 10) {
      const code = num?.substr?.(0, num.length - 10);
      const phone = num?.substr?.(num.length - 10)
      return "+" + code + "-" + phone;
    }
    return num;
  }

  return (
    <>
      <div className="user-settings--account">
        <h5 className="setting-title">Account Information</h5>
        <hr className="hr" />

        {/* Name */}
        <Name />
        <hr className="hr" />

        {/* Password */}
        <Password />
        <hr className="hr" />

        {/* Language */}
        <Language />
        <hr className="hr" />

        {/* Email address */}
        {/* <Email /> */}
        <div className="row g-0 justify-content-md-between">
          <div className="col-12 col-lg">
            <div className="row">
              <div className="col-12 col-lg">
                <p className="label">Email Address</p>
                {/* <p className="label-subtext">
                                    Add new email address
                                </p> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2">
            <div className="text-lg-end">
              <p className="text-primary links" role="button" onClick={editEmail}>
                Add email address
              </p>
            </div>
          </div>
          <div className="col-12 mb-3">
            <p className="fw-semibold m-0">
              {emailInfo?.results?.find?.(ei => ei.is_primary)?.email ?? userProfileInfo?.email} <span className="text-muted">Primary</span>
            </p>
          </div>
          {/* Add new email */}
          {addEditEmail && (<>
            <div className="col-12 ">
              <p class="label-subtext">Add New Email Id</p>
            </div>
            <div className="d-flex justify-content-between gap-3 pb-3 border-bottom align-items-center">
              <div className="gap-2">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Add New Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
              <div className="d-flex gap-3">
                <Button
                  className="btn btn-secondary fw-normal"
                  onClick={() => {
                    setAddEditEmail(false);
                    setEmail('');
                    setEmailError('');
                  }}
                >
                  Cancel
                </Button>
                <Button className="btn btn-primary fw-normal" onClick={handleEmail}>
                  Save
                </Button>
              </div>
            </div>
          </>
          )}
          {/* OTP fields for verification */}
          {addEditEmailOtp && (<>
            <div className="col-12">
              <p class="label-subtext">Verify OTP for {verifyEmailId}</p>
              <div className="d-flex justify-content-between gap-3 pb-3 border-bottom align-items-center">
                <div className="gap-2">
                  <Input
                    type=""
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="form-control"
                    placeholder="Enter Email OTP "
                  />
                  {otpError && <div className="text-danger">{otpError}</div>}
                </div>
                <div className="d-flex gap-3">
                  <Button className="btn btn-secondary fw-normal" onClick={() => setAddEditEmailOtp(false)}>
                    Cancel
                  </Button>
                  <Button disabled={loading} className="btn btn-primary fw-normal" onClick={verifyEmailOtpCode}>
                    {loading ? <Spinner /> : "Verify"}
                  </Button>
                </div>
              </div>
            </div>
          </>
          )}

          {/* Email ID list */}
          {emailInfo?.results?.length > 0 && (
            <>
              {' '}
              <div className="col-12">
                {emailInfo?.results?.map(({ email, is_primary, is_verified }) => (
                  <div key={email} className="d-flex justify-content-between gap-3">
                    <div className="d-flex gap-2">
                      <p className="fw-semibold email">{email}</p>
                      {is_primary && <p className="text-muted">Primary</p>}
                    </div>
                    <div className="d-flex gap-4">
                      {!is_verified && (
                        <p className="text-primary links" role="button" onClick={() => resendVerification(email)}>
                          Resend verification
                        </p>
                      )}
                      {!is_primary && is_verified && (
                        <p
                          className="text-primary links"
                          role="button"
                          onClick={() => {
                            handleSetPrimaryEmail(email);
                          }}
                        >
                          Set Primary
                        </p>
                      )}
                      {!is_primary && (
                        <p className="text-primary links" role="button" onClick={() => deleteEmail(email)}>
                          Remove
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <hr className="hr" />

        {/* Phone Number */}
        {!addEditMobileNo ? (
          <div className="row g-0 justify-content-md-between">
            <div className="col-12 col-lg">
              <div className="row">
                <div className="col-12 col-lg">
                  <p className="label">Phone Number</p>
                  <p className="label-subtext">Add a phone number in case you have trouble signing in</p>
                </div>
                <div className="col-12 col-lg">
                  <div key={email} className="d-flex gap-3">
                    <p className="fw-semibold">{phoneWithCode(userProfileInfo?.mobile_number)}</p>
                    {userProfileInfo.is_mobile_verified && <p className="text-muted">Verified</p>}
                    {!userProfileInfo.is_mobile_verified && (
                      <p className="text-primary links" role="button" onClick={handleSendOtpCode}>
                        Verify Mobile Number
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2">
              <div className="text-lg-end">
                <p className="text-primary links" role="button" onClick={editMobileNo}>
                  Change
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row g-0 justify-content-md-between">
            <div className="col-12 col-lg">
              <div className="row">
                <div className="col-12">
                  <p className="label">Phone Number</p>
                  <p>Add a phone number in case you have trouble signing in</p>
                </div>
                <div className="col-12">
                  <div className="row">
                    {isChangeNumber && (
                      <div className="col-12 col-md-6 col-lg-4">
                        <PhoneInput
                          country={'us'}
                          value={fields.changeNumber}
                          onChange={phoneOnChange}
                          name="changeNumber"
                          placeholder="Enter New Phone Number"
                        />
                        {errorFields.changeNumber && <div className="text-danger">{errorFields.changeNumber}</div>}
                        <div className="d-flex gap-3 mt-4">
                          <Button className="btn btn-secondary fw-normal" onClick={cancelChangeNumber}>
                            Cancel
                          </Button>
                          <Button disabled={isChangeNumber && loading} className="btn btn-primary fw-normal" onClick={saveNewMobileNumber}>
                            {(isChangeNumber && loading) ? <Spinner /> : "Save"}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 form-group">
                    {mobileOtp && (
                      <>
                        <p>Enter the verification code we sent to the number</p>

                        <div className="row align-items-center">
                          <div className="col-12 col-md-6 col-lg-4">
                            <Input
                              type="number"
                              name="mobileOtp"
                              value={fields.mobileOtp}
                              onChange={fieldsOnChange}
                              className="form-control"
                              placeholder="Enter OTP"
                            />
                            {errorFields.mobileOtp && <div className="text-danger">{errorFields.mobileOtp}</div>}
                          </div>
                          <div className="col-12 col-md-6 col-lg-4">
                            <Link className="form-group" role="button" onClick={handleSendOtpCode}>
                              Resend Code
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex gap-2 mt-4">
                          <Button className="btn btn-secondary fw-normal" onClick={() => setAddEditMobileNo(false)}>
                            Cancel
                          </Button>
                          <Button disabled={loading && mobileOtp} className="btn btn-primary fw-normal" onClick={handleVerifyMobileOtp}>
                            {(loading && mobileOtp) ? <Spinner /> : "Verify"}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr className="hr" />

        {/* Location */}
        <Location />
        <hr className="hr" />

        {/* Deactivate Account */}
        <Deactivation />
      </div>
    </>
  );
};

export default Account;
