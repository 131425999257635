import { ReportIcon } from 'components/common/Icons';
import Loader from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import RadioButton from 'components/common/RadioButton';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportConnection, resetReport } from 'store/slices/connectionSlice';
import { Text } from 'components/StyledComponents';
import styled from 'styled-components';

const ConfirmationSubText = styled.div`
  color: #5b5b5b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding:0px;
`;

const ReportModal = ({ onClose, id, name, title, isTeam = false }) => {
  const [reportReason, setReportReason] = useState(null);
  const isLoading = useSelector((state) => state.connection.childLoading);
  const userReport = useSelector((state) => state.connection.report);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setReportReason(e.target.value);
  };

  const handleSend = () => {
    const payload = {
      block_user_id: id,
      report_message: reportReason,
    };
    dispatch(reportConnection(payload));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && (
        <Modal
          onClose={handleClose}
          className="confirmation-modal"
          heading={
            !userReport ? (
              <h3>
                {title ?? 'Report'} {name}?
              </h3>
            ):(
              <Text color="#3D5A80" fS="18px" fW={600}>
                Reported
              </Text>
              )
          }
          action={
            <>
              <button
                className={`btn ${userReport?' btn-primary':'btn-outline-primary'} ${userReport ? '' : ''}`}
                onClick={() => {
                  onClose();
                  if (userReport) dispatch(resetReport());
                }}
              >
                {!userReport ? 'Cancel' : 'Done'}
              </button>
              {!userReport && (
                <button className="btn btn-primary" disabled={Boolean(!reportReason)} onClick={handleSend}>
                  Submit
                </button>
              )}
            </>
          }
        >
          {!userReport ? (
            <>
              <h4 className="mb-1" style={{ color: '#3D5A80' }}>
                Please select a problem
              </h4>
              <p className="mb-3" style={{ fontSize: 15, fontWeight: 400, color: '#5B5B5B' }}>
                 If someone is in immediate danger, get help before reporting to myBridge. Don't wait. 
              </p>

              <div className="radio-wrapper">
                <RadioButton
                  color="#3D5A80"
                  changed={handleChange}
                  name="report"
                  label="Pretending to be someone"
                  value="Pretending to be someone"
                />
                <RadioButton
                  changed={handleChange}
                  color="#3D5A80"
                  name="report"
                  label="Fake account"
                  value="Fake account"
                />
                <RadioButton changed={handleChange} color="#3D5A80" name="report" label="Fake name" value="Fake name" />
                <RadioButton
                  color="#3D5A80"
                  changed={handleChange}
                  name="report"
                  label="Posting inappropriate things"
                  value="Posting inappropriate things"
                />
                <RadioButton
                  color="#3D5A80"
                  changed={handleChange}
                  name="report"
                  label="Harassment or bullying"
                  value="Harassment or bullying"
                />
                <RadioButton
                  changed={handleChange}
                  color="#3D5A80"
                  name="report"
                  label="I want to help"
                  value="I want to help"
                />
              </div>
            </>
          ) : (
            // <div className="pt-5 text-center">
            //   <ReportIcon color="#000" width={80} height={80} className="mb-3" />
            //   <h4 className="text-capitalize mb-0 lh-base">
            //     {name} {userReport.message}
            //   </h4>
            // </div>
            <ConfirmationSubText className="mt-1"><span style={{color:'#3D5A80'}}>{name}</span> {userReport.message}</ConfirmationSubText>
          )}
        </Modal>
      )}
    </>
  );
};

export default ReportModal;
