import { DegreeIcon } from 'components/common/Icons';
import PrimaryAvatarList from 'components/common/List';
import Loader from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSharedConnectionsData, setIdforMutual } from 'store/slices/connectionSlice';
import { Link, useNavigate } from 'react-router-dom';

const SharedConnections = ({ onClose, name, id, icon, designation }) => {
  const { mutualConnections } = useSelector((state) => state.connection);
  const isLoading = useSelector((state) => state.connection.childLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const payload = {
      id: id,
    };
    dispatch(getSharedConnectionsData(payload));
  }, []);




  const renderData = () => {
    let temp;

    if (mutualConnections?.results?.length < 1) {
      temp = <p>No Data Found!</p>;
    } else {
      temp = (
        <>
          {mutualConnections?.results?.slice(0,3).map((connection) => { 
            console.log(connection?.degree_count, 'connection?.degree_count');
            return (
              <PrimaryAvatarList
                key={connection.id}
                icon={connection.profile_pic}
                size="lg"
                name={connection.first_name + ' ' + connection.last_name}
                designation={connection.position}
              >
                <ul className="connection-chaining mt-2">
                  <li>
                    {connection?.first_degree?.length}
                    <DegreeIcon />
                    1st Degrees
                  </li>

                  <li>
                    {connection?.second_degree?.length}
                    <DegreeIcon />
                    2nd Degrees
                  </li>

                  <li>
                    {connection?.third_degree?.length}
                    <DegreeIcon />
                    3rd Degrees
                  </li>
                </ul>
              </PrimaryAvatarList>
              
            );
          })}
          <div className='modal-more-section'>
            <a href={`/mutual-connections/${id}`}>See All</a>
          </div>
        </>
      );
    }

    return temp;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal onClose={onClose} className="confirmation-modal lg" heading={<h3>Mutual connection with {name}</h3>}>
          {renderData()}
        </Modal>
      )}
    </>
  );
};

export default SharedConnections;
