import React from 'react';
// import Avatar from 'react-avatar';
// import { DegreeIcon, SimpleCloseIcon } from 'components/common/Icons';
// import Button from 'components/common/Button';
// import ReadMore from 'components/common/ReadMore';
import InviteCard from 'components/Network/InviteCard';

const SentInvitations = (props) => {
    const { user } = props;
    const userList = user;

    console.log(userList);
    return (
        <>
            <div className='invite-section'>
               
                <div className="invite-container">
                {userList?.map((userInfo, i) => (
                            <InviteCard type="sent" key={i} user={userInfo} />
                        ))}
                    
                </div>
            </div>
        </>
    )
}

export default SentInvitations