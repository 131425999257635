import Card from 'components/common/Card';
import React, {useState, useEffect} from 'react';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamDetails } from 'store/slices/teamCommunitySlice';
import { Input } from 'components/common/Input';
const MembersNote = () => {
  const { teamId, teamSlugPage, isTeamUpdated } = useSelector((state) => state.teamsCommunity);
  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [message, setMessagen] = useState(teamSlugPage?.welcome_note);
  const dispatch = useDispatch();

  const updateTeamWelcomeDetails = (e) => {
       let body = {
        "id": teamId,
        "is_enable_welcome_note": e.target.checked,
       };
       dispatch(updateTeamDetails(body));
  };
  
  const updateTeamWelcomeMessage = () => {
       let body = {
        "id": teamId,
        "welcome_note": message,
       };
       dispatch(updateTeamDetails(body));
  };

  useEffect(() => {
    setMessageBoxOpen(false);
  }, [isTeamUpdated]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <div className="welcomeNoteInner">
          <h6 className="d-flex align-items-center gap-2">
            Automatic welcome note
            <ToggleSwitch onChange={(e) => updateTeamWelcomeDetails(e)} name="enableWelcomeNote" defaultValue={teamSlugPage?.is_enable_welcome_note} label="enableWelcomeNote" />
          </h6>
          <p>New members will see the note when they visit the Team.</p>
          
          
        </div>
        <div className="">
          {!messageBoxOpen && <Button className="btn-sm" onClick={() => setMessageBoxOpen(true)}>Set up</Button>}
        </div>
      </div>
      {messageBoxOpen && <React.Fragment><Input placeholder="Location" type="text" onChange={(e) => setMessagen(e.target.value)} name="welcomeNote" value={message} />
            <p className='text-end'><Button className="btn-sm" onClick={() => updateTeamWelcomeMessage()}>Save</Button> </p></React.Fragment>
          }
    </React.Fragment>
  );
};

export default MembersNote;
