import React from "react";

const JobListingHeading = () => {
    return <>
        <div className="mb-4">
            Below are other jobs you might like, based on your resume, prior job applications and search history.
        </div>
    </>
}

export default JobListingHeading;