import Checkbox from 'components/common/Checkbox';
import {
    Chart,
    Documents,
    FolderIcon,
    Music,
    Others,
    PDF,
    Pictures,
    Sheet,
    Videos
} from 'components/common/Icons';
import moment from 'moment';

export const FileTypes = [
    {
        label: "File",
        value: "F"
    },
    {
        label: "Folder",
        value: "D"
    },
]

export const LastModified = [
    {
        label: "Any Time",
        value: "all"
    },
    {
        label: "Last 24 Hours",
        value: [moment(), moment().add(1, "day")]
    },
    {
        label: "Last 7 Days",
        value: [moment().add(-7, 'days'), moment().add(1,"day")]
    },
    {
        label: "Last 30 Days",
        value: [moment().add(-30, 'days'), moment().add(1,"day")]
    },
    {
        label: "Last 90 Days",
        value: [moment().add(-90, 'days'), moment().add(1,"day")]
    },
    {
        label: "Last 12 months",
        value: [moment().add(-12, 'months'), moment().add(1,"day")]
    },
    // {
    //     label: "Custom",
    //     value: [moment().add(-24, 'hours'), moment()]
    // },
]

export const MimeTypes = [
    {
        label: "Images",
        icon: <Pictures />,
        value: "images"
    },
    // {
    //     label: <Checkbox label="Folders" />,
    //     icon: <FolderIcon />,
    //     value:""
    // },
    {
        label: "Video",
        icon: <Videos />,
        value: "videos"
    },
    // {
    //     label: <Checkbox label="PDFs" />,
    //     icon: <PDF />,
    //     value:"documents"
    // },
    {
        label: "Documents",
        icon: <Documents />,
        value: "documents"
    },
    {
        label: "Spreadsheets",
        icon: <Sheet />,
        value: "spreadsheets"
    },
    {
        label: "Presentations",
        icon: <Chart />,
        value: "presentations"
    },
    {
        label: "Audio",
        icon: <Music />,
        value: "audios"
    },
    // {
    //     label: <Checkbox label="Other" />,
    //     icon: <Others />,
    //     value:""
    // }
]