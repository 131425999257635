import { Text } from 'components/StyledComponents';
import Button from 'components/common/Button';
import {
  AmexIcon,
  CardIcon,
  CircleFilled,
  DeleteIcon,
  LockIcon,
  MastercardIcon,
  QuestionIcon,
  VisaIcon,
} from 'components/common/Icons';
import { Input } from 'components/common/Input';
import RadioButton from 'components/common/RadioButton';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { addCard, cardList, deleteCard, updateCard } from 'store/slices/paymentSlice';
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate
} from './paymentUtils';
import { Spinner } from 'components/common/Loader';
import moment from 'moment';

const ERROR_VALUES = {
  name: 'Please provide card owner\'s name',
  number: 'Please enter a valid card number',
  expiry: 'Please enter a valid expiry date',
  cvc: 'Enter CVC number',
};

export default function Payment() {
  const { cardInfo, isCardUpdated, error, msg, loading, addingCard } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [focused, setFocused] = useState();
  const [tooltipMsg, setTooltipMsg] = useState()
  const securityCodeInfoRef = useRef()
  const cardInfoRef = useRef()

  useEffect(() => {
    dispatch(cardList());
  }, []);

  useEffect(() => {
    if (isCardUpdated) {
      dispatch(cardList());
    }
  }, [isCardUpdated]);


  const fieldsOnChange = (e) => {

    let { name, value } = e.target;
    if (name === 'number') {
      value = formatCreditCardNumber(value)
    } else if (name === 'expiry') {
      value = formatExpirationDate(value)
      console.log(value);
    } else if (name === 'cvc') {
      value = formatCVC(value)
    }
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
    const isValue = value.trim().length > 0;
    setErrorFields((prevErrors) => ({ ...prevErrors, [name]: isValue ? undefined : ERROR_VALUES[name] }));
  };

  const saveCard = () => {
    const { number, expiry, cvc, name } = fields;
    const creditNumber =
      /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
    const errorObject = {};
    const expiryDate = _.split(expiry, '/', 2);

    if (!name || name.length <= 0) {
      errorObject.name = ERROR_VALUES.name
    }

    if (!number || !creditNumber.test(number.replaceAll(" ", ""))) {
      errorObject.number = ERROR_VALUES.number;
    }
    if (
      !expiry ||
      !expiryDate
    ) {
      errorObject.expiry = ERROR_VALUES.expiry;
    }

    if (parseInt(expiryDate[1]) < moment().year() || parseInt(expiryDate[1]) > (moment().year() + 100)) {
      errorObject.expiry = ERROR_VALUES.expiry;
    } else {
      if (parseInt(expiryDate[1]) === moment().year()) {
        if (parseInt(expiryDate[0]) < (moment().month() + 1) || parseInt(expiryDate[0]) > 12) {
          errorObject.expiry = ERROR_VALUES.expiry;
        }
      } else {
        if (parseInt(expiryDate[0]) < 1 || parseInt(expiryDate[0]) > 12) {
          errorObject.expiry = ERROR_VALUES.expiry;
        }
      }
    }

    if (!cvc) {
      errorObject.cvc = ERROR_VALUES.cvc;
    }

    if (Object.keys(errorObject).length > 0) {
      setErrorFields(errorObject);
      return;
    } else {
      setErrorFields({});
    }

    const payload = {
      number: number.replaceAll(" ", ""),
      exp_month: expiryDate[0],
      exp_year: expiryDate[1],
      cvc: cvc,
      name: name,
    };
    dispatch(addCard(payload)).then(() => {
      setFields({})
    });
  };

  const handleDelete = (cardId) => {
    confirmAlert({
      // title: 'Confirm to Delete',
      message: 'Are you sure you want to delete the card?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(deleteCard(cardId)),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const displayCardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <VisaIcon />;
      case 'mastercard':
        return <MastercardIcon />;
      case 'amex':
        return <AmexIcon />;
      default:
        return <CardIcon />;
    }
  };

  const markPrimary = (cardId) => {
    confirmAlert({
      // title: 'Confirm to Delete',
      message: 'Are you sure you want to make this card default?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(updateCard({ card_id: cardId })),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleClickScroll = () => {
    const element = document.getElementById('addCardsForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className="user-settings--payment">
      <div className="row gap-lg-3 mb-5">
        <div className="col-12">
          <div className="border-bottom d-flex align-items-center justify-content-between">
            <Text fS="20px">Payment</Text>
            <button className="btn fw-normal p-0 p-lg-2 d-flex" onClick={() => handleClickScroll()}>
              <Text fS="18px">Add New Credit or Debit Card</Text>
              <CircleFilled className="ms-2" color="#3D5A80" />
            </button>
          </div>
          <div className="pt-4 d-block d-lg-flex align-items-center justify-content-between">
            <Text fS="18px">Your Credit and Debit Cards {loading ? <Spinner /> : ""}</Text>
          </div>
        </div>

        <div className="col-12">
          <div className="table-responsive">
            <table className="table">
              <thead className="table-light">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <Text fW="600" color="#333333">
                      Name on Card
                    </Text>
                  </th>
                  <th scope="col">
                    <Text fW="600" color="#333333">
                      Expires on
                    </Text>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {cardInfo?.cards?.length > 0 ? (
                  cardInfo?.cards?.map((payment, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <RadioButton
                            name="primary"
                            changed={() => markPrimary(payment.id)}
                            isSelected={cardInfo.default_card_id === payment.id ? true : false}
                          />
                          <Text fW="500" color="#333333">
                            {displayCardIcon(payment.card.brand)}
                            &nbsp;ending in {payment.card.last4}
                          </Text>
                        </div>
                      </td>
                      <td>
                        <Text fW="500" color="#333333">
                          {payment?.card?.name ?? payment.billing_details?.name ?? '-'}
                        </Text>
                      </td>
                      <td>
                        <Text fW="500" color="#333333">
                          {payment.card.exp_month}/{payment.card.exp_year}
                        </Text>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleDelete(payment.id)}
                          className="btn btn-link onlyIcon"
                        >
                          <DeleteIcon width="14" />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" align="center">
                      No Cards Added
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-12">
          <Text fS="18px" color="#333333" className="py-3">
            Add New Credit or Debit Cards
          </Text>

          {msg !== undefined && <p className="text-success helper-text my-3">{msg}</p>}
          <div className="card" id="addCardsForm">
            <div className="card-header bg-transparent d-flex align-items-center justify-content-between">
              <Text fW="600" color="#333333">
                Add Credit card
              </Text>
              <div className="d-flex gap-2 align-items-center">
                <VisaIcon />
                <MastercardIcon />
                <AmexIcon />
                <CardIcon />
                <Text fS="12px" color="#333333">
                  and more...
                </Text>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <Input
                    type="text"
                    placeholder="Name on card"
                    className={errorFields.name && 'is-invalid'}
                    name="name"
                    onChange={fieldsOnChange}
                    value={fields.name}
                    error={errorFields.name}
                  />
                </div>
                <div className="col-12 mb-2">
                  <Input
                    type="tel"
                    className={errorFields.number && 'is-invalid'}
                    placeholder="Card number"
                    id="cardnumber"
                    name="number"
                    onChange={fieldsOnChange}
                    value={fields.number}
                    endIcon={
                      <div aria-label="All transactions are secure and encrypted." data-microtip-position="top-left" data-microtip-size="large" role="tooltip">
                        <LockIcon />
                      </div>
                    }
                    containerClass="d-flex"
                    error={errorFields.number && errorFields.number}
                  />
                </div>
                <div className="col-6 mb-2">
                  <Input
                    type="tel"
                    className={errorFields.expiry && 'is-invalid'}
                    name="expiry"
                    onChange={fieldsOnChange}
                    placeholder="Expiry date (MM / YYYY)"
                    error={errorFields.expiry && errorFields.expiry}
                    value={fields.expiry}
                  />
                </div>
                <div className="col-6 mb-2">
                  <Input
                    type="number"
                    className={errorFields.cvc && 'is-invalid'}
                    placeholder="Security Code"
                    name="cvc"
                    onChange={fieldsOnChange}
                    value={fields.cvc}
                    endIcon={
                      <div aria-label="Security code is the last three digits on the back of your credit card, or the four digit code on the front of your American Express." data-microtip-position="bottom-left" data-microtip-size="large" role="tooltip">
                        <QuestionIcon />
                      </div>
                    }
                    containerClass="d-flex"
                    error={errorFields.cvc && errorFields.cvc}
                  />
                </div>
                <div className="col-12">
                  <Button disabled={addingCard} className="btn mt-3 w-25" onClick={saveCard}>
                    <Text fW="600" fS="18px" color="#fff">
                      {addingCard ? <Spinner /> : "Save Card"}
                    </Text>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
