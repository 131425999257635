import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'components/common/Input';
import Button from 'components/common/Button';
import { CometChat } from '@cometchat-pro/chat';
import { updateUserDetails, getUserProfileInfo } from 'store/slices/userProfileSlice';
import { Spinner } from 'components/common/Loader';

const Name = () => {
  const dispatch = useDispatch();
  const { userProfileInfo, isProfileUpdated, updateStatus } = useSelector((state) => state.userProfile);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (userProfileInfo?.id) {
      setValues(userProfileInfo);
    }

    return () => setValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProfileUpdated) {
      setEditMode(false);
      dispatch(getUserProfileInfo());
    }
  }, [isProfileUpdated])

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const onClickSave = () => {
    const errorObject = {};
    if (!values.first_name || !values.last_name) {
      errorObject.name = 'Please enter first name and last name';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrors(errorObject);
      return;
    }
    dispatch(updateUserDetails(values)).then(res => {
      if (res) {
        let uid = userProfileInfo.user_uuid;
        let name = `${values.first_name} ${values.last_name}`;

        var user = new CometChat.User(uid);

        user.setName(name);

        CometChat.updateCurrentUserDetails(user).then(
          user => {
            console.log("user updated");
          }, error => {
            console.log("error", error);
          }
        )
      }
    });
  };

  const onClickCancel = () => {
    setEditMode(false);
    setErrors({});
    setValues(userProfileInfo);
  };

  if (editMode) {
    return (
      <div className="row g-0 justify-content-md-between">
        <div className="col-12 col-lg">
          <div className="row">
            <div className="col-12">
              <p className="label">Name</p>
              <p>Choose how your name appear to other members</p>
            </div>
            <div className="col-12 col-lg">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <Input
                    type="text"
                    name="first_name"
                    className="form-control"
                    value={values.first_name}
                    onChange={fieldsOnChange}
                    placeholder="Enter First Name"
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <Input
                    type="text"
                    name="last_name"
                    className="form-control"
                    value={values.last_name}
                    onChange={fieldsOnChange}
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
            <div className="d-flex gap-2 mt-4">
              <Button className="btn btn-secondary fw-normal" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button disabled={updateStatus === "pending"} className="btn btn-primary fw-normal" onClick={onClickSave}>
                {updateStatus === "pending" ? <Spinner /> : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row g-0 justify-content-md-between">
      <div className="col-12 col-lg">
        <div className="row">
          <div className="col-12 col-lg">
            <p className="label">Name</p>
            <p className="label-subtext">Choose how your name appear to other members</p>
          </div>
          <div className="col-12 col-lg">
            <p className="fw-semibold">
              {userProfileInfo.first_name} {userProfileInfo.last_name}
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-2">
        <div className="text-lg-end">
          <p className="text-primary links" role="button" onClick={() => setEditMode(true)}>
            Edit
          </p>
        </div>
      </div>
    </div>
  );
};

export default Name;
