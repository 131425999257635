import Button from 'components/common/Button';
import Modal from 'components/common/Modal/Modal';
import PostContext from 'context/PostContext';
import { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ignorePost } from 'store/slices/postSlice';

const IgnorePostModal = ({ onClose, postId }) => {
  const { postData, updatePost, removePost } = useContext(PostContext);
  const [loading, setLoading] = useState()
  const dispatch = useDispatch();
  const handleConfirm = () => {
    setLoading(true)
    dispatch(ignorePost({ id: postData?.id })).then(() => {
      setLoading(false)
      removePost()
      onClose();
    }).catch(() => {
      setLoading(false)
    });
  }

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<h3>Not interested in this post?</h3>}
      action={
        <>
          {!loading ? <Button className={`btn btn-secondary`} onClick={onClose}>
            Cancel
          </Button> : ""}
          <Button disabled={loading} className="btn btn-primary" onClick={handleConfirm}>
            {loading ? <Spinner /> : "Ignore Post"}
          </Button>
        </>
      }
    >
      <p>
        We won't show you post's similar to this anymore.
      </p>
    </Modal>
  )
};

export default IgnorePostModal