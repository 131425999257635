import React from 'react';
import { MyBridgeAvatar, MyBridgeCompoanyAvatar } from '../Avatar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCompanyPageData } from 'store/actions/companyPages.Action';
import SectionLoader from '../Loader/sectionLoader';
const ResultComponent = ({ searchResults, setSearchQuery }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToProfile = (slug) => {
    setSearchQuery('');
    navigate(`/p/${slug}/introduction`);
  };

  const goToPage = (pageId, slug) => {
    const payload = {
      id: pageId,
    };
    dispatch(getCompanyPageData(payload)).then((res) => {
      if (res) {
        setSearchQuery('');
        navigate(`/c/${slug}/overview`);
      }
    });
  };

  const people = searchResults?.people?.slice(0, 3).map((item) => {
    return (
      <div className="search-result--container cursor-pointer" onClick={() => goToProfile(item.slug)}>
        <div className="search-result--profile">
          <MyBridgeAvatar size={40} name={`${item.first_name}${item.last_name}`} />
        </div>
        <div className="search-result--details">
          <h5>
            {item.first_name} {item.last_name}
          </h5>
          <p>{item.position}</p>
        </div>
      </div>
    );
  });

  const pages = searchResults?.pages?.slice(0, 2).map((item) => {
    return (
      <div className="search-result--container cursor-pointer" onClick={() => goToPage(item.id, item.slug)}>
        <div className="search-result--profile" style={{ width: '50px', height: '40px' }}>
          <MyBridgeCompoanyAvatar
            icon={item.image}
            name={item.name}
            size={45}
            className="company-avatar overflow-hidden"
            round={true}
          />
        </div>
        <div className="search-result--details">
          <h5>{item.name}</h5>
          <p>{`${item.main_industry ? 'Company - ' : ''} ${item.main_industry ? item.main_industry : ''} ${
            item.main_industry ? '|' : ''
          } ${item.state_region ? item.state_region : ''}`}</p>
        </div>
      </div>
    );
  });

  return (
    <>
      {(!people && !pages) && <SectionLoader /> }
      {people}
      {pages}
    </>
  );
};

export default ResultComponent;
