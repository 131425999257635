import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem } from 'store/slices/searchSlice';
import TeamCard from './TeamCard';
import { Row, Col } from 'react-bootstrap';
import FilterSection from 'components/common/FilterSection';
import SectionLoader from 'components/common/Loader/sectionLoader';
import { getGlobalIndustries } from 'store/slices/globalDataSlice';
import DefaultSearchPage from '../DefaultSearchPage';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';

const SearchTeam = () => {
  const dispatch = useDispatch();
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const results = item?.results;
  const [updatedFilters, setUpdatedFilters] = useState();
  const { globalIndustries } = useSelector((state) => state.globalData);

  const { query } = useContext(SearchContext);

  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'team' }));
    dispatch(getGlobalIndustries());
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'team' }));
  }, [dispatch, updatedFilters]);

  const keywordIndustry = globalIndustries?.map((size) => {
    return { label: size, name: size, value: size };
  });

  const locationFilterData = [...new Set(results?.map((item) => item.location))]
    .map((item) => {
      if (item) return { label: item, name: item, value: item };
      return null;
    })
    .filter((e) => e !== undefined);

  const sortByFilterData = [
    { label: 'Best Match', name: 'sort_by', value: 'top_match' },
    { label: 'Latest', name: 'sort_by', value: 'latest' },
  ];

  const filters = [
    { label: 'Location', type: 'checkbox', data: locationFilterData },
    { label: 'Industry', type: 'checkbox', data: keywordIndustry },
    { label: 'Sort by', type: 'radio', data: sortByFilterData },
  ];

  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);

  const teams =
    results?.length > 0 ? (
      results?.map((item) => {
        return (
          <Col xs={6} key={item.id}>
            <TeamCard {...item} isSearchResult={true} />
          </Col>
        );
      })
    ) : (
      <DefaultSearchPage />
    );

  return (
    <React.Fragment>
      <FilterSection filters={filters} filterData={filterData} />
      <h5 className="mb-4">Team</h5>
      {isItemsLoading ? (
        <SectionLoader />
      ) : (
        <>
          <Row>{teams}</Row>
        </>
      )}
    </React.Fragment>
  );
};

export default SearchTeam;
