import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MyBLogoHeader, Search } from 'components/common/Icons';
import ProfileNotFoundIllustrationSvg from '../../assets/profile-not-found-illustration-svg';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='page-not-found'>
      <MyBLogoHeader width={'94px'} height={'94px'} />
      <ProfileNotFoundIllustrationSvg />
      <p className='page-not-found__description'>The profile you’re looking for isn’t public or does’t exist.</p>
      <p className='page-not-found__description'>To search and filter new user click on below button.</p>
      <button className='btn btn-primary mt-4 d-flex gap-2 align-items-center px-3' onClick={() => navigate('/search')}>
        <Search width={16} height={16} color={'#ffffff'}/>
        Search
      </button>
    </div>
  );
};

export default NotFound;