import { DeleteIcon } from 'components/common/Icons';
import { Flex } from 'components/core';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const VideoPreview = ({ video, onRemove }) => {
  let src = null;
  if (typeof video === 'object') {
    src = video?.file;
  } else {
    src = URL.createObjectURL(video);
  }
  return (
    <Flex position="relative" width="100%" height="100%">
      {video && (
        <>
          <video src={src} style={{ width: '100%', height: 400, objectFit: 'cover' }} controls />
          <Flex
            position="absolute"
            top={'10px'}
            left={'10px'}
            width={100}
            padding="5px"
            onClick={() => onRemove()}
            style={{ cursor: 'pointer' }}
          >
            <DeleteIcon color="#fff" width={20} height={20} />
          </Flex>
        </>
      )}
    </Flex>
  );
};

VideoPreview.propTypes = {
  video: PropTypes.object,
  onRemove: PropTypes.func,
};

VideoPreview.defaultProps = {
  video: null,
  onRemove: () => {},
};

export default memo(VideoPreview);
