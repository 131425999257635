import classNames from 'classnames';
import { getUserFullName } from 'components/MyDisk/common/utils';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import {
    HandShakeSimpleIcon,
    Message
} from 'components/common/Icons';
import { Spinner } from 'components/common/Loader';
import React, { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFollowers, getInvitations, requestConnection, requestFollow, startConversationWithUser, unfollow, withdrawConnection } from 'store/slices/connectionSlice';



const NonOrganizerNetworkCard = (props) => {
    const { user, isSearchResult, type, isOrganizer } = props;
    const { teamId } = useSelector((state) => state.teamsCommunity);
    const { invites, follow, followRequestLoading, loading: hrLoading, loading } = useSelector((state) => state.connection);
    const location = useLocation();
    const [requestFor, setRequestFor] = useState()
    const dispatch = useDispatch();

    const [textYou, setTextYou] = useState(false);
    const dashboardRoute = location.pathname.includes('/');
    const userName = user?.first_name + ' ' + user?.last_name;
    const { userProfileInfo, } = useSelector((state) => state.userProfile);


    const handshakesRequested = useMemo(() => invites?.map?.(inv => ({ cid: inv?.id, uid: inv?.user_info?.id })) ?? [], [invites])
    const following = useMemo(() => follow?.map?.(f => f.id) ?? [], [follow])

    useEffect(() => {
        if (userProfileInfo.id === user.id) {
            setTextYou(true);
        }
    }, []);



    const sendHandshakeRequest = ({ id }) => {
        const body = {
            "to_user_id": id
        }
        setRequestFor(id);
        dispatch(requestConnection(body)).then((response) => {
            dispatch(getInvitations('sent'));
            setRequestFor(null);
        });
    }

    const withdrawHandshake = (user) => {
        const { id } = user ?? {}
        confirmAlert({
            title: "Withdraw handshake request?",
            message: "Are you sure you want to withdraw the handshakr request sent to " + (getUserFullName(user)),
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        const cid = handshakesRequested?.find(hs => hs.uid === id)?.cid
                        if (cid) {
                            const body = {
                                "connection_request_id": cid
                            }
                            setRequestFor(id);
                            dispatch(withdrawConnection(body)).then((response) => {
                                setRequestFor(null);
                                dispatch(getInvitations('sent'));
                            });
                        }
                    }
                },
                {
                    label: "No"
                },
            ]
        })

    }

    const sendFollowRequest = (id) => {
        const body = {
            "follow_to": id
        }
        setRequestFor(id);
        dispatch(requestFollow(body)).then(() => {
            setRequestFor(null)
            dispatch(getFollowers('following'));
        });
    }
    const unfollowRequest = (user) => {
        const { id } = user ?? {}
        confirmAlert({
            title: "Unfollow member?",
            message: "Are you sure you want to unfollow " + (getUserFullName(user)),
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        const body = {
                            "follow_to": id
                        }
                        setRequestFor(id);
                        dispatch(unfollow(body)).then(() => {
                            setRequestFor(null)
                            dispatch(getFollowers('following'));
                        });
                    }
                },
                {
                    label: "No"
                },
            ]
        })

    }

    const handleStartChat = () => {
        dispatch(startConversationWithUser({ ...(user ?? {}), user_uuid: user?.uuid }));
    };



    return (
        <>
            <div className={classNames('network-list-card', dashboardRoute ? 'small' : '', isSearchResult ? 'border-bottom-0' : '', "members-network-card")}>
                <div className='network-list-card--inner'>
                    <div className={dashboardRoute ? 'network-list-card--profile dashboard' : 'network-list-card--profile'}>
                        <MyBridgeAvatar size={dashboardRoute ? 40 : 60} name={userName} icon={user?.profile_pic} />
                    </div>
                    <div className='network-list-card--details'>
                        <h5>{userName}
                            {textYou && <span className="yourBadge"> &bull; You</span>}
                        </h5>
                        {/* <p>{user?.position ?? "Position: Not specified"}</p>
                        {!dashboardRoute && <p style={{ opacity: 0.5 }}>{user?.location ?? "No location specified"}</p>} */}
                        {userProfileInfo?.id === user?.id ? <></>
                            : <div className='non-org-actions-container'>
                                <Button className='btn-sm btn-secondary'
                                    onClick={() => handshakesRequested?.map?.(inv => inv.uid)?.includes(user?.id) ? withdrawHandshake(user) : sendHandshakeRequest(user)}
                                    disabled={(requestFor === user?.id && hrLoading)}>
                                    {requestFor === user?.id && (hrLoading || loading) ? <Spinner /> : <>
                                        <HandShakeSimpleIcon height={16} width={16} /> &nbsp;
                                        {handshakesRequested?.map?.(inv => inv.uid)?.includes(user?.id) ? "Withdraw" : "Handshake"}
                                    </>}

                                </Button>
                                <Button onClick={handleStartChat} className='btn-sm btn-secondary'><Message height={16} width={16} /> Message</Button>
                                <Button className='btn-sm' onClick={() => following?.includes?.(user?.id) ? unfollowRequest(user) : sendFollowRequest(user?.id)} disabled={requestFor === user?.id && followRequestLoading}>
                                    {requestFor === user?.id && followRequestLoading ? <Spinner /> : following?.includes?.(user?.id) ? "Unfollow" : "Follow"}
                                </Button>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default NonOrganizerNetworkCard