import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchContext from 'context/searchContext';
import { searchItem } from 'store/slices/searchSlice';
import NetworkCard from 'pages/Dashboard/Circle/NetworkCard';
import SectionLoader from 'components/common/Loader/sectionLoader';
import FilterSection from 'components/common/FilterSection';
import { Col, Row } from 'react-bootstrap';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import DefaultSearchPage from '../DefaultSearchPage';

const SearchPeople = () => {
  const dispatch = useDispatch();
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const results = item?.results;
  const { data: connectionData } = useSelector((state) => state.connection);
  const { query } = useContext(SearchContext);
  const [updatedFilters, setUpdatedFilters] = useState();

  useEffect(() => {
    if (updatedFilters) {
      dispatch(
        searchItem({ query: `&${queryGeneratorForFilters(updatedFilters)}`, item: 'people', searchQuery: query }),
      );
    }
  }, [dispatch, query, updatedFilters]);

  useEffect(() => {
    dispatch(searchItem({ query: query, item: 'people' }));
  }, [dispatch, query]);

  const connectionsFilterData = [
    { label: '1st Degree', name: '1st_degree', value: '1st_degree' },
    { label: '2nd Degree', name: '2nd_degree', value: '2nd_degree' },
    { label: '3rd Degree', name: '3rd_degree', value: '3rd_degree' },
  ];

  const locationFilterData = [...new Set(results?.map((item) => item.location))]
    .map((item) => {
      if (item) return { label: item, name: item, value: item };
      return null
    })
    .filter((e) => e !== undefined);

  const companyFilterData = [
    ...new Set(results?.map((item) => item?.experiences?.map((company) => 
    {
      if(company?.currently_working) return company?.company_name
      return null
    }))?.flat().filter((e) => e !== null))
  ]
    .map((item) => {
      if (item) return { label: item, name: item, value: item };
      return null
    })
    .filter((e) => e !== undefined);

  const filters = [
    { label: 'Connections', type: 'checkbox', data: connectionsFilterData, showKeywords: false },
    {
      label: 'Locations',
      type: 'checkbox',
      data: locationFilterData,
      showInput: true,
      showKeywords: false,
    },
    {
      label: 'Current company',
      type: 'checkbox',
      data: companyFilterData,
      showInput: true,
      showKeywords: false,
    },
    { label: 'Keyword', type: '', data: null, showInput: false, showKeywords: true },
  ];

  const people =
    results?.length > 0 ? (
      results?.map((item) => {
        return (
          <Col xs={6} key={item.id}>
            <NetworkCard
              connection={connectionData?.results?.map((e) => e.id).includes(item.id)}
              isSearchResult={true}
              user={item}
            />
          </Col>
        );
      })
    ) : (
      <DefaultSearchPage />
    );
    
  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedData = groupData(filters);
    setUpdatedFilters(groupedData);
  }, []);
  
  return (
    <React.Fragment>
      <FilterSection filters={filters} filterData={filterData} />
      <h5 className="mb-4">People</h5>
      {isItemsLoading ? (
        <SectionLoader />
      ) : (
        <>
          <Row>{people}</Row>
        </>
      )}
    </React.Fragment>
  );
};

export default SearchPeople;
