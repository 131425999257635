import { Documents, FolderIcon, Music, Pictures, Videos } from "components/common/Icons"
import moment from "moment"

/**
 * Returns the name based on type : 
 * file, folder, root directory or team disk
 * @param {*} item 
 * @returns 
 */
export const getFileFolderNameByType = (item) => {
    switch (item?.type) {
        case "RD": {
            return item?.team_info?.name ? item?.team_info?.name : item?.team_name ? item?.team_name : item?.name;
        };
        case "TD": {
            return item?.team_info?.name ? item?.team_info?.name : item?.team_name ? item?.team_name : item?.name;
        };
        case "D": {
            return item?.name;
        };
        case "F": {
            return item?.name;
        };
        default: {
            return item?.name;
        }
    }
}

/**
 * Returns header title based on active tab type
 * @returns 
 */
export const getActiveTabHeading = (activeTab) => {
    const tabs = ["", "My Disk", "Shared Disks", "Team Disk", "Team Disks"]
    return tabs[activeTab]
}

/**
 * Returns true if item is directory type
 * @param {*} item 
 * @returns 
 */
export const isDir = (item) => {
    return ["RD", "TD", "D"].includes(item?.type)
}

/**
 * Returns true if item is either team disk or root dir
 * @param {*} item 
 * @returns 
 */
export const isRootDir = (item) => {
    return ["RD", "TD"].includes(item?.type)
}

/**
 * Returns true if item is either a simple file/directory
 * @param {*} item 
 * @returns 
 */
export const isChildFileOrDir = (item) => {
    return ["F", "D"].includes(item?.type)
}

/**
 * format date in common format 
 * @param {*} date 
 * @returns 
 */
export const formatDate = (date, format) => moment(date).format(format ?? 'MMM DD, YYYY hh:mm A')

/**
 * Calculates and returns human readbale size (B, KB, GB, TB ...)
 * @param {*} inBytes 
 * @param {*} si 
 * @param {*} dp 
 * @returns 
 */
export function humanFileSize(inBytes, si = false, dp = 1) {
    let bytes = inBytes;
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' MB';
    }

    const units = si
        ? ['KB','MB','GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB','MiB','GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export const getUserFullName = (user) => {
    return (user?.first_name ?? "") + (user?.first_name && user?.last_name ? " " : "") + (user?.last_name ?? "")
}




/**
 * Get the grid icon for top level items in disk listing area
 * @param {*} item 
 * @returns 
 */
export const getGridIcon = (item) => {
    return item.name === 'documents' ? (
        <span className="menu-icon">
            <Documents width={44} height={36} />
        </span>
    )
        : item.name === 'music' ? (
            <span className="menu-icon">
                <Music width={44} height={36} />
            </span>
        )
            : item.name === 'pictures' ? (
                <span className="menu-icon">
                    <Pictures width={44} height={36} />
                </span>
            )
                : item.name === 'movies' ? (
                    <span className="menu-icon">
                        <Videos width={44} height={36} />
                    </span>
                )
                    : <>
                        {item?.team_info?.image_logo ?
                            <img style={{ width: "42px", height: "42px" }} className='team-logo' src={item?.team_info?.image_logo} />
                            : <FolderIcon width={44} height={36} />
                        }

                    </>
}

/**
 * Get list icon for top level items in disk listing area
 * @param {*} item 
 * @returns 
 */
export const getListIcon = (item) => {
    return item.name === 'documents' ? (
        <span className="menu-icon">
            <Documents width={20} height={18} />
        </span>
    )
        : item.name === 'music' ? (
            <span className="menu-icon">
                <Music width={20} height={18} />
            </span>
        )
            : item.name === 'pictures' ? (
                <span className="menu-icon">
                    <Pictures width={20} height={18} />
                </span>
            )
                : item.name === 'movies' ? (
                    <span className="menu-icon">
                        <Videos width={20} height={18} />
                    </span>
                )
                    : <>
                        {item?.team_info?.image_logo ?
                            <img style={{ width: "20px", height: "20px" }} className='team-logo' src={item?.team_info?.image_logo} />
                            : <FolderIcon width={20} height={18} />
                        }

                    </>
}

export const hasAccessLevel = (item, level) => {
    return item?.access_type === level
}