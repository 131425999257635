import classNames from 'classnames';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import {
    Message,
    ThreeDotsIcon
} from 'components/common/Icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { startConversationWithUser } from 'store/slices/connectionSlice';
import { updateUserRole } from 'store/slices/teamCommunitySlice';
import MemberStatusModificationModal from './MemberStatusModificationModal';
import DiskAccessModificationModal from './DiskAccessModificationModal';

const NetworkCard = (props) => {
    const { user, userObject, isSearchResult, type, isOrganizer, allowMessage = false } = props;
    const { teamId, teamSlugPage } = useSelector((state) => state.teamsCommunity);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showMemberStatusModificationModal, setShowMemberStatusModificationModal] = useState(false)
    const [memberStatusModificationModalType, setMemberStatusModificationModalType] = useState(false)
    const [showDiskAccessModificationModal, setShowDiskAccessModificationModal] = useState(false)
    const [diskAccessModificationModalType, setDiskAccessModificationModalType] = useState(false)
    const [textYou, setTextYou] = useState(false);
    const dashboardRoute = location.pathname.includes('/');
    const [showMenu, setShowMenu] = useState(false)
    const userName = user?.first_name + ' ' + user?.last_name;
    const { userProfileInfo } = useSelector((state) => state.userProfile);

    const ref = useRef()
    useOnClickOutside(ref, () => setShowMenu(false))
    useEffect(() => {
        if (userProfileInfo.id === user.id) {
            setTextYou(true);
        }
    }, []);

    const getDiskStatusText = (userObject) => {
        return userObject?.disk_status === "NO" ? <span className="text-danger">No Disk Access</span> :
            userObject?.disk_status === "R" ? <span className="text-success">Read Disk Access</span> :
                userObject?.disk_status === "W" ? <span className="">Write Disk Access</span> : ""
    }

    const showMemberModificationModal = (type) => {
        setShowMemberStatusModificationModal(true)
        setMemberStatusModificationModalType(type)
    }

    const hideMemberModificationModal = () => {
        setShowMemberStatusModificationModal(false)
        setMemberStatusModificationModalType('')
    }

    const showDiskModificationModal = (type) => {
        setShowDiskAccessModificationModal(true)
        setDiskAccessModificationModalType(type)
    }

    const hideDiskModificationModal = () => {
        setShowDiskAccessModificationModal(false)
        setDiskAccessModificationModalType('')
    }

    const handleStartChat = () => {
        dispatch(startConversationWithUser({ ...(user ?? {}), user_uuid: user?.uuid }));
    };

    return (
        <>
            <div className={classNames('network-list-card', dashboardRoute ? 'small' : '', isSearchResult ? 'border-bottom-0' : '')}>
                <div className='network-list-card--inner'>
                    <div className={dashboardRoute ? 'network-list-card--profile dashboard' : 'network-list-card--profile'}>
                        <MyBridgeAvatar size={dashboardRoute ? 40 : 60} name={userName} icon={user?.profile_pic} />
                    </div>
                    <div className='network-list-card--details'>
                        <h5>{userName} {textYou && <span className="yourBadge"> &bull; You</span>}</h5>
                        {user?.position ? <p>{user?.position}</p> : ""}
                        {userObject?.disk_status ? <p>{getDiskStatusText(userObject)}</p> : ""}
                        {!dashboardRoute && <span>{user?.location}</span>}
                    </div>
                    {userProfileInfo?.id === user?.id ? <></> : <div className={`network-list-card--actions`}>
                        <>
                            {allowMessage ? <Button className='btn-secondary' onClick={handleStartChat}><Message height={16} width={16} /> Message</Button> : ""}
                            {isOrganizer ?
                                <>
                                    <span style={{ cursor: "pointer" }} onClick={() => setShowMenu(true)}><ThreeDotsIcon /></span>
                                    {showMenu ?
                                        <div ref={ref} className="actions-container">
                                            <Card className="actions-container--menu">
                                                <ul>
                                                    {type === 'member' ?
                                                        <>
                                                            <li onClick={() => showMemberModificationModal('make-organizer')}>
                                                                Make Organizer
                                                            </li>
                                                            <li onClick={() => showMemberModificationModal('remove-member')}>
                                                                Remove from team
                                                            </li>
                                                            <li onClick={() => showMemberModificationModal('block-member')}>
                                                                Block from team
                                                            </li>
                                                        </>
                                                        :
                                                        <li onClick={() => showMemberModificationModal('remove-organizer')}>
                                                            Remove Organizer
                                                        </li>
                                                    }

                                                    <li onClick={() => showDiskModificationModal('no-access')}>
                                                        No Disk Access
                                                    </li>
                                                    <li onClick={() => showDiskModificationModal('read-access')}>
                                                        View Access
                                                    </li>
                                                    <li onClick={() => showDiskModificationModal('write-access')}>
                                                        Write Access
                                                    </li>
                                                </ul>
                                            </Card>
                                            {/* <Card className="actions-container--menu">
                                                    <ul>
                                                        <li onClick={() => showMemberModificationModal('remove-organizer')}>
                                                            Remove Organizer
                                                        </li>

                                                    </ul>
                                                </Card> */}
                                            {/* } */}

                                        </div>
                                        : ""
                                    }
                                </> : ""}
                        </>



                    </div>
                    }
                </div>
            </div >
            {
                showMemberStatusModificationModal ? <MemberStatusModificationModal
                    onClose={() => hideMemberModificationModal()}
                    team={teamSlugPage}
                    user={user}
                    type={memberStatusModificationModalType}
                /> : ""
            }
            {
                showDiskAccessModificationModal ? <DiskAccessModificationModal
                    onClose={() => hideDiskModificationModal()}
                    team={teamSlugPage}
                    user={user}
                    type={diskAccessModificationModalType}
                /> : ""
            }
        </>
    )
}

export default NetworkCard