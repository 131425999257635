import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMeetings } from 'store/slices/calendarSlice';
import { HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import { useState } from 'react';
import { Layout } from 'components/Layouts';
import { ScheduleIcon, JoinMeetingIcon, EventIcon } from 'components/common/Icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { meetingsByDate, isAfterPresentTime } from 'components/utils';
import Loader from 'components/common/Loader';
import { getEventDetail, joinMeeting, startDefaultMeeting, joinAMeeting } from 'store/slices/meetingSlice';
import AvatarGroup from '@atlaskit/avatar-group';
import { MyBridgeAvatar } from 'components/common/Avatar';


// const colors = ['primary', 'secondary', 'info', 'success', 'danger', 'warning', 'tertiary'];

// const badge = (badgeText, index) => {
//   return badgeText === 0 ? (
//     ''
//   ) : (
//     <span class={`p-2 mx-1 bg-${colors[index % 6]} border border-light rounded-circle text-light`}>
//       <span>{!isNaN(badgeText) ? `+${badgeText}` : badgeText}</span>
//     </span>
//   );
// };

const avatarGroup = (attendees) => {
  const data = attendees?.map((attendee, i) => ({
    email: attendee.user_email,
    key: attendee.id,
    first_name: attendee?.first_name ,
    last_name:attendee?.last_name,
    name: attendee?.first_name + ' ' + attendee?.last_name,
    href: '#',
    src: attendee.attendee_details.profile_pic,
  }));

  return (
    <>
        {data && data?.length > 0 &&  
    (
    <AvatarGroup
          maxCount={8}
          appearance="grid"
          data={data}
          overrides={{
            Avatar: {
              render(Comp, props, ind) {
                return <MyBridgeAvatar
                  key={ind}
                  size={30}
                  icon={(props?.src?.includes("placeholder") ? undefined : props.src)}
                  name={props?.first_name + ' ' + props?.last_name}
                />
              }
            },
            AvatarGroupItem: {
              render: (Component, props, index) => {
                return (
                <div style={{display:'flex', alignItems:'center', padding:'10px'}}>
                  <MyBridgeAvatar 
                      size={30}
                      icon={(props?.avatar?.src?.includes("placeholder") ? undefined : props?.avatar?.src)}
                      name={props?.avatar?.first_name + ' ' + props?.avatar?.last_name}
                      key={index} 
                  />
                <span>{props?.avatar?.name}</span>
                </div>
                )

              },
            },
          }}
        />
      )}    
    </>
  )




  // return <AvatarGroup appearance="grid" maxCount={8} data={data} />;
};

const TabContent = (key) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [events, setUpcomingEvents] = useState([]);
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const handleEventClick = (id) => {
    dispatch(getEventDetail(id)).then((res) => {
      navigate('/profile/viewMeeting');
    });
  };

  const meetingDetailing = (day, events , index ) =>
    events && events?.length !== 0 ? (
      <div className="py-3 border-bottom">
        <div className="meetings__header">
          <p className="meetings__title">{day}</p>
        </div>
        {events?.map((event, index) => (
          <>
            <div className="meetings__details row my-4">
              <div className="col-2 border-end border-primary">
                <div className="meetings__time fs-6">{moment(event?.start_time).format('hh:mm A')}</div>
                <div className="meetings__time fs-6">{moment(event?.end_time).format('hh:mm A')}</div>
              </div>
              <div className={`meetings__links col-10 px-3 ${index !== 0 ? 'border-top' : ''}`}>
                <span
                  onClick={() => handleEventClick(event?.id)}
                  className="meetings__links--join text-primary fs-5 cursor-pointer"
                >
                  {event?.subject}
                </span>
                <div>
                  <span className="meetings__links--visit">
                    {event?.location !== 'builtin' ? event?.location_url : event?.location}
                  </span>
                </div>
                <div className="mt-2">
                  {avatarGroup(event?.attendees)}
                  {/* {event?.attendees
                    ?.slice(0, 8)
                    .map((person, indx) => badge(`${person?.first_name[0]}${person?.last_name[0]}`, indx))}
                  {badge(event?.attendees?.slice(8)?.length, 1)} */}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    ) : (
      <p className="py-2 px-3 text-center">No Upcoming Events.</p>
    );

  useEffect(() => {
    dispatch(getAllMeetings());
  }, []);

  const { loading: loadingCalendar, allCalendarMeeting } = useSelector((state) => state.calendar);

  useEffect(() => {
    if (!!allCalendarMeeting) {
      let sortedMeetings = meetingsByDate(
        allCalendarMeeting?.results
          ?.filter((event) => {
            const declinedAttendee = event?.attendees?.find(
              (m) => m?.user === userProfileInfo?.id && m?.status === 'DECLINED',
            );
            if (declinedAttendee?.status !== 'DECLINED' && isAfterPresentTime(declinedAttendee?.start_time)) {
              if (isAfterPresentTime(event?.start_time)) return event;
            }
          })
          ?.sort((a, b) => {
            return new Date(a.start_time) - new Date(b.start_time);
          }),
      );
      setUpcomingEvents(sortedMeetings);
    }
  }, [allCalendarMeeting]);

  const goToUrl = (page) => {
    navigate(`/profile/${page}`);
  };

  const handleJoinMeeting = (meetingNumber) => {
    const meetingCode = parseInt(Math.random().toFixed(6).replace('0.', ''));
    const meetingId = meetingNumber ? meetingNumber : parseInt(`6002${meetingCode}`);
    const meeting = {
      created_by: userProfileInfo,
      meetingId: meetingId,
      attendees: null,
      isWaiting: false,
    };
    if (meeting) dispatch(joinMeeting(meeting));
  };

  switch (key) {
    case 'upcoming-meetings':
      return !loadingCalendar && events ? (
        <div className="row w-50">
          <div className="d-flex justify-content-around">
            <div className="mx-3 my-1 text-center cursor-pointer" onClick={() => handleJoinMeeting()}>
              <EventIcon className="mx-5 my-2 d-block" />
              Meet Now
            </div>
            <div className="mx-3 text-center cursor-pointer" onClick={() => dispatch(joinAMeeting(true))}>
              <JoinMeetingIcon className="mx-5 my-2 d-block" />
              Join a Meeting
            </div>
            <div className="mx-3 text-center cursor-pointer" onClick={() => goToUrl('schedule')}>
              <ScheduleIcon className="mx-5 my-2 d-block" width="27" height="27" />
              Schedule a Meeting
            </div>
          </div>
          {events['Today']?.length ? meetingDetailing('Today', events['Today']) : ''}
          {events['Tomorrow']?.length ? meetingDetailing('Tomorrow', events['Tomorrow']) : ''}
          {Object.keys(events)?.map((meeting) => {
            if (meeting !== 'Today' && meeting !== 'Tomorrow') return meetingDetailing(meeting, events[meeting]);
          })}
        </div>
      ) : (
        <>
          <Loader secondary />
        </>
      );
    case 'recordings':
      return <div className="d-flex h-50 justify-content-center align-items-center">Coming Soon</div>;
    default:
      break;
  }
};

const CalendarListing = () => {
  const [openTab, setOpenTab] = useState('upcoming-meetings');

  return (
    <React.Fragment>
      <section className="primary-layout py-5">
        <div className="container bordered-container py-3 h-auto">
          <div className="user__tabs border-bottom">
            <HorizontalTabs className="user_tabs_item">
              <Tab setOpenTab={setOpenTab} openTab={openTab} to="upcoming-meetings">
                Upcoming Meetings
              </Tab>
              {/* <Tab setOpenTab={setOpenTab} openTab={openTab} to="recordings">
                Recordings
              </Tab> */}
            </HorizontalTabs>
          </div>
          <div className="d-flex justify-content-center py-3">{TabContent(openTab)}</div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CalendarListing;
