import { MyBridgeAvatar } from 'components/common/Avatar';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editComment, fetchComments, postComment } from 'store/slices/commentSlice';

import { getUserFullName } from 'components/MyDisk/common/utils';
import EmojiPicker from 'components/common/EmojiPicker';
import FilePicker from 'components/common/FilePicker';
import GiphyPicker from 'components/common/GiphyPicker';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import Img from './components/Img';
import PostContext from 'context/PostContext';
import { Spinner } from 'components/common/Loader';
import { getFileExtension, imageLinkToFileObject } from 'components/utils';
import { Link } from 'react-router-dom';
import VideoPlayer from '../PostBody/VideoPlayer';
import Vid from './components/vid';

const CommentBox = forwardRef(({ comment, author, noGif = false, noVideo = false, name, id, icon, className, parentCommentId, commentId, commentText = '', setShowEditCommentBox, setShowCommentBox }, fref) => {
  const cref = useRef();
  const inputRef = fref ?? cref;
  const ref = React.useRef();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [text, setText] = useState(commentText);
  const [pics, setPics] = useState([]);
  const [gif, setGif] = useState([]);
  const [video, setVideo] = useState();
  const [showGiphy, setShowGiphy] = useState(false);
  const { updatePost } = useContext(PostContext) ?? {}
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [loading, setLoading] = useState()
  const showImgaeUpload = pics.length || gif.length || video;

  const postIsValid = text.trim().length !== 0 || pics.length !== 0 || gif.length !== 0;

  useOnClickOutside(ref, () => {
    setShow(false);
    setShowGiphy(false);
  });

  useEffect(() => {
    console.log(comment);
    if (comment?.media?.length) {
      const isVid = getFileExtension(comment?.media?.[0]?.file)?.toLowerCase() === ".mp4"
      if (isVid) {
        setVideo([comment?.media?.[0]?.file])
      } else {
        setPics([comment?.media?.[0]?.file])
      }
    }
    if (comment?.gif) {
      setGif([comment?.gif])
    }
  }, [comment])

  const insertAtPos = (value) => {
    const { current: ref } = inputRef;
    let startPos = ref.selectionStart;
    let endPos = ref.selectionEnd;
    setText(ref.value.substring(0, startPos) + value.native + ref.value.substring(endPos, ref.value.length));
  };

  const onGifClick = (image) => {
    console.log(image);
    // e.preventDefault();
    setGif([...gif, image]);
    showGiphy(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    if (text.length) {
      formData.append('body', text);
    }
    if (pics?.length || gif?.length) {
      formData.append('body', text?.length > 0 ? text : "‏‏‎ ‎");
    }


    if (pics.length > 0) {
      formData.append('media', pics[0]);
    } else {
      if (video) {
        formData.append('media', video);
      } else {
        formData.append('media', ["null"]);
      }
    }


    if (gif.length > 0) {
      // const gifFile = await imageLinkToFileObject(gif[0].images.original.url, {
      //   type: "image/gif",
      //   fileName: "simple.gif"
      // })
      // console.log(gifFile)
      // formData.append('media', gifFile);
      formData.append('gif', gif?.[0]?.images?.original?.url ?? gif?.[0]);
    } else {
      formData.append('gif', 'null');
    }

    const payload = {
      formData,
      id,
    };

    console.log(formData?.values())

    setLoading(true);
    if (commentId) {
      // edit flow
      payload.id = commentId;
      if (pics.length) {
        formData.append('media_updated', true);
      }
      dispatch(editComment(payload)).then(() => {
        setShowCommentBox?.(null);
        setShowEditCommentBox?.(null);
        dispatch(fetchComments({ id }));
        updatePost?.();
        setLoading(false);
        clearForm();
      }).catch(() => {
        setLoading(false);
      });
    } else {
      // create flow
      if (parentCommentId) {
        formData.append('parent_comment', parentCommentId);
      }
      if (postIsValid) {
        dispatch(postComment(payload)).then(() => {
          setShowCommentBox?.(null);
          setShowEditCommentBox?.(null);
          dispatch(fetchComments({ id }));
          updatePost?.();
          setLoading(false);
          clearForm();
        }).catch(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }

  };

  const clearForm = () => {
    setText('');
    setPics([]);
    setGif([]);
  }

  const handleShowGiphy = () => {
    setShow(false);
    setShowGiphy((prev) => !prev);
  };

  const handleInputOnKeyUp = (e) => {
    e.stopPropagation();
    if (e.keyCode === 27) {
      setShowCommentBox?.(null);
      setShowEditCommentBox?.(null);
    }
  }

  const onFileSelect = (event) => {
    const files = event.target.files;
    const videoFile = Array.from(files).find((file) => file?.type?.includes('video'));
    const filterImages = Array.from(pics).filter((file) => file?.type?.includes('image'));
    if (videoFile) {
      if (filterImages.length === 0) {
        console.log(videoFile)
        setVideo(videoFile);
      }
    } else {
      setPics([...files, ...pics]);
    }
    console.log("files", files)
  };

  console.log()

  return (
    <div className={`comment-box ${className ?? ""}`} ref={ref} style={{ width: '100%' }}>
      <div className="icon">
        <MyBridgeAvatar icon={icon ?? userProfileInfo?.profile_pic} name={name ?? getUserFullName(userProfileInfo)} />
      </div>
      <div className="content">
        <form onSubmit={handleSubmit}>
          <input
            onKeyDown={handleInputOnKeyUp}
            className={showImgaeUpload ? 'add-more-space' : null}
            type="text"
            onChange={(e) => setText(e.target.value)}
            name="comment"
            onFocus={() => {
              setShow(false);
              setShowGiphy(false);
            }}
            disabled={loading}
            placeholder="What's on your mind?"
            ref={inputRef}
            value={text}
          />
        </form>
        <ul>
          {loading ? <>
            <li className="p-1">
              <Spinner />
            </li>
          </> : <>
            {!showImgaeUpload && (
              <>
                <li >
                  <FilePicker
                    onClick={() => {
                      setShow(false);
                      setShowGiphy(false);
                    }}
                    onSelect={onFileSelect}
                    // imageDisable={postHelper?.imageDisable}
                    videoDisable={noVideo}
                    accept={`image/png,image/jpg,image/jpeg${noVideo ? "" : ",video/mp4,video/mov,video/avi,video/wmv"}`}
                  // onChange={(pics) => setPics(pics)}
                  />
                </li>
                {noGif ? "" : <li className="position-relative">
                  <GiphyPicker onSelect={onGifClick} />
                </li>}
              </>
            )}
            <li className="position-relative">
              <EmojiPicker show={show} setShowGiphy={setShowGiphy} onSelect={insertAtPos} setShow={setShow} />
            </li>
          </>}
          {text?.length || pics?.length || gif?.length || video ? <>
            {loading ? "" : <Link onClick={handleSubmit} style={{ paddingRight: "0.5rem" }}>
              Send
            </Link>}
          </> : <></>}
        </ul>
        {pics.length > 0 && (
          <div className="mt-2">
            {pics.map((picFile, index) => (
              <Img
                key={index}
                index={index}
                file={picFile}
                onRemove={(rmIndx) => setPics(pics.filter((pic, index) => index !== rmIndx))}
              />
            ))}
          </div>
        )}
        {video ? (
          <div className="mt-2">
            <Vid
              file={video}
              onRemove={() => setVideo(undefined)}
            />
          </div>
        ) : ""}
        {gif.length > 0 && (
          <div className="mt-2">
            {gif.map((picFile, index) => (
              <Img
                gif
                key={index}
                index={index}
                file={picFile?.images?.original?.url ?? picFile}
                onRemove={(rmIndx) => setGif(gif.filter((pic, index) => index !== rmIndx))}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default CommentBox;
