import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Masonry from 'react-responsive-masonry';
import PostFormContext from 'context/PostFromContext';
import { Devider, Flex, Select } from 'components/core';
import { POST_ACTIONS, POST_REPLY_ACTIONS, COMPANY_POST_REPLY_ACTIONS } from 'components/PostCard/constant';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { BlogIcon, DeleteIcon, EventSecondaryIcon, UserIcon } from 'components/common/Icons';
import EmojiPicker from 'components/common/EmojiPicker';
import FilePicker from 'components/common/FilePicker';
import GiphyPicker from 'components/common/GiphyPicker';
import EventPost from 'pages/Dashboard/Feed/Event';
import PostActionTile from './PosActionTiles';
import PostSelectedImage from './PostSelectedImage';
import PostInput from './PostInput';
import VideoPreview from './VideoPreview';
import PostButton from './PostButton';
import { isImageURL } from 'components/utils';
import TagPeopleModal from 'pages/Dashboard/Feed/TagPeopleModal';
import { getUserFullName } from 'components/MyDisk/common/utils';
import { PostTaggedAction } from './PostTaggedAction';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => props.padding ?? '20px 16px'};
  background: #ffffff;
  border: ${(props) => props?.border ?? `1px solid #e4e6eb`};
  border-radius: 8px;
`;

const PostForm = ({ repostData, repostSuccess, editable, type, setShowModal, isModal, containerStyle }) => {
  const inputRef = useRef(null);
  const [postText, setPostText] = useState('');
  const [visibility, setVisibility] = useState(0);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [replyAudience, setReplyAudience] = useState(0);
  const navigate = useNavigate();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const [showTagModal, setShowTagModal] = useState(false);
  const [tagged, setTagged] = useState([]);
  const [formActive, setFormActive] = useState(false);
  const USER_NAME =
    companyPageDetail !== undefined && type === 'company'
      ? companyPageDetail.name
      : userProfileInfo?.first_name + ' ' + userProfileInfo?.last_name;

  const removeImage = useCallback(
    (index) => {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
    },
    [images],
  );
  // console.log(type);
  // console.log(companyPageDetail);
  useEffect(() => {
    if (repostData !== null && editable) {
      setPostText(repostData.content);
      const { gif } = repostData;
      const onlyImages = repostData?.media?.filter((media) => isImageURL(media?.file));
      const findVideo = repostData?.media?.find((file) => file?.file?.includes('.mp4'));
      if (gif) {
        onlyImages.push({ type: 'gif', file: gif, id: Math.random() });
      }
      setImages((prev) => [...prev, ...(onlyImages ?? [])]);
      setVisibility(repostData.visibility);
      setReplyAudience(repostData.reply_control);
      setTagged(repostData?.tagged_users ?? []);
      if (findVideo) {
        setVideo(findVideo);
      }
    }
  }, [editable]);

  const getColumnCount = () => {
    switch (images.length) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 2;
      default:
        return 3;
    }
  };

  const insertAtPos = (value) => {
    const { current: ref } = inputRef;
    let startPos = ref.selectionStart;
    let endPos = ref.selectionEnd;
    setPostText(ref.value.substring(0, startPos) + value.native + ref.value.substring(endPos, ref.value.length));
  };

  const onGifClick = (image, e) => {
    e.preventDefault();
    setImages([image, ...images]);
  };

  const onFileSelect = (event) => {
    const files = event.target.files;
    const videoFile = Array.from(files).find((file) => file?.type?.includes('video'));
    const filterImages = Array.from(images).filter((file) => file?.type?.includes('image'));
    if (videoFile) {
      if (video === null && filterImages.length === 0) {
        setVideo(videoFile);
      }
    } else {
      setImages([...files, ...images]);
    }
  };

  const postHelper = usePosthelper(images, video, repostData, editable);

  const onVideoRemove = useCallback(() => {
    setVideo(null);
  }, []);

  const value = useMemo(
    () => ({
      postText,
      setPostText,
      images,
      setImages,
      removeImage,
      inputRef,
      postHelper,
      video,
      setVideo,
      visibility,
      replyAudience,
      repostData,
      repostSuccess,
      editable,
      tagged,
      setTagged,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images, removeImage, postHelper, video, visibility, replyAudience, repostData, postText, tagged, setTagged],
  );

  return (
    <PostFormContext.Provider value={value}>
      <Container {...(containerStyle ?? {})}>
        <Flex>
          <Flex>
            <MyBridgeAvatar
              size={40}
              icon={
                companyPageDetail !== undefined && type === 'company'
                  ? companyPageDetail?.image
                  : userProfileInfo?.profile_pic
              }
              name={USER_NAME}
            />
          </Flex>
          <Flex direction="column" flex={10}>
            <PostInput
              onFocus={e => setFormActive(true)}
              style={{ height: formActive ? "auto" : "20px" }}
            />
            {isModal || formActive ? <>
              {images?.length ? <>
                <Masonry
                  columnsCount={getColumnCount()} style={{ padding: '8px 0px 8px 0px' }}>
                  {images.map((image, index) => {
                    return <PostSelectedImage file={image} key={index} index={index} />;
                  })}
                </Masonry>
              </> : ""}
              {video ?
                <VideoPreview video={video} onRemove={onVideoRemove} />
                : ""
              }
              <PostTaggedAction
                tagged={tagged}
                setShowTagModal={setShowTagModal}
              />
              {type !== 'company' && (
                <div className='pt-1'>
                  <Select
                    style={{ padding: "5px 10px" }}
                    value={visibility}
                    options={POST_ACTIONS}
                    onChange={(option) => setVisibility(option.key)}
                    title="Choose audience"
                  />
                </div>
              )}
            </> : ""}
          </Flex>
        </Flex>
        {isModal || formActive ?
          <>
            {repostData && !editable && <EventPost data={repostData} repost />}

            <Devider margin="10px 0px" />
            <Flex justify="space-between" align="center">
              <Flex width="auto" flex="1">
                <PostActionTile
                  component={
                    <FilePicker
                      onSelect={onFileSelect}
                      imageDisable={postHelper?.imageDisable}
                      videoDisable={postHelper?.videoDisable}
                      accept="image/png,image/jpg,image/jpeg,video/mp4,video/mov,video/avi,video/wmv"
                    />
                  }
                  toolTipTitle="Add photo or video"
                  toolTipPosition="top-right"
                />
                <PostActionTile
                  component={<GiphyPicker onSelect={onGifClick} disabled={postHelper?.gifDisable} />}
                  toolTipTitle="Add gif"
                />
                <PostActionTile component={<EmojiPicker onSelect={insertAtPos} />} toolTipTitle="Add emoji" />
                {/* <PostActionTile
              component={<EventSecondaryIcon onClick={() => navigate('/event')} />}
              toolTipTitle="Add event"
            />
            <PostActionTile component={<BlogIcon onClick={() => navigate('/blog/new')} />} toolTipTitle="Add blog" /> */}
              </Flex>
              <Flex width="auto" flex="auto" justify="flex-end">
                <Select
                  style={{ border: 'none' }}
                  value={replyAudience}
                  options={type !== 'company' ? POST_REPLY_ACTIONS : COMPANY_POST_REPLY_ACTIONS}
                  onChange={(option) => setReplyAudience(option.key)}
                  title="Who can reply?"
                  subTitle="Choose who can reply to this post"
                />
                <PostButton setShowModal={setShowModal} type={type} />
              </Flex>
            </Flex>
          </>
          : (<></>)}
      </Container>
      {showTagModal ? <TagPeopleModal onClose={() => setShowTagModal(false)} /> : ''}
    </PostFormContext.Provider>
  );
};

PostForm.propTypes = {
  repostData: PropTypes.object,
  repostSuccess: PropTypes.func,
  editable: PropTypes.bool,
  type: PropTypes.string,
};
PostForm.defaultProps = {
  repostData: null,
  repostSuccess: () => { },
  editable: false,
  type: 'dashboard',
};
export default PostForm;

/**
 *
 * @param {array} images
 * @param {object} video
 * @param {object} repostData
 * @returns  {object} postHelper
 *
 */

function usePosthelper(images, video, repostData, editable) {
  const postHelper = useMemo(() => {
    let gifDisable = false;
    let imageDisable = false;
    let textDisable = false;
    let videoDisable = false;
    let blogDisable = false;

    const findGif = images.find((image) => image?.type === 'gif');
    if (video !== null || findGif) {
      gifDisable = true;
      imageDisable = true;
      textDisable = true;
      videoDisable = true;
      blogDisable = true;
    }

    const isImage = images.find((image) => image?.type?.includes('image'));
    if (isImage) {
      gifDisable = true;
      imageDisable = false;
      textDisable = true;
      videoDisable = true;
      blogDisable = true;
    }

    if (repostData !== null) {
      gifDisable = true;
      if (editable && findGif === undefined) {
        gifDisable = false;
      }
      imageDisable = editable ? false : true;
      textDisable = true;
      videoDisable = true;
      blogDisable = true;
    }

    return { gifDisable, imageDisable, textDisable, videoDisable, blogDisable };
  }, [images, video]);

  return postHelper;
}
