import React from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetProfileSections } from '../../../hooks';

import './AddSections.scss';
const AddSection = ({ onAddSection }) => {
  const { tid } = useParams();
  const [createParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    showProfileIntroductionButton,
    showExperienceButton,
    showSkillButton,
    showEducationButton,
    showMilestoneButton,
    showCertificationButton,
    showTestimonialsButton,
  } = useGetProfileSections();

  const showAddPanel =
    showProfileIntroductionButton ||
    showExperienceButton ||
    showSkillButton ||
    showEducationButton ||
    showMilestoneButton ||
    showCertificationButton ||
    showTestimonialsButton;

  const profileSections = [
    {
      title: 'Add Introduction',
      path: 'introduction',
      show: showProfileIntroductionButton,
    },
    {
      title: 'Add Experience',
      path: 'experience',
      show: showExperienceButton,
    },
    {
      title: 'Add Skill',
      path: 'skills',
      show: showSkillButton,
    },
    {
      title: 'Add Education',
      path: 'education',
      show: showEducationButton,
    },
    {
      title: 'Add Milestones',
      path: 'milestones',
      show: showMilestoneButton,
    },
    {
      title: 'Certifications and Awards',
      path: 'certificates-and-awards',
      show: showCertificationButton,
    },
    {
      title: 'Testimonial',
      path: 'testimonial',
      show: showTestimonialsButton,
    },
  ];

  function handleAddSection(section) {
    navigate({
      pathname: `/profile/my-profile/${section.path}`,
      search: createSearchParams({
        create: 'true',
      }).toString(),
    });
    onAddSection(section);
  }

  const buttons = profileSections
    .filter((b) => b.show && !(tid === b.path && createParams.get('create')))
    .map((b) => {
      return (
        <button key={b.path} className="add_profile_sections__button" type="button" onClick={() => handleAddSection(b)}>
          {b.title}
        </button>
      );
    });

  return (
    showAddPanel && (
      <div className="add_profile_sections bordered-container">
        <h4 className="text-capitalize fw-bold m-0 section_title">Add profile section</h4>
        <div className="add_profile_sections__divider"></div>
        <div className="add_profile_sections__buttons">{buttons}</div>
      </div>
    )
  );
};

export default AddSection;
