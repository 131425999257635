import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AddCircle } from 'components/common/Icons';
import { HorizontalTabs, TabContent, Tab } from 'components/common/Tabs/Tabs';
import { BorderLessButton, Text } from 'components/StyledComponents';

import JobCard from 'components/JobCard';
import Candidates from './candidates';

import {
  getCompanyActiveJobs,
  getCompanyAllJobs,
  getCompanyCloseJobs,
  getCompanyDraftJobs,
} from 'store/actions/companyJobs.Actions';
import { Button } from 'react-bootstrap';
import noJobsfound from "assets/images/blank_jobs_image.svg";

const Jobs = () => {
  let { tid = 'all-jobs' } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);

  const { companyJobList, activeJobs, closedJobs, draftJobs, jobClosed } = useSelector((state) => state.companyJobsReducer);

  const [openTab, setOpenTab] = useState(tid || 'all-jobs');
  const blankPageText = `${companyPageDetail?.name} jobs will appear here.`;
  useEffect(() => {
    if (companyPageDetail?.id) {
      dispatch(getCompanyAllJobs(companyPageDetail.id));
      dispatch(getCompanyActiveJobs(companyPageDetail.id));
      dispatch(getCompanyCloseJobs(companyPageDetail.id));
      dispatch(getCompanyDraftJobs(companyPageDetail.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyPageDetail, jobClosed]);

  let path = `company/page/${companyPageDetail?.id}/jobs`;
  
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="bordered-container overflow-visible">
          {companyJobList?.count === 0 ? <div className="job-blank-container">
            <img src={noJobsfound} alt="" width="182" className='mb-3' />
            <h3>{blankPageText}</h3>
            <Button onClick={() => navigate('/create/company/job')} variant='white' className="btn-sm mb-3 border border-0">Post Jobs</Button>
          </div> : <><div className="d-flex align-items-center justify-content-between mx-3 pt-3">
            <HorizontalTabs className="job-tabs-container">
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={path} to="all-jobs">
                All Jobs
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={path} to="active-jobs">
                Active Jobs
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={path} to="draft-jobs">
                Drafts
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={path} to="closed-jobs">
                Close Jobs
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={path} to="candidates-jobs">
                Candidates
              </Tab>
            </HorizontalTabs>
            
            <Button onClick={() => navigate('/create/company/job')} variant='white' className="btn-sm mb-3 border border-0"><AddCircle color="#3d5a80" width={15} height={15} /> Post Jobs</Button>
          </div>

          <div>
            <TabContent tab="all-jobs" openTab={tid}>
              <div className="row">
                {companyJobList?.results?.length ? companyJobList?.results?.map((item, index) => (
                  <JobCard key={index} job={item} index={index} />
                )) : <div className="job-blank-container">
                <img src={noJobsfound} alt="" width="182" className='mb-3' />
                <h3>{blankPageText}</h3>
                <Button onClick={() => navigate('/create/company/job')} variant='white' className="btn-sm mb-3 border border-0">Post Jobs</Button>
              </div>}
              </div>
            </TabContent>
            <TabContent tab="active-jobs" openTab={tid}>
              <div className="row">
                {activeJobs?.results?.length ? activeJobs?.results?.map((item, index) => (
                  <JobCard key={index} index={index} job={item} />
                )) : <div className="job-blank-container">
                <img src={noJobsfound} alt="" width="182" className='mb-3' />
                <h3>{blankPageText}</h3>
                <Button onClick={() => navigate('/create/company/job')} variant='white' className="btn-sm mb-3 border border-0">Post Jobs</Button>
              </div>}
              </div>
            </TabContent>
            <TabContent tab="draft-jobs" openTab={tid}>
              <div className="row">
                {draftJobs?.results?.length ? draftJobs?.results?.map((item, index) => (
                  <JobCard key={index} index={index} job={item} />
                )) : <div className="job-blank-container">
                <img src={noJobsfound} alt="" width="182" className='mb-3' />
                <h3>{blankPageText}</h3>
                <Button onClick={() => navigate('/create/company/job')} variant='white' className="btn-sm mb-3 border border-0">Post Jobs</Button>
              </div>}
              </div>
            </TabContent>
            <TabContent tab="closed-jobs" openTab={tid}>
              <div className="row">
                {closedJobs?.results?.length ? closedJobs?.results?.map((item, index) => (
                  <JobCard key={index} index={index} job={item} />
                )) : <div className="job-blank-container">
                <img src={noJobsfound} alt="" width="182" className='mb-3' />
                <h3>{blankPageText}</h3>
                <Button onClick={() => navigate('/create/company/job')} variant='white' className="btn-sm mb-3 border border-0">Post Jobs</Button>
              </div>}
              </div>
            </TabContent>
            <TabContent tab="candidates-jobs" openTab={tid}>
              <Candidates />
            </TabContent>
          </div></>}
        </div>
      </div>
    </div>
  );
};
export default Jobs;
