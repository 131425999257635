import React from 'react';
import { useNavigate } from 'react-router-dom';
import Error404IllustrationSvg from 'assets/error-404-illustration-svg';
import { MyBLogoHeader } from 'components/common/Icons';

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div className="page-not-found">
        <MyBLogoHeader width={'94px'} height={'94px'} />
        <Error404IllustrationSvg />
        <h1 className='page-not-found__heading'>Page Not Found</h1>
        <p className='page-not-found__description'>Can’t seem to find the page you are looking for, try going back to the previous page</p>
        <button className="btn btn-primary mt-4" onClick={() => navigate('/')}>
          Let’s go Back on Home Page
        </button>
    </div>
  );
};

export default Error404;