import Modal from 'components/common/Modal/Modal';
import PostContext from 'context/PostContext';
import { useContext } from 'react';
import PostForm from 'components/PostForm';

const PostEditModal = ({ onClose }) => {
  const { postData } = useContext(PostContext);
  return (
    <Modal onClose={onClose} className="post-edit-modal" heading={<h3>Edit Post</h3>}>
      <PostForm
        containerStyle={{ border: "none", padding:"0" }}
        repostData={postData}
        editable
        repostSuccess={() => {
          onClose();
        }}
        isModal
      />
    </Modal>
  );
};

export default PostEditModal;
