import React, { useEffect } from 'react';
import { useQueries } from '@tanstack/react-query';
// import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserAPIEndpoints } from 'store/common/endPoint';
import API from 'store/common/api';
import { useDispatch } from 'react-redux';
import { setAllDegreeConnections } from 'store/slices/connectionSlice';

const ConnectionContainer = styled.div``;

const ConnectionDegreeContainer = styled.div`
  padding: ${(props) => (props?.isBorder ? '1rem' : '0')};
  border-bottom: ${(props) => (props?.isBorder ? '1px ' : '0px ')}solid #d9d9d9;
  box-shadow: ${(props) => (props?.isBorder ? '0 4px 6px -6px rgb(217, 217, 217)' : ' ')};
`;

const ConnectionTextContainer = styled.div`
  display: flex;
  gap: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #3d5a80;
  margin-bottom: ${(props) => props.pb || '10px'};
`;

const ConnectionCount = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #3d5a80;
`;

const ConnectionText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
`;

const UserConnections = (props) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const results = useQueries({
    queries: [
      {
        queryKey: ['degree1'],
        queryFn: () => API({ url: UserAPIEndpoints.GetDegreeCount(1) }),
      },
      {
        queryKey: ['degree2'],
        queryFn: () => API({ url: UserAPIEndpoints.GetDegreeCount(2) }),
      },
      {
        queryKey: ['degree3'],
        queryFn: () => API({ url: UserAPIEndpoints.GetDegreeCount(3) }),
      },
    ],
  });

  // const isLoading = results.some((result) => result.isLoading);
  const [firstDegResponse, secondDegResponse, thirdDegResponse] = results;

  const { count: firstDegreeCount } = firstDegResponse?.data || {count:0};
  const { count: secondDegreeCount } = secondDegResponse?.data || {count:0};
  const { count: thirdDegreeCount } = thirdDegResponse?.data || {count:0};

  return (
    <ConnectionContainer>
      <ConnectionDegreeContainer isBorder={!!firstDegreeCount || !!secondDegreeCount || !!thirdDegreeCount}>
        {!!firstDegreeCount && (
          <ConnectionTextContainer pb={'20px'}>
            <ConnectionCount>{firstDegreeCount}</ConnectionCount>
            <ConnectionText>1st Degree</ConnectionText>
          </ConnectionTextContainer>
        )}
        {!!secondDegreeCount && (
          <ConnectionTextContainer pb={'20px'}>
            <ConnectionCount>{secondDegreeCount}</ConnectionCount>
            <ConnectionText>2nd Degree</ConnectionText>
          </ConnectionTextContainer>
        )}
        {!!thirdDegreeCount && (
          <ConnectionTextContainer>
            <ConnectionCount>{thirdDegreeCount}</ConnectionCount>
            <ConnectionText>3rd Degree</ConnectionText>
          </ConnectionTextContainer>
        )}
      </ConnectionDegreeContainer>
    </ConnectionContainer>
  );
};
export default UserConnections;
