import React from 'react';
import Avatar from 'react-avatar';
import Button from 'components/common/Button';
import { Message, EventIcon, ScheduleIcon } from 'components/common/Icons';
import { MyBridgeAvatar } from 'components/common/Avatar';

const MutualUser = (props) => {
    const { userdetails } = props;
    const userName = `${ userdetails.first_name } ${ userdetails.last_name }`;
    
    return (
        <div className="mutual-user-card">
            <div className="mutual-user-card--inner">
                <div className='mutual-user-card--profile'>
                    {/* <Avatar name="Kamal Kant" size='70' round={true} /> */}
                    <MyBridgeAvatar size={70} src={userdetails.profile_pic} name={userName} />
                </div>
                <div className='mutual-user-card--details'>
                    <h5 className="card-name">{userdetails.first_name} {userdetails.last_name}</h5>
                    <p className="card-desc">{userdetails.position}</p>
                </div>
            </div>
            <div className='mutual-user-card--bottom'>
                <Button className="btn-sm" variant='btn-secondary'><Message width={14} /> Message</Button>
                <Button className="btn-sm" variant='btn-secondary'><EventIcon width={14} /> Meet Now</Button>
                <Button className="btn-sm" variant='btn-secondary'><ScheduleIcon width={14} /> Sehedule</Button>
            </div>
        </div>
    )
}

export default MutualUser