import React from 'react';
import { useState } from 'react';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import ReadMore from 'components/common/ReadMore';
import dollar from 'assets/Jobs/dollor.svg';

import { Text, VerticalLine } from 'components/StyledComponents';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import {
  ArrowUp2,
  Brifecase,
  ChevronDown,
  Industry,
  Candidates,
  EditJob,
  Eye,
  Email,
  SimpleCloseIcon,
} from 'components/common/Icons';

import { CURRENCY, EMPLOYMENT_CHOICES } from 'data/data';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { currencyFormatter, convertToSlug } from 'components/utils';
import {
  applyJob,
  getJobsDetail,
  saveJobs,
  editJobDetail,
  setRepostToEditPost,
} from 'store/actions/Job.Action';
import { getCandidates, setGlobalJobId, closeJob } from 'store/actions/companyJobs.Actions';

const JobCard = (props) => {
  const { job, index } = props;
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeMenu, setActiveMenu] = useState(null);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [filter, setFilter] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [limitPerPage, setLimitPerPage] = useState(50);

  const onActiveMenu = (index) => {
    setActiveMenu(index);
  };

  const closeMenu = () => {
    setActiveMenu(null);
  };

  const getcandidatesByJob = (id, name) => {
    setSelectedJob(id);
    dispatch(setGlobalJobId({ id: id, title: name }));
    navigate(`/company/page/${companyPageDetail.id}/jobs/candidates-jobs`);
  };

  const ref = React.useRef();
  useOnClickOutside(ref, () => setActiveMenu(null));

  const getJobDetails = (id) => {
    dispatch(getJobsDetail(id)).then((res) => {
      if (res) {
        navigate(`/jobs/${convertToSlug(job.title)}-${job.id}`);
      }
    });
  };

  const closeActiveJob = (id, status) => {
   const body = {
      "job_status": status,
      "id":id
  }
    dispatch(closeJob(body))
  };

  const editJobDetails = (id, type) => {
    dispatch(editJobDetail(id)).then((res) => {
      if (res) {
        if (type === 'repost') {
          dispatch(setRepostToEditPost(true));
        }
        navigate('/create/company/job');
      }
    });
  };

  const applySelectedJob = (jobId) => {
    const payload = {
      candidate_id: userProfileInfo?.id,
      jobs_id: jobId,
      status: 'APPLIED',
    };
    dispatch(applyJob(payload));
  };

  const saveJob = (jobId) => {
    const payload = {
      user_id: userProfileInfo?.id,
      job_id: jobId,
    };
    dispatch(saveJobs(payload));
  };

  function stripHTML(myString) {
    return myString?.replace(/(<([^>]+)>)/gi, '');
  }

  const getCurrencyIcon = (name) => {
    let job = CURRENCY.find((item) => item.value === name);
    return job?.symbol;
  };

  let jobColor = '#3D5A80';
  if (job.job_status === 'active') jobColor = '#037F29';
  else if (job.job_status === 'closed') jobColor = '#E41C19';
  return (
    <div className="job-card">
      <div className="job-header">
        <div className="company-logo">
          <MyBridgeAvatar
            icon={job?.hiring_company_details?.image}
            name={job?.hiring_company_details?.name}
            size={60}
            className="company-avatar"
            round={false}
          />
        </div>
        <div className="company-title-country">
          <Text fS="16px" fW="500" color="#5B5B5B">
            {job.title}
          </Text>
          <Text color="#868585">{job?.city ? `${job.city}, ${job.country}` : `${job.country}`}</Text>
        </div>
        <div className="company-actions">
          <Button onClick={activeMenu === index ? closeMenu : () => onActiveMenu(index)}>
            Manage <ChevronDown color="#FFFFFF" />
          </Button>
          {activeMenu === index && (
            <div className="action-menu" ref={ref}>
              <div className="action">
                <div className="icon">
                  <ArrowUp2 width={14} height={16} color="#868585" />
                </div>
                <Text color="#5B5B5B" onClick={() => editJobDetails(job?.id, 'repost')}>
                  {' '}
                  Re-post job
                </Text>
              </div>
              <div className="action" onClick={() => getcandidatesByJob(job?.id, job?.title)}>
                <div className="icon">
                  <Candidates color="#868585" />
                </div>
                <Text color="#5B5B5B"> View candidates({job.candidate_count})</Text>
              </div>
              <div className="action" onClick={() => editJobDetails(job?.id, 'edit')}>
                <div className="icon">
                  <EditJob color="#868585" />
                </div>
                <Text color="#5B5B5B"> Edit Job</Text>
              </div>
              <div className="action" onClick={() => getJobDetails(job?.id)}>
                <div className="icon">
                  <Eye width={18} height={12} color="#868585" />
                </div>
                <Text color="#5B5B5B">Preview job</Text>
              </div>
              <div className="action">
                <div className="icon">
                  <Email width={17} color="#868585" />
                </div>
                <Text color="#5B5B5B"> Email candidates</Text>
              </div>
              <div className="action" onClick={() => closeActiveJob(job?.id, job.job_status === 'closed' ? true : false)}>
                <div className="icon">
                {job.job_status === 'closed' ? <ArrowUp2 width={14} height={16} color="#868585" /> : <SimpleCloseIcon width={12} color="#868585" />}
                </div>
                <Text color="#5B5B5B">{job.job_status === 'closed' ? 'Activate Job' : 'Close Job'} </Text>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="job-body">
        <div className="job-payments">
          <img src={dollar} alt="" />
          <Text fS="13px" color="#868585">
            {/* {getCurrencyIcon(job.currency_choice)} */}
            {currencyFormatter(job.compensation_range_min_amt, job.currency_choice)}
          </Text>
          <Text fS="13px" color="#868585">
            to
          </Text>
          <Text fS="13px" color="#868585">
            {/* {getCurrencyIcon(job.currency_choice)} */}
            {currencyFormatter(job.compensation_range_max_amt, job.currency_choice)}
          </Text>
          <Text fS="13px" color="#868585">
            {capitalize(job.payment_choice)}
          </Text>
        </div>
        {job.benefits?.length > 0 && (
          <Text fS="13px" color="#868585">
            <Industry color="#868585" className="me-1" />
            {job.benefits.map((e) => e.name).join(', ')}
          </Text>
        )}
        <Text fS="13px" color="#868585" className="text-center">
          <Brifecase color="#868585" className="me-1" />
          {EMPLOYMENT_CHOICES.find((e) => e.value === job.employment_type).label}
        </Text>
      </div>

      <div className="job-description  overflow-hidden">
        <div className="single-line">{stripHTML(job?.job_description).slice(0, 140)}</div>{' '}
        <Link onClick={() => getJobDetails(job?.id)}>See More</Link>
        {/* <div>
          <Link>See More</Link>
        </div> */}
      </div>

      <div className="job-footer">
        <div className="d-flex gap-1">
          <Text fW="500" fS="16px" color="#5B5B5B">
            Job posted:
          </Text>
          <Text fS="16px" color="#868585">
            {job.days_posted} day(s) ago
          </Text>
        </div>
        <VerticalLine bColor="#3D5A80" />
        <div className="d-flex gap-1">
          <Text fW="500" fS="16px" color="#5B5B5B">
            Status:
          </Text>
          <Text fS="16px" color={jobColor}>
            {capitalize(job.job_status)}
          </Text>
        </div>
        <VerticalLine bColor="#3D5A80" />
        <div className="d-flex gap-1">
          <Text fW="500" fS="16px" color="#5B5B5B">
            Total View:
          </Text>
          <Text fS="16px" color="#868585">
            {job.total_views}
          </Text>
        </div>
        <VerticalLine bColor="#3D5A80" />
        <div className="d-flex gap-1">
          <Text fW="500" fS="16px" color="#5B5B5B">
            Candidate applied:
          </Text>
          <Text fS="16px" color="#868585">
            {job.candidate_count ? job.candidate_count : '0'}
          </Text>
        </div>
      </div>
    </div>
  );
};
export default JobCard;
