import {
  Documents,
  ExclamationIcon,
  FolderCircleIcon,
  FolderClosed,
  FolderIcon,
  GridView,
  ListView,
  Menu,
  Music,
  MyDiskIcon,
  Pictures,
  SharedDisksIcon,
  TeamDiskMemberIcon,
  Videos
} from 'components/common/Icons';
import ReactDOM from "react-dom"
import Modal from 'components/common/Modal/Modal';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ModalPopups from './ModalPopups';
import MyDisk from './MyDisk';
import SharedDisks from './SharedDisks';
import MyDiskHeader from './header';
// import { sharedDisk } from 'pages/Profile/constent'
// import avatar from 'assets/profiles/banner/avtar.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import SearchResult from './SearchResult';
import FolderMenu from './folderMenu';

import classNames from 'classnames';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { trimStr } from 'components/utils';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  clearFile,
  getDeleteFiles,
  getOccupiedDiskSize,
  getRootDir,
  getSharedDiskUsers,
  getSharedItemUsers,
  getTeamDiskDetails,
  getTeamFileNFolder,
  getUserSubscriptionDetailsbyUser,
  getfileNFolder,
  hideFilesUploadModal,
  initRootDirs,
  searchFileFolder,
  setOpenFolderItem,
  setSelectedFile,
  showFilesUploadModal
} from 'store/actions/myDisk.Action';
import { FileUploadModal } from './ModalPopups/FileUploadModal';
import TeamDisk from './TeamDisk';
import { getIconByExtension } from './common/iconsMap';
import { getActiveTabHeading, getFileFolderNameByType, getUserFullName, humanFileSize, isDir } from './common/utils';
import { Spinner } from 'components/common/Loader';
import { getAllConnectionsData } from 'store/slices/connectionSlice';


let tids = {
  searchTid: -1
}
const MyDiskMain = (props) => {
  const { diskType, teamId, team, teamOrganizers } = props;
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(diskType === "teamDisk" ? 3 : 1);
  const [activeSubTab, setActiveSubTab] = useState();
  const handleActiveSubTab = useCallback((index) => setActiveSubTab(index), []);
  const [viewType, setViewType] = useState('ListView');
  const handleViewType = useCallback((type) => setViewType(type), []);

  useEffect(() => {
    handleActiveTab(diskType === "teamDisk" ? 3 : 1)
    setActiveSubTab(null)
  }, [])

  const handleActiveTab = useCallback((label) => {
    dispatch(setOpenFolderItem(undefined))
    dispatch(setSelectedFile(undefined))
    setActiveTab(label)
  }, []);

  const {
    directory,
    folderCreated,
    fileFolderList,
    openFolderItem,
    deleteSeccess,
    deleteFolderSeccess,
    uploadSeccess,
    renameFilesSuccess,
    fileRestored,
    folderRestored,
    renameFolderSuccess,
    copyFilesSuccess,
    moveFilesSuccess,
    searchResult,
    selectedFile,
    shareUserList,
    teamDiskDetails,
    userSubscription,
    occupiedSize,
    filesUploadModalVisible,
    loading
  } = useSelector((state) => state.myDiskReducer);


  const [popUpType, setPopUpType] = useState(undefined);
  const [modalOpen, showPopup] = useState(false);
  const [popupHeader, setPopupHeader] = useState('');
  const [searchValue, setSearchValue] = useState(undefined);
  const [searchFilters, setSearchFilters] = useState({});
  const [folderId, setFolderId] = useState(null);
  const [currentPlan, setCurrentPlan] = useState();
  const [storage, setStorage] = useState();
  const [sharer, setSharer] = useState();
  const [initDone, setInitDone] = useState(false)
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(getAllConnectionsData())
  }, [])

  useEffect(() => {
    dispatch(getRootDir()).then(res => {
      if (!res.payload || res.payload?.length <= 0) {
        dispatch(initRootDirs()).then(() => {
          dispatch(getRootDir())
        })
      }
    })
  }, [])

  const handlePopups = (type) => {
    setPopUpType(type);
    if (type === 'BinOpen') {
      dispatch(getDeleteFiles({ is_deleted: true }));
    }
    showPopup((prevState) => !prevState);
    setPopupHeader(heading(type));
  };

  useEffect(() => {
    if (activeTab || activeSubTab) {
      setSearchValue("")
    }
  }, [activeTab, activeSubTab])

  const heading = (type) => {
    if (type === 'Create') {
      return <h5 className="modal-title">Create a new folder</h5>;
    }
    if (type === 'Rename') {
      return <h5 className="modal-title">Rename Folder or file </h5>;
    }
    if (type === 'Bin') {
      return <h5 className="modal-title">Delete {selectedFile ? selectedFile?.name : openFolderItem?.name}?</h5>;
    }
    if (type === 'RemoveMe') {
      return <h5 className="modal-title">Remove</h5>;
    }
    if (type === 'BinOpen') {
      return <h5 className="modal-title">Selected files will be restored</h5>;
    }
    if (type === 'Subscriber') {
      return <h5 className="modal-title">Change Subscriber</h5>;
    }
    if (type === 'Move') {
      return (
        <h5 className="modal-title">Move {selectedFile ? selectedFile?.name : openFolderItem?.name} here to....</h5>
      );
    }
    if (type === 'Copy') {
      return (
        <h5 className="modal-title">Copy {selectedFile ? selectedFile?.name : openFolderItem?.name} here to....</h5>
      );
    }
    if (type === 'Share') {
      return (
        <div className="d-flex align-items-start">
          {loading ? <>
            <Spinner />
          </> : <>
            {selectedFile?.type === "F" ? getIconByExtension(selectedFile, { width: 38, height: 38 }) : <FolderIcon height={38} width={38} />}
          </>}
          <div className="ms-2 ">
            <h5 className="modal-title lh-1">Share {selectedFile?.type === "F" ? "File" : "Folder"}</h5>
            <p className="small-font">{selectedFile ? (selectedFile?.name + (selectedFile?.extension ? ("." + selectedFile?.extension) : "")) : openFolderItem?.name}</p>
          </div>
        </div>
      );
    }
    if (type === 'Upload') {
      return <h5 className="modal-title">Upload Files</h5>;
    }
  };

  const toQueryStr = (json = {}) => {
    let str = "";
    Object.keys(json).forEach((key, ind) => {
      const val = json?.[key]
      if (Array.isArray(val)) {
        for (let iind in val) {
          const v = val[iind]
          str += `&${key}=${v}`
        }
      }
      else {
        str += `&${key}=${val}`
      }
    })
    return str?.substring(1)
  }

  const searchNow = (filters) => {
    clearTimeout(tids.searchTid)
    tids.searchTid = setTimeout(() => {
      const str = toQueryStr(filters)
      dispatch(searchFileFolder({
        filters,
        str
      }));
    }, 200)
  }

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value)
    if (!value || value.length <= 0) {
      setSearchFilters({})
      return;
    }
    let parent = openFolderItem ? openFolderItem.id : folderId;
    const filters = {
      ...(parent ? { parent } : {}),
      name: value,
    };
    handleSearchFiltersChange(filters)
  };

  const handleSearchFiltersChange = (filters) => {
    const nfilters = { ...(searchFilters ?? {}), ...(filters ?? {}) }
    console.log(nfilters)
    setSearchFilters(nfilters)
    searchNow(nfilters)
  }


  const [showMenu, onMenuClick] = useState(false);

  useEffect(() => {
    if (teamDiskDetails) {
      dispatch(getTeamFileNFolder({ parent: teamDiskDetails?.id }))
    }
  }, [
    teamDiskDetails
  ])

  useEffect(() => {
    if (userProfileInfo?.id) {
      dispatch(getUserSubscriptionDetailsbyUser(userProfileInfo?.id)).then(res => {
        const { payload } = res ?? {}
        setStorage(payload?.[0]?.disk_plan?.storage);
        setCurrentPlan(payload?.[0])
      });
    }
    dispatch(getOccupiedDiskSize());
    if (diskType === 'teamDisk') {
      dispatch(getTeamDiskDetails(teamId)).then(res => {

      })
    } else {
      dispatch(getRootDir());
      dispatch(getSharedDiskUsers());
    }
    // eslint-disable-next-line
  }, [diskType, teamId, userProfileInfo]);

  useEffect(() => {
    if (folderCreated || deleteFolderSeccess) {
      showPopup(false);
      dispatch(getfileNFolder({ parent: openFolderItem?.id }));
    }
    if (
      deleteSeccess ||
      renameFilesSuccess ||
      fileRestored ||
      folderRestored ||
      renameFolderSuccess ||
      copyFilesSuccess ||
      moveFilesSuccess
    ) {
      showPopup(false);
      if (sharer) {
        dispatch(getSharedItemUsers(activeSubTab?.id))
      } else {
        dispatch(getfileNFolder({ parent: openFolderItem && openFolderItem.id }))
      }
      dispatch(clearFile())
    }
    if (uploadSeccess) {
      dispatch(clearFile());
      dispatch(getfileNFolder({ parent: openFolderItem ? openFolderItem.id : folderId }));
    }

    // eslint-disable-next-line
  }, [
    deleteSeccess,
    uploadSeccess,
    deleteFolderSeccess,
    folderCreated,
    renameFilesSuccess,
    fileRestored,
    folderRestored,
    renameFolderSuccess,
    copyFilesSuccess,
    moveFilesSuccess,
  ]);

  const handleSubTab_getfileNFolder = (item) => {
    setActiveSubTab(item);
    setSharer(null);
    dispatch(getfileNFolder({ parent: item.id })).then(() => {
      dispatch(setOpenFolderItem(item));
      dispatch(setSelectedFile(item));
      setFolderId(item.id);
      onMenuClick(false);
    })
  };

  const handleShareTab_getfileNFolder = (item) => {
    setSharer(item);
    setActiveSubTab(item);
    onMenuClick(false);
    dispatch(getSharedItemUsers(item.id))
  };

  const onShowFilesUploadModal = () => dispatch(showFilesUploadModal())
  const onHideFilesUploadModal = () => dispatch(hideFilesUploadModal())

  const inputFile = useRef(null);

  const ref = useRef();
  useOnClickOutside(ref, () => {
    onMenuClick(false);
  });
  const outsideClickRef = useRef();
  useOnClickOutside(outsideClickRef, (e) => {
    dispatch(setSelectedFile(null))
  });

  const gotoRootFolder = (rootDir) => {
    handleActiveTab(rootDir);
    handleActiveSubTab(null);
    onMenuClick(false);
  };

  let finalList = directory?.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleOutsideClick = () => {
    dispatch(setSelectedFile(null))
  }


  const InfoPanel = () => {
    return (<div className="my-disk-right-block h-100 ps-3">
      {/* <div className="head">{activeSubTab ? (activeSubTab.name ?? (activeSubTab.first_name + " " + activeSubTab.last_name)) : 'Shared Disks'}</div> */}
      {(selectedFile || openFolderItem || activeSubTab?.first_name) ?
        <div className="head">{selectedFile?.name ? getFileFolderNameByType(selectedFile) : openFolderItem?.name ? getFileFolderNameByType(openFolderItem) : activeSubTab?.first_name ? getUserFullName(activeSubTab) : ""}</div>
        : <div className="head">{getActiveTabHeading(activeTab)}</div>}
      <div className="info">
        {diskType === "teamDisk" ? <>
          <strong className="pe-1">Owner </strong>
          {`${userProfileInfo?.first_name} ${userProfileInfo?.last_name}`}
        </> : <>
          {(selectedFile?.owner?.id ?? openFolderItem?.owner?.id) ? <p className="my-2">
            <strong className="pe-1">Owner </strong>
            {selectedFile ? `${selectedFile?.owner?.first_name || ''} ${selectedFile?.owner?.last_name || ''}` : (openFolderItem?.owner ? (openFolderItem?.owner?.first_name + " " + openFolderItem?.owner?.last_name) : "")}
          </p> : ""}
        </>}
        {activeSubTab?.updated_at && (
          <p className="my-2">
            <strong className="pe-1">Changed </strong>
            {activeSubTab?.updated_at && <> {moment(activeSubTab?.updated_at).format('MMM DD, YYYY')} </>}
          </p>
        )}

        {activeSubTab?.download_count > 0 && (
          <p className="my-2">
            <strong className="pe-1">Downloads </strong>
            {activeSubTab?.download_count} times
          </p>
        )}
        {activeSubTab?.viewed_count > 0 && (
          <p className="my-2">
            <strong className="pe-1">Viewed </strong>
            {activeSubTab?.viewed_count} times
          </p>
        )}
      </div>
      {activeSubTab ? <FolderMenu checkForAccessLevel={activeTab === 2 && true} item={selectedFile ?? openFolderItem} handlePopups={handlePopups} activeSubTab={activeSubTab} onUploadFilee={onShowFilesUploadModal} /> : <></>}

    </div >)
  }

  const isOrganizer = () => {
    if (teamOrganizers && teamOrganizers?.length) {
      return teamOrganizers?.find(to => to.user?.id === userProfileInfo?.id)
    }
    return false;
  }

  return (
    <>
      {/* <div className="d-flex">
        <pre style={{ height: "200px" }}>
          {JSON.stringify(activeSubTab, null, 2)}
        </pre>
        <pre style={{ height: "200px" }}>
          {JSON.stringify(openFolderItem, null, 2)}
        </pre>
        <pre style={{ height: "200px" }}>
          {JSON.stringify(selectedFile, null, 2)}
        </pre>
      </div> */}
      <section className="Profile_Tab">
        <div className="card">
          <div className="my-disk row">
            <div className="left-tabs col-xl-3" onClick={handleOutsideClick}>
              {isMobile ? (
                <div className="mobile-head mb-3">
                  <div className="left-wrapper">
                    <Menu onClick={() => onMenuClick(true)} />

                    {diskType === "teamDisk" ? "" : <h5 className="mb-0 ms-2">{'myDisk'}</h5>}
                  </div>
                  <div className="right-wrapper">
                    <div className="viewBtn" onClick={() => handleViewType('GridView')}>
                      <GridView color={viewType === 'GridView' ? '#0255fe' : null} />
                    </div>
                    <div className="viewBtn ms-2" onClick={() => handleViewType('ListView')}>
                      <ListView color={viewType === 'ListView' ? '#0255fe' : null} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {diskType === "teamDisk" ? "" : <h5 className="disk-main-heding">{'myDisk'}</h5>}
                </>
              )}

              {isMobile ? (
                showMenu && (
                  // Mobile View
                  <div className="mobile-menu" ref={ref}>
                    <div
                      className={activeTab === 1 ? `tab-menu ${activeSubTab ? "subtab-active" : "active"}` : 'tab-menu'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleActiveTab(1);
                        handleActiveSubTab(null);
                        onMenuClick(false);
                      }}
                    >
                      <span className="me-3">
                        {diskType === 'personalDisk' ? <MyDiskIcon /> : <TeamDiskMemberIcon />}
                      </span>
                      {diskType === 'personalDisk' ? 'My Disk' : 'Team Disk'}
                    </div>
                    {activeTab === 1 && (
                      <ul className="sub-tab">
                        {finalList &&
                          finalList.length > 0 ?
                          finalList.map((item, index) => (
                            <li
                              className={activeSubTab === item.name ? 'active' : null}
                              key={index}
                              onClick={() => {
                                handleSubTab_getfileNFolder(item);
                                // handleActiveSubTab(item.name);
                              }}
                            >
                              {item.name === 'documents' && (
                                <span className="menu-icon">
                                  <Documents width={17} height={16} />
                                </span>
                              )}
                              {item.name === 'music' && (
                                <span className="menu-icon">
                                  <Music width={14} height={15} />
                                </span>
                              )}
                              {item.name === 'pictures' && (
                                <span className="menu-icon">
                                  <Pictures width={16} height={16} />
                                </span>
                              )}
                              {item.name === 'movies' && (
                                <span className="menu-icon">
                                  <Videos width={16} height={16} />
                                </span>
                              )}
                              {item.name}
                            </li>
                          )) : 'No Directory'}
                      </ul>
                    )}
                    <div
                      className={activeTab === 1 ? `tab-menu ${activeSubTab ? "subtab-active" : "active"}` : 'tab-menu'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleActiveTab(2);
                        handleActiveSubTab(null);
                        onMenuClick(false);
                      }}
                    >
                      <span className="me-3">
                        <SharedDisksIcon />
                      </span>
                      Shared Disks
                    </div>
                    {activeTab === 2 && (
                      <ul className="sub-tab">
                        {shareUserList?.map((item, index) => (
                          <li
                            className={activeSubTab === item.id ? 'active' : null}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleShareTab_getfileNFolder(item);
                            }}
                          >
                            <span className="avtar">
                              <MyBridgeAvatar
                                icon={item?.profile_pic}
                                name={`${item?.first_name} ${item?.last_name}`}
                              />
                              {/* <img src={item?.profile_pic} className="img-fluid" alt="avatar" /> */}
                            </span>
                            {item?.first_name} {item?.last_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              ) : (
                // Desktop View
                <div className="team-left-pannel">
                  {diskType !== "teamDisk" ? <>
                    <div
                      className={activeTab === 1 ? `tab-menu ${activeSubTab ? "subtab-active" : "active"}` : 'tab-menu'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleActiveTab(1);
                        handleActiveSubTab(null);
                      }}
                    >
                      <span className="me-3">
                        <MyDiskIcon fill={activeTab === 1 ? "#3D5A80" : null} color={activeTab === 1 ? "white" : null} />
                      </span>
                      <span>My Disk</span>
                    </div>
                    {activeTab === 1 && (
                      <ul className="sub-tab">
                        {finalList &&
                          finalList.length > 0 ?
                          finalList.filter(item => item.name?.toLowerCase?.()?.includes?.("team_disk") ? false : true)?.map((item, index) => (
                            <li
                              className={activeSubTab?.name === item.name ? 'active' : null}
                              key={index}
                              title={getFileFolderNameByType(item)}
                              onClick={() => {
                                handleSubTab_getfileNFolder(item);
                              }}
                            >
                              {item.name === 'documents' ? (
                                <span className="menu-icon">
                                  <Documents width={17} height={16} />
                                </span>
                              )
                                : item.name === 'music' ? (
                                  <span className="menu-icon">
                                    <Music width={14} height={15} />
                                  </span>
                                )
                                  : item.name === 'pictures' ? (
                                    <span className="menu-icon">
                                      <Pictures width={16} height={16} />
                                    </span>
                                  )
                                    : item.name === 'movies' ? (
                                      <span className="menu-icon">
                                        <Videos width={16} height={16} />
                                      </span>
                                    )
                                      : <FolderCircleIcon />
                              }{trimStr(getFileFolderNameByType(item), { length: 15 })}
                            </li>
                          )) : 'No Directory'}
                      </ul>
                    )}
                    {finalList?.filter?.(item => item.type === "TD")?.length ? <>
                      <div
                        className={activeTab === 4 ? `tab-menu ${activeSubTab ? "subtab-active" : "active"}` : 'tab-menu'}
                        onClick={(e) => {
                          e.preventDefault();
                          handleActiveTab(4);
                          handleActiveSubTab(null);
                        }}
                      >
                        <span className="me-3">
                          <TeamDiskMemberIcon fill={activeTab === 4 ? "#3D5A80" : null} color={activeTab === 4 ? "white" : null} />
                        </span>
                        <span>Team disks</span>
                      </div>
                    </> : ""}
                    {activeTab === 4 && (
                      <ul className="sub-tab team-sub-tab">
                        {finalList &&
                          finalList.length > 0 ?
                          finalList.filter(item => item.type === "TD" || item.name?.includes("team_disk"))?.map((item, index) => (
                            <li
                              className={activeSubTab?.name === item.name ? 'active' : null}
                              key={index}
                              onClick={() => {
                                handleSubTab_getfileNFolder(item);
                              }}
                              title={getFileFolderNameByType(item)}
                            >
                              {item?.team_info?.image_logo ? <img className='team-logo' src={item?.team_info?.image_logo} /> : <FolderCircleIcon />}
                              {trimStr(getFileFolderNameByType(item), { length: 15 })}
                            </li>
                          )) : 'No Directory'}
                      </ul>
                    )}
                    {shareUserList && shareUserList?.length ? (
                      <div
                        className={activeTab === 2 ? `tab-menu ${activeSubTab ? "subtab-active" : "active"}` : 'tab-menu'}
                        onClick={(e) => {
                          e.preventDefault();
                          handleActiveTab(2);
                          handleActiveSubTab(null);
                        }}
                      >
                        <span className="me-3">
                          <SharedDisksIcon fill={activeTab === 2 ? "#3D5A80" : null} color={activeTab === 2 ? "white" : null} />
                        </span>
                        Shared Disks
                      </div>
                    ) : null}
                    {activeTab === 2 && (
                      <ul className="sub-tab">
                        {shareUserList?.map((item, index) => {
                          return (
                            <li
                              className={activeSubTab?.id === item.id ? 'active' : null}
                              key={index}
                              onClick={(e) => {
                                e.preventDefault();
                                handleShareTab_getfileNFolder(item);
                              }}
                              title={getUserFullName(item)}
                            >
                              <span className="avtar">
                                <MyBridgeAvatar
                                  icon={item?.profile_pic}
                                  name={getUserFullName(item)}
                                />
                                {/* <img src={item?.profile_pic} className="img-fluid" alt="avatar" /> */}
                              </span>
                              {trimStr(getUserFullName(item), { length: 15 })}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </> : ""}
                  {diskType === "teamDisk" ? (
                    <div
                      className={activeTab === 3 ? `tab-menu ${activeSubTab ? "subtab-active" : "active"}` : 'tab-menu'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleActiveTab(3);
                        dispatch(setSelectedFile(teamDiskDetails))
                        dispatch(setOpenFolderItem(teamDiskDetails))
                        handleActiveSubTab(0);
                      }}
                    >
                      <span className="me-3">
                        <TeamDiskMemberIcon fill={activeTab === 3 ? "#3D5A80" : null} color={activeTab === 3 ? "white" : null} />
                      </span>
                      Team Disk
                    </div>
                  ) : null}
                  {activeTab === 3 && (
                    <ul className="sub-tab team-sub-tab">
                      <li
                        className={activeSubTab?.name === teamDiskDetails.name ? 'active' : null}
                        onClick={() => {
                          handleSubTab_getfileNFolder(teamDiskDetails);
                        }}
                        title={team.name}
                      >
                        <img className='team-logo' src={team?.image_logo} />
                        {trimStr(team?.name, { length: 15 })}
                      </li>
                    </ul>
                  )}
                </div>
              )}

              <div className="storage">
                {(!storage || storage <= 0) ?
                  <div className="storage--top py-2 fs-12">
                    No disk subscription found
                  </div>
                  :
                  <div className="storage--top py-2">
                    {/* <span>{humanFileSize(occupiedSize.occupied_size, true)} of {humanFileSize(storage, true)} used</span> */}
                    <span>{humanFileSize(occupiedSize?.occupied_size ?? 0, true)} of {humanFileSize(parseInt(`${storage ?? 0}000000)`), true)} used</span>
                    {diskType === "teamDisk" && isOrganizer() ?
                      <Link to="/settings/disk-subscription">Upgrade</Link> : ""}
                  </div>
                }
                <ProgressBar now={60} />
                {diskType === "teamDisk" ?
                  <div className="storage--bottom py-2">
                    <div className="d-flex align-items-center">
                      <div style={{ opacity: 0.5 }}>Subscriber</div>
                      <div title={teamDiskDetails?.created_by} className="ps-1">{trimStr((teamDiskDetails?.created_by), { length: 10 })}</div>
                    </div>
                    {isOrganizer() ? < div >
                      <Link onClick={() => handlePopups('Subscriber')}>Change</Link>
                    </div> : ""}
                  </div> :
                  <div className="py-2">
                    <Link to="/settings/disk-subscription">Upgrade</Link>
                  </div>
                }
              </div>
              {/* <div className="storage">

                <div className="storage--top py-2">
                  <span>{humanFileSize(occupiedSize.occupied_size, true)} of {humanFileSize(storage, true)} used</span>
                </div>
                <ProgressBar now={60} />
                <Link to="/settings/disk-subscription">Upgrade</Link>
              </div> */}
              {/* } */}
            </div>
            <div
              className={classNames('right-pannel', [searchResult && searchValue ? 'col-xl-9' : 'col-xl-6'])}>
              <MyDiskHeader
                setActiveTab={setActiveTab}
                activeSubTab={activeSubTab}
                viewType={viewType}
                handleViewType={handleViewType}
                heading={activeTab === 1 ? 'My Disk' : activeTab === 2 ? 'Shared Disks' : "Team Disk"}
                onSearch={onSearch}
                searchValue={searchValue}
                activeTab={activeTab}
                gotoRootFolder={gotoRootFolder}
              />
              {searchResult && searchValue ? (
                <SearchResult
                  onFiltersChange={handleSearchFiltersChange}
                  activeSubTab={activeSubTab}
                  viewType={viewType}
                  handlePopups={handlePopups}
                  onUploadFilee={onShowFilesUploadModal}
                />
              ) : (
                <React.Fragment>
                  {activeTab === 1 && (
                    <MyDisk
                      directory={directory?.filter?.(item => item.name?.toLowerCase?.()?.includes?.("team_disk") ? false : true)}
                      activeSubTab={activeSubTab}
                      handlePopups={handlePopups}
                      viewType={viewType}
                      fileFolderList={fileFolderList}
                      openFolder={null}
                      onUploadFilee={onShowFilesUploadModal}
                      handleSubTab_getfileNFolder={handleSubTab_getfileNFolder}
                      humanFileSize={humanFileSize}
                    />
                  )}
                  {activeTab === 2 && (
                    <SharedDisks
                      directory={directory?.filter?.(item => item.name?.toLowerCase?.()?.includes?.("team_disk") ? false : true)}
                      activeSubTab={activeSubTab}
                      handlePopups={handlePopups}
                      viewType={viewType}
                      fileFolderList={fileFolderList}
                      openFolder={null}
                      onUploadFilee={onShowFilesUploadModal}
                      handleSubTab_getfileNFolder={handleSubTab_getfileNFolder}
                      humanFileSize={humanFileSize}
                      handleShareTab_getfileNFolder={handleShareTab_getfileNFolder}
                    />
                  )}
                  {activeTab === 3 && (
                    <TeamDisk
                      team={team}
                      directory={directory}
                      activeSubTab={activeSubTab}
                      handlePopups={handlePopups}
                      viewType={viewType}
                      fileFolderList={fileFolderList}
                      openFolder={null}
                      handleSubTab_getfileNFolder={handleSubTab_getfileNFolder}
                      onUploadFilee={onShowFilesUploadModal}
                      humanFileSize={humanFileSize}
                    />
                  )}
                  {activeTab === 4 && (
                    <MyDisk
                      setActiveTab={setActiveTab}
                      diskType="teamDisk"
                      directory={directory?.filter?.(item => item.type === "TD" || item.name?.includes("team_disk"))}
                      activeSubTab={activeSubTab}
                      handlePopups={handlePopups}
                      viewType={viewType}
                      fileFolderList={fileFolderList}
                      openFolder={null}
                      handleSubTab_getfileNFolder={handleSubTab_getfileNFolder}
                      onUploadFilee={onShowFilesUploadModal}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
            {(!isMobile && searchValue?.length <= 0) ? (
              <div className="right-pannel col-xl-3 ps-0">
                <InfoPanel />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={onUploadFile} /> */}
      </section >

      {/* File upload modal */}
      {
        filesUploadModalVisible &&
        <FileUploadModal
          heading={<h5 className="modal-title">Upload Files</h5>}
          className="disk-popup"
          onClose={onHideFilesUploadModal}
          parentDirectoryId={(selectedFile && isDir(selectedFile)) ? selectedFile.id : (openFolderItem?.id ?? folderId)}
        />
      }

      {
        modalOpen && (
          <Modal
            onClose={handlePopups}
            heading={popupHeader}
            className={popUpType === 'Share' ? 'disk-popup share-disk' : 'disk-popup'}
          >
            <ModalPopups activeSubTab={activeSubTab} teamId={teamId} teamOrganizers={teamOrganizers} handlePopups={handlePopups} popUpType={popUpType} folderId={folderId} />
          </Modal>
        )
      }

      {/* {uploadSeccess &&
        <div className="upload-seccess">File Uploaded</div>
      } */}
    </>
  );
};
export default MyDiskMain;
