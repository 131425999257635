import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import OnboardingWrapper from "../Components/OnboardingWrapper"
import { onboardingSteps } from "../constant";
import { getOnboardingConnections, setOnboardingData, getOnboardingStatus, getOnboardingData } from 'store/slices/onboardingSlice';
import { MyBridgeAvatar } from "components/common/Avatar";
import { DegreeIcon } from "components/common/Icons";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "./action";
import SectionLoader from "components/common/Loader/sectionLoader";
import { setUserFirstLogin } from "store/slices/authSlice";

const currStep = onboardingSteps[5];
const currActiveSteps = [onboardingSteps[0].id, onboardingSteps[1].id, onboardingSteps[2].id, onboardingSteps[3].id, onboardingSteps[4].id, onboardingSteps[5].id];

const OnboardingConnect = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loading, currStepData, connections} = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const navigate = useNavigate()


  useEffect(() => {
    const payload = {
      step: currStep?.name,
      emailId: userProfileInfo?.email
    };
    dispatch(getOnboardingStatus(payload));
    dispatch(getOnboardingConnections(payload));
  }, [userProfileInfo])


  const handleSubmit = () => {
    const payload = {
      step: currStep?.name,
    }
    dispatch(setOnboardingData(payload));
    dispatch(setUserFirstLogin(true));
    // window.location.assign('/');
    navigate('/');
  }
  return (
    <OnboardingWrapper activeSteps={currActiveSteps} className='full-width'>
      <h3>Connect with people you know by extending a virtual handshake. Follow those you want to get to know better.</h3>
      <p>We recommend following at least 5 people to help you begin to network.</p>

      <div className="row">
        {loading ? <SectionLoader /> : connections?.data?.map((connection) => {
          const NAME = connection.first_name + ' ' + connection.last_name;
          const ICON = connection.profile_pic;
          return (
            <div key={connection.id} className="col-md-4">
              <div className="connection-card">
                <div className="icon">
                  <MyBridgeAvatar size={70} name={NAME} icon={ICON} />
                </div>
                <h3>{NAME}</h3>
                <p>{connection.position ? connection.position : ' '}</p>

                <ul className="connection-chaining">
                  <li>
                    {connection.degree_count.first}
                    <DegreeIcon />
                    1st Degrees
                  </li>

                  <li>
                  {connection.degree_count.second}
                    <DegreeIcon />
                    2nd Degrees
                  </li>

                  <li>
                  {connection.degree_count.third}
                    <DegreeIcon />
                    3rd Degrees
                  </li>
                </ul>

                <ButtonGroup />
              </div>
            </div>
          )
        })}
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-primary" onClick={handleSubmit}>
          Finish
        </button>
      </div>
    </OnboardingWrapper>
  )
}

export default OnboardingConnect