import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchCompanyPosts, fetchPosts } from 'api';
import { fetchMyActivity } from 'api/post';
import PostForm from 'components/PostForm';
import Loader from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserFirstLogin } from 'store/slices/authSlice';
import { fetchComments } from 'store/slices/commentSlice';
import { triggerFetchPosts } from 'store/slices/postSlice';
import styled from 'styled-components';
import { defaultPosts } from '../constant';
import EventPost from './Event';

export const Text = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3d5a80;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 0.63rem;
`;

const Header = styled.p`
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #000000;
  flex: 1;
  font-family: 'Inter';
  font-style: normal;
`;

const ActionButton = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #3d5a80;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 24px;
  color: #4f4f4f;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
`;

// Styled component for the subheader
const Description = styled.p`
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: #666;
  margin-bottom: 5px;
`;

const PostsList = (props) => {
  const { listType, companyDetails, postModal, isPublic } = props;
  const { userFirstLogin } = useSelector((state) => state.user);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { refetchPosts } = useSelector((state) => state.post);
  const dispatch = useDispatch();
  const isFetch = useRef(true);
  const [showModal, setShowModal] = useState(false);
  const [postData, setPostData] = useState([]);

  /**
   *
   * i am not sure of this function what it does
   */
  const handleChat = (id) => {
    dispatch(fetchComments({ id }));
  };

  useEffect(() => {
    if (refetchPosts) {
      refetch()
      setTimeout(() => {
        dispatch(triggerFetchPosts(false))
      }, 1)
    }
  }, [refetchPosts])

  let queryFn = fetchPosts;
  if (listType === 'company') {
    queryFn = fetchCompanyPosts;
  } else if (listType === 'myActivity') {
    queryFn = fetchMyActivity;
  }

  useEffect(() => {
    if (userFirstLogin)
      setTimeout(() => {
        dispatch(setUserFirstLogin(false));
      }, 100000);
  }, [userFirstLogin]);

  /**
   * @description this function will be called when user scroll down the page
   * and it will fetch next page of posts
   */
  const {
    isLoading,
    data = { pages: [] },
    isFetchingNextPage,
    fetchNextPage,
    refetch
  } = useInfiniteQuery({
    queryKey: ['feed', companyDetails?.id],
    queryFn: queryFn,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.next) {
        const url = new URL(lastPage.next);
        const page = url.searchParams.get('page');
        return parseInt(page, 10);
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
  });


  useEffect(() => {
    setPostData(data?.pages);
  }, [JSON.stringify(data?.pages)]);

  /**
   *
   * @param {Event} e
   * @description this function will be called when user scroll down the page
   * and it will fetch next page of posts
   * we have defined max threshold and min threshold to avoid multiple api calls
   * we are also restricting the api call untila previous api call is completed for that we are using isFetch.current
   * we imediatly set isFetch.current to false when we call fetchNextPage() and set it to true when we get response from api
   * this will prevent multiple api calls
   */

  const handleScroll = async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
    const scrolled = scrollHeight - scrollTop;
    const minThreshold = clientHeight * 4;
    const maxThreshold = clientHeight * 5;
    if (isFetch.current && scrolled < maxThreshold && scrolled > minThreshold) {
      isFetch.current = false;
      // console.log('scrollHeight', scrollHeight);
      await fetchNextPage();
      isFetch.current = true;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const isUserInactive = userProfileInfo.is_active === false || userFirstLogin === true;
  // if (isLoading) {
  //   return <Loader secondary />;
  // }

  /**
   * TODO:right now this condition is bind with redux posts state but we need to bind it with api response using react query
   * as feed data is coming from api and not from redux
   */
  return (
    <>
      {postModal && (
        <>
          <HeaderContainer>
            <Header>Activity</Header>

            {!isPublic && <ActionButton onClick={() => setShowModal(true)}>Create a post</ActionButton>}
          </HeaderContainer>
          {!isPublic && postData?.reduce((count, current) => count + current.results.length, 0) === 0 && (
            <div className="row">
              <Title>Your connections have missed you.</Title>
              <Description>Create a post so the networking can continue.</Description>
            </div>
          )}
        </>
      )}
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          heading={
            <Text fW={600} fS="24px" color="#3D5A80">
              Create a post
            </Text>
          }
          className={'testimonial-popup'}
        >
          <PostForm type={listType} setShowModal={setShowModal} />
        </Modal>
      )}

      {/* {JSON.stringify(data)} */}
      {data.pages?.map((page) => {
        const isUserInactive = page.code === 'user_inactive' || page.code === 'token_not_valid' || userFirstLogin === true;

        if (isUserInactive) {
          return defaultPosts.map((post) => {
            return <EventPost key={post.id} data={post} postType="default" />;
          });
        }

        return page?.results?.map((post, index) => {
          return <EventPost key={index} data={post} repost={false} />;
        });
      })}

      {isFetchingNextPage && <Loader secondary />}
    </>
  );
};

export default PostsList;
