/* eslint-disable jsx-a11y/alt-text */
import { POST_ACTIONS } from 'components/PostCard/constant';
import { Flex, Select, Text, TextInput } from 'components/core';
import React, { useState } from 'react';
import ImagePicker from './ImagePicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Spinner } from 'components/common/Loader';
import ReactDatePicker from 'react-datepicker';
import { TIME_FORMATE } from 'data/data';
import Dropdown from 'components/common/Dropdown';
import momentTimeZone from 'moment-timezone';
import MyBlogEditor from 'pages/CreatePost/editor';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectPicker = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e6eb;
  background: #fff;
  padding: 5px 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5b5b5b;
  padding-left: 10px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('/icon/arrow-down.svg');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
`;

// const EventTime = styled.input`
//   width: 250px;
//   height: 50px;
//   border-radius: 5px;
//   border: 1px solid #e4e6eb;
//   background: #fff;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 25px;
//   color: #000000;
//   position: relative;
//   padding-left: 15%;
//   ::-webkit-calendar-picker-indicator {
//     position: absolute;
//     left: 15%;
//     width: 20px;
//     height: 20px;
//     background-image: url(/icon/time-icon.svg);
//   }
// `;

// const EventDatePicker = styled.input`
//   width: 250px;
//   height: 50px;
//   border-radius: 5px;
//   border: 1px solid #e4e6eb;
//   background: #fff;

//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 25px;
//   color: #000000;
//   position: relative;
//   padding-left: 10%;
//   ::-webkit-calendar-picker-indicator {
//     position: absolute;
//     right: 20px;
//     width: 20px;
//     height: 20px;
//     background-image: url(/icon/date-icon.svg);
//   }
// `;

const EditorContainer = styled.div`
  width: 100%;
  .ql-container {
    min-height: 20px;
  }

  .ql-editor {
    min-height: 200px;
  }

  .ql-formats {
    position: relative;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px !important;
  }
`;

const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #e4e6eb;
  background: #fff;
  margin-right: 10px;
`;

const DescriptionDropDown = styled.div`
  width: 100%;
  border-radius: 5px;
  border: none;
  background: #fff;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #5b5b5b;
  margin-left: 20px;
  cursor: pointer;
`;

const PublishButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  gap: 10px;
  width: 100px;
  height: 36px;
  background: #3d5a80;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  margin-left: 20px;
`;

const CancelButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  gap: 10px;
  width: 100px;
  height: 36px;
  background: #e4e6eb;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #3d5a80;
  border: none;
`;

export const LOCATION_TYPE = [
  {
    key: '',
    value: 'Add a Location',
  },
  {
    key: 'builtin',
    value: 'Built-in Audio / Video Conferencing',
  },
  {
    key: 'provide',
    value: 'Provide a location',
  },
];

const timezoneArray = Intl.supportedValuesOf('timeZone');
// console.log(timezoneArray);

const timeZone = timezoneArray.map((item) => {
  return { value: item, label: item };
});

const EventForm = ({ onSubmit, onCancel, loading, edit, editData }) => {
  const [visibility, setVisibility] = useState(0);
  const [title, setTitle] = useState('');
  const [locationType, setLocationType] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState(moment().toDate());
  const currentHour = parseInt(moment().format('HH'), 10);
  const currentMinute = parseInt(moment().format('mm'), 10);
  const addedMinutes = currentMinute >= 30 ? 60 : 30;
  const [startTime, setStartTime] = useState(moment(currentHour, 'HH').add(addedMinutes, 'minute').format('HH:mm'));
  const [endDate, setEndDate] = useState(moment().toDate());
  const [endTime, setEndTime] = useState(
    moment(currentHour, 'HH')
      .add(addedMinutes + 30, 'minute')
      .format('HH:mm'),
  );
  const [allDaye, setAllDay] = useState(false);
  const [description, setDescription] = useState('');
  const [writingDescription, setWritingDescription] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [timeZoneSelected, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  React.useEffect(() => {
    if (edit) {
      const { media, all_day_event = false } = editData;
      console.log(all_day_event);
      const file = media && media.length > 0 ? media[0].file : null;
      setImageFile(file);
      setAllDay(all_day_event);
      setVisibility(editData.visibility);
      setTitle(editData.title);
      setLocationType(editData.location_type);
      setLocation(editData.location);
      setStartDate(moment(editData.start_time).toDate());
      setStartTime(moment(editData.start_time).format('HH:mm'));
      setEndDate(moment(editData.end_time).toDate());
      let endDateTime = moment(editData.end_time);
      if (endDateTime.format('HH:mm') === '23:59') {
        endDateTime = endDateTime.set({ hour: 0, minute: 0 });
      }
      setEndTime(endDateTime.format('HH:mm'));
      setDescription(editData.description);
      setWritingDescription(true);
      setTimeZone(editData.time_zone);
    }
  }, []);

  const onSubmitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('visibility', visibility);
    formData.append('description', description);
    formData.append('location_type', locationType);

    // convert start date and time to ISO format
    const offset = momentTimeZone.tz(timeZoneSelected || 'Asia/Calcutta').utcOffset();
    // console.log(offset);

    let startTimeBuilder = moment(startTime, 'HH:mm');
    let endTimeBuilder = moment(endTime, 'HH:mm');

    let startDateTime = moment(startDate).set({
      hour: startTimeBuilder.get('hour'),
      minute: startTimeBuilder.get('minute'),
    });

    let endDateTime = moment(endDate).set({
      hour: endTimeBuilder.get('hour'),
      minute: endTimeBuilder.get('minute'),
    });

    if (startDateTime.format('YYYY-MM-DDTHH:mm:ss') === endDateTime.format('YYYY-MM-DDTHH:mm:ss')) {
      endDateTime = endDateTime.set({
        hour: 23,
        minute: 59,
      });
      formData.append('all_day_meeting', true);
    }

    if (allDaye) {
      formData.append('all_day_meeting', true);
    }

    const startDateISO = startDateTime.toISOString();
    const endDateISO = endDateTime.toISOString();

    formData.append('start_time', startDateISO);
    formData.append('end_time', endDateISO);
    formData.append('type', 2);
    // check if imageFile is URL or File
    if (imageFile?.type?.includes('image')) {
      formData.append('media', imageFile);
      formData.append('media_updated', true);
    }
    onSubmit(formData);
  };
  return (
    <div>
      <Form onSubmit={onSubmitForm}>
        <ImagePicker
          imageFile={imageFile}
          setImageFile={setImageFile}
          onSelect={(image) => {
            setImageFile(image);
          }}
        />
        <Flex justify="flex-start" margin="10px 0px">
          <Select
            value={visibility}
            options={POST_ACTIONS}
            onChange={(option) => setVisibility(option.key)}
            title="Choose audience"
          />
        </Flex>
        <TextInput
          placeholder="Title"
          iconLeft={<img src="/icon/title-icon.svg" style={{ paddingLeft: 10 }} />}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <SelectPicker
          value={locationType}
          options={LOCATION_TYPE}
          onChange={(e) => setLocationType(e.target.value)}
          title="Who can reply?"
          required
        >
          {LOCATION_TYPE.map((item) => (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          ))}
        </SelectPicker>
        {locationType === 'provide' && (
          <TextInput placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} />
        )}

        <Flex justify="space-between" align="center" margin="10px 0px">
          <Flex>
            <div className="date-wrapper form-group mb-0">
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  if (endDate < date) setEndDate(date);
                }}
                minDate={new Date()}
                className="date-selector form-control"
                dateFormat="EEE, MMMM d, yyyy"
              />
            </div>
          </Flex>
          <Flex margin="0px 0px 0px 10px">
            <Dropdown
              id={'fromTime'}
              name="fromTime"
              options={TIME_FORMATE}
              title={'fromTime'}
              selectedValue={startTime}
              handleChange={(e) => {
                const currentTime = e.target.value;
                setStartTime(currentTime);
                setEndTime(moment(currentTime, 'hh:mm').add(30, 'minute').format('hh:mm'));
              }}
              containerClass="m-0"
              disabled={allDaye}
            />
          </Flex>
        </Flex>
        <Flex justify="space-between" align="center" margin="10px 0px">
          <Flex>
            <div className="date-wrapper form-group mb-0">
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  if (date >= startDate) {
                    setEndDate(date);
                  }
                }}
                minDate={startDate}
                className="date-selector form-control"
                dateFormat="EEE, MMMM d, yyyy"
              />
            </div>
          </Flex>
          <Flex margin="0px 0px 0px 10px">
            <Dropdown
              id={'fromTime'}
              name="fromTime"
              options={TIME_FORMATE}
              title={'fromTime'}
              selectedValue={endTime}
              handleChange={(e) => {
                const currentTime = e.target.value;
                setEndTime(currentTime);
              }}
              containerClass="m-0"
              disabled={allDaye}
            />
          </Flex>
        </Flex>
        <Flex>
          <Dropdown
            id={'timeZone'}
            name="timeZone"
            options={timeZone}
            title={'timeZone'}
            selectedValue={timeZoneSelected}
            handleChange={(event) => setTimeZone(event.target.value)}
            containerClass="m-0"
          />
        </Flex>
        <Flex justify="flex-start" align="center" margin="20px 0px">
          <CheckBox
            type="checkbox"
            checked={allDaye}
            onChange={(e) => {
              const isAllday = e.target.checked;
              if (isAllday) {
                setStartTime('00:00');
                setEndTime('00:00');
              } else {
                if (edit && editData) {
                  setStartDate(moment(editData.start_time, 'YYYY-MM-DDTHH:mm:ss').toDate());
                  setStartTime(moment(editData.start_time, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'));
                  setEndDate(moment(editData.end_time, 'YYYY-MM-DDTHH:mm:ss').toDate());
                  let endDateTime = moment(editData.end_time, 'YYYY-MM-DDTHH:mm:ss');
                  if (endDateTime.format('HH:mm') === '23:59') {
                    endDateTime = endDateTime.set({ hour: 0, minute: 0 });
                  }
                  setEndTime(endDateTime.format('HH:mm'));
                } else {
                  setStartTime(moment(currentHour, 'HH').add(60, 'minute').format('hh:mm'));
                  setEndTime(moment(currentHour, 'HH').add(90, 'minute').format('hh:mm'));
                }
              }
              setAllDay(isAllday);
            }}
          />
          <Text.Label size={16}> All Day Event</Text.Label>
        </Flex>

        <Flex justify="flex-start" align="center" margin="20px 0px">
          <img src="/icon/description-icon.svg" />{' '}
          <DescriptionDropDown value={writingDescription} onClick={() => setWritingDescription(!writingDescription)}>
            <span>Add description</span>{' '}
            {writingDescription ? <img src="/icon/arrow-up.svg" /> : <img src="/icon/arrow-down.svg" />}
          </DescriptionDropDown>
        </Flex>

        {writingDescription && (
          <EditorContainer>
            <MyBlogEditor
              setEditorState={(text) => {
                setDescription(text);
              }}
              editorState={description}
            />
          </EditorContainer>
        )}

        <Flex justify="flex-end" align="center" margin="0px 0px">
          <CancelButton type="reset" onClick={onCancel}>
            Cancel
          </CancelButton>
          <PublishButton type="submit">{loading ? <Spinner /> : edit ? 'Save' : 'Publish'}</PublishButton>
        </Flex>
      </Form>
    </div>
  );
};

EventForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  submit: PropTypes.func,
  edit: PropTypes.bool,
  editData: PropTypes.object,
};

EventForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  loading: false,
  submit: () => {},
  edit: false,
  editData: null,
};

export default EventForm;
