import React from 'react';
import { MyBLogoHeader } from 'components/common/Icons';
import Button from 'components/common/Button';
import { useSearchParams, useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const payment = searchParams.get("payment");

  const userNavigate = () => {
    if(payment === 'job'){
      navigate('/create/company/job');
    } else {
      navigate('/');
    }
  }

  return (
    <div className="fullpage-container">
      <div className='thankyoupage'>
      <MyBLogoHeader width={95} height={95} />

      <h1 className="thankyoupage--header">Thank You For Your Payment!</h1>
      <p> Your payment was successful and a receipt is on it's way to the email address we have on file.</p>

        <Button onClick={() => userNavigate()}>{payment === 'job' ? 'Continue Publishing Jobs' : 'Home'}</Button>
        </div>
    </div>
  )
}

export default ThankYouPage