import { useState, useRef } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { DownIcon } from '../Icons';
import Checkbox from '../Checkbox';
import RadioButton from '../RadioButton';
import Button from '../Button';
import { Input } from '../Input';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';

const SingleFilter = ({ label, type, data, showInput, filterData, showKeywords, showKeywordsTitle, searchJobsKeywords, searchJobsKeywordsData }) => {

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));
  const [showMenu, setShowMenu] = useState(false);

  const [radioValue, setRadioValue] = useState({ name: null, value: null });
  const [checkboxValue, setCheckboxValue] = useState([{}]);

  const handleShow = () => {
    setShowMenu((prev) => !prev);
  };

  //if (data === null) return;

  return (
    <div className="border border-secondary cursor-pointer single-filter position-relative me-2" ref={ref}>
      <div className="d-flex align-items-center px-2 py-1" onClick={handleShow}>
        <p style={{ fontSize: '14px' }}>{label}</p>
        <DownIcon className="ms-1" />
      </div>

      {showMenu && (
        <div className="navbar-dropdown--menu profile-dropdown">
          <div className="menu-body pb-0">
            {
              showKeywords ?
                <div>
                  <h4>{label}</h4>
                  <div className='mt-3'>
                    <div className='d-flex' style={{gap:"10px"}}>
                      <div className="single-filter-keywords-input">
                        <h6>First Name</h6>
                        <Input placeholder="First Name" />
                      </div>
                      <div >
                        <h6>Last Name</h6>
                        <Input placeholder="Last Name" />
                      </div>
                    </div>
                    <div>
                      <div>
                        <h6>Title</h6>
                        <Input placeholder="Title" />
                      </div>
                    </div>
                    <div className='d-flex' style={{gap:"10px"}}>
                      <div className="single-filter-keywords-input">
                        <h6>Current Company</h6>
                        <Input placeholder="Current Company" />
                      </div>
                      <div>
                        <h6>Last Company</h6>
                        <Input placeholder="Last Company" />
                      </div>
                    </div>
                    <div>
                      <div>
                        <h6>School</h6>
                        <Input placeholder="School" />
                      </div>
                    </div>
                  </div>
                </div>
                : ''
            }
            {showInput ?
              <Input placeholder={`Add a ${label}`} /> : ''}
            {showKeywordsTitle == "Keyword" ? <h4 className='mb-3'>{showKeywordsTitle}</h4> : ""}
            {data?.map(item => {
              let comp;
              if (type === 'radio') {
                comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
              } else if (type === 'checkbox') {
                if (item.labelHead) {
                  comp =
                    <div>
                      <h6>{item.labelHeadTitle}</h6>
                      <Checkbox label={item.label} name={item.name} value={item.value} />
                    </div>
                } else {
                  comp = <Checkbox label={item.label} name={item.name} value={item.value} />
                }
              }
              return comp;
            })
            }
            {searchJobsKeywords ?
              <div>
                <h6>{Object.keys(searchJobsKeywordsData)[0]}</h6>
                {searchJobsKeywordsData?.location?.map(item => {
                  let comp;
                  <Input placeholder='Add a location' />
                  if (item.type === 'radio') {
                    comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
                  } else if (item.type === 'checkbox') {
                    comp =
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox label={item.label} name={item.name} value={item.value} />
                      </div>
                  }
                  return comp;
                })}
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[1]}</h6>
                {searchJobsKeywordsData?.Industry?.map(item => {
                  let comp;
                  <Input placeholder='Add a Industry' />
                  if (item.type === 'radio') {
                    comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
                  } else if (item.type === 'checkbox') {
                    comp =
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox label={item.label} name={item.name} value={item.value} />
                      </div>
                  }
                  return comp;
                })}
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[2]}</h6>
                {searchJobsKeywordsData["Job Function"]?.map(item => {
                  let comp;
                  <Input placeholder='Add a Job Function' />
                  if (item.type === 'radio') {
                    comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
                  } else if (item.type === 'checkbox') {
                    comp =
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox label={item.label} name={item.name} value={item.value} />
                      </div>
                  }
                  return comp;
                })}
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[3]}</h6>
                {searchJobsKeywordsData?.Title?.map(item => {
                  let comp;
                  <Input placeholder='Add a Title' />
                  if (item.type === 'radio') {
                    comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
                  } else if (item.type === 'checkbox') {
                    comp =
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox label={item.label} name={item.name} value={item.value} />
                      </div>
                  }
                  return comp;
                })}
                <hr></hr>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    Under 10 applicants
                  </div>
                  <ToggleSwitch name="applicants" label="applicants" />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    In your network
                  </div>
                  <ToggleSwitch name="network" label="network" />
                </div>
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[4]}</h6>
                <div className='columns-2-salary-keywords'>
                  {searchJobsKeywordsData?.Salary?.map(item => {
                    let comp;
                    if (item.type === 'radio') {
                      comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
                    } else if (item.type === 'checkbox') {
                      comp =
                        <div>
                          {/* <h6>{item.labelHeadTitle}</h6> */}
                          <Checkbox label={item.label} name={item.name} value={item.value} />
                        </div>
                    }
                    return comp;
                  })}
                </div>
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[5]}</h6>
                {searchJobsKeywordsData?.Benefits?.map(item => {
                  let comp;
                  if (item.type === 'radio') {
                    comp = <RadioButton label={item.label} name={item.name} changed={e => setRadioValue({ name: item.name, value: e.target.value })} value={item.value} />
                  } else if (item.type === 'checkbox') {
                    comp =
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox label={item.label} name={item.name} value={item.value} />
                      </div>
                  }
                  return comp;
                })}
              </div>
              : ''}

          </div>
          <hr />
          <div className="d-flex justify-content-end me-3 mb-3">
            <Button className="btn-secondary me-2" onClick={handleShow}>Cancel</Button>
            <Button onClick={() => filterData(radioValue)}>Show results</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleFilter;