import React, { useState } from 'react';
import styled from 'styled-components';

import Pills, { PillsContainer } from 'components/common/Pills';

const AutocompleteContainer = styled.div`
  position: relative;
`;

const AutocompleteInput = styled.input`
  padding: 14px 20px;
  gap: 10px;
  width: 100%;
  height: 3rem;
  background: #f8f8f8;
  border: 1px solid #dddddd;
  border-radius: 5px;
`;

const AutocompleteList = styled.ul`
  position: absolute;
  list-style: none;
  background: #fff;
  left: 20px;
  box-shadow: 0 0 15px #ccc;
  cursor: pointer;
  width: 93%;
  z-index: 1000;
`;

const AutocompleteItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background-color: #f3ba86;
    color: #fff;
  }
`;

const AutocompleteWithPills = (props) => {
  const { options, placeholder, initialData = [], setData } = props;

  const [inputText, setInputText] = useState('');
  const [pills, setPills] = useState(initialData || []);

  const updateData = (data) => {
    setData(data);
  };

  const handleKeyDown = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key) && inputText.length) {
      e.preventDefault();
      if (pills.find((pill) => pill.toLowerCase() === inputText.toLowerCase())) return;

      addItem(inputText);
      setInputText('');
    }
  };

  const handleItemClick = (item) => {
    if (pills.find((pill) => pill.toLowerCase() === item.toLowerCase())) return;
    addItem(item);
    setInputText('');
  };

  const addItem = (item) => {
    setPills((pills) => [...pills, item]);
    updateData([...pills, item]);
  };

  const removeItem = (item) => {
    setPills((pills) => pills.filter((pill) => pill.toLowerCase() !== item.toLowerCase()));
    updateData([...pills, item]);
  };

  return (
    <AutocompleteContainer>
      <AutocompleteInput
        placeholder={placeholder}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      {inputText && (
        <AutocompleteList>
          {options
            ?.filter((option) => option.toLowerCase().includes(inputText.toLowerCase()))
            .map((item) => {
              return (
                <AutocompleteItem key={item.toLowerCase()} onClick={() => handleItemClick(item)}>
                  {item}
                </AutocompleteItem>
              );
            })}
        </AutocompleteList>
      )}

      <PillsContainer>
        {pills?.map((pill, index) => {
          return <Pills key={index} label={pill} onClose={() => removeItem(pill)} />;
        })}
      </PillsContainer>
    </AutocompleteContainer>
  );
};

export default AutocompleteWithPills;
