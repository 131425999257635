import React, { useRef, useState, useEffect } from 'react';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Modal from 'components/common/Modal/Modal';
import { AddCircle, ThreeDot } from 'components/common/Icons';
import Button from 'components/common/Button';
import { BorderLessButton, Text } from 'components/StyledComponents';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { clear, createService, deleteCompanyService, editCompanyService } from 'store/actions/companyPages.Action';
import { getCompanyServicesInfo } from 'store/actions/companyPages.Action';
import noServicesBackground from 'assets/settings/no-services-bg.svg';
import AddService from 'pages/Company/components/AddService';

const ServiceBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  height: 15rem;
`;

const ImageWrapper = styled.div`
  height: 100px;
  width: 100px;
`;

const Image = styled.img`
  /* height: 100%; */
  width: 100%;
`;

const MenuWrapper = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const Menu = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 160px;
  border: 1px solid #e4e6eb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: white;
`;

const Services = (props) => {
  const dispatch = useDispatch();
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);

  useEffect(() => {
    dispatch(getCompanyServicesInfo(companyPageDetail?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyPageDetail]);

  const [modalType, setModalType] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [showMenuForService, setShowMenuForService] = useState(null);

  const { serviceReload, companyServicesList } = useSelector((state) => state.companyPageReducer);

  useEffect(() => {
    if (serviceReload) {
      onClose();
      dispatch(clear());
      dispatch(getCompanyServicesInfo(companyPageDetail.id));
    }
    // eslint-disable-next-line
  }, [serviceReload]);

  const handleService = (type, item) => {
    setModalType(type);
    setServiceDetails(item);
    setShowMenuForService(false);
  };

  const onClose = () => {
    setModalType(null);
    setServiceDetails(null);
    setShowMenuForService(false);
  };

  const handleMenuForService = (serviceId) => {
    setShowMenuForService(serviceId);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenuForService(null));

  const setData = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      let updatedValue = value;
      if (name === 'desc') {
        updatedValue = value.slice(0, 200);
      }
      setServiceDetails((state) => ({
        ...state,
        [name]: updatedValue,
      }));
    } else {
      setServiceDetails((state) => ({
        ...state,
        ['image']: e,
      }));
    }
  };

  const inputFile = useRef(null);
  const uploadBanner = () => {
    inputFile.current.click();
  };

  const onClickSaveUpdate = () => {
    const payload = {
      ...serviceDetails,
      pageId: companyPageDetail.id,
    };

    if (modalType === 'add') {
      dispatch(createService(payload));
    } else {
      dispatch(editCompanyService(payload));
    }
  };

  const deleteService = () => {
    const payload = {
      pageId: companyPageDetail.id,
      id: serviceDetails?.id,
    };
    dispatch(deleteCompanyService(payload));
  };

  return (
    <div className="container g-0 mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <Text fS="18px" fW="500" color="#5B5B5B">
          Services
        </Text>
        <BorderLessButton className="d-flex align-items-center borderless-btn" onClick={() => handleService('add')}>
          <AddCircle width={15} height={15} color="#3D5A80" />
          <Text className="ps-2" color="#3D5A80">
            Add new services
          </Text>
        </BorderLessButton>
      </div>
      <div className="col-xl-12">
        <div className="mt-3 row gx-2">
          {companyServicesList?.length > 0 ? (
            companyServicesList.map((item) => (
              <div className="col-xl-6" key={item.id}>
                <ServiceBox className="bordered-container mb-2 flex-column">
                  <div className="d-flex justify-content-start align-items-center">
                    <ImageWrapper>
                      <Image src={item.image} alt={item.image} />
                    </ImageWrapper>

                    <MenuWrapper onClick={() => handleMenuForService(showMenuForService !== item.id && item.id)}>
                      <ThreeDot />

                      {showMenuForService === item.id && (
                        <Menu ref={ref}>
                          <BorderLessButton
                            onClick={() => handleService('edit', item)}
                            className="borderless-btn no-br"
                          >
                            <Text fS="15px" color="#5B5B5B" style={{ textAlign: 'initial' }}>
                              Edit Service
                            </Text>
                          </BorderLessButton>

                          <BorderLessButton
                            onClick={() => handleService('delete', item)}
                            className="borderless-btn p-2 no-br"
                          >
                            <Text fS="15px" color="#5B5B5B" style={{ textAlign: 'initial' }}>
                              Delete Service
                            </Text>
                          </BorderLessButton>
                        </Menu>
                      )}
                    </MenuWrapper>
                  </div>

                  <div className="d-flex flex-column mt-2 pe-2">
                    <Text fS="16px" fW="500" color="#5B5B5B" className="my-2">
                      {item.name}
                    </Text>

                    <Text fS="15px" fW="400" color="#868585">
                      {item.desc}
                    </Text>
                  </div>
                </ServiceBox>
              </div>
            ))
          ) : (
            <div className="no-posts-image-container">
              <div
                style={{
                  backgroundImage: `url(${noServicesBackground})`,
                }}
                className="no-posts-image"
              />
              <p className="d-block mb-1">Services will appear here.</p>
              <BorderLessButton
                onClick={() => handleService('add')}
                className="d-flex align-items-center justify-content-center borderless-btn"
              >
                <Text color="#5B5B5B">Add new service</Text>
              </BorderLessButton>
            </div>
          )}
        </div>
        {modalType && (
          <Modal
            onClose={onClose}
            heading={
              <Text fW="600" fS="18px" color="#3D5A80">
                {startCase(modalType)} Service
              </Text>
            }
            className={`modal-services ${modalType === 'delete' ? 'service-delete-modal' : ''}`}
            action={
              <div className="action-btn">
                <Button variant="btn-secondary" className="h-36 me-2" onClick={onClose}>
                  Cancel
                </Button>
                {(modalType === 'edit' || modalType === 'add') && (
                  <Button className="h-36" onClick={onClickSaveUpdate}>
                    Save
                  </Button>
                )}
                {modalType === 'delete' && (
                  <Button className="h-36" variant={'btn-danger'} onClick={deleteService}>
                    Delete
                  </Button>
                )}
              </div>
            }
          >
            {modalType !== 'delete' && (
              <AddService
                setData={setData}
                uploadBanner={uploadBanner}
                inputFile={inputFile}
                serviceDetails={serviceDetails}
              />
            )}
            {modalType === 'delete' && (
              <>
                <p className="mb-3">
                  The service will be permanently deleted and service will no longer be able to access.
                </p>
                <p>Would you still like to delete this service?</p>
              </>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};
export default Services;
