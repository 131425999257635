import { createReducer } from '@reduxjs/toolkit';
import {
  addFilesToUpload,
  cleanDownloadedFile,
  clearFile,
  copyFiles,
  createFolder,
  createShareLink,
  deleteFile,
  deleteFolder,
  downloadFile,
  finishFileDownload,
  getDeleteFiles,
  getDiskSubscriptionPlan,
  getOccupiedDiskSize,
  getRootDir,
  getSharedDiskUsers,
  getSharedItemUsers,
  getSubfileNFolder,
  getTeamDiskDetails,
  getTeamFileNFolder,
  getUserSubscriptionDetails,
  getfileNFolder,
  getfileNFolderCopyMove,
  hideFilesUploadModal,
  moveFiles,
  moveFolder,
  removeFilesToUpload,
  renameFiles,
  renameFolder,
  restoreFile,
  restoreFolder,
  searchFileFolder,
  setFilesToUpload,
  setOpenFolderItem,
  setSelectedFile,
  setSelectedUser,
  setUploadSuccess,
  showFilesUploadModal,
  startFileDownload,
  uploadFile,
  uploadFileByIndex,
  getUserSubscriptionDetailsbyUser,
  getSharedDiskUsersByFile,
  getShareLink,
  deleteShareLink,
  setDeleteFolderSeccess,
  setDeleteSeccess,
  updateFileToUpload,
  changeTeamDiskSubscriber,
  setTeamDiskSubscriberChanged,
  initRootDirs,
} from '../actions/myDisk.Action';

const initialState = {
  directory: [],
  loading: false,
  fileFolderList: [],
  deletedfileFolderList: [],
  folderCreated: false,
  openFolderItem: null,
  selectedFile: null,
  deleteSeccess: false,
  deleteFolderSeccess: false,
  uploadSeccess: false,
  renameFilesSuccess: null,
  subFileFolderList: [],
  fileListCopyMove: [],
  fileRestored: undefined,
  folderRestored: undefined,
  renameFolderSuccess: undefined,
  moveFilesSuccess: undefined,
  copyFilesSuccess: undefined,
  moveFolderSuccess: undefined,
  searchResult: [],
  shareItemListByUser: [],
  shareUserList: [],
  shareUserListByFile: [],
  selectedUser: undefined,
  sharelinkData: undefined,
  subscriptionPlans: {},
  teamDiskDetails: {},
  occupiedSize: 0,
  filesToUpload: [],
  filesUploadModalVisible: false,
  downloadedFile: undefined,
  downloading: false,
  changeTeamDiskSubscriberLoading: false,
  teamDiskSubscriberChanged: false,
};

const myDiskReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRootDir.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRootDir.fulfilled, (state, action) => {
      state.loading = false;
      state.directory = action.payload;
    })
    .addCase(getRootDir.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(initRootDirs.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(initRootDirs.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(initRootDirs.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getfileNFolder.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getfileNFolder.fulfilled, (state, action) => {
      state.loading = false;
      state.fileFolderList = action?.payload?.results ?? action?.payload;
      state.selectedFile = initialState.selectedFile;
    })
    .addCase(getfileNFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getDeleteFiles.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDeleteFiles.fulfilled, (state, action) => {
      state.deletedfileFolderList = action.payload.results;
      state.loading = false;
    })
    .addCase(getDeleteFiles.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(setOpenFolderItem, (state, action) => {
      state.openFolderItem = action.payload;
    })
    .addCase(setSelectedFile, (state, action) => {
      state.selectedFile = action.payload;
    })
    .addCase(startFileDownload, (state, action) => {
      state.downloading = true;
    })
    .addCase(downloadFile.fulfilled, (state, action) => {
      state.downloadedFile = action.payload;
    })
    .addCase(finishFileDownload, (state, action) => {
      state.downloading = false;
    })
    .addCase(cleanDownloadedFile, (state, action) => {
      state.downloadedFile = initialState.downloadedFile;
    })
    .addCase(setSelectedUser, (state, action) => {
      state.selectedUser = action.payload;
    })
    .addCase(deleteFile.pending, (state, action) => {
      state.loading = true;
      state.deleteSeccess = false;
    })
    .addCase(deleteFile.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteSeccess = action.payload?.id ?? true;
    })
    .addCase(deleteFile.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(showFilesUploadModal, (state, action) => {
      state.filesUploadModalVisible = true
    })
    .addCase(hideFilesUploadModal, (state, action) => {
      state.filesUploadModalVisible = false
    })
    .addCase(uploadFile.fulfilled, (state, action) => {
      state.uploadSeccess = true
    })
    .addCase(setUploadSuccess, (state, action) => {
      state.uploadSeccess = action.payload
    })
    .addCase(uploadFileByIndex.fulfilled, (state, action) => {
      const filesToUpload = [...state.filesToUpload]
      const index = parseInt(action.payload.index)
      const f = filesToUpload[index]
      if (f) {
        f.loaded = 100
        f.status = action.payload?.error ? "error" : "done"
        console.log(action.payload)
        f.errorMessage = action.payload?.error?.data?.detail ??
          action.payload?.error?.data?.messages?.[0]?.message ??
          action.payload?.error?.data?.message ??
          action.payload?.error?.message;
        filesToUpload[index] = f
      }
      state.filesToUpload = [...filesToUpload]
    })
    .addCase(addFilesToUpload, (state, action) => {
      state.filesToUpload = [...state.filesToUpload, ...action.payload];
    })
    .addCase(removeFilesToUpload, (state, action) => {
      state.filesToUpload = state.filesToUpload.filter((_, index) => !action.payload.includes(index))
    })
    .addCase(setFilesToUpload, (state, action) => {
      state.filesToUpload = [...action.payload]
    })
    .addCase(updateFileToUpload, (state, action) => {
      let { index, json } = action.payload ?? {}
      index = parseInt(index + "")
      const files_ = [...(state.filesToUpload ?? [])]
      const f = files_[index]
      if (f) {
        Object.assign(files_[index], json);
        state.filesToUpload = [...(files_ ?? [])]
      }
    })
    .addCase(renameFiles.pending, (state, action) => {
      state.loading = true;
      state.renameFilesSuccess = initialState.renameFilesSuccess;
    })
    .addCase(renameFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.renameFilesSuccess = action.payload;
    })
    .addCase(renameFiles.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(moveFiles.pending, (state, action) => {
      state.loading = true;
      state.moveFilesSuccess = initialState.moveFilesSuccess;
    })
    .addCase(moveFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.moveFilesSuccess = action.payload;
    })
    .addCase(moveFiles.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(copyFiles.pending, (state, action) => {
      state.loading = true;
      state.copyFilesSuccess = initialState.copyFilesSuccess;
    })
    .addCase(copyFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.copyFilesSuccess = action.payload;
    })
    .addCase(copyFiles.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getSubfileNFolder.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getSubfileNFolder.fulfilled, (state, action) => {
      state.loading = false;
      state.subFileFolderList = action.payload.results;
    })
    .addCase(getSubfileNFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getfileNFolderCopyMove.fulfilled, (state, action) => {
      state.fileListCopyMove = action.payload.results;
    })
    .addCase(createFolder.pending, (state, action) => {
      state.loading = true;
      state.folderCreated = false;
    })
    .addCase(createFolder.fulfilled, (state, action) => {
      state.loading = false;
      state.folderCreated = true;
    })
    .addCase(createFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteFolder.pending, (state, action) => {
      state.loading = true;
      state.deleteFolderSeccess = false;
    })
    .addCase(deleteFolder.fulfilled, (state, action) => {
      state.deleteFolderSeccess = action.payload?.id ?? true;
      state.loading = false;
    })
    .addCase(deleteFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(restoreFile.pending, (state, action) => {
      state.loading = true;
      state.fileRestored = initialState.fileRestored;
    })
    .addCase(restoreFile.fulfilled, (state, action) => {
      state.fileRestored = action.payload;
      state.loading = false;
    })
    .addCase(restoreFile.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(restoreFolder.pending, (state, action) => {
      state.loading = true;
      state.folderRestored = initialState.folderRestored
    })
    .addCase(restoreFolder.fulfilled, (state, action) => {
      state.folderRestored = action.payload;
      state.loading = false;
    })
    .addCase(restoreFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(renameFolder.pending, (state, action) => {
      state.loading = true;
      state.renameFolderSuccess = initialState.renameFolderSuccess;
    })
    .addCase(renameFolder.fulfilled, (state, action) => {
      state.renameFolderSuccess = action.payload;
      state.loading = false;
    })
    .addCase(renameFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(moveFolder.pending, (state, action) => {
      state.loading = true;
      state.moveFolderSuccess = initialState.moveFolderSuccess
    })
    .addCase(moveFolder.fulfilled, (state, action) => {
      state.moveFolderSuccess = action.payload;
      state.loading = false;
    })
    .addCase(moveFolder.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(searchFileFolder.fulfilled, (state, action) => {
      state.searchResult = action.payload
    })
    .addCase(getSharedItemUsers.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getSharedItemUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.shareItemListByUser = action.payload.results;
      // state.fileFolderList = initialState.fileFolderList;
      state.fileFolderList = action.payload.results;
    })
    .addCase(getSharedItemUsers.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getSharedDiskUsers.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getSharedDiskUsers.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload.results) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.shareUserList = action.payload.results
      }
    })
    .addCase(getSharedDiskUsers.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.message
    })
    .addCase(getSharedDiskUsersByFile.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getSharedDiskUsersByFile.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload.results) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.shareUserListByFile = action.payload.results
      }
    })
    .addCase(getSharedDiskUsersByFile.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.message
    })
    .addCase(getShareLink.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getShareLink.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload.public_share_link) {
        state.error = true;
        state.message = action.payload.message;
        state.sharelinkData = undefined
      } else {
        state.sharelinkData = action.payload
      }
    })
    .addCase(getShareLink.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.message
    })
    .addCase(createShareLink.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createShareLink.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload.public_share_link) {
        state.error = true;
        state.message = action.payload.message;
        state.sharelinkData = undefined
      } else {
        state.sharelinkData = action.payload
      }
    })
    .addCase(createShareLink.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.message
    })
    .addCase(deleteShareLink.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteShareLink.fulfilled, (state, action) => {
      state.loading = false;
      if (!action.payload.length) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.sharelinkData = undefined;
      }
    })
    .addCase(deleteShareLink.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.message
    })
    .addCase(changeTeamDiskSubscriber.pending, (state, action) => {
      state.changeTeamDiskSubscriberLoading = true;
      state.error = false;
      state.errorMessage = null;
      state.teamDiskSubscriberChanged = false;
    })
    .addCase(changeTeamDiskSubscriber.fulfilled, (state, action) => {
      state.changeTeamDiskSubscriberLoading = false;
      const { message } = action.payload ?? {};
      if (message === "request sent") {
        state.teamDiskSubscriberChanged = true;
      } else {
        state.error = true;
        state.errorMessage = message;
      }
    })
    .addCase(changeTeamDiskSubscriber.rejected, (state, action) => {
      state.changeTeamDiskSubscriberLoading = false;
      state.error = true;
      state.errorMessage = action.payload.message
    })
    .addCase(clearFile, (state, action) => {
      state.selectedFile = initialState.selectedFile;
      // state.openFolderItem = initialState.openFolderItem;
      state.copyFilesSuccess = initialState.copyFilesSuccess;
      state.moveFilesSuccess = initialState.moveFilesSuccess;
      state.renameFilesSuccess = initialState.renameFilesSuccess;
      state.renameFolderSuccess = initialState.renameFolderSuccess;
      state.deleteSeccess = initialState.deleteSeccess;
      state.deleteFolderSeccess = initialState.deleteFolderSeccess;
      state.folderCreated = initialState.folderCreated;
      state.moveFolderSuccess = initialState.moveFolderSuccess;
      state.fileRestored = initialState.fileRestored;
      state.folderRestored = initialState.folderRestored;
      state.uploadSeccess = initialState.uploadSeccess;
    })
    .addCase(getDiskSubscriptionPlan.fulfilled, (state, action) => {
      state.subscriptionPlans = action.payload
    })
    .addCase(getTeamDiskDetails.fulfilled, (state, action) => {
      state.teamDiskDetails = action.payload;
    })
    .addCase(getTeamFileNFolder.fulfilled, (state, action) => {
      state.directory = action.payload?.results;
    })
    .addCase(getUserSubscriptionDetails.fulfilled, (state, action) => {
      state.userSubscription = action.payload;
    })
    .addCase(getUserSubscriptionDetailsbyUser.fulfilled, (state, action) => {
      state.userSubscription = action.payload;
    })
    .addCase(getOccupiedDiskSize.fulfilled, (state, action) => {
      state.occupiedSize = action.payload;
    })
    .addCase(setDeleteFolderSeccess, (state, action) => {
      state.deleteFolderSeccess = action.payload;
    })
    .addCase(setDeleteSeccess, (state, action) => {
      state.deleteSeccess = action.payload;
    })
    .addCase(setTeamDiskSubscriberChanged, (state, action) => {
      state.teamDiskSubscriberChanged = action.payload;
    })
    .addDefaultCase((state, action) => { });
});

export default myDiskReducer;
