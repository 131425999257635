import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MyBLogoHeader } from 'components/common/Icons';
import SearchBar from 'components/common/SearchBar';
import { navLoggedOutLinks } from './constant';
import { getConnectionsData } from 'store/slices/connectionSlice';
import { triggerFetchPosts } from 'store/slices/postSlice';
import { getUpcomingEvents } from 'store/slices/calendarSlice';
import Button from "components/common/Button";
import moment from 'moment';

const StaticNavbar = () => {
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const DASHBOARD_URL = '/';


  const handleClick = (url) => {
    if (location?.pathname === DASHBOARD_URL) {
      const value = new Date();
      dispatch(getConnectionsData());
      // dispatch(fetchPosts());
      dispatch(triggerFetchPosts(true))
      dispatch(
        getUpcomingEvents({
          date: `${moment(value).date()}-${moment(value).month() + 1}-${moment(value).year()}`,
        }),
      );
    } else {
      navigate(url);
    }
  }

  return (
    <>
      <header className="header">
        <div className="container">
          <div className={`header-full pe-3 pe-xl-3`}>
            <button onClick={() => handleClick(DASHBOARD_URL)}>
              <MyBLogoHeader className="me-md-3" />
            </button>
            <SearchBar />
          </div>

          <div className="header-middle">
            {
            (
            <>
              <ul className="navigation">
                {!isMobile && (
                  <>
                    {navLoggedOutLinks.map((item) => {
                      const { id, icon, url } = item;
                      return (
                        <li key={id} className={location.pathname === url ? 'active' : ''}>
                          {url === DASHBOARD_URL ? (
                            <button onClick={() => handleClick(DASHBOARD_URL)}>{icon}</button>
                          ) : (
                            <Link to={url}>{icon}</Link>
                          )}
                        </li>
                      );
                    })}
                  </>
                )}
                  <Button className="btn btn-white btn-lg" onClick={() => handleClick('/')}>Log in</Button>
                  <Button className="btn btn-white btn-lg" onClick={() => handleClick('/signup')}>Sign up</Button>
              </ul>
            </>
            )}
          </div>

        </div>
      </header>
    </>
  );
};

export default StaticNavbar;
