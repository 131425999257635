import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { useEffect, useContext, useState, useRef } from 'react';
import Card from '../Card';
import { CheckMark, ChevronDown } from '../Icons';
import { userStatus } from './constant';
import { useSelector } from 'react-redux';
import { CometChat } from "@cometchat-pro/chat";
import { getUnixTimestamp } from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/common';
import { parentMessageStyle } from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Messages/CometChatMessageThread/style';


const StatusDropdown = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(userStatus[0]);
  const { userNavStatus } = useSelector(state => state.meeting);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const UID = userProfileInfo.user_uuid;
  const ref = useRef();

  useOnClickOutside(ref, () => setOpen(false));
  const handleSelect = (param) => {
    setSelected(param);
    var user = new CometChat.User(UID);
    if (param.id === 1) {
      user.setStatus('online');
      user.setMetadata({ 'currentStatus': 'online' });
    } else if (param.id === 3) {
      user.setStatus('offline');
      user.setMetadata({ 'currentStatus': 'offline' });
      user.setLastActiveAt(getUnixTimestamp());
    }  else if (param.id === 2) {
      user.setMetadata({ 'currentStatus': 'busy' });
      user.setStatus('online');
    }
    CometChat.updateCurrentUserDetails(user).then(
      user => {
        // console.log('user status', user);
      }, error => {
        console.error("error", error);
      }
    )
    setOpen(false);
  }

  const handleShow = () => {
    setOpen(prevState => !prevState)
  }

  const updateUserStatus = (status) => {
    switch (status) {
      case 'online':
        return setSelected({
          id: 1,
          title: 'Available',
          subTitle: 'Based on activity',
          color: '#0AB33E',
        });
      case 'offline':
        return setSelected({
          id: 3,
          title: 'Away',
          color: '#5B5B5B',
        });
      case 'busy':
        return setSelected({
          id: 2,
          title: 'Busy',
          subTitle: 'Mute chat notifications',
          color: '#FF0600',
        });
      default:
        return setSelected({
          id: 1,
          title: 'Available',
          subTitle: 'Based on activity',
          color: '#0AB33E',
        });
    }
  }

  const getUserStatusDetails = () => {
    CometChat.getLoggedinUser().then(
      user => {
        if (user && user?.metadata?.currentStatus === 'busy') {
          updateUserStatus(user?.metadata?.currentStatus)
        } else {
          updateUserStatus(user?.status);
        }
      }, error => {
        console.error("error getting details:", { error });
      }
    );
  }

  useEffect(() => {
    getUserStatusDetails();
    updateUserStatus(userNavStatus);
    // eslint-disable-next-line
  }, [userNavStatus]);

  return (
    <div className='status-dropdown' ref={ref}>
      <span onClick={handleShow}>
        <em style={{ background: selected.color }}>Dot</em>
        {selected.title}
        <ChevronDown />
      </span>
      {open && (
        <Card className='status-dropdown--menu'>
          {userStatus.map(status => {
            const { id, title, subTitle, color } = status;
            return (
              <div className='wrap' key={id} onClick={() => handleSelect(status)}>
                <div className='icon' style={{ backgroundColor: color }} />
                <div className='content'>
                  <p>{title}</p>
                  {subTitle && <span>{subTitle}</span>}
                </div>
                {selected.id === id && <CheckMark />}
              </div>
            )
          })}
        </Card>
      )}
    </div>
  )
};

export default StatusDropdown;