import React, { createElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { render } from 'react-dom';
import { Flex } from 'components/core';

const ExcerptContainer = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #5b5b5b;
  span {
    color: #e18c3e;
  }
`;

const Excerpt = (props) => {
  const navigate = useNavigate();
  const { truncateLength } = props;
  const handleClick = () => {
    navigate(props.to);
  };
  let isTruncated = false;
  let { text } = props;
  // if excerpt is greater than 200 characters, truncate it
  if (text?.length > truncateLength) {
    text = text.substring(0, truncateLength);
    isTruncated = true;
  }

  return (
    <span>
      <ExcerptContainer {...props} dangerouslySetInnerHTML={{ __html: text }} />
      {isTruncated && (
        <span style={{ color: props.readMoreColor, cursor: 'pointer' }} onClick={handleClick}>
          {' '}
          ... read more
        </span>
      )}
    </span>
  );
};

Excerpt.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  readMoreColor: PropTypes.string,
  truncateLength: PropTypes.number,
};

Excerpt.defaultProps = {
  text: '',
  to: '',
  readMoreColor: '#3D5A80',
  truncateLength: 120,
};

export default Excerpt;
