import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const HorizontalTabs = memo(({ children, className }) => {
  return <ul className={`tabs-container--horizontal nav nav-pills mb-3 ${className}`}>{children}</ul>;
});

const VerticalTabs = memo(({ children, className }) => {
  return <ul className={`tabs-container--vertical nav flex-column nav-pills mb-3 ${className}`}>{children}</ul>;
});

const Tab = memo(({ children, path, to, setOpenTab, openTab, activeStyle, style, disabled }) => {
  let activeClass = openTab === to ? 'active' : '';
  return (
    <li className={`nav-item`} role="presentation">
      {path ? (
        <Link
          onClick={() => setOpenTab(to)}
          className={`nav-link ${activeClass}`}
          to={`/${path}/${to}`}
          style={activeClass ? activeStyle : style}
          disabled={disabled}
        >
          {children}
        </Link>
      ) : (
        <span
          onClick={() => setOpenTab(to)}
          className={`nav-link ${activeClass}`}
          style={activeClass ? activeStyle : style}
          disabled={disabled}
        >
          {children}
        </span>
      )}
    </li>
  );
});

const TabContent = memo(({ tab, openTab, children }) => {
  return tab === openTab ? <div className="tab-content">{children}</div> : null;
});

function TabWithoutLink({ onClick, children, className, activeClass }) {
  return (
    <li className={className ? `nav-item ${className}` : 'nav-item'} role="presentation">
      <button onClick={onClick} className={activeClass ? 'nav-link active' : 'nav-link'}>
        {children}
      </button>
    </li>
  );
}

export { HorizontalTabs, VerticalTabs, Tab, TabContent, TabWithoutLink };
