import React from 'react';
import { MyBridgeAvatar, UserAvatarPopup } from '../Avatar';
import { CircleIcon, InitialsLogo } from '../Icons';

const PrimaryAvatarList = ({
  iconSize,
  icon,
  name,
  designation,
  size,
  location,
  following,
  follower,
  children,
  overlapIcon,
  user,
  mutualConnection,
}) => {
  return (
    <div className={`primary-avatar-list flex-grow-1 ${size ? size : ''}`}>
      <div className={`user-icon ${size ? size : ''}`}>
        {/* <MyBridgeAvatar size={iconSize} name={name} icon={icon} /> */}
        <UserAvatarPopup user={user} size={iconSize} name={name} icon={icon} />
        {overlapIcon && overlapIcon}
      </div>

      <div className={`content flex-grow-1 ${size ? size : ''}`}>
        {!follower ? (
          <div className="d-flex align-items-start">
            <div className={`flex-grow-1 ${size ? 'pe-2' : ''}`}>
              {name && <h4>{name}</h4>}
              {designation && <p>{designation}</p>}
              {location && <span>{location}</span>}
            </div>
            {following && (
              <div className="d-flex align-items-center followers">
                <CircleIcon width={20} height={20} className="me-2" />
                {following}
              </div>
            )}
          </div>
        ) : (
          <p></p>
        )}

        {/* {name && <h4>{name}</h4>}
        {designation && <p>{designation}</p>}
        {location && <span>{location}</span>}
        {mutualConnection && <em>{mutualConnection} mutual connection(s)</em>}
        {following || follower ? (
          <ul>
            {following && (
              <li>
                <CircleIcon width={20} height={20} className="me-2" />
                {following}
              </li>
            )}

            {follower && (
              <li>
                <InitialsLogo width={20} height={20} className="me-2" />
                {follower}
              </li>
            )}
          </ul>
        ) : null} */}

        {children}
      </div>
    </div>
  );
};

export default PrimaryAvatarList;
