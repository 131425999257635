import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';

import servicesPic from '../../../../../assets/profiles/profile/avtar_md.jpeg';
import { employmentChoices, genralMonths } from 'config/formConstants';
import '../../../../../scss/components/_timeline.scss';

const ExperienceContainer = styled.div``;

const ExperienceDetailsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: ${(props) => (props.noBorder ? '' : '1px solid #d9d9d9')};
  padding: 10px 0;
`;

const ExperienceDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExperienceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
`;

const ExperienceDetailsImage = styled.img`
  height: 50px;
`;

const ExperienceDetailsName = styled.p`
  flex-grow: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
`;

const ExperienceDetailsTimeline = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #747474;
`;

const ExperienceDetailsDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5b5b5b;
`;

const Experience = (props) => {
  const { publicProfileData } = useSelector((state) => state.userProfile);
  const { users_experiences: experiences } = publicProfileData;

  const getDiffYear = (endYear, endMonth, startYear, startMonth) => {
    let sY = startYear !== '0' ? startYear : new Date().getFullYear();
    let eY = endYear !== '0' ? endYear : new Date().getFullYear();

    let sM = startMonth !== '0' ? startMonth : '1';
    let eM = endMonth !== '0' ? endMonth : '1';

    let a = moment(`01/${eM}/${eY}`, 'DD/MM/YYYY');
    let b = moment(`01/${sM}/${sY}`, 'DD/MM/YYYY');

    const diff = moment.duration(a.diff(b));
    return `${diff.years() || '0'} years ${diff.months() || '0'} months`;
  };

  
  const formattedExperiences = experiences?.reduce((acc, experience) => {
    const {
      company_name,
      title,
      id,
      employment_type,
      location,
      industry,
      desc,
      profile_headline,
      start_month,
      start_year,
      end_month,
      end_year,
      currently_working,
    } = experience;

    // Check if the company already exists in the accumulator
    const existingCompany = acc.find((item) => item.company_name === company_name);

    if (existingCompany) {
      // If the company exists, append the current experience to its "experiences" array
      existingCompany.children.push({
        title,
        id,
        employment_type,
        location,
        industry,
        desc,
        profile_headline,
        start_month,
        start_year,
        end_month,
        end_year,
        currently_working,
      });
    } else {
      // If the company doesn't exist, create a new object for the company
      const newCompany = {
        company_name,
        title,
        location,
        industry,
        employment_type,
        start_month,
        start_year,
        end_month,
        end_year,
        currently_working,
        profile_headline,
        id,
        desc,
        children: [
          {
            title,
            id,
            employment_type,
            location,
            industry,
            desc,
            profile_headline,
            currently_working,
            start_month,
            start_year,
            end_month,
            end_year,
          },
        ],
      };
      acc.push(newCompany);
    }

    return acc;
  }, []);

  return (
    <ExperienceContainer>
      {formattedExperiences?.map((exp, index) => (
        <ExperienceDetailsContainer key={index} noBorder={formattedExperiences?.length == index+1}>
        <ExperienceDetailsImage src={servicesPic} />
        <ExperienceDetailsContent>
          <ExperienceDetailsHeader>
            <ExperienceDetailsName>
              {exp?.children?.length > 1 ? (
                <>
                  <strong>{exp?.company_name}</strong>
                </>
              ) : (
                <>
                  {' '}
                  {exp.title} at <strong>{exp?.company_name}</strong>
                </>
              )}
            </ExperienceDetailsName>
            
          </ExperienceDetailsHeader>
          {exp?.children?.length > 1 ? (
            <>
              <ExperienceDetailsTimeline>{exp.location}</ExperienceDetailsTimeline>
              <ExperienceDetailsDescription>
                <div class="timeline">
                  {exp?.children?.map((indChild, index) => {
                    return (
                      <div class="timeline__event timeline__event--type2">
                        <div class="timeline__event__content ">
                          <ExperienceDetailsHeader>
                            <ExperienceDetailsName>{indChild.title}</ExperienceDetailsName>
                          </ExperienceDetailsHeader>
                          <ExperienceDetailsTimeline>
                            {`${genralMonths[parseInt(indChild.start_month - 1)]?.label} ${indChild.start_year} - ${
                              indChild.currently_working
                                ? 'Present'
                                : `${genralMonths[parseInt(indChild.end_month - 1)]?.label} ${indChild.end_year}`
                            } - ${indChild.location}`}
                          </ExperienceDetailsTimeline>

                          {indChild.end_year && (
                            <ExperienceDetailsTimeline>
                              <p>{`${getDiffYear(
                                indChild.end_year,
                                indChild.end_month,
                                indChild.start_year,
                                indChild.start_month,
                              )}`}</p>
                            </ExperienceDetailsTimeline>
                          )}

                          <ExperienceDetailsTimeline>
                            {indChild.industry} -{' '}
                            {employmentChoices.find((item) => item.value === indChild?.employment_type)?.label}
                          </ExperienceDetailsTimeline>

                          <div class="timeline__event__description">
                            <p>{indChild.desc}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ExperienceDetailsDescription>
            </>
          ) : (
            <>
              <ExperienceDetailsTimeline>
                {`${genralMonths[parseInt(exp.start_month - 1)]?.label} ${exp.start_year} - ${
                  exp.currently_working
                    ? 'Present'
                    : `${genralMonths[parseInt(exp.end_month - 1)]?.label} ${exp.end_year}`
                } - ${exp.location}`}
              </ExperienceDetailsTimeline>

              {exp.end_year && (
                <ExperienceDetailsTimeline>
                  <p>{`${getDiffYear(exp.end_year, exp.end_month, exp.start_year, exp.start_month)}`}</p>
                </ExperienceDetailsTimeline>
              )}

              <ExperienceDetailsTimeline>
                {exp.industry} - {employmentChoices.find((item) => item.value === exp?.employment_type)?.label}
              </ExperienceDetailsTimeline>

              <ExperienceDetailsDescription>{exp.desc}</ExperienceDetailsDescription>
            </>
          )}
        </ExperienceDetailsContent>
      </ExperienceDetailsContainer>
        // <ExperienceDetailsContainer key={index} noBorder={experiences?.length <= 1}>
        //   <ExperienceDetailsImage src={servicesPic} />
        //   <ExperienceDetailsContent>
        //     <ExperienceDetailsHeader>
        //       <ExperienceDetailsName>
        //         {exp.title} at <strong>{exp?.company_name}</strong>
        //       </ExperienceDetailsName>
        //     </ExperienceDetailsHeader>

        //     <ExperienceDetailsTimeline>
        //       {`${genralMonths[parseInt(exp.start_month - 1)]?.label} ${exp.start_year} - ${
        //         exp.currently_working
        //           ? 'Present'
        //           : `${genralMonths[parseInt(exp.end_month - 1)]?.label} ${exp.end_year}`
        //       } - ${exp.location}`}
        //     </ExperienceDetailsTimeline>

        //     {exp.end_year && (
        //       <ExperienceDetailsTimeline>
        //         <p>{`${getDiffYear(exp.end_year, exp.end_month, exp.start_year, exp.start_month)}`}</p>
        //       </ExperienceDetailsTimeline>
        //     )}

        //     <ExperienceDetailsTimeline>
        //       {exp.industry} - {employmentChoices.find((item) => item.value === exp?.employment_type)?.label}
        //     </ExperienceDetailsTimeline>

        //     <ExperienceDetailsDescription>{exp.desc}</ExperienceDetailsDescription>
        //   </ExperienceDetailsContent>
        // </ExperienceDetailsContainer>
      ))}
    </ExperienceContainer>
  );
};

export default Experience;
