const Checkbox = ({ label, className, name, checked, onChange, ...props }) => {
  return (
    <div className={`form-group custom-checkbox ${className}`}>
      <label>
        <input type="checkbox" checked={checked} name={name} onChange={onChange} {...props} />
        <span>{label ?? <>&nbsp;</>}</span>
      </label>
    </div>
  );
};
export default Checkbox;