export const DiskListingHeader = ({ isMobile,diskType, ...props }) => {
    return (
        <div className="headers" {...props}>
            <div className="hd1">Name</div>
            {!isMobile && (
                <>
                    <div className="hd2">Modified</div>
                    <div className="hd3">Size</div>
                    <div className="hd3">More</div>
                </>
            )}
        </div>
    )
}