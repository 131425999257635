import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

export const followRequestFunc = async ({ to }) => {
  try {
    const obj = {
      url: APIEndPoints.Follower,
      body: JSON.stringify({ follow_to: to }),
      method: 'POST',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
