import React from "react";

const Dropdown = ({ id, name, options, width, className, handleChange, selectedValue, containerClass, error, defaultValue = "Select", customDefault = true, disabled }) => (
  <div className={containerClass ? `form-group ${containerClass}` : "form-group"} style={{width:width}}>
    <select defaultValue={defaultValue} name={name} className={`form-select ${className}`} id={id} onChange={handleChange} value={selectedValue} disabled={disabled}>
      {customDefault ? <option value="">{defaultValue}</option> : ""}
      {options && options.length && options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && <div className="text-danger helper-text">{error}</div>}
  </div>
);

export default Dropdown;
