import { getUserFullName } from "components/MyDisk/common/utils"
import { UserIcon } from "components/common/Icons"
import { Flex } from "components/core"

export const PostTaggedAction = ({ tagged, setShowTagModal }) => {
    return <Flex className='tag-people-link' align="center" onClick={e => setShowTagModal(true)}>
        <UserIcon />
        <a className='ps-2'>
            {tagged?.length ? `${getUserFullName(tagged?.[0])} ${tagged?.length > 1 ? `and ${tagged?.length - 1} others` : ""}` : "Tag People"}
        </a>
    </Flex>
}