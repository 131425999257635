import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';

import banner from 'assets/profiles/banner/banner.png';
import myBridge from 'assets/icons/myBridge.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'components/StyledComponents';
import { HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import Loader from 'components/common/Loader';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { getFollowCompany, getUnFollowCompany, getCompanyPageFollowers } from 'store/actions/companyPages.Action';

const Banner = (props) => {
  const { openTab, setOpenTab, companyDetails } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const USER_NAME = companyDetails?.name;
  const { companyPageReducer, userProfile, isCompanyFollowed } = useSelector((state) => state);
  const { companyPublicData, companyFollowers } = companyPageReducer;
  const { userProfileInfo } = userProfile;
  const slug = companyDetails?.slug;
  const [unFollow, setUnFollow] = useState(false);
  const gotoPublicProfile = () => {
    navigate(`/c/${slug}/overview`);
  };

  const followCompany = (isUnFollow) => {
    if (!isUnFollow) {
      dispatch(getFollowCompany(companyDetails?.id));
    } else {
      dispatch(getUnFollowCompany(companyDetails?.id));
    }
    dispatch(getCompanyPageFollowers({ id: companyDetails?.id }));
  };

  let BgImage = companyDetails?.banner_image;

  if (!BgImage || BgImage.includes('banner-placeholder.png')) {
    BgImage = banner;
  }
  useEffect(() => {
    dispatch(getCompanyPageFollowers({ id: companyDetails?.id }));
  }, []);
  useEffect(() => {
    setUnFollow(companyFollowers.some((follower) => follower['id'] === userProfileInfo.id));
  }, [companyFollowers]);

  const customCSS = {
    imgBg: css`
      background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 100%), url(${BgImage}), #ffffff;
      background-size: cover;
    `,
  };

  return (
    <section>
      <div className="bordered-container">
        <div className={`Profile_Banner ${cx(customCSS.imgBg)}`}>
          <div className="banner company-profile-banner p-0">
            {BgImage ? <img src={BgImage} className="img-fluid" alt="banner" /> : <Loader secondary />}
          </div>
        </div>

        <div className="container" style={{ position: 'relative' }}>
          <div className="user">
            <div className="avatar company-avtar">
              <MyBridgeAvatar
                icon={companyDetails?.image}
                name={USER_NAME ? USER_NAME : 'Company'}
                size={120}
                className="company-avatar"
              />
            </div>

            <div className="left">
              <div className="about">
                <div className="info">
                  <Text fW={600} fS="18px" color="#5B5B5B">
                    {companyDetails?.name}
                  </Text>
                </div>

                <div className="personal_info">
                  <Text color="#868585">
                    {companyDetails?.address} | {companyDetails?.state_region}, {companyDetails?.country}
                  </Text>
                </div>
              </div>
            </div>

            <div className="right">
              {companyDetails?.follower_count > 0 && (
                <div className="mybridge_count mb-3">
                  <div className="count">
                    <div className="circle">
                      <>
                        <img src={myBridge} className="icon" alt="circle" />
                        <Text fS="16px" color="#868585">
                          {companyDetails?.follower_count}
                        </Text>
                      </>
                    </div>
                  </div>
                </div>
              )}
              <div className="actions">
                <button
                  type="button"
                  className={`${
                    companyPublicData.user === userProfileInfo.id ? 'disabled' : ''
                  } btn btn-sm profile-action-btn`}
                  onClick={() => followCompany(unFollow)}
                  style={{ background: '#3D5A80', color: 'white' }}
                >
                  <span>{!unFollow ? 'Follow' : 'Unfollow'} </span>
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <HorizontalTabs className="mb-0 company-public_tabs_item">
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`c/${slug}`} to="overview">
                Overview
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`c/${slug}`} to="posts">
                Posts
              </Tab>
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={`c/${slug}`} to="jobs">
                Jobs
              </Tab>
            </HorizontalTabs>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
