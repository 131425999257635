import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

export const createNewBlogFunc = async ({ body }) => {
  try {
    const obj = {
      url: APIEndPoints.CreateBlog,
      method: 'POST',
      body: body,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editBlogFunc = async ({ id, body }) => {
  try {
    const obj = {
      url: APIEndPoints.editBlog(id),
      body: body,
      isFormData: true,
      method: 'PUT',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getBlogsByStatus = async ({ queryKey }) => {
  const status = queryKey[1];
  try {
    const obj = {
      url: status === 'published' ? APIEndPoints.listPublished : APIEndPoints.listBlog,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const publishBlogFunc = async ({ postId, method }) => {
  try {
    const obj = {
      url: APIEndPoints.publishBlog(postId),
      method: method,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getMybridgeArticlesFunc = async ({ pageParam = 1 }) => {
  try {
    const obj = {
      url: APIEndPoints.GetNews(pageParam, 20),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const bookMarkBlogFunc = async ({ blogId }) => {
  try {
    const obj = {
      url: APIEndPoints.BookmarkBlog(blogId),
      method: 'POST',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const removeBookMarkBlogFunc = async ({ blogId }) => {
  try {
    const obj = {
      url: APIEndPoints.BookMarkRemove(blogId),
      method: 'DELETE',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
