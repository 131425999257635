import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

const initialState = {
  data: [],
  loading: false,
  flag: false,
};

export const triggerFetchPosts = createAction("TriggerFetchPosts");

export const fetchPosts = createAsyncThunk('fetchPosts', async (body) => {
  const obj = {
    url: APIEndPoints.Post(1, body),
  };
  return await API(obj);
});

export const fetchCompanyPosts = createAsyncThunk('fetchCompanyPosts', async (body) => {
  const obj = {
    url: APIEndPoints.CompanyPostPublic(body.page, body.size, body.companyId),
  };
  return await API(obj);
});

export const fetchUpdatedPosts = createAsyncThunk('fetchUpdatedPosts', async (body) => {
  const obj = {
    url: APIEndPoints.Post(),
  };
  return await API(obj);
});

export const postDislike = createAsyncThunk('postDislike', async (body) => {
  const obj = {
    url: APIEndPoints.PostDislike(body.id),
    method: 'DELETE',
    body: JSON.stringify(body.requestBody),
  };

  return await API(obj);
});

export const postLike = createAsyncThunk('postLike', async (body) => {
  const obj = {
    url: APIEndPoints.PostLike(body.id),
    method: 'PUT',
    body: JSON.stringify(body.requestBody),
  };

  return await API(obj);
});

export const postRepost = createAsyncThunk('postRepost', async (body) => {
  const obj = {
    url: APIEndPoints.PostRepost(body.id),
    method: body.method,
  };

  return await API(obj);
});

export const pinPost = createAsyncThunk('pinPost', async (body) => {
  const obj = {
    url: APIEndPoints.PinPost(body.id),
    method: 'POST',
  };

  return await API(obj);
});

export const postDelete = createAsyncThunk('postDelete', async (body) => {
  const obj = {
    url: APIEndPoints.PostDelete(body.id),
    method: 'DELETE',
  };

  return await API(obj);
});

export const newPost = createAsyncThunk('newPost', async (body) => {
  const obj = {
    url: APIEndPoints.NewPost,
    method: 'POST',
    body: body,
    isFormData: true,
  };

  return await API(obj);
});

export const updatePost = createAsyncThunk('updatePost', async (body) => {
  const obj = {
    url: APIEndPoints.UpdatePost(body.id),
    method: 'PUT',
    body: body.formData,
    isFormData: true,
  };

  return await API(obj);
});

export const ignorePost = createAsyncThunk('ignorePost', async (body) => {
  const obj = {
    url: APIEndPoints.IgnorePost(body.id),
    method: 'POST',
  };

  return await API(obj);
});

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPosts.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPosts.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchPosts.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    [fetchCompanyPosts.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCompanyPosts.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchCompanyPosts.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    [fetchUpdatedPosts.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [updatePost.pending]: (state, action) => {
      state.flag = true;
    },
    [updatePost.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [updatePost.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [ignorePost.pending]: (state, action) => {
      state.flag = true;
    },
    [ignorePost.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [ignorePost.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [postLike.pending]: (state, action) => {
      state.flag = true;
    },
    [postLike.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [postLike.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [postDelete.pending]: (state, action) => {
      state.flag = true;
    },
    [postDelete.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [postDelete.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [postDelete.pending]: (state, action) => {
      state.flag = true;
    },
    [postDelete.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [postDelete.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [pinPost.pending]: (state, action) => {
      state.flag = true;
    },
    [pinPost.fulfilled]: (state, action) => {
      state.flag = true;
    },
    [pinPost.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [postDislike.pending]: (state, action) => {
      state.flag = true;
    },
    [postDislike.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [postDislike.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [postRepost.pending]: (state, action) => {
      state.flag = true;
    },
    [postRepost.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [postRepost.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },

    [newPost.pending]: (state, action) => {
      state.flag = true;
    },
    [newPost.fulfilled]: (state, action) => {
      state.flag = false;
    },
    [newPost.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },
    [triggerFetchPosts]: (state, action) => {
      state.refetchPosts = action.payload
    }
  },
});

export default postSlice.reducer;
