/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import NetworkuserCard from 'components/Network/NetworkUserCard';

const NetworkSuggestions = (props) => {
    const { suggestions } = props;
    const userList = suggestions?.follow;
    return (
        <div className='invite-section'>
            <div className='invite-container mb-3'>
                <div className='pt-3 pb-3 d-flex justify-content-between'>
                    <p className='m-0'>People you may know based on who you follow</p>
                    <a href='#'>See all</a>
                </div>
                <div className="row">
                    {userList?.map((user, i) =>
                        <div className='col-lg-4 col-md-6' key={i}>
                            <NetworkuserCard user={user} />
                        </div>
                    )}
                </div>
            </div>




        </div>
    )
}

export default NetworkSuggestions;