import { Input, TextArea } from 'components/common/Input';

import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { askTestimonal, giveTestimonal, giveTestimonalForAsked } from 'store/actions/testimonial.Action';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {} from 'preact/hooks';
import Button from 'components/common/Button';

const getPredefinedMessage = (selectedUser, userProfileInfo) => {
  return `Hi ${selectedUser?.label || ''} \nHere is a testimonial you can include on your profile.\n- ${
    userProfileInfo?.first_name
  } ${userProfileInfo?.last_name} \nwww.mybridge.me/${userProfileInfo?.slug}`;
};

const GiveTestimonialModal = (props) => {
  const { onClose, preTestimonialData } = props;
  const dispatch = useDispatch();

  const { data: connectionData } = useSelector((state) => state.connection);
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const [testimonialData, setTestimonialData] = useState();
  const [selectedUser, setSelectedUser] = useState({});
  const [isUserPresent,setIsUserPresent] = useState(false);
  let predefinedMessage =""
  //  getPredefinedMessage(selectedUser, userProfileInfo);

  useEffect(() => {
    if (preTestimonialData) {
      connectionData?.results?.forEach((item) => {
        if (item.id === preTestimonialData.asker.id) {
          setSelectedUser({ value: item.id, label: item.first_name + ' ' + item.last_name });
          setIsUserPresent(true)
        }
      });
      setTestimonialData((state) => ({
        ...state,
        testimonial: preTestimonialData.testimonial,
        message: preTestimonialData.message,
      }));
    }
    // eslint-disable-next-line
  }, [preTestimonialData]);

  const setData = (e) => {
    const { name, value } = e.target;
    setTestimonialData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const giveTestimonialToUser = () => {
    const payload = {
      ...testimonialData,
      asker: selectedUser.value,
      form_type: 'GIVE',
    };

    if (!payload.message) payload.message = predefinedMessage;

    if (preTestimonialData?.id) {
      payload.id = preTestimonialData.id;
      dispatch(giveTestimonalForAsked(payload)).then(() => {
        setTestimonialData();
        onClose();
      });
    } else {
      dispatch(giveTestimonal(payload)).then(() => {
        setTestimonialData();
        onClose();
      });
    }
  };

  const handleSelect = (data) => {
    setSelectedUser(data);
  };

  const connectionOptions = () => {
    return connectionData?.results?.map((t) => {
      return { value: t.id, label: t.first_name + ' ' + t.last_name };
    });
  };
  const memoizedConnectionOptions = useMemo(connectionOptions, [connectionData]);

  return (
    <div className="testimonial-modal-body">
      <div>
        <p>Who do you want to recommend?</p>
        <Select
          className="dropdown mb-3"
          options={memoizedConnectionOptions}
          placeholder="Search Connection"
          value={selectedUser}
          onChange={handleSelect}
          isSearchable={true}
          isDisabled={isUserPresent}
          styles={{
            container: (style, action) => ({
              ...style,
              width: '100%',
              borderRadius: '8px',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: '8px',
              border: '1px solid #d9d9d9',
              width: '100%',
              background: '#F8F8F8',
              padding: '0.313rem .25rem 0.313rem 0.5rem',
            }),
            indicatorsContainer: (provide, state) => ({
              ...provide,
            }),
          }}
        />
        {/* <Input type="text" placeholder="Search Person" name="asker" onChange={setData} /> */}

        {/* <p>Write a Testimonial</p>
        <p className="label-text">If needed, you can make changes or delete it even after you send it.</p> */}

        <TextArea
          placeholder={'Ex: Brian is a brilliant internet marketing presenter with many years of experience....'}
          className={'testimonial-data'}
          value={testimonialData?.testimonial}
          onChange={setData}
          name="testimonial"
        />

        <p style={{ marginTop: '1rem' }}>Write a message to {selectedUser?.label ?`${selectedUser?.label} to`:''}  send with your testimonial.</p>
        <TextArea
          placeholder={'Ex: Brian is a brilliant internet marketing presenter with many years of experience....'}
          className={'testimonial-msg'}
          value={predefinedMessage}
          onChange={setData}
          style={{marginBottom:0}}
          name="message"
        />
         <p className="label-text">Note: {selectedUser?.label || ''} will be asked to approve your changes to this testimonial</p>
      </div>
      <div className="mt-4 text-end">
        <Button variant="btn-secondary" className="h-36" data-dismiss="modal" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="btn-primary"
          className="ms-3 h-36"
          data-dismiss="modal"
          // onClick={preTestimonialData ? () => give_Testimonal_ForAsked(preTestimonialData.id) : giveRequest}
          onClick={giveTestimonialToUser}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default GiveTestimonialModal;
