import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import lodash from 'lodash';

import Pills from 'components/common/Pills';
import Button from 'components/common/Button';
import { BorderLessButton } from 'components/StyledComponents';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { AddCircle, HandShakeSimpleIcon } from 'components/common/Icons';

import AutocompleteWithPills from 'components/common/AutocompleteWithPills';

import { addSkill, deleteSkill, getProfileSkills } from 'store/slices/userProfileSlice';
import { getGlobalSkills } from 'store/slices/globalDataSlice';

const MAX_SKILLS = 5;

const SkillHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  flex-wrap: wrap;
  gap: 10px;
`;

const SkillBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 11px;
  gap: 6px;
  background: #f1f1f1;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;

const SkillReactions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 0px;
  gap: 10px;
  background: #f1f1f1;
  align-items: center;
`;

const SkillCount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

const SkillName = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

const SeeMoreLessText = styled.p`
  color: var(--heading, #3d5a80);
  font-size: 15px;
  font-family: Inter;
  line-height: 30px;
  cursor: pointer;
`;

const Skills = (props) => {
  const dispatch = useDispatch();
  const { profileSkillsList: userSkills } = useSelector((state) => state.userProfile);
  const { globalSkills } = useSelector((state) => state.globalData);
  const [isSeeMore, setSeeMore] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skills, setSkills] = useState([]);
  const [error, setError] = useState('');

  /* Get Available skills in system  */
  useEffect(() => {
    dispatch(getGlobalSkills());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSave = () => {
    // add fix for new skills
    let newSkills = skills.map((e) => ({ name: e }));
    newSkills = lodash.differenceBy(newSkills, userSkills, 'name');
    if (newSkills.length <= 0 && userSkills.length !== 0) {
      setError('Add skills already exist. Please select new skills.');
      return;
    }

    const totalSkills = lodash.unionBy(newSkills, userSkills, 'name');

    // if (totalSkills.length > MAX_SKILLS) {
    //   setError(`Upto ${MAX_SKILLS} skills are allowed`);
    //   return;
    // }

    const payload = newSkills.map((e) => ({
      agreed_user: [],
      name: e.name,
      is_active: true,
    }));

    dispatch(addSkill(payload)).then((res) => {
      dispatch(getProfileSkills());
      setAddMode(false);
      setError('');
    });
  };

  const removeSkill = (id) => {
    dispatch(deleteSkill(id)).then((res) => {
      dispatch(getProfileSkills());
      // setEditMode(false);
      setError('');
    });
  };
  let subItem = '';
  if (!addMode) {
    return (
      <>
        <SkillHeader>
          <div>
            <AddCircle />
            <BorderLessButton onClick={() => setAddMode(true)}>Add Skills</BorderLessButton>
          </div>

          <BorderLessButton onClick={() => setEditMode((editMode) => !editMode)}>
            {editMode ? 'Done' : 'Edit'}
          </BorderLessButton>
        </SkillHeader>

        <SkillContainer>
          {userSkills?.slice(0, isSeeMore ? userSkills.length : 3)?.map((item) => {
            if (editMode) {
              return <Pills key={item.id} label={item.name} onClose={() => removeSkill(item.id)} />;
            }

            return (
              <SkillBox key={item.id}>
                <SkillName>{item.name}</SkillName>

                {item.agreed_user_count > 0 && (
                  <SkillReactions>
                    <HandShakeSimpleIcon width="20" height="20" color={'#6BBB84'} className={'me-1 cursor-disable'} />
                    <div style={{ display: 'flex' }}>
                      {item?.agreed_user?.slice(0, 3).map((subItem, index) => (
                        <div className="user-pic">
                          <MyBridgeAvatar
                            icon={subItem?.profile_pic}
                            size={18}
                            margin={'0px -3px'}
                            name={`${subItem?.first_name} ${subItem?.last_name}`}
                          />
                        </div>
                      ))}
                    </div>
                    <SkillCount>
                      {item?.agreed_user_count > 3 ? `+${item?.agreed_user_count} agreed` : `agreed`}
                    </SkillCount>
                  </SkillReactions>
                )}
              </SkillBox>
            );
          })}
          {userSkills?.length > 3 && (
            <SeeMoreLessText onClick={() => setSeeMore(!isSeeMore)}>See {!isSeeMore ? 'more' : 'less'}</SeeMoreLessText>
          )}
        </SkillContainer>
      </>
    );
  }

  return (
    <>
      <SkillHeader>
        <BorderLessButton>Add Skills</BorderLessButton>
      </SkillHeader>

      <SkillContainer column>
        <AutocompleteWithPills
          options={globalSkills.map((item) => item.name)}
          placeholder={'Search for skill'}
          setData={setSkills}
        />

        <div className="d-flex gap-2 justify-content-end mt-4">
          <Button variant="btn-secondary" className="h-36" onClick={() => setAddMode(false)}>
            Cancel
          </Button>
          <Button variant="btn-primary" className="h-36" onClick={onClickSave}>
            Save
          </Button>
        </div>
        {error && <p className="color-red">{error}</p>}
      </SkillContainer>
    </>
  );
};

export default Skills;
