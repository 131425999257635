import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from 'components/common/Loader';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Post from './postEdit';
import Draft from './draft';
import styles from './style.module.scss';
import { Flex, Text } from 'components/core';
import styled from 'styled-components';
import queryString from 'query-string';
import { store } from 'store/store';

const CreatePost = () => {
  const { loading } = useSelector((state) => state.blog);
  const { status } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { is_staff } = userProfileInfo;
  const parsed = queryString.parse(search);
  const { blogType } = parsed;
  const isBlogEditorRoute = pathname.includes('new');

  // const { newBlog } = useSelector((state) => state.blog);
  // const isBlogEmpty = Object.keys(newBlog).length === 0;

  const handleSelect = (key) => {
    const storeState = store.getState();
    const { newBlog } = storeState.blog;
    const { blogType = 1 } = newBlog;
    navigate(`/blog/${key}?blogType=${blogType}`);
  };

  return (
    <React.Fragment>
      <section className="primary-layout py-4">
        <div className={`${styles.blogCont} container`}>
          {loading && <Loader />}
          <Tab.Container onSelect={handleSelect} id="left-tabs-example" activeKey={status} defaultActiveKey={status}>
            <Row className={styles.blogRow}>
              <Col className={styles.tbBlog} sm={3}>
                <h2>Blog</h2>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="new">
                      New article
                      {/* {!isBlogEmpty && <span style={{ color: 'blue' }}>one draft</span>} */}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="draft">myDrafts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="published">myArticles</Nav.Link>
                  </Nav.Item>
                </Nav>

                {is_staff && isBlogEditorRoute && (
                  <Flex position="absolute" bottom="0px" left="10px" margin="0px 10px" direction="column">
                    <Text.Title level={6} secondary margin="0px 0px 20px 0px">
                      Publish as
                    </Text.Title>
                    <Flex direction="column">
                      <Flex margin="0px 0px 10px 0px">
                        <RadioButton
                          type="radio"
                          name="blogType"
                          onClick={() => navigate(`${pathname}?blogType=1`)}
                          checked={blogType === '1'}
                        />
                        <RadioLabel>Blog (Public user)</RadioLabel>
                      </Flex>
                      <Flex>
                        <RadioButton
                          type="radio"
                          name="blogType"
                          onClick={() => navigate(`${pathname}?blogType=2`)}
                          checked={blogType === '2'}
                        />
                        <RadioLabel>Admin (myBridge article)</RadioLabel>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Col>
              <Col
                className={styles.blogContent}
                sm={9}
                style={{ borderLeft: '1px solid #e4e6eb', padding: '10px 38px' }}
              >
                <Tab.Content>
                  <Tab.Pane eventKey="new">{status === 'new' && <Post />}</Tab.Pane>
                  <Tab.Pane eventKey="draft">
                    <Draft />
                  </Tab.Pane>
                  <Tab.Pane eventKey="published">
                    <Draft />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </React.Fragment>
  );
};
export default CreatePost;

const RadioButton = styled.input`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: #3d5a80;
  border: 1px solid #3d5a80;
`;

const RadioLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #3d5a80;
`;
