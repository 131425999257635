import { Input, TextArea } from 'components/common/Input';
import React, { useMemo, useState, useEffect } from 'react';
import Card from 'components/common/Card';
import Checkbox from 'components/common/Checkbox';
import RadioButton from 'components/common/RadioButton';
import Dropdown from 'components/common/Dropdown';
import { EMPLOYMENT_CHOICES, CURRENCY, PAYMENT_TYPE, BENEFITS, WORKPLACE_TYPE } from 'data/data';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import EditorConvertToHTML from 'components/common/Editor';
// import Select from 'react-select';
import { getAllCompanyPages } from 'store/actions/companyPages.Action';
import makeAnimated from 'react-select/animated';
import { postNewJob, UpdateJob } from 'store/actions/companyJobs.Actions';
import { getGlobalSkills } from 'store/slices/globalDataSlice';

import { useNavigate } from 'react-router-dom';
// import AutoComplete from 'components/common/AutoComplete';
import { clear } from 'store/actions/Job.Action';
import AutoComplete2 from 'components/common/AutoComplete2';

const PostJob = () => {
  const [toolbarOptions] = useState(['inline', 'list', 'textAlign']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyPageList } = useSelector((state) => state.companyPageReducer);
  const { editDetails } = useSelector((state) => state.jobReducer);
  const { skillsList } = useSelector((state) => state.globalData);
  const [jobData, setJobData] = useState();
  const [skills, setSkills] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [jobPostedSuccess, setJobPostedSuccess] = useState(false);
  const [jobDescription, setJobDescription] = useState();
  useEffect(() => {
    dispatch(getAllCompanyPages());
    dispatch(getGlobalSkills());
    if (editDetails) {
      setJobData(editDetails);
      setBenefits(editDetails?.benefits);
      let selectedSkills = editDetails?.skills?.map((skill) => {
        return { value: skill.name, label: skill.name };
      });
      // console.log(selectedSkills)
      setJobDescription(editDetails.job_description);
      setSkills(selectedSkills);
    }
    // eslint-disable-next-line
  }, []);

  const setData = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'plus_comission') {
      if (checked) {
        setJobData((state) => ({
          ...state,
          [name]: true,
        }));
      } else {
        setJobData((state) => ({
          ...state,
          [name]: false,
        }));
      }
    }
    if (name === 'hiring_company') {
      if (value === 'Create Company') {
        navigate('/create_page');
      } else {
        setJobData((state) => ({
          ...state,
          [name]: parseInt(value),
        }));
      }
    } else {
      setJobData((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const setDataValue = (name, data) => {
    setJobData((state) => ({
      ...state,
      [name]: data.name,
    }));
  };

  const setEditorData = (name, data) => {
    setJobData((stats) => ({
      ...stats,
      [name]: data,
    }));
  };
  const handleSelect = (data) => {
    setSkills(data);
  };
  const companyOption = useMemo(
    () =>
      companyPageList?.map((t, index) => {
        return { value: t.id, label: t.name };
      }),
    [companyPageList],
  );
  let finalCompanyList = [...companyOption, { value: 'Create Company', label: 'Create Company' }];

  const skillsOption = useMemo(
    () =>
      skillsList &&
      skillsList.length &&
      skillsList?.map((skill) => {
        return { value: skill.name, label: skill.name };
      }),
    [skillsList],
  );

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...benefits];
    if (event.target.checked) {
      updatedList = [...benefits, event.target.value];
    } else {
      updatedList.splice(benefits.indexOf(event.target.value), 1);
    }
    setBenefits(updatedList);
  };

  const savePostJobs = (isDraft) => {
    let finalSkills = [];
    for (let i = 0; i < skills.length; i++) {
      finalSkills.push(skills[i].label);
    }
    const payload = {
      ...jobData,
      is_draft: isDraft ? true : false,
      published_date: isDraft ? null : new Date(),
      benefits,
      skills: finalSkills,
    };
    // console.log(payload);
    dispatch(postNewJob(payload)).then((res) => {
      setJobData();
      setSkills([]);
      setBenefits(undefined);
      setJobPostedSuccess(true);
      navigate(-1);
    });
  };

  const updateJobDetails = () => {
    let finalSkills = [];
    for (let i = 0; i < skills.length; i++) {
      finalSkills.push(skills[i].label);
    }
    const payload = {
      ...jobData,
      is_draft: false,
      published_date: new Date(),
      benefits,
      skills: finalSkills,
    };
    // console.log(payload);
    dispatch(UpdateJob(payload)).then((res) => {
      dispatch(clear());
      setJobData();
      setSkills([]);
      setBenefits(undefined);
      setJobPostedSuccess(true);
      navigate(-1);
    });
  };
  const goBack = () => {
    navigate(-1);
  };
  // console.log(skills);
  const animatedComponents = makeAnimated();
  return (
    <React.Fragment>
      <section className="container">
        <Card className="p-4 mt-5">
          <h4>Post Job</h4>
          <div className="row">
            {/* <p className="label-text">Job Title*</p> */}
            <Input placeholder="Job Title" type="text" onChange={setData} name={'title'} value={jobData?.title} />
            <Input
              placeholder="Job Location"
              type="text"
              onChange={setData}
              name={'location'}
              value={jobData?.location}
            />
            <Input
              placeholder="Street Address"
              type="text"
              onChange={setData}
              name={'address'}
              value={jobData?.address}
            />
          </div>
          {/* <div className="row mb-3">
            <p>*Can this role be performed as a “remote work from home” job with no on-site work required?</p>
            <div className="d-flex mt-2">
              <RadioButton name="remote" value="Y" label="Yes" labelClass="label-text" changed={setData} isSelected={jobData?.remote === "Y"}/>
              <RadioButton
                name="remote"
                value="N"
                label="No"
                className="ms-3"
                labelClass="label-text"
                changed={setData}
                isSelected={jobData?.remote === "N"}
              />
            </div>
          </div> */}

          <div className="row">
            <p>*Employment Type</p>
            <Dropdown
              id={'employment_type'}
              name={'employment_type'}
              options={EMPLOYMENT_CHOICES}
              title={'employment_type'}
              handleChange={setData}
              defaultValue="Select Employment Type"
              selectedValue={jobData?.employment_type}
            />
          </div>

          <div className="row">
            <p>*Workplace type</p>
            {/* <Dropdown
              id={'workplace_type'}
              name={'workplace_type'}
              options={WORKPLACE_TYPE}
              title={'workplace_type'}
              handleChange={setData}
              defaultValue="Select Workplace type"
              selectedValue={jobData?.workplace_type}
            /> */}
            <Dropdown
              id={'remote'}
              name={'remote'}
              options={WORKPLACE_TYPE}
              title={'remote'}
              handleChange={setData}
              defaultValue="Select Workplace type"
              selectedValue={jobData?.workplace_type}
            />
          </div>

          <div className="row mb-3">
            <p>*Job Description</p>
            <EditorConvertToHTML
              toolbarOptions={toolbarOptions}
              toolbardropDown={false}
              setEditorData={setEditorData}
              name="job_description"
              defaultValue={jobDescription}
              editorClass={'page-textarea'}
            />
          </div>

          <div className="row mb-3">
            <div>
              <p>*Benefits</p>
              <div className="d-flex">
                {BENEFITS.map((item, index) => (
                  <Checkbox
                    value={item.value}
                    name="benefits"
                    label={item.label}
                    onChange={handleCheck}
                    className={'mb-0'}
                    key={index}
                    checked={item.checked}
                    id={item.id}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <p>Compensation Range</p>
            <div className="row align-items-center gap-2 mb-3">
              <div className="col-auto">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    $
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Min amount"
                    name="compensation_range_min_amt"
                    onChange={setData}
                    value={jobData?.compensation_range_min_amt}
                  />
                </div>
              </div>
              {/* <div className="col text-center"> to </div> */}
              <div className="col-auto">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    $
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Max amount"
                    name="compensation_range_max_amt"
                    onChange={setData}
                    value={jobData?.compensation_range_max_amt}
                  />
                </div>
              </div>
              <div className="col-auto">
                <Dropdown
                  id={'currency_choice'}
                  name={'currency_choice'}
                  options={CURRENCY}
                  title={'currency_choice'}
                  handleChange={setData}
                  defaultValue="Select Currency"
                  containerClass={'mb-0'}
                  selectedValue={jobData?.currency_choice}
                />
              </div>
              <div className="col-auto">
                <Dropdown
                  id={'payment_choice'}
                  name={'payment_choice'}
                  options={PAYMENT_TYPE}
                  title={'payment_choice'}
                  handleChange={setData}
                  defaultValue="Select Payment Type"
                  containerClass={'mb-0'}
                  selectedValue={jobData?.payment_choice}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-xl-12 ms-2">
              <Checkbox
                name="plus_comission"
                label={'Plus Commission'}
                onChange={setData}
                checked={jobData?.plus_comission}
              />
            </div>
          </div>
          <div className="row">
            <p>Skills</p>
            <p className="label-text">Target the excel jobseekers you need by keyword below</p>
            <AutoComplete2
              setdatabyname={setDataValue}
              tagList={skillsList}
              listedData={jobData?.skills}
              tags={jobData?.skills}
              name={'skills'}
            />

            {/* <Select
              styles={{
                container: (style, action) => ({
                  ...style,
                  width: '100%',
                  borderRadius: '8px',
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: '8px',
                  border: '1px solid #d9d9d9',
                  width: '100%',
                  background: '#F8F8F8',
                  padding: '0.313rem .25rem 0.313rem 0.5rem',
                }),
                indicatorsContainer: (provide, state) => ({
                  ...provide,
                }),
              }}
              value={skills}
              options={skillsOption}
              name="skills"
              onChange={handleSelect}
              components={animatedComponents}
              isMulti
            /> */}
          </div>
          <div className="row">
            <p>*Hiring Company</p>
            <Dropdown
              id={'hiring_company'}
              name={'hiring_company'}
              options={finalCompanyList}
              title={'hiring_company'}
              handleChange={setData}
              defaultValue="Select Hiring Company"
              selectedValue={jobData?.hiring_company}
            />
          </div>

          <div className="row">
            <p>*Why Work at this Company?</p>
            <p className="label-text">
              Give a one-line sales pitch for working at this company (140 characters max.). Note: editing this field
              will affect all jobs at this hiring company.
            </p>
            <TextArea
              placeholder="Why Work at this Company"
              className="form-control textarea"
              rows="3"
              onChange={setData}
              name={'why_work_here'}
              value={jobData?.why_work_here}
            />
          </div>

          <div className="row">
            <p>*How would you like people to apply?</p>
            <div className="form-check form-check-inline">
              <RadioButton
                changed={setData}
                name="how_to_apply"
                value="INTERNAL"
                label="Let candidates apply with their myBridge profile and notify me by email (recommended)"
                labelClass="label-text"
                isSelected={jobData?.how_to_apply === 'INTERNAL'}
              />
            </div>
            <Input
              type="text"
              name="how_to_apply_text"
              className="form-control"
              value={jobData?.how_to_apply_text}
              onChange={setData}
              disabled={jobData?.how_to_apply === 'INTERNAL' ? false : true}
            />
          </div>

          <div className="row">
            <div className="form-check form-check-inline">
              <RadioButton
                changed={setData}
                name="how_to_apply"
                value="EXTERNAL"
                label="Direct applicants to an external site to apply"
                labelClass="label-text"
              />
            </div>
            <Input
              type="text"
              name="how_to_apply_text"
              className="form-control"
              onChange={setData}
              disabled={jobData?.how_to_apply === 'EXTERNAL' ? false : true}
            />
          </div>

          {/* <div className="row">
            <p>*Workplace type</p>
            <Dropdown
              id={'workplace_type'}
              name={'workplace_type'}
              options={WORKPLACE_TYPE}
              title={'workplace_type'}
              handleChange={setData}
              defaultValue="Select Workplace type"
              selectedValue={jobData?.workplace_type}
            />
          </div> */}

          {/* <div className="row">
            <p>*Add to a project</p>
            <p className="label-text">
              Your job will be created within a project to help you manage application, source passive candidates and
              get recommended matches in one place
            </p>
            <Input type="text" className="form-control" id="exampleFormControlTextarea1" />
          </div> */}

          <div className="row mb-3">
            <div>
              <Button variant="btn-secondary" className="me-3 h-36" onClick={goBack}>
                Cancel
              </Button>

              {editDetails ? (
                <Button variant="btn-primary" className="h-36" onClick={updateJobDetails}>
                  Update & Post
                </Button>
              ) : (
                <Button variant="btn-primary" className="h-36" onClick={() => savePostJobs(false)}>
                  Save & Post
                </Button>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              Need to finish up later?{' '}
              <span className="color-primary cursor-pointer" onClick={() => savePostJobs(true)}>
                Save a draft
              </span>
            </div>
          </div>
        </Card>
      </section>
      {/* {jobPostedSuccess && (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Holy guacamole!</strong> You should check in on some of those fields below.
          <Button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => jobPostedSuccess(false)}
          ></Button>
        </div>
      )} */}
    </React.Fragment>
  );
};

export default PostJob;
