import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanDownloadedFile, downloadFile, finishFileDownload } from 'store/actions/myDisk.Action';

let interuptDownload = false;
export const useDownloadFile = () => {
    const { selectedFile, downloadedFile, downloading } = useSelector((state) => state.myDiskReducer);
    const [downloadedPercent, setDownloadedPercent] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        if (downloadedFile && downloadedFile.blob) {
            downloadStream(downloadedFile)
        }
    }, [downloadedFile])

    /**
     * Download the response stream
     * @param {*} response 
     * @returns 
     */
    const downloadStream = async response => {
        if (response.body) {
            const chunks = [];
            const reader = response.body.getReader()
            const contentLength = response.headers.get("Content-Length")
            let received = 0
            while (true) {
                const { done, value } = await reader.read()
                if (interuptDownload || done) {
                    break
                }
                chunks.push(value);
                received += value.length;
                const dp = (received / contentLength) * 100
                setDownloadedPercent(dp.toFixed(0))
            }
            dispatch(cleanDownloadedFile())
            if (interuptDownload) {
                interuptDownload = false
                return
            }
            let chunksAll = new Uint8Array(received);
            let position = 0;
            for (let chunk of chunks) {
                chunksAll.set(chunk, position);
                position += chunk.length;
            }
            const file = new Blob([chunksAll])
            dispatch(finishFileDownload())

            download(file, `${selectedFile.name}.${selectedFile.extension}`)
        }
    }

    /**
     * download as file in browser
     * @param {*} file 
     * @param {*} filename 
     */
    const download = async (file, filename) => {
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }


    /**
     * Handler download operation
     */
    const onDownload = () => {
        if (selectedFile && selectedFile.id) {
            dispatch(downloadFile(selectedFile.id))
        }
    };

    /**
     * Stop download
     */
    const onStop = () => {
        if (downloading) {
            dispatch(finishFileDownload())
            dispatch(cleanDownloadedFile())
            setDownloadedPercent(0)
            interuptDownload = true
        }
    }

    return {
        downloadedFile,
        downloading,
        downloadedPercent,
        onDownload,
        onStop
    }
}