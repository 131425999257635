import React from 'react';
import Card from 'components/common/Card';
import { EventIcon, ScheduleIcon, JoinMeetingIcon } from 'components/common/Icons';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router';
import UpcomingMeetings from 'pages/Profile/Individual/CalendarSection/UpcomingMeetings';
import { joinMeeting, startDefaultMeeting, clearJoinedMeetingData, joinAMeeting } from 'store/slices/meetingSlice';
import { useDispatch, useSelector } from 'react-redux';

const Sidebar = () => {
  const [value, onChange] = React.useState(new Date());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const handleJoinMeeting = (meetingNumber) => {
    const meetingCode = parseInt(Math.random().toFixed(6).replace('0.', ''));
    const meetingId = meetingNumber ? meetingNumber : parseInt(`6002${meetingCode}`);
    const meeting = {
      created_by: userProfileInfo,
      meetingId: meetingId,
      attendees: null,
      isWaiting: false,
    };
    if (meeting) dispatch(joinMeeting(meeting));
    // .then(() => {
    //   dispatch(clearJoinedMeetingData());
    // })
    // .catch(() => {
    //   console.error('Joining meeting failed');
    // });
  };

  return (
    <>
      <div className="sidebar mb-3" style={{ minWidth: '270px' }}>
        <Calendar
          showFixedNumberOfWeeks={7}
          onChange={(e) => {
            localStorage.setItem('dateSelectedFromDashboard', e.toISOString());
            navigate('/profile/calendar');
          }}
          value={value}
        />
      </div>
      <div className=" sidebar" style={{ minWidth: '270px' }}> 
      {/* // removed stick-top */}
        <Card className="mb-3 cursor-pointer" onClick={() => handleJoinMeeting()}>
          <EventIcon className="me-3" />
          <p>Meet Now</p>
        </Card>
        <Card className="mb-3 cursor-pointer" onClick={() => dispatch(joinAMeeting(true))}>
          <JoinMeetingIcon className="me-3" />
          <p>Join a Meeting</p>
        </Card>
        <Card
          className="cursor-pointer"
          onClick={() => {
            window.location.href = '/profile/schedule';
          }}
        >
          <ScheduleIcon className="me-3" width="27" height="27" />
          <p>Schedule a Meeting</p>
        </Card>

        <UpcomingMeetings isDashboard={true} />
      </div>
    </>
  );
};

export default Sidebar;
