import React, { useContext } from 'react';
// import { useSelector } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ChatIcon, DisagreeIcon, HandShakeSimpleIcon } from 'components/common/Icons';
import { Flex, Text } from 'components/core';
import { BorderLessButton } from 'components/StyledComponents';
import { Spinner } from 'components/common/Loader';
import PostContext from 'context/PostContext';
// import { Spinner } from 'components/common/Loader';

const FooterActions = ({ agreeing, disagreeing, level, onChatClick, onAgreeClick, onDisagreeClick }) => {
  const { commentAllowed } = useContext(PostContext)
  return (
    <Flex
      justify="space-evenly"
      align="center"
      padding="8px 0px 8px 0px"
      style={{ borderTop: '1px solid #efefef', }}
    >
      {level < 3 && commentAllowed? (
        <Flex align="center" justify="center" onClick={onChatClick}>
          <BorderLessButton width="100%">
            <ChatIcon className="me-2 cursor-pointer" width="18" height="26" />
            <Text.Label className="cursor-pointer" size="15px">Comment</Text.Label>
          </BorderLessButton>
        </Flex>
      ):""}
      <Flex align="center" justify="center">
        <BorderLessButton width="100%" onClick={onAgreeClick}>
          {agreeing ? <Spinner /> : <HandShakeSimpleIcon className="me-2 md-md-2 cursor-pointer" width="18" height="26" />}
          <Text.Label className="cursor-pointer" size="15px">Agreee</Text.Label>
        </BorderLessButton>
      </Flex>
      <Flex align="center" justify="center">
        <BorderLessButton width="100%" onClick={onDisagreeClick}>
          {disagreeing ? <Spinner /> : <DisagreeIcon className="me-2 cursor-pointer" width="18" height="26" color={'#E41C19'} />}
          <Text.Label className="cursor-pointer" size="15px">Disagree</Text.Label>
        </BorderLessButton>
      </Flex>
    </Flex>
  );
};

export default FooterActions;
