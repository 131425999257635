import { DiskIcon, HomeIcon, JobsIcon, LogoutIcon, ScheduleIcon, Settings } from 'components/common/Icons';


export const STATUSES = [
  { name: 'Available', message: 'Based on activity', status: 'available' },
  { name: 'Busy', message: 'Mute chat notifications', status: 'busy' },
  { name: 'Away', message: '', status: 'away' },
];

export const navLinks = [
  {
    id: 1,
    icon: <HomeIcon />,
    url: '/'
  },
  {
    id: 2,
    icon: <JobsIcon />,
    url: '/jobs'
  },
];



export const navLoggedOutLinks = [
  {
    id: 1,
    icon: <HomeIcon />,
    url: '/'
  }
];

export const PROFILE_OPTIONS = [
  {
    url: '/profile/calendar',
    name: 'MyCalendar',
    icon: <ScheduleIcon />,
    inactiveDisplay:false
  },
  {
    url: '/profile/mydisk',
    name: 'MyDisk',
    icon: <DiskIcon />,
    inactiveDisplay:false
  },
  {
    url: '/settings',
    name: 'Settings',
    icon: <Settings width={15} height={15} color="#5B5B5B" />,
    inactiveDisplay:true
  },
  {
    url: '/',
    name: 'Logout',
    icon: <LogoutIcon />,
    inactiveDisplay:true
  },
];

export const notifications = [
  {
    id: 1,
    type: 'handshake',
    sender: 'Samuel Oni',
    status: 'unread',
    timestamp: '5 minutes ago',
  },
  {
    id: 2,
    type: 'meeting',
    sender: 'Samuel Oni',
    status: 'unread',
    timestamp: '5 minutes ago',
    meetingTime: '10am - 11am ET, Mon 7/13',
  },
  {
    id: 3,
    type: 'meeting',
    sender: 'Samuel Oni',
    status: 'unread',
    timestamp: '5 minutes ago',
    meetingTime: '10am - 11am ET, Mon 7/13',
  },
];