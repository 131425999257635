import Authentication from "pages/Auth/Auth";
import { useSelector } from "react-redux";


const PrivateRoute = ({ children }) => {
    const { access } = useSelector(state => state.user)
    return access ?
        children :
        <Authentication />
}

export default PrivateRoute;