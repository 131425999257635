import React, { useContext } from 'react';

import PostContext from 'context/PostContext';

import OverlayList from 'components/OverlayList';
import { DisagreeIcon, HandShakeSimpleIcon } from 'components/common/Icons';
import { Flex, Text } from 'components/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { postHasInsights } from 'components/utils';

const InsightPalate = ({ setShowComments }) => {
  const { postData, commentAllowed } = useContext(PostContext);
  const { like_count, comment_count, reposts_by, dislike_count, comment_by, liked_by, dislike_by } = postData;
  const likeDislikeCount = (like_count ?? 0) + (dislike_count ?? 0)
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Flex justify="space-between" align="center" padding={postHasInsights(postData) ? "1rem 1.5rem 1rem 1.5rem" : ""}>
      <Flex flex={1} align="center" width="auto">
        {likeDislikeCount > 0 && (
          <Flex align="center" width="auto" flex={"0"}>
            {liked_by?.length ? <OverlayList data={[...liked_by]} type={'name'}>
              <div>
                <HandShakeSimpleIcon className="me-1 md-md-2" width="12" height="18" />
              </div>
            </OverlayList> : ""}
            {dislike_by?.length ? <OverlayList data={[...dislike_by]} type={'name'}>
              <div>
                <DisagreeIcon className="me-2 md-md-2" width="12" height="18" color="#E41C19" />
              </div>
            </OverlayList> : ""}
            <Text.Label size="15" style={{ marginRight: 10 }}>
              {likeDislikeCount}
            </Text.Label>
          </Flex>
        )}
      </Flex>
      {/* <Flex flex={9} /> */}
      <Flex align="center" width="auto" flex="0">
        {comment_count !== 0 && (
          <Text.Label size="15px" >
            <OverlayList data={comment_by} type={'name'}>
              <Flex>
                {/* <ChatIcon height={18} /> */}
                <Link to={`${pathname}?postId=${postData.id}`} onClick={() => {
                  // navigate(``);
                  // setShowComments?.(true);
                }} style={{ whiteSpace: "nowrap" }}>
                  {comment_count} Comments
                </Link>
              </Flex>
            </OverlayList>
          </Text.Label>
        )}
        {reposts_by?.length !== 0 && (
          <Flex padding="0 0 0 0.5rem">
            <Text.Label size="15" whiteSpace="nowrap">
              <OverlayList data={reposts_by} type={'name'}>
                <Link to={`${pathname}?postId=${postData.id}`} onClick={() => {
                }} style={{ whiteSpace: "nowrap" }}>
                  {/* {comment_count} Comments */}
                  {reposts_by?.length} Shares
                </Link>
              </OverlayList>
            </Text.Label>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default InsightPalate;
