import styled from 'styled-components';

export const PostContent = styled.div`
  font-family: 'Inter';
  line-height: 120%;
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  color: #777;
  padding: 0 1.3rem 0rem 1.3rem;
  word-break:break-all;
`;

export default PostContent;
