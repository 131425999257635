import React from 'react';
import EmailVerification from 'pages/Onboarding-ui/EmailVerification';

const InactiveUserModal = (props) => {
    const { onClose } = props;
  return (
    <div className="inactiveUserOverlay">
      <div className="inactiveModal">
              <EmailVerification type="dashboard" onClose={onClose} />
      </div>
    </div>
  );
};

export default InactiveUserModal;
