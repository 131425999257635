import React from 'react';

import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

import ErrorBoundary from 'components/ErrorBoundary';

const OverlayList = ({ data, type, children }) => {
  if (!data) return <></>;

  let list = data;
  if (type === 'name') {
    list = data?.map((e) => `${e?.first_name} ${e?.last_name}`);
  }

  return (
    <ErrorBoundary>
      <OverlayTrigger
        key={'bottom'}
        placement={'bottom'}
        overlay={
          <Tooltip id={`tooltip-${'bottom'}`}>
            {list?.map?.((item) => {
              return <p key={item}>{item}</p>;
            })}
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    </ErrorBoundary>
  );
};

export default OverlayList;
