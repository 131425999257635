import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';

import { LANG_CHOICES } from 'data/data';

import { updateUserDetails } from 'store/slices/userProfileSlice';
import { Spinner } from 'components/common/Loader';

const Language = () => {
  const dispatch = useDispatch();
  const { userProfileInfo, updateStatus } = useSelector((state) => state.userProfile);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (userProfileInfo?.id) {
      setValues(userProfileInfo);
    }

    return () => setValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const onClickSave = () => {
    const errorObject = {};
    if (!values.first_name || !values.last_name) {
      errorObject.name = 'Please enter first name and last name';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrors(errorObject);
      return;
    }

    dispatch(updateUserDetails(values)).then(() => {
      onClickCancel()
    });
  };

  const onClickCancel = () => {
    setEditMode(false);
    setErrors({});
    setValues(userProfileInfo);
  };

  if (editMode) {
    return (
      <div className="row g-0 justify-content-md-between">
        <div className="col-12 col-lg">
          <div className="row">
            <div className="col-12">
              <p className="label">Language</p>
              <p>Select the language you use</p>
            </div>
            <div className="col-12 col-lg">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <Dropdown
                    name={'lang'}
                    options={LANG_CHOICES}
                    title={'language'}
                    handleChange={fieldsOnChange}
                    selectedValue={values.lang}
                  />
                </div>
                {errors.lang && <div className="text-danger">{errors.lang}</div>}
              </div>
            </div>
            <div className="d-flex gap-2 mt-4">
              <Button className="btn btn-secondary fw-normal" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button disabled={updateStatus === "pending"} className="btn btn-primary fw-normal" onClick={onClickSave}>
                {updateStatus === "pending" ? <Spinner /> : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row g-0 justify-content-md-between">
      <div className="col-12 col-lg">
        <div className="row">
          <div className="col-12 col-lg">
            <p className="label">Language</p>
            <p className="label-subtext">Select the language you use</p>
          </div>
          <div className="col-12 col-lg">
            <p className="fw-semibold">{LANG_CHOICES.find((item) => item.value === userProfileInfo?.lang)?.label}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-2">
        <div className="text-lg-end">
          <p className="text-primary links" role="button" onClick={() => setEditMode(true)}>
            Change
          </p>
        </div>
      </div>
    </div>
  );
};

export default Language;
