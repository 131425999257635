import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EventFillIcon, EventIcon, Search } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { meetingsByDate, isAfterPresentTime } from 'components/utils';
import moment from 'moment';
import Avatar from '@atlaskit/avatar';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { clearJoinedMeetingData, joinMeeting } from 'store/slices/meetingSlice';
import { MeetingDefaultIcon } from 'components/common/Icons/NotificationIcons';

const EventMenu = () => {
  const [show, setShow] = React.useState(false);
  const ref = React.useRef();
  useOnClickOutside(ref, () => setShow(false));
  const dispatch = useDispatch();

  const handleShow = () => {
    setShow((prev) => !prev);
  };
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const { loading, allCalendarMeeting } = useSelector((state) => state.calendar);
  const [events, setUpcomingEvents] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    if (!!allCalendarMeeting) {
      let sortedMeetings = meetingsByDate(
        allCalendarMeeting?.results
          ?.filter((event) => {
            const declinedAttendee = event?.attendees?.find(
              (m) => m?.user === userProfileInfo?.id && m?.status === 'DECLINED',
            );
            if (declinedAttendee?.status !== 'DECLINED' && isAfterPresentTime(declinedAttendee?.start_time)) {
              if (isAfterPresentTime(event?.start_time)) return event;
            }
          })
          ?.sort((a, b) => {
            return new Date(a.start_time) - new Date(b.start_time);
          }),
      );
      setUpcomingEvents(sortedMeetings);
      setUnreadCount(sortedMeetings?.length)
    }
  }, [allCalendarMeeting]);

  const handleJoinMeeting = (meeting) => {
    if (meeting) {
      if (meeting?.location_url) {
        window.open(meeting.location_url, '_blank');
        return;
      } else if (meeting?.meeting_id) {
        if (userProfileInfo?.id === meeting.created_by.id) {
          // if person is host or attendee
          dispatch(
            joinMeeting({
              created_by: userProfileInfo,
              meetingId: meeting?.meeting_id,
              attendees: null,
              isWaiting: meeting?.waiting_room,
            }),
          );
        } else {
          dispatch(
            joinMeeting({
              created_by: null,
              meetingId: meeting?.meeting_id,
              attendees: userProfileInfo,
              isWaiting: meeting?.waiting_room,
            }),
          );
        }
      }
    }
  };

  const meetingDetailing = (day, meetings) => {
    return (
      <>
        <div className="block">
          <div className="block-header">
            <p>{day === 'Later' ? moment(meetings[0]?.start_time).format('ddd MMM D, YYYY') : day}</p>
          </div>
          {meetings?.map(
            (meeting) =>
              meeting &&
              meeting?.subject && (
                <>
                  <div className="block-body">
                    <div className="block-row">
                      <div className="icon">
                        <Avatar
                          appearance="circle"
                          src={meeting?.created_by.profile_pic}
                          size={60}
                          name={`${meeting?.created_by.first_name} ${meeting.created_by.last_name}`}
                        />
                        <EventFillIcon />
                      </div>
                      <div className="ps-3">
                        <p>
                          Meeting request from{' '}
                          <Link>{`${meeting.created_by.first_name} ${meeting.created_by.last_name}`}</Link>
                        </p>
                        <h4>{meeting.subject}</h4>
                        <p>
                          {moment(meeting.start_time).format('h:mma')} - {moment(meeting.end_time).format('h:mma')},{' '}
                          {moment(meeting.start_time).format(' ddd M/D')}
                        </p>
                        <ul>
                          <li>
                            {(meeting?.meeting_id || !!meeting.location_url) && (
                              <button onClick={() => handleJoinMeeting(meeting)}>Join</button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ),
          )}
        </div>
      </>
    );
  };

  return (
    <div className="navbar-dropdown" ref={ref}>
      <div className={classNames('dropdown-wrap transparent', [show ? 'active' : ''])} onClick={handleShow}>
        <EventIcon width={35} height={35} className="default" color={show ? '#3D5A80' : '#5B5B5B'} />
        {unreadCount > 0 ? <span>{unreadCount}</span> : ''}
      </div>
      {show && !loading && (
        <div className="navbar-dropdown--menu">
          <div className="menu-header position-relative">
            <div className="wrap">
              <h3>Meeting</h3>
            </div>
            {/* <div className="menu-search">
                <Search />
                <input type="text" placeholder="Search" />
              </div> */}
            {events !== undefined && Object.keys(events).length ? (
              <div className="position-absolute bottom-0 end-0 top-100 me-4">
                <Link to="/calendarlisting">See all</Link>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="menu-body scrollbar">
            {events !== undefined && Object.keys(events).length ? (
              <>
                {events['Today']?.length ? meetingDetailing('Today', events['Today']) : ''}
                {events['Tomorrow']?.length ? meetingDetailing('Tomorrow', events['Tomorrow']) : ''}
                {Object.keys(events)?.map((meeting) => {
                  if (meeting !== 'Today' && meeting !== 'Tomorrow') return meetingDetailing(meeting, events[meeting]);
                })}
              </>
            ) : (
              <div className="px-3 py-5 text-center"><MeetingDefaultIcon /></div>
            )}
          </div>
          <div className="menu-footer">
            <Link to="/calendarlisting">Upcoming Meetings</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventMenu;
