import React, { useState, useEffect, useContext} from 'react';
import Button from 'components/common/Button';
import { AddCircle, ChevronLeft, MapMarker, Search, Arrowback } from 'components/common/Icons';
import Form from 'react-bootstrap/Form';
import { Input } from 'components/common/Input';
import { getActiveJobs, getApplyedJobs, getSavedJobs, getSearchJobs } from 'store/actions/Job.Action';
import { useDispatch, useSelector } from 'react-redux';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import { searchItem } from 'store/slices/searchSlice';
import SearchContext from 'context/searchContext';

const SubHeaderSearch = (props) => {
  const { searchFilters, searchData } = props;
    const dispatch = useDispatch()
    const [title, setTitle] = useState();
    const [location, setLocation] = useState();
    const [searchDataSet, setSearchDataSet] = useState();
    const { query } = useContext(SearchContext);

    const setData = (e)=>{
        const {name, value} = e.target
        setSearchDataSet(state=>({
          ...state,
          [name]:value
        }))
      }
    const sendSearchData = () => { 
        searchData(searchDataSet);
        dispatch(getSearchJobs(searchDataSet));
    }
    useEffect(() => {
      if (searchFilters) {
        dispatch(getSearchJobs(searchFilters));
      }
    }, [dispatch, searchFilters]);

    useEffect(() => {
      setTitle(query)
    },[query]);
  
  return (
    <div className="searchForm">
          <Input className='m-0' name="title" value={title} onChange={(e) => {setTitle(e.target.value);setData(e)}} startIcon={<Search />} placeholder="Job title of Keyword" />
          <Input className='m-0' name="location" value={location} onChange={(e) => setData(e)} startIcon={<MapMarker />} placeholder="Search location" />
          <Button className="btn-dark-blue" onClick={() => sendSearchData()}>Search Jobs</Button>
    </div>
  );
};

export default SubHeaderSearch;
