import { getUserFullName } from 'components/MyDisk/common/utils';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import { Search } from 'components/common/Icons';
import { Input } from 'components/common/Input';
import Modal from 'components/common/Modal/Modal';
import { Flex } from 'components/core';
import SearchFriendsDropdown from 'components/searchFriendsDropdown';
import PostFormContext from 'context/PostFromContext';
import { useContext, useEffect, useState } from 'react';
import { CloseButton, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { searchItem } from 'store/slices/searchSlice';

const gids = {
  tid: -1
}
const TagPeopleModal = ({ onClose, postId, onChange }) => {
  const { tagged, setTagged } = useContext(PostFormContext);
  const [loading, setLoading] = useState()
  const [people, setPeople] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    search("")
  }, [])

  const handleSelected = (friend) => {
    const found = tagged?.find?.(t => t.id === friend?.id)
    let nTagged = [];
    if (found) {
      nTagged = [...(tagged?.filter?.(t => t?.id !== friend?.id) ?? [])]
    } else {
      nTagged = [...(tagged ?? []), friend]
    }
    setTagged(nTagged)
    onChange?.(nTagged)
  }

  const handleSearch = (inputValue) => {
    clearTimeout(gids.tid)
    gids.tid = setTimeout(() => {
      search(inputValue)
    }, 300)
  };

  const search = (inputValue) => {
    setLoading(true)
    dispatch(searchItem({ searchQuery: inputValue, query: `&custom_param_name=mycircle`, item: 'people' })).then(
      (res) => {
        setLoading(false)
        setPeople(res.payload.results);
      },
    ).catch(e => {
      setLoading(false)
    });
  }

  return (
    <Modal
      onClose={onClose}
      className="tag-people-modal"
      heading={<h3>Tag People</h3>}
    // action={
    //   <>
    //     {!loading ? <Button className={`btn btn-secondary`} onClick={onClose}>
    //       Cancel
    //     </Button> : ""}
    //     <Button disabled={loading} className="btn btn-primary" >
    //       {loading ? <Spinner /> : "Ignore Post"}
    //     </Button>
    //   </>
    // }
    >
      <div className='tpm-container'>
        <Flex className='search'>
          <Input onChange={e => handleSearch(e?.target?.value)} placeholder="Search..." startIcon={<Search />} />
          <Button onClick={e => onClose?.()}>Done</Button>
        </Flex>
        {tagged?.length ? <>
          <h5>Tagged</h5>
          <ul className='tag-list'>
            {tagged?.map((person, tind) =>
              <li
                onClick={e => handleSelected(person)}
                key={tind}
              >
                <MyBridgeAvatar name={getUserFullName(person)} size={20} />
                <p>{getUserFullName(person)}</p>
                <CloseButton />
              </li>)}
          </ul> </> : <></>}
        {people?.length ? <>
          <h5>Suggestions</h5>
          <ul className='people-list'>
            {people?.filter?.(p => !tagged?.find?.(t => t.id === p.id))?.map((person, tind) =>
              <li
                onClick={e => handleSelected(person)}
                key={tind}
              >
                <MyBridgeAvatar name={getUserFullName(person)} size={40} />
                <p>{getUserFullName(person)}</p>
              </li>)}
          </ul></> : !loading ? <>
            <h6>No results found.</h6>
          </> : <></>}
        {/* <pre>
          {JSON.stringify(people, null, 2)}
        </pre> */}
      </div>
    </Modal>
  )
};

export default TagPeopleModal