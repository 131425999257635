import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import meetNow from 'assets/icons/meetNow.svg';
import message from 'assets/icons/message.svg';
import schedule from 'assets/icons/schedule.svg';
import firstConnection from 'assets/icons/firstConnection.svg';
import otherConnection from 'assets/icons/otherConnection.svg';

import Layout from 'components/Layouts';
import { BorderLessButton } from 'components/StyledComponents';
import { HandShakeSimpleIcon } from 'components/common/Icons';

import { getProfileViewers } from 'store/slices/userProfileSlice';
import { MyBridgeAvatar } from 'components/common/Avatar';
import moment from 'moment';

const ProfileViewsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 12px;
  margin-bottom: 10px;
  overflow: hidden;
  margin-top: 20px;
  padding: 30px;
`;

const ProfileViewText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => props.fW || 400};
  font-size: ${(props) => props.fS || '20px'};
  line-height: 120%;
  color: ${(props) => props.color || '#000000'};
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ProfileViewBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;
const ProfileDetails = styled.div`
  /* margin: 10px; */
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.jc};
  border-bottom: ${(props) => (props.bNone ? '' : '1px solid #d9d9d9')};
  padding: 15px 0;
`;

const ProfileAction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HandshakeButton = styled.button`
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #3d5a80;
  border-radius: 100px;
`;

const MeetButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #5b5b5b;
  border-radius: 100px;
`;

const UserProfile = ({ profile, view, viewType }) => {
  const { first_name, last_name, profile_pic, position, degree_count } = profile;
  const fullName = `${first_name} ${last_name}`;

  return (
    <ProfileBox jc="space-between">
      <ProfileContent>
        <MyBridgeAvatar icon={profile_pic} name={fullName} size={50} />
        <ProfileDetails>
          <ProfileViewText fS="15px" fW={500}>
            {fullName}
          </ProfileViewText>
          <ProfileViewText fS="12px" fW={500} color="#747474" className="mt-1">
            {position}
          </ProfileViewText>
          <ProfileContent className="mt-1">
            <ProfileViewText fS="12px" color="#747474" className="me-2">
              {degree_count.first}
              <img src={firstConnection} alt="" />
              1st Degrees
            </ProfileViewText>

            <ProfileViewText fS="12px" color="#747474" className="me-2">
              {degree_count.second}
              <img src={otherConnection} alt="" />
              2nd Degrees
            </ProfileViewText>

            <ProfileViewText fS="12px" color="#747474">
              {degree_count.third}
              <img src={otherConnection} alt="" />
              3rd Degrees
            </ProfileViewText>
          </ProfileContent>
          <ProfileViewText fS="10px" fW={500} color="#747474" className="mt-1">
            {`Viewed ${moment(view.created).format('hh:mm A zz, ddd M/DD')}`}
          </ProfileViewText>
        </ProfileDetails>
      </ProfileContent>
      <ProfileAction>
        {view.is_connection ? (
          <>
            <MeetButton>
              <img src={message} alt="" />
              <ProfileViewText fS="15px">Message</ProfileViewText>
            </MeetButton>
            <MeetButton>
              <img src={meetNow} alt="" />
              <ProfileViewText fS="15px">Meet Now</ProfileViewText>
            </MeetButton>
            <MeetButton>
              <img src={schedule} alt="" />
              <ProfileViewText fS="15px">Schedule</ProfileViewText>
            </MeetButton>
          </>
        ) : (
          <>
            <HandshakeButton>
              <HandShakeSimpleIcon />
              <ProfileViewText fS="15px" color="#3D5A80">
                Handshake
              </ProfileViewText>
            </HandshakeButton>
            <BorderLessButton color="#5B5B5B">Follow</BorderLessButton>
          </>
        )}
      </ProfileAction>
    </ProfileBox>
  );
};

const ViewBox = ({ viewType, views }) => {
  if (views.length <= 0) return null;

  return (
    <ProfileViewBox className="mt-4">
      <ProfileViewText fS="15px" fW={600}>
        {viewType}
      </ProfileViewText>

      {views.map((view) => {
        return <UserProfile key={view.id} view={view} profile={view.user} viewType={viewType} />;
      })}
    </ProfileViewBox>
  );
};

const ProfileViews = () => {
  const dispatch = useDispatch();

  const { profileViewers } = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(getProfileViewers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newViews = profileViewers.filter((view) => moment().diff(moment(view.created), 'days') <= 30);
  const earlierViews = profileViewers.filter((view) => moment().diff(moment(view.created), 'days') > 30);

  return (
    <>
      <ProfileViewsContainer className="container">
        <ProfileViewBox>
          <ProfileViewText>These folks may want to network.</ProfileViewText>
          <ProfileViewText color="#5B5B5B">
            {profileViewers.length} People viewed your profile in the past 90 days
          </ProfileViewText>
        </ProfileViewBox>

        <ViewBox viewType="New" views={newViews} />

        <ViewBox viewType="Earlier" views={earlierViews} />

        <ProfileBox bNone jc="center">
          <ProfileViewText fS="16px" color="#3d5a80" className="text-center">
            Show more
          </ProfileViewText>
        </ProfileBox>

        {/* <div className="invite-section mt-4">
        <h5 className="invite-heading">Earlier</h5>
        <div className="invite-container">
          <NetworkCard />
        </div>
      </div> */}
      </ProfileViewsContainer>
    </>
  );
};

export default ProfileViews;
