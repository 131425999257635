import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

import { Input, TextArea } from 'components/common/Input';
import { userProfileAddInfo } from 'store/slices/userProfileSlice';
import { BorderLessButton } from 'components/StyledComponents';
import classNames from 'classnames';
import Feed from 'pages/Dashboard/Feed';

const IntroductionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
`;

const ProfileHeadline = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
`;

const ProfileDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
  margin-top: 20px;
  padding-bottom: 24px;
  border-bottom: 2px solid #d9d9d9;
`;

const SeeMoreLessText = styled.p`
  color: var(--heading, #3d5a80);
  font-size: 15px;
  font-family: Inter;
  line-height: 30px;
  text-align: right;
  margin-top: -20px;
  cursor: pointer;
`;

export default function Introduction(props) {
  const dispatch = useDispatch();
  const { loading, isProfileUpdated, userProfileInfo } = useSelector((state) => state.userProfile);
  const [isSeeMore, setSeeMore] = useState(false);

  const [headline, setHeadline] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [editMode, setEditMode] = useState(props.edit);

  const handleProfileUpdate = (remove) => {
    const formData = new FormData();
    formData.append('profile_headline', remove ? '' : headline);
    formData.append('profile_intro', remove ? '' : introduction);
    dispatch(userProfileAddInfo(formData));
    props.onTabUpdate();
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    props.onTabUpdate();
  };

  useEffect(() => {
    setHeadline(userProfileInfo?.profile_headline);
    setIntroduction(userProfileInfo?.profile_intro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProfileUpdated) {
      setEditMode(false);
      setHeadline(userProfileInfo?.profile_headline);
      setIntroduction(userProfileInfo?.profile_intro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileUpdated]);

  if (loading) {
    return <Loader />;
  }

  if (editMode) {
    return (
      <>
        <p className="label-text color-darkGrey2">Profile Headline</p>
        <Input placeholder={'Profile headline'} onChange={(e) => setHeadline(e.target.value)} value={headline} />

        <p className="label-text color-darkGrey2">Introduction</p>
        <TextArea
          name="introduction"
          rows={6}
          maxLength={1000}
          placeholder="Add Introduction"
          value={introduction}
          onChange={(e) => setIntroduction(e.target.value)}
          containerClass={'mb-1'}
        />

        <div>
          <p className="text-end color-darkGrey2 fs-12">{`${
            1000 - (introduction?.length || 0)
          } characters remaining`}</p>
        </div>

        <div
          className={classNames('d-flex mt-4 justify-content-end', {
            'justify-content-between': userProfileInfo.profile_headline || userProfileInfo.profile_intro,
          })}
        >
          {(userProfileInfo.profile_headline || userProfileInfo.profile_intro) && (
            <Button variant="btn-outline-danger" className="h-36 border-0" onClick={() => handleProfileUpdate(true)}>
              Delete
            </Button>
          )}
          <div className="d-flex gap-2 justify-content-end">
            <Button variant="btn-secondary" className="h-36" onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button variant="btn-primary" className="h-36" onClick={() => handleProfileUpdate(false)}>
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__introduction__section">
        <IntroductionHeader>
          <ProfileHeadline>{userProfileInfo?.profile_headline}</ProfileHeadline>
          <BorderLessButton onClick={() => setEditMode(true)}>Edit</BorderLessButton>
        </IntroductionHeader>
        <ProfileDescription>
          {userProfileInfo?.profile_intro?.length > (!isSeeMore ? 250 : userProfileInfo?.profile_intro?.length)
            ? `${userProfileInfo?.profile_intro.substring(0, 250)}...`
            : userProfileInfo?.profile_intro}
            {userProfileInfo?.profile_intro?.length > 250 && (
          <SeeMoreLessText onClick={() => setSeeMore(!isSeeMore)}>See {!isSeeMore ? 'more' : 'less'}</SeeMoreLessText>
        )}
        </ProfileDescription>
        
        {/* {item.description.length > 250 ?
    `${item.description.substring(0, 250)}...` : item.description
  } */}
      </div>
      <div className="row" style={{ paddingTop: 24 }}>
        <Feed type="myActivity" showModal />
      </div>
    </>
  );
}
