import { useMutation } from '@tanstack/react-query';
import { getIntegrationURL } from 'api/google-integration';
import { OutlookIcon, Yahoo, AOL, Mail } from 'components/common/Icons';
import { Spinner } from 'components/common/Loader';
import { Text, TextInput } from 'components/core';
import React from 'react';
import { useDispatch , useSelector } from 'react-redux';
import styled from 'styled-components';

const AddContact = () => {
  const dispatch = useDispatch();
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const { mutate, isLoading } = useMutation(getIntegrationURL, {
    onSuccess: (data) => {
      const { url = null } = data?.data;
      if (url) {
        window.open(url, 'mywin', 'width=500,height=500');
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <Container>
      <Text color="#5B5B5B" size="14px" weight="500" lineHeight="130%">
        Invite your contacts to join you here on myBridge.
      </Text>

      <Text color="#3D5A80" size="16px" weight="600" lineHeight="30px" margin="0px">
        Add Contact
      </Text>

      <Text color="#5B5B5B" size="14px" weight="500" lineHeight="130%" margin="0px 0px 20px 0px">
        See who you already know on myBridge. Syncing your contacts is the fastest way to grow your network.
      </Text>

      <TextInput readonly={true} placeholder={`${userProfileInfo?.email}`} style={{ height: 60 , overflow : 'hidden' , whiteSpace:"nowrap" , textOverflow:'ellipsis'}} />

      <ContactButton onClick={mutate}>{isLoading ? <Spinner /> : 'Continue'}</ContactButton>

      <IconList>
        <li >
          <Mail width={40} height={40}  />
        </li>
        <li>
          <OutlookIcon width={40} height={40} color="#5B5B5B" />
        </li>
        <li>
          <AOL width={40} height={40} />
        </li>
        <li>
          <Yahoo width={40} height={40}  />
        </li>
      </IconList>
    </Container>
  );
};

export default AddContact;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 12px;
  gap: 12px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e4e6eb;
  border-radius: 8px;
  margin-bottom: 16px;
  text-align: left;
`;

const ContactButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  width: 100%;
  height: 40px;
  background: #3d5a80;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 87%;
  color: #ffffff;
`;

const IconList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  li {
    border: 1px solid #e4e6eb;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
