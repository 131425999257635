import styled from 'styled-components';

const Text = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin:${(props) => props.margin || '0'};
  padding: 0;
  font-family: 'Inter', sans-serif;
  color: ${(props) => props.color || '#000'}};
  font-size: ${(props) => props.size || '13px'};
  font-weight: ${(props) => props.weight || '400'};
  line-height: ${(props) => props.lineHeight || '15.6px'};
  display: ${(props) => props.display || 'block'};
`;

const Label = styled.label`
  font-size: ${(props) => props.size || '13px'};
  font-weight: 400;
  line-height: 15.6px;
  color: ${(props) => props.color || '#5b5b5b'}};
  margin: ${(props) => props.margin || '0'};
  padding: 0;
  white-space: ${(props) => props?.whiteSpace ?? ''};
  font-family: 'Inter', sans-serif;
`;

const Title = styled.h1`
  font-size: ${(props) => getTitleProps(props.level).fontSize};
  font-weight: ${(props) => getTitleProps(props.level).fontWeight};
  line-height: ${(props) => getTitleProps(props.level).lineHeight};
  color: ${(props) => (props.secondary ? '#3D5A80' : '#000')};
  margin: ${(props) => props.margin || '0'};
  padding: 0;
  font-family: 'Inter', sans-serif;
`;

const Tag = styled.span`
border-radius: ${(props) => props.borderRadius || '0.5rem'};
background-color:${(props) => props.backgroundColor || '#E9EAEE'};
padding:${(props) => props.padding || '0.2rem 0.5rem'};
margin:${(props) => props.margin || '0rem 0.2rem'};
font-size:${(props) => props.fontSize || '12px'};
color:${(props) => props.color || '#666'};
`

Text.Label = Label;
Text.Title = Title;
Text.Tag = Tag;

export default Text;

const getTitleProps = (level) => {
  switch (level) {
    case 1:
      return { fontSize: '30px', lineHeight: '120%', fontWeight: '600' };
    case 2:
      return { fontSize: '2.4rem', lineHeight: '2.8rem', fontWeight: '700' };
    case 3:
      return { fontSize: '20px', lineHeight: '120%', fontWeight: '700' };
    case 4:
      return { fontSize: '20px', lineHeight: '120%', fontWeight: '500' };
    case 5:
      return { fontSize: '18px', lineHeight: '120%', fontWeight: '700' };
    case 6:
      return { fontSize: '16px', lineHeight: '120%', fontWeight: '700' };
    case 7:
      return { fontSize: '15px', lineHeight: '120%', fontWeight: '500' };
    case 8:
      return { fontSize: '13px', lineHeight: '120%', fontWeight: '500' };
    default:
      return { fontSize: '1.6rem', lineHeight: '120%', fontWeight: '700' };
  }
};
