import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultBanner from 'assets/profiles/banner/banner.png';
import backarrow from 'assets/new/backarrow.svg';
import downArrowSelect from 'assets/icons/arrowDownSelect.svg';
import ImageUploader from 'components/ImageUploader';
import { Camera } from 'components/common/Icons';
import Select, { components } from 'react-select';
import { genralMonths } from 'data/data';
import { rangeOfFullYears } from 'components/utils';
import { Input, TextArea } from 'components/common/Input';
import Modal from 'components/common/Modal/Modal';
import Dropdown from 'components/common/Dropdown';
import Checkbox from 'components/common/Checkbox';
import { Text } from 'components/StyledComponents';
import Button from 'components/common/Button';
import { Country, State, City } from 'country-state-city';
import { useFormik } from 'formik';
import {
  getGlobalCountries,
  getGlobalStates,
  getGlobalIndustries,
  getGlobalCompanyTypes,
  getGlobalCompanySize,
} from 'store/slices/globalDataSlice';

const yearOptions = rangeOfFullYears(1920, 'backward');

const CompanySection = ({ companyDetails, setCompanyDetails, setSection }) => {
  const dispatch = useDispatch();
  const { globalCountries, globalStates, globalCompanyTypes, globalCompanySize, globalIndustries } = useSelector(
    (state) => state.globalData,
  );
  const [updatedStates, setUpdatedStates] = useState([]);
  const [updatedCities, setUpdatedCities] = useState([]);
  const [errors, setErrors] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const CustomIndicators = (props) => {
    return (
      <components.IndicatorsContainer {...props}>
        {<img src={downArrowSelect} alt="Not Found" className="arrow-down" />}
      </components.IndicatorsContainer>
    );
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Page name is Missing';
    }

    if (!values.website) {
      errors.website = 'Website is missing';
    } else if (
      !/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(
        values.website,
      )
    ) {
      errors.website = 'Website not valid';
    }
    if (!values.email) {
      errors.email = 'Email is missing';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    } else if (
      !errors.website &&
      values.email.indexOf(values?.website.split(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim)[1]) === -1
    ) {
      errors.email = 'Email domain should match website url';
    }

    if (!values.main_industry) {
      errors.main_industry = 'Select main industry';
    }
    if (!values.company_type) {
      errors.company_type = 'Select Company type';
    }
    if (!values.introduction) {
      errors.introduction = 'Company Introduction missing';
    }
    if (!values.image) {
      errors.image = 'Upload logo';
    }
    if (!values.checkbox) {
      errors.checkbox = 'Verify yourself';
    }
    if (!values.country) {
      errors.country = 'Country is missing';
    }
    if (!values.state_region && updatedStates.length) {
      errors.state_region = 'State is missing';
    }
    if (!values.city && updatedStates.length && updatedCities.length) {
      errors.city = 'City is missing';
    }
    if (!values.company_size) {
      errors.company_size = 'Company size is missing';
    }
    return errors;
  };

  const companyFormik = useFormik({
    initialValues: {
      address: null,
      checkbox: null,
      company_size: null,
      company_type: null,
      country: null,
      state_region: null,
      city: null,
      zipcode: null,
      main_industry: null,
      email: null,
      image: null,
      banner_image: null,
      introduction: null,
      month_founded: null,
      name: null,
      phone_number: null,
      website: null,
      year_founded: null,
    },
    validate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const {
        address,
        checkbox,
        company_size,
        company_type,
        country,
        state_region,
        city,
        zipcode,
        main_industry,
        email,
        image,
        introduction,
        month_founded,
        name,
        banner_image,
        phone_number,
        website,
        year_founded,
      } = values;
      const payload = {
        json: true,
        isFormData: false,
        data: {
          address: address,
          checkbox: checkbox,
          company_size: company_size,
          company_type: company_type,
          country: country,
          state_region: state_region,
          city: city,
          zipcode: zipcode,
          main_industry: main_industry,
          email: email,
          image: image,
          banner_image: banner_image,
          introduction: introduction,
          month_founded: month_founded,
          name: name,
          phone_number: phone_number,
          website: website,
          year_founded: year_founded,
        },
      };
      for (let key in payload.data) {
        if (
          payload.data[key] &&
          typeof payload.data[key] !== 'string' &&
          typeof payload.data[key] !== 'boolean' &&
          !(payload.data[key] instanceof File)
        ) {
          payload.data[key] = payload.data[key].value;
        }
      }
      setCompanyDetails(payload.data);
      setSection('services');
    },
  });

  const { values, handleSubmit, setFieldValue, setValues } = companyFormik;

  const setFormattedContent = React.useCallback(
    (text) => {
      setFieldValue('introduction', text.slice(0, 350));
    },
    [350, values?.introduction],
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState();

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const uploadCropedImg = (img) => {
    if (cropType === 'profilePhoto') {
      setFieldValue('image', img);
    } else {
      setFieldValue('banner_image', img);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    dispatch(getGlobalCountries());
    dispatch(getGlobalStates());
    dispatch(getGlobalIndustries());
    dispatch(getGlobalCompanyTypes());
    dispatch(getGlobalCompanySize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (values?.country) {
      setUpdatedStates(
        State.getStatesOfCountry(values.country?.value).map((state) => ({
          label: state.name,
          value: state.isoCode,
          ...state,
        })),
      );
    } else {
      setUpdatedStates(State.getAllStates().map((state) => ({ label: state.name, value: state.isoCode, ...state })));
    }
  }, [values?.country]);
  useEffect(() => {
    if (values?.state_region) {
      setUpdatedCities(
        City.getCitiesOfState(values.country?.value, values?.state_region?.value).map((city) => ({
          label: city.name,
          value: city.isoCode ? city.isoCode : city.name,
          ...city,
        })),
      );
    } else {
      setUpdatedCities(City.getAllCities().map((city) => ({ label: city.name, value: city.isoCode, ...city })));
    }
  }, [values?.state_region]);
  return (
    <>
      <div className="col-12 d-flex flex-column border-bottom gap-5 p-5">
        <div className="container d-flex gap-5 flex-column">
          <button className="back d-flex gap-3" onClick={() => setSection('intro')}>
            <img src={backarrow} alt="Not Found" className="arrow-back" />
            <Text fS="20px" color="#3d5a80">
              Back
            </Text>
          </button>

          <Text fS="18px" color="#3D5A80">
            Let’s get started with a few details about your company.
          </Text>
        </div>
      </div>
      <form onSubmit={companyFormik.handleSubmit}>
        <section className="bordered-container container user-settings--pages mt-5">
          <div className="row gap-lg-3 mb-5 p-4 justify-content-between">
            <div className="col-6 row justify-content">
              <div className="company-banner">
                <img
                  src={values?.banner_image ? URL.createObjectURL(values?.banner_image) : defaultBanner}
                  alt={values?.banner_image?.name || 'default_banner'}
                  onClick={() => handlePopups('bannerPhoto')}
                />

                {!values?.banner_image && (
                  <Button
                    variant="btn-secondary"
                    className={'upload-banner cursor-pointer'}
                    onClick={() => handlePopups('bannerPhoto')}
                  >
                    <label htmlFor="file-banner" className="cursor-pointer">
                      <Camera width={20} height={20} />
                      <span className="upload-banner-text">Update Photo</span>
                    </label>
                  </Button>
                )}
                <div className="upload-logo cursor-pointer" onClick={() => handlePopups('profilePhoto')}>
                  <img
                    src={values?.image ? URL.createObjectURL(values?.image) : null}
                    alt={values?.image?.name || null}
                  />

                  {!values?.image && (
                    <span className="upload">
                      <div className="avatar_upload">
                        <label htmlFor="file-input" className="cursor-pointer">
                          <Camera width={56} height={56} />
                        </label>
                      </div>
                    </span>
                  )}
                  {companyFormik.errors.image && (
                    <p className="text-danger text-center">{companyFormik.errors.image}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 row justify-content-start">
              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer starlabel">
                  <label>Page Name</label>
                  <Input
                    type="text"
                    placeholder="Page Name"
                    name="name"
                    value={values?.name}
                    onChange={(value) => setFieldValue('name', value.target.value)}
                    className={companyFormik.errors.name ? 'error' : ''}
                  />
                  {companyFormik.errors.name ? <div className="text-danger">{companyFormik.errors.name}</div> : null}
                </div>
                <div className="form-group form-group-outer starlabel">
                  <label>Company Website URL</label>
                  <Input
                    type="url"
                    name="website"
                    placeholder="URL"
                    value={values?.website}
                    onChange={(value) => setFieldValue('website', value.target.value)}
                    className={companyFormik.errors.website ? 'error' : ''}
                  />
                  {companyFormik.errors.website ? (
                    <div className="text-danger">{companyFormik.errors.website}</div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer">
                  <label>Phone</label>
                  <Input
                    type="number"
                    placeholder="Phone"
                    name="phone_number"
                    value={values?.phone_number}
                    onChange={(value) => setFieldValue('phone_number', value.target.value)}
                    className={companyFormik.errors.phone_number ? 'error' : ''}
                  />
                  {companyFormik.errors.phone_number ? (
                    <div className="text-danger">{companyFormik.errors.phone_number}</div>
                  ) : null}
                </div>
                <div className="form-group form-group-outer starlabel">
                  <label>Your email at the Company</label>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Your email at the Company"
                    value={values?.email}
                    onChange={(value) => setFieldValue('email', value.target.value)}
                    className={companyFormik.errors.email ? 'error' : ''}
                  />
                  {companyFormik.errors.email ? <div className="text-danger">{companyFormik.errors.email}</div> : null}
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer">
                  <label>Address</label>
                  <Input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={values?.address}
                    onChange={(value) => setFieldValue('address', value.target.value)}
                  />
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer starlabel">
                  <label>Country</label>
                  <Select
                    id={'country'}
                    name="country"
                    options={updatedCountries}
                    placeholder="Search Country"
                    value={updatedCountries.filter(function (option) {
                      const countryName = values?.country?.value ? values?.country?.value : values?.country;
                      return option.value === countryName;
                    })}
                    onChange={(value) => {
                      setFieldValue('country', value);
                      setFieldValue('state_region', '');
                      setFieldValue('city', '');
                    }}
                    isSearchable={true}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                    className={companyFormik.errors.country ? 'error' : ''}
                  />
                  {companyFormik.errors.country ? (
                    <div className="text-danger">{companyFormik.errors.country}</div>
                  ) : null}
                </div>
                <div className="form-group form-group-outer starlabel">
                  <label>State or Region</label>
                  <Select
                    id={'state_region'}
                    name="state_region"
                    options={updatedStates}
                    placeholder="Search States"
                    value={updatedStates.filter(function (option) {
                      const stateName = values?.state_region?.value
                        ? values?.state_region?.value
                        : values?.state_region;
                      return option.value === stateName;
                    })}
                    isDisabled={!values?.country?.value}
                    onChange={(value) => {
                      setFieldValue('state_region', value);
                      setFieldValue('city', '');
                    }}
                    isSearchable={true}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                    className={companyFormik.errors.state_region ? 'error' : ''}
                  />
                  {companyFormik.errors.state_region ? (
                    <div className="text-danger">{companyFormik.errors.state_region}</div>
                  ) : null}
                </div>
                <div className="form-group form-group-outer starlabel">
                  <label>City</label>
                  <Select
                    id={'city'}
                    name="city"
                    options={updatedCities}
                    placeholder="Search city"
                    value={updatedCities.filter(function (option) {
                      const cityName = values?.city?.value ? values?.city?.value : values?.city;
                      return option.value === cityName;
                    })}
                    isDisabled={!values?.state_region?.value}
                    onChange={(value) => {
                      setFieldValue('city', value);
                    }}
                    isSearchable={true}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                    className={companyFormik.errors.city ? 'error' : ''}
                  />
                  {companyFormik.errors.city ? <div className="text-danger">{companyFormik.errors.city}</div> : null}
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer">
                  <label>Zip Code</label>
                  <Input
                    type="number"
                    placeholder="Zip Code"
                    name="zipcode"
                    value={values?.zipcode}
                    onChange={(value) => setFieldValue('zipcode', value.target.value)}
                  />
                </div>
                <div className="form-group form-group-outer starlabel">
                  <label>Main Company Industry</label>
                  <Select
                    id={'main_industry'}
                    name="main_industry"
                    options={globalIndustries.map((e) => ({ label: e, value: e }))}
                    placeholder="Search Industries"
                    value={globalIndustries
                      .map((e) => ({ label: e, value: e }))
                      .filter(function (option) {
                        return option.value === values.main_industry?.value;
                      })}
                    onChange={(value) => {
                      setFieldValue('main_industry', value);
                    }}
                    isSearchable={true}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                    className={companyFormik.errors.main_industry ? 'error' : ''}
                  />
                  {companyFormik.errors.main_industry ? (
                    <div className="text-danger">{companyFormik.errors.main_industry}</div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer">
                  <label>Year Founded</label>
                  <Select
                    id={'year_founded'}
                    name="year_founded"
                    options={yearOptions}
                    placeholder="Select Year"
                    value={yearOptions.filter(function (option) {
                      return option.value === values.year_founded?.value;
                    })}
                    onChange={(value) => {
                      setFieldValue('year_founded', value);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                  />
                </div>
                <div className="form-group form-group-outer">
                  <label></label>
                  <Select
                    id={'month_founded'}
                    name="month_founded"
                    options={genralMonths}
                    placeholder="Select Month"
                    value={genralMonths.filter(function (option) {
                      return option.value === values.month_founded?.value;
                    })}
                    onChange={(value) => {
                      setFieldValue('month_founded', value);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                  />
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer starlabel">
                  <label>Company Type</label>
                  <Select
                    id={'company_type'}
                    name="company_type"
                    options={globalCompanyTypes.map((e) => ({ label: e, value: e }))}
                    placeholder="Select Company type"
                    value={globalCompanyTypes
                      .map((e) => ({ label: e, value: e }))
                      .filter(function (option) {
                        return option.value === values.company_type?.value;
                      })}
                    onChange={(value) => {
                      setFieldValue('company_type', value);
                    }}
                    isSearchable={true}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                    className={companyFormik.errors.company_type ? 'error' : ''}
                  />
                  {companyFormik.errors.company_type ? (
                    <div className="text-danger">{companyFormik.errors.company_type}</div>
                  ) : null}
                </div>
                <div className="form-group form-group-outer starlabel">
                  <label>Company size</label>
                  <Select
                    id={'company_size'}
                    name="company_size"
                    options={globalCompanySize.map((e) => ({ label: e, value: e }))}
                    placeholder="Select Size"
                    value={globalCompanySize
                      .map((e) => ({ label: e, value: e }))
                      .filter(function (option) {
                        return option.value === values.company_size?.value;
                      })}
                    onChange={(value) => {
                      setFieldValue('company_size', value);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                    components={{ IndicatorsContainer: CustomIndicators }}
                    className={companyFormik.errors.company_size ? 'error' : ''}
                  />
                  {companyFormik.errors.company_size ? (
                    <div className="text-danger">{companyFormik.errors.company_size}</div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="form-group form-group-outer starlabel">
                  <label>Company Introduction</label>
                  <TextArea
                    placeholder={'Company Introduction'}
                    name="introduction"
                    value={values?.introduction}
                    onChange={(event) => setFormattedContent(event.target.value)}
                    className={`company_detail ${companyFormik.errors.introduction ? 'error' : ''}`}
                    maxLength={350}
                  />
                  {companyFormik.errors.introduction ? (
                    <div className="text-danger">{companyFormik.errors.introduction}</div>
                  ) : null}
                  <p className="limit-indicator">
                    {values?.introduction ? values.introduction.length : 0}/{350}
                  </p>
                </div>
              </div>

              <div className="d-flex gap-3 p-0 mb-4">
                <div className="col-12 d-flex">
                  <div className="col-1">
                    <Checkbox
                      name={'checkbox'}
                      value={values?.checkbox}
                      onChange={(value) => setFieldValue('checkbox', value.target.checked)}
                      className={companyFormik.errors.checkbox ? 'm-0 verify-checkbox error' : 'm-0 verify-checkbox'}
                    />
                  </div>
                  <div className="col-11 terms-text">
                    I verify that I am the official representative of this company and have the right to act on behalf
                    of my company when creating this page.
                    {companyFormik.errors.checkbox && <p className=" text-danger">{companyFormik.errors.checkbox}</p>}
                  </div>
                </div>
              </div>

              <div className="d-flex gap-3 mt-5 justify-content-end">
                <Button className="submit btn btn-primary fw-normal" variant="primary" type="submit">
                  Create Page
                </Button>
              </div>
            </div>
          </div>
        </section>
      </form>
      {modalOpen && (
        <Modal onClose={handlePopups} className={cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}>
          <ImageUploader cropType={cropType} uploadCropedImg={uploadCropedImg} handlePopups={handlePopups} />
        </Modal>
      )}
    </>
  );
};

export default CompanySection;
