import React from 'react';

const Button = ({ type = 'button', variant = 'btn-primary', className, onClick, children, disabled }) => {
  const btnClass = className ? `btn ${variant} ${className}` : `btn ${variant}`;

  return (
    <button type={type} className={`${btnClass}`} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
