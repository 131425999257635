import React, { useState, useEffect } from 'react';
import {
    StatusIcon
} from 'components/common/Icons';
import { CometChat } from "@cometchat-pro/chat";

const UserPresence = (props) => {
    const { userId } = props;
    const [selected, setSelected] = useState();
    const [stateColor, setStateColor] = useState('#0AB33E');

    const updateUserStatus = (status) => {
        switch (status) {
            case 'online':
                return setStateColor('#0AB33E');
            case 'offline':
                return setStateColor('#5B5B5B');
            case 'busy':
                return setStateColor('#FF0600');
            default:
                return setStateColor('#0AB33E');
        }
    }
    const getUserStatusDetails = () => {
        let UID = userId;
        CometChat.getUser(UID).then(
            user => {
                if (user && user?.metadata?.currentStatus) {
                    updateUserStatus(user.metadata.currentStatus)
                } else {
                    updateUserStatus(user.status);
                }
            }, error => {
                console.error("User details fetching failed with error:", error);
            }
        );
    }

    useEffect(() => {
        getUserStatusDetails();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <StatusIcon className="position-absolute bottom-0 end-0" width={15} height={15} color={stateColor} />
        </React.Fragment>
    )
}

export default UserPresence;