import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import API from 'store/common/api';
import { APIEndPoints, GlobalAPIEndpoints } from 'store/common/endPoint';

const initialState = {
  msg: '',
  loading: false,
  error: false,
  globalCountries: [],
  globalStates: [],
  globalCities: [],
  globalCompanies: [],
  globalCompanyTypes: [],
  globalCompanySize: [],
  globalSkills: [],
  globalSchools: [],
  globalColleges: [],
  globalCertificates: [],
  globalCourses: [],
  globalDegrees: [],
  globalIndustries: [],
  globalLanguages: [],
  companyCreated: false,
};

export const getGlobalCountries = createAsyncThunk('globalCountries', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCountries(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalStates = createAsyncThunk('globalStates', async ({ page = 1, search = '', country_code = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetStates(page, search, country_code)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalCities = createAsyncThunk('globalCities', async ({ search = '', state_code=''} = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCities(search, state_code)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalCompanies = createAsyncThunk('globalCompanies', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCompanies(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const createGlobalCompanies = createAsyncThunk('createGlobalCompanies', async (body) => {
  const obj = { url: `${GlobalAPIEndpoints.GDCompanies}`, method: 'POST', body: JSON.stringify(body), isNoToken: true };
  return await API(obj);
});

export const getGlobalCompanyTypes = createAsyncThunk('globalCompanyTypes', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCompanyTypes(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalCompanySize = createAsyncThunk('globalCompanySize', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCompanySize(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalSkills = createAsyncThunk('globalSkills', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetSkills(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalSchools = createAsyncThunk('globalSchools', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetSchools(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const createGlobalSchools = createAsyncThunk('createGlobalSchools', async (body) => {
  const obj = { url: `${GlobalAPIEndpoints.GetSchools}`, method: 'POST', body: JSON.stringify(body), isNoToken: true };
  return await API(obj);
});

export const getGlobalColleges = createAsyncThunk('globalColleges', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetColleges(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalCertificates = createAsyncThunk('globalCertificates', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCertificates(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalLanguages = createAsyncThunk('globalLanguages', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCertificates(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalCertificateAuthorities = createAsyncThunk(
  'globalCertificateAuthorities',
  async ({ page = 1, search = '' } = {}) => {
    const obj = { url: `${GlobalAPIEndpoints.GetCertificateAuthorities(page, search)}`, isNoToken: true };
    return await API(obj);
  },
);

export const getGlobalCourses = createAsyncThunk('globalCourses', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetCourses(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalDegrees = createAsyncThunk('globalDegrees', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetDegrees(page, search)}`, isNoToken: true };
  return await API(obj);
});

export const getGlobalIndustries = createAsyncThunk('globalIndustries', async ({ page = 1, search = '' } = {}) => {
  const obj = { url: `${GlobalAPIEndpoints.GetIndustries(page, search)}`, isNoToken: true };
  return await API(obj);
});

const globalDataSlice = createSlice({
  name: 'globalData',
  initialState,
  extraReducers: {
    [getGlobalCountries.pending]: (state, action) => {
      state.loading = true;
    },
    [getGlobalCountries.fulfilled]: (state, action) => {
      state.globalCountries = action?.payload?.results?.map((item) => item);
      state.loading = false;
    },
    [getGlobalStates.pending]: (state, action) => {
      state.loading = true;
    },
    [getGlobalStates.fulfilled]: (state, action) => {
      state.globalStates = action?.payload?.results?.map((item) => item);
      state.loading = false;
    },
    [getGlobalCities.pending]: (state, action) => {
      state.loading = true;
    },
    [getGlobalCities.fulfilled]: (state, action) => {
      state.globalCities = action?.payload?.results?.map((item) => item);
      state.loading = false;
    },
    [getGlobalCompanies.fulfilled]: (state, action) => {
      state.globalCompanies = action?.payload?.results?.map((item) => item.name);
    },
    [createGlobalCompanies.fulfilled]: (state, action) => {
      state.companyCreated = true;
    },
    [getGlobalCompanyTypes.fulfilled]: (state, action) => {
      state.globalCompanyTypes = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalCompanySize.fulfilled]: (state, action) => {
      state.globalCompanySize = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalSkills.fulfilled]: (state, action) => {
      state.globalSkills = action?.payload?.results;
    },
    [getGlobalSchools.fulfilled]: (state, action) => {
      state.globalSchools = action?.payload?.results?.map((item) => item.name);
    },
    [createGlobalSchools.fulfilled]: (state, action) => {
      state.companyCreated = true;
    },
    [getGlobalColleges.fulfilled]: (state, action) => {
      state.globalColleges = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalCertificates.fulfilled]: (state, action) => {
      state.globalCertificates = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalCertificateAuthorities.fulfilled]: (state, action) => {
      state.globalCertificateAuthorities = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalCourses.fulfilled]: (state, action) => {
      state.globalCourses = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalDegrees.fulfilled]: (state, action) => {
      state.globalDegrees = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalIndustries.fulfilled]: (state, action) => {
      state.globalIndustries = action?.payload?.results?.map((item) => item.name);
    },
    [getGlobalLanguages.fulfilled]: (state, action) => {
      state.globalIndustries = action?.payload?.results?.map((item) => item.name);
    }
  },
});

export default globalDataSlice.reducer;
