import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/common/Button';
import { Spinner } from 'components/common/Loader';
import { City, Country, State } from "country-state-city";
import { getUserProfileInfo, updateUserDetails } from 'store/slices/userProfileSlice';
import Select from "react-select";
import { setOnboardingData } from 'store/slices/onboardingSlice';

const Location = () => {
  const dispatch = useDispatch();
  const { userProfileInfo, updateStatus, isProfileUpdated } = useSelector((state) => state.userProfile);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [loading, setLoading] = useState(false);
  const countries = Country.getAllCountries()

  const states = useMemo(() => {
    return State.getStatesOfCountry(country?.value)
  }, [country])
  const cities = useMemo(() => City.getCitiesOfState(country?.value, state?.value), [country, state])

  useEffect(() => {
    if (userProfileInfo?.id) {
      try {
        const country_ = Country.getAllCountries()?.find(c => c.name === userProfileInfo?.country)
        const state_ = State.getAllStates()?.find(c => c.name === userProfileInfo?.state)
        const city_ = City.getCitiesOfState(country_?.isoCode, state_?.isoCode)?.find(c => c.name === userProfileInfo?.city)
        if (country_?.isoCode) {
          setCountry({
            label: country_?.name,
            value: country_?.isoCode,
          })
        }
        if (state_?.name) {
          setState({
            label: state_?.name,
            value: state_?.isoCode,
          })
        }
        if (city_?.name) {
          setCity({
            label: city_?.name,
            value: city_?.name,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
    // return () => setValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileInfo]);

  useEffect(() => {
    if (isProfileUpdated) {
      setEditMode(false)
    }
  }, [isProfileUpdated])

  const onClickSave = () => {
    const errorObject = {};
    if (!country || !country.label || country.label.length <= 0) {
      errorObject.country = 'Please select a country';
    }

    if (Object.keys(errorObject).length > 0) {
      setErrors(errorObject);
      return;
    }
    const payload = {
      step: "profile",
      emailId: userProfileInfo?.email,
      data: {
        country: country?.label ?? "",
        state: state?.label ?? "",
        city: city?.label ?? "",
      }
    }
    setLoading(true);
    dispatch(setOnboardingData(payload)).then(() => {
      dispatch(getUserProfileInfo())
      setLoading(false)
      onClickCancel();
    }).catch(() => setLoading(false));
  };

  const onClickCancel = () => {
    setEditMode(false);
    setErrors({});
  };

  if (editMode) {
    return (
      <div className="row g-0 justify-content-md-between">
        <div className="col-12 col-lg">
          <div className="row">
            <div className="col-12">
              <p className="label mb-0">Location</p>
            </div>
            <div className="col-12 col-lg">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <Select
                    id="country"
                    name="country"
                    label="country"
                    placeholder="Country"
                    isSearchable
                    options={countries?.map(c => ({
                      label: c.name,
                      value: c.isoCode,
                    }))}
                    onChange={(value) => {
                      setCity(null)
                      setState(null)
                      setCountry(value)
                    }}
                    value={country}
                  />
                  {errors.country && <div className="text-danger">{errors.country}</div>}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <Select
                    id="state"
                    name="state"
                    label="state"
                    placeholder="State"
                    isSearchable
                    options={states?.map(c => ({
                      label: c.name,
                      value: c.isoCode,
                    }))}
                    onChange={(value) => {
                      setCity(null)
                      setState(value)
                    }}
                    value={state}
                  />
                  {errors.state && <div className="text-danger">{errors.state}</div>}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <Select
                    id="city"
                    name="city"
                    label="city"
                    placeholder="City"
                    isSearchable
                    options={cities?.map(c => ({
                      label: c.name,
                      value: c.name,
                    }))}
                    onChange={(value) => {
                      setCity(value)
                    }}
                    value={city}
                  />
                  {errors.city && <div className="text-danger">{errors.city}</div>}
                </div>

              </div>
            </div>
            <div className="d-flex gap-2 mt-4">
              <Button className="btn btn-secondary fw-normal" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button disabled={loading} className="btn btn-primary fw-normal" onClick={onClickSave}>
                {loading ? <Spinner /> : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row g-0 justify-content-md-between">
      <div className="col-12 col-lg">
        <div className="row">
          <div className="col-12 col-lg">
            <p className="label">Location</p>
          </div>
          <div className="col-12 col-lg">
            <p className="fw-semibold">
              {userProfileInfo?.city ? userProfileInfo?.city + ", " : ""}
              {userProfileInfo?.state ? userProfileInfo?.state + ", " : ""}
              {userProfileInfo?.country ? userProfileInfo?.country : ""}
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-2">
        <div className="text-lg-end">
          <p className="text-primary links" role="button" onClick={() => setEditMode(true)}>
            Change
          </p>
        </div>
      </div>
    </div>
  );
};

export default Location;
