import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { BorderLessButton, Button, Text } from 'components/StyledComponents';
import { Input } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import classNames from 'classnames';
import { EMPLOYMENT_CHOICES, WORKPLACE_TYPE } from 'data/data';
import { Country, State, City } from 'country-state-city';
import { getAllCompanyPages } from 'store/actions/companyPages.Action';
import { isEmpty } from 'lodash';

const IntroSection = ({ setSection, jobDetails, setJobDetails }) => {
  const { companyPageList } = useSelector((state) => state.companyPageReducer);
  const countries = Country.getAllCountries();
  const [countriesField, setCountriesField] = useState(
    {label: 'United States', value: 'US', name: 'United States', isoCode: 'US'});
  const [stateField, setStateField] = useState('');
  const [cityField, setCityField] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCompanyPages());
    if(localStorage.getItem("recentJob")){
      setJobDetails(JSON.parse(localStorage.getItem("recentJob")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [errors, setErrors] = useState({});

  const setData = (e, type) => {
    let name = "";
    let value = '';
    let checked = "";
    if (type === 'country') {
      name = 'country';
      value = e.name;
    } else if (type === 'state') {
      name = 'state';
      value = e.name;
    } else if (type === 'city') {
      name = 'city';
      value = e.name;
    } else { 
      name = e.target.name;
      value = e.target.value;
      checked = e.target.checked;
    }
    

    setJobDetails((state) => ({
      ...state,
      [name]: value,
    }));

    if (name === 'checkbox') {
      setJobDetails((state) => ({
        ...state,
        [name]: checked,
      }));
    }
  };

  const onClickNext = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!jobDetails.title) {
      newErrors.title = 'error';
    }

    if (!jobDetails.hiring_company) {
      newErrors.hiring_company = 'error';
    }

    if (!jobDetails.remote) {
      newErrors.remote = 'error';
    }

    if (!jobDetails.country) {
      if (countriesField) {
        setData(countriesField, 'country');
        setData(stateField, 'state');
        setData(cityField, 'city');
      } else { 
        newErrors.country = 'error';
      }
    }
    // if (!jobDetails.state) {
    //   newErrors.state = 'error';
    // }
    // if (!jobDetails.city) {
    //   newErrors.city = 'error';
    // }

    setErrors(newErrors);

    if (isEmpty(newErrors)) {
      setSection(2);
    }
  };

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));
  const updatedStates = (countryId) => {
    if (countryId) {
      return State.getStatesOfCountry(countryId).map((state) => ({
        label: state.name,
        value: state.isoCode,
        ...state,
      }));
    } else {
      return State.getAllStates().map((state) => ({ label: state.name, value: state.isoCode, ...state }));
    }
  };
  const updatedCities = (counrtyId, stateId) => {
    if (counrtyId || stateId) {
      return City.getCitiesOfState(counrtyId, stateId).map((city) => ({ label: city.name, value: city.id, ...city }));
    } else {
      return City.getAllCities().map((city) => ({ label: city.name, value: city.id, ...city }));
    }
  };
  return (
    <div className="container">
      <div className="row d-flex align-items-center flex-column">
        <div className="bordered-container col-md-5 mt-4 py-4">
          <div className="d-flex flex-column gap-1">
            <Text color="#868585">Job Title*</Text>
            <Input
              type="text"
              placeholder="Job Title"
              name="title"
              value={jobDetails.title}
              onChange={setData}
              className={classNames('jobFields', errors.title ? 'is-invalid' : '')}
              error={errors.title && 'Please enter title'}
            />
          </div>

          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center justify-content-between">
              <Text color="#868585">Hiring Company*</Text>
              <Link to="/create/company">Create company</Link>
            </div>
            <Dropdown
              id={'hiring_company'}
              name="hiring_company"
              options={companyPageList?.map((e) => ({ label: e.name, value: e.id }))}
              title={'hiring_company'}
              defaultValue="Select company"
              selectedValue={jobDetails.hiring_company}
              handleChange={setData}
              error={errors.hiring_company && 'Please select hiring company'}
              className={classNames('jobFields', errors.hiring_company ? 'is-invalid' : '')}
            />
          </div>

          <div className="d-flex flex-column gap-1 job-form-select">
            <Text color="#868585">Country*</Text>
            <Select
              id="country"
              name="country"
              label="country"
              placeholder="Country"
              isSearchable
              options={updatedCountries}
              onChange={(e) => { setData(e, 'country'); setCountriesField(e) }}
              className="jobFields mb-3"
              classNamePrefix="job-select"
              defaultValue={{ name: "United States", value: "US" }}
              value={updatedCountries.filter(function (option) {
                const countryName = countriesField?.name ? countriesField?.name : jobDetails?.country;
                return option.name === countryName;
              })}
            />
            {errors.country && <div className="text-danger helper-text mt-n3">Please select country</div>}
          </div>
          <div className="d-flex flex-column gap-1 job-form-select">
            <Text color="#868585">State*</Text>
            <Select
              id="state"
              name="state"
              label="State"
              placeholder="State"
              isSearchable
              options={updatedStates(countriesField ? countriesField?.isoCode : null)}
              onChange={(e) => { setData(e, 'state'); setStateField(e) }}
              className="jobFields mb-3"
              classNamePrefix="job-select"
              value={updatedStates(countriesField ? countriesField?.isoCode : null).filter(function (option) {
                const stateName = stateField?.name ? stateField?.name : jobDetails?.state;
                return option.label === stateName;
              })}
            />
            {errors.state && <div className="text-danger helper-text mt-n3">Please select state</div>}
          </div>
          <div className="d-flex flex-column gap-1 job-form-select">
            <Text color="#868585">City*</Text>
            <Select
              id="city"
              name="city"
              label="City"
              placeholder="City"
              isSearchable
              options={updatedCities(countriesField?.isoCode,stateField?.isoCode)}
              onChange={(e) => { setData(e, 'city'); setCityField(e) }}
              className="jobFields mb-3"
              classNamePrefix="job-select"
              value={updatedCities(countriesField?.isoCode,stateField?.isoCode).filter(function (option) {
                const cityName = cityField?.name ? cityField?.name : jobDetails?.city;
                return option.label === cityName;
              })}
            />
             {errors.city && <div className="text-danger helper-text mt-n3">Please select city</div>}
          </div>
          <div className="d-flex flex-column gap-1">
            <Text color="#868585">Postal Code</Text>
            <Input
              type="text"
              placeholder="Postal Code"
              name="postal_code"
              value={jobDetails.postal_code}
              onChange={setData}
              className={classNames('jobFields', errors.postal_code ? 'is-invalid' : '')}
              error={errors.postal_code && 'Please enter title'}
            />
          </div>

          <div className="d-flex flex-column gap-1">
            <Text color="#868585">Select Employment Type</Text>
            <Dropdown
              id={'employment_type'}
              name="employment_type"
              options={EMPLOYMENT_CHOICES}
              title={'employment_type'}
              defaultValue="Select employment type"
              selectedValue={jobDetails.employment_type}
              handleChange={setData}
              className={classNames('jobFields', errors.employment_type ? 'is-invalid' : '')}
              error={errors.employment_type && 'Please select employment type'}
            />
          </div>

          <div className="d-flex flex-column gap-1">
            <Text color="#868585">Workplace type*</Text>
            <Dropdown
              id={'remote'}
              name="remote"
              options={WORKPLACE_TYPE}
              title={'remote'}
              defaultValue="Workplace type"
              selectedValue={jobDetails.remote}
              handleChange={setData}
              className={classNames('jobFields', errors.remote ? 'is-invalid' : '')}
              error={errors.remote && 'Please Workplace type'}
            />
          </div>

          <div className="d-flex flex-column gap-1">
            <Button padding=".7rem" width="100%" bgColor="#3D5A80" color="#fff" onClick={onClickNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
