import styled from 'styled-components';

export const PostEventAcceptButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 12px;
  // background: #e18c3e;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #ffffff;
`;

export const PostEventAcceptedButton = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #00875a;
  padding: 10px;
  cursor: pointer;
`;

export default PostEventAcceptButton;
