import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from 'components/StyledComponents';
import ReadMore from 'components/common/ReadMore';
import EventPost from 'pages/Dashboard/Feed/Event';
import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import { getCompanyActiveJobs } from 'store/actions/companyJobs.Actions';
import { useDispatch, useSelector } from 'react-redux';
import SectionLoader from 'components/common/Loader/sectionLoader';

const ServiceBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  height: 15rem;
  flex: 1;
`;

const ImageWrapper = styled.div`
  height: 100px;
  width: 100px;
`;

const Image = styled.img`
  aspect-ratio: 1;
  width: 100%;
`;

const Overview = (props) => {
  const { companyDetails, postData, setOpenTab } = props;
  const { introduction, services } = companyDetails || {};
  const { companyJobsReducer } = useSelector((state) => state);
  const { activeJobs, overviewLoading } = companyJobsReducer;
  const [dataLength, setDataLength] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (companyDetails) {
      dispatch(getCompanyActiveJobs(companyDetails?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails]);

  useEffect(() => {
    let dataLength = {
      servicesLength: services?.length,
      jobsLength: activeJobs?.length,
      postsLength: postData?.results?.length,
    };
    setDataLength(dataLength);
  }, [services, postData, activeJobs]);

  if (!companyDetails) {
    return null;
  }

  const handleTabNavigation = (id) => {
    navigate('/company/alphabet-inc/' + id);
    setOpenTab(id);
  };
  return (
    <>
      {overviewLoading ? (
        <SectionLoader />
      ) : (
        <section>
          <div className="bordered-container">
            <div className="p-4">
              <Text fS="18px" fW="500" color="#5B5B5B" className="mb-3">
                About
              </Text>
              <ReadMore wordcount={200}>{introduction}</ReadMore>
            </div>
          </div>

          <div className="bordered-container container p-3">
            <Text fS="18px" fW="500" color="#5B5B5B" className="mt-3 mb-3">
              Services
            </Text>

            <div className="row gx-2">
              {services &&
                services?.length > 0 &&
                services.slice(0, 2).map((item) => (
                  <div className="col-md-6" key={item.id}>
                    <ServiceBox className="bordered-container">
                      <div className="d-flex justify-content-between align-items-center">
                        <ImageWrapper>
                          <Image src={item.image} alt={item.image} />
                        </ImageWrapper>
                      </div>

                      <div className="mt-2">
                        <Text fS="16px" fW="500" color="#5B5B5B" className="my-2">
                          {item.name}
                        </Text>

                        <Text fS="15px" fW="500" color="#868585">
                          {item.desc}
                        </Text>
                      </div>
                    </ServiceBox>
                  </div>
                ))}
              {/* {dataLength?.servicesLength > 2 && (
                <div className="border-top">
                  <Text color="#3D5A80" className="py-4 ps-2">
                    See all services
                  </Text>
                </div>
              )} */}
            </div>
          </div>

          <div className="bordered-container container mt-3 p-3">
            <Text fS="18px" fW="500" color="#5B5B5B" className="mt-3 mb-3">
              Recent job openings
            </Text>

            <div className="row gx-2">
              {activeJobs?.length > 0 &&
                activeJobs.slice(0, 2).map((item, index) => (
                  <div className="col-md-6" key={item.id}>
                    <JobCardIndividual
                      key={item.id + '_' + index}
                      index={index}
                      actions={true}
                      data={item}
                      candidateView={true}
                    />
                  </div>
                ))}
            </div>

            {dataLength?.jobsLength > 2 && (
              <div className="border-top">
                <Text
                  color="#3D5A80"
                  cursor="pointer"
                  className="py-4 ps-2"
                  onClick={() => handleTabNavigation('jobs')}
                >
                  See all jobs
                </Text>
              </div>
            )}
          </div>

          <div className="bordered-container container mt-3 p-3">
            <Text fS="18px" fW="500" color="#5B5B5B" className="ps-2 pt-4">
              Page posts
            </Text>

            <div className="row">
              {postData?.results?.length > 0 &&
                postData.results.slice(0, 2).map((item) => (
                  <div className="col-12">
                    <EventPost key={item.id} data={item} postType={'overview'} />
                  </div>
                ))}
            </div>

            {dataLength?.postsLength > 1 && (
              <div className="border-top">
                <Text
                  color="#3D5A80"
                  cursor="pointer"
                  className="py-4 ps-2"
                  onClick={() => handleTabNavigation('posts')}
                >
                  See all posts
                </Text>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Overview;
