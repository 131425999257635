import React, { useEffect, useState } from 'react';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { DegreeIcon, HandShakeSimpleIcon, TickMarkIcon } from 'components/common/Icons';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { requestConnection, requestFollow, getAllConnectionIDs} from 'store/slices/connectionSlice';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'components/common/Modal/Modal';
import { TextArea } from 'components/common/Input';
import { Link } from 'react-router-dom';

const NetworkuserCard = (props) => {
  const { user } = props;
  const { requestChange, report, allConnectionIDs } = useSelector((state) => state.connection);

  const dispatch = useDispatch();
  const userInfo = user;
  const username = `${userInfo.first_name} ${userInfo.last_name}`;
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [directRequest, setDirectRequest] = useState(true);
  const [addCustomMessage, setAddCustomMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (requestChange) {
      setOpenRequestModal(false);
      setCustomMessage();
      setAddCustomMessage(false);
      setDirectRequest(true);
    }
  }, [requestChange]);

  const handleHandshakeReq = (id) => {
    dispatch(requestConnection({ to_user_id: id, invite_message: customMessage }));
  };
  const handleFollowReq = (id) => {
    dispatch(requestFollow({ follow_to: id }));
  };

  const checkforButtonDisable =(id) =>{
    return allConnectionIDs?.handshake_requests?.includes(id)||allConnectionIDs?.circle?.includes(id)
  }

  const handleClose = () => {
    setOpenRequestModal(false);
  }; 
  return (
    <>
      <div className="user-card" style={{ padding: '15px 16px' }}>
        <div className="user-card--profile">
          <MyBridgeAvatar name={username} size="60" round={true} />
        </div>
        <div className="user-card--details">
          <h5 className="card-name">{username}</h5>
          <p className="card-desc">{userInfo.position}</p>
          {/* <p className="card-degrees"><span>15 <DegreeIcon width="12" /> 1st Degrees</span><span>15 <DegreeIcon width="12" /> 2nd Degrees</span> <span>15 <DegreeIcon width="12" /> 3rd Degrees</span></p> */}
          <div className="button-section">
            <Button className={'plr-font'} variant="btn-secondary" disabled={checkforButtonDisable(userInfo?.id)} onClick={() => setOpenRequestModal(true)}>
              <HandShakeSimpleIcon mR="10" color={checkforButtonDisable(userInfo?.id)?"#037f29b3":''}/> Handshake
            </Button>
            <Button className="plr-font" onClick={() => handleFollowReq(userInfo.id)}>
              Follow
            </Button>
          </div>
        </div>
      </div>

      {openRequestModal && (
        <Modal
          onClose={handleClose}
          className="confirmation-modal"
          heading={<h3>{!addCustomMessage ? 'You can customize this invitation' : 'Invite Kamal kant to connect'}</h3>}
          action={
            <>
              {directRequest ? (
                <Button
                  className="h-36"
                  variant="btn-secondary"
                  onClick={() => {
                    setAddCustomMessage(true);
                    setDirectRequest(false);
                  }}
                >
                  Add a note
                </Button>
              ) : (
                <Button className="h-36" variant="btn-secondary" onClick={handleClose}>
                  Cancel
                </Button>
              )}

              <Button className="h-36" onClick={() => handleHandshakeReq(userInfo.id)}>
                Send
              </Button>
            </>
          }
        >
          {directRequest && (
            <p>
              <TickMarkIcon /> You can add a note to personalize your invitation to {username}
            </p>
          )}
          {addCustomMessage && (
            <div>
              <p className="mb-2">Build a quality network by connecting only with people you know.</p>
              <div className="clearfix">
                <TextArea
                  label="Message (optional)"
                  placeholder="Ex We know each other from..."
                  rows="3"
                  onChange={(e) => {
                    setCount(e.target.value.length);
                    setCustomMessage(e.target.value);
                  }}
                  className={count >= 300 && 'is-invalid'}
                  maxLength={300}
                  error={count >= 300 && 'Character Limit is reached'}
                />
                <span className="form-text float-end text-end mt-0">{count}/300</span>
              </div>
              {/* <div className="mt-4">
                <h6 className="mb-1">PREMIUM</h6>
                <p className="mb-1">
                  Don’t know Kamal kant? Send an InMail with Premium to introduce yourself. More people reply to an
                  InMail than a connection request.
                </p>
                <p>
                  <Link>Retry Premium Free</Link>
                </p>
              </div> */}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default NetworkuserCard;
