import PostImage from 'components/PostCard/PostBody/PostImage';
import { DeleteIcon } from 'components/common/Icons';
import PostFormContext from 'context/PostFromContext';
import React, { memo, useContext } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin: 5px;
  img {
    max-width: 100%;
    width: 100%;
    display: block;
    border-radius: 20px;
  }
  button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    transition: all ease-in-out 0.3s;
    border: none;

    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
  }
`;

const PostSelectedImage = ({ file, index }) => {
  const { removeImage } = useContext(PostFormContext);
  let src;
  const isGif = file?.type === 'gif';
  if (isGif) {
    if (file?.images) {
      src = file?.images['downsized'].url;
    } else {
      src = file.file;
    }
  } else {
    if (file.id) {
      src = file.file;
    } else {
      src = URL.createObjectURL(file);
    }
  }

  return (
    <Container>
      <PostImage src={src} alt={file.name} key={file.name} style={{ width: '100%' }} />
      <button
        onClick={() => {
          removeImage(index);
        }}
      >
        <DeleteIcon color="#fff" width={20} height={20} />
      </button>
    </Container>
  );
};

export default memo(PostSelectedImage);
