import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";
import {
    CometChatContextProvider,
    CometChatContext,
} from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import { CometChatAvatar } from "../../Shared";
import {
    checkMessageForExtensionsData,
    ID,
    getUnixTimestamp,
} from "../../../util/common";


import addUser from './resources/addUsers.svg';
import editName from './resources/editName.svg';
import groupMembers from './resources/groupMembers.svg';
import editPhotoIcon from './resources/editPhotoIcon.svg';
import Modal from "components/common/Modal/Modal";
import ImageUploader from 'components/ImageUploader';
import { Input, TextArea } from "components/common/Input";
import Button from "components/common/Button";
import AddMembersModal from "components/addMembersModal";


class CometChatGroupAdminSection extends React.PureComponent {
    static contextType = CometChatContext;

    constructor(props) {
        super(props);
        this.token = localStorage.getItem('access');
        this.state = {
            openCopperModal: false,
            cropType: null,
            loggedInUser: null,
            showNameModal: false,
            showAddMemberModal: false,
            customMessage: '',
            selectedOption: null,
            userOptions: [],
            userList:[],
        };

        this.contextProviderRef = React.createRef();
        this.composerRef = React.createRef();
        this.reactionName = "heart";
        this.commonHeader = {
            apiKey: '01bd9d9ac16c296fe50adbb91de88f08715625db',
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
        this.commonHeaderAPI = {
            apiKey: this.token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }

        this.baseUrl = 'https://2276002860111f2a.api-us.cometchat.io/v3/';
        this.apiUrl = `${process.env.REACT_APP_API_URL}`;
    }

    componentDidMount() {
        CometChat.getLoggedinUser()
            .then((user) => {
                this.setState({
                    loggedInUser: user,
                });
            })
            .catch((error) =>
                this.props.actionGenerated(
                    enums.ACTIONS["ERROR"],
                    [],
                    "SOMETHING_WRONG"
                )
            );
    }

    getReceiverDetails = () => {
        let receiverId;
        let receiverType;

        if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER) {
            receiverId = this.context.item.uid;
            receiverType = CometChat.RECEIVER_TYPE.USER;
        } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
            receiverId = this.context.item.guid;
            receiverType = CometChat.RECEIVER_TYPE.GROUP;
        }

        return { receiverId: receiverId, receiverType: receiverType };
    };
    
    sendMediaMessage = (image) => {
        const { receiverId, receiverType } = this.getReceiverDetails();
        const customType = 'file';
        const newType = CometChat.ACTION_TYPE.TYPE_GROUP;
        const customMessage = new CometChat.MediaMessage(
            receiverId,
            image,
            customType,
            receiverType
        );

        customMessage.setSender(this.loggedInUser);
        customMessage.setReceiver(this.context.type);
        customMessage.setMetadata({ showMessageinChat: false });
        customMessage._composedAt = getUnixTimestamp();
        customMessage._id = ID();

        this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
            customMessage,
        ]);

        CometChat.sendMessage(customMessage)
            .then((message) => {
                const newMessageObj = { ...message, _id: customMessage._id };
                // this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
                //     newMessageObj,
                // ]);
                const userChatOptions = {
                    method: 'PUT',
                    headers: {
                        ...this.commonHeader,
                    },
                    body: JSON.stringify({
                        icon: newMessageObj?.data?.url,
                    })
                };

                fetch(`${this.baseUrl}groups/${receiverId}`, userChatOptions)
                    .then(response => response.json())
                    .then(response => {
                        this.context.setTypeAndItem(newType, response?.data)
                        this.setState({ openCopperModal: false });
                    })
                    .catch(err => console.error(err));
            })
            .catch((error) => {
                const newMessageObj = { ...customMessage, error: error };
                this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
                    newMessageObj,
                ]);
            });
    };

    uploadCropedImg = (img) => {
        const file = img;
        const uploadedFile = file;
        var reader = new FileReader(); // Creating reader instance from FileReader() API
        reader.addEventListener(
            "load",
            (event) => {
                // Setting up base64 URL on image

                const newFile = new File(
                    [reader.result],
                    uploadedFile.name,
                    uploadedFile
                );
                this.sendMediaMessage(newFile);
            },
            false
        );
        reader.readAsArrayBuffer(uploadedFile);
    }

    editName = () => {
        this.setState({ showNameModal: true });
    }
    addMember = () => {
        this.setState({ showAddMemberModal: true });
    }

    handleNameChange = () => {
        const { receiverId } = this.getReceiverDetails();
        const newType = CometChat.ACTION_TYPE.TYPE_GROUP;
        const nameChangeOption = {
            method: 'PUT',
            headers: {
                ...this.commonHeader,
            },
            body: JSON.stringify({
                name: this.state.customMessage,
            })
        };

        fetch(`${this.baseUrl}groups/${receiverId}`, nameChangeOption)
            .then(response => response.json())
            .then(response => {
                this.context.setTypeAndItem(newType, response?.data)
                this.setState({ showNameModal: false });
            })
            .catch(err => console.error(err));
    }

    viewMembers = () => { 
        this.props.actionGenerated(enums.ACTIONS["VIEW_DETAIL"]);
    }

    handlePopups = (type) => {
        this.setState({ openCopperModal: false });
        this.setState({ showNameModal: false });
        this.setState({ showAddMemberModal: false });

    };
    updateGroupDisplayImage = () => {
        this.setState({ openCopperModal: true });
        this.setState({ cropType: 'profilePhoto' });
    }

    addSelectedMembers = (users) => { 
        let data = [];
        users?.map((user) => { 
            data.push(user.user_uuid.toString());
        })
        const { receiverId } = this.getReceiverDetails();
        const addselectedmembers = {
            method: 'POST',
            headers: {
                ...this.commonHeader,
            },
            body: JSON.stringify({
                "participants": data
            })
        };

        fetch(`${this.baseUrl}groups/${receiverId}/members`, addselectedmembers)
            .then(response => response.json())
            .then(response => {
                this.setState({ showAddMemberModal: false });
            })
            .catch(err => console.error(err));
    }

    render() {
        // Adding Image logo to group
        let addImageModal = null;
        if (this.state.openCopperModal) {
            addImageModal = (<Modal onClose={this.setState({ showNameModal: false })} className={this.state.cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}>
                <ImageUploader cropType={this.state.cropType} uploadCropedImg={this.uploadCropedImg} handlePopups={this.handlePopups} />
            </Modal>)
        }
        // Change group name to group
        let editNameModal = null;
        if (this.state.showNameModal) {
            editNameModal = (<Modal onClose={this.handlePopups}
                heading={<h3 className="deactivation-header">
                    Change chat name
                </h3>}
                action={
                    <>
                        <Button className="btn btn-secondary btn-sm" onClick={this.handlePopups}>Cancel</Button>
                        <Button className="btn btn-primary btn-sm" onClick={this.handleNameChange}>Save</Button>
                    </>
                }
            >
                <div className="chatNameModal">
                    <p>Changing the name of a group chat changes it for everyone.</p>
                    <div className='text-area-container'>
                        <TextArea
                            placeholder="Chat name"
                            rows="2"
                            onChange={(e) => {
                                this.setState({ customMessage: e.target.value });
                            }}
                            className={this.state.customMessage?.length >= 300 && 'is-invalid'}
                            maxLength={100}
                            error={this.state.customMessage?.length >= 300 && 'Character Limit is reached'}
                        />
                        <span className="form-text float-end text-end mt-n3">{this.state.customMessage?.length}/300</span>
                    </div>
                </div>
            </Modal>)
        }
        // Adding Members to group
        let addMemmberstoGroup = null;
        if (this.state.showAddMemberModal) {
            addMemmberstoGroup = (<AddMembersModal onClose={this.handlePopups} selectedUsers={(value) => this.addSelectedMembers(value)} />)
        }

        let actionComponent = (
            <React.Fragment>
                <div className="group-admin-section">
                    <div className='group-admin-section--avatar' onClick={this.updateGroupDisplayImage}>
                        <CometChatAvatar group={this.context.item} />
                        <div className="edit-icon"><img src={editPhotoIcon} alt="" title="" /></div>
                    </div>
                    <h3 className='group-admin-section--name'>{this.context.item.name}</h3>
                    <p className='group-admin-section--subtext'>You created this group</p>

                    <div className="group-admin-section--actions">
                        <button onClick={this.addMember}><img src={addUser} alt="" title="" width={50} /> Add</button>
                        <button onClick={this.editName}><img src={editName} alt="" title="" width={50} /> Name</button>
                        <button onClick={ this.viewMembers}><img src={groupMembers} alt="" title="" width={50} /> Members</button>
                    </div>
                </div>
                {addImageModal}
                {editNameModal}
                {addMemmberstoGroup}
            </React.Fragment>

        );

        return actionComponent;
    }
}

// Specifies the default values for props:
CometChatGroupAdminSection.defaultProps = {

};

CometChatGroupAdminSection.propTypes = {

};

export { CometChatGroupAdminSection };