import DefaultSearchIcon from 'components/common/Icons/DefaultSearchIcon';
import React from 'react';
// import { Link } from 'react-router-dom';

const DefaultSearchPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column text-center align-items-center py-5">
        <DefaultSearchIcon />
        <h5>No results found</h5>
        <h5>Try shortening or rephrasing your search.</h5>
        {/* <h5>  
          <Link>Edit search</Link>
        </h5> */}
      </div>
    </div>
  );
};

export default DefaultSearchPage;
