import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { genralMonths } from 'data/data';
import { VerticalLine } from 'components/StyledComponents';

const CertificationName = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;

  color: #000000;
`;

const CertificationText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  color: ${(props) => props.color || '#747474'};
`;

const CertificationLink = styled(Link)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  color: #e18c3e;
`;

const CertificationsContainer = styled.div``;

const Certifications = (props) => {
  const { publicProfileData } = useSelector((state) => state.userProfile);
  const { profile_certificates: certificates } = publicProfileData;

  return (
    <CertificationsContainer>
      {certificates &&
        certificates?.map((certificate, id) => (
          <>
            <div className="mb-4" key={id}>
              <div className="d-block d-lg-flex align-items-center justify-content-between">
                <CertificationName>{certificate.cert_name}</CertificationName>
              </div>
              <CertificationText className="mb-1">
                <span>
                  {`${genralMonths[parseInt(certificate?.start_month - 1)]?.label}`}
                  {` ${certificate?.start_year}`}
                </span>
                {!certificate.cert_not_expire ? (
                  <>
                    {!!certificate?.end_month&& !!certificate?.end_year && (
                      <span>
                        {` - ${genralMonths[parseInt(certificate?.end_month - 1)]?.label}`}
                        {` ${certificate?.end_year}`}
                      </span>
                    )}
                  </>
                ) : (
                  <span>{` - Present`}</span>
                )}
              </CertificationText>
              <CertificationText className="mb-1">{certificate.cert_authority}</CertificationText>

              <CertificationText className="mb-1 text-grey">{certificate.license_number}</CertificationText>

              <CertificationLink className="mb-1" href={certificate.cert_url} target="_blank">
                <span style={{ color: '#3D5A80' }}>{certificate.cert_url}</span>
              </CertificationLink>
            </div>
            {certificates.length > 0 && <VerticalLine />}
          </>
        ))}
    </CertificationsContainer>
  );
};

export default Certifications;
