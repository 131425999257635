import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

const initialState = {};

export const unFollowUser = createAsyncThunk('unFollow', async (body) => {
  const obj = {
    url: APIEndPoints.Follower,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const followUser = createAsyncThunk('follow', async (body) => {
  const obj = {
    url: APIEndPoints.Follower,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

const followSlice = createSlice({
  name: 'follow',
  initialState,
  reducers: {},

  extraReducers: {},
});

export default followSlice.reducer;
