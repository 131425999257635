import { useMutation } from '@tanstack/react-query';
import { deletePost } from 'api/post';
import { Spinner } from 'components/common/Loader';
import Modal from 'components/common/Modal/Modal';
import { Flex, Text } from 'components/core';
import PostContext from 'context/PostContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const DeleteHeading = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #3d5a80;
`;

const DeleteUnOrdereList = styled.ul`
  list-style: none;
  li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    color: #3d5a80;
    margin-bottom: 10px;
  }
  li:before {
    content: '•';
    font-size: 30px;
    padding-right: 5px;
  }
`;

const DeleteButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  width: 100px;
  height: 36px;

  background: #e13e3e;
  border-radius: 5px;
  border: none;
  color: #fff;
`;

const DeleteCancelButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;

  width: 100px;
  height: 36px;

  background: #e4e6eb;
  border-radius: 5px;
  border: none;
  color: #3d5a80;
`;

const DeletePostModal = ({ onClose }) => {
  const { postData, updatePost } = useContext(PostContext);
  const { id } = postData;
  const postId = id;
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(deletePost, {
    onSuccess: (data) => {
      onClose();
      //navigate("/")
      setTimeout(() => {
        updatePost();
      }, 100);
    },
    onError: (error) => {
      onClose();
      console.log(error);
    },
  });

  const handleDelete = () => {
    mutate({ id: postId });
  };

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<DeleteHeading>Delete Post</DeleteHeading>}
      action={
        <>
          <DeleteCancelButton onClick={onClose}>Cancel</DeleteCancelButton>
          <DeleteButton onClick={handleDelete}>{isLoading ? <Spinner /> : 'Delete'}</DeleteButton>
        </>
      }
    >
      <Flex padding="16px 24px" direction="column">
        <Text.Label size={18} margin="0px 0px 20px 0px">
          This can’t be undone and it will be removed from
        </Text.Label>
        <DeleteUnOrdereList>
          <li>Your profile.</li>
          <li>The timeline of any accounts that follow you.</li>
          <li>From myBridge search results.</li>
        </DeleteUnOrdereList>
      </Flex>
    </Modal>
  );
};

export default DeletePostModal;
