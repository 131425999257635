import servicesPic from '../../assets/profiles/profile/avtar_md.jpeg';

export const lastModifyValue = [
  {
    label: 'Any date',
  },
  {
    label: 'Last 24 hours',
  },
  {
    label: 'Last 7 days',
  },
  {
    label: 'Last 30 days',
  },
  {
    label: 'Last 90 days',
  },
  {
    label: 'Last 12 months',
  },
  {
    label: 'Custom',
  },
];
export const propleList = [
  {
    label: 'Anyone',
  },
  {
    label: 'Sola Daves',
  },
  {
    label: 'Manish kumar',
  },
  {
    label: 'Asim',
  },
];
export const sortList = [
  {
    label: 'Most relevant',
  },
  {
    label: 'Newest',
  },
  {
    label: 'Oldest',
  },
];
export const diskSortList = [
  {
    label: 'Newest',
    value: "newest"
  },
  {
    label: 'Oldest',
    value: "oldest"
  },
];

export const services = [
  {
    servicesImg: servicesPic,
    serviceTitle: 'Data Technology & Strategy Consulting',
    servicesDetails:
      'Duration varies At databridge, we provide Artificial intelligence, Big Data & Data Science consulting.',
  },
  {
    servicesImg: servicesPic,
    serviceTitle: 'Data Technology & Strategy Consulting',
    servicesDetails:
      'Duration varies At databridge, we provide Artificial intelligence, Big Data & Data Science consulting.',
  },
  {
    servicesImg: servicesPic,
    serviceTitle: 'Data Technology & Strategy Consulting',
    servicesDetails:
      'Duration varies At databridge, we provide Artificial intelligence, Big Data & Data Science consulting.',
  },
];
export const JobList = [
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
  {
    logo: servicesPic,
    jobTitle: 'Financial Analyst Entry Level',
    JobDetails: 'Lenmar Consulting Inc. South Jordan, UT',
    ctc: '$20 to $23 Hourly',
    jobType: 'Full-Time',
    industry: 'Dental, Medical, Vision',
    otherDetail: 'At databridge, we provide Artificial intelligence, Big Data & Data Science consulting. ',
    postedDay: '41',
    jobview: '25',
    candidates: '5',
    status: 'Closed',
  },
];
export const DEBOUNCE_DELAY = 600;
