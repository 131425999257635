import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { LANG_CHOICES } from 'data/data';

import { CausesIcon, DOBIcon, EmailIcon, InterestIcon, LanguageIcon, PhoneIcon } from 'components/common/Icons';
import { Text } from 'components/StyledComponents';

const PersonalDetailsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`;

const PersonalDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PersonalDetailsValue = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) => props.color || '#525252'};
`;

const PersonalDetails = (props) => {
  const { publicProfileData } = useSelector((state) => state.userProfile);

  const [causesYou, setCausesYou] = useState();

  useEffect(() => {
    if (publicProfileData) {
      let causes_you = publicProfileData?.causes_you_care?.split(',');
      // console.log(causes_you)
      setCausesYou(causes_you);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicProfileData]);

  return (
    <>
      {publicProfileData?.email && (
        <PersonalDetailsContainer>
          <EmailIcon />

          <PersonalDetailsContent>
            <Text fW={600} fS="16px" color="#525252">
              Email
            </Text>
            <PersonalDetailsValue color="#3D5A80">{publicProfileData?.email}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {publicProfileData?.mobile_number && (
        <PersonalDetailsContainer>
          <PhoneIcon />
          <PersonalDetailsContent>
            <Text fW={600} fS="16px" color="#525252">
              Mobile Number
            </Text>
            <PersonalDetailsValue color="#3D5A80">{publicProfileData?.mobile_number}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {publicProfileData?.lang && (
        <PersonalDetailsContainer>
          <LanguageIcon />
          <PersonalDetailsContent>
            <Text fW={600} fS="16px" color="#525252">
              Languages
            </Text>
            <PersonalDetailsValue>
              {LANG_CHOICES.find((item) => item.value === publicProfileData?.lang)?.label}
            </PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {publicProfileData?.dob && (
        <PersonalDetailsContainer>
          <DOBIcon />
          <PersonalDetailsContent>
            <Text fW={600} fS="16px" color="#525252">
              Date of Birth
            </Text>
            <PersonalDetailsValue>{moment(publicProfileData?.dob).format('MMMM DD YYYY')}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {publicProfileData?.interested_in && (
        <PersonalDetailsContainer>
          <InterestIcon />
          <PersonalDetailsContent>
            <Text fW={600} fS="16px" color="#525252">
              Interests
            </Text>
            <PersonalDetailsValue>{publicProfileData?.interested_in}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {publicProfileData?.causes_you_care?.length > 0 && (
        <PersonalDetailsContainer>
          <CausesIcon />
          <PersonalDetailsContent>
            <Text fW={600} fS="16px" color="#525252">
              Causes you care about?
            </Text>
            <PersonalDetailsValue>
              <ul>
                {causesYou?.map((item, i) => {
                  if (causesYou.length === i + 1) {
                    return <span key={i}>{item}</span>;
                  } else {
                    return <span key={i}>{item}, </span>;
                  }
                })}
              </ul>
            </PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}
    </>
  );
};

export default PersonalDetails;
