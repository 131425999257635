import React, { useEffect, useState } from 'react';
import banner from 'assets/profiles/banner/banner.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { css, cx } from '@emotion/css';
import { MyBridgeCompoanyAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import { joinTeamRequest } from 'store/slices/teamCommunitySlice';
import { Spinner } from 'components/common/Loader';


const Banner = (props) => {
  const { userRequested, loading } = useSelector((state) => state.teamsCommunity);
  const { teamDetails, teamOrganisers, alreadyInvited, handleAcceptInvite, handleJoinRequest, handleWithdrawRequest, alreadyRequested, isOrganizer } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slug = useParams();
  const [joinButtonText, setJoinButtonText] = useState('Join Team');
  const [joinButtonDisable, setJoinButtonDisable] = useState(false);

  const url = `teams/${slug.slug}`;

  const { userProfileInfo } = useSelector((state) => state.userProfile);


  const customCSS = {
    imgBg: css`
      background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%), url(${teamDetails?.banner_image}), #ffffff;
      background-size: cover;
    `,
  };

  return (
    <section className="container bordered-container public_profile__section">
      <div className="">
        <div className={`Profile_Banner ${cx(customCSS.imgBg)}`}>
          <div className="banner container p-0">
            <img src={teamDetails?.banner_image || banner} className="img-fluid" alt="banner" />
          </div>
        </div>

        <div className="Profile_Banner container">
          <div className="user border border-0">
            <div className="avatar">
              <MyBridgeCompoanyAvatar
                icon={teamDetails?.image_logo}
                name={teamDetails?.name}
                size={120}
                className="profile-avatar"
              />
            </div>

            <div className="left">
              <div className="about">
                <div className="info">
                  <p className="profile__user__name text-capitalize">{teamDetails?.name}</p>
                </div>

                <div className="personal_info">
                  {teamDetails?.industry_name?.length ? (teamDetails?.industry_name?.[0]?.name) : ''} {(teamDetails?.industry_name?.[0]?.name && teamDetails?.location?.length) ? " | " : ""} {teamDetails?.location?.length ? (teamDetails?.location) : ""}
                  {/* <p className="user__position">{teamDetails?.industry_name?.length ?teamDetails?.industry_name?.[0]?.name : 'NA'} | {teamDetails?.location}</p> */}
                </div>
              </div>
            </div>

            <div className="right">
              <div className="count">
                {teamOrganisers && teamOrganisers.length > 0 ? teamOrganisers?.map(o => ((o?.user?.first_name ?? "") + " " + (o?.user?.last_name ?? "")))?.join(", ")
                  // map((organizer, i) => (
                  //   <div className="circle" key={i}>{organizer.user.first_name} {organizer.user.last_name}</div>
                  // ))
                  :
                  `${userProfileInfo.first_name} ${userProfileInfo.last_name}`}
                {/* <div className="circle">{allOrganisers?.user?.first_name} {allOrganisers?.user?.last_name}</div> */}

                <div className="mybridge_count">
                  <span className="organizer-label">Organizer</span>
                </div>
              </div>

              <div className="actions">
                {alreadyInvited ?
                  <Button className="btn-join-team btn-sm btn-secondary mt-3" onClick={handleAcceptInvite} disabled={loading}>
                    {loading ? <Spinner /> : "Accept Invite"}
                  </Button> :
                  <Button className="btn-join-team btn-sm btn-secondary mt-3" onClick={(alreadyRequested) ? handleWithdrawRequest : handleJoinRequest} disabled={loading}>
                    {loading ? <Spinner /> : (alreadyRequested ? "Withdraw Request" : "Join")}
                  </Button>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </section >
  );
};
export default Banner;
