import React, { useState } from 'react';

import Modal from 'components/common/Modal/Modal';
import { Text } from 'components/StyledComponents';
import { Input } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import { deleteCompanyPage } from 'store/actions/companyPages.Action';
import { useDispatch, useSelector } from 'react-redux';

const DeactivatePageModal = (props) => {
  const { company, onClose, setModalType } = props;

  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const onSubmit = () => {
    console.log(data, company);
    dispatch(deleteCompanyPage({ id: company.id })).then((res) => {
      if (res) {
        setModalType('');
      }
    });
  };

  return (
    <Modal
      onClose={onClose}
      heading={
        <Text fW="700" fS="24px">
          Deactivation Page
        </Text>
      }
      className={'confirmation-modal'}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center justify-content-between gap-3">
          <Text fW="500" fS="16px">
            Are you sure you want to deactivate page for {company.name}? Y/N
          </Text>
          <Dropdown
            id={'are_you_sure'}
            name={'are_you_sure'}
            options={[
              { label: 'Y', value: 'Y' },
              { label: 'N', value: 'N' },
            ]}
            title={'are_you_sure'}
            handleChange={handleChange}
            defaultValue="-"
            containerClass="w-auto m-0"
            selectedValue={data.are_you_sure}
          />
        </div>
        <div className="d-flex flex-column gap-3">
          <Text fW="500" fS="16px">
            Before you deactivate this page, can you tell us why?
          </Text>
          <Input
            containerClass="d-flex"
            className="rounded"
            type="text"
            placeholder="--"
            name="why"
            value={data.why}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column gap-3">
          <Text fW="500" fS="16px">
            To continue, please re-enter your password:
          </Text>
          <Input
            containerClass="d-flex"
            className="rounded"
            type="password"
            placeholder="Password"
            name="password"
            value={data.password}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex align-self-end gap-3">
          <Button variant="btn-secondary">
            <Text fS="15px" fW="500" color="#3D5A80" onClick={onClose}>
              Cancel
            </Text>
          </Button>
          <Button onClick={onSubmit}>Deactivate</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivatePageModal;
