import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { Button, Text } from 'components/StyledComponents';
import { Input } from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';

import { BENEFITS, CURRENCY, PAYMENT_TYPE } from 'data/data';

import { getAllCompanyPages } from 'store/actions/companyPages.Action';

const BenefitsSection = ({ setSection, jobDetails, setJobDetails }) => {
  const dispatch = useDispatch();
  const [defaultCurrency, setDefaultCurrency] = useState('USD');

  useEffect(() => {
    dispatch(getAllCompanyPages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, setErrors] = useState({});

  const setData = (e) => {
    const { name, value, checked } = e.target;

    setJobDetails((state) => ({
      ...state,
      [name]: value,
    }));

    if (name === 'plus_comission') {
      setJobDetails((state) => ({
        ...state,
        [name]: checked,
      }));
    }

    if (name === 'currency_choice') { 
      setJobDetails((state) => ({
        ...state,
        [name]: value,
      }));
    }

    if (name === 'benefits') {
      setErrors({ ...errors, benefits: '' });
      let benefits = jobDetails.benefits ? [...jobDetails.benefits] : [];
      if (checked) {
        if (value === 'None of these') {
          benefits = [value];
          benefits = benefits.filter((benefit) => benefit === 'None of these');
          // benefits = [];
        } else {
          benefits = benefits.filter((benefit) => benefit !== 'None of these');
          benefits.push(value);
        }
      } else {
        benefits = benefits.filter((benefit) => benefit !== value);
      }
      setJobDetails((state) => ({
        ...state,
        [name]: benefits.includes('None of these') ? null : benefits,
      }));
    }
  };

  const onClickBack = (e) => {
    e.preventDefault();
    setSection(1);
  };

  const onClickNext = (e) => {
    e.preventDefault();

    const newErrors = {};
    // if (jobDetails?.benefits?.length <= 0) {
    //   newErrors.benefits = 'error';
    // }

    if (!jobDetails.currency_choice) { 
      let data = { target: { name: 'currency_choice', value: defaultCurrency } }
      setData(data);
    }

    setErrors(newErrors);

    if (isEmpty(newErrors)) {
      setSection(3);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex align-items-center flex-column">
        <div className="bordered-container col-md-5 mt-4 py-4">
          <div className="d-flex flex-column gap-1">
            <Text color="#3D5A80">Benefits*</Text>
            <div className="d-flex flex-column">
              {BENEFITS.map((item, index) => (
                <Checkbox
                  value={item.label}
                  name="benefits"
                  label={item.label}
                  onChange={setData}
                  className={'m-0 py-1'}
                  key={index}
                  checked={jobDetails?.benefits?.find((e) => e === item.label)}
                  id={item.id}
                />
              ))}
              {errors.benefits && <p className="text-danger">Please select at least one benefit</p>}
            </div>
          </div>

          <div className="d-flex flex-column gap-1 mt-3">
            <Text color="#868585">Compensation Range</Text>

            <div className="d-flex gap-2">
              <Input
                containerClass="d-flex"
                className="rounded"
                startIcon="$"
                type="number"
                placeholder="Min Amount"
                name="compensation_range_min_amt"
                value={jobDetails.compensation_range_min_amt}
                onChange={setData}
              />
              <Input
                containerClass="d-flex"
                className="rounded"
                startIcon="$"
                type="number"
                placeholder="Max Amount"
                name="compensation_range_max_amt"
                value={jobDetails.compensation_range_max_amt}
                onChange={setData}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-1">
            <div className="d-flex gap-2">
              <Dropdown
                id={'currency_choice'}
                name={'currency_choice'}
                options={CURRENCY}
                title={'currency_choice'}
                handleChange={setData}
                defaultValue="Select Currency"
                containerClass={'mb-0'}
                selectedValue={jobDetails?.currency_choice ? jobDetails?.currency_choice : defaultCurrency}
              />
              <Dropdown
                id={'payment_choice'}
                name={'payment_choice'}
                options={PAYMENT_TYPE}
                title={'payment_choice'}
                handleChange={setData}
                defaultValue="Select Pay Type"
                containerClass={'mb-0'}
                selectedValue={jobDetails?.payment_choice}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-1">
            <Checkbox
              name="plus_comission"
              label={'Plus Commission'}
              onChange={setData}
              checked={jobDetails?.plus_comission}
            />
          </div>

          <div className="d-flex gap-2">
            <Button padding=".7rem" width="100%" bgColor="#E4E6EB" hoverBgColor="#3D5A80" color="#5B5B5B" onClick={onClickBack}>
              Back
            </Button>
            <Button padding=".7rem" width="100%" bgColor="#3D5A80" hoverBgColor="#003049" color="#fff" onClick={onClickNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
