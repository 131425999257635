import React, { useEffect, useState } from 'react';
import { Input } from 'components/common/Input';
import { SimpleCloseIcon } from '../Icons';

const AutoComplete = (props) => {
  const { tags, tagList, onChange, name, limit, placeholder, className, ...restProps } = props ?? {}
  const [value, setValue] = useState('');

  // useEffect(()=>{
  //   console.log("new tags:",tags)
  // },[tags])

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
    }
  };

  const handleDelete = (id) => () => {
    if (tags && tags.length) {
      const filteredTags = tags.filter((item) => item !== id);

      if (filteredTags.length < tags.length) {
        onChange?.(name, filteredTags);
      }
    }
  };

  const handleValue = (item) => {
    const exists = tags?.find(t => t === item.id)
    if (!exists) {
      const newTags = [...(tags ?? []), item?.id]
      setValue('');
      // console.log("setting", name, newTags)
      onChange?.(name, newTags)
    }
  };

  return (
    <div className={className ? `${className} search-chip` : "search-chip"}>
      <Input
        id="clauses"
        placeholder={tags?.length >= limit ? 'Limit Reached' : placeholder}
        fullWidth
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={tags?.length >= limit ? true : false}
      />

      {value && (
        <ul className="tagList">
          {tagList &&
            tagList
              .filter((tag) => !tags?.find(t => t === tag.id))
              .filter((tag) => tag.name.toLowerCase().includes(value.toLowerCase()))
              .map((item, index) => (
                <li key={index} onClick={() => handleValue(item)}>
                  {item && item.name}
                </li>
              ))}
        </ul>
      )}
      {tags?.length ? (
        <div className='mb-3'>
          {tags.map((tag, i) => {
            const tag_ = tagList?.find((tl => tl.id === tag));
            return <span key={i} className={'chips'}>
              {tag_?.name ?? "Unknown"}
              <SimpleCloseIcon width={12} height={12} onClick={handleDelete(tag)} className="ms-2" />
            </span>
          })}
        </div>
      ) : ""}
    </div>
  );
};

export default AutoComplete;
