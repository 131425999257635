import { getUserFullName } from 'components/MyDisk/common/utils';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal/Modal';
import PostContext from 'context/PostContext';
import { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { blockConnection } from 'store/slices/connectionSlice';

const BlockUserModal = ({ onClose, postId, author }) => {
  const { postData, updatePost, removePost } = useContext(PostContext);
  const [loading, setLoading] = useState()
  const dispatch = useDispatch();
  const handleConfirm = () => {
    setLoading(true)
    dispatch(blockConnection({ block_user_id: postData?.author?.id, })).then(() => {
      setLoading(false)
      onClose();
    }).catch(() => {
      setLoading(false)
    });
  }

  const name = getUserFullName(postData?.author)

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<h3>Block user {getUserFullName(author)}?</h3>}
      action={
        <>
          {!loading ? <Button className={`btn btn-secondary`} onClick={onClose}>
            Cancel
          </Button> : ""}
          <Button disabled={loading} className="btn btn-primary" onClick={handleConfirm}>
            {loading ? <Spinner /> : "Block Now"}
          </Button>
        </>
      }
    >
      {/* <p>
        Are you sure you want to block and report user {getUserFullName(author)}?
      </p> */}
      <p className="mb-3">
        <strong>
          <span className="text-capitalize">{name}</span> will no longer be able to:
        </strong>
      </p>

      <ul className="mb-4">
        <li>See your posts on your timeline</li>
        <li>Tag you</li>
        <li>Invite you to events or groups</li>
        <li>Message you</li>
        <li>Add you as a friend</li>
      </ul>

      <p>
        If you're friends, blocking <strong className="text-capitalize">{name}</strong> will also unfriend them.
      </p>

      <p>
        If you just want to limit what you share with <strong className="text-capitalize">{name}</strong> or see
        less of them on myBridge, you can take a break from them instead.
      </p>
    </Modal>
  )
};

export default BlockUserModal