import { ImageIcon } from 'components/common/Icons';
import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Conatiner = styled.button`
  border: none;
  position: relative;
  background: transparent;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const FilePicker = ({ onSelect, imageDisable, videoDisable, ...props }) => {
  const fileRef = useRef();

  const onClick = () => {
    if (videoDisable && imageDisable) return;
    fileRef.current.click();
  };

  const onPickFile = (event) => {
    onSelect(event);
  };

  return (
    <Conatiner disabled={imageDisable && videoDisable} onClick={onClick}>
      <input
        ref={fileRef}
        accept="accept=file_extension|audio/*|video/*|image/*|media_type"
        onChange={onPickFile}
        type="file"
        hidden
        multiple
        {...props}
      />
      <ImageIcon color="#5B5B5B" />
    </Conatiner>
  );
};

FilePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  imageDisable: PropTypes.bool,
  videoDisable: PropTypes.bool,
};

FilePicker.defaultProps = {
  onSelect: () => { },
  imageDisable: false,
  videoDisable: false,
};

export default FilePicker;
