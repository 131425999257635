import styled from 'styled-components';

const Devider = styled.div`
  width: 100%;
  height: 1px;
  background: #e4e6eb;
  margin: ${(props) => props.margin || '10px 0px'};
`;

export default Devider;
