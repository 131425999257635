import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

const initialState = {
  loading: false,
  flag: false,
  allCollectedFilters: [],
};

export const updateFilters = createAction('updateFilters');

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [updateFilters]: (state, action) => {
      // state.allCollectedFilters = {
      //   ...state,
      //   ...action.payload,
      // };
      state.allCollectedFilters.push(action.payload)
      console.log("Filters updated with", action.payload)  // for testing purpose only.
    },
  },
});

export default filterSlice.reducer;
