import React from 'react';
import Feed from 'pages/Dashboard/Feed';

const Posts = (props) => {
  const { companyDetails } = props;
  return (
    <Feed type='company' companyDetails={companyDetails} />
  )
}

export default Posts