import ellipse from 'assets/icons/ellipse.svg';
import noProfileBackground from 'assets/settings/no-posts-bg.svg';
import { BorderLessButton, Text } from 'components/StyledComponents';
import Button from 'components/common/Button';
import { CircleFilled, MyBridgeIcon } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCompanyPages } from 'store/actions/companyPages.Action';
import { MyBridgeAvatar } from '../../../components/common/Avatar';
import DeactivatePageModal from './modals/DeactivatePageModal';
import ManagePageModal from './modals/ManagePageModal';

export default function Pages() {
  const { companyPageList } = useSelector((state) => state.companyPageReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeMenu, setActive] = useState(null);
  const [modalType, setModalType] = useState('');
  const [company, setCompany] = useState({});

  useEffect(() => {
    dispatch(getAllCompanyPages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onActiveMenu = (page, index) => {
    console.log(page, index);
    setActive(index);
    setCompany(page);
  };

  const onClose = (event, type) => {
    setActive(null);
    setModalType('');
    setCompany({});
    if (type === 'triggerDeactivate') {
      setModalType('deactivation');
    }
  };

  const deletePage = (event, type) => {
    setActive(null);
    setModalType('deactivation');
  };

  const ref = React.useRef();
  useOnClickOutside(ref, () => onClose());

  return (
    <div className="user-settings--pages">
      <div className="col-12 pb-2 d-flex align-items-center justify-content-between usp-header">
        <Text fS="20px" color="#333333">
          Pages
        </Text>
        <BorderLessButton
          onClick={() => navigate('/create/company')}
          className="d-flex align-items-center borderless-btn"
        >
          <Text color="#5B5B5B">Create a new Page</Text>
          <CircleFilled className="ms-3" />
        </BorderLessButton>
      </div>

      <div>
        {companyPageList && companyPageList.length ? (
          companyPageList?.map((page, index) => (
            <div className="card pages-card" key={index} onClick={() => navigate(`/company/page/${page.id}/home`)}>
              <div className="page-list-wrapper">
                <div className="logo" onClick={() => navigate(`/company/page/${page.id}/home`)}>
                  <MyBridgeAvatar icon={page.image} size={60} name={page.name} />
                  <img src={page.image} className="company-icon" alt="Avatar" />
                </div>
                <div className="page-details" onClick={() => navigate(`/company/page/${page.id}/home`)}>
                  <Text fS="16px" fW="600" color="#5B5B5B">
                    {page.name}
                  </Text>
                  <Text color="#868585" className="mt-2">
                    {page.main_industry} | {page.state_region}, {page.country}
                  </Text>

                  {page.follower_count > 0 ? (
                    <div className="d-flex gap-1 align-items-center mt-1">
                      <MyBridgeIcon />
                      <Text color="#868585">{page.follower_count}</Text>
                    </div>
                  ) : null}
                </div>
                <Button
                  // width="120px"
                  // padding="0.5rem 1rem"
                  onClick={(e) => {
                    e?.stopPropagation?.();
                    navigate(`/company/page/${page.id}/home`);
                  }}
                >
                  View Page
                </Button>
                <div className="position-relative">
                  <img
                    src={ellipse}
                    alt=""
                    onClick={(e) => {
                      e.stopPropagation();
                      onActiveMenu(page, index);
                    }}
                    className="cursor-pointer mb-1"
                  />

                  {activeMenu === index && (
                    <div className="more-menu p-0" ref={ref}>
                      {/* <div className="link p-2" onClick={() => setModalType('manage')}>
                        Manage Page
                      </div> */}
                      <div className="link p-2" onClick={(e) => { e.stopPropagation(); navigate(`/settings/team`) }}>
                        Manage Page
                      </div>
                      <div className="link p-2" onClick={(e) => { e.stopPropagation(); deletePage() }}>
                        Deactivate Page
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-posts-image-container">
            <div
              style={{
                backgroundImage: `url(${noProfileBackground})`,
              }}
              className="no-posts-image"
            />
            <p className="d-block mb-1">When you create or are an admin on a page,</p>
            <p className="d-block mb-3"> it will appear here. Create page</p>
            <BorderLessButton
              onClick={() => navigate('/create/company')}
              className="d-flex align-items-center justify-content-center borderless-btn"
            >
              <Text color="#5B5B5B">Create a new Page</Text>
            </BorderLessButton>
          </div>
        )}
      </div>

      {modalType === 'deactivation' && (
        <DeactivatePageModal company={company} onClose={onClose} setModalType={setModalType} />
      )}
      {modalType === 'manage' && <ManagePageModal company={company} onClose={onClose} />}
    </div>
  );
}
