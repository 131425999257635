import React, { useContext } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import PostFormContext from 'context/PostFromContext';

import { Flex } from 'components/core';

import PostCharacter from './PostCharacter';

const PostInput = ({ style, ...props }) => {
  const { postText, setPostText, inputRef } = useContext(PostFormContext);
  const whiteSpace = postText?.match(/\s/g);
  const wordpCount = postText?.length - (whiteSpace?.length || 0);

  return (
    <Flex align="center" position="relative">
      <TextareaAutosize
        onChange={(e) => {
          setPostText(e.target.value);
          // if (wordpCount.length > 280) {
          //   inputRef.current.style.background = '#f8d7da';
          // } else {
          //   inputRef.current.style.background = 'inherit';
          // }
        }}
        ref={inputRef}
        value={postText}
        className={'focused'}
        placeholder="What's on your mind"
        minRows={1}
        maxRows={5}
        style={{ ...styles.input, backgroundColor: wordpCount > 300 ? '#f8d7da' : 'inherit', ...(style ?? {}) }}
        {...props}
      />
      <PostCharacter />
    </Flex>
  );
};

export default PostInput;

const styles = {
  input: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '120%',
    resize: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 10px',
    background: '#ffffff',
    border: '1px solid #e4e6eb',
    borderRadius: '8px',
    flex: 10,
    overflowY: 'hidden',
    paddingBottom: 40,
  },
};
