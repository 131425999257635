import { APIEndPoints } from './../common/endPoint';
import axiosInstance from 'store/common/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/common/api';

export const setOpenFolderItem = createAction('Open_Folder');
export const setSelectedFile = createAction('selectedFile');
export const setSelectedUser = createAction('setSelectedUser')
export const addFilesToUpload = createAction('addFilesToUpload')
export const removeFilesToUpload = createAction('removeFilesToUpload')
export const setUploadSuccess = createAction('setUploadSuccess')
export const setFilesToUpload = createAction('setFilesToUpload')
export const updateFileToUpload = createAction('updateFileToUpload')
export const showFilesUploadModal = createAction('showFilesUploadModal')
export const hideFilesUploadModal = createAction('hideFilesUploadModal')
export const cleanDownloadedFile = createAction('cleanDownloadFile')
export const startFileDownload = createAction('startFileDownload')
export const finishFileDownload = createAction('finishFileDownload')
export const setDeleteFolderSeccess = createAction('setDeleteFolderSeccess')
export const setDeleteSeccess = createAction('setDeleteSeccess')
export const setTeamDiskSubscriberChanged = createAction('setTeamDiskSubscriberChanged')

export const getRootDir = createAsyncThunk('GET_ROOT_DIR', async (opts) => {
  const { dirName } = opts ?? {}
  const obj = {
    url: `${APIEndPoints.MyDisk_Root}`,
    method: 'GET',
  };
  const response = await API(obj);
  return response;
});

export const initRootDirs = createAsyncThunk('INIT_ROOT_DIR', async () => {
  const obj = {
    url: `${APIEndPoints.MyDisk_Root}?dir_name=root`,
    method: 'GET',
  };
  const response = await API(obj);
  return response;
});

export const getTeamDiskDetails = createAsyncThunk('GET_TEAM_DISK_DETAILS', async (id) => {
  const obj = {
    url: `${APIEndPoints.TeamDisk}?for_team_community_id=${id}`,
    method: 'GET',
  };
  const response = await API(obj);
  return response;
});


export const getfileNFolder = createAsyncThunk('GET_FILE_FOLDERS', async (payload) => {
  try {
    if (payload.parent) {
      const obj = {
        url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    }
  } catch (error) {
    return error;
  }
});


export const getTeamFileNFolder = createAsyncThunk('getTeamFileNFolder', async (payload) => {
  try {
    if (payload.parent) {
      const obj = {
        url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    }
  } catch (error) {
    return error;
  }
});


export const getSubfileNFolder = createAsyncThunk('GET_FILE_FOLDERS_SUB', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getfileNFolderCopyMove = createAsyncThunk('COPY_MOVE_FOLDER-LIST', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getDeleteFiles = createAsyncThunk('getDeleteFiles', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk}?is_deleted=${payload.is_deleted}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});
// File Actions
export const uploadFile = createAsyncThunk('UploadFile', async (payload) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.post(`${APIEndPoints.MyDisk_Files}`, payload, { headers });
    return response;
  } catch (error) {
    return error;
  }
});
export const uploadFileByIndex = createAsyncThunk('UploadFileByIndex', async ({ payload, index }) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.post(`${APIEndPoints.MyDisk_Files}`, payload, { headers });
    return { response, index };
  } catch (error) {
    return { error, index };
  }
});
export const deleteFile = createAsyncThunk('deleteFile', async (id) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${id}/`,
      method: 'DELETE',
    };
    const response = await API(obj);
    return { id, response };
  } catch (error) {
    return error;
  }
});
export const renameFiles = createAsyncThunk('renameFile', async (payload) => {
  try {
    const { id, name } = payload;
    let formdata = new FormData();
    formdata.append('name', name);
    // const finalData = {'name': name};

    // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Files}${id}/`, JSON.stringify(finalData), headers);
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${payload.id}/`,
      method: 'PUT',
      body: formdata,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});
export const restoreFile = createAsyncThunk('restoreFile', async (id) => {
  try {
    let payload = new FormData();
    payload.append('is_deleted', false);


    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${id}/`,
      method: 'PUT',
      body: payload,
      isFormData: true
    };
    const response = await API(obj);

    return response;
  } catch (error) {
    return error;
  }
});
export const moveFiles = createAsyncThunk('moveFile', async (payload) => {
  try {
    const { id, parent } = payload;
    let formData = new FormData();
    formData.append('id', id);
    formData.append('parent', parent);
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${payload.id}/`,
      method: 'PUT',
      body: formData,
      isFormData: true
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    console.log(error)
    return error;
  }
});
export const copyFiles = createAsyncThunk('copyFile', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${payload.id}/?act=copy&parent=${payload.parent}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

// Folder Actions
export const createFolder = createAsyncThunk('createFolder', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk}`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteFolder = createAsyncThunk('deleteFolder', async (id) => {
  const obj = {
    url: `${APIEndPoints.MyDisk_Folder}${id}/`,
    method: 'DELETE',
  };
  const response = await API(obj);
  return { id, response };
});
export const renameFolder = createAsyncThunk('renameFolder', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Folder}${payload.id}/`,
      method: 'PUT',
      body: JSON.stringify(payload)
    };
    // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
    const response = await API(obj);

    return response;
  } catch (error) {
    return error;
  }
});
export const restoreFolder = createAsyncThunk('restoreFolder', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Folder}${payload.id}/`,
      method: 'PUT',
      body: JSON.stringify(payload),

    };
    const response = await API(obj);

    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   Authorization: `Bearer ${token}`,
    // };
    // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
    return response;
  } catch (error) {
    return error;
  }
});
export const moveFolder = createAsyncThunk('moveFolder', async (payload) => {
  try {

    const obj = {
      url: `${APIEndPoints.MyDisk_Folder}${payload.id}/`,
      method: 'PUT',
      body: JSON.stringify(payload),

    };
    const response = await API(obj);

    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   Authorization: `Bearer ${token}`,
    // };
    // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
    return response;
  } catch (error) {
    return error;
  }
});

export const searchFileFolder = createAsyncThunk('searchFileFolder', async (payload) => {
  try {
    const { filters, str } = payload;

    const obj = {
      url: `${APIEndPoints.MyDisk_Search}?${str}`,
      method: 'GET',
    };
    const response = await API(obj);

    return response;
  } catch (error) {
    console.error(error)
    return error;
  }
});

export const downloadFile = createAsyncThunk('downloadFile', async (id, { dispatch }) => {
  try {
    dispatch(startFileDownload())
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${id}/?act=download`,
      method: 'GET',
      isResponseJSON: false
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    dispatch(finishFileDownload())
    return error;
  }
});
// Share Disk
// Share Item
export const sharedDisk = createAsyncThunk('sharedDisk', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${payload.id}/share`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});


export const removeFileShare = createAsyncThunk('removeFileShare', async (payload) => {
  const { id, shared_with_id } = payload ?? {}
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${id}/share`,
      method: 'DELETE',
      body: JSON.stringify({ shared_with_id })
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const removeShare = createAsyncThunk('removeShare', async (id) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}sharee/${id}`,
      method: 'DELETE',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSharedDiskUsers = createAsyncThunk('getSharedDiskUsers', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}users`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSharedDiskUsersByFile = createAsyncThunk('getSharedDiskUsersByFile', async (payload) => {
  const { id } = payload ?? {}
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${id}/share`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});


export const getSharedItemUsers = createAsyncThunk('getSharedItemUsers', async (id) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}sharee/${id}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});


export const createShareLink = createAsyncThunk('createShareLink', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${payload.id}/link`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteShareLink = createAsyncThunk('deleteShareLink', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${payload.id}/link`,
      method: 'DELETE',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getShareLink = createAsyncThunk('getShareLink', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${payload.id}/link`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getDiskSubscriptionPlan = createAsyncThunk('getDiskSubscriptionPlan', async () => {
  try {
    const obj = {
      url: `${APIEndPoints.diskSubscription}/plan/`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getUserSubscriptionDetails = createAsyncThunk('getUserSubscriptionDetails', async () => {
  try {
    const obj = {
      url: `${APIEndPoints.diskSubscription}/`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});
export const getUserSubscriptionDetailsbyUser = createAsyncThunk('getUserSubscriptionDetailsbyUser', async (user) => {
  try {
    const obj = {
      url: `${APIEndPoints.diskSubscription}/?user=${user}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getOccupiedDiskSize = createAsyncThunk('getOccupiedDiskSize', async () => {
  try {
    const obj = {
      url: `${APIEndPoints.diskSize}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});
export const changeTeamDiskSubscriber = createAsyncThunk('changeTeamDiskSubscriber', async (payload) => {
  const { teamId, organizerId } = payload ?? {}
  const obj = {
    url: `${APIEndPoints.TeamCommunityDisk}change/request/?team_id=${teamId}&organizer_id=${organizerId}`,
    method: 'GET',
  };
  const response = await API(obj);
  return response;
});

export const clearFile = createAction('CLEAR');
