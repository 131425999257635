import React from 'react';
import { Text } from 'components/StyledComponents';
import backarrow from 'assets/new/backarrow.svg';
import { useNavigate } from 'react-router-dom';

const IntroSection = ({ setSection }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="">
        <div className="create-company-intro-section">
          <Text fS="32px" color="#3D5A80">
            Create a Page
          </Text>
          <Text fS="16px" color="#3D5A80">
            Create a Page for your company, nonprofit, small business
          </Text>

          <button className="btn btn-primary px-5" onClick={() => setSection('company')}>
            <Text fW="600" fS="16px" color="#fff">
              Get Started
            </Text>
          </button>
          <button className="back d-flex gap-3" onClick={() => navigate(-1)}>
            <Text fS="20px" color="#3D5A80">
              Back
            </Text>
          </button>
        </div>
      </div>
    </>
  );
};

export default IntroSection;
