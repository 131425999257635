import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, VerticalLine } from 'components/StyledComponents';

const ManageSubscriptions = (props) => {
  const navigate = useNavigate();
  const { companyDetails, setOpenTab } = props;
  const handlenavigation = (url, sectionId) => {
    navigate(url);
    if (sectionId) {
      setOpenTab(sectionId);
    }
  };
  return (
    <div className="bordered-container py-3 px-3 mt-4">
      <Text fS="18px" fW="600" color="#5B5B5B">
        Manage
      </Text>
      <div className="row mt-3">
        <button className="btn edit_page" onClick={() => handlenavigation('/create/company/job')}>
          Post a free job
        </button>
      </div>
      <div className="row mt-3">
        <div className="access team_color">
          <Text fS="16px" fW="500" color="#3D5A80" className="pb-3" onClick={() => handlenavigation('/settings/team')}>
            Team & Access
          </Text>
          <Text fS="16px" fW="500" color="#3D5A80" className="pb-3">
            mySubscriptions
          </Text>
          <VerticalLine />
          <Text fS="16px" fW="500" color="#5B5B5B" className="pt-3">
            Billing Information
          </Text>
          <Text
            fS="16px"
            fW="500"
            color="#3D5A80"
            className="pt-3"
            onClick={() => handlenavigation('/settings/payment')}
          >
            Payment method
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscriptions;
