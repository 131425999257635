import { getUserFullName } from 'components/MyDisk/common/utils';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal/Modal';
import PostContext from 'context/PostContext';
import { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { reportConnection } from 'store/slices/connectionSlice';

const UnfriendModal = ({ onClose, postId, author }) => {
  const { postData, updatePost, removePost } = useContext(PostContext);
  const [loading, setLoading] = useState()
  const dispatch = useDispatch();
  const handleConfirm = () => {
    setLoading(true)
    dispatch(reportConnection({ block_user_id: author?.id, report_message: "others" })).then(() => {
      setLoading(false)
      onClose();
    }).catch(() => {
      setLoading(false)
    });
  }

  return (
    <Modal
      onClose={onClose}
      className="confirmation-modal"
      heading={<h3>Unfriend {getUserFullName(author)}?</h3>}
      action={
        <>
          {!loading ? <Button className={`btn btn-secondary`} onClick={onClose}>
            Cancel
          </Button> : ""}
          <Button disabled={loading} className="btn btn-primary" onClick={handleConfirm}>
            {loading ? <Spinner /> : "Unfriend Now"}
          </Button>
        </>
      }
    >
      <p>
        Are you sure you want to unfriend {getUserFullName(author)}?
      </p>
    </Modal>
  )
};

export default UnfriendModal