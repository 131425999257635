import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { CommentAPIEndpoints } from 'store/common/endPoint';

const initialState = {
  commentFlag: false,
  data: [],
};

export const postComment = createAsyncThunk('postComment', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.CreateComment(body.id),
    method: 'POST',
    body: body.formData,
    isFormData: true,
  };
  return await API(obj);
});

export const postCommentReply = createAsyncThunk('postCommentReply', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.Comments(body.id),
    method: 'POST',
    body: JSON.stringify(body.requestBody),
  };
  return await API(obj);
});

export const fetchComments = createAsyncThunk('fetchComments', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.Comments(body.id),
  };
  return await API(obj);
});

export const deleteComment = createAsyncThunk('deleteComment', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.CommentDelete(body.id),
    method: 'DELETE',
  };
  return await API(obj);
});

export const editComment = createAsyncThunk('editComment', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.EditComment(body.id),
    method: 'PUT',
    body: body.formData,
    isFormData: true,
  };
  return await API(obj);
});

export const likeComment = createAsyncThunk('likeComment', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.CommentLike(body.id),
    method: 'PUT',
    body: JSON.stringify(body.requestBody),
  };
  return await API(obj);
});

export const disLikeComment = createAsyncThunk('disLikeComment', async (body) => {
  const obj = {
    url: CommentAPIEndpoints.CommentDisLike(body.id),
    method: 'DELETE',
    body: JSON.stringify(body.requestBody),
  };
  return await API(obj);
});

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {},
  extraReducers: {
    [postComment.pending]: (state, action) => {
      state.commentFlag = true;
    },
    [postComment.fulfilled]: (state, action) => {
      state.commentFlag = false;
    },
    [postComment.rejected]: (state, action) => {
      state.commentFlag = false;
      state.error = 'Try again later or contact customer support';
    },

    [postCommentReply.pending]: (state, action) => {
      state.commentFlag = true;
    },
    [postCommentReply.fulfilled]: (state, action) => {
      state.commentFlag = false;
    },
    [postCommentReply.rejected]: (state, action) => {
      state.commentFlag = false;
      state.error = 'Try again later or contact customer support';
    },

    [likeComment.pending]: (state, action) => {
      state.commentFlag = true;
    },
    [likeComment.fulfilled]: (state, action) => {
      state.commentFlag = false;
    },
    [likeComment.rejected]: (state, action) => {
      state.commentFlag = false;
      state.error = 'Try again later or contact customer support';
    },

    [disLikeComment.pending]: (state, action) => {
      state.commentFlag = true;
    },
    [disLikeComment.fulfilled]: (state, action) => {
      state.commentFlag = false;
    },
    [disLikeComment.rejected]: (state, action) => {
      state.commentFlag = false;
      state.error = 'Try again later or contact customer support';
    },

    [fetchComments.pending]: (state, action) => {},
    [fetchComments.fulfilled]: (state, action) => {
      console.log({ ...state.data, ...action.payload });
      state.data = action.payload;
    },
    [fetchComments.rejected]: (state, action) => {
      state.error = 'Try again later or contact customer support';
    },
  },
});

export default commentSlice.reducer;
