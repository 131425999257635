import { createRef, useEffect, useState } from 'react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEventDetail, getGoogleMeetingDetails } from 'store/slices/meetingSlice';
import moment from 'moment';
import { SimpleCloseIcon } from 'components/common/Icons';
import _ from 'lodash';

const CalendarComp = () => {
  const calendarRef = createRef();
  const navigate = useNavigate();
  const [calendarEvents, setCalendarEvents] = useState();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [dateFromDashboard, setDateFromDashboard] = useState(
    localStorage.getItem('dateSelectedFromDashboard') || new Date(),
  );

  const { allCalendarMeeting, allGoogleMeetings, allOutlookMeetings } = useSelector((state) => state.calendar);

  useEffect(() => {
    let events = [];
    let myBridgeEventsIds = [];
    if (allCalendarMeeting.results) {
      for (let meeting of allCalendarMeeting.results) {
        const meetingStatus = meeting?.attendees.find((item) => item.status === 'ACCEPTED');
        const declinedAttendee = meeting.attendees.find(
          (m) => m.user === userProfileInfo?.id && m.status === 'DECLINED',
        );
        if (declinedAttendee?.status !== 'DECLINED') {
          myBridgeEventsIds.push(meeting?.id?.toString());
          events.push({
            title: meeting.subject,
            start: meeting.start_time,
            end: meeting.end_time,
            ...meeting,
            allDay: meeting.all_day_meeting,
            backgroundColor:
              meeting?.created_by?.id === userProfileInfo?.id ? (meetingStatus ? '#037f29' : '') : '#FFF',
            textColor: meeting?.created_by?.id === userProfileInfo?.id ? '#FFF' : '#3788d8',
          });
        }
      }
    }

    if (allGoogleMeetings?.items) {
      for (let meeting of allGoogleMeetings?.items) {
        if (!myBridgeEventsIds.includes(meeting?.id?.slice(6))) {
          events.push({
            host: meeting?.creator?.email,
            timezone: meeting?.start?.timeZone,
            description: meeting?.description,
            attendees: meeting?.attendees,
            location: meeting?.location,
            title: meeting?.summary,
            start: meeting?.start?.dateTime,
            end: meeting?.end?.dateTime,
            allDay: meeting?.all_day_meeting,
            backgroundColor: meeting?.created_by?.id === userProfileInfo?.id ? '#037f29' : '#FFF',
            textColor: meeting?.created_by?.id === userProfileInfo?.id ? '#FFF' : '#3788d8',
            url: meeting?.htmlLink,
          });
        }
      }
    }
    if (allOutlookMeetings?.value) {
      for (let meeting of allOutlookMeetings?.value) {
        if (!myBridgeEventsIds.includes(meeting?.id?.slice(6))) {
          events.push({
            title: meeting?.subject,
            description: meeting?.bodyPreview,
            location: meeting?.location?.displayName,
            timezone: meeting?.start?.timeZone,
            attendees: meeting?.attendees?.map((attendee) => attendee?.emailAddress?.address),
            start: meeting?.start?.dateTime,
            end: meeting?.end?.dateTime,
            backgroundColor: meeting?.created_by?.id === userProfileInfo?.id ? '#037f29' : '#FFF',
            textColor: meeting?.created_by?.id === userProfileInfo?.id ? '#FFF' : '#3788d8',
            allDay: meeting?.isAllDay,
            host: meeting?.organizer?.emailAddress?.address,
            url: meeting?.onlineMeeting?.joinUrl,
          });
        }
      }
    }
    setCalendarEvents([...events]);
  }, [allCalendarMeeting, allGoogleMeetings]);

  useEffect(() => {
    setDateFromDashboard(localStorage.getItem('dateSelectedFromDashboard'));
    setTimeout(() => {
      localStorage.removeItem('dateSelectedFromDashboard');
    }, 3000);
  }, []);

  const getView = () => {
    return localStorage.getItem('dateSelectedFromDashboard') ? 'timeGridDay' : 'dayGridMonth';
  };

  const onClickChangeView = (name) => {
    const calendar = calendarRef.current;
    if (calendar) {
      const calendarApi = calendar.getApi();
      calendarApi.changeView(name);
    }
  };

  const handleDateClick = (arg) => {
    // console.log(arg)
    let todayDate = moment(new Date()).format('YYYY-MM-DD');

    if (arg.startStr >= todayDate) {
      localStorage.setItem('startStrFromCalendar', arg.start);
      localStorage.setItem('endStrFromCalendar', arg.start);
      navigate('/profile/schedule');
    } else {
      setErrorMsg('Previous Date Not Allow');
      setTimeout(() => {
        setErrorMsg(undefined);
      }, 3000);
    }
  };

  // const handleMouseEnter = () => {
  //   Tippy(arg.el, {
  //       content: "my tooltip!"
  //   });
  //   alert("Dasd")
  // };

  const handleEventClick = (info) => {
    info.jsEvent.preventDefault();
    if (info?.event?.url) {
      dispatch(getGoogleMeetingDetails(info?.event));
      navigate('/profile/viewGoogleMeeting');
      return;
    }
    const id =
      info.event._def.publicId.slice(0, 6) === 'bridge' ? info.event._def.publicId.slice(6) : info.event._def.publicId;
    dispatch(getEventDetail(id)).then((res) => {
      navigate('/profile/viewMeeting');
    });
  };

  //   const renderEventContent=(eventInfo)=> {
  //   return (
  //     <>
  //       <b>{eventInfo.timeText}</b>
  //       <i>{eventInfo.event.title}</i>
  //     </>
  //   )
  // }

  return (
    <>
      <FullCalendar
        themeSystem
        events={calendarEvents}
        // eventColor='#378006'
        eventDisplay="block"
        ref={calendarRef}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        editable={true}
        initialView={getView()}
        weekends={true}
        selectable={true}
        eventLimit={true}
        selectMirror={true}
        initialDate={dateFromDashboard}
        timeFormat='H:mm'
        eventTimeFormat = {{ // like '14:30 PM'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: true
        }}
        locale="en"
        // eventContent={renderEventContent}
        start={calendarRef.start}
        dayMaxEventRows={true}
        select={(e) => {
          handleDateClick(e);
        }}
        eventClick={(e) => handleEventClick(e)}
        customButtons={{
          myTimeDayBtn: {
            text: 'Day',
            click() {
              onClickChangeView('timeGridDay');
            },
          },
          myTimeWeekBtn: {
            text: 'Week',
            click() {
              onClickChangeView('timeGridWeek');
            },
          },
          myTimeMonthBtn: {
            text: 'Month',
            click() {
              onClickChangeView('dayGridMonth');
            },
          },
          prevBtn: {
            icon: 'chevron-left',
            click() {
              const calendar = calendarRef.current;
              if (calendar) {
                const calendarApi = calendar.getApi();
                calendarApi.prev();
              }
            },
          },
          nextBtn: {
            icon: 'chevron-right',
            click() {
              const calendar = calendarRef.current;
              if (calendar) {
                const calendarApi = calendar.getApi();
                calendarApi.next();
              }
            },
          },
        }}
        buttonText={{ today: 'Today' }}
        headerToolbar={{
          left: 'prevBtn,nextBtn',
          center: 'title',
          right: 'today,timeGridDay,timeGridWeek,dayGridMonth',
        }}
        dateClick={handleDateClick}
      />
      {errorMsg && (
        <div className="error-msg">
          {errorMsg}
          <SimpleCloseIcon color={'#FFF'} width={15} height={15} onClick={() => setErrorMsg(undefined)} />
        </div>
      )}
    </>
  );
};

export default CalendarComp;
