import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authSlice from './slices/authSlice';
import userProfileSlice from './slices/userProfileSlice';
import connectionSlice from './slices/connectionSlice';
import {
  layoutReducer,
  myDiskReducer,
  companyPageReducer,
  TestimonialReducer,
  companyJobsReducer,
  jobReducer,
  teamsReducer,
} from './reducers';
import postSlice from './slices/postSlice';
import blogSlice from './slices/blogSlice';
import searchSlice from './slices/searchSlice';
import calendarSlice from './slices/calendarSlice';
import commentSlice from './slices/commentSlice';
import settingsSlice from './slices/settingsSlice';
import meetingSlice from './slices/meetingSlice';
import teamSlice from './slices/teamSlice';
import followSlice from './slices/followSlice';
import paymentSlice from './slices/paymentSlice';
import onboardingSlice from './slices/onboardingSlice';
import teamCommunitySlice from './slices/teamCommunitySlice';
import globalDataSlice from './slices/globalDataSlice';
import filterSlice from './slices/filterSlice';
import notificationSLice from './slices/notificationSLice';

export const store = configureStore({
  reducer: {
    user: authSlice,
    userProfile: userProfileSlice,
    layoutReducer,
    connection: connectionSlice,
    post: postSlice,
    calendar: calendarSlice,
    myDiskReducer,
    comment: commentSlice,
    settings: settingsSlice,
    meeting: meetingSlice,
    team: teamSlice,
    payment: paymentSlice,
    companyPageReducer,
    follow: followSlice,
    TestimonialReducer,
    onboarding: onboardingSlice,
    companyJobsReducer,
    filterSlice,
    jobReducer,
    search: searchSlice,
    teamsReducer,
    teamsCommunity: teamCommunitySlice,
    blog: blogSlice,
    globalData: globalDataSlice,
    notification:notificationSLice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});
