import React, { useContext, useRef, useState } from 'react';
import { EditJob, SharedConnectionIcon } from 'components/common/Icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import QuoteModal from './QuoteModal';
import { Flex, Text } from 'components/core';
import PostContext from 'context/PostContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { rePost } from 'api/post';
import { Spinner } from 'components/common/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { copyTextToClipboard } from 'components/utils';

const QuoteDropdown = () => {
  const { postData, updatePost } = useContext(PostContext);
  const { author } = postData;
  const repostsBy = postData.reposts_by;
  const postId = postData.id;
  const queryClient = useQueryClient();

  const [copied, setCopied] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const ref = useRef();

  const currUserRepost = repostsBy && repostsBy?.filter((el) => el.id === author.id);

  useOnClickOutside(ref, () => setShowMenu(false));

  const handleShow = () => {
    setShowMenu((prev) => !prev);
  };

  const { mutate: repost, isLoading: rePostLoading } = useMutation(rePost, {
    onSuccess: (data) => {
      updatePost();
      queryClient.invalidateQueries('feed');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleRepost = () => {
    repost({ id: postId, body: { visibility: 0, reply_control: 0 } });
  };

  const handleRepostWithQuote = () => {
    setShowMenu(false);
    setShowModal(true);
  };
  let modalLink = ""
  if (typeof window !== undefined) {
    let index = window.location.href.indexOf("?") > -1 ? window.location.href.indexOf("?") : 0
    modalLink = `${window.location.href?.substring(0, index || window.location.href.length)}?postId=${postData.id}`;
  }

  return (
    <>
      <div className="navbar-dropdown" ref={ref} onClick={handleShow}>
        <Flex align="center">
          <SharedConnectionIcon className="me-2" width="18" height="26" />
          <Text.Label className="cursor-pointer" size="15px">Share</Text.Label>
        </Flex>
        <DropdownMenu
          items={[
            {
              icon: <SharedConnectionIcon width={15} height={16} />,
              label: currUserRepost?.length > 0 ? 'Undo Repost' : 'Repost',
              onClick: handleRepost
            },
            {
              icon: <EditJob width={15} height={16} />,
              label: 'Quote post',
              onClick: handleRepostWithQuote
            },
            {
              icon: <img src="/icon/copy-link.svg" alt="copy-link" />,
              label: copied ? 'Coppied' : 'Copy link',
              onClick: async (e) => {
                e.stopPropagation()
                await copyTextToClipboard(modalLink);
                setCopied(true);
                setTimeout(() => { setCopied(false) }, 2000);
              }
            }
          ]}
          targetRef={ref}
        />

        {/* {showMenu && (
          <div className="navbar-dropdown--menu profile-dropdown">
            <div className="menu-body">
              <ul>
                <li>
                  {rePostLoading ? (
                    <Spinner />
                  ) : (
                    <button onClick={handleRepost}>
                      <SharedConnectionIcon width={15} height={16} className="me-2" />
                      {currUserRepost?.length > 0 ? 'Undo Repost' : 'Repost'}
                    </button>
                  )}
                </li>
                <li>
                  <button onClick={handleRepostWithQuote}>
                    <EditJob width={15} height={16} className="me-2" /> Quote Post
                  </button>
                </li>
                <li>
                  <CopyToClipboard text={modalLink} onCopy={() => setCopied(true)}>
                    <button>
                      <img src="/icon/copy-link.svg" alt="copy-link" /> &nbsp; {copied ? 'Coppied' : 'Copy link'}
                    </button>
                  </CopyToClipboard>
                </li>
              </ul>
            </div>
          </div>
        )} */}
      </div>
      {showModal && <QuoteModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default QuoteDropdown;
