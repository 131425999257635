import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import servicesPic from 'assets/profiles/profile/avtar_md.jpeg';

import { VerticalLine } from 'components/StyledComponents';
import { monthToNameMapping } from 'config/formConstants';

const EducationContainer = styled.div``;

const EducationDetailsContainer = styled.div`
  margin-bottom: 10px;
`;

const EducationDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const EducationDetailsType = styled.p``;

const EducationDetailsBody = styled.div`
  display: flex;
`;

const EducationDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const EducationDetailsImage = styled.img`
  height: 50px;
`;

const EducationDetailsName = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
`;

const EducationDetailsTimeline = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #000000;
`;

const EducationDetailsDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
`;

const EDUCATION_TYPES = {
  Degree: 'College',
  School: 'High School',
};

const Education = (props) => {
  const { publicProfileData } = useSelector((state) => state.userProfile);

  const renderEducationList = (educationType) => {
    const educationRecords = publicProfileData?.users_educations?.filter((edu) => edu.type === educationType);

    return educationRecords?.map((education) => {
      const { id, name, start_month, start_year, end_month, end_year, is_graduate, desc, type } = education;

      return (
        <EducationDetailsContainer key={`edu-${id}`}>
          <EducationDetailsHeader>
            <EducationDetailsType>{EDUCATION_TYPES[type]}</EducationDetailsType>
          </EducationDetailsHeader>

          <EducationDetailsBody>
            <EducationDetailsImage src={servicesPic} alt="" />

            <EducationDetailsContent>
              <EducationDetailsName>{name}</EducationDetailsName>

              <EducationDetailsTimeline>
                {`${monthToNameMapping[start_month || '01']} ${start_year} - ${
                  is_graduate ? `${monthToNameMapping[end_month || '01']} ${end_year}` : 'In Progress'
                }`}
              </EducationDetailsTimeline>

              <EducationDetailsDescription>{desc}</EducationDetailsDescription>
            </EducationDetailsContent>
          </EducationDetailsBody>
        </EducationDetailsContainer>
      );
    });
  };

  const hasCollegeEducation = publicProfileData?.users_educations?.results?.find(
    (edu) => edu.type === 'Degree' || edu.type === 'College',
  );

  return (
    <>
      <EducationContainer>
        {renderEducationList('Degree')}
        {hasCollegeEducation && <VerticalLine />}
        {renderEducationList('School')}
      </EducationContainer>
    </>
  );
};

export default Education;
