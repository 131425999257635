import React from 'react';
import Footer from 'components/Layouts/Footer';
import StaticNavbar from 'components/Layouts/StaticNavbar';
import '../../scss/pages/_aboutus.scss';
import { useNavigate } from 'react-router-dom';

const TermsCondition = () => {
  const navigate = useNavigate();

  return (
    <>
      <StaticNavbar />
      <div className="header-page">
        <div className="head">{`Terms and Conditions`}</div>
        <div className="route">
          <span
            onClick={() => {
              navigate('/');
            }}
          >
            Home{' '}
          </span>
          <span>{` / Terms and Conditions`}</span>
        </div>
      </div>
      <div className="aboutus-container">
        <div className="container">
          <div className="heading">Terms and Conditions</div>
          <div className="fs-20">Effective date: 16 March 2023</div>
          <div className="container-heading">Welcome to myBridge</div>
          <div className="container-text">
            Welcome and thank you for using myBridge! These terms of service ("Terms") cover your use and access to our
            services, client software, and websites ("Services"). Our Privacy Policy explains how we collect and use
            your information while our Terms of Service outlines your responsibilities when using our services. By using
            our services, you are agreeing to be bound by these Terms, and to review our Privacy Policy and Terms of
            Service policies. If you are using our services for an organization, group or forum, you are agreeing to
            these Terms on their behalf.
          </div>
          <div className="container-heading">Use</div>
          <div className="container-text">
            When you use our services, you provide us with things like your files, content, email messages, contacts,
            etc ("Your Information"). These terms do not give us any rights to your information except for the limited
            rights that enable us to offer the services.
          </div>
          <div className="container-heading">Our Services</div>
          <div className="container-text">
            Our services include, among others:
            <div className='mg-10'></div>
            <ul className="container-text">
              <li>
                Providing Online interactive chatting, message posting, mentoring, meeting organizing/scheduling,
                audio/video calling capabilities, networking, career support, content posting, electronic transmission
                of messages, storage of data, and images.
              </li>
              <li>
                Providing capabilities to participate in discussions, get feedback from peers, form virtual communities,
                share information and resources, and engage in social, business and community networking featuring
                social media, photos, audio and video content on general topics of social interest.
              </li>
              <li>Providing capabilities to facilitate, organize, and conduct meetings and interactive discussions.</li>
              <li>
                Providing capabilities to search, locate, and communicate information of general interest for purposes
                of social, business and community networking, marketing , surveys, tracking job opportunities,
                recruitment, employment, and other business topics.
              </li>
              <li>
                Providing customized web pages featuring user-defined information, personal profiles, and images, along
                with capabilities to post and display online videos and photos for sharing with others for entertainment
                purposes.
              </li>
            </ul>
          </div>
          <div className="container-heading">Your Responsibilities</div>
          <div className="container-text">
            You are responsible for your conduct and your information and you must comply with our Terms of Service.
            Content in the services may be protected by others' intellectual property rights. Please do not copy,
            upload, download or share content unless you have the right to do so.
            <div className="mg-10"></div>
            You also agree to the following:
            <div className="mg-10"></div>
            <ul>
              <li>
                Moderation - You agree we may moderate myBridge access and use at our sole discretion, e.g. by blocking
                (e.g. IP addresses), filtering, deletion, delay, omission, verification, and/or access/account/license
                termination. You also agree <br></br>
                a: not to bypass said moderation,
                <br></br>
                b: that we are not liable for moderating, not moderating, or representations as to moderating, and
                <br></br>
                c: nothing we say or do waives our right to moderate or not. All site rules are incorporated herein.
                <br></br>
              </li>
              <li>
                Sale - You authorize us to charge your account for associated/transaction fees. Unless noted, fees are
                in US dollars; tax is additional. To the extent permitted by law, fees are nonrefundable, even for posts
                we remove. We may refuse purchases, which may place a hold on your account.
              </li>
            </ul>
            <div className="mg-10">
              We may review your conduct and content for compliance with these terms and our Terms of Service. With that
              said, we have no obligation to do so. We are not responsible for the content people post and share via the
              services.
            </div>
            <div className="mg-10">
              Please safeguard your password to the services, make sure that others do not have access to it, and keep
              your account information current.
            </div>
            <div className="mg-10">You agree not to do the following in connection with our services:</div>
            <ul>
              <li> Promote or advertise products or services other than your own without appropriate authorization;</li>
              <li>
                {' '}
                Advocate bigotry or hatred against any person or group of people based on their race, religion,
                ethnicity, sex, gender identity, sexual preference, disability, or impairment;
              </li>
              <li>
                {' '}
                Publish or share materials that are unlawfully pornographic or indecent, or that contain extreme acts of
                violence;
              </li>
              <li>
                {' '}
                Send altered, deceptive or false source-identifying information, including "spoofing" or "phishing";
              </li>
              <li> Send unsolicited communications, promotions or advertisements, or spam;</li>
              <li> Probe, scan, or test the vulnerability of any system or network;</li>
              <li> Breach or otherwise circumvent any security or authentication measures;</li>
              <li>
                {' '}
                Access, tamper with, or use non-public areas or parts of our services, or shared areas of our services
                you haven't been invited to;
              </li>
              <li>
                {' '}
                Interfere with or disrupt any user, host, or network, for example by sending a virus, overloading,
                flooding, spamming, or mail-bombing any part of the services;
              </li>
              <li>
                {' '}
                Access, search, or create accounts for the services by any means other than our publicly supported
                interfaces (for example, "scraping" or creating accounts in bulk);
              </li>
              <li>
                {' '}
                Violate the law in any way, including storing, publishing or sharing material that's fraudulent,
                defamatory, or misleading; or violate the privacy or infringe the rights of others.
              </li>
            </ul>
          </div>
          <div className="container-heading">Software</div>
          <div className="container-text">
            Some of our services allow you to download client software ("Software") which may update automatically. So
            long as you comply with these terms, we give you a limited, nonexclusive, nontransferable, revocable license
            to use the software, solely to access the services. To the extent any component of the software may be
            offered under an open-source license, we will make that license available to you and the provisions of that
            license may expressly override some of these terms. Unless the following restrictions are prohibited by law,
            you agree not to reverse engineer or decompile the services, attempt to do so, or assist anyone in doing so.
          </div>
          <div className="container-heading">Our Information</div>
          <div className="container-text">
            The services are protected by copyright, trademark, and other US and foreign laws. These terms don't grant
            you any right, title or interest in the services, others' content in the services, myBridge trademarks,
            logos and other brand features. We welcome feedback, but note that we may use comments or suggestions
            without any obligation to you.
          </div>
          <div className="container-heading">Copyright</div>
          <div className="container-text">
            We respect the intellectual property of others and ask that you do too. We respond to notices of alleged
            copyright infringement if they comply with the law, and such notices should be reported through
            <span className='link'> terms@myBridge.me.</span> We reserve the right to delete or disable content alleged to be infringing and terminate
            accounts of repeat infringers.
          </div>
          <div className="container-heading">Paid Accounts</div>
          <div className="container-text">
            <ul>
              <li>
                Billing - You can increase your storage space and add paid features to your account (turning your
                account into a "Paid Account"). We will automatically bill you from the date you convert to a Paid
                Account and on each periodic renewal until cancellation. You are responsible for all applicable taxes,
                and we will charge tax when required to do so.
              </li>
              <li>
                No Refunds - You may cancel your myBridge Paid Account at any time but you will not be issued a refund
                unless it is legally required.
              </li>
              <li>
                Downgrades - Your Paid Account will remain in effect until it is canceled or terminated under these
                terms. If you do not pay for your Paid Account on time, we reserve the right to suspend it or reduce
                your storage to free space levels.
              </li>
              <li>
                Changes - We may change the fees in effect but will give you advance notice of these changes via a
                message to the email address associated with your account.
              </li>
            </ul>
          </div>
          <div className="container-heading">Termination</div>
          <div className="container-text">
            You are free to stop using our services at any time. We also reserve the right to suspend or end the
            services at any time at our discretion and without notice. For example, we may suspend or terminate your use
            of the services if you are not complying with these terms, or use the services in a manner that would cause
            us legal liability, disrupt the services or disrupt others' use of the services. Except for Paid Accounts,
            we reserve the right to terminate and delete your account if you have not accessed our services for 12
            consecutive months. We will of course provide you with notice via the email address associated with your
            account before we do so.
          </div>
          <div className="container-heading">Guarantee</div>
          <div className="container-text">
            We strive to provide great services, but there are certain things that we can't guarantee. To the fullest
            extent permitted by law, myBridge and its affiliates, suppliers and distributors make no warranties, either
            express or implied, about the services. The services are provided "as is." We also disclaim any warranties
            of merchantability, fitness for a particular purpose and non-infringement. Some places do not allow the
            disclaimers in this paragraph, so they may not apply to you.
          </div>
          <div className="container-heading">Limitation of Liability</div>
          <div className="container-text">
            To the fullest extent permitted by law, except for any liability for myBridge ’s or its affiliates’ fraud,
            fraudulent misrepresentation, or gross negligence, in no event will myBridge, its affiliates, suppliers or
            distributors be liable for:
            <div className="mg-10"></div>
            1. Any indirect, special, incidental, punitive, exemplary or consequential damages, or
            <br></br>
            2. Any loss of use, data, business, or profits, regardless of legal theory.
            <br></br>
            <div className="mg-10"></div>
            This will be regardless of whether or not myBridge or any of its affiliates has been warned of the
            possibility of such damages, and even if a remedy fails of its essential purpose.
            <div className="mg-10"></div>
            Additionally, myBridge, its affiliates, suppliers and distributors will not be liable for aggregate
            liability for all claims relating to the services for more than the greater of $20 or the amounts paid by
            you to myBridge for the past 12 months of the services in question.
            <div className="mg-10"></div>
            Some places do not allow the types of limitations in this paragraph, so they may not apply to you.
          </div>
          <div className="container-heading">Resolving Disputes</div>
          <div className="container-text">
            Let's Try To Sort Things Out First. We want to address your concerns without needing a formal legal case.
            Before filing a claim against myBridge, you agree to try to resolve the dispute informally by contacting
            terms@myBridge.me. We will try to resolve the dispute informally by contacting you via email. If a dispute
            is not resolved within 15 days of submission, you or myBridge may bring a formal proceeding.
            <br></br>
            Judicial forum for disputes. You and myBridge agree that any judicial proceeding to resolve claims relating
            to these terms or the services will be brought in the federal or state courts of Mecklenburg County, North
            Carolina, subject to the mandatory arbitration provisions below. Both you and myBridge consent to venue and
            personal jurisdiction in such courts.
          </div>
          <div className="container-heading">Controlling Law</div>
          <div className="container-text">
            These terms will be governed by North Carolina law except for its conflicts of laws principles unless
            otherwise required by a mandatory law of any other jurisdiction.
          </div>
          <div className="container-heading">Entire Agreement</div>
          <div className="container-text">
            These terms constitute the entire agreement between you and myBridge with respect to the subject matter of
            these terms, and supersede and replace any other prior or contemporaneous agreements, or terms and
            conditions applicable to the subject matter of these terms. These terms create no third-party beneficiary
            rights.
          </div>
          <div className="container-heading">Waiver, Severability & Assignment</div>
          <div className="container-text">
            MyBridge’s failure to enforce a provision is not a waiver of its right to do so later. If a provision is
            found unenforceable, the remaining provisions of the terms will remain in full effect and an enforceable
            term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights
            under these terms, and any such attempt will be void. myBridge may assign its rights to any of its
            affiliates or subsidiaries, or to any successor in the interest of any business associated with the
            services.
          </div>
          <div className="container-heading">Modifications</div>
          <div className="container-text">
            We may revise these terms from time to time, and will always post the most current version on our website.
            If a revision meaningfully reduces your rights, we will notify you (by, for example, sending a message to
            the email address associated with your account or posting on this page). By continuing to use or access the
            Services after the revisions come into effect, you agree to be bound by the revised terms.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;
