import { ThreeDot } from 'components/common/Icons';
// import ReportModal from 'components/ReportModal';
import React from 'react';
import styled from 'styled-components';
import PostActionDropDown from './PostActionDropDown';
// import ChangeReplyModal from './ChangeReplyModal';
// import DeletePostModal from './DeletePostModal';
// import IgnorePostModal from './IgnorePostModal';
// import PinModal from './PinModal';
// import PostEditModal from './PostEditModal';

const Container = styled.div`
  position: relative;
`;

export const PostActionThreeDotContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  &:hover{
    background: #e4e6eb;

  }
`;

const PostActions = () => {

  const [show, setShow] = React.useState(false);
  const ref = React.useRef();
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Container ref={ref}>
        <PostActionThreeDotContainer ref={ref} onClick={handleShow}>
          <ThreeDot />
        </PostActionThreeDotContainer>
        <PostActionDropDown targetRef={ref} />
        {/* }


      {modalOpen.ignore && <IgnorePostModal postId={id} onClose={() => setModalOpen((prev) => ({ ...prev, ignore: false }))} />}

      {modalOpen.edit && <PostEditModal onClose={() => setModalOpen((prev) => ({ ...prev, edit: false }))} id={id} />}

      {modalOpen.report && (
        <ReportModal title="Block and report" name={userName} id={author.id} onClose={() => setModalOpen((prev) => ({ ...prev, report: false }))} />
      )} */}
      </Container>
    </>
  );
};

export default PostActions;
