import { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { DownIcon } from '../Icons';
import Checkbox from '../Checkbox';
import RadioButton from '../RadioButton';
import Button from '../Button';
import { Input } from '../Input';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalCompanySize, getGlobalIndustries, getGlobalCompanies } from 'store/slices/globalDataSlice';
import _ from 'lodash';
const SingleFilter = ({
  label,
  type,
  data,
  showInput,
  showKeywords,
  showArticleKeywords,
  showKeywordsTitle,
  searchJobsKeywords,
  searchJobsKeywordsData,
  setCollectedFilters,
  feature
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));
  const [showMenu, setShowMenu] = useState(false);
  const [radioValue, setRadioValue] = useState([]);
  //keywords
  const [firstName, setFirstName] = useState({ first_name: null });
  const [lastName, setLastName] = useState({ last_name: null });
  const [title, setTitle] = useState({ title: null });
  const [currentCompany, setCurrentCompany] = useState({ current_company: null });
  const [lastCompany, setLastCompany] = useState({ last_company: null });
  const [school, setSchool] = useState({ school: null });
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [incomingData, setIncomingData] = useState();
  const dispatch = useDispatch();

  const { globalCompanies, globalCompanySize, globalIndustries } = useSelector((state) => state.globalData);

  const labelMap = (label) => {
    switch (label) {
      case 'Locations':
        return 'location';
      case 'Current company':
        return 'current_company';
      case 'Connections':
        return 'connection';
      case 'Date Posted':
        return 'date_posted';
      case 'Company':
        return 'company';
      case 'Experience level':
        return 'experience_level';
      case 'Job type':
        return 'job_type';
      case 'On-site/Remote':
        return 'onsite_remote';
      case 'Benefits':
        return 'benefits';
      default:
        return labelGenerator(label);
    }
  };

  const labelGenerator = (label) => {
    if (label.includes(' '))
      return label
        .split(' ')
        .map((e) => e?.toLowerCase())
        .join('_');
    else return label.toLowerCase();
  };

  const [input, setInput] = useState({ [labelMap(label)]: null });
  const checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== '') return true;
    }
    return false;
  };

  const collectAllFilters = () => {
    const filterCollections = [
      firstName,
      lastName,
      title,
      currentCompany,
      lastCompany,
      school,
      checkboxValue,
      radioValue,
    ].flat();
    return filterCollections.filter((item) => {
      // if (Object.keys(item).length > 0 && Object.keys(item) !== null) return item;
      if (checkProperties(item)) {
        return item;
      }
    });
  };

  useEffect(() => {
    if (input !== '')
      switch (labelMap(label)) {
        case 'company':
          dispatch(getGlobalCompanies());
          setIncomingData(globalCompanies);
          break;
        case 'industry':
          dispatch(getGlobalIndustries());
          setIncomingData(globalIndustries);
          break;
        case 'company_size':
          dispatch(getGlobalCompanySize());
          setIncomingData(globalCompanySize);
          break;

        default:
          setIncomingData(data?.filter((item) => item?.label?.includes(input[labelMap(label)])));
          break;
      }
    else setIncomingData(data?.filter((e) => e !== undefined));
  }, [input]);

  useEffect(() => {
    setIncomingData(data?.filter((e) => e !== undefined));
  }, [data]);

  const applyFilters = () => {
    setCollectedFilters(collectAllFilters());
    handleShow();
  };

  const handleCheckboxes = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if (checked) {
      setCheckboxValue((prevValue) => [...prevValue, { [labelMap(label)]: value }]);
    } else {
      const filteredItems = checkboxValue.filter((item) => item[labelMap(label)] !== value);
      setCheckboxValue(filteredItems);
    }
  };

  const handleRadios = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    if (checked) {
      setRadioValue({ [labelMap(label)]: value });
    } else {
      const filteredItems = radioValue.filter((item) => item[labelMap(label)] !== value);
      setRadioValue(filteredItems);
    }
  };

  const handleShow = () => {
    ((Array.isArray(data) && data?.length) || data === null) && setShowMenu((prev) => !prev);
  };

  return (
    <div className={`border border-secondary single-filter position-relative user-select-none me-2 ${(Array.isArray(data) && !data?.length) && 'opacity-50 cursor-disable'}`} ref={ref}>
      <div className={`d-flex align-items-center px-2 py-1 ${(Array.isArray(data) && !data?.length) ? 'cursor-disable' : 'cursor-pointer'}`} onClick={handleShow}>
        <p style={{ fontSize: '14px' }}>{label}</p>
        <DownIcon className="ms-1" />
      </div>
      {showMenu && (
        <div className={`navbar-dropdown--menu w-auto px-3 py-2 ${feature === 'jobList' && 'drop-left'}`}>
          {showInput ? (
            <div className="mx-2 mt-1">
              <Input placeholder={`Add a ${label}`} onChange={(e) => setInput({ [labelMap(label)]: e.target.value })} />
            </div>
          ) : (
            ''
          )}
          <div className="menu-body py-0">
            {showKeywords ? (
              <div>
                <h4 className="mt-3 filter-header">{label}</h4>
                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <div className="single-filter-keywords-input w-50 me-1">
                      <h6>First Name</h6>
                      <Input
                        placeholder="First Name"
                        value={firstName.first_name}
                        onChange={(e) => {
                          setFirstName({ first_name: e.target.value });
                        }}
                      />
                    </div>
                    <div className="single-filter-keywords-input w-50 ms-1">
                      <h6>Last Name</h6>
                      <Input
                        placeholder="Last Name"
                        value={lastName.last_name}
                        onChange={(e) => {
                          setLastName({ last_name: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <h6>Title</h6>
                      <Input
                        placeholder="Title"
                        value={title.title}
                        onChange={(e) => {
                          setTitle({ title: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="single-filter-keywords-input me-1">
                      <h6>Current Company</h6>
                      <Input
                        placeholder="Current Company"
                        value={currentCompany.current_company}
                        onChange={(e) => {
                          setCurrentCompany({ current_company: e.target.value });
                        }}
                      />
                    </div>
                    <div className="single-filter-keywords-input ms-1">
                      <h6>Last Company</h6>
                      <Input
                        placeholder="Last Company"
                        value={lastCompany.last_company}
                        onChange={(e) => {
                          setLastCompany({ last_company: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <h6>School</h6>
                      <Input
                        placeholder="School"
                        value={school.school}
                        onChange={(e) => {
                          setSchool({ school: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {showArticleKeywords ? (
              <div>
                <h4 className="mt-3">{label}</h4>
                <div className="mt-3">
                  <div>
                    <span>Title</span>
                    <Input
                      placeholder="Title"
                      value={title.title}
                      onChange={(e) => {
                        setTitle({ title: e.target.value });
                      }}
                    />
                  </div>
                  <div className="single-filter-keywords-input me-1">
                    <span>From Company</span>
                    <Input
                      placeholder="Current Company"
                      value={currentCompany.current_company}
                      onChange={(e) => {
                        setCurrentCompany({ current_company: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {showKeywordsTitle === 'Keyword' ? <h4 className="mb-3">{showKeywordsTitle}</h4> : ''}
            {incomingData?.map((item) => {
              let comp;
              if (type === 'radio') {
                comp = (
                  <RadioButton
                    label={item.label}
                    name={item.name}
                    value={item.value}
                    changed={(e) => handleRadios(e)}
                    checked={_.some(radioValue, { [labelMap(label)]: item.value }) === true ? true : false}
                  />
                );
              } else if (type === 'checkbox' && item && item.label) {
                if (item?.labelHead) {
                  comp = (
                    <div>
                      <h6>{item.labelHeadTitle}</h6>
                      <Checkbox
                        label={item.label}
                        name={item.name}
                        value={item.value}
                        onChange={(e) => handleCheckboxes(e)}
                        checked={_.some(checkboxValue, { [labelMap(label)]: item?.value }) === true ? true : false}
                      />
                    </div>
                  );
                } else {
                  comp = (
                    <Checkbox
                      label={item?.label}
                      name={item?.name}
                      value={item?.value}
                      onChange={(e) => handleCheckboxes(e)}
                      checked={_.some(checkboxValue, { [labelMap(label)]: item?.value }) === true ? true : false}
                    />
                  );
                }
              }
              return comp;
            })}
            {searchJobsKeywords ? (
              <div>
                <h6>{Object.keys(searchJobsKeywordsData)[0]}</h6>
                {searchJobsKeywordsData?.location?.map((item) => {
                  let comp;
                  <Input placeholder="Add a location" />;
                  if (item.type === 'radio') {
                    comp = (
                      <RadioButton
                        label={item.label}
                        name={item.name}
                        changed={(e) => handleRadios(e)}
                        checked={_.some(radioValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        value={item.value}
                      />
                    );
                  } else if (item.type === 'checkbox') {
                    comp = (
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox
                          label={item.label}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => handleCheckboxes(e)}
                          checked={_.some(checkboxValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        />
                      </div>
                    );
                  }
                  return comp;
                })}
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[1]}</h6>
                {searchJobsKeywordsData?.Industry?.map((item) => {
                  let comp;
                  <Input placeholder="Add a Industry" />;
                  if (item.type === 'radio') {
                    comp = (
                      <RadioButton
                        label={item.label}
                        name={item.name}
                        changed={(e) => handleRadios(e)}
                        checked={_.some(radioValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        value={item.value}
                      />
                    );
                  } else if (item.type === 'checkbox') {
                    comp = (
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox
                          label={item.label}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => handleCheckboxes(e)}
                          checked={_.some(checkboxValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        />
                      </div>
                    );
                  }
                  return comp;
                })}
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[2]}</h6>
                {searchJobsKeywordsData['Job Function']?.map((item) => {
                  let comp;
                  <Input placeholder="Add a Job Function" />;
                  if (item.type === 'radio') {
                    comp = (
                      <RadioButton
                        label={item.label}
                        name={item.name}
                        changed={(e) => handleRadios(e)}
                        checked={_.some(radioValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        value={item.value}
                      />
                    );
                  } else if (item.type === 'checkbox') {
                    comp = (
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox
                          label={item.label}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => handleCheckboxes(e)}
                          checked={_.some(checkboxValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        />
                      </div>
                    );
                  }
                  return comp;
                })}
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[3]}</h6>
                {searchJobsKeywordsData?.Title?.map((item) => {
                  let comp;
                  <Input placeholder="Add a Title" />;
                  if (item.type === 'radio') {
                    comp = (
                      <RadioButton
                        label={item.label}
                        name={item.name}
                        changed={(e) => handleRadios(e)}
                        checked={_.some(radioValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        value={item.value}
                      />
                    );
                  } else if (item.type === 'checkbox') {
                    comp = (
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox
                          label={item.label}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => handleCheckboxes(e)}
                          checked={_.some(checkboxValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        />
                      </div>
                    );
                  }
                  return comp;
                })}
                <hr></hr>
                <div className="d-flex align-items-center justify-content-between">
                  <div>Under 10 applicants</div>
                  <ToggleSwitch name="applicants" label="applicants" />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>In your network</div>
                  <ToggleSwitch name="network" label="network" />
                </div>
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[4]}</h6>
                <div className="columns-2-salary-keywords">
                  {searchJobsKeywordsData?.Salary?.map((item) => {
                    let comp;
                    if (item.type === 'radio') {
                      comp = (
                        <RadioButton
                          label={item.label}
                          name={item.name}
                          changed={(e) => handleRadios(e)}
                          checked={_.some(radioValue, { [labelMap(label)]: item.value }) === true ? true : false}
                          value={item.value}
                        />
                      );
                    } else if (item.type === 'checkbox') {
                      comp = (
                        <div>
                          {/* <h6>{item.labelHeadTitle}</h6> */}
                          <Checkbox
                            label={item.label}
                            name={item.name}
                            value={item.value}
                            onChange={(e) => handleCheckboxes(e)}
                            checked={_.some(checkboxValue, { [labelMap(label)]: item.value }) === true ? true : false}
                          />
                        </div>
                      );
                    }
                    return comp;
                  })}
                </div>
                <hr></hr>
                <h6>{Object.keys(searchJobsKeywordsData)[5]}</h6>
                {searchJobsKeywordsData?.Benefits?.map((item) => {
                  let comp;
                  if (item.type === 'radio') {
                    comp = (
                      <RadioButton
                        label={item.label}
                        name={item.name}
                        changed={(e) => {
                          setRadioValue([
                            ...radioValue,
                            { [labelMap(label)]: e.target.checked === true && e.target.value },
                          ]);
                        }}
                        value={item.value}
                      />
                    );
                  } else if (item.type === 'checkbox') {
                    comp = (
                      <div>
                        {/* <h6>{item.labelHeadTitle}</h6> */}
                        <Checkbox
                          label={item.label}
                          name={item.name}
                          value={item.value}
                          onChange={(e) => handleCheckboxes(e)}
                          checked={_.some(checkboxValue, { [labelMap(label)]: item.value }) === true ? true : false}
                        />
                      </div>
                    );
                  }
                  return comp;
                })}
              </div>
            ) : (
              ''
            )}
          </div>
          <hr />
          <div className="d-flex justify-content-end m-3">
            <Button className="btn-secondary me-2 h-36" onClick={handleShow}>
              Cancel
            </Button>
            <Button onClick={() => applyFilters()} className="h-36 btn-blue">
              Show results
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleFilter;
