import { useMutation, useQuery } from '@tanstack/react-query';
import { getCalendarIntegrationURL, getIntegrationURL } from 'api/google-integration';
import Button from 'components/common/Button';
import {
  AppleCalendarIcon,
  GoogleCalendarIcon,
  LinkedinIcon,
  OutlookIcon,
  YahooCalendarIcon,
  TickIcon,
} from 'components/common/Icons';
import { Input } from 'components/common/Input';
import { Spinner } from 'components/common/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGoogleIntegrationUrl, getOutlookIntegrationUrl } from 'store/slices/settingsSlice';
import { Text } from 'components/StyledComponents';
import Modal from 'components/common/Modal/Modal';

export default function Integration() {
  const dispatch = useDispatch();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [modalOpen, setModalOpen] = useState(false);
  const [heading, setHeading] = useState(null);
  const [googleLinked, setGoogleLinked] = useState(false)

  window.addEventListener('message', (event) => {
    if (event.data?.msg == 'showIntegrationModal') {
        setModalOpen(true);
    }
  });


  const { googleIntLink } = useQuery(["getCalIntLink"], getCalendarIntegrationURL, {
    refetchOnWindowFocus:false,
    onError(err) {
      console.log(err)
    },
    onSuccess(data) {
      if (data.error === "already_integrated") {
        setGoogleLinked(true)
      }
    }
  })
  const { mutate, isLoading, error } = useMutation(getCalendarIntegrationURL, {
    onSuccess: (data) => {
      console.log(data);
      setHeading('google');
      if (data?.error) {
        alert(data.error);
      } else {
        const { url = null } = data;
        console.log(url);
        if (url) {
          window.open(url, 'mywin', 'width=500,height=500');
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  //   const navigate = useNavigate();
  const integrateCalender = (platform) => {
    switch (platform) {
      case 'google':
        dispatch(getGoogleIntegrationUrl())
          .then((res) => {
            window.open(res.payload.url, '_blank', 'width=500,height=500');
            // navigate(googleIntegrationUrl);
          })
          .catch((err) => console.log('Integrating google failed', err));
        break;
      case 'outlook':
        dispatch(getOutlookIntegrationUrl())
          .then((res) => {
            window.open(res.payload.url, '_blank', 'width=500,height=500');
            // navigate(outlookIntegrationUrl);
          })
          .catch((err) => console.log('Integrating google failed', err));
        break;
      default:
        break;
    }
  };

  const { mutate:contactMutate ,loading } = useMutation(getIntegrationURL, {
    onSuccess: (data) => {
      const { url = null } = data?.data;
      setHeading('contacts');
      if (url) {
        window.open(url, 'mywin', 'width=500,height=500');
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });


  const handleClick = (e) => {
    contactMutate();
  };

  return (
    <div className="user-settings--integration">
      <div className="row gap-lg-3 mb-5">
        <div className="col-12">
          <Text fS="20px" color="#333333" className="border-bottom pb-2 mb-3">
            Calendar Integration
          </Text>
          <p className="mb-3 mb-lg-0">
            Syncing meetings between myBridge and your calendar makes it easier to schedule new meetings or view and
            join upcoming meetings directly from your calendar.
          </p>
        </div>
        <div className="col-12">
          <div
            style={{ display: "inline" }}
          // aria-label="Hey tooltip!" data-microtip-position="top" role="tooltip"
          >
            {/* <Button
              className="integration-button"
              onClick={() => {
                if (userProfileInfo?.outlook_calendar_email) {
                  setModalOpen((prevState) => !prevState)
                  setHeading('Outlook Calendar')
                }
                else {
                  integrateCalender('outlook');
                }
              }}
            >
              <OutlookIcon className="me-2" />
              Outlook
            </Button> */}
          </div>
          <div
            style={{ display: "inline" }}
            // aria-label="Hey tooltip!" data-microtip-position="top" role="tooltip"
            {...(googleLinked ? {
              "aria-label": "Already linked!",
              "data-microtip-position": "top",
              "role": "tooltip"
            } : {})}
          >
            <Button
              // disabled={googleLinked} 
              className={`${googleLinked ? 'integration-button disabled-button' : 'integration-button '}`}
              onClick={() => {
                if (userProfileInfo?.google_calendar_email) {
                  setHeading('google');
                  setModalOpen((prevState) => !prevState);
                }
                else {
                  // intaddegrateCalender('google');
                  mutate();
                }
              }}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                {
                  !googleLinked ? (
                    <GoogleCalendarIcon className="me-2" />
                  ) : (
                    <TickIcon className="me-2" />
                  )
                }
                  Google Calendar
                </>
              )}
            </Button>
            {/* )} */}
          </div>
        </div>
        {/* <div className="col-12">
                    <Button className="integration-button">
                        <YahooCalendarIcon className="me-2" />
                        Yahoo Calendar
                    </Button>
                    <Button className="integration-button">
                        <AppleCalendarIcon className="me-2" />
                        Apple Calendar
                    </Button>
                </div> */}
      </div>

      {/* <div className="row gap-lg-3 mb-5">
        <div className="col-12">
          <h5 className="fw-medium mb-3">LinkedIn Profile Integration</h5>
          <p className="mb-3 mb-lg-0">
            Syncing your profile with LinkedIn is the most efficient way to get your online profiles consistent.
          </p>
          <p className="mb-3 mb-lg-0">
            Keep your profiles updated by syncing your name and profile picture across apps
          </p>
        </div>
        <div className="col-12">
          <LinkedinIcon className="me-3" />
          <Button className="integration-button">Import your Linkedin Profile</Button>
        </div>
      </div> */}

      <div className="row gap-lg-3 mb-5">
        <div className="col-12">
          <h5 className="fw-medium mb-3">Personal Contacts</h5>
          <p className="mb-3 mb-lg-0">See who you already know</p>
          <p className="mb-3 mb-lg-0">
            Syncing your contacts is the fastest way to grow your network. We’ll periodically import and store your
            contacts to help you and others connect. You choose who to connect to and who to invite.
          </p>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-8">
              <Input type="text" className="rounded" placeholder="Email" />
            </div>
            <div className="col-12 col-lg-3">
              <Button onClick={()=>handleClick()} className="continue-button">Continue</Button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen &&  heading==='google' && (
        <Modal
          onClose={() => setModalOpen((prevState) => !prevState)}
          heading={'Calendar Integration'}
          className={'meeting-popup'}
        >
          <p>{`You have synced your Google and myBridge calendars`}</p>
          <div className="text-end">
            <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
              OK
            </Button>
          </div>
        </Modal>
      )}
      {modalOpen && heading==='contacts' && (
        <Modal
          onClose={() => setModalOpen((prevState) => !prevState)}
          heading={'Syncing your contact'}
          className={'meeting-popup'}
        >
          <p>{`You're good to go, your contacts have been have synced`}</p>
          <div className="text-end">
            <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
              OK
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}
