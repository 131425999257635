import styled from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 38px;
  background: #3d5a80;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;

const EventJoinButton = ({ eventId }) => {
  return <Container>Join</Container>;
};

export default EventJoinButton;
