import React from 'react';
// import FeedForm from './FeedForm';
import PostForm from 'components/PostForm';
import PostsList from './PostsList';

const Feed = (props) => { 
  const { type, companyDetails, showForm, showModal,isPublic } = props;
 
  return (
    <div className="feed">
      {!isPublic&&
      <>
 {showForm && <PostForm type={type} />}
 </>
      }
     
      <PostsList listType={type} companyDetails={companyDetails} postModal={showModal} isPublic={isPublic}/>
    </div>
  );
};

export default Feed;
