import Authentication from 'pages/Auth/Auth';
import ResetPassword from 'pages/Auth/ForgotPassword/ResetPassword';
import Dashboard from 'pages/Dashboard';
import JobsPage from 'pages/Jobs';
import ProfilePage from 'pages/Profile';
import PostJob from 'pages/Jobs/PostJob';
import SettingsPage from 'pages/Settings/Index';
import ActivateAccount from 'pages/Auth/ForgotPassword/ActivateAccount';
import PublicProfilePage from 'pages/Profile/PublicProfile';
import NetworkInvitations from 'pages/Invitations';
import MutualConnections from 'pages/MutualConnections';
import Search from 'pages/Search';
import TestimonialPage from 'pages/Testimonial';
import JobDetails from 'pages/Jobs/JobListing/JobDetails';
import OnboardingProfile from 'pages/Onboarding-ui/Profile';
import OnboardingEmployment from 'pages/Onboarding-ui/Employment';
import OnboardingPhoto from 'pages/Onboarding-ui/Photo';
import { onboardingSteps } from 'pages/Onboarding-ui/constant';
import OnboardingJob from 'pages/Onboarding-ui/Job';
import OnboardingContact from 'pages/Onboarding-ui/Contact';
import OnboardingConnect from 'pages/Onboarding-ui/Connect';
import EmailVerification from 'pages/Onboarding-ui/EmailVerification';
import ProfileViews from 'pages/Profile/Visits/ProfileViews';
// import Meeting from 'components/Meeting';
import { Teams } from 'pages/Teams';
import TeamProfile from 'pages/Teams/Profile';
import TeamProfilePublic from 'pages/Teams/Public';
import CalendarListing from 'pages/CalendarListing';

// Company Route Handlers
import CreateCompany from 'pages/Company/create';
import CompanyPage from 'pages/Company';
import PublicProfileCompany from 'pages/Company/public';
import CreateCompanyJob from 'pages/Company/tabs/jobs/create';

import CreatePost from 'pages/CreatePost';
import AboutUs from 'pages/AboutUs';
import TermsConditions from 'pages/TermsConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';


import Blogdetail from 'pages/CreatePost/detail';
import EventPage from 'pages/Event/EventPage';
import EventListPage from 'pages/Event/EventListPage';
import NotificationPage from 'pages/Notifications';
import ThankYouPage from 'pages/Thankyou';
import GoogleIntegrationCallBack from 'pages/GoogleIntegration';
import MYBridgeArticleListPage from 'pages/CreatePost/MyBridgeArticleListPage';
import MicrosoftIntegrationCallBack from 'pages/MicrosoftIntegration';

const routes = [
  {
    component: <Authentication />,
    route: '/signin',
    routeProps: { exact: true },
  },

  {
    component: <Authentication />,
    route: '/signup',
    routeProps: { exact: true },
  },

  {
    component: <Authentication />,
    route: '/forgot-password',
    routeProps: { exact: true },
  },

  {
    component: <Dashboard />,
    route: '/',
    routeProps: { exact: true },
    privateRoute: true,
  },

  {
    component: <ProfilePage />,
    route: '/profile',
    routeProps: { exact: true },
    privateRoute: true,
  },

  {
    component: <ProfilePage />,
    route: '/profile/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CalendarListing />,
    route: '/calendarlisting',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CalendarListing />,
    route: '/calendarlisting',
    routeProps: { exact: true },
    privateRoute: true,
  },

  {
    component: <ProfilePage />,
    route: '/profile/:id/:tid',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <Authentication />,
    route: '/password_resetconfirm',
    routeProps: { exact: true },
    // privateRoute: true
  },
  {
    component: <Authentication />,
    route: '/password_resetconfirm/:id',
    routeProps: { exact: true },
    // privateRoute: true
  },
  {
    component: <ActivateAccount />,
    route: '/activate',
    routeProps: { exact: true },
  },

  {
    component: <ActivateAccount />,
    route: '/activate/:uid/:token',
    routeProps: { exact: true },
  },

  {
    component: <JobsPage />,
    route: '/jobs',
    routeProps: { exact: true },
    privateRoute: true,
  },

  {
    component: <PostJob />,
    route: '/post_jobs',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <JobDetails />,
    route: '/jobs/:slug',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TestimonialPage />,
    route: '/testimonial',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <SettingsPage />,
    route: '/settings',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <SettingsPage />,
    route: '/settings/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <PublicProfilePage />,
    route: '/p/:slug',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <PublicProfilePage />,
    route: '/p/:slug/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <PublicProfilePage />,
    route: '/p/:slug/:id/:tid',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CreateCompany />,
    route: '/create/company',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CreateCompanyJob />,
    route: '/create/company/job/',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CompanyPage />,
    route: '/company/page/:companyId/:id/',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CompanyPage />,
    route: '/company/page/:companyId/:id/:tid',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <PublicProfileCompany />,
    route: '/c/:slug/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <NetworkInvitations />,
    route: '/invitations',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <NetworkInvitations />,
    route: '/invitations/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <MutualConnections />,
    route: '/mutual-connections',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <MutualConnections />,
    route: '/mutual-connections/:uid',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <MutualConnections />,
    route: '/mutual-connections/:uid/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <Search />,
    route: '/search',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <Search />,
    route: '/search/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <ProfileViews />,
    route: '/profile-views',
    routeProps: { exact: true },
    privateRoute: true,
  },

  {
    component: <OnboardingProfile />,
    route: `${onboardingSteps[0].url}/:step`,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <OnboardingProfile />,
    route: onboardingSteps[0].url,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <OnboardingEmployment />,
    route: onboardingSteps[1].url,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <OnboardingPhoto />,
    route: onboardingSteps[2].url,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <OnboardingJob />,
    route: onboardingSteps[3].url,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <OnboardingContact />,
    route: onboardingSteps[4].url,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <OnboardingConnect />,
    route: onboardingSteps[5].url,
    routeProps: { exact: true },
    privateRoute: false,
  },

  {
    component: <EmailVerification />,
    route: '/email-verify',
    routeProps: { exact: true },
    privateRoute: false,
  },
  {
    component: <></>,
    route: '/meeting/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <Teams />,
    route: '/teams',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TeamProfile />,
    route: '/teams/profile/:slug',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TeamProfile />,
    route: '/teams/profile/:slug/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TeamProfile />,
    route: '/teams/profile/:slug/:id/:tid',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TeamProfilePublic />,
    route: '/t/:slug',
    routeProps: { exact: true },
    privateRoute: false,
  },
  {
    component: <TeamProfile />,
    route: '/teams/profile/:slug/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TeamProfile />,
    route: '/teams/profile/:slug/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <TeamProfile />,
    route: '/teams/profile/:slug/:id/:tid',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CreatePost />,
    route: '/blog/:status',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CreatePost />,
    route: '/blog/:status/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <CreatePost />,
    route: '/blog/:status/:id',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <Blogdetail />,
    route: '/blog/detail/:id',
    routeProps: { exact: true },
    privateRoute: false,
  },
  {
    component: <Blogdetail />,
    route: '/blog/detail/draft/:id',
    routeProps: { exact: true },
    privateRoute: false,
  },
  {
    component: <EventPage />,
    route: '/event/new',
    routeProps: { exact: false },
    privateRoute: true,
  },
  {
    component: <EventListPage />,
    route: '/event',
    routeProps: { exact: false },
    privateRoute: true,
  },
  {
    component: <Blogdetail />,
    route: '/event/detail/:id',
    routeProps: { exact: false },
    privateRoute: true,
  },
  {
    component: <NotificationPage />,
    route: '/notifications',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <ThankYouPage />,
    route: '/thankyou',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <GoogleIntegrationCallBack />,
    route: '/integration/google/callback/',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <MicrosoftIntegrationCallBack />,
    route: '/integration/microsoft/callback/',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <MYBridgeArticleListPage />,
    route: '/mybridgearticle',
    routeProps: { exact: true },
    privateRoute: true,
  },
  {
    component: <AboutUs />,
    route: '/aboutus',
    routeProps: { exact: true },
    privateRoute: false,
  },
  {
    component: <TermsConditions />,
    route: '/termsandconditions',
    routeProps: { exact: true },
    privateRoute: false,
  },
  {
    component: <PrivacyPolicy />,
    route: '/privacypolicy',
    routeProps: { exact: true },
    privateRoute: false,
  },
];

export default routes;
