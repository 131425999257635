import React from 'react';

const ProfileNotFoundIllustrationSvg = () => (
  <svg width="246" height="230" viewBox="0 0 246 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9993_32508)">
      <path d="M246 193.302H-29V193.44H246V193.302Z" fill="#EBEBEB"/>
      <path d="M218.445 202.184H200.229V202.322H218.445V202.184Z" fill="#EBEBEB"/>
      <path d="M193.304 202.184H154.359V202.322H193.304V202.184Z" fill="#EBEBEB"/>
      <path d="M132.882 197.061H122.327V197.199H132.882V197.061Z" fill="#EBEBEB"/>
      <path d="M18.7125 197.988H-0.146973V198.126H18.7125V197.988Z" fill="#EBEBEB"/>
      <path d="M57.8999 197.988H23.6074V198.126H57.8999V197.988Z" fill="#EBEBEB"/>
      <path d="M94.827 200.318H74.5815V200.456H94.827V200.318Z" fill="#EBEBEB"/>
      <path d="M101.35 168.683H-4.84937C-5.68133 168.681 -6.47872 168.349 -7.06649 167.758C-7.65426 167.167 -7.98438 166.366 -7.98437 165.531V15.7015C-7.97713 14.8713 -7.64382 14.0775 -7.05683 13.4925C-6.46984 12.9075 -5.67659 12.5786 -4.84937 12.5771H101.35C102.183 12.5771 102.982 12.9092 103.571 13.5003C104.16 14.0914 104.491 14.8931 104.491 15.7291V165.531C104.491 166.367 104.16 167.168 103.571 167.76C102.982 168.351 102.183 168.683 101.35 168.683ZM-4.84937 12.6875C-5.64487 12.689 -6.40728 13.0072 -6.96926 13.5723C-7.53124 14.1373 -7.84688 14.9031 -7.84687 15.7015V165.531C-7.84688 166.329 -7.53124 167.095 -6.96926 167.66C-6.40728 168.225 -5.64487 168.543 -4.84937 168.545H101.35C102.146 168.543 102.909 168.225 103.472 167.66C104.035 167.095 104.352 166.33 104.353 165.531V15.7015C104.352 14.9026 104.035 14.1368 103.472 13.5719C102.909 13.007 102.146 12.689 101.35 12.6875H-4.84937Z" fill="#EBEBEB"/>
      <path d="M220.321 168.683H114.116C113.283 168.681 112.485 168.349 111.897 167.758C111.308 167.167 110.977 166.366 110.975 165.531V15.7015C110.984 14.8708 111.318 14.077 111.906 13.4921C112.494 12.9073 113.288 12.5786 114.116 12.5771H220.321C221.147 12.58 221.939 12.9096 222.524 13.4944C223.11 14.0793 223.443 14.8722 223.45 15.7015V165.531C223.45 166.365 223.121 167.165 222.534 167.756C221.948 168.346 221.152 168.68 220.321 168.683ZM114.116 12.6875C113.32 12.689 112.557 13.007 111.994 13.5719C111.431 14.1368 111.114 14.9026 111.113 15.7015V165.531C111.114 166.33 111.431 167.095 111.994 167.66C112.557 168.225 113.32 168.543 114.116 168.545H220.321C221.117 168.543 221.88 168.225 222.442 167.66C223.005 167.095 223.322 166.33 223.324 165.531V15.7015C223.322 14.9026 223.005 14.1368 222.442 13.5719C221.88 13.007 221.117 12.689 220.321 12.6875H114.116Z" fill="#EBEBEB"/>
      <path d="M12.7122 88.8745L87.3472 88.8745L87.3472 23.9759L12.7122 23.9759L12.7122 88.8745Z" fill="#E6E6E6"/>
      <path d="M11.0457 88.8745L85.6807 88.8745L85.6807 23.9759L11.0457 23.9759L11.0457 88.8745Z" fill="#F5F5F5"/>
      <path d="M82.7051 86.5563V26.2944L14.0266 26.2944V86.5563H82.7051Z" fill="#FAFAFA"/>
      <path d="M55.1609 86.5563L67.6074 26.2944H41.5704L29.1294 86.5563H55.1609Z" fill="white"/>
      <path d="M15.2803 86.5616L15.2803 26.2998H14.0263L14.0263 86.5616H15.2803Z" fill="#E6E6E6"/>
      <path d="M12.2777 75.7534L84.4487 75.7534V72.6401L12.2777 72.6401V75.7534Z" fill="#F5F5F5"/>
      <path d="M83.8818 190.018H95.8003V138.146H83.8818V190.018Z" fill="#F0F0F0"/>
      <path d="M6.05157 193.302H93.5181V190.017H6.05157V193.302Z" fill="#F0F0F0"/>
      <path d="M83.8818 138.141H3.75781V190.012H83.8818V138.141Z" fill="#F5F5F5"/>
      <path d="M79.2013 144.527H8.44385V161.54H79.2013V144.527Z" fill="#F0F0F0"/>
      <path d="M79.2013 166.618H8.44385V183.631H79.2013V166.618Z" fill="#F0F0F0"/>
      <path d="M24.7513 154.198H62.8938C63.2012 154.197 63.4955 154.074 63.7128 153.856C63.9301 153.637 64.0529 153.342 64.0543 153.034C64.0529 152.725 63.9303 152.429 63.7132 152.21C63.496 151.991 63.2017 151.866 62.8938 151.863H24.7513C24.4435 151.866 24.1491 151.991 23.932 152.21C23.7148 152.429 23.5923 152.725 23.5908 153.034C23.5923 153.342 23.715 153.637 23.9323 153.856C24.1496 154.074 24.444 154.197 24.7513 154.198Z" fill="#F5F5F5"/>
      <path d="M24.7513 176.289H62.8938C63.2012 176.288 63.4955 176.164 63.7128 175.946C63.9301 175.728 64.0529 175.433 64.0543 175.124C64.0543 174.815 63.9322 174.518 63.7148 174.299C63.4973 174.079 63.2021 173.956 62.8938 173.954H24.7513C24.443 173.956 24.1479 174.079 23.9304 174.299C23.7129 174.518 23.5908 174.815 23.5908 175.124C23.5923 175.433 23.715 175.728 23.9323 175.946C24.1496 176.164 24.444 176.288 24.7513 176.289Z" fill="#F5F5F5"/>
      <path d="M208.391 57.8961C215.178 56.0193 217.499 43.66 200.35 38.6147C204.134 51.0458 196.561 61.1529 208.391 57.8961Z" fill="#EBEBEB"/>
      <path d="M209.893 63.2617C215.244 57.4602 210.184 44.3337 190.912 49.6991C201.813 59.2708 200.57 73.3799 209.893 63.2617Z" fill="#E0E0E0"/>
      <path d="M204.613 64.7523C200.136 60.3032 206.549 44.8196 221.333 51.9956C212.956 60.0492 212.45 72.5079 204.613 64.7523Z" fill="#FAFAFA"/>
      <path d="M200.146 73.4184H213.176L214.16 64.5698H199.167L200.146 73.4184Z" fill="#E6E6E6"/>
      <path d="M199.019 66.1543H214.309C214.567 66.1543 214.815 66.0513 214.997 65.8681C215.18 65.6849 215.282 65.4364 215.282 65.1772C215.282 64.9181 215.18 64.6696 214.997 64.4864C214.815 64.3031 214.567 64.2002 214.309 64.2002H199.019C198.761 64.2002 198.513 64.3031 198.331 64.4864C198.148 64.6696 198.045 64.9181 198.045 65.1772C198.045 65.4364 198.148 65.6849 198.331 65.8681C198.513 66.0513 198.761 66.1543 199.019 66.1543Z" fill="#E6E6E6"/>
      <path d="M90.5206 178.315V163.328H102.956V148.347H115.397V133.36H127.833V118.373H140.274V103.392H152.709V88.4053H165.15V73.4185H177.591H246V193.302H90.5206H78.0796V178.315H90.5206Z" fill="#FAFAFA"/>
      <path d="M158.93 178.315V163.328H171.371V148.347H183.806V133.36H196.247V118.373H208.683V103.392H221.124V88.4053H233.559V73.4185H246V88.4053V193.302H158.93H146.494V178.315H158.93Z" fill="#E0E0E0"/>
      <path d="M146.494 178.315H77.3481V182.808H146.494V178.315Z" fill="#EBEBEB"/>
      <path d="M158.935 163.328H89.7891V167.821H158.935V163.328Z" fill="#EBEBEB"/>
      <path d="M171.371 148.347H102.225V152.84H171.371V148.347Z" fill="#EBEBEB"/>
      <path d="M183.812 133.36H114.666V137.854H183.812V133.36Z" fill="#EBEBEB"/>
      <path d="M196.247 118.374H127.101V122.867H196.247V118.374Z" fill="#EBEBEB"/>
      <path d="M208.688 103.392H139.542V107.885H208.688V103.392Z" fill="#EBEBEB"/>
      <path d="M221.124 88.4053H151.978V92.8986H221.124V88.4053Z" fill="#EBEBEB"/>
      <path d="M233.564 73.4185H164.418V77.9117H233.564V73.4185Z" fill="#EBEBEB"/>
      <path d="M108.5 218.23C167.395 218.23 215.139 215.433 215.139 211.982C215.139 208.531 167.395 205.733 108.5 205.733C49.6045 205.733 1.86035 208.531 1.86035 211.982C1.86035 215.433 49.6045 218.23 108.5 218.23Z" fill="#F5F5F5"/>
      <path d="M155.085 69.7806C154.986 71.4366 154.794 72.9546 154.579 74.5278C154.365 76.101 154.073 77.6411 153.743 79.1867C153.1 82.3149 152.17 85.3767 150.966 88.3333L150.465 89.4373L150.344 89.7133L150.3 89.8568L150.212 90.0335C150.044 90.38 149.831 90.7029 149.58 90.994C149.28 91.3325 148.922 91.6148 148.524 91.8275C148.132 92.0335 147.708 92.168 147.27 92.2249C146.597 92.2971 145.917 92.2257 145.273 92.0152C144.362 91.7029 143.502 91.2564 142.721 90.6904C142.038 90.2072 141.386 89.6818 140.769 89.1172C138.454 86.9629 136.371 84.5707 134.554 81.9798L136.374 80.4287C137.474 81.4499 138.574 82.4821 139.674 83.4592C140.768 84.436 141.91 85.3571 143.095 86.2192C143.653 86.6198 144.234 86.9884 144.833 87.3232C145.317 87.6174 145.839 87.8423 146.384 87.9911C146.571 88.0297 146.703 87.9911 146.643 87.9911C146.513 88.0055 146.391 88.0617 146.296 88.1512C146.263 88.1512 146.263 88.1512 146.296 88.1512L146.472 87.693L146.857 86.6884C147.811 83.9224 148.556 81.0881 149.085 78.2096C149.376 76.7634 149.58 75.2896 149.8 73.8268C150.02 72.364 150.174 70.857 150.3 69.4604L155.085 69.7806Z" fill="#FFC3BD"/>
      <path d="M151.752 63.7032C149.701 64.2276 148.265 72.6952 148.265 72.6952L153.914 76.5592C153.914 76.5592 159.331 68.0198 157.764 65.7566C156.114 63.3885 154.744 62.9414 151.752 63.7032Z" fill="#3D5A80"/>
      <path d="M151.752 63.7032C149.701 64.2276 148.265 72.6952 148.265 72.6952L153.914 76.5592C153.914 76.5592 159.331 68.0198 157.764 65.7566C156.114 63.3885 154.744 62.9414 151.752 63.7032Z" fill="#3D5A80"/>
      <path opacity="0.4" d="M151.752 63.7032C149.701 64.2276 148.265 72.6952 148.265 72.6952L153.914 76.5592C153.914 76.5592 159.331 68.0198 157.764 65.7566C156.114 63.3885 154.744 62.9414 151.752 63.7032Z" fill="black"/>
      <path opacity="0.3" d="M151.934 65.5967L150.361 74.1251L153.534 76.2889L151.934 65.5967Z" fill="black"/>
      <path d="M136.468 80.0808L133.8 75.8193L132.277 80.6604C132.277 80.6604 134.477 82.9788 136.473 82.4268L136.468 80.0808Z" fill="#FFC3BD"/>
      <path d="M129.84 74.4121L128.784 78.53L132.255 80.6607L133.778 75.8197L129.84 74.4121Z" fill="#FFC3BD"/>
      <path d="M162.84 206.191H157.868L157.533 194.632H162.51L162.84 206.191Z" fill="#FFC3BD"/>
      <path d="M206.725 183.007L202.935 186.242L194.262 178.469L198.051 175.24L206.725 183.007Z" fill="#FFC3BD"/>
      <path d="M201.896 185.855L205.575 181.627C205.638 181.554 205.725 181.507 205.82 181.493C205.916 181.48 206.012 181.502 206.092 181.555L209.838 183.99C209.93 184.054 210.008 184.137 210.066 184.234C210.124 184.331 210.161 184.439 210.175 184.551C210.188 184.663 210.178 184.776 210.144 184.884C210.111 184.992 210.055 185.092 209.981 185.176C208.672 186.628 207.968 187.263 206.351 189.118C205.361 190.26 204.024 192.038 202.649 193.611C201.274 195.184 199.646 193.914 200.081 193.131C202.011 189.631 201.549 188.406 201.582 186.683C201.587 186.379 201.698 186.086 201.896 185.855Z" fill="#263238"/>
      <path d="M157.698 205.617H163.286C163.382 205.616 163.475 205.65 163.548 205.713C163.621 205.776 163.669 205.863 163.682 205.959L164.32 210.375C164.338 210.492 164.33 210.611 164.296 210.724C164.263 210.837 164.205 210.942 164.127 211.03C164.048 211.118 163.952 211.188 163.843 211.234C163.735 211.28 163.618 211.301 163.5 211.297C161.553 211.264 160.613 211.148 158.154 211.148C156.642 211.148 153.518 211.308 151.428 211.308C149.338 211.308 149.228 209.232 150.102 209.05C153.996 208.206 155.531 207.046 156.845 205.937C157.082 205.73 157.385 205.617 157.698 205.617Z" fill="#263238"/>
      <path opacity="0.2" d="M157.538 194.638L157.709 200.594H162.681L162.51 194.638H157.538Z" fill="black"/>
      <path opacity="0.2" d="M198.057 175.24L194.262 178.469L198.739 182.477L202.528 179.242L198.057 175.24Z" fill="black"/>
      <path d="M151.752 63.7032C151.752 63.7032 149.382 64.5423 154.128 93.8314H174.324C173.989 85.579 173.978 80.4895 177.905 63.5597C175.077 62.9746 172.212 62.5946 169.33 62.4226C166.276 62.2042 163.21 62.2042 160.156 62.4226C156.218 62.809 151.752 63.7032 151.752 63.7032Z" fill="#3D5A80"/>
      <path opacity="0.4" d="M151.752 63.7032C151.752 63.7032 149.382 64.5423 154.128 93.8314H174.324C173.989 85.579 173.978 80.4895 177.905 63.5597C175.077 62.9746 172.212 62.5946 169.33 62.4226C166.276 62.2042 163.21 62.2042 160.156 62.4226C156.218 62.809 151.752 63.7032 151.752 63.7032Z" fill="black"/>
      <path d="M169.33 62.4226C166.276 62.2042 163.21 62.2042 160.156 62.4226C159.661 62.4667 159.155 62.5219 158.655 62.5827C158.448 62.8964 158.315 63.2537 158.267 63.6269C158.22 64.0002 158.258 64.3795 158.38 64.7355C159.023 66.3418 161.344 66.5295 163.038 66.5295C169.292 66.5295 170.348 62.8642 170.392 62.7096L170.452 62.4888C170.1 62.4667 169.704 62.4391 169.33 62.4226Z" fill="white"/>
      <path d="M168.45 52.0615C167.867 55.0202 167.295 60.4464 169.357 62.4226C169.357 62.4226 168.549 65.431 163.065 65.431C157.015 65.431 160.183 62.4281 160.183 62.4281C163.483 61.6387 163.39 59.1878 162.818 56.9081L168.45 52.0615Z" fill="#FFC3BD"/>
      <path opacity="0.2" d="M166.124 54.0264L162.791 56.8802C162.934 57.4227 163.03 57.9769 163.077 58.5362C164.331 58.354 166.058 56.9685 166.195 55.6492C166.272 55.1085 166.248 54.5581 166.124 54.0264Z" fill="black"/>
      <path d="M164.991 35.9539C164.479 34.2151 164.05 31.4772 167.13 30.2407C165.926 28.7613 165.634 25.9792 167.13 24.3288C168.626 22.6783 170.87 23.2855 170.87 23.2855C170.87 23.2855 171.53 19.2504 176.42 19.9735C181.309 20.6966 179.131 23.9258 179.131 23.9258C179.131 23.9258 185.803 22.601 186.248 28.8938C186.694 35.1866 182.866 34.4138 182.866 34.4138C182.866 34.4138 185.841 36.9199 184.191 39.6744C182.541 42.4288 178.73 41.4794 178.73 41.4794C178.73 41.4794 176.937 46.348 172.713 43.577C168.489 40.806 164.991 35.9539 164.991 35.9539Z" fill="#263238"/>
      <path d="M155.085 39.6248C153.435 41.7665 154.161 44.1898 155.635 45.1448C159.183 47.419 164.314 37.8805 162.978 36.7378C161.641 35.5952 156.735 37.4775 155.085 39.6248Z" fill="#263238"/>
      <path d="M169.985 34.5244C167.653 33.5804 165.288 34.5685 164.551 35.9927C162.752 39.4648 170.92 45.7189 173.285 41.3802C174.687 38.8024 172.388 35.4959 169.985 34.5244Z" fill="#3D5A80"/>
      <path d="M169 45.1446C169.171 50.1126 169.347 52.1882 167.108 54.9537C163.748 59.1103 157.648 58.2381 156.009 53.4357C154.535 49.1135 154.508 41.7443 159.122 39.3266C160.137 38.788 161.272 38.5187 162.419 38.5441C163.566 38.5695 164.688 38.8889 165.678 39.4718C166.667 40.0548 167.492 40.8819 168.074 41.8748C168.655 42.8676 168.974 43.9932 169 45.1446Z" fill="#FFC3BD"/>
      <path d="M168.258 47.7446C166.058 46.9222 165.508 44.3443 165.86 43.3286C164.76 43.2845 162.356 42.887 161.46 41.5181C159.744 42.6221 156.119 43.7261 155.729 41.8879C155.338 40.0498 160.805 35.3246 164.941 34.9603C169.858 34.5298 174.709 39.3763 173.461 44.0186C172.212 48.661 168.258 47.7446 168.258 47.7446Z" fill="#263238"/>
      <path d="M154.15 93.8311C154.15 93.8311 150.229 132.278 150.553 147.502C150.894 163.333 156.675 199.44 156.675 199.44H163.396C163.396 199.44 162.334 164.255 162.928 148.711C163.577 131.759 168.94 93.8311 168.94 93.8311H154.15Z" fill="#263238"/>
      <path d="M163.957 199.683H156.07L155.487 197.16L164.325 196.851L163.957 199.683Z" fill="#3D5A80"/>
      <path opacity="0.2" d="M162.84 103.398C157.89 113.411 161.845 131.682 163.599 138.483C164.496 128.271 166.014 115.79 167.196 106.627C166.448 101.449 165.15 98.7774 162.84 103.398Z" fill="black"/>
      <path d="M159.463 93.8314C159.463 93.8314 164.039 133.802 167.493 146.636C171.596 161.871 197.078 182.378 197.078 182.378L202.248 177.962C202.248 177.962 183.779 155.683 180.638 147.657C173.945 130.545 179.676 104.998 174.324 93.8203L159.463 93.8314Z" fill="#263238"/>
      <path d="M203.073 177.735L196.66 183.2L194.262 181.759L201.516 174.986L203.073 177.735Z" fill="#3D5A80"/>
      <path d="M161.68 47.2202C161.718 47.6232 161.537 47.9654 161.278 47.993C161.02 48.0206 160.772 47.7115 160.728 47.3141C160.684 46.9166 160.871 46.5633 161.135 46.5413C161.399 46.5192 161.641 46.8007 161.68 47.2202Z" fill="#263238"/>
      <path d="M157.17 47.6567C157.208 48.0541 157.027 48.4019 156.763 48.4295C156.499 48.4571 156.262 48.148 156.213 47.745C156.163 47.342 156.356 46.9998 156.614 46.9777C156.873 46.9556 157.131 47.2537 157.17 47.6567Z" fill="#263238"/>
      <path d="M156.741 46.9885L155.756 46.8008C155.756 46.8008 156.35 47.5073 156.741 46.9885Z" fill="#263238"/>
      <path d="M158.424 48.2305C158.156 49.1782 157.76 50.0846 157.247 50.9242C157.465 51.0502 157.707 51.1272 157.958 51.1501C158.209 51.173 158.461 51.1411 158.699 51.0567L158.424 48.2305Z" fill="#ED847E"/>
      <path d="M160.915 52.3759C160.697 52.4358 160.475 52.4764 160.25 52.4973C160.234 52.4989 160.219 52.4973 160.205 52.4929C160.19 52.4884 160.176 52.4811 160.165 52.4714C160.153 52.4616 160.143 52.4496 160.136 52.4361C160.129 52.4225 160.125 52.4077 160.123 52.3925C160.12 52.3611 160.129 52.3297 160.149 52.305C160.168 52.2803 160.197 52.2641 160.228 52.26C160.725 52.2232 161.208 52.0697 161.636 51.8118C162.064 51.5539 162.425 51.1988 162.692 50.7751C162.698 50.7605 162.707 50.7474 162.719 50.7368C162.731 50.7261 162.745 50.7182 162.76 50.7135C162.775 50.7087 162.791 50.7074 162.807 50.7094C162.823 50.7115 162.838 50.7169 162.851 50.7254C162.878 50.7406 162.899 50.7657 162.908 50.7955C162.917 50.8253 162.914 50.8575 162.901 50.8855C162.457 51.6224 161.745 52.157 160.915 52.3759Z" fill="#263238"/>
      <path d="M170.155 48.573C170.035 49.5229 169.544 50.3863 168.791 50.9742C167.785 51.747 166.817 50.9742 166.701 49.7653C166.602 48.6613 166.998 47.0053 168.197 46.6852C168.459 46.6317 168.729 46.6427 168.986 46.7174C169.242 46.792 169.477 46.928 169.669 47.1136C169.862 47.2993 170.007 47.5291 170.091 47.7833C170.176 48.0375 170.198 48.3085 170.155 48.573Z" fill="#FFC3BD"/>
      <path d="M162.164 44.3833C162.133 44.3911 162.102 44.3926 162.07 44.3879C162.039 44.3832 162.009 44.3722 161.982 44.3557C161.733 44.196 161.45 44.0977 161.155 44.0689C160.861 44.0402 160.564 44.0817 160.288 44.1901C160.231 44.2156 160.166 44.2184 160.107 44.1979C160.048 44.1774 159.999 44.1352 159.969 44.0797C159.943 44.0233 159.94 43.9586 159.96 43.8997C159.981 43.8408 160.024 43.7925 160.079 43.7651C160.426 43.6215 160.801 43.5636 161.175 43.5963C161.548 43.6289 161.908 43.7512 162.224 43.9527C162.251 43.9686 162.273 43.9895 162.291 44.0142C162.309 44.0389 162.322 44.0669 162.329 44.0967C162.337 44.1265 162.338 44.1574 162.333 44.1876C162.328 44.2179 162.317 44.2468 162.301 44.2729C162.271 44.3257 162.222 44.3651 162.164 44.3833Z" fill="#263238"/>
      <path d="M154.959 45.7577C154.902 45.7794 154.839 45.7794 154.783 45.7577C154.729 45.7264 154.69 45.6758 154.672 45.6163C154.655 45.5568 154.661 45.4928 154.689 45.4375C154.857 45.1016 155.103 44.8112 155.407 44.5912C155.71 44.3711 156.062 44.2278 156.433 44.1735C156.463 44.1704 156.494 44.1737 156.524 44.1829C156.553 44.1922 156.581 44.2073 156.604 44.2274C156.628 44.2474 156.647 44.2721 156.661 44.2998C156.675 44.3275 156.683 44.3578 156.686 44.3887C156.69 44.4508 156.67 44.5123 156.63 44.5598C156.59 44.6073 156.533 44.637 156.471 44.6427C156.179 44.6919 155.903 44.8096 155.665 44.9861C155.427 45.1625 155.234 45.3929 155.102 45.6583C155.086 45.684 155.065 45.7061 155.041 45.7232C155.016 45.7403 154.988 45.7521 154.959 45.7577Z" fill="#263238"/>
      <path d="M179.967 68.9414C181.089 71.6948 182.057 74.5089 182.866 77.3704C183.724 80.2569 184.335 83.2117 184.692 86.2024C184.785 86.9642 184.813 87.7646 184.846 88.554V89.0011C184.846 89.1612 184.818 89.3268 184.796 89.4703C184.751 89.7462 184.688 90.019 184.609 90.2872C184.466 90.7463 184.282 91.1915 184.059 91.6176C183.297 93.0328 182.362 94.3474 181.276 95.5312C179.269 97.7446 177.059 99.7646 174.676 101.565L173.087 99.7927C174.918 97.6454 176.766 95.4429 178.334 93.1687C179.091 92.1366 179.737 91.0263 180.259 89.8567C180.349 89.6303 180.422 89.3979 180.479 89.1612C180.494 89.081 180.503 88.9998 180.506 88.9183C180.509 88.8945 180.509 88.8704 180.506 88.8465V88.7692C180.435 88.1179 180.385 87.4665 180.264 86.7986C179.791 84.0717 179.131 81.3808 178.29 78.7449C177.448 76.0732 176.508 73.3629 175.54 70.7796L179.967 68.9414Z" fill="#FFC3BD"/>
      <path d="M177.905 63.5595C179.929 64.1777 182.36 71.1715 182.36 71.1715L174.5 76.802C174.5 76.802 171.635 70.9673 172.487 68.3398C173.4 65.6074 175.6 62.8529 177.905 63.5595Z" fill="#3D5A80"/>
      <path opacity="0.4" d="M177.905 63.5595C179.929 64.1777 182.36 71.1715 182.36 71.1715L174.5 76.802C174.5 76.802 171.635 70.9673 172.487 68.3398C173.4 65.6074 175.6 62.8529 177.905 63.5595Z" fill="black"/>
      <path d="M200.537 188.262C200.436 188.259 200.336 188.234 200.245 188.187C200.155 188.14 200.076 188.073 200.015 187.992C199.965 187.94 199.929 187.876 199.912 187.806C199.895 187.736 199.896 187.663 199.916 187.594C200.103 186.987 201.83 186.49 202.028 186.435C202.049 186.427 202.073 186.427 202.095 186.434C202.117 186.441 202.136 186.455 202.149 186.474C202.161 186.492 202.168 186.514 202.168 186.537C202.168 186.56 202.161 186.582 202.149 186.6C201.841 187.191 201.23 188.185 200.603 188.256L200.537 188.262ZM201.813 186.744C201.131 186.965 200.24 187.346 200.163 187.666C200.155 187.696 200.155 187.728 200.164 187.758C200.173 187.789 200.19 187.816 200.213 187.837C200.257 187.9 200.316 187.951 200.386 187.984C200.455 188.017 200.532 188.031 200.609 188.025C200.928 187.986 201.373 187.511 201.813 186.744Z" fill="#3D5A80"/>
      <path d="M201.269 186.766C200.754 186.819 200.239 186.673 199.828 186.358C199.77 186.285 199.739 186.194 199.739 186.101C199.739 186.008 199.77 185.917 199.828 185.844C199.877 185.768 199.942 185.703 200.019 185.655C200.096 185.608 200.183 185.578 200.273 185.568C200.988 185.48 202.072 186.418 202.116 186.457C202.132 186.47 202.144 186.487 202.151 186.507C202.157 186.526 202.159 186.547 202.154 186.567C202.152 186.589 202.143 186.609 202.13 186.626C202.116 186.643 202.098 186.655 202.077 186.661C201.814 186.736 201.542 186.772 201.269 186.766ZM200.394 185.8H200.317C200.261 185.807 200.207 185.825 200.158 185.855C200.11 185.884 200.068 185.924 200.037 185.971C199.954 186.098 199.982 186.165 200.037 186.209C200.251 186.49 201.23 186.595 201.824 186.485C201.418 186.131 200.923 185.895 200.394 185.8Z" fill="#3D5A80"/>
      <path d="M155.828 206.489C155.403 206.528 154.978 206.419 154.623 206.18C154.548 206.111 154.489 206.025 154.452 205.929C154.415 205.833 154.4 205.73 154.409 205.628C154.413 205.562 154.434 205.499 154.47 205.444C154.506 205.389 154.555 205.344 154.612 205.313C155.223 204.976 157.203 205.992 157.428 206.114C157.449 206.125 157.466 206.143 157.477 206.165C157.488 206.187 157.492 206.211 157.489 206.235C157.486 206.259 157.475 206.282 157.458 206.3C157.441 206.318 157.419 206.33 157.395 206.334C156.879 206.434 156.354 206.486 155.828 206.489ZM154.942 205.457C154.866 205.452 154.791 205.467 154.722 205.501C154.699 205.512 154.679 205.531 154.665 205.553C154.652 205.576 154.645 205.602 154.645 205.628C154.636 205.697 154.644 205.767 154.668 205.833C154.692 205.898 154.731 205.956 154.783 206.003C155.085 206.279 155.883 206.323 156.983 206.141C156.347 205.798 155.656 205.566 154.942 205.457Z" fill="#3D5A80"/>
      <path d="M157.373 206.329H157.323C156.729 206.059 155.558 204.982 155.673 204.419C155.673 204.287 155.789 204.115 156.113 204.082C156.231 204.07 156.349 204.082 156.462 204.117C156.574 204.152 156.678 204.21 156.768 204.287C157.389 204.811 157.505 206.152 157.51 206.213C157.512 206.233 157.508 206.254 157.5 206.272C157.491 206.291 157.478 206.306 157.461 206.318C157.448 206.326 157.433 206.331 157.418 206.333C157.403 206.335 157.388 206.334 157.373 206.329ZM156.185 204.303H156.119C155.899 204.303 155.888 204.414 155.882 204.441C155.827 204.778 156.625 205.628 157.23 205.992C157.181 205.425 156.958 204.887 156.592 204.452C156.481 204.35 156.336 204.291 156.185 204.287V204.303Z" fill="#3D5A80"/>
      <path d="M174.626 92.6167L175.143 94.4327C175.215 94.5707 175.05 94.7143 174.813 94.7143H154.029C153.847 94.7143 153.693 94.6259 153.682 94.51L153.501 92.6995C153.501 92.5725 153.644 92.4676 153.847 92.4676H174.302C174.364 92.4623 174.427 92.4732 174.484 92.4994C174.541 92.5255 174.59 92.5659 174.626 92.6167Z" fill="#3D5A80"/>
      <path opacity="0.3" d="M174.626 92.6167L175.143 94.4327C175.215 94.5707 175.05 94.7143 174.813 94.7143H154.029C153.847 94.7143 153.693 94.6259 153.682 94.51L153.501 92.6995C153.501 92.5725 153.644 92.4676 153.847 92.4676H174.302C174.364 92.4623 174.427 92.4732 174.484 92.4994C174.541 92.5255 174.59 92.5659 174.626 92.6167Z" fill="white"/>
      <path d="M171.882 94.9352H172.432C172.537 94.9352 172.619 94.88 172.614 94.8082L172.355 92.4512C172.355 92.3849 172.256 92.3242 172.146 92.3242H171.596C171.486 92.3242 171.404 92.3849 171.415 92.4512L171.668 94.8082C171.679 94.88 171.75 94.9352 171.882 94.9352Z" fill="#263238"/>
      <path d="M155.569 94.9352H156.119C156.229 94.9352 156.312 94.88 156.306 94.8082L156.048 92.4512C156.048 92.3849 155.943 92.3242 155.839 92.3242H155.289C155.179 92.3242 155.096 92.3849 155.102 92.4512L155.36 94.8082C155.366 94.88 155.459 94.9352 155.569 94.9352Z" fill="#263238"/>
      <path d="M163.726 94.9352H164.276C164.386 94.9352 164.468 94.88 164.463 94.8082L164.204 92.4512C164.204 92.3849 164.105 92.3242 163.995 92.3242H163.445C163.335 92.3242 163.253 92.3849 163.264 92.4512L163.5 94.8248C163.5 94.88 163.616 94.9352 163.726 94.9352Z" fill="#263238"/>
      <path d="M161.256 46.5524L160.271 46.3647C160.271 46.3647 160.832 47.0713 161.256 46.5524Z" fill="#263238"/>
      <path opacity="0.2" d="M143.051 116.844C133.733 120.477 123.551 121.256 113.792 119.081C104.033 116.907 95.1355 111.876 88.225 104.627C81.3144 97.377 76.7009 88.2336 74.9679 78.3524C73.235 68.4713 74.4603 58.2964 78.4891 49.1142C82.5178 39.932 89.169 32.1551 97.6016 26.7667C106.034 21.3782 115.869 18.6204 125.864 18.842C135.858 19.0635 145.562 22.2544 153.75 28.0111C161.937 33.7679 168.24 41.832 171.86 51.1838C174.266 57.3935 175.428 64.0179 175.282 70.6787C175.136 77.3395 173.684 83.9061 171.008 90.0035C168.333 96.1009 164.487 101.61 159.69 106.215C154.893 110.82 149.239 114.432 143.051 116.844Z" fill="#3D5A80"/>
      <path opacity="0.1" d="M133.905 19.6702L94.2608 110.033C93.6503 109.569 93.0453 109.089 92.4513 108.603C88.6196 105.422 85.2828 101.684 82.5513 97.5133L116.794 19.4605C122.467 18.5434 128.256 18.6144 133.905 19.6702Z" fill="white"/>
      <path opacity="0.1" d="M162.087 35.3467L124.813 120.305C117.071 120.313 109.431 118.534 102.483 115.105L143.183 22.3306C150.394 25.1607 156.861 29.6137 162.087 35.3467Z" fill="white"/>
      <path d="M83.1342 35.3855C89.8693 27.126 98.8935 21.0564 109.066 17.9442C119.238 14.8321 130.101 14.8172 140.282 17.9014C150.462 20.9856 159.503 27.0304 166.26 35.2714C173.018 43.5124 177.189 53.5795 178.246 64.1996C179.303 74.8198 177.198 85.5159 172.198 94.9355C167.198 104.355 159.527 112.075 150.155 117.119C140.784 122.163 130.132 124.305 119.547 123.274C108.963 122.242 98.9209 118.084 90.6912 111.324C79.6567 102.259 72.6616 89.1663 71.2444 74.9254C69.8272 60.6846 74.104 46.4619 83.1342 35.3855ZM162.95 100.941C169.132 93.3564 172.935 84.1023 173.877 74.3488C174.819 64.5953 172.858 54.7804 168.243 46.1453C163.627 37.5101 156.564 30.4425 147.946 25.8361C139.328 21.2298 129.543 19.2915 119.828 20.2664C110.113 21.2413 100.904 25.0856 93.3652 31.3132C85.8267 37.5408 80.2975 45.8721 77.4767 55.2534C74.6559 64.6348 74.6701 74.645 77.5177 84.0182C80.3652 93.3915 85.9181 101.707 93.4742 107.913C98.492 112.034 104.269 115.123 110.477 117.003C116.684 118.883 123.199 119.517 129.651 118.87C136.103 118.222 142.364 116.306 148.077 113.23C153.791 110.153 158.845 105.978 162.95 100.941Z" fill="#3D5A80"/>
      <path d="M167.697 100.754C172.993 103.928 178.031 107.416 182.981 111.015C187.931 114.614 192.755 118.362 197.501 122.21C202.248 126.057 206.906 130.015 211.4 134.172C213.651 136.247 215.851 138.378 218 140.564C220.156 142.772 222.273 144.98 224.27 147.375C224.731 147.93 224.985 148.629 224.989 149.351C224.992 150.074 224.745 150.775 224.289 151.334C223.834 151.893 223.198 152.276 222.492 152.416C221.786 152.556 221.053 152.444 220.42 152.101C217.67 150.61 215.096 148.965 212.527 147.282C209.959 145.598 207.451 143.843 204.976 142.038C200.026 138.439 195.235 134.646 190.544 130.733C185.852 126.819 181.232 122.817 176.739 118.655C172.245 114.493 167.84 110.231 163.693 105.65C163.175 105.083 162.878 104.348 162.856 103.579C162.835 102.811 163.09 102.06 163.575 101.465C164.06 100.87 164.743 100.471 165.498 100.34C166.253 100.209 167.03 100.356 167.686 100.754H167.697Z" fill="#3D5A80"/>
      <path d="M173.615 99.2407L168.302 100.052L172.102 103.397C172.102 103.397 175.105 102.293 175.402 100.251L173.615 99.2407Z" fill="#FFC3BD"/>
      <path d="M165.431 103.116L168.769 105.744L172.845 103.072L168.302 100.063L165.431 103.116Z" fill="#FFC3BD"/>
    </g>
    <defs>
      <clipPath id="clip0_9993_32508">
        <rect width="246" height="230" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default ProfileNotFoundIllustrationSvg;