import { EditCircle, EditJob, EditPostIcon, MailIcon, Pencil } from 'components/common/Icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getOnboardingData, setOnboardingData } from 'store/slices/onboardingSlice';
import OnboardingWrapper from '../Components/OnboardingWrapper';
import { onboardingSteps } from '../constant';
import ContactModal from './Modal';
import Button from 'components/common/Button';
import { getIntegrationURL } from 'api/google-integration';
import { useMutation } from '@tanstack/react-query';

const currStep = onboardingSteps[4];
const nextStep = onboardingSteps[5];
const currActiveSteps = [
  onboardingSteps[0].id,
  onboardingSteps[1].id,
  onboardingSteps[2].id,
  onboardingSteps[3].id,
  onboardingSteps[4].id,
];

const OnboardingContact = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { currStepData, externalAction } = useSelector((state) => state.onboarding);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [edit, setEdit] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setEmail(userProfileInfo?.email);
  }, [userProfileInfo]);

  useEffect(() => { 
    if (externalAction) { 
      navigate('/onboarding/connect');
    }
  }, [externalAction])

  useEffect(() => {
    const payload = {
      step: currStep.name,
    };
    dispatch(setOnboardingData(payload));
  }, []);

  const handleNext = () => {
    setOpen(true);
  };

  const handleBack = () => {
    navigate(onboardingSteps[3].url);
  };
  const { mutate, isLoading } = useMutation(getIntegrationURL, {
    onSuccess: (data) => {
      const { url = null } = data?.data;
      if (url) {
        window.open(url, 'mywin', 'width=500,height=500');
      }
      setTimeout(() => {
        navigate('/onboarding/connect');
      }, 6000);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleClick = (e) => {
    e.preventDefault();
    mutate();
  };
  
  return (
    <>
      <OnboardingWrapper activeSteps={currActiveSteps}>
        <h3> Let's add your email contacts to see who you already know on myBridge</h3>
        <p className="mb-3">
        We'll periodically import and store your contacts to suggest connections and show you relevant updates. You control who you connect to, and you can manage your contacts anytime.
        </p>

        {/* <form> */}
        <div className={'editable-input-field custom-react-select'}>
          <MailIcon />
          <input
            value={email}
            onBlur={() => setEdit(false)}
            ref={ref}
            readOnly={edit ? false : true}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className={edit ? ' edit' : ''}
            placeholder="Enter here..."
          />
          {/* {!edit && <Pencil onClick={() => {
              setEdit(true)
              ref.current.focus()
            }} className="right-icon" />} */}
        </div>

        <Button className="w-100 mt-3" onClick={handleClick}>
          Continue
        </Button>
        {/* </form> */}

        <ul className="additional-actions justify-content-between">
          <li onClick={handleBack}>Back</li>
          <li onClick={handleNext}>Skip</li>
        </ul>
      </OnboardingWrapper>

      {open && <ContactModal user={userProfileInfo} onClose={() => setOpen(false)} />}
    </>
  );
};

export default OnboardingContact;
