import React, { useEffect, useState } from 'react';
import OnboardingWrapper from '../Components/OnboardingWrapper';
import { onboardingSteps } from '../constant';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOnboardingData,
  setOnboardingData,
  getOnboardingStatus,
  clearOnboardingData,
} from 'store/slices/onboardingSlice';
import { userProfileAddInfo, getUserProfileInfo } from 'store/slices/userProfileSlice';
import Button from 'components/common/Button';
import GlobalCompanyDropdown from 'components/common/GlobalCompanyDropdown';
import GlobalUniversityDropdown from 'components/common/GlobalUniversityDropdown';
import _ from 'lodash';
import { logout } from 'store/slices/authSlice';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

const currStep = onboardingSteps[1];
const nextStep = onboardingSteps[2];
const currActiveSteps = [onboardingSteps[0].id, onboardingSteps[1].id];

const arr = [
  { label: 'Full-Time', value: 'FT' },
  { label: 'Part-Time', value: 'PT' },
  { label: 'Contractor', value: 'C' },
  { label: 'Temporary', value: 'T' },
  { label: 'Other', value: 'O' },
];

const OnboardingEmployment = () => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { onboardingStatus, currStepData } = useSelector((state) => state.onboarding);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const [student, setStudent] = useState(false);
  const [employment, setEmployment] = useState({
    title: '',
    employment_type: '',
    company_name: '',
  });
  const [studentData, setStudentData] = useState({
    collage: '',
    start_year: '',
    end_year: '',
    eligibility: true,
    dob: '',
  });

  const getYears = () => {
    let currentYear = new Date().getFullYear() + 10;
    let earliestYear = 1970;
    let arr = [];
    while (currentYear >= earliestYear) {
      arr.push({ label: currentYear, value: currentYear });
      currentYear -= 1;
    }

    return arr;
  };

  useEffect(() => {
    const yearsArr = getYears();
    setYears(yearsArr);
  }, []);

  useEffect(() => {
    if (userProfileInfo) {
      const payload = {
        step: currStep?.name,
        emailId: userProfileInfo?.email,
      };
      dispatch(getOnboardingStatus(payload));
      dispatch(getOnboardingData(payload));
    }
  }, [userProfileInfo]);

  const getEmploymentStatus = () => {
    const { title, company_name, employment_type } = employment;
    return !isEmpty(title) && !isEmpty(company_name) && !isEmpty(employment_type);
  };

  const getStudentStatus = () => {
    const { name, start_year, end_year, eligibility, month, day, year } = studentData;
    const temp = !isEmpty(name) && !isEmpty(start_year) && !isEmpty(end_year);
    const isEligible = eligibility ? temp : temp;
    return isEligible;
  };

  const getEndYear = () => {
    const arr = cloneDeep(years);
    const startYear = studentData?.start_year;
    const updatedYears = arr.filter((el) => startYear < el.value);
    const endYearArr = isEmpty(studentData.start_year) ? years : updatedYears;
    return endYearArr.map((year) => ({ label: year.name, value: year.value, ...year }));
  };

  const getStartYear = () => {
    const arr = cloneDeep(years);
    // const startYear = studentData?.end_year?.value;
    // const updatedYears = arr.filter((el) => startYear > el.value);
    // const yearsArr = isEmpty(studentData.end_year) ? arr : updatedYears;
    // console.log('year Value', yearsArr);
    return arr.map((year) => ({ label: year.name, value: year.value, ...year }));
  };

  useEffect(() => {
    getEmploymentStatus();
  }, [employment]);

  useEffect(() => {
    getStudentStatus();
  }, [studentData]);

  const handleBack = () => {
    navigate(onboardingSteps[0].url);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      step: currStep.name,
      json: true,
      isFormData: false,
      emailId: userProfileInfo.email,
    };

    const workingProfessionalPayload = {
      ...obj,
      data: {
        title: employment?.title,
        employment_type: employment?.employment_type?.label
          ? employment?.employment_type?.label
          : employment?.employment_type,
        company_name: employment?.company_name?.label ? employment?.company_name?.label : employment?.company_name,
        is_student: student,
      },
    };

    const studentPayload = {
      ...obj,
      data: {
        name: studentData.name,
        start_year: studentData?.start_year,
        end_year: studentData?.end_year,
        is_student: student,
        eligibility:studentData.eligibility,
        dob:studentData.eligibility ? moment.utc().format('YYYY-MM-DD') : moment.utc(studentData.dob).format('YYYY-MM-DD')
      },
    };

    if (student) {
      dispatch(setOnboardingData(studentPayload)).then((res) => {
        if (res?.payload?.status_code === 200) {
          if (userProfileInfo.is_active) {
            navigate(nextStep.url);
          } else {
            navigate('/email-verify');
          }
        }
      });
    } else {
      const formData = new FormData();
      formData.append('position', employment?.title);
      dispatch(userProfileAddInfo(formData)).then((res) => {
        dispatch(getUserProfileInfo());
      });
      dispatch(setOnboardingData(workingProfessionalPayload)).then((res) => {
        if (res?.payload?.status_code === 200) {
          navigate(nextStep.url);
          if (userProfileInfo.is_active) {
            navigate(nextStep.url);
          } else {
            navigate('/email-verify');
          }
        }
      });
    }
  };

  const isValid = !student ? getEmploymentStatus() : getStudentStatus();

  useEffect(() => {
    if (currStepData?.data) {
      if (currStepData.is_student === true) {
        setStudentData(currStepData?.data);
        setStudent(true);
      } else {
        setEmployment(currStepData?.data);
      }
    }
  }, [currStepData, onboardingStatus]);

  return (
    <OnboardingWrapper activeSteps={currActiveSteps}>
      <h3>{!student ? `Next, let's expand on your career details.` : `Next, let's establish your background.`}</h3>
      {/* <p>
        Your profile helps you discover new people and opportunities
      </p> */}

      <form onSubmit={handleSubmit}>
        {!student && (
          <>
            <div className="custom-react-select">
              <label>
                Most recent job title<sup>*</sup>
              </label>
              <input
                placeholder="Type...."
                name="title"
                value={employment?.title}
                onChange={(e) => {
                  setEmployment((prev) => {
                    return {
                      ...prev,
                      title: e?.target?.value,
                    };
                  });
                }}
              />
            </div>

            <div className="custom-react-select">
              <label>
                Employment type<sup>*</sup>
              </label>
              <Select
                id="employment_type"
                name="employment_type"
                placeholder="Select one"
                value={arr.filter(function (option) {
                  const employmentType = employment?.employment_type?.label
                    ? employment?.employment_type?.label
                    : employment?.employment_type;
                  return option.label === employmentType;
                })}
                options={arr}
                onChange={(value) => {
                  setEmployment((prev) => {
                    return {
                      ...prev,
                      employment_type: value,
                    };
                  });
                }}
              />
            </div>

            <div className="custom-react-select">
              <label>
                Most Recent Company<sup>*</sup>
              </label>

              <GlobalCompanyDropdown
                id="company"
                name="company"
                selectedValue={
                  employment?.company_name?.label ? employment?.company_name?.label : employment?.company_name
                }
                onChange={(value) => {
                  setEmployment((prev) => {
                    return {
                      ...prev,
                      company_name: value,
                    };
                  });
                }}
              />
            </div>
          </>
        )}

        {student && (
          <>
            <div className="custom-react-select mb-3">
              <label>
                School or College/University<sup>*</sup>
              </label>
              <GlobalUniversityDropdown
                id="collegeName"
                name="name"
                selectedValue={studentData?.name?.label ? studentData?.name?.label : studentData?.name}
                onChange={(e) =>
                  setStudentData((prev) => {
                    return {
                      ...prev,
                      name: e.value,
                    };
                  })
                }
              />
              {/* <input
                value={studentData.name}
                onChange={(e) => setStudentData((prev) => {
                  return {
                    ...prev,
                    collage: e?.target?.value
                  }
                })}
                placeholder='School or College/University'
              /> */}
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <div className="custom-react-select">
                  <label>
                    Start year<sup>*</sup>
                  </label>
                  <Select
                    id="start_year"
                    name="start_year"
                    placeholder="Start year"
                    value={getStartYear().filter(function (option) {
                      return option.label == studentData?.start_year;
                    })}
                    onChange={(value) => {
                      setStudentData((prev) => {
                        return {
                          ...prev,
                          start_year: value.label.toString(),
                        };
                      });
                    }}
                    options={getStartYear()}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="custom-react-select">
                  <label>
                    End year(or expected)<sup>*</sup>
                  </label>
                  <Select
                    id="end_year"
                    name="end_year"
                    placeholder="End Year"
                    options={getEndYear()}
                    value={getEndYear().filter(function (option) {
                      return option.label == studentData?.end_year;
                    })}
                    onChange={(value) => {
                      setStudentData((prev) => {
                        return {
                          ...prev,
                          end_year: value.label.toString(),
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="eligibility">
              <p>I'm over 13</p>

              <div className="d-flex align-items-center">
                {!studentData.eligibility && <p className="me-2">No</p>}

                <div className="custom-switch">
                  <input
                    checked={studentData.eligibility ? true : false}
                    onClick={() =>
                      setStudentData((prev) => {
                        return {
                          ...prev,
                          eligibility: !prev.eligibility,
                        };
                      })
                    }
                    type="checkbox"
                  />
                  <span></span>
                </div>
              </div>
            </div>

            {!studentData.eligibility && (
              <>
                <div className="custom-react-select mt-3 mb-3">
                  <label>
                    <strong>Date of birth</strong>
                  </label>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="date-wrapper form-group mb-0">
                      <ReactDatePicker
                      selected={studentData.dob}
                        onChange={(date) => {
                          setStudentData((prev) => {
                            return {
                              ...prev,
                              dob: date,
                            };
                          });
                        }}
                        className="date-selector form-control"
                        dateFormat="YYYY-MM-DD"
                        showYearDropdown
                        showMonthDropdown
                      />
                    </div>
                  </div>
                </div>

                <div className="additional-text">
                  We love having ambitious people like you on myBridge, but you’re currently under our age limit
                </div>
              </>
            )}
          </>
        )}

        <div className="student-toggle">
          <span onClick={() => setStudent((prev) => !prev)}>{student ? 'I’m not a student' : 'I’m a student'}</span>
        </div>

        {!studentData.eligibility ? (
          <Button onClick={() => handleLogout()} className="w-100 mt-3">
            Finish
          </Button>
        ) : (
          <>
            <Button type="submit" disabled={!isValid} className="w-100 mt-3">
              Next
            </Button>
            <ul className="additional-actions justify-content-center">
              <li onClick={handleBack}>Back</li>
            </ul>
          </>
        )}
      </form>
    </OnboardingWrapper>
  );
};

export default OnboardingEmployment;
