import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

// export const getIntegrationURL = async () => {
//   try {
//     const obj = {
//       url: APIEndPoints.OUTLOOK_INTEGRATION_CONTACT,
//       method: 'POST',
//     };
//     const response = await API(obj);
//     return response;
//   } catch (error) {
//     throw new Error(error.message);
//   }
// };

export const getIntegrationCallBack = async ({ queryKey }) => {
  try {
    const queryString = queryKey[1];
    const obj = {
      url: APIEndPoints.OUTLOOK_INTEGRATION_CALLBACK(queryString),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// export const getCalendarIntegrationURL = async () => {
//   try {
//     const obj = {
//       url: APIEndPoints.OUTLOOK_INTEGRATION,
//     };
//     const response = await API(obj);
//     return response;
//   } catch (error) {
//     throw new Error(error.message);
//   }
// };
