import React, { useEffect } from 'react';
import UserCard from './UserCard';
import { CircleIcon } from 'components/common/Icons';
import { Link } from 'react-router-dom';
import Loader from 'components/common/Loader';
import { getConnectionsData } from 'store/slices/connectionSlice';
import { useDispatch, useSelector } from 'react-redux';

const Circles = () => {
  const { loading, data , refetchData } = useSelector((state) => state.connection);
  const dispatch = useDispatch();


  useEffect(()=>{
    if(refetchData){
      dispatch(getConnectionsData());
    }
  },[refetchData])

  const renderData = () => {
    let temp;

    if (loading) {
      temp = <Loader secondary />;
    } else if (data?.results?.length < 1) {
      temp = <p>No Connections Found!</p>;
    } else {
      temp = data?.results?.map((user) => <UserCard user={user} key={user.id} />);
    }

    return temp;
  };

  return (
    <>
      {data?.results?.length > 0 && (
        <div className="my-circle mb-4">
          <div className="my-circle--header">
            <h3>
              <CircleIcon className="me-2" />
              Circle
            </h3>
          </div>

          <div className="my-circle--body scrollbar">{renderData()}</div>

          {data?.results?.length > 6 && (
            <div className="my-circle--footer">
              <Link to="/profile/network">See all in Network</Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Circles;
