import { CometChat } from "@cometchat-pro/chat"

export const sanitizeBeforeUpdate = (data) => {
    // remove slug from the update
    if (data.slug) {
        delete data.slug
    }
    // remove logo and banners if not updated (i.e. type is still string and not file/binary)
    if (data.image_logo && typeof data.image_logo === "string") {
        delete data.image_logo
    }
    if (data.banner_image && typeof data.banner_image === "string") {
        delete data.banner_image
    }
    // remove disk_payer field if not a pk (i.e. still a string that was saved earlier)
    if (data.disk_payer && typeof data.disk_payer === "string") {
        delete data.disk_payer
    }
    // flatten industry_type field to include ids only
    if (data.industry_name && data.industry_name.length) {
        data.industry_name = data.industry_name.map(indus => indus?.id ?? indus)
    }
    if (data.organizers) {
        delete data.organizers
    }
    if (data.degree_count) {
        delete data.degree_count
    }
    return data
}

/**
 * Send a comet chat post/message based on type
 * @param {*} opts 
 * @param {*} cb 
 */
export const sendGroupPost = (opts, success, error) => {
    const { receiverID, senderId, messageType, text, gif, image } = opts ?? {}
    const receiverType = CometChat.RECEIVER_TYPE.GROUP;
    if (messageType === 'Text') {
        const messageText = text;
        const textMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);
        textMessage.setSender(senderId)
        CometChat.sendMessage(textMessage).then(
            (message) => {
                console.log('Message sent successfully:', message);
                success?.(message)
            },
            (error) => {
                console.log('Message sending failed with error:', error);
                error?.(error)
            },
        );
    } else if (messageType === 'Gif') {
        const customData = {
            gif_url: gif?.url,
            gif_name: gif?.name,
        };
        const customMessage = new CometChat.CustomMessage(
            receiverID,
            receiverType,
            "gif",
            customData
        );
        customMessage.setSender(senderId)
        CometChat.sendCustomMessage(customMessage).then(
            (msg) => {
                console.log('Message sent successfully:', msg);
                success?.(msg)
            },
            (error) => {
                console.log('Message sending failed with error:', error);
                error?.(error)
            },
        );
    } else if (messageType === 'Image') {
        const mediaMessage = new CometChat.MediaMessage(receiverID, image, CometChat.MESSAGE_TYPE.IMAGE, receiverType);
        mediaMessage.setCaption(text);
        mediaMessage.setSender(senderId)
        CometChat.sendMediaMessage(mediaMessage).then(
            (msg) => {
                console.log('Message sent successfully:', msg);
                success?.(msg)
            },
            (error) => {
                console.log('Message sending failed with error:', error);
                error?.(error)
            },
        );
    }
}