export const APIEndPoints = {
  Certificate: 'api/v1/user/profile/certificate',
  Milestone: 'api/v1/user/profile/milestone',
  UserProfileInfo: 'api/v1/user/profile/info',
  ProfileExperience: 'api/v1/user/profile/experience',
  InactiveUserChangeEmail: 'api/v1/user/change-email',
  ProfileActivity: 'api/v1/user/activity?page=1&page_size=1',
  ProfileSkills: 'api/v1/user/profile/skills/',
  UserAnalytics: 'api/v1/user/analytics',
  ResetPassword: 'api/v1/password_resetconfirm',
  Forgotpassword: 'api/v1/password_reset',
  GoogleSign: 'api/v1/google/register',
  SignIn: 'api/token',
  Register: 'api/v1/register',
  Education: 'api/v1/user/profile/education',
  Universities: 'api/v1/universities',
  School: 'api/v1/schools',
  Course: 'api/v1/courses',
  Degree: 'api/v1/degrees',
  Industries: 'api/v1/gd/industries',
  Companies: 'api/v1/companies',
  Certificates: 'api/v1/certificates',
  CertAuthorities: 'api/v1/cert_authorities',
  locations: 'api/v1/locations',
  Skills: 'api/v1/gd/skills',
  Connections: 'api/v1/user/connection',
  // MyDisk_Root: 'api/v1/disk/dir/?dir_name=root',
  MyDisk_Root: 'api/v1/disk/dir/',
  MyDisk: 'api/v1/disk/dir/',
  TeamDisk: 'api/v1/disk/team/dir/',
  MyDisk_Folder: 'api/v1/disk/dir/',
  MyDisk_Files: 'api/v1/disk/files/',
  MyDisk_Search: 'api/v1/disk/search/',
  shareDisk: 'api/v1/shared-disk/',
  shareDiskUser: 'api/v1/shared-disk/sharee/',
  AllConnections: 'api/v1/user/profile/connection/',
  UserDetailsByUuid:'api/v1/user/get_details/',
  BlockConnection: 'api/v1/user/connection/block',
  ReportConnection: 'api/v1/user/connection/report',
  RequestConnection: 'api/v1/user/connection',
  Invitations: (type) => `api/v1/user/connection/manage-invite/?list_type=${type}`,
  Suggestions: (type) => `api/v1/user/connection/manage-invite/suggestion?filter_type=${type}`,
  // Post: 'api/v1/posts',
  getPost: (id) => 'api/v1/post/' + id + '/detail',
  Post: (page = 1, size = 10) => 'api/v1/posts?page_size=' + size + '&page=' + page,
  MyActivity: (page = 1, size = 10) => 'api/v1/user/activity?page_size=' + size + '&page=' + page,
  CompanyPost: (page = 1, size = 10, companyId) =>
    'api/v1/posts?page_size=' + size + '&page=' + page + '&company_id=' + companyId,
  CompanyPostPublic: (page, size, companyId) =>
    'api/v1/posts?page_size=' + size + '&page=' + page + '&company_id=' + companyId,
  NewPost: 'api/v1/post/create',
  AllEvents: 'api/v1/meeting',
  AcceptEvent: (postId) => 'api/v1/event/' + postId + '/accept',
  IgnoreEvent: (postId) => 'api/v1/event/' + postId + '/ignore',
  GetEvents: (page, size = 20) => `api/v1/event/list?page_size=${size}&page=${page}`,
  AllMeetings: 'api/v1/meetingwithdraft',
  UpcomingEvents: (date) => 'api/v1/meeting/upcoming?limit=3&date=' + date,
  SharedConnections: (id) => 'api/v1/user/' + id + '/connection/mutual',
  PostDislike: (id) => `api/v1/post/${id}/dislike`,
  PostLike: (id) => 'api/v1/post/' + id + '/like',
  PostRepost: (id) => 'api/v1/repost/' + id,
  TodayEvents: (date) => 'api/v1/meeting?year=' + date.year + '&month=' + date.month + '&day=' + date.date,
  Accounts: 'api/v1/user/settings/update',
  Email: 'api/v1/user/emails',
  PrimaryEmail: 'api/v1/user/emails/primary',
  Deactivate: 'api/v1/user/deactivate',
  Activate: 'api/v1/activate',
  VerifyEmail: 'api/v1/user/emails/verify',
  changePassword: 'api/v1/user/change-password',
  mobileVerifyCode: 'api/v1/user/mobile/verify',
  Teams: 'api/v1/user/team/',
  Companies_Page: 'api/v1/user/company/page/',
  Pages: 'api/v1/user/company/page/',
  Slug: 'api/v1/slug/',
  Testimonial: 'api/v1/testimonial/',
  Follow: (type) => `api/v1/user/follow/?follow_type=${type}`,
  Follower: 'api/v1/user/follower',
  PaymentCards: 'api/v1/user/payment/card/',
  newMeeting: 'api/v1/meeting',
  proposeNewTime: (meetingId) => `api/v1/meeting/${meetingId}/propose`,
  acceptProposeNewTime: ({ meetingId, attendeeId }) => `api/v1/meeting/${meetingId}/proposal/${attendeeId}/accept`,
  monthlyCalendarEvents: (month, year) => 'api/v1/meeting?month=' + month + '&year=' + year,
  updateAttendeeStatus: (meetingId, id) => 'api/v1/meeting/' + meetingId + '/attendee/' + id + '/update',
  fetchingAllCalendarEvents: (url) => 'api/v1/meeting?page=' + url,
  PublicProfile: 'api/v1/user/profile/public/',
  PostDelete: (id) => 'api/v1/post/' + id,
  PinPost: (id) => 'api/v1/post/' + id + '/pin',
  UpdatePost: (id) => 'api/v1/post/' + id + '/edit',
  IgnorePost: (id) => 'api/v1/post/' + id + '/ignore',
  GetStatus: (email) => 'api/v1/user/onboarding/?email=' + email,
  SetOnboardingData: (currStep, email) => 'api/v1/user/onboarding/?step=' + currStep + '&email=' + email + '',
  getConnections: 'api/v1/user/connection',
  Notification: 'api/v1/notifications',
  Resend: (email) => 'api/v1/user/onboarding/?step=resend_mail&email=' + email,
  Search: (query) => `api/v1/search/?query=${query}`,
  SearchItem: (searchType) =>
    `api/v1/search/${searchType.item}/?query=${searchType.searchQuery ?? ''}${
      searchType.query ? `${searchType.query}` : ''
    }`,
  CreateBlog: 'api/v1/post/create',
  // Notification: 'api/v1/notifications',
  TeamCommunity: 'api/v1/team/',
  TeamCommunityDisk: 'api/v1/team/disk/subscription/',
  TeamChat: 'api/v1/team_chat/posts/',
  listBlog: 'api/v1/blog/list?status=1',
  publishBlog: (query) => `api/v1/blog/${query}/publish`,
  editBlog: (query) => `api/v1/post/${query}/edit`,
  listPublished: 'api/v1/blog/list?status=2',
  diskSubscription: 'api/v1/disk/subscription',
  diskSize: 'api/v1/disk/size/',
  //? Integration urls
  GOOGLE_INTEGRATION: 'api/v1/integration/google/',
  GOOGLE_INTEGRATION_CONTACT: 'api/v1/user/onboarding/?step=contact&email="',
  GOOGLE_INTEGRATION_CALLBACK: (querySting) => `api/v1/integration/google/callback/?${querySting}`,
  GOOGLE_EVENT: 'api/v1/event/google/',

  OUTLOOK_INTEGRATION_CONTACT: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  OUTLOOK_INTEGRATION_CALLBACK: (querySting) => `api/v1/integration/microsoft/callback/?${querySting}`,
  OUTLOOK_INTEGRATION: 'api/v1/integration/microsoft/',
  OUTLOOK_EVENT: 'api/v1/event/microsoft/',
  GetNews: (pageNo, limitPerPage) => `api/v1/news/list?page=${pageNo}&page_size=${limitPerPage}`,
  BookmarkBlog: (id) => `api/v1/blog/${id}/bookmark`,
  BookMarkRemove: (id) => `api/v1/blog/${id}/bookmark`,
  InviteConnectionsToFollowCompany: 'api/v1/company/page/follow/invite',
  meetingIDAPI: 'api/v1/meeting' 
};

export const UserAPIEndpoints = {
  GetDegreeCount: (degree) => `api/v1/user/connections/?degree_num=${degree}&limit=0`,
  GetProfileViews: `api/v1/user/profile/viewed/`,
  UpdateUser: 'api/v1/user/settings/update',
  UpdatePassword: 'api/v1/user/change-password',
  ActivateUser: 'api/v1/activate',
  DeactivateUser: 'api/v1/user/deactivate',
};

export const CompanyAPIEndpoints = {
  CompanyJobsAPI: 'api/v1/jobs',
  JobSearchAPI: 'api/v1/search/jobs',
  JobPreferences: 'api/v1/job',
  CompaniesAPI: 'api/v1/user/company/page/',
  JobSubscriptionCheckout: 'api/v1/user/payment/subscription/',
  UserJobSubscriptions: 'api/v1/user/subscription/',
  updateCompanyInfo: (id) => `api/v1/user/company/page/${id}/`,
  AnalyticsApi: (id) => `api/v1/page/analytics/${id}`,
  FollowersApi: (id) => `api/v1/user/company/page/follower-list/?company_id=${id}`,
  FollowCompanyApi: (id) => `api/v1/user/company/page/follow/?company_id=${id}`,
  UnFollowCompanyApi: (id) => `api/v1/user/company/page/unfollow/?company_id=${id}`,
  PublicCompaniesAPI: (slug) => `api/v1/user/company/page/detail/?slug=${slug}`,
  CompanyDelete: (id) => `api/v1/user/company/page/${id}/`,
};

export const CommentAPIEndpoints = {
  Comments: (id) => 'api/v1/post/' + id + '/comments',
  CreateComment: (id) => 'api/v1/post/' + id + '/comment/create',
  EditComment: (id) => 'api/v1/post/comment/' + id + '/edit',
  CommentLike: (id) => 'api/v1/post/comment/' + id + '/like',
  CommentDisLike: (id) => 'api/v1/post/comment/' + id + '/dislike',
  CommentDelete: (id) => 'api/v1/post/comment/' + id + '/delete',
};

export const GlobalAPIEndpoints = {
  GetCountries: (page, search) => `api/v1/gd/countries/?page=${page}&search=${search}`,
  GetStates: (page, search, country_code) => `api/v1/gd/states/?page=${page}&search=${search}&country_code=${country_code}`,
  GetCities: (search, state_code) => `api/v1/gd/cities/?search=${search}&state_code=${state_code}`,
  GDCompanies: `api/v1/gd/companies/`,
  GetCompanies: (page, search) => `api/v1/gd/companies/?page=${page}&search=${search}`,
  GetCompanyTypes: (page, search) => `api/v1/gd/company_type/`,
  GetCompanySize: (page, search) => `api/v1/gd/company_size/`,
  GetSkills: (page, search) => `api/v1/gd/skills/?page=${page}&search=${search}`,
  GetSchools: (page, search) => `api/v1/gd/schools/?page=${page}&search=${search}`,
  GetColleges: (page, search) => `api/v1/gd/universities/?page=${page}&search=${search}`,
  GetCertificates: (page, search) => `api/v1/gd/certificates/?page=${page}&search=${search}`,
  GetCertificateAuthorities: (page, search) => `api/v1/gd/cert_authorities/?page=${page}&search=${search}`,
  GetCourses: (page, search) => `api/v1/gd/courses/?page=${page}&search=${search}`,
  GetDegrees: (page, search) => `api/v1/gd/degrees/?page=${page}&search=${search}`,
  GetIndustries: (page, search) => `api/v1/gd/industries/`,
  GetLanguages: (page, search) => `api/v1/gd/language/`,
};
