import React from 'react';
import { Camera } from 'components/common/Icons';
import styles from './style.module.scss';
import DeletePostModal from 'pages/Dashboard/Feed/DeletePostModal';
import PostContext from 'context/PostContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Flex, Text } from 'components/core';
import SaveArticleButton from './SaveArticleButton';
import { getNewsArticles } from 'store/slices/blogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getPostFunc } from 'api/post';
import { Button } from 'react-bootstrap';

const Card = ({ item, readonly }) => {
  const [blogState, setBlogState] = React.useState(item);
  const { author, blog_type = 1, blog_status, bookmark_user_ids = [] } = blogState;
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  // eslint-disable-next-line no-unused-vars
  const name = author?.first_name + ' ' + author?.last_name;
  const queryClient = useQueryClient();
  const { status } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogId = item?.id;

  const { refetch } = useQuery(['getBlogDetail', blogId], getPostFunc, {
    refetchOnWindowFocus: false,
    enabled: false,
    initialData: () => {
      const blogs = queryClient.getQueryData(['getBlogsByStatus', status]);
      const blog = blogs?.find((blog) => blog?.id?.toString() === blogId?.toString());
      return blog;
    },
    onSuccess: (data) => {
      setBlogState(data);
    },
  });

  // console.log('queryInfo', queryInfo.data);

  const [deleted, setDeleted] = React.useState(false);
  const isBookmarked = bookmark_user_ids.find((id) => id?.toString() === userProfileInfo?.id?.toString());

  return (
    <div key={item.id} className={styles.draftItem}>
      {item.cover_image && (
        <div className={styles.draftImage}>
          {item.cover_image ? <img loading='lazy' height="200" src={item.cover_image || Camera} alt="" /> : <Camera />}
        </div>
      )}
      <div className={styles.draftDescr}>
        <Link to={`/blog/detail/${blogId}?blogType=${blog_type}`}>
          <h6> {item.title || 'Untitled'}</h6>
        </Link>
        {/* <div className={styles.subtitle}> {name} on my bridge </div> */}
        <p className={styles.descDraftText} dangerouslySetInnerHTML={{ __html: item.description }}></p>
        {!readonly && <button onClick={() => navigate(`/blog/detail/draft/${blogId}`)} className={styles.readMore}>
          Read more
        </button>}
        {/* <span className={styles.subtext}> {moment(item.updated_at).format('MMM Do YYYY')}</span> */}
        <Flex className={readonly && 'mt-3'} justify="space-between" align="center">
          {
            readonly ?
              <Button className="btn-sm btn-secondary" onClick={() => navigate(`/blog/detail/draft/${blogId}`)}>View Details</Button>
              : <div className={styles.actionIcons}>
                <span className={styles.edit} onClick={() => navigate(`/blog/new/${blogId}?blogType=${blog_type}`)}>
                  Edit
                </span>
                <span
                  className={styles.deleteButton}
                  onClick={() => {
                    setDeleted(true);
                  }}
                >
                  Delete
                </span>
              </div>
          }
          {blog_type === 2 ? (
            <Text color="#00875A" size="15px" lineHeight="20px" weight="600">
              Admin
            </Text>
          ) : (
            <SaveArticleButton
              saved={Boolean(isBookmarked)}
              blogId={blogId}
              onBookMarkAction={() => {
                refetch();
              }}
            />
          )}
        </Flex>
        {deleted && (
          <PostContext.Provider value={{ postData: item }}>
            <DeletePostModal
              onClose={() => {
                setDeleted(false);
                queryClient.invalidateQueries(['getBlogsByStatus', status]);
                dispatch(getNewsArticles({ pageNo: 1, limitPerPage: 5 }));
              }}
            />
          </PostContext.Provider>
        )}
      </div>
    </div>
  );
};
export default Card;
