import React, { useState, useEffect } from 'react';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';

import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { CometChat } from '@cometchat-pro/chat';
import { updateUserRole, removeTeamMember } from 'store/slices/teamCommunitySlice';
import { Spinner } from 'components/common/Loader';

const PendingsRequestsCard = (props) => {
  const { user, isSearchResult, type } = props;
  const { teamId, groupId, loading, isRemovingTeamMember, teamSlugPage } = useSelector((state) => state.teamsCommunity);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardRoute = location.pathname.includes('/');
  const userName = user?.first_name + ' ' + user?.last_name;
  const teamGroupId = `teams_${teamSlugPage?.slug}`;


  const updateUserStatus = (user, action) => {
    const body = {
      "for_team_community_id": teamId,
      "user_id": user.id,
      "user_status": action
    }

    dispatch(updateUserRole(body)).then(() => {
      let GUID = teamGroupId;
      let UID = user.uuid;
      let membersList = [
        new CometChat.GroupMember(UID, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
      ];

      CometChat.addMembersToGroup(GUID, membersList, []).then(
        response => {
          console.log("response", response);
        }, error => {
          console.log("Something went wrong", error);
        }
      );
    });
  }

  const removeTeamMemberRole = (id) => {
    const body = {
      "for_team_community_id": teamId,
      "user_id": id
    }
    dispatch(removeTeamMember(body));
  }

  const handleButtonDisplay = (type) => {
    switch (type) {
      case 'blocked':
        return <Button disabled={loading} className='btn-secondary' onClick={() => updateUserStatus(user, 'member')}>{loading ? <Spinner /> : "Unblock"}</Button>;
      case 'invited':
        return <Button disabled={isRemovingTeamMember} className='btn-secondary' onClick={() => removeTeamMemberRole(user.id)}>{isRemovingTeamMember ? <Spinner /> : "Withdraw"}</Button>;
      case 'pending':
        return <><Button disabled={isRemovingTeamMember} className='btn-secondary' onClick={() => removeTeamMemberRole(user.id)}>{isRemovingTeamMember ? <Spinner /> : "Deny"}</Button> <Button disabled={loading} onClick={() => updateUserStatus(user, 'member')}>{loading ? <Spinner /> : "Approve"}</Button></>;
      default:
        return <><Button disabled={isRemovingTeamMember} className='btn-secondary' onClick={() => removeTeamMemberRole(user.id)}>{isRemovingTeamMember ? <Spinner /> : "Deny"}</Button> <Button disabled={loading} onClick={() => updateUserStatus(user, 'member')}>{loading ? <Spinner /> : "Approve"}</Button></>;
    }
  }

  return (
    <>
      <div className={classNames('network-list-card', dashboardRoute ? 'small' : '', isSearchResult ? 'border-bottom-0' : '')}>
        <div className='network-list-card--inner'>
          <div className={dashboardRoute ? 'network-list-card--profile dashboard' : 'network-list-card--profile'}>
            <MyBridgeAvatar size={dashboardRoute ? 40 : 60} name={userName} icon={user?.profile_pic} />
          </div>
          <div className='network-list-card--details'>
            <h5>{userName}</h5>
            <p>{user?.position}</p>
            {!dashboardRoute && <span>{user?.location}</span>}

          </div>
          <div className={`network-list-card--actions`}>
            {handleButtonDisplay(type)}
          </div>

        </div>
      </div>
    </>
  )
}

export default PendingsRequestsCard;