import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'components/Layouts';
import { TabContent } from 'components/common/Tabs/Tabs';
import Loader from 'components/common/Loader';
import Posts from './Posts';

import { getPublicProfile } from 'store/slices/userProfileSlice';
import { getUpcomingEvents } from 'store/slices/calendarSlice';

import Banner from './Banner';
import Profile from './Profile';
import NetworkTab from './NetworkTab';
import lockIcon from '../../../assets/new/lock.svg';
import styled from 'styled-components';
const LockProfileContainer = styled.div`
  text-align: center;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LockProfileText = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
  color:#9C9C9C;
  margin-top: 1rem;
`;

const urlParams = [
  'introduction',
  'personal-details',
  'experience',
  'skills',
  'education',
  'milestones',
  'certificates-and-awards',
  'testimonial',
];

const PublicProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, slug } = useParams();

  const { data: eventData, loading } = useSelector((state) => state.calendar);
  const { publicProfileData, isLoading } = useSelector((state) => state.userProfile);
  const [isProfileLocked, setProfileLock] = useState(false);
  const [openTab, setOpenTab] = useState(id);
  const [eventList, setEventList] = useState();

  /* Get profile data from backend */
  useEffect(() => {
    dispatch(getPublicProfile(slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    let date = new Date();
    dispatch(
      getUpcomingEvents({
        date: `${moment(date).date()}-${moment(date).month() + 1}-${moment(date).year()}`,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  /* Set the active tab */
  useEffect(() => {
    if (urlParams.includes(id) || !id) {
      setOpenTab('introduction');
    } else {
      setOpenTab(id);
    }
    // eslint-disable-next-line
  }, [id]);

  /* Not found page if profile is hidden */
  useEffect(() => {
    if (publicProfileData?.is_visible === false) {
      // lock page visiblity
      setProfileLock(true);
      // navigate('/profile-not-found');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicProfileData]);

  /* Upcoming Events */
  useEffect(() => {
    if (eventData) {
      let finalData = [];
      const grouped = groupBy(eventData?.results, (item) => item.start_time);
      if (grouped) {
        for (const [key, value] of grouped.entries()) {
          if (value && value.length) {
            let finalObject = Object.assign({ eventDate: key, list: value });
            finalData.push(finalObject);
          }
        }
      }
      setEventList(finalData);
    }
    // eslint-disable-next-line
  }, [eventData]);

  const groupBy = (list, keyGetter) => {
    if (list) {
      const map = new Map();
      let collection = [];
      list.forEach((item) => {
        const key = keyGetter(item);
        collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });

      return map;
    }
  };

  const renderData = () => {
    let temp;
    if (loading) {
      temp = <Loader secondary />;
    } else if (eventList?.length < 1) {
      temp = <p>No Upcoming Events. Enjoy :)</p>;
    } else {
      temp = eventList?.map((el) => (
        <div className="events--row" key={el.id}>
          <p className="date">{moment(el.eventDate).format('MMM Do YYYY')}</p>
          {el &&
            el.list.length &&
            el.list.map((subitem, index) => (
              <ul key={index}>
                <li>
                  <strong>{moment(subitem.start_time).format('LT')}</strong>
                </li>
                <li>
                  <p>{subitem.subject}</p>
                  <span>{subitem.location}</span>
                </li>
              </ul>
            ))}
        </div>
      ));
    }

    return temp;
  };

  if (isLoading && !publicProfileData) {
    return (
      <React.Fragment>
        <Loader secondary />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <section className="container profile__section">
        <Banner openTab={openTab} setOpenTab={setOpenTab} isPublic={true} isProfileLocked={isProfileLocked} />
        {isProfileLocked && (
          <LockProfileContainer>
            <img src={lockIcon} alt="lockIcon" style={{ width: 140 }} />
            <LockProfileText>
              <span style={{ textTransform: 'capitalize' }}>{publicProfileData?.first_name}</span> locked his profile
            </LockProfileText>
          </LockProfileContainer>
        )}
        {!isProfileLocked && (
          <section className="Profile_Tab">
            <div className="container p-0">
              <TabContent tab="introduction" openTab={openTab}>
                <Profile isPublic={true} eventData={eventList} renderData={renderData} />
              </TabContent>
              <TabContent tab="posts" openTab={openTab}>
                {/* <Posts isPublic={true} eventData={eventList} renderData={renderData} /> */}
              </TabContent>
              <TabContent tab="network" openTab={openTab}>
                <NetworkTab isPublic={true} />
              </TabContent>
            </div>
          </section>
        )}
      </section>
    </React.Fragment>
  );
};

export default PublicProfilePage;
