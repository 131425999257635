import { size } from 'lodash';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

export const acceptEventApi = async ({ postId }) => {
  try {
    const obj = {
      url: APIEndPoints.AcceptEvent(postId),
      method: 'POST',
      isFormData: false,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const ignoreEventApi = async ({ postId }) => {
  try {
    const obj = {
      url: APIEndPoints.IgnoreEvent(postId),
      method: 'POST',
      isFormData: false,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createEventFunc = async (body) => {
  try {
    const obj = {
      url: APIEndPoints.NewPost,
      method: 'POST',
      body: body,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editEventFunc = async ({ id, body }) => {
  try {
    const obj = {
      url: APIEndPoints.UpdatePost(id),
      method: 'PUT',
      body: body,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getEventsFunc = async ({ queryKey, pageParam = 1 }) => {
  const size = queryKey[2] || 20;
  try {
    const obj = {
      url: APIEndPoints.GetEvents(pageParam, size),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
