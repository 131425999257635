import { Component, useEffect, useState } from "react";
import API from "store/common/api";

import "scss/components/_infinite-list.scss"
import { Spinner } from "components/common/Loader";
global.infiniteListGids = {
    id: 1,
}
const newId = () => { global.infiniteListGids.id = global.infiniteListGids.id + 1; return global.infiniteListGids.id }
export const InfiniteList = ({
    fetch,
    fetchMore,
    data,
    loading,
    id,
    parent,
    showLoader,
    render,
    noDataBox,
    className
}) => {

    const [observer, setObserver] = useState()
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        fetch?.().then?.(() => {
            setMounted(true)
        })
    }, [])

    useEffect(() => {
        // setup intersection observer
        if (mounted) {
            setupIntersection()
        }
    }, [mounted])

    const setupIntersection = () => {
        const obs_ = new IntersectionObserver(handleIntersection, { root: parent })
        const ele = document?.querySelector(`#${id}_TRIGGER`)
        if (ele) {
            obs_.observe(ele)
        }
    }

    const handleIntersection = ([entry]) => {
        if (!mounted) return;
        const ph = parent?.getBoundingClientRect?.()?.bottom;
        const wh = window?.innerHeight;
        if (entry?.boundingClientRect?.top <= (ph ?? wh)) {
            fetchMore?.()
        }
    }

    return (<>
        {/* <pre style={{ height: "400px" }}>
            {JSON.stringify(data, null, 2)}
        </pre> */}
        <div id={`${id}`} className="il-list-container">
            {data && data?.length ? <div id={`${id}_LIST`}>
                {data?.map((item, index) => (render ? render({ item, index }) : <></>))}
            </div> : !loading ?
                <>{noDataBox}</> : ""
            }
            <div id={`${id}_TRIGGER`} />
            {
                (showLoader && loading) ?
                    <div className="spinner-container">
                        <div id={`${id}`} className={`spinner-border ${className}`} role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : <></>
            }
        </div >
    </>
    )
}