import React from 'react';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { SignalIcon, Mic, StartVideo, TickMarkIcon, SimpleCloseIcon } from 'components/common/Icons';


const MeetingParticipants = (props) => {
    const { participants, currentUser } = props;
    
    return (
        <React.Fragment>
            <div className="participantsContainer">
                {participants && participants?.map((user, i) => (
                    <div className="participantCard" key={i}>
                        <div className='participantCard--inner'>
                            <div className="participantCard--profile">
                                <MyBridgeAvatar icon={user.avatar} name={user.name} size='40' round={true} />
                            </div>
                            <div className='participantCard--details'>
                                <h4>{user.name}</h4>
                                <p>{currentUser.user_uuid === user.uid && 'You'}</p>
                            </div>
                            {/* <div className='participantCard--actions'>
                                <button><SignalIcon /></button>
                                <button><Mic /></button>
                                <button><StartVideo /></button>
                            </div> */}
                        </div>
                    </div>
                ))}
                
            </div>
        </React.Fragment>
    )
}

export default MeetingParticipants