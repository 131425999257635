import React, { Fragment, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
// import { folderList } from "constent";
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { getfileNFolder, setOpenFolderItem, setSelectedFile } from 'store/actions/myDisk.Action';
import { DiskFilesListing } from './common/DiskFilesListing';
import { DiskListingHeader } from './common/DiskListingHeader';
import { NoFilesFolderIllustration } from './common/NoFilesFolderIllustration';
import { isDir, isRootDir } from './common/utils';

const TeamDisk = (props) => {
  const { viewType, activeSubTab, team } = props;
  const isMobile = useSelector((state) => state.layoutReducer.isMobile);
  const [activeMenu, setActiveMenu] = useState(null);
  const { selectedFile, directory, fileFolderList } = useSelector((state) => state.myDiskReducer);
  const [overlay, setOverlay] = useState(false)
  const dispatch = useDispatch();

  const outsideClickRef = useRef()

  const onCloseMenu = () => {
    setActiveMenu(null);
    setOverlay(false);
  };
  const onOpenMenu = (index, item) => {
    setActiveMenu(index);
    setOverlay(true);
    dispatch(setSelectedFile(item));
  };
  // console.log(props.subTabs)

  const openFolder = (item) => {
    if (isRootDir(item)) {
      props.handleSubTab_getfileNFolder(item);
    } else {
      dispatch(getfileNFolder({ parent: item.id }));
      dispatch(setOpenFolderItem(item));
    }
    setActiveMenu(null);
    setOverlay(false);
  };
  const onSelectFile = (item) => {
    dispatch(setSelectedFile(item));
    setOverlay(false);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => {
    setActiveMenu(null);
  });


  const handleItemClick = (item) => {
    if (isDir(item)) {
      openFolder(item)
    } else {
      onSelectFile(item)
    }
  }

  return (
    <>
      {overlay ? <div className='overlay-fix' onClick={onCloseMenu}></div> : null}
      <div className="row">
        <div className="my-disk-left-block">
          {viewType === 'ListView' &&
            <DiskListingHeader isMobile={isMobile} />}

          {viewType === 'ListView' ? (
            <Fragment>
              {activeSubTab ? (
                <>
                  {/* {fileFolderList && fileFolderList.length && fileFolderList.map((item, index) => (<div className="file-wrapper"> */}
                  {fileFolderList && fileFolderList.length ? (
                    <DiskFilesListing
                      ref={outsideClickRef}
                      fileFolderList={fileFolderList}
                      isMobile={isMobile}
                      selectedFile={selectedFile}
                      onOpenMenu={onOpenMenu}
                      handlePopups={props?.handlePopups}
                      activeSubTab={activeSubTab}
                      onUploadFilee={props?.onUploadFilee}
                      handleItemClick={handleItemClick}
                    />
                  ) : (
                    <><NoFilesFolderIllustration /></>
                  )}
                </>
              ) : (
                directory &&
                  directory.length ?
                  <DiskFilesListing
                    ref={outsideClickRef}
                    fileFolderList={directory}
                    isMobile={isMobile}
                    selectedFile={selectedFile}
                    onOpenMenu={onOpenMenu}
                    handlePopups={props?.handlePopups}
                    activeSubTab={activeSubTab}
                    onUploadFilee={props?.onUploadFilee}
                    handleItemClick={handleItemClick}
                  />
                  // directory.map((item, index) => (
                  //   <div
                  //     className={
                  //       (item?.type === "F" && selectedFile && selectedFile.id === item.id) ? 'file-wrapper active' : 'file-wrapper'
                  //     }
                  //     key={index}
                  //   >
                  //     <div className="file" onClick={item.type === 'D' || item.type === 'RD' ? (() => openFolder(item)) : (() => onSelectFile(item))}>
                  //       <span className="g1 align-items-center" >
                  //         <div >
                  //           {item?.type === 'F' ? getIconByExtension(item?.extension) : <FolderIcon />}
                  //         </div>
                  //         {trimStr(item?.name, { length: 20 })}
                  //       </span>
                  //       {!isMobile ? (
                  //         <>
                  //           <span className="g2">{moment(item?.updated_at).format('MMM DD, YYYY hh:mm A')}</span>
                  //           <span className="g3">{item?.shared_with.length}</span>
                  //           <span className="g4"
                  //             onClick={(e) => {
                  //               e.stopPropagation();
                  //               if (activeMenu === index) { onCloseMenu?.() } else { onOpenMenu(index, item) }
                  //             }}
                  //           >
                  //             <ThreeDot />
                  //           </span>
                  //         </>
                  //       ) : (
                  //         <>
                  //           <span></span>
                  //           <span onClick={(e) => {
                  //             e.stopPropagation();
                  //             if (activeMenu === index) { onCloseMenu?.() } else { onOpenMenu(index, item) }
                  //           }}>
                  //             <ThreeDot />
                  //           </span>
                  //         </>
                  //       )}
                  //     </div>
                  //     {activeMenu === index && (
                  //       <FileMenu activeSubTab={props.activeSubTab} handlePopups={props.handlePopups} onUploadFilee={props.onUploadFilee} onCloseMenu={onCloseMenu} />
                  //     )}
                  //   </div>
                  // )) 
                  : <NoFilesFolderIllustration />
              )}
            </Fragment>
          ) : (
            // Grid View
            <Fragment>
              {activeSubTab ? (
                <>
                  {fileFolderList && fileFolderList.length ? (
                    <DiskFilesListing
                      ref={outsideClickRef}
                      fileFolderList={fileFolderList}
                      isMobile={isMobile}
                      selectedFile={selectedFile}
                      onOpenMenu={onOpenMenu}
                      handlePopups={props?.handlePopups}
                      activeSubTab={activeSubTab}
                      onUploadFilee={props?.onUploadFilee}
                      handleItemClick={handleItemClick}
                      view="grid"
                    />
                  ) : (
                    <NoFilesFolderIllustration />
                  )}
                </>
              ) : (
                directory &&
                  directory.length ?
                  <DiskFilesListing
                    ref={outsideClickRef}
                    fileFolderList={directory}
                    isMobile={isMobile}
                    selectedFile={selectedFile}
                    onOpenMenu={onOpenMenu}
                    handlePopups={props?.handlePopups}
                    activeSubTab={activeSubTab}
                    onUploadFilee={props?.onUploadFilee}
                    handleItemClick={handleItemClick}
                    view="grid"
                  />
                  // <div className="grid-item-wrapper">{
                  //   directory.map((item, index) => (
                  //     <div
                  //       className="grid-view-file"
                  //       onClick={
                  //         item.type === 'D' || item.type === 'RD' ? (() => openFolder(item)) : (() => onSelectFile(item))
                  //       }
                  //     >
                  //       {item.type === 'F' ? (
                  //         getIconByExtension(item?.extension, { width: 44, height: 36 })
                  //       ) : (
                  //         <FolderIcon width={44} height={36} />
                  //       )}
                  //       {isMobile ? (
                  //         <div className="grid-view-name">
                  //           <div>
                  //             <span className="g1">{trimStr(item?.name, { length: 20 })}</span>
                  //             {/* <span className="g3">3 files</span> */}
                  //           </div>

                  //         </div>
                  //       ) : (
                  //         <>
                  //           <span className="g1">{trimStr(item?.name, { length: 20 })}</span>
                  //           {/* <span className="g3">3 files</span> */}
                  //         </>
                  //       )}
                  //       <div className='grid-view-submenu' onClick={(e) => {
                  //         e.stopPropagation()
                  //         activeMenu === index ? onCloseMenu() : onOpenMenu(index, item)
                  //       }
                  //       }>
                  //         <VerticalThreeDot />
                  //       </div>
                  //       {activeMenu === index && (
                  //         <FileMenu handlePopups={props.handlePopups} activeSubTab={props.activeSubTab} onUploadFilee={props.onUploadFilee} onCloseMenu={onCloseMenu} />
                  //       )}
                  //     </div>
                  //   ))}
                  // </div>
                  : <NoFilesFolderIllustration />
              )}

            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default TeamDisk;
