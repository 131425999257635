import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import notificationsIcon from 'assets/settings/notifications.svg';
import pagesIcon from 'assets/settings/pages.svg';
import paymentIcon from 'assets/settings/payment.svg';
import teamIcon from 'assets/settings/team.svg';
import diskSubscriptionIcon from 'assets/settings/disc-subscription.svg';
import integrationIcon from 'assets/settings/integration.svg';

import Layout from 'components/Layouts';
import { TabContent, Tab, VerticalTabs } from 'components/common/Tabs/Tabs';
import { MyBridgeAvatar } from 'components/common/Avatar';

import Account from './Account/Account';
import Notifications from './Notifications/Notifications';
import Pages from './Pages';
import Payment from './Payment';
import Team from './Team';
import Integration from './Integration/Integration';
import DiskSubscription from './DiskSubscription/DiskSubscription';
import {
  SettingsDiskSubscriptionIcon,
  SettingsIntegrationIcon,
  SettingsNotificationsIcon,
  SettingsPagesIcon,
  SettingsPaymentsIcon,
  SettingsTeamsIcon,
} from 'components/common/Icons';

const SettingsPage = () => {
  const { id = 'account' } = useParams();
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const { first_name, last_name, profile_pic } = userProfileInfo;
  const userName = `${first_name} ${last_name}`;

  const [openTab, setOpenTab] = useState(id || 'account');

  useEffect(() => {
    setOpenTab(id);
  }, [id]);

  let tabProps = {
    openTab: openTab,
    setOpenTab: setOpenTab,
    activeStyle: {
      background: '#E4E6EB',
    },
  };

  const getMenuItemStyle = (tab) => {
    return tab === id
      ? {
          color: 'white',
          fill: '#3C5A80',
        }
      : {};
  };

  return (
    <React.Fragment>
      <section className="user-settings">
        <div className="user-settings--container container-fluid px-md-5 py-5">
          <div className="bordered-container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="p-3">
                  <h4 className="user-settings--main-header">Search Results</h4>
                  <VerticalTabs>
                    <Tab {...tabProps} path="settings" to="account">
                      <MyBridgeAvatar size={31} icon={profile_pic} name={userName} />
                      <p className="tab-title">Account</p>
                    </Tab>

                    <Tab {...tabProps} path="settings" to="notifications">
                      {/* <img src={notificationsIcon} alt="" /> */}
                      <SettingsNotificationsIcon {...getMenuItemStyle('notifications')} />
                      <p className="tab-title">Notifications</p>
                    </Tab>

                    <Tab {...tabProps} path="settings" to="pages">
                      <SettingsPagesIcon {...getMenuItemStyle('pages')} />
                      <p className="tab-title">Pages</p>
                    </Tab>

                    <Tab {...tabProps} path="settings" to="payment">
                      <SettingsPaymentsIcon {...getMenuItemStyle('payment')} />
                      <p className="tab-title">Payment</p>
                    </Tab>

                    <Tab {...tabProps} path="settings" to="team">
                      <SettingsTeamsIcon {...getMenuItemStyle('team')} />
                      <p className="tab-title">Permissions</p>
                    </Tab>

                    <Tab {...tabProps} path="settings" to="disk-subscription">
                      <SettingsDiskSubscriptionIcon {...getMenuItemStyle('disk-subscription')} />
                      <p className="tab-title">Disk Subscription</p>
                    </Tab>

                    <Tab {...tabProps} path="settings" to="integration">
                      <SettingsIntegrationIcon {...getMenuItemStyle('integration')} />
                      <p className="tab-title">Integration</p>
                    </Tab>
                  </VerticalTabs>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="border-start p-4 h-100">
                  <TabContent tab="account" openTab={id}>
                    <Account />
                  </TabContent>

                  <TabContent tab="notifications" openTab={id}>
                    <Notifications />
                  </TabContent>

                  <TabContent tab="pages" openTab={id}>
                    <Pages />
                  </TabContent>

                  <TabContent tab="payment" openTab={id}>
                    <Payment />
                  </TabContent>

                  <TabContent tab="team" openTab={id}>
                    <Team />
                  </TabContent>

                  <TabContent tab="disk-subscription" openTab={id}>
                    <DiskSubscription />
                  </TabContent>

                  <TabContent tab="integration" openTab={id}>
                    <Integration />
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SettingsPage;
