import { Accordion } from 'react-bootstrap';
import {
  ActivityIcon,
  BrowserIcon,
  CalendarIcon,
  CircleIcon,
  CommentsIcon,
  EmailOutlineIcon,
  FolderIcon,
  PageIcon,
  PushIcon,
  ReminderIcon,
  TagIcon,
  TeamDiskMemberIcon,
  TeamMembersIcon,
  TeamNotificationMembersIcon,
  ThreeDotsIcon,
  UsersIcon,
} from 'components/common/Icons';
import HandShake from '../../../assets/icons/followers.svg';
import API from 'store/common/api';
import { useQueries } from '@tanstack/react-query';
import { APIEndPoints } from 'store/common/endPoint';
import PushToggle from './PushToggle';
import EmailToggle from './EmailToggle';
import SMSToggle from './SMSToggle';
import Loader, { Spinner } from 'components/common/Loader';
import TagSection from './TagSection';
import { Text } from 'components/StyledComponents';
import AllToggle from './AllToggle';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyPages } from 'store/actions/companyPages.Action';
import { useEffect } from 'react';
import { trimStr } from 'components/utils';

export default function Notifications() {
  const [emailQuery, smsQuery, pushQuery, pages] = useQueries({
    queries: [
      {
        queryKey: ['email'],
        queryFn: async () => {
          return await API({ method: 'GET', url: `${APIEndPoints.Notification}/email/` });
        },
      },
      {
        queryKey: ['sms'],
        queryFn: async () => {
          return await API({ method: 'GET', url: `${APIEndPoints.Notification}/sms/` });
        },
      },
      {
        queryKey: ['push'],
        queryFn: async () => {
          return await API({ method: 'GET', url: `${APIEndPoints.Notification}/push/` });
        },
      },
    ],
  });

  const dispatch = useDispatch()

  const { companyPageList, loading } = useSelector((state) => state.companyPageReducer);
  useEffect(() => {
    dispatch(getAllCompanyPages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (emailQuery.isLoading || smsQuery.isLoading || pushQuery.isLoading) {
    return <Loader secondary={true} />;
  }

  if (emailQuery.isError || smsQuery.isError || pushQuery.isError) {
    return <div>Error...</div>;
  }

  const emailSettings = emailQuery?.data?.results || [];
  const smsSettings = smsQuery?.data?.results || [];
  const pushSettings = pushQuery?.data?.results || [];

  const sections = [
    {
      title: 'Handshake Request',
      subTitle: 'Push, Email, SMS',
      icon: <UsersIcon />,
      description: `Choose how you want to be notified when you receive a Handshake Request.`,
      key: 'connection',
    },
    {
      title: 'Comments',
      subTitle: 'Push, Email, SMS',
      icon: <CommentsIcon />,
      description: 'Choose how you want to be notified when your post receives a new comment.',
      key: 'comments',
    },
    {
      title: 'Tag',
      subTitle: 'Push, Email, SMS',
      icon: <TagIcon />,
      description: `Choose how you want to be notified when you're are Tagged.`,
      key: 'tags',
      radio: true,
      component: <TagSection whoCanSee={emailSettings.find((set) => set._for === 'tag')} />,
    },
    {
      title: 'Reminders',
      subTitle: 'Push, Email, SMS',
      icon: <ReminderIcon />,
      description: `Choose how you want to be notified when there are posts made on your profile or a connection agrees or disagrees to a post you've made.`,
      key: 'reminder',
      subDescription: `When you receive these notifications`,
      allowDenyAll: {}
    },
    {
      title: 'More Activity About You',
      subTitle: 'Push, Email, SMS',
      icon: <ActivityIcon />,
      description: `Choose how you want to be notified when there are posts made on your profile or a connection agrees or disagrees to a post you've made.`,
      key: 'more_activity',
      subDescription: `When you receive these notifications`,
      allowDenyAll: {}
    },
    {
      title: 'Updates from your circle',
      subTitle: 'Push, Email, SMS',
      icon: <CircleIcon width="26" height="26" viewBox="0 0 26 26" />,
      description: 'Choose how you want to be notified when your connections post or update their status.',
      key: 'updates_from_friend',
      subDescription: `When you receive these notifications`,
      allowDenyAll: {}
    },

    {
      title: 'Team Notification',
      subTitle: 'Push, Email, SMS',
      icon: <TeamNotificationMembersIcon  fill="transparent" />,
      description: 'Choose how you want to be notified when your connections post or update their status.',
      key: 'team',
      subDescription: `When you receive these notifications`,
      allowDenyAll: {}
    },
    {
      title: 'Disk Notification',
      subTitle: 'Push, Email, SMS',
      icon: <FolderIcon width="24" height="26"/>,
      description: 'Choose how you want to be notified when your connections post or update their status.',
      key: 'disk',
      subDescription: `When you receive these notifications`,
      allowDenyAll: {}
    },

    {
      title: 'Meetings and Events',
      subTitle: 'Push, Email, SMS',
      icon: <CalendarIcon />,
      description: `Choose how you want to be notified about Meetings and Events.`,
      key: 'event',
      subDescription: `When you receive these notifications`,
      allowDenyAll: {},
      // extra: {
      //   title: "Receive notifications for",
      //   items: [
      //     {
      //       title: "Activity"
      //     },
      //     {
      //       title: "Reminders"
      //     },
      //     {
      //       title: "Changes & Updates"
      //     },
      //     {
      //       title: "Event Subscriptions"
      //     },
      //     {
      //       title: "Suggestions"
      //     },
      //   ]
      // }
    },
    // {
    //   title: 'Calendars',
    //   subTitle: 'Push, Email, SMS',
    //   icon: <CalendarIcon />,
    //   description: `Choose how you want to be notified about Meetings and Events.`,
    //   key: 'calendar',
    //   subDescription: `When you receive these notifications`,
    //   allowDenyAll: {}
    // },
    // {
    //   title: 'Profile Updates',
    //   subTitle: 'Push, Email, SMS',
    //   icon: <PageIcon />,
    //   key: 'profile_updates',
    //   subDescription: `When you receive these notifications`,
    //   allowDenyAll: {}
    // },
    {
      title: 'Pages and profiles you manage',
      subTitle: 'Push, Email, SMS',
      icon: <PageIcon />,
      description: 'These are notifications about activity in Pages and profiles that you manage.',
      key: 'company_pages',
      component: <>
        {companyPageList?.length ? <Accordion className='sub-accordion' defaultActiveKey={0}>
          {companyPageList.map(({ id, name, image }, cpind) =>
            <Accordion.Item key={cpind} eventKey={cpind}>
              <Accordion.Header>
                <div title={name} className='d-flex align-items-center gap-3'>
                  <img src={image} />
                  <div>
                    <h6>
                      {trimStr(name, { length: 50 })}
                    </h6>
                    <div>
                      Notifications on page, email
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <>
                  <PushToggle onChange={pushQuery.refetch} companyPage={id} module={'company_pages'} settings={pushSettings} />
                  <EmailToggle onChange={emailQuery.refetch} companyPage={id} module={'company_pages'} settings={emailSettings} />
                  <SMSToggle onChange={smsQuery.refetch} companyPage={id} module={'company_pages'} settings={smsSettings} />
                </>
              </Accordion.Body>
            </Accordion.Item>)}
        </Accordion> : loading ? <Spinner /> : <div className="pt-2">No company pages found! The company pages that you manage will appear here.</div>}
      </>
    },
    // {
    //   title: 'Pages and profiles you follow',
    //   subTitle: 'Push, Email, SMS',
    //   icon: <PageIcon />,
    //   description: 'These are notifications about activity in Pages and profiles that you follow.',
    //   key: 'company_pages_followed',
    // },
    // {
    //   title: 'Other Notifications',
    //   subTitle: 'Push, Email, SMS',
    //   icon: <ThreeDotsIcon />,
    //   description:
    //     'These are notifications for app or game requests, breaking news, restaurants you may be near, expiring offers and more.',
    //   key: 'others',
    // },
    // {
    //   title: 'Browser',
    //   icon: <BrowserIcon />,
    //   description: 'Push Notifications',
    //   key: 'browser',
    // },
    // {
    //   title: 'Email',
    //   subTitle: 'On, Suggested',
    //   icon: <EmailOutlineIcon />,
    //   key: 'emails',
    // },
  ];

  const refetchAll = async () => {
    emailQuery?.refetch?.()
    pushQuery?.refetch?.()
    smsQuery?.refetch?.()
  }

  return (
    <div className="user-settings--notifications">
      <Text fS="20px" className="border-bottom pb-2">
        Notifications settings
      </Text>
      <div className="row">
        <div className="col-12">
          <Accordion onChange={console.log} defaultActiveKey="0" style={{ border: 'none', margin: 0, padding: 0 }}>
            {sections.filter(s => s)?.map(({ title, extra, description, subDescription, subTitle, icon, key, allowDenyAll, component }, index) => {
              return (
                <Accordion.Item
                  key={index}
                  eventKey={index}
                  style={{ marginTop: 20, border: '1px solid #D9D9D9', borderRadius: 8, padding: '10px 0px' }}
                >
                  <Accordion.Header elementType="span">
                    <div className="d-flex">
                      <span className="mt-1 me-2">{icon}</span>
                      <div>
                        <Text fS="16px" fW="600" color="#333333">
                          {title}
                        </Text>
                        <Text fS="14px" fW="500" color="#333333">
                          {subTitle}
                        </Text>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Text fS="14px" fW="600" color="#333333">
                      {description}
                    </Text>
                    {
                      allowDenyAll ? <div className='pb-3'>
                        <AllToggle onChange={refetchAll} module={key} settings={{ pushSettings, emailSettings, smsSettings }} />
                      </div> : ""
                    }
                    {subDescription ?
                      <Text fS="14px" fW="600" color="#333333">
                        {subDescription}
                      </Text> : ""}
                    {component ? (
                      component
                    ) : (
                      <>
                        <PushToggle onChange={pushQuery.refetch} module={key} settings={pushSettings} />
                        <EmailToggle onChange={emailQuery.refetch} module={key} settings={emailSettings} />
                        <SMSToggle onChange={smsQuery.refetch} module={key} settings={smsSettings} />
                      </>
                    )}
                    {extra?.items?.length ? <>
                      <Text className='pt-2' fS="14px" fW="600" color="#333333">
                        {extra?.title}
                      </Text>
                      {extra?.items?.map((toggle, tind) =>
                        <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                          <div key={tind}>
                            {/* <PushIcon className="me-3" /> */}
                            {toggle?.title}
                          </div>
                          <ToggleSwitch name={toggle.title} label="Push" value={true} defaultValue={true} />
                        </div>
                      )}
                    </> : ""}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
