import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Circles from './Circle';
// import Articles from './Articles';
import AddContact from './Contact';
import Feed from './Feed';
import Sidebar from './Calendar';
import InactiveUserModal from './InactiveUserModal';
import { activateYourAccount } from 'store/slices/authSlice';
import { getAllMeetings, getEventsFromGoogle } from 'store/slices/calendarSlice';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal/Modal';



export const DashboardPage = () => {
  const { uid, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useSelector((state) => state.layoutReducer);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { msg, error, isActivated } = useSelector((state) => state.user);

  const [activateBox, setActivateBox] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  window.addEventListener('message', (event) => {
    if (event.data?.msg == 'showIntegrationModal') {
        setModalOpen(true);
    }
  });


  useEffect(() => {
    if (uid && token) {
      const body = { uid: uid, token: token };
      dispatch(activateYourAccount(body));
    }
  }, [token, uid]);

  useEffect(() => {
    setActivateBox(false);
    navigate('/');
    dispatch(getAllMeetings());
    dispatch(getEventsFromGoogle());
  }, [isActivated]);

  useEffect(() => {
    if (!userProfileInfo.is_active) {
      let timer = setTimeout(() => {
        setActivateBox(true);
      }, 10000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [userProfileInfo]);

  return (
    <React.Fragment>
      <section className="primary-layout py-4">
        <div className="container">
          <div className="row">
            {!isMobile && (
              <div className="col-md-5 col-lg-4 col-xl-3">
                <Circles />
                {/* <Articles /> */}
                <AddContact />
              </div>
            )}
            <div className="col-md-7 col-lg-8 col-xl-6">
              <Feed showForm />
              {isTablet && <Sidebar />}
            </div>
            {!isTablet && (
              <div className="col-xl-3">
                <Sidebar />
              </div>
            )}
          </div>
        </div>
      </section>
      {activateBox && <InactiveUserModal onClose={(value) => setActivateBox(value)} />}
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen((prevState) => !prevState)}
          heading={'Syncing your contact'}
          className={'meeting-popup'}
        >
          <p>{`You're good to go, your contacts have been have synced`}</p>
          <div className="text-end">
            <Button variant="btn-primary" className={'h-36 me-2'} onClick={() => setModalOpen(false)}>
              OK
            </Button>
          </div>
        </Modal>
      )}
      </React.Fragment>
  );
};

export default DashboardPage;
