import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import EditorToolbar, { modules, formats } from './editorToolbar';
import API from 'store/common/api';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);
class MyBlogEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.inputOpenImageRef = React.createRef();
    this.reactQuillRef = props.forwadedRef || null;
  }

  handleChange(html) {
    if (this.props.setEditorState) {
      this.props.setEditorState(html);
    }
    // console.log(html);
  }
  
  onInputClick = (event) => {
    event.target.value = '';
  };

  imageClick = (e) => {
    this.inputOpenImageRef.current.click();
  };
  imageHandler = async (e) => {
    const file = e.currentTarget.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const quill = this.reactQuillRef.getEditor();
    const range = quill.getSelection(true);
    quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);
    quill.setSelection(range.index + 1);
    const obj = {
      url: 'api/v1/blog/media/upload',
      body: formData,
      isFormData: true,
      method: 'POST',
    };
    const response = await API(obj);
    quill.deleteText(range.index, 1);
    quill.insertEmbed(range.index, 'image', response.file.split('?')[0]);
    this.inputOpenImageRef.current.files[0] = null;
  };

  render() {
    return (
      <div className="text-editor">
        <EditorToolbar />
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          onChange={this.handleChange}
          value={this.props.editorState}
          modules={{
            toolbar: {
              container: '#toolbar',
              handlers: {
                image: this.imageClick,
              },
            },
            imageResize: {
              parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize'],
            },
          }}
          bounds={'#root'}
        />
        <input
          type="file"
          accept="image/*"
          onClick={this.onInputClick}
          ref={this.inputOpenImageRef}
          style={{ display: 'none' }}
          onChange={this.imageHandler}
        />
      </div>
    );
  }
}

export default MyBlogEditor;
