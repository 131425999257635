import { Flex, Text } from 'components/core';
import PostContext from 'context/PostContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Excerpt from './Excerpt';

const BlogBody = () => {
  const { postData } = useContext(PostContext);

  const { title, author } = postData;

  let { excerpt } = postData;

  const name = author?.first_name + ' ' + author?.last_name;

  return (
    <Link to={`/blog/detail/${postData.id}`} style={{ width: '100%' }}>
      <Flex style={{ backgroundColor: '#E7F3FF', overflow: 'hidden' }} padding="10px 16px" direction="column">
        <Text.Title level={4} secondary weight={400}>
          {title}
        </Text.Title>
        <Text.Label margin="4px 0px">{name} on myBridge</Text.Label>
        <Flex>
          <Excerpt margin="10px 8px 0px 0px" text={excerpt} />
        </Flex>
      </Flex>
    </Link>
  );
};

export default BlogBody;
