import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import styled from 'styled-components';

import followers from 'assets/icons/followers.svg';
import message from 'assets/icons/message.svg';
import meetNow from 'assets/icons/meetNow.svg';
import schedule from 'assets/icons/schedule.svg';
import ellipse from 'assets/icons/ellipse.svg';
import share from 'assets/icons/share.svg';
import report from 'assets/icons/report.svg';
import block from 'assets/icons/block.svg';
import pdf from 'assets/icons/pdf.svg';
import chat from 'assets/icons/chat.svg';
import unfollow from 'assets/icons/unfollow.svg';

import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { monthNameMapping } from 'config/constants';
import { HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import Loader from 'components/common/Loader';
import { MyBridgeAvatar } from 'components/common/Avatar';

import './Banner.scss';
import { Text } from 'components/StyledComponents';

const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;
  gap: 4px;
  height: 36px;
  background: #e4e6eb;
  border-radius: 5px;
  border: none;
`;

const MenuList = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 8px;
  list-style: none;
  top: -12rem;
  left: 11rem;
  overflow: hidden;
`;

const MenuItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 8px;
  background: #ffffff;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f3ba86;
    color: #fff;
  }
`;

const Banner = (props) => {
  const navigate = useNavigate();
  const { publicProfileData } = useSelector((state) => state.userProfile);

  console.log({ publicProfileData });

  const [showMenu, setShowMenu] = useState(false);

  const { openTab, setOpenTab, isProfileLocked } = props;

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const {
    first_name,
    last_name,
    slug,
    profile_banner_pic: BgImage,
    position,
    users_experiences,
  } = publicProfileData || {};

  const { company_name, location, start_month, currently_working, start_year, title, end_month, end_year } =
    users_experiences?.[0] || {};

  const USER_NAME = `${first_name} ${last_name}`;

  const gotoPublicProfile = () => {
    navigate(`/${slug}`);
  };

  const customCSS = {
    imgBg: css`
      background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%), url(${BgImage}), #ffffff;
      background-size: cover;
    `,
  };

  return (
    <div className="bordered-container mb-4">
      <div className={`Profile_Banner ${cx(customCSS.imgBg)}`}>
        <div className="banner p-0">
          {BgImage ? <img src={BgImage} className="img-fluid" alt="banner" /> : <Loader secondary />}
        </div>
      </div>

      <div className="user__info">
        <div className="user">
          <div className="avatar">
            <MyBridgeAvatar
              icon={publicProfileData?.profile_pic}
              name={USER_NAME}
              size={120}
              className="profile-avatar"
            />
          </div>

          <div className="left">
            <div className="about">
              <div className="info">
                <p className="profile__user__name text-capitalize">
                  {publicProfileData.first_name} {publicProfileData.last_name}
                </p>
                {publicProfileData.connect_count > 0 ? (
                  <p className="user__connections">{publicProfileData.connect_count} connections</p>
                ) : null}
              </div>

              <div className="personal_info">
                {/* <p className="role">{publicProfileData?.position}</p> */}
                {position && (
                  <>
                    <p className="user__position">
                      {title} at <b>{company_name}</b>
                    </p>
                    <p className="user__position__timeline">
                      {monthNameMapping[start_month]} {start_year} -{' '}
                      {currently_working ? 'Present' : `${monthNameMapping[end_month]} ${end_year}`} · {location}
                    </p>
                  </>
                )}
              </div>

              <div className="badge">
                <span>Follow</span>
              </div>
            </div>
          </div>

          {!isProfileLocked && <div className="right">
            <div className="count">
              <div className="circle">
                {publicProfileData.follow_count > 0 ? (
                  <>
                    <img src={followers} className="icon" alt="circle" />
                    <span className="user__follow__count">{publicProfileData.follow_count}</span>
                  </>
                ) : null}
              </div>
            </div>

            <div className="actions">
              <ActionButton onClick={gotoPublicProfile}>
                <img src={message} alt="" />
                <Text fs="15px" color="#5B5B5B">
                  Message
                </Text>
              </ActionButton>
              <ActionButton>
                <img src={meetNow} alt="" />
                <Text fs="15px" color="#5B5B5B">
                  Meet Now
                </Text>
              </ActionButton>
              <ActionButton>
                <img src={schedule} alt="" />
                <Text fs="15px" color="#5B5B5B">
                  Schedule
                </Text>
              </ActionButton>

              <img src={ellipse} alt="" onClick={() => setShowMenu((showMenu) => !showMenu)} />
              {showMenu && (
                <MenuList ref={ref}>
                  <MenuItem>
                    <img src={share} alt="" />
                    <Text fs="15px" color="#5B5B5B">
                      Shared Connection
                    </Text>
                  </MenuItem>
                  <MenuItem>
                    <img src={report} alt="" />
                    <Text fs="15px" color="#5B5B5B">
                      Report
                    </Text>
                  </MenuItem>
                  <MenuItem>
                    <img src={block} alt="" />
                    <Text fs="15px" color="#5B5B5B">
                      Block
                    </Text>
                  </MenuItem>
                  <MenuItem>
                    <img src={pdf} alt="" />
                    <Text fs="15px" color="#5B5B5B">
                      Save to pdf
                    </Text>
                  </MenuItem>
                  <MenuItem>
                    <img src={chat} alt="" />
                    <Text fs="15px" color="#5B5B5B">
                      Ask for Testimonial
                    </Text>
                  </MenuItem>
                  <MenuItem>
                    <img src={unfollow} alt="" />
                    <Text fs="15px" color="#5B5B5B">
                      Unfollow
                    </Text>
                  </MenuItem>
                </MenuList>
              )}
            </div>
          </div>}
        </div>
        {!isProfileLocked && (
          <div className="user__tabs">
            <HorizontalTabs className="user_tabs_item">
              <Tab setOpenTab={setOpenTab} openTab={openTab} path={slug} to="introduction">
                Profile
              </Tab>
              {/* <Tab setOpenTab={setOpenTab} openTab={openTab} path={slug} to="posts">
                  Posts
                </Tab>
                <Tab setOpenTab={setOpenTab} openTab={openTab} path={slug} to="network">
                  Network  </Tab> 
              */}
            </HorizontalTabs>
          </div>
        )}
      </div>
    </div>
  );
};
export default Banner;
