export const actionMessageStyle = () => {

    return {
        padding: "9px 10px",
        marginBottom: "16px",
        textAlign: "left",
        height: "auto",
        backgroundColor: "#F6F7F9",
        maxWidth: '270px',
        marginLeft:'16px',
        borderRadius:'12px'
    }
}

export const actionMessageTxtStyle = () => {

    return {
        fontSize: "16px",
        margin: "0",
        lineHeight: "20px",
    }
}
