import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import { candidatesList } from 'pages/Company/constent';

import React, { useRef, useState, useEffect } from 'react';
import { TeamChatMessages } from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Messages/CometChatMessages/TeamChatMessages';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from 'components/common/Input';
import {
  GifIcon,
  ImageIcon,
  SmileyIcon,
  CalendarIcon,
  EventIcon,
  StartVideo,
  CloseIcon,
  DeleteIcon,
  SimpleCloseIcon,
} from 'components/common/Icons';
import moment from 'moment';
import ReadMore from 'components/common/ReadMore';
import { CometChat } from '@cometchat-pro/chat';
import FeedForm from './Feed/FeedForm';
import InviteFriends from './InviteFriends';
import AvatarGroup from '@atlaskit/avatar-group';
import { setGroupId } from 'store/slices/teamCommunitySlice';

import _ from 'lodash';
import { CometChatContextProvider } from 'cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/CometChatContext';

// import EmojiPicker from 'pages/Dashboard/Feed/Comment/components/EmojiPicker';

const Overview = (props) => {
  const slug = useParams();
  // const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [showNewMeeting, setShowNewMeeting] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState([]);
  const [groupChatArray, setGroupChatArray] = useState([]);
  const [membersList, setMembersList] = useState();
  const [inviteFriends, setInviteFriends] = useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { teamSlugPage, teamMembers, teamOrganisers, teamId, loading, pending, isTeamUpdated, groupId, usersInvited } =
    useSelector((state) => state.teamsCommunity);

  const [showWelcomeNote, setShowWelcomeNote] = useState(true);
  const listenerID = `teams_${teamSlugPage?.slug}_${new Date().getTime()}`;
  const teamGroupId = `teams_${teamSlugPage?.slug?.replace("-","_")}`;

  useEffect(() => {
    if (!teamId || teamId?.length <= 0) return;
    if (typeof localStorage !== 'undefined') {
      const hide = localStorage.getItem(`team_${teamId}_hide_welcome_note`);
      if (hide) {
        setShowWelcomeNote(false);
      } else {
        setShowWelcomeNote(true);
      }
    }
  }, [teamId]);

  const handleNewChatShow = (userInfo) => {
    setSelectedUserInfo(userInfo);
  };

  const handleNewMeetingShow = () => {
    setShowNewMeeting(true);
  };

  useEffect(() => {
    if (teamMembers.length > 0) {
      const data = teamMembers?.map((d, i) => ({
        key: d.user.id,
        first_name: d.user.first_name,
        last_name: d.user.last_name,
        name: d.user.first_name + ' ' + d.user.last_name,
        src: d.user.profile_pic?.includes('placeholder') ? undefined : d.user.profile_pic,
      }));
      setMembersList(data);
    } else {
      setMembersList([]);
    }
    // eslint-disable-next-line
  }, [teamMembers]);

  const handleInviteConnection = () => {
    setInviteFriends(true);
  };

  const handleInviteClose = (value) => {
    setInviteFriends(value);
  };
  const seeAllMembers = () => {
    navigate(`/teams/profile/${slug.slug}/manage_team`);
  };

  useEffect(() => {
    dispatch(setGroupId(teamGroupId));
    // eslint-disable-next-line
  }, [teamGroupId]);

  useEffect(() => {
    setInviteFriends(false);
    // eslint-disable-next-line
  }, [usersInvited]);

  useEffect(() => {
    _.forEach(teamOrganisers, function (user, key) {
      if (user.user.id === userProfileInfo.id) {
        setIsOrganizer(true);
      }
    });
  }, [teamOrganisers]);
  // console.log(teamMembers);

  const getInitials = (name) => {
    return name?.length > 2 ? name?.substr?.(0, 2)?.toUpperCase?.() : name?.toUpperCase?.();
  };

  const removeWelcomeNote = () => {
    if (typeof localStorage !== undefined) {
      localStorage.setItem(`team_${teamId}_hide_welcome_note`, true);
      setShowWelcomeNote(false);
    }
  };

  return (
    <section className="profile-section">
      <div className="row gap-4 gap-md-0 mb-4">
        <div className="col-12 col-md-4 col-xl-4">
          <Card className="member-card">
            <div className="card-header">
              {teamMembers && teamMembers?.length > 0 ? teamMembers.length : '0'} members
            </div>
            <div className="card-body">
              <div className="user-list mb-2">
                {membersList && membersList?.length > 0 && (
                  <AvatarGroup
                    maxCount={4}
                    appearance="grid"
                    data={membersList}
                    overrides={{
                      Avatar: {
                        render(Comp, props, ind) {
                          return (
                            <MyBridgeAvatar
                              onClick={() => navigate('/' + props?.slug)}
                              key={ind}
                              size={60}
                              icon={props?.src?.includes('placeholder') ? undefined : props.src}
                              name={props?.first_name + ' ' + props?.last_name}
                            />
                          );
                        },
                      },
                    }}
                  />
                )}
              </div>
              <div className="text-center">
                {isOrganizer || teamSlugPage.permission_invite_members ? (
                  <Button className="more-btn btn-sm" onClick={handleInviteConnection}>
                    Invite Connections
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="card-footer">
              <Button onClick={seeAllMembers} className={'show-all d-block'} style={{ color: '#3D5A80' }}>
                Show All
              </Button>
            </div>
          </Card>
          <Card className={'about-card'}>
            <div className="card-header">About this team</div>
            <div className="card-body">
              {teamSlugPage?.desc?.length ? (
                <>
                  <div className="sub-head">Description</div>
                  <div className="sub-body">
                    <ReadMore wordcount={100}>{teamSlugPage?.desc}</ReadMore>
                    {/* <span className='cursor-pointer color-primary'>see more</span> */}
                  </div>
                  <hr />
                </>
              ) : (
                ''
              )}
              <div className="sub-head">Details</div>
              <div className="sub-body mb-3">
                Discoverability
                <br />
                {teamSlugPage?.discoverability === true ? 'Listed Team' : 'Unlisted Team'}
              </div>
              <div className="sub-body">
                Created
                <br />
                {moment(teamSlugPage?.created_at).format('MMMM YYYY')}
              </div>
              {teamSlugPage?.rules?.length ? (
                <>
                  <hr />
                  <div className="sub-head">Rules</div>
                  <div className="sub-body mb-3">
                    <ReadMore wordcount={100}>{teamSlugPage?.rules}</ReadMore>
                    {/* <span className='cursor-pointer color-primary'>see more</span> */}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </Card>
        </div>
        <div className="col-12 col-md-8 col-xl-8">
          <div className="team-chat-container">
            <TeamChatMessages
              messageComposer={({ actionGenerated }) => (
                <FeedForm
                  teamDetails={teamSlugPage}
                  groupId={teamGroupId}
                  messageSent={(value) => setMessageSent(value)}
                  organiser={isOrganizer}
                  actionGenerated={actionGenerated}
                />
              )}
              welcomeNote={
                showWelcomeNote && teamSlugPage?.is_enable_welcome_note ? (
                  <Card className="mb-3">
                    <div className="p-2 ps-4 pe-4 d-flex align-items-center">
                      <span
                        style={{
                          flex: 1,
                          fontWeight: 'bold',
                          color: '#3C5A80',
                        }}
                      >
                        {teamSlugPage?.welcome_note}
                      </span>
                      <span
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={removeWelcomeNote}
                        aria-label="Remove Note"
                        data-microtip-position="left"
                        role="tooltip"
                      >
                        <SimpleCloseIcon width={12} />
                      </span>
                    </div>
                  </Card>
                ) : (
                  ''
                )
              }
              messageSent={messageSent}
              onNewChat={(userInfo) => handleNewChatShow(userInfo)}
              onNewMeeting={handleNewMeetingShow}
              chatWithGroup={teamGroupId}
            />
          </div>
        </div>
      </div>
      {inviteFriends && <InviteFriends onClose={(value) => handleInviteClose(value)} teamId={teamSlugPage.id} />}
    </section>
  );
};

export default Overview;
