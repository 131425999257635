import React from 'react';

const Alert = ({ children, type }) => {
  return (
    <div class={`alert alert-${type || 'warning'} mt-3 mb-3`} role="alert">
      {children}
    </div>
  );
};

export default Alert;
