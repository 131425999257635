
import { randomId } from "components/utils"
import { useEffect, useState } from "react"
import "./index.scss"
import { PauseIcon } from "@giphy/react-components"

/**
 * Circular Progress Component
 * @param {*} props
 * @returns 
 */
export const CircularProgress = ({ done, total, canStop, ...props }) => {
    const [id] = useState(randomId())

    const valToDeg = (val) => (val / 100 * 360)

    return (
        <div {...props} id={id} className={`circular-progress mx-auto ${props.className}`} >
            <span className={`progress-left`}>
                <span
                    className={`progress-bar`}
                    style={{
                        transform: `rotate(${valToDeg(done > 50 ? (done - 50) : 0)}deg)`
                    }}
                />
            </span>
            <span className={`progress-right`}>
                <span
                    className={`progress-bar`}
                    style={{
                        transform: `rotate(${valToDeg(done <= 50 ? done : 50)}deg)`
                    }}
                />
            </span>
            {canStop && <div className={"stopper"} >
                <PauseIcon style={{width:"18px"}} />
            </div>}
            <div className={`progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center`}>

                <div className={`font-weight-bold`}>{done}<sup className={`small`}>%</sup></div>
            </div>
        </div>
    )
}