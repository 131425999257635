import ImageUploader from 'components/ImageUploader';
import { Camera, DeleteIcon } from 'components/common/Icons';
import Modal from 'components/common/Modal/Modal';
import { Flex } from 'components/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.set ? '20px 10px' : '0px 0px')};
  width: 100%;
  background: #e4e6eb;
  cursor: pointer;
`;

const ImagePickerTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
  margin-top: 20px;
`;

const ImagePickerDecs = styled.p`
  font-family: 'Inter';
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  text-align: center;
  color: #000000;
`;

const ImagePicker = ({ onSelect, imageFile = null, setImageFile }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState('4:3');

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const uploadCropedImg = (img) => {
    setModalOpen(false);
    const src = URL.createObjectURL(img);
    setImageFile(src);
    onSelect(img);
  };

  let imageSrc = imageFile;
  if (imageSrc?.type?.includes('image')) {
    imageSrc = URL.createObjectURL(imageFile);
  }

  return (
    <Container onClick={() => setModalOpen(true)} set={imageFile === null}>
      {imageFile ? (
        <Flex position="relative">
          <img src={imageSrc} alt="cover" style={{ width: '100%', height: 'auto' }} />
          <Flex
            position="absolute"
            top={'10px'}
            right={'10px'}
            width={70}
            onClick={() => setImageFile(null)}
            background="#fff"
            padding="5px"
            zIndex={10}
            radius={'6px'}
          >
            <DeleteIcon />
          </Flex>
        </Flex>
      ) : (
        <>
          <Camera />
          <ImagePickerTitle>No cover image uploaded</ImagePickerTitle>
          <ImagePickerDecs>
            Grab more attention with a cover photo. We recommend uploading a pixel size <br /> of 1280x720.
          </ImagePickerDecs>
        </>
      )}
      {modalOpen && (
        <Modal onClose={handlePopups} className={cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}>
          <ImageUploader
            ratio={1.95}
            cropType={cropType}
            uploadCropedImg={uploadCropedImg}
            handlePopups={handlePopups}
          />
        </Modal>
      )}
    </Container>
  );
};

ImagePicker.propTypes = {
  onSelect: PropTypes.func,
};

ImagePicker.defaultProps = {
  onSelect: () => {},
};

export default ImagePicker;
