import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import './style.scss';
import { useSelector } from 'react-redux';
import SectionLoader from 'components/common/Loader/sectionLoader';
import _ from 'lodash';
const CreateDirectGroup = (props) => {

  const {
    userProfileInfo
  } = useSelector((state) => state.userProfile);
  const { data: connectionData, loading } = useSelector((state) => state.connection);

  const [users, setUsers] = useState([]);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [memberNames, setMemberNames] = useState([]);

  const [isLoading, setIsLoading] = useState(false)

  const commonHeader = {
    apiKey: '01bd9d9ac16c296fe50adbb91de88f08715625db',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  const baseUrl = 'https://2276002860111f2a.api-us.cometchat.io/v3/';

  useEffect(() => {

    // TODO: move to api file
    const getUsersOptions = {
      method: 'GET',
      headers: {
        ...commonHeader
      }
    };

    fetch(`${baseUrl}users?perPage=1000&page=1&withTags=false`, getUsersOptions)
      .then(response => response.json())
      .then(response => {
        setUsers(response.data)
      })
      .catch(err => console.error(err));

  }, [])

  useEffect(() => {
    if (connectionData) {
      const filteredConnectedUsers = connectionData?.results?.filter((element1) =>
        users.map((element2) => element2.uid).includes(element1.user_uuid));
      let connectedUsers = [];
      if (props.defaultUser !== '') {
        connectedUsers = filteredConnectedUsers.filter((item) => item.user_uuid !== props.defaultUser.uid);
        setConnectedUsers(connectedUsers);
      } else {
        setConnectedUsers(filteredConnectedUsers);
      }
    }
  }, [connectionData, users])


  const sendMessageOnEnter = (event) => {
    if (event.target.value === null || event.target.value.trim() === "") return;
    const newMsg = event.target.value;
    if (event.keyCode === 13 && !event.shiftKey) {

      if (members.length === 1) {

        const userChatOptions = {
          method: 'POST',
          headers: {
            ...commonHeader,
            onBehalfOf: userProfileInfo?.user_uuid
          },
          body: JSON.stringify({
            category: 'message',
            type: 'text',
            data: {
              text: newMsg,
              metadata: {
                key1: 'value1'
              }
            },
            receiver: members[0],
            receiverType: 'user'
          })
        };
        setIsLoading(true);

        const memberAdded = {
          uid: members[0].toString()
        }

        fetch(`${baseUrl}messages`, userChatOptions)
          .then(response => response.json())
          .then(response => {
            setIsLoading(false);
            props.onSuccess(memberAdded, 'user')
          })
          .catch(err => console.error(err));

      } else if (members.length > 1) {
        const groupNames = _.map(memberNames, (memberNames) => _.split(memberNames, ' ')[0]);
        const groupFinalName = groupNames.join(',');
        const groupCreationOptions = {
          method: 'POST',
          headers: {
            ...commonHeader,
            onBehalfOf: userProfileInfo?.user_uuid
          },
          body: JSON.stringify({
            metadata: {
              test: 'test'
            },
            owner:`${userProfileInfo?.user_uuid}`,
            type: 'private',
            name: groupFinalName,
            guid: new Date().getTime().toString()
          })
        };

        fetch(`${baseUrl}groups`, groupCreationOptions)
          .then(response => response.json())
          .then(response => {

            setIsLoading(true);
            const firstMember = members[0];
            let finalArr = [];

            if (
              typeof firstMember === 'object' &&
              !Array.isArray(firstMember) &&
              firstMember !== null
            ) {
              let [, ...restArray] = members;
              let newArray = [];
              newArray.push(firstMember.uid);
              finalArr = newArray.concat(restArray);
            } else {
              finalArr = [...members];
            }

            const addMembersOptions = {
              method: 'POST',
              headers: {
                ...commonHeader
              },
              body: JSON.stringify({
                participants: [...finalArr, userProfileInfo?.user_uuid]
              })
            };

            const guid = response.data.guid;
            const guidData = response.data;

            fetch(`${baseUrl}groups/${guid}/members`, addMembersOptions)
              .then(response => response.json())
              .then(response => {

                const newGroupChatOptions = {
                  method: 'POST',
                  headers: {
                    ...commonHeader,
                    onBehalfOf: userProfileInfo?.user_uuid
                  },
                  body: JSON.stringify({
                    category: 'message',
                    type: 'text',
                    data: {
                      text: newMsg,
                      metadata: {
                        key1: 'value1'
                      }
                    },
                    receiverType: 'group',
                    receiver: guid
                  })
                };

                fetch(`${baseUrl}messages`, newGroupChatOptions)
                  .then(response => response.json())
                  .then(response => {
                    setIsLoading(false);
                    props.onSuccess(guidData, 'group')
                  })
                  .catch(err => console.error(err));
              })
              .catch(err => console.error(err));
          }

          )
          .catch(err => console.error(err));
        event.target.value = ''

      }

    }
  };
  return (
    <div className="new-chat-wrapper position-relative bg-white">
      {!loading ? <>
        <div className="main__chat" dir="ltr">
          <div className="chat__header">
            <div className="chat__details">
              <div className="chat__user">
                <h6 className="user__name">New message</h6>
              </div>
            </div>
            <div className="chat__options">
              <div title="Close chat" onClick={props.onClose} className="chat-close"><i></i></div>
            </div>
          </div>

          <div className="rbt-wrap">
            <span className='ps-3 pt-2 position-absolute'>To:</span>
            {props.defaultUser !== '' && (
              <Typeahead
                multiple
                labelKey={connectedUsers => `${connectedUsers.first_name} ${connectedUsers.last_name}`}
                options={connectedUsers}
                onChange={(selected) => {
                  let membersArray = [];
                  let membersNames = [];
                  selected.map(user => membersArray.push(user.user_uuid))
                  selected.map(user => membersNames.push(user.first_name))
                  setMembers(membersArray);
                  setMemberNames(membersNames);
                }}
                defaultSelected={[{ user_uuid: props.defaultUser, first_name: props.defaultUser.name, last_name: '' }]}
              />
            )}
            {props.defaultUser === '' && (
              <Typeahead
                multiple
                labelKey={connectedUsers => `${connectedUsers.first_name} ${connectedUsers.last_name}`}
                options={connectedUsers}
                onChange={(selected) => {
                  let membersArray = [];
                  let membersNames = [];
                  selected.map(user => membersArray.push(user.user_uuid))
                  selected.map(user => membersNames.push(user.first_name))
                  setMembers(membersArray);
                  setMemberNames(membersNames);
                }}
              />
            )}
          </div>

          <div className="chat__list">
            <div className="border-bottom px-2">
              <div className="w-50 text-center py-3 border-bottom border-primary">
                <span class="text-primary">Suggested</span>
              </div>
            </div>
          </div>
          <div className="chat__composer">
            <div className="composer__input">
              <div tabindex="-1" className="input__inner">
                <input type="text" className="input__message-input" contenteditable="true" placeholder="." dir="ltr" onKeyDown={sendMessageOnEnter}
                />
              </div>
            </div>
          </div>
        </div>
        {
          isLoading && (
            <div className="position-absolute d-flex justify-content-center align-items-center loading-wrap">
              <span>Please wait, loading chat..</span>
            </div>
          )
        }
      </> : <SectionLoader />}

    </div>
  )
}

export default CreateDirectGroup;