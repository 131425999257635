import { createReducer } from '@reduxjs/toolkit';
import {
  patchCompanyInfo,
  getCompanyServicesInfo,
  getAllCompanyPages,
  createCompanyPage,
  getCompanyPageInfo,
  getCompanyPageData,
  clear,
  companyAddInfo,
  createService,
  editCompanyService,
  deleteCompanyService,
  getCompanyPublicData,
  getFollowCompany,
  getUnFollowCompany,
  getCompanyAnalytics,
  getCompanyPageFollowers,
  listFollowedCompanies,
} from '../actions/companyPages.Action';

const initialState = {
  loading: false,
  companyPageList: [],
  createdPage: undefined,
  companyPageDetail: undefined,
  companyDetailUpdate: undefined,
  serviceReload: undefined,
  companyServicesList: [],
  companyPublicData: {},
  companyAnalyticsData: [],
  companyFollowers: [],
  following: []
};

const companyPageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCompanyPageInfo.fulfilled, (state, action) => {
      state.companyPageDetail = { ...state.companyPageDetail, ...action.payload };
    })
    .addCase(patchCompanyInfo.fulfilled, (state, action) => {
      state.companyPageDetail = { ...state.companyPageDetail, ...action.payload };
    })
    .addCase(getCompanyServicesInfo.fulfilled, (state, action) => {
      state.companyServicesList = action.payload.results;
    })
    .addCase(getAllCompanyPages.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAllCompanyPages.fulfilled, (state, action) => {
      state.loading = false;
      state.companyPageList = action.payload.results;
    })
    .addCase(getAllCompanyPages.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(createCompanyPage.fulfilled, (state, action) => {
      state.createdPage = action.payload;
    })
    .addCase(getCompanyPageData.fulfilled, (state, action) => {
      state.companyPageDetail = { ...state.companyPageDetail, ...action.payload };
    })
    .addCase(companyAddInfo.fulfilled, (state, action) => {
      state.companyDetailUpdate = action.payload;
    })
    .addCase(listFollowedCompanies.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(listFollowedCompanies.fulfilled, (state, action) => {
      state.loading = false;
      state.following = action.payload;
    })
    .addCase(listFollowedCompanies.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getCompanyPublicData.fulfilled, (state, action) => {
      state.companyPublicData = action.payload;
    })
    .addCase(getFollowCompany.fulfilled, (state, action) => {
      state.isCompanyFollowed = true;
    })
    .addCase(getUnFollowCompany.fulfilled, (state, action) => {
      state.isCompanyFollowed = false;
    })
    .addCase(getCompanyAnalytics.fulfilled, (state, action) => {
      state.companyAnalyticsData = action.payload?.results;
    })

    .addCase(createService.fulfilled, (state, action) => {
      state.serviceReload = action.payload;
    })
    .addCase(editCompanyService.fulfilled, (state, action) => {
      state.serviceReload = action.payload;
    })
    .addCase(deleteCompanyService.fulfilled, (state, action) => {
      state.serviceReload = action.payload;
    })

    .addCase(getCompanyPageFollowers.fulfilled, (state, action) => {
      state.companyFollowers = action.payload.results;
    })

    .addCase(clear, (state, action) => {
      state.createdPage = initialState.createdPage;
      state.serviceAdded = initialState.serviceAdded;
      state.serviceUpdated = initialState.serviceUpdated;
    })
    .addDefaultCase((state, action) => { });
});

export default companyPageReducer;
