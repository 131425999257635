import React from 'react';

import Card from 'components/common/Card';
import CardHeading from 'components/common/CardHeading';

import JobCard from 'components/JobCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import CompanyBio from 'pages/Company/components/CompanyBio';

import { getCompanyActiveJobs } from 'store/actions/companyJobs.Actions';
import noJobsfound from 'assets/images/blank_jobs_image.svg';
const Jobs = (props) => {
  const { companyDetails } = props;

  const dispatch = useDispatch();

  const { activeJobs } = useSelector((state) => state.companyJobsReducer);
  const blankPageText = `${companyDetails?.name} jobs will appear here.`;
  useEffect(() => {
    dispatch(getCompanyActiveJobs(companyDetails?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails]);
  return (
    <div className="row">
      <div className="col-xl-12">
        <Card className="py-3 px-3">
          <CardHeading
            Title={'Recent Job Openings'}
            // action={
            //     <div className="links" onClick={postJobs}>
            //         <CirclePlus /> Post Jobs
            //     </div>}
          />
          <div>
            <div className="row gx-2">
              {activeJobs ? (
                activeJobs?.map((item, index) => (
                  <div className="col-6">
                    <JobCardIndividual key={index} index={index} actions={true} data={item} candidateView={true} />
                  </div>
                ))
              ) : (
                <div className="job-blank-container">
                  <img src={noJobsfound} alt="" width="182" className="mb-3" />
                  <h3>{blankPageText}</h3>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
      {/* <div className="col-xl-3">
        <CompanyBio companyDetails={companyDetails} />
      </div> */}
    </div>
  );
};
export default Jobs;
