import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Search } from '../Icons';
import ResultComponent from './ResultComponent';
import { searchCall, addQuery } from 'store/slices/searchSlice';
import SearchContext from 'context/searchContext';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

const SearchBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchResultCheck, setSearchResultCheck] = useState(false);
  const [searchResult, setSearchResult] = useState('');
  const { results } = useSelector((state) => state.search.data);
  // const [showSellAll, setShowSellAll] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setSearchResultCheck(false));
  // let { id } = useParams();
  const { query, setSearchQuery } = useContext(SearchContext);
  const [searchText, setSearchText] = useState(query);
  // let isSearchLocation = window.location.pathname.split('/').includes('search');
  // useEffect(() => {
  //   if (isSearchLocation) {
  //     setSearchResult('');
  //   }
  // }, [isSearchLocation]);

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      setSearchQuery(searchText)
    }, 500)

    return () => clearTimeout(getData)
  }, [setSearchQuery, searchText])

  useEffect(() => {
    if (query.trim() !== '') {
      dispatch(searchCall(query));
      dispatch(addQuery({ queryText: query }));
    }
  }, [dispatch, query]);

  useEffect(() => {
    setSearchResult(results);
    // const totalPeople = results?.people?.length;
    // const totalPages = results?.pages?.length;
    // if (totalPeople + totalPages > 5) {
    //   setShowSellAll(true);
    // } else {
    //   setShowSellAll(false);
    // }
  }, [results]);

  const navigateToSearch = () => {
    navigate('/search');
    setSearchResultCheck(false);
  };

  return (
    <>
      <div className="search-bar" ref={ref}>
        <span onClick={handleShow}>
          <Search />
        </span>
        <div className={`wrap ${show ? 'in' : ''}`}>
          <input
            type="text"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search"
            onFocus={() => setSearchResultCheck(true)}
            value={searchText}
          />
        </div>
        {searchResultCheck && searchResult !== '' && query !== '' && (
          <div className="search-result">
            <ResultComponent searchResults={searchResult} setSearchQuery={setSearchQuery} />
            <div className="search-result--bottom">
              <span className="cursor-pointer color-primary" onClick={navigateToSearch}>
                See all
              </span>
            </div>
            {/* {showSellAll ? 
            : ''} */}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
