import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, VerticalLine } from 'components/StyledComponents';
import EditPageModal from '../modals/EditPageModal';
import { patchCompanyInfo } from 'store/actions/companyPages.Action';

const CompanyConnections = (props) => {
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const onClose = () => setShowModal(false);
  const onClickSave = (companyData) => {
    const { company_size, image, banner_image, ...payload } = companyData;
    dispatch(patchCompanyInfo(payload));
    onClose();
  };
  return (
    <>
      <div className="bordered-container py-3 px-3">
        <Text fS="18px" fW="500" color="#5B5B5B">
          {companyPageDetail?.name}
        </Text>
        <div>
          <div className="row mt-3">
            <Text fS="15px" color="#5B5B5B">
              <Text fS="15px" fW="500" color="#3D5A80" className="d-inline pe-2">
                {companyPageDetail?.follower_count}
              </Text>
              Total Connections
            </Text>
            <VerticalLine className="my-2" />
            <Text fS="15px" color="#5B5B5B">
              <Text fS="15px" fW="500" color="#3D5A80" className="d-inline pe-2">
                {companyPageDetail?.follower_count}
              </Text>
              Recent Connections
            </Text>
          </div>
          <div className="row mt-3">
            <button onClick={() => setShowModal(true)} className="btn edit_page">
              Edit Page
            </button>
          </div>
        </div>
      </div>
      {showModal && <EditPageModal onClose={() => setShowModal(false)} onClickSave={onClickSave} />}
    </>
  );
};

export default CompanyConnections;
