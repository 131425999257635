import React, { useState, useEffect } from "react";
import OnboardingWrapper from "../Components/OnboardingWrapper";
import { onboardingSteps } from "../constant";
import GoogleIcon from "../../../assets/icons/google.svg"
import { useDispatch, useSelector } from "react-redux";
import FileInput from "./FileInput";
import { isNull } from "lodash";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import { getOnboardingData, setOnboardingData, getOnboardingStatus } from 'store/slices/onboardingSlice';
import Modal from 'components/common/Modal/Modal';
import ImageUploader from 'components/ImageUploader';
import { Camera, ImageIcon } from 'components/common/Icons';

const currStep = onboardingSteps[2];
const nextStep = onboardingSteps[3];
const currActiveSteps = [onboardingSteps[0].id, onboardingSteps[1].id, onboardingSteps[2].id];

const OnboardingPhoto = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { onboardingStatus, currStepData, connections } = useSelector((state) => state.onboarding);
  const [profilePic, setprofilePic] = useState(null);
  const [profilePicURL, setprofilePicURL] = useState(null);
  const USER_NAME = userProfileInfo?.first_name + ' ' + userProfileInfo?.last_name;
  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState();

  const handleNext = () => {
    navigate(onboardingSteps[3].url)
  }

  const handleSetProfilePicture = (profilePic) => {
    setprofilePic(profilePic);
    const formData = new FormData();
    formData.append('profile_pic', profilePic);
    const payload = {
      step: currStep.name,
      json: true,
      isFormData: true,
      emailId: userProfileInfo.email,
      data: { profile_pic: profilePic }
    };
    dispatch(setOnboardingData(payload));
  };

  const handleUseGooglePhoto = () => {
    const formData = new FormData();
    formData.append('use_google_photo', true);

    const payload = {
      step: currStep.name,
      json: false,
      isFormData: true,
      data: formData
    };

    dispatch(setOnboardingData(payload));
    navigate(nextStep.url)
  };

  const handleBack = () => {
    navigate(onboardingSteps[1].url)
  }

  useEffect(() => {
    if (userProfileInfo) {
      const payload = {
        step: currStep?.name,
        emailId: userProfileInfo?.email
      };
      dispatch(getOnboardingStatus(payload));
      dispatch(getOnboardingData(payload));
    }
  }, [userProfileInfo]);

  const handlePopups = (type) => {
    setModalOpen((prevState) => !prevState);
    setCropType(type);
  };

  const gotoNextStep = () => { 
    navigate(nextStep.url)
  }

  useEffect(() => {
    if (currStepData) {
      if (!currStepData?.data?.profile_pic?.includes('default-profile-pic.jpg')) {
        setprofilePicURL(currStepData?.data?.profile_pic);
      }
    }
  }, [currStepData]);
  
  return (
    <OnboardingWrapper activeSteps={currActiveSteps}>
      <h3>Ok, now let's put a face to that name! Add a photo to myProfile</h3>
      {/* <p>
        Add a photo to your profile
      </p> */}

      <div className='onboarding-profile'>

        <div className='icon'>
          {isNull(profilePic) && profilePicURL && (
            <span>
              {userProfileInfo?.first_name?.charAt(0) + userProfileInfo?.last_name?.charAt(0)}
            </span>
          )}

          {/* {!isNull(profilePic) && !profilePicURL && (
            <span>
                <img src={URL.createObjectURL(profilePic)} alt="" />
            </span>
          )}
          {profilePicURL !== undefined && !isNull(profilePicURL) && <span>
            <img src={profilePicURL} alt="" />
          </span>} */}
          <button onClick={() => handlePopups('profilePhoto')}>
            <label>
              <Camera />
            </label>
          </button>
        </div>

        <h3>{USER_NAME}</h3>
        <h4>{userProfileInfo.position}</h4>
        <h5>{userProfileInfo.location}</h5>
      </div>


      <div className='photo-btn-group'>
        <Button className="w-100 mt-3" disabled={isNull(profilePic)} onClick={() => gotoNextStep()}>
          Set Profile Picture
        </Button>

        <button className="submit secondary" onClick={handleUseGooglePhoto}>
          <img src={GoogleIcon} alt="" />
          Use my Google photo
        </button>
      </div>

      <ul className='additional-actions justify-content-between'>
        <li onClick={handleBack}>Back</li>
        <li onClick={handleNext}>Skip</li>
      </ul>
      {modalOpen && (
        <Modal onClose={handlePopups} className={cropType === 'profilePhoto' ? 'crop-modal profile-pic' : 'crop-modal'}>
          <ImageUploader cropType={cropType} uploadCropedImg={(file) => { handleSetProfilePicture(file); setModalOpen(false); }} handlePopups={handlePopups} />
        </Modal>
      )}
    </OnboardingWrapper>
  )
};

export default OnboardingPhoto;