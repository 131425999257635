import React from 'react';
import Footer from 'components/Layouts/Footer';
import StaticNavbar from 'components/Layouts/StaticNavbar';
import '../../scss/pages/_aboutus.scss';
import { useNavigate } from 'react-router-dom';


const AboutUs = () => {

  const navigate = useNavigate();

  return (
    <>
    <StaticNavbar/>
      <div className="header-page">
        <div className='head'>{`About us`}</div>
        <div className="route">
          <span onClick={() => { navigate('/')}}>Home </span>
          <span>
            {`/ About Us`}
          </span>
        </div>
      </div>
      <div className="aboutus-container">
        <div className="container">
            <div className="heading">About Us</div>

          <div className="container-heading">About Us</div>
          <div className="container-text">
            Our mission is simple: to <b>connect</b> professionals of various backgrounds with ideas, unlimited opportunities,
            and possibilities; to help them <b>develop</b> through different stages of their careers and <b>grow</b> into productive
            and successful professionals.
          </div>
          <div className="container-heading">About You</div>
          <div className="container-text">
            As a busy working professional, you need efficient systems to make your workday run smoothly. Whether you
            work at home or in an office, collaboration and networking are a part of your day-to-day activities and you
            should not settle for non-intuitive, inefficient platforms that can’t help you perform at your best.
            myBridge was created exclusively for business professionals like you to bridge the gap between where you are
            in your career and where you want to go.
          </div>
          <div className="container-heading">About the Platform</div>
          <div className="container-text">
            With unprecedented opportunities for professional development and career growth through business insights,
            job listings, networking, and mentoring opportunities, you will never need to log off and take your
            conversations elsewhere.
            <br></br><br></br>
            We have designed an innovative dashboard to chat and message through text and
            audio/visual capabilities. You can create virtual communities with close friends and those you would like to
            know better. Share and store photos and files; schedule meetings, and share your thoughts, knowledge, and
            opinions in your personalized feed to be truly collaborative with your community.
            <br></br><br></br>
            We foster a community of forward thinkers where ideas are shared and brought to life in a space that does not tolerate bigotry or hatred against any person or group of people based on their race, religion, ethnicity, sex, gender identity,
            sexual preference, disability, or impairment. Elevate your career by creating a myProfile today.
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AboutUs;
