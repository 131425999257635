import classNames from 'classnames';
import React from 'react';

const Input = React.forwardRef(({ value, label, name, placeholder, type = 'text', onChange, className, startIcon, endIcon, error, onBlur, disabled, onKeyDown, containerClass, maxLength, helpText }, ref) => {
  return (<><div className={containerClass?`form-group input-group ${containerClass}`:"form-group"}>
    {label && <label htmlFor={name}>{label}</label>}
    {startIcon && <span className="input-group-icon start-1">{startIcon}</span>}
    <input
      ref={ref}
      type={type}
      value={value}
      name={name}
      className={className ? `form-control ${className}` : "form-control"}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      onKeyDown={onKeyDown}
      maxLength={maxLength}
    />
    {endIcon && <span className="input-group-icon end-1">{endIcon}</span>}

  </div>
    {error && !helpText && <div className="helper-text mt-n3 text-danger">{error}</div>}
    {!error && helpText && <div className="helper-text mt-n3">{helpText}</div>}
  </>)
}
);

const TextArea = ({ rows, value, name, placeholder, onChange, label, className, error, maxLength, containerClass, ...props }) => {
  return (
    <>
      <div className={containerClass?`form-group ${containerClass}`:"form-group"}>
        <span> {label && <label htmlFor="formControlLabel">{label}</label>} </span>
        <textarea
          rows={rows}
          value={value}
          name={name}
          className={className?`form-control ${className}`:"form-control"}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
          {...props}
        />
      </div>
      {error && <div className="text-danger helper-text mb-3">{error}</div>}
    </>
  );
};

export { Input, TextArea };
