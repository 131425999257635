const months = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const genralMonths = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const causesYouCare = [
  'Animal Welfare',
  'Civil Rights and Social Action',
  'Economic Empowerment',
  'Environment',
  'Human Rights',
  'Poverty Alleviation',
  'Social Services',
  'Children',
  'Disaster and Humanitarian Relief',
  'Education',
  'Health',
  'Politics',
  'Science and Technology',
  'Others',
];

const EMPLOYMENT_CHOICES = [
  { label: 'Full-Time', value: 'FT' },
  { label: 'Part-Time', value: 'PT' },
  { label: 'Contractor', value: 'C' },
  { label: 'Temporary', value: 'T' },
  { label: 'Other', value: 'O' },
];
const PAYMENT_TYPE = [
  { label: 'Annually', value: 'ANUALLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Daily', value: 'DAILY' },
  { label: 'Hourly', value: 'HOURLY' },
];

const BENEFITS = [
  { label: 'Medical Insurance', value: 'Medical_Insurance', id: 1 },
  { label: 'Dental Insurance', value: 'Dental_Insurance', id: 2 },
  { label: 'Vision Insurance', value: 'Vision_Insurance', id: 3 },
  { label: '401K', value: '401K', id: 4 },
  { label: 'Life Insurance', value: 'Life_Insurance', id: 5 },
  { label: 'None of these', value: 'None_of_these', id: 6 },
];

const LANG_CHOICES = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Spanish', value: 'es' },
];

const COUNTRY_CODE = [
  { label: '+91', value: 'c1' },
  { label: '+911', value: 'c2' },
  { label: '+566', value: 'c3' },
  { label: '+1234', value: 'c4' },
];

const BOOL_CHOICE = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const DEACT_REASONS = [
  { label: 'I have a duplicate account', value: 'I have a duplicate account' },
  { label: "I'm getting too many emails", value: "I'm getting too many emails" },
  { label: "I'm not getting any value from my membership", value: "I'm not getting any value from my membership" },
  { label: 'I have a privacy concern', value: 'I have a privacy concern' },
  { label: "I'm receiving unwanted contacts", value: "I'm receiving unwanted contacts" },
  { label: 'Other', value: 'other' },
];

const CURRENCY = [
  {
    symbol: '$',
    label: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'USD',
    name_plural: 'US dollars',
  },
  {
    symbol: 'CA$',
    label: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'CAD',
    name_plural: 'Canadian dollars',
  },
  {
    symbol: '€',
    label: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    value: 'EUR',
    name_plural: 'euros',
  },
  {
    symbol: 'AED',
    label: 'United Arab Emirates Dirham',
    symbol_native: 'د.إ.‏',
    decimal_digits: 2,
    rounding: 0,
    value: 'AED',
    name_plural: 'UAE dirhams',
  },
  {
    symbol: 'Af',
    label: 'Afghan Afghani',
    symbol_native: '؋',
    decimal_digits: 0,
    rounding: 0,
    value: 'AFN',
    name_plural: 'Afghan Afghanis',
  },
  {
    symbol: 'ALL',
    label: 'Albanian Lek',
    symbol_native: 'Lek',
    decimal_digits: 0,
    rounding: 0,
    value: 'ALL',
    name_plural: 'Albanian lekë',
  },
  {
    symbol: 'AMD',
    label: 'Armenian Dram',
    symbol_native: 'դր.',
    decimal_digits: 0,
    rounding: 0,
    value: 'AMD',
    name_plural: 'Armenian drams',
  },
  {
    symbol: 'AR$',
    label: 'Argentine Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'ARS',
    name_plural: 'Argentine pesos',
  },
  {
    symbol: 'AU$',
    label: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'AUD',
    name_plural: 'Australian dollars',
  },
  {
    symbol: 'man.',
    label: 'Azerbaijani Manat',
    symbol_native: 'ман.',
    decimal_digits: 2,
    rounding: 0,
    value: 'AZN',
    name_plural: 'Azerbaijani manats',
  },
  {
    symbol: 'KM',
    label: 'Bosnia-Herzegovina Convertible Mark',
    symbol_native: 'KM',
    decimal_digits: 2,
    rounding: 0,
    value: 'BAM',
    name_plural: 'Bosnia-Herzegovina convertible marks',
  },
  {
    symbol: 'Tk',
    label: 'Bangladeshi Taka',
    symbol_native: '৳',
    decimal_digits: 2,
    rounding: 0,
    value: 'BDT',
    name_plural: 'Bangladeshi takas',
  },
  {
    symbol: 'BGN',
    label: 'Bulgarian Lev',
    symbol_native: 'лв.',
    decimal_digits: 2,
    rounding: 0,
    value: 'BGN',
    name_plural: 'Bulgarian leva',
  },
  {
    symbol: 'BD',
    label: 'Bahraini Dinar',
    symbol_native: 'د.ب.‏',
    decimal_digits: 3,
    rounding: 0,
    value: 'BHD',
    name_plural: 'Bahraini dinars',
  },
  {
    symbol: 'FBu',
    label: 'Burundian Franc',
    symbol_native: 'FBu',
    decimal_digits: 0,
    rounding: 0,
    value: 'BIF',
    name_plural: 'Burundian francs',
  },
  {
    symbol: 'BN$',
    label: 'Brunei Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'BND',
    name_plural: 'Brunei dollars',
  },
  {
    symbol: 'Bs',
    label: 'Bolivian Boliviano',
    symbol_native: 'Bs',
    decimal_digits: 2,
    rounding: 0,
    value: 'BOB',
    name_plural: 'Bolivian bolivianos',
  },
  {
    symbol: 'R$',
    label: 'Brazilian Real',
    symbol_native: 'R$',
    decimal_digits: 2,
    rounding: 0,
    value: 'BRL',
    name_plural: 'Brazilian reals',
  },
  {
    symbol: 'BWP',
    label: 'Botswanan Pula',
    symbol_native: 'P',
    decimal_digits: 2,
    rounding: 0,
    value: 'BWP',
    name_plural: 'Botswanan pulas',
  },
  {
    symbol: 'Br',
    label: 'Belarusian Ruble',
    symbol_native: 'руб.',
    decimal_digits: 2,
    rounding: 0,
    value: 'BYN',
    name_plural: 'Belarusian rubles',
  },
  {
    symbol: 'BZ$',
    label: 'Belize Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'BZD',
    name_plural: 'Belize dollars',
  },
  {
    symbol: 'CDF',
    label: 'Congolese Franc',
    symbol_native: 'FrCD',
    decimal_digits: 2,
    rounding: 0,
    value: 'CDF',
    name_plural: 'Congolese francs',
  },
  {
    symbol: 'CHF',
    label: 'Swiss Franc',
    symbol_native: 'CHF',
    decimal_digits: 2,
    rounding: 0.05,
    value: 'CHF',
    name_plural: 'Swiss francs',
  },
  {
    symbol: 'CL$',
    label: 'Chilean Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    value: 'CLP',
    name_plural: 'Chilean pesos',
  },
  {
    symbol: 'CN¥',
    label: 'Chinese Yuan',
    symbol_native: 'CN¥',
    decimal_digits: 2,
    rounding: 0,
    value: 'CNY',
    name_plural: 'Chinese yuan',
  },
  {
    symbol: 'CO$',
    label: 'Colombian Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    value: 'COP',
    name_plural: 'Colombian pesos',
  },
  {
    symbol: '₡',
    label: 'Costa Rican Colón',
    symbol_native: '₡',
    decimal_digits: 0,
    rounding: 0,
    value: 'CRC',
    name_plural: 'Costa Rican colóns',
  },
  {
    symbol: 'CV$',
    label: 'Cape Verdean Escudo',
    symbol_native: 'CV$',
    decimal_digits: 2,
    rounding: 0,
    value: 'CVE',
    name_plural: 'Cape Verdean escudos',
  },
  {
    symbol: 'Kč',
    label: 'Czech Republic Koruna',
    symbol_native: 'Kč',
    decimal_digits: 2,
    rounding: 0,
    value: 'CZK',
    name_plural: 'Czech Republic korunas',
  },
  {
    symbol: 'Fdj',
    label: 'Djiboutian Franc',
    symbol_native: 'Fdj',
    decimal_digits: 0,
    rounding: 0,
    value: 'DJF',
    name_plural: 'Djiboutian francs',
  },
  {
    symbol: 'Dkr',
    label: 'Danish Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    value: 'DKK',
    name_plural: 'Danish kroner',
  },
  {
    symbol: 'RD$',
    label: 'Dominican Peso',
    symbol_native: 'RD$',
    decimal_digits: 2,
    rounding: 0,
    value: 'DOP',
    name_plural: 'Dominican pesos',
  },
  {
    symbol: 'DA',
    label: 'Algerian Dinar',
    symbol_native: 'د.ج.‏',
    decimal_digits: 2,
    rounding: 0,
    value: 'DZD',
    name_plural: 'Algerian dinars',
  },
  {
    symbol: 'Ekr',
    label: 'Estonian Kroon',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    value: 'EEK',
    name_plural: 'Estonian kroons',
  },
  {
    symbol: 'EGP',
    label: 'Egyptian Pound',
    symbol_native: 'ج.م.‏',
    decimal_digits: 2,
    rounding: 0,
    value: 'EGP',
    name_plural: 'Egyptian pounds',
  },
  {
    symbol: 'Nfk',
    label: 'Eritrean Nakfa',
    symbol_native: 'Nfk',
    decimal_digits: 2,
    rounding: 0,
    value: 'ERN',
    name_plural: 'Eritrean nakfas',
  },
  {
    symbol: 'Br',
    label: 'Ethiopian Birr',
    symbol_native: 'Br',
    decimal_digits: 2,
    rounding: 0,
    value: 'ETB',
    name_plural: 'Ethiopian birrs',
  },
  {
    symbol: '£',
    label: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    value: 'GBP',
    name_plural: 'British pounds sterling',
  },
  {
    symbol: 'GEL',
    label: 'Georgian Lari',
    symbol_native: 'GEL',
    decimal_digits: 2,
    rounding: 0,
    value: 'GEL',
    name_plural: 'Georgian laris',
  },
  {
    symbol: 'GH₵',
    label: 'Ghanaian Cedi',
    symbol_native: 'GH₵',
    decimal_digits: 2,
    rounding: 0,
    value: 'GHS',
    name_plural: 'Ghanaian cedis',
  },
  {
    symbol: 'FG',
    label: 'Guinean Franc',
    symbol_native: 'FG',
    decimal_digits: 0,
    rounding: 0,
    value: 'GNF',
    name_plural: 'Guinean francs',
  },
  {
    symbol: 'GTQ',
    label: 'Guatemalan Quetzal',
    symbol_native: 'Q',
    decimal_digits: 2,
    rounding: 0,
    value: 'GTQ',
    name_plural: 'Guatemalan quetzals',
  },
  {
    symbol: 'HK$',
    label: 'Hong Kong Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    value: 'HKD',
    name_plural: 'Hong Kong dollars',
  },
  {
    symbol: 'HNL',
    label: 'Honduran Lempira',
    symbol_native: 'L',
    decimal_digits: 2,
    rounding: 0,
    value: 'HNL',
    name_plural: 'Honduran lempiras',
  },
];

const COUNTRY = [
  {
    id: 'ABW',
    iso2Code: 'AW',
    name: 'Aruba',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Oranjestad',
    longitude: '-70.0167',
    latitude: '12.5167',
  },
  {
    id: 'AFE',
    iso2Code: 'ZH',
    name: 'Africa Eastern and Southern',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AFG',
    iso2Code: 'AF',
    name: 'Afghanistan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Kabul',
    longitude: '69.1761',
    latitude: '34.5228',
  },
  {
    id: 'AFR',
    iso2Code: 'A9',
    name: 'Africa',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AFW',
    iso2Code: 'ZI',
    name: 'Africa Western and Central',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'AGO',
    iso2Code: 'AO',
    name: 'Angola',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Luanda',
    longitude: '13.242',
    latitude: '-8.81155',
  },
  {
    id: 'ALB',
    iso2Code: 'AL',
    name: 'Albania',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Tirane',
    longitude: '19.8172',
    latitude: '41.3317',
  },
  {
    id: 'AND',
    iso2Code: 'AD',
    name: 'Andorra',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Andorra la Vella',
    longitude: '1.5218',
    latitude: '42.5075',
  },
  {
    id: 'ARB',
    iso2Code: '1A',
    name: 'Arab World',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'ARE',
    iso2Code: 'AE',
    name: 'United Arab Emirates',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Abu Dhabi',
    longitude: '54.3705',
    latitude: '24.4764',
  },
  {
    id: 'ARG',
    iso2Code: 'AR',
    name: 'Argentina',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Buenos Aires',
    longitude: '-58.4173',
    latitude: '-34.6118',
  },
  {
    id: 'ARM',
    iso2Code: 'AM',
    name: 'Armenia',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Yerevan',
    longitude: '44.509',
    latitude: '40.1596',
  },
  {
    id: 'ASM',
    iso2Code: 'AS',
    name: 'American Samoa',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: 'EAP',
      iso2code: '4E',
      value: 'East Asia & Pacific (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Pago Pago',
    longitude: '-170.691',
    latitude: '-14.2846',
  },
  {
    id: 'ATG',
    iso2Code: 'AG',
    name: 'Antigua and Barbuda',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: "Saint John's",
    longitude: '-61.8456',
    latitude: '17.1175',
  },
  {
    id: 'AUS',
    iso2Code: 'AU',
    name: 'Australia',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Canberra',
    longitude: '149.129',
    latitude: '-35.282',
  },
  {
    id: 'AUT',
    iso2Code: 'AT',
    name: 'Austria',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Vienna',
    longitude: '16.3798',
    latitude: '48.2201',
  },
  {
    id: 'AZE',
    iso2Code: 'AZ',
    name: 'Azerbaijan',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Baku',
    longitude: '49.8932',
    latitude: '40.3834',
  },
  {
    id: 'BDI',
    iso2Code: 'BI',
    name: 'Burundi',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bujumbura',
    longitude: '29.3639',
    latitude: '-3.3784',
  },
  {
    id: 'BEA',
    iso2Code: 'B4',
    name: 'East Asia & Pacific (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BEC',
    iso2Code: 'B7',
    name: 'Europe & Central Asia (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BEL',
    iso2Code: 'BE',
    name: 'Belgium',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Brussels',
    longitude: '4.36761',
    latitude: '50.8371',
  },
  {
    id: 'BEN',
    iso2Code: 'BJ',
    name: 'Benin',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Porto-Novo',
    longitude: '2.6323',
    latitude: '6.4779',
  },
  {
    id: 'BFA',
    iso2Code: 'BF',
    name: 'Burkina Faso',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Ouagadougou',
    longitude: '-1.53395',
    latitude: '12.3605',
  },
  {
    id: 'BGD',
    iso2Code: 'BD',
    name: 'Bangladesh',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Dhaka',
    longitude: '90.4113',
    latitude: '23.7055',
  },
  {
    id: 'BGR',
    iso2Code: 'BG',
    name: 'Bulgaria',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Sofia',
    longitude: '23.3238',
    latitude: '42.7105',
  },
  {
    id: 'BHI',
    iso2Code: 'B1',
    name: 'IBRD countries classified as high income',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BHR',
    iso2Code: 'BH',
    name: 'Bahrain',
    region: {
      id: 'MEA',
      iso2code: 'ZQ',
      value: 'Middle East & North Africa',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Manama',
    longitude: '50.5354',
    latitude: '26.1921',
  },
  {
    id: 'BHS',
    iso2Code: 'BS',
    name: 'Bahamas, The',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Nassau',
    longitude: '-77.339',
    latitude: '25.0661',
  },
  {
    id: 'BIH',
    iso2Code: 'BA',
    name: 'Bosnia and Herzegovina',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Sarajevo',
    longitude: '18.4214',
    latitude: '43.8607',
  },
  {
    id: 'BLA',
    iso2Code: 'B2',
    name: 'Latin America & the Caribbean (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BLR',
    iso2Code: 'BY',
    name: 'Belarus',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: 'ECA',
      iso2code: '7E',
      value: 'Europe & Central Asia (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Minsk',
    longitude: '27.5766',
    latitude: '53.9678',
  },
  {
    id: 'BLZ',
    iso2Code: 'BZ',
    name: 'Belize',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Belmopan',
    longitude: '-88.7713',
    latitude: '17.2534',
  },
  {
    id: 'BMN',
    iso2Code: 'B3',
    name: 'Middle East & North Africa (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BMU',
    iso2Code: 'BM',
    name: 'Bermuda',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Hamilton',
    longitude: '-64.706',
    latitude: '32.3293',
  },
  {
    id: 'BOL',
    iso2Code: 'BO',
    name: 'Bolivia',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'La Paz',
    longitude: '-66.1936',
    latitude: '-13.9908',
  },
  {
    id: 'BRA',
    iso2Code: 'BR',
    name: 'Brazil',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: 'LAC',
      iso2code: 'XJ',
      value: 'Latin America & Caribbean (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Brasilia',
    longitude: '-47.9292',
    latitude: '-15.7801',
  },
  {
    id: 'BRB',
    iso2Code: 'BB',
    name: 'Barbados',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bridgetown',
    longitude: '-59.6105',
    latitude: '13.0935',
  },
  {
    id: 'BRN',
    iso2Code: 'BN',
    name: 'Brunei Darussalam',
    region: {
      id: 'EAS',
      iso2code: 'Z4',
      value: 'East Asia & Pacific',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bandar Seri Begawan',
    longitude: '114.946',
    latitude: '4.94199',
  },
  {
    id: 'BSS',
    iso2Code: 'B6',
    name: 'Sub-Saharan Africa (IBRD-only countries)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'BTN',
    iso2Code: 'BT',
    name: 'Bhutan',
    region: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    adminregion: {
      id: 'SAS',
      iso2code: '8S',
      value: 'South Asia',
    },
    incomeLevel: {
      id: 'LMC',
      iso2code: 'XN',
      value: 'Lower middle income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Thimphu',
    longitude: '89.6177',
    latitude: '27.5768',
  },
  {
    id: 'BWA',
    iso2Code: 'BW',
    name: 'Botswana',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'UMC',
      iso2code: 'XT',
      value: 'Upper middle income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Gaborone',
    longitude: '25.9201',
    latitude: '-24.6544',
  },
  {
    id: 'CAA',
    iso2Code: 'C9',
    name: 'Sub-Saharan Africa (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CAF',
    iso2Code: 'CF',
    name: 'Central African Republic',
    region: {
      id: 'SSF',
      iso2code: 'ZG',
      value: 'Sub-Saharan Africa ',
    },
    adminregion: {
      id: 'SSA',
      iso2code: 'ZF',
      value: 'Sub-Saharan Africa (excluding high income)',
    },
    incomeLevel: {
      id: 'LIC',
      iso2code: 'XM',
      value: 'Low income',
    },
    lendingType: {
      id: 'IDX',
      iso2code: 'XI',
      value: 'IDA',
    },
    capitalCity: 'Bangui',
    longitude: '21.6407',
    latitude: '5.63056',
  },
  {
    id: 'CAN',
    iso2Code: 'CA',
    name: 'Canada',
    region: {
      id: 'NAC',
      iso2code: 'XU',
      value: 'North America',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Ottawa',
    longitude: '-75.6919',
    latitude: '45.4215',
  },
  {
    id: 'CEA',
    iso2Code: 'C4',
    name: 'East Asia and the Pacific (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CEB',
    iso2Code: 'B8',
    name: 'Central Europe and the Baltics',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CEU',
    iso2Code: 'C5',
    name: 'Europe and Central Asia (IFC classification)',
    region: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'NA',
      iso2code: 'NA',
      value: 'Aggregates',
    },
    lendingType: {
      id: '',
      iso2code: '',
      value: 'Aggregates',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CHE',
    iso2Code: 'CH',
    name: 'Switzerland',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: 'Bern',
    longitude: '7.44821',
    latitude: '46.948',
  },
  {
    id: 'CHI',
    iso2Code: 'JG',
    name: 'Channel Islands',
    region: {
      id: 'ECS',
      iso2code: 'Z7',
      value: 'Europe & Central Asia',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'LNX',
      iso2code: 'XX',
      value: 'Not classified',
    },
    capitalCity: '',
    longitude: '',
    latitude: '',
  },
  {
    id: 'CHL',
    iso2Code: 'CL',
    name: 'Chile',
    region: {
      id: 'LCN',
      iso2code: 'ZJ',
      value: 'Latin America & Caribbean ',
    },
    adminregion: {
      id: '',
      iso2code: '',
      value: '',
    },
    incomeLevel: {
      id: 'HIC',
      iso2code: 'XD',
      value: 'High income',
    },
    lendingType: {
      id: 'IBD',
      iso2code: 'XF',
      value: 'IBRD',
    },
    capitalCity: 'Santiago',
    longitude: '-70.6475',
    latitude: '-33.475',
  },
];

const COMPANY_TYPE = [
  { label: 'Private', value: 'Private' },
  { label: 'Public', value: 'Public' },
  { label: 'Goverment', value: 'Goverment' },
  { label: 'Private Limited', value: 'Private_Limited' },
  { label: 'Associate Companies', value: 'Associate_Companies' },
];

const COMPANY_TIMING_TYPE = [
  { value: 'NO', label: 'No Hours Available' },
  { value: 'AO', label: 'Always Open' },
  { value: 'PC', label: 'Permanently Closed' },
  { value: 'TC', label: 'Temporarily Closed' },
  { value: 'O', label: 'Open on selected hours' },
];

const COMPANY_SIZE = [
  { label: '0 to 15', value: 5 },
  { label: '15 TO 50', value: 50 },
  { label: '50 TO 100', value: 100 },
  { label: '100 TO 500', value: 500 },
  { label: '500 TO 1000', value: 1000 },
];

const WORKPLACE_TYPE = [
  { label: 'On-site', value: 'N' },
  { label: 'Hybrid', value: 'H' },
  { label: 'Remote', value: 'Y' },
];

const PAGE_NUMBER = [
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
];

const BULK_ACTION = [
  { label: 'Delete All', value: 'Delete All' },
  { label: 'Send Mail', value: 'Send Mail' },
];

const SHAREOPTION = [
  { label: 'Can Edit', value: 1 },
  { label: 'Can View', value: 2 },
];

const MEETING_LOCATION = [
  { label: 'Built-in Audio / Video Conferencing', value: 'builtin' },
  { label: 'Provide a location', value: 'provide' },
];
const TIME_FORMATE = [
  { label: '12:00 AM', value: '00:00' },
  { label: '12:30 AM', value: '00:30' },
  { label: '01:00 AM', value: '01:00' },
  { label: '01:30 AM', value: '01:30' },
  { label: '02:00 AM', value: '02:00' },
  { label: '02:30 AM', value: '02:30' },
  { label: '03:00 AM', value: '03:00' },
  { label: '03:30 AM', value: '03:30' },
  { label: '04:00 AM', value: '04:00' },
  { label: '04:30 AM', value: '04:30' },
  { label: '05:00 AM', value: '05:00' },
  { label: '05:30 AM', value: '05:30' },
  { label: '06:00 AM', value: '06:00' },
  { label: '06:30 AM', value: '06:30' },
  { label: '07:00 AM', value: '07:00' },
  { label: '07:30 AM', value: '07:30' },
  { label: '08:00 AM', value: '08:00' },
  { label: '08:30 AM', value: '08:30' },
  { label: '09:00 AM', value: '09:00' },
  { label: '09:30 AM', value: '09:30' },
  { label: '10:00 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11:00 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12:00 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '01:00 PM', value: '13:00' },
  { label: '01:30 PM', value: '13:30' },
  { label: '02:00 PM', value: '14:00' },
  { label: '02:30 PM', value: '14:30' },
  { label: '03:00 PM', value: '15:00' },
  { label: '03:30 PM', value: '15:30' },
  { label: '04:00 PM', value: '16:00' },
  { label: '04:30 PM', value: '16:30' },
  { label: '05:00 PM', value: '17:00' },
  { label: '05:30 PM', value: '17:30' },
  { label: '06:00 PM', value: '18:00' },
  { label: '06:30 PM', value: '18:30' },
  { label: '07:00 PM', value: '19:00' },
  { label: '07:30 PM', value: '19:30' },
  { label: '08:00 PM', value: '20:00' },
  { label: '08:30 PM', value: '20:30' },
  { label: '09:00 PM', value: '21:00' },
  { label: '09:30 PM', value: '21:30' },
  { label: '10:00 PM', value: '22:00' },
  { label: '10:30 PM', value: '22:30' },
  { label: '11:00 PM', value: '23:00' },
  { label: '11:30 PM', value: '23:30' },
];

export {
  months,
  genralMonths,
  causesYouCare,
  EMPLOYMENT_CHOICES,
  LANG_CHOICES,
  COUNTRY_CODE,
  BOOL_CHOICE,
  DEACT_REASONS,
  CURRENCY,
  PAYMENT_TYPE,
  BENEFITS,
  COUNTRY,
  COMPANY_TYPE,
  COMPANY_SIZE,
  COMPANY_TIMING_TYPE,
  WORKPLACE_TYPE,
  SHAREOPTION,
  MEETING_LOCATION,
  TIME_FORMATE,
  PAGE_NUMBER,
  BULK_ACTION,
};
