import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// import Popup from 'reactjs-popup';

import { MyBridgeAvatar } from 'components/common/Avatar';
import { BorderLessButton, BorderedContainer, Image, Text } from 'components/StyledComponents';
import { ChatIcon, DisagreeIcon, HandShakeSimpleIcon, SharedConnectionIcon } from 'components/common/Icons';

import { postDislike, postLike, postRepost } from 'store/slices/postSlice';

import PostActions from './PostActions';
import Comments from './Comment';
import CommentBox from './Comment/CommentBox';
// import QuoteDropdown from './QuoteDropdown';

const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 15px;
  position: relative;
`;

const UserDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const PostBody = styled.div`
  margin: 15px 0;
`;

const GroupDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GroupSubDiv = styled.div`
  display: flex;
  align-items: center;
`;

const PostReactions = styled.div`
  display: flex;
  justify-content: ${(props) => props.jc || 'space-around'};
  padding: 15px 10px;
  border-bottom: 1px solid #e4e6eb;
`;

const PostFooter = styled.div`
  padding: 0 15px 5px;
  overflow: visible;
  position: relative;
`;

const Post = ({ data, onClick }) => {
  const {
    author,
    content,
    media,
    gif,
    comment_count,
    like_count,
    dislike_count,
    reposts_by,
    created_at,
    id,
    pinned,
    comment_by,
    liked_by,
    dislike_by,
  } = data;

  const [showComments, setShowComments] = useState(false);
  const dispatch = useDispatch();

  const handleShowComments = (postId) => {
    if (comment_count > 0) {
      onClick(postId);
      setShowComments(true);
    }
  };

  const handleAgree = () => {
    const payload = {
      id,
      requestBody: {
        remove: false,
      },
    };
    dispatch(postLike(payload));
  };

  const handleDisagree = () => {
    const payload = {
      id,
      requestBody: {
        remove: false,
      },
    };
    dispatch(postDislike(payload));
  };

  const handleRepost = () => {
    const payload = {
      id,
    };
    dispatch(postRepost(payload));
  };

  const USER_NAME = author?.first_name + ' ' + author?.last_name;

  const getPostActionInfo = () => {
    let temp;

    if (comment_by?.length > 0) {
      temp = (
        <div className="wrap align-items-center">
          <div className="icon transparent d-flex align-items-center justify-content-center">
            <ChatIcon width="18" height="18" />
          </div>
          <div className="content nested-images">
            {comment_by.map((el) => (
              <img key={el.id} src={el.profile_pic} alt="" />
            ))}
            {comment_by?.length > 3 && <p>+{comment_by?.length - 3} commented</p>}
          </div>
        </div>
      );
    } else if (liked_by?.length > 0) {
      temp = (
        <div className="wrap align-items-center">
          <div className="icon transparent d-flex align-items-center justify-content-center">
            <HandShakeSimpleIcon width="18" height="18" color={'#037F29'} />
          </div>
          <div className="content nested-images">
            {liked_by.map((el) => (
              <img key={el.id} src={el.profile_pic} alt="" />
            ))}
            {liked_by?.length > 3 && <p>+{liked_by?.length - 3} agreed</p>}
          </div>
        </div>
      );
    } else if (dislike_by?.length > 0) {
      temp = (
        <div className="wrap align-items-center">
          <div className="icon transparent d-flex align-items-center justify-content-center">
            <HandShakeSimpleIcon width="18" height="18" color={'#E41C19'} />
          </div>
          <div className="content nested-images">
            {dislike_by.map((el) => (
              <img key={el.id} src={el.profile_pic} alt="" />
            ))}
            {dislike_by?.length > 3 && <p>+{dislike_by?.length - 3} disagreed</p>}
          </div>
        </div>
      );
    } else if (reposts_by?.length > 0) {
      temp = (
        <div className="wrap align-items-center">
          <div className="icon transparent d-flex align-items-center justify-content-center">
            <SharedConnectionIcon width="18" height="18" />
          </div>
          <div className="content nested-images">
            {reposts_by.map((el) => (
              <img key={el.id} src={el.profile_pic} alt="" />
            ))}
            {reposts_by?.length > 3 && <p>+{reposts_by?.length - 3} reposted</p>}
          </div>
        </div>
      );
    }

    return temp;
  };

  const getPostActionList = () => {
    let temp;

    if (comment_by?.length > 0) {
      temp = (
        <ul>
          {comment_by.map((el) => (
            <li key={el.id}>
              <Link>
                <img key={el.id} src={el.profile_pic} alt="" />
                {el.first_name + ' ' + el.last_name}
              </Link>
            </li>
          ))}
        </ul>
      );
    } else if (liked_by?.length > 0) {
      temp = (
        <ul>
          {liked_by.map((el) => (
            <li key={el.id}>
              <Link>
                <img key={el.id} src={el.profile_pic} alt="" />
                {el.first_name + ' ' + el.last_name}
              </Link>
            </li>
          ))}
        </ul>
      );
    } else if (dislike_by?.length > 0) {
      temp = (
        <ul>
          {dislike_by.map((el) => (
            <li key={el.id}>
              <Link>
                <img key={el.id} src={el.profile_pic} alt="" />
                {el.first_name + ' ' + el.last_name}
              </Link>
            </li>
          ))}
        </ul>
      );
    } else if (reposts_by?.length > 0) {
      temp = (
        <ul>
          {reposts_by.map((el) => (
            <li>
              <Link>
                <img key={el.id} src={el.profile_pic} alt="" />
                {el.first_name + ' ' + el.last_name}
              </Link>
            </li>
          ))}
        </ul>
      );
    }

    return temp;
  };

  return (
    <div className="d-flex justify-content-center">
      <BorderedContainer overflow="visible" className="py-2 w-75">
        {/* <div className="d-flex mb-2">
        <Popup
          className="card-action-info"
          on="hover"
          offsetX={10}
          position={'bottom left'}
          arrow={true}
          trigger={<div className="post-card--header pb-0">{getPostActionInfo()}</div>}
        >
          {getPostActionList()}
        </Popup>
      </div> */}

        <PostHeader>
          <UserDetails>
            <MyBridgeAvatar icon={author?.profile_pic} size={40} />
            <UserInfo>
              <Text fW="500" fS="15px" color="#3D5A80">
                {USER_NAME}
              </Text>
              <Text fS="13px" color="#5B5B5B">
                {author?.position}
              </Text>
              <Text fS="12px" color="#747474">
                {moment(created_at).startOf().fromNow()}
              </Text>
            </UserInfo>
          </UserDetails>

          <PostActions pinned={pinned} id={id} author={author} />
        </PostHeader>

        <PostBody>
          <Text fS="16px" color="#5B5B5B" className="ms-3 mb-2">
            {content}
          </Text>
          <div className="post-main-image">
          {!!gif ? (
            <Image src={gif} />
          ) : media?.length ? (
            media?.map((img) => <img key={img?.id} src={img?.file} alt={img?.id} />)
          ) : (
            ''
            )}
            </div>
          <PostReactions jc="space-between" className="px-3">
            <GroupDiv>
              <GroupSubDiv>
                {like_count ? <HandShakeSimpleIcon className="me-2" width="12" height="12" color={'#037F29'} /> : <></>}
                {dislike_count ? <DisagreeIcon className="me-2" width="12" height="12" color={'#E41C19'} /> : <></>}
                <Text fS="15px" color="#5B5B5B">
                  {like_count || dislike_count ? like_count + dislike_count : <></>}
                </Text>
              </GroupSubDiv>
              {/* <GroupSubDiv>
                <DisagreeIcon className="me-2" width="12" height="12" color={'#E41C19'} />
                <Text fS="15px" color="#5B5B5B">
                  {dislike_count}
                </Text>
              </GroupSubDiv> */}
            </GroupDiv>

            <GroupDiv>
              <GroupSubDiv>
                <ChatIcon className="me-2" width="18" height="18" />
                <Text fS="15px" color="#3D5A80">
                  {comment_count}
                </Text>
              </GroupSubDiv>
              <GroupSubDiv>
                <SharedConnectionIcon className="me-2" width="18" height="18" />
                <Text fS="15px" color="#3D5A80">
                  {reposts_by?.length}
                </Text>
              </GroupSubDiv>
            </GroupDiv>
          </PostReactions>

          <PostReactions>
            <BorderLessButton onClick={() => handleShowComments(id)}>
              <ChatIcon className="me-2" width="26" height="26" />
              Comment
              {/* {comment_count} */}
            </BorderLessButton>

            <BorderLessButton onClick={handleAgree}>
              <HandShakeSimpleIcon className="me-2" color={'#037F29'} width="26" height="26" />
              Agree
              {/* {like_count} */}
            </BorderLessButton>

            <BorderLessButton onClick={handleDisagree}>
              <DisagreeIcon className="me-2" width="26" height="26" color={'#E41C19'} />
              {/* {dislike_count} */}
              Disagree
            </BorderLessButton>

            <BorderLessButton onClick={handleDisagree}>
              <SharedConnectionIcon className="me-2" width="26" height="26" />
              {/* {dislike_count} */}
              Share
            </BorderLessButton>

            {/* <QuoteDropdown repostsBy={reposts_by} postId={id} author={author} /> */}
            {/* <button onClick={handleRepost}>
              <SharedConnectionIcon className="me-2" />
              {reposts_by?.length}
            </button> */}
          </PostReactions>
        </PostBody>

        {/* <div className="post-card--body">
        {media?.length > 0 ? (
          <>
            {media?.map((img) => (
              <img key={img.id} src={img.file} alt={img.id} />
            ))}
          </>
        ) : null}

        {gif && <img src={gif} alt="gif" />}
      </div> */}

        <PostFooter>
          {showComments && <Comments author={author} id={id} />}

          <CommentBox name={USER_NAME} id={id} author={author} />
        </PostFooter>
      </BorderedContainer>
    </div>
  );
};

export default Post;
