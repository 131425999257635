import VideoPlayer from 'components/PostCard/PostBody/VideoPlayer';
import { DeleteIcon } from 'components/common/Icons';
import React, { useEffect, useState } from 'react';

const Vid = ({ file, onRemove, index }) => {

  return file ? (
    <div className="comment-image">
      <VideoPlayer
        src={file}
        width="100%"
        height="100%"
      />

      {onRemove && (
        <button onClick={() => onRemove?.()}>
          <DeleteIcon color="#fff" width={20} height={20} />
        </button>
      )}
    </div>
  ) : null;
};

export default Vid;
