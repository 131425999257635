import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints, UserAPIEndpoints } from 'store/common/endPoint';

const initialState = {
  data: [],
  loading: false,
  refetchData: false,
  mutualConnections: [],
  follow: [],
  invites: [],
  suggestions: [],
  childLoading: false,
  isCircleAvailable: false,
  block: null,
  report: null,
  requestChange: false,
  requestDeclined: false,
  requestedHandshakes: [],
  userConversationId: null,
  mutualUserId: null,
  followRequested: false,
  followRequestLoading: false,
  allConnectionIDs: [],
  allDegreeConnections: []
};

export const clear = createAction('clear');
export const setAllDegreeConnections = createAction('setAllDegreeConnections');

export const getConnectionsData = createAsyncThunk('getConnectionsData', async (opts) => {
  const { page, forUserId } = opts ?? {}
  const obj = {
    url: `${APIEndPoints.Connections}${forUserId ? ("/" + forUserId) : ""}?page=${page ? page : 1}`,
  };
  return await API(obj);
});

export const getAllConnectionsData = createAsyncThunk('getAllConnectionsData', async (page) => {
  const obj1 = {
    url: `${UserAPIEndpoints.GetDegreeCount(1)}`,
  };
  const obj2 = {
    url: `${UserAPIEndpoints.GetDegreeCount(2)}`,
  };
  const obj3 = {
    url: `${UserAPIEndpoints.GetDegreeCount(3)}`,
  };
  return await Promise.all([API(obj1), API(obj2), API(obj3)]);
});

export const getUserConnectionsData = createAsyncThunk('getUserConnectionsData', async (id) => {
  const obj = {
    url: `${APIEndPoints.Connections}/${id}`,
  };
  return await API(obj);
});

export const getFollowers = createAsyncThunk('getFollowers', async (body) => {
  const obj = {
    url: APIEndPoints.Follow(body),
  };
  return await API(obj);
});

export const getInvitations = createAsyncThunk('getInvitations', async (body) => {
  const obj = {
    url: APIEndPoints.Invitations(body),
  };
  return await API(obj);
});

export const getSuggestions = createAsyncThunk('getSuggestions', async (body) => {
  const obj = {
    url: APIEndPoints.Suggestions(body),
  };
  return await API(obj);
});

export const getSharedConnectionsData = createAsyncThunk('getSharedConnectionsData', async (body) => {
  const obj = {
    url: APIEndPoints.SharedConnections(body.id),
  };
  return await API(obj);
});

export const blockConnection = createAsyncThunk('blockConnection', async (body) => {
  const obj = {
    url: APIEndPoints.BlockConnection,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const reportConnection = createAsyncThunk('reportConnection', async (body) => {
  const obj = {
    url: APIEndPoints.ReportConnection,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const requestConnection = createAsyncThunk('requestConnection', async (body) => {
  const obj = {
    url: APIEndPoints.RequestConnection,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const withdrawConnection = createAsyncThunk('withdrawConnection', async (body) => {
  const obj = {
    url: APIEndPoints.RequestConnection,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const requestFollow = createAsyncThunk('requestFollow', async (body) => {
  const obj = {
    url: APIEndPoints.Follower,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const unfollow = createAsyncThunk('unfollow', async (body) => {
  const obj = {
    url: APIEndPoints.Follower,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const acceptRequest = createAsyncThunk('acceptRequest', async (body) => {
  const obj = {
    url: APIEndPoints.RequestConnection,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const rejectRequest = createAsyncThunk('rejectRequest', async (body) => {
  const obj = {
    url: APIEndPoints.RequestConnection,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const getAllConnectionIDs = createAsyncThunk('getAllConnectionIDs', async ({ page = 1, search = '' } = {}) => {
  const obj = {
    url: `${APIEndPoints.AllConnections}`,
    method: "GET"
  };
  return await API(obj);
});

export const getUserByUuid = createAsyncThunk('getUserByUuid', async (uuid) => {
  const obj = {
    url: `${APIEndPoints.UserDetailsByUuid}?uuid=${uuid}`,
    method: "GET"
  };
  return await API(obj);
});

export const startConversationWithUser = createAction('startConversationWithUser');
export const setIdforMutual = createAction('setIdforMutual');

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    resetReport: (state, action) => {
      state.report = null;
    },
  },
  extraReducers: {
    [getConnectionsData.pending]: (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    },
    [getConnectionsData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getConnectionsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    },
    [getAllConnectionsData.pending]: (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    },
    [getAllConnectionsData.fulfilled]: (state, action) => {
      state.allDegreeConnections = action.payload?.map(p => p?.results ?? [])?.reduceRight((acc, v) => [...(acc ?? []), ...(v ?? [])], [])
      // state.allDegreeConnections = action.payload?.map(p => p?.results ?? []);
      state.loading = false;
    },
    [getAllConnectionsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    },

    [getUserConnectionsData.pending]: (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    },
    [getUserConnectionsData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [getUserConnectionsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    },

    // Getting Followers / Following users
    [getFollowers.pending]: (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    },
    [getFollowers.fulfilled]: (state, action) => {
      state.loading = false;
      state.follow = action.payload?.results;
    },
    [getFollowers.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    },

    // Getting Invitations / Sent / Pending
    [getInvitations.pending]: (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    },
    [getInvitations.fulfilled]: (state, action) => {
      state.loading = false;
      state.invites = action.payload?.results;
    },
    [getInvitations.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    },


    // Getting Suggestions
    [getSuggestions.pending]: (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    },
    [getSuggestions.fulfilled]: (state, action) => {
      state.loading = false;
      state.suggestions = action.payload;
    },
    [getSuggestions.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    },

    [getSharedConnectionsData.pending]: (state, action) => {
      state.childLoading = true;
    },
    [getSharedConnectionsData.fulfilled]: (state, action) => {
      state.childLoading = false;
      state.mutualConnections = action.payload;
    },
    [getSharedConnectionsData.rejected]: (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    [blockConnection.pending]: (state, action) => {
      state.childLoading = true;
    },
    [blockConnection.fulfilled]: (state, action) => {
      state.childLoading = false;
      state.block = action.payload;
      state.refetchData = true;
    },
    [blockConnection.rejected]: (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    [reportConnection.pending]: (state, action) => {
      state.childLoading = true;
    },
    [reportConnection.fulfilled]: (state, action) => {
      state.childLoading = false;
      state.report = action.payload;
      state.refetchData = true;
    },
    [reportConnection.rejected]: (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    // Accept connection request
    [acceptRequest.pending]: (state, action) => {
      state.childLoading = true;
    },
    [acceptRequest.fulfilled]: (state, action) => {
      state.childLoading = false;
      state.report = action.payload;
      state.requestChange = true;
    },
    [acceptRequest.rejected]: (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    },


    // Reject connection request
    [rejectRequest.pending]: (state, action) => {
      state.childLoading = true;
    },
    [rejectRequest.fulfilled]: (state, action) => {
      state.childLoading = false;
      state.report = action.payload;
      state.requestDeclined = true;
    },
    [rejectRequest.rejected]: (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    // Send Handshake request
    [requestConnection.pending]: (state, action) => {
      state.loading = true;
    },
    [requestConnection.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.id) {
        state.requestChange = true;
      } else {
        state.error = true;
        state.message = action.payload.message
      }
    },
    [requestConnection.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    // Withdraw Handshake request
    [withdrawConnection.pending]: (state, action) => {
      state.loading = true;
    },
    [withdrawConnection.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.id) {
        state.requestChange = true;
      } else {
        state.error = true;
        state.message = action.payload.message
      }
    },
    [withdrawConnection.rejected]: (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    },

    // Send Follow request
    [requestFollow.pending]: (state, action) => {
      state.followRequestLoading = true;
    },
    [requestFollow.fulfilled]: (state, action) => {
      state.followRequestLoading = false;
      state.report = action.payload;
      state.followRequested = true
    },
    [requestFollow.rejected]: (state, action) => {
      state.followRequestLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    //  Unfollow
    [unfollow.pending]: (state, action) => {
      state.followRequestLoading = true;
    },
    [unfollow.fulfilled]: (state, action) => {
      state.followRequestLoading = false;
      state.report = action.payload;
    },
    [unfollow.rejected]: (state, action) => {
      state.followRequestLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    [startConversationWithUser]: (state, action) => {
      state.userConversationId = action.payload;
    },
    [getAllConnectionIDs.fulfilled]: (state, action) => {
      state.allConnectionIDs = action?.payload ?? []
    },

    [setIdforMutual]: (state, action) => {
      state.mutualUserId = action.payload
    },
    [clear]: (state, action) => {
      state.userConversationId = null
    },
    [setAllDegreeConnections]: (state, action) => {
      state.allDegreeConnections = action.payload
    },
  },
});

export const { getConnections, rejectConnection, acceptConnection , resetReport } = connectionSlice.actions;

export default connectionSlice.reducer;
