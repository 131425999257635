import React, { useState, useEffect } from 'react';

import backarrow from 'assets/new/backarrow.svg';
import { AddCircle } from 'components/common/Icons';
import { BorderLessButton, Button, Text } from 'components/StyledComponents';

import AddService from 'pages/Company/components/AddService';

const AddServiceWrapper = ({ service, index, companyServices, setCompanyServices }) => {
  const [serviceDetails, setServiceDetails] = useState({});
  useEffect(() => {
    setServiceDetails(service);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateServiceDetails = (service) => {
    setServiceDetails(service);
    const newServices = [...companyServices];
    newServices[index] = service;
    setCompanyServices(newServices);
  };

  return <AddService serviceDetails={serviceDetails} setServiceDetails={updateServiceDetails} />;
};

const ServicesSection = ({ companyServices, setCompanyServices, setSection, addCompanyWithServices }) => {
  return (
    <>
      <div className="col-12 d-flex flex-column border-bottom gap-5 p-5">
        <div className="container d-flex gap-5 flex-column">
          <button className="back d-flex gap-3" onClick={() => setSection('company')}>
            <img src={backarrow} alt="Not Found" className="arrow-back" />
            <Text fS="20px" color="#3D5A80">
              Back
            </Text>
          </button>

          <Text fS="18px" color="#3D5A80">
            What is the service you provide to your customers?
          </Text>
        </div>
      </div>

      <section className="bordered-container container mt-5 create-company-services-section">
        {companyServices.map((service, index) => {
          return (
            <AddServiceWrapper
              service={service}
              index={index}
              companyServices={companyServices}
              setCompanyServices={setCompanyServices}
            />
          );
        })}

        {/* <BorderLessButton className="d-flex gap-2 align-items-center borderless-btn" onClick={addExtraItem}>
          <AddCircle color="#3D5A80" />
          <Text color="#3D5A80">Add more services</Text>
        </BorderLessButton> */}

        <div className="d-flex align-items-center justify-content-end">
          <Button bgColor="#3D5A80" padding="1rem" onClick={addCompanyWithServices}>
            <Text color="#fff">Add services</Text>
          </Button>
        </div>
      </section>
    </>
  );
};

export default ServicesSection;
