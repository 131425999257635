import React, { useState, useEffect, useRef } from 'react';
import { MyBridgeAvatar } from 'components/common/Avatar';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import {
  BlockIcon,
  ChatIcon,
  EventIcon,
  KebabMenuIcon,
  Message,
  ThreeDot,
  ReportIcon,
  ScheduleIcon,
  SharedConnectionIcon,
  StatusIcon,
  ViewProfile,
  FollowIcon,
  HandShakeSimpleIcon,
  TickMarkIcon,
  CalendarIcon,
} from 'components/common/Icons';
import BlockModal from './BlockModal';
import SharedConnections from './SharedConnections';
import ReportModal from 'components/ReportModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Popup } from 'reactjs-popup';
import PrimaryAvatarList from 'components/common/List';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestConnection,
  requestFollow,
  startConversationWithUser,
  clear,
  getAllConnectionIDs,
} from 'store/slices/connectionSlice';
import circle from 'assets/icons/circle.svg';
import myBridge from 'assets/icons/myBridge.svg';
import { TextArea } from 'components/common/Input';
import Modal from 'components/common/Modal/Modal';
import UserPresence from 'components/common/UserPresense';
import { Text } from 'components/StyledComponents';
import { CometChat } from '@cometchat-pro/chat';
import styled from 'styled-components';
import ellipse from 'assets/icons/ellipse.svg';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import _ from 'lodash';

import FilterSection from 'components/common/FilterSection';

const ListContainer = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-bottom: 1px solid #d9d9d9;
`;

const ListTitle = styled.div`
  font-family: 'Inter';
  color: #272727;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;
const ListSubTitle = styled.div`
  font-family: 'Inter';
  color: #272727;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const ListLocation = styled.div`
  font-family: 'Inter';
  color: #747474;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const NetworkCard = (props) => {
  const { user, isSearchResult, connection, event, following, manageNetwork } = props;
  const [followReqSent, setFollowReqSent] = useState(false);
  const [block, setBlock] = useState(false);
  const [report, setReport] = useState(false);
  const outgoingDirectCallRef = useRef();
  const [sharedConnections, setSharedConnections] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardRoute = location.pathname === '/';
  const searchRoute = location.pathname.includes('/search');
  const userName = user?.first_name + ' ' + user?.last_name;
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { requestChange, allConnectionIDs } = useSelector((state) => state.connection);
  const [handShake, setHandShake] = useState(false);
  const [callInitiated, setCallInitiated] = useState(false);
  const [callInitiatedText, setCallInitiatedText] = useState('Meet Now');
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [directRequest, setDirectRequest] = useState(true);
  const [addCustomMessage, setAddCustomMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState();
  const [count, setCount] = useState(0);
  const [manageConnectionsData, setManageConnectionsData] = useState();
  const [selfUser, setSelfUser] = useState();
  const navigateTo = () =>{ navigate('/schedule');window.location.reload()}
 

  let listnerID = 'NetworkcardListner';
  let outgoingCallView = null;
  useEffect(() => {
    if (requestChange) {
      setOpenRequestModal(false);
      setCustomMessage();
      setAddCustomMessage(false);
      setDirectRequest(true);
    }
  }, [requestChange]);

  useEffect(() => {
    if (manageConnectionsData && manageConnectionsData?.handshake_requests) {
      setHandShake(_.includes(manageConnectionsData?.handshake_requests, user.id));
    } 
    if(manageConnectionsData && manageConnectionsData?.following){
      setFollowReqSent(_.includes(manageConnectionsData?.following, user.id));
    } 
    if (manageConnectionsData && manageConnectionsData?.circle){
      setSelfUser(_.includes(manageConnectionsData?.following, user.id));
    }
    if(userProfileInfo?.id === user?.id){
      setSelfUser(true);
    }
  }, [manageConnectionsData]);

  const handleHandshakeReq = () => {
    dispatch(requestConnection({ to_user_id: user?.id, invite_message: customMessage })).then((res) => {
      setOpenRequestModal(false);
      setHandShake(!handShake);
      dispatch(getAllConnectionIDs());
    });
  };
  const handleFollowReq = () => {
    dispatch(requestFollow({ follow_to: user?.id })).then((res) => {
      setFollowReqSent(true);
    });
    dispatch(getAllConnectionIDs());
  };

  const handleClose = () => {
    setOpenRequestModal(false);
  };

  const goToProfile = (user) => {
    navigate(`/p/${user.slug}/personal-details`);
  };

  const handleStartChat = (user, type) => {
    console.log(user);
    dispatch(startConversationWithUser(user));
  };

  const handleMeetNow = (user) => {
    const receiverID = user.user_uuid;
    const callType = CometChat.CALL_TYPE.VIDEO;
    const receiverType = CometChat.RECEIVER_TYPE.USER;

    const call = new CometChat.Call(receiverID, callType, receiverType);

    CometChat.initiateCall(call).then(
      (outGoingCall) => {
        console.log('Call initiated successfully:', outGoingCall);
        setCallInitiated(true);
        setCallInitiatedText('Calling');
      },
      (error) => {
        console.log('Call initialization failed with exception:', error);
        setCallInitiated(false);
        setCallInitiatedText('Meet Now');
      },
    );
  };

  useEffect(() => {
    CometChat.addCallListener(
      listnerID,
      new CometChat.CallListener({
        onIncomingCallReceived: (call) => {
          console.log('Incoming call:', call);
        },
        onOutgoingCallAccepted: (call) => {
          console.log('Outgoing call accepted:', call);
          setCallInitiated(false);
          setCallInitiatedText('Accepted');
        },
        onOutgoingCallRejected: (call) => {
          console.log('Outgoing call rejected:', call);
          setCallInitiated(false);
          setCallInitiatedText('Meet Now');
        },
        onIncomingCallCancelled: (call) => {
          console.log('Incoming call calcelled:', call);
          setCallInitiated(false);
          setCallInitiatedText('Meet Now');
        },
      }),
    );
  }, [callInitiated]);

  useEffect(() => {
    setManageConnectionsData(allConnectionIDs);
  }, [allConnectionIDs]);

  return (
    <>
      {!searchRoute ? (
        <>
          <PrimaryAvatarList
            user={user}
            icon={user?.profile_pic}
            name={userName}
            designation={user?.position}
            overlapIcon={<UserPresence userId={user?.user_uuid} />}
          />
        </>
      ) : (
        // <Card className="border my-2 px-3 w-90">
        <>
          {!dashboardRoute && !searchRoute && (
            <div>
              <div className="card" style={{ border: 'unset' }}>
                <div
                  className="page-list-wrapper"
                  style={{ display: 'flex', gap: '1.5rem', justifyContent: 'center', alignItems: 'center' }}
                >
                  <div>
                    <MyBridgeAvatar icon={user?.profile_pic} size={60} name={userName} />
                    <div style={{ textAlign: 'right', marginTop: '-1rem' }}>
                      <UserPresence userId={user?.user_uuid} />
                    </div>
                  </div>
                  <div className="page-details" style={{ position: 'relative', flexGrow: 1 }}>
                    <ListTitle fS="16px" fW="600" color="#5B5B5B">
                      {userName}
                    </ListTitle>
                    <ListSubTitle>{user?.position}</ListSubTitle>
                    <ListLocation>{user?.location}</ListLocation>
                  </div>
                  <Button className="btn-white" onClick={() => handleStartChat(user, 'user')}>
                    <Message height={16} width={16} /> Message
                  </Button>
                  {manageNetwork && (
                    <div className="kebab-action network-list-kabab">
                      <div className="p-3 ms-2">
                        <KebabMenuIcon />
                      </div>
                      <div className="kebab-action--menu bordered-container">
                        <ul>
                          <li onClick={() => goToProfile(user)}>
                            <ViewProfile height={15} width={15} className="me-1" /> View Profile
                          </li>
                          <li onClick={() => handleStartChat(user, 'user')}>
                            <EventIcon width={15} height={15} className="me-1" />
                            <span className={classNames(callInitiated ? 'heartbeat' : '')}>{callInitiatedText}</span>
                          </li>
                          <li onClick={navigateTo}>
                            <ScheduleIcon className="me-1" />
                            Schedule
                          </li>

                          <li onClick={() => setSharedConnections(true)}>
                            <SharedConnectionIcon width="15" height="15" color="#5B5B5B" className="me-2" />
                            Shared Connection
                          </li>
                          <li onClick={() => setReport(true)}>
                            <ReportIcon className="me-2" />
                            Report
                          </li>
                          <li onClick={() => setBlock(true)}>
                            <BlockIcon className="me-2" />
                            Block
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <hr className="mt-2" />
              </div>
            </div>
          )}

          {(dashboardRoute || searchRoute) && (
            <>
              <div
                className={classNames(
                  'network-list-card',
                  dashboardRoute ? 'small' : '',
                  //   isSearchResult ? 'border-bottom-0' : '',
                )}
              >
                {outgoingCallView}
                <div className={classNames(!isSearchResult ? 'network-list-card--inner' : '')}>
                  <div
                    className={dashboardRoute ? 'network-list-card--profile dashboard' : 'network-list-card--profile'}
                  >
                    <div>
                      <MyBridgeAvatar size={dashboardRoute ? 40 : 65} name={userName} icon={user?.profile_pic} />
                    </div>
                    {isSearchResult && (
                      <div className="ms-3">
                        <h5>{userName}</h5>
                        <p>{user?.position}</p>
                        {!dashboardRoute && <span className="subDetails">{user?.location}</span>}
                      </div>
                    )}
                  </div>
                  {manageNetwork && (
                    <div className="kebab-action network-list-kabab">
                      <div className="p-3 ms-2">
                        <KebabMenuIcon />
                      </div>

                      <div className="kebab-action--menu bordered-container">
                        <ul>
                          <li onClick={() => setSharedConnections(true)}>
                            <SharedConnectionIcon width="15" height="15" color="#5B5B5B" className="me-2" />
                            Shared Connection
                          </li>
                          <li onClick={() => setReport(true)}>
                            <ReportIcon className="me-2" />
                            Report
                          </li>
                          <li onClick={() => setBlock(true)}>
                            <BlockIcon className="me-2" />
                            Block
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  <div className="network-list-card--details">
                    {isSearchResult ? (
                      <div className="icon-module d-flex my-2">
                        {userProfileInfo?.follow_count > 0 && (
                          <div className="me-3">
                            <img src={circle} className="icon" alt="circle" />
                            <span className="ms-1">{userProfileInfo?.follow_count}</span>
                          </div>
                        )}

                        {userProfileInfo?.connect_count > 0 && (
                          <div>
                            <img src={myBridge} className="icon" alt="myBridge" />
                            <span>{userProfileInfo?.connect_count}</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={`network-list-card--actions my-2 ${
                      isSearchResult ? 'network-list-card-via-search' : ''
                    }`}
                  >
                    {isSearchResult ? (
                      connection ? (
                        <>
                          <div className="w-100 d-flex justify-content-between text-center">
                            <Button className="btn-white me-2 w-50" onClick={() => goToProfile(user)}>
                              <ViewProfile height={16} width={16} className="me-1" /> View Profile
                            </Button>
                            <Button className="btn-white w-50" onClick={() => handleStartChat(user, 'user')}>
                              <Message height={16} width={16} /> Message
                            </Button>
                          </div>
                          <div className="w-100 d-flex justify-content-between text-center">
                            <Button className="btn-white me-2 w-50" onClick={() => handleStartChat(user, 'user')}>
                              <EventIcon height={16} width={16} className="me-1" />{' '}
                              <span className={classNames(callInitiated ? 'heartbeat' : '')}>{callInitiatedText}</span>
                            </Button>
                            <Button className="btn-white w-50" onClick={navigateTo}>
                              <ScheduleIcon height={16} width={16} className="me-1" /> Schedule
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-100 d-flex justify-content-between text-center">
                            <Button className="btn-white me-2 w-50" onClick={() => goToProfile(user)}>
                              <ViewProfile height={16} width={16} className="me-1" /> View Profile
                            </Button>
                            {event ? (
                              <Button className="btn-white w-50" onClick={() => setOpenRequestModal(true)}>
                                <ChatIcon height={16} width={16} className="me-1" /> Message
                              </Button>
                            ) : (
                              <Button
                                className="btn-white w-50"
                                disabled={handShake || selfUser}
                                onClick={() => setOpenRequestModal(true)}
                              >
                                <HandShakeSimpleIcon
                                  height={16}
                                  width={16}
                                  className="me-1"
                                  color={!handShake ? '' : 'grey'}
                                />{' '}
                                {!handShake ? 'Handshake' : 'Request sent'}
                              </Button>
                            )}
                          </div>

                          {event ? (
                            <div className="w-100 d-flex justify-content-between text-center">
                              <Button className="btn-white me-2 w-50" onClick={() => handleStartChat(user, 'user')}>
                                <EventIcon height={16} width={16} className="me-1" />{' '}
                                <span className={classNames(callInitiated ? 'heartbeat' : '')}>
                                  {callInitiatedText}
                                </span>
                              </Button>
                              <Button className="btn-white w-50" onClick={navigateTo}>
                                <CalendarIcon height={16} width={16} className="me-1" /> Schedule
                              </Button>
                            </div>
                          ) : (
                            <div className="w-100 text-center">
                              <Button
                                disabled={following || followReqSent || selfUser}
                                className="btn-blue me-2 w-100"
                                onClick={() => handleFollowReq()}
                              >
                                <FollowIcon height={16} width={16} color="#fff" className="me-1" />{' '}
                                {following || followReqSent ? 'Following' : 'Follow'}
                              </Button>
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <Button className="btn-secondary" onClick={() => handleStartChat(user, 'user')}>
                          <Message height={16} width={16} /> Message
                        </Button>
                        <div className="kebab-action">
                          <Button className="btn-link">
                            <ThreeDot />
                          </Button>
                          <Card className="kebab-action--menu">
                            <ul>
                              <li onClick={() => handleStartChat(user, 'user')}>
                                <EventIcon width={15} height={15} className="me-1" />
                                <span className={classNames(callInitiated ? 'heartbeat' : '')}>
                                  {callInitiatedText}
                                </span>
                              </li>
                              <li onClick={() => navigate('profile/schedule')}>
                                <ScheduleIcon className="me-1" />
                                Schedule
                              </li>
                              <li onClick={() => setSharedConnections(true)}>
                                <SharedConnectionIcon width="15" height="15" color="#5B5B5B" className="me-2" />
                                Shared Connection
                              </li>
                              <li onClick={() => setReport(true)}>
                                <ReportIcon className="me-2" />
                                Report
                              </li>
                              <li onClick={() => setBlock(true)}>
                                <BlockIcon className="me-2" />
                                Block
                              </li>
                            </ul>
                          </Card>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
        // </Card>
      )}

      {block && <BlockModal name={userName} id={user.id} onClose={() => setBlock(false)} />}

      {report && <ReportModal name={userName} id={user.id} onClose={() => setReport(false)} />}

      {sharedConnections && (
        <SharedConnections
          id={user.id}
          icon={user.image}
          designation={user.position}
          name={userName}
          onClose={() => setSharedConnections(false)}
        />
      )}

      {openRequestModal && (
        <Modal
          onClose={handleClose}
          className="confirmation-modal"
          heading={<h3>{!addCustomMessage ? 'You can customize this invitation' : `Invite ${userName} to connect`}</h3>}
          action={
            <>
              {directRequest ? (
                <Button
                  className="h-36"
                  variant="btn-secondary"
                  onClick={() => {
                    setAddCustomMessage(true);
                    setDirectRequest(false);
                  }}
                >
                  Add a note
                </Button>
              ) : (
                <Button className="h-36" variant="btn-secondary" onClick={handleClose}>
                  Cancel
                </Button>
              )}
              <Button className="h-36" onClick={() => handleHandshakeReq(user?.id)}>
                Send
              </Button>
            </>
          }
        >
          {directRequest && (
            <p>
              <TickMarkIcon /> You can add a note to personalize your invitation to {userName}
            </p>
          )}
          {addCustomMessage && (
            <div>
              <p className="mb-2">Build a quality network by connecting only with people you know.</p>
              <div className="clearfix">
                <TextArea
                  label="Message (optional)"
                  placeholder="Ex We know each other from..."
                  rows="3"
                  onChange={(e) => {
                    setCount(e.target.value.length);
                    setCustomMessage(e.target.value);
                  }}
                  className={count >= 300 && 'is-invalid'}
                  maxLength={300}
                  error={count >= 300 && 'Character Limit is reached'}
                />
                <span className="form-text float-end text-end mt-0">{count}/300</span>
              </div>
              {/* <div className="mt-4">
                <h6 className="mb-1">PREMIUM</h6>
                <p className="mb-1">
                  Don’t know Kamal kant? Send an InMail with Premium to introduce yourself. More people reply to an
                  InMail than a connection request.
                </p>
                <p>
                  <Link>Retry Premium Free</Link>
                </p>
              </div> */}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default NetworkCard;
