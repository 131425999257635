import { Input, TextArea } from 'components/common/Input';

import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { askTestimonal, giveTestimonal, giveTestimonalForAsked } from 'store/actions/testimonial.Action';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {} from 'preact/hooks';
import Button from 'components/common/Button';

const AskTestimonialModal = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();

  const { data: connectionData } = useSelector((state) => state.connection);
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const [message, setMessage] = useState(
    `Can you please write a testimonial that I can include on my profile.\n\nLet me know if you have questions.\n-${userProfileInfo?.first_name} ${userProfileInfo?.last_name} \nwww.mybridge.me/${userProfileInfo?.slug}`,
  );
  const [selectedUser, setSelectedUser] = useState([]);

  const getUserList = () => {
    let userList = [];
    for (let i = 0; i < selectedUser.length; i++) {
      userList.push(selectedUser[i].value);
    }
    return userList;
  };

  const askRequest = () => {
    let giver = getUserList();
    const payload = {
      message,
      giver: giver,
      form_type: 'ASK',
    };
    dispatch(askTestimonal(payload));
    onClose();
  };

  const handleSelect = (data) => {
    setSelectedUser(data);
  };

  const connectionOptions = () => {
    return connectionData?.results?.map((t) => {
      return { value: t.id, label: t.first_name + ' ' + t.last_name };
    });
  };
  const memoizedConnectionOptions = useMemo(connectionOptions, [connectionData]);

  return (
    <div className="testimonial-modal-body">
      <div className="d-flex flex-column gap-3">
        <Select
          className="dropdown"
          components={makeAnimated()}
          options={memoizedConnectionOptions}
          placeholder="Add up to three connections"
          value={selectedUser}
          onChange={handleSelect}
          isSearchable={true}
          isMulti
          isOptionDisabled={() => selectedUser.length >= 3}
        />

        <TextArea
          placeholder={'message'}
          className={'testimonial-data'}
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="mt-4 text-end">
        <Button variant="btn-secondary" className="h-36" data-dismiss="modal" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="btn-primary" className="ms-3 h-36" data-dismiss="modal" onClick={askRequest}>
          Send{' '}
        </Button>
      </div>
    </div>
  );
};

export default AskTestimonialModal;
