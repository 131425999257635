import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'components/common/Input';
import { Search } from 'components/common/Icons';
import { Text, Button } from 'components/StyledComponents';
import { Camera } from 'components/common/Icons';
import { useNavigate } from 'react-router-dom';

import UserCard from 'pages/Dashboard/Circle/UserCard';
import SectionLoader from 'components/common/Loader/sectionLoader';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { getConnectionsData, getFollowers } from 'store/slices/connectionSlice';
import ellipse from 'assets/icons/ellipse.svg';
import UserPresence from 'components/common/UserPresense';
import {
  BlockIcon,
  ChatIcon,
  EventIcon,
  KebabMenuIcon,
  Message,
  ThreeDot,
  ReportIcon,
  ScheduleIcon,
  SharedConnectionIcon,
  StatusIcon,
  ViewProfile,
  FollowIcon,
  HandShakeSimpleIcon,
  TickMarkIcon,
  CalendarIcon,
} from 'components/common/Icons';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import noNetworkFound from "assets/circle-network.png"
import FilterSection from 'components/common/FilterSection';
import styled from 'styled-components';

const ListContainer = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-bottom: 1px solid #d9d9d9;
`;

const ProfileActionText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  word-break: break-word;
  font-weight: ${(props) => props.fWeight || 400};
  font-size: ${(props) => props.fSize || '16px'};
  line-height: 120%;
  color: ${(props) => props.color || '#000000'};
`;

const NetworkList = (props) => {
  const { type } = props;
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { data, follow, loading } = useSelector((state) => state.connection);
  const [updatedFilters, setUpdatedFilters] = useState();

  const { item, flag } = useSelector((state) => state.search);
  const results = item?.results;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (type !== 'network') {
      dispatch(getFollowers(type));
    } else {
      dispatch(getConnectionsData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);


  const connectionsFilterData = [
    { label: '1st Degree', name: '1st_degree', value: '1st_degree' },
    { label: '2nd Degree', name: '2nd_degree', value: '2nd_degree' },
    { label: '3rd Degree', name: '3rd_degree', value: '3rd_degree' },
  ];

  const locationFilterData = [...new Set(results?.map((item) => item.location))]
    .map((item) => {
      if (item) return { label: item, name: item, value: item };
    })
    .filter((e) => e !== undefined);

  const companyFilterData = [
    ...new Set(results?.map((item) => item?.experiences?.map((company) => company?.company_name))?.flat()),
  ]
    .map((item) => {
      if (item !== null) return { label: item, name: item, value: item };
    })
    .filter((e) => e !== undefined);

  const filters = [
    { label: 'Connections', type: 'checkbox', data: connectionsFilterData, showKeywords: false },
    {
      label: 'Locations',
      type: 'checkbox',
      data: locationFilterData,
      showInput: true,
      showKeywords: false,
    },
    {
      label: 'Current company',
      type: 'checkbox',
      data: companyFilterData,
      showInput: true,
      showKeywords: false,
    },
    { label: 'Keyword', type: '', data: null, showInput: false, showKeywords: true },
  ];
  const filterData = (collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedDate = groupData(filters);
    setUpdatedFilters(groupedDate);
  };


  const renderNetworkData = () => {
    let userdata = <div className='d-flex py-4 justify-content-center align-items-center' style={{ flexDirection: "column" }}>
        <img src={noNetworkFound} />
        <span style={{fontSize:18, fontWeight:500}}>No files or folders found...</span>
        <ProfileActionText
            className="cursor-pointer"
            fSize="14px"
            color="#ffffff"
            onClick={() => navigate('/invitations')}
          >
            Manage invitations
          </ProfileActionText>
    </div>

    if (type === 'network' && data.count !== 0) {
      return (
        <>
         
          <div className="network-item-list">
        
            {data?.results?.map((user, i) => {
              return <UserCard manageNetwork connection user={user} key={i} />;
            })}
          </div>
        </>
      );
    }

    if (type !== 'network' && follow.count !== 0) {
      return (
        <>
          <div className="network-item-list">
            {follow?.map((user, i) => {
              return <UserCard manageNetwork following user={user} key={i} />;
            })}
          </div>
        </>
      );
    }

    return userdata;
  };

  return (
    <div>
      {/* <Input
        type="url"
        value={userProfileInfo?.public_profile_url}
        placeholder="Search"
        startIcon={<Search />}
        className="rounded"
        // left={false}
        containerClass="d-flex"
      /> */}
       <FilterSection filters={filters} filterData={filterData} />
      {loading ? <SectionLoader /> : <>{renderNetworkData()}</>}
    </div>
  );
};

export default NetworkList;
