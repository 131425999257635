import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { monthOptions, getDayOptionsInMonth, generalCauses } from 'config/formConstants';

import { BorderLessButton } from 'components/StyledComponents';
import Button from 'components/common/Button';
import { Input, TextArea } from 'components/common/Input';
import Dropdown from 'components/common/Dropdown';
import Checkbox from 'components/common/Checkbox';

import { rangeOfFullYears } from 'components/utils';
import { userProfileAddInfo } from '../../../../../store/slices/userProfileSlice';
import { LANG_CHOICES } from 'data/data';
import Pills from 'components/common/Pills';

import { CausesIcon, DOBIcon, EmailIcon, InterestIcon, LanguageIcon, PhoneIcon } from 'components/common/Icons';

const initialState = {
  mobile_number: '',
  interested_in: '',
  lang: '',
  day: '',
  month: '',
  year: '',
  causes_you_care: '',
  email: '',
};

const PersonalDetailsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const PersonalDetailsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`;

const PersonalDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PersonalDetailsLabel = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #525252;
`;

const PersonalDetailsValue = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) => props.color || '#525252'};
`;

const IntrestWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const IntrestContainer = styled.div`
  padding: 4px 11px;
  gap: 10px;
  background: #f1f1f1;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
`;

const IntrestText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding: 9px 11px;
`;

const PersonalDetails = (props) => {
  const dispatch = useDispatch();
  const { userProfileInfo, isProfileUpdated } = useSelector((state) => state.userProfile);

  const [errorFields, setErrorFields] = useState({});
  const years = rangeOfFullYears('1940');
  const [fields, setFields] = useState(initialState);
  const [editMode, setEditMode] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(generalCauses.length).fill(false));
  const [causesYou, setCausesYou] = useState();
  const [tempIntrest, setTempIntrest] = useState('')
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const fieldsOnChange = (e) => {
    const { name, value } = e.target;
    setFields((prevValues) => ({ ...prevValues, [name]: value }));

  };

  const addValueToIntrest = (e) =>{
    if (e.keyCode === 13) {
      const { name, value } = e.target;
      let data = fields[name]+","+value 
      setFields((prevValues) => ({ ...prevValues, interested_in: data }));
      setTempIntrest('')
    }
  
  }

  const getIntrestArr = () =>{
   return (fields?.interested_in && fields?.interested_in?.includes(',')) ? fields?.interested_in?.split(','): [fields?.interested_in] 
  }

  const onIntrestdataChange = (e)  =>{
    const { name, value } = e.target;
    setTempIntrest(value)
  }

  const handleAddPersonalDetails = () => {
    const formData = new FormData();
    formData.append('interested_in', fields.interested_in);
    formData.append('mobile_number', fields.mobile_number);
    formData.append('lang', fields.lang);
    formData.append('dob', `${fields.year}-${fields.month}-${fields.day}`);
    formData.append('email', fields.email);
    const cares = generalCauses.filter((item, index) => checkedState[index] === true).join(', ');
    formData.append('causes_you_care', cares);
    dispatch(userProfileAddInfo(formData));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isProfileUpdated) {
      setEditMode(false);
    }
  }, [isProfileUpdated]);

  useEffect(() => {
    if (userProfileInfo) {
      let causes_you = userProfileInfo?.causes_you_care?.split(',');
      // console.log(causes_you)
      setCausesYou(causes_you);
    }
  }, [userProfileInfo]);

  const editPersonalDetail = () => {
    setErrorFields({});
    setEditMode(true);
    const dob = userProfileInfo?.dob?.split('-');
    const caresList = userProfileInfo?.causes_you_care?.split(', ');
    const updatedCheckedState = [];
    generalCauses.forEach((care, index) => updatedCheckedState.push(caresList.includes(care) ? true : false));
    setCheckedState(updatedCheckedState);
    const obj = { ...userProfileInfo, year: dob ? dob[0] : '', month: dob ? dob[1] : '', day: dob ? dob[2] : '' };
    setFields(obj);
  };

  const handleCheckbox = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);
  };

  const removeCollegeCourses = (idx) =>{
    let intArr = getIntrestArr(fields.interested_in);
    let finalArr = intArr.filter((_, index) => index !== idx);
    setFields((prevValues) => ({ ...prevValues, interested_in: finalArr?.toString() }));
  }

  if (editMode) {
    return (
      <>
        <div>
          <p className="helper-text">Mobile Number </p>
          <Input
            type="text"
            placeholder="Mobile number"
            value={fields.mobile_number}
            name="mobile_number"
            onChange={fieldsOnChange}
          />
          {errorFields.mobile_number && <div className="text-danger">{errorFields.mobile_number}</div>}
        </div>

        <div className="mt-3">
          <p className="helper-text">Languages </p>
          <Dropdown
            id={'lang'}
            name="lang"
            options={LANG_CHOICES}
            title={'lang'}
            handleChange={handleChange}
            selectedValue={fields.lang}
          />
          {errorFields.lang && <div className="text-danger">{errorFields.lang}</div>}
        </div>

        <div className="mt-3">
          <p className="helper-text">Date of Birth</p>
          <div className="d-flex gap-3">
            <Dropdown
              id={'day'}
              name={'day'}
              options={getDayOptionsInMonth(fields.month, fields.year)}
              title={'Day'}
              handleChange={handleChange}
              selectedValue={Number(fields.day)}
              defaultValue={'Day'}
            />
            <Dropdown
              id={'month'}
              name={'month'}
              options={monthOptions}
              title={'month'}
              handleChange={handleChange}
              selectedValue={fields.month}
              defaultValue={'Month'}
            />
            <Dropdown
              id={'year'}
              name={'year'}
              options={years}
              title={'Year'}
              handleChange={handleChange}
              selectedValue={fields.year}
              defaultValue={'Year'}
            />
          </div>
          {errorFields.dob && <div className="text-danger">{errorFields.dob}</div>}
        </div>

        <div>
          <p className="helper-text">Interests</p>
          <Input
            className="mb-3"
            rows="4"
            type="text"
            placeholder="Interests"
            value={tempIntrest}
            name="interested_in"
            maxLength={350}
            onChange={onIntrestdataChange}
            onKeyDown={addValueToIntrest}
          />
          {errorFields.interested_in && <div className="text-danger">{errorFields.interested_in}</div>}
        </div>
        <div style={{display: 'flex',flexFlow: 'row wrap',gap: 10}}>
          {getIntrestArr()?.map((crs, idx) => {
            return (
              crs?<Pills key={idx} label={crs} onClose={() => removeCollegeCourses(idx)} />:''
              // 
            );
          })}
          </div>

        <div className="my-3">
          <p>Causes you care about?</p>
        </div>
        <div className="row">
          <div className="col-12 col-md"></div>
          {generalCauses.map((care, index) => (
            <div key={care}>
              <Checkbox
                value={care}
                checked={checkedState[index]}
                onChange={() => handleCheckbox(index)}
                className="mt-1"
                name={care}
                label={care}
              />
            </div>
          ))}
        </div>

        <div className="d-flex gap-2 justify-content-end mt-4">
          <Button variant="btn-secondary" className="h-36" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          <Button variant="btn-primary" className="h-36" onClick={handleAddPersonalDetails}>
            Save
          </Button>
        </div>
      </>
    );
  }

  const onInterestChange = (index) => {
    console.log('userProfileInfo?.interested_in', getIntrestArray(userProfileInfo?.interested_in));
    let payloadInterset = getIntrestArray(userProfileInfo?.interested_in);
    payloadInterset.splice(index, 1);
    const formData = new FormData();
    formData.append('interested_in', payloadInterset.toString());
    formData.append('mobile_number', userProfileInfo?.mobile_number);
    formData.append('lang', userProfileInfo?.lang);
    formData.append('dob', `${userProfileInfo?.dob}`);
    formData.append('email', userProfileInfo?.email);
    formData.append('causes_you_care', userProfileInfo?.causes_you_care);
    dispatch(userProfileAddInfo(formData));
  };

  const getIntrestArray = (intrest) => {
    return intrest?.indexOf(',') ? intrest.split(',') : [intrest];
  };

  return (
    <>
      <PersonalDetailsHeader>
        <BorderLessButton onClick={editPersonalDetail}>Edit</BorderLessButton>
      </PersonalDetailsHeader>

      {userProfileInfo?.email && (
        <PersonalDetailsContainer>
          <div>
            <EmailIcon />
          </div>
          <PersonalDetailsContent>
            <PersonalDetailsLabel>Email</PersonalDetailsLabel>
            <PersonalDetailsValue color="#3d5a80">{userProfileInfo?.email}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {userProfileInfo?.mobile_number && (
        <PersonalDetailsContainer>
          <div>
            <PhoneIcon />
          </div>
          <PersonalDetailsContent>
            <PersonalDetailsLabel>Mobile Number</PersonalDetailsLabel>
            <PersonalDetailsValue color="#3d5a80">{userProfileInfo?.mobile_number}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {userProfileInfo?.lang && (
        <PersonalDetailsContainer>
          <div>
            <LanguageIcon />
          </div>
          <PersonalDetailsContent>
            <PersonalDetailsLabel>Languages</PersonalDetailsLabel>
            <PersonalDetailsValue>
              {LANG_CHOICES.find((item) => item.value === userProfileInfo?.lang)?.label}
            </PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {userProfileInfo?.dob && (
        <PersonalDetailsContainer>
          <div>
            <DOBIcon />
          </div>
          <PersonalDetailsContent>
            <PersonalDetailsLabel>Date of Birth</PersonalDetailsLabel>
            <PersonalDetailsValue>{moment(userProfileInfo?.dob).format('DD MMMM YYYY')}</PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {userProfileInfo?.interested_in && (
        <PersonalDetailsContainer>
          <div>
            <InterestIcon />
          </div>
          <PersonalDetailsContent style={{ gap: '20px' }}>
            <PersonalDetailsLabel>Interests</PersonalDetailsLabel>
            {/* <PersonalDetailsValue>{userProfileInfo?.interested_in}</PersonalDetailsValue> */}
            <IntrestWrapper>
              {getIntrestArray(userProfileInfo?.interested_in).map((indIntrest, index) => (
                <Pills key={index} label={indIntrest} onClose={() => onInterestChange(index)} />
                // <IntrestContainer>
                //   <IntrestText>{indIntrest}</IntrestText>
                // </IntrestContainer>
              ))}
            </IntrestWrapper>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}

      {userProfileInfo?.causes_you_care && (
        <PersonalDetailsContainer>
          <div>
            <CausesIcon />
          </div>
          <PersonalDetailsContent>
            <PersonalDetailsLabel>Causes you care about?</PersonalDetailsLabel>
            <PersonalDetailsValue>
              {userProfileInfo?.causes_you_care?.length > 0 ? (
                <ul>
                  {causesYou?.map((item, i) => {
                    if (causesYou.length === i + 1) {
                      return <span key={i}>{item}</span>;
                    } else {
                      return <span key={i}>{item}, </span>;
                    }
                  })}
                </ul>
              ) : (
                <div>-</div>
              )}
            </PersonalDetailsValue>
          </PersonalDetailsContent>
        </PersonalDetailsContainer>
      )}
    </>
  );
};

export default PersonalDetails;
