import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FollowerCard from 'components/common/FollowerCard';
import SearchBar from 'components/common/SearchBar';
import noFollowersBackground from '../../../../assets/images/no-followers-bg.svg';
import { BorderLessButton, Text } from 'components/StyledComponents';
import InviteFriends from 'pages/Teams/Profile/Overview/InviteFriends';
import { patchCompanyInfo } from 'store/actions/companyPages.Action';
import FilterSection from 'components/common/FilterSection';
import { groupData, queryGeneratorForFilters } from 'utils/queryGenerator';
import { searchItem } from 'store/slices/searchSlice';
import SectionLoader from 'components/common/Loader/sectionLoader';

const Followers = (props) => {
  const { companyId } = props;
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const { item, isItemsLoading } = useSelector((state) => state.search);
  const results = item?.results;
  const [showModalType, setShowModalType] = useState(null);
  const onClose = () => setShowModalType(null);
  const dispatch = useDispatch();
  const [updatedFilters, setUpdatedFilters] = useState({});
  const [query, setQuery] = useState('');
  useEffect(() => {
    if (companyPageDetail && companyPageDetail.id) {
      dispatch(
        searchItem({
          query: `&${queryGeneratorForFilters(updatedFilters)}&custom_param_name=company&custom_param_id=${
            companyPageDetail.id
          }`,
          item: 'people',
          searchQuery: query,
        }),
      );
    }
  }, [dispatch, query, updatedFilters, companyPageDetail]);
  const locationFilterData = [...new Set(results?.map((item) => item.location))]
    .map((item) => {
      if (item) return { label: item, name: item, value: item };
    })
    .filter((e) => e !== undefined);

  const companyFilterData = [
    ...new Set(results?.map((item) => item?.experiences?.map((company) => company?.company_name))?.flat()),
  ]
    .map((item) => {
      if (item !== null) return { label: item, name: item, value: item };
    })
    .filter((e) => e !== undefined);

  const filters = [
    {
      label: 'Locations',
      type: 'checkbox',
      data: locationFilterData,
      showInput: true,
      showKeywords: false,
    },
    {
      label: 'Current company',
      type: 'checkbox',
      data: companyFilterData,
      showInput: true,
      showKeywords: false,
    },
    { label: 'Keyword', type: '', data: null, showInput: false, showKeywords: true },
  ];

  const onClickSave = (companyData) => {
    const { company_size, image, banner_image, ...payload } = companyData;
    dispatch(patchCompanyInfo(payload));
    onClose();
  };
  const filterData = useCallback((collectedFilters) => {
    const filters = collectedFilters?.filter((item) => item !== undefined && !Object.values(item)?.includes(null));
    const groupedDate = groupData(filters);
    setUpdatedFilters(groupedDate);
  }, []);

  return (
    <div className="container mt-4">
      {results?.length >= 0 && (
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-xl-4 p-0">
            <SearchBar />
          </div>
          <div className="col-xl-8 p-0 follower-filters">
            <FilterSection
              nobottomBorder={true}
              filters={filters}
              className="justify-content-end"
              filterData={filterData}
              filterAlign={'justify-content-end'}
            />
          </div>
        </div>
      )}
      {isItemsLoading ? (
        <SectionLoader />
      ) : (
        <>
          <div className="row mt-4">
            {results?.length ? (
              results?.map((user) => <FollowerCard key={user.id} user={user} />)
            ) : (
              <div className="no-followers-image-container">
                <div
                  style={{
                    backgroundImage: `url(${noFollowersBackground})`,
                  }}
                  className="no-followers-image"
                />
                <p className="d-block mb-1">{companyPageDetail?.name} followers will appear here</p>
                <BorderLessButton
                  onClick={() => setShowModalType('invite')}
                  className="d-flex align-items-center justify-content-center borderless-btn"
                >
                  <Text color="#5B5B5B">Invite Connections</Text>
                </BorderLessButton>
              </div>
            )}
          </div>
        </>
      )}
      {showModalType === 'invite' && <InviteFriends teamId={companyId} onClose={onClose} onClickSave={onClickSave} />}
    </div>
  );
};
export default Followers;
