import styled from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 38px;
  background: #ffff;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #3d5a80;
`;

const EditEvent = ({ eventId, ...rest }) => {
  return <Container {...rest}>Edit</Container>;
};

export default EditEvent;
