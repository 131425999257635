import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editPostFunc, newPost, rePost } from 'api/post';
import { Spinner } from 'components/common/Loader';
import PostFormContext from 'context/PostFromContext';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 18px;

  background: ${({ disabled }) => (disabled ? '#c7c8cb' : '#3d5a80')};
  border-radius: 5px;
  border: none;
  margin: 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #ffffff;
`;

const PostButton = (props) => {
  const { type, setShowModal } = props;
  const {
    postText,
    images,
    video,
    visibility,
    replyAudience,
    repostData,
    setImages,
    setPostText,
    setVideo,
    repostSuccess,
    editable,
    tagged,
    setTagged
  } = useContext(PostFormContext);

  const whiteSpace = postText?.match(/\s/g);
  const letterCount = postText?.length - (whiteSpace?.length || 0);
  const queryClient = useQueryClient();
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);

  const { mutate: postBlog, isLoading } = useMutation(newPost, {
    onSuccess: (data) => {
      setShowModal && setShowModal(false);
      setImages([]);
      setTagged([]);
      setPostText('');
      setVideo(null);
      queryClient.invalidateQueries('feed');
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { mutate: repost, isLoading: rePostLoading } = useMutation(rePost, {
    onSuccess: (data) => {
      repostSuccess();
      setTagged([]);
      setShowModal && setShowModal(false);
      setImages([]);
      setPostText('');
      setVideo(null);
      queryClient.invalidateQueries('feed');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: editPost, isLoading: editPostLoading } = useMutation(editPostFunc, {
    onSuccess: (data) => {
      repostSuccess();
      setTagged([]);
      setShowModal && setShowModal(false);
      setImages([]);
      setPostText('');
      setVideo(null);
      queryClient.invalidateQueries('feed');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const createNewPost = useCallback(() => {
    const formData = new FormData();

    if (images.length === 0 && letterCount === 0 && video === null) {
      return;
    }

    // if posText is more than 300 characters then return
    if (letterCount > 300) {
      return;
    }

    formData.append('visibility', visibility);

    formData.append('reply_control', replyAudience);

    if (letterCount > 0) {
      formData.append('content', postText);
    }

    if (companyPageDetail !== undefined && type === 'company') {
      formData.append('company_id', companyPageDetail.id);
    }

    if (tagged && tagged?.length) {
      console.log("Taggin", tagged)
      tagged?.forEach?.(t =>
        formData.append('tagged_user_ids', t?.id)
      )
    }

    if (editable) {
      let isMediaChangedFlag = false;
      const mediaIds = [];

      images.forEach((image) => {
        // we are adding new images to form data if user has added new post other than existing one then it will be added to form data
        const isImage = image?.type?.includes('image');
        if (isImage) {
          isMediaChangedFlag = true;
          formData.append('media', image);
        }

        // if user has not added any media the we also have to retain existing media for that we are pulling out thosde media ids and adding to form data
        // just making sure gif media id should not be included in origin media ids
        const mediaId = image?.id;
        const notGif = image?.type !== 'gif';
        if (mediaId && notGif) {
          mediaIds.push(mediaId);
        }

        // now we are adding gif to form data
        if (image.type === 'gif') {
          formData.append('gif', image?.images?.original?.url);
        }
      });

      // here one more way to identify media is changed or not
      // if state is different than original media the we can say media is changed
      const isMediaDifferwithOriginal = repostData?.media?.length !== images?.length;
      if (isMediaDifferwithOriginal) {
        isMediaChangedFlag = true;
      }

      if (isMediaChangedFlag) {
        formData.append('media_updated', true);
      }

      // here we are adding media ids to form data
      formData.append('media_ids', mediaIds.toString());

      editPost({ id: repostData.id, body: formData });
      return;
    }

    images.forEach((image) => {
      const isImage = image?.type?.includes('image');
      if (isImage) {
        formData.append('media', image);
      }

      // now we are adding gif to form data
      if (image.type === 'gif') {
        formData.append('gif', image?.images?.original?.url);
      }
    });

    if (video !== null) {
      formData.append('media', video);
    }

    if (repostData !== null) {
      repost({ id: repostData.id, body: { content: postText, visibility, reply_control: replyAudience } });
    } else {
      postBlog(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, postText, video, visibility, replyAudience, postBlog, tagged]);

  const isMediaAttached = images.length > 0 || video !== null;
  const disableButton = (letterCount <= 0 || letterCount > 300) && !isMediaAttached;

  return (
    <Container disabled={disableButton} onClick={createNewPost}>
      {isLoading || rePostLoading || editPostLoading ? <Spinner secondary /> : editable ? 'Update' : 'Post'}
    </Container>
  );
};

export default PostButton;
