import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import circle from 'assets/icons/circle.svg';
import myBridge from 'assets/icons/myBridge.svg';

import { Text } from 'components/StyledComponents';
import { MyBridgeAvatar } from 'components/common/Avatar';
import {
  BlockIcon,
  ChatIcon,
  EventIcon,
  HandShakeSimpleIcon,
  KebabMenuIcon,
  ReportIcon,
  ScheduleIcon,
  SharedConnectionIcon,
  UsersIcon,
} from '../Icons';

import UserPresence from '../UserPresense';
import ReportModal from 'components/ReportModal';
import HandshakeModal from 'components/Modals/HandshakeModal';
import BlockModal from 'pages/Dashboard/Circle/BlockModal';
import SharedConnections from 'pages/Dashboard/Circle/SharedConnections';
import { useNavigate } from 'react-router-dom';
import { startConversationWithUser, clear } from 'store/slices/connectionSlice';
import { CometChat } from '@cometchat-pro/chat';

import './user-card.scss';

const UserCard = ({ user }) => {
  const { id, first_name, last_name, position, profile_pic, follow_count, degree_count , location } = user;

  const fullName = `${first_name} ${last_name}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isConnection = true;

  const [modalType, setModalType] = useState('');
  const [callInitiated, setCallInitiated] = useState(false);
  const [callInitiatedText, setCallInitiatedText] = useState('Meet Now');

  const handleStartChat = (user) => {
    dispatch(startConversationWithUser(user));
  };

  const goToProfile = (user) => {
    navigate(`/p/${user.slug}/personal-details`);
  };

  const handleMeetNow = (user) => {
    const receiverID = user.user_uuid;
    const callType = CometChat.CALL_TYPE.VIDEO;
    const receiverType = CometChat.RECEIVER_TYPE.USER;

    const call = new CometChat.Call(receiverID, callType, receiverType);

    CometChat.initiateCall(call).then(
      (outGoingCall) => {
        // console.log('Call initiated successfully:', outGoingCall);
        setCallInitiated(true);
        setCallInitiatedText('Calling');
      },
      (error) => {
        // console.log('Call initialization failed with exception:', error);
        setCallInitiated(false);
        setCallInitiatedText('Meet Now');
      },
    );
  };

  return (
    <>
      <div className="user-card-container" key={id}>
        <div className="user-info-container">
          <div className="icon-status-container">
            <MyBridgeAvatar icon={profile_pic} size={70} name={fullName} />
            <span className={`status`}>
              <UserPresence userId={user?.user_uuid} />
            </span>
          </div>

          <div className="name-position-container">
            <Text fS="16px" fW="500">
              {fullName}
            </Text>
            <Text fS="12px" fW="500" color="#747474">
              {position}
            </Text>
            <Text fS="13px" color="#747474">
              {location}
            </Text>
          </div>

          <div className="kebab-action">
            <div className="p-3 ms-2">
              <KebabMenuIcon />
            </div>

            <div className="kebab-action--menu bordered-container">
              <ul>
                <li onClick={() => setModalType('sharedConnections')}>
                  <SharedConnectionIcon width="15" height="15" color="#5B5B5B" className="me-2" />
                  Shared Connection
                </li>
                <li onClick={() => setModalType('report')}>
                  <ReportIcon className="me-2" />
                  Report
                </li>
                <li onClick={() => setModalType('block')}>
                  <BlockIcon  className="me-2" />
                  Block
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="connection-circle-container">
          {degree_count && degree_count.first > 0 && (
            <div className="d-flex align-items-center gap-1">
              <img src={circle} alt="" />
              <Text margin="0 5px" fS="15px">{degree_count.first}</Text>
            </div>
          )}

          {follow_count > 0 && (
            <div className="d-flex align-items-center gap-1">
              <img src={myBridge} alt="" />
              <Text margin="0 5px" fS="15px">{follow_count}</Text>
            </div>
          )}
        </div>

        <div className="user-actions-container">
          <div className="d-flex gap-2">
            <button className="gray-button" onClick={()=>goToProfile(user)} >
              <UsersIcon width={15} height={15} />
              <Text margin="0 5px" fS="13px">View Profile</Text>
            </button>
            {isConnection ? (
              <button className="gray-button" onClick={() => handleStartChat(user)}>
                <ChatIcon width={15} height={15} />
                <Text margin="0 5px" fS="13px">Message</Text>
              </button>
            ) : (
              <button className="gray-button" onClick={() => setModalType('handshake')}>
                <HandShakeSimpleIcon width={15} height={15} />
                <Text margin="0 5px" fS="13px">Handshake</Text>
              </button>
            )}
          </div>

          <div className="d-flex gap-2">
            {isConnection ? (
              <>
                <button className="gray-button" onClick={() => handleStartChat(user)}>
                  <EventIcon width={15} height={15} />
                  <Text margin="0 5px" fS="13px">{callInitiatedText}</Text>

                </button>
                <button className="gray-button" onClick={() => navigate('/profile/calendar')}>
                  <ScheduleIcon />
                  <Text margin="0 5px" fS="13px">Schedule</Text>
                </button>
              </>
            ) : (
              <button className="orange-button">
                <UsersIcon width={15} height={15} color="#fff" />
                <Text margin="0 5px" fS="13px" color="#fff">
                  Follow
                </Text>
              </button>
            )}
          </div>
        </div>
      </div>

      {modalType === 'block' && <BlockModal name={fullName} id={user.id} onClose={setModalType} />}

      {modalType === 'report' && <ReportModal name={fullName} id={user.id} onClose={setModalType} />}

      {modalType === 'handshake' && <HandshakeModal user={user} id={user.id} onClose={setModalType} />}

      {modalType === 'sharedConnections' && (
        <SharedConnections
          id={user.id}
          icon={user.image}
          designation={user.position}
          name={fullName}
          onClose={setModalType}
        />
      )}
    </>
  );
};

export default UserCard;
