import React from 'react';
import MutualUser from 'components/Network/MutualUser';

const ThirdDegreeConnect = (props) => {
  const { user } = props;
  return (
    <div className='invite-section'>
            <div className='invite-container mb-3'>
            {user ? <><div className='pt-3 pb-3 d-flex justify-content-between'>
                    <p className='m-0'>People you may know based on who you follow</p>
                </div>
                <div className="mutual-container-grid">
                    {user.map((user, i) => (
                       <MutualUser key={i} userdetails={user} />
                      ))}
                </div></> : <p className="text-center">No Data Found!</p>}
            </div>
        </div>
  )
}

export default ThirdDegreeConnect;