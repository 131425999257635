import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowDown, ArrowUp } from 'components/common/Icons';
import { Text, BorderLessButton } from 'components/StyledComponents';
import Modal from 'components/common/Modal/Modal';
import PostForm from 'components/PostForm';
import InviteFriends from 'pages/Teams/Profile/Overview/InviteFriends';
import { patchCompanyInfo } from 'store/actions/companyPages.Action';

const Analytics = (props) => {
  const { analyticsList, isComapnypage, companyId } = props;
  const [showModalType, setShowModalType] = useState('');
  const onClose = () => setShowModalType('');
  const dispatch = useDispatch();
  const onClickSave = (companyData) => {
    const { company_size, image, banner_image, ...payload } = companyData;
    dispatch(patchCompanyInfo(payload));
    onClose();
  };
  return (
    <div className="bordered-container mt-4 p-3">
      <Text fS="18px" fW="600" color="#5B5B5B">
        Analytics
      </Text>
      {analyticsList?.map((indAnalytics, index) => {
        let subTitle = indAnalytics?.sub_title;
        let isClickable = false;
        let modalType = '';
        if (isComapnypage && indAnalytics?.title.toLowerCase() === 'new followers') {
          subTitle = 'Invite connections to follow';
          modalType = 'invite';
          isClickable = true;
        }
        if (isComapnypage && indAnalytics?.title.toLowerCase() === 'post impressions') {
          subTitle = 'Start a post';
          modalType = 'post';
          isClickable = true;
        }
        return (
          <div
            className={`d-flex justify-content-between mt-4`}
            key={index}
            style={
              index != analyticsList.length - 1
                ? { paddingBottom: '0.75rem', borderBottom: '1px solid #D9D9D9' }
                : { paddingBottom: '0.75rem' }
            }
          >
            <div className="d-flex flex-column">
              <Text fS="15px" fW="500" color="#3D5A80" lh="20px">
                {indAnalytics?.total_count}
              </Text>
              <Text fS="15px" color="#5B5B5B" lh="25px">
                {indAnalytics?.title}
              </Text>
              {!isClickable ? (
                <Text fS="16px" fw="400" color="#3D5A80" lh="25px">
                  {subTitle}
                </Text>
              ) : (
                <BorderLessButton
                  color="#3D5A80"
                  fontWeight="400"
                  fontSize="16px"
                  padding="1px"
                  tAlign="left"
                  onClick={() => setShowModalType(modalType)}
                >
                  {subTitle}
                </BorderLessButton>
              )}
            </div>

            <div className="d-flex align-items-baseline">
              <Text fS="15px" color={indAnalytics?.data?.color_code}>
                {indAnalytics?.data?.val}
              </Text>
              {indAnalytics?.data?.performance === 'DEC' ? (
                <ArrowDown className="ms-2" />
              ) : (
                <ArrowUp className="ms-2" />
              )}
            </div>
          </div>
        );
      })}
      {showModalType === 'post' && (
        <Modal
          onClose={() => setShowModalType(false)}
          heading={
            <Text fW={600} fS="24px" color="#3D5A80">
              Create a post
            </Text>
          }
          className={'testimonial-popup'}
        >
          <PostForm type={'company'} setShowModal={showModalType} />
        </Modal>
      )}
      {showModalType === 'invite' && <InviteFriends teamId={companyId} onClose={onClose} onClickSave={onClickSave} />}
    </div>
  );
};

export default Analytics;
