export const queryGeneratorForFilters = (filters) => {
  let query = '';
  if (filters) {
    Object.entries(filters).forEach((entry) => {
      const [key, value] = entry;
      if (query) {
        query += '&';
      }
      query += `${key}=${value}`;
    });
  }
  return query;
};

const replaceSpace = (str) => {
  if (str.includes(' ')) return str.replace(/ /g, '%20');
  else return str;
};

export const groupData = (filteredData) => 
  filteredData?.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null) {
        acc[key] = acc[key] ? `${replaceSpace(acc[key])},${replaceSpace(obj[key])}` : replaceSpace(obj[key]);
      }
    });
    return acc;
  }, {});
