import { ImageIcon } from 'components/common/Icons';
import React, { useRef } from 'react';

const FileInput = ({ onChange, onClick, setTextFocus }) => {
  const fileRef = useRef();
  const onPickFile = (event) => {
    onChange([...event.target.files]);
    setTextFocus(true);
  };
  return (
    <button onClick={onClick}>
      <label>
        <input ref={fileRef} accept="image/*" onChange={onPickFile} type="file" />
        <ImageIcon />
      </label>
    </button>
  );
};

export default FileInput;
