import React, { memo } from 'react';
import styled from 'styled-components';
import { SimpleCloseIcon } from '../Icons';
import closeIcon from './closeIcon.svg';

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 11px;
  gap: 10px;

  background: #f1f1f1;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
`;

const PillText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 18px */

  color: #000000;
`;

const PillCloseIcon = styled.img`
  cursor: pointer;
`;

const Pill = ({ label, onClose, className, closeIconColor }) => {
  return (
    <PillContainer className={className ? className : ''}>
      <PillText>{label}</PillText>
      {/* <PillCloseIcon src={closeIcon} alt="" onClick={onClose} /> */}
      <SimpleCloseIcon color={closeIconColor ? closeIconColor : "#3D5A80"} width={12} height={12} onClick={onClose} />
    </PillContainer>
  );
};

const PillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
`;

export const PillsContainer = ({ children }) => {
  return <PillsWrapper>{children}</PillsWrapper>;
};

export default memo(Pill);
