import { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { PostCard } from 'components/PostCard';
import PostContext from 'context/PostContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPostFunc } from 'api/post';
import DeletePostModal from './DeletePostModal';
import Card from 'components/common/Card';
import { useSelector } from 'react-redux';

const EventPost = ({ data, repost, width, postType }) => {
  const [post, setPost] = useState(data);
  const queryClient = useQueryClient();

  const { allConnectionIDs } = useSelector(state => state.connection)
  const { circle, following } = allConnectionIDs ?? {}
  const { author } = post ?? {}
  const { userProfileInfo } = useSelector(state => state.userProfile)

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const { refetch } = useQuery(['getPostDetail', data?.id], getPostFunc, {
    refetchOnWindowFocus: false,
    enabled: false,
    initialData: () => {
      const result = queryClient?.getQueryData(['feed', undefined]);
      if (result) {
        const { pages, pageParams = [] } = result || {};
        const pageNumber = pageParams?.length - 1;
        const posts = pages[pageNumber]?.results || [];
        const post = posts?.find((post) => post?.id?.toString() === data?.id?.toString());
        return post;
      }
      return null;
    },
    onSuccess: (data) => {
      setPost(data);
    },
    onError: (err) => {
      console.log(err)
    }
  });
  useEffect(() => {
    console.log("updated post", post)
  }, [post])

  useEffect(() => {
    setPost(data);
  }, [JSON.stringify(data)]);

  const removePost = () => {
    console.log("removed")
    setPost({})
  }

  const commentAllowed = useMemo(() => {
    if (data?.author?.id === userProfileInfo?.id) return true;
    const isInCircle = circle?.includes(data?.author?.id);
    const isInFollowers = following?.includes(data?.author?.id);
    if (data?.reply_control === 0) {
      return true
    } else if (data?.reply_control === 1) {
      return isInCircle
    } else if (data?.reply_control === 2) {
      return isInCircle || isInFollowers
    }
  }, [data, circle, following])

  const value = {
    postData: post,
    repost,
    updatePost: refetch,
    removePost,
    commentAllowed
  };


  return (
    <PostContext.Provider value={value}>
      {post?.id ?
        <PostCard repost={value.repost} width={repost ? 'auto' : width}>
          {postType !== 'default' && <PostCard.Header />}
          <PostCard.Body postType={postType} />
          {!repost && <PostCard.Footer />}
        </PostCard> : ""
      }
    </PostContext.Provider>
  );
};

EventPost.propTypes = {
  data: PropTypes.object.isRequired,
  repost: PropTypes.bool,
};

EventPost.defaultProps = {
  repost: false,
};

export default memo(EventPost);
