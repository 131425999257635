import SingleFilter from './SingleFilter';
import { useEffect, useState } from 'react';
import _ from 'lodash';

const FilterSection = ({ filters, filterData, nobottomBorder, filterAlign, feature }) => {
  const [collectedFilters, setCollectedFilters] = useState();

  const collectAllFilters = (filterProps) => {
    setCollectedFilters([...filterProps].flat());
  };

  const filter = filters.map((filter, i) => {
    return <SingleFilter key={i} {...filter} setCollectedFilters={collectAllFilters} feature={feature}/>;
  });

  useEffect(() => {
    filterData(collectedFilters);
  }, [filterData, collectedFilters]);

  return (
    <>
      <div className={`d-flex ${filterAlign}`}>{filter}</div>
      {!nobottomBorder && <hr className="mt-3" />}
    </>
  );
};

export default FilterSection;
