import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

const initialState = {
  newBlog: {},
  newsArticles: [],
};

export const createBlog = createAsyncThunk('createBlog', async (body) => {
  const obj = {
    url: APIEndPoints.CreateBlog,
    method: 'POST',
    body: body,
    isFormData: true,
  };
  const response = await API(obj);
  return response;
});

export const listDraft = createAsyncThunk('listDraft', async (body) => {
  const obj = {
    url: APIEndPoints.listBlog,
  };
  return await API(obj);
});

export const getNewsArticles = createAsyncThunk('getNewsArticles', async (payload) => {
  const obj = {
    url: APIEndPoints.GetNews(payload.pageNo, payload.limitPerPage),
    isNoToken:true,
  };
  return await API(obj);
});

export const listPublished = createAsyncThunk('listPublished', async (body) => {
  const obj = {
    url: APIEndPoints.listPublished,
  };
  return await API(obj);
});

export const publishBlog = createAsyncThunk('publishBlog', async (query, fn) => {
  const obj = {
    url: APIEndPoints.publishBlog(query),
    method: 'PUT',
  };
  return await API(obj);
});

export const deleteBlog = createAsyncThunk('deleteBlog', async (query) => {
  const obj = {
    url: APIEndPoints.PostDelete(query),
    method: 'DELETE',
  };
  return await API(obj);
});

export const editBlog = createAsyncThunk('editBlog', async (body) => {
  const obj = {
    url: APIEndPoints.editBlog(body.id),
    body: body.formdata,
    isFormData: true,
    method: 'PUT',
  };
  return await API(obj);
});

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.newBlog.post_id = action.payload;
    },
    setTitle: (state, action) => {
      state.newBlog.title = action.payload;
    },
    setDescription: (state, action) => {
      state.newBlog.description = action.payload;
    },
    setCoverImage: (state, action) => {
      state.newBlog.cover_image = action.payload;
    },
    setBlogType: (state, action) => {
      state.newBlog.blogType = action.payload;
    },
    clearCreateBlog: (state, action) => {
      state.newBlog = {};
    },
  },
  extraReducers: {
    [getNewsArticles.fulfilled]: (state, action) => {
      state.newsArticles = action?.payload;
    },
  },
});
export const { clearCreateBlog, setBlogType, setCoverImage, setTitle, setDescription, setId } = blogSlice.actions;
export default blogSlice.reducer;
