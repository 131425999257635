import axios from 'axios';

export default async function API({ url, method = 'GET', body = '', isFormData = false, isNoToken = false, headers }) {
  let options = {
    method,
    headers: {
      Accept: 'application/json',
    },
  };
  if (!isNoToken) {
    options.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access');
  }
  if (body) {
    options.data = body;
  }
  if (!isFormData) {
    options.headers['content-type'] = 'application/json';
  }
  if (headers) {
    options.headers['content-type'] = 'multipart/form-data';
  }

  const fullUrl = `${process.env.REACT_APP_API_URL}${url}`;

  try {
    const { data } = await axios(fullUrl, options);
    return data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}
