import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NetworkCard from 'pages/Dashboard/Circle/NetworkCard';
import SectionLoader from 'components/common/Loader/sectionLoader';
import PageCard from 'components/PageCard/index.js';
import Post from 'components/Post';
import Button from 'components/common/Button';
import EventPost from 'pages/Dashboard/Feed/Event';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import JobCardIndividual from 'components/JobCard/JobCardIndividual';
import { searchCall } from 'store/slices/searchSlice';
import SearchContext from 'context/searchContext';
import TeamCard from '../Team/TeamCard';
import DefaultSearchPage from '../DefaultSearchPage';
import Card from 'components/common/Card';
import { Flex } from 'components/core';

const AllSearch = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { query } = useContext(SearchContext);
  
  // useEffect(() => {
  //   // if(query !== '') {
  //     dispatch(searchItem({query: ''}));
  //   // }
  //   // eslint-disable-next-line
  // }, [])
  useEffect(() => {
    dispatch(searchCall(query ? query : ''));
  }, [query]);

  const { data, flag } = useSelector((state) => state.search);
  const { data: connectionData } = useSelector((state) => state.connection);
  const results = data.results;
  useEffect(() => {
    const filteredJobs = results?.jobs?.filter((item) => {
      return item.job_status === 'active';
    });
    setJobs(filteredJobs);
  }, [data?.results?.jobs]);


  const navigateToPeople = () => {
    navigate('/search/people');
  };

  const navigateToPages = () => {
    navigate('/search/pages');
  };

  const navigateToPosts = () => {
    navigate('/search/posts');
  };

  const navigateToEvents = () => {
    navigate('/search/events');
  };

  const navigateToJobs = () => {
    navigate('/jobs');
  };
  const navigateToTeams = () => {
    navigate('/search/team');
  };
  // const navigateToArticles = () => {
  //   navigate('/search/article');
  // };

  const people = results?.people?.slice(0, 4)?.map((item) => {
    return (
      <Col xs={6}>
        <NetworkCard
          connection={connectionData?.results?.map((e) => e.id).includes(item.id)}
          isSearchResult={true}
          user={item}
          key={item.id}
        />
      </Col>
    );
  });

  const pages = results?.pages?.slice(0, 4)?.map((item) => {
    return (
      <Col xs={6}>
        <PageCard
          {...item}
          connection={connectionData?.results?.map((e) => e.id).includes(item.id)}
          isSearchResult={true}
          key={item.id}
        />
      </Col>
    );
  });

  const jobList = jobs?.slice(0, 4)?.map((item) => {
    return (
      <div className="col-12 col-sm-6">
        <JobCardIndividual isSearchResult={true} data={item} key={item.id} />
      </div>
    );
  });

  const posts = results?.post?.slice(0, 4)?.map((item) => {
    return (
      <Card style={{ width: '100%', overflow: 'hidden' }}>
        <Post modal={false} key={item.id} data={item} />
      </Card>
    );
  });

  const events = results?.events?.slice(0, 4).map((item) => {
    return (
      <div className="d-flex justify-content-center">
        <EventPost data={item} key={item.id} width={'75%'} />;
      </div>
    );
  });

  const teams = results?.team?.slice(0, 4)?.map((item) => {
    return (
      <Col xs={6}>
        <TeamCard {...item} isSearchResult={true} key={item.id} />
      </Col>
    );
  });

  return flag ? (
    <SectionLoader />
  ) : people?.length || pages?.length || posts?.length || teams?.length ? (
    <>
      <h5 className="mb-4">All</h5>

      {people?.length > 0 && (
        <Row>
          <h5 className="mb-3">People</h5>
          {people}
          {results?.people?.length > 4 && (
            <div className="py-3 text-center">
              <Button className="btn-secondary px-5" onClick={navigateToPeople}>
                View All
              </Button>
            </div>
          )}
          <hr className="mb-4" />
        </Row>
      )}
      {jobList?.length > 0 && (
        <>
          <h5 className="mb-4">Jobs</h5>
          <div className="row">{jobList}</div>
          <div className="py-3 text-center">
            <Button className="btn-secondary px-5 align-items-center" onClick={navigateToJobs}>
              View All
            </Button>
          </div>
          <hr className="mb-4" />
        </>
      )}
      {pages?.length > 0 && (
        <Row>
          <h5 className="mb-3">Pages</h5>
          {pages}
          {results?.pages?.length > 4 && (
            <div className="py-3 text-center">
              <Button className="btn-secondary px-5" onClick={navigateToPages}>
                View All
              </Button>
            </div>
          )}
          <hr className="mb-4" />
        </Row>
      )}
      {posts?.length > 0 && (
        <>
          <h5 className="mb-4">Posts</h5>
          <div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>
            <Flex gap="1rem" direction="column">
              {posts}
            </Flex>
          </div>
          {results?.post?.length > 4 && (
            <div className="py-3 text-center">
              <Button className="btn-secondary px-5" onClick={navigateToPosts}>
                View All
              </Button>
            </div>
          )}
          <hr className="mb-4" />
        </>
      )}
      {/* {events?.length > 0 && (
        <>
          <h5 className="mb-4">Events</h5>
          {events}
          {results?.events?.length > 4 && (
            <div className="py-3 text-center">
              <Button className="btn-secondary px-5" onClick={navigateToEvents}>
                View All
              </Button>
            </div>
          )}
        </>
      )} */}

      {teams?.length > 0 && (
        <Row>
          <h5 className="mb-4">Teams</h5>
          {teams}
          {results?.team?.length > 4 && (
            <div className="py-3 text-center">
              <Button className="btn-secondary px-5" onClick={navigateToTeams}>
                View All
              </Button>
            </div>
          )}
        </Row>
      )}
    </>
  ) : (
    <DefaultSearchPage />
  );
};

export default AllSearch;
