import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1px;
  margin: 0 auto;
  overflow: hidden;

  div:last-child:nth-child(odd) {
    grid-column: 1 / -1;
  }
`;

export default Grid;
