import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Checkbox from 'components/common/Checkbox';
import { BorderLessButton, Text } from 'components/StyledComponents';
import { ChevronDown, Email } from 'components/common/Icons';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { getCandidates, updateCandidateStatus, clearGlobalJobId } from 'store/actions/companyJobs.Actions';
import { MyBridgeAvatar } from 'components/common/Avatar';
import { VerticalLine } from 'components/StyledComponents';
import { CircleDotIcon, StarIcon, UnsureIcon, LocationIcon } from './icons';
import noJobsfound from 'assets/images/blank_jobs_image.svg';
const candidateStatusMap = {
  APPLIED: 'NEW',
  OFFERED: 'INTERESTED',
  SHORTLISTED: 'UNSURE',
  REJECTED: 'NOT INTERESTED',
};

const Candidates = (props) => {
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const { companyJobList, candidateList, globalJobId } = useSelector((state) => state.companyJobsReducer);

  const dispatch = useDispatch();

  const [filter, setFilter] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [limitPerPage, setLimitPerPage] = useState(50);
  const [activeMenu, setActiveMenu] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filterJobTitle, setFilterJobTitle] = useState('Select Jobs');

  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState([]);

  const ref = React.useRef();
  useOnClickOutside(ref, () => setActiveMenu(null));

  useEffect(() => {
    if (globalJobId && companyPageDetail?.id) {
      setSelectedJob(globalJobId.id);
      setFilterJobTitle(globalJobId.title);
      dispatch(
        getCandidates({ id: companyPageDetail.id, jobId: globalJobId.id, status: filter, perPage: limitPerPage }),
      );
    } else if (companyPageDetail?.id) {
      dispatch(getCandidates({ id: companyPageDetail.id, jobId: selectedJob, status: filter, perPage: limitPerPage }));
    }
    dispatch(clearGlobalJobId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyPageDetail, selectedJob, filter, limitPerPage, refresh, globalJobId]);
  const onChangeFilter = (filterName) => {
    setFilter(filterName);
  };
  const onChangeJob = (jobId) => {
    setSelectedJob(jobId);
    setActiveMenu('');
  };

  const onChangeLimit = (limit) => {
    setLimitPerPage(limit);
    setActiveMenu('');
  };

  const onCheckAllCandidates = (checked) => {
    if (checked) {
      setSelectedCandidatesIds([...candidateList?.results?.map((c) => c.id)]);
    } else {
      setSelectedCandidatesIds([]);
    }
  };

  const onCheckCandidate = (id, checked) => {
    if (checked) {
      setSelectedCandidatesIds((ids) => [...ids, id]);
    } else {
      setSelectedCandidatesIds((ids) => ids.filter((cid) => cid !== id));
    }
  };

  const handleCandidateStatus = (candidate, newStatus) => {
    dispatch(
      updateCandidateStatus({
        jobId: candidate?.jobs_id,
        status: newStatus,
        candidateId: candidate?.candidate_details?.id,
      }),
    ).then(() => setRefresh((refresh) => !refresh));
  };

  return (
    <div className="candidate-jobs-container mx-3 px-3">
      <div className="candidate-filter">
        <div className="candidate-filter-buttons">
          <button
            className={`candidate-filter-button ${filter === '' ? 'active' : ''}`}
            onClick={() => onChangeFilter('')}
          >
            <Text color={filter === '' ? '#e18c3e' : '#5b5b5b'}>All</Text>
          </button>
          <button
            className={`candidate-filter-button ${filter === 'APPLIED' ? 'active' : ''}`}
            onClick={() => onChangeFilter('APPLIED')}
          >
            <Text color={filter === 'APPLIED' ? '#e18c3e' : '#5b5b5b'}>
              <CircleDotIcon width={12} height={12} /> New
            </Text>
          </button>
          <button
            className={`candidate-filter-button ${filter === 'OFFERED' ? 'active' : ''}`}
            onClick={() => onChangeFilter('OFFERED')}
          >
            <Text color={filter === 'OFFERED' ? '#e18c3e' : '#5b5b5b'}>
              <StarIcon width={12} height={12} /> Interested
            </Text>
          </button>
          <button
            className={`candidate-filter-button ${filter === 'SHORTLISTED' ? 'active' : ''}`}
            onClick={() => onChangeFilter('SHORTLISTED')}
          >
            <Text color={filter === 'SHORTLISTED' ? '#e18c3e' : '#5b5b5b'}>
              <UnsureIcon /> Unsure
            </Text>
          </button>
          <button
            className={`candidate-filter-button ${filter === 'REJECTED' ? 'active' : ''}`}
            onClick={() => onChangeFilter('REJECTED')}
          >
            <Text color={filter === 'REJECTED' ? '#e18c3e' : '#5b5b5b'}>
              <StarIcon width={12} height={12} color="#FF0600" /> Not Interested
            </Text>
          </button>
          <button
            className={`candidate-filter-button ${filter === 'within-100mi' ? 'active' : ''}`}
            onClick={() => onChangeFilter('within-100mi')}
          >
            <Text color={filter === 'within-100mi' ? '#e18c3e' : '#5b5b5b'}>
              <LocationIcon /> Within 100 Mi
            </Text>
          </button>
        </div>

        <Text fS="16px" fW="500" color="#747474">
          (1-{limitPerPage}) of {candidateList?.count} Candidates
        </Text>

        <div className="candidate-filter-pagination-header mt-3">
          <div className="check-box-section">
            <Checkbox className={'m-0 check-box-all'} onChange={(e) => onCheckAllCandidates(e.target.checked)} />
            <BorderLessButton
              className="d-flex align-items-center"
              borderRadius="5px"
              background="#fff"
              fontWeight="500"
              fontSize="16px"
              color="#5B5B5B"
              hover={false}
              onClick={() => setActiveMenu('bulk-actions')}
            >
              Bulk Actions
              <ChevronDown className="ms-2" color="#868585" />
            </BorderLessButton>
            {activeMenu === 'bulk-actions' && (
              <div className="action-menu" ref={ref}>
                <div className="action" onClick={() => setActiveMenu()}>
                  <div className="icon">
                    <Email width={17} color="#868585" />
                  </div>
                  <Text color="#5B5B5B"> Email candidates</Text>
                </div>
              </div>
            )}
          </div>

          <div className="select-jobs-section">
            <BorderLessButton
              className="d-flex align-items-center"
              borderRadius="5px"
              background="#fff"
              fontWeight="500"
              fontSize="16px"
              color="#5B5B5B"
              hover="#fff"
              onClick={() => setActiveMenu('select-jobs')}
            >
              {filterJobTitle}
              <ChevronDown className="ms-2" color="#868585" />
            </BorderLessButton>
            {activeMenu === 'select-jobs' && (
              <div className="action-menu" ref={ref}>
                {companyJobList?.results?.map((job) => (
                  <div
                    className="action"
                    key={job.id}
                    onClick={() => {
                      onChangeJob(job.id);
                      setFilterJobTitle(job.title);
                    }}
                  >
                    <Text color="#5B5B5B">{job.title}</Text>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="page-limit-section">
            <BorderLessButton
              className="d-flex align-items-center me-2"
              padding="0 8px"
              borderRadius="5px"
              background="#fff"
              fontWeight="500"
              fontSize="16px"
              color="#5B5B5B"
              onClick={() => setActiveMenu('limit-per-page')}
              hover={false}
            >
              {limitPerPage}
              <ChevronDown className="ms-2" color="#868585" />
            </BorderLessButton>
            {activeMenu === 'limit-per-page' && (
              <div className="action-menu limit" ref={ref}>
                {[50, 75, 100].map((limit) => (
                  <div className="action" key={limit} onClick={() => onChangeLimit(limit)}>
                    <Text color="#5B5B5B">{limit}</Text>
                  </div>
                ))}
              </div>
            )}
            <Text fW="500" color="#5B5B5B">
              per page
            </Text>
          </div>
        </div>
      </div>

      <div className="candidates-container">
        {candidateList?.results?.length > 0 ? (
          candidateList?.results?.map((candidate) => {
            const { first_name, last_name, position, location } = candidate?.candidate_details; // candidate should have all this information

            return (
              <div className="candidate bordered-container">
                <div className="check-box-section">
                  <Checkbox
                    className={'m-0 check-box-all'}
                    checked={selectedCandidatesIds.find((id) => id === candidate.id)}
                    onChange={(e) => onCheckCandidate(candidate.id, e.target.checked)}
                  />
                </div>

                <div className="candidate-image">
                  <MyBridgeAvatar
                    icon={candidate?.candidate_details?.profile_pic}
                    name={`${first_name} ${last_name}`}
                    size={60}
                    round={true}
                  />
                </div>

                <div className="candidate-information">
                  <div className="candidate-name-actions">
                    <div className="name">
                      <Text fS="15px" fW="500" color="#3D5A80">
                        {`${first_name} ${last_name}`}{' '}
                        <span className="text-success">
                          {candidate.status === 'APPLIED' && candidateStatusMap[candidate.status]}
                        </span>
                      </Text>
                    </div>
                    <div className="actions">
                      <BorderLessButton
                        fontSize="14px"
                        fontWeight={candidate.status === 'OFFERED' ? '600' : '400'}
                        color={candidate.status === 'OFFERED' ? '#3D5A80' : '#5B5B5B'}
                        onClick={() => handleCandidateStatus(candidate, 'OFFERED')}
                      >
                        Interested
                      </BorderLessButton>
                      <VerticalLine />
                      <BorderLessButton
                        fontSize="14px"
                        fontWeight={candidate.status === 'SHORTLISTED' ? '600' : '400'}
                        color={candidate.status === 'SHORTLISTED' ? '#3D5A80' : '#5B5B5B'}
                        onClick={() => handleCandidateStatus(candidate, 'SHORTLISTED')}
                      >
                        Unsure
                      </BorderLessButton>
                      <VerticalLine />
                      <BorderLessButton
                        fontSize="14px"
                        fontWeight={candidate.status === 'REJECTED' ? '600' : '400'}
                        color={candidate.status === 'REJECTED' ? '#3D5A80' : '#5B5B5B'}
                        onClick={() => handleCandidateStatus(candidate, 'REJECTED')}
                      >
                        Not Interested
                      </BorderLessButton>
                    </div>
                  </div>

                  <div className="candidate-application">
                    <Text fS="13px" color="#5B5B5B">
                      {position}
                    </Text>
                    <Text fS="12px" color="#747474">
                      {location}
                    </Text>
                    <Text fS="12px" fW="500" color="#3D5A80">
                      {moment(candidate.created_at).format('MMM DD, YYYY')}
                    </Text>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="job-blank-container">
              <img src={noJobsfound} alt="" width="182" className="mb-3" />
              <h3>No Candidates applied for this job yet.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default Candidates;
