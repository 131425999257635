import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Address, Email, Globe, Page, Phone, Star } from 'components/common/Icons';
import { Input } from 'components/common/Input';
import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button';
import { Text, VerticalLine } from 'components/StyledComponents';
import ToggleSwitch from 'components/common/ToggleSwitch';
import { useNavigate } from 'react-router-dom';
import { patchCompanyInfo } from 'store/actions/companyPages.Action';

import ReadMore from 'components/common/ReadMore';
import HoursModal from '../modals/HoursModal';
import EditPageModal from '../modals/EditPageModal';
import InviteFriends from 'pages/Teams/Profile/Overview/InviteFriends';

import '../Home/homestyles.css';

const CompanyBio = (props) => {
  const dispatch = useDispatch();
  const { companyPageDetail } = useSelector((state) => state.companyPageReducer);
  const navigate = useNavigate();
  const [showModalType, setShowModalType] = useState('');

  const [companyUrl, setCompanyUrl] = useState('');
  const [publicCompany, setPublicCompany] = useState(false);

  let timeout = useRef();
  useEffect(() => {
    clearTimeout(timeout.current);
    if (companyPageDetail?.id && publicCompany !== companyPageDetail?.is_visible) {
      timeout.current = setTimeout(() => {
        dispatch(
          patchCompanyInfo({
            id: companyPageDetail?.id,
            is_visible: publicCompany,
          }),
        );
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicCompany]);

  useEffect(() => {
    if (companyPageDetail?.id) {
      setCompanyUrl(companyPageDetail?.slug);
      setPublicCompany(companyPageDetail?.is_visible);
    }
  }, [companyPageDetail]);

  const onClose = () => setShowModalType('');

  const onClickSave = (companyData) => {
    const { company_size, image, banner_image, ...payload } = companyData;
    dispatch(patchCompanyInfo(payload));
    onClose();
  };

  return (
    <div>
      <div className="">
        <div className="companyprofile">
          <div className="companyprofile2">
            <Text fS="18px" fW={600} color="#5B5B5B">
              Company Profile
            </Text>
          </div>
          <div className="text-container">
            <Text fS="15px" color="#3D5A80">
              Make myCompany profile public
            </Text>
            <ToggleSwitch
              name={'company_profile'}
              value={publicCompany}
              defaultValue={publicCompany}
              size="small"
              onChange={(value) => setPublicCompany(value)}
            />
          </div>
          <div className="edit-public-profile-container">
            <p className="edit-public-profile-url links" onClick={() => setShowModalType('companyUrl')}>
              Edit public profile & URL
            </p>
          </div>
        </div>

        <div className="frame-49527 mt-3">
          <div className="frame-495501">
            <p className="grow-your-page">Grow your Page</p>
          </div>
          <div className="frame-487111">
            <p className="adding-admins-helps-you">Adding admins helps your Page start and join conversations</p>
          </div>
          <div className="frame-49551" onClick={() => navigate('/settings/team')}>
            <p className="add-page-admins">Add Page admins</p>
          </div>
          <div className="frame-495041" onClick={() => setShowModalType('inviteFriends')}>
            <p className="invite-connections cursor-pointer">Invite Connections</p>
          </div>
        </div>

        <div className="intro-container mt-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            <Text fS="18px" fW="600" color="#5B5B5B">
              Introduction
            </Text>

            <Text fW="500" color="#3D5A80" className="cursor-pointer" onClick={() => setShowModalType('editPage')}>
              Edit intro
            </Text>
          </div>

          <div className="d-flex flex-column justify-content-between w-100">
            <Text fS="15px" color="#868585" className="mb-3 mt-1">
              <ReadMore wordcount={100}>{companyPageDetail?.introduction}</ReadMore>
            </Text>
            <VerticalLine />
          </div>

          <div className="frame-49286">
            <div className="d-flex">
              <Page />

              <div className="d-flex flex-column ps-3">
                <Text fS="15px" color="#5B5B5B" fW="500">
                  Page
                </Text>
                <Text fS="15px" color="#868585">
                  {companyPageDetail?.main_industry}
                </Text>
              </div>
            </div>

            <div className="d-flex mt-3">
              <Address />

              <div className="d-flex flex-column ps-3">
                <Text fS="15px" color="#5B5B5B" fW="500">
                  Address
                </Text>
                <Text fS="15px" color="#868585">
                  {companyPageDetail?.address}
                </Text>
              </div>
            </div>

            <div className="d-flex mt-3">
              <Phone />

              <div className="d-flex flex-column ps-3">
                <Text fS="15px" color="#5B5B5B" fW="500">
                  Phone Number
                </Text>
                <Text fS="15px" color="#3D5A80">
                  {companyPageDetail?.phone_number}
                </Text>
              </div>
            </div>

            <div className="d-flex mt-3">
              <Email />

              <div className="d-flex flex-column ps-3">
                <Text fS="15px" color="#5B5B5B" fW="500">
                  Email
                </Text>
                <Text fS="15px" color="#3D5A80">
                  {companyPageDetail?.email}
                </Text>
              </div>
            </div>

            {companyPageDetail?.website && (
              <div className="d-flex mt-3">
                <Globe />

                <div className="d-flex flex-column ps-3">
                  <Text fS="15px" color="#5B5B5B" fW="500">
                    Website
                  </Text>
                  <Text fS="15px" color="#3D5A80">
                    {companyPageDetail?.website}
                  </Text>
                </div>
              </div>
            )}

            {/* <div className="d-flex mt-4">
              <Star />

              <div className="d-flex flex-column ps-3">
                <Text fS="15px" color="#5B5B5B">Rating 4.3 (7 reviews)</Text>
              </div>
            </div> */}
          </div>

          {/* <div className="col text-end frame-487112">
            <Text fS="15px" color="#3D5A80" className='me-2'>
              Allow reviews
            </Text>
            <ToggleSwitch
              name={'allow_reviews'}
              value={true}
              size='small'
              //onChange={(value) => setPublicCompany(value)}
            />
          </div>

          <div className="w-100">
            <VerticalLine />
          </div> */}

          {/* <div className="d-flex justify-content-between w-100">
            <Text color="#3D5A80">Open Now</Text>
            <Text color="#3D5A80" className="cursor-pointer" onClick={() => setShowModalType('hours')}>
              Select hours
            </Text>
          </div> */}
        </div>
      </div>

      {showModalType === 'companyUrl' && (
        <Modal
          onClose={() => setShowModalType('')}
          heading={
            <Text color="#3D5A80" fS="18px" fW={600}>
              Edit public profile & URL
            </Text>
          }
          action={
            <div className="action-btn">
              <Button variant="btn-secondary" className="h-36 me-2" onClick={() => setShowModalType('')}>
                Cancel
              </Button>
              <Button
                className="h-36"
                onClick={async () => {
                  const payload = {
                    id: companyPageDetail.id,
                    slug: companyUrl,
                  };
                  await dispatch(patchCompanyInfo(payload));
                  setShowModalType('');
                }}
              >
                Save
              </Button>
            </div>
          }
        >
          <Input value={companyUrl} onChange={(e) => setCompanyUrl(e.target.value)} />
        </Modal>
      )}

      {showModalType === 'hours' && <HoursModal onClose={onClose} onClickSave={onClickSave} />}
      {showModalType === 'editPage' && <EditPageModal onClose={onClose} onClickSave={onClickSave} />}
      {showModalType === 'inviteFriends' && (
        <InviteFriends teamId={companyPageDetail?.id} type="company" onClose={onClose} onClickSave={onClickSave} />
      )}
    </div>
  );
};
export default CompanyBio;
