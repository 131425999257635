import React, { useEffect, useState } from 'react';
import { NotificationIcon, PushIcon, RemindersBell } from 'components/common/Icons';
import ToggleSwitch from 'components/common/ToggleSwitch/ToggleSwitch';
import { APIEndPoints } from 'store/common/endPoint';
import API from 'store/common/api';
import { useMutation } from '@tanstack/react-query';

const AllToggle = ({ module, settings, onChange }) => {
  const number = Math.floor(100 + Math.random() * 900);
  // const isTrue = settings.find((item) => item._for === module && item.enabled);
  const [isTrue, setIsTrue] = useState(false)
  const [enable, setEnable] = React.useState(isTrue ? true : false);

  useEffect(() => {
    let flag = false;
    const { pushSettings, emailSettings, smsSettings } = settings ?? {}
    if (pushSettings && pushSettings.length) {
      flag = pushSettings.find((item) => item._for === module && item.enabled);
      if (flag) {
        (setIsTrue(true))
        return;
      }
    }
    if (emailSettings && emailSettings.length) {
      flag = emailSettings.find((item) => item._for === module && item.enabled);
      if (flag) {
        (setIsTrue(true))
        return;
      }
    }
    if (smsSettings && smsSettings.length) {
      flag = smsSettings.find((item) => item._for === module && item.enabled);
      if (flag) {
        (setIsTrue(true))
        return;
      }
    }

    setIsTrue(flag);
  }, [settings])

  const { mutateAsync } = useMutation({
    mutationFn: async (body) => {
      return Promise.all([
        await API({ method: 'POST', url: `${APIEndPoints.Notification}/push/`, body: JSON.stringify(body) }),
        await API({ method: 'POST', url: `${APIEndPoints.Notification}/sms/`, body: JSON.stringify(body) }),
        await API({ method: 'POST', url: `${APIEndPoints.Notification}/email/`, body: JSON.stringify(body) })
      ]);
    },
  });

  const onChange_ = async () => {
    const current = !isTrue;
    await mutateAsync({ _for: module, enabled: current });
    onChange?.();
    setTimeout(() => {
      setEnable(current);
    }, 100);
  };

  return (
    <div className="d-flex align-items-center justify-content-between border-bottom py-3">
      <div>
        <RemindersBell width={24} height={25} className="me-3" />
        Allow notifications on myBridge
      </div>
      <ToggleSwitch name={module + number} label="Push" value={isTrue} defaultValue={isTrue} onChange={onChange_} />
    </div>
  );
};

export default AllToggle;
