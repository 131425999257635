import styled from 'styled-components';

export const PostEventIgnoreButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  border-radius: 5px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #3d5a80;
`;

export default PostEventIgnoreButton;
