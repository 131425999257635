import React, { useState, useEffect } from 'react';
import Layout from 'components/Layouts';
import { TabContent, HorizontalTabs, Tab } from 'components/common/Tabs/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import FirstDegreeConnect from './FirstDegree';
import SecondDegreeConnect from './SecondDegree';
import ThirdDegreeConnect from './ThirdDegree';
import { getSharedConnectionsData } from 'store/slices/connectionSlice';
import SectionLoader from 'components/common/Loader/sectionLoader';

const MutualConnections = () => {
    let { id, uid } = useParams();
    if (!id) {
        id = 'first-degree';
    }
    const [, setOpenTab] = useState(id || 'first-degree');
    const { mutualConnections, childLoading, loading } = useSelector((state) => state.connection);
    const dispatch = useDispatch();
    const firstDegree = mutualConnections.results;

    useEffect(() => {
        dispatch(getSharedConnectionsData({id: uid}));
    }, []);

    return (
        <React.Fragment>
            <section className='networks-container'>
                <div className='container'>
                    <div className='networks-container--body'>
                        <h5 className="fw-medium">
                            Mutual connections
                        </h5>
                        {loading ? <SectionLoader /> : <div className="networks-container--tabContainer">
                            <HorizontalTabs>
                                <Tab setOpenTab={setOpenTab} openTab={id} path={`mutual-connections/${uid}`} to={`first-degree`}>
                                    1st Degrees
                                </Tab>
                                <Tab setOpenTab={setOpenTab} openTab={id} path={`mutual-connections/${uid}`} to={`second-degree`}>
                                    2nd Degrees
                                </Tab>
                                <Tab setOpenTab={setOpenTab} openTab={id} path={`mutual-connections/${uid}`} to={`third-degree`}>
                                    3rd Degrees
                                </Tab>

                            </HorizontalTabs>
                            <TabContent tab="first-degree" openTab={id}>
                                <FirstDegreeConnect user={firstDegree} />
                            </TabContent>
                            <TabContent tab="second-degree" openTab={id}>
                                <SecondDegreeConnect />
                            </TabContent>
                            <TabContent tab="third-degree" openTab={id}>
                                <ThirdDegreeConnect />
                            </TabContent>
                        </div>}
                        
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default MutualConnections;