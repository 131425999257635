import API from 'store/common/api';
import { APIEndPoints } from 'store/common/endPoint';

export const fetchPosts = async ({ pageParam = 1 }) => {
  try {
    const obj = {
      url: APIEndPoints.Post(pageParam, 20),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchMyActivity = async ({ pageParam = 1 }) => {
  try {
    const obj = {
      url: APIEndPoints.MyActivity(pageParam, 20),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const fetchCompanyPosts = async ({ pageParam = 1, page = 20, queryKey }) => {
  const companyId = queryKey[1];
  try {
    const obj = {
      url: APIEndPoints.CompanyPost(pageParam, page, companyId),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPostFunc = async ({ queryKey }) => {
  const postId = queryKey[1];
  if(!postId) return
  try {
    const obj = {
      url: APIEndPoints.getPost(postId),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const pinPost = async ({ id, method }) => {
  try {
    const obj = {
      url: APIEndPoints.PinPost(id),
      method: method,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const newPost = async (body) => {
  try {
    const obj = {
      url: APIEndPoints.NewPost,
      method: 'POST',
      body: body,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const rePost = async ({ id, body }) => {
  try {
    const obj = {
      url: APIEndPoints.PostRepost(id),
      method: 'POST',
      body: JSON.stringify(body),
      isFormData: false,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const editPostFunc = async ({ id, body }) => {
  try {
    const obj = {
      url: APIEndPoints.UpdatePost(id),
      method: 'PUT',
      body: body,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postLikeFunc = async ({ id, remove }) => {
  try {
    const obj = {
      url: APIEndPoints.PostLike(id),
      method: 'PUT',
      body: JSON.stringify({ remove: remove }),
      isFormData: false,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const postDisLikeFunc = async ({ id, remove }) => {
  try {
    const obj = {
      url: APIEndPoints.PostDislike(id),
      method: 'DELETE',
      body: JSON.stringify({ remove: remove }),
      isFormData: false,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const deletePost = async ({ id, remove }) => {
  try {
    const obj = {
      url: APIEndPoints.PostDelete(id),
      method: 'DELETE',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
