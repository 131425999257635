import React from 'react';
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
         <div className="footer-page">
            <div>
                <Link to="/aboutus">
                        {`About Us`}
                        {' | '}
                </Link>
                <Link to="/termsandconditions">
                    {`Terms & Conditions`}
                    {' | '}
                </Link>
                <Link to="/privacypolicy">
                    {`Privacy Policy`}
                </Link>
                {/* {' | '}
                <Link to="/business">
                        {`For Business`}
                </Link> */}
            </div>
        <div>{`myBridge Corporation. 101 North Tryon Street, Suite 1260. Charlotte, NC 28246`}</div>
      </div>
    </>
  );
};

export default Footer;
