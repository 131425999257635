import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "store/common/api";
import { APIEndPoints } from '../common/endPoint';

const initialState = {
    msg: '',
    loading: false,
    error: false,
    teamInfo: {},
    isTeamUpdated: false,
    pages: null
}

export const teamList = createAsyncThunk('teamList', async (body) => {
    const obj = {
        url: `${APIEndPoints.Teams}`,
        method: 'GET'
    }
    return await API(obj)
})

export const addTeam = createAsyncThunk('addTeam', async (body) => {
    const obj = {
        url: `${APIEndPoints.Teams}`,
        method: 'POST',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const updateTeam = createAsyncThunk('updateTeam', async (body) => {
    const obj = {
        url: `${APIEndPoints.Teams}${body.id}/`,
        method: 'PUT',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const deleteTeam = createAsyncThunk('deleteTeam', async (id) => {
    const obj = {
        url: `${APIEndPoints.Teams}${id}/`,
        method: 'DELETE',
    }
    return await API(obj)
})

export const getPages = createAsyncThunk('getPages', async (body) => {
    const obj = {
        url: `${APIEndPoints.Pages}`,
        method: 'GET'
    }
    return await API(obj)
})




const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
    },
    extraReducers: {
        [teamList.pending]: (state, action) => {
            state.pending = true
            state.error = false;
        },
        [teamList.fulfilled]: (state, action) => {
            state.pending = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action.payload.msg;
                state.teamInfo = action.payload;
            }
        },
        [teamList.rejected]: (state, action) => {
            state.loading = false
        },

        [addTeam.pending]: (state, action) => {
            state.pending = true
            state.loading = true
            state.error = false;
            state.isTeamUpdated = false;
        },
        [addTeam.fulfilled]: (state, action) => {
            state.pending = false;
            state.loading = false;
            if (action.payload.error || !action.payload.id) {
                state.error = action.payload.error
                state.message = action.payload?.message
            } else {
                state.msg = action.payload.id;
                state.isTeamUpdated = true;
            }
        },
        [addTeam.rejected]: (state, action) => {
            state.loading = false
        },

        [updateTeam.pending]: (state, action) => {
            state.pending = true
            state.loading = true
            state.error = false;
            state.isTeamUpdated = false;
        },
        [updateTeam.fulfilled]: (state, action) => {
            state.pending = false;
            state.loading = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action.payload.msg;
                state.isTeamUpdated = true;
            }
        },
        [updateTeam.rejected]: (state, action) => {
            state.loading = false
        },

        /* delete team */

        [deleteTeam.pending]: (state, action) => {
            state.pending = true
            state.error = false;
            state.isTeamUpdated = false;
        },
        [deleteTeam.fulfilled]: (state, action) => {
            state.pending = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action.payload.msg;
                state.isTeamUpdated = true;
            }
        },
        [deleteTeam.rejected]: (state, action) => {
            state.loading = false
        },

        /* pages */
        [getPages.pending]: (state, action) => {
            state.pending = true
            state.error = false;
            state.pages = null;
        },
        [getPages.fulfilled]: (state, action) => {
            state.pending = false;
            if (action.payload.error) {
                state.error = action.payload.error
            } else {
                state.msg = action.payload.msg;
                state.pages = action.payload;
            }
        },
        [getPages.rejected]: (state, action) => {
            state.loading = false
            state.pages = null;
        },

    }
})

export default teamSlice.reducer